import {Switch} from "@material-ui/core";
import React from "react";
import {axiosAPI} from "../api";

export const WatchProjectSwitch = (props: {
  projectId: number;
  initialChecked: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {initialChecked, projectId, ...rest} = props;
  const [checked, setChecked] = React.useState(initialChecked);

  return (
    <Switch
      {...rest}
      checked={checked}
      onChange={() =>
        setChecked((oldChecked) => {
          axiosAPI.post(`projects/watched/${projectId}/${oldChecked ? "unwatch" : "watch"}/`);
          return !oldChecked;
        })
      }
    />
  );
};
