import {faMagicWandSparkles} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Tooltip, Typography} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import {groupBy} from "lodash";
import React from "react";
import {Helmet} from "react-helmet";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import Typography2 from "../../../components/Typography2";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";

const SCCOFromApprovedItems = (props) => {
  const {project, ...rest} = props;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      pcoItems: [],
      subContracts: [],
    },
  });

  const pageData = pageDataQuery.data;
  const {pcoItems, subContracts} = pageData;

  const pcoItemByBudgetCode = groupBy(pcoItems, (item) => item.budget.id);

  return (
    <>
      <Helmet title={`Subcontract From Approved Items`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/change-order-wizard/`}>
          <Typography color="textSecondary">Change Order Wizard</Typography>
        </MuiNavLink>
        <Typography color="textSecondary">SCO</Typography>
        <Typography color="textPrimary">Create SCO from approved PCO items</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Title>
          <FontAwesomeIcon icon={faMagicWandSparkles} /> Create SCO from approved PCO items
        </PageHeader.Title>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Body>
          {subContracts.map((contract, index) => {
            return (
              <React.Fragment key={contract.id}>
                <ContractPaperItem contract={contract} projectId={project.id} />
                {pcoItemByBudgetCode[contract.budget.id].map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <PCOItemPaperItem item={item} />
                    </React.Fragment>
                  );
                })}
              </React.Fragment>
            );
          })}
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/sccos/from-approved-items/`} mt={2} />

      {/* <h2>PCO Items By Budget Code</h2>
      <pre>{JSON.stringify(pcoItems, null, 2)}</pre>
      <h2>Page Data Query</h2>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default SCCOFromApprovedItems;

const ContractPaperItem = (props) => {
  const {contract, projectId, ...rest} = props;

  return (
    <PaperItem bgcolor="grey.100">
      <PaperItem.Body>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <MuiNavLink href={contract.url} underline={"always"}>
              {contract.display}
            </MuiNavLink>
          </Grid>
          <Grid item xs={12} sm={5}>
            {contract?.to_company?.name}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Tooltip title={`Subcontract current value`}>
              <Box textAlign="right">
                <Currency number={contract?.current_value} />
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={36}></PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton
          icon={LinkIcon}
          component={MuiNavLink}
          title="Link to COR or Internal Change Order"
          href={`/v2/projects/${projectId}/change-order-wizard/sco/${contract.id}/create/`}
        />
      </PaperItem.RightHover>
      {/*  */}
    </PaperItem>
  );
};

const PCOItemPaperItem = (props) => {
  const {item, projectId, ...rest} = props;

  return (
    <PaperItem>
      <PaperItem.Body>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <MuiNavLink href={item.url} underline={"always"}>
              <small>{item.pco_display}</small>
            </MuiNavLink>
          </Grid>
          <Grid item sm={4}>
            <small>{item.description}</small>
          </Grid>
          <Grid item sm={1}>
            <Typography2 type="metadata">{item.budget.budget_code}</Typography2>
          </Grid>

          <Grid item xs={12} sm={2}>
            <Tooltip title={`Budget approved value`}>
              <Box textAlign="right">
                <small>
                  <Currency number={item.budget_approved_amount} />
                </small>
              </Box>
            </Tooltip>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={36}></PaperItem.Right>
      {/*  */}
    </PaperItem>
  );
};
