import {Box, Button, Divider, FormControl, FormHelperText, makeStyles} from "@material-ui/core";
import {green, red} from "@material-ui/core/colors";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import {Field, Form} from "react-final-form";
import HtmlRender from "../../../js/components/HtmlRender";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    green: {
      color: green[600],
      minWidth: "120px",
      "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: green[600],
        fontWeight: 900,
      },
    },
    red: {
      color: "red",
      minWidth: "120px",
      "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: red[600],
        fontWeight: 900,
      },
    },
  };
});

export const WellnessCheckExternalQuestionnaireFrom = (props: {
  questionSet: {};
  onSubmitted: any;
  language?: "EN" | "SP";
  [rest: string]: any;
}) => {
  const {questionSet, onSubmitted, language = "EN"} = props;
  const questionCount = Object.keys(questionSet).length;
  const classes = useStyles();
  const postCurrentPage = usePostCurrentPage();

  return (
    <Form
      onSubmit={(values) => {
        postCurrentPage.mutateAsync(values).then((data) => onSubmitted(data));
      }}
      validate={(values) => {
        const errors = {};
        Object.keys(questionSet).map((question_key, i) => {
          if (values[question_key] === undefined || values[question_key] === null) {
            errors[question_key] = "Required";
          }
        });
        return errors;
      }}
    >
      {(formProps) => {
        const {handleSubmit, form, submitting, pristine, errors, invalid, values} = formProps;
        return (
          <form onSubmit={handleSubmit}>
            {/*
            <h3>Props</h3>
            <pre>{JSON.stringify(values, null, 2)}</pre>
            */}
            {Object.keys(questionSet).map((question_key, i) => {
              const question = questionSet[question_key];
              const isFirst = i === 0;
              const isLast = i + 1 === questionCount;
              return (
                <div key={question_key}>
                  <Box pb={isLast ? 0 : 2} pt={isFirst ? 0 : 2}>
                    <HtmlRender html={question} />
                    <Field name={question_key}>
                      {(fieldProps) => {
                        const {input, meta, ...rest} = fieldProps;
                        return (
                          <FormControl error={meta.invalid}>
                            {/* <div>
                                <pre>{JSON.stringify(meta, null, 2)}</pre>
                              </div> */}

                            {question_key.startsWith("-") ? (
                              // these questions yes is positive
                              <ToggleButtonGroup
                                exclusive
                                {...input}
                                onChange={(event, value) => {
                                  input.onChange(value);
                                }}
                              >
                                <ToggleButton value={true} className={classes.green}>
                                  {language === "SP" ? "Si" : "Yes"}
                                </ToggleButton>
                                <ToggleButton value={false} className={classes.red}>
                                  No
                                </ToggleButton>
                              </ToggleButtonGroup>
                            ) : (
                              // these questions no is positive
                              <ToggleButtonGroup
                                exclusive
                                {...input}
                                onChange={(event, value) => {
                                  input.onChange(value);
                                }}
                              >
                                <ToggleButton value={false} className={classes.green}>
                                  No
                                </ToggleButton>
                                <ToggleButton value={true} className={classes.red}>
                                  {language === "SP" ? "Si" : "Yes"}
                                </ToggleButton>
                              </ToggleButtonGroup>
                            )}
                            {meta.invalid && meta.touched && (
                              <FormHelperText id="my-helper-text">{meta.error}</FormHelperText>
                            )}
                          </FormControl>
                        );
                      }}
                    </Field>
                  </Box>
                  {!isLast && <Divider />}
                </div>
              );
            })}
            <Box mt={4} mb={4}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                type="submit"
                size="large"
                disabled={invalid || submitting || pristine || postCurrentPage.isLoading}
              >
                {invalid ? `Complete All ${questionCount} Questions` : "Submit"}
              </Button>
            </Box>
            {/* <pre>{JSON.stringify(formProps, null, 2)}</pre> */}
          </form>
        );
      }}
    </Form>
  );
};
