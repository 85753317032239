import {faBuilding} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Typography} from "@material-ui/core";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {axiosAPI} from "../../api";
import {ConfirmationDialog} from "../../components/Dialogs";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import useBlockUI from "../../hooks/useBlockUI";
import {CompanyCreateForm} from "./CompanyForms";

const CompanyCreate = (props) => {
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const [values, setValues] = React.useState({name: "", markets: [], company_role_id: "subcontractor"});
  const [createConfirmationIsOpen, setCreateConfirmationIsOpen] = React.useState(false);

  return (
    <>
      <Helmet title={`Create New Company`} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Create Company</Typography>
      </Breadcrumbs>

      {/* <PageHeader mt={2} mb={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faBuilding} /> Create New Company
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader> */}
      <Box mt={2} />
      <PaperPanel mt={2}>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faBuilding} /> Create New Company
          </PaperPanel.Header.Title>
        </PaperPanel.Header>

        <PaperPanel.Body p={1}>
          <CompanyCreateForm
            onSubmit={(values) => {
              setValues(values);
              setCreateConfirmationIsOpen(true);
            }}
            initialValues={values}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <ConfirmationDialog
        isOpen={createConfirmationIsOpen}
        onApprove={() => {
          setCreateConfirmationIsOpen(false);
          blockUI.blockUI("Creating company...");
          axiosAPI.post("/companies/", values).then((res) => {
            navigate(`/v2/companies/${res.data.id}/update/`);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => setCreateConfirmationIsOpen(false)}
      >
        You want to create new company {values.name}. Please make sure this company does not already exist to avoid
        duplicate companies.
      </ConfirmationDialog>

      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre>
      <pre>{JSON.stringify(companyQuery, null, 2)}</pre> */}
    </>
  );
};

export default CompanyCreate;
