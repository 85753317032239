import {faCheckCircle, faList} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import pluralize from "pluralize";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {MuiNavLink} from "../../../components/Links";
import {DaysOverdueLabel} from "../../../components/OverdueLabels";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import {TagList} from "../../../components/Tags";
import Typography2 from "../../../components/Typography2";
import BudgetPaperItemGrid from "./BudgetPaperItemGrid";

export const CORPaperItem = (props) => {
  const {cor, isDragging, dragHandleProps, corIsSelected, onEditCor, onChangeCorSelected, ...rest} = props;
  const itemCount = cor.pcoitem_count;

  return (
    <PaperItem isDragging={isDragging} {...rest}>
      <PaperItem.Left minWidth={45}>
        <PaperItem.SelectedCheckbox checked={corIsSelected} onChange={onChangeCorSelected} />
        {dragHandleProps && (
          <Box display="flex" alignItems="center">
            <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
            <PaperItem.Position position={cor.position} />
          </Box>
        )}
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12}>
              <StatusLabel status={cor.status_display} />{" "}
              <MuiNavLink href={cor.url} underline="always">
                {cor.description}
              </MuiNavLink>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6} sm={3}>
                <Typography2 type="metadata">
                  <MuiNavLink href={`${cor.pco.url}#tab=items`}>
                    <FontAwesomeIcon icon={faList} /> {pluralize(`${itemCount} Item`, itemCount)}
                  </MuiNavLink>
                </Typography2>
              </Grid>
              <Grid item xs={6} sm={3}>
                {cor.pco && (
                  <Typography2 type="metadata">
                    <MuiNavLink underline="always" href={cor.pco.url}>
                      PCO: #{cor.pco.number_display}
                    </MuiNavLink>
                  </Typography2>
                )}
              </Grid>
              <Grid item xs={6} sm={3}>
                {cor.pcco && (
                  <Typography2 type="metadata">
                    <MuiNavLink underline="always" href={cor.pcco.url}>
                      OCO: #{cor.pcco.number_display}
                    </MuiNavLink>
                  </Typography2>
                )}
              </Grid>
              <Grid item xs={6} sm={3}></Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <BudgetPaperItemGrid item={cor} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TagList tags={cor.tags} />{" "}
          </Grid>
          <Grid item xs={12} sm={3}>
            {cor.is_billable && (
              <Typography2 type="metadata" style={{color: "green"}}>
                <FontAwesomeIcon icon={faCheckCircle} /> Ready For Billing
              </Typography2>
            )}
          </Grid>
          <Grid item xs={12} sm={3}>
            {cor.is_overdue && (
              <DaysOverdueLabel
                businessDaysOverdue={cor.required_date_business_days_delta}
                dueDate={cor.required_date}
                showUpcomingWarning
              />
            )}
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={onEditCor ? 105 : 85}>
        <Box textAlign="right">
          <Typography2 noWrap type="metadata">
            {cor.number_display}
          </Typography2>
        </Box>
      </PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.ItemNumber number={cor.number_display} />
        <PaperItem.RightHover.IconButton icon={LinkIcon} title="Link" component={Link} href={cor.url} />
        {onEditCor && <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditCor} />}
        <PaperItem.RightHover.IconButton
          icon={DeleteIcon}
          title="Delete"
          component={MuiNavLink}
          to={`/v2/projects/${cor.pco?.project_id}/delete/cors/cor/${cor.id}/?next=/v2/projects/${cor.pco?.project_id}/cors/&cancel=/v2/projects/${cor.pco?.project_id}/cors/`}
          disabled={cor.is_closed}
        />

        <BoxFilesIconButtonLink
          projectId={cor.project_id}
          app="cors"
          model="cor"
          objectId={cor.id}
          boxFolderId={cor.box_folder_id}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
