import PaperPanel from "../../../components/PaperPanel";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";

import {Box, Grid, Typography} from "@material-ui/core";
import moment from "moment";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {useNavigate, useSearchParams} from "react-router-dom";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import durationUnitFieldChoices from "../../../components/forms/choices/durationUnitFieldChoices.json";
import pcoReasons from "../../../components/forms/choices/pcoReasons.json";
import {
  ApiAutoselectMui,
  AutoselectMui,
  DatePickerMui,
  NumberFieldMui,
  ProjectTagAutocompleteMui,
  SimpleAutoselectMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import BlockUI from "../../../components/GlobalLoaders";
import InnerFooter from "../../../components/InnerFooter";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import useBlockUI from "../../../hooks/useBlockUI";
import {PCOSelectStatus} from "../ChangeOrders/PCOForms";

const PCOCreate = (props) => {
  const {project, ...rest} = props;
  const [searchParams, setSearchParams] = useSearchParams();
  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {initialFormValues: {}},
    },
    Object.fromEntries(searchParams)
  );
  const pageData = pageDataQuery.data;
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const {create: createPCO} = useSentinelDetailAPI(`projects/${project.id}/pcos/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const {initialFormValues} = pageData;
  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Project Details..." />;
  }

  return (
    <>
      <Helmet title={`${project?.display} - Create External PCO`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" href={`/v2/projects/${project.id}/change-order-wizard/`}>
          Change Order Wizard
        </MuiNavLink>
        <Typography color="textPrimary">Create PCO</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Title>{`${project?.display} - Create External PCO`}</PageHeader.Title>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Create External PCO</PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body m={1}>
          <PCOCreateForm
            projectId={project.id}
            onSubmit={(values, form) => {
              blockUI.blockUI("Creating...");
              const submitMode = values.submitMode;
              delete values.submitMode;
              form.pauseValidation();

              createPCO.mutateAsync(values).then((result) => {
                if (submitMode === "saveAndEdit") {
                  window.location = result.url;
                }
                if (submitMode === "saveAndClose") {
                  // window.location.href = `/v2/projects/${project.id}/pcos/`;
                  navigate(`/v2/projects/${project.id}/pcos/`);
                  blockUI.unblockUI();
                }
                if (submitMode === "saveAndAdd") {
                  pageDataQuery.refetch().then(() => {
                    form.restart();
                    form.resumeValidation();
                    blockUI.unblockUI();
                  });
                }
              });
            }}
            initialValues={{
              schedule_impact_unit: "days",
              status: "pending",
              number: pageData.nextPCONumber,
              is_internal: false,
              tags: [],
              date: moment(),
              contract: pageData?.contract,
              ...initialFormValues,
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/pcos/${project.id}/create/`} />
      {/* <h2>Page Data Query</h2>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default PCOCreate;

const PCOCreateForm = (props) => {
  const {projectId, isReadOnly, onSubmit, ...rest} = props;

  const validateNumber = async (value, values, field) => {
    if (!values.number) return "Required";
    if (values.number < 1) return "Must use a positive number";
    if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
    const response = await axiosAPI.get(
      `/projects/${projectId}/pcos/validate-number/?number=${values?.number || ""}&is_internal=${values.is_internal}${
        values?.id ? `&id=${values.id}` : ``
      }`
    );
    if (response.data.error) {
      return response.data.error;
    }
  };

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values, dirtyFields, ...rest} = props;
          return (
            <>
              {/* <BeforeUnload block={!pristine} /> */}
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <PCOSelectStatus
                        name="status"
                        label="Status"
                        pco={values}
                        isDirty={Boolean(dirtyFields.status)}
                        required
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <NumberFieldMui
                        name="number"
                        label="Number"
                        disabled={isReadOnly}
                        fieldProps={{
                          validate: validateNumber,
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <NumberFieldMui name="revision" label="Revision" disabled />
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <TextFieldMui name="description" label="Description" disabled={isReadOnly} required />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <DatePickerMui name="date" label="Date" disabled={isReadOnly} />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <TextFieldMui name="category" label="Category" disabled={isReadOnly} />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <AutoselectMui
                        name="reason"
                        label="Reason"
                        valueProp="id"
                        labelProp="description"
                        disabled={isReadOnly}
                        options={pcoReasons.map((option) => {
                          return {
                            id: option.value,
                            description: option.label,
                          };
                        })}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" disabled={isReadOnly} />
                    </Grid>
                    <Grid item sm={2} xs={6}>
                      <NumberFieldMui
                        name="schedule_impact"
                        label="Schedule Impact"
                        disabled={isReadOnly}
                        decimalScale={1}
                        required
                      />
                    </Grid>
                    <Grid item sm={2} xs={6}>
                      <SimpleAutoselectMui
                        name="schedule_impact_unit"
                        label="Units"
                        disabled={isReadOnly}
                        options={durationUnitFieldChoices}
                        required
                      />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <ApiAutoselectMui
                        name="rfi"
                        label="RFI"
                        baseURL={`projects/${projectId}/rfis/`}
                        minCharacters={0}
                        disabled={isReadOnly}
                        queryParams={{page_size: 10000, parent__isnull: true}}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <ApiAutoselectMui
                        name="contract"
                        label="Contract"
                        baseURL={`projects/${projectId}/contracts/prime/`}
                        minCharacters={0}
                        disabled={isReadOnly}
                        required
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TinyMCEMui name="notes" label="Notes" disabled={isReadOnly} />
                    </Grid>
                  </Grid>

                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton
                        disabled={submitting || pristine}
                        onClick={() => {
                          form.change("submitMode", "saveAndClose");
                        }}
                      />
                      <Box mx={1}>
                        <FormActions.SaveAndEditButton
                          disabled={submitting || pristine}
                          label="Save and Edit"
                          onClick={() => {
                            form.change("submitMode", "saveAndEdit");
                          }}
                        />
                      </Box>
                      <FormActions.SaveAndAddButton
                        disabled={submitting || pristine}
                        onClick={() => {
                          form.change("submitMode", "saveAndAdd");
                        }}
                      />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};
