import {faLongArrowRight} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";

const SubmittalTransmittalPaperItem = (props: {
  transmittal: any;
  onEdit: (transmittal) => void;
  projectId: number;
  packageId: number;
  [rest: string]: any;
}) => {
  const {transmittal, onEdit, projectId, packageId, ...rest} = props;

  return (
    <PaperItem>
      {/* <PaperItem.Left></PaperItem.Left> */}
      <PaperItem.Body>
        <Grid container spacing={0}>
          <Grid item sm={6} xs={12}>
            <MuiNavLink
              href={`/v2/projects/${projectId}/submittals/packages/${packageId}/transmittals/${transmittal.id}/`}
            >
              {transmittal.from_contact.full_name} <FontAwesomeIcon icon={faLongArrowRight} />{" "}
              {transmittal.to_contact.full_name}
            </MuiNavLink>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Typography2 type="metadata">Date Sent: {transmittal.date_sent}</Typography2>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Typography2 type="metadata">Date Required: {transmittal.date_required || "None"}</Typography2>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Typography2 type="metadata">Transmitted For: {transmittal.transmitted_for_display}</Typography2>
          </Grid>
        </Grid>
        {/* <pre>{JSON.stringify(transmittal, null, 2)}</pre> */}
      </PaperItem.Body>
      <PaperItem.Right>
        <Box textAlign="right">
          <Typography2 noWrap type="metadata">
            {transmittal.number_display}
          </Typography2>
        </Box>
      </PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton
          icon={PictureAsPdfIcon}
          title="Get PDF"
          component={Link}
          href={`/reports2/submittals/transmittal/${transmittal.id}/?display_from_signature=on`}
          target="_blank"
        />
        <PaperItem.RightHover.IconButton
          icon={EditIcon}
          title="Quick Edit"
          onClick={() => {
            onEdit(transmittal);
          }}
        />
        <PaperItem.RightHover.IconButton
          icon={LinkIcon}
          title="Link"
          href={`/v2/projects/${projectId}/submittals/packages/${packageId}/transmittals/${transmittal.id}/`}
          component={MuiNavLink}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export default SubmittalTransmittalPaperItem;
