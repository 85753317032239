import {faHistory} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {PaperProps} from "@material-ui/core";
import classnames from "classnames";
import Moment from "react-moment";
import {useSentinelListAPI} from "../hooks/useSentinelAPI";
import {makeNovoClasses} from "../theme";
import PaperPanel from "./PaperPanel";
import {ReactTableMui} from "./ReactTableMui";

const FSMLog = (props: {url: string; initialPageSize?: number; paperPanelProps?: PaperProps}) => {
  const {url, initialPageSize = 20, paperPanelProps = {}, ...rest} = props;
  const novoClasses = makeNovoClasses();

  const {query: fsmLogQuery} = useSentinelListAPI(`${url}`, {
    initialData: [],
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });

  return (
    <>
      <PaperPanel square variant="outlined" {...paperPanelProps}>
        <PaperPanel.Header isLoading={fsmLogQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faHistory} /> State Log
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => fsmLogQuery.refetch()}
                isFetching={fsmLogQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}
            rightAlignColumns={["timestamp"]}
            columns={[
              {
                Header: "By",
                accessor: "by.full_name",
              },
              {
                Header: "State",
                accessor: "state_display",
              },
              {
                Header: "Timestamp",
                accessor: "timestamp",
                Cell: ({value, row}) => (
                  <>
                    <Moment calendar withTitle date={value} />
                  </>
                ),
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "timestamp",
                  desc: false,
                },
              ],
            }}
            data={fsmLogQuery.data}
            {...rest}
          />
          {/* {mailLogQuery.data.total_pages > 1 && ( */}
          {/* )} */}
          {/* <pre>{JSON.stringify(fsmLogQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default FSMLog;
