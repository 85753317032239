import {Box, Button} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";

const WellnessCheckSiteRestrictedMessage = (props) => {
  const {wellnessCheckResult} = props;
  const postCurrentPage = usePostCurrentPage();

  return (
    <>
      <Alert severity="error">
        <AlertTitle>Site Restricted</AlertTitle>
        Your failed survey needs to be corrected before you are allowed on-site. All questions must be answered in the
        affirmative. Please reset your check in below and try again.
      </Alert>
      <Box mt={4}>
        <Button
          variant="contained"
          fullWidth
          color="secondary"
          onClick={() =>
            postCurrentPage.mutateAsync({action: "reset"}).then((values) => {
              window.location.reload();
            })
          }
        >
          Reset Check In
        </Button>
      </Box>
    </>
  );
};

export default WellnessCheckSiteRestrictedMessage;
