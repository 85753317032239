import {faPlus, faSortAmountDown, faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Link,
  Tooltip,
} from "@material-ui/core";
import {Checkboxes} from "mui-rff";
import pluralize from "pluralize";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import NumberFormat from "react-number-format";
import {useLocation} from "react-router-dom";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import durationUnitFieldChoices from "../../../components/forms/choices/durationUnitFieldChoices.json";
import pcoInternalStatuses from "../../../components/forms/choices/pcoInternalStatuses.json";
import pcoReasons from "../../../components/forms/choices/pcoReasons.json";
import pcoStatuses from "../../../components/forms/choices/pcoStatuses.json";
import {
  ApiAutoselectMui,
  AutoselectMui,
  CSICodeAutocompleteMui,
  CurrencyFieldMui,
  DatePickerMui,
  FileFieldMui,
  NumberFieldMui,
  PercentFieldMui,
  ProjectBudgetAutoselectMui,
  ProjectTagAutocompleteMui,
  SelectStatus,
  SimpleAutoselectMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import {beforeDialogClose} from "../../../components/forms/Form";
import FormActions from "../../../components/forms/FormActions";
import {StatusIcon} from "../../../components/Icons";
import InnerFooter from "../../../components/InnerFooter";
import {MuiNavLink, ProjectAuditLink} from "../../../components/Links";
import {PrevNext} from "../../../components/PrevNext";
import {SuperuserInfo} from "../../../components/SuperuserInfo";
import useFormFocusFieldRef from "../../../hooks/useFormFocusFieldRef";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";

export const PCOSelectStatus = (props: {pco: any; isDirty?: boolean; [rest: string]: any}) => {
  const {pco, isDirty, ...rest} = props;
  if (isDirty) {
    return (
      <SelectStatus
        options={pco.is_internal ? pcoInternalStatuses : pcoStatuses}
        name="status"
        label="Status"
        {...rest}
      />
    );
  }
  if (["revised", "void"].includes(pco.status) || pco.cor || pco.parent_id) {
    return (
      <>
        <StatusIcon status={pco.status} type="changeOrder" /> {pco.status_display}
        {pco.cor && (
          <FormHelperText>
            Set by{" "}
            <MuiNavLink to={`/v2/projects/${pco.project_id}/pcos/${pco.id}/#tab=cor`}>
              COR: {pco.cor?.display}
            </MuiNavLink>
          </FormHelperText>
        )}
      </>
    );
  }
  return (
    <SelectStatus
      options={pco.is_internal ? pcoInternalStatuses : pcoStatuses}
      name="status"
      label="Status"
      {...rest}
    />
  );
};

export const PCODialogForm = React.forwardRef(
  (
    props: {
      projectId: number;
      onSubmit: (values: any) => void;
      handleClose: () => void;
      isOpen: boolean;
      isNew?: boolean;
      isReadOnly?: boolean;
      previous: any;
      next: any;
      activePCO: any;
      isInternal?: boolean;
      [rest: string]: any;
    },
    ref: React.RefObject<HTMLFormElement>
  ) => {
    const {
      projectId,
      onSubmit,
      isNew,
      isOpen,
      handleClose,
      isReadOnly,
      setActivePCO,
      activePCO,
      pcos,
      isInternal = activePCO.is_internal,
      ...rest
    } = props;
    const formPropsRef = React.useRef(null);

    const validateNumber = async (value, values, field) => {
      if (!values.number) return "Required";
      if (!isNew && values.number === activePCO.number) return;
      if (values.number < 1) return "Must use a positive number";
      if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
      const response = await axiosAPI.get(
        `/projects/${projectId}/pcos/validate-number/?number=${values?.number || ""}&is_internal=${isInternal}${
          !isNew ? `&id=${activePCO.id}` : ""
        }`
      );
      if (response.data.error) {
        return response.data.error;
      }
    };

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={(event, reason) => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
          maxWidth="md"
          fullWidth
          // scroll="paper"
        >
          <FinalForm onSubmit={onSubmit} {...rest}>
            {(props) => {
              const {handleSubmit, form, submitting, pristine = true, values, dirtyFields} = props;
              formPropsRef.current = props;

              return (
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                  <DialogTitle id="quick-edit-form">
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        {/* {!isNew && <StatusLabel status={values.status_display} />}  */}
                        {isNew ? "Create PCO" : "Update PCO"}
                      </Grid>
                      {!isNew && (
                        <Grid item>
                          <PrevNext
                            displayProp="number_display"
                            setActiveItem={setActivePCO}
                            items={pcos}
                            activeItem={activePCO}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <PCOSelectStatus
                            name="status"
                            label="Status"
                            pco={values}
                            isDirty={Boolean(dirtyFields.status)}
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextFieldMui
                            min={0}
                            label="Number"
                            name="number"
                            type="number"
                            required
                            fieldProps={{
                              validate: validateNumber,
                            }}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui label="Description" name="description" required autoFocus />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <DatePickerMui label="Date" name="date" disabled={isReadOnly} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <ProjectTagAutocompleteMui projectId={projectId} label="Tags" name="tags" />
                        </Grid>
                        <Grid item xs={12}>
                          <TinyMCEMui label="Notes" name="notes" disabled={isReadOnly} />
                        </Grid>
                      </Grid>
                    </FinalFormKeyboardShortcuts>
                    {!isNew && (
                      <SuperuserInfo
                        objects={[
                          {
                            contentType: "pcos",
                            model: "pco",
                            id: values.id,
                            title: values.display,
                          },
                        ]}
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton
                      label="Save"
                      disabled={pristine}
                      onClick={() => form.change("submitMode", "saveAndClose")}
                    />
                    {isNew && (
                      <FormActions.SaveAndEditButton
                        disabled={submitting || pristine}
                        onClick={() => form.change("submitMode", "editAfterSubmit")}
                      />
                    )}
                    {isNew && (
                      <FormActions.SaveAndAddButton
                        disabled={pristine}
                        onClick={() => form.change("submitMode", "addAnother")}
                      />
                    )}
                    <FormActions.CancelButton
                      onClick={() => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
                    />
                  </DialogActions>
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const PCODetailForm = (props) => {
  const {projectId, isReadOnly, onSubmit, ...rest} = props;
  const {pathname} = useLocation();

  const validateNumber = async (value, values, field) => {
    if (!values.number) return "Required";
    if (values.number < 1) return "Must use a positive number";
    if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
    const response = await axiosAPI.get(
      `/projects/${projectId}/pcos/validate-number/?number=${values?.number || ""}&is_internal=${values.is_internal}${
        values?.id ? `&id=${values.id}` : ``
      }`
    );
    if (response.data.error) {
      return response.data.error;
    }
  };

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values, dirtyFields, ...rest} = props;
          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <PCOSelectStatus
                        name="status"
                        label="Status"
                        pco={values}
                        isDirty={Boolean(dirtyFields.status)}
                        required
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <NumberFieldMui
                        name="number"
                        label="Number"
                        disabled={isReadOnly}
                        fieldProps={{
                          validate: validateNumber,
                        }}
                      />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <NumberFieldMui name="revision" label="Revision" disabled />
                    </Grid>
                    <Grid item sm={9} xs={12}>
                      <TextFieldMui name="description" label="Description" disabled={isReadOnly} required />
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <DatePickerMui name="date" label="Date" disabled={isReadOnly} />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <TextFieldMui name="category" label="Category" disabled={isReadOnly} />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      {/* <SimpleAutoselectMui name="reason" label="Reason" disabled={isReadOnly} options={pcoReasons} /> */}
                      <AutoselectMui
                        name="reason"
                        label="Reason"
                        valueProp="id"
                        labelProp="description"
                        disabled={isReadOnly}
                        options={pcoReasons.map((option) => {
                          return {
                            id: option.value,
                            description: option.label,
                          };
                        })}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" disabled={isReadOnly} />
                    </Grid>
                    <Grid item sm={2} xs={6}>
                      <NumberFieldMui
                        name="schedule_impact"
                        label="Schedule Impact"
                        disabled={isReadOnly}
                        decimalScale={1}
                      />
                    </Grid>
                    <Grid item sm={2} xs={6}>
                      <SimpleAutoselectMui
                        name="schedule_impact_unit"
                        label="Units"
                        disabled={isReadOnly}
                        options={durationUnitFieldChoices}
                        required
                      />
                    </Grid>

                    <Grid item sm={4} xs={12}>
                      <ApiAutoselectMui
                        name="rfi"
                        label="RFI"
                        baseURL={`projects/${projectId}/rfis/`}
                        minCharacters={0}
                        disabled={isReadOnly}
                        queryParams={{page_size: 10000, parent__isnull: true}}
                        // renderOption={(option: any) => {
                        //   return (
                        //     <>
                        //       <div>{option.display}</div>
                        //     </>
                        //   );
                        // }}
                      />
                    </Grid>
                    <Grid item sm={4} xs={12}>
                      <ApiAutoselectMui
                        name="contract"
                        label="Contract"
                        baseURL={`projects/${projectId}/contracts/prime/`}
                        minCharacters={0}
                        disabled={isReadOnly}
                        required
                        // renderOption={(option: any) => {
                        //   return (
                        //     <>
                        //       <div>{option.display}</div>
                        //     </>
                        //   );
                        // }}
                      />
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <TinyMCEMui name="notes" label="Notes" disabled={isReadOnly} />
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}

                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                      <FormActions.DeleteButton
                        href={`/v2/projects/${projectId}/delete/pcos/pco/${values.id}/?next=/v2/projects/${projectId}/pcos/&cancel=${pathname}`}
                        disabled={isReadOnly}
                      />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

export const PCOBulkUpdateForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  pcoCount?: number;
  restrictNovoSigners?: number;

  [rest: string]: any;
}) => {
  const {
    projectId,
    onSubmit,
    isOpen,
    handleClose,
    isReadOnly = false,
    pcoCount,
    statusOptions,
    restrictNovoSigners = false,
    ...rest
  } = props;

  return (
    <>
      <Box display="flex" justifyContent={"center"} mx={"auto"}>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} initialValues={{tags: []}} {...rest}>
            {({handleSubmit, form, submitting, pristine = true, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="bulk-update-form">
                  Bulk Update {pcoCount} {pluralize("Potential Change Order", pcoCount)}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  {/* <FormActions.DeleteButton onClick={onDelete} disabled={isReadOnly} /> */}
                  <FormActions.SaveButton disabled={submitting || pristine || isReadOnly} label={"Bulk Update"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </Box>
    </>
  );
};

export const PCOItemDialogForm = React.forwardRef(
  (
    props: {
      projectId: number;
      isOpen: boolean;
      isNew?: boolean;
      onSubmit: (values, form) => void;
      handleClose: () => void;
      isReadOnly?: boolean;
      onCreateBudgetCode?: (values?: {}) => void;
      afterPrevNext?: () => void;
      beforePrevNext?: (values) => Promise<void>;
      [rest: string]: any;
    },
    ref
  ) => {
    const {
      projectId,
      onSubmit,
      onCreateBudgetCode,
      isOpen,
      handleClose,
      isNew = false,
      isReadOnly = false,
      pco,
      pcoItems,
      setActivePCOItem,
      activePCOItem,
      initialFocusField,
      setInitialFocusField,
      beforePrevNext,
      afterPrevNext,

      ...rest
    } = props;

    const formRef = useFormFocusFieldRef(initialFocusField, [props.initialValues.id, props.initialFocusField]);

    const onFocusChange = (props) => {
      const {active} = props;
      if (setInitialFocusField && active) setInitialFocusField(active);
    };

    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values, hasValidationErrors}) => {
              const immutableFields = values.immutable_fields ?? [];
              const immutableFieldsReasons = values.immutable_fields_reasons ?? [];
              return (
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={formRef}>
                  <DialogTitle id="form-dialog-title">
                    <Grid container justifyContent="space-between">
                      <Grid item>{isNew ? "Create PCO Item" : "Update PCO Item"}</Grid>
                      {!isNew && (
                        <Grid item>
                          <PrevNext
                            items={pcoItems}
                            setActiveItem={setActivePCOItem}
                            activeItem={activePCOItem}
                            displayProp="number_display"
                            // beforePrevNext={() => {
                            //   return !pristine ? beforePrevNext(values, form) : undefined;
                            // }}
                            beforePrevNext={!pristine && beforePrevNext ? () => beforePrevNext(values) : undefined}
                            afterPrevNext={!pristine && afterPrevNext ? () => afterPrevNext() : undefined}
                            disabled={hasValidationErrors && !pristine}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    {/* <DialogContentText>DialogContentText</DialogContentText> */}
                    <BeforeUnload block={!pristine} />
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ProjectBudgetAutoselectMui
                            projectId={projectId}
                            name="budget"
                            label="Budget"
                            disabled={isReadOnly}
                            autoFocus={isNew}
                            required
                            onSelect={() => {
                              form.change("description", values?.budget?.display);
                            }}
                            // helperText={
                            //   onCreateBudgetCode ? (
                            //     <>
                            //       <Box style={{cursor: "pointer"}} onClick={() => onCreateBudgetCode(values)}>
                            //         <FontAwesomeIcon icon={faPlus} fixedWidth />
                            //         Create New Budget Code
                            //       </Box>
                            //     </>
                            //   ) : (
                            //     ""
                            //   )
                            // }
                          />
                          {onCreateBudgetCode && (
                            <FormHelperText
                              style={{cursor: "pointer"}}
                              onClick={() => onCreateBudgetCode(values)}
                              component="span"
                            >
                              <FontAwesomeIcon icon={faPlus} fixedWidth />
                              Create New Budget Code
                            </FormHelperText>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui
                            name="description"
                            label="Description"
                            // disabled={isReadOnly}
                            required={isNew}
                            autoFocus={!isNew}
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <CurrencyFieldMui name="budget_rom" label="ROM" disabled={isReadOnly} required />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <CurrencyFieldMui
                            name="budget_proposed_amount"
                            label="Proposed"
                            disabled={isReadOnly}
                            required
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <CurrencyFieldMui
                            name="budget_approved_amount"
                            label="Approved"
                            // disabled={isReadOnly || Boolean(pco?.pcco?.is_closed)}
                            disabled={isReadOnly || immutableFields.includes("budget_approved_amount")}
                            required
                            helperText={
                              immutableFieldsReasons.includes("has_closed_oco") ? (
                                <>
                                  This PCO is linked to closed{" "}
                                  <MuiNavLink to={pco?.pcco?.url}>OCO #{pco.pcco?.number_display}</MuiNavLink>. This
                                  amount cannot be changed.
                                </>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Grid>
                        <Grid item sm={4} xs={12}>
                          <CurrencyFieldMui
                            name="budget_cost"
                            label="Cost"
                            disabled={isReadOnly || immutableFields.includes("budget_cost")}
                            required
                            helperText={
                              immutableFieldsReasons.includes("has_closed_sco") ? (
                                <>
                                  This PCO item is linked to{" "}
                                  <MuiNavLink to={values?.scco?.url}>SCO #{values?.scco?.number_display}</MuiNavLink>.
                                  This amount cannot be changed.
                                </>
                              ) : (
                                ""
                              )
                            }
                          />
                        </Grid>
                        <Grid item sm={8} xs={12}>
                          <TextFieldMui
                            name="subcontractor_ref_number"
                            label="Sub Ref Number"
                            // disabled={isReadOnly}
                          />
                        </Grid>
                      </Grid>
                    </FinalFormKeyboardShortcuts>
                    {!isNew && (
                      <ProjectAuditLink projectId={projectId} app="pcos" model="pcoitem" id={values.id} mt={1} />
                    )}
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton disabled={submitting || pristine} label="Save And Close" />
                    {isNew && (
                      <FormActions.SaveAndAddButton
                        disabled={pristine || isReadOnly}
                        onClick={() => form.change("submitMode", "addAnother")}
                      />
                    )}
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                  {/* <FormSpy onChange={onFocusChange} subscription={{active: true}} /> */}
                  <ActiveFieldsFormSpy onChange={onFocusChange} />
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const QuickCreatePCOItemForm = React.forwardRef(
  (
    props: {
      projectId: number;
      onSubmit: (values: any, form: any) => void;
      isReadOnly?: boolean;
      [rest: string]: any;
    },
    ref: React.RefObject<HTMLFormElement>
  ) => {
    const {projectId, onSubmit, isReadOnly, style = {flexGrow: 1}, ...rest} = props;
    return (
      <>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <>
                <BeforeUnload block={!pristine} />
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref} style={style}>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item sm={6} xs={12}>
                        <ProjectBudgetAutoselectMui
                          projectId={projectId}
                          name="budget"
                          label="Budget"
                          disabled={isReadOnly}
                          autoFocus
                          required
                          onSelect={() => {
                            form.change("description", values?.budget?.display);
                          }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <TextFieldMui name="description" label="Description" required />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <CurrencyFieldMui name="budget_rom" label="ROM" required />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <CurrencyFieldMui name="budget_proposed_amount" label="Proposed" required />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <CurrencyFieldMui name="budget_approved_amount" label="Approved" required />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <CurrencyFieldMui name="budget_cost" label="Cost" required />
                      </Grid>
                      <Grid item xs={12}>
                        <Box display="flex" alignItems="center">
                          <Box mr={1} flexGrow={1}>
                            <FormHelperText>
                              Items will be temporarily added to the top of the list for visibility. Refresh the items
                              to see the final order.
                            </FormHelperText>
                          </Box>
                          <Box>
                            <FormActions.CreateButton />
                          </Box>
                        </Box>
                      </Grid>
                    </Grid>
                    {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
                  </FinalFormKeyboardShortcuts>
                </form>
              </>
            );
          }}
        </FinalForm>
      </>
    );
  }
);

// allocation: "allowance"
// budget_code: "86753"
// description: "Jenny"
// is_compound_markup: true
// is_excluded_from_markup: false
// is_markup: false
// original_budget: "0.00"
// Output

export const AddBudgetCodeDialog = (props: {
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  projectId: number;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, isReadOnly, handleClose, projectId, ...rest} = props;

  const validateBudgetCode = async (value, values, field) => {
    if (!values.budget_code) return "Required";
    if (String(value).endsWith("000")) {
      return "Cannot end with 000. These are reserved for divisions.";
    }
    if (!String(value).match(/\b\d{5}\b/g)) {
      return "Must be exactly 5 digits.";
    }
    if (values.allocation) {
      const response = await axiosAPI.get(
        `/projects/${projectId}/budgets/validate-code/?budget_code=${values?.budget_code || ""}&allocation=${
          values?.allocation?.value || ""
        }`
      );
      if (response.data.error) {
        return response.data.error;
      }
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine = true, values, invalid}) => (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate={true}>
              <DialogTitle id="form-dialog-title">
                <FontAwesomeIcon icon={faPlus} fixedWidth /> Add Budget Code
              </DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CSICodeAutocompleteMui
                        name="budget_code"
                        label="Budget Code"
                        onChange={(e, selection) => form.change("description", selection?.description)}
                        required
                        fieldProps={{
                          validate: validateBudgetCode,
                          validateFields: ["budget_code", "allocation"],
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextFieldMui name="description" label="Description" required />
                    </Grid>

                    <Grid item xs={12}>
                      <ApiAutoselectMui
                        baseURL="/budgets/allocations/"
                        name="allocation"
                        label="Allocation"
                        minCharacters={0}
                        valueProp="value"
                        labelProp="label"
                        required
                        fieldProps={{
                          validateFields: ["budget_code", "allocation"],
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CurrencyFieldMui name="original_budget" label="Amount" disabled />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkboxes name="is_excluded_from_markup" data={{label: "Exclude From Markup", value: false}} />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkboxes name="is_markup" data={{label: "Markup", value: false}} />
                    </Grid>
                    {values.is_markup && (
                      <>
                        <Grid item xs={12}>
                          <Checkboxes
                            name="is_compound_markup"
                            data={{label: "Compound Markup", value: false}}
                            helperText="Should this markup be applied after other markups in a compound manner"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <PercentFieldMui name="markup_rate" label="Markup Rate" decimalScale={3} />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton label="Save" disabled={pristine || isReadOnly || invalid} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const PCOItemUploadDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, isReadOnly, handleClose, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine = true, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                <FontAwesomeIcon icon={faUpload} fixedWidth /> Upload PCO Items
              </DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  Please, use{" "}
                  <Link underline={"always"} href="/static/files/templates/pcoitems.xlsx">
                    this template file
                  </Link>{" "}
                  to avoid validation errors.
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FileFieldMui name="pco_item_upload" />
                <FormActions.SaveButton label="Upload" disabled={pristine || isReadOnly} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const MarkupSelectDialog = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isNew?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, handleClose, isNew = false, isReadOnly = false, ...rest} = props;

  const {query: markupQuery} = useSentinelListAPI(`/projects/${projectId}/budgets/?is_markup=true`, {
    initialData: {
      results: [],
    },
  });

  const markups = markupQuery.data.results;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xs" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Choose Markups To Add</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Checkboxes
                    name="markups"
                    data={markups.map((markup) => ({
                      label: (
                        <>
                          {markup.display} (
                          <NumberFormat value={markup.markup_rate} displayType={"text"} suffix={"%"} decimalScale={3} />
                          ){" "}
                          {markup.is_compound_markup && (
                            <Tooltip title="Compound Markup">
                              <FontAwesomeIcon icon={faSortAmountDown} />
                            </Tooltip>
                          )}
                        </>
                      ),
                      value: markup.id,
                    }))}
                  />
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                {isNew && (
                  <FormActions.SaveAndEditButton
                    disabled={submitting || pristine}
                    onClick={() => form.change("editAfterSubmit", true)}
                  />
                )}
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
