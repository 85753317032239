import {
  faClipboardListCheck,
  faElevator,
  faFireFlameCurved,
  faForklift,
  faListDots,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Grid, Typography} from "@material-ui/core";
import pluralize from "pluralize";
import useBlockUI from "../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";
import {StatusLabel} from "../../components/Status";
import {range} from "lodash";
import Typography2 from "../../components/Typography2";
import InnerFooter from "../../components/InnerFooter";

export const MissingFormsSpeedBump = (props: {
  missingForms: {
    forkliftInspection: boolean;
    hotWork: boolean;
    scissorBoomLift: boolean;
    hotWorkNeeded: number;
    missingFormCount: number;
  };
  pretaskPlanLink: string;
  permitLink: string;
  isSpanish?: boolean;
  hotWorkPermits: any;
}) => {
  const {missingForms, pretaskPlanLink, isSpanish, hotWorkPermits, permitLink, ...rest} = props;

  // const missingFormCount = Object.values(missingForms).filter((val) => val).length;
  const {forkliftInspection, hotWork, scissorBoomLift, missingFormCount, hotWorkNeeded} = missingForms;
  const postCurrentPage = usePostCurrentPage();
  const blockUi = useBlockUI();

  const getOrCreateForm = (
    form_type: "forklift_inspection" | "scissor_boom_lift_inspection" | "hot_work",
    forceCreate?: boolean
  ) => {
    blockUi.blockUI("Generating Form...");
    postCurrentPage
      .mutateAsync({action: "create_form", form_type: form_type, force_create: forceCreate})
      .then((response) => {
        window.location.href = response.redirect;
      });
  };

  const completedOrApprovedHotWorkCount = hotWorkPermits.filter((permit) =>
    ["complete", "approved"].includes(permit.status)
  ).length;

  return (
    <>
      <Box textAlign="center" mb={3}>
        <Typography variant="h2">
          {isSpanish ? (
            <>
              {/* Estás faltando {missingFormCount < 1 ? "un inspección" : `${missingFormCount} inspecciónes`} o{" "}
              {pluralize("permiso", missingFormCount)} para el trabajo listado en tu Plan de Tareas Previas. Por favor
              completa lo siguiente: */}
              Estás faltando inspecciónes o permisos para el trabajo listado en tu Plan de Tareas Previas. Por favor
              completa lo siguiente:
            </>
          ) : (
            <>
              {/* You are missing {missingFormCount > 1 ? missingFormCount : "an"}{" "}
              {pluralize("inspection", missingFormCount)} or {pluralize("permit", missingFormCount)} for work listed on
              your Pretask Plan. Please complete the following: */}
              You are missing inspections or permits for work listed on your Pretask Plan. Please complete the
              following:
            </>
          )}
        </Typography>
      </Box>

      <Grid container spacing={2}>
        {forkliftInspection && (
          <MissingFormItem
            label={isSpanish ? "Permiso de montacargas" : "Forklift Permit"}
            icon={faForklift}
            onClick={() => {
              getOrCreateForm("forklift_inspection");
            }}
          />
        )}
        {scissorBoomLift && (
          <MissingFormItem
            label={isSpanish ? "Permiso de elevación de tijera/elevación de pluma" : "Scissor Lift/Boom Lift Permit"}
            icon={faElevator}
            onClick={() => {
              getOrCreateForm("scissor_boom_lift_inspection");
            }}
          />
        )}
        {hotWork && (
          <Box width="100%" textAlign="center" mt={2}>
            <Typography variant="h5">
              {isSpanish
                ? `Un Permiso de Trabajo en Caliente Completo Por Área (${completedOrApprovedHotWorkCount}/${
                    hotWorkNeeded + hotWorkPermits.length
                  })`
                : `One Completed Hot Work Permit Per Area (${completedOrApprovedHotWorkCount}/${
                    hotWorkNeeded + hotWorkPermits.length
                  })`}
            </Typography>
          </Box>
        )}
        {hotWork &&
          hotWorkNeeded > 0 &&
          range(hotWorkNeeded).map((i) => (
            <MissingFormItem
              key={i}
              label={isSpanish ? `Permiso de trabajo en caliente (Crear nuevo)` : `Hot Work Permit (Create New)`}
              icon={faFireFlameCurved}
              onClick={() => {
                getOrCreateForm("hot_work", true); //Force Create
              }}
            />
          ))}
        {hotWork &&
          hotWorkPermits.map((permit) => {
            return (
              <Grid item xs={12} key={permit.id}>
                <MissingFormItem
                  label={
                    isSpanish
                      ? `Permiso de Trabajo en Caliente ${
                          permit?.answers?.general?.location ? `(${permit.answers.general.location})` : ``
                        }`
                      : `Hot Work Permit ${
                          permit?.answers?.general?.location ? `(${permit.answers.general.location})` : ``
                        }`
                  }
                  icon={faFireFlameCurved}
                  onClick={() => {
                    window.location.href = permit.url;
                  }}
                />
                <StatusLabel status={permit.status_with_expiration_display} hint="projectSafetyForm" width="100%" />
                {permit?.expiration_date && permit.status === "approved" && (
                  <Box width={1} display="flex" justifyContent="center">
                    <Typography2 type={permit.is_permit_expired ? "error" : "metadata"}>
                      {isSpanish ? `Comienza` : `Start`}: {permit.start_date}
                      {"   "}
                      {isSpanish ? `Vence` : `Expiration`}: {permit.expiration_date}
                    </Typography2>
                  </Box>
                )}
              </Grid>
            );
          })}
        {/* <Grid item xs={12}>
          <NavButton
            label={isSpanish ? `Permisos Recienteso` : `Recent Permits`}
            icon={faClipboardListCheck}
            href={permitLink}
            color="primary"
          />
        </Grid>
        <Grid item xs={12}>
          <NavButton
            label={isSpanish ? `Volver al Plan de Tareas Previo` : `Back to Pretask Plan`}
            icon={faListDots}
            href={`${pretaskPlanLink}#forms`}
            color="primary"
          />
        </Grid> */}
      </Grid>
      <Box mb={15} />
      <InnerFooter hasSidebar={false}>
        <NavButton
          label={isSpanish ? `Permisos Recienteso` : `Recent Permits`}
          icon={faClipboardListCheck}
          href={permitLink}
          color="primary"
        />

        <Box mt={1} />
        <NavButton
          label={isSpanish ? `Volver al Plan de Tareas Previo` : `Back to Pretask Plan`}
          icon={faListDots}
          href={`${pretaskPlanLink}#forms`}
          color="primary"
        />
      </InnerFooter>
    </>
  );
};

const MissingFormItem = (props) => {
  const {label, icon, ...rest} = props;
  return (
    <Grid item xs={12}>
      <Box textAlign="center">
        <Button fullWidth variant="contained" startIcon={<FontAwesomeIcon icon={icon} />} {...rest}>
          {label}
        </Button>
      </Box>
    </Grid>
  );
};

const NavButton = (props) => {
  const {label, icon, ...rest} = props;
  return (
    <Grid item xs={12}>
      <Box textAlign="center">
        <Button fullWidth variant="contained" startIcon={<FontAwesomeIcon icon={icon} />} color="primary" {...rest}>
          {label}
        </Button>
      </Box>
    </Grid>
  );
};
