import {Box, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid, Typography} from "@material-ui/core";
import {FormApi} from "final-form";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {FileFieldMui, TextFieldMui, TinyMCEMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";
import PaperPanel from "../../../components/PaperPanel";

export const SafetyOrientationUpdateForm = (props: {
  onSubmit: (values: any, form: FormApi) => void;
  projectId: number;
  isNew?: boolean;
  isReadOnly?: boolean;
  orientationEnabled: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, isReadOnly, orientationEnabled, hospitalMapImage, urgentCareMapImage, ...rest} = props;

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values, invalid} = props;
          React.useEffect(() => {
            form.reset(values);
          }, [orientationEnabled]);
          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={1}>
                    <Grid item xs={12}>
                      <Typography variant="h4">Site Access</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} container spacing={1}>
                      <Grid item xs={12}>
                        <Typography>Weekdays</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldMui name="weekday_start" label="Start Time" autoFocus required={orientationEnabled} />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldMui name="weekday_end" label="End Time" required={orientationEnabled} />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} container spacing={1}>
                      <Grid item xs={12}>
                        <Typography>Weekends</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldMui name="weekend_start" label="Start Time" />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldMui name="weekend_end" label="End Time" />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h4">Safety Items</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui name="emergency_signal" label="Emergency Signal" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui name="emergency_signal_location" label="Emergency Signal Location" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui
                        name="rtk_area_location"
                        label="Right To Know / HAZCOM Location"
                        required={orientationEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui
                        name="fire_sprinkler_shutoff_location"
                        label="Fire Sprinkler Shutoff Location"
                        required={orientationEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui
                        name="domestic_water_shutoff_location"
                        label="Domestic Water Shutoff Location"
                        required={orientationEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui
                        name="gas_shutoff_location"
                        label="Gas Shutoff Location"
                        required={orientationEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui
                        name="electrical_shutoff_location"
                        label="Electrical Shutoff Location"
                        required={orientationEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui
                        name="sanitary_facilities_location"
                        label="Sanitary Facilities Location"
                        required={orientationEnabled}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <TextFieldMui
                        name="first_aid_location"
                        label="First Aid Cabinet Location"
                        required={orientationEnabled}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}></Grid>

                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12}>
                        <TextFieldMui
                          name="nearest_hospital"
                          label="Nearest Hospital"
                          placeholder="Enter Name or Address of Nearest Hospital"
                          multiline
                          minRows={3}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {hospitalMapImage && (
                          <>
                            <Box textAlign="center">
                              <FormLabel>Hospital Map Preview</FormLabel>
                            </Box>
                            <Box textAlign="center">
                              {/* <Link href={`https://www.google.com/maps/dir/37.3541024,-121.9279888/O'Connor+Hospital/`}></Link> */}
                              <img src={`data:image/jpeg;base64,${hospitalMapImage}`} width="200px" height="200px" />
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6} container>
                      <Grid item xs={12}>
                        <TextFieldMui
                          name="nearest_urgent_care"
                          label="Nearest Urgent Care"
                          placeholder="Enter Name or Address of Nearest Urgent Care"
                          multiline
                          minRows={3}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {urgentCareMapImage && (
                          <>
                            <Box textAlign="center">
                              <FormLabel>Urgent Care Map Preview</FormLabel>
                            </Box>
                            <Box textAlign="center">
                              {/* <Link href={`https://www.google.com/maps/dir/37.3541024,-121.9279888/O'Connor+Hospital/`}></Link> */}
                              <img src={`data:image/jpeg;base64,${urgentCareMapImage}`} width="200px" height="200px" />
                            </Box>
                          </>
                        )}
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h4">Site Specific Information</Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui name="site_specific_information" label="" />
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="h4">Contacts</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextFieldMui name="superintendent" label="Project Superintendent" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextFieldMui name="project_assistant_superintendent" label="Project Assistant Superintendent" />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <TextFieldMui name="project_foreman" label="Project Foreman" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextFieldMui name="project_manager" label="Project Manager" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextFieldMui name="project_engineer" label="Project Engineer" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <TextFieldMui name="safety_director" label="Safety Director" />
                    </Grid>
                  </Grid>
                  <InnerFooter>
                    <Box display="flex" mt={3}>
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
              {/* <Typography variant="h2">Form Values</Typography>
              <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

export const AdditionalSafetyDocumentUploadDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Upload Additional Documents</DialogTitle>
              <DialogContent>
                <PaperPanel.Body>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      Attach PDFs of critical job site communications like a Site Survey.
                    </Grid>
                    <Grid item xs={12}>
                      <FileFieldMui label="File" name="file" accept=".pdf" required />
                    </Grid>
                  </Grid>
                </PaperPanel.Body>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label="Upload" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const UpdateAdditionalDocumentDialogForm = (props: {
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isOpen, handleClose, isNew = false, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">{isNew ? "Create Document" : "Update Document"}</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldMui name="name" label="Name" required autoFocus />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="description" label="Description" multiline minRows={2} />
                        </Grid>
                      </Grid>
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                  {!false && <FormActions.DeleteButton onClick={() => onDelete()} />}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
