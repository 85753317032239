import {faSquare} from "@fortawesome/pro-regular-svg-icons";
import {faCheckSquare, faList} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import moment from "moment";
import pluralize from "pluralize";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {ImportanceLabel} from "../../../components/ImportanceLabel";
import {MuiNavLink} from "../../../components/Links";
import {DaysOverdueLabel} from "../../../components/OverdueLabels";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import {TagList} from "../../../components/Tags";
import Typography2 from "../../../components/Typography2";
import {colorSuccess, colorWarning} from "../../../theme/colors";

export const SubmittalPackagePaperItem = (props) => {
  const {
    submittalPackage,
    isSelected,
    isDragging,
    dragHandleProps,
    onEditSubmittalPackage,
    onChangeSelectedSubmittalPackage,
    onDeleteSubmittalPackage,
    onUpdateSubmittalPackage,
    ...rest
  } = props;

  const itemCount = submittalPackage.item_count;
  const url = `/v2/projects/${submittalPackage.project_id}/submittals/packages/${submittalPackage.id}/`;

  return (
    <>
      <PaperItem isDragging={isDragging} {...rest}>
        <PaperItem.Left minWidth={45}>
          <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangeSelectedSubmittalPackage} />
          {dragHandleProps && (
            <Box display="flex" alignItems="center">
              <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
              <PaperItem.Position position={submittalPackage.position} />
            </Box>
          )}
        </PaperItem.Left>
        <PaperItem.Body>
          <Grid container spacing={0}>
            <Grid item xs={6}>
              <StatusLabel status={submittalPackage.status_display} hint="submittalPackage" />{" "}
              <MuiNavLink href={url} underline="always">
                {submittalPackage.description}
              </MuiNavLink>
            </Grid>

            <Grid item xs={3}>
              <Typography2 type="metadata">
                <Link href={submittalPackage?.author_company?.url} underline="always">
                  {submittalPackage.author_company?.name}
                </Link>
              </Typography2>
            </Grid>

            <Grid item xs={3}>
              <Typography2 type="metadata">
                {submittalPackage?.submitted_date ? `Submitted: ${submittalPackage?.submitted_date}` : "Not Submitted"}
              </Typography2>
            </Grid>

            <Grid item xs={1}>
              <Typography2 type="metadata">
                <MuiNavLink href={`${url}#tab=items`}>
                  <FontAwesomeIcon icon={faList} /> {pluralize(`${itemCount} Item`, itemCount)}
                </MuiNavLink>
              </Typography2>
            </Grid>

            <Grid item xs={2}>
              {!submittalPackage.is_closed && (
                <Box>
                  <DaysOverdueLabel
                    dueDate={submittalPackage.due_date}
                    showUpcomingVisible={moment(submittalPackage.due_date).diff(moment().startOf("day"), "days") <= 14}
                    showUpcomingWarning={
                      submittalPackage.due_date &&
                      moment(submittalPackage.due_date).diff(moment().startOf("day"), "days") <= 7
                    }
                  />
                </Box>
              )}
            </Grid>

            <Grid item xs={2}>
              <Typography2 type="metadata">
                <ImportanceLabel importance={submittalPackage?.importance_display} />
              </Typography2>
            </Grid>

            <Grid item xs={4}>
              {submittalPackage.is_draft ? (
                <Typography2
                  type="metadata"
                  style={{color: colorWarning, cursor: "pointer"}}
                  onClick={() => onUpdateSubmittalPackage({is_draft: false})}
                >
                  <FontAwesomeIcon icon={faCheckSquare} /> Draft
                </Typography2>
              ) : (
                <Typography2
                  type="metadata"
                  style={{color: colorSuccess, cursor: "pointer"}}
                  onClick={() => onUpdateSubmittalPackage({is_draft: true})}
                >
                  <FontAwesomeIcon icon={faSquare} /> Draft
                </Typography2>
              )}
            </Grid>

            <Grid item xs={3}>
              <Tooltip title="Spec Section">
                <Typography2 type="metadata">{submittalPackage?.spec_section_code}</Typography2>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TagList tags={submittalPackage.tags} />
            </Grid>
          </Grid>
        </PaperItem.Body>
        <PaperItem.Right minWidth={70}>
          <Box textAlign="right">
            <Typography2 noWrap type="metadata">
              {submittalPackage.number_display}
            </Typography2>
          </Box>
        </PaperItem.Right>

        <PaperItem.RightHover>
          <PaperItem.RightHover.ItemNumber number={submittalPackage.number_display} />
          <PaperItem.RightHover.IconButton icon={LinkIcon} title="Link" component={MuiNavLink} href={url} />
          <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditSubmittalPackage} />
          <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onDeleteSubmittalPackage} />
          <BoxFilesIconButtonLink
            projectId={submittalPackage.project_id}
            app="submittals"
            model="submittalpackage"
            objectId={submittalPackage.id}
            boxFolderId={submittalPackage.box_folder_id}
          />
          <Box textAlign="right"></Box>
        </PaperItem.RightHover>
      </PaperItem>
    </>
  );
};
