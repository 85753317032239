import {faCheckCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Tab,
} from "@material-ui/core";
import ReplyIcon from "@material-ui/icons/Reply";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Checkboxes, Switches as FinalSwitches} from "mui-rff";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router-dom";
import {useQueryState} from "use-location-state";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import discipline from "../../../components/forms/choices/discipline.json";
import rfiCategory from "../../../components/forms/choices/rfiCategory.json";
import rfiImpacts from "../../../components/forms/choices/rfiImpacts.json";
import rfiStatuses from "../../../components/forms/choices/rfiStatuses.json";
import {
  CurrencyFieldMui,
  DatePickerMui,
  NumberFieldMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  ProjectDrawingAutocompleteMui,
  ProjectTagAutocompleteMui,
  SelectImportance,
  SelectStatus,
  SimpleAutoselectMui,
  SimpleSelect,
  SpecSectionAutocompleteMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";
import PaperPanel from "../../../components/PaperPanel";
import {PrevNext} from "../../../components/PrevNext";
import {StatusLabel} from "../../../components/Status";
import {SuperuserInfo} from "../../../components/SuperuserInfo";
import {colorSuccess} from "../../../theme/colors";

export const RFICategoryAutocomplete = (props) => {
  return <SimpleAutoselectMui options={rfiCategory} name="category" label="Category" {...props} />;
};
export const DisciplineAutocomplete = (props) => {
  return <SimpleAutoselectMui options={discipline} name="discipline" label="Discipline" {...props} />;
};

export const RFIStatusSelect = (props) => {
  return <SelectStatus name="status" label="Status" type="rfi" options={rfiStatuses} {...props} />;
};

export const RFIUpdateForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  fieldSize?: string;
  contactChildren?: React.ReactNode;

  // All other props
  [rest: string]: any;
}) => {
  const [selectedImpactTab, setSelectedImpactTab] = useQueryState("impactTab", "cost");
  const {pathname} = useLocation();
  // useWhyDidYouUpdate("RFIUpdateDialogForm", props);
  const {onSubmit, projectId, contactChildren, ...rest} = props;

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values} = props;
        return (
          <>
            <BeforeUnload block={!pristine} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <PaperPanel.TabToolbar mb={2} px={3} pt={2} pb={1}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <ProjectContactAutoselectMui
                        projectId={projectId}
                        name="response_required_contacts"
                        label="Response Required"
                        multiple
                        getOptionDisabled={
                          (option) => values.cc_contacts.map((value) => value.id).includes(option.id)
                          // values.response_required_contacts.map((value) => value.id).includes(option.id)
                        }
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ProjectContactAutoselectMui
                        projectId={projectId}
                        name="cc_contacts"
                        label="CC"
                        multiple
                        getOptionDisabled={
                          (option) => values.response_required_contacts.map((value) => value.id).includes(option.id)
                          // values.cc_contacts.map((value) => value.id).includes(option.id)
                        }
                      />
                    </Grid>
                  </Grid>
                  {contactChildren}
                </PaperPanel.TabToolbar>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui name="number" label="Number" required />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="subject" label="Subject" required autoFocus />
                      </Grid>
                      <Grid item xs={12}>
                        <RFIStatusSelect name="status" label="Status" />
                      </Grid>
                      <Grid item xs={12}>
                        <SelectImportance name="importance" label="Importance" allowNull />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DisciplineAutocomplete />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <RFICategoryAutocomplete />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePickerMui label="Date Created" name="date_created" required />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePickerMui label="Date Required" name="date_required" />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui projectId={projectId} name="authored_by" label="Authored By" />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui projectId={projectId} name="answered_by" label="Answered By" />
                      </Grid>
                      <Grid item xs={12}>
                        <DatePickerMui label="Date Answered" name="date_answered" />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectCompanyAutoselectMui
                          projectId={projectId}
                          name="responsible_party"
                          label="Responsible Party"
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <SpecSectionAutocompleteMui
                          name="spec_section_code"
                          projectId={projectId}
                          label="Spec Section"
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextFieldMui name="spec_subsection" label="Spec Subsection" />
                      </Grid>
                      <Grid item xs={12}>
                        <FinalSwitches name="is_draft" data={{label: "Draft", value: true}} />
                        <FormHelperText>Drafts will not show up on external site</FormHelperText>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TinyMCEMui name="question" label="Question" height={220} />
                      </Grid>
                      <Grid item xs={12}>
                        <TinyMCEMui name="suggestion" label="Suggestion" height={160} />
                      </Grid>
                      <Grid item xs={12}>
                        <TinyMCEMui name="answer" label="Final Answer" height={220} />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <ProjectDrawingAutocompleteMui projectId={projectId} name="drawings2" label="Drawings" />
                  </Grid>
                </Grid>
                <Box mt={2} mx={-3}>
                  <TabContext value={selectedImpactTab}>
                    <Box mb={2}>
                      <AppBar position="static" color="default" elevation={1}>
                        <TabList
                          value={selectedImpactTab}
                          onChange={(event, newValue) => {
                            setSelectedImpactTab(newValue);
                          }}
                          variant="scrollable"
                          scrollButtons="auto"
                          // indicatorColor="primary"
                          // elevation={0}
                        >
                          <Tab
                            label={
                              <Box display="flex" justifyContent="center" alignItems="center">
                                {values.cost_impact === "yes" && (
                                  <FontAwesomeIcon icon={faCheckCircle} color={colorSuccess} />
                                )}
                                Cost Impact
                              </Box>
                            }
                            value="cost"
                          />
                          <Tab
                            label={
                              <Box display="flex" justifyContent="center" alignItems="center">
                                {values.schedule_impact === "yes" && (
                                  <FontAwesomeIcon icon={faCheckCircle} color={colorSuccess} />
                                )}
                                Schedule Impact
                              </Box>
                            }
                            value="schedule"
                          />
                          <Tab
                            label={
                              <Box display="flex" justifyContent="center" alignItems="center">
                                {values.drawing_impact === "yes" && (
                                  <FontAwesomeIcon icon={faCheckCircle} color={colorSuccess} />
                                )}
                                Drawing Impact
                              </Box>
                            }
                            value="drawing"
                          />
                        </TabList>
                      </AppBar>
                    </Box>
                    <TabPanel value="cost">
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <SimpleSelect name="cost_impact" label="Cost Impact" options={rfiImpacts} />
                        </Grid>
                        <Grid item xs={4}>
                          <CurrencyFieldMui name="cost_impact_amount" label="Cost Impact Amount" required />
                          <FormHelperText>0 displayed as TBD</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                          <TinyMCEMui name="cost_impact_notes" label="Cost Impact Notes" />
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="schedule">
                      <Grid container spacing={2}>
                        <Grid item xs={4}>
                          <SimpleSelect name="schedule_impact" label="Schedule Impact" options={rfiImpacts} />
                        </Grid>
                        <Grid item xs={4}>
                          <NumberFieldMui name="schedule_impact_days" label="Cost Impact Days" required />
                          <FormHelperText>0 displayed as TBD</FormHelperText>
                        </Grid>
                        <Grid item xs={12}>
                          <TinyMCEMui name="schedule_impact_notes" label="Schedule Impact Notes" />
                        </Grid>
                      </Grid>
                    </TabPanel>
                    <TabPanel value="drawing">
                      <SimpleSelect name="drawing_impact" label="Drawing Impact" options={rfiImpacts} />
                      <TinyMCEMui name="drawing_impact_notes" label="Drawing Impact Notes" />
                    </TabPanel>
                  </TabContext>
                </Box>

                <InnerFooter>
                  <Box display="flex">
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    <Box ml={1} />
                    <FormActions.DeleteButton
                      href={`/v2/projects/${projectId}/delete/rfis/rfi/${values.id}/?next=/v2/projects/${projectId}/rfis/&cancel=${pathname}`}
                      // href={`/v2/projects/${projectId}/delete/rfis/rfi/${values.id}/?cancel=${pathname}`}
                    />
                  </Box>
                </InnerFooter>
                {/* <FormActions>
                        <FormActions.Left>
                          <FormActions.SaveButton disabled={submitting || pristine} />
                        </FormActions.Left>
                      </FormActions> */}
                {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const RFIUpdateDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  fieldSize?: string;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, handleClose, isNew = false, rfis, setActiveRFI, activeRFI, ...rest} = props;

  const validateNumber = async (value, values, field) => {
    if (!values.number) return "Required";
    if (!isNew) return;
    if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
    const response = await axiosAPI.get(`/projects/${projectId}/rfis/validate-number/?number=${values?.number || ""}`);
    if (response.data.error) {
      return response.data.error;
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                <Grid container justifyContent="space-between">
                  <Grid item>
                    {!isNew && <StatusLabel status={values.status_display} />} {isNew ? "Create RFI" : "Update RFI"}
                  </Grid>
                  {!isNew && (
                    <Grid item>
                      <PrevNext
                        items={rfis}
                        setActiveItem={setActiveRFI}
                        activeItem={activeRFI}
                        displayProp="number_display"
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldMui
                        name="number"
                        label="Number"
                        required
                        fieldProps={{
                          validate: validateNumber,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="subject" label="Subject" required autoFocus />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <RFIStatusSelect name="status" label="Status" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <SelectImportance name="importance" label="Importance" allowNull />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerMui label="Date Created" name="date_created" required />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerMui label="Date Required" name="date_required" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <ProjectContactAutoselectMui projectId={projectId} name="authored_by" label="Authored By" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <ProjectContactAutoselectMui projectId={projectId} name="answered_by" label="Answered By" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerMui label="Date Answered" name="date_answered" />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <ProjectCompanyAutoselectMui
                        projectId={projectId}
                        name="responsible_party"
                        label="Responsible Party"
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FinalSwitches name="is_draft" data={{label: "Draft", value: true}} />
                      <FormHelperText>Drafts will not show up on external site</FormHelperText>
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui name="question" label="Question" height={180} />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
                <SuperuserInfo
                  objects={[
                    {
                      contentType: "rfis",
                      model: "rfi",
                      id: values.id,
                      title: values.display,
                    },
                  ]}
                />
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                {isNew && (
                  <FormActions.SaveAndEditButton
                    disabled={submitting || pristine}
                    onClick={() => form.change("editAfterSubmit", true)}
                  />
                )}
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const RFIBulkUpdateDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  fieldSize?: string;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, handleClose, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} initialValues={{tags: []}} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Bulk Update RFIs</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <RFIStatusSelect name="status" label="Status" />
                    </Grid>
                    <Grid item xs={12}>
                      <SelectImportance name="importance_id" label="Importance" allowNull />
                    </Grid>
                    <Grid item xs={12}>
                      {/* <FinalSwitches name="clear_importance" data={{label: "Clear Importance", value: true}} /> */}
                      <Checkboxes
                        // label="Check at least one..."
                        name="clear_importance"
                        data={{label: "Clear Importance", value: true}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectCompanyAutoselectMui
                        projectId={projectId}
                        name="responsible_party"
                        label="Responsible Party"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectContactAutoselectMui projectId={projectId} name="cc_contact" label="Add Contact To CC" />
                    </Grid>
                    <Grid item xs={12}>
                      <Checkboxes
                        name="clear_responsible_party"
                        data={{label: "Clear Responsible Party", value: true}}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <Checkboxes name="is_draft" data={{label: "Is Draft", value: true}} />
                    </Grid>
                    <Grid item xs={6}>
                      <Checkboxes name="not_draft" data={{label: "Not Draft", value: true}} />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label="Bulk Update" startIcon={null} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const RFIResponseCreateForm = (props: {
  onSubmit: (values: any, form?: any) => void;
  projectId: number;
  // All other props
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values} = props;
        return (
          <>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item sm={8} xs={12}>
                    <TextFieldMui name="response" label="Response" required multiline rows={4} />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <ProjectContactAutoselectMui projectId={projectId} name="author" label="Author" required />
                  </Grid>
                  <Grid item xs={12}>
                    <Button startIcon={<ReplyIcon />} variant="contained" color="primary" type="submit">
                      Create Response
                    </Button>
                  </Grid>
                </Grid>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const RFIResponseUpdateDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  projectId: number;
  // All other props
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, isOpen, handleClose, ...rest} = props;
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values} = props;
          return (
            <>
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Update Response</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item sm={12}>
                        <ProjectContactAutoselectMui projectId={projectId} name="author" label="Author" required />
                      </Grid>
                      <Grid item sm={12}>
                        <TextFieldMui name="response" label="Response" required multiline rows={4} />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>

                  <SuperuserInfo
                    objects={[
                      {
                        contentType: "rfis",
                        model: "rfiresponse",
                        id: values.id,
                        title: values.display,
                      },
                    ]}
                  />
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            </>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

export const RFICustomDataForm = (props: {
  onSubmit: (values: any) => void;
  fieldSize?: string;
  // All other props
  [rest: string]: any;
}) => {
  const [selectedImpactTab, setSelectedImpactTab] = useQueryState("impactTab", "cost");
  // useWhyDidYouUpdate("RFIUpdateDialogForm", props);
  const {onSubmit, projectId, contactChildren, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values} = props;
        return (
          <>
            <BeforeUnload block={!pristine} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui
                      name="custom_data.sharepoint_link"
                      label="Sharepoint link"
                      helperText={`SharePoint link for BioMarin Projects`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui
                      name="custom_data.fusion_live_link"
                      label="FusionLive link"
                      helperText={`FusionLive link`}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <FormActions.SaveButton disabled={submitting || pristine} />
                  </Grid>
                </Grid>

                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};
