import {Box, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router-dom";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {Currency} from "../../../components/Accounting";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import csiCodesNovo from "../../../components/forms/choices/csiCodesNovo.json";
import subcontractTypes from "../../../components/forms/choices/subcontractTypes.json";
import {
  CSVTagsMui,
  CurrencyFieldMui,
  DatePickerMui,
  ProjectBudgetAutoselectMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  SimpleSelect,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";
import PaperPanel from "../../../components/PaperPanel";
import {PrevNext} from "../../../components/PrevNext";
import useFormFocusFieldRef from "../../../hooks/useFormFocusFieldRef";

const LetterOfIntentCommonFields = (props: {
  projectId: number;
  values: any;
  isNew?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, values, isNew, isReadOnly, ...rest} = props;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextFieldMui name="number" label="Number" required autoFocus disabled={isReadOnly} />
        </Grid>
        <Grid item xs={6}>
          <CurrencyFieldMui name="not_to_exceed_value" label="Not To Exceed" required disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProjectCompanyAutoselectMui
                projectId={projectId}
                name="from_company"
                label="From Company"
                required
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <ProjectContactAutoselectMui
                projectId={projectId}
                name="from_contact"
                label="From Contact"
                required
                disabled={isReadOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ProjectCompanyAutoselectMui
                projectId={projectId}
                name="to_company"
                label="To Company"
                required
                disabled={isReadOnly}
              />
            </Grid>
            <Grid item xs={12}>
              <ProjectContactAutoselectMui
                projectId={projectId}
                name="to_contact"
                label="To Contact"
                required
                disabled={isReadOnly}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerMui label="Issued Date" name="issued_date" required disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerMui label="Required Date" name="required_date" disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerMui
            label="Executed Date"
            name="executed_date"
            disabled={isReadOnly}
            helperText={`Date the LOI was signed`}
          />
        </Grid>
        <Grid item xs={12}>
          <CSVTagsMui
            name="csi_codes"
            label="CSI Code(s)"
            disabled={isReadOnly}
            options={csiCodesNovo.map((option) => option.label)}
          />
        </Grid>
        <Grid item xs={12}>
          <TinyMCEMui name="scope_of_work" label="Scope Of Work" disabled={isReadOnly} />
        </Grid>
      </Grid>
    </>
  );
};

export const LetterOfIntentUpdateForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  isNew?: boolean;
  isReadOnly?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {pathname} = useLocation();
  // useWhyDidYouUpdate("RFIUpdateDialogForm", props);
  const {onSubmit, projectId, isNew, isReadOnly, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values} = props;
        return (
          <>
            <BeforeUnload block={!pristine} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <LetterOfIntentCommonFields
                  values={values}
                  isReadOnly={isReadOnly}
                  isNew={isNew}
                  projectId={projectId}
                />
                <InnerFooter>
                  <Box display="flex">
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    <Box ml={1} />
                    <FormActions.DeleteButton
                      href={`/v2/projects/${projectId}/delete/contracts/letterofintent/${values.id}/?next=/v2/projects/${projectId}/letters-of-intent/&cancel=${pathname}`}
                      disabled={isReadOnly}
                    />
                  </Box>
                </InnerFooter>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const LetterOfIntentDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  setActiveLetterOfIntent?: (letterOfIntent: any) => void;
  lettersOfIntent?: [];
  activeLetterOfIntent?: {id?: number};
  [rest: string]: any;
}) => {
  const {
    projectId,
    onSubmit,
    onDelete,
    isOpen,
    handleClose,
    isNew = false,
    isReadOnly = false,
    lettersOfIntent,
    activeLetterOfIntent,
    setActiveLetterOfIntent,
    initialFocusField,
    setInitialFocusField,
    beforePrevNext,
    afterPrevNext,
    ...rest
  } = props;

  const formRef = useFormFocusFieldRef(initialFocusField, [props.initialValues.id, props.initialFocusField]);

  const onFocusChange = (props) => {
    const {active} = props;
    if (setInitialFocusField && active) setInitialFocusField(active);
  };
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values, hasValidationErrors}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={formRef}>
              <ActiveFieldsFormSpy onChange={onFocusChange} />
              <DialogTitle id="form-dialog-title">
                <Grid container justifyContent="space-between">
                  <Grid item>{isNew ? "Create Letter Of Intent" : "Update Letter Of Intent"}</Grid>
                  {!isNew && (
                    <Grid item>
                      <PrevNext
                        displayProp="number_display"
                        setActiveItem={setActiveLetterOfIntent}
                        items={lettersOfIntent}
                        activeItem={activeLetterOfIntent}
                        beforePrevNext={!pristine && beforePrevNext ? () => beforePrevNext(values) : undefined}
                        afterPrevNext={!pristine && afterPrevNext ? () => afterPrevNext() : undefined}
                        disabled={hasValidationErrors && !pristine}
                      />
                    </Grid>
                  )}
                </Grid>
              </DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <LetterOfIntentCommonFields
                      values={values}
                      isReadOnly={isReadOnly}
                      isNew={isNew}
                      projectId={projectId}
                    />

                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton
                  disabled={submitting || pristine}
                  // label={isNew ? "Create" : "Save"}
                  onClick={() => form.change("submitMode", "saveAndClose")}
                />
                {isNew && (
                  <FormActions.SaveAndEditButton
                    disabled={submitting || pristine}
                    onClick={() => form.change("submitMode", "editAfterSubmit")}
                  />
                )}
                {!isNew && <FormActions.DeleteButton onClick={() => onDelete()} disabled={isReadOnly} />}
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const CreateSubcontractForLetterOfIntentDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, handleClose, isNew = false, isReadOnly = false, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">{isNew ? "Create Subcontract" : "Update Subcontract"}</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui name="description" label="Description" required autoFocus disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ProjectBudgetAutoselectMui
                        projectId={projectId}
                        name="budget"
                        label="Budget"
                        required
                        disabled={isReadOnly}
                        disableClearable={false}
                      />
                      {values?.budget && (
                        <FormHelperText>
                          <Currency number={values?.budget?.available_budget} precision={2} /> left in{" "}
                          {values?.budget?.display}
                        </FormHelperText>
                      )}
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <SimpleSelect
                        name="type"
                        label="Type"
                        options={subcontractTypes}
                        required
                        disabled={isReadOnly}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerMui label="Issued Date" name="issued_date" required disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerMui label="Required Date" name="required_date" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <CurrencyFieldMui name="value" label="Value" disabled={isReadOnly} />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label="Create" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
