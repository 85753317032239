import {
  CraneLiftEvaluation,
  ForkliftInspection,
  CranePretaskPlan,
  ScissorBoomLiftOperation,
  HotWorkPermit,
  ExcavationChecklist,
} from "../pages/WellnessCheckExternalQuestionnaire/JobsiteForms";

type FormType =
  | "forklift_inspection"
  | "crane_lift_evaluation"
  | "scissor_boom_lift_inspection"
  | "crane_pretask_plan"
  | "excavation_checklist"
  | "hot_work";

const getSafetyFormComponentFromType = (form_type: FormType) => {
  switch (form_type) {
    case "forklift_inspection":
      return ForkliftInspection;
    case "crane_lift_evaluation":
      return CraneLiftEvaluation;
    case "scissor_boom_lift_inspection":
      return ScissorBoomLiftOperation;
    case "crane_pretask_plan":
      return CranePretaskPlan;
    case "hot_work":
      return HotWorkPermit;
    case "excavation_checklist":
      return ExcavationChecklist;
    default:
      throw new Error(`Invalid form_type: ${form_type}`);
  }
};

export default getSafetyFormComponentFromType;
