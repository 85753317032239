import {faCircle1, faCircle2, faCircle3, faCircle4} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip, useMediaQuery} from "@material-ui/core";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Stepper from "@material-ui/core/Stepper";
import {useTheme} from "@material-ui/core/styles";
import {colorSuccess, colorWarning} from "../../../theme/colors";

const SubmittalItemScheduleStepper = (props) => {
  const {submittalItem, stepLabelSize = "2x", ...rest} = props;

  const steps = [
    {label: "Requested From Sub", value: submittalItem.requested_date, icon: faCircle1},
    {label: "Received From Sub", value: submittalItem.received_date, icon: faCircle2},
    {label: "Returned To Sub", value: submittalItem.returned_date, icon: faCircle3},
    {label: "Delivered", value: submittalItem.delivered_date, icon: faCircle4},
  ];

  const getActiveStep = () => {
    return steps.findIndex((step) => step.value === null);
  };

  const theme = useTheme();
  const minStepperSize = useMediaQuery(theme.breakpoints.up("sm"));

  return (
    <>
      <Stepper
        activeStep={getActiveStep()}
        orientation={!minStepperSize ? "vertical" : "horizontal"}
        style={{padding: 0}}
      >
        {steps.map((step, index) => {
          return (
            <Step key={index} completed={step.value}>
              <Tooltip title={step?.value ? "Complete" : "Incomplete"}>
                <StepLabel
                  icon={
                    step.value ? (
                      <FontAwesomeIcon
                        icon={step.icon}
                        color={colorSuccess}
                        size={!minStepperSize ? "lg" : stepLabelSize}
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={step.icon}
                        color={colorWarning}
                        size={!minStepperSize ? "lg" : stepLabelSize}
                      />
                    )
                  }
                >
                  {step.label}
                </StepLabel>
              </Tooltip>
            </Step>
          );
        })}
      </Stepper>
    </>
  );
};
export default SubmittalItemScheduleStepper;
