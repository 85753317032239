import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout";

const UnauthenticatedTest = (props) => {
  return (
    <UnauthenticatedLayout>
      <h1>Unauthenticated Test</h1>
    </UnauthenticatedLayout>
  );
};

export default UnauthenticatedTest;
