import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, makeStyles} from "@material-ui/core";

import classNames from "classnames";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useNavigate} from "react-router-dom";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import {CurrencyFieldMui, TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import useBlockUI from "../../../hooks/useBlockUI";

export const NameChangeOrderForm = (props) => {
  const {onSubmit, isOpen, handleClose, counts, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Create Internal Change Order</DialogTitle>
              <DialogContent>
                {/* <BeforeUnload block={!pristine} /> */}
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldMui name="description" label="Description" autoFocus required />
                    </Grid>
                    <Grid item xs={12}>
                      <CurrencyFieldMui name="amount" label="Amount" required />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting} label="Create" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

const colorHover = "#fffaf0";
const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    choice: {
      paddingBottom: `3px`,
      "&:hover": {
        backgroundColor: colorHover,
        boxShadow: `rgb(0 0 0 / 10%) 0px 2px 1px -1px, rgb(0 0 0 / 7%) 0px 1px 1px 0px, rgb(0 0 0 / 6%) 0px 1px 3px 0px;`,
      },
    },
  };
});

export const TypeSelectDialog = (props: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  subcontract: {id: number; budget?: {budget_code: string}; to_company?: {name: string}; description?: string};
  project: {id: number};
}) => {
  const {isOpen, setIsOpen, subcontract, project} = props;
  const classes = useStyles();
  const choiceClasses = classes.choice;
  const [nameChangeOrderDialogIsOpen, setNameChangeOrderDialogIsOpen] = React.useState(false);
  const blockUI = useBlockUI();
  const navigate = useNavigate();

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          setIsOpen(false);
        }}
        maxWidth="sm"
      >
        <DialogTitle>Select Change Order Type</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Which type of change order would you like to create for{" "}
              <strong>{`${subcontract?.budget?.budget_code ?? ""} ${subcontract?.to_company
                ?.name} - ${subcontract?.description}`}</strong>
              ?
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box textAlign="center" className={classNames(choiceClasses)}>
                Create a new single item Internal Change Order and link to this Subcontract Change Order.
                <Box textAlign="center">
                  <Button variant="contained" onClick={() => setNameChangeOrderDialogIsOpen(true)}>
                    INTERNAL
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box textAlign="center" className={classNames(choiceClasses)}>
                Link this Subcontract Change Order to an existing Change Order Request or Internal Change Order.
                <Box textAlign="center">
                  <Button
                    variant="contained"
                    onClick={() =>
                      navigate(`/v2/projects/${project.id}/change-order-wizard/sco/${subcontract.id}/create/`)
                    }
                  >
                    EXISTING
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
      <NameChangeOrderForm
        isOpen={nameChangeOrderDialogIsOpen}
        handleClose={() => setNameChangeOrderDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          axiosAPI
            .post(`/projects/${project.id}/contracts/sub/${subcontract.id}/sccos/`, {
              subcontract_id: subcontract.id,
              ...values,
            })
            .then((response) => {
              window.location.href = response.data.url;
            });
        }}
      />
    </>
  );
};
