import {
  faCircleExclamation,
  faCircleQuestion,
  faGavel,
  faMagicWandSparkles,
  faSync,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {LegacyUILink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";

const ChangeOrderWizard = (props) => {
  const {project, ...rest} = props;

  const prompts = [
    {
      text: "Potential Change Order",
      description: "Create a Potential Change Order for use with a future Change Order Request",
      link: `/v2/projects/${project.id}/change-order-wizard/pco/`,
      icon: faCircleExclamation,
    },

    {
      text: "Subcontract Change Order",
      description: "Issue a Change Order Against an Existing Subcontract",
      link: `/v2/projects/${project.id}/change-order-wizard/sco/select-subcontract/`,
      icon: faGavel,
    },
    {
      text: "Change Order Request",
      description: "Convert One Or More Potential Change Orders to a Change Order Request",
      link: `/v2/projects/${project.id}/change-order-wizard/cor/`,
      icon: faCircleQuestion,
    },

    {
      text: "Owner Change Order",
      description:
        "Link Approved Change Order Requests to a Prime Contract, then Finalize Change Orders for Owner Approval",
      link: `/v2/projects/${project.id}/change-order-wizard/pcco/select-contract/`,
      disableReactRouter: true,
      icon: faUsers,
    },

    {
      text: "COR or Internal PCO",
      description:
        "Create a Net $0 Change Order. Moving money between budget items internally will create an Internal PCO. Moving money between budget items formally with Owner approval will create a Change Order Request against an existing Prime Contract.",
      link: `/v2/projects/${project.id}/change-order-wizard/net-zero/`,
      icon: faSync,
    },
  ];

  return (
    <>
      <Helmet title={`Change Order Wizard`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Change Order Wizard</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Title>
          <FontAwesomeIcon icon={faMagicWandSparkles} /> Change Order Wizard - {project?.display}
        </PageHeader.Title>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Body>
          <WizardTable prompts={prompts} history={props.history} />
        </PaperPanel.Body>
      </PaperPanel>
      <Box mt={2}>
        <LegacyUILink href={`/projects/${project.id}/co/create/legacy/`} />
      </Box>
      {/* <h2>Page Data Query</h2>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default ChangeOrderWizard;

const WizardTable = (props) => {
  const {prompts, history, ...rest} = props;
  const navigate = useNavigate();
  return (
    <>
      <PaperPanel.Header>
        <PaperPanel.Header.Title>I want to create....</PaperPanel.Header.Title>
      </PaperPanel.Header>
      <PaperPanel.Body>
        {prompts.map((prompt) => {
          return (
            <PaperItem
              key={prompt.text}
              style={{cursor: "pointer"}}
              onClick={() => {
                if (prompt.disableReactRouter) {
                  window.location.href = prompt.link;
                } else {
                  navigate(prompt.link);
                }
              }}
              alignItems="center"
              {...rest}
            >
              <PaperItem.Left minWidth={40}>
                <FontAwesomeIcon icon={prompt.icon} fixedWidth size="xl" />
              </PaperItem.Left>
              <PaperItem.Body>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <strong>{prompt.text}</strong>
                  </Grid>

                  <Grid item xs={12}>
                    {prompt?.description}
                  </Grid>
                </Grid>
              </PaperItem.Body>
            </PaperItem>
          );
        })}
      </PaperPanel.Body>
    </>
  );
};
