import {Box, Chip, IconButton, Tooltip} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import React from "react";

export const FilterOptionChips = (props: {
  filterOptions: {
    [key: string]: {
      value: string | number;
      label: string | number;
    };
  };
  onDelete: (key) => void;
  onDeleteAll: () => void;
  [rest: string]: any;
}) => {
  const {filterOptions, onDelete, onDeleteAll, ...rest} = props;
  let hasFilters = false;
  Object.entries(filterOptions).map(([key, value]) => {
    if (value.value !== "") {
      hasFilters = true;
    }
  });
  return (
    <Box {...rest}>
      {Object.entries(filterOptions).map(([key, value]) => {
        if (value.value === "") return null;
        return (
          <React.Fragment key={key}>
            <Chip size="small" variant="outlined" label={`${key}: ${value.label}`} onDelete={() => onDelete(key)} />{" "}
          </React.Fragment>
        );
      })}
      {hasFilters && (
        <Tooltip title="Clear All Filters">
          <IconButton size="small" aria-label="clear" component="span" onClick={onDeleteAll}>
            <HighlightOffIcon />
          </IconButton>
        </Tooltip>
      )}
    </Box>
  );
};
