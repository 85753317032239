import {Box, Breadcrumbs, Link, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";

import {faFileExcel, faFilePdf} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classnames from "classnames";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {StatusLabel} from "../../../components/Status";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";
import BlockUI from "../../../components/GlobalLoaders";

const JobStart = (props) => {
  const {...rest} = props;

  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      jobsToCreate: [],
      recentlyCreatedJobs: [],
    },
  });
  const pageData = pageDataQuery.data;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Data..." />;
  }

  return (
    <>
      <Helmet title={`Job Start`} />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        <Typography color="textSecondary">Accounting Tools</Typography>
        <Typography color="textPrimary">Job Start</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Jobs To Be Created</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                onClick={() => {
                  saveExcel(
                    pageData.jobsToCreate.map((job) => {
                      return {
                        "Suggested #": job.suggestedJobNumber,
                        Name: job.name,
                        Office: job.office,
                        Submitted: {v: job.dateSubmitted, t: "d", z: "yyyy-mm-dd"},
                        "Submitted By": job.submittedBy,
                      };
                    }),
                    "Jobs To Be Created",
                    [{wch: 15}, {wch: 55}, {wch: 12}, {wch: 10}, {wch: 15}]
                  );
                }}
                disabled={pageDataQuery.isFetching || pageData.jobsToCreate.length < 1}
              >
                Get Excel
              </PaperPanel.Header.Button>
              <Box mr={-1} ml={1}>
                <PaperPanel.Header.RefreshButton
                  isFetching={pageDataQuery.isFetching}
                  onClick={() => pageDataQuery.refetch()}
                />
              </Box>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            hover
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["original_value"]}
            columns={[
              {Header: "Suggested Job #", accessor: "suggestedJobNumber"},
              {
                Header: "Name",
                accessor: "name",
                Cell: ({row, value}) => (
                  <Link href={row.original.url} underline="always" target="_blank">
                    {value}
                  </Link>
                ),
              },

              {Header: "Office", accessor: "office"},
              {Header: "Submitted", accessor: "dateSubmitted"},
              {Header: "Submitted By", accessor: "submittedBy"},
              {
                Header: "",
                id: "report",
                accessor: "reportURL",
                Cell: ({row, value}) => (
                  <Link href={value}>
                    <FontAwesomeIcon icon={faFilePdf} size="xs" fixedWidth />
                    Get PDF
                  </Link>
                ),
              },
            ]}
            //   initialState={{
            //     sortBy: [
            //       {
            //         id: "submitted_date",
            //         asc: true,
            //       },
            //     ],
            //   }}
            data={pageData.jobsToCreate}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Jobs Recently Created</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                onClick={() => {
                  saveExcel(
                    pageData.recentlyCreatedJobs.map((job) => {
                      return {
                        Status: job.status,
                        "Job #": job.jobNo,
                        Name: job.name,
                        "TL Name": job.tlName,
                        Office: job.office,
                        Submitted: {v: job.dateSubmitted, t: "d", z: "yyyy-mm-dd"},
                        "Submitted By": job.submittedBy,
                      };
                    }),
                    "Recently Created Jobs",
                    [{wch: 15}, {wch: 10}, {wch: 50}, {wch: 30}, {wch: 20}, {wch: 10}, {wch: 20}]
                  );
                }}
                disabled={pageDataQuery.isFetching || pageData.recentlyCreatedJobs.length < 1}
              >
                Get Excel
              </PaperPanel.Header.Button>
              <Box mr={-1} ml={1}>
                <PaperPanel.Header.RefreshButton
                  isFetching={pageDataQuery.isFetching}
                  onClick={() => pageDataQuery.refetch()}
                />
              </Box>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            hover
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["original_value"]}
            columns={[
              {
                Header: "Status",
                accessor: "status",
                Cell: ({row, value}) => <StatusLabel status={value} />,
              },
              {Header: "Job #", accessor: "jobNo"},
              {
                Header: "Name",
                accessor: "name",
                Cell: ({row, value}) => (
                  <Link href={row.original.url} underline="always" target="_blank">
                    {value}
                  </Link>
                ),
              },
              {Header: "TL Name", accessor: "tlName"},
              {Header: "Office", accessor: "office"},
              {Header: "Submitted", accessor: "dateSubmitted"},
              {Header: "Submitted By", accessor: "submittedBy"},
              {
                Header: "",
                id: "report",
                accessor: "reportURL",
                Cell: ({row, value}) => (
                  <Link href={value}>
                    <FontAwesomeIcon icon={faFilePdf} size="xs" fixedWidth />
                    Get PDF
                  </Link>
                ),
              },
            ]}
            //   initialState={{
            //     sortBy: [
            //       {
            //         id: "submitted_date",
            //         asc: true,
            //       },
            //     ],
            //   }}
            data={pageData.recentlyCreatedJobs}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <Box mt={2}>
        <LegacyUILink href="/accounting-tools/job-start/" />
      </Box>
      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default JobStart;
