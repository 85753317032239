import ExternalBoxUpload from "../pages/external/pages/projects/Box/ExternalBoxUpload";
import ExternalProjectDetail from "../pages/external/pages/projects/ExternalProjectDetail";
import {ExternalRFIDetail} from "../pages/external/pages/projects/RFIs";
import {ExternalSubmittalPackageDetail} from "../pages/external/pages/projects/Submittals";

const PATH_PREFIX = "/v2/external/";

const externalRoutes = [
  {path: `${PATH_PREFIX}:contactUUID/projects/:projectUUID/`, component: ExternalProjectDetail},
  {path: `${PATH_PREFIX}:contactUUID/projects/:projectUUID/rfis/:rfiUUID/`, component: ExternalRFIDetail},
  {
    path: `${PATH_PREFIX}:contactUUID/projects/:projectUUID/submittals/packages/:submittalPackageUUID/`,
    component: ExternalSubmittalPackageDetail,
  },
  {
    path: `${PATH_PREFIX}:contactUUID/projects/:projectUUID/:app/:model/:uuid/box-upload/`,
    component: ExternalBoxUpload,
  },
];

export default externalRoutes;
