import {Box, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid} from "@material-ui/core";
import accounting from "accounting";
import {Checkboxes} from "mui-rff";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router-dom";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import purchaseOrderCategories from "../../../components/forms/choices/purchaseOrderCategories.json";
import {
  CurrencyFieldMui,
  DatePickerMui,
  NovoRestrictedSignersAutoselectMui,
  NumberFieldMui,
  PercentFieldMui,
  ProjectBudgetAutoselectMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  SimpleAutoselectMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";
import usePermissions from "../../../hooks/usePermissions";
import getCompanySignator from "../../../utils/getCompanySignator";

const PurchaseOrderCommonFields = (props) => {
  const {
    projectId,
    isReadOnly,
    values,
    isNew,
    restrictNovoSigners,
    isPurchaseOrderAmendment,
    onChangeToCompany,
    onChangeFromCompany,
    validateNumber,
    ...rest
  } = props;
  const permissions = usePermissions();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        {/* <TextFieldMui type="number" name="number" label="Number" required disabled={isReadOnly} /> */}
        <NumberFieldMui
          name="number"
          label="Number"
          required
          disabled={isReadOnly}
          fieldProps={validateNumber && {validate: validateNumber}}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldMui
          type="text"
          name="description"
          label="Description"
          inputProps={{
            maxLength: 512,
          }}
          required
          autoFocus
          disabled={isReadOnly}
        />
      </Grid>
      {isPurchaseOrderAmendment ? (
        <>
          <Grid item xs={12} sm={6}>
            <PercentFieldMui name="tax_rate" label="Tax Rate" decimalScale={4} disabled={isReadOnly} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextFieldMui type="text" name="terms" label="Terms" disabled={isReadOnly} />
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12} sm={6}>
            <ProjectBudgetAutoselectMui
              projectId={projectId}
              name="budget"
              label="Budget"
              required
              disabled={isReadOnly}
              disableClearable={false}
            />
            {values?.budget && isNew && (
              <FormHelperText>
                <Currency number={values?.budget?.available_budget} precision={2} /> left in {values?.budget?.display}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <PercentFieldMui name="tax_rate" label="Tax Rate" decimalScale={4} disabled={isReadOnly} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextFieldMui type="text" name="terms" label="Terms" disabled={isReadOnly} />
          </Grid>
        </>
      )}
      <Grid item xs={12} sm={3}>
        <SimpleAutoselectMui name="category" label="Category" options={purchaseOrderCategories} disabled={isReadOnly} />
      </Grid>
      <Grid item xs={12} sm={3}>
        <DatePickerMui
          label="Issued Date"
          name="issued_date"
          helperText={`Date the PO was issued`}
          required
          disabled={isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <DatePickerMui
          label="Scheduled Date"
          name="scheduled_date"
          helperText={`Date the PO is scheduled`}
          disabled={isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <DatePickerMui label="Due Date" name="due_date" helperText={`Date the PO is Due`} disabled={isReadOnly} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectCompanyAutoselectMui
          projectId={projectId}
          name="from_company"
          label="From Company"
          required
          disabled={isReadOnly}
          onChange={(e, selection, reason) => {
            if (onChangeFromCompany && reason === "select-option") {
              onChangeFromCompany(selection);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectCompanyAutoselectMui
          projectId={projectId}
          name="to_company"
          label="To Company"
          required
          disabled={isReadOnly}
          onChange={(e, selection, reason) => {
            if (onChangeToCompany && reason === "select-option") {
              onChangeToCompany(selection);
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {restrictNovoSigners ? (
          <NovoRestrictedSignersAutoselectMui
            projectId={projectId}
            name="from_contact"
            label="From Contact"
            disabled={isReadOnly}
          />
        ) : (
          <ProjectContactAutoselectMui
            projectId={projectId}
            name="from_contact"
            label="From Contact"
            disabled={isReadOnly}
          />
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectContactAutoselectMui projectId={projectId} name="to_contact" label="To Contact" disabled={isReadOnly} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextFieldMui
              type="text"
              name="vendor_po_number"
              label="Vendor PO Number"
              inputProps={{
                maxLength: 32,
              }}
              disabled={isReadOnly}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldMui type="text" name="sent_via" label="Sent Via" disabled={isReadOnly} />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextFieldMui
          type="text"
          name="ship_to_address"
          label="Ship To Address"
          multiline
          minRows={4}
          disabled={isReadOnly}
        />
      </Grid>
      {isNew && (
        <Grid item sm={6} xs={12}>
          <CurrencyFieldMui
            name="value"
            label="Value"
            disabled={isReadOnly || !isNew}
            helperText="Optional - Will create a single PO item with this value"
          />
        </Grid>
      )}
      {permissions.link_subcontracts && !isNew && !isPurchaseOrderAmendment && (
        <Grid item xs={12}>
          <TextFieldMui
            name="timberline_subcontract_id_override"
            label="Timberline Subcontract Override"
            helperText={`For accounting use only: Override Timberline subcontract ${
              values?.timberline_subcontract_id ? ` current id: ${values?.timberline_subcontract_id}` : ""
            }`}
          />
        </Grid>
      )}
      <Grid item xs={12}>
        <TinyMCEMui name="comments" label="Comments" disabled={isReadOnly} />
      </Grid>
    </Grid>
  );
};

export const PurchaseOrderUpdateDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isNew?: boolean;
  restrictNovoSigners?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {
    projectId,
    onSubmit,
    isOpen,
    handleClose,
    isNew = false,
    isReadOnly = false,
    restrictNovoSigners = false,
    ...rest
  } = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            const onChangeToCompany = async (selection) => {
              const signator = await getCompanySignator(selection.id);
              form.batch(() => {
                if (signator) {
                  form.change("to_contact", signator);
                } else {
                  form.change("to_contact", undefined);
                }
              });
            };

            const onChangeFromCompany = async (selection) => {
              const signator = await getCompanySignator(selection.id);
              form.batch(() => {
                if (signator) {
                  form.change("from_contact", signator);
                } else {
                  form.change("from_contact", undefined);
                }
              });
            };
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  {isNew ? "Create Purchase Order" : "Update Purchase Order"}
                </DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  {/* <BeforeUnload block={!pristine} /> */}
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PurchaseOrderCommonFields
                      projectId={projectId}
                      isReadOnly={isReadOnly}
                      values={values}
                      isNew={isNew}
                      restrictNovoSigners={restrictNovoSigners}
                      onChangeToCompany={onChangeToCompany}
                      onChangeFromCompany={onChangeFromCompany}
                    />
                  </FinalFormKeyboardShortcuts>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  {isNew && (
                    <FormActions.SaveAndEditButton
                      disabled={submitting || pristine}
                      onClick={() => form.change("editAfterSubmit", true)}
                    />
                  )}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const PurchaseOrderGeneralInfoForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  isNew?: boolean;
  isReadOnly?: boolean;
  restrictNovoSigners?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, isReadOnly, isNew, restrictNovoSigners = false, ...rest} = props;
  const {pathname} = useLocation();

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values} = props;
          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PurchaseOrderCommonFields
                    projectId={projectId}
                    values={values}
                    isNew={isNew}
                    restrictNovoSigners={restrictNovoSigners}
                    isReadOnly={isReadOnly}
                  />
                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                      <FormActions.DeleteButton
                        href={`/v2/projects/${projectId}/delete/pos/purchaseorder/${values.id}/?next=/v2/projects/${projectId}/pos/&cancel=${pathname}`}
                        disabled={isReadOnly}
                      />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

export const PurchaseOrderItemDialog = React.forwardRef(
  (
    props: {
      isOpen: boolean;
      isNew?: boolean;
      onSubmit: (values, form) => void;
      handleClose: () => void;
      [rest: string]: any;
    },
    ref
  ) => {
    const {onSubmit, handleClose, isOpen, isNew, isReadOnly, ...rest} = props;

    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => {
              const [subtotal, setSubtotal] = React.useState(values.subtotal);
              const [taxes, setTaxes] = React.useState(values.taxes);
              React.useEffect(() => {
                let subtotal = values.quantity * values.unit_price;
                let taxes = 0;
                if (values.is_taxable) {
                  const tax_multiplier = values.tax_rate / 100;
                  taxes = tax_multiplier * subtotal;
                }
                subtotal += taxes;
                setSubtotal(subtotal);
                setTaxes(taxes);
              }, [values]);
              return (
                <>
                  <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                    <DialogTitle id="form-dialog-title">
                      <Grid container justifyContent="space-between">
                        <Grid item>{isNew ? "Create" : "Update"} Item</Grid>
                      </Grid>
                    </DialogTitle>
                    <DialogContent>
                      <BeforeUnload block={!pristine} />
                      <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextFieldMui
                              name="description"
                              label="Description"
                              required
                              autoFocus
                              disabled={isReadOnly}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CurrencyFieldMui name="unit_price" label="Unit Price" required disabled={isReadOnly} />
                          </Grid>
                          <Grid item xs={4}>
                            <NumberFieldMui
                              name="quantity"
                              label="Quantity"
                              decimalScale={2}
                              required
                              disabled={isReadOnly}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <TextFieldMui name="unit_of_measure" label="Unit of Measure" disabled={isReadOnly} />
                          </Grid>
                          <Grid item xs={8}>
                            <Checkboxes
                              name="is_taxable"
                              data={{label: "Taxable", value: values.is_taxable}}
                              disabled={isReadOnly}
                            />
                          </Grid>
                          <Grid item xs={4} container>
                            <Grid item xs={6}>
                              Subtotal:
                            </Grid>
                            <Grid item xs={6}>
                              <Box textAlign="right">
                                <Currency number={subtotal - taxes} />
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              Taxes
                              <small>
                                {values.tax_rate &&
                                  values.is_taxable &&
                                  ` ${accounting.formatNumber(values.tax_rate, 4)}%`}
                              </small>
                              :
                            </Grid>
                            <Grid item xs={6}>
                              <Box textAlign="right">
                                <Currency number={taxes} />
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <strong>Total:</strong>
                            </Grid>
                            <Grid item xs={6}>
                              <Box textAlign="right">
                                <strong>
                                  <Currency number={subtotal} />
                                </strong>
                              </Box>
                            </Grid>
                          </Grid>
                        </Grid>
                      </FinalFormKeyboardShortcuts>
                    </DialogContent>
                    <DialogActions>
                      <FormActions.SaveButton disabled={submitting || pristine || isReadOnly} label={"Save"} />
                      {isNew && (
                        <FormActions.SaveAndAddButton
                          disabled={pristine || isReadOnly}
                          onClick={() => form.change("submitMode", "addAnother")}
                        />
                      )}
                      <FormActions.CancelButton onClick={handleClose} />
                    </DialogActions>
                  </form>
                </>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const PurchaseOrderAmendmentDialogForm = (props: {
  isOpen: boolean;
  isNew?: boolean;
  restrictNovoSigners?: boolean;
  onSubmit: (values) => void;
  handleClose: () => void;
  projectId: number;
  purchaseOrderId: number;
  [rest: string]: any;
}) => {
  const {
    onSubmit,
    handleClose,
    isOpen,
    isNew,
    isReadOnly,
    projectId,
    restrictNovoSigners = false,
    purchaseOrderId,
    ...rest
  } = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            const validateNumber = async (value, values, field) => {
              if (!values.number) return "Required";
              if (values.number < 1) return "Invalid";
              if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
              const response = await axiosAPI.get(
                `/projects/${projectId}/pos/${purchaseOrderId}/amendments/validate-number/?number=${values?.number}${
                  !isNew ? `&id=${values.id}` : ``
                }`
              );
              if (response.data.error) {
                return response.data.error;
              }
            };
            return (
              <>
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <DialogTitle id="form-dialog-title">
                    <Grid container justifyContent="space-between">
                      <Grid item>{isNew ? "Create" : "Update"} Amendment</Grid>
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <BeforeUnload block={!pristine} />
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <PurchaseOrderCommonFields
                        isReadOnly={isReadOnly}
                        isNew={isNew}
                        projectId={projectId}
                        restrictNovoSigners={restrictNovoSigners}
                        isPurchaseOrderAmendment
                        validateNumber={validateNumber}
                      />
                    </FinalFormKeyboardShortcuts>
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton
                      disabled={submitting || (pristine && !isNew) || isReadOnly}
                      label={"Save"}
                    />
                    {isNew && (
                      <FormActions.SaveAndEditButton
                        disabled={submitting || pristine || isReadOnly}
                        label={"Save and Edit"}
                        onClick={() => form.change("editAfterSubmit", true)}
                      />
                    )}
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                </form>
              </>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const PurchaseOrderAmendmentDetailForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  isNew?: boolean;
  restrictNovoSigners?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, isNew, isReadOnly, restrictNovoSigners = false, ...rest} = props;
  const {pathname} = useLocation();

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values} = props;
          const validateNumber = async (value, values, field) => {
            if (!values.number) return "Required";
            if (values.number < 1) return "Invalid";
            if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
            const response = await axiosAPI.get(
              `/projects/${projectId}/pos/${values.purchase_order_id}/amendments/validate-number/?number=${
                values?.number
              }${!isNew ? `&id=${values.id}` : ``}`
            );
            if (response.data.error) {
              return response.data.error;
            }
          };
          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PurchaseOrderCommonFields
                    projectId={projectId}
                    restrictNovoSigners={restrictNovoSigners}
                    isNew={isNew}
                    isReadOnly={isReadOnly}
                    isPurchaseOrderAmendment
                    validateNumber={validateNumber}
                    {...rest}
                  />
                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                      <FormActions.DeleteButton
                        href={`/v2/projects/${projectId}/delete/pos/purchaseorderamendment/${values.id}/?next=${values.purchase_order_url}&cancel=${pathname}`}
                        disabled={isReadOnly}
                      />
                    </Box>
                  </InnerFooter>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};
