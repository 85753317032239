import * as Sentry from "@sentry/browser";
import {useLocation} from "react-router-dom";
import {PageHeader} from "./PageHeader";

const RouteNotFound = (props) => {
  const location = useLocation();
  Sentry.captureException(`Route Not Found ${location.pathname}`);
  return (
    <>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Page Not Found</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <p>Please use the Feedback option in the bottom right of this page to let us know how you got here.</p>
      {/* <p>
        <span>
          <Link href="mailto:skuehn@novoconstruction.com">Sam</Link> and{" "}
          <Link href="mailto:cstoner@novoconstruction.com">Colin</Link> have been notified.
        </span>
      </p>
      <p>In the meantime have you tried refreshing the page?</p> */}
      {/* <pre>{JSON.stringify(location, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};
export default RouteNotFound;
