import {faBell} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumbs, Checkbox, Link, makeStyles, Tooltip, Typography} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import DraftsIcon from "@material-ui/icons/Drafts";
import EmailIcon from "@material-ui/icons/Email";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useSelectIds} from "../../../js/hooks";
import {ConfirmationDialog} from "../../components/Dialogs";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui, ReactTableMuiCheckboxSelector} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import useUser from "../../hooks/useUser";
import AuthenticatedLayout from "../../layouts/AuthenticatedLayout";
import {makeNovoClasses} from "../../theme";

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    table: {
      "& .MuiTableCell-body, .MuiTableCell-head": {
        fontSize: "11px",
      },
      "& td, th": {
        padding: "2px 8px 2px 8px",
      },
      "& a:hover": {
        cursor: "pointer",
      },
      "& tr.read": {
        backgroundColor: grey[200],
      },
      // "& tbody tr:hover": {
      //   backgroundColor: grey[200],
      // },
    },
  };
});

const Notifications = (props) => {
  const user = useUser();
  const novoClasses = makeNovoClasses();
  const classes = useStyles();
  const [allNotificationIds, setAllNotificationIds] = React.useState();
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const blockUI = useBlockUI();
  const {query: notificationQuery, rpc: notificationRPC} = useSentinelListAPI(["users", user.id, "notifications"], {
    initialData: {},
    // refetchOnWindowFocus: true,
  });

  const {
    selectedIds: selectedNotificationIds,
    addSelectedId: addSelectedNotificationId,
    removeSelectedId: removeSelectedNotificationId,
    addAllSelectedIds: addAllNotificationIds,
    removeAllSelectedIds: removeAllNotificationIds,
    allIdsSelected: allNotificationsSelected,
  } = useSelectIds(allNotificationIds);

  const notifications = notificationQuery.data.results || [];

  React.useEffect(() => {
    setAllNotificationIds(notifications.map((notification) => notification.id));
  }, [notificationQuery?.data?.count]);

  return (
    <AuthenticatedLayout>
      <Helmet title={`${user.full_name} - Notifications`} />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Notifications</Typography>
      </Breadcrumbs>

      <PaperPanel>
        <PaperPanel.Header isLoading={notificationQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faBell} /> Notifications
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                disabled={selectedNotificationIds.size === 0}
                onClick={() => {
                  notificationRPC.mutateAsync({action: "mark-read", data: Array.from(selectedNotificationIds)});
                }}
                startIcon={<DraftsIcon />}
              >
                Mark As Read
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                disabled={selectedNotificationIds.size === 0}
                onClick={() => {
                  notificationRPC.mutateAsync({action: "mark-unread", data: Array.from(selectedNotificationIds)});
                }}
                startIcon={<EmailIcon />}
              >
                Mark As Unread
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.DeleteButton
                disabled={selectedNotificationIds.size === 0}
                onClick={() => {
                  setDeleteConfirmationIsOpen(true);
                }}
              >
                Delete
              </PaperPanel.Header.DeleteButton>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            // size="small"
            className={classnames(novoClasses.boldHeaderTable, classes.table)}
            hover
            getRowProps={(row) => {
              return {
                // hover: true,
                className: classnames({unread: row.original.unread, read: !row.original.unread}),
                selected: selectedNotificationIds.has(row.original.id),
                // onClick: (row) => {
                //   console.log("click row", row);
                // },
              };
            }}
            columns={[
              {
                accessor: "id",
                disableSortBy: true,
                Header: () => (
                  <Checkbox
                    // checked={checked}
                    size="small"
                    style={{width: "20px", padding: 0}}
                    indeterminate={!allNotificationsSelected && selectedNotificationIds.size !== 0}
                    checked={allNotificationsSelected}
                    onChange={(event, checked) => {
                      if (checked) {
                        addAllNotificationIds();
                      } else {
                        removeAllNotificationIds();
                      }
                    }}
                  />
                ),

                Cell: ({value, row}) => (
                  <ReactTableMuiCheckboxSelector
                    id={row.original.id}
                    selected={selectedNotificationIds.has(row.original.id)}
                    onAddSelected={addSelectedNotificationId}
                    onRemoveSelected={removeSelectedNotificationId}
                    onClick={(event) => event.preventDefault()}
                  />
                ),
              },
              {
                Header: "Actor",
                accessor: "actor",
              },
              {
                Header: "Message",
                accessor: "description",
                Cell: ({value, row}) => (
                  <Link
                    onClick={() => {
                      blockUI.blockUI();
                      notificationRPC
                        .mutateAsync({action: "mark-read", data: Array.from([row.original.id])})
                        .then(() => {
                          window.location = row.original.link;
                        });
                    }}
                  >
                    {row.original.verb} {value}
                  </Link>
                ),
              },
              {
                Header: "Target",
                accessor: "target",
              },
              {
                Header: "Created",
                accessor: "timestamp",
                Cell: ({value, row}) => (
                  <>
                    <Tooltip title={moment(value).format("LLLL")} placement="bottom">
                      <span>{moment(value).calendar()}</span>
                    </Tooltip>
                  </>
                ),
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "timestamp",
                  desc: true,
                },
              ],
            }}
            data={notifications}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          notificationRPC
            .mutateAsync({
              action: "delete",
              method: "DELETE",
              data: {ids: Array.from(selectedNotificationIds)},
            })
            .then(() => removeAllNotificationIds());
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete the selected {selectedNotificationIds.size} notifications.
      </ConfirmationDialog>
      {/*
      <pre>{JSON.stringify(notificationQuery, null, 2)}</pre>
      <pre>{JSON.stringify(Array.from(selectedNotificationIds), null, 2)}</pre>
      <h1>Props</h1>
      <pre>{JSON.stringify(props, null, 2)}</pre>
       */}
    </AuthenticatedLayout>
  );
};

export default Notifications;
