import Dropzone from "react-dropzone-uploader";
import useBlockUI from "../../hooks/useBlockUI";
import "./dropzone.scss";

const DropzoneMui = (props) => {
  const blockUI = useBlockUI();
  const {onChangeStatus, ...rest} = props;
  return (
    <Dropzone
      onChangeStatus={(event) => {
        console.log("dz onChangeStatus", event.meta.status);
        if (["uploading"].includes(event.meta.status)) {
          blockUI.blockUI("Uploading...");
        }
        if (["error_upload", "done"].includes(event.meta.status)) {
          blockUI.unblockUI();
        }
        if (onChangeStatus) {
          onChangeStatus(event);
        }
      }}
      {...rest}
    />
  );
};

export default DropzoneMui;
