import {faCheck, faXmark} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Grid, Tooltip, Typography} from "@material-ui/core";
import {groupBy, set} from "lodash";
import moment from "moment";
import {useSnackbar} from "notistack";
import pluralize from "pluralize";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {UploadFileButton} from "../../../components/Buttons";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import InnerFooter from "../../../components/InnerFooter";
import {MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import safetyReviewQuestionStatusChoices from "../../../components/forms/choices/safetyReviewQuestionStatusChoices.json";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import usePermissions from "../../../hooks/usePermissions";
import {
  useFetchCurrentPage,
  usePostCurrentPage,
  useSentinelDetailAPI,
  useSentinelListAPI,
} from "../../../hooks/useSentinelAPI";
import {SectionHeader} from "../ProjectReviews/ProjectReviewForms";
import {Thumbnail, ThumbnailDetailDialog} from "./SafetyReviewComponents";
import {AllSafetyReviewForms} from "./SafetyReviewForms";
import {getStatusColor} from "../../../theme/colors";

const MOMENT_FORMAT = "YYYY-MM-DD h:mm a";

const onChangeSectionErrors = (
  state: Set<unknown>,
  action: {type: "addSection" | "removeSection"; data: string | number}
): Set<unknown> => {
  const result = new Set(state);

  switch (action.type) {
    case "addSection": {
      result.add(action.data);
      return result;
    }
    case "removeSection": {
      result.delete(action.data);
      return result;
    }
  }
  return state;
};

const ProjectSafetyReviewDetail = (props) => {
  const {project, user, ...rest} = props;
  const {safetyReviewId} = useParams();
  const [sectionErrors, setSectionErrors] = React.useReducer(onChangeSectionErrors, new Set());
  const [markCompleteConfirmationIsOpen, setMarkCompleteConfirmationIsOpen] = React.useState(false);
  const [markPendingConfirmationIsOpen, setMarkPendingConfirmationIsOpen] = React.useState(false);
  const [deleteReviewConfirmationIsOpen, setDeleteReviewConfirmationIsOpen] = React.useState(false);
  const [updateFromCheckinsConfirmationIsOpen, setUpdateFromCheckinsConfirmationIsOpen] = React.useState(false);
  const [markUnansweredYesConfirmationIsOpen, setMarkUnansweredYesConfirmationIsOpen] = React.useState(false);
  const [markUnansweredNAConfirmationIsOpen, setMarkUnansweredNAConfirmationIsOpen] = React.useState(false);
  const [finalizeDialogIsOpen, setFinalizeDialogIsOpen] = React.useState(false);
  const [finalSubmitDialogIsOpen, setFinalSubmitDialogIsOpen] = React.useState(false);
  const [offerToFinalizeDialogIsOpen, setOfferToFinalizeDialogIsOpen] = React.useState(false);
  const [activeAttachment, setActiveAttachment] = React.useState({} as any);
  const [thumbnailDetailIsOpen, setThumbnailDetailIsOpen] = React.useState(false);
  const [deleteAttachmentConfirmationIsOpen, setDeleteAttachmentConfirmationIsOpen] = React.useState(false);

  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      superintendentUser: {},
    },
  });

  const permissions = usePermissions();

  const [questionFilterOptions, setQuestionFilterOption, clearQuestionFilterOption, clearAllQuestionFilterOptions] =
    useFilterOptions(["Search", "Status"]);
  const questionFilterParams = {
    q: questionFilterOptions.Search.value,
    status: questionFilterOptions.Status.value,
  };

  const {
    query: safetyReviewQuery,
    update: safetyReviewUpdate,
    delete: deleteSafetyReview,
  } = useSentinelDetailAPI(`projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/`, {
    initialData: {
      status_counts: {},
    },
  });

  const {query: questionQuery, update: questionUpdate} = useSentinelListAPI(
    `projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/questions/?${qs.stringify(
      questionFilterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const {
    query: attachmentQuery,
    delete: deleteAttachment,
    update: updateAttachment,
  } = useSentinelListAPI(`projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/attachments/`, {
    initialData: {results: []},
    enabled: true,
  });

  const attachments = attachmentQuery.data.results;

  const {query: thumbnailQuery} = useSentinelListAPI(
    `projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/attachments/thumbnails/`,
    {
      initialData: [],
      enabled: true,
    }
  );

  const refreshAttachments = () => Promise.all([attachmentQuery.refetch(), thumbnailQuery.refetch()]);
  const thumbnails = thumbnailQuery.data;

  const postCurrentPage = usePostCurrentPage();
  const navigate = useNavigate();

  const pageData = pageDataQuery.data;
  const safetyReview = safetyReviewQuery.data;

  const questions = questionQuery.data.results;

  const allQueriesFinished =
    safetyReviewQuery.isFetchedAfterMount &&
    pageDataQuery.isFetchedAfterMount &&
    questionQuery.isFetchedAfterMount &&
    thumbnailQuery.isFetchedAfterMount &&
    attachmentQuery.isFetchedAfterMount;

  const groupedQuestions = groupBy(questions, "category");

  const attachmentsWithThumbnails = groupBy(
    attachments.map((att) => ({...att, thumbnail: thumbnails.find((thumbnail) => thumbnail.id === att.file)})),
    "safety_review_question"
  );
  const generalAttachments = attachmentsWithThumbnails["null"] || [];

  // if (!allQueriesFinished) {
  //   return <BlockUI show={true} message="Fetching Project Safety Review info..." />;
  // }

  return (
    <>
      <Helmet title={`Project Safety Review - ${moment(safetyReview.review_datetime).format(MOMENT_FORMAT)}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/safety/project-safety-reviews/`}>
          <Typography color="textSecondary">Project Safety Reviews</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">{moment(safetyReview.review_datetime).format(MOMENT_FORMAT)}</Typography>
      </ProjectBreadcrumbs>
      <Box mt={1} />
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>
            Project Safety Review - {moment(safetyReview.review_datetime).format(MOMENT_FORMAT)}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          <Grid container spacing={0}>
            <Grid item xs={4}>
              <PageHeader.Right.RightSpark
                title="Needs Attention"
                value={safetyReview?.status_counts?.noncompliant_needs_attention}
                color={getStatusColor("noncompliant - needs attention")}
                style={{cursor: "pointer"}}
                onClick={() =>
                  setQuestionFilterOption("Status", "noncompliant_needs_attention", "Noncompliant - Needs Attention")
                }
              />
            </Grid>
            <Grid item xs={4}>
              <PageHeader.Right.RightSpark
                title="Compliant"
                value={safetyReview?.status_counts?.compliant}
                color={getStatusColor("compliant")}
                style={{cursor: "pointer"}}
                onClick={() => setQuestionFilterOption("Status", "compliant", "Compliant")}
              />
            </Grid>

            <Grid item xs={4}>
              <PageHeader.Right.RightSpark
                title="Corrected On Site"
                value={safetyReview?.status_counts?.noncompliant_corrected_on_site}
                color={getStatusColor("noncompliant - corrected on site")}
                style={{cursor: "pointer"}}
                onClick={() =>
                  setQuestionFilterOption(
                    "Status",
                    "noncompliant_corrected_on_site",
                    "Noncompliant - Corrected On Site"
                  )
                }
              />
            </Grid>
            <Grid item xs={4}>
              <PageHeader.Right.RightSpark
                title="Corrected"
                value={safetyReview?.status_counts?.noncompliant_corrected}
                color={getStatusColor("noncompliant - corrected")}
                style={{cursor: "pointer"}}
                onClick={() => setQuestionFilterOption("Status", "noncompliant_corrected", "Noncompliant - Corrected")}
              />
            </Grid>
            <Grid item xs={4}>
              <PageHeader.Right.RightSpark
                title="Unanswered"
                value={safetyReview?.status_counts?.unanswered}
                color={getStatusColor("unanswered")}
                style={{cursor: "pointer"}}
                onClick={() => setQuestionFilterOption("Status", "unanswered", "Unanswered")}
              />
            </Grid>
            <Grid item xs={4}>
              <PageHeader.Right.RightSpark
                title="N/A"
                value={safetyReview?.status_counts?.na}
                color={getStatusColor("na")}
                style={{cursor: "pointer"}}
                onClick={() => setQuestionFilterOption("Status", "na", "N/A")}
              />
            </Grid>
          </Grid>
        </PageHeader.Right>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header
          isLoading={
            safetyReviewQuery.isFetching ||
            pageDataQuery.isFetching ||
            questionQuery.isFetching ||
            thumbnailQuery.isFetching ||
            attachmentQuery.isFetching
          }
        >
          <PaperPanel.Header.Actions>
            {permissions.can_change_safety_reviews && (
              <>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.Button
                    onClick={() => {
                      setUpdateFromCheckinsConfirmationIsOpen(true);
                    }}
                    disabled={safetyReview.is_closed}
                  >
                    Update Personnel from Checkins
                  </PaperPanel.Header.Button>
                </PaperPanel.Header.Action>

                <PaperPanel.Header.Action>
                  <PaperPanel.Header.Button
                    onClick={() => setMarkUnansweredYesConfirmationIsOpen(true)}
                    disabled={safetyReview.is_closed}
                    startIcon={<FontAwesomeIcon icon={faCheck} fixedWidth size="sm" />}
                  >
                    Mark Unanswered as Yes
                  </PaperPanel.Header.Button>
                </PaperPanel.Header.Action>

                <PaperPanel.Header.Action>
                  <PaperPanel.Header.Button
                    onClick={() => setMarkUnansweredNAConfirmationIsOpen(true)}
                    disabled={safetyReview.is_closed}
                    startIcon={<FontAwesomeIcon icon={faXmark} fixedWidth size="xs" />}
                  >
                    Mark Unanswered as N/A
                  </PaperPanel.Header.Button>
                </PaperPanel.Header.Action>
              </>
            )}

            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButton
                target="_blank"
                href={`/reports2/projects/${project.id}/safety/project-safety-reviews/${safetyReview?.id}/`}
              />
            </PaperPanel.Header.Action>
            {permissions.can_change_safety_reviews && safetyReview.status !== "finalized" && (
              <PaperPanel.Header.Action border pr={0}>
                <PaperPanel.Header.Menu>
                  {(popupState) => (
                    <div>
                      {permissions.can_manage_safety_reviews && (
                        <>
                          <Tooltip
                            title={
                              safetyReview.status !== "submitted" ? "PSR Must be submitted prior to finalizing" : ""
                            }
                          >
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={() => setFinalizeDialogIsOpen(true)}
                                disabled={safetyReview.status !== "submitted"}
                              >
                                Finalize PSR
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>
                          <Tooltip title={safetyReview.status === "pending" ? "PSR is not locked" : ""}>
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={() => {
                                  setMarkPendingConfirmationIsOpen(true);
                                  popupState.close();
                                }}
                                disabled={safetyReview.status === "pending"}
                              >
                                Unlock PSR
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>
                          <span>
                            <PaperPanel.Header.Menu.MenuItem
                              onClick={() => {
                                setDeleteReviewConfirmationIsOpen(true);
                                popupState.close();
                              }}
                              disabled={!safetyReview.can_delete}
                            >
                              Delete PSR
                            </PaperPanel.Header.Menu.MenuItem>
                          </span>
                        </>
                      )}

                      <Tooltip
                        title={
                          <>
                            <div>Submit this PSR to Matt Buzzetta for Final Review</div>
                            {(safetyReview.status !== "complete" || safetyReview.noncompliant_items !== 0) && (
                              <div>(Must be complete and all items must be compliant)</div>
                            )}
                          </>
                        }
                      >
                        <span>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => setFinalSubmitDialogIsOpen(true)}
                            disabled={safetyReview.status !== "complete" || safetyReview.noncompliant_items !== 0}
                          >
                            Submit For Review
                          </PaperPanel.Header.Menu.MenuItem>
                        </span>
                      </Tooltip>
                    </div>
                  )}
                </PaperPanel.Header.Menu>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action pr={-2} border={!permissions.can_change_safety_reviews}>
              <PaperPanel.Header.RefreshButton
                isFetching={
                  safetyReviewQuery.isFetching ||
                  pageDataQuery.isFetching ||
                  questionQuery.isFetching ||
                  thumbnailQuery.isFetching ||
                  attachmentQuery.isFetching
                }
                onClick={() => {
                  safetyReviewQuery.refetch();
                  pageDataQuery.refetch();
                  questionQuery.refetch();
                  thumbnailQuery.refetch();
                  attachmentQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSearch
                label="Question Search"
                value={questionFilterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setQuestionFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FilterSelect
                options={safetyReviewQuestionStatusChoices}
                label="Question Status"
                name="Status"
                onChange={(value, label) => {
                  setQuestionFilterOption("Status", value, label);
                }}
                allowNull
                value={questionFilterOptions.Status.value}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={questionFilterOptions}
                onDelete={(key) => {
                  clearQuestionFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllQuestionFilterOptions();
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body p={1}>
          <AllSafetyReviewForms
            questions={groupedQuestions}
            onQuestionSubmit={(values) => {
              questionUpdate.mutateAsync(values);
            }}
            onReviewSubmit={(values) => {
              safetyReviewUpdate.mutateAsync(values);
            }}
            refreshAttachments={refreshAttachments}
            refreshQuestions={() => questionQuery.refetch()}
            onAttachmentSubmit={(values) => updateAttachment.mutateAsync(values)}
            onDeleteAttachment={(attachment) => deleteAttachment.mutateAsync(attachment.id)}
            projectId={project.id}
            initialFormValues={{
              ...safetyReview,
            }}
            safetyReview={safetyReview}
            setSectionErrors={setSectionErrors}
            superintendent={pageData.superintendentUser}
            attachmentsWithThumbnails={attachmentsWithThumbnails}
            readOnly={!permissions.can_change_safety_reviews}
          />
          {/* General attachments */}
          {allQueriesFinished && (
            <>
              <SectionHeader mx={2}>
                <Typography variant="h6" component="span">
                  General Attachments
                </Typography>
              </SectionHeader>
              {permissions.can_change_safety_reviews && (
                <UploadFileButton
                  label="Upload Attachment"
                  name="file"
                  accept="image/jpeg,image/jpg,image/heic,image/png"
                  onUpload={(file) => {
                    blockUI.blockUI("Uploading");
                    let formData = new FormData();
                    formData.append("file", file);
                    blockUI.blockUI("Uploading");
                    axiosAPI
                      .post(
                        `/projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/attachments/upload/`,
                        formData
                      )
                      .then(() => {
                        refreshAttachments().then(() => blockUI.unblockUI());
                      });
                  }}
                >
                  Upload Attachment
                </UploadFileButton>
              )}
              <Grid container spacing={2}>
                {generalAttachments.length > 0 && (
                  <Grid item xs={12}>
                    <Typography variant="h6">{pluralize("Attachment", generalAttachments.length)}:</Typography>
                  </Grid>
                )}
                {generalAttachments.map((attachment) => {
                  const {thumbnail} = attachment;

                  return (
                    thumbnail?.thumbnail_base64 && (
                      <Grid item key={attachment.id}>
                        <Thumbnail
                          thumbnail={thumbnail}
                          attachment={attachment}
                          onClickThumbnail={(event) => {
                            setActiveAttachment(attachment);
                            setThumbnailDetailIsOpen(true);
                          }}
                        />
                      </Grid>
                    )
                  );
                })}
              </Grid>
              <Box mb={3} />
            </>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      {permissions.can_change_safety_reviews && safetyReview?.status === "pending" && (
        <InnerFooter>
          <Box display="flex">
            <Tooltip title={sectionErrors.size > 0 ? "Please ensure all review items have been completed." : ""}>
              <div>
                <Button
                  disabled={!allQueriesFinished || sectionErrors.size > 0 || safetyReview.is_closed}
                  variant="contained"
                  color="primary"
                  startIcon={<FontAwesomeIcon icon={faCheck} />}
                  onClick={() => setMarkCompleteConfirmationIsOpen(true)}
                >
                  Mark Complete
                </Button>
              </div>
            </Tooltip>
            {/* <Box ml={1} />
            <div>
              {safetyReview.can_delete && <DeleteButton onClick={() => setDeleteReviewConfirmationIsOpen(true)} />}
            </div> */}
          </Box>
        </InnerFooter>
      )}

      <ThumbnailDetailDialog
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          axiosAPI
            .put(
              `/projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/attachments/${activeAttachment.id}/`,
              values
            )
            .then(() => {
              refreshAttachments().then(() => {
                blockUI.unblockUI();
                setThumbnailDetailIsOpen(false);
              });
            });
        }}
        onDelete={() => {
          setDeleteAttachmentConfirmationIsOpen(true);
        }}
        thumbnail={activeAttachment?.thumbnail ?? {}}
        isOpen={thumbnailDetailIsOpen}
        handleClose={() => setThumbnailDetailIsOpen(false)}
        initialValues={activeAttachment}
        readOnly={!permissions.can_change_safety_reviews}
      />
      <ConfirmationDialog
        isOpen={deleteAttachmentConfirmationIsOpen}
        onDeny={() => setDeleteAttachmentConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          setDeleteAttachmentConfirmationIsOpen(false);
          axiosAPI
            .delete(
              `/projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/attachments/${activeAttachment.id}/`
            )
            .then(() => {
              refreshAttachments().then(() => {
                blockUI.unblockUI();
                setThumbnailDetailIsOpen(false);
                setDeleteAttachmentConfirmationIsOpen(false);
              });
            });
        }}
        title="Delete Attachment?"
      >
        Are you sure you want to delete this attachment?
      </ConfirmationDialog>

      <ConfirmationDialog
        onDeny={() => setMarkCompleteConfirmationIsOpen(false)}
        isOpen={markCompleteConfirmationIsOpen}
        onApprove={() => {
          blockUI.blockUI("Marking Complete...");
          postCurrentPage.mutateAsync({action: "mark_complete"}).then((response) => {
            setMarkCompleteConfirmationIsOpen(false);
            if (response.data.noncompliant_items === 0) {
              safetyReviewQuery.refetch();
              setOfferToFinalizeDialogIsOpen(true);
              blockUI.unblockUI();
            } else {
              navigate(`/v2/projects/${project.id}/safety/project-safety-reviews`);
              blockUI.unblockUI();
              enqueueSnackbar(response.message, {
                variant: "success",
              });
            }
          });
        }}
        title="Mark PSR Complete?"
      >
        This project safety review will be finalized, emails will be sent to all item assignees.
      </ConfirmationDialog>
      <ConfirmationDialog
        onDeny={() => setDeleteReviewConfirmationIsOpen(false)}
        isOpen={deleteReviewConfirmationIsOpen}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          setDeleteReviewConfirmationIsOpen(false);
          deleteSafetyReview.mutateAsync().then(() => {
            navigate(`/v2/projects/${project.id}/safety/project-safety-reviews/`);
            blockUI.unblockUI();
          });
        }}
        title="Delete PSR?"
      >
        You want to delete this safety review? This will delete all questions, responses, and attachments. This can not
        be undone.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={updateFromCheckinsConfirmationIsOpen}
        onDeny={() => setUpdateFromCheckinsConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Updating...");
          setUpdateFromCheckinsConfirmationIsOpen(false);
          postCurrentPage.mutateAsync({action: "update_personnel_count"}).then((response) => {
            safetyReviewQuery.refetch().then(() => blockUI.unblockUI());
          });
        }}
        title="Update Personnel From Checkins?"
      >
        This will count all Novo personnel that have checked in so far on the date of this review. This will overwrite
        any count you have entered.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={markUnansweredYesConfirmationIsOpen}
        onDeny={() => setMarkUnansweredYesConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving...");
          axiosAPI
            .post(`/projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/set-unanswered-to-yes/`)
            .then(() => {
              Promise.all([questionQuery.refetch(), safetyReviewQuery.refetch()]).then(() => {
                setMarkUnansweredYesConfirmationIsOpen(false);
                blockUI.unblockUI();
              });
            });
        }}
        title="Mark all unanswered as yes?"
      >
        All questions that have not been answered will be marked as "Yes" indicating that they were in compliance at the
        time of the Project Safety Review.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={markUnansweredNAConfirmationIsOpen}
        onDeny={() => setMarkUnansweredNAConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving...");
          axiosAPI
            .post(`/projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/set-unanswered-to-na/`)
            .then(() => {
              Promise.all([questionQuery.refetch(), safetyReviewQuery.refetch()]).then(() => {
                setMarkUnansweredNAConfirmationIsOpen(false);
                blockUI.unblockUI();
              });
            });
        }}
        title="Mark all unanswered as N/A?"
      >
        All questions that have not been answered will be marked as "N/A" indicating that they are Not Applicable to
        this PSR.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={finalizeDialogIsOpen}
        onDeny={() => setFinalizeDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Finalizing...");
          axiosAPI
            .post(`/projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/finalize/`)
            .then(() => {
              Promise.all([questionQuery.refetch(), safetyReviewQuery.refetch()]).then(() => {
                setFinalizeDialogIsOpen(false);
                blockUI.unblockUI();
              });
            });
        }}
        title="Finalize Project Safety Review?"
      >
        By finalizing the Project Safety Review you are indicating that any safety issues have been corrected. This will
        lock the PSR from changes or deletion.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={markPendingConfirmationIsOpen}
        onDeny={() => setMarkPendingConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving...");
          axiosAPI
            .post(`/projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/mark-pending/`)
            .then(() => {
              Promise.all([questionQuery.refetch(), safetyReviewQuery.refetch()]).then(() => {
                setMarkPendingConfirmationIsOpen(false);
                blockUI.unblockUI();
              });
            });
        }}
        title="Unlock PSR?"
      >
        This will unlock the PSR for editing by marking it pending. The reviewer will be able to edit the PSR and update
        their responses. When completed, new emails will be sent to assignees.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={offerToFinalizeDialogIsOpen}
        onDeny={() => setOfferToFinalizeDialogIsOpen(false)}
        onApprove={() => {
          setOfferToFinalizeDialogIsOpen(false);
          setFinalSubmitDialogIsOpen(true);
        }}
        title="All questions are compliant. Would you like to submit?"
      >
        It appears that there are no out of compliance questions, would you like to submit this PSR for final review?
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={finalSubmitDialogIsOpen}
        onDeny={() => setFinalSubmitDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Finalizing...");
          axiosAPI
            .post(`/projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/submit-for-review/`)
            .then(() => {
              Promise.all([questionQuery.refetch(), safetyReviewQuery.refetch()]).then(() => {
                setFinalSubmitDialogIsOpen(false);
                blockUI.unblockUI();
              });
            });
        }}
        title="Submit for Final Review?"
      >
        This will submit this Project Safety Review to Matt Buzzetta for final review and approval. Once submitted it
        will be locked from further changes.
      </ConfirmationDialog>

      {/* <pre>{JSON.stringify(safetyAuditQuery.data, null, 2)}</pre>
      <pre>{JSON.stringify(pageDataQuery.data, null, 2)}</pre> */}
    </>
  );
};

export default ProjectSafetyReviewDetail;
