import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Cookies from "js-cookie";
import {Form as FinalForm} from "react-final-form";

import {faFilePdf, faGalleryThumbnails} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  Link,
  Tooltip,
} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import {Skeleton} from "@material-ui/lab";
import {Switches as FinalSwitches} from "mui-rff";
import React from "react";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import useBlockUI from "../../hooks/useBlockUI";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {ConfirmationDialog} from "../Dialogs";
import DropzoneMui from "../DropzoneMui";
import {TextFieldMui} from "../forms/Fields";
import FormActions from "../forms/FormActions";
import {PaperItem} from "../PaperItem";
import PaperPanel from "../PaperPanel";
import {SuperuserInfo} from "../SuperuserInfo";
import VirtualizedList from "../VirtualizedList";

export const PDFAttachmentPaperItem = (props) => {
  const {
    setActiveAttachment,
    onDelete,
    onEdit,
    onToggleDisplayAsThumbnail,
    attachment,
    isDragging,
    dragHandleProps,
    ...rest
  } = props;
  return (
    <PaperItem isDragging={isDragging} {...rest}>
      <PaperItem.Left minWidth={45}>
        <Box display="flex" alignItems="center">
          <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
          <PaperItem.Position position={attachment.position} />
        </Box>
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            <Link onClick={onEdit} underline="always" style={{cursor: "pointer"}}>
              {attachment.name}
            </Link>
          </Grid>
          <Grid item xs={8}>
            {attachment.description}
          </Grid>
        </Grid>
      </PaperItem.Body>

      <PaperItem.Right minWidth={80} textAlign="left">
        <Tooltip title="Display as Thumbnail">
          <span>
            <FontAwesomeIcon
              icon={faGalleryThumbnails}
              color={attachment.display_as_thumbnail ? undefined : grey[400]}
              style={{cursor: "pointer"}}
              onClick={() => onToggleDisplayAsThumbnail(attachment)}
            />
          </span>
        </Tooltip>
      </PaperItem.Right>

      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEdit} />
        <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete Item" onClick={onDelete} />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

const PDFAttachmentDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  thumbnailURL: string;
  contentType: string;
  models: string;
  readOnly?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isOpen, handleClose, thumbnailURL, contentType, model, readOnly, ...rest} = props;
  const {query: thumbnailQuery} = useSentinelDetailAPI(thumbnailURL, {
    initialData: {},
    enabled: Boolean(thumbnailURL),
  });

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values} = props;

          return (
            <>
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Attachment Detail</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Box textAlign="center">
                          {thumbnailQuery.isFetching ? (
                            <Box p={1}>
                              {[...Array(14)].map((_, i) => (
                                <Skeleton key={i} />
                              ))}
                            </Box>
                          ) : (
                            <Link href={thumbnailQuery.data.thumbnail_shared_link} target="_blank">
                              <img
                                src={`data:image/png;base64, ${thumbnailQuery.data.thumbnail_base64}`}
                                style={{maxHeight: 350}}
                              />
                            </Link>
                          )}
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="name" label="Name" autoFocus disabled={readOnly} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="description" label="Description" disabled={readOnly} />
                      </Grid>
                      <Grid item xs={12}>
                        <FinalSwitches
                          name="display_as_thumbnail"
                          data={{label: "Display As Thumbnail", value: true}}
                        />
                        <FormHelperText>Will show up as thumbnail in PDF</FormHelperText>
                      </Grid>
                    </Grid>
                    <SuperuserInfo
                      objects={[
                        {
                          contentType: contentType,
                          model: model,
                          id: values.id,
                          title: values.display,
                        },
                      ]}
                    />
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  {!readOnly && (
                    <>
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <FormActions.DeleteButton onClick={onDelete} />
                    </>
                  )}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            </>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

const PDFAttachmentsPaperPanel = (props: {
  url: string;
  uploadURL?: string;
  contentType: string;
  model: string;
  accept?: string;
}) => {
  const {url, uploadURL = `${url}upload/`, accept = "image/*,.heic,.pdf", contentType, model, ...rest} = props;
  const blockUI = useBlockUI();
  const [activeAttachment, setActiveAttachment] = React.useState(null);
  const [attachmentDialogIsOpen, setAttachmentDialogIsOpen] = React.useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);

  const {
    query: attachmentQuery,
    update: updateAttachment,
    delete: deleteAttachment,
    onDragEnd: onDragEnd,
  } = useSentinelListAPI(url, {
    initialData: {
      results: [],
    },
  });

  const attachments = attachmentQuery.data?.results;

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={attachmentQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faFilePdf} /> PDF Attachments
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {/* <PaperPanel.Header.Action>
              <UploadFileButton
                accept={accept}
                onUpload={(file) => {
                  blockUI.blockUI("Uploading");
                  let formData = new FormData();
                  formData.append("file", file);
                  axiosAPI.post(uploadURL, formData).then(() => {
                    attachmentQuery.refetch().then(() => {
                      blockUI.unblockUI();
                    });
                  });
                }}
                {...paperPanelButtonProps}
              >
                Upload Attachment
              </UploadFileButton>
            </PaperPanel.Header.Action> */}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => attachmentQuery.refetch()}
                isFetching={attachmentQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
        <PaperPanel.Body>
          <VirtualizedList itemList={attachments} isVirtualized={false} onDragEnd={onDragEnd}>
            {(props) => {
              const {listItem, ...rest} = props;
              return (
                <PDFAttachmentPaperItem
                  key={listItem.id}
                  attachment={listItem}
                  setActiveAttachment={setActiveAttachment}
                  onDelete={() => {
                    setActiveAttachment(listItem);
                    setDeleteConfirmationIsOpen(true);
                  }}
                  onEdit={() => {
                    setActiveAttachment(listItem);
                    setAttachmentDialogIsOpen(true);
                  }}
                  onToggleDisplayAsThumbnail={(listItem) => {
                    blockUI.blockUI("Saving...");
                    updateAttachment
                      .mutateAsync({
                        id: listItem.id,
                        display_as_thumbnail: !listItem.display_as_thumbnail,
                      })
                      .then(() => {
                        attachmentQuery.refetch().then(() => {
                          blockUI.unblockUI();
                        });
                      });
                  }}
                  {...rest}
                />
              );
            }}
          </VirtualizedList>
          <DropzoneMui
            getUploadParams={() => {
              return {
                url: uploadURL,
                headers: {"X-CSRFToken": Cookies.get("csrftoken")},
              };
            }}
            timeout={1000 * 60 * 5}
            accept={accept}
            onChangeStatus={(event) => {
              if (event.meta.status === "done") {
                attachmentQuery.refetch();
              }
            }}
          />
        </PaperPanel.Body>
        {/* <PaperPanel.Footer p={1}>Footer</PaperPanel.Footer> */}
      </PaperPanel>
      <PDFAttachmentDialogForm
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateAttachment.mutateAsync(values).then(() => {
            attachmentQuery.refetch().then(() => {
              setAttachmentDialogIsOpen(false);
              blockUI.unblockUI();
            });
          });
        }}
        onDelete={() => setDeleteConfirmationIsOpen(true)}
        thumbnailURL={activeAttachment?.id ? `${url}${activeAttachment?.id}/thumbnail/` : ""}
        attachment={activeAttachment}
        isOpen={attachmentDialogIsOpen}
        handleClose={() => setAttachmentDialogIsOpen(false)}
        initialValues={activeAttachment}
        readOnly={false}
        contentType={contentType}
        model={model}
      />

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        title={`Are you sure?`}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          blockUI.blockUI("Deleting...");
          deleteAttachment.mutateAsync(activeAttachment.id).then(() => {
            setAttachmentDialogIsOpen(false);
            setDeleteConfirmationIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        {`You want to delete this attachment ${activeAttachment?.name}.`}
      </ConfirmationDialog>

      {/* <pre>{JSON.stringify(activeAttachment, null, 2)}</pre> */}
    </>
  );
};

export default PDFAttachmentsPaperPanel;
