import {faAward, faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {Currency} from "../../components/Accounting";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterDate, FilterSelect} from "../../components/Filters";
import offices from "../../components/forms/choices/offices.json";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import Typography2 from "../../components/Typography2";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchCurrentPage} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {saveExcel} from "../../utils/excel";

export const SubcontractorAwardFilterForm = (props) => {
  const {filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions} = props;
  return (
    <>
      <Grid container spacing={1}>
        <Grid item sm={3} xs={12}>
          <FilterDate
            name="Start Date"
            value={filterOptions["Start Date"].value}
            label="Start Date"
            onChange={(date) => {
              setFilterOption("Start Date", date, date);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <FilterDate
            name="End Date"
            value={filterOptions["End Date"].value}
            label="End Date"
            onChange={(date) => {
              setFilterOption("End Date", date, date);
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <FilterSelect
            name="Date Range"
            label="Date Range"
            options={[
              {
                label: "Last 90 Days",
                value: "last_90_days",
              },
              {
                label: "Last 180 Days",
                value: "last_180_days",
              },
              {
                label: "YTD",
                value: "ytd",
              },
              {
                label: "Last Year",
                value: "last_year",
              },
            ]}
            onChange={(value, label) => {
              if (value === "last_90_days") {
                const startDate = moment().subtract(90, "days").format("YYYY-MM-DD");
                setFilterOption("Start Date", startDate, startDate);
                clearFilterOption("End Date");
              }
              if (value === "last_180_days") {
                const startDate = moment().subtract(180, "days").format("YYYY-MM-DD");
                setFilterOption("Start Date", startDate, startDate);
                clearFilterOption("End Date");
              }
              if (value === "ytd") {
                const startDate = moment().startOf("year").format("YYYY-MM-DD");
                setFilterOption("Start Date", startDate, startDate);
                clearFilterOption("End Date");
              }
              if (value === "last_year") {
                const startDate = moment().subtract(1, "years").startOf("year").format("YYYY-MM-DD");
                const endDate = moment().subtract(1, "years").endOf("year").format("YYYY-MM-DD");
                setFilterOption("Start Date", startDate, startDate);
                setFilterOption("End Date", endDate, endDate);
              }
            }}
          />
        </Grid>
        <Grid item sm={3} xs={12}>
          <FilterSelect
            // native
            label="Office"
            name="Office"
            options={offices}
            value={filterOptions.Office.value}
            onChange={(value, label) => {
              setFilterOption("Office", value, label);
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={1}>
        <Grid item sm={6} xs={12}>
          <FilterOptionChips
            filterOptions={filterOptions}
            onDelete={(key) => {
              clearFilterOption(key);
            }}
            onDeleteAll={() => {
              clearAllFilterOptions();
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

const SubcontractorAwards = (props) => {
  // const appContext = React.useContext(AppContext);
  const novoClasses = makeNovoClasses();
  const location = useLocation();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Start Date", "End Date", "Office"],
    {
      "Start Date": {
        value: moment().startOf("year").format("YYYY-MM-DD"),
        label: moment().startOf("year").format("YYYY-MM-DD"),
      },
      // "End Date": {
      //   value: moment().format("YYYY-MM-DD"),
      //   label: moment().format("YYYY-MM-DD"),
      // },
    }
  );

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        commitments: [],
        commitmentsSummary: [],
        otherCodesCount: {},
      },
    },
    {
      start_date: filterOptions["Start Date"].value,
      end_date: filterOptions["End Date"].value,
      office: filterOptions.Office.value,
    }
    // qs.parse(location.search)
  );

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching subcontractor awards..." />;
  }

  const otherCodesCount = Object.entries(pageDataQuery.data.otherCodesCount).map(([key, value]) => `${key} (${value})`);

  return (
    <>
      <Helmet title="Subcontractor Awards" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Subcontractor Awards</Typography>
      </Breadcrumbs>
      <PaperPanel mt={2}>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faAward} /> Subcontractor Awards
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    pageDataQuery.data.commitments.map((item) => {
                      return {
                        Group: item.group_name,
                        Vendor: item.vendor_name,
                        "Job Count": item.job_code_count,
                        "Job Average": item.job_average,
                        Amount: item.amount,
                      };
                    }),
                    `Subcontractor Awards`,
                    [{wch: 40}, {wch: 40}, {wch: 10}, {wch: 15}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <SubcontractorAwardFilterForm
            filterOptions={filterOptions}
            setFilterOption={setFilterOption}
            clearFilterOption={clearFilterOption}
            clearAllFilterOptions={clearAllFilterOptions}
          />
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            rightAlignColumns={["job_code_count", "job_average", "amount"]}
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            showFooter={Boolean(pageDataQuery.data.commitmentsSummary.length)}
            columns={[
              {
                Header: "Group",
                accessor: "group_name",
              },
              {
                Header: "Vendor",
                accessor: "vendor_name",
                Cell: ({value, row}) => (
                  <MuiNavLink
                    to={`/v2/preconstruction/subcontractor-awards/${row.original.vendor_id}/`}
                    underline="always"
                  >
                    {value}
                  </MuiNavLink>
                ),
              },
              {
                Header: "Job Count",
                accessor: "job_code_count",
                Cell: ({value, row}) => accounting.formatNumber(value),
              },
              {
                Header: "Job Average",
                accessor: "job_average",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
              },
              // {
              //   Header: "Jobs",
              //   accessor: "job_codes",
              // },
              // {
              //   Header: "Cost Codes",
              //   accessor: "cost_codes",
              // },
              // {
              //   Header: "Position",
              //   accessor: "group_position",
              // },
              {
                Header: "Amount",
                accessor: "amount",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageDataQuery.data.commitmentsSummary.reduce((sum, row) => row.amount + sum, 0),
                    [pageDataQuery.data.commitmentsSummary]
                  );
                  return total ? <Currency number={total} /> : "";
                },
              },
            ]}
            initialState={{}}
            data={pageDataQuery.data.commitments}
          />
          <Box my={1} p={1}>
            <Typography variant="h6">Other Codes</Typography>
            <Typography2 type="metadata">{otherCodesCount.join(" | ")}</Typography2>
          </Box>
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={2} />
      {/* <pre>{JSON.stringify(pageDataQuery.data.otherCodesCount, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(pageDataQuery.data, null, 2)}</pre> */}

      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faAward} /> Award Summary
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    pageDataQuery.data.commitmentsSummary.map((item) => {
                      return {
                        Group: item.group_name,
                        "Job Count": item.job_code_count,
                        "Job Average": item.job_average,
                        Amount: item.amount,
                      };
                    }),
                    `Award Summary`,
                    [{wch: 40}, {wch: 10}, {wch: 15}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <SubcontractorAwardFilterForm
            filterOptions={filterOptions}
            setFilterOption={setFilterOption}
            clearFilterOption={clearFilterOption}
            clearAllFilterOptions={clearAllFilterOptions}
          />
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            rightAlignColumns={["job_code_count", "job_average", "amount"]}
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            showFooter={Boolean(pageDataQuery.data.commitmentsSummary.length)}
            columns={[
              {
                Header: "Group",
                accessor: "group_name",
              },
              {
                Header: "Job Count",
                accessor: "job_code_count",
                Cell: ({value, row}) => accounting.formatNumber(value),
              },
              {
                Header: "Job Average",
                accessor: "job_average",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
              },
              // {
              //   Header: "Jobs",
              //   accessor: "job_codes",
              // },
              // {
              //   Header: "Cost Codes",
              //   accessor: "cost_codes",
              // },
              // {
              //   Header: "Position",
              //   accessor: "group_position",
              // },
              {
                Header: "Amount",
                accessor: "amount",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageDataQuery.data.commitmentsSummary.reduce((sum, row) => row.amount + sum, 0),
                    [pageDataQuery.data.commitmentsSummary]
                  );
                  return total ? <Currency number={total} /> : "";
                },
              },
            ]}
            initialState={{}}
            data={pageDataQuery.data.commitmentsSummary}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <Box mb={2} />

      {/* <Grid container spacing={2}>
        <Grid item sm={12}>
          <Typography variant="h2">Page Data Query</Typography>
          <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre>
        </Grid>
        <Grid item sm={12}>
          <Typography variant="h2">Props</Typography>
          <pre>{JSON.stringify(props, null, 2)}</pre>
        </Grid>
      </Grid> */}
    </>
  );
};

export default SubcontractorAwards;
