import {faFilter} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import React from "react";
import {Currency} from "../../../components/Accounting";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";

const JobCostTransactionTable = (props: {transactions: any[]; isFiltered?: boolean}) => {
  const {transactions, isFiltered} = props;
  const novoClasses = makeNovoClasses();
  return (
    <>
      <ReactTableMui
        size="small"
        className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
        showFooter={Boolean(transactions.length)}
        rightAlignColumns={["units", "amount"]}
        columns={[
          {
            Header: "Category",
            accessor: "category",
          },
          {
            Header: "Cost Code",
            accessor: "cost_code",
          },
          {
            Header: "Vendor",
            accessor: "vendor_name",
          },
          {
            Header: "Invoice",
            accessor: "invoice",
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "Transaction Date",
            accessor: "transaction_date",
            Footer: () => {
              if (isFiltered) {
                return (
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <Box textAlign="right">
                        <FontAwesomeIcon icon={faFilter} /> Filtered
                      </Box>
                    </Box>
                  </Box>
                );
              }
            },
          },
          {
            Header: "Units",
            accessor: "units",
            Cell: ({value}) => accounting.formatNumber(value, 2),
            Footer: () => {
              const total = transactions.reduce((sum, row) => row.units + sum, 0);
              return accounting.formatNumber(total, 2);
            },
          },
          {
            Header: "Amount",
            accessor: "amount",
            Cell: ({value}) => <Currency number={value} />,
            Footer: () => {
              const total = transactions.reduce((sum, row) => row.amount + sum, 0);
              return <Currency number={total} />;
            },
          },
        ]}
        initialState={{
          sortBy: [
            {
              id: "cost_code",
              desc: false,
            },
            {
              id: "transaction_date",
              desc: false,
            },
          ],
        }}
        data={transactions}
      />
    </>
  );
};

export default JobCostTransactionTable;
