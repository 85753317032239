import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import {getCookie} from "./utils/cookie";

const getAxiosErrorMessage = (error) => {
  let message = error.message;
  if (error.response && error.response.data && error.response.data.message)
    message += `<br/>${error.response.data.message}`;
  if (error.response && error.response.status === 400) {
    message += `<p>${JSON.stringify(error.response.data, undefined, 2)}</p>`;
  }
  return message;
};

export const axiosAPI = axios.create({
  baseURL: "/api/v1/",
  // timeout: 1000,
  headers: {
    Accept: "application/json; charset=utf-8",
    "X-CSRFToken": getCookie("csrftoken"),
  },
});
axiosAPI.interceptors.response.use(null, (error) => {
  console.error(error);
  $.smallBoxError({
    content: getAxiosErrorMessage(error),
  });
  return Promise.reject(error);
});

export const staticDataAPI = axios.create({
  baseURL: "/static/data/",
  // timeout: 1000,
  headers: {
    Accept: "application/json; charset=utf-8",
    "X-CSRFToken": getCookie("csrftoken"),
  },
});
staticDataAPI.interceptors.response.use(null, (error) => {
  console.error(error);
  $.smallBoxError({
    content: getAxiosErrorMessage(error),
  });
  return Promise.reject(error);
});

const IS_TEST = process.env.NODE_ENV === "test";

if (IS_TEST) {
  const mockAPI = new MockAdapter(axiosAPI);
  const mockStaticAPI = new MockAdapter(staticDataAPI);
  mockAPI.onGet(new RegExp(`revisions/$`)).reply(200, []);
  mockAPI.onGet(new RegExp(`external-links/$`)).reply(200, []);
  mockAPI.onGet(new RegExp(`/drawings/$`)).reply(200, []);
  mockAPI.onGet(new RegExp(`/tags/$`)).reply(200, []);
  mockAPI.onAny().reply(200, {
    results: [],
    metadata: {},
  });

  mockStaticAPI.onAny().reply(200, []);
}
