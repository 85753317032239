import {faDownload, faRetweet, faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link} from "@material-ui/core";
import {Checkboxes} from "mui-rff";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import budgetAllocations from "../../../components/forms/choices/budgetAllocations.json";
import {
  AutoselectMui,
  CSICodeAutocompleteMui,
  CurrencyFieldMui,
  FileFieldMui,
  MaskedInputMui,
  PercentFieldMui,
  SimpleAutoselectMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {PrevNext} from "../../../components/PrevNext";
import {SuperuserInfo} from "../../../components/SuperuserInfo";
import useFormFocusFieldRef from "../../../hooks/useFormFocusFieldRef";

export const BudgetItemDialogForm = React.forwardRef(
  (
    props: {
      isOpen: boolean;
      onSubmit: (values) => void;
      handleClose: () => void;
      isLocked?: boolean;
      isNew?: boolean;
      isReadOnly?: boolean;
      projectId: number;
      [rest: string]: any;
    },
    ref
  ) => {
    const {
      onSubmit,
      handleClose,
      isOpen,
      isLocked,
      isNew,
      isReadOnly,
      projectId,
      activeItem,
      setActiveItem,
      budgetItems,
      beforePrevNext,
      afterPrevNext,
      initialFocusField,
      setInitialFocusField,
      ...rest
    } = props;

    const formRef = useFormFocusFieldRef(initialFocusField, [props.initialValues.id, props.initialFocusField]);

    const onFocusChange = (props) => {
      const {active} = props;
      if (setInitialFocusField && active) setInitialFocusField(active);
    };

    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values, modified, errors, hasValidationErrors}) => {
              const validateBudgetCode = async (value, values, field) => {
                // if (!isNew && !(modified["budget_code"] || modified["allocation"])) {
                //   return;
                // }

                if (!values.budget_code) return "Required";
                if (String(value).endsWith("000")) {
                  return "Cannot end with 000. These are reserved for divisions.";
                }
                if (!String(value).match(/\b\d{5}\b/g)) {
                  return "Must be exactly 5 digits.";
                }
                if (values.allocation) {
                  const response = await axiosAPI.get(
                    `/projects/${projectId}/budgets/validate-code/?budget_code=${
                      values?.budget_code || ""
                    }&allocation=${values?.allocation || ""}${!isNew ? `&budget_id=${values.id}` : ``}`
                  );
                  if (response.data.error) {
                    return response.data.error;
                  }
                }
              };

              return (
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={formRef}>
                  <DialogTitle id="form-dialog-title">
                    <Grid container justifyContent="space-between">
                      <Grid item>{isNew ? "Create" : "Edit"} Budget Item</Grid>
                      {!isNew && (
                        <Grid item>
                          <PrevNext
                            items={budgetItems}
                            setActiveItem={setActiveItem}
                            activeItem={activeItem}
                            displayProp="budget_code"
                            beforePrevNext={!pristine ? () => beforePrevNext(values) : undefined}
                            afterPrevNext={!pristine ? () => afterPrevNext() : undefined}
                            disabled={hasValidationErrors && !pristine}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <BeforeUnload block={false} />
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CSICodeAutocompleteMui
                            name="budget_code"
                            label="Budget Code"
                            onChange={(e, selection) => form.change("description", selection?.description)}
                            required
                            fieldProps={{
                              validate: validateBudgetCode,
                              validateFields: ["budget_code", "allocation"],
                            }}
                            disabled={isLocked && !isNew}
                            autoFocus
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextFieldMui name="description" label="Description" required disabled={isLocked && !isNew} />
                        </Grid>
                        <Grid item xs={12}>
                          <SimpleAutoselectMui
                            name="allocation"
                            label="Allocation"
                            required
                            options={budgetAllocations}
                            fieldProps={{
                              validateFields: ["budget_code", "allocation"],
                            }}
                            disabled={isLocked && !isNew}
                          />
                          {/* <AutoselectMui
                            name="allocation"
                            label="Allocation"
                            required
                            options={budgetAllocations}
                            fieldProps={{
                              validateFields: ["budget_code", "allocation"],
                            }}
                            disabled={isLocked && !isNew}
                          /> */}
                        </Grid>
                        <Grid item xs={12}>
                          <CurrencyFieldMui
                            name="original_budget"
                            label="Value"
                            required
                            disabled={isLocked}
                            helperText={isLocked && `Adjustments to the budget must be made via change orders.`}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Checkboxes
                            name="is_excluded_from_markup"
                            data={{label: "Exclude From Markup", value: false}}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Checkboxes name="is_markup" data={{label: "Markup", value: false}} />
                        </Grid>
                        {values.is_markup && (
                          <>
                            <Grid item xs={12}>
                              <Checkboxes
                                name="is_compound_markup"
                                data={{label: "Compound Markup", value: false}}
                                helperText="Should this markup be applied after other markups in a compound manner"
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <PercentFieldMui name="markup_rate" label="Markup Rate" decimalScale={3} />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </FinalFormKeyboardShortcuts>
                    {!isNew && (
                      <SuperuserInfo
                        objects={[
                          {
                            contentType: "budgets",
                            model: "budget",
                            id: values.id,
                            title: values.display,
                          },
                        ]}
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    {isNew && (
                      <FormActions.SaveAndAddButton
                        disabled={submitting || pristine}
                        onClick={() => {
                          form.change("submitMode", "addAnother");
                        }}
                      />
                    )}
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>

                  <ActiveFieldsFormSpy onChange={onFocusChange} />
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const SubmitToAccountingForm = (props: {
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  [rest: string]: any;
}) => {
  const {
    onSubmit,
    isOpen,
    handleClose,

    submitToAccountingContacts,
    ...rest
  } = props;

  return (
    <>
      <Box display="flex" justifyContent={"center"} mx={"auto"}>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine = true, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="submit-to-accounting">Submit budget to accounting</DialogTitle>

                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={6}>
                        <AutoselectMui
                          name="submit_to"
                          label="Contact"
                          options={submitToAccountingContacts}
                          labelProp="full_name"
                          valueProp="id"
                          autoFocus
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TinyMCEMui name="comment" label="Comments to Accounting" />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton
                    disabled={submitting || pristine}
                    label={
                      <>
                        <FontAwesomeIcon icon={faRetweet} /> Submit To Accounting
                      </>
                    }
                  />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </Box>
    </>
  );
};

export const BudgetUploadDialog = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, isReadOnly, handleClose, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine = true, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                {" "}
                <FontAwesomeIcon icon={faUpload} fixedWidth /> Upload Budget Items
              </DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  Please, use{" "}
                  <Link underline={"always"} href="/static/files/templates/csi-novo.xlsx">
                    this template file
                  </Link>{" "}
                  to avoid validation errors.
                </FinalFormKeyboardShortcuts>
                <FileFieldMui name="file" label="File" autoFocus />
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton label="Upload" disabled={pristine || isReadOnly} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const DownloadTimberlineImportDialog = (props: {
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, isReadOnly, handleClose, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine = true, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                <FontAwesomeIcon icon={faDownload} fixedWidth /> Download Timberline Import File Details
              </DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <MaskedInputMui
                    autoFocus
                    mask="999-999"
                    name="timberline_job"
                    label="Timberline Job"
                    maskChar="_"
                    required
                  />
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton label="Download" disabled={isReadOnly} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
