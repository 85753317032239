import {faPenFancy} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import accounting from "accounting";
import {Currency} from "../../../components/Accounting";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";

export const PurchaseOrderItemPaperItem = (props) => {
  const {item, isSelected, onEdit, isReadOnly, onDelete, onSelectedChange, ...rest} = props;

  return (
    <PaperItem>
      <PaperItem.Left>
        <PaperItem.SelectedCheckbox checked={isSelected} onChange={onSelectedChange} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container>
          <Grid item md={1} xs={1}>
            {item.number}
          </Grid>
          <Grid item md={5} xs={11}>
            {item.display}
          </Grid>
          <Grid item md={1} xs={2}>
            <Currency number={item.unit_price} />
          </Grid>
          <Grid item md={2} xs={2}>
            {accounting.formatNumber(item.quantity, 2)} {item.unit_of_measure}
          </Grid>
          {/* <Grid item md={1} xs={1}>

            </Grid> */}
          <Grid item md={1} xs={3}>
            <Currency number={item.subtotal} />
          </Grid>
          <Grid item md={1} xs={3}>
            <Currency number={item.taxes} />
          </Grid>
          {/* <Grid item md={1} xs={2}>
              {item.is_taxable ? <FontAwesomeIcon icon={faCheckSquare} /> : <FontAwesomeIcon icon={faSquare} />}
            </Grid> */}
          <Grid item md={1} xs={2}>
            <Box textAlign="right">
              <Currency number={item.total_price} />
            </Box>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={60} />
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEdit} disabled={isReadOnly} />
        <PaperItem.RightHover.IconButton
          icon={DeleteIcon}
          title="Delete Item"
          onClick={onDelete}
          disabled={isReadOnly}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export const AmendmentPaperItem = (props) => {
  const {amendment, onEditAmendment, ...rest} = props;

  return (
    <PaperItem>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={6}>
            <StatusLabel status={amendment.status_display} />{" "}
            <MuiNavLink
              underline="always"
              href={`/v2/projects/${amendment.project_id}/pos/${amendment.purchase_order_id}/amendments/${amendment.id}/`}
            >
              {amendment.display}
            </MuiNavLink>
          </Grid>
          <Grid item sm={2}>
            <Typography2 type="metadata">{amendment.timberline_subcontract_co_id}</Typography2>
          </Grid>
          <Grid item sm={2}>
            {amendment.docusign_envelope_status && (
              <Link href={amendment.current_docusign_envelope_detail_url}>
                <Tooltip title="DocuSign Status">
                  <small>
                    <FontAwesomeIcon icon={faPenFancy} /> {amendment.docusign_envelope_status}
                  </small>
                </Tooltip>
              </Link>
            )}
          </Grid>
          <Grid item sm={1}></Grid>
          <Grid item sm={1}>
            <Box textAlign="right">
              <Currency number={amendment.total_price} />
            </Box>
          </Grid>
        </Grid>
        {/* <pre>{JSON.stringify(amendment, null, 2)}</pre> */}
      </PaperItem.Body>
      <PaperItem.Right minWidth={60}>
        <Typography2 type="metadata">{amendment.number_display}</Typography2>
      </PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={LinkIcon} title="Link" component={Link} href={amendment.url} />
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditAmendment} />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
