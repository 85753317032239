import {orange} from "@material-ui/core/colors";
import accounting from "accounting";
import {colorError, colorSuccess, colorWarning} from "../theme/colors";
import {ColoredChip} from "./Chips";

export const DaysUntilExpiration = (props: {days: number; [rest: string]: any}) => {
  const {days, ...rest} = props;
  let color;
  color = colorSuccess;
  if (days < 60) {
    color = colorWarning;
  }
  if (days < 30) {
    color = orange[500];
  }
  if (days < 0) {
    color = colorError;
  }
  return <ColoredChip variant="outlined" size="small" label={accounting.formatNumber(days)} color={color} {...rest} />;
};
