import {Box, Button} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useSnackbar} from "notistack";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {DeleteButton} from "../../components/Buttons";
import BlockUI from "../../components/GlobalLoaders";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../hooks/useSentinelAPI";

const Tree = (props) => {
  const {items} = props;
  // our base case, if we have no items, render nothing.
  if (!items || !items.length) {
    return null;
  }
  return (
    <ul style={{listStyleType: "none"}}>
      {items.map((item) => (
        <React.Fragment key={item}>{Array.isArray(item) ? <Tree items={item} /> : <li>{item}</li>}</React.Fragment>
      ))}
    </ul>
  );
};

const ProjectDeleteObject = (props) => {
  const qsValues = qs.parse(location.search);
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      object: {
        display: "",
      },
      protected: [],
      deleted_objects: [],
    },
  });
  const pageData = pageDataQuery.data as any;
  const postCurrentPage = usePostCurrentPage();
  const {enqueueSnackbar} = useSnackbar();
  const blockUI = useBlockUI();

  const object = pageData.object;
  const canDelete = pageData.protected.length === 0 && pageDataQuery.isFetchedAfterMount;
  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message={`Fetching delete info...`} />;
  }
  if (!canDelete) {
    return (
      <>
        <Helmet title={`Delete - ${object.display}`} />
        <BlockUI show={!pageDataQuery.isFetched} />
        <Alert severity="error">
          <strong>{`Cannot delete ${object.display}`}</strong>{" "}
          {`it would require deleting the following protected related objects.`}
        </Alert>
        <Box>
          <Tree items={pageData.protected} />
        </Box>
      </>
    );
  }
  return (
    <>
      <Helmet title={`Delete - ${object.display}`} />
      <BlockUI show={!pageDataQuery.isFetched} />
      <Alert severity="warning">
        <strong>{`Are you sure you want to delete ${object.display}?`}</strong> {`This cannot be undone.`}
        <br />
        The following related items will also be deleted.
      </Alert>

      <Box>
        <Tree items={pageData.deleted_objects} />
      </Box>
      <Box display="flex">
        <DeleteButton
          onClick={() => {
            blockUI.blockUI("Deleting...");
            postCurrentPage
              .mutateAsync({})
              .then((data) => {
                window.location = qsValues.next as any;
              })
              .catch(() => blockUI.unblockUI());
          }}
        />
        <Box ml={1} />
        <Button href={`${qsValues.cancel}${location.hash || ""}` as string}>Cancel</Button>
      </Box>

      {/* <Typography variant="h2">Query String</Typography>
      <pre>{JSON.stringify(qsValues, null, 2)}</pre>

      <Typography variant="h2">pageDataQuery</Typography>
      <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
    </>
  );
};

export default ProjectDeleteObject;

// http://localhost:8000/v2/projects/5619/delete/contracts/subcontract/37863/
// http://localhost:8000/v2/projects/1143/delete/rfis/rfi/35612/
