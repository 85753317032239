import {faBlockBrickFire} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumbs, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useSelectIds} from "../../../js/hooks";
import {ConfirmationDialog} from "../../components/Dialogs";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui, ReactTableMuiCheckboxSelector} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {colorError, colorWarning} from "../../theme/colors";

const RequestRemoteAccess = (props) => {
  const [allIpIds, setAllIpIds] = React.useState([]);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      ips: [],
      securityGroupCounts: [],
    },
  });
  const pageData = pageDataQuery.data;

  const postCurrentPage = usePostCurrentPage();

  const blockUI = useBlockUI();

  // const allIpIds = pageData.ips.map((ip) => ip.key);

  const {selectedIds, addSelectedId, removeSelectedId, addAllSelectedIds, removeAllSelectedIds, allIdsSelected} =
    useSelectIds(allIpIds);

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Remote Access info..." />;
  }

  return (
    <>
      <Helmet title="Request Remote Access" />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary"> Remote Access</Typography>
      </Breadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faBlockBrickFire} /> Remote Access Admin
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          {pageData.securityGroupCounts.map((sg) => {
            let ruleCountColor = "inherit";
            if (sg.sgCount >= 150) {
              ruleCountColor = colorWarning;
            }
            if (sg.sgCount >= 180) {
              ruleCountColor = colorError;
            }
            return (
              <PageHeader.Right.RightSpark
                title={sg.sgName}
                value={accounting.formatNumber(sg.sgCount)}
                color={ruleCountColor}
                // color={green[800]}
              />
            );
          })}
        </PageHeader.Right>
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faBlockBrickFire} /> Remote Access Admin
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.DeleteButton
                disabled={selectedIds.size === 0}
                onClick={() => {
                  setDeleteConfirmationIsOpen(true);
                }}
              >
                Delete
              </PaperPanel.Header.DeleteButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={pageDataQuery.isFetching}
                onClick={() => pageDataQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                Header: "",
                accessor: "key",
                Cell: ({value, row}) => (
                  <ReactTableMuiCheckboxSelector
                    id={value}
                    selected={selectedIds.has(value)}
                    onAddSelected={addSelectedId}
                    onRemoveSelected={removeSelectedId}
                    onClick={(event) => event.preventDefault()}
                  />
                ),
              },
              {
                Header: "SG Name",
                accessor: "sg_name",
              },
              {
                Header: "IP",
                accessor: "ip",
              },
              {
                Header: "Description",
                accessor: "description",
                sortType: (prev, curr, columnId) => {
                  if (prev.original[columnId].toLowerCase() > curr.original[columnId].toLowerCase()) {
                    return 1;
                  } else if (prev.original[columnId].toLowerCase() < curr.original[columnId].toLowerCase()) {
                    return -1;
                  } else {
                    return 0;
                  }
                },
              },
              {
                Header: "Date",
                accessor: (value) => {
                  const possibleData = value.description.substr(value.description.length - 10);
                  return moment(possibleData, "YYYY-MM-DD", true).isValid()
                    ? moment(possibleData).format("YYYY-MM-DD")
                    : "";
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "sg_name",
                },
                {
                  id: "description",
                },
              ],
            }}
            data={pageData.ips}
            onSortBy={(sortBy, rows) => {
              setAllIpIds(rows.map((row) => row.original.key));
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          blockUI.blockUI("Deleting...");
          postCurrentPage.mutateAsync({action: "delete_selected", ips: Array.from(selectedIds)}).then(() => {
            pageDataQuery.refetch().then(() => {
              removeAllSelectedIds();
              blockUI.unblockUI();
            });
          });
        }}
        onDeny={() => {
          setDeleteConfirmationIsOpen(false);
        }}
      >
        You would like to delete {accounting.formatNumber(selectedIds.size)} selected rules? This cannot be undone.
      </ConfirmationDialog>

      {/* <h1>ids</h1>
      <pre>{JSON.stringify(Array.from(selectedIds), null, 2)}</pre>
      <h1>page data</h1>
      <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
    </>
  );
};

export default RequestRemoteAccess;
