import {
  faCaretDown,
  faCaretUp,
  faClone,
  faCopy,
  faDownload,
  faEraser,
  faList,
  faMoneyBill,
  faPaperclip,
  faPlusCircle,
  faTable,
  faUpload,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Link,
  MenuItem,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {Alert, TabContext, TabPanel} from "@material-ui/lab";
import {isEmpty} from "lodash";
import {useSnackbar} from "notistack";
import pluralize from "pluralize";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import ActivityStream from "../../../components/ActivityStream";
import {DropdownButton, SplitButton} from "../../../components/Buttons";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {StatusIcon} from "../../../components/Icons";
import {LegacyUILink, MuiNavLink, PortalLink, ProjectAuditLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Column, Row} from "@silevis/reactgrid";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import ReactGrid from "../../../components/ReactGrid";
import {NumberCellTemplate} from "../../../components/ReactGrid/CurrencyCellTemplate";
import {StatusLabel} from "../../../components/Status";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {
  getAxiosAPIResponseMessage,
  useFetchCurrentPage,
  usePostCurrentPage,
  useSentinelDetailAPI,
  useSentinelListAPI,
} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {colorSuccess} from "../../../theme/colors";
import {CORDetailForm} from "./CORForms";
import {
  AddBudgetCodeDialog,
  MarkupSelectDialog,
  PCODetailForm,
  PCOItemDialogForm,
  PCOItemUploadDialogForm,
  QuickCreatePCOItemForm,
} from "./PCOForms";
import {PCOItemPaperItem} from "./PCOPaperItem";
const PCODetail = (props) => {
  const {project, ...rest} = props;
  const {pcoId} = useParams();
  const permissions = usePermissions();
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();
  const postCurrentPage = usePostCurrentPage(
    {},
    {
      baseURL: "",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [pcoItemCreateIsOpen, setPCOItemCreateIsOpen] = React.useState(false);
  const [pcoItemUpdateIsOpen, setPCOItemUpdateIsOpen] = React.useState(false);
  const [pcoItemDeleteIsOpen, setPCOItemDeleteIsOpen] = React.useState(false);
  const [pcoItemBulkDeleteIsOpen, setPCOItemBulkDeleteIsOpen] = React.useState(false);
  const [pcoItemMarkupAllIsOpen, setPCOItemMarkupAllIsOpen] = React.useState(false);
  const [pcoItemSelectMarkupsIsOpen, setPCOItemSelectMarkupsIsOpen] = React.useState(false);
  const [pcoItemCopyProposedIsOpen, setPCOItemCopyProposedIsOpen] = React.useState(false);
  const [addBudgetCodeIsOpen, setAddBudgetCodeIsOpen] = React.useState(false);
  const [copyAmountField, setCopyAmountField] = React.useState({
    fromField: "",
    fromFieldName: "",
    toField: "",
    toFieldName: "",
  });
  const [fieldToZero, setFieldToZero] = React.useState({field: "", fieldName: ""});
  const [valuesToZeroIsOpen, setValuesToZeroIsOpen] = React.useState(false);
  const [uploadDialogIsOpen, setUploadDialogIsOpen] = React.useState(false);
  const [activePCOItem, setActivePCOItem] = React.useState({} as {id?: number; display?: string; is_closed?: boolean});
  const [convertPCOToExternalConfirmation, setConvertPCOToExternalConfirmation] = React.useState(false);
  const [convertPCOToInternalConfirmation, setConvertPCOToInternalConfirmation] = React.useState(false);
  const [cloneConfirmationIsOpen, setCloneConfirmationIsOpen] = React.useState(false);
  const [markupToCopy, setMarkupToCopy] = React.useState({item: {}, fieldName: "", field: "", amount: 0}) as any;
  const [copyOneMarkupIsOpen, setCopyOneMarkupIsOpen] = React.useState(false);
  const [newlyCreatedBudget, setNewlyCreatedBudget] = React.useState({} as any);
  const [savedFormValues, setSavedFormValues] = React.useState({} as any);
  const [editGridDialogIsOpen, setEditGridDialogIsOpen] = React.useState(false);
  const [accordionIsExpanded, setAccordionIsExpanded] = React.useState(false);
  const [itemIdToFlash, setItemIdToFlash] = React.useState(0);
  const [itemInsertionIndex, setItemInsertionIndex] = React.useState(null);
  const [initialFocusField, setInitialFocusField] = React.useState(null);

  const quickCreateFormRef = React.createRef() as any;

  React.useEffect(() => {
    setItemInsertionIndex(accordionIsExpanded ? 0 : null);
  }, [accordionIsExpanded]);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
    },
  });

  const {
    query: pcoQuery,
    update: updatePCO,
    delete: deletePCO,
    rpc: pcoRPC,
  } = useSentinelDetailAPI(["projects", project.id, "pcos", parseInt(pcoId)], {
    initialData: {
      tags: [],
      cor: {
        id: null,
      },
    },
  });

  const {
    query: pcoItemsQuery,
    create: createPCOItem,
    update: updatePCOItem,
    delete: deletePCOItem,
    rpc: pcoItemRPC,
    onDragEnd: pcoItemsOnDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/pcos/${pcoId}/items/`,
    {
      initialData: {
        results: [],
      },
    },
    {insertionIndex: itemInsertionIndex, disableOnSuccess: true}
  );

  const pco = pcoQuery.data;
  const pcoItems = pcoItemsQuery.data.results;
  const allPCOItemIds = pcoItems.map((item) => item.id);

  const {
    query: corQuery,
    update: updateCOR,
    rpc: corRPC,
  } = useSentinelDetailAPI(["projects", project.id, "cors", pco?.cor?.id], {
    initialData: {
      tags: [],
    },
    enabled: Boolean(pco?.cor?.id),
  });

  const {create: createCOR} = useSentinelListAPI(["projects", project.id, "cors"]);

  const cor = corQuery.data;

  const {selectedIds, addSelectedId, removeSelectedId, addAllSelectedIds, removeAllSelectedIds, allIdsSelected} =
    useSelectIds(allPCOItemIds);

  const reorderPCOs = (orderBy) => {
    blockUI.blockUI("Reordering...");

    // pcoRPC
    //   .mutateAsync({action: `reposition-pco-items`, data: {order_by: orderBy}})
    axiosAPI
      .post(`/projects/${project.id}/pcos/${pcoId}/reposition-pco-items/?order_by=${orderBy}`)
      .then(() => {
        Promise.all([pcoQuery.refetch(), pcoItemsQuery.refetch()]).then(() => blockUI.unblockUI());
      })
      .catch((error) => {
        enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
        blockUI.unblockUI();
      });
  };

  const {query: markupQuery} = useSentinelListAPI(`/projects/${project.id}/budgets/?is_markup=true`, {
    initialData: {
      results: [],
    },
  });

  const canCreateCOR = pco.can_create_revision && !pco.cor && !pco.is_internal;
  const hasCOR = !isEmpty(pco.cor);
  const hasClosedPCCO = Boolean(pco?.pcco?.is_closed);
  const hasPCCO = Boolean(pco.pcco);
  const isCORTab = location.hash.includes("cor");

  const onExpectedMarkupClickCallback = React.useCallback((itemObject) => {
    if (itemObject.item?.is_closed) return;
    setMarkupToCopy(itemObject);
    setCopyOneMarkupIsOpen(true);
  }, []);

  const emptyFormValues = {
    budget_rom: 0,
    budget_proposed_amount: 0,
    budget_approved_amount: 0,
    budget_cost: 0,
  };

  const createPCOItemInitialValues = React.useMemo(() => {
    if (!isEmpty(savedFormValues)) {
      return {
        // budget_rom: 0,
        // budget_proposed_amount: 0,
        // budget_approved_amount: 0,
        // budget_cost: 0,
        ...emptyFormValues,
        ...savedFormValues,
        budget: !isEmpty(newlyCreatedBudget) ? {...newlyCreatedBudget} : savedFormValues?.budget,
        description: !isEmpty(newlyCreatedBudget) ? newlyCreatedBudget?.description : savedFormValues?.description,
      };
    }
    // else {
    return emptyFormValues;
    // {
    //   budget_rom: 0,
    //   budget_proposed_amount: 0,
    //   budget_approved_amount: 0,
    //   budget_cost: 0,
    // };
    // }
  }, [newlyCreatedBudget, activePCOItem, savedFormValues]);

  if ((!pcoQuery.isFetchedAfterMount && !corQuery.isFetchedAfterMount) || !pcoItemsQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching PCO info..." />;
  }

  return (
    <>
      {isCORTab ? <Helmet title={`COR ${cor.display}`} /> : <Helmet title={`PCO ${pco.display}`} />}

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/pcos/`}>
          PCOs
        </MuiNavLink>
        {hasCOR ? (
          <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/pcos/${pco.id}/`}>
            {pco.display}
          </MuiNavLink>
        ) : (
          <Typography color="textPrimary">{pco.display}</Typography>
        )}

        {hasCOR && (
          <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/cors/`}>
            CORs
          </MuiNavLink>
        )}
        {hasCOR && (
          <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/pcos/${pco.id}/#tab=cor`}>
            {pco.cor.display}
          </MuiNavLink>
        )}
      </Breadcrumbs>
      {pco.is_closed && (
        <>
          <Box mt={1}>
            <Alert severity="warning">
              <strong>This PCO is in a closed status "{pco.status_display}" and has been locked.</strong>
              <br />
              You will not be able to edit this PCO or add, remove or change any items.
              {pco.cor ? (
                <>
                  <br /> If you need to change this PCO change the COR (via the COR tab below) to an open status such as
                  "Pending".
                </>
              ) : (
                <>
                  <br /> If you need to change this PCO change the status to an open status such as "Pending".
                </>
              )}
            </Alert>
          </Box>
        </>
      )}

      {pco.is_internal && (
        <>
          <Box mt={1}>
            <Alert severity="info">This is an Internal PCO</Alert>
          </Box>
          {pco.budget_approved_amount !== 0 && (
            <Box mt={1}>
              <Alert severity="warning">
                PCO approved amount should usually total 0 for an internal change order. This is not always the case;
                you can ignore this message if you know what you are doing.
              </Alert>
            </Box>
          )}
        </>
      )}

      {pageDataQuery.data.currentRevisionUrl && (
        <Box mt={1}>
          <Alert severity="info">
            This is a prior revision of this {isCORTab ? "COR" : "PCO"}.{" "}
            <MuiNavLink
              to={`/v2/projects/${project.id}${pageDataQuery.data.currentRevisionUrl}${
                isCORTab ? `#tab=cor` : location.hash
              }`}
            >
              Click here
            </MuiNavLink>{" "}
            to go to the current revision.
          </Alert>
        </Box>
      )}

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>{isCORTab ? `COR` : `PCO`} Detail</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          {pcoQuery.isFetchedAfterMount && (
            <>
              <PageHeader.Right.CurrencySpark title="ROM" number={pco.budget_rom} precision={2} />
              <PageHeader.Right.CurrencySpark
                title="Proposed"
                number={pco.budget_proposed_amount}
                border
                precision={2}
              />
              <PageHeader.Right.CurrencySpark
                title="Approved"
                number={pco.budget_approved_amount}
                border
                precision={2}
              />
              <PageHeader.Right.CurrencySpark title="Cost" number={pco.budget_cost} border precision={2} />
            </>
          )}
        </PageHeader.Right>
      </PageHeader>

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <StatusIcon status={pco.status} tooltip={pco.status_display} showTooltip />
                    <Box ml={1}>PCO #{pco.number_display}</Box>
                  </Box>
                }
                value="general"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Items <TabCountChip count={pcoItems.length} isLoading={pcoItemsQuery.isFetching} />
                  </Box>
                }
                value="items"
                className={novoClasses.smallTab}
              />
              {pco.cor && (
                <Tab
                  label={
                    <Box display="flex" alignItems="center">
                      <StatusIcon status={pco.cor?.status} tooltip={pco.cor?.status_display} showTooltip />
                      <Box ml={1}>COR #{pco.cor?.number_display}</Box>
                    </Box>
                  }
                  value="cor"
                  className={novoClasses.smallTab}
                />
              )}
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <Box ml={1}>Logs</Box>
                  </Box>
                }
                value="logs"
                className={novoClasses.smallTab}
              />
              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />

          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={pcoQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <Box justifyItems="space-between">
                    <StatusLabel status={pco.status_display} /> {pco.display}
                  </Box>
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  {canCreateCOR && (
                    <PaperPanel.Header.Action>
                      <PaperPanel.Header.CreateButton
                        onClick={() => {
                          blockUI.blockUI("Creating COR...");
                          createCOR
                            .mutateAsync({pco: pco, description: pco.description})
                            .then((response) => {
                              setTimeout(() => {
                                window.location.reload();
                              });
                            })
                            .catch(() => blockUI.unblockUI());
                        }}
                      >
                        Create COR
                      </PaperPanel.Header.CreateButton>
                    </PaperPanel.Header.Action>
                  )}
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/pcos/${pco.id}/`}
                      buildReportURL={`/reports2/projects/${project.id}/pcos/detail/filter/?pk=${pco.id}`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RevisionsButtons
                      url={`projects/${project.id}/pcos/${pcoId}/revisions/`}
                      obj={pco}
                      onCreateRevision={() => {
                        blockUI.blockUI("Creating new revision...");
                        pcoRPC
                          .mutateAsync({action: "revisions"})
                          .then((response) => {
                            window.location.href = `/v2/projects/${project.id}/pcos/${response.id}/`;
                          })
                          .catch(() => blockUI.unblockUI());
                      }}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/pcos/pco/${pco.id}/`}
                      uploadURL={`/projects/${project.id}/pcos/${pco.id}/upload-to-box/`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          {pco.is_internal ? (
                            <PaperPanel.Header.Menu.MenuItem
                              onClick={(event) => {
                                popupState.close();
                                setConvertPCOToExternalConfirmation(true);
                              }}
                            >
                              Convert To External PCO
                            </PaperPanel.Header.Menu.MenuItem>
                          ) : (
                            <Tooltip
                              title={
                                hasCOR
                                  ? `You must delete the COR for this PCO before converting it to an internal PCO.`
                                  : ""
                              }
                            >
                              <span>
                                <PaperPanel.Header.Menu.MenuItem
                                  disabled={hasCOR}
                                  onClick={(event) => {
                                    popupState.close();
                                    setConvertPCOToInternalConfirmation(true);
                                  }}
                                >
                                  Convert To Internal PCO
                                </PaperPanel.Header.Menu.MenuItem>
                              </span>
                            </Tooltip>
                          )}
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                              setCloneConfirmationIsOpen(true);
                            }}
                          >
                            <FontAwesomeIcon icon={faClone} fixedWidth />
                            Clone PCO
                          </PaperPanel.Header.Menu.MenuItem>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action px={0}>
                    <PaperPanel.Header.RefreshButton
                      isFetching={pcoQuery.isFetching}
                      onClick={() => {
                        setItemIdToFlash(0);
                        pcoQuery.refetch();
                      }}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>

              <PCODetailForm
                initialValues={pco}
                projectId={project.id}
                isReadOnly={pco.is_closed}
                onSubmit={(values) => {
                  blockUI.blockUI("Saving...");
                  updatePCO
                    .mutateAsync(values)
                    .then(() => pcoQuery.refetch())
                    .then(() => blockUI.unblockUI());
                }}
              />
              <CreatedByModifiedBy obj={pco} mt={2} />
              <ProjectAuditLink projectId={project.id} app="pcos" model="pco" id={pcoId} mt={1} />
              <PortalLink href={`/projects/${project.id}/pcos/${pco.id}/`} />
            </>
          </TabPanel>
          <TabPanel value="items">
            <PaperPanel.TabHeader isLoading={pcoQuery.isFetching}>
              <PaperPanel.Header.Title>
                <PaperItem.SelectedCheckbox
                  indeterminate={!allIdsSelected && selectedIds.size !== 0}
                  checked={allIdsSelected}
                  onChange={(event, checked) => {
                    if (checked) {
                      addAllSelectedIds();
                    } else {
                      removeAllSelectedIds();
                    }
                  }}
                />{" "}
                <FontAwesomeIcon icon={faList} /> Items
              </PaperPanel.Header.Title>

              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <Tooltip
                    title={
                      hasClosedPCCO
                        ? `Cannot modify items. This PCO is linked to closed OCO #${pco?.pcco?.number_display}`
                        : ""
                    }
                  >
                    <span>
                      <PaperPanel.Header.CreateButton
                        onClick={() => setPCOItemCreateIsOpen(true)}
                        disabled={hasClosedPCCO || pco.is_closed}
                      />
                    </span>
                  </Tooltip>
                  <Box ml={1} />
                  <Tooltip
                    title={
                      hasClosedPCCO
                        ? `Cannot modify items. This PCO is linked to closed OCO #${pco?.pcco?.number_display}`
                        : ""
                    }
                  >
                    <span>
                      <PaperPanel.Header.DeleteButton
                        onClick={() => setPCOItemBulkDeleteIsOpen(true)}
                        disabled={selectedIds.size < 1 || hasClosedPCCO || pco.is_closed}
                      />
                    </span>
                  </Tooltip>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <Tooltip
                    title={
                      hasClosedPCCO
                        ? `Cannot modify items. This PCO is linked to closed OCO #${pco?.pcco?.number_display}`
                        : ""
                    }
                  >
                    <span>
                      <SplitButton
                        title={
                          <>
                            <FontAwesomeIcon icon={faMoneyBill} fixedWidth />
                            Apply Markups
                          </>
                        }
                        size="small"
                        onClick={() => setPCOItemMarkupAllIsOpen(true)}
                        disabled={pco.is_closed || hasClosedPCCO}
                        disableDropDown={pco.is_closed || hasClosedPCCO}
                      >
                        <Tooltip title={markupQuery?.data?.results.length === 0 ? `No Markups Found` : ""}>
                          <span>
                            <MenuItem
                              onClick={() => setPCOItemSelectMarkupsIsOpen(true)}
                              disabled={pco.is_closed || hasClosedPCCO || markupQuery?.data?.results.length === 0}
                            >
                              Select Markups To Apply
                            </MenuItem>
                          </span>
                        </Tooltip>
                      </SplitButton>
                    </span>
                  </Tooltip>
                  <Box ml={1} />
                  <Tooltip
                    title={
                      pco.is_closed || hasClosedPCCO
                        ? hasClosedPCCO
                          ? `Cannot set values on items. This PCO is linked to closed OCO #${pco?.pcco?.number_display}`
                          : `Cannot set values on items. This PCO is closed`
                        : ""
                    }
                  >
                    <span>
                      <DropdownButton size="small" title="Set Values" disabled={pco.is_closed || hasClosedPCCO}>
                        <MenuItem
                          onClick={() => {
                            setCopyAmountField({
                              fromField: "budget_proposed_amount",
                              fromFieldName: "Proposed",
                              toField: "budget_approved_amount",
                              toFieldName: "Approved",
                            });
                            setPCOItemCopyProposedIsOpen(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faCopy} fixedWidth />
                          Copy Proposed Values To Approved
                        </MenuItem>

                        <MenuItem divider disabled />
                        <MenuItem
                          onClick={() => {
                            setFieldToZero({field: "budget_rom", fieldName: "ROM"});
                            setValuesToZeroIsOpen(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faEraser} fixedWidth />
                          Set ROM to 0
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setFieldToZero({field: "budget_proposed_amount", fieldName: "Proposed"});
                            setValuesToZeroIsOpen(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faEraser} fixedWidth />
                          Set Proposed to 0
                        </MenuItem>

                        <MenuItem
                          onClick={() => {
                            setFieldToZero({field: "budget_approved_amount", fieldName: "Approved"});
                            setValuesToZeroIsOpen(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faEraser} fixedWidth />
                          Set Approved to 0
                        </MenuItem>
                      </DropdownButton>
                    </span>
                  </Tooltip>
                  <Box ml={1} />
                  <DropdownButton size="small" title="Manage Items">
                    <MenuItem>
                      <FontAwesomeIcon icon={faDownload} fixedWidth />
                      <Link href={`/projects/${project.id}/pcos/${pco.id}/items/download/`} underline="none">
                        Download PCO Items
                      </Link>
                    </MenuItem>
                    <MenuItem onClick={() => setUploadDialogIsOpen(true)} disabled={pco.is_closed}>
                      <FontAwesomeIcon icon={faUpload} fixedWidth />
                      Upload PCO Items
                    </MenuItem>
                  </DropdownButton>
                  <Box ml={1} />
                  <PaperPanel.Header.ReorderButton
                    onReorder={(orderBy) => reorderPCOs(orderBy)}
                    onFixPositions={() => {
                      blockUI.blockUI("Fixing...");
                      pcoRPC
                        .mutateAsync({action: `fix-item-positions`})
                        .then(() => Promise.all([pcoQuery.refetch(), pcoItemsQuery.refetch()]))
                        .then(() => blockUI.unblockUI());
                    }}
                    onRenumber={() => {
                      blockUI.blockUI("Renumbering...");
                      pcoRPC
                        .mutateAsync({action: `renumber-items-on-position`})
                        .then(() => Promise.all([pcoQuery.refetch(), pcoItemsQuery.refetch()]))
                        .then(() => blockUI.unblockUI());
                    }}
                  >
                    {(setOpen) => (
                      <>
                        <Divider />
                        <PaperPanel.Header.Menu.MenuItem
                          onClick={() => {
                            setOpen(false);
                            reorderPCOs("budget__budget_code");
                          }}
                        >
                          <FontAwesomeIcon icon={faCaretDown} fixedWidth />
                          Budget Code
                        </PaperPanel.Header.Menu.MenuItem>
                        <PaperPanel.Header.Menu.MenuItem
                          onClick={() => {
                            setOpen(false);
                            reorderPCOs("-budget__budget_code");
                          }}
                        >
                          <FontAwesomeIcon icon={faCaretUp} fixedWidth />
                          Budget Code
                        </PaperPanel.Header.Menu.MenuItem>
                      </>
                    )}
                  </PaperPanel.Header.ReorderButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.Button
                    // disabled={pco.is_closed}
                    onClick={(event) => {
                      setEditGridDialogIsOpen(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTable} fixedWidth />
                    Edit In Grid
                  </PaperPanel.Header.Button>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={pcoItemsQuery.isFetching}
                    onClick={() => Promise.all([pcoItemsQuery.refetch(), pcoQuery.refetch()])}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>

            <Box mt={-2} mb={-3} mx={-3}>
              {!hasClosedPCCO && !pco.is_closed && (
                <Box mt={1} mb={0.25}>
                  <Accordion
                    expanded={accordionIsExpanded}
                    onChange={() => {
                      setAccordionIsExpanded(!accordionIsExpanded);
                    }}
                    square
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography>
                        <FontAwesomeIcon icon={faPlusCircle} color={colorSuccess} /> Quick Create
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <QuickCreatePCOItemForm
                        ref={quickCreateFormRef}
                        projectId={project.id}
                        initialValues={emptyFormValues}
                        onSubmit={(values, form) => {
                          const focusElement = quickCreateFormRef.current.elements.budget;

                          createPCOItem.mutateAsync(values).then((result) => {
                            form.restart();
                            focusElement.focus();
                            setItemIdToFlash(result.id);
                          });
                        }}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
              <VirtualizedList isVirtualized={pcoItems.length > 100} onDragEnd={pcoItemsOnDragEnd} itemList={pcoItems}>
                {(props) => {
                  const {listItem: pcoItem, ...rest} = props;

                  return (
                    <PCOItemPaperItem
                      pcoItem={pcoItem}
                      projectId={project.id}
                      onChangePCOItemSelected={(event, value) => {
                        if (value) {
                          addSelectedId(pcoItem.id, event.nativeEvent.shiftKey);
                        } else {
                          removeSelectedId(pcoItem.id);
                        }
                      }}
                      isSelected={selectedIds.has(pcoItem.id)}
                      onEditPCOItem={(event, item) => {
                        setInitialFocusField(item);
                        setActivePCOItem(pcoItem);
                        setPCOItemUpdateIsOpen(true);
                      }}
                      onDeletePCOItem={() => {
                        setActivePCOItem(pcoItem);
                        setPCOItemDeleteIsOpen(true);
                      }}
                      onExpectedMarkupClick={onExpectedMarkupClickCallback}
                      flash={itemIdToFlash === pcoItem.id}
                      highlight={activePCOItem.id === pcoItem.id && pcoItemUpdateIsOpen}
                      {...rest}
                    />
                  );
                }}
              </VirtualizedList>
            </Box>
          </TabPanel>
          {pco.cor && (
            <TabPanel value="cor">
              {!corQuery.isFetchedAfterMount ? (
                <BlockUI show={true} message="Fetching COR info..." />
              ) : (
                <>
                  <PaperPanel.TabHeader isLoading={corQuery.isFetching}>
                    <PaperPanel.Header.Title>
                      <Box justifyItems="space-between">
                        <StatusLabel status={cor.status_display} /> {cor.display}
                      </Box>
                    </PaperPanel.Header.Title>
                    <PaperPanel.Header.Actions>
                      <PaperPanel.Header.Action>
                        <PaperPanel.Header.PDFButtons
                          pdfURL={`/reports2/cors/${cor.id}/`}
                          buildReportURL={`/reports2/projects/${project.id}/cors/detail/filter/?pk=${cor.id}`}
                        />
                      </PaperPanel.Header.Action>
                      {hasPCCO && (
                        <PaperPanel.Header.Action border>
                          <PaperPanel.Header.Button href={cor?.pcco?.url} size="small">
                            <FontAwesomeIcon icon={faPaperclip} fixedWidth />
                            OCO #{cor?.pcco?.number_display}
                          </PaperPanel.Header.Button>
                        </PaperPanel.Header.Action>
                      )}
                      <PaperPanel.Header.Action border>
                        <PaperPanel.Header.RevisionsButtons
                          url={`projects/${project.id}/cors/${cor.id}/revisions/`}
                          obj={cor}
                          onCreateRevision={() => {
                            blockUI.blockUI("Creating new revision...");
                            corRPC
                              .mutateAsync({action: "revisions"})
                              .then((response) => {
                                window.location.href = `/v2/projects/${project.id}/pcos/${response.pco.id}/#tab=cor`;
                              })
                              .catch(() => blockUI.unblockUI());
                          }}
                        />
                      </PaperPanel.Header.Action>

                      <PaperPanel.Header.Action border>
                        <PaperPanel.Header.DocuSignButton docuSignObject={cor} />
                      </PaperPanel.Header.Action>

                      <PaperPanel.Header.Action border>
                        <PaperPanel.Header.BoxFilesButton
                          href={`/v2/projects/${project.id}/box/files/cors/cor/${cor.id}/`}
                          uploadURL={`/projects/${project.id}/cors/${cor.id}/upload-to-box/`}
                        />
                      </PaperPanel.Header.Action>

                      <PaperPanel.Header.Action border mr={-1}>
                        <PaperPanel.Header.RefreshButton
                          isFetching={corQuery.isFetching}
                          onClick={() => corQuery.refetch()}
                        />
                      </PaperPanel.Header.Action>
                    </PaperPanel.Header.Actions>
                  </PaperPanel.TabHeader>
                  <CORDetailForm
                    projectId={project.id}
                    restrictNovoSigners={project.restrict_novo_signers}
                    onSubmit={(values) => {
                      blockUI.blockUI("Saving COR...");
                      updateCOR.mutateAsync(values).then(() => {
                        Promise.all([corQuery.refetch(), pcoQuery.refetch(), pcoItemsQuery.refetch()]).then(() =>
                          blockUI.unblockUI()
                        );
                      });
                    }}
                    initialValues={cor}
                    isReadOnly={cor.is_closed}
                  />
                  <CreatedByModifiedBy obj={cor} mt={2} />
                  <ProjectAuditLink projectId={project.id} app="cors" model="cor" id={cor.id} mt={1} />
                  <PortalLink href={`/projects/${project.id}/cors/${cor.id}/`} />
                </>
              )}
            </TabPanel>
          )}
          <TabPanel value="logs">
            <Box mx={-3} mt={-4}>
              <ActivityStream
                url={`actstream/pcos/pco/${pco.id}/`}
                title={`PCO #${pco.number_display} Activity Stream`}
              />
              <Box my={2} />
              {pco?.cor && (
                <ActivityStream
                  url={`actstream/cors/cor/${pco?.cor?.id}`}
                  title={`COR #${pco.cor?.number_display} Activity Stream`}
                />
              )}
            </Box>
          </TabPanel>
        </PaperPanel>
      </TabContext>

      <LegacyUILink href={`/projects/${project.id}/pcos/${pcoId}/`} mt={1} />
      <LegacyUILink href={`/pcos/${pcoId}/?legacy=True`} title="Old Legacy UI Link:" />
      {/* <h2>PCO</h2>
      <pre>{JSON.stringify(pco, null, 2)}</pre>
      <h2>createPCOItem</h2>
      <pre>{JSON.stringify({isLoading: createPCOItem.isLoading, isIdle: createPCOItem.isIdle}, null, 2)}</pre>
      <h2>updatePCOItem</h2>
      <pre>{JSON.stringify({isLoading: updatePCOItem.isLoading, isIdle: updatePCOItem.isIdle}, null, 2)}</pre> */}

      {/* Quick Create Item */}

      <PCOItemDialogForm
        projectId={project.id}
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;
          blockUI.blockUI("Creating...");
          setSavedFormValues({});
          setNewlyCreatedBudget({});
          if (submitMode === "addAnother") {
            // const focusElement = createItemFormRef.current.elements.budget;
            // focusElement.focus();
            setInitialFocusField(null);
          }
          createPCOItem.mutateAsync(values).then((result) => {
            if (submitMode === "addAnother") {
              // form.reset();
              form.restart();
              setInitialFocusField("budget");
            } else {
              setPCOItemCreateIsOpen(false);
            }
            setItemIdToFlash(result.id);
            pcoQuery.refetch();
            pcoItemsQuery.refetch();
            blockUI.unblockUI();
          });
        }}
        handleClose={() => {
          setSavedFormValues({});
          setNewlyCreatedBudget({});
          setPCOItemCreateIsOpen(false);
        }}
        isOpen={pcoItemCreateIsOpen}
        isNew
        isReadOnly={pco.is_closed}
        initialValues={createPCOItemInitialValues}
        onCreateBudgetCode={(values) => {
          setSavedFormValues(values);
          // setPCOItemCreateIsOpen(false);
          setAddBudgetCodeIsOpen(true);
        }}
        initialFocusField={initialFocusField}
      />

      {/* Quick Update Item */}
      <PCOItemDialogForm
        projectId={project.id}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updatePCOItem
            .mutateAsync(values)
            .then(() => Promise.all([pcoQuery.refetch(), pcoItemsQuery.refetch()]))
            .then(() => {
              setPCOItemUpdateIsOpen(false);
              setInitialFocusField(null);
              blockUI.unblockUI();
            });
        }}
        handleClose={() => setPCOItemUpdateIsOpen(false)}
        isOpen={pcoItemUpdateIsOpen}
        isReadOnly={activePCOItem.is_closed}
        initialValues={activePCOItem}
        pco={pco}
        pcoItems={pcoItems}
        setActivePCOItem={setActivePCOItem}
        activePCOItem={activePCOItem}
        initialFocusField={initialFocusField}
        setInitialFocusField={setInitialFocusField}
        // beforePrevNext={(values) => {
        //   blockUI.blockUI("Updating...");
        //   return new Promise((resolve, reject) => {
        //     updatePCOItem
        //       .mutateAsync(values)
        //       .then(() => Promise.all([pcoQuery.refetch(), pcoItemsQuery.refetch()]))
        //       .then(() => {
        //         resolve(blockUI.unblockUI());
        //       });
        //   });
        // }}
        beforePrevNext={(values) => {
          blockUI.blockUI("Updating...");
          return updatePCOItem.mutateAsync(values).then(() => {
            blockUI.unblockUI();
          });
        }}
        afterPrevNext={() => {
          pcoQuery.refetch();
          pcoItemsQuery.refetch();
        }}
      />

      {/* <Choose Markups */}
      <MarkupSelectDialog
        isOpen={pcoItemSelectMarkupsIsOpen}
        projectId={project.id}
        handleClose={() => setPCOItemSelectMarkupsIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Applying...");
          pcoRPC
            .mutateAsync({action: `apply-markups`, data: values})
            .then(() => Promise.all([pcoQuery.refetch(), pcoItemsQuery.refetch()]))
            .then(() => {
              setPCOItemSelectMarkupsIsOpen(false);
              blockUI.unblockUI();
            });
        }}
      />

      <PCOItemUploadDialogForm
        projectId={project.id}
        isOpen={uploadDialogIsOpen}
        onSubmit={(values) => {
          setUploadDialogIsOpen(false);
          // setTimeout(() => blockUI.blockUI("Uploading...")); // need to setTimeout to allow dialog to close
          blockUI.blockUI("Uploading...");
          let formData = new FormData();
          Object.entries(values).map(([key, value]) => {
            /* @ts-ignore */
            formData.append(key, value);
          });
          postCurrentPage
            .mutateAsync(formData)
            .then(() => Promise.all([pcoQuery.refetch(), pcoItemsQuery.refetch()]))
            .then(() => blockUI.unblockUI());
        }}
        handleClose={() => setUploadDialogIsOpen(false)}
      />

      <AddBudgetCodeDialog
        projectId={project.id}
        isOpen={addBudgetCodeIsOpen}
        handleClose={() => {
          setAddBudgetCodeIsOpen(false);
          setPCOItemCreateIsOpen(true);
        }}
        onSubmit={(values) => {
          if (values.allocation) {
            values.allocation = values.allocation.value;
          }
          blockUI.blockUI("Saving...");
          axiosAPI.post(`projects/${project.id}/budgets/`, values).then((result) => {
            setNewlyCreatedBudget(result.data);
            setAddBudgetCodeIsOpen(false);
            setPCOItemCreateIsOpen(true);
            blockUI.unblockUI();
          });
        }}
        initialValues={{original_budget: 0, markup_rate: 0}}
      />

      <EditPCOItemGridDialog
        // updatePCOItem={updatePCOItem}
        projectId={project.id}
        pco={pco}
        pcoItems={pcoItems}
        isOpen={editGridDialogIsOpen}
        onClose={() => {
          // if (reason === "escapeKeyDown") return;
          // lets always refetch description can be changed even if item is closed
          blockUI.blockUI("Updating...");
          pcoItemsQuery
            .refetch()
            .then(() => pcoQuery.refetch())
            .then(() => {
              setEditGridDialogIsOpen(false);
              blockUI.unblockUI();
            });

          // if (!pco.is_closed) {
          //   blockUI.blockUI("Updating...");
          //   pcoItemsQuery
          //     .refetch()
          //     .then(() => pcoQuery.refetch())
          //     .then(() => {
          //       setEditGridDialogIsOpen(false);
          //       blockUI.unblockUI();
          //     });
          // } else {
          //   setEditGridDialogIsOpen(false);
          // }
        }}
      />

      <ConfirmationDialog
        isOpen={pcoItemMarkupAllIsOpen}
        title="Are you sure?"
        onApprove={() => {
          blockUI.blockUI("Applying...");
          pcoRPC
            .mutateAsync({action: `apply-markups`})
            .then(() => Promise.all([pcoQuery.refetch(), pcoItemsQuery.refetch()]))
            .then(() => {
              setPCOItemMarkupAllIsOpen(false);
              blockUI.unblockUI();
            });
        }}
        onDeny={() => setPCOItemMarkupAllIsOpen(false)}
      >
        Are you sure that you would like to add and recalculate all markups for this PCO?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={pcoItemDeleteIsOpen}
        title="Are you sure you want to delete this PCO Item?"
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deletePCOItem
            .mutateAsync(activePCOItem.id)
            .then(() => pcoQuery.refetch())
            .then(() => {
              setPCOItemDeleteIsOpen(false);
              blockUI.unblockUI();
            });
        }}
        onDeny={() => setPCOItemDeleteIsOpen(false)}
      >
        This will delete {activePCOItem?.display}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={pcoItemBulkDeleteIsOpen}
        title="Are You Sure?"
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          pcoItemRPC
            .mutateAsync({
              action: "delete",
              method: "DELETE",
              data: {ids: Array.from(selectedIds)},
            })
            .then(() => {
              setPCOItemBulkDeleteIsOpen(false);
              removeAllSelectedIds();
              pcoItemsQuery
                .refetch()
                .then(() => pcoQuery.refetch())
                .then(() => blockUI.unblockUI());
            });
        }}
        onDeny={() => setPCOItemBulkDeleteIsOpen(false)}
      >
        This will delete {selectedIds.size} {pluralize("item", selectedIds.size)}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={pcoItemCopyProposedIsOpen}
        title="Are you sure?"
        onApprove={() => {
          blockUI.blockUI("Copying...");
          pcoRPC
            .mutateAsync({
              action: `copy-amounts`,
              method: `POST`,
              data: {from: copyAmountField.fromField, to: copyAmountField.toField},
            })
            .then(() => Promise.all([pcoItemsQuery.refetch(), pcoQuery.refetch()]))
            .then(() => {
              setPCOItemCopyProposedIsOpen(false);
              blockUI.unblockUI();
            });
        }}
        onDeny={() => setPCOItemCopyProposedIsOpen(false)}
      >
        Are you sure you want copy <strong>{copyAmountField.fromFieldName}</strong> to{" "}
        <strong>{copyAmountField.toFieldName}</strong> on all {pcoItems.length} PCO items? This cannot be undone
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={valuesToZeroIsOpen}
        title="Are you sure?"
        onApprove={() => {
          blockUI.blockUI("Saving...");
          const updateData = {
            ids: [...allPCOItemIds],
            update: {},
          };
          updateData.update[fieldToZero.field] = 0;
          pcoItemRPC
            .mutateAsync({action: `update`, method: `PUT`, data: updateData})
            .then(() => Promise.all([pcoItemsQuery.refetch(), pcoQuery.refetch()]))
            .then(() => {
              blockUI.unblockUI();
              setValuesToZeroIsOpen(false);
            });
        }}
        onDeny={() => setValuesToZeroIsOpen(false)}
      >
        Are you sure you want to set <strong>{fieldToZero.fieldName}</strong> to 0 on all {pcoItems.length} PCO items?
        This cannot be undone
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={copyOneMarkupIsOpen}
        onDeny={() => setCopyOneMarkupIsOpen(false)}
        onApprove={() => {
          const values = {} as any;
          values.id = markupToCopy.item?.id;
          values[markupToCopy.field] = markupToCopy?.amount;
          updatePCOItem.mutateAsync(values).then(() => Promise.all([pcoItemsQuery.refetch(), pcoQuery.refetch()]));
          setCopyOneMarkupIsOpen(false);
        }}
      >
        Are you sure that you would like to copy <Currency number={markupToCopy?.amount} /> to {markupToCopy.fieldName}?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={convertPCOToExternalConfirmation}
        title="Convert to External"
        onApprove={() => {
          blockUI.blockUI();
          pcoRPC.mutateAsync({action: `convert-to-external`}).then(() => window.location.reload());
        }}
        onDeny={() => {
          setConvertPCOToExternalConfirmation(false);
        }}
      >
        Are you sure that you want to convert this PCO to an external PCO?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={convertPCOToInternalConfirmation}
        title="Convert to Internal"
        onApprove={() => {
          blockUI.blockUI();
          pcoRPC.mutateAsync({action: `convert-to-internal`}).then(() => window.location.reload());
        }}
        onDeny={() => {
          setConvertPCOToInternalConfirmation(false);
        }}
      >
        Are you sure that you want to convert this PCO to an internal PCO?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={cloneConfirmationIsOpen}
        title="Clone PCO"
        onApprove={() => {
          blockUI.blockUI();
          pcoRPC
            .mutateAsync({action: `clone`})
            .then(
              (response) => (window.location = response.url)
              // navigate(`/v2/projects/${project.id}/pcos/${response.id}/`)
            )
            .catch(() => blockUI.unblockUI());
        }}
        onDeny={() => {
          setCloneConfirmationIsOpen(false);
        }}
      >
        Are you sure you would like to clone this PCO? This will copy all items to a new PCO.
      </ConfirmationDialog>
    </>
  );
};

export default PCODetail;

// React Grid - Tabled for now

const EditPCOItemGridDialog = (props: {isOpen: boolean; onClose: () => void; pco: any; [rest: string]: any}) => {
  const {pco, projectId, isOpen, onClose, ...rest} = props;
  const hasClosedPCCO = Boolean(pco?.pcco?.is_closed);
  const {query: pcoItemsQuery, update: updatePCOItem} = useSentinelListAPI(
    `projects/${projectId}/pcos/${pco.id}/items/`,
    {
      initialData: {
        results: [],
      },
    },
    {disableOnSuccess: true}
  );

  const pcoItems = pcoItemsQuery.data.results;
  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="lg">
      <DialogTitle>Edit PCO Items</DialogTitle>
      <DialogContent>
        <Box mb={4}>
          <ReactGrid
            // onRowsReordered={(foo) => {
            //   console.log(`foo ${foo}`);
            // }}
            // enableRowSelection
            // onCellsChanged={(changes) => {
            //   console.log("onCellsChanged", changes);
            // }}
            onChange={(item) => {
              // console.log(item);

              updatePCOItem.mutateAsync(item);
            }}
            enableFillHandle
            enableRangeSelection
            // enableRowSelection
            customCellTemplates={{number: new NumberCellTemplate()}}
            canReorderRows={(targetRowId, rowIds) => {
              return false;
              // return targetRowId !== "header" ;
            }}
            // onRowsReordered={(targetRowId, rowIds) => {
            //   console.log("onRowsReordered", targetRowId, rowIds);
            // }}
            rows={
              [
                {
                  rowId: "header",
                  cells: [
                    {type: "header", text: "Description"},
                    {type: "header", text: "ROM"},
                    {type: "header", text: "Proposed"},
                    {type: "header", text: "Approved"},
                    {type: "header", text: "Budget Cost"},
                  ],
                },
                ...pcoItems.map((item) => ({
                  rowId: item.id,
                  cells: [
                    {type: "text", text: item.description, nonEditable: false},
                    {type: "number", value: item.budget_rom, nonEditable: pco.is_closed},
                    {type: "number", value: item.budget_proposed_amount, nonEditable: pco.is_closed},
                    {type: "number", value: item.budget_approved_amount, nonEditable: pco.is_closed || hasClosedPCCO},
                    {
                      type: "number",
                      value: item.budget_cost,
                      nonEditable: pco.is_closed || Boolean(item.scco),
                    },
                  ],
                })),
                {
                  rowId: "footer",
                  cells: [
                    {type: "text", text: "Total", nonEditable: true, className: "rg-cell-bold"},
                    {
                      type: "number",
                      // text: "ROM",
                      nonEditable: true,
                      value: pcoItems.reduce((sum, item) => item.budget_rom + sum, 0),
                      className: "rg-cell-bold",
                    },
                    {
                      type: "number",
                      // text: "Proposed",
                      nonEditable: true,
                      value: pcoItems.reduce((sum, item) => item.budget_proposed_amount + sum, 0),
                      className: "rg-cell-bold",
                    },
                    {
                      type: "number",
                      // text: "Approved",
                      nonEditable: true,
                      value: pcoItems.reduce((sum, item) => item.budget_approved_amount + sum, 0),
                      className: "rg-cell-bold",
                    },
                    {
                      type: "number",
                      // text: "Approved",
                      nonEditable: true,
                      value: pcoItems.reduce((sum, item) => item.budget_cost + sum, 0),
                      className: "rg-cell-bold",
                    },
                  ],
                },
              ] as Row[]
            }
            columns={
              [
                {columnId: "description", width: 0},
                {columnId: "budget_rom", width: 100},
                {columnId: "budget_proposed_amount", width: 100},
                {columnId: "budget_approved_amount", width: 100},
                {columnId: "budget_cost", width: 100},
              ] as Column[]
            }
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
