import {Box, Link} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import {MuiNavLink} from "./Links";

const ClosedBanner = (props: {
  severity?: "success" | "info" | "warning" | "error";
  object: {
    display: boolean;
    is_closed: boolean;
    is_closed_reason: string;
  };
  [rest: string]: any;
}) => {
  const {severity = "info", object, ...rest} = props;
  if (!object.is_closed) return null;
  return (
    <Box {...rest}>
      <Alert severity={severity} {...props}>
        {object.display} is locked for editing: {object.is_closed_reason}
      </Alert>
    </Box>
  );
};

export default ClosedBanner;

export const CommitmentBanner = (props: {
  // severity?: "success" | "info" | "warning" | "error";
  object: {
    display: boolean;
    is_closed: boolean;
    is_closed_reason: string;
    timberline_subcontract_id: string;
    status: string;
    is_void: boolean;
    submitted_date: string;
    current_docusign_envelope_detail_url: string;
    to_company: {
      name: string;
    };
    id: number;
    budget: {
      project_id: number;
    };
    timberline_subcontract_co?: string;
    commitment?: {
      to_company: {
        name: string;
      };
    };
  };
  type: "subcontract" | "purchase order" | "SCO";
  [rest: string]: any;
}) => {
  const {object, type = "commitment", ...rest} = props;
  const days_ago = Math.abs(moment(object.submitted_date).diff(moment(), "days"));

  if (type === "SCO") {
    object.to_company = object.commitment.to_company;
  }

  const getMessage = (): {body; severity} => {
    if (object.status === "void") {
      return {body: `Warning - this ${type} is void`, severity: "warning"};
    }
    if (object.timberline_subcontract_id || object.timberline_subcontract_co) {
      return {
        body: (
          <>
            This {type} was submitted {object.submitted_date} and has been linked to Timberline contract{" "}
            {type === "subcontract" ? (
              <MuiNavLink
                underline="always"
                to={`/v2/projects/${object.budget.project_id}/contracts/sub/${object.id}/#tab=timberline`}
              >
                {object.timberline_subcontract_id} - {object.to_company.name}
              </MuiNavLink>
            ) : type === "SCO" ? (
              <>
                {object.timberline_subcontract_co} - {object.to_company.name}
              </>
            ) : (
              <Link href={`/timberline/subcontracts/${object.timberline_subcontract_id}/`} underline="always">
                {object.timberline_subcontract_id} - {object.to_company.name}
              </Link>
            )}
          </>
        ),
        severity: "success",
      };
    }
    if (object.is_closed) {
      if (days_ago < 5) {
        const daysAgoString = days_ago === 0 ? `today.` : `${days_ago} ${pluralize("day", days_ago)} ago.`;
        return {
          body: `This ${type} is not linked to a Timberline contract but it was only submitted to accounting ${daysAgoString} Please give them time to process the submission.`,
          severity: "warning",
        };
      }
      if (object.submitted_date) {
        return {
          body: (
            <>
              This {type} was submitted on {object.submitted_date} but could not be matched with a Timberline contract.
              Please contact <Link href="mailto:cstoner@novoconstruction.com">Colin</Link> to have this corrected.
            </>
          ),
          severity: `warning`,
        };
      }
      if (object.is_closed_reason.includes("DocuSign") || !object.submitted_date) {
        return {
          body: (
            <>
              This {type} has an existing DocuSign envelope open and changes cannot be made. You must void the existing
              DocuSign envelope before making any changes to this {type}.
              {/* <Link href={`/docusign/${type === "SCO" ? 37 : 29}/${object.id}/envelopes/`} underline="always"> */}
            </>
          ),
          severity: "info",
        };
      }
      return {body: `This ${type} can not be edited: ${object.is_closed_reason}`, severity: "info"};
    }
  };

  const message = React.useMemo(() => getMessage(), [object]);

  if (message) {
    return (
      <Box {...rest}>
        <Alert severity={message.severity}>{message.body}</Alert>
      </Box>
    );
  }
};
