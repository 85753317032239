import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid} from "@material-ui/core";
import classnames from "classnames";
import {Checkboxes, Switches as FinalSwitches} from "mui-rff";
import pluralize from "pluralize";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router-dom";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {ConfirmationDialog} from "../../../components/Dialogs";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import {
  DatePickerMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  ProjectTagAutocompleteMui,
  SelectImportance,
  SpecSectionAutocompleteMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import {beforeDialogClose} from "../../../components/forms/Form";
import FormActions from "../../../components/forms/FormActions";
import {StatusIcon} from "../../../components/Icons";
import InnerFooter from "../../../components/InnerFooter";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {PrevNext} from "../../../components/PrevNext";
import {ReactTableMui, ReactTableMuiCheckboxSelector} from "../../../components/ReactTableMui";
import {StatusLabel} from "../../../components/Status";
import {SuperuserInfo} from "../../../components/SuperuserInfo";
import useBlockUI from "../../../hooks/useBlockUI";
import useFormFocusFieldRef from "../../../hooks/useFormFocusFieldRef";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

export const SubmittalPackageBulkUpdateDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  onDelete: () => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, onDelete, isOpen, handleClose, isReadOnly = false, packageCount, ...rest} = props;

  return (
    <>
      <Box display="flex" justifyContent={"center"} mx={"auto"}>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine = true, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="bulk-update-form">
                  Bulk Update {packageCount} {pluralize("Submittal Package", packageCount)}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <SelectImportance name="importance_id" label="Importance" allowNull />
                      </Grid>
                      <Grid item xs={12}>
                        <Checkboxes name="clear_importance" data={{label: "Clear Importance", value: true}} />
                      </Grid>
                      <Grid item xs={12}>
                        <SpecSectionAutocompleteMui
                          name="spec_section_code"
                          projectId={projectId}
                          label="Spec Section"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui
                          label="Add To CC"
                          name="add_to_cc"
                          projectId={projectId}
                          disabled={isReadOnly}
                        />
                      </Grid>

                      <Grid item xs={6}>
                        <Checkboxes name="is_draft" data={{label: "Is Draft", value: true}} />
                      </Grid>
                      <Grid item xs={6}>
                        <Checkboxes name="not_draft" data={{label: "Not Draft", value: true}} />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  {/* <FormActions.DeleteButton onClick={onDelete} disabled={isReadOnly} /> */}
                  <FormActions.SaveButton disabled={submitting || pristine || isReadOnly} label={"Bulk Update"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </Box>
    </>
  );
};

const SubmittalPackageCommonFields = (props: {
  projectId: number;
  values: any;
  isNew?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, values, isNew, isReadOnly, ...rest} = props;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={4} xs={12}>
          <TextFieldMui
            label="Number"
            name="number"
            // helperText="# In Project"
            disabled={isReadOnly}
            required
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextFieldMui label="Revision" name="revision" disabled />
        </Grid>
        <Grid item sm={4} xs={12}>
          <TextFieldMui label="Author Package Number" name="author_package_number" disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12}>
          <TextFieldMui label="Description" name="description" disabled={isReadOnly} autoFocus required />
        </Grid>

        <Grid item sm={4} xs={12}>
          <DatePickerMui
            label="Submitted Date"
            name="submitted_date"
            disabled={isReadOnly}
            helperText="GC to Design Team"
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <DatePickerMui label="Due Date" name="due_date" disabled={isReadOnly} helperText="Due from Design Team" />
        </Grid>
        <Grid item sm={4} xs={12}>
          <DatePickerMui
            label="Returned Date"
            name="returned_date"
            disabled={isReadOnly}
            helperText="From design team to GC"
          />
        </Grid>

        <Grid item sm={4} xs={12}>
          <SpecSectionAutocompleteMui
            name="spec_section_code"
            projectId={projectId}
            label="Spec Section"
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item sm={4} xs={12}>
          <SelectImportance name="importance" label="Importance" allowNull disabled={isReadOnly} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <FinalSwitches name="is_draft" data={{label: "Draft", value: true}} />
          <FormHelperText>Drafts will not show up on external site</FormHelperText>
        </Grid>
        <Grid item sm={6} xs={12}>
          <ProjectCompanyAutoselectMui
            projectId={projectId}
            name="author_company"
            label="Author Company"
            disabled={isReadOnly}
            required
          />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ProjectContactAutoselectMui
            label="Author Contact"
            name="author_contact"
            projectId={projectId}
            disabled={isReadOnly}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" />
        </Grid>

        <Grid item sm={6} xs={12}>
          <TinyMCEMui name="general_notes" label="General Notes" />
        </Grid>
        <Grid item sm={6} xs={12}>
          <TinyMCEMui name="reviewer_notes" label="Reviewer Notes" />
        </Grid>
      </Grid>
    </>
  );
};

export const SubmittalPackageUpdateForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  isNew?: boolean;
  isReadOnly?: boolean;
  contactChildren?: React.ReactNode;
  // All other props
  [rest: string]: any;
}) => {
  const {pathname} = useLocation();
  // useWhyDidYouUpdate("RFIUpdateDialogForm", props);
  const {onSubmit, projectId, isNew, isReadOnly, contactChildren, ...rest} = props;
  const [dialogFormIsOpen, setDialogFormIsOpen] = React.useState(false);
  const blockUI = useBlockUI();
  return (
    <>
      <FinalForm
        onSubmit={(values) => {
          const newApprovers = values.approver_contacts;
          const originalApprovers = props?.initialValues?.approver_contacts || [];

          const contactWasRemoved = !originalApprovers.every((contact) => newApprovers.includes(contact)); // We removed a contact

          if (contactWasRemoved) {
            setDialogFormIsOpen(true);
          } else {
            onSubmit(values);
          }
        }}
        {...rest}
      >
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values} = props;
          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.TabToolbar mb={2} px={3} pt={2} pb={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <ProjectContactAutoselectMui
                          projectId={projectId}
                          name="approver_contacts"
                          label="Approver"
                          multiple
                          getOptionDisabled={(option) =>
                            values.approver_contacts.map((value) => value.id).includes(option.id)
                          }
                        />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <ProjectContactAutoselectMui
                          projectId={projectId}
                          name="cc_contacts"
                          label="CC"
                          multiple
                          getOptionDisabled={(option) =>
                            values.cc_contacts.map((value) => value.id).includes(option.id)
                          }
                        />
                      </Grid>
                    </Grid>

                    {contactChildren}
                  </PaperPanel.TabToolbar>
                  <SubmittalPackageCommonFields
                    values={values}
                    isReadOnly={isReadOnly}
                    isNew={isNew}
                    projectId={projectId}
                  />
                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton disabled={submitting || pristine} />
                      <Box ml={1} />
                      <FormActions.DeleteButton
                        href={`/v2/projects/${projectId}/delete/submittals/submittalpackage/${values.id}/?next=/v2/projects/${projectId}/submittals/packages/&cancel=${pathname}`}
                      />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
                <ConfirmationDialog
                  isOpen={dialogFormIsOpen}
                  onApprove={() => {
                    setDialogFormIsOpen(false);
                    blockUI.blockUI("Saving...");
                    onSubmit(values);
                  }}
                  onDeny={() => {
                    setDialogFormIsOpen(false);
                  }}
                >
                  It looks like 1 or more approvers were removed from the package. This will also delete all their
                  reviews. This cannot be undone.
                </ConfirmationDialog>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

export const SubmittalPackageUpdateDialogForm = React.forwardRef(
  (
    props: {
      projectId: number;
      onSubmit: (values: any) => void;
      handleClose: () => void;
      isOpen: boolean;
      isNew?: boolean;
      isReadOnly?: boolean;
      [rest: string]: any;
    },
    ref
  ) => {
    const {
      projectId,
      onSubmit,
      isNew,
      isOpen,
      handleClose,
      isReadOnly,
      submittalPackages,
      setActiveSubmittalPackage,
      activeSubmittalPackage,
      beforePrevNext,
      afterPrevNext,
      initialFocusField,
      setInitialFocusField,
      ...rest
    } = props;
    const formPropsRef = React.useRef(null);

    const formRef = useFormFocusFieldRef(initialFocusField, [props.initialValues.id, props.initialFocusField]);

    const onFocusChange = (props) => {
      const {active} = props;
      if (setInitialFocusField && active) setInitialFocusField(active);
    };
    return (
      <>
        <Dialog
          open={isOpen}
          onClose={(event, reason) => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
          maxWidth="lg"
          fullWidth
          scroll="paper"
        >
          <FinalForm onSubmit={onSubmit} {...rest}>
            {(props) => {
              const {handleSubmit, form, submitting, pristine = true, values, hasValidationErrors} = props;
              formPropsRef.current = props;

              return (
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={formRef}>
                  <ActiveFieldsFormSpy onChange={onFocusChange} />
                  <DialogTitle id="quick-edit-form">
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        {!isNew && <StatusLabel status={values.status_display} />}{" "}
                        {isNew ? "Create Submittal Package" : "Update Submittal Package"}
                      </Grid>
                      {!isNew && (
                        <Grid item>
                          <PrevNext
                            items={submittalPackages}
                            setActiveItem={setActiveSubmittalPackage}
                            activeItem={activeSubmittalPackage}
                            displayProp="number_display"
                            beforePrevNext={!pristine && beforePrevNext ? () => beforePrevNext(values) : undefined}
                            afterPrevNext={!pristine && afterPrevNext ? () => afterPrevNext() : undefined}
                            disabled={hasValidationErrors && !pristine}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    {/* <BeforeUnload block={!pristine} /> */}
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <SubmittalPackageCommonFields
                        values={values}
                        isReadOnly={isReadOnly}
                        isNew={isNew}
                        projectId={projectId}
                      />
                    </FinalFormKeyboardShortcuts>
                    {!isNew && (
                      <SuperuserInfo
                        objects={[
                          {
                            contentType: "submittals",
                            model: "submittalpackage",
                            id: values.id,
                            title: values.display,
                          },
                        ]}
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    {/* {!pristine && <Chip color="secondary" label="Unsaved Changes!" />} */}
                    <FormActions.SaveButton
                      label="Save"
                      disabled={pristine || isReadOnly}
                      onClick={() => form.change("submitMode", "saveAndClose")}
                    />
                    {isNew && (
                      <FormActions.SaveAndEditButton
                        disabled={submitting || pristine}
                        onClick={() => form.change("submitMode", "editAfterSubmit")}
                      />
                    )}
                    {isNew && (
                      <FormActions.SaveAndAddButton
                        disabled={pristine || isReadOnly}
                        onClick={() => form.change("submitMode", "addAnother")}
                      />
                    )}
                    <FormActions.CancelButton
                      onClick={() => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
                    />
                  </DialogActions>
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const SubmittalPackageCustomDataForm = (props) => {
  const {onSubmit, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {({handleSubmit, form, submitting, pristine = true, values}) => (
        <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
          <BeforeUnload block={!pristine} />
          <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldMui
                  name="custom_data.sharepoint_link"
                  label="Sharepoint Link"
                  helperText="SharePoint link for BioMarin Projects"
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldMui name="custom_data.fusion_live_link" label="FusionLive link" />
              </Grid>
              <Grid item xs={12}>
                <FormActions.SaveButton disabled={submitting || pristine} />
              </Grid>
            </Grid>
          </FinalFormKeyboardShortcuts>
        </form>
      )}
    </FinalForm>
  );
};

export const LinkSubmittalItemsDialog = (props) => {
  const {onSubmit, handleClose, isOpen, projectId, packageId, refetchPackages, ...rest} = props;

  const [linkItemsConfirmationIsOpen, setLinkItemsConfirmationIsOpen] = React.useState(false);

  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const {query: linkableItemsQuery, rpc: linkableItemsRPC} = useSentinelListAPI(
    `projects/${projectId}/submittals/packages/${packageId}/linkable-items/`,
    {
      initialData: [],
      keepPreviousData: true,
    }
  );

  const linkableItems = linkableItemsQuery.data;

  const allItemIds = linkableItems.map((item) => item.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  const linkSubmittalItems = (itemsIds) => {
    blockUI.blockUI("Saving...");

    axiosAPI.post(`projects/${projectId}/submittals/packages/${packageId}/linkable-items/`, itemsIds).then(() => {
      removeAllSelectedItemIds();
      handleClose();
      Promise.all([refetchPackages(), linkableItemsQuery.refetch()]).then(() => blockUI.unblockUI());
    });
  };

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          removeAllSelectedItemIds();
          handleClose();
        }}
        maxWidth="lg"
        fullWidth
        scroll="paper"
      >
        <FinalForm onSubmit={linkSubmittalItems} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form
                onSubmit={handleSubmit}
                noValidate={true}
                autoComplete="off"
                style={{
                  overflowY: "auto",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <DialogTitle id="form-dialog-title">
                  <Box display="flex" justifyContent="space-between">
                    <Box>Linkable Submittal Items</Box>
                    <Box>
                      <PaperPanel.Header.RefreshButton
                        onClick={() => linkableItemsQuery.refetch()}
                        isFetching={linkableItemsQuery.isFetching}
                      />
                    </Box>
                  </Box>
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Box mb={3} />

                    <ReactTableMui
                      size="small"
                      className={classnames(
                        novoClasses.stripedTable,
                        novoClasses.smallTable,
                        novoClasses.boldHeaderTable
                      )}
                      columns={[
                        {
                          accessor: "id",
                          disableSortBy: true,
                          Cell: ({value, row}) => (
                            <ReactTableMuiCheckboxSelector
                              key={row.original.id}
                              id={row.original.id}
                              selected={selectedItemIds.has(row.original.id)}
                              onAddSelected={addSelectedItemId}
                              onRemoveSelected={removeSelectedItemId}
                              onClick={(event) => event.preventDefault()}
                            />
                          ),
                        },
                        {
                          Header: "Spec Section Code",
                          accessor: "spec_section_code",
                          Cell: ({value, row}) => {
                            return Boolean(value) ? value : "No Spec Section";
                          },
                        },
                        {
                          Header: "Status",
                          accessor: "status_display",
                          Cell: ({value, row}) => <StatusIcon status={value} type="submittal" showTooltip />,
                        },
                        {
                          Header: "Name",
                          accessor: "display",
                          Cell: ({value, row}) => (
                            <MuiNavLink href={row.original.url} underline="always" target="_blank">
                              {value}
                            </MuiNavLink>
                          ),
                        },
                      ]}
                      initialState={{
                        sortBy: [
                          {
                            id: "spec_section_code",
                            asc: true,
                          },
                        ],
                      }}
                      data={linkableItems}
                      getRowProps={(row) => {
                        return {
                          onClick: () => {
                            selectedItemIds.has(row.original.id)
                              ? removeSelectedItemId(row.original.id)
                              : addSelectedItemId(row.original.id);
                          },
                        };
                      }}
                      {...rest}
                    />
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.Right>
                    <Button
                      onClick={() => {
                        setLinkItemsConfirmationIsOpen(true);
                      }}
                      variant="contained"
                      color="primary"
                      disabled={selectedItemIds.size === 0}
                    >
                      Link ({selectedItemIds.size})
                    </Button>
                  </FormActions.Right>
                  <FormActions.CancelButton
                    onClick={() => {
                      removeAllSelectedItemIds();
                      handleClose();
                    }}
                  />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
      <ConfirmationDialog
        isOpen={linkItemsConfirmationIsOpen}
        onDeny={() => setLinkItemsConfirmationIsOpen(false)}
        onApprove={() => {
          const submittalItemIds = Array.from(selectedItemIds);
          linkSubmittalItems(submittalItemIds);
          setLinkItemsConfirmationIsOpen(false);
        }}
      >
        You want to link <strong>{selectedItemIds.size}</strong> selected Submittal Items to this Submittal Package?
      </ConfirmationDialog>
    </>
  );
};
