import {faCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Tooltip} from "@material-ui/core";
import {FormApi} from "final-form";
import {SectionHeader} from "../../pages/projects/ProjectReviews/ProjectReviewForms";
import {colorError, colorSuccess, colorWarning} from "../../theme/colors";
import Typography2 from "../Typography2";

const AutoSubmitSection = (props: {
  readOnly?: boolean;
  form: FormApi<any, Partial<any>>;
  title?: React.ReactNode;
  children?: React.ReactNode;
}) => {
  const {readOnly, form, title = "", children, ...rest} = props;
  const {dirty, pristine, hasValidationErrors} = form.getState();

  return (
    <SectionHeader mx={2} mt={-2} {...rest}>
      <Box>{title}</Box>

      {!readOnly && (
        <Box display="flex" flexWrap={{xs: "wrap", sm: "noWrap"}}>
          <Box component="span" mr={1}>
            {children}
          </Box>
          <Tooltip
            title={
              hasValidationErrors ? (
                <>
                  <div>Please Correct All Errors</div>
                  <div>(click to show)</div>
                </>
              ) : dirty ? (
                "Unsaved Changes"
              ) : (
                "Up To Date"
              )
            }
          >
            <Box
              onClick={() => {
                form.submit();
              }}
              style={{cursor: "pointer"}}
              component="span"
            >
              {hasValidationErrors && !pristine && <Typography2 type="metadata">(Unsaved Changes!) </Typography2>}
              <FontAwesomeIcon
                icon={faCircle}
                color={hasValidationErrors ? colorError : dirty ? colorWarning : colorSuccess}
              />
            </Box>
          </Tooltip>
        </Box>
      )}
    </SectionHeader>
  );
};

AutoSubmitSection.Action = (props) => {
  const {children, ...rest} = props;
  return (
    <Box component="span" display="flex" justifyContent="space-evenly" {...rest}>
      {children}
    </Box>
  );
};

export {AutoSubmitSection};
