import {faClock, faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {FilterDate} from "../../../components/Filters";
import BlockUI from "../../../components/GlobalLoaders";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";

const TimecardsForEmployee = (props) => {
  const [startDate, setStartDate] = React.useState(moment().subtract(180, "d").format("YYYY-MM-DD"));
  const [endDate, setEndDate] = React.useState(moment().format("YYYY-MM-DD"));
  const novoClasses = makeNovoClasses();

  const currentPageQuery = useFetchCurrentPage(
    {
      initialData: {
        employee: {},
        timecards: [],
      },
    },
    {
      start_date: startDate,
      end_date: endDate,
    }
  );

  const employee = currentPageQuery.data.employee;
  const timecards = currentPageQuery.data.timecards;

  if (!currentPageQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching timecards..." />;
  }
  return (
    <>
      <Helmet title={`${employee.full_name} - Timecards `} />

      <Breadcrumbs>
        <Link color="inherit" href="/v2/">
          Dashboard
        </Link>
        <Typography>Accounting Tools</Typography>
        <Typography color="textPrimary">{`${employee.full_name} - Timecards `}</Typography>
      </Breadcrumbs>
      <Box mb={2} />
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <FilterDate
            label="Start Date"
            value={startDate}
            required
            onChange={(date) => {
              setStartDate(date);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FilterDate
            label="End Date"
            value={endDate}
            required
            onChange={(date) => {
              setEndDate(date);
            }}
          />
        </Grid>
      </Grid>
      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faClock} /> {`${employee.full_name} - Timecards `}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={currentPageQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    timecards.map((timecard) => {
                      return {
                        Date: timecard.date,
                        Job: timecard.jobDisplay,
                        "Cost Code": timecard.costCode,
                        Hours: timecard.hours,
                      };
                    }),
                    `${employee.full_name} - Timecards`,
                    [{wch: 15}, {wch: 60}, {wch: 15}, {wch: 10}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => currentPageQuery.refetch()}
                isFetching={currentPageQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            hover
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}
            rightAlignColumns={["amount_paid", "retainage_held", "retainage_paid", "date_stamp"]}
            columns={[
              {
                Header: "Date",
                accessor: "date",
              },
              {
                Header: "Job",
                accessor: "jobDisplay",
              },
              {
                Header: "Cost Code",
                accessor: "costCode",
              },
              {
                Header: "Hours",
                accessor: "hours",
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "date",
                  desc: true,
                },
              ],
            }}
            data={timecards}
          />
          {/* <pre>{JSON.stringify(currentPageQuery, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default TimecardsForEmployee;
