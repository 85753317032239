// misc formatters
import {unslugify} from "../utils/string";

export function getOpenClosedShopIcon(is_union) {
  if (is_union) {
    return `<span rel="tooltip" title="Union"><i class="fa fa-check-circle"></i><span>`;
  }
  return `<span rel="tooltip" title="Not Union"><i class="fa fa-circle"></i><span>`;
}

//title="${statusDisplay}"></i>`;
// status formatters
export function getProjectStatusIcon(status) {
  if (!status) {
    status = "";
  }
  if (["unstarted"].includes(status.toLowerCase())) {
    return `<i class="fa fa-clock status-color-blue" title="${status}"></i>`;
  }
  if (["complete", "closed"].includes(status.toLowerCase())) {
    return `<i class="fa fa-check status-color-dark-red" title="${status}"></i>`;
  }
  if (["sentinel deleted"].includes(status.toLowerCase())) {
    return `<i class="fa fa-exclamation-circle status-color-dark-red" title="${status}"></i>`;
  }
  if (["in progress"].includes(status.toLowerCase())) {
    return `<i class="fa fa-cogs status-color-green" title="${status}"></i>`;
  }
  if (["submitted"].includes(status.toLowerCase())) {
    return `<i class="fa fa-share" title="${status}"></i>`;
  }
  if (["submitted for closeout"].includes(status.toLowerCase())) {
    return `<i class="fa fa-share status-color-dark-red" title="${status}"></i>`;
  }
  return `<i class="fa fa-question-circle text-muted" title="${status}"></i>`;
}

export function getTimberlineStatusIcon(status) {
  if (!status) {
    status = "";
  }
  if (["unstarted"].includes(status.toLowerCase())) {
    return `<i class="fa fa-clock status-color-blue" title="${status}"></i>`;
  }
  if (["closed"].includes(status.toLowerCase())) {
    return `<i class="fa fa-check status-color-dark-red" title="${status}"></i>`;
  }
  if (["in progress"].includes(status.toLowerCase())) {
    return `<i class="fa fa-cogs status-color-green" title="${status}"></i>`;
  }
  return `<i class="fa fa-question-circle text-muted" title="${status}"></i>`;
}

export function getStatusIcon(status) {
  if (!status) {
    status = "";
  }
  let statusDisplay = unslugify(status);
  if (["unstarted"].includes(status.toLowerCase())) {
    return `<i class="fa fa-clock" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["pending"].includes(status.toLowerCase())) {
    return `<i class="fa fa-clock status-color-blue" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["pending_approval"].includes(status.toLowerCase())) {
    return `<i class="fa fa-clock status-color-orange" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["pending_work_on_hold"].includes(status.toLowerCase())) {
    return `<i class="fa fa-clock status-color-red" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (status.toLowerCase().startsWith("pending")) {
    return `<i class="fa fa-clock" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["pending_work_on_hold"].includes(status.toLowerCase())) {
    return `<i class="fa fa-clock status-color-red" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["submitted"].includes(status.toLowerCase())) {
    return `<i class="fa fa-share" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["in_review"].includes(status.toLowerCase())) {
    return `<i class="fa fa-history" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["revised"].includes(status.toLowerCase())) {
    return `<i class="fa fa-exclamation-circle status-color-yellow" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["approved"].includes(status.toLowerCase())) {
    return `<i class="fa fa-thumbs-up status-color-green" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["approved_as_noted"].includes(status.toLowerCase())) {
    return `<i class="far fa-thumbs-up status-color-green" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["complete"].includes(status.toLowerCase())) {
    return `<i class="fa fa-check-circle status-color-green" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["review_complete"].includes(status.toLowerCase())) {
    return `<i class="far fa-check status-color-green" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["revise_resubmit"].includes(status.toLowerCase())) {
    return `<i class="far fa-thumbs-down status-color-red" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["rejected"].includes(status.toLowerCase())) {
    return `<i class="fa fa-thumbs-down status-color-red" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["cancelled"].includes(status.toLowerCase())) {
    return `<i class="fa fa-minus-circle status-color-red" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["void"].includes(status.toLowerCase())) {
    return `<i class="fa fa-times-circle status-color-red" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["closed"].includes(status.toLowerCase())) {
    return `<i class="fa fa-times-circle muted" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["zero_contract"].includes(status.toLowerCase())) {
    return `<i class="far fa-circle-o muted" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["external_response"].includes(status.toLowerCase())) {
    return `<i class="fa fa-reply status-color-yellow" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["partial_response"].includes(status.toLowerCase())) {
    return `<i class="fa fa-reply status-color-blue" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["unlocked"].includes(status.toLowerCase())) {
    return `<i class="fa fa-unlock" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  if (["for_record_only"].includes(status.toLowerCase())) {
    return `<i class="fa fa-folder text-muted" rel="tooltip" title="${statusDisplay}"></i>`;
  }
  return `<i class="fa fa-question-circle text-muted" title="${statusDisplay}"></i>`;
}
