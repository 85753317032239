import classnames from "classnames";
import PropTypes from "prop-types";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {getStatusIcon} from "../common/formatters";

export const TooltipIcon = (props) => {
  const {className, tooltip, placement, ...rest} = props;
  return (
    <OverlayTrigger placement={placement} overlay={<Tooltip id="tooltip">{tooltip}</Tooltip>} {...rest}>
      <span>
        <i className={classnames(className)} />
      </span>
    </OverlayTrigger>
  );
};

TooltipIcon.propTypes = {
  className: PropTypes.string.isRequired,
  tooltip: PropTypes.string.isRequired,
  placement: PropTypes.string,
};

TooltipIcon.defaultProps = {
  placement: "top",
};

export const StatusIcon = (props) => {
  const {status, ...rest} = props;
  return <span dangerouslySetInnerHTML={{__html: getStatusIcon(status)}} {...rest} />;
};

StatusIcon.propTypes = {
  status: PropTypes.string.isRequired,
};
