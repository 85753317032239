import {faExternalLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import LinkIcon from "@material-ui/icons/Link";
import accounting from "accounting";
import classnames from "classnames";
import qs from "query-string";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import {CompanyAutoselectMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import {PageHeader} from "../../components/PageHeader";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchCurrentPage, usePostCurrentPage, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {FilterOptionChips} from "../../components/FilterOptions";

const LinkHighwireContractorDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Link Highwire Contractor To Sentinel</DialogTitle>
              <DialogContent>
                {values?.name}
                <Box mt={2} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CompanyAutoselectMui autoFocus required name="sentinel_company" label="Sentinel Company" />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </DialogContent>
              <DialogActions>
                <Button
                  startIcon={<LinkIcon />}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={pristine || submitting}
                >
                  Link
                </Button>
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

const HighwireAdminDashboard = (props) => {
  const {...rest} = props;
  const [activeHighwireContractor, setActiveHighwireContractor] = React.useState({});
  const [showLinkHighwireContractorDialogForm, setShowLinkHighwireContractorDialogForm] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(500);
  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "Has Sentinel Company",
  ]);
  const filterParams = {
    q: filterOptions.Search.value,
    has_sentinel_company: filterOptions["Has Sentinel Company"].value,
  };

  const {query: highwireContractorQuery} = useSentinelListAPI(
    `/highwire/contractors/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const highwireContractors = highwireContractorQuery.data.results;

  const pageDataQuery = useFetchCurrentPage(
    {
      initialData: {
        stats: {},
      },
    }
    // {...filterParams, ...paginationParams}
  );
  const pageData = pageDataQuery.data;

  const postCurrentPage = usePostCurrentPage();

  return (
    <>
      <Helmet title="Highwire Admin Dashboard" />
      <Breadcrumbs>
        <Link href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </Link>
        <Typography color="textPrimary">Highwire Admin Dashboard</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left></PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.RightSpark title="Total Count" value={pageData.stats.totalCount} />
          <PageHeader.Right.RightSpark title="Unlinked Count" value={pageData.stats.unlinkedCount} border />
        </PageHeader.Right>
      </PageHeader>
      <Box mt={2} />
      {/* <pre>{JSON.stringify(highwireContractorQuery, null, 2)}</pre> */}
      <PaperPanel>
        <PaperPanel.Header isLoading={highwireContractorQuery.isFetching || pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>Highwire Admin Dashboard</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  highwireContractorQuery.refetch();
                  highwireContractorQuery.refetch();
                }}
                isFetching={highwireContractorQuery.isFetching || pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                label="Has Sentinel Company"
                name="Has Sentinel Company"
                options={[
                  {label: "Yes", value: "true"},
                  {label: "No", value: "false"},
                ]}
                allowNull
                value={filterOptions["Has Sentinel Company"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Has Sentinel Company", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination count={highwireContractorQuery.data.total_pages} page={page} setPage={setPage} />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                Header: "Highwire Contractor",
                accessor: "name",
                sortType: "basic",
                // disableSortBy: true,
                Cell: ({value, row}) => (
                  <>
                    <Link
                      underline="always"
                      style={{cursor: "pointer"}}
                      onClick={() => {
                        setActiveHighwireContractor(row.original);
                        setShowLinkHighwireContractorDialogForm(true);
                      }}
                    >
                      {value}
                    </Link>{" "}
                    <Link underline="always" target="_blank" href={row.original.highwire_url}>
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  </>
                ),
              },
              {
                Header: "Safety Score",
                accessor: "safety_score",
                Cell: ({value, row}) => {
                  if (!value) return null;
                  return (
                    <>
                      <Tooltip title="Out of 100">
                        <span>{accounting.format(value, 2)}</span>
                      </Tooltip>
                    </>
                  );
                },
              },
              {
                Header: "Sentinel Company",
                accessor: "sentinel_company.name",
                sortType: "basic",
                // disableSortBy: true,
                Cell: ({value, row}) => (
                  <>
                    <Link underline="always" target="_blank" href={row.original.sentinel_company?.url}>
                      {value}
                    </Link>
                  </>
                ),
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "name",
                  desc: false,
                },
              ],
            }}
            // data={pageData.highwireContractors}
            data={highwireContractors}
          />
          {/* <pre>{JSON.stringify(pageData.stats, null, 2)}</pre> */}
        </PaperPanel.Body>
        {(highwireContractorQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={highwireContractorQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[10, 100, 250, 500, 1000]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
      <LinkHighwireContractorDialogForm
        isOpen={showLinkHighwireContractorDialogForm}
        handleClose={() => setShowLinkHighwireContractorDialogForm(false)}
        initialValues={activeHighwireContractor}
        onSubmit={(values) => {
          // console.log("onSubmit", values);
          blockUI.blockUI("Linking...");
          postCurrentPage.mutateAsync(values).then((response) => {
            Promise.all([highwireContractorQuery.refetch(), pageDataQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowLinkHighwireContractorDialogForm(false);
            });
          });
        }}
      />
    </>
  );
};

export default HighwireAdminDashboard;
