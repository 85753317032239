import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Link, Tooltip, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import {useSnackbar} from "notistack";
import React from "react";
import {Helmet} from "react-helmet";
import {Metadata} from "../../../../js/components/StyledComponents";
import {Currency} from "../../../components/Accounting";
import {FrozenProjectAlert} from "../../../components/Alerts";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";
import {normalizeCostCode} from "../../../utils/string";

const ProjectBudgetVsJTD = (props) => {
  const {project} = props;

  const novoClasses = makeNovoClasses();
  const {enqueueSnackbar} = useSnackbar();
  const blockUI = useBlockUI();
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      byCostCode: [],
      byDivision: [],
    },
  });
  const pageData = pageDataQuery.data;

  return (
    <>
      <Helmet title={`${project.display} - Budget Vs Job to Date`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/budget/current/`}>
          Current Budget
        </MuiNavLink>
        <Typography color="textPrimary">Budget Vs JTD</Typography>
      </ProjectBreadcrumbs>

      {pageData.frozenDate && (
        <>
          <FrozenProjectAlert frozenDate={pageData.frozenDate} />
          <Box mb={1} />
        </>
      )}

      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>Budget Vs JTD</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    pageData.byCostCode.map((row) => {
                      return {
                        Code: row.budget_code,
                        Allocation: (row.allocation || []).join(", "),
                        Description: row.description ? row.description.join(", ") : row.tl_description,
                        "Original Budget (Sentinel)": {v: row.sentinel_current_budget, t: "n", z: "$#,##0.00"},
                        "Committed Costs (Sentinel)": {v: row.sentinel_current_cost, t: "n", z: "$#,##0.00"},
                        "LMOE Costs (Timberline)": {v: row.lmoe, t: "n", z: "$#,##0.00"},
                        "Total Cost": {v: row.total_cost, t: "n", z: "$#,##0.00"},
                        "Available Budget": {v: row.remaining_budget, t: "n", z: "$#,##0.00"},
                      };
                    }),
                    `${project.display} - Budget Vs JTD`,
                    [{wch: 15}, {wch: 25}, {wch: 60}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={[
              "sentinel_current_budget",
              "sentinel_current_cost",
              "lmoe",
              "total_cost",
              "remaining_budget",
            ]}
            showFooter={Boolean(pageData.byCostCode.length)}
            columns={[
              {
                Header: "Code",
                accessor: "budget_code",
                Cell: ({value, row}) => {
                  if (!row.original.sentinel_id) {
                    return (
                      <Tooltip title="Job Cost Detail">
                        <div>
                          <MuiNavLink
                            href={`/v2/projects/${project.id}/budget/cost-detail/?cost_code=${normalizeCostCode(value)}`}
                            underline={"always"}
                          >
                            {value}
                          </MuiNavLink>
                        </div>
                      </Tooltip>
                    );
                  }
                  return row.original.sentinel_id.map((sentinel_id) => {
                    return (
                      <Tooltip title="Cost Code Detail">
                        <div>
                          <MuiNavLink href={`/v2/projects/${project.id}/budgets/${sentinel_id}/`} underline={"always"}>
                            {value}
                          </MuiNavLink>
                        </div>
                      </Tooltip>
                    );
                  });
                },
              },
              {
                Header: "Allocation",
                accessor: (value) => {
                  return value.allocation ? value.allocation.join(", ") : "";
                },
                Cell: ({value, row}) => {
                  if (!row.original.allocation) {
                    return "";
                  }
                  return row.original.allocation.map((value) => {
                    return <div>{value}</div>;
                  });
                },
              },
              {
                id: "description",
                Header: "Description",
                accessor: (value) => {
                  return value.description ? value.description.join(", ") : value.tl_description;
                },
                Cell: ({value, row}) => {
                  if (typeof row.original.description === "string") {
                    return value;
                  }
                  return row.original.description.map((value) => {
                    return <div>{value}</div>;
                  });
                },
              },
              {
                Header: (
                  <>
                    Current Budget
                    <br />
                    (Sentinel)
                  </>
                ),
                accessor: "sentinel_current_budget",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byCostCode.reduce((sum, row) => row.sentinel_current_budget + sum, 0),
                    [pageData.byCostCode]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: (
                  <>
                    Committed Costs
                    <br />
                    (Sentinel)
                  </>
                ),
                accessor: "sentinel_current_cost",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byCostCode.reduce((sum, row) => row.sentinel_current_cost + sum, 0),
                    [pageData.byCostCode]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: (
                  <>
                    LMOE Costs
                    <br />
                    (Timberline)
                  </>
                ),
                accessor: "lmoe",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byCostCode.reduce((sum, row) => row.lmoe + sum, 0),
                    [pageData.byCostCode]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: (
                  <Tooltip title="Committed Costs (Sentinel) + LMOE Cost (Timberline)">
                    <span>Total Cost</span>
                  </Tooltip>
                ),
                accessor: "total_cost",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byCostCode.reduce((sum, row) => row.total_cost + sum, 0),
                    [pageData.byCostCode]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: (
                  <Tooltip title="Current Budget (Sentinel) - Total Cost">
                    <span>Available Budget</span>
                  </Tooltip>
                ),
                accessor: "remaining_budget",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byCostCode.reduce((sum, row) => row.remaining_budget + sum, 0),
                    [pageData.byCostCode]
                  );
                  return <Currency number={total} />;
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "budget_code",
                },
              ],
            }}
            data={pageData.byCostCode}
          />
          {pageData.budgetRecalculatedDate && (
            <Box p={1}>
              <Metadata>Budget last calculated on: {moment(pageData.budgetRecalculatedDate).format("LLLL")}</Metadata>
            </Box>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={1} />
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>By Division</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    pageData.byDivision.map((row) => {
                      return {
                        Division: row.division_code,
                        "Original Budget (Sentinel)": {v: row.sentinel_current_budget, t: "n", z: "$#,##0.00"},
                        "Committed Costs (Sentinel)": {v: row.sentinel_current_cost, t: "n", z: "$#,##0.00"},
                        "LMOE Costs (Timberline)": {v: row.lmoe, t: "n", z: "$#,##0.00"},
                        "Total Cost": {v: row.total_cost, t: "n", z: "$#,##0.00"},
                        "Available Budget": {v: row.remaining_budget, t: "n", z: "$#,##0.00"},
                      };
                    }),
                    `${project.display} - Budget Vs JTD`,
                    [{wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={[
              "sentinel_current_budget",
              "sentinel_current_cost",
              "lmoe",
              "total_cost",
              "remaining_budget",
            ]}
            showFooter={Boolean(pageData.byCostCode.length)}
            columns={[
              {
                Header: "Division",
                accessor: "division_code",
              },
              {
                Header: (
                  <>
                    Current Budget
                    <br />
                    (Sentinel)
                  </>
                ),
                accessor: "sentinel_current_budget",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byDivision.reduce((sum, row) => row.sentinel_current_budget + sum, 0),
                    [pageData.byDivision]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: (
                  <>
                    Committed Costs
                    <br />
                    (Sentinel)
                  </>
                ),
                accessor: "sentinel_current_cost",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byDivision.reduce((sum, row) => row.sentinel_current_cost + sum, 0),
                    [pageData.byDivision]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: (
                  <>
                    LMOE Costs
                    <br />
                    (Timberline)
                  </>
                ),
                accessor: "lmoe",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byDivision.reduce((sum, row) => row.lmoe + sum, 0),
                    [pageData.byDivision]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: (
                  <Tooltip title="Committed Costs (Sentinel) + LMOE Cost (Timberline)">
                    <span>Total Cost</span>
                  </Tooltip>
                ),
                accessor: "total_cost",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byDivision.reduce((sum, row) => row.total_cost + sum, 0),
                    [pageData.byDivision]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: (
                  <Tooltip title="Current Budget (Sentinel) - Total Cost">
                    <span>Available Budget</span>
                  </Tooltip>
                ),
                accessor: "remaining_budget",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.byDivision.reduce((sum, row) => row.remaining_budget + sum, 0),
                    [pageData.byDivision]
                  );
                  return <Currency number={total} />;
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "division_code",
                },
              ],
            }}
            data={pageData.byDivision}
          />
          {pageData.budgetRecalculatedDate && (
            <Box p={1}>
              <Metadata>Budget last calculated on: {moment(pageData.budgetRecalculatedDate).format("LLLL")}</Metadata>
            </Box>
          )}
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};
export default ProjectBudgetVsJTD;
