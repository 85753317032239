import {Box, Dialog, DialogContent, DialogTitle, Grid, makeStyles, Typography} from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import {Editor as TinyMCEEditor} from "tinymce";
import HtmlRender from "../../../../js/components/HtmlRender";
import useBlockUI from "../../../hooks/useBlockUI";
import {TinyMCEMui} from "./TextFields";

const fakeAPIEndpoint = (inputString) => {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      const response = `\n<ul>\n<li>Hello</li>\n<li>World</li>\n</ul>\n`;
      resolve(response);
    }, 1000);
  });
};

const colorHover = "#fffaf0";
const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    choice: {
      paddingBottom: `3px`,
      height: "auto",
      "&:hover": {
        backgroundColor: colorHover,
        boxShadow: `rgb(0 0 0 / 10%) 0px 2px 1px -1px, rgb(0 0 0 / 7%) 0px 1px 1px 0px, rgb(0 0 0 / 6%) 0px 1px 3px 0px;`,
        cursor: "pointer",
      },
    },
  };
});

export const AITinyMCEMui = (props: {name: string; label: string; disabled?: boolean; [rest: string]: any}) => {
  const [confirmationDialogIsOpen, setConfirmationDialogIsOpen] = React.useState(false);
  const [originalContent, setOriginalContent] = React.useState("");
  const [suggestedContent, setSuggestedContent] = React.useState(undefined as any);
  const blockUI = useBlockUI();
  const classes = useStyles();
  const choiceClasses = classes.choice;
  const mceEditorRef = React.useRef(undefined as TinyMCEEditor);
  const {...rest} = props;

  return (
    <>
      <TinyMCEMui
        editorConfig={{
          setup: (editor: TinyMCEEditor) => {
            editor.ui.registry.addIcon(
              "magicWand",
              `<svg height="24" width="24" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --><path d="M234.7 42.7L197 56.8c-3 1.1-5 4-5 7.2s2 6.1 5 7.2l37.7 14.1L248.8 123c1.1 3 4 5 7.2 5s6.1-2 7.2-5l14.1-37.7L315 71.2c3-1.1 5-4 5-7.2s-2-6.1-5-7.2L277.3 42.7 263.2 5c-1.1-3-4-5-7.2-5s-6.1 2-7.2 5L234.7 42.7zM46.1 395.4c-18.7 18.7-18.7 49.1 0 67.9l34.6 34.6c18.7 18.7 49.1 18.7 67.9 0L529.9 116.5c18.7-18.7 18.7-49.1 0-67.9L495.3 14.1c-18.7-18.7-49.1-18.7-67.9 0L46.1 395.4zM484.6 82.6l-105 105-23.3-23.3 105-105 23.3 23.3zM7.5 117.2C3 118.9 0 123.2 0 128s3 9.1 7.5 10.8L64 160l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L128 160l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L128 96 106.8 39.5C105.1 35 100.8 32 96 32s-9.1 3-10.8 7.5L64 96 7.5 117.2zm352 256c-4.5 1.7-7.5 6-7.5 10.8s3 9.1 7.5 10.8L416 416l21.2 56.5c1.7 4.5 6 7.5 10.8 7.5s9.1-3 10.8-7.5L480 416l56.5-21.2c4.5-1.7 7.5-6 7.5-10.8s-3-9.1-7.5-10.8L480 352l-21.2-56.5c-1.7-4.5-6-7.5-10.8-7.5s-9.1 3-10.8 7.5L416 352l-56.5 21.2z"/></svg>`
            );

            editor.ui.registry.addMenuButton("aiReWrite", {
              icon: "magicwand",
              tooltip: "AI ReWrite",
              fetch: (callback) => {
                const items = [
                  {
                    type: "menuitem",
                    text: "Get AI Suggestions",
                    onAction: () => {
                      blockUI.blockUI("Getting Suggestions...");
                      const original = editor.getContent();
                      setOriginalContent(original);
                      fakeAPIEndpoint(original).then((response) => {
                        setSuggestedContent(response);
                        setConfirmationDialogIsOpen(true);
                        mceEditorRef.current = editor;
                        blockUI.unblockUI();
                      });
                    },
                  },
                ];
                callback(items);
              },
            });
          },

          content_css: "/static/css/tinymceMUI.css",
          toolbar: `aiReWrite | undo redo | bold italic underline strikethrough forecolor | subscript superscript | bullist numlist outdent indent | table | hr | pastetext code fullscreen`,
        }}
        {...rest}
      />

      <Dialog
        open={confirmationDialogIsOpen}
        onClose={(event, reason) => {
          if (["escapeKeyDown", "backdropClick"].includes(reason)) {
            setConfirmationDialogIsOpen(false);
          }
        }}
        maxWidth="sm"
      >
        <DialogTitle>Use Suggested Changes?</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Do you want to accept the following suggested changes?
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                textAlign="center"
                className={classNames(choiceClasses)}
                onClick={() => {
                  setConfirmationDialogIsOpen(false);
                }}
              >
                <Typography variant="h4">Original</Typography>
                <HtmlRender html={originalContent} />
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box
                textAlign="center"
                className={classNames(choiceClasses)}
                onClick={() => {
                  const editor = mceEditorRef?.current;
                  if (editor) {
                    editor.execCommand("mceSetContent", false, suggestedContent);
                  }

                  setConfirmationDialogIsOpen(false);
                }}
              >
                <Typography variant="h4">New</Typography>
                <HtmlRender html={suggestedContent} />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
