import axios from "axios";

const getFileNameFromContentDisposition = (contentDisposition) => {
  const match = contentDisposition.match(/filename="(.*?)"/);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};

const downloadFile = (url: string) =>
  axios({
    method: "get",
    url: url,
    responseType: "blob",
  })
    .then((response) => {
      const blob = new Blob([response.data], {type: response.data.type});

      const contentDisposition = response.headers["content-disposition"];
      let fileName = "unknown";

      if (contentDisposition) {
        const extractedFileName = getFileNameFromContentDisposition(contentDisposition);
        if (extractedFileName) {
          fileName = extractedFileName;
        }
      }

      const link = document.createElement("a");

      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;

      document.body.appendChild(link);
      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    })
    .catch((error) => {
      console.error(`Error downloading file: ${error}`);
    });

export default downloadFile;
