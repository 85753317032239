import {Box, Toolbar} from "@material-ui/core";
import {getHeader} from "@mui-treasury/layout";
import styled from "styled-components";
import Announcements from "../components/Announcements";
import {HeaderLogo} from "../components/Header";
import StagingBanner from "../components/StagingBanner";

const HeaderTUI = getHeader(styled);

const UnauthenticatedLayout = (props) => {
  const {isStaging, children} = props;
  return (
    <>
      <HeaderTUI elevation={4} color="primary">
        <Toolbar>
          <HeaderLogo />
        </Toolbar>
      </HeaderTUI>
      <Box pl={3} pr={3}>
        {isStaging && <StagingBanner />}
        <Announcements />
        {children}
      </Box>
    </>
  );
};

export default UnauthenticatedLayout;
