import {Box, FormControl, InputLabel, MenuItem, Select} from "@material-ui/core";
import {Pagination as PaginationMui} from "@material-ui/lab";
import NumberFormat from "react-number-format";

export const Pagination = (props) => {
  if (!props?.count || props?.count === 1) {
    return null;
  }
  const {setPage, ...rest} = props;
  return (
    <Box display="flex">
      <PaginationMui
        variant="outlined"
        shape="rounded"
        size="small"
        onChange={(event, value) => setPage(value)}
        {...rest}
      />
    </Box>
  );
};

export const PageSize = (props) => {
  const {onChange, pageSizes = [50, 100, 250, 500], pageSize, ...rest} = props;
  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel id="page_size">Page Size</InputLabel>
      <Select label="page_size" labelId="page_size" onChange={(event) => onChange(event.target.value)} value={pageSize}>
        {pageSizes.map((pageSize) => {
          return (
            <MenuItem key={pageSize} value={pageSize} dense selected={pageSize === pageSize}>
              <NumberFormat thousandSeparator={true} displayType="text" value={pageSize} />
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};
export const PaginationWithPageSize = (props: {
  count: number;
  page: number;
  pageSize: number;
  setPage: (page: number) => void;
  setPageSize: (page: number) => void;
  pageSizes?: number[];
  [rest: string]: any;
}) => {
  const {count, page, setPage, pageSize, setPageSize, pageSizes, ...rest} = props;
  return (
    <Box display="flex" justifyContent="flex-end" alignItems="center" {...rest}>
      <Box>
        <Pagination count={count} page={page} setPage={setPage} />
      </Box>
      <Box ml={1} minWidth={80}>
        <PageSize
          pageSize={pageSize}
          onChange={(pageSize) => {
            setPage(1);
            setPageSize(pageSize);
          }}
          pageSizes={pageSizes}
        />
      </Box>
    </Box>
  );
};
