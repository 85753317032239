import {faExternalLink, faLink, faLinkSlash} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import {useSnackbar} from "notistack";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {ProjectStatusIcon} from "../../../components/Icons";
import {LegacyUILink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage, useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {ProjectLinksForm} from "./ProjectLinkForms";

const ProjectLinks = (props) => {
  const {project: initialProject} = props;
  const {projectId} = useParams();
  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();
  const {enqueueSnackbar} = useSnackbar();
  const postCurrentPage = usePostCurrentPage();

  const {query: projectQuery, update: updateProject} = useSentinelDetailAPI(["projects", parseInt(projectId)], {
    initialData: initialProject,
  });
  const project = projectQuery.data;

  const pageDataQuery = useFetchCurrentPage({
    initialData: {
      contacts: [],
      planGridProjectOptions: [],
      allowLinkPlangrid: false,
      sentinelUserOnFieldwireProject: false,
    },
  });

  if (!pageDataQuery.isFetchedAfterMount || !projectQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching project links..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Links`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Links</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faLink} /> Project Links
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <Typography variant="h4"> Timberline</Typography>
          {project.timberline_job ? (
            <>
              <ProjectStatusIcon project={project} showTooltip />{" "}
              {`${project.timberline_job} - ${project.timberline_job_description}`}
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faLinkSlash} /> This project is not linked to Timberline
            </>
          )}
          <Box mb={2} />

          <ProjectLinksForm
            planGridProjectOptions={pageDataQuery.data.planGridProjectOptions}
            onSubmit={(values) => {
              updateProject.mutateAsync(values);
            }}
            allowLinkPlangrid={pageDataQuery.data.allowLinkPlangrid}
            initialValues={project}
            sentinelUserOnFieldwireProject={pageDataQuery.data.sentinelUserOnFieldwireProject}
            onLinkFieldwireProject={() => {
              blockUI.blockUI("Linking Fieldwire project");
              postCurrentPage
                .mutateAsync({action: "link_fieldwire_projects"})
                .then((response) => {
                  enqueueSnackbar(response.message, {
                    variant: "success",
                  });
                  window.location.reload();
                })
                .catch(() => blockUI.unblockUI());
            }}
          />
          {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={2} />

      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faLink} /> External Dashboard Links
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                Header: "Contact",
                accessor: "name",
                Cell: ({value, row}) => {
                  return (
                    <Link href={row.original.external_link} target="_blank" underline="always">
                      {value} <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  );
                },
              },
              {
                Header: "Company",
                accessor: "company",
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "company",
                  desc: false,
                },
                {
                  id: "name",
                  desc: false,
                },
              ],
            }}
            data={pageDataQuery.data.contacts}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/links/`} mt={2} />
    </>
  );
};
export default ProjectLinks;
