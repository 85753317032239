import {faNote} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  MenuItem as MenuItemMui,
  Tooltip,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import HtmlRender from "../../../../js/components/HtmlRender";
import AutoSaveDebounce from "../../../../js/components/ReactFinalForm/AutoSaveDebounce";
import submittalItemReviewStatuses from "../../../components/forms/choices/submittalItemReviewStatuses.json";
import {ApiSelectMui, DatePickerMui, SelectStatus, TinyMCEMui} from "../../../components/forms/Fields";
import {beforeDialogClose} from "../../../components/forms/Form";
import FormActions from "../../../components/forms/FormActions";
import {StatusIcon} from "../../../components/Icons";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import {SuperuserInfo} from "../../../components/SuperuserInfo";
import Typography2 from "../../../components/Typography2";

export const SubmittalReviewCompanyStampSelect = (props: {
  companyId: number;
  baseURL?: string;
  [rest: string]: any;
}) => {
  const {companyId, baseURL = `companies/${companyId}/submittals/stamp/`, ...rest} = props;

  return (
    <ApiSelectMui
      name="company_submittal_stamp_choice.id"
      label="Company Stamp"
      baseURL={baseURL}
      optionsProp="choices"
      allowNull
      {...rest}
    >
      {(option) => (
        <MenuItemMui dense key={option.id} value={option.id}>
          <Box alignItems="center">
            <StatusIcon status={option.status} type="submittal" />
            <Box display="inline" ml={1} />
            {option.description}
            {option.additional_description && (
              <Box>
                <Typography2 type="metadata">{option.additional_description}</Typography2>
              </Box>
            )}
          </Box>
        </MenuItemMui>
      )}
    </ApiSelectMui>
  );
};

export const SubmittalItemReviewStatusSelect = (props) => {
  return (
    <SelectStatus name="status" label="Status" options={submittalItemReviewStatuses} type="submittal" {...props} />
  );
};

export const SubmittalItemReviewBulkStatusSelect = (props) => {
  const options = submittalItemReviewStatuses.filter((status) =>
    ["approved", "approved_as_noted", "revise_resubmit", "rejected", "for_record_only"].includes(status.value)
  );
  return <SelectStatus name="status" label="Status" options={options} type="submittal" {...props} />;
};

export const SubmittalItemReviewPaperItemForm = (props) => {
  const {
    isReadOnly,
    onSave,
    addSelectedReviewId,
    removeSelectedReviewId,
    selectedReviewIds,
    setShowSubmittalItemReviewUpdateDialogForm,
    setActiveReview,
    review,
    packageId,
    showNotesInline,
    onCreateTransmittal,
    ...rest
  } = props;

  //   const initialValues = review;
  const hasCustomStamp = review.company_submittal_stamp !== null;

  return (
    <FinalForm key={review.id} initialValues={review} onSubmit={(values) => {}}>
      {({handleSubmit, form, submitting, pristine, values}) => {
        return (
          <form
            autoComplete="off"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <PaperItem pt={1}>
              <PaperItem.Left>
                <PaperItem.SelectedCheckbox
                  checked={selectedReviewIds.has(review.id)}
                  onChange={(e, value) => {
                    if (value) {
                      addSelectedReviewId(review.id);
                    } else {
                      removeSelectedReviewId(review.id);
                    }
                  }}
                />
                <AutoSaveDebounce
                  debounce={0}
                  save={(newValue, oldValues) => {
                    // console.log("New Value for debounce", newValue, oldValues);
                    if (isReadOnly) {
                      return;
                    }
                    // only save is something has actually changed
                    if (
                      Object.keys(newValue).includes("status") &&
                      newValue.status !== oldValues.status &&
                      !oldValues.company_submittal_stamp_choice?.id
                    ) {
                      onSave(newValue, values);
                      return;
                    }
                    if (
                      Object.keys(newValue).includes("company_submittal_stamp_choice") &&
                      newValue.company_submittal_stamp_choice?.id !== oldValues.company_submittal_stamp_choice?.id
                    ) {
                      // Set status back to pending if stamp is changed to null
                      if (newValue.company_submittal_stamp_choice === null) {
                        newValue["status"] = "pending";
                        newValue["status_display"] = "Pending";
                      }
                      onSave(newValue, values);
                      return;
                    }
                    if (
                      Object.keys(newValue).includes("returned_date") &&
                      moment(newValue.returned_date).format("YYYY-MM-DD") !==
                        moment(oldValues.returned_date).format("YYYY-MM-DD")
                    ) {
                      onSave(newValue, values);
                      return;
                    }
                  }}
                />
              </PaperItem.Left>
              <PaperItem.Body>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <Box>
                      {review.contact.full_name} -{" "}
                      <MuiNavLink href={review.contact.company.url}>
                        <Typography2 type="metadata">{review.contact.company.name}</Typography2>
                      </MuiNavLink>{" "}
                      {review?.reviewer_notes && !showNotesInline && (
                        <Tooltip title={review?.reviewer_notes.replace(/(<([^>]+)>)/gi, "")}>
                          <span>
                            <FontAwesomeIcon icon={faNote} size="sm" />
                          </span>
                        </Tooltip>
                      )}
                    </Box>
                    {/* <Typography2 type="metadata">Last Transmittal: </Typography2> */}
                    <Link
                      // href={`/submittals/transmittals/${packageId}/create/?to_contact=${review.contact.id}`}
                      // underline="always"
                      style={{cursor: "pointer"}}
                      onClick={(e) => onCreateTransmittal(review)}
                    >
                      <Typography2 type="metadata">Create Transmittal</Typography2>
                    </Link>
                  </Grid>

                  <Grid item xs={12} md={3}>
                    {hasCustomStamp ? (
                      <>
                        <SubmittalReviewCompanyStampSelect
                          name="company_submittal_stamp_choice.id"
                          label="Company Stamp"
                          helperText={`Last Changed: ${
                            review.status_changed ? moment(review.status_changed).format("dddd, MMMM Do YYYY") : "Never"
                          }`}
                          companyId={review.company_submittal_stamp.company}
                          disabled={isReadOnly}
                          {...rest}
                        />
                        {/* <SubmittalItemReviewCompanyStampAutoselect
                          value={review.company_submittal_stamp_choice ?? null}
                          name="company_submittal_stamp_choice"
                          label="Company Stamp"
                          helperText={`Last Changed: ${
                            review.status_changed ? moment(review.status_changed).format("dddd, MMMM Do YYYY") : "Never"
                          }`}
                          companyId={review.company_submittal_stamp.company}
                          {...rest}
                        /> */}
                        {/* <pre>{JSON.stringify(review, null, 2)}</pre> */}
                        {/* <pre>{JSON.stringify(review.company_submittal_stamp_choice, null, 2)}</pre> */}
                      </>
                    ) : (
                      <SubmittalItemReviewStatusSelect
                        name="status"
                        label="Status"
                        disabled={isReadOnly}
                        helperText={`Last Changed: ${
                          review.status_changed ? moment(review.status_changed).format("dddd, MMMM Do YYYY") : "Never"
                        }`}
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <DatePickerMui
                      name="returned_date"
                      disabled={isReadOnly}
                      label="Returned Date"
                      helperText="Returned to Design Team"
                    />
                  </Grid>
                  {showNotesInline && review?.reviewer_notes && (
                    <Grid item xs={12}>
                      <HtmlRender html={review?.reviewer_notes} />
                      {/* {review?.reviewer_notes.replace(/(<([^>]+)>)/gi, "")} */}
                    </Grid>
                  )}
                  {/* <Grid item xs={6}>
                    <pre>{JSON.stringify(review, null, 2)}</pre>
                  </Grid>
                  <Grid item xs={6}>
                    <pre>{JSON.stringify(values, null, 2)}</pre>
                  </Grid> */}
                </Grid>
              </PaperItem.Body>
              <PaperItem.Right minWidth={40}>
                {/* <FontAwesomeIcon
                  icon={faPenToSquare}
                  onClick={() => {
                    setShowSubmittalItemReviewUpdateDialogForm(true);

                    setActiveReview(review);
                  }}
                /> */}
              </PaperItem.Right>
              <PaperItem.RightHover>
                <PaperItem.RightHover.IconButton
                  icon={EditIcon}
                  title="Quick Edit"
                  disabled={isReadOnly}
                  onClick={() => {
                    setShowSubmittalItemReviewUpdateDialogForm(true);
                    setActiveReview(review);
                  }}
                />
                <Box textAlign="right"></Box>
              </PaperItem.RightHover>
            </PaperItem>
          </form>
        );
      }}
    </FinalForm>
  );
};

export const SubmittalItemReviewUpdateDialogForm = React.forwardRef(
  (
    props: {
      onSubmit: (values: any) => void;
      handleClose: () => void;
      isOpen: boolean;
      isReadOnly?: boolean;
      customStampId?: any;
      review: any;
      [rest: string]: any;
    },
    ref
  ) => {
    const {onSubmit, isOpen, handleClose, isReadOnly, setActiveReview, projectId, review, ...rest} = props;
    const formPropsRef = React.useRef(null);
    // const formRef: React.MutableRefObject<FormApi> = React.useRef(null);
    const hasCustomStamp = review.company_submittal_stamp !== null;
    return (
      <>
        <Dialog
          open={isOpen}
          onClose={(event, reason) => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
          maxWidth="sm"
          fullWidth
          scroll="paper"
        >
          <FinalForm onSubmit={onSubmit} initialValues={review} {...rest}>
            {(props) => {
              const {handleSubmit, form, submitting, pristine = true, values} = props;
              formPropsRef.current = props;

              return (
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                  <DialogTitle id="update-form">
                    {/* <Grid container justifyContent="space-between"> */}
                    <Grid item>Update {values.item_display}</Grid>
                    {/* </Grid> */}
                  </DialogTitle>
                  <DialogContent>
                    <BeforeUnload block={!pristine} />
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {hasCustomStamp ? (
                            <SubmittalReviewCompanyStampSelect
                              name="company_submittal_stamp_choice.id"
                              label="Company Stamp"
                              companyId={review.company_submittal_stamp?.company}
                            />
                          ) : (
                            <SubmittalItemReviewStatusSelect name="status" label="Status" />
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <DatePickerMui
                            name="returned_date"
                            label="Returned Date"
                            helperText="Returned to Design Team"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TinyMCEMui name="reviewer_notes" label="Reviewer Notes" />
                        </Grid>
                      </Grid>
                    </FinalFormKeyboardShortcuts>
                    <SuperuserInfo
                      objects={[
                        {
                          contentType: "submittals",
                          model: "submittalitemreview",
                          id: values.id,
                          title: "Submittal Item Review",
                        },
                      ]}
                    />
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton
                      label="Save"
                      disabled={pristine || isReadOnly}
                      //   onClick={() => form.change("submitMode", "saveAndClose")}
                    />

                    <FormActions.CancelButton
                      onClick={() => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
                    />
                  </DialogActions>
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const SubmittalItemReviewBulkUpdateForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  reviewCount?: number;
  customStamp: number | string;
  [rest: string]: any;
}) => {
  const {
    projectId,
    onSubmit,
    isOpen,
    handleClose,
    isReadOnly = false,
    reviewCount,
    statusOptions,
    customStamp,
    ...rest
  } = props;

  return (
    <>
      <Box display="flex" justifyContent={"center"} mx={"auto"}>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine = true, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="bulk-update-form">
                  Bulk Update {reviewCount} {pluralize("Review", reviewCount)}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {typeof customStamp === "number" ? (
                          <SubmittalReviewCompanyStampSelect
                            name="company_submittal_stamp_choice.id"
                            label="Company Stamp"
                            companyId={customStamp}
                          />
                        ) : (
                          <SubmittalItemReviewStatusSelect
                            name="status"
                            label="Status"
                            disabled={customStamp === "disabled"}
                            helperText={
                              customStamp === "disabled"
                                ? "You have selected reviewers with both a custom stamp and a standard response.  Bulk status update is disabled.  Filter your selection to a single reviewer to enable status bulk update."
                                : ""
                            }
                          />
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <DatePickerMui
                          name="returned_date"
                          label="Returned Date"
                          helperText="Returned to Design Team"
                        />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  {/* <FormActions.DeleteButton onClick={onDelete} disabled={isReadOnly} /> */}
                  <FormActions.SaveButton disabled={submitting || pristine || isReadOnly} label={"Bulk Update"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </Box>
    </>
  );
};

export const SubmittalItemReviewBulkUpdateStatusForm = (props: {
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;

  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, ...rest} = props;

  return (
    <>
      <Box display="flex" justifyContent={"center"} mx={"auto"}>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine = true, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="bulk-update-form">Update status on all pending reviews for this package</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        <SubmittalItemReviewBulkStatusSelect name="status" label="Status" />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  {/* <FormActions.DeleteButton onClick={onDelete} disabled={isReadOnly} /> */}
                  <FormActions.SaveButton disabled={submitting || pristine} label={"Bulk Update"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </Box>
    </>
  );
};
