import {faList} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link} from "@material-ui/core";
import pluralize from "pluralize";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import {TagList} from "../../../components/Tags";
import Typography2 from "../../../components/Typography2";
import PCCOItemPaperItemGrid from "./PCCOItemPaperItemGrid";

export const PCCOItemPaperItem = (props) => {
  const {item, itemIsSelected, onChangeSelected, ...rest} = props;
  const itemCount = item.pcoitem_count;

  return (
    <PaperItem noHover>
      <PaperItem.Left minWidth={45}>
        <PaperItem.SelectedCheckbox checked={itemIsSelected} onChange={onChangeSelected} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container>
          <Grid item container xs={12} md={6}>
            <Grid item xs={12}>
              <StatusLabel status={item.status_display} />{" "}
              <Link href={`${item.url}`} underline="always">
                {item.description}
              </Link>
            </Grid>
            <Grid item container xs={12}>
              <Grid item xs={6} sm={3}>
                <Typography2 type="metadata">
                  <Link href={`/projects/${item.pco.project_id}/pcos/${item.pco.id}/#tab_items`}>
                    <FontAwesomeIcon icon={faList} /> {pluralize(`${itemCount} Item`, itemCount)}
                  </Link>
                </Typography2>
              </Grid>
              <Grid item xs={6} sm={3}>
                {item.pco && (
                  <Typography2 type="metadata">
                    {/* <MuiNavLink underline="always" href={`/v2/projects/${item.pco.project_id}/pcos/${item.pco.id}/`}> */}
                    <Link underline="always" href={item.pco.url}>
                      PCO: #{item.pco.number_display}
                    </Link>
                    {/* </MuiNavLink> */}
                  </Typography2>
                )}
              </Grid>
              <Grid item xs={6} sm={3}></Grid>
              <Grid item xs={6} sm={3}></Grid>
            </Grid>
          </Grid>
          <Grid item container xs={12} md={6}>
            <PCCOItemPaperItemGrid item={item} />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TagList tags={item.tags} />
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={50}>
        <Box textAlign="right">
          <Typography2 noWrap type="metadata">
            {item.number_display}
          </Typography2>
        </Box>
      </PaperItem.Right>
    </PaperItem>
  );
};
