import Announcements from "../components/Announcements";
import Content from "../components/Content";
import Header from "../components/Header";
import SessionExpirationBanner from "../components/SessionExpirationBanner";
import Sidebar from "../components/Sidebar";
import StagingBanner from "../components/StagingBanner";
import {SuperuserInfo} from "../components/SuperuserInfo";
import {AuthenticatedContextProvider} from "../Contexts";
import usePermissions from "../hooks/usePermissions";
import {useSentinelDetailAPI} from "../hooks/useSentinelAPI";
import useUser from "../hooks/useUser";
import {getProjectLayoutRoutes} from "../routes";

const ProjectLayout = (props) => {
  const {children, isStaging, sessionExpireDate, adminObjects = []} = props;
  const user = useUser();
  const permissions = usePermissions();
  // const {projectId} = useParams();

  const {query: projectBadgeQuery} = useSentinelDetailAPI(["projects", parseInt(props.project.id), "counts"], {
    // initialData: {},
    staleTime: 1000 * 60 * 5,
  });
  return (
    <AuthenticatedContextProvider>
      <Header />
      <Sidebar
        routes={getProjectLayoutRoutes(user, permissions, props.waffle, props.project)}
        badgeData={projectBadgeQuery?.data}
      />
      <Content>
        {isStaging && <StagingBanner />}
        <Announcements />
        <SessionExpirationBanner sessionExpireDate={sessionExpireDate} />
        {children}
        <SuperuserInfo
          objects={[
            {
              contentType: "projects",
              model: "project",
              id: props.project.id,
              title: props.project.display,
            },
            ...adminObjects,
          ]}
        />
      </Content>
      {/* <Content toolbar={toolbar}>{children(projectQuery.data)}</Content> */}
    </AuthenticatedContextProvider>
  );
};

export default ProjectLayout;
