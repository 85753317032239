import diff from "object-diff";
import React from "react";
import {FormSpy} from "react-final-form";

class AutoSaveDebounce extends React.Component {
  constructor(props) {
    super(props);
    this.state = {values: props.values, submitting: false};
  }

  componentDidUpdate(nextProps) {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(this.save, this.props.debounce);
  }

  save = async () => {
    if (this.promise) {
      await this.promise;
    }
    const {values, save} = this.props;
    // This diff step is totally optional
    const difference = diff(this.state.values, values);
    // console.log("difference", difference);
    if (Object.keys(difference).length) {
      // values have changed
      this.setState({submitting: true, values});
      this.promise = save(difference, this.state.values);
      await this.promise;
      delete this.promise;
      this.setState({submitting: false});
    }
  };

  render() {
    // This component doesn't have to render anything, but it can render
    // submitting state.
    return this.state.submitting && this.props.children;
  }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
const AutoSaveDebounceWithFormSpy = (props) => (
  <FormSpy {...props} subscription={{values: true}} component={AutoSaveDebounce} />
);
AutoSaveDebounceWithFormSpy.defaultProps = {
  debounce: 1000,
};
export default AutoSaveDebounceWithFormSpy;
