import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Typography} from "@material-ui/core";
import classnames from "classnames";
import {Helmet} from "react-helmet";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import offices from "../../components/forms/choices/offices.json";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchCurrentPage} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {saveExcel} from "../../utils/excel";

const MBESubcontractors = (props) => {
  const novoClasses = makeNovoClasses();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "Office",
  ]);
  const filterParams = {
    q: filterOptions.Search.value,
    markets: filterOptions.Office.value,
  };
  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        companies: [],
      },
    },
    filterParams
  );
  const pageData = pageDataQuery.data;
  return (
    <>
      <Helmet title="Diverse Subcontractors" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Diverse Subcontractors</Typography>
      </Breadcrumbs>
      <Box mt={2} />
      {/* <pre>{JSON.stringify(filterParams, null, 2)}</pre> */}
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>Diverse Subcontractors</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    pageData.companies.map((item) => {
                      return {
                        Company: item.name,
                        "Primary CSI Code": item.primary_csi_code_display,
                        Office: item.markets.map((market) => market.description).join(", "),
                        "Is Union": item.is_union,
                        Diversity: item.company_diversity.map((diversity) => diversity.description).join(", "),
                      };
                    }),
                    `Diverse Subcontractors`,
                    [{wch: 40}, {wch: 40}, {wch: 40}, {wch: 20}, {wch: 80}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={pageDataQuery.isFetching}
                onClick={() => pageDataQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={8} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Office"
                value={filterOptions.Office.value}
                name="Office"
                options={offices}
                onChange={(value, label) => {
                  setFilterOption("Office", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}
            rightAlignColumns={["timestamp"]}
            columns={[
              {
                Header: "Name",
                accessor: "name",
                Cell: ({value, row}) => (
                  <MuiNavLink href={`/v2/companies/${row.original.id}/`} underline="always">
                    {value}
                  </MuiNavLink>
                ),
              },
              {
                Header: "Primary CSI Code",
                accessor: "primary_csi_code_display",
              },
              {
                Header: "Office",
                accessor: (value) => {
                  const markets = value.markets.map((market) => market.description);
                  return markets.join(", ");
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "name",
                  desc: false,
                },
              ],
            }}
            data={pageData.companies}
          />
          {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default MBESubcontractors;
