import {faArrowUpRightFromSquare, faUnlock} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import CloseIcon from "@material-ui/icons/Close";
import moment from "moment";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {PDFButton} from "../../../components/Buttons";
import {DatePickerMui, ProjectContactAutoselectMui, TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {StatusLabel} from "../../../components/Status";

export const PretaskPlanDialogForm = (props: {
  projectId: number;
  userContact: any;
  initialValues: any;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  onUnlockPlan: () => void;
  onReviseResubmit: (values: any) => void;
  canUnlock?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {
    projectId,
    userContact,
    onSubmit,
    isOpen,
    handleClose,
    onUnlockPlan,
    onReviseResubmit,
    canUnlock,
    isReadOnly,
    ...rest
  } = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values, hasValidationErrors}) => {
            if (!values.audited_by && !values.audited_date) {
              form.batch(() => {
                form.change("audited_by", userContact);
                form.change("audited_date", moment().format("YYYY-MM-DD"));
              });
            }
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  <Box alignItems={"center"} display="flex">
                    <StatusLabel status={values.status_display} mr={1} />
                    Pretask Plan: {values.twilio_contact?.name} - {moment(values.date).format("dddd - YYYY-MM-DD")}
                  </Box>
                </DialogTitle>
                <DialogContent>
                  {/* <Box mb={2}>
                    <Link href={`/pretask-plan/${values.uuid}/`} target="_blank">
                      <FontAwesomeIcon icon={faExternalLink} /> External Link
                    </Link>
                  </Box> */}

                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <ProjectContactAutoselectMui
                          projectId={projectId}
                          name="audited_by"
                          label="Audited By"
                          required
                          value={values.audited_by as any}
                          getOptionSelected={(option, value) => {
                            return option.id === value.id;
                          }}
                          disabled={isReadOnly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui label="Audited Date" name="audited_date" required disabled={isReadOnly} />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui label="Comments" name="auditor_comments" disabled={isReadOnly} />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <Tooltip title="Go To Pretask Plan" placement="top">
                    <Button
                      // href={`/pretask-plan/${values.uuid}/`}
                      href={values.url}
                      target="_blank"
                      onClick={() => {
                        handleClose();
                      }}
                      variant="contained"
                      startIcon={<FontAwesomeIcon icon={faArrowUpRightFromSquare} />}
                    >
                      Pretask Plan Link
                    </Button>
                  </Tooltip>

                  {canUnlock && (
                    <Tooltip title="Unlock For Editing by Foreman" placement="top">
                      <Button
                        startIcon={<FontAwesomeIcon icon={faUnlock} />}
                        onClick={onUnlockPlan}
                        variant="contained"
                      >
                        Unlock
                      </Button>
                    </Tooltip>
                  )}
                  <PDFButton href={`/reports2/projects/${projectId}/pretask-plan/${values.id}/`} target="_blank" />
                  <Tooltip
                    title={
                      !values.auditor_comments && values.status === "submitted"
                        ? "Auditor Comments required for Revise Resubmit"
                        : ""
                    }
                    placement="top"
                  >
                    <span>
                      <FormActions.Button
                        onClick={() => {
                          if (hasValidationErrors) {
                            form.submit();
                          } else {
                            onReviseResubmit(values);
                          }
                        }}
                        disabled={submitting || isReadOnly || !values.auditor_comments}
                        label="Revise & Resubmit"
                        startIcon={<CloseIcon />}
                        type="button"
                      />
                    </span>
                  </Tooltip>
                  <FormActions.SaveButton
                    disabled={submitting || isReadOnly}
                    label="Mark Audited"
                    startIcon={<CheckIcon />}
                  />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
