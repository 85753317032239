import {Link, Table, TableBody, TableCell, TableRow} from "@material-ui/core";

const TeamRow = ({title, company, contact}) => {
  return (
    <TableRow>
      <TableCell>
        <strong>{title}</strong>
      </TableCell>
      <TableCell>
        <Link href={company?.url} underline="always">
          {company?.name}
        </Link>
      </TableCell>
      <TableCell>
        {contact && (
          <Link href={contact?.url} underline="always">
            {contact?.full_name}
          </Link>
        )}
      </TableCell>
    </TableRow>
  );
};

const Team = (props) => {
  const {team} = props;
  return (
    <Table size="small" padding="none">
      <TableBody>
        {team.client && <TeamRow title="Client" company={team.client} contact={team.clientContact} />}
        {team.architect && <TeamRow title="Architect" company={team.architect} contact={team.architectContact} />}
        {team.constructionManager && (
          <TeamRow
            title="Construction Manager"
            company={team.constructionManager}
            contact={team.constructionManagerContact}
          />
        )}
        {team.buildingOwner && (
          <TeamRow title="Building Owner" company={team.buildingOwner} contact={team.buildingOwnerContact} />
        )}
      </TableBody>
    </Table>
  );
};

export default Team;
