import * as React from "react";

// NOTE: all modules imported below may be imported from '@silevis/reactgrid'

import {
  Cell,
  CellStyle,
  CellTemplate,
  Compatible,
  HeaderCell,
  Span,
  Uncertain,
  getCellProperty,
} from "@silevis/reactgrid";
import {Tooltip} from "@material-ui/core";

export interface TooltipHeaderCell extends Cell, Span {
  type: "header";
  text: string;
  tooltip?: string;
}

export class TooltipHeaderCellTemplate implements CellTemplate<TooltipHeaderCell> {
  getCompatibleCell(uncertainCell: Uncertain<TooltipHeaderCell>): Compatible<TooltipHeaderCell> {
    const text = getCellProperty(uncertainCell, "text", "string");
    const value = parseFloat(text);
    const tooltip = getCellProperty(uncertainCell, "tooltip", "string");
    return {...uncertainCell, text, value};
  }

  render(
    cell: Compatible<TooltipHeaderCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<TooltipHeaderCell>, commit: boolean) => void
  ): React.ReactNode {
    return cell?.tooltip ? (
      <Tooltip title={cell.tooltip}>
        <span>{cell.text}</span>
      </Tooltip>
    ) : (
      cell.text
    );
  }

  isFocusable = (cell: Compatible<TooltipHeaderCell>): boolean => false;

  getClassName(cell: Compatible<TooltipHeaderCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : "";
  }

  getStyle = (cell: Compatible<TooltipHeaderCell>): CellStyle => ({background: "rgba(128, 128, 128, 0.1)"});
}
