import {Link, makeStyles, Tooltip, Typography} from "@material-ui/core";
import {orange, red} from "@material-ui/core/colors";
import accounting from "accounting";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {Currency} from "../../../../components/Accounting";
import {Breadcrumbs} from "../../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../../components/Dialogs";
import {ProjectStatusIcon} from "../../../../components/Icons";
import {PageHeader} from "../../../../components/PageHeader";
import PaperPanel from "../../../../components/PaperPanel";
import {ReactTableMui} from "../../../../components/ReactTableMui";
import useBlockUI from "../../../../hooks/useBlockUI";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../../theme";
import {DirectCostBatchDialogForm, DirectCostItemDialogForm} from "../DirectCostForms";

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    table: {
      "& .MuiTableCell-body, .MuiTableCell-head": {
        fontSize: "10px",
      },
      "& td, th": {
        padding: "2px 8px 2px 8px",
      },
      "& tr": {
        cursor: "pointer",
      },
      "& tr.closedJob": {
        backgroundColor: orange[50],
      },
      "& tr.noJob": {
        backgroundColor: red[50],
      },
    },
  };
});

const HomeDepotBatchDetail = (props) => {
  const novoClasses = makeNovoClasses();
  const {batchId} = useParams();
  const [page, setPage] = useQueryState("page", 1);
  const [allItemIds, setAllItemIds] = React.useState();
  const [showUpdateItemDialog, setShowUpdateItemDialog] = React.useState(false);
  const [showUpdateBatchDialog, setShowUpdateBatchDialog] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState({} as any);
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const classes = useStyles();

  const pageSize = 10000;
  const batchQueryURL = `accounting-tools/direct-cost-batches/${batchId}/`;
  const itemQueryURL = `accounting-tools/direct-cost-batches/${batchId}/items/?page_size=${pageSize}&page=${page}`;
  const {
    query: batchQuery,
    update: updateBatch,
    delete: deleteBatch,
  } = useSentinelDetailAPI(batchQueryURL, {
    initialData: {},
  });

  const {
    query: itemQuery,
    create: createItem,
    update: updateItem,
    delete: deleteItem,
  } = useSentinelListAPI(itemQueryURL, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });

  const items = itemQuery.data.results;
  const batch = batchQuery.data;

  const missingJobNumbersLength = items.filter((item) => {
    return !item.job;
  }).length;
  const hasMissingJobNumbers = missingJobNumbersLength > 0;
  const closedJobLength = items.filter((item) => {
    return item?.job?.is_closed;
  }).length;
  const hasClosedJobs = closedJobLength > 0;

  React.useEffect(() => {
    setAllItemIds(items.map((item) => item.id));
  }, [itemQueryURL]);
  return (
    <>
      <Helmet title={`Detail Home Depot Upload`} />
      <Breadcrumbs>
        <Link color="inherit" component={NavLink} to="/v2/">
          Dashboard
        </Link>
        <Typography>Accounting Tools</Typography>
        <Typography>Direct Costs Upload</Typography>
        <Link color="inherit" component={NavLink} to="/v2/accounting-tools/direct-cost-batches/home-depot">
          Home Depot Uploads
        </Link>
        <Typography color="textPrimary">Detail</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Detail Home Depot Upload</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={itemQuery.isFetching}>
          <PaperPanel.Header.Title>
            {batchQuery.isFetchedAfterMount && (
              <>
                {batch.description} - <Currency number={batch.amount} precision={2} />
              </>
            )}
            {itemQuery.isFetchedAfterMount && <small> - ({accounting.formatNumber(items.length)})</small>}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.EditButton
                onClick={() => {
                  setShowUpdateBatchDialog(true);
                }}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.DeleteButton
                onClick={() => {
                  setDeleteConfirmationIsOpen(true);
                }}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <Tooltip
                title={
                  hasMissingJobNumbers || hasClosedJobs
                    ? `Assign open jobs to all items - ${missingJobNumbersLength + closedJobLength} missing or closed`
                    : ""
                }
              >
                <span>
                  <PaperPanel.Header.Button
                    disabled={hasMissingJobNumbers || hasClosedJobs || itemQuery.isFetching}
                    href={`/accounting-tools/direct-costs/${batch.id}/download/`}
                  >
                    Download Timberline Import
                  </PaperPanel.Header.Button>
                </span>
              </Tooltip>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton onClick={() => itemQuery.refetch()} isFetching={itemQuery.isFetching} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(
              novoClasses.stripedTable,
              novoClasses.smallTable,
              novoClasses.boldHeaderTable,
              classes.table
            )}
            showFooter={Boolean(items.length)}
            rightAlignColumns={["amount"]}
            hover
            getRowProps={(row) => {
              return {
                // hover: true,
                className: classnames({
                  closedJob: row.original.job?.status != "In progress",
                  noJob: !row.original.job,
                }),
                onClick: () => {
                  setActiveItem(row.original);
                  setShowUpdateItemDialog(true);
                },
              };
            }}
            columns={React.useMemo(
              () => [
                {
                  Header: "Date",
                  accessor: "transaction_date",
                },
                {
                  Header: "Description",
                  accessor: "description",
                  // Cell: ({value, row}) => <Link onClick={() => setActiveItem(row.original)}>{value}</Link>,
                },
                {
                  Header: "Job",
                  accessor: "job.id",
                  Cell: ({value, row}) => (
                    <>
                      {value && (
                        <>
                          <Tooltip title={row.original.job?.display || ""}>
                            <span>
                              <ProjectStatusIcon status={row.original.job?.status} /> {value}
                            </span>
                          </Tooltip>
                        </>
                      )}
                    </>
                  ),
                },
                {
                  Header: "Job Code Raw",
                  accessor: "job_code_raw",
                },
                {
                  Header: "Cost Code",
                  accessor: "cost_code",
                },
                {
                  Header: "Credit",
                  accessor: "credit_account",
                },
                {
                  Header: "Debit",
                  accessor: "debit_account",
                },
                {
                  Header: "Cat",
                  accessor: "category",
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  Cell: ({value, row}) => <Currency number={value} precision={2} />,
                  Footer: () => {
                    return <Currency number={batch.amount} precision={2} />;
                  },
                },
              ],
              []
            )}
            initialState={{
              sortBy: [
                {
                  id: "transaction_date",
                  // desc: true,
                },
              ],
            }}
            data={items}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <DirectCostBatchDialogForm
        isOpen={showUpdateBatchDialog}
        isNew={false}
        handleClose={() => {
          setShowUpdateBatchDialog(false);
        }}
        initialValues={batch}
        onSubmit={(values) => {
          setShowUpdateBatchDialog(false);
          updateBatch.mutateAsync(values);
        }}
      />

      <DirectCostItemDialogForm
        isOpen={showUpdateItemDialog}
        isNew={false}
        handleClose={() => {
          setActiveItem({});
          setShowUpdateItemDialog(false);
        }}
        initialValues={activeItem}
        onSubmit={(values) => {
          setShowUpdateItemDialog(false);
          updateItem.mutateAsync(values);
        }}
      />

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          blockUI.blockUI();
          deleteBatch.mutateAsync().then(() => {
            navigate(`/v2/accounting-tools/direct-cost-batches/home-depot/`);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setDeleteConfirmationIsOpen(false);
        }}
      >
        You want to delete this batch.
      </ConfirmationDialog>
      {/* <pre>{JSON.stringify(batch, null, 2)}</pre> */}
    </>
  );
};
export default HomeDepotBatchDetail;
