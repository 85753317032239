import {Box, Button, Divider, Grid, Typography} from "@material-ui/core";
import {
  Lock as LockIcon,
  LockOpen as LockOpenIcon,
  Refresh as RefreshIcon,
  RestoreFromTrash as RestoreFromTrashIcon,
} from "@material-ui/icons";
import {useSnackbar} from "notistack";
import React from "react";
import {Form} from "react-final-form";
import {Helmet} from "react-helmet";
import {TextFieldMui} from "../../components/forms/Fields";
import {PageHeader} from "../../components/PageHeader";
import useBlockUI from "../../hooks/useBlockUI";
import {getAxiosAPIResponseMessage, useFetchCurrentPage, usePostCurrentPage} from "../../hooks/useSentinelAPI";

const BoxAdmin = (props) => {
  const {data: boxData, refetch: refetchBoxData} = useFetchCurrentPage({useErrorBoundary: false});
  const [folderLockInfo, setFolderLockInfo] = React.useState(null);
  const postCurrentPage = usePostCurrentPage();
  const blockUI = useBlockUI();

  const {enqueueSnackbar} = useSnackbar();
  return (
    <>
      <Helmet title="Box Admin" />

      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Box Admin</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>

      {/* <Box my={2} /> */}
      <Typography variant="h4" gutterBottom>
        Restore Box File/Folder
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Form
            onSubmit={(values, form) => {
              blockUI.blockUI("Restoring Box Folder...");
              postCurrentPage
                .mutateAsync(values)
                .then((data) => {
                  enqueueSnackbar(getAxiosAPIResponseMessage(data), {variant: "success"});
                  blockUI.unblockUI();
                  // form.reset();
                })
                .catch(() => blockUI.unblockUI());
            }}
          >
            {({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="restore_box_folder_id" label="Box Folder Id" required size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={submitting || pristine}
                      startIcon={<RestoreFromTrashIcon />}
                      variant="contained"
                      type="submit"
                    >
                      Restore Box Folder
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Grid>
        <Grid item sm={6}>
          <Form
            onSubmit={(values, form) => {
              blockUI.blockUI("Restoring Box File...");
              postCurrentPage
                .mutateAsync(values)
                .then((data) => {
                  enqueueSnackbar(getAxiosAPIResponseMessage(data), {variant: "success"});
                  blockUI.unblockUI();
                  // form.reset();
                })
                .catch(() => blockUI.unblockUI());
            }}
          >
            {({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="restore_box_file_id" label="Box File Id" required size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={submitting || pristine}
                      startIcon={<RestoreFromTrashIcon />}
                      variant="contained"
                      type="submit"
                    >
                      Restore Box File
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Grid>
      </Grid>

      <Box my={2} />
      <Typography variant="h4" gutterBottom>
        Lock/Unlock Box Folder
      </Typography>
      <Grid container spacing={2}>
        <Grid item sm={4}>
          <Form
            onSubmit={(values, form) => {
              blockUI.blockUI("Checking Box Folder Lock...");
              postCurrentPage
                .mutateAsync(values)
                .then((data) => {
                  // enqueueSnackbar(getAxiosAPIResponseMessage(data), {variant: "success"});
                  setFolderLockInfo(data);
                  blockUI.unblockUI();
                  // form.reset();
                })
                .catch(() => blockUI.unblockUI());
            }}
          >
            {({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="check_lock_box_folder_id" label="Box Folder Id" required size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={submitting || pristine}
                      startIcon={<LockIcon />}
                      variant="contained"
                      type="submit"
                    >
                      Check Box Folder Lock
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Grid>
        <Grid item sm={4}>
          <Form
            onSubmit={(values, form) => {
              blockUI.blockUI("Locking Box Folder...");
              postCurrentPage
                .mutateAsync(values)
                .then((data) => {
                  enqueueSnackbar(getAxiosAPIResponseMessage(data), {variant: "success"});
                  blockUI.unblockUI();
                  // form.reset();
                })
                .catch(() => blockUI.unblockUI());
            }}
          >
            {({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="lock_box_folder_id" label="Box Folder Id" required size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={submitting || pristine}
                      startIcon={<LockIcon />}
                      variant="contained"
                      type="submit"
                    >
                      Lock Box Folder
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Grid>
        <Grid item sm={4}>
          <Form
            onSubmit={(values, form) => {
              blockUI.blockUI("Unlocking Box Folder...");
              postCurrentPage
                .mutateAsync(values)
                .then((data) => {
                  enqueueSnackbar(getAxiosAPIResponseMessage(data), {variant: "success"});
                  blockUI.unblockUI();
                  // form.reset();
                })
                .catch(() => blockUI.unblockUI());
            }}
          >
            {({handleSubmit, form, submitting, pristine, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextFieldMui name="unlock_box_folder_id" label="Box Folder Id" required size="small" />
                  </Grid>
                  <Grid item xs={12}>
                    <Button
                      disabled={submitting || pristine}
                      startIcon={<LockOpenIcon />}
                      variant="contained"
                      type="submit"
                    >
                      Unlock Box Folder
                    </Button>
                  </Grid>
                </Grid>
              </form>
            )}
          </Form>
        </Grid>
        {folderLockInfo && (
          <Grid item xs={12}>
            <pre>{JSON.stringify(folderLockInfo, null, 2)}</pre>
          </Grid>
        )}
      </Grid>
      <Box my={2}>
        <Divider />
      </Box>
      <Typography variant="h4" gutterBottom>
        Login to Box.com
      </Typography>
      <Button variant="contained" href="/box/login/" startIcon={<LockOpenIcon />}>
        Login
      </Button>
      <Box my={2}>
        <Divider />
      </Box>
      <Typography variant="h4" gutterBottom>
        Box Data
      </Typography>
      <Button
        variant="contained"
        startIcon={<RefreshIcon />}
        onClick={() => {
          refetchBoxData();
        }}
      >
        Refetch Box Data
      </Button>
      <pre>{JSON.stringify(boxData, null, 2)}</pre>
    </>
  );
};

export default BoxAdmin;
