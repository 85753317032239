import {faDownload, faLock, faPiggyBank, faRetweet, faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import React from "react";
import {Helmet} from "react-helmet";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {StatusIcon} from "../../../components/Icons";
import PaperPanel from "../../../components/PaperPanel";

import {Alert} from "@material-ui/lab";
import {groupBy, sumBy, uniq} from "lodash";
import {useSnackbar} from "notistack";
import pluralize from "pluralize";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {
  BudgetItemDialogForm,
  BudgetUploadDialog,
  DownloadTimberlineImportDialog,
  SubmitToAccountingForm,
} from "./ProjectBudgetOriginalForms";
import {CommitmentPaperItem} from "./ProjectBudgetOriginalPaperItem";

const ProjectBudgetOriginal = (props) => {
  const {project, ...rest} = props;
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [deleteOneConfirmationIsOpen, setDeleteOneConfirmationIsOpen] = React.useState(false);
  const [itemCreateDialogIsOpen, setItemCreateDialogIsOpen] = React.useState(false);
  const [itemEditDialogIsOpen, setItemEditDialogIsOpen] = React.useState(false);
  const [showUpdateBudgetMarkupConfirmation, setShowUpdateBudgetMarkupConfirmation] = React.useState(false);
  const [isSubmitToAccountingDialogOpen, setIsSubmitToAccountingDialogOpen] = React.useState(false);
  const [uploadDialogIsOpen, setUploadDialogIsOpen] = React.useState(false);
  const [downloadTimberlineDialogIsOpen, setDownloadTimberlineDialogIsOpen] = React.useState(false);
  const [activeBudgetItem, setActiveBudgetItem] = React.useState({} as any);
  const [allocationInitialValue, setAllocationInitialValue] = React.useState("sub");
  const [initialFocusField, setInitialFocusField] = React.useState(null);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      submitToAccountingContacts: [],
    },
  });

  const {submitToAccountingContacts} = pageDataQuery.data;

  const {
    query: budgetItemsQuery,
    create: createBudgetItem,
    update: updateBudgetItem,
    delete: deleteBudgetItem,
    rpc: budgetItemRPC,
    // onDragEnd: onDragEnd,
  } = useSentinelListAPI(`/projects/${project.id}/budgets/`, {
    initialData: {
      results: [],
    },

    keepPreviousData: true,
  });

  const {query: metadataQuery} = useSentinelDetailAPI(`/projects/${project.id}/budgets/metadata/`, {
    initialData: {
      original_budget: null,
    },
    keepPreviousData: true,
  });

  const budgetItems = budgetItemsQuery.data.results;

  const projectDivisions: string[] = uniq(budgetItems.map((item) => item.division_display));

  const divisionLookup = groupBy(budgetItems, "division_display");

  const allBudgetItemIds = budgetItems.map((item) => item.id);

  const {
    selectedIds,
    addSelectedId,
    addSelectedIds,
    removeSelectedId,
    removeSelectedIds,
    addAllSelectedIds,
    removeAllSelectedIds,
    allIdsSelected,
  } = useSelectIds(allBudgetItemIds);

  const isLocked = project.is_budget_locked;

  const budgetItemInitialValues = React.useMemo(
    () => ({
      ...activeBudgetItem,
      // allocation: {value: activeBudgetItem?.allocation, label: activeBudgetItem?.allocation_display},
    }),
    [activeBudgetItem]
  );

  if (!pageDataQuery.isFetchedAfterMount || !metadataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching original budget..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Original Budget`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Original Budget</Typography>
      </ProjectBreadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faPiggyBank} fixedWidth /> {project.name}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark
            number={metadataQuery?.data?.original_budget}
            title="Original Budget"
            precision={2}
          />
          {isLocked && (
            <PageHeader.Right.CurrencySpark
              number={metadataQuery?.data?.current_budget}
              title="Current Budget"
              precision={2}
              border
            />
          )}
        </PageHeader.Right>
      </PageHeader>

      {isLocked && (
        <>
          <Alert severity="warning" icon={<FontAwesomeIcon icon={faLock} />}>
            {project.budget_status === "submitted" ? (
              <>
                <Box>
                  <strong>This budget has been submitted to accounting and is locked pending approval.</strong>
                </Box>
                You will not be able to add, remove or change any items. Adjustments to the budget must be made via
                change orders.
              </>
            ) : project.budget_status === "approved" ? (
              <>
                <Box>
                  <strong>This project budget is approved and has been locked.</strong>
                </Box>
                You will not be able to add, remove or change any items. Adjustments to the budget must be made via
                change orders.
              </>
            ) : (
              <>
                <Box>
                  Status of project budget is <strong>{project.budget_status_display}</strong> and is locked.
                </Box>
                You will not be able to add, remove or change any items. Adjustments to the budget must be made via
                change orders.
              </>
            )}
          </Alert>
          <Box mb={2} />
        </>
      )}
      <PaperPanel>
        <PaperPanel.Header isLoading={budgetItemsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <StatusIcon status={project.budget_status_display} showTooltip /> Original Budget
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setItemCreateDialogIsOpen(true)}>
                Add Item
              </PaperPanel.Header.CreateButton>
              <Box ml={1} />
              <Tooltip title={isLocked ? "Budget is Locked" : selectedIds.size < 1 ? "No Items Selected" : ""}>
                <span>
                  <PaperPanel.Header.DeleteButton
                    disabled={selectedIds.size < 1 || isLocked}
                    onClick={() => setDeleteConfirmationIsOpen(true)}
                  />
                </span>
              </Tooltip>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons buildReportURL={`/reports2/projects/${project.id}/budget/current/filter/`}>
                <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/projects/${project.id}/budget/current/`}>
                  Without Costs
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/budget/current/?display_costs=on`}
                >
                  With Costs
                </PaperPanel.Header.Menu.PDFMenuItem>
              </PaperPanel.Header.PDFButtons>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              {!isLocked && (
                <Tooltip
                  title={
                    !project.timberline_job ? "Job start form must be submitted before you can submit the budget" : ""
                  }
                >
                  <span>
                    <PaperPanel.Header.Button
                      startIcon={<FontAwesomeIcon icon={faRetweet} />}
                      onClick={() => setIsSubmitToAccountingDialogOpen(true)}
                      disabled={!project.timberline_job}
                      // href={`/budgets/project/${project.id}/submit/`}
                      // component={Link}
                      // underline="none"
                    >
                      Submit
                    </PaperPanel.Header.Button>
                  </span>
                </Tooltip>
              )}
              <Box ml={1} />
              <PaperPanel.Header.DropdownMenu title={`Manage Budget`}>
                {(setOpen) => (
                  <>
                    <PaperPanel.Header.Menu.MenuItem href={`/budgets/${project.id}/download-budget/`} component={Link}>
                      <FontAwesomeIcon icon={faDownload} fixedWidth /> Download Budget
                    </PaperPanel.Header.Menu.MenuItem>
                    {!isLocked && (
                      <PaperPanel.Header.Menu.MenuItem onClick={() => setUploadDialogIsOpen(true)}>
                        <FontAwesomeIcon icon={faUpload} fixedWidth /> Upload Budget
                      </PaperPanel.Header.Menu.MenuItem>
                    )}
                    {/* {permissions.can_view_accounting_tools_menu && (
                      <> */}
                    <MenuItemHeader border />
                    <PaperPanel.Header.Menu.MenuItem onClick={() => setDownloadTimberlineDialogIsOpen(true)}>
                      Download Timberline Import File
                    </PaperPanel.Header.Menu.MenuItem>
                    {/* </>
                    )} */}
                  </>
                )}
              </PaperPanel.Header.DropdownMenu>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={budgetItemsQuery.isFetching}
                onClick={() => {
                  budgetItemsQuery.refetch();
                  metadataQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
            <Box mr={-1} />
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {projectDivisions.map((division) => (
            <React.Fragment key={division}>
              <PaperItem.Header>
                <PaperItem.Body>
                  <Grid container>
                    <Grid item xs={12} sm={8}>
                      <h3 style={{margin: 0}}>{division}</h3>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Tooltip title="Original Budget">
                        <Box textAlign="right">
                          <h3 style={{margin: 0}}>
                            <Currency number={sumBy(divisionLookup[division], "original_budget")} precision={2} />
                          </h3>
                        </Box>
                      </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Tooltip title="Current Budget">
                        <Box textAlign="right">
                          <h3 style={{margin: 0}}>
                            <Currency number={sumBy(divisionLookup[division], "current_budget")} precision={2} />
                          </h3>
                        </Box>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </PaperItem.Body>
                <PaperItem.Right minWidth={59} />
              </PaperItem.Header>

              {divisionLookup[division].map((budgetItem) => {
                return (
                  <CommitmentPaperItem
                    budgetItem={budgetItem}
                    key={budgetItem.id}
                    isSelected={selectedIds.has(budgetItem.id)}
                    onChangeSelected={(event, value) => {
                      if (value) {
                        addSelectedId(budgetItem.id, event.nativeEvent.shiftKey);
                      } else {
                        removeSelectedId(budgetItem.id);
                      }
                    }}
                    isLocked={isLocked}
                    onEdit={() => {
                      setActiveBudgetItem(budgetItem);
                      setItemEditDialogIsOpen(true);
                    }}
                    onDelete={() => {
                      setActiveBudgetItem(budgetItem);
                      setDeleteOneConfirmationIsOpen(true);
                    }}
                    onUpdateBudgetMarkup={() => {
                      setActiveBudgetItem(budgetItem);
                      setShowUpdateBudgetMarkupConfirmation(true);
                    }}
                    highlight={activeBudgetItem.id === budgetItem.id && itemEditDialogIsOpen}
                    // leftHighlight={activeBudgetItem.id === budgetItem.id && itemEditDialogIsOpen}
                  />
                );
              })}
            </React.Fragment>
          ))}
        </PaperPanel.Body>
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/budget/original/legacy/`} mt={2} />

      {/* <Typography variant="h2">Page Data Query</Typography> */}
      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          budgetItemRPC
            .mutateAsync({method: "DELETE", action: "delete", data: {ids: Array.from(selectedIds)}})
            .then(() => Promise.all([budgetItemsQuery.refetch(), metadataQuery.refetch()]))
            .then(() => {
              removeAllSelectedIds();
              setDeleteConfirmationIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        title="Delete Selected Items"
      >
        Do you wish to delete the selected {selectedIds.size > 1 && selectedIds.size} budget{" "}
        {pluralize("item", selectedIds.size)}?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteOneConfirmationIsOpen}
        onDeny={() => setDeleteOneConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          budgetItemRPC
            .mutateAsync({method: "DELETE", action: "delete", data: {ids: [activeBudgetItem.id]}})
            .then(() => Promise.all([budgetItemsQuery.refetch(), metadataQuery.refetch()]))
            .then(() => {
              removeSelectedId(activeBudgetItem.id);
              setDeleteOneConfirmationIsOpen(false);
              setActiveBudgetItem({});
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        title={`Delete ${activeBudgetItem.display} - ${activeBudgetItem.allocation_display}`}
      >
        Do you wish to delete this budget item? This can not be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showUpdateBudgetMarkupConfirmation}
        onDeny={() => setShowUpdateBudgetMarkupConfirmation(false)}
        onApprove={() => {
          blockUI.blockUI("Setting markup...");
          updateBudgetItem
            .mutateAsync({id: activeBudgetItem.id, original_budget: activeBudgetItem.expected_markup})
            .then(() => Promise.all([budgetItemsQuery.refetch(), metadataQuery.refetch()]))
            .then(() => {
              setShowUpdateBudgetMarkupConfirmation(false);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        title="Update Markup?"
      >
        You want to update the markup for {activeBudgetItem.display} to{" "}
        <Currency precision={2} number={activeBudgetItem.expected_markup} /> This cannot be undone.
      </ConfirmationDialog>

      <BudgetItemDialogForm
        projectId={project.id}
        isLocked={isLocked}
        isNew
        isOpen={itemCreateDialogIsOpen}
        handleClose={() => setItemCreateDialogIsOpen(false)}
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;
          // values.allocation = values?.allocation?.value;
          blockUI.blockUI("Creating...");
          createBudgetItem
            .mutateAsync(values)
            .then((response) => {
              setAllocationInitialValue(values.allocation);
              form.restart();
              if (submitMode === "addAnother") {
                setInitialFocusField(null);
              } else {
                setItemCreateDialogIsOpen(false);
              }

              enqueueSnackbar(`Budget Item: ${response.display} was successfully created`, {variant: "success"});
              Promise.all([budgetItemsQuery.refetch(), metadataQuery.refetch()]).then(() => {
                if (submitMode === "addAnother") {
                  setInitialFocusField("budget_code");
                }
                blockUI.unblockUI();
              });
            })
            .catch(() => blockUI.unblockUI());
        }}
        initialValues={{original_budget: 0, allocation: allocationInitialValue}}
        initialFocusField={initialFocusField}
      />

      <BudgetItemDialogForm
        projectId={project.id}
        isLocked={isLocked}
        isOpen={itemEditDialogIsOpen}
        handleClose={() => {
          setItemEditDialogIsOpen(false);
          setInitialFocusField(null);
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          // values.allocation = values?.allocation?.value;
          //   delete values.allocation;
          updateBudgetItem
            .mutateAsync(values)
            .then(() => Promise.all([budgetItemsQuery.refetch(), metadataQuery.refetch()]))
            .then((response) => {
              setActiveBudgetItem({});
              setInitialFocusField(null);
              setItemEditDialogIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        initialValues={budgetItemInitialValues}
        setActiveItem={setActiveBudgetItem}
        activeItem={activeBudgetItem}
        budgetItems={budgetItems}
        beforePrevNext={(values) => {
          blockUI.blockUI("Updating...");
          return updateBudgetItem.mutateAsync(values).then(() => {
            blockUI.unblockUI();
          });
        }}
        afterPrevNext={() => {
          budgetItemsQuery.refetch();
          metadataQuery.refetch();
        }}
        initialFocusField={initialFocusField}
        setInitialFocusField={setInitialFocusField}
      />

      <SubmitToAccountingForm
        isOpen={isSubmitToAccountingDialogOpen}
        handleClose={() => setIsSubmitToAccountingDialogOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Submitting...");
          budgetItemRPC
            .mutateAsync({action: "submit-to-accounting", method: "POST", data: values})
            .then(() => {
              enqueueSnackbar("Request Sent!", {variant: "success"});
              setIsSubmitToAccountingDialogOpen(false);

              window.location.reload();
            })
            .catch(() => blockUI.unblockUI());
        }}
        submitToAccountingContacts={submitToAccountingContacts}
      />

      <BudgetUploadDialog
        projectId={project.id}
        isOpen={uploadDialogIsOpen}
        onSubmit={(values) => {
          setUploadDialogIsOpen(false);
          setTimeout(() => blockUI.blockUI("Uploading...")); // need to setTimeout to allow dialog to close
          let formData = new FormData();
          Object.entries(values).map(([key, value]) => {
            /* @ts-ignore */
            formData.append(key, value);
          });

          axiosAPI
            .post(`/budgets/${project.id}/upload-budget/`, formData, {
              baseURL: "",
              headers: {
                "Content-Type": "multipart/form-data",
              },
            })
            .then(() => Promise.all([budgetItemsQuery.refetch(), metadataQuery.refetch()]))
            .then(() => blockUI.unblockUI())
            .catch(() => blockUI.unblockUI());
        }}
        handleClose={() => setUploadDialogIsOpen(false)}
      />

      <DownloadTimberlineImportDialog
        isOpen={downloadTimberlineDialogIsOpen}
        handleClose={() => setDownloadTimberlineDialogIsOpen(false)}
        onSubmit={(values) => {
          window.location.href = `/budgets/${project.id}/download-timberline-budget-import/?timberline_job=${values.timberline_job}`;
          setDownloadTimberlineDialogIsOpen(false);
        }}
        initialValues={{timberline_job: project.timberline_job}}
      />
    </>
  );
};

export default ProjectBudgetOriginal;
