import {Editor} from "@tinymce/tinymce-react";
import React from "react";

const TinyMCEInput = (props) => {
  const {height, auto_focus, editorConfig, ...rest} = props;

  const editorConfig_ = {
    browser_spellcheck: true,
    menubar: false,
    nonbreaking_force_tab: true,
    paste_merge_formats: true,
    plugins: "advlist lists paste code legacyoutput fullscreen nonbreaking hr table",
    toolbar:
      "undo redo | bold italic underline strikethrough forecolor | subscript superscript | bullist numlist outdent indent | table | hr | pastetext code fullscreen",
    valid_elements: "p,br,b/strong,i,u,strike,sup,sub,ol[style],ul[style],li,font[color],hr,table,tr,td,img[src]",
    content_css: "/static/css/tinymce.css",
    toolbar_drawer: "floating",
    branding: false,
    height: parseInt(height),
    auto_focus: auto_focus,
    ...editorConfig,
  };

  return (
    <React.Fragment>
      <Editor init={editorConfig_} {...rest} />
    </React.Fragment>
  );
};

TinyMCEInput.propTypes = {};
export default TinyMCEInput;
