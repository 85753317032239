export const Address = (props: {
  addressObject: {
    address1?: string;
    address2?: string;
    address3?: string;
    city?: string;
    state?: string;
    postal_code?: string;
  };
}) => {
  const {addressObject} = props;
  return (
    <address>
      {addressObject.address1 && <div>{addressObject.address1}</div>}
      {addressObject.address2 && <div>{addressObject.address2}</div>}
      {addressObject.address3 && <div>{addressObject.address3}</div>}
      {addressObject.city && addressObject.state ? (
        <div>
          {addressObject.city}, {addressObject.state} {addressObject.postal_code}
        </div>
      ) : (
        <div>
          {addressObject.city} {addressObject.state} {addressObject.postal_code}
        </div>
      )}
    </address>
  );
};
