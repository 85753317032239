import {faList, faPaperclip, faShoppingCart} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, FormControlLabel, Grid, Link, Switch, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import {uniqBy} from "lodash";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {StatusIcon} from "../../../components/Icons";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TabCountChip} from "../../../components/Tabs";
import Typography2 from "../../../components/Typography2";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import getQueryStringFromIds from "../../../utils/getQueryStringFromIds";
import SCOPaperItem from "./SCOPaperItem";

const ProjectSCOs = (props) => {
  const {project, ...rest} = props;

  const [selectedTab, setSelectedTab] = useQueryState("tab", "scos");
  const [timberlineCommitmentType, setTimberlineCommitmentType] = React.useState("subcontract");
  const [showOriginalCostInline, setShowOriginalCostInline] = React.useState(false);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = useLocalStorage("scoPageSize", 100);

  const novoClasses = makeNovoClasses();

  const [scoFilterOptions, setSCOFilterOption, clearSCOFilterOption, clearAllSCOFilterOptions] = useFilterOptions([
    "Status",
    "Search",
  ]);

  const {query: scoStatusQuery} = useSentinelListAPI(`projects/${project.id}/sccos/status/`, {
    initialData: [],
  });

  const scoStatusOptions = scoStatusQuery.data;

  const filterParams = {status: scoFilterOptions.Status.value, q: scoFilterOptions.Search.value};
  const timberlineFilterParams = {type: timberlineCommitmentType};

  const {
    query: SCOQuery,
    create: createSCO,
    update: updateSCO,
    delete: deleteSCO,
    rpc: SCORPC,
  } = useSentinelListAPI(
    `projects/${project.id}/sccos/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
    }
  );
  const SCOs = SCOQuery.data.results;

  const groupedCommitments = uniqBy(
    SCOs.map((sco) => sco.commitment),
    "id"
  );
  // .sort((a: any, b: any) => parseInt(a.budget_code) - parseInt(b.budget_code)
  // );

  // console.log(groupedCommitments);

  const getSCOByCommitment = (commitmentNumber: number) => SCOs.filter((sco) => sco.commitment.id === commitmentNumber);

  const currentPageQuery = useFetchCurrentPage({
    initialData: {},
  });

  //http://localhost:8000/api/v1/projects/5619/timberline-commitment-cos/
  const {
    query: timberlineQuery,
    create: timberlineCreate,
    update: timberlineUpdate,
    delete: timberlineDelete,
    rpc: timberlineRPC,
  } = useSentinelListAPI(
    `projects/${project.id}/timberline-commitment-cos/?page_size=10000&${qs.stringify(timberlineFilterParams)}`,
    {
      initialData: {
        results: [],
      },
    }
  );
  const timberlineSCOs = timberlineQuery.data.results;

  const pdfQueryString = React.useMemo(() => getQueryStringFromIds(undefined, filterParams), [filterParams]);

  return (
    <>
      <Helmet title={`${project.display} - Subcontract Change Orders`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Subcontract Change Orders</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar
            position="static"
            color="default"
            // elevation={0}
          >
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    SCOs
                    <TabCountChip isLoading={!SCOQuery.isFetchedAfterMount} count={SCOQuery.data?.count} />
                  </Box>
                }
                value="scos"
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Timberline
                    <TabCountChip
                      isLoading={!timberlineQuery.isFetchedAfterMount}
                      count={timberlineQuery.data?.count}
                    />
                  </Box>
                }
                value="timberline"
              />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="scos">
            <PaperPanel.TabHeader isLoading={SCOQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faPaperclip} /> Subcontract Change Orders
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.CreateButton
                    href={`/v2/projects/${project.id}/change-order-wizard/sco/select-subcontract/`}
                  >
                    Create
                  </PaperPanel.Header.CreateButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.PDFButtons
                    pdfURL={`/reports2/projects/${project.id}/subcontracts/?display_sccos=on${pdfQueryString}`}
                    buildReportURL={`/reports2/projects/${project.id}/subcontracts/filter/?display_sccos=on`}
                  />
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.ColoredButton href={`/projects/${project.id}/scco-items/`}>
                    <FontAwesomeIcon icon={faList} />
                    <Box mr={1} />
                    SCCO Items
                  </PaperPanel.Header.ColoredButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action pr={0}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={showOriginalCostInline}
                        onChange={(event) => {
                          event.target.checked ? setShowOriginalCostInline(true) : setShowOriginalCostInline(false);
                        }}
                      />
                    }
                    label="Original Value Inline"
                  />
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={SCOQuery.isFetching}
                    onClick={() => SCOQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>

            <PaperPanel.TabToolbar p={1}>
              <Grid container spacing={1}>
                <Grid item sm={4} xs={12}>
                  <FilterSearch
                    label="Search"
                    value={scoFilterOptions.Search.value}
                    name="Search"
                    onChange={(value) => {
                      setPage(1);
                      setSCOFilterOption("Search", value, value);
                    }}
                  />
                </Grid>
                <Grid item sm={4} xs={12}>
                  <FilterSelect
                    label="Status"
                    name="status"
                    options={scoStatusOptions}
                    value={scoFilterOptions.Status.value}
                    onChange={(value, label) => {
                      setPage(1);
                      setSCOFilterOption("Status", value, label);
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <FilterOptionChips
                    filterOptions={scoFilterOptions}
                    onDelete={(key) => {
                      clearSCOFilterOption(key);
                    }}
                    onDeleteAll={() => {
                      clearAllSCOFilterOptions();
                    }}
                  />
                </Grid>
                <Grid item sm={6} xs={12}>
                  <Box display="flex" justifyContent="flex-end">
                    <Pagination count={SCOQuery.data.total_pages} page={page} setPage={setPage} />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.TabToolbar>

            <Box mx={-3} mb={-3}>
              <PaperPanel.Body>
                {groupedCommitments.map((commitment: any) => {
                  const scoInThisCommitment = getSCOByCommitment(commitment.id);
                  return (
                    <React.Fragment key={commitment.id}>
                      <PaperItem.Header>
                        <PaperItem.Body>
                          <Grid container>
                            <Grid item sm={8} xs={12}>
                              <StatusIcon status={commitment.status} tooltip={commitment.status_display} showTooltip />{" "}
                              <MuiNavLink href={commitment.url}>{commitment.display}</MuiNavLink> -{" "}
                              <Typography2 type="metadata">
                                <MuiNavLink to={commitment.to_company.url}>{commitment.to_company.name}</MuiNavLink>
                              </Typography2>{" "}
                              {commitment.type === "Purchase Order" && (
                                <Tooltip title="Purchase Order">
                                  <FontAwesomeIcon icon={faShoppingCart} />
                                </Tooltip>
                              )}
                            </Grid>

                            <Grid item sm={4} xs={12}>
                              <Box textAlign={"right"}>
                                <Tooltip
                                  title={
                                    <>
                                      Original Value: <Currency number={commitment.original_value} />
                                      <br />
                                      Current Value: <Currency number={commitment.current_value} />
                                    </>
                                  }
                                >
                                  <span>
                                    {showOriginalCostInline ? (
                                      <>
                                        Original Value{"  "}
                                        <Currency number={commitment.original_value} />
                                      </>
                                    ) : (
                                      <strong>
                                        <Currency number={commitment.current_value} />{" "}
                                      </strong>
                                    )}
                                  </span>
                                </Tooltip>
                              </Box>
                            </Grid>
                          </Grid>
                        </PaperItem.Body>
                        <PaperItem.Right minWidth={37} />
                      </PaperItem.Header>
                      {scoInThisCommitment.map((sco) => (
                        <SCOPaperItem sco={sco} projectId={project.id} key={sco.id} />
                      ))}
                      {!showOriginalCostInline ? null : (
                        <>
                          <PaperItem.Header>
                            <PaperItem.Body>
                              <Grid container>
                                <Grid item xs={12}>
                                  <Box textAlign="right">
                                    <strong>
                                      {" "}
                                      Current Value <Currency number={commitment.current_value} />
                                    </strong>
                                  </Box>
                                </Grid>
                              </Grid>
                            </PaperItem.Body>
                            <PaperItem.Right minWidth={37} />
                          </PaperItem.Header>
                          <Box mb={2} borderBottom="1px solid #696969" />
                        </>
                      )}
                    </React.Fragment>
                  );
                })}
              </PaperPanel.Body>
              {(SCOQuery.data.total_pages > 1 || pageSize > 100) && (
                <PaperPanel.Footer display="flex" justifyContent="flex-end">
                  <PaginationWithPageSize
                    count={SCOQuery.data.total_pages}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                  />
                </PaperPanel.Footer>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="timberline">
            <PaperPanel.TabHeader isLoading={timberlineQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faPaperclip} /> Timberline Commitments
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={timberlineCommitmentType === ""}
                        onChange={(event) => {
                          event.target.checked
                            ? setTimberlineCommitmentType("")
                            : setTimberlineCommitmentType("subcontract");
                        }}
                      />
                    }
                    label="Show POs"
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.Body mx={-3} mt={-2} mb={-3}>
              <PaperPanel.Alert severity={"info"} {...props}>
                Timberline data is refreshed nightly and does not reflect changes made today.
              </PaperPanel.Alert>

              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                rightAlignColumns={["amount"]}
                showFooter={Boolean(timberlineSCOs.length)}
                columns={[
                  {
                    Header: "Commitment",
                    accessor: "commitment",
                    Cell: ({value, row}) => value,
                    // <Link underline="always" href={`/contracts/primecontract/${row.original.id}/`}>

                    // </Link>
                  },
                  {
                    Header: "Status",
                    accessor: "status",
                    Cell: ({value, row}) => value,
                  },
                  {
                    Header: "Commitment CO",
                    accessor: "commitment_co",
                    Cell: ({value, row}) => value,
                  },
                  {
                    Header: "Description",
                    accessor: "description",
                    sortType: "basic",
                    Cell: ({value, row}) => value,
                  },
                  {
                    Header: "Date",
                    accessor: "date",
                    sortType: "basic",
                    Cell: ({value, row}) => value,
                  },
                  {
                    Header: "Amount",
                    accessor: "amount",
                    sortType: "basic",
                    Cell: ({value, row}) => <Currency number={value} precision={2} />,
                    Footer: () => {
                      const total = timberlineSCOs.reduce((sum, row) => row.amount + sum, 0);
                      return total ? <Currency number={total} /> : "";
                    },
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "commitment",
                      desc: false,
                    },
                    {
                      id: "commitment_co",
                      desc: false,
                    },
                  ],
                }}
                data={timberlineSCOs}
              />

              {/* commit, commit co, desc, date, amt, status */}
            </PaperPanel.Body>
          </TabPanel>
        </PaperPanel>
      </TabContext>
      <LegacyUILink href={`/projects/${project.id}/sccos/legacy/`} mt={2} />
    </>
  );
};

export default ProjectSCOs;
