import React from "react";
import {useHotkeys} from "react-hotkeys-hook";

// https://github.com/marmelab/react-admin/issues/3551
// https://github.com/final-form/react-final-form/issues/130
export const identity = (value) => value;

export const FinalFormKeyboardShortcuts = ({children, pristine, handleSubmit, ...rest}) => {
  const saveForm = React.useCallback(
    (event) => {
      event.preventDefault();
      if (pristine) {
        return;
      }
      handleSubmit();
    },
    [pristine]
  );
  useHotkeys(
    "meta+s",
    saveForm,
    {
      // filter: () => {
      //   // https://github.com/jaywcjlove/hotkeys/#filter
      //   return true;
      // },
      // enabled: true,
      enableOnFormTags: ["INPUT", "TEXTAREA", "SELECT"],
    },
    [pristine]
  );

  return <React.Fragment>{children}</React.Fragment>;
};
