import {faUsers} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {LegacyUILink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {ProjectGroupDialogForm} from "./ProjectGroupForms";

const ProjectGroups = (props) => {
  const {project, ...rest} = props;
  const novoClasses = makeNovoClasses();
  const [showUpdateDialogForm, setShowUpdateDialogForm] = React.useState(false);
  const [showCreateDialogForm, setShowCreateDialogForm] = React.useState(false);
  const [deleteProjectGroupConfirmationIsOpen, setDeleteProjectGroupConfirmationIsOpen] = React.useState(false);
  const [activeProjectGroup, setActiveProjectGroup] = React.useState({} as any);
  const [initialValues, setInitialValues] = React.useState({
    response_required_contacts: [],
    cc_contacts: [],
  });
  const blockUI = useBlockUI();

  const {
    query: projectGroupQuery,
    create: createProjectGroup,
    update: updateProjectGroup,
    delete: deleteProjectGroup,
  } = useSentinelListAPI(`projects/${project.id}/groups/`, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });
  const projectGroups = projectGroupQuery.data.results;

  const {query: projectGroupContactsQuery} = useSentinelListAPI(
    `projects/${project.id}/groups/${activeProjectGroup?.id}/contacts/`,
    {
      initialData: {
        results: [],
      },
      enabled: Boolean(activeProjectGroup?.id),
    }
  );

  React.useEffect(() => {
    const responseRequiredContacts = projectGroupContactsQuery.data.results
      .filter((item) => item.role === "response_required")
      .map((item) => item.contact);
    const ccContacts = projectGroupContactsQuery.data.results
      .filter((item) => item.role === "cc")
      .map((item) => item.contact);
    setInitialValues({
      ...activeProjectGroup,
      response_required_contacts: responseRequiredContacts,
      cc_contacts: ccContacts,
    });
  }, [activeProjectGroup, projectGroupContactsQuery.dataUpdatedAt]);

  return (
    <>
      <Helmet title={`${project.display} - Groups`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Groups</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faUsers} /> Groups
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialogForm(true)}>
                Create Group
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => projectGroupQuery.refetch()}
                isFetching={projectGroupQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                Header: "Name",
                accessor: "name",
                Cell: ({value, row}) => (
                  <Link
                    style={{cursor: "pointer"}}
                    underline="always"
                    onClick={() => {
                      setShowUpdateDialogForm(true);
                      setActiveProjectGroup(row.original);
                    }}
                  >
                    {value}
                  </Link>
                ),
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "name",
                  desc: false,
                },
              ],
            }}
            data={projectGroups}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/groups/legacy/`} mt={2} />

      <ProjectGroupDialogForm
        projectId={project.id}
        isNew
        onSubmit={(values) => {
          setShowCreateDialogForm(false);
          createProjectGroup.mutateAsync(values);
          //.then(() => projectGroupQuery.refetch());
        }}
        initialValues={{
          response_required_contacts: [],
          cc_contacts: [],
        }}
        handleClose={() => {
          setShowCreateDialogForm(false);
        }}
        isOpen={showCreateDialogForm}
      />
      <ProjectGroupDialogForm
        projectId={project.id}
        onSubmit={(values) => {
          setShowUpdateDialogForm(false);
          updateProjectGroup.mutateAsync(values).then(() => projectGroupContactsQuery.refetch());
        }}
        onDelete={() => {
          setShowUpdateDialogForm(false);
          setDeleteProjectGroupConfirmationIsOpen(true);
        }}
        initialValues={initialValues}
        handleClose={() => {
          setShowUpdateDialogForm(false);
        }}
        isOpen={showUpdateDialogForm}
      />

      <ConfirmationDialog
        isOpen={deleteProjectGroupConfirmationIsOpen}
        onApprove={() => {
          setDeleteProjectGroupConfirmationIsOpen(false);
          blockUI.blockUI();
          deleteProjectGroup
            .mutateAsync(activeProjectGroup.id)
            .then(() => {
              blockUI.unblockUI();
              setActiveProjectGroup({});
            })
            .catch(() => blockUI.unblockUI());
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setDeleteProjectGroupConfirmationIsOpen(false);
        }}
      >
        You want to delete {activeProjectGroup?.name}.
      </ConfirmationDialog>

      {/* <pre>{JSON.stringify(projectGroupQuery.data, null, 2)}</pre> */}
    </>
  );
};

export default ProjectGroups;
