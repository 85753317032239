import {Box, Grid} from "@material-ui/core";
import classnames from "classnames";
import {sumBy} from "lodash";
import moment from "moment";
import React from "react";
import {useSentinelDetailAPI, useSentinelListAPI} from "../hooks/useSentinelAPI";
import {makeNovoClasses} from "../theme";
import {Currency} from "./Accounting";
import PaperPanel from "./PaperPanel";
import {ReactTableMui} from "./ReactTableMui";

export const TimberlineCommitmentInfoPanel = (props: {projectId: number; timberlineCommitmentId: number}) => {
  const {projectId, timberlineCommitmentId, ...rest} = props;

  const {
    query: timberlineQuery,
    create: timberlineCreate,
    update: timberlineUpdate,
    delete: timberlineDelete,
    rpc: timberlineRPC,
  } = useSentinelDetailAPI(["projects", projectId, "timberline-commitments", timberlineCommitmentId], {
    initialData: {},
    enabled: Boolean(timberlineCommitmentId),
  });
  const timberlineCommitment = timberlineQuery.data;

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={timberlineQuery.isFetching}>
          <PaperPanel.Header.Title>Timberline Details</PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <Box {...rest}>
            <PaperPanel.Alert severity={"info"}>
              Timberline data is refreshed nightly and does not reflect changes made today.
            </PaperPanel.Alert>
          </Box>
          <Box mx={5}>
            <Grid container>
              <Grid container item sm={6} xs={12}>
                <Grid item xs={6}>
                  <strong>Description</strong>
                </Grid>
                <Grid item xs={6}>
                  {timberlineCommitment.description}
                </Grid>
                <Grid item xs={6}>
                  <strong>Number</strong>
                </Grid>
                <Grid item xs={6}>
                  {timberlineCommitment.commitment}
                </Grid>
                <Grid item xs={6}>
                  <strong>Date</strong>
                </Grid>
                <Grid item xs={6}>
                  {timberlineCommitment.date && moment(timberlineCommitment.date).format("LL")}
                </Grid>
                <Grid item xs={6}>
                  <strong>Vendor Name</strong>
                </Grid>
                <Grid item xs={6}>
                  {timberlineCommitment.vendor_name}
                </Grid>
                <Grid item xs={6}>
                  <strong>Last Invoice</strong>
                </Grid>
                <Grid item xs={6}>
                  {timberlineCommitment.last_invoice}
                </Grid>
                <Grid item xs={6}>
                  <strong>Last Check</strong>
                </Grid>
                <Grid item xs={6}>
                  {timberlineCommitment.last_check}
                </Grid>
                <Grid item xs={6}>
                  <strong>Last Check Date</strong>
                </Grid>
                <Grid item xs={6}>
                  {timberlineCommitment.last_check_date && moment(timberlineCommitment.last_check_date).format("LL")}
                </Grid>
              </Grid>
              <Grid container item sm={6} xs={12}>
                <Grid item xs={6}>
                  <strong>Amount</strong>
                </Grid>
                <Grid item xs={6}>
                  <Currency number={timberlineCommitment.amount} />
                </Grid>
                <Grid item xs={6}>
                  <strong>Approved SCO Amount</strong>
                </Grid>
                <Grid item xs={6}>
                  <Currency number={timberlineCommitment.approved_commitment_co_amount} />
                </Grid>
                <Grid item xs={6}>
                  <strong>Total Committed Cost</strong>
                </Grid>
                <Grid item xs={6}>
                  <Currency number={timberlineCommitment.committed} />
                </Grid>
                <Grid item xs={6}>
                  <strong>Amount Invoiced</strong>
                </Grid>
                <Grid item xs={6}>
                  <Currency number={timberlineCommitment.amount_invoiced} />
                </Grid>
                <Grid item xs={6}>
                  <strong>Amount Being Retained</strong>
                </Grid>
                <Grid item xs={6}>
                  <Currency number={timberlineCommitment.amount_being_retained} />
                </Grid>
                <Grid item xs={6}>
                  <strong>Amount Paid</strong>
                </Grid>
                <Grid item xs={6}>
                  <Currency number={timberlineCommitment.amount_paid} />
                </Grid>
                <Grid item xs={6}>
                  <strong>Balance (Committed - Amount Invoiced)</strong>
                </Grid>
                <Grid item xs={6}>
                  <Currency number={timberlineCommitment.balance} />
                </Grid>
              </Grid>
            </Grid>
          </Box>
          {/* <pre>{JSON.stringify(timberlineCommitment, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export const TimberlineCommitmentItemPanel = (props: {timberlineCommitmentId: number; projectId: number}) => {
  const {timberlineCommitmentId, projectId, ...rest} = props;
  const novoClasses = makeNovoClasses();

  const {
    query: timberlineItemsQuery,
    create: timberlineItemsCreate,
    update: timberlineItemsUpdate,
    delete: timberlineItemsDelete,
    rpc: timberlineItemsRPC,
  } = useSentinelDetailAPI(["projects", projectId, "timberline-commitments", timberlineCommitmentId, "items"], {
    initialData: {
      results: [],
    },
    enabled: Boolean(timberlineCommitmentId),
  });
  const timberlineItems = timberlineItemsQuery.data.results;

  return (
    <PaperPanel>
      <PaperPanel.Header isLoading={timberlineItemsQuery.isFetching}>
        <PaperPanel.Header.Title>Items</PaperPanel.Header.Title>
      </PaperPanel.Header>
      <PaperPanel.Body>
        <ReactTableMui
          size="small"
          className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
          showFooter={true}
          rightAlignColumns={["amount"]}
          columns={[
            {
              Header: "#",
              accessor: "item_number",
            },

            {
              Header: "Description",
              accessor: "description",
            },
            {
              Header: "Cost Code",
              accessor: "cost_code",
            },
            {
              Header: "Cat",
              accessor: "category",
            },
            {
              Header: "Amount",
              accessor: "amount",
              Cell: ({value, row}) => <Currency number={value} />,
              Footer: () => {
                const total = React.useMemo(() => sumBy(timberlineItems, "amount"), [timberlineItems]);
                return <Currency number={total} />;
              },
            },
          ]}
          initialState={{
            sortBy: [
              {
                // id: "pco_number_display",
                // asc: true,
              },
            ],
          }}
          data={timberlineItems}
          {...rest}
        />
        {/* <pre>{JSON.stringify(timberlineItems, null, 2)}</pre> */}
      </PaperPanel.Body>
    </PaperPanel>
  );
};
export const TimberlineCommitmentChangeOrderPanel = (props: {projectId: number; timberlineCommitmentId: number}) => {
  const {projectId, timberlineCommitmentId, ...rest} = props;
  const novoClasses = makeNovoClasses();

  const {
    query: timberlineCOQuery,
    create: timberlineCOCreate,
    update: timberlineCOUpdate,
    delete: timberlineCODelete,
    rpc: timberlineCORPC,
  } = useSentinelListAPI(`projects/${projectId}/timberline-commitment-cos/?commitment=${timberlineCommitmentId}`, {
    initialData: {
      results: [],
    },
    enabled: Boolean(timberlineCommitmentId),
  });

  const timberlineCOs = timberlineCOQuery.data.results;

  return (
    <PaperPanel>
      <PaperPanel.Header isLoading={timberlineCOQuery.isFetching}>
        <PaperPanel.Header.Title>Change Orders</PaperPanel.Header.Title>
      </PaperPanel.Header>
      <PaperPanel.Body>
        <ReactTableMui
          size="small"
          className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
          showFooter={true}
          rightAlignColumns={["amount"]}
          columns={[
            {
              Header: "#",
              accessor: "commitment_co",
              Cell: ({value, row}) => value,
            },

            {
              Header: "Description",
              accessor: "description",
              Cell: ({value, row}) => value,
            },
            {
              Header: "Amount",
              accessor: "amount",
              Cell: ({value, row}) => <Currency number={value} />,
              Footer: () => {
                const total = React.useMemo(() => sumBy(timberlineCOs, "amount"), [timberlineCOs]);
                return <Currency number={total} />;
              },
            },
          ]}
          initialState={{
            sortBy: [
              {
                id: "commitment_co",
                asc: true,
              },
            ],
          }}
          data={timberlineCOs}
          {...rest}
        />
      </PaperPanel.Body>
    </PaperPanel>
  );
};
