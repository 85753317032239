import {Checkboxes} from "mui-rff";
import {FormEvent} from "react";

export const CheckboxMui = (props: {
  label: string;
  name: string;
  value?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (event: FormEvent<HTMLDivElement>, value: boolean) => void;
}) => {
  const {name, label, value, onChange: _onChange, disabled = false, required, ...rest} = props;

  return (
    <Checkboxes
      name={name}
      data={{label: label, value: value || name}}
      formControlProps={{
        onChange: (e) => {
          if (_onChange) _onChange(e, e.target.checked);
        },
        ...rest,
      }}
      disabled={disabled}
      required={required}
    />
  );
};
