import {faElevator} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Chip, FormLabel, Grid, IconButton} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {isEmpty} from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import {FieldArray} from "react-final-form-arrays";
import LabeledGroup from "../../../../js/components/LabeledGroup";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import PaperPanel from "../../../components/PaperPanel";
import {
  MultipleAutocompleteMui,
  SimpleAutoselectMui,
  SingleAutocompleteMui,
  TextFieldMui,
  YesNoRadios,
} from "../../../components/forms/Fields";
import JobsiteSafetyForm, {EquipmentFailureCounts} from "./JobsiteFormComponents";
import useBuildField from "./useBuildField";
import {buildQuestionObjects} from "./utils";

export const ScissorBoomLiftOperation = (props) => {
  const {safetyForm, internalDetailView, recentEquipmentCount, postCurrentPage, operatorOptions = []} = props;
  const isSpanish = !internalDetailView && safetyForm?.twilio_contact?.language === "SP";
  const isReadOnly = internalDetailView || safetyForm.is_closed;
  const liftTypeOptions = [
    {label: isSpanish ? "Tijera" : "Scissor", value: "scissor"},
    {label: isSpanish ? "Pluma" : "Boom", value: "boom"},
  ];
  const [copyRecentConfirmationIsOpen, setCopyRecentConfirmationIsOpen] = React.useState(false);

  const buildField = useBuildField(isSpanish, isReadOnly, {
    yesLabel: isSpanish ? `Aprobar` : `Pass`,
    noLabel: isSpanish ? `Suspender` : `Fail`,
  });

  const [equipmentInspectionFields, workplaceAssessmentFields] = ["equipment_inspection", "workplace_assessment"].map(
    (name) => buildQuestionObjects(safetyForm, name, YesNoRadios)
  );

  const generalFields = buildQuestionObjects(safetyForm, "general", YesNoRadios, (field) => {
    field.yesLabel = isSpanish ? `Si` : `Yes`;
    field.noLabel = `No`;
  });

  const addEquipmentCallback = React.useRef(() => {});

  const equipmentInspectionValidation = (value, values, meta) => {
    const [nameSpace, fieldName] = meta.name.split(".");
    if (value === undefined) return "Required";
    if (value === false && isEmpty(values.failures[fieldName]))
      return isSpanish
        ? "Debe seleccionar al menos una pieza de equipo"
        : "Must select at least one piece of equipment";
    if (value === true) {
      values.failures[fieldName] = [];
    }
  };

  if (!isReadOnly && operatorOptions?.length === 0 && safetyForm.twilio_contact?.fuzzy_company) {
    return <BlockUI show={true} message="Fetching Data..." />;
  }

  return (
    <JobsiteSafetyForm
      isSpanish={isSpanish}
      title={isSpanish ? `Permiso de elevación de tijera/elevación de pluma` : `Scissor Lift/Boom Lift Permit`}
      initialValues={{general: {date: moment()}, equipment: [{}], failures: {}}}
      keepDirtyOnReinitialize
      {...props}
    >
      {(form, values) => {
        equipmentInspectionFields.forEach((field) => {
          if (values.failures[field.name] === undefined) {
            form.change(`failures.${field.name}`, []);
          }
        });
        const failedItemOptions = values.equipment
          .map((equipment, index) => equipment?.equipment_number)
          .filter((value) => value);
        const failingEquipment = new Set(Object.values(values.failures).flat());
        return (
          <>
            {!internalDetailView && (
              <Box textAlign="center">
                <EquipmentFailureCounts
                  equipmentCount={values.equipment.length}
                  failureCount={failingEquipment.size}
                  formType={safetyForm.form_type}
                />
              </Box>
            )}
            {recentEquipmentCount > 0 && !isReadOnly && (
              <Box my={1}>
                <Button
                  size="small"
                  onClick={() => {
                    setCopyRecentConfirmationIsOpen(true);
                  }}
                  variant="outlined"
                  fullWidth
                  color="primary"
                  disabled={isReadOnly}
                >
                  <Box mr={1}>
                    <Chip size="small" label={recentEquipmentCount} />
                  </Box>

                  {isSpanish ? `Copiar el equipo de la última inspección` : `Copy Equipment From Last Inspection`}
                </Button>
              </Box>
            )}

            <Grid item xs={12}>
              <PaperPanel py={1}>
                <PaperPanel.Header>
                  <PaperPanel.Header.Title>
                    <FontAwesomeIcon icon={faElevator} /> {isSpanish ? `Equipo` : `Equipment`} (
                    {values.equipment.length})
                  </PaperPanel.Header.Title>
                  <PaperPanel.Header.Actions>
                    {!internalDetailView && (
                      <PaperPanel.Header.Action>
                        <PaperPanel.Header.CreateButton
                          onClick={() => {
                            addEquipmentCallback.current();
                          }}
                          disabled={isReadOnly}
                        >
                          {isSpanish ? `Agregar Equipo` : `Add Equipment`}
                        </PaperPanel.Header.CreateButton>
                      </PaperPanel.Header.Action>
                    )}
                  </PaperPanel.Header.Actions>
                </PaperPanel.Header>
                <PaperPanel.Body>
                  <FieldArray name={`equipment`}>
                    {({fields}) => {
                      addEquipmentCallback.current = () => fields.push(undefined);
                      return (
                        <div>
                          {fields.map((name, i) => {
                            const operatorsInUse = fields.value
                              .map((value) => value?.operator)
                              .filter((value) => value);
                            return (
                              <React.Fragment key={name}>
                                {i !== 0 && <Box mt={1} />}
                                <LabeledGroup
                                  label={`# ${i + 1}`}
                                  pt={1}
                                  error={failingEquipment.has(fields.value[i]?.equipment_number)}
                                >
                                  <Grid container spacing={2}>
                                    <Grid item xs={12} sm={4}>
                                      <SimpleAutoselectMui
                                        name={`${name}.lift_type`}
                                        label={isSpanish ? `Tipo de elevación` : `Type of lift`}
                                        options={liftTypeOptions}
                                        required
                                        disabled={isReadOnly}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      <TextFieldMui
                                        name={`${name}.equipment_number`}
                                        label={`${isSpanish ? `Modelo o equipo numero` : `Model or equipment number`}`}
                                        size="small"
                                        required
                                        disabled={isReadOnly}
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={4}>
                                      {isReadOnly ? (
                                        <>
                                          <TextFieldMui
                                            name={`${name}.operator`}
                                            label={isSpanish ? `Operador` : `Operator`}
                                            disabled={isReadOnly}
                                            required
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <SingleAutocompleteMui
                                            name={`${name}.operator`}
                                            label={isSpanish ? `Operador` : `Operator`}
                                            options={operatorOptions}
                                            required
                                            disabled={isReadOnly}
                                            renderOption={(option) => {
                                              return (
                                                <Box display="flex" alignItems="center">
                                                  <Box mr={1}>{option.label}</Box>
                                                  {operatorsInUse.includes(option.value) && (
                                                    <span>{isSpanish ? `(Ya Asignado)` : `(Already Assigned)`}</span>
                                                  )}
                                                </Box>
                                              );
                                            }}
                                            getOptionDisabled={(option) => {
                                              return operatorsInUse.includes(option.value);
                                            }}
                                            freeSolo
                                          />
                                        </>
                                      )}
                                    </Grid>
                                    <Grid item xs={12}>
                                      <Box textAlign={"right"}>
                                        <IconButton
                                          aria-label="delete"
                                          size="small"
                                          onClick={() => fields.remove(i)}
                                          disabled={values.equipment.length < 2 || isReadOnly}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </Grid>
                                  </Grid>
                                </LabeledGroup>
                              </React.Fragment>
                            );
                          })}
                        </div>
                      );
                    }}
                  </FieldArray>
                </PaperPanel.Body>
              </PaperPanel>
              <Box mb={3} />
            </Grid>
            <Grid container spacing={2}>
              {generalFields.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {buildField("general", field)}
                </Grid>
              ))}

              <Grid item xs={12}>
                <Box textAlign="center">
                  <FormLabel>
                    <h3>{isSpanish ? `Inspección de equipamiento` : `Equipment Inspection`}</h3>
                  </FormLabel>
                </Box>
              </Grid>

              {equipmentInspectionFields.map((field) => (
                <Grid item xs={12} sm={4} key={field.name}>
                  {buildField("equipment_inspection", {
                    ...field,
                    fieldProps: {
                      validate: equipmentInspectionValidation,
                    },
                  })}
                  {values?.equipment_inspection?.[field.name] === false && (
                    <MultipleAutocompleteMui
                      name={`failures.${field.name}`}
                      label={isSpanish ? `Notas` : `Equipment`}
                      multiline
                      disabled={isReadOnly}
                      options={failedItemOptions}
                      fieldProps={{validateFields: [`equipment_inspection.${field.name}`]}}
                      required
                    />
                  )}
                </Grid>
              ))}
            </Grid>

            <Box textAlign="center" mb={1}>
              <FormLabel>
                <h3>{isSpanish ? `Evaluación del lugar de trabajo` : `Workplace Assessment`}</h3>
                {isSpanish
                  ? `Inspeccione el área de trabajo para detectar condiciones operativas potencialmente peligrosas antes de usar el elevador. Asegúrese de que todos los peligros identificados se aborden en la planificación previa a la tarea con estrategias suficientes para mitigar los peligros o riesgos.`
                  : `Survey work area for potential hazardous operating conditions prior to use of lift. Ensure all the
                    hazards identified are addressed in pre- task planning with sufficient strategies to mitigate the
                    hazards and or risks.`}
              </FormLabel>
            </Box>
            <Grid container spacing={2}>
              {workplaceAssessmentFields.map((field) => (
                <Grid item xs={12} sm={6} key={field.name}>
                  {buildField("workplace_assessment", field)}
                </Grid>
              ))}
            </Grid>
            <ConfirmationDialog
              isOpen={copyRecentConfirmationIsOpen}
              onDeny={() => {
                setCopyRecentConfirmationIsOpen(false);
              }}
              onApprove={() => {
                form.pauseValidation();
                postCurrentPage
                  .mutateAsync({answers: values, action: "copy_recent_equipment"})
                  .then(() => {
                    setCopyRecentConfirmationIsOpen(false);
                  })
                  .then(() => {
                    form.resumeValidation();
                    setCopyRecentConfirmationIsOpen(false);
                    window.location.reload();
                  });
              }}
              title={isSpanish ? `Copiar equipo de la última inspección` : `Copy equipment from last inspection`}
            >
              {isSpanish ? (
                <>
                  Copie {recentEquipmentCount} {recentEquipmentCount > 1 ? "piezas" : "pezado"} del equipo de su último
                  ¿inspección? Esto borrará cualquier equipo que haya ingresado.
                </>
              ) : (
                <>
                  Copy {recentEquipmentCount} {pluralize("piece", recentEquipmentCount)} of equipment from your last
                  inspection? This will erase any equipment you have entered.
                </>
              )}
            </ConfirmationDialog>
          </>
        );
      }}
    </JobsiteSafetyForm>
  );
};
