import {Box, Grid} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import React from "react";
import {CheckboxMui, TextFieldMui} from "../../../../components/forms/Fields";

const InsuranceFields = (props) => {
  const {namespace, form, values, getValue} = props;
  const [shouldDisable, setShouldDisable] = React.useState(true);

  return (
    <>
      <Alert severity="info">
        This step will allow you to create a single initial certificate holder/additional insured. Be sure you check the
        box if you would like to create one. You will have the opportunity to create additional certificate
        holders/additional insured once the project has been created.
      </Alert>
      <Box mb={2} />
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CheckboxMui
            name={`${namespace}.create_certificate_holder`}
            label="Create Certificate Holder"
            onChange={(e, value) => setShouldDisable(!value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldMui
            name={`${namespace}.certificate_holder_name`}
            label="Certificate Holder Name"
            // disabled={!values[namespace].create_certificate_holder}
            disabled={shouldDisable}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldMui
            name={`${namespace}.certificate_holder_address`}
            label="Certificate Holder Address"
            multiline
            minRows={4}
            // disabled={!values[namespace].create_certificate_holder}
            disabled={shouldDisable}
          />
        </Grid>
        <Grid item xs={12}>
          <TextFieldMui
            name={`${namespace}.additional_insured`}
            label="Additional Insured"
            multiline
            minRows={4}
            autoFocus
          />
        </Grid>
      </Grid>
    </>
  );
};

export default InsuranceFields;
