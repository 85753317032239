import {Box} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";

const InnerFooter = (props) => {
  const {children, hasSidebar = true, ...rest} = props;
  let sx = {};
  if (hasSidebar) {
    sx = {
      "@media (min-width:960px)": {
        width: "calc(100% - 200px)",
        marginLeft: 200,
      },
      "@media (min-width:1280px)": {
        width: "calc(100% - 256px)",
        marginLeft: 256,
      },
    };
  }

  return (
    <Box
      // display="flex"
      // flexGrow={1}
      position="fixed"
      top="auto"
      left={0}
      bottom={0}
      borderTop={1}
      borderColor={grey[300]}
      px={3}
      py={1}
      width="100%"
      zIndex={10}
      bgcolor="#fff"
      sx={sx}
      {...rest}
    >
      {children}
    </Box>
  );
};
export default InnerFooter;
