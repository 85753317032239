import {Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {NavLink, useNavigate} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {Currency} from "../../../../components/Accounting";
import {Breadcrumbs} from "../../../../components/Breadcrumbs";
import {PageHeader} from "../../../../components/PageHeader";
import {PaginationWithPageSize} from "../../../../components/Pagination";
import PaperPanel from "../../../../components/PaperPanel";
import {ReactTableMui} from "../../../../components/ReactTableMui";
import useBlockUI from "../../../../hooks/useBlockUI";
import {usePostCurrentPage, useSentinelListAPI} from "../../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../../theme";
import {DirectCostBatchDialogForm} from "../DirectCostForms";

const HomeDepotBatchList = (props) => {
  const novoClasses = makeNovoClasses();
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const {
    query: batchQuery,
    create: createBatch,
    update: updateBatch,
    delete: deleteBatch,
  } = useSentinelListAPI(`accounting-tools/direct-cost-batches/?page_size=${pageSize}&page=${page}&type=home_depot`, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });
  const postCurrentPage = usePostCurrentPage(
    {},
    {
      baseURL: "",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  const batches = batchQuery.data.results;
  return (
    <>
      <Helmet title={`Home Depot Uploads`} />
      <Breadcrumbs>
        <Link color="inherit" href="/v2/">
          Dashboard
        </Link>
        <Typography>Accounting Tools</Typography>
        <Typography>Direct Costs Upload</Typography>
        <Typography color="textPrimary">Home Depot Uploads</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Home Depot Uploads</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={batchQuery.isFetching}>
          <PaperPanel.Header.Title>
            Home Depot Uploads{" "}
            {batches.length !== 0 && (
              <>
                ({batches.length}/{batchQuery.data.count})
              </>
            )}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  // blockUI.blockUI();
                  setShowCreateDialog(true);
                }}
              >
                Create
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => batchQuery.refetch()}
                isFetching={batchQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["amount"]}
            columns={[
              {
                Header: "Description",
                accessor: "description",
                disableSortBy: true,
                Cell: ({value, row}) => (
                  <Link
                    to={`/v2/accounting-tools/direct-cost-batches/home-depot/${row.original.id}/`}
                    component={NavLink}
                    underline="always"
                  >
                    {value}
                  </Link>
                ),
              },
              {
                Header: "Accounting Date",
                disableSortBy: true,
                accessor: "date",
              },
              {
                Header: "Amount",
                disableSortBy: true,
                accessor: "amount",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
              },
            ]}
            initialState={
              {
                //   sortBy: [
                //     {
                //       id: "date_stamp",
                //       desc: true,
                //     },
                //   ],
              }
            }
            data={batches}
          />
        </PaperPanel.Body>
        {/* {batchQuery.data.total_pages > 1 && ( */}
        <PaperPanel.Footer display="flex" justifyContent="flex-end">
          <PaginationWithPageSize
            count={batchQuery.data.total_pages}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        </PaperPanel.Footer>
        {/* )} */}
      </PaperPanel>

      <DirectCostBatchDialogForm
        isOpen={showCreateDialog}
        isNew={true}
        handleClose={() => {
          setShowCreateDialog(false);
        }}
        initialValues={{
          date: moment().format("YYYY-MM-DD"),
        }}
        onSubmit={(values) => {
          setShowCreateDialog(false);
          setTimeout(() => blockUI.blockUI("Creating...")); // need to setTimeout to allow dialog to close
          let formData = new FormData();
          Object.entries(values).map(([key, value]) => {
            /* @ts-ignore */
            formData.append(key, value);
          });
          postCurrentPage
            .mutateAsync(formData)
            .then((data) => {
              navigate(data.url);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
      />
      {/* <pre>{JSON.stringify(batchQuery, null, 2)}</pre> */}
    </>
  );
};
export default HomeDepotBatchList;
