import {Box, Typography} from "@material-ui/core";
import React from "react";

const StickerSpeedBump = (props) => {
  const {project, twilioContact, refreshStickerIssued, ...rest} = props;
  const isSpanish = twilioContact?.language === "SP";

  const [timer, setTimer] = React.useState(30);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setTimer((prevTimer) => prevTimer - 1);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  React.useEffect(() => {
    if (timer === 0) {
      refreshStickerIssued().then(() => setTimer(30));
    }
  }, [timer]);

  return (
    <>
      <Box textAlign="center" mb={2}>
        <Typography variant="h5">
          {isSpanish ? (
            <>
              Consulte al personal de NOVO para obtener su etiqueta de Orientación de seguridad. Si usted es un capataz
              de cuadrilla, por favor identifíquese.
            </>
          ) : (
            <>
              See NOVO personnel for your Safety Orientation sticker. If you are a crew foreman, please identify
              yourself.
            </>
          )}
        </Typography>
      </Box>
      <Box textAlign="center">
        {isSpanish ? `Comprobando la etiqueta en ${timer} segundos...` : `Checking for sticker in ${timer} seconds...`}
      </Box>
    </>
  );
};
export default StickerSpeedBump;
