import {TextField as _TextFieldMui} from "@material-ui/core";
import {showErrorOnChange} from "mui-rff";
import {Field} from "react-final-form";
import {identity} from "../../../../js/components/FinalFormEnhancers";

export const FileFieldMui = (props) => {
  const {name, ...rest} = props;
  return (
    <Field
      name={name}
      parse={identity}
      validate={(value) => {
        if (props.required) {
          return value ? undefined : "Required";
        }
      }}
      {...rest}
      type="file"
    >
      {(props) => {
        const {
          input: {name, value, onChange, ...restInput},
          meta,
          required,
          accept,
          fullWidth = true,
          helperText,
          showError = showErrorOnChange,
          ...rest
        } = props;

        const {error, submitError} = meta;
        const isError = showError({meta});

        return (
          <_TextFieldMui
            InputLabelProps={{
              shrink: true,
            }}
            variant="outlined"
            size="small"
            fullWidth={fullWidth}
            helperText={isError ? error || submitError : helperText}
            error={isError}
            onChange={(event) => {
              // @ts-ignore
              onChange(event.target.files[0]);
            }}
            // onChange={onChange}
            name={name}
            // value={value?.name || ""}
            required={required}
            inputProps={{required, accept, ...restInput}}
            {...rest}
            type="file"
          />
        );
      }}
    </Field>
  );
};
