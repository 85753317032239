import {useEffect, useState} from "react";

export const useLocalStorage = (key, initialValue) => {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = window.localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.error(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      window.localStorage.setItem(key, JSON.stringify(valueToStore));
    } catch (error) {
      console.error(error);
    }
  };
  const deleteKey = () => {
    try {
      setStoredValue(undefined);
      window.localStorage.removeItem(key);
    } catch (error) {
      console.error(error);
    }
  };
  return [storedValue, setValue, deleteKey];
};

// Helper function to set and get data in localStorage with expiration
const setLocalStorageWithExpiration = (key, value, ttlDays) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttlDays * 60 * 60 * 24 * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
};

const getLocalStorageWithExpiration = (key) => {
  const itemStr = localStorage.getItem(key);

  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const useLocalStorageWithExpiration = (key, initialValue, ttlDays = 7) => {
  const [storedValue, setStoredValue] = useState(() => {
    const localStorageValue = getLocalStorageWithExpiration(key);
    return localStorageValue !== null ? localStorageValue : initialValue;
  });

  const setValue = (value) => {
    setStoredValue(value);
    setLocalStorageWithExpiration(key, value, ttlDays);
  };

  useEffect(() => {
    const handleStorageChange = (e) => {
      if (e.key === key) {
        const localStorageValue = getLocalStorageWithExpiration(key);
        setStoredValue(localStorageValue !== null ? localStorageValue : initialValue);
      }
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, [key, initialValue]);

  return [storedValue, setValue];
};
