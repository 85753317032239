import {faTasks} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Typography} from "@material-ui/core";
import moment from "moment-timezone";
import ReactDiffViewer from "react-diff-viewer";
import {Helmet} from "react-helmet";
import BlockUI from "../../components/GlobalLoaders";
import {PageHeader} from "../../components/PageHeader";
import PaperPanel from "../../components/PaperPanel";
import {useFetchCurrentPage} from "../../hooks/useSentinelAPI";

const ProjectUserAuditView = (props) => {
  const {project} = props;
  const auditQuery = useFetchCurrentPage({
    initialData: {
      events: [],
    },
  });
  const events = auditQuery.data.events;
  const display = auditQuery.data.display ? auditQuery.data.display : project.display;

  if (!auditQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message={`Fetching audit log...`} />;
  }

  return (
    <>
      <Helmet title={`${display} - Audit`} />

      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>{`${display} - Audit`}</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={auditQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faTasks} /> Events
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => auditQuery.refetch()}
                isFetching={auditQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {events.map((event, i) => {
            return (
              <Box key={event.id} mt={i === 0 ? 0 : 3}>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  bgcolor="grey.50"
                  p={1}
                  borderTop={i === 0 ? 0 : 1}
                  borderColor="grey.400"
                >
                  <Typography>
                    {event.actor || "Sentinel"} - {event.event_type}
                  </Typography>
                  <Typography>
                    {moment.tz(event.timestamp, moment.tz.guess()).format("YYYY-MM-DD hh:mm A z")}
                  </Typography>
                </Box>
                <ReactDiffViewer
                  oldValue={JSON.stringify(event.old, null, 2)}
                  newValue={JSON.stringify(event.new, null, 2)}
                  splitView={true}
                  hideLineNumbers={true}
                />
              </Box>
            );
          })}
        </PaperPanel.Body>
      </PaperPanel>
      {/* <pre>{JSON.stringify(events, null, 2)}</pre> */}
    </>
  );
};

export default ProjectUserAuditView;
