import axios from "axios";
import MockAdapter from "axios-mock-adapter";
import {getCookie} from "../js/utils/cookie";

export const axiosAPI = axios.create({
  baseURL: "/api/v1/",
  // timeout: 1000,
  headers: {
    Accept: "application/json; charset=utf-8",
    "X-CSRFToken": getCookie("csrftoken"),
    "X-Requested-With": "XMLHttpRequest",
  },
});
axiosAPI.interceptors.response.use(null, (error) => {
  console.error(error);
  return Promise.reject(error);
});

const IS_TEST = process.env.NODE_ENV === "test";

if (IS_TEST) {
  const mockAPI = new MockAdapter(axiosAPI);
  mockAPI.onAny().reply(200, {
    results: [],
    metadata: {},
  });
}
