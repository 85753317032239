import {Box, makeStyles, Typography} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Currency} from "./Accounting";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    title: {
      fontSize: 20,
      [theme.breakpoints.up("md")]: {
        fontSize: 24,
      },
    },
    spark: {
      borderColor: palette.grey[400],
      borderLeftStyle: "dotted",
      "& h3": {
        textTransform: "uppercase",
        fontSize: 12,
        fontWeight: "normal",
      },
      "& h4": {
        fontSize: 14,
      },
    },
  };
});

export const PageHeader = (props: {
  children?: React.ReactNode;
  // All other props
  [rest: string]: any;
}) => {
  const {children, ...rest} = props;
  return (
    <Box display="flex" flexDirection={{xs: "column", sm: "row"}} pb={1} {...rest}>
      {children}
    </Box>
  );
};

PageHeader.Left = (props) => {
  const {children, ...rest} = props;
  return (
    <Box display="flex" flexGrow={1} {...rest}>
      {children}
    </Box>
  );
};

const Right = (props: {
  children?: React.ReactNode;
  border?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {children, border, ...rest} = props;
  return (
    <Box display="flex" borderLeft={border ? 1 : 0} justifyContent="flex-end" {...rest}>
      {children}
    </Box>
  );
};
PageHeader.Right = Right;

PageHeader.Title = (props) => {
  const {children, className, ...rest} = props;
  const classes = useStyles();
  return (
    // <Box flexWrap="nowrap" flexShrink={0}>
    <Box>
      <Typography variant="h2" className={classnames(classes.title, className)} {...rest}>
        {children}
      </Typography>
    </Box>
  );
};

const RightCurrencySpark = (props: {
  title: string;
  number: number;
  color?: string;
  className?: string;
  border?: boolean;
  precision?: number;
  symbol?: string;
  children?: React.ReactNode;
  [rest: string]: any;
}) => {
  const {title, number, border, color = "inherit", precision = 0, symbol, className, ...rest} = props;
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      textAlign="right"
      borderLeft={border ? 1 : 0}
      px={1}
      className={classnames(classes.spark, className)}
      {...rest}
    >
      <Box>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box>
        <Typography variant="h4">
          <Currency number={number} precision={precision} color={color} symbol={symbol} />
        </Typography>
      </Box>
    </Box>
  );
};
Right.CurrencySpark = RightCurrencySpark;

const RightSpark = (props: {
  title: string;
  value: string;
  color?: string;
  className?: string;
  border?: boolean;

  children?: React.ReactNode;
  [rest: string]: any;
}) => {
  const {title, value, border, color = "inherit", className, ...rest} = props;
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      textAlign="right"
      borderLeft={border ? 1 : 0}
      px={1}
      className={classnames(classes.spark, className)}
      {...rest}
    >
      <Box>
        <Typography variant="h3">{title}</Typography>
      </Box>
      <Box color={color}>
        <Typography variant="h4">{value}</Typography>
      </Box>
    </Box>
  );
};
Right.RightSpark = RightSpark;
