import {faEdit} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Checkbox, DialogContent, Grid, Tooltip, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {LabelValue} from "../../../components/LabelValue";
import {LegacyUILink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {
  useFetchCurrentPage,
  usePostCurrentPage,
  useSentinelDetailAPI,
  useSentinelListAPI,
} from "../../../hooks/useSentinelAPI";
import {JobStartForm} from "./ProjectJobStartForms";

const ProjectJobStart = (props) => {
  const {...rest} = props;
  const [submitConfirmationIsOpen, setSubmitConfirmationIsOpen] = React.useState(false);
  const [pendingFormValues, setPendingFormValues] = React.useState({} as any);
  const {projectId} = useParams();
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      externalTeam: {},
    },
  });
  const blockUI = useBlockUI();
  const pageData = pageDataQuery.data;

  const postCurrentPage = usePostCurrentPage();

  const {
    query: projectQuery,
    create: createProject,
    update: updateProject,
    delete: deleteProject,
    rpc: projectRPC,
  } = useSentinelDetailAPI(["projects", parseInt(projectId)], {
    initialData: {},
  });
  const project = projectQuery.data as any;

  const {
    query: companyQuery,
    create: createCompany,
    update: updateCompany,
    delete: deleteCompany,
    rpc: companyRPC,
    // onDragEnd,
  } = useSentinelListAPI(`projects/${project.id}/companies/?page_size=10000`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
    enabled: false,
  });

  // const projectCompanies = companyQuery?.data?.results;

  // const getProjectCompany = (company) => {
  //   return projectCompanies.find((projectCompany) => projectCompany.company.id === company?.id);
  // };

  // console.log(getProjectCompany(pageData?.externalTeam?.architect));

  const onChangeCopy = (e, value, company) => {
    blockUI.blockUI("Saving...");
    company.copy_invoice = value;
    updateCompany.mutateAsync(company).then(() => {
      blockUI.unblockUI();
    });
  };

  if (!pageDataQuery.isFetchedAfterMount || !projectQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Project Info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Project Directory`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Project Start</Typography>
      </ProjectBreadcrumbs>

      {project.job_start_submitted &&
        (pageData?.timberlineJob ? (
          <Box mb={2}>
            <Alert severity="info">
              This job start form was submitted on {moment(project.job_start_submitted).format("LL")} by{" "}
              {pageData.jobStartSubmittedBy}. It has been linked to Timberline job {project.timberline_job}.
            </Alert>
          </Box>
        ) : (
          <Box mb={2}>
            <Alert severity="warning">
              This job start form was submitted on {moment(project.job_start_submitted).format("LL")} by{" "}
              {pageData.jobStartSubmittedBy}. It has not been linked to a to a Timberline job yet.
            </Alert>
          </Box>
        ))}

      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Project Details</PaperPanel.Header.Title>

          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Tooltip title="Job Start PDF">
                <span>
                  <PaperPanel.Header.PDFButton href={`/reports2/projects/${projectId}/start/`} target="_blank" />
                </span>
              </Tooltip>
              <Box mr={1} />
              <PaperPanel.Header.Button
                href={`/projects/${projectId}/update/`}
                startIcon={<FontAwesomeIcon icon={faEdit} />}
              >
                Edit Project Details
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body mx={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <LabelValue label="Name" value={project.display} />
              <LabelValue label="Project Executive" value={pageData.internalTeam?.projectExecutive} />
              <LabelValue label="Project Manager" value={pageData.internalTeam?.projectManager} />
              <LabelValue label="Superintendent" value={pageData.internalTeam?.superintendent} />
              <LabelValue label="Project Engineer" value={pageData.internalTeam?.projectEngineer} />
            </Grid>
            <Grid item xs={12} sm={8}>
              <LabelValue label="Address" value={pageData.formData?.full_address} />
              <LabelValue label="Additional Insured" value={project?.additional_insured} />
            </Grid>
          </Grid>
        </PaperPanel.Body>
      </PaperPanel>
      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Project Directory</PaperPanel.Header.Title>

          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Box mr={1} />
              <PaperPanel.Header.Button
                href={`/v2/projects/${projectId}/directory/`}
                startIcon={<FontAwesomeIcon icon={faEdit} />}
              >
                Edit Project Directory
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body mx={1} p={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <DirectoryCard
                projectCompany={pageData.externalTeam?.client}
                contact={pageData.externalTeam?.clientContact}
                label="Client"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DirectoryCard
                projectCompany={pageData.externalTeam?.architect}
                label="Architect"
                onChangeCopy={onChangeCopy}
                contact={pageData.externalTeam?.architectContact}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DirectoryCard
                projectCompany={pageData.externalTeam?.constructionManager}
                label="Construction Manager"
                onChangeCopy={onChangeCopy}
                contact={pageData.externalTeam?.constructionManagerContact}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DirectoryCard
                projectCompany={pageData.externalTeam?.buildingOwner}
                label="Building Owner"
                contact={pageData.externalTeam?.buildingOwnerContact}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <DirectoryCard
                projectCompany={pageData.externalTeam?.tenant}
                contact={pageData.externalTeam?.tenantContact}
                label="Tenant"
                onChangeCopy={onChangeCopy}
              />
            </Grid>
          </Grid>
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={3} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Job Start Form</PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <JobStartForm
            onSubmit={(values) => {
              const submitMode = values.submitMode;
              if (submitMode === "save") {
                blockUI.blockUI("Saving...");
                postCurrentPage.mutateAsync(values).then((result) => {
                  pageDataQuery.refetch().then(() => {
                    blockUI.unblockUI();
                  });
                });
              } else {
                setPendingFormValues(values);
                setSubmitConfirmationIsOpen(true);
              }
            }}
            initialValues={{...pageData.formData}}
            needsJobStartSubmission={pageData.formData.needs_job_start_submission}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <ConfirmationDialog
        isOpen={submitConfirmationIsOpen}
        onDeny={() => setSubmitConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Submitting...");
          postCurrentPage.mutateAsync(pendingFormValues).then((result) => {
            window.location = result.url;
          });
        }}
        title="Submit This Job?"
      >
        <DialogContent>
          You are about to submit the Job Start Form to accounting with a suggested job number of:{" "}
          <strong>{pageData?.suggestedJobNumber}</strong>
        </DialogContent>
      </ConfirmationDialog>
      <LegacyUILink mt={2} href={`/projects/${project.id}/start/`} />
      {/* <h1>JOB START</h1>
      <h2>PageData</h2>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <h2>Project</h2>
      <pre>{JSON.stringify(project, null, 2)}</pre> */}
    </>
  );
};

export default ProjectJobStart;

const DirectoryCard = (props) => {
  const {contact, label, onChangeCopy, projectCompany} = props;

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header flexDirection="row">
          <PaperPanel.Header.Title maxWidth={"100%"}>{label}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action justifyContent="flex-end" mb={-2} mt={-1.5}>
              {onChangeCopy && (
                <Box>
                  <Checkbox
                    checked={projectCompany?.copy_invoice}
                    onChange={(event, value) => onChangeCopy(event, value, projectCompany)}
                    disabled={!projectCompany?.id}
                  />
                  <Typography component="span" color={!projectCompany ? "textSecondary" : "textPrimary"}>
                    Copy Invoice
                  </Typography>
                </Box>
              )}
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body mx={1} minHeight={65}>
          <strong>{projectCompany?.company?.name}</strong>
          <Typography>{contact?.full_name}</Typography>
          <Typography>{projectCompany?.company_location?.full_address}</Typography>
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};
