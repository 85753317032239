import {faArrowRightArrowLeft, faDownload, faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Divider, Grid, Link, MenuItem, Typography} from "@material-ui/core";
import {groupBy, keyBy, sortBy} from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {
  CreateMissingBoxFoldersConfirmationDialog,
  FixBoxFolderNamesConfirmationDialog,
  MoveToCorrectBoxFoldersConfirmationDialog,
} from "../../../components/Box";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {DropdownButton} from "../../../components/Buttons";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {usePostCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import getQueryStringFromIds from "../../../utils/getQueryStringFromIds";
import {
  SubmittalItemBulkUpdateDialogForm,
  SubmittalItemUpdateDialogForm,
  SubmittalItemUploadDialogForm,
} from "./SubmittalItemForms";
import {SubmittalItemPaperItem} from "./SubmittalItemPaperItem";

const ProjectSubmittalItems = (props) => {
  const {project, userContact, ...rest} = props;

  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = useLocalStorage("submittalItemPageSize", 100);

  const [currentGrouping, setCurrentGrouping] = useLocalStorage("projectSubmittalItemsGrouping", "packages");
  const [queryOrdering, setQueryOrdering] = useLocalStorage(
    "projectSubmittalItemsQueryOrdering",
    "submittal_package__number"
  );
  const [groupedItems, setGroupedItems] = React.useState({});
  const [groupLookup, setGroupLookup] = React.useState({});
  const [bulkUpdateIsOpen, setBulkUpdateIsOpen] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [deleteMultipleConfirmationIsOpen, setDeleteMultipleConfirmationIsOpen] = React.useState(false);
  const [activeSubmittalItem, setActiveSubmittalItem] = React.useState({} as any);
  const [uploadDialogIsOpen, setUploadDialogIsOpen] = React.useState(false);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [createMissingBoxFolders, setCreateMissingBoxFolders] = React.useState(false);
  const [fixBoxFolderLocationsDialog, setFixBoxFolderLocationsDialog] = React.useState(false);
  const [fixBoxFolderNamesDialog, setFixBoxFolderNamesDialog] = React.useState(false);
  const [initialFocusField, setInitialFocusField] = React.useState(null);

  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Status",
    "Spec Section",
    "Package",
    "No Package",
    "Responsible Company",
    "Search",
  ]);

  const filterParams = {
    status: filterOptions.Status.value,
    responsible_company_id: filterOptions["Responsible Company"].value,
    spec_section_code: filterOptions["Spec Section"].value,
    q: filterOptions.Search.value,
    ordering: queryOrdering,
    submittal_package_id: filterOptions.Package.value === "NULL" ? "" : filterOptions.Package.value,
    submittal_package_id__isnull: filterOptions.Package.value === "NULL" ? "True" : "",
  };

  const {
    query: submittalItemsQuery,
    create: createSubmittal,
    update: updateSubmittal,
    delete: deleteSubmittal,
    rpc: submittalItemRPC,
    // onDragEnd: purchaseOrderOnDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/submittals/items/?parent__isnull=True&page_size=${pageSize}&page=${page}&${qs.stringify(
      filterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const submittalItems = submittalItemsQuery.data.results;

  const {query: submittalPackageQuery} = useSentinelListAPI(`projects/${project.id}/submittals/items/packages/`, {
    initialData: [],
  });

  const submittalPackageOptions = submittalPackageQuery.data;

  const {query: responsibleCompanyQuery} = useSentinelListAPI(
    `projects/${project.id}/submittals/items/responsible-companies/`,
    {
      initialData: [],
    }
  );
  const responsibleCompanyOptions = responsibleCompanyQuery.data;

  const allItemIds = submittalItems.map((item) => item.id);
  const sortedGroupKeys = sortBy(groupLookup, "display").map((item: {id: number}) => item?.id);
  // const sortedGroupKeys = map(groupLookup, (group) => group?.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedItemIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemsSelected,
  } = useSelectIds(allItemIds);

  const {
    selectedIds: selectedGroupIds,
    addSelectedId: addSelectedGroupId,
    addSelectedIds: addSelectedGroupIds,
    removeSelectedId: removeSelectedGroupId,
    removeSelectedIds: removeSelectedGroupIds,
    addAllSelectedIds: addAllSelectedGroupIds,
    removeAllSelectedIds: removeAllSelectedGroupIds,
    allIdsSelected: allGroupIdsSelected,
  } = useSelectIds([sortedGroupKeys]);

  /* Old Method - replaced by lodash keyBy */
  // const packagesLookup = submittalItems.reduce((lookupTable, item) => {
  //   const lookupCode = item?.submittal_package?.id;
  //   if (lookupCode && !lookupTable[lookupCode]) {
  //     lookupTable[lookupCode] = item.submittal_package;
  //     return lookupTable;
  //   }
  //   return lookupTable;
  // }, {});

  const {query: statusQuery} = useSentinelListAPI(`projects/${project.id}/submittals/items/status`, {
    initialData: [],
  });

  const statusOptions = statusQuery.data;

  const {query: specSectionQuery} = useSentinelListAPI(`projects/${project.id}/submittals/items/spec-sections`, {
    initialData: [],
  });

  const specSectionOptions = specSectionQuery.data;

  const navigate = useNavigate();
  const postCurrentPage = usePostCurrentPage(
    {},
    {
      baseURL: "",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  // const submittalIdQueryString = () => {
  //   if (!selectedItemIds.size) {
  //     return `&${qs.stringify(filterParams)}`;
  //   } else {
  //     return `&pk=${Array.from(selectedItemIds).join("&pk=")}&${qs.stringify(filterParams)}`;
  //   }
  // };

  const pdfQueryString = React.useMemo(
    () => getQueryStringFromIds(selectedItemIds, filterParams),
    [selectedItemIds, filterParams]
  );

  const groupByOptions = [
    {value: "csi_code", label: "CSI Code"},
    {value: "spec_section", label: "Spec Section"},
    {value: "packages", label: "Submittal Package"},
  ];

  const getAdjacentSubmittalItems = (item) => {
    const index = submittalItems.indexOf(item);
    const groupLength = submittalItems.length;
    const previous = index - 1 > -1 ? index - 1 : null;
    const next = index < groupLength - 1 ? index + 1 : null;
    return {previous: submittalItems[previous], next: submittalItems[next]};
  };

  React.useEffect(() => {
    removeAllSelectedGroupIds();
    // removeAllSelectedItemIds(); - Leave Ids selected for ease of use
    switch (currentGrouping) {
      case "csi_code":
        setQueryOrdering("csi_code__code");
        setGroupedItems(groupBy(submittalItems, (item) => item?.csi_code?.id));

        setGroupLookup(
          keyBy(
            submittalItems.map((item) => item?.csi_code),
            "id"
          )
        );

        break;
      case "spec_section":
        setQueryOrdering("spec_section_code");
        setGroupedItems(groupBy(submittalItems, (item) => item?.spec_section_code));
        setGroupLookup(
          keyBy(
            submittalItems.map((item, i) => {
              return {id: item.spec_section_code, display: item.spec_section_code};
            }),
            "id"
          )
        );

        break;
      case "packages":
        setQueryOrdering("submittal_package__number");
        setGroupedItems(groupBy(submittalItems, (item) => item?.submittal_package?.id));
        setGroupLookup(
          keyBy(
            submittalItems.map((item) => item.submittal_package),
            "id"
          )
        );

        break;
    }
  }, [currentGrouping, submittalItemsQuery.dataUpdatedAt]);

  return (
    <>
      <Helmet title={`${project.display} - Submittal Items`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Submittal Items</Typography>
      </ProjectBreadcrumbs>

      <PaperPanel>
        <PaperPanel.Header isLoading={submittalItemsQuery.isLoading || submittalItemsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              label={"Select All"}
              onChange={(event, value) => {
                if (value) {
                  addSelectedItemIds(allItemIds);
                  addSelectedGroupIds(sortedGroupKeys);
                } else {
                  removeAllSelectedItemIds();
                  removeAllSelectedGroupIds();
                }
              }}
              indeterminate={!allItemsSelected && selectedItemIds.size < allItemIds.length && selectedItemIds.size}
              checked={allItemsSelected}
            />{" "}
            <FontAwesomeIcon icon={faArrowRightArrowLeft} /> Submittal Items
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setShowCreateDialog(true);
                }}
                // disabled={project.is_closed}
              >
                Create
              </PaperPanel.Header.CreateButton>
              <Box ml={1} />
              <PaperPanel.Header.EditButton disabled={!selectedItemIds.size} onClick={() => setBulkUpdateIsOpen(true)}>
                Edit Selected
              </PaperPanel.Header.EditButton>
            </PaperPanel.Header.Action>

            {/* <PaperPanel.Header.Action>
                <PaperPanel.Header.DeleteButton
                  disabled={!selectedItemIds.size}
                  onClick={() => setDeleteMultipleConfirmationIsOpen(true)}
                >
                  Delete Selected
                </PaperPanel.Header.DeleteButton>
              </PaperPanel.Header.Action> */}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons>
                <MenuItemHeader>Submittal Item Summary</MenuItemHeader>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/items/?${pdfQueryString}`}
                >
                  By Number
                </PaperPanel.Header.Menu.PDFMenuItem>{" "}
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/items/?by_spec=on${pdfQueryString}`}
                >
                  By Spec Section
                </PaperPanel.Header.Menu.PDFMenuItem>
                <MenuItemHeader border>Procurement Log Reports</MenuItemHeader>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/procurement-log/?v=basic${pdfQueryString}`}
                >
                  Basic
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/procurement-log/?v=simplified${pdfQueryString}`}
                >
                  Simplified
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/procurement-log/?v=detailed${pdfQueryString}`}
                >
                  Detailed
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/procurement-log/?v=legacy${pdfQueryString}`}
                >
                  Legacy
                </PaperPanel.Header.Menu.PDFMenuItem>
                <MenuItemHeader border>Tracking Log</MenuItemHeader>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/tracking-log/?${pdfQueryString}`}
                >
                  Tracking Log
                </PaperPanel.Header.Menu.PDFMenuItem>
                <MenuItemHeader border>Other</MenuItemHeader>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/items/ball-in-court/?${pdfQueryString}`}
                >
                  Ball In Court
                </PaperPanel.Header.Menu.PDFMenuItem>
              </PaperPanel.Header.PDFButtons>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <DropdownButton
                size="small"
                title={`Group By: ${groupByOptions.find((option) => option.value === currentGrouping).label}`}
              >
                {(setOpen) => (
                  <div>
                    {groupByOptions.map((item) => (
                      <MenuItem
                        onClick={() => {
                          setOpen(false);
                          setCurrentGrouping(item.value);
                          submittalItemsQuery.refetch();
                        }}
                        selected={currentGrouping === item.value}
                        key={item.value}
                      >
                        {item.label}
                      </MenuItem>
                    ))}
                  </div>
                )}
              </DropdownButton>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border pr={0}>
              <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setUploadDialogIsOpen(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faUpload} fixedWidth />
                      Upload Submittal Items
                    </PaperPanel.Header.Menu.MenuItem>

                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        // window.open(`/submittals/items/${project.id}/download/`, "_self");
                      }}
                      href={`/submittals/items/${project.id}/download/`}
                      target="_blank"
                      component={Link}
                    >
                      <FontAwesomeIcon icon={faDownload} fixedWidth /> Download Submittal Items
                    </PaperPanel.Header.Menu.MenuItem>
                    <Divider />
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setCreateMissingBoxFolders(true);
                      }}
                    >
                      Create Missing Box Folders
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setFixBoxFolderLocationsDialog(true);
                      }}
                    >
                      Fix Box Folder Locations
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setFixBoxFolderNamesDialog(true);
                      }}
                    >
                      Fix Box Folder Names
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.Menu>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action pl={0}>
              <PaperPanel.Header.RefreshButton
                isFetching={submittalItemsQuery.isFetching}
                onClick={() => submittalItemsQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                  removeAllSelectedGroupIds();
                  removeAllSelectedItemIds();
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Status"
                name="status"
                options={statusOptions}
                value={filterOptions.Status.value}
                showStatusIcon
                statusType="submittal"
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                  removeAllSelectedGroupIds();
                  removeAllSelectedItemIds();
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Spec Section"
                name="spec_section"
                options={[{value: "EMPTY", label: "(Blank)"}, ...specSectionOptions]}
                // options={specSectionOptions}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Spec Section", value, label);
                  removeAllSelectedGroupIds();
                  removeAllSelectedItemIds();
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Package"
                name="Package"
                // options={[{value: null, label: "(None)"}, ...submittalPackageOptions]}
                options={[{value: "NULL", label: "(None)"}, ...submittalPackageOptions]}
                value={filterOptions.Package.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Package", value, label);
                  removeAllSelectedGroupIds();
                  removeAllSelectedItemIds();
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="Responsible Company"
                name="Responsible Company"
                options={responsibleCompanyOptions}
                value={filterOptions["Responsible Company"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Responsible Company", value, label);
                  removeAllSelectedGroupIds();
                  removeAllSelectedItemIds();
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                  removeAllSelectedGroupIds();
                  removeAllSelectedItemIds();
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                  removeAllSelectedGroupIds();
                  removeAllSelectedItemIds();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={submittalItemsQuery.data.total_pages}
                  page={page}
                  // pageSize={pageSize}
                  setPage={setPage}
                  // setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          {sortedGroupKeys.map((groupId) => {
            const items = groupedItems[groupId];
            const group = groupLookup[groupId];

            const groupItemIds = items.map((item) => item.id);

            const allIdsSelected = groupItemIds.every((itemId) => selectedItemIds.has(itemId));
            const anyIdsSelected = groupItemIds.some((itemId) => selectedItemIds.has(itemId));

            return (
              <React.Fragment key={groupId}>
                <PaperItem.Header>
                  <PaperItem.Left>
                    <PaperItem.SelectedCheckbox
                      indeterminate={Boolean(!allIdsSelected && anyIdsSelected)}
                      // checked={selectedGroupIds.has(groupId) && allIdsSelected}
                      checked={allIdsSelected}
                      onChange={(event, value) => {
                        if (value) {
                          addSelectedItemIds(groupItemIds);
                          addSelectedGroupId(groupId);
                        } else {
                          removeSelectedItemIds(groupItemIds);
                          removeSelectedGroupId(groupId);
                        }
                      }}
                    />
                  </PaperItem.Left>
                  <PaperItem.Body>
                    {queryOrdering === "submittal_package__number" && group?.url && group?.status_display ? (
                      <>
                        <StatusLabel status={group?.status_display} hint="submittalPackage" mr={1} />
                        <Link href={group?.url} target="_blank" underline="always">
                          <strong>{group?.display || "None"}</strong>
                        </Link>{" "}
                        {/* <FontAwesomeIcon icon={faExternalLink} /> */}
                      </>
                    ) : (
                      <strong key={groupId || -1}>{group?.display || "None"}</strong>
                    )}
                  </PaperItem.Body>
                </PaperItem.Header>

                {items.map((item) => {
                  return (
                    <>
                      <SubmittalItemPaperItem
                        key={item.id}
                        submittalItem={item}
                        isSelected={selectedItemIds.has(item.id)}
                        onChangeSelectedSubmittalItem={(event, value) => {
                          if (value) {
                            addSelectedItemId(item.id, event.nativeEvent.shiftKey);
                          } else {
                            removeSelectedItemId(item.id);
                          }
                        }}
                        currentGrouping={currentGrouping}
                        onEditSubmittalItem={() => {
                          setActiveSubmittalItem(item);
                          setShowUpdateDialog(true);
                        }}
                        onDeleteSubmittalItem={() => {
                          setActiveSubmittalItem(item);
                          setDeleteConfirmationIsOpen(true);
                        }}
                        // highlight={item.id === activeSubmittalItem?.id}
                      />
                    </>
                  );
                })}
              </React.Fragment>
            );
          })}
        </PaperPanel.Body>
        {(submittalItemsQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={submittalItemsQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/submittals/items/legacy/`} mt={2} />

      <SubmittalItemUpdateDialogForm
        projectId={project.id}
        isOpen={showCreateDialog}
        isNew
        handleClose={() => {
          setShowCreateDialog(false);
        }}
        initialValues={{
          number: submittalItemsQuery?.data?.metadata?.next_number,
          date_created: moment().format("YYYY-MM-DD"),
          authored_by: userContact,
          quantity: 1,
          revision: 0,
        }}
        onSubmit={(values, form) => {
          // Check values for some value submitMode
          const submitMode = values.submitMode;
          delete values.submitMode;
          if (submitMode === "addAnother") {
            setInitialFocusField(null);
          }
          if (submitMode === "saveAndClose") {
            blockUI.blockUI("Creating...");

            createSubmittal.mutateAsync(values).then(() => {
              submittalItemsQuery.refetch().then(() => {
                blockUI.unblockUI();
                setShowCreateDialog(false);
              });
            });
          }
          if (submitMode === "addAnother") {
            blockUI.blockUI("Creating...");
            // const focusElement = createFormRef.current[10];
            // const focusElement = createFormRef.current.elements.description;
            createSubmittal.mutateAsync(values).then(() => {
              // focusElement.focus();
              submittalItemsQuery.refetch().then(() => {
                form.restart();
                setInitialFocusField("description");
                blockUI.unblockUI();
              });
            });
          }
        }}
        // isReadOnly={project.is_closed}
        initialFocusField={initialFocusField}
      />

      <SubmittalItemUpdateDialogForm
        projectId={project.id}
        isOpen={showUpdateDialog}
        handleClose={() => {
          setShowUpdateDialog(false);
          setInitialFocusField(null);
          setActiveSubmittalItem({});
        }}
        initialValues={activeSubmittalItem}
        onSubmit={(values) => {
          // Missing keys key set to empty string. All because a bug in React Final Form - values with an empty string are getting set to undefined.
          // Parse is not getting called at the right time. https://github.com/final-form/react-final-form/issues/130
          values = {
            ...{spec_section_code: ""},
            ...values,
          };

          updateSubmittal.mutateAsync(values).then(() => submittalItemsQuery.refetch());
          setShowUpdateDialog(false);
        }}
        activeSubmittalItem={activeSubmittalItem}
        setActiveSubmittalItem={setActiveSubmittalItem}
        submittalItems={submittalItems}
        beforePrevNext={(values) => {
          blockUI.blockUI("Updating...");
          values = {
            ...{spec_section_code: ""},
            ...values,
          };
          return updateSubmittal.mutateAsync(values).then(() => {
            blockUI.unblockUI();
          });
        }}
        afterPrevNext={() => {
          submittalItemsQuery.refetch();
        }}
        initialFocusField={initialFocusField}
        setInitialFocusField={setInitialFocusField}
      />

      <SubmittalItemBulkUpdateDialogForm
        projectId={project.id}
        isOpen={bulkUpdateIsOpen}
        onSubmit={(values) => {
          if (values.delete_selected) {
            setBulkUpdateIsOpen(false);
            setDeleteMultipleConfirmationIsOpen(true);
            return;
          }

          blockUI.blockUI("Bulk updating...");
          setBulkUpdateIsOpen(false);

          const date_fields = [
            "start_date",
            "requested_date",
            "received_date",
            "returned_date",
            "delivered_date",
            "needed_onsite_date",
          ];

          if (values.clear_submittal_package) {
            values["submittal_package_id"] = null;
          }
          if (values.responsible_company) {
            values["responsible_company_id"] = values.responsible_company.id;
          }
          if (values.submittal_package) {
            values["submittal_package_id"] = values.submittal_package.id;
          }
          if (values.clear_responsible_company) {
            values["responsible_company_id"] = null;
          }
          if (values.clear_supplier) {
            values["supplier"] = "";
          }
          if (values.csi_code) {
            values["csi_code_id"] = values.csi_code.id;
          }

          if (values.include_in_pl) {
            values["is_included_in_pl"] = true;
          }
          if (values.exclude_from_pl) {
            values["is_included_in_pl"] = false;
          }

          date_fields.forEach((date) => {
            if (values[date]) {
              values[date] = moment(values[date]).format("YYYY-MM-DD");
            }
          });

          submittalItemRPC
            .mutateAsync({
              action: "update",
              data: {
                ids: Array.from(selectedItemIds),
                update: values,
              },
              method: "PUT",
            })
            .then(() => submittalItemsQuery.refetch().then(() => blockUI.unblockUI()))
            .catch(() => blockUI.unblockUI());
        }}
        onDelete={() => {
          setBulkUpdateIsOpen(false);
          setDeleteMultipleConfirmationIsOpen(true);
        }}
        isReadOnly={false}
        handleClose={() => setBulkUpdateIsOpen(false)}
        itemCount={selectedItemIds.size}
        submittalStatuses={statusOptions}
      />
      <SubmittalItemUploadDialogForm
        projectId={project.id}
        isOpen={uploadDialogIsOpen}
        onSubmit={(values) => {
          setUploadDialogIsOpen(false);
          setTimeout(() => blockUI.blockUI("Uploading...")); // need to setTimeout to allow dialog to close
          let formData = new FormData();
          Object.entries(values).map(([key, value]) => {
            /* @ts-ignore */
            formData.append(key, value);
          });
          postCurrentPage
            .mutateAsync(formData)
            .then((data) => {
              navigate(data.url);
              submittalItemsQuery.refetch();
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        handleClose={() => setUploadDialogIsOpen(false)}
      />

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          deleteSubmittal.mutateAsync(activeSubmittalItem.id);
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete {activeSubmittalItem?.display}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteMultipleConfirmationIsOpen}
        onApprove={() => {
          setDeleteMultipleConfirmationIsOpen(false);
          blockUI.blockUI("Deleting...");
          submittalItemRPC
            .mutateAsync({
              action: "delete",
              method: "DELETE",
              data: {ids: Array.from(selectedItemIds)},
            })
            .then(() => {
              removeAllSelectedItemIds();
              submittalItemsQuery.refetch().then(() => blockUI.unblockUI());
            });
        }}
        onDeny={() => setDeleteMultipleConfirmationIsOpen(false)}
      >
        You want to delete {selectedItemIds.size} {pluralize("item", selectedItemIds.size)}
      </ConfirmationDialog>

      <CreateMissingBoxFoldersConfirmationDialog
        isOpen={createMissingBoxFolders}
        setIsOpen={setCreateMissingBoxFolders}
        rpc={submittalItemRPC}
      />

      <MoveToCorrectBoxFoldersConfirmationDialog
        isOpen={fixBoxFolderLocationsDialog}
        setIsOpen={setFixBoxFolderLocationsDialog}
        rpc={submittalItemRPC}
      >
        You want to update the Box folder locations for all submittal items with Box folders? This will move all
        submittal items with a submittal package into the submittal package folder. All other items will be moved to a
        folder named "Submittal Items". This will run in the background and may take some time.
      </MoveToCorrectBoxFoldersConfirmationDialog>

      <FixBoxFolderNamesConfirmationDialog
        isOpen={fixBoxFolderNamesDialog}
        setIsOpen={setFixBoxFolderNamesDialog}
        rpc={submittalItemRPC}
      />

      {/* {props.user.is_superuser && (
        <>
          <h1>Submittal Items</h1>
          <pre>{JSON.stringify(submittalItemsQuery[0], null, 2)}</pre>
          <h1>Current Page Query</h1>
          <pre>{JSON.stringify(currentPageQuery, null, 2)}</pre>
          <h1>Submittal Items Query</h1>
          <pre>{JSON.stringify(submittalItemsQuery, null, 2)}</pre>
          <h1>Props</h1>
          <pre>{JSON.stringify(props, null, 2)}</pre>
        </>
      )} */}
    </>
  );
};

export default ProjectSubmittalItems;
