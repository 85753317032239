import {Box, makeStyles} from "@material-ui/core";
import {getContent} from "@mui-treasury/layout";
import classnames from "classnames";
import React from "react";
import styled from "styled-components";

const ContentTUI = getContent(styled);

const useStyles = makeStyles((theme) => {
  return {
    content: {
      flexGrow: 1,
    },
    toolbar: {
      backgroundColor: "#fff",
      zIndex: theme.zIndex.appBar - 1,
      top: "64px",
      [theme.breakpoints.down("xs")]: {
        top: "56px",
      },
    },
  };
});

const Content = (props: {children?: React.ReactNode; toolbar?: React.ReactNode}) => {
  const {children, ...rest} = props;
  const classes = useStyles();
  return (
    <ContentTUI className={classnames("content", classes.content)} {...rest}>
      <Box mb={10} px={{xs: 1, sm: 2}}>
        {children}
      </Box>
    </ContentTUI>
  );
};

export default Content;
