import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, FormControlLabel, Switch, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {Metadata} from "../../../../js/components/StyledComponents";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {StatusIcon} from "../../../components/Icons";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";

const ProjectBudgetCurrent = (props) => {
  const {project, ...rest} = props;
  const [showPendingColumns, setShowPendingColumns] = React.useState(false);
  const [hiddenColumns, setHiddenColumns] = React.useState(["pending_proposed_budget_revs", "pending_cost_revs"]);
  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      budgets: [],
      budgetRecalculatedDate: null,
    },
  });
  const postCurrentPage = usePostCurrentPage();

  const pageData = pageDataQuery.data;
  return (
    <>
      <Helmet title={`${project.display} - Current Budget`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Current Budget</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            <StatusIcon status={project.budget_status} tooltip={project.budget_status_display} showTooltip /> Current
            Budget
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <FormControlLabel
                control={
                  <Switch
                    checked={showPendingColumns}
                    onChange={(e, checked) => {
                      setShowPendingColumns((prev) => !prev);
                      if (checked) {
                        setHiddenColumns((prev) =>
                          prev.filter((item) => !["pending_proposed_budget_revs", "pending_cost_revs"].includes(item))
                        );
                      } else {
                        setHiddenColumns((prev) => {
                          prev.push("pending_proposed_budget_revs");
                          prev.push("pending_cost_revs");
                          return prev;
                        });
                      }
                      console.log("checked", checked);
                    }}
                  />
                }
                label="Show Pending Columns"
              />
            </PaperPanel.Header.Action>
            {project.timberline_job && (
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.Button href={`/v2/projects/${project.id}/budget/vs-jtd/`}>
                  Current vs JTD
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    pageData.budgets.map((budget) => {
                      return {
                        Code: budget.budget_code,
                        Allocation: budget.allocation,
                        Description: budget.description,
                        "Original Budget": {v: budget.original_budget, t: "n", z: "$#,##0.00"},
                        "Original Cost": {v: budget.original_cost, t: "n", z: "$#,##0.00"},
                        "Pending Budget Changes": {v: budget.pending_proposed_budget_revs, t: "n", z: "$#,##0.00"},
                        "Pending Cost Changes": {v: budget.pending_cost_revs, t: "n", z: "$#,##0.00"},
                        "Approved Budget Changes": {v: budget.approved_budget_revs, t: "n", z: "$#,##0.00"},
                        "Approved Cost Changes": {v: budget.approved_cost_revs, t: "n", z: "$#,##0.00"},
                        "Current Budget": {v: budget.current_budget, t: "n", z: "$#,##0.00"},
                        "Committed Cost": {v: budget.current_cost, t: "n", z: "$#,##0.00"},
                        "Available Budget": {v: budget.available_budget, t: "n", z: "$#,##0.00"},
                      };
                    }),
                    `${project.display} - Current Budget`,
                    [
                      {wch: 15},
                      {wch: 20},
                      {wch: 60},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                    ]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons buildReportURL={`/reports2/projects/${project.id}/budget/current/filter/`}>
                <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/projects/${project.id}/budget/current/`}>
                  Without Costs
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/budget/current/?display_costs=on`}
                >
                  With Costs
                </PaperPanel.Header.Menu.PDFMenuItem>
              </PaperPanel.Header.PDFButtons>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  blockUI.blockUI("Recalculating budget...");
                  postCurrentPage.mutateAsync().then(() => pageDataQuery.refetch().then(() => blockUI.unblockUI()));
                }}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            showFooter={Boolean(pageData.budgets.length)}
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={[
              "original_budget",
              "original_cost",
              "pending_proposed_budget_revs",
              "pending_cost_revs",
              "approved_budget_revs",
              "approved_cost_revs",
              "current_budget",
              "current_cost",
              "available_budget",
            ]}
            columns={[
              {
                Header: "Code",
                accessor: "budget_code",
                sortType: "string",
                Cell: ({value, row}) => (
                  <MuiNavLink to={`/v2/projects/${project.id}/budgets/${row.original.id}/`} underline="always">
                    {value}
                  </MuiNavLink>
                ),
              },
              {
                Header: "Allocation",
                accessor: "allocation",
              },
              {
                Header: "Description",
                accessor: "description",
              },
              {
                Header: "Original Budget",
                accessor: "original_budget",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.budgets.reduce((sum, row) => row.original_budget + sum, 0),
                    [pageData.budgets]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Original Cost",
                accessor: "original_cost",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.budgets.reduce((sum, row) => row.original_cost + sum, 0),
                    [pageData.budgets]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Pending Budget Changes",
                accessor: "pending_proposed_budget_revs",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.budgets.reduce((sum, row) => row.pending_proposed_budget_revs + sum, 0),
                    [pageData.budgets]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Pending Cost Changes",
                accessor: "pending_cost_revs",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.budgets.reduce((sum, row) => row.pending_cost_revs + sum, 0),
                    [pageData.budgets]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Approved Budget Changes",
                accessor: "approved_budget_revs",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.budgets.reduce((sum, row) => row.approved_budget_revs + sum, 0),
                    [pageData.budgets]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Approved Cost Changes",
                accessor: "approved_cost_revs",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.budgets.reduce((sum, row) => row.approved_cost_revs + sum, 0),
                    [pageData.budgets]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Current Budget",
                accessor: "current_budget",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.budgets.reduce((sum, row) => row.current_budget + sum, 0),
                    [pageData.budgets]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Committed Cost",
                accessor: "current_cost",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.budgets.reduce((sum, row) => row.current_cost + sum, 0),
                    [pageData.budgets]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Available Budget",
                accessor: "available_budget",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => pageData.budgets.reduce((sum, row) => row.available_budget + sum, 0),
                    [pageData.budgets]
                  );
                  return <Currency number={total} />;
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "budget_code",
                },
              ],
              hiddenColumns: hiddenColumns,
            }}
            data={pageData.budgets}
          />
          {pageData.budgetRecalculatedDate && (
            <Box p={1}>
              <Metadata>Budget last calculated on: {moment(pageData.budgetRecalculatedDate).format("LLLL")}</Metadata>
            </Box>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/budget/current/legacy/`} mt={2} />

      {/* <pre>{JSON.stringify(project, null, 2)}</pre> */}
    </>
  );
};

export default ProjectBudgetCurrent;
