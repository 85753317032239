const useBuildField = (isSpanish?: boolean, isReadOnly?: boolean, additionalFieldProps?: {}) => {
  const buildField = (
    namespace: string,
    field: {
      labelES: string;
      labelEN: string;
      name: string;
      Component: any;
      required?: boolean;
      passRequired?: boolean;
      passRequiredMessage?: string;
      [rest: string]: any;
    }
  ) => {
    const {labelES, labelEN, name, Component, required = true, ...rest} = field;
    return (
      <Component
        name={`${namespace}.${name}`}
        label={isSpanish ? labelES : labelEN}
        required={required}
        disabled={isReadOnly}
        {...additionalFieldProps}
        {...rest}
      />
    );
  };
  return buildField;
};

export default useBuildField;
