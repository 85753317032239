import {faClipboardCheck, faUserClock} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, makeStyles, Tooltip, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import HtmlRender from "../../../../js/components/HtmlRender";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterDate} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {ManualCheckInDialogCreateForm, ManualCheckInDialogUpdateForm} from "./ProjectDailyCheckInForms";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    questions: {
      fontSize: "1.2em",
      "& p": {
        margin: 0,
      },
      "& ol": {
        margin: 0,
      },
    },
  };
});

const ProjectManualDailyCheckIns = (props) => {
  const {project: project, user, userContact, ...rest} = props;
  const [date, setDate] = useQueryState("date", moment().format("YYYY-MM-DD"));
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(500);
  const [currentCheckIn, setCurrentCheckIn] = React.useState({} as any);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const novoClasses = makeNovoClasses();
  const classes = useStyles();
  const blockUI = useBlockUI();

  const {
    data: pageData,
    refetch: refetchPageData,
    isFetching: pageDataIsFetching,
  } = useFetchCurrentPage({
    initialData: {
      questions: [],
    },
  });
  const {
    query: checkInsQuery,
    create: createCheckIn,
    update: updateCheckIn,
  } = useSentinelListAPI(
    `projects/${project.id}/superintendents/manual-wellness-checks/?page_size=${pageSize}&page=${page}&compliance_date=${date}&ordering=checkin_timestamp`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const checkIns = checkInsQuery.data.results;

  return (
    <>
      <Helmet title={`${project.display} - Manual Daily Check-ins`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Manual Check-ins</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faClipboardCheck} /> Manual Check-ins
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>

      <Box my={2}>
        <Alert severity="info">
          <div className={classes.questions}>
            <div>
              The following questions must be asked of the site visitor. If their answer is <strong>No</strong> to any
              of the questions, they are <strong>NOT</strong> allowed on site.
            </div>
            <ol>
              {Object.keys(pageData.questions).map((question_key, i) => {
                const question = pageData.questions[question_key];
                return (
                  <li key={question_key}>
                    <HtmlRender html={question} />
                  </li>
                );
              })}
            </ol>
          </div>
        </Alert>
      </Box>
      <Box my={2}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <FilterDate
              label="Date"
              value={date}
              required
              onChange={(date) => {
                setDate(date);
              }}
            />
          </Grid>
        </Grid>
      </Box>

      <PaperPanel>
        <PaperPanel.Header isLoading={checkInsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faClipboardCheck} /> Recent Check Ins{" "}
            {checkInsQuery.data?.count &&
              `(${checkInsQuery.data.results.length} of ${accounting.formatNumber(checkInsQuery.data?.count)})`}
          </PaperPanel.Header.Title>

          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialog(true)}>
                Create Check-In
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => checkInsQuery.refetch()}
                isFetching={checkInsQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            getCellProps={(cell) => {
              if (["checkin_timestamp", "checkout_timestamp", "vax_status"].includes(cell.column.id)) {
                return {
                  style: {
                    whiteSpace: "nowrap",
                  },
                };
              }
            }}
            columns={[
              {
                Header: "Name",
                accessor: "name",
                Cell: ({row, value}) => {
                  const checkIn = row.original;
                  return (
                    <Link
                      style={{cursor: "pointer"}}
                      underline="always"
                      onClick={() => {
                        setCurrentCheckIn(checkIn);
                        setShowUpdateDialog(true);
                      }}
                    >
                      {value}
                    </Link>
                  );
                },
              },

              {
                Header: "Company",
                accessor: "company",
              },
              {
                Header: "Check In",
                accessor: "checkin_timestamp",
                Cell: ({value}) => (value ? moment.tz(value, project.timezone).format("hh:mm A z") : ""),
              },
              {
                Header: "Check Out",
                accessor: "checkout_timestamp",
                Cell: ({value, row}) => {
                  return (
                    <>
                      {value ? (
                        moment.tz(value, project.timezone).format("hh:mm A z")
                      ) : (
                        <div>
                          <Tooltip title={`Check ${row.original.name} out`}>
                            <span
                              style={{cursor: "pointer"}}
                              onClick={() => {
                                blockUI.blockUI();
                                updateCheckIn
                                  .mutateAsync({
                                    id: row.original.id,
                                    checkout_timestamp: moment(),
                                  })
                                  .then(() => checkInsQuery.refetch().then(() => blockUI.unblockUI()));
                              }}
                            >
                              <FontAwesomeIcon icon={faUserClock} />
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    </>
                  );
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "checkin_timestamp",
                  desc: true,
                },
              ],
            }}
            data={checkIns}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <ManualCheckInDialogCreateForm
        initialValues={{}}
        isOpen={showCreateDialog}
        projectId={project.id}
        onSubmit={(values) => {
          setShowCreateDialog(false);
          createCheckIn.mutateAsync(values);
        }}
        handleClose={() => setShowCreateDialog(false)}
      />
      <ManualCheckInDialogUpdateForm
        initialValues={currentCheckIn}
        isOpen={showUpdateDialog}
        projectId={project.id}
        onSubmit={(values) => {
          setShowUpdateDialog(false);
          updateCheckIn.mutateAsync(values);
        }}
        handleClose={() => setShowUpdateDialog(false)}
      />

      <LegacyUILink href={`/projects/${project.id}/superintendents/manual-wellness-checks/?date=${date}`} mt={2} />

      {/* <pre>{JSON.stringify(currentCheckIn, null, 2)}</pre>
      <pre>{JSON.stringify(checkInsQuery, null, 2)}</pre>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default ProjectManualDailyCheckIns;
