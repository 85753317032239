import {Chip, makeStyles} from "@material-ui/core";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import classnames from "classnames";
import React from "react";

const useStyles = makeStyles((theme) => {
  return {
    chip: {
      fontSize: 11,
      height: 18,
      "& .MuiChip-iconSmall": {
        width: 11,
        height: 11,
      },
    },
  };
});

export const TagList = (props: {tags?: string[]; className?: any}) => {
  const {tags, className, ...rest} = props;
  const styles = useStyles();
  return (
    <>
      {/* {tags && <LocalOfferIcon />} */}
      {tags.map((tag) => (
        <React.Fragment key={tag}>
          <Chip
            size="small"
            key={tag}
            label={tag}
            variant="outlined"
            className={classnames(styles.chip, className)}
            icon={<LocalOfferIcon />}
            {...rest}
          />{" "}
        </React.Fragment>
      ))}
    </>
  );
};
