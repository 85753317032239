import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  makeStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import accounting from "accounting";
import {pick} from "lodash";
import {Field, Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import AutoSaveDebounce from "../../../../js/components/ReactFinalForm/AutoSaveDebounce";
import AutoSaveSelectiveDebounce from "../../../../js/components/ReactFinalForm/AutoSaveSelectiveDebounce";
import {
  CompanyAutocomplete,
  ContactAutocomplete,
  CSICodeAutoselectMui,
  DatePickerMui,
  NumberFieldMui,
  ProjectCompanyAutoselectMui,
  ProjectTagAutocompleteMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";
import useWhyDidYouUpdate from "../../../hooks/useWhyDidYouUpdate";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography, spacing} = theme;
  return {
    radioButton: {
      color: palette.primary.main,
      minWidth: 55,
      "&.Mui-selected, &.Mui-selected:hover": {
        color: "white",
        backgroundColor: palette.primary.main,
        fontWeight: 900,
      },
    },
    helperText: {
      "& .MuiFormHelperText-root": {
        color: "#007bff",
      },
    },
  };
});

export const NANoYesButtons = (props: {name: string; disabled?: boolean; onChange?: (event, value) => void}) => {
  const {name, disabled = false, onChange} = props;
  const classes = useStyles();
  return (
    <Field name={name} onChange={onChange}>
      {(fieldProps) => {
        const {input, meta, ...rest} = fieldProps;
        return (
          <FormControl error={meta.invalid}>
            <ToggleButtonGroup
              size="small"
              exclusive
              {...input}
              onChange={(event, value) => {
                input.onChange(value);
              }}
            >
              <ToggleButton disabled={disabled || input.value === "n/a"} value="n/a" className={classes.radioButton}>
                N/A
              </ToggleButton>
              <ToggleButton disabled={disabled || input.value === "no"} value="no" className={classes.radioButton}>
                No
              </ToggleButton>
              <ToggleButton disabled={disabled || input.value === "yes"} value="yes" className={classes.radioButton}>
                Yes
              </ToggleButton>
            </ToggleButtonGroup>
            {meta.invalid && meta.touched && <FormHelperText id="my-helper-text">{meta.error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};

export const ProjectDailyReportDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  fieldSize?: string;
  handleClose: () => void;
  projectId: number;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, projectId, ...rest} = props;
  // const validateDailyReportDate = async (value, values, field) => {
  //   if (!values.date) return "Required";
  //   const response = await axiosAPI.get(`/projects/${projectId}/daily-reports/validate-date/?date=${values.date}`);
  //   if (response.data.error) {
  //     return (
  //       <>
  //         {response.data.error} Click{" "}
  //         <Link href={response.data.daily_report.url} underline="always">
  //           here
  //         </Link>{" "}
  //         to go to the current report.
  //       </>
  //     );
  //     // return response.data.error;
  //   }
  // };
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">{isNew ? "Create Daily Report" : "Update Daily Report"}</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DatePickerMui
                        label="Date"
                        name="date"
                        required
                        autoFocus
                        // fieldProps={{validate: validateDailyReportDate}}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectTagAutocompleteMui projectId={projectId} name="tags" label="Tags" />
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui
                        name="notes"
                        label="Notes"
                        // height={220}
                      />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || (pristine && !isNew)} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ProjectDailyReportCreateSubcontractorDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, handleClose, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Add Subcontractor to Work Log</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <Grid item xs={12}>
                        <ProjectCompanyAutoselectMui
                          projectId={projectId}
                          name="company"
                          label="Company"
                          required
                          autoFocus
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <CSICodeAutoselectMui
                        name="csi_code"
                        label="CSI Code"
                        // height={220}
                      />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label="Create" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ProjectDailyReportVisitorDialogForm = (props: {
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isOpen, isNew, handleClose, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">{isNew ? "Add Visitor" : "Update Visitor"}</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ContactAutocomplete
                        autoFocus
                        label="Contact"
                        name="visitor_name"
                        required
                        onChange={(event, value, reason) => {
                          if (reason === "clear") return;
                          form.batch(() => {
                            form.change("visitor_company_name", value.company.name);
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CompanyAutocomplete label="Company" name="visitor_company_name" required />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="notes" label="Notes" multiline minRows={2} />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                {!isNew && <FormActions.DeleteButton onClick={() => onDelete()} />}
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const WorkLogRowForm = (props) => {
  useWhyDidYouUpdate("WorkLogRowForm", props);
  const {workLogItem, isReadOnly, onSave, onDelete, projectId, ...rest} = props;
  const initialValues = pick(workLogItem, [
    "id",
    "hours",
    "quantity",
    "description",
    "prior_hours",
    "name_display",
    "type",
  ]);
  return (
    <FinalForm
      key={workLogItem.id}
      // pick only certain fields so that update is not triggered after refetch
      initialValues={initialValues}
      onSubmit={(values) => {
        console.log("onSubmit", values);
      }}
      validate={(values) => {
        const errors = {};
        if (values.quantity > 0 && values.hours === 0) {
          errors["hours"] = true;
        }
        if (values.hours > 0 && values.quantity === 0) {
          errors["quantity"] = true;
        }
        return errors;
      }}
    >
      {({handleSubmit, form, submitting, pristine, values}) => (
        // Add form tag so that radio buttons behave
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <PaperItem key={workLogItem.id} pt={1} noHover>
            <PaperItem.Left>
              <AutoSaveDebounce
                save={(newValue) => {
                  if (isReadOnly) {
                    return;
                  }
                  return onSave(newValue, values);
                }}
              >
                {/* <i className="fas fa-spinner fa-spin" /> */}
                <CircularProgress color="secondary" size={12} />
              </AutoSaveDebounce>
            </PaperItem.Left>
            <PaperItem.Body>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  {workLogItem.company?.company_role_id.includes("subcontractor") ? (
                    <>
                      <MuiNavLink
                        underline="always"
                        to={`/v2/projects/${projectId}/daily-reports/work-log-by-subcontractor/${workLogItem.company?.id}`}
                      >
                        {values.name_display}
                        {/* {Boolean(workLogItem.additional_description1)
                          ? workLogItem.additional_description1
                          : values.name_display} */}
                      </MuiNavLink>
                    </>
                  ) : (
                    <>{values.name_display}</>
                  )}
                </Grid>
                <Grid item xs={12} md={1}>
                  <NumberFieldMui required name="quantity" label="Qty" disabled={isReadOnly} />
                </Grid>
                <Grid item xs={12} md={1}>
                  <NumberFieldMui required name="hours" label="Hours" disabled={isReadOnly} />
                </Grid>
                <Grid item xs={12} md={2}>
                  <Typography2 noWrap type="metadata">
                    <Box>Current Hours: {accounting.formatNumber(values.quantity * values.hours)}</Box>
                    <Box>Prior Hours: {accounting.formatNumber(values.prior_hours)}</Box>
                    <Box>
                      Hours to date: {accounting.formatNumber(values.quantity * values.hours + values.prior_hours)}
                    </Box>
                  </Typography2>
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextFieldMui name="description" label="Description" multiline disabled={isReadOnly} />
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(workLogItem, null, 2)}</pre> */}
            </PaperItem.Body>
            <PaperItem.Right minWidth={30}>
              {values.type === "subcontractor" && (
                <Box>
                  <IconButton
                    aria-label="delete"
                    size="small"
                    disabled={isReadOnly}
                    onClick={() => onDelete(workLogItem)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              )}
            </PaperItem.Right>
          </PaperItem>
        </form>
      )}
    </FinalForm>
  );
};

export const SurveyRowForm = (props) => {
  useWhyDidYouUpdate("SurveyRowForm", props);
  const {surveyItem, isReadOnly, onSave, ...rest} = props;
  const initialValues = pick(surveyItem, ["id", "answer", "description", "question"]);

  return (
    <FinalForm
      key={surveyItem.id}
      // pick only certain fields so that update is not triggered after refetch
      initialValues={initialValues}
      onSubmit={(values) => {
        console.log("onSubmit", values);
      }}
    >
      {({handleSubmit, form, submitting, pristine, values}) => (
        // Add form tag so that radio buttons behave
        <form
          autoComplete="off"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <PaperItem key={surveyItem.id} pt={1} noHover>
            <PaperItem.Left>
              <AutoSaveSelectiveDebounce
                debounced={["description"]}
                save={(newValue) => {
                  if (isReadOnly) {
                    return;
                  }
                  // HACK to get around race condition on re-render which causes loop.
                  // Need to fix re-render triggered by onSave changing
                  if (newValue.answer === "") {
                    return;
                  }
                  onSave(newValue, values);
                }}
              >
                {/* <i className="fas fa-spinner fa-spin" /> */}
                <CircularProgress color="secondary" size={12} />
              </AutoSaveSelectiveDebounce>
            </PaperItem.Left>
            <PaperItem.Body>
              <Grid container spacing={1}>
                <Grid item xs={12} md={4}>
                  {values.question}
                </Grid>
                <Grid item xs={12} md={3}>
                  <NANoYesButtons name="answer" disabled={isReadOnly} />
                </Grid>
                <Grid item xs={12} md={5}>
                  <TextFieldMui name="description" label="Description" multiline disabled={isReadOnly} />
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </PaperItem.Body>
          </PaperItem>
        </form>
      )}
    </FinalForm>
  );
};
