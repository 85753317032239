import PropTypes from "prop-types";

export const LabelValue = (props) => {
  const {label, value, children} = props;
  return (
    <div>
      <strong>{label}:</strong> {value} {children}
    </div>
  );
};
LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};

export const FormLabelValue = (props) => {
  const {label, value, children} = props;
  return (
    <div>
      <label>{label}:</label> {value} {children}
    </div>
  );
};
FormLabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
};
