import {
  faEnvelope,
  faPaperclip,
  faPaperPlaneTop,
  faUmbrella,
  faUpRightFromSquare,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Grid, Link, MenuItem, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {TabContext, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import {sumBy} from "lodash";
import {useSnackbar} from "notistack";
import pluralize from "pluralize";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {Currency} from "../../../components/Accounting";
import ActivityStream from "../../../components/ActivityStream";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {DropdownButton, useButtonStyles} from "../../../components/Buttons";
import {CommitmentBanner} from "../../../components/ClosedBanner";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {StatusIcon} from "../../../components/Icons";
import {MuiNavLink, ProjectAuditLink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {StatusLabel} from "../../../components/Status";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import {
  TimberlineCommitmentChangeOrderPanel,
  TimberlineCommitmentInfoPanel,
  TimberlineCommitmentItemPanel,
} from "../../../components/TimberlineInfoPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {ExistingSCOCreateDialog, InternalSCOCreateDialog} from "../ChangeOrders/SCOForms";
import SCOPaperItem from "../ChangeOrders/SCOPaperItem";
import {TypeSelectDialog} from "../ChangeOrderWizard/SubcontractSelectForms";
import {
  SendSubcontractInsuranceReminderDialogForm,
  SubcontractGeneralInfoForm,
  SubContractItemForm,
} from "./SubcontractForms";

const SubcontractDetail = (props) => {
  const {project, ...rest} = props;
  const {subcontractId} = useParams();
  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [requestDialogIsOpen, setRequestDialogIsOpen] = React.useState(false);
  const [insuranceDialogIsOpen, setInsuranceDialogIsOpen] = React.useState(false);
  const [createExistingSCODialogIsOpen, setCreateExistingSCODialogIsOpen] = React.useState(false);
  const [createInternalSCODialogIsOpen, setCreateInternalSCODialogIsOpen] = React.useState(false);
  const [activeInsuranceContact, setActiveInsuranceContact] = React.useState(0);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [voidDialogIsOpen, setVoidDialogIsOpen] = React.useState(false);
  const [submitDialogIsOpen, setSubmitDialogIsOpen] = React.useState(false);
  const [newItemFormIsOpen, setNewItemFormIsOpen] = React.useState(false);
  const [editItemFormIsOpen, setEditItemFormIsOpen] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState({} as any);
  const [typeSelectDialogIsOpen, setTypeSelectDialogIsOpen] = React.useState(false);

  const blockUI = useBlockUI();
  const permissions = usePermissions();
  const {enqueueSnackbar} = useSnackbar();
  const novoClasses = makeNovoClasses();
  const buttonStyles = useButtonStyles();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
      pendingPCOItems: [],
      manageInsuranceContacts: [{full_name: ""}],
    },
  });

  const pendingPCOItems = pageDataQuery.data.pendingPCOItems;

  const insuranceContact = () => {
    return pageDataQuery?.data?.manageInsuranceContacts[activeInsuranceContact];
  };

  const {
    query: subcontractQuery,
    update: updateSubcontract,
    delete: deleteSubcontract,
    rpc: subcontractRPC,
  } = useSentinelDetailAPI(["projects", project.id, "contracts", "sub", parseInt(subcontractId)], {
    initialData: {},
  });
  const subcontract = subcontractQuery.data;

  const {
    query: subcontractItemsQuery,
    update: updateSubcontractItem,
    delete: deleteSubcontractItem,
    create: createSubcontractItem,
    rpc: subcontractItemRPC,
  } = useSentinelListAPI(["projects", project.id, "contracts", "sub", parseInt(subcontractId), "items"], {
    initialData: {
      results: [],
    },
  });
  const items = subcontractItemsQuery.data.results;
  const allItemIds = items.map((item) => item.id);

  const {query: linkableSCOItemsQuery} = useSentinelListAPI(
    `projects/${project.id}/contracts/sub/${subcontractId}/sco/linkable/`,
    {
      initialData: [],
    }
  );
  const linkableSCOItems = linkableSCOItemsQuery.data;

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  const {
    query: sccosQuery,
    create: createSCCO,
    update: updateSCCO,
    delete: deleteSCCO,
    rpc: sccoRPC,
  } = useSentinelListAPI(["projects", project.id, "contracts", "sub", parseInt(subcontractId), "sccos"], {
    initialData: {
      results: [],
    },
  });
  const sccos = sccosQuery.data.results;

  const approvedRevisions = sumBy(sccos, "cost");

  const createExistingSCOInitialValues = React.useMemo(() => {
    return {
      description: subcontract?.description,
      items: [],
    };
  }, [subcontract?.description]);

  if (!subcontractQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Subcontract info..." />;
  }

  // console.log("Contract", subcontract);
  // console.log("PageData", pageDataQuery.data);
  // console.log("permissions", usePermissions());

  return (
    <>
      <Helmet title={`${project.display}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/contracts/`}>
          Subcontracts
        </MuiNavLink>
        <Typography color="textPrimary">{subcontract.display}</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Left></PageHeader.Left>
        <PageHeader.Right>
          {subcontract.original_value !== subcontract.current_value && (
            <PageHeader.Right.CurrencySpark title="Original Value" number={subcontract.original_value} precision={2} />
          )}
          <PageHeader.Right.CurrencySpark
            title="Current Value"
            number={subcontract.current_value}
            border={subcontract.original_value !== subcontract.current_value}
            precision={2}
          />
        </PageHeader.Right>
      </PageHeader>
      <CommitmentBanner object={subcontract} mb={2} type="subcontract" />
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <StatusIcon status={subcontract.status} tooltip={subcontract.status_display} showTooltip />
                    <Box ml={1}>General Info</Box>
                  </Box>
                }
                value="general"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Items
                    <TabCountChip
                      isLoading={!subcontractItemsQuery.isFetchedAfterMount}
                      count={subcontractItemsQuery.data.count}
                    />
                  </Box>
                }
                value="items"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    SCO's
                    <TabCountChip isLoading={!sccosQuery.isFetchedAfterMount} count={sccosQuery.data.count} />
                  </Box>
                }
                value="scos"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Timberline
                  </Box>
                }
                value="timberline"
                disabled={!subcontract.timberline_subcontract_id}
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Pending Items
                    <TabCountChip
                      isLoading={!pageDataQuery.isFetchedAfterMount}
                      count={pageDataQuery.data.pendingPCOItems.length}
                    />
                  </Box>
                }
                value="pendingItems"
                className={novoClasses.smallTab}
              />
              <Tab label="Logs" value="logs" className={novoClasses.smallTab} />
              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />

          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={subcontractQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <Box justifyItems="space-between">
                    <StatusLabel status={subcontract.status_display} /> {subcontract.display}
                  </Box>
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/contracts/subcontract/${subcontract.id}/`}
                      buildReportURL={`/reports2/projects/${project.id}/subcontracts/detail/filter/?pk=${subcontract.id}`}
                    />
                  </PaperPanel.Header.Action>

                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.DocuSignButton docuSignObject={subcontract} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/contracts/subcontract/${subcontract.id}/`}
                      uploadURL={`/projects/${project.id}/contracts/sub/${subcontract.id}/upload-to-box/`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <DropdownButton
                      title={"Insurance"}
                      startIcon={<FontAwesomeIcon icon={faUmbrella} />}
                      size="small"
                      {...rest}
                    >
                      {(setOpen) => (
                        <>
                          <MenuItem
                            dense
                            onClick={(e) => {
                              setOpen(false);
                              // window.open(`/projects/${project.id}/subcontracts/${subcontract.id}/insurance/`, "_self");
                            }}
                            href={`https://portal.novoconstruction.com/projects/${project.id}/subcontracts/${subcontract.id}/insurance/`}
                            target="_blank"
                            component={Link}
                          >
                            View In Portal <FontAwesomeIcon icon={faUpRightFromSquare} fixedWidth />
                          </MenuItem>
                          <MenuItemHeader border>Send Reminder</MenuItemHeader>

                          {pageDataQuery.data.manageInsuranceContacts.length > 0 ? (
                            pageDataQuery.data.manageInsuranceContacts.map((contact, index) => {
                              return (
                                <MenuItem
                                  dense
                                  onClick={(e: any) => {
                                    setOpen(false);
                                    setInsuranceDialogIsOpen(true);
                                    setActiveInsuranceContact(e.target.value);
                                  }}
                                  value={index}
                                  key={contact.id}
                                >
                                  {contact.full_name}
                                </MenuItem>
                              );
                            })
                          ) : (
                            <MuiNavLink to={`${subcontract.to_company.url}#tab=contacts`} underline="always">
                              <Tooltip title={`Manage Insurance For ${subcontract.to_company.name}`}>
                                <Box>
                                  <MenuItem dense disabled>
                                    No Insurance Contacts Set
                                  </MenuItem>
                                </Box>
                              </Tooltip>
                            </MuiNavLink>
                          )}
                        </>
                      )}
                    </DropdownButton>
                  </PaperPanel.Header.Action>
                  {subcontract.loi && (
                    <PaperPanel.Header.Action>
                      <PaperPanel.Header.Button href={subcontract?.absolute_url}>
                        <FontAwesomeIcon icon={faPaperclip} fixedWidth />
                        LOI: #{subcontract.loi?.number_display}
                      </PaperPanel.Header.Button>
                    </PaperPanel.Header.Action>
                  )}
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          {permissions.can_import_subcontracts && (
                            <>
                              <Tooltip title="Request this contract be submitted">
                                <span>
                                  <PaperPanel.Header.Menu.MenuItem
                                    onClick={(event) => {
                                      popupState.close();
                                      setRequestDialogIsOpen(true);
                                    }}
                                    disabled={
                                      subcontract.submitted_date || ["void", "submitted"].includes(subcontract.status)
                                    }
                                  >
                                    <FontAwesomeIcon icon={faEnvelope} fixedWidth /> <Box ml={1} /> Request Submission
                                  </PaperPanel.Header.Menu.MenuItem>
                                </span>
                              </Tooltip>
                              <MenuItemHeader border />
                            </>
                          )}
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                            }}
                          >
                            {/* <Chip label={sccosQuery.data.count} variant="outlined" size="small" /> */}
                            {/* <Link underline="none" href={`/pcos/items/list/?subcontract=${subcontract.id}`}>
                              PCO Items (SCCO)
                            </Link> */}
                          </PaperPanel.Header.Menu.MenuItem>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                            }}
                          >
                            {/* <Chip label={pageDataQuery.data.pendingPCOItems.length} variant="outlined" size="small" /> */}
                            <MuiNavLink
                              underline="none"
                              to={`/v2/projects/${project.id}/pcos/items/?budget_id=${subcontract.budget?.id}`}
                            >
                              PCO Items (CSI)
                            </MuiNavLink>
                          </PaperPanel.Header.Menu.MenuItem>

                          <Tooltip title={subcontract.can_void_reason || ""}>
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={(event) => {
                                  popupState.close();
                                  setVoidDialogIsOpen(true);
                                }}
                                disabled={!subcontract.can_void}
                              >
                                <FontAwesomeIcon icon={faXmark} fixedWidth /> <Box ml={1} /> Void
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>

                          <Tooltip title={subcontract?.can_submit_to_accounting_reason || ""}>
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={(event) => {
                                  setSubmitDialogIsOpen(true);
                                  popupState.close();
                                }}
                                disabled={!subcontract.can_submit_to_accounting}
                              >
                                <FontAwesomeIcon icon={faPaperPlaneTop} />
                                <Box ml={1} /> Submit
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>
                          {/*
                          This does not appear to get used anywhere only 1 created since 2016 years
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                            }}
                          >
                            <Link underline="none" href={`/contracts/subcontract/${subcontract.id}/closeout/`}>
                              Subcontract Closeout
                            </Link>
                          </PaperPanel.Header.Menu.MenuItem>
                           */}
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>

              <SubcontractGeneralInfoForm
                projectId={project.id}
                onSubmit={(values) => {
                  blockUI.blockUI("Saving...");

                  updateSubcontract
                    .mutateAsync(values)
                    .then(() => subcontractQuery.refetch())
                    .then(() => blockUI.unblockUI());
                }}
                initialValues={subcontract}
                isReadOnly={subcontract.is_closed}
                restrictNovoSigners={project.restrict_novo_signers}
                // drawings={subcontract.drawings.map((drawing) => drawing)}
              />
              {/* <pre>{JSON.stringify(project, null, 2)}</pre> */}
              {/* <h1>Subcontract</h1>
            <pre>{JSON.stringify(subcontract, null, 2)}</pre>
            <h1>Page Data</h1>
            <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
            </>
          </TabPanel>
          <TabPanel value="items">
            <PaperPanel.TabHeader isLoading={subcontractItemsQuery.isFetching}>
              <PaperPanel.Header.Title>
                {items.length > 0 && (
                  <PaperItem.SelectedCheckbox
                    label={"Select All"}
                    onChange={(event, value) => {
                      if (value) {
                        addSelectedItemIds(allItemIds);
                      } else {
                        removeAllSelectedItemIds();
                      }
                    }}
                    indeterminate={Boolean(
                      !allItemIdsSelected && selectedItemIds.size < allItemIds.length && selectedItemIds.size
                    )}
                    checked={allItemIdsSelected}
                  />
                )}
                {subcontract.display}
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <Tooltip title={subcontract.is_closed ? subcontract.is_closed_reason : ""}>
                    <span>
                      <PaperPanel.Header.CreateButton
                        onClick={() => setNewItemFormIsOpen(true)}
                        disabled={subcontract.is_closed}
                      />
                    </span>
                  </Tooltip>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action>
                  <Tooltip title={subcontract.is_closed ? subcontract.is_closed_reason : ""}>
                    <span>
                      <PaperPanel.Header.DeleteButton
                        onClick={() => setDeleteConfirmationIsOpen(true)}
                        disabled={selectedItemIds.size < 1 || subcontract.is_closed}
                      />
                    </span>
                  </Tooltip>
                </PaperPanel.Header.Action>
                {!subcontract.is_closed && (
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                            }}
                          >
                            <Link
                              underline="none"
                              href={`/contracts/subcontract/${subcontract.id}/copy-pco-items-to-subcontract/`}
                            >
                              Copy PCO Items to Subcontract
                            </Link>
                          </PaperPanel.Header.Menu.MenuItem>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                )}
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={subcontractItemsQuery.isFetching}
                    onClick={() => subcontractItemsQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <Box mx={-3} mb={-3} mt={-2}>
              {items.map((item) => {
                return (
                  <SubcontractItemPaperItem
                    key={item.id}
                    item={item}
                    isSelected={selectedItemIds.has(item.id)}
                    onDelete={() => deleteSubcontractItem.mutateAsync(item.id)}
                    onEdit={() => {
                      setActiveItem(item);
                      setEditItemFormIsOpen(true);
                    }}
                    onSelectedChange={(e, value) => {
                      if (value) {
                        addSelectedItemId(item.id, e.nativeEvent.shiftKey);
                      } else {
                        removeSelectedItemId(item.id);
                      }
                    }}
                    isReadOnly={subcontract.is_closed}
                  />
                );
              })}

              <PaperItem.Header>
                <Grid container>
                  <Grid item xs={12}>
                    <Box textAlign={"right"}>
                      <strong>
                        Total <Currency number={sumBy(items, "value")} />
                      </strong>
                    </Box>
                  </Grid>
                </Grid>
                <PaperItem.Right minWidth={60} />
              </PaperItem.Header>

              {/* <pre>{JSON.stringify(items, null, 2)}</pre> */}
            </Box>
          </TabPanel>
          <TabPanel value="scos">
            <PaperPanel.TabHeader isLoading={sccosQuery.isFetching}>
              <PaperPanel.Header.Title>{subcontract.display}</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.CreateButton
                    // href={`/ccos/scco/${project.id}/sco-wizard/link/${subcontract.id}/`}
                    onClick={() => setTypeSelectDialogIsOpen(true)}
                  />
                  {/* <PaperPanel.Header.DropdownMenu
                    title="Create"
                    startIcon={<AddIcon />}
                    className={classnames(buttonStyles.textSuccess, buttonStyles.outlinedSuccess)}
                  >
                    {(setOpen) => (
                      <>
                        <PaperPanel.Header.Menu.MenuItem
                          onClick={(event) => {
                            setCreateInternalSCODialogIsOpen(true);
                            setOpen(false);
                          }}
                        >
                          <ListItemText
                            primary="Internal"
                            secondary={
                              <>
                                Create a new single item Internal Change Order
                                <br />
                                and link to this Subcontract Change Order.
                              </>
                            }
                          />
                        </PaperPanel.Header.Menu.MenuItem>
                        <PaperPanel.Header.Menu.MenuItem
                          onClick={(event) => {
                            setCreateExistingSCODialogIsOpen(true);
                            setOpen(false);
                          }}
                        >
                          <ListItemText
                            primary="Existing"
                            secondary={
                              <>
                                Link this Subcontract Change Order
                                <br />
                                to an existing Change Order Request or Internal Change Order.
                              </>
                            }
                          />
                        </PaperPanel.Header.Menu.MenuItem>
                      </>
                    )}
                  </PaperPanel.Header.DropdownMenu> */}
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.PDFButton href={`/reports2/contracts/subcontract/${subcontract.id}/scos/`} />
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={sccosQuery.isFetching}
                    onClick={() => sccosQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <Box mx={-3} mb={-3} mt={-2}>
              {sccos.map((sco) => {
                return <SCOPaperItem sco={sco} key={sco.id} projectId={project.id} />;
              })}
              <PaperItem.Header>
                <Grid container>
                  <Grid item xs={6}></Grid>
                  <Grid item xs={6}>
                    <Box textAlign={"right"}>
                      <strong>
                        Total <Currency number={sumBy(sccos, "cost")} />
                      </strong>
                    </Box>
                  </Grid>
                </Grid>
                <PaperItem.Right minWidth={37} />
              </PaperItem.Header>
            </Box>
            {/* <pre>{JSON.stringify(sccos, null, 2)}</pre> */}
          </TabPanel>

          <TabPanel value="timberline">
            <Box mx={-3} mb={-3} mt={-4}>
              <TimberlineCommitmentInfoPanel
                projectId={project.id}
                timberlineCommitmentId={subcontract?.timberline_subcontract_id}
              />

              <Box mb={3} />
              <TimberlineCommitmentItemPanel
                projectId={project.id}
                timberlineCommitmentId={subcontract?.timberline_subcontract_id}
              />

              <Box mb={3} />
              <TimberlineCommitmentChangeOrderPanel
                projectId={project.id}
                timberlineCommitmentId={subcontract?.timberline_subcontract_id}
              />
            </Box>
          </TabPanel>

          <TabPanel value="pendingItems">
            <PaperPanel.TabHeader isLoading={pageDataQuery.isFetching}>
              <PaperPanel.Header.Title>
                PCO items that have not been included in an SCO (void excluded).
              </PaperPanel.Header.Title>
            </PaperPanel.TabHeader>{" "}
            <Box mx={-3} mb={-3} mt={-2}>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                showFooter={true}
                rightAlignColumns={[
                  "budget_rom",
                  "budget_proposed_amount",
                  "budget_approved_amount",
                  "budget_cost",
                  "cost",
                ]}
                columns={[
                  {
                    Header: "#",
                    accessor: "pco_number_display",
                    Cell: ({value, row}) => value,
                  },
                  {
                    Header: "PCO",
                    accessor: "pco_description",
                    Cell: ({value, row}) => (
                      <Link href={row.original.pco_url} underline="always">
                        {value}
                      </Link>
                    ),
                  },
                  {
                    Header: "Item #",
                    accessor: "number_display",
                    Cell: ({value, row}) => value,
                  },
                  {
                    Header: "PCO Item",
                    accessor: "display",
                    Cell: ({value, row}) => (
                      <Tooltip title={value}>
                        <Box overflow="hidden" textOverflow="ellipsis" maxWidth="14rem">
                          <Link
                            href={`/projects/${project.id}/pcos/${row.original.pco_id}/#tab_items`}
                            underline="always"
                          >
                            <Typography noWrap style={{fontSize: 11}}>
                              {value}
                            </Typography>
                          </Link>
                        </Box>
                        {/* </div> */}
                      </Tooltip>
                    ),
                  },

                  {
                    Header: "Code",
                    accessor: "budget.budget_code",
                    Cell: ({value, row}) => value,
                  },
                  {
                    Header: "Allocation",
                    accessor: "budget.allocation_display",
                    Cell: ({value, row}) => value,
                  },
                  {
                    Header: " ",
                    accessor: "status_display",
                    Cell: ({value, row}) => (
                      <Tooltip title={value}>
                        <span>
                          <StatusIcon status={value} />
                        </span>
                      </Tooltip>
                    ),
                  },
                  {
                    Header: "ROM",
                    accessor: "budget_rom",
                    Cell: ({value, row}) => <Currency number={value} />,
                    Footer: () => {
                      const total = React.useMemo(() => sumBy(pendingPCOItems, "budget_rom"), [pendingPCOItems]);
                      return <Currency number={total} />;
                    },
                  },
                  {
                    Header: "Proposed",
                    accessor: "budget_proposed_amount",
                    Cell: ({value, row}) => <Currency number={value} />,
                    Footer: () => {
                      const total = React.useMemo(
                        () => sumBy(pendingPCOItems, "budget_proposed_amount"),
                        [pendingPCOItems]
                      );
                      return <Currency number={total} />;
                    },
                  },
                  {
                    Header: "Approved",
                    accessor: "budget_approved_amount",
                    Cell: ({value, row}) => <Currency number={value} />,
                    Footer: () => {
                      const total = React.useMemo(
                        () => sumBy(pendingPCOItems, "budget_approved_amount"),
                        [pendingPCOItems]
                      );
                      return <Currency number={total} />;
                    },
                  },
                  {
                    Header: "Budget Cost",
                    accessor: "budget_cost",
                    Cell: ({value, row}) => <Currency number={value} />,
                    Footer: () => {
                      const total = React.useMemo(() => sumBy(pendingPCOItems, "budget_cost"), [pendingPCOItems]);
                      return <Currency number={total} />;
                    },
                  },
                  {
                    Header: "Cost",
                    accessor: "cost",
                    Cell: ({value, row}) => <Currency number={value} />,
                    Footer: () => {
                      const total = React.useMemo(() => sumBy(pendingPCOItems, "cost"), [pendingPCOItems]);
                      return <Currency number={total} />;
                    },
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "pco_number_display",
                      asc: true,
                    },
                  ],
                }}
                data={pendingPCOItems}
                {...rest}
              />
            </Box>
            {/* <h1>Pending Items</h1>
            <pre>{JSON.stringify(pageDataQuery.data.pendingPCOItems, null, 2)}</pre> */}
          </TabPanel>
          <TabPanel value="logs">
            <Box mx={-3} mt={-4}>
              <ActivityStream url={`actstream/contracts/subcontract/${subcontractId}/`} />
              {/* <Box mb={2} />
              <MailLog url={`mailer/contracts/subcontract/${subcontractId}/log/`} /> */}
            </Box>
          </TabPanel>
        </PaperPanel>
      </TabContext>

      <CreatedByModifiedBy obj={subcontract} mt={2} />

      <ProjectAuditLink projectId={project.id} app="contracts" model="subcontract" id={subcontractId} mt={1} />
      {/* <LegacyUILink href={`/contracts/subcontract/${subcontract.id}/legacy/`} /> */}

      {/* <pre>{JSON.stringify(linkableSCOItems, null, 2)}</pre> */}

      <ConfirmationDialog
        isOpen={requestDialogIsOpen}
        title="Send Email?"
        onApprove={() => {
          setRequestDialogIsOpen(false);
          blockUI.blockUI("Requesting...");
          subcontractRPC.mutateAsync({action: "request-submission"}).then((res) => {
            res === "success" && enqueueSnackbar("Email Sent!", {variant: "success"});

            blockUI.unblockUI();
            subcontractQuery.refetch();
          });
        }}
        onDeny={() => {
          setRequestDialogIsOpen(false);
        }}
      >
        This will send an email to {subcontract.created_by.full_name} and request that they submit this subcontract.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        title={`Are you sure?`}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          blockUI.blockUI("Deleting...");
          subcontractItemRPC
            .mutateAsync({
              action: "delete",
              method: "DELETE",
              data: {ids: Array.from(selectedItemIds)},
            })
            .then(() => {
              removeAllSelectedItemIds();
              subcontractItemsQuery.refetch().then(() => blockUI.unblockUI());
            });
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        {`Are you sure you want to delete ${selectedItemIds.size} ${pluralize(`item`, selectedItemIds.size)}?`}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={voidDialogIsOpen}
        title={`Void this subcontract and related change orders?`}
        onApprove={() => {
          setVoidDialogIsOpen(false);
          blockUI.blockUI("Voiding...");
          subcontractRPC
            .mutateAsync({action: "void"})
            .then((res) => subcontractQuery.refetch())
            .then(() => blockUI.unblockUI());
        }}
        onDeny={() => {
          setVoidDialogIsOpen(false);
        }}
      >
        This will mark them as void and create a change order to zero out all committed costs related to this
        subcontract and submit it to accounting.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={submitDialogIsOpen}
        title={"Submit this Subcontract?"}
        onApprove={() => {
          setSubmitDialogIsOpen(false);
          blockUI.blockUI("Submitting...");

          //data: values
          subcontractRPC.mutateAsync({action: "submit"}).then((subcontract) => {
            window.location.href = subcontract.url;
            subcontractQuery.refetch();
            blockUI.unblockUI();
          });
        }}
        onDeny={() => setSubmitDialogIsOpen(false)}
      >
        Submit this subcontract to accounting? You will be unable to change the contract value once submitted.
      </ConfirmationDialog>

      <SendSubcontractInsuranceReminderDialogForm
        insuranceContact={insuranceContact()}
        isOpen={insuranceDialogIsOpen}
        onSubmit={(values) => {
          setInsuranceDialogIsOpen(false);
          setActiveInsuranceContact(0);
          blockUI.blockUI("Sending...");
          subcontractRPC
            .mutateAsync({
              action: "send-insurance-reminder",
              data: {contactId: values?.contactId, message: values?.message},
            })
            .then((res) => {
              res === "success" && enqueueSnackbar("Email Sent!", {variant: "success"});

              subcontractQuery.refetch();

              blockUI.unblockUI();
            });
        }}
        handleClose={() => {
          setInsuranceDialogIsOpen(false);
          setActiveInsuranceContact(0);
        }}
        projectDisplay={project.display}
        contractDisplay={subcontract.display}
      />

      <SubContractItemForm
        isNew
        isOpen={newItemFormIsOpen}
        projectId={project.id}
        onSubmit={(values) => {
          createSubcontractItem.mutateAsync(values).then(() => subcontractItemsQuery.refetch());
          setNewItemFormIsOpen(false);
        }}
        handleClose={() => setNewItemFormIsOpen(false)}
      />

      <SubContractItemForm
        initialValues={activeItem}
        isOpen={editItemFormIsOpen}
        projectId={project.id}
        onSubmit={(values) => {
          updateSubcontractItem.mutateAsync(values).then(() => {
            subcontractItemsQuery.refetch();
            setEditItemFormIsOpen(false);
          });
        }}
        handleClose={() => setEditItemFormIsOpen(false)}
      />

      <ExistingSCOCreateDialog
        isOpen={createExistingSCODialogIsOpen}
        handleClose={() => setCreateExistingSCODialogIsOpen(false)}
        projectId={project.id}
        initialValues={createExistingSCOInitialValues}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          createSCCO.mutateAsync(values).then(() => {
            Promise.all([
              subcontractQuery.refetch(),
              sccosQuery.refetch(),
              sccosQuery.refetch(),
              linkableSCOItemsQuery.refetch(),
            ]).then(() => {
              setCreateExistingSCODialogIsOpen(false);
              blockUI.unblockUI();
            });
          });
        }}
        linkableItems={linkableSCOItems}
      />
      <InternalSCOCreateDialog
        isOpen={createInternalSCODialogIsOpen}
        handleClose={() => setCreateInternalSCODialogIsOpen(false)}
        projectId={project.id}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          createSCCO.mutateAsync(values).then(() => {
            Promise.all([
              subcontractQuery.refetch(),
              sccosQuery.refetch(),
              sccosQuery.refetch(),
              linkableSCOItemsQuery.refetch(),
            ]).then(() => {
              setCreateInternalSCODialogIsOpen(false);
              blockUI.unblockUI();
            });
          });
        }}
      />
      <TypeSelectDialog
        isOpen={typeSelectDialogIsOpen}
        setIsOpen={setTypeSelectDialogIsOpen}
        subcontract={subcontract}
        project={project}
      />
    </>
  );
};

export default SubcontractDetail;

const SubcontractItemPaperItem = (props) => {
  const {item, onDelete, isSelected, onEdit, onSelectedChange, isReadOnly, ...rest} = props;

  return (
    <PaperItem>
      <PaperItem.Left>
        <PaperItem.SelectedCheckbox checked={isSelected} onChange={onSelectedChange} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container>
          <Grid item xs={6}>
            {item.display}
          </Grid>
          <Grid item xs={6}>
            <Box textAlign={"right"}>
              <Currency number={item.value} />
            </Box>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={60} />
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEdit} disabled={isReadOnly} />
        <PaperItem.RightHover.IconButton
          icon={DeleteIcon}
          title="Delete Item"
          onClick={onDelete}
          disabled={isReadOnly}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
