import {faSackDollar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment-timezone";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {Currency} from "../../components/Accounting";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch} from "../../components/Filters";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";

const BonusAccountTransactionList = (props) => {
  const [showNotesInline, setShowNotesInline] = React.useState(false);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);

  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const updateDistributionRequestFormRef: any = React.createRef();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Status"]
    // {
    //   Status: {
    //     value: "submitted",
    //     label: "Submitted",
    //   },
    // }
  );

  const filterParams = {
    q: filterOptions.Search.value,
    status: filterOptions.Status.value,
  };

  const {query: bonusAccountTransactionsQuery} = useSentinelListAPI(
    `bonuses/transactions/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );

  return (
    <>
      <Helmet title="Bonus Transactions" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Bonus Transactions</Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faSackDollar} /> Bonus Transactions
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header isLoading={bonusAccountTransactionsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faSackDollar} /> Bonus Transactions
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {/* <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setShowCreateDialog(true);
                }}
              >
                Create
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action> */}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={bonusAccountTransactionsQuery.isFetching}
                onClick={() => bonusAccountTransactionsQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={bonusAccountTransactionsQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["amount"]}
            columns={[
              {
                Header: "Account",
                accessor: "account.description",
                Cell: ({value, row}) => (
                  <MuiNavLink to={`/v2/bonuses/accounts/${row.original.account.id}/`} underline="always">
                    {value}
                  </MuiNavLink>
                ),
              },
              {
                Header: "Description",
                accessor: "description",
              },
              {
                Header: "Notes",
                accessor: "notes",
              },
              {
                Header: "Created",
                accessor: "created",
                Cell: ({value}) => (value ? moment(value).format("YYYY-MM-DD hh:mm A") : ""),
              },
              {
                Header: "Closeout Request",
                accessor: "closeout_request.description",
                Cell: ({value, row}) => (
                  <MuiNavLink
                    to={`/v2/bonuses/accounts/${row.original.account.id}/closeout-requests/${row.original.closeout_request?.id}/`}
                    underline="always"
                  >
                    {value}
                  </MuiNavLink>
                ),
              },
              {
                Header: "Distribution Request",
                accessor: "distribution_request.description",
                Cell: ({value, row}) => (
                  <MuiNavLink
                    to={`/v2/bonuses/accounts/${row.original.account.id}/distribution-requests/${row.original.distribution_request?.id}/`}
                    underline="always"
                  >
                    {value}
                  </MuiNavLink>
                ),
              },
              {
                Header: "Amount",
                accessor: "amount",
                Cell: ({value, row}) => <Currency number={value} />,
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "created",
                  desc: true,
                },
              ],
              hiddenColumns: showNotesInline ? [] : ["notes"],
            }}
            data={bonusAccountTransactionsQuery.data.results}
          />
          {bonusAccountTransactionsQuery.data.total_pages > 1 && (
            <PaperPanel.Footer display="flex" justifyContent="flex-end">
              <PaginationWithPageSize
                count={bonusAccountTransactionsQuery.data.total_pages}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                pageSizes={[100, 250, 500, 1000]}
              />
            </PaperPanel.Footer>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      {/*
      <pre>{JSON.stringify(bonusAccountTransactionsQuery, null, 2)}</pre>
      <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
    </>
  );
};

export default BonusAccountTransactionList;
