import {Box} from "@material-ui/core";
import moment from "moment";
import Typography2 from "./Typography2";

const CreatedByModifiedBy = (props) => {
  const {obj, ...rest} = props;
  return (
    <Box {...rest}>
      <Typography2 type="metadata">
        Created by {obj?.created_by?.full_name ? obj?.created_by?.full_name : "Sentinel"}{" "}
        {moment(obj?.created).format("LLLL")} | Modified by{" "}
        {obj?.modified_by?.full_name ? obj?.modified_by?.full_name : "Sentinel"} {moment(obj?.modified).format("LLLL")}
      </Typography2>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </Box>
  );
};

export default CreatedByModifiedBy;
