const getChoicesFromObjectList = (objectList, valueProp, labelProp) => {
  let choicesMap = {};
  objectList.map((object) => {
    choicesMap[object[valueProp]] = object[labelProp];
  });
  const choices = Object.keys(choicesMap).map((key, index) => {
    return {value: key, label: choicesMap[key]};
  });
  return choices;
};

export default getChoicesFromObjectList;
