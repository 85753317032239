import {faCalendarDay, faImage} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {NavLink as RouterNavLink} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSelect} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TableStatusLabel} from "../../../components/Status";
import {TagList} from "../../../components/Tags";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {ProjectDailyReportDialogForm} from "./ProjectDailyReportForms";

const ProjectDailyReportsList = (props) => {
  const {project, user, userContact, ...rest} = props;

  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);

  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();
  const currentDate = moment().format("YYYY-MM-DD");

  const [
    dailyReportFilterOptions,
    setDailyReportFilterOption,
    clearDailyReportFilterOption,
    clearAllDailyReportFilterOptions,
  ] = useFilterOptions(["Prepared By", "Tag"]);

  const {query: tagQuery} = useSentinelListAPI(`projects/${project.id}/daily-reports/tags/`, {
    initialData: [],
  });
  const tagOptions = tagQuery.data;

  const dailyReportFilterParams = {
    prepared_by_id: dailyReportFilterOptions["Prepared By"].value,
    tag: dailyReportFilterOptions.Tag.value,
  };

  const {query: dailyReportsQuery, create: createDailyReport} = useSentinelListAPI(
    `projects/${project.id}/daily-reports/?page_size=${pageSize}&page=${page}&ordering=-date&${qs.stringify(
      dailyReportFilterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const dailyReports = dailyReportsQuery.data.results;

  const {query: preparedByQuery} = useSentinelListAPI(`projects/${project.id}/daily-reports/prepared-by/`, {
    initialData: [],
  });
  const preparedByOptions = preparedByQuery.data;

  const reportExists =
    dailyReports.filter((dailyReport) => dailyReport.date === currentDate && dailyReport.prepared_by.id === user.id)
      .length > 0;

  // if (!dailyReportsQuery.isFetchedAfterMount) {
  //   return <BlockUI show={true} message="Fetching daily reports..." />;
  // }

  return (
    <>
      <Helmet title={`${project.display} - Daily Reports`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Daily Reports</Typography>
      </ProjectBreadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faCalendarDay} /> Daily Reports
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header isLoading={dailyReportsQuery.isFetching}>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              {/* {reportExists ? (
                <Tooltip title="Report Already Exists">
                  <span>
                    <PaperPanel.Header.CreateButton disabled={true}>Create Daily Report</PaperPanel.Header.CreateButton>
                  </span>
                </Tooltip>
              ) : (
                <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialog(true)}>
                  Create Daily Report
                </PaperPanel.Header.CreateButton>
              )} */}
              <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialog(true)}>
                Create Daily Report
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => dailyReportsQuery.refetch()}
                isFetching={dailyReportsQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                label="Prepared By"
                name="Prepared By"
                options={preparedByOptions}
                value={dailyReportFilterOptions["Prepared By"].value}
                onChange={(value, label) => {
                  setDailyReportFilterOption("Prepared By", value, label);
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                label="Tag"
                name="Tag"
                options={tagOptions}
                value={dailyReportFilterOptions.Tag.value}
                onChange={(value, label) => {
                  setPage(1);
                  setDailyReportFilterOption("Tag", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={12} xs={12}>
              <FilterOptionChips
                filterOptions={dailyReportFilterOptions}
                onDelete={(key) => {
                  clearDailyReportFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllDailyReportFilterOptions();
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["box_folder_id"]}
            columns={[
              // {
              //   Header: "Status",
              //   accessor: "status_display",
              //   Cell: ({value, row}) => <StatusLabel status={value} />,
              // },
              {
                Header: "Date",
                accessor: "date",
                Cell: ({value, row}) => (
                  <TableStatusLabel status={row.original.status_display}>
                    <Link component={RouterNavLink} to={row.original.url} underline="always">
                      {moment(value).format("YYYY-MM-DD - ddd")}
                    </Link>
                  </TableStatusLabel>
                ),
              },
              {
                Header: "Prepared By",
                accessor: "prepared_by.full_name",
              },
              {
                Header: "Reviewed By",
                accessor: "reviewed_by.full_name",
              },
              {
                Header: "Tags",
                accessor: "tags",
                sortable: false,
                Cell: ({value, row}) => <TagList tags={value} />,
              },

              {
                Header: "Images",
                accessor: "box_folder_id",
                Cell: ({value, row}) =>
                  value && (
                    <Link component={RouterNavLink} to={`${row.original.url}#Image%20Gallery`} underline="always">
                      <FontAwesomeIcon icon={faImage} />
                    </Link>
                  ),
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "date",
                  desc: true,
                },
              ],
            }}
            data={dailyReports}
            {...rest}
          />
          {dailyReportsQuery.data.total_pages > 1 && (
            <PaperPanel.Footer display="flex" justifyContent="flex-end">
              <PaginationWithPageSize
                count={dailyReportsQuery.data.total_pages}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            </PaperPanel.Footer>
          )}
          {/* <pre>{JSON.stringify(dailyReports, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/daily-reports/legacy/`} mt={2} />
      <ProjectDailyReportDialogForm
        isNew
        isOpen={showCreateDialog}
        initialValues={{
          date: currentDate,
          tags: [],
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Creating daily report...");
          createDailyReport
            .mutateAsync(values)
            .then((newDailyReport) => {
              // navigate(newDailyReport.url);
              window.location = newDailyReport.url;
              // blockUI.unblockUI();
            })
            .catch(() => {
              blockUI.unblockUI();
            });
          setShowCreateDialog(false);
        }}
        handleClose={() => setShowCreateDialog(false)}
        projectId={project.id}
      />
    </>
  );
};

export default ProjectDailyReportsList;
