import {faTrafficCone} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Helmet} from "react-helmet";
import {PageHeader} from "../../components/PageHeader";
import {PSRDashboard} from "./PSRDashboard";

const SafetyDashboard = (props) => {
  const {...rest} = props;
  return (
    <>
      <Helmet title="Safety Dashboard" />
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faTrafficCone} /> Safety Dashboard
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PSRDashboard />
    </>
  );
};

export default SafetyDashboard;
