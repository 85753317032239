import {Typography} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import {Helmet} from "react-helmet";

const Theme = (props) => {
  const theme = useTheme();
  return (
    <>
      <Helmet title="Theme" />
      <Typography variant="h2">Theme</Typography>
      <pre>{JSON.stringify(theme, null, 2)}</pre>
    </>
  );
};

export default Theme;
