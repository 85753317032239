import {faUsers} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Typography} from "@material-ui/core";
import moment from "moment";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";

import {useNavigate} from "react-router-dom";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import useBlockUI from "../../../hooks/useBlockUI";

const ProjectReviews = (props) => {
  const {project, ...rest} = props;
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const {query: reviewsQuery, create: createReview} = useSentinelListAPI(`/projects/${project.id}/reviews/`, {
    initialData: {
      results: [],
    },

    keepPreviousData: true,
  });

  const reviews = reviewsQuery.data.results;

  return (
    <>
      <Helmet title={`${project.display} - Reviews`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Reviews</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faUsers} /> Project Reviews
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  blockUI.blockUI();
                  createReview.mutateAsync().then((response) => {
                    navigate(`/v2${response.url}`);
                    // window.location.href = response.url;
                    blockUI.unblockUI();
                  });
                }}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => reviewsQuery.refetch()}
                isFetching={reviewsQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {reviews.map((review) => {
            return (
              <PaperItem key={review.id}>
                <PaperItem.Body>
                  <Grid container spacing={2}>
                    <Grid item xs={6}>
                      <StatusLabel status={review.status_display} hint="projectReview" />{" "}
                      <MuiNavLink href={`/v2/projects/${project.id}/reviews/${review.id}/`} underline="always">
                        {review.reviewer.full_name}
                      </MuiNavLink>
                    </Grid>
                    <Grid item xs={6}>
                      {moment(review.status_changed).format("YYYY-MM-DD")}
                    </Grid>
                  </Grid>
                </PaperItem.Body>
              </PaperItem>
            );
          })}
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/reviews/`} mt={2} />
    </>
  );
};

export default ProjectReviews;
