import {Button, Dialog, DialogActions, DialogContent, DialogContentText} from "@material-ui/core";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import React from "react";
import Webcam from "react-webcam";

const WebcamCaptureDialog = (props) => {
  const {isOpen, handleClose, onCapture, ...rest} = props;
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    onCapture(imageSrc);
  }, [webcamRef]);

  return (
    <>
      <Dialog
        open={isOpen}
        // onClose={handleClose}
        // fullScreen
        fullWidth
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* <DialogTitle id="alert-dialog-title">{title}</DialogTitle> */}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Webcam
              width="100%"
              height="100%"
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              // videoConstraints={{facingMode: {exact: "environment"}}}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={capture} color="primary" startIcon={<CameraAltIcon />}>
            Take Photo
          </Button>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default WebcamCaptureDialog;
