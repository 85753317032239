import {Box, Button, Divider, Tooltip} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SaveIcon from "@material-ui/icons/Save";
import OSKey from "os-key";
import React from "react";
import {ColoredButton, DeleteButton} from "../Buttons";

const keys = OSKey(navigator.userAgent);
let ctrlKey = "";
if (keys && keys.primaryMeta && keys.meta && keys.meta.symbol) {
  // ⌘+C
  ctrlKey = `${keys.meta.symbol}`;
} else {
  // Ctrl+C
  ctrlKey = `Ctrl`;
}

export const FormActions = (props: {
  children?: React.ReactNode;
  pad?: number;
  divider?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {children, pad = 1, divider = true, ...rest} = props;
  return (
    <>
      {divider && <Divider />}
      <Box display="flex" p={pad} {...rest}>
        {children}
      </Box>
    </>
  );
};

FormActions.Left = (props) => {
  const {children, ...rest} = props;
  return (
    <Box display="flex" {...rest}>
      {children}
    </Box>
  );
};

FormActions.Right = (props) => {
  const {children, ...rest} = props;
  return (
    <Box display="flex" flexGrow={1} justifyContent="flex-end" {...rest}>
      {children}
    </Box>
  );
};

FormActions.Button = (props) => {
  const {variant = "contained", label = "", disableElevation = true, ...rest} = props;
  return (
    <Button variant={variant} type="submit" disableElevation {...rest}>
      {label}
    </Button>
  );
};

FormActions.ColoredButton = (props) => {
  const {label = "", disableElevation = true, ...rest} = props;
  return (
    <ColoredButton color="error" variant="outlined" disableElevation {...rest}>
      {label}
    </ColoredButton>
  );
};

FormActions.CreateButton = (props) => {
  const {
    tooltipPlacement = "top",
    variant = "contained",
    color = "primary",
    label = "Create",
    startIcon = <SaveIcon />,
    ...rest
  } = props;
  return (
    <Tooltip title={`${ctrlKey}+S`} aria-label="create" placement={tooltipPlacement}>
      <span>
        <Button variant={variant} color={color} type="submit" startIcon={startIcon} {...rest}>
          {label}
        </Button>
      </span>
    </Tooltip>
  );
};

FormActions.SaveButton = (props) => {
  const {
    tooltipPlacement = "top",
    variant = "contained",
    color = "primary",
    label = "Save",
    startIcon = <SaveIcon />,
    tooltip = `${ctrlKey}+S`,
    ...rest
  } = props;
  return (
    <Tooltip title={tooltip} aria-label="save" placement={tooltipPlacement}>
      <span>
        <Button variant={variant} color={color} type="submit" startIcon={startIcon} {...rest}>
          {label}
        </Button>
      </span>
    </Tooltip>
  );
};

FormActions.NextButton = (props) => {
  const {
    tooltipPlacement = "top",
    variant = "contained",
    color = "primary",
    label = "Next",
    endIcon = <ArrowForwardIcon />,
    tooltip = "",
    ...rest
  } = props;
  return (
    <Tooltip title={tooltip} aria-label="save" placement={tooltipPlacement}>
      <span>
        <Button variant={variant} color={color} type="submit" endIcon={endIcon} {...rest}>
          {label}
        </Button>
      </span>
    </Tooltip>
  );
};

FormActions.PreviousButton = (props) => {
  const {
    tooltipPlacement = "top",
    variant = "contained",
    color = "",
    label = "Previous",
    startIcon = <ArrowBackIcon />,
    tooltip = "",
    ...rest
  } = props;
  return (
    <Tooltip title={tooltip} aria-label="save" placement={tooltipPlacement}>
      <span>
        <Button variant={variant} color={color} type="submit" startIcon={startIcon} {...rest}>
          {label}
        </Button>
      </span>
    </Tooltip>
  );
};

FormActions.SaveAndAddButton = (props) => {
  return (
    <Button variant="contained" type="submit" {...props}>
      Save & Add
    </Button>
  );
};

FormActions.SaveAndEditButton = (props) => {
  const {label = "Save & Edit"} = props;
  return (
    <Button variant="contained" type="submit" {...props}>
      {label}
    </Button>
  );
};

FormActions.CancelButton = (props) => {
  return <Button {...props}>Cancel</Button>;
};

FormActions.CloseButton = (props) => {
  return <Button {...props}>Close</Button>;
};

FormActions.DeleteButton = (props) => {
  return <DeleteButton {...props} />;
};

export default FormActions;
