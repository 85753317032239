import {Box, Grid, Typography} from "@material-ui/core";
import {FieldArray} from "react-final-form-arrays";
import {CreateButton, DeleteButton} from "../../../components/Buttons";
import {CheckboxMui, DatePickerMui, TextFieldMui, YesNoNARadios} from "../../../components/forms/Fields";
import JobsiteSafetyForm from "./JobsiteFormComponents";
import useBuildField from "./useBuildField";
import {AddButton} from "./JobsiteFormComponents";
import moment from "moment";

export const CranePretaskPlan = (props) => {
  const {safetyForm, internalDetailView} = props;
  const isSpanish = !internalDetailView && safetyForm?.twilio_contact?.language === "SP";
  const isReadOnly = internalDetailView || safetyForm.is_closed;
  // const buildField = (
  //   namespace: string,
  //   field: {labelES: string; labelEN: string; name: string; Component: any; required?: boolean}
  // ) => {
  //   const {labelES, labelEN, name, Component, required = true, ...rest} = field;
  //   return (
  //     <>
  //       <Component name={`${namespace}.${name}`} label={isSpanish ? labelES : labelEN} required={required} {...rest} />
  //     </>
  //   );
  // };

  const buildField = useBuildField(isSpanish, isReadOnly);

  const craneEvaluationFields = [
    {Component: DatePickerMui, name: "date", labelEN: "Date", labelES: "Fecha"},
    {
      Component: YesNoNARadios,
      labelES:
        "¿Ha recorrido su área de trabajo para identificar sistemas en funcionamiento, líneas aéreas, servicios públicos, condiciones subterráneas, exposiciones públicas, iluminación, etc.?",
      labelEN:
        "Have you walked your work area to identify live systems, overhead lines, utilities, underground conditions, public exposures, lighting, etc?",
      name: "work_area_identification",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Existen peligros creados por otros trabajadores en su área o su trabajo crea peligros para otros?",
      labelEN:
        "Are there any hazards created by any other workers in your area or does your work create hazards for others?",
      name: "hazards_created",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Se ha inspeccionado todo el aparejo, equipo y herramientas antes de su uso?",
      labelEN: "Have all rigging, equipment and tools been inspected prior to use?",
      name: "rigging_inspection",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Los materiales, herramientas y mano de obra son adecuados para realizar el trabajo de manera segura?",
      labelEN: "Are materials, tools and manpower adequate to perform the job safely?",
      name: "adequate_materials",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Se han verificado los pesos de todas las cargas?",
      labelEN: "Have weights of all loads been verified?",
      name: "verified_load_weights",
    },
    {
      Component: YesNoNARadios,
      labelES:
        "¿Es esta una elevación crítica? (es decir, la carga supera el 80% de la capacidad de la grúa o pone en riesgo a personal, equipo o estructuras).",
      labelEN:
        "Is this a critical lift? (i.e. load exceeds 80% of crane capacity, or puts personnel, equipment or structures at risk.)",
      name: "critical_lift",
    },
    {
      Component: YesNoNARadios,
      labelES:
        "¿Esta tarea requiere permisos o procedimientos adicionales? (Notificación a la FAA, Permiso de OSHA, etc.)",
      labelEN: "Does this task require any additional permits or procedures? (FAA Notification, OSHA Permit, etc.)",
      name: "additional_permits_procedures",
    },
    {
      Component: YesNoNARadios,
      labelES:
        "¿Ha abordado los requisitos de barricadas, sistemas de advertencia o señalización apropiados para la tarea?",
      labelEN: "Have you addressed any barricading, warning system or signage requirements appropriate to the task?",
      name: "barricading_requirements",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Ha establecido un sistema de comunicación para la señalización?",
      labelEN: "Have you established a communication system for signaling?",
      name: "communication_system",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Los estabilizadores están completamente extendidos con almohadillas adecuadas?",
      labelEN: "Are outriggers fully extended with adequate pads?",
      name: "outriggers_extended",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Todos los aparejadores han sido capacitados en prácticas de aparejo seguras?",
      labelEN: "Have all riggers been trained in safe rigging practices?",
      name: "riggers_training",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Esta tarea requerirá levantar, doblarse o torcerse?",
      labelEN: "Will this task require any lifting, bending or twisting?",
      name: "lifting_bending_twisting",
    },
    {
      Component: YesNoNARadios,
      labelES:
        "¿Se asignan suficientes personas para completar la tarea de manera segura? (levantamiento, observación, guía con líneas de etiqueta, etc.)",
      labelEN: "Are enough people assigned to safely complete the task? (lifting, spotting, guiding w/taglines etc.)",
      name: "sufficient_personnel_assigned",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Hay un nuevo empleado o miembro nuevo en el proyecto que necesitará apoyo?",
      labelEN: "Is there a new hire, or new team member on the project who will need support?",
      name: "new_hire_support",
    },
    {
      Component: YesNoNARadios,
      labelES: "¿Tiene una lesión que reportar o resultó herido el día laborable anterior?",
      labelEN: "Do you have an injury to report or were you injured the prior working day?",
      name: "injury_report",
    },
  ];

  const ppeFields = [
    {
      Component: CheckboxMui,
      labelES: "Casco de seguridad",
      labelEN: "Hard Hat",
      name: "hard_hat",
    },
    {
      Component: CheckboxMui,
      labelES: "Protección contra caídas",
      labelEN: "Fall Protection",
      name: "fall_protection",
    },
    {
      Component: CheckboxMui,
      labelES: "Equipo de protección para los ojos y la cara",
      labelEN: "Eye / Face PPE",
      name: "eye_face_ppe",
    },
    {
      Component: CheckboxMui,
      labelES: "Equipo de protección para las manos y los brazos",
      labelEN: "Hand / Arm PPE",
      name: "hand_arm_ppe",
    },
    {
      Component: CheckboxMui,
      labelES: "Equipo de protección para la audición",
      labelEN: "Hearing PPE",
      name: "hearing_ppe",
    },
    {
      Component: CheckboxMui,
      labelES: "Equipo de protección para los pies",
      labelEN: "Foot PPE",
      name: "foot_ppe",
    },
    {
      Component: CheckboxMui,
      labelES: "Respirador",
      labelEN: "Respirator",
      name: "respirator",
    },
  ];
  const locateFields = [
    {
      Component: CheckboxMui,
      labelES: "Teléfonos de emergencia",
      labelEN: "Emergency Telephones",
      name: "emergency_phones",
    },
    {
      Component: CheckboxMui,
      labelES: "Extintor de incendios",
      labelEN: "Fire Extinguisher",
      name: "fire_extinguisher",
    },
    {
      Component: CheckboxMui,
      labelES: "Rutas de salida de emergencia",
      labelEN: "Emergency Exit Routes",
      name: "emergency_exit_routes",
    },
    {
      Component: CheckboxMui,
      labelES: "Equipo de primeros auxilios",
      labelEN: "First Aid Equipment",
      name: "first_aid_equipment",
    },
  ];

  return (
    <JobsiteSafetyForm
      //   isSpanish={isSpanish}
      // initialValues={{lift_evaluation: {date: moment()}, ...safetyForm.answers}}
      initialValues={{lift_evaluation: {date: moment()}}}
      title={
        isSpanish
          ? "Plan de tareas previas al levantamiento de materiales y grúas"
          : "Crane & Material Lifting Pre-Task Plan"
      }
      {...props}
    >
      {(form, values) => {
        if (values?.pretask_plan_steps === undefined) {
          form.mutators.push("pretask_plan_steps", undefined);
        }
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">
                    {isSpanish
                      ? `Responde lo siguiente al evaluar tu trabajo`
                      : `Answer the following when evaluating your work`}
                  </Typography>
                </Box>
              </Grid>
              {craneEvaluationFields.map((field) => (
                <Grid item xs={12} sm={6} key={field.name}>
                  {buildField("lift_evaluation", field)}
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h4">
                    {isSpanish ? `¿Se requiere alguno de los siguientes?` : `Are any of the Following Required?`}
                  </Typography>
                </Box>
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {isSpanish ? `Equipo de protección personal:` : `Personal Protective Equipment:`}
                    </Typography>
                  </Box>
                </Grid>

                {ppeFields.map((field) => (
                  <Grid item xs={12} sm={6} key={field.name}>
                    {buildField("ppe", field)}
                  </Grid>
                ))}
              </Grid>
              <Grid item xs={12} sm={6} container>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">{isSpanish ? `Localizar:` : `Locate:`}</Typography>
                  </Box>
                </Grid>
                {locateFields.map((field) => (
                  <Grid item xs={12} sm={6} key={field.name}>
                    {buildField("located", field)}
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box textAlign="center">
                  <Typography variant="h6">{isSpanish ? `Plan previo a la tarea` : `Pretask Plan`}</Typography>
                </Box>
              </Grid>
              <Grid item xs={12}>
                <TextFieldMui name={`pretask_plan.name`} label={isSpanish ? `Tarea` : `Task`} disabled={isReadOnly} />
              </Grid>
              <FieldArray name="pretask_plan_steps">
                {({fields}) => {
                  return (
                    <>
                      {fields.map((name, index) => {
                        return (
                          <PretaskPlanItem
                            isSpanish={isSpanish}
                            namespace={name}
                            key={index}
                            index={index}
                            onDelete={fields.length > 1 && (() => fields.remove(index))}
                            isReadOnly={isReadOnly}
                          />
                        );
                      })}
                      <Grid item xs={12}>
                        {!isReadOnly && (
                          <AddButton onClick={() => form.mutators.push("pretask_plan_steps", undefined)}>
                            Add Step
                          </AddButton>
                        )}
                      </Grid>
                    </>
                  );
                }}
              </FieldArray>
            </Grid>
          </>
        );
      }}
    </JobsiteSafetyForm>
  );
};

// const AddButton = (props) => {
//   const {children = "Add", ...rest} = props;
//   return (
//     <CreateButton fullWidth variant="outlined" disableElevation {...rest}>
//       {children}
//     </CreateButton>
//   );
// };

const PretaskPlanItem = (props) => {
  const {isSpanish, namespace, onDelete, index, isReadOnly} = props;

  return (
    <>
      <Grid item xs={12}>
        <Box textAlign="center">
          <Typography variant="h6">Step #{index + 1}:</Typography>
        </Box>
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldMui
          name={`${namespace}.step`}
          label={isSpanish ? "Pasos para el trabajo" : "Steps for Work"}
          required
          multiline
          disabled={isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={3}>
        <TextFieldMui
          name={`${namespace}.hazards`}
          label={isSpanish ? "Peligros" : "Hazards"}
          required
          multiline
          disabled={isReadOnly}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldMui
          name={`${namespace}.steps_to_address_hazards`}
          label={isSpanish ? "Medidas adoptadas para abordar los peligros" : "Steps Taken to Address Hazards"}
          required
          multiline
          disabled={isReadOnly}
        />
      </Grid>
      {!isReadOnly && onDelete && (
        <Grid item xs={12}>
          <DeleteButton fullWidth variant="outlined" disableElevation onClick={() => onDelete()}>
            Delete Step #{index + 1}
          </DeleteButton>
        </Grid>
      )}
    </>
  );
};
