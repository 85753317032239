import {
  faArrowDown,
  faArrowUp,
  faExternalLink,
  faPaperPlaneTop,
  faPlus,
  faTasks,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Breadcrumbs,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import accounting from "accounting";
import classnames from "classnames";
import {groupBy} from "lodash";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import nl2br from "../../../../js/utils/nl2br";
import {axiosAPI} from "../../../api";
import {Currency, Percentage} from "../../../components/Accounting";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {ProjectStatusIcon, StatusIcon} from "../../../components/Icons";
import InnerFooter from "../../../components/InnerFooter";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {PrevNext} from "../../../components/PrevNext";
import {ReactTableMui} from "../../../components/ReactTableMui";
import FormActions from "../../../components/forms/FormActions";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {colorError, colorSuccess} from "../../../theme/colors";
import {
  MeetingProjectCreateToolbarForm,
  MeetingProjectForecastDialogForm,
  MeetingProjectNotesDialogForm,
  MeetingProjectPaperItemForm,
} from "./ProjectManagerMeetingForms";

const ProjectManagerMeetingDetail = (props) => {
  const {employeeId, meetingId} = useParams();
  const [showSubmitDialog, setShowSubmitDialog] = React.useState(false);
  const [showEditMeetingProjectNotesDialogForm, setShowEditMeetingProjectNotesDialogForm] = React.useState(false);
  const [showEditMeetingProjectForecastDialogForm, setShowEditMeetingProjectForecastDialogForm] = React.useState(false);
  const [showProjectDetailDialogForm, setShowProjectDetailDialogForm] = React.useState(false);
  const [copyFromPreviousReportDialog, setCopyFromPreviousReportDialog] = React.useState(false);
  const [deleteMeetingProjectDialog, setDeleteMeetingProjectDialog] = React.useState(false);
  const [activeMeetingProject, setActiveMeetingProject] = React.useState({}) as any;

  const permissions = usePermissions();
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previousMeeting: {},
    },
  });
  const pageData = pageDataQuery.data;

  const {
    query: meetingQuery,
    update: updateMeeting,
    delete: deleteMeeting,
    rpc: meetingRPC,
  } = useSentinelDetailAPI(`employees/${employeeId}/pm-meetings/${meetingId}/`, {
    initialData: {},
  });

  const meeting = meetingQuery.data;

  const {
    query: meetingProjectsQuery,
    create: createMeetingProject,
    update: updateMeetingProject,
    delete: deleteMeetingProject,
  } = useSentinelListAPI(
    `employees/${employeeId}/pm-meetings/${meetingId}/projects/`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    },
    {disableOnSuccess: true, disableOnMutate: true}
  );
  const meetingProjects = meetingProjectsQuery.data.results;

  const {query: previousMeetingProjectsQuery, rpc: previousMeetingProjectsRPC} = useSentinelListAPI(
    `employees/${employeeId}/pm-meetings/${meetingId}/projects/previous/`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const previousMeetingProjects = previousMeetingProjectsQuery.data.results;
  const groupedPreviousMeetingProjects = groupBy(previousMeetingProjects, "project.id");

  const unansweredProjectsCount = meetingProjects.filter(
    (meetingProject) => !meetingProject.contract_value && !meetingProject.expected_profit && !meetingProject.notes
  ).length;

  const selectedProjectIds = meetingProjects.map((meetingProject) => meetingProject.project?.id);

  const includedMeetingProjects = meetingProjects.filter((meetingProject) => meetingProject.is_excluded === false);
  const excludedMeetingProjects = meetingProjects.filter((meetingProject) => meetingProject.is_excluded);

  const hasPreviousMeeting = Boolean(pageData.previousMeeting?.id);

  const getPreviousMeetingProject = (projectId) => {
    return groupedPreviousMeetingProjects[projectId] && groupedPreviousMeetingProjects[projectId][0]
      ? groupedPreviousMeetingProjects[projectId][0]
      : null;
  };

  if (!pageDataQuery.isFetchedAfterMount || !meetingQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching meeting..." />;
  }

  return (
    <>
      <Helmet title="Project Manager Meetings" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/employees/${employeeId}/pm-meetings/`}>
          Project Manager Meeting
        </MuiNavLink>
        <Typography color="textPrimary">
          {meeting.employee?.full_name} {moment(meeting.created).format("YYYY-MM-DD")}
        </Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            {meeting.employee?.full_name} {moment(meeting.created).format("YYYY-MM-DD")}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header isLoading={meetingQuery.isFetching}>
          <PaperPanel.Header.Title>
            <StatusIcon status={meeting.status} showTooltip /> {meeting.employee?.full_name}{" "}
            {moment(meeting.created).format("YYYY-MM-DD")}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={meetingQuery.isFetching}
                onClick={() => {
                  meetingQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Body p={1}>
          {hasPreviousMeeting && (
            <>
              <Box>{meeting.employee?.full_name}</Box>
              <Box>{moment(meeting.created).format("YYYY-MM-DD")}</Box>
              <Box>{meeting.status_display}</Box>
              <Box>
                <Link
                  underline="always"
                  target="_blank"
                  href={`/v2/employees/${pageData.previousMeeting?.employee?.id}/pm-meetings/${pageData.previousMeeting?.id}/`}
                >
                  <FontAwesomeIcon icon={faExternalLink} /> Previous Meeting{" "}
                  {moment(pageData.previousMeeting.created).format("YYYY-MM-DD")}
                </Link>
              </Box>
            </>
          )}
        </PaperPanel.Body>
      </PaperPanel>

      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={meetingProjectsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faTasks} /> Projects{" "}
            <Chip size="small" label={accounting.formatNumber(includedMeetingProjects.length)} />
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {!meeting.is_closed && hasPreviousMeeting && (
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button
                  onClick={() => setCopyFromPreviousReportDialog(true)}
                  startIcon={<FileCopyIcon />}
                >
                  Copy Contract Amounts From Previous Meeting
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={meetingProjectsQuery.isFetching}
                onClick={() => {
                  meetingProjectsQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {meeting.is_closed ? (
          <>
            <PaperPanel.Body>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                showFooter={Boolean(includedMeetingProjects.length)}
                rightAlignColumns={[
                  "contract_value",
                  "current_budget",
                  "prev_expected_profit",
                  "expected_profit",
                  "expected_profit_percent",
                  "underbilled_amount",
                  "aging_90",
                ]}
                getHeaderProps={(column) => {
                  if (["percent_complete_by_cost"].includes(column.id)) {
                    return {
                      style: {
                        width: "20px",
                      },
                    };
                  }
                }}
                columns={[
                  {
                    id: "percent_complete_by_cost",
                    // Header: "% Complete by cost",
                    disableSortBy: true,
                    accessor: (value) => {
                      return value.jtd_cost / value.contract_value > 1
                        ? 100
                        : (value.jtd_cost / value.contract_value) * 100 || 0;
                    },
                    Cell: ({value, row}) => (
                      <Tooltip
                        title={
                          <>
                            % Complete by cost <Currency number={row.original.jtd_cost} precision={0} />/
                            <Currency number={row.original.contract_value} precision={0} />
                          </>
                        }
                      >
                        <span>
                          <Percentage number={value / 100} precision={0} />
                        </span>
                      </Tooltip>
                    ),
                  },
                  {
                    id: "project",
                    Header: "Project",
                    accessor: (value) => {
                      return `${value.project.status_sort}-${value.project.display}`;
                    },
                    Cell: ({value, row}) => (
                      <>
                        {/* <ProjectStatusIcon project={row.original} showTooltip />{" "} */}
                        <ProjectStatusIcon project={row.original.project} showTooltip />{" "}
                        <Link
                          underline="always"
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            setActiveMeetingProject(row.original);
                            setShowProjectDetailDialogForm(true);
                          }}
                        >
                          {row.original.project?.display}
                        </Link>
                        <Box lineHeight={1}>
                          <small>{nl2br(row.original.notes)}</small>
                        </Box>
                      </>
                    ),
                  },
                  {
                    Header: "Contract Value",
                    accessor: "contract_value",
                    Cell: ({value, row}) => <Currency number={value} precision={0} />,
                    Footer: () => {
                      const total = includedMeetingProjects.reduce((sum, row) => row.contract_value + sum, 0);
                      return <Currency number={total} precision={0} />;
                    },
                  },
                  {
                    Header: "Current Budget",
                    accessor: "current_budget",
                    Cell: ({value, row}) => <Currency number={value} precision={0} />,
                    Footer: () => {
                      const total = includedMeetingProjects.reduce((sum, row) => row.current_budget + sum, 0);
                      return <Currency number={total} precision={0} />;
                    },
                  },
                  {
                    id: "prev_expected_profit",
                    Header: "Prev Expected Profit",
                    accessor: (value) => {
                      const previousMeetingProject = getPreviousMeetingProject(value.project.id);
                      return previousMeetingProject ? previousMeetingProject.expected_profit : null;
                    },
                    Cell: ({value, row}) => (value !== null ? <Currency number={value} precision={0} /> : null),
                  },
                  {
                    Header: "Expected Profit",
                    accessor: "expected_profit",
                    Cell: ({value, row}) => {
                      return (
                        <ExpectedProfit
                          previousMeetingProject={getPreviousMeetingProject(row.original.project?.id)}
                          meetingProject={row.original}
                        />
                      );
                    },
                    Footer: () => {
                      const total = includedMeetingProjects.reduce((sum, row) => row.expected_profit + sum, 0);
                      return <Currency number={total} precision={0} />;
                    },
                  },
                  {
                    id: "expected_profit_percent",
                    Header: "EP %",
                    accessor: (value) => {
                      return (value.expected_profit / value.contract_value) * 100;
                    },
                    Cell: ({value, row}) => <Percentage number={value / 100} precision={1} />,
                    Footer: () => {
                      const expectedProfit = includedMeetingProjects.reduce((sum, row) => row.expected_profit + sum, 0);
                      const contractValue = includedMeetingProjects.reduce((sum, row) => row.contract_value + sum, 0);
                      return <Percentage number={expectedProfit / contractValue} precision={1} />;
                    },
                    // Cell: ({value, row}) => (value !== null ? <Currency number={value} precision={0} /> : null),
                  },
                  {
                    id: "underbilled_amount",
                    Header: "Under Billed Amount",
                    accessor: (value) => {
                      return value.is_underbilled ? value.underbilled_amount : 0;
                    },
                    Cell: ({value, row}) => <Currency number={value} precision={0} />,
                    Footer: () => {
                      const total = includedMeetingProjects.reduce(
                        (sum, row) => (row.is_underbilled ? row.underbilled_amount : 0) + sum,
                        0
                      );
                      return <Currency number={total} precision={0} />;
                    },
                  },
                  {
                    Header: "Aging > 90",
                    accessor: "aging_90",
                    Cell: ({value, row}) => <Currency number={value} precision={0} />,
                    Footer: () => {
                      const total = includedMeetingProjects.reduce((sum, row) => row.aging_90 + sum, 0);
                      return <Currency number={total} precision={0} />;
                    },
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "project",
                      desc: false,
                    },
                  ],
                }}
                // onSortBy={(sortBy, rows) => {
                //   console.log("rows", rows);
                //   // setSortedIncludedMeetingProjects(rows);
                // }}
                data={includedMeetingProjects}
              />
            </PaperPanel.Body>
          </>
        ) : (
          <>
            <MeetingProjectCreateToolbarForm
              onSubmit={(values, form) => {
                createMeetingProject.mutateAsync(values).then(() => {
                  meetingProjectsQuery.refetch();
                  form.restart();
                });
                // console.log("onSubmit", values, form);
              }}
              selectedProjectIds={selectedProjectIds}
            />
            <PaperPanel.Body>
              {includedMeetingProjects.map((meetingProject) => {
                return (
                  <MeetingProjectPaperItemForm
                    meetingProject={meetingProject}
                    key={meetingProject.id}
                    onSave={(newValues, values) => {
                      updateMeetingProject.mutateAsync({id: values.id, ...newValues});
                    }}
                    onDelete={(values) => {
                      deleteMeetingProject.mutateAsync(values.id);
                    }}
                    onEditNotes={(values) => {
                      setActiveMeetingProject(values);
                      setShowEditMeetingProjectNotesDialogForm(true);
                    }}
                    onEditForecast={(values) => {
                      setActiveMeetingProject(values);
                      setShowEditMeetingProjectForecastDialogForm(true);
                    }}
                  />
                );
              })}
              {/* <pre>{JSON.stringify(meetingProjects, null, 2)}</pre> */}
            </PaperPanel.Body>
          </>
        )}
      </PaperPanel>
      {excludedMeetingProjects.length > 0 && (
        <>
          <Box mb={2} />
          <PaperPanel>
            <PaperPanel.Header isLoading={meetingProjectsQuery.isFetching}>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faTasks} /> Removed Projects{" "}
                <Chip size="small" label={accounting.formatNumber(excludedMeetingProjects.length)} />
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={meetingProjectsQuery.isFetching}
                    onClick={() => {
                      meetingProjectsQuery.refetch();
                    }}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                // containerComponent={Paper}
                rightAlignColumns={["current_budget", "aging_90", "underbilled_amount"]}
                getHeaderProps={(column) => {
                  if (["add"].includes(column.id)) {
                    return {
                      style: {
                        width: "20px",
                      },
                    };
                  }
                }}
                columns={[
                  {
                    Header: "",
                    accessor: "id",
                    id: "add",
                    disableSortBy: true,
                    Cell: ({value, row}) => {
                      return (
                        <Tooltip title="Add Job To Meeting">
                          <FontAwesomeIcon
                            icon={faPlus}
                            fixedWidth
                            onClick={() => {
                              updateMeetingProject.mutateAsync({id: value, is_excluded: false}).then(() => {
                                meetingProjectsQuery.refetch();
                              });
                            }}
                            style={{cursor: "pointer"}}
                          />
                        </Tooltip>
                      );
                    },
                  },
                  {
                    id: "project",
                    Header: "Project",
                    accessor: (value) => {
                      return `${value.project.status_sort}-${value.project.display}`;
                    },
                    Cell: ({value, row}) => (
                      <>
                        {/* <ProjectStatusIcon project={row.original} showTooltip />{" "} */}
                        <ProjectStatusIcon project={row.original.project} showTooltip />{" "}
                        <Link underline="always" href={row.original.project?.url}>
                          {row.original.project?.display}
                        </Link>
                      </>
                    ),
                  },
                  {
                    Header: "Current Budget",
                    accessor: "current_budget",
                    Cell: ({value, row}) => <Currency number={value} precision={0} />,
                  },
                  {
                    Header: "Aging 90",
                    accessor: "aging_90",
                    Cell: ({value, row}) => <Currency number={value} precision={0} />,
                  },
                  {
                    id: "underbilled_amount",
                    Header: "Under Billed Amount",
                    accessor: (value) => {
                      return value.is_underbilled ? value.underbilled_amount : 0;
                    },
                    Cell: ({value, row}) => <Currency number={value} precision={0} />,
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "project",
                      desc: false,
                    },
                  ],
                  hiddenColumns: meeting.is_closed ? ["add"] : [],
                }}
                data={excludedMeetingProjects}
              />
            </PaperPanel.Body>
          </PaperPanel>
        </>
      )}

      <LegacyUILink href={`/employees/${employeeId}/pm-meetings/${meetingId}/`} mt={2} />

      <InnerFooter>
        <Box display="flex">
          <Button
            onClick={() => {
              setShowSubmitDialog(true);
            }}
            color="primary"
            variant="contained"
            disabled={meeting.is_closed}
          >
            <FontAwesomeIcon icon={faPaperPlaneTop} /> <Box ml={1} />
            Submit
          </Button>

          {meeting.can_unlock && !permissions.can_view_all_pm_meetings && (
            <>
              <Box ml={1} />
              <Button
                onClick={() => {
                  blockUI.blockUI("Unlocking...");
                  meetingRPC.mutateAsync({action: "mark-unlocked"}).then(() => {
                    Promise.all([meetingQuery.refetch(), meetingProjectsQuery.refetch()]).then(() => {
                      blockUI.unblockUI();
                      setShowSubmitDialog(false);
                    });
                  });
                }}
                variant="outlined"
              >
                Unlock
              </Button>
            </>
          )}
          {meeting.is_closed && permissions.can_view_all_pm_meetings && (
            <>
              <Box ml={1} />
              <Button
                onClick={() => {
                  blockUI.blockUI("Unlocking...");
                  meetingRPC.mutateAsync({action: "mark-unlocked"}).then(() => {
                    Promise.all([meetingQuery.refetch(), meetingProjectsQuery.refetch()]).then(() => {
                      blockUI.unblockUI();
                      setShowSubmitDialog(false);
                    });
                  });
                }}
                variant="outlined"
              >
                Executive Unlock
              </Button>
            </>
          )}
          {!meeting.is_closed && (
            <>
              <Box ml={1} />
              <FormActions.DeleteButton onClick={() => setDeleteMeetingProjectDialog(true)} />
            </>
          )}
        </Box>
      </InnerFooter>

      <MeetingProjectNotesDialogForm
        isOpen={showEditMeetingProjectNotesDialogForm}
        initialValues={activeMeetingProject}
        onSubmit={(values) => {
          blockUI.blockUI("Updating Notes...");
          updateMeetingProject.mutateAsync({id: values.id, notes: values.notes}).then(() => {
            meetingProjectsQuery.refetch().then(() => {
              blockUI.unblockUI();
              setShowEditMeetingProjectNotesDialogForm(false);
            });
          });
        }}
        handleClose={() => {
          setShowEditMeetingProjectNotesDialogForm(false);
        }}
      />

      <MeetingProjectForecastDialogForm
        projectId={activeMeetingProject.project?.id}
        isOpen={showEditMeetingProjectForecastDialogForm}
        initialValues={activeMeetingProject}
        onCreateNewForecast={(values) => {
          blockUI.blockUI("Creating Forecast...");
          axiosAPI.post(`projects/${values.project?.id}/forecasts/`).then((response) => {
            updateMeetingProject.mutateAsync({id: values.id, project_forecast: response.data}).then(() => {
              blockUI.unblockUI();
              window.location.href = response.data.url;
            });
          });
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Updating Forecast...");
          updateMeetingProject.mutateAsync(values).then(() => {
            Promise.all([meetingProjectsQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowEditMeetingProjectForecastDialogForm(false);
            });
          });
        }}
        handleClose={() => {
          setShowEditMeetingProjectForecastDialogForm(false);
        }}
      />

      <Dialog
        open={showProjectDetailDialogForm}
        onClose={() => setShowProjectDetailDialogForm(false)}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle id="form-dialog-title">
          <Grid container justifyContent="space-between">
            <Grid item>
              <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" maxWidth={730} fontSize={36}>
                <ProjectStatusIcon project={activeMeetingProject.project} showTooltip />{" "}
                {activeMeetingProject.project?.display}
              </Box>
            </Grid>
            <Grid item>
              <PrevNext
                setActiveItem={setActiveMeetingProject}
                items={includedMeetingProjects}
                activeItem={activeMeetingProject}
              />
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Box display="flex" justifyContent="center" alignItems="center">
            <Box maxWidth={800}>
              <Grid container spacing={0}>
                <MeetingProjectDetailLabel>
                  <Tooltip title="JDT Cost/Contract Value">
                    <span>% Complete By Cost</span>
                  </Tooltip>
                </MeetingProjectDetailLabel>
                <MeetingProjectDetailValue>
                  <Tooltip
                    title={
                      <>
                        % Complete by cost <Currency number={activeMeetingProject.jtd_cost} precision={0} />/
                        <Currency number={activeMeetingProject.contract_value} precision={0} />
                      </>
                    }
                  >
                    <span>
                      <Percentage
                        number={
                          activeMeetingProject.jtd_cost / activeMeetingProject.contract_value > 1
                            ? 1
                            : (activeMeetingProject.jtd_cost / activeMeetingProject.contract_value) * 1 || 0
                        }
                        precision={0}
                      />
                    </span>
                  </Tooltip>
                </MeetingProjectDetailValue>

                <MeetingProjectDetailLabel>Contract Value</MeetingProjectDetailLabel>
                <MeetingProjectDetailValue>
                  <Currency number={activeMeetingProject.contract_value} precision={0} />
                </MeetingProjectDetailValue>
                <MeetingProjectDetailLabel>Current Budget</MeetingProjectDetailLabel>
                <MeetingProjectDetailValue>
                  <Currency number={activeMeetingProject.current_budget} precision={0} />
                </MeetingProjectDetailValue>
                <MeetingProjectDetailLabel>Prev Expected Profit</MeetingProjectDetailLabel>
                <MeetingProjectDetailValue>
                  {getPreviousMeetingProject(activeMeetingProject.project?.id) && (
                    <Currency
                      number={getPreviousMeetingProject(activeMeetingProject.project?.id)?.expected_profit}
                      precision={0}
                    />
                  )}
                </MeetingProjectDetailValue>
                <MeetingProjectDetailLabel>Expected Profit</MeetingProjectDetailLabel>
                <MeetingProjectDetailValue>
                  <ExpectedProfit
                    previousMeetingProject={getPreviousMeetingProject(activeMeetingProject.project?.id)}
                    meetingProject={activeMeetingProject}
                  />
                </MeetingProjectDetailValue>
                <MeetingProjectDetailLabel>Expected Profit %</MeetingProjectDetailLabel>
                <MeetingProjectDetailValue>
                  <Percentage
                    number={activeMeetingProject.expected_profit / activeMeetingProject.contract_value}
                    precision={1}
                  />
                </MeetingProjectDetailValue>
                <MeetingProjectDetailLabel>Under Billed Amount</MeetingProjectDetailLabel>
                <MeetingProjectDetailValue>
                  <Currency
                    number={activeMeetingProject.is_underbilled ? activeMeetingProject.underbilled_amount : 0}
                    precision={0}
                  />
                </MeetingProjectDetailValue>
                <MeetingProjectDetailLabel>{`Aging > 90`}</MeetingProjectDetailLabel>
                <MeetingProjectDetailValue>
                  <Currency number={activeMeetingProject.aging_90} precision={0} />
                </MeetingProjectDetailValue>
              </Grid>
            </Box>
          </Box>

          {/* <pre>{JSON.stringify(activeMeetingProject, null, 2)}</pre> */}
        </DialogContent>

        <DialogActions>
          {activeMeetingProject.project_forecast && (
            <Button
              variant="outlined"
              href={activeMeetingProject.project_forecast?.url}
              target="_blank"
              startIcon={<FontAwesomeIcon icon={faExternalLink} />}
            >
              Project Forecast
            </Button>
          )}
          <Button
            variant="outlined"
            href={activeMeetingProject.project?.url}
            target="_blank"
            startIcon={<FontAwesomeIcon icon={faExternalLink} />}
          >
            Project Dashboard
          </Button>
          <Button onClick={() => setShowProjectDetailDialogForm(false)}>Cancel</Button>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        isOpen={showSubmitDialog}
        onApprove={() => {
          blockUI.blockUI("Submitting...");
          meetingRPC.mutateAsync({action: "mark-submitted"}).then(() => {
            Promise.all([meetingQuery.refetch(), meetingProjectsQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowSubmitDialog(false);
            });
          });
        }}
        onDeny={() => {
          setShowSubmitDialog(false);
        }}
      >
        {unansweredProjectsCount === 0 ? (
          <>
            Are you sure you want to submit the PM meeting? You will have 30 minutes to unlock and edit the meeting.
            After that time you not be able to edit.
          </>
        ) : (
          <>
            Are you sure you want to submit the PM meeting you have {unansweredProjectsCount} unanswered questions? You
            will have 30 minutes to unlock and edit the meeting. After that time you not be able to edit.
          </>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={copyFromPreviousReportDialog}
        onApprove={() => {
          blockUI.blockUI("Copying Values From Previous Meeting...");
          previousMeetingProjectsRPC.mutateAsync({action: "copy"}).then(() => {
            Promise.all([meetingQuery.refetch(), meetingProjectsQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setCopyFromPreviousReportDialog(false);
            });
          });
        }}
        onDeny={() => {
          setCopyFromPreviousReportDialog(false);
        }}
      >
        This will copy all contract values from you previous PM report. It will only copy values where the contract
        value is 0 in the current report.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteMeetingProjectDialog}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteMeeting.mutateAsync().then(() => {
            blockUI.unblockUI();
            window.location.href = `/v2/employees/${employeeId}/pm-meetings/`;
          });
        }}
        onDeny={() => {
          setDeleteMeetingProjectDialog(false);
        }}
      >
        You would like to delete this meeting. This cannot be undone.
      </ConfirmationDialog>

      {/* <pre>{JSON.stringify(groupedPreviousMeetingProjects, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(meeting, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

const MeetingProjectDetailLabel = ({children, ...rest}) => (
  <Grid item xs={7}>
    <Box fontSize={36}>{children}</Box>
  </Grid>
);
const MeetingProjectDetailValue = ({children, ...rest}) => (
  <Grid item xs={5}>
    <Box fontSize={36} fontWeight="fontWeightBold" textAlign="right">
      {children}
    </Box>
  </Grid>
);

const ExpectedProfit = ({previousMeetingProject, meetingProject}) => {
  const previousExpectedProfit = previousMeetingProject ? previousMeetingProject.expected_profit : null;
  return (
    <>
      {previousExpectedProfit ? (
        <>
          {previousExpectedProfit - meetingProject.expected_profit < 0 && (
            <>
              <FontAwesomeIcon icon={faArrowUp} color={colorSuccess} />{" "}
            </>
          )}
          {previousExpectedProfit - meetingProject.expected_profit > 0 && (
            <>
              <FontAwesomeIcon icon={faArrowDown} color={colorError} />{" "}
            </>
          )}
        </>
      ) : null}
      <Currency number={meetingProject.expected_profit} precision={0} />
    </>
  );
};
export default ProjectManagerMeetingDetail;
