import {Box, FormHelperText, Grid, Typography} from "@material-ui/core";
import {
  CheckboxMui,
  DatePickerMui,
  MaskedInputMui,
  TextFieldMui,
  YesNoNARadios,
  YesNoRadios,
} from "../../../components/forms/Fields";
import JobsiteSafetyForm from "./JobsiteFormComponents";
import {colorError} from "../../../theme/colors";
import useBuildField from "./useBuildField";
import moment from "moment";

export const ExcavationChecklist = (props) => {
  const {safetyForm, internalDetailView} = props;
  const isSpanish = !internalDetailView && safetyForm?.twilio_contact?.language === "SP";
  const isReadOnly = internalDetailView || safetyForm.is_closed;

  const buildField = useBuildField(isSpanish, isReadOnly);

  const generalFields = [
    {Component: DatePickerMui, name: "date", labelEN: "Date", labelES: "Fecha"},
    {
      Component: TextFieldMui,
      labelES: "Ubicación Específica del Trabajo",
      labelEN: "Specific Location of Work",
      name: "work_location",
    },
    {
      Component: TextFieldMui,
      labelES: "Nombre de la Persona Competente",
      labelEN: "Competent Person's Name",
      name: "competent_person",
    },

    {
      Component: CheckboxMui,
      labelEN: `A copy of the listed operators required to locate per the 811 excavation ticket has been obtained. Prior to starting any excavation verify that all utilities have located/marked or has advised of clearance.`,
      labelES: `Se obtuvo una copia de los operadores enumerados que deben ubicarse según el boleto de excavación 811. Antes de comenzar cualquier excavación, verifique que todos los servicios públicos hayan localizado/marcado o hayan informado de su autorización.`,
      name: "has_checklist",
    },
  ];

  const emergencyContactFields = [
    {
      Component: MaskedInputMui,
      labelES: "Eléctrico",
      labelEN: "Electrical",
      name: "electrical",
      mask: "(999) 999-9999",
    },
    {
      Component: MaskedInputMui,
      labelES: "Gas",
      labelEN: "Gas",
      name: "gas",
      mask: "(999) 999-9999",
    },
    {
      Component: MaskedInputMui,
      labelES: "Fibra",
      labelEN: "Fiber",
      name: "fiber",
      mask: "(999) 999-9999",
    },
    {
      Component: MaskedInputMui,
      labelES: "Agua",
      labelEN: "Water",
      name: "water",
      mask: "(999) 999-9999",
    },
    {
      Component: MaskedInputMui,
      labelES: "Otros",
      labelEN: "Others",
      name: "others",
      mask: "(999) 999-9999",
    },
  ];

  const ticketDetails = [
    {
      Component: TextFieldMui,
      labelES: "Ticket#",
      labelEN: "Ticket#",
      name: "ticket",
    },
    {
      Component: DatePickerMui,
      labelES: "Fecha",
      labelEN: "Date",
      name: "date",
    },
    {
      Component: DatePickerMui,
      labelES: "Fecha de caducidad",
      labelEN: "Expiration Date",
      name: "expiration_date",
    },
    {
      Component: DatePickerMui,
      labelES: "Fecha de actualización",
      labelEN: "Update Date",
      name: "update_date",
    },
  ];

  return (
    <JobsiteSafetyForm
      //   isSpanish={isSpanish}
      initialValues={{general: {date: moment().format("YYYY-MM-DD")}}}
      title={isSpanish ? "Lista de verificación previa a la excavación" : "Pre-Excavation Checklist"}
      {...props}
    >
      {(form, values) => (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography variant="h6">
                  {isSpanish
                    ? `Este formulario debe ser completado por la persona competente en el sitio antes de cualquier excavación o zanja.`
                    : `This form must be completed by the competent person onsite prior to any excavation or trenching.`}
                </Typography>
              </Box>
            </Grid>

            {generalFields.map((field) => (
              <Grid item xs={12} sm={4} key={`general.${field.name}`}>
                {buildField("general", field)}
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CheckboxMui
                name={"ticket.is_issued"}
                label={
                  isSpanish
                    ? "Se contactó al 811 USA y se generó un ticket de excavación al 811."
                    : "811 USA has been contacted and an 811 excavation ticket been generated."
                }
                disabled={isReadOnly}
              />
            </Grid>
            {!values?.ticket?.is_issued ? (
              <>
                <Grid item xs={12}>
                  <Box textAlign="center" color={colorError}>
                    {isSpanish ? (
                      <>
                        Si no se ha comunicado con el 811, <strong>DETENGA</strong> toda actividad hasta que se haya
                        notificado al 811 sobre la excavación propuesta y se haya generado un ticket. No se permitirá
                        comenzar ningún trabajo hasta que se hayan marcado todos los servicios públicos. USA NORTH 811
                        proporcionará una fecha de inicio.
                      </>
                    ) : (
                      <>
                        If you have not contacted 811, <strong>STOP</strong> all activity until 811 has been notified of
                        proposed excavation and a ticket has been generated. No work will be permitted to start until
                        all utilities have been marked. USA NORTH 811 will provide a start date.
                      </>
                    )}
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                {ticketDetails.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("ticket", field)}
                  </Grid>
                ))}
              </>
            )}

            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography variant="h6">
                  {isSpanish
                    ? `Número de emergencia para todos los servicios públicos ubicados en la zona de trabajo propuesta.`
                    : `Emergency number for all located utilities in the proposed work zone`}
                </Typography>
              </Box>
            </Grid>

            {emergencyContactFields.map((field) => (
              <Grid item xs={12} sm={4} key={field.name}>
                {buildField("emergency_contact", field)}
              </Grid>
            ))}

            <Grid item xs={12}>
              <Box textAlign="center" color={colorError}>
                {isSpanish ? (
                  <>
                    <strong>TENGA EN CUENTA</strong>: Deberá excavar manualmente dentro de las 24” del diámetro exterior
                    del servicio público. Los servicios públicos que entren en conflicto con su excavación deben
                    ubicarse con métodos de examen no destructivos, herramientas manuales y protegidas antes de utilizar
                    equipos eléctricos. Notifique a la empresa de servicios públicos afectada de CUALQUIER contacto para
                    su utilidad.
                  </>
                ) : (
                  <>
                    <strong>BE ADVISED</strong>: You shall hand excavate within 24” of the outside diameter of the
                    utility. Utilities that conflict with your excavation are to be located with non-destructive
                    examination methods, hand tools and protected before power equipment is used. Notify the affected
                    utility of ANY contact to their utility.
                  </>
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </JobsiteSafetyForm>
  );
};
