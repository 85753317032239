import {faFlagCheckered} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import pluralize from "pluralize";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import Gravatar from "../../../components/Gravatar";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";

const UnmetConditionListItem = (props) => {
  const {condition} = props;
  return (
    <li>
      {condition.url ? (
        <Link href={condition.url} underline="always">
          {condition.obj_verbose_name === "None" ? "" : condition.obj_verbose_name}
        </Link>
      ) : (
        <>{condition.obj_verbose_name === "None" ? "" : condition.obj_verbose_name}</>
      )}{" "}
      {condition.message}
    </li>
  );
};

const ContactListItem = (props) => {
  const {contact, title} = props;
  if (!contact || !contact.full_name) {
    return null;
  }
  return (
    <ListItem button component="a" href={contact.url} style={{width: "auto"}}>
      <ListItemAvatar>
        <Avatar>
          <Gravatar alt={contact.full_name} email={contact.email} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={contact.full_name} secondary={title} />
    </ListItem>
  );
};

const ProjectCloseout = (props) => {
  const {project} = props;
  const permissions = usePermissions();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      unmetConditions: [],
    },
  });
  const unmetConditions = pageDataQuery.data?.unmetConditions;
  const projectExecutive = pageDataQuery.data?.projectExecutive;
  const projectManager = pageDataQuery.data?.projectManager;
  const projectEngineer = pageDataQuery.data?.projectEngineer;
  const showBonusCloseoutLink = permissions.can_manage_bonuses || permissions.has_bonus_account;
  const canCloseout = unmetConditions.length === 0;
  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching closeout info..." />;
  }
  return (
    <>
      <Helmet title={`${project.display} - Closeout`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Closeout</Typography>
      </ProjectBreadcrumbs>

      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faFlagCheckered} /> {project.display} - Closeout
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {showBonusCloseoutLink && (
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button
                  component={MuiNavLink}
                  underline="none"
                  href={`/v2/projects/${project.id}/bonuses/closeout-requests/`}
                >
                  Bonus Closeout Requests
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border={showBonusCloseoutLink}>
              <PaperPanel.Header.RefreshButton
                isFetching={pageDataQuery.isFetching}
                onClick={() => pageDataQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
        {canCloseout ? (
          <PaperPanel.Alert severity="success">
            It appears that this project qualifies to be closed out.
          </PaperPanel.Alert>
        ) : (
          <PaperPanel.Alert severity="warning">
            This project cannot be closed out for the following {pluralize("reason", unmetConditions.length)}.
          </PaperPanel.Alert>
        )}
        <PaperPanel.Body p={1}>
          <List dense style={{display: "flex", flexDirection: isSmall ? "column" : "row"}}>
            <ContactListItem contact={projectExecutive} title="Project Executive" />
            <ContactListItem contact={projectManager} title="Project Manager" />
            <ContactListItem contact={projectEngineer} title="Project Engineer" />
          </List>
          <ol>
            {unmetConditions.map((condition, i) => (
              <UnmetConditionListItem key={i} condition={condition} />
            ))}
          </ol>
          {/* <pre>{JSON.stringify(unmetConditions, null, 2)}</pre> */}
        </PaperPanel.Body>
        {/* <PaperPanel.Footer p={1}>Footer</PaperPanel.Footer> */}
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/closeout/legacy/`} mt={2} />

      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default ProjectCloseout;
