import {AppBar, Box, Grid, Link, Tab, Typography} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import {Switches as FinalSwitches} from "mui-rff";
import {Form as FinalForm} from "react-final-form";
import {useQueryState} from "use-location-state";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {PDFButton} from "../../../components/Buttons";
import constructionTypes from "../../../components/forms/choices/constructionTypes.json";
import contractTypes from "../../../components/forms/choices/contractTypes.json";
import covid19VaccinationVerification from "../../../components/forms/choices/covid19VaccinationVerification.json";
import csiCodeTypes from "../../../components/forms/choices/csiCodeTypes.json";
import GMPTypes from "../../../components/forms/choices/GMPTypes.json";
import offices from "../../../components/forms/choices/offices.json";
import projectDelivery from "../../../components/forms/choices/projectDelivery.json";
import timezones from "../../../components/forms/choices/timezones.json";
import usStates from "../../../components/forms/choices/usStates.json";
import {
  DatePickerMui,
  GoogleMapsAddressAutocompleteMui,
  MaskedInputMui,
  NumberFieldMui,
  SimpleAutoselectMui,
  SimpleSelect,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";
import PaperPanel from "../../../components/PaperPanel";
import usePermissions from "../../../hooks/usePermissions";
import {makeNovoClasses} from "../../../theme";

export const ProjectUpdateForm = (props: {
  onSubmit: (values: any) => void;
  fieldSize?: string;
  needsJobStartSubmission?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const permissions = usePermissions();
  // const [selectedTab, setSelectedTab] = React.useState(history.location.hash || "#overview");
  const [selectedTab, setSelectedTab] = useQueryState("tab", "overview");
  const novoClasses = makeNovoClasses();

  const {onSubmit, csiCodeChoices, needsJobStartSubmission = false, ...rest} = props;
  return (
    <TabContext value={selectedTab}>
      <PaperPanel>
        <PaperPanel.Body>
          <Box mb={2}>
            <AppBar
              position="static"
              color="default"
              // elevation={0}
            >
              <TabList
                value={selectedTab}
                onChange={(event, newValue) => {
                  setSelectedTab(newValue);
                  // navigate(newValue);
                }}
                variant="scrollable"
                scrollButtons="auto"
              >
                <Tab label="Overview" value="overview" className={novoClasses.smallTab} />
                <Tab label="Construction Details" value="construction-details" className={novoClasses.smallTab} />
                <Tab label="External Permissions" value="external-permissions" className={novoClasses.smallTab} />
                <Tab label="Job Start" value="job-start" className={novoClasses.smallTab} />
              </TabList>
            </AppBar>
          </Box>
        </PaperPanel.Body>

        <PaperPanel.Body>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => (
              <>
                <BeforeUnload block={!pristine} />
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <TabPanel value="overview">
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            <TextFieldMui name="name" label="Name" required autoFocus />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui
                              name="description"
                              label="Description"
                              multiline
                              rows={2}
                              maxRows={4}
                              helperText={`Short project description`}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui
                              name="project_invoice_name"
                              label="Project Invoice Name"
                              helperText={`Project name that will show up on client invoices.  If blank, it will default to project name.`}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleSelect name="csi_code_type" label="CSI Code" required options={csiCodeTypes} />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleSelect
                              name="type_of_contract"
                              label="Contract Type"
                              required
                              options={contractTypes}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleSelect name="job_start_gmp" label="GMP Type" options={GMPTypes} />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleSelect name="office" label="Office" required options={offices} />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleAutoselectMui label="Timezone" name="timezone" required options={timezones} />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleSelect
                              name="project_delivery"
                              label="Project Delivery"
                              allowNull
                              options={projectDelivery}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant="h5">Address</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <GoogleMapsAddressAutocompleteMui
                              name="address1"
                              label="Address 1"
                              onPlaceSelected={(place) => {
                                form.change("address1", place.address1);
                                form.change("address2", place.address2);
                                form.change("city", place.locality_long_name);
                                form.change("state", place.administrative_area_level_1_short_name);
                                form.change("postal_code", place.postal_code);
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui name="address2" label="Address 2" />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui name="address3" label="Address 3" />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <TextFieldMui name="city" label="City" />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <SimpleSelect name="state" label="State" options={usStates} />
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <TextFieldMui name="postal_code" label="Postal Code" />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <MaskedInputMui name="job_phone" label="Job Phone" mask="(999)-999-9999" />
                          </Grid>
                          {/* <Grid item xs={12}>
                            <FinalSwitches
                              name="restrict_novo_signers"
                              data={{label: "Restrict NOVO Signers", value: true}}
                              disabled={!permissions.is_pm_or_greater}
                              helperText={`This will restrict signers on commitments and change orders to PM and above.`}
                            />
                          </Grid> */}
                        </Grid>
                      </TabPanel>
                      <TabPanel value="construction-details">
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={4}>
                            <DatePickerMui label="Start Date" name="start_date" />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <DatePickerMui label="Finish Date" name="finish_date" />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <DatePickerMui
                              label="Warranty Date"
                              name="warranty_date"
                              helperText={`Defaults to 1 year after finish date`}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <NumberFieldMui required name="square_feet" label="Square Footage" />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <SimpleSelect
                              name="type_of_construction"
                              label="Type of Construction"
                              options={constructionTypes}
                              allowNull
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <TextFieldMui name="utilities" label="Utilities" />
                          </Grid>
                          {/*
                          <Grid item xs={12} md={6}>
                            <DateTimePickerMui label="Created Date" name="created" />
                          </Grid>
                          */}
                          <Grid item xs={12} sm={4}>
                            <SimpleSelect
                              allowNull
                              name="vaccination_verification"
                              label="Vaccination Verification"
                              options={covid19VaccinationVerification}
                              disabled={values.vaccination_verification_hint}
                              helperText={
                                values.vaccination_verification_hint
                                  ? `This values was automatically set.  Reason: ${values.vaccination_verification_hint}`
                                  : `Do not change if you don't know what you are doing.  This will significantly effect check ins for this job.`
                              }
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value="external-permissions">
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <FinalSwitches
                              name="is_externally_visible"
                              data={{label: "Show in Sentinel Portal", value: true}}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FinalSwitches
                              name="can_view_external_reports"
                              data={{label: "Download PDFs in Portal", value: true}}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FinalSwitches
                              name="external_permissions.can_read_pcos"
                              data={{label: "View PCOs", value: true}}
                              helperText={`Allow owners and CM's to view PCO's`}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FinalSwitches
                              name="external_permissions.can_read_cors"
                              data={{label: "View CORs", value: true}}
                              helperText={`Allow owners and CM's to view COR's`}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <FinalSwitches
                              name="external_permissions.can_read_daily_reports"
                              data={{label: "View Daily Reports", value: true}}
                              helperText={`Allow clients and owners to view daily reports`}
                            />
                          </Grid>
                        </Grid>
                      </TabPanel>
                      <TabPanel value="job-start">
                        {needsJobStartSubmission && (
                          <Box mt={-4} mx={-3} mb={2}>
                            <PaperPanel.Alert severity="info">
                              This project has not completed a job start form.{" "}
                              <Link href={`/v2/projects/${values.id}/start/`} underline="always">
                                Click here
                              </Link>{" "}
                              to create and submit the job start form and get a job number assigned
                            </PaperPanel.Alert>
                          </Box>
                        )}
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            <TextFieldMui
                              name="job_start_authorization_number"
                              label="Job Start Authorization #"
                              multiline
                              helperText={`Contract #, PO # etc`}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <PDFButton size="small" title="Job Start" href={`/reports2/projects/${values.id}/start/`} />
                          </Grid>
                          <Grid item xs={12}>
                            <TinyMCEMui name="job_start_notes" label="Job Start Notes" />
                          </Grid>
                        </Grid>
                      </TabPanel>
                    </PaperPanel.Body>
                    <InnerFooter>
                      <FormActions.SaveButton disabled={submitting || pristine} />
                    </InnerFooter>
                    {/* <FormActions>
                        <FormActions.Left>
                          <FormActions.SaveButton disabled={submitting || pristine} />
                        </FormActions.Left>
                      </FormActions> */}
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </FinalFormKeyboardShortcuts>
                </form>
              </>
            )}
          </FinalForm>
        </PaperPanel.Body>
      </PaperPanel>
    </TabContext>
  );
};
