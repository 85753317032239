import React from "react";
import {VictoryTooltip} from "victory";

export const VictoryTooltipMui: React.FC = (props: any) => {
  return (
    <VictoryTooltip
      {...props}
      y={props.y + 7}
      flyoutPadding={3}
      style={{fontSize: 6}}
      pointerWidth={3}
      pointerLength={4}
      cornerRadius={2}
      flyoutStyle={{
        fill: "white",
        strokeWidth: 0.5,

        // stroke: ({datum}) => (datum.x === 10 ? "tomato" : "black"),
      }}
    />
  );
};
