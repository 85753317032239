import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, makeStyles} from "@material-ui/core";
import {Radios} from "mui-rff";
import {Form} from "react-final-form";
import {axiosAPI} from "../../api";
import covid19VaccineStatuses from "../../components/forms/choices/covid19VaccineStatuses.json";
import covid19VaccineStatusesSelfAttest from "../../components/forms/choices/covid19VaccineStatusesSelfAttest.json";
import FormActions from "../../components/forms/FormActions";
import PaperPanel from "../../components/PaperPanel";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    form: {
      "& .MuiFormControlLabel-root": {
        paddingTop: "5px",
        paddingBottom: "5px",
        // backgroundColor: "red",
      },
    },
  };
});

export const VaccineStatusCheckForm = (props: {
  twilioContactUUID: string;
  selfAttest: boolean;
  onSubmitted: (values: any) => void;
  [rest: string]: any;
}) => {
  const {twilioContactUUID, onSubmitted, selfAttest, ...rest} = props;
  const classes = useStyles();

  return (
    <>
      <Form
        onSubmit={(values) => {
          axiosAPI
            .post(`/twilio-contact/${twilioContactUUID}/update-covid19-vaccine-status/`, values, {baseURL: ""})
            .then((response) => onSubmitted(response.data));
        }}
        validate={(values) => {
          const errors = {};
          return errors;
        }}
        // initialValues={{
        //   covid19_vaccine_status: wellnessCheckResult.twilio_contact.covid19_vaccine_status,
        // }}
        {...rest}
      >
        {(formProps) => {
          const {handleSubmit, form, submitting, pristine, errors, invalid, values} = formProps;
          return (
            <form onSubmit={handleSubmit} className={classes.form}>
              <Radios
                // label="Vaccine Status"
                name="covid19_vaccine_status"
                required={true}
                data={selfAttest ? covid19VaccineStatusesSelfAttest : covid19VaccineStatuses}
              />

              <Box mt={4} mb={4}>
                <Button
                  fullWidth
                  variant="contained"
                  color="secondary"
                  type="submit"
                  size="large"
                  disabled={invalid || submitting}
                >
                  {invalid ? "Submit" : "Submit"}
                </Button>
              </Box>
            </form>
          );
        }}
      </Form>
    </>
  );
};

export const VaccineStatusUpdateDialogForm = (props: {
  twilioContactUUID: string;
  onSubmitted: (values: any) => void;
  isOpen: boolean;
  selfAttest: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {twilioContactUUID, onSubmitted, isOpen, selfAttest, handleClose, ...rest} = props;
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        // maxWidth="xl"
      >
        <Form
          onSubmit={(values) => {
            axiosAPI
              .post(`/twilio-contact/${twilioContactUUID}/update-covid19-vaccine-status/`, values, {baseURL: ""})
              .then((response) => onSubmitted(response.data));
          }}
          {...rest}
        >
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Update Status</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <PaperPanel.Body>
                  <Radios
                    name="covid19_vaccine_status"
                    required={true}
                    data={selfAttest ? covid19VaccineStatusesSelfAttest : covid19VaccineStatuses}
                  />
                </PaperPanel.Body>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
    </>
  );
};
