export const buildQuestionObjects = (
  safetyForm: {fields: {[key: string]: {[key: string]: any}}},
  key: string,
  defaultComponent: React.ComponentType<any>,
  specialCaseCallback?: (field: any & {name: string; Component: React.ComponentType<any>}) => void
) => {
  const result = Object.keys(safetyForm.fields[key]).map((fieldName) => ({
    ...safetyForm.fields[key][fieldName],
    name: fieldName,
    Component: defaultComponent,
  }));
  if (specialCaseCallback) result.forEach((field) => specialCaseCallback(field));
  return result;
};
