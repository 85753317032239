import React from "react";
import {AppContext} from "../Contexts";

const useBlockUI = () => {
  const appContext = React.useContext(AppContext);
  return {
    blockUI: appContext.blockUI,
    unblockUI: appContext.unblockUI,
    toggleBlockUI: appContext.toggleBlockUI,
    isBlocked: appContext.showBlockUI,
  };
};
export default useBlockUI;
