import {Box} from "@material-ui/core";
import PaperPanel from "./PaperPanel";

const ExternalInfoCard = (props: {
  children: React.ReactNode;
  title?: React.ReactNode;
  icon?: React.ReactNode;
  display?: string;
  [rest: string]: any;
}) => {
  const {children, title, icon, iconColor, display = "flex", ...rest} = props;
  return (
    <Box my={2} {...rest}>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title maxWidth={"100%"}>
            {icon} {title}
          </PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <Box display={display} flexWrap={"wrap"} px={1}>
            {children}
          </Box>
        </PaperPanel.Body>
      </PaperPanel>
    </Box>
  );
};

export default ExternalInfoCard;
