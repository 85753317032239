import {faLink, faLinkSlash, faPaperPlaneTop} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Breadcrumbs, Grid, Link, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {Alert, TabContext, TabPanel} from "@material-ui/lab";
import {groupBy, sumBy} from "lodash";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import ActivityStream from "../../../components/ActivityStream";
import ClosedBanner from "../../../components/ClosedBanner";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {StatusIcon} from "../../../components/Icons";
import {MuiNavLink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {PCCOGeneralInfoForm, PCCOLinkableSelectDialog} from "././PCCOForms";
import {PCCOItemPaperItem} from "./PCCOItemPaperItem";
import TimberlineCOPanel from "./TimberlineCOPanel";

const PCCODetail = (props) => {
  const {project, ...rest} = props;
  const {pccoId: ocoId} = useParams();
  const novoClasses = makeNovoClasses();
  const permissions = usePermissions();
  const blockUI = useBlockUI();

  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [submitDialogIsOpen, setSubmitDialogIsOpen] = React.useState(false);
  const [removeItemsConfirmationIsOpen, setRemoveItemsConfirmationsIsOpen] = React.useState(false);
  const [linkItemsDialogIsOpen, setLinkItemsDialogIsOpen] = React.useState(false);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
    },
  });

  const pageData = pageDataQuery.data;

  const {
    query: ocoQuery,
    update: updateOCO,
    delete: deleteOCO,
    rpc: ocoRPC,
  } = useSentinelDetailAPI(["projects", project.id, "pccos", parseInt(ocoId)], {
    initialData: {},
  });

  const oco = ocoQuery.data;

  const {
    query: pccoItemsQuery,
    create: createPCCOItem,
    update: updatePCCOItem,
    delete: deletePCCOItem,
    rpc: pccoItemRPC,
  } = useSentinelListAPI(["projects", project.id, "pccos", parseInt(ocoId), "items"], {
    initialData: {
      results: [],
    },
  });

  const pccoItems = pccoItemsQuery.data.results;

  const allItemIds = pccoItems.map((item) => item.id);

  const {query: linkableItemsQuery} = useSentinelListAPI(
    `projects/${project.id}/pccos/${parseInt(ocoId)}/items/linkable/`,

    {
      initialData: [],
    }
  );

  const linkableItems = linkableItemsQuery.data;

  const {query: timberlineQuery} = useSentinelListAPI(
    ["projects", project.id, "pccos", parseInt(ocoId), "timberline-run"],
    {
      initialData: [],
    }
  );

  const timberlineResults = timberlineQuery.data;

  const groupedByRunNumber = groupBy(timberlineResults, "run");

  const timberlineRunNumbers = Object.keys(groupedByRunNumber);

  const runNumberTotals = timberlineRunNumbers.map((num) => ({
    number: num,
    total: sumBy(groupedByRunNumber[num], "amount"),
  }));

  const parseDurationString = (duration: string): number => {
    const durationArray = duration.split(",");
    if (durationArray.length > 1) {
      return parseInt(durationArray[0].split(" ")[0]);
    }
    return moment.duration(durationArray[0]).days();
  };

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  if (!ocoQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching OCO info..." />;
  }

  return (
    <>
      <Helmet title={`OCO Detail - ${oco.display}`} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/pccos/`}>
          OCOs
        </MuiNavLink>
        <Typography color="textPrimary">{oco.display}</Typography>
      </Breadcrumbs>
      {oco.extracker_id && (
        <Box mb={2}>
          <Alert severity="info">This OCO was created by Extracker</Alert>
        </Box>
      )}
      <ClosedBanner object={oco} />
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>OCO Detail</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right>
          <>
            <PageHeader.Right.CurrencySpark
              title="Original Contract Value"
              number={oco.contract.original_value}
              precision={2}
            />
            <PageHeader.Right.CurrencySpark
              title="Prior Change Orders"
              number={oco.prior_contract_value - oco.contract.original_value}
              precision={2}
              border
            />
            <PageHeader.Right.CurrencySpark
              title="Contract Prior to This OCO"
              number={oco.prior_contract_value}
              precision={2}
              border
            />
            <PageHeader.Right.CurrencySpark title="This OCO" number={oco.budget_approved_amount} border precision={2} />
            <PageHeader.Right.CurrencySpark
              title="New Contract Value"
              number={oco.current_contract_value}
              border
              precision={2}
            />
            {parseDurationString(oco.schedule_impact) > 0 && (
              <PageHeader.Right.RightSpark
                value={`${parseDurationString(oco.schedule_impact)} Days`}
                title="Schedule Impact"
                border
              />
            )}
          </>
        </PageHeader.Right>
      </PageHeader>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <StatusIcon status={oco.status} tooltip={oco.status_display} showTooltip type="pcco" />
                    <Box ml={1}>OCO #{oco.number_display}</Box>
                  </Box>
                }
                value="general"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Items
                    <TabCountChip isLoading={!pccoItemsQuery.isFetchedAfterMount} count={pccoItemsQuery.data?.count} />
                  </Box>
                }
                className={novoClasses.smallTab}
                value="items"
              />
              {oco.timberline_run && <Tab value="timberline" className={novoClasses.smallTab} label="Timberline" />}
              <Tab label={"Logs"} value="logs" className={novoClasses.smallTab} />

              <PreviousNextTabs previous={pageData.previous} next={pageData.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={ocoQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <Grid container wrap="nowrap" spacing={4}>
                    <Grid item>
                      <StatusLabel status={oco.status_display} hint="pcco" /> {oco.display}
                    </Grid>
                  </Grid>
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButtons
                      // pdfURL={`/reports2/ccos/scco/${oco.id}/`}
                      buildReportURL={`/reports2/projects/${project.id}/ccos/pccos/detail/filter/?pk=${oco.id}`}
                    >
                      <PaperPanel.Header.Menu.PDFMenuItem
                        href={`/reports2/ccos/pcco/${ocoId}/?display_architect_signature=on&display_items=on`}
                      >
                        With Items
                      </PaperPanel.Header.Menu.PDFMenuItem>
                      <PaperPanel.Header.Menu.PDFMenuItem
                        href={`/reports2/ccos/pcco/${ocoId}/?display_architect_signature=on`}
                        divider
                      >
                        Without Items
                      </PaperPanel.Header.Menu.PDFMenuItem>

                      <PaperPanel.Header.Menu.PDFMenuItem
                        href={`/reports2/ccos/pcco/${ocoId}/?display_architect_signature=on&display_items=on&group_by_budget=on`}
                      >
                        With Items by Budget Code
                      </PaperPanel.Header.Menu.PDFMenuItem>
                      <PaperPanel.Header.Menu.PDFMenuItem
                        href={`/reports2/ccos/pcco/${ocoId}/?display_architect_signature=on&group_by_budget=on`}
                      >
                        Without Items By Budget Code
                      </PaperPanel.Header.Menu.PDFMenuItem>
                    </PaperPanel.Header.PDFButtons>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.DocuSignButton docuSignObject={oco} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/ccos/pcco/${oco.id}/`}
                      uploadURL={`/projects/${project.id}/pccos/${oco.id}/upload-to-box/`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          {permissions.can_view_accounting_tools_menu && (
                            <>
                              <PaperPanel.Header.Menu.MenuItem
                                component={Link}
                                href={`/accounting-tools/pccos/single-import/${oco.id}/download/`}
                              >
                                Download Timberline Import File
                              </PaperPanel.Header.Menu.MenuItem>
                              <MenuItemHeader border />
                            </>
                          )}

                          <Tooltip
                            title={
                              !oco.can_submit_to_accounting
                                ? oco?.can_submit_to_accounting_reason || ``
                                : `Submit OCO to Accounting`
                            }
                          >
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={(event) => {
                                  setSubmitDialogIsOpen(true);
                                  popupState.close();
                                }}
                                disabled={!oco.can_submit_to_accounting}
                              >
                                <FontAwesomeIcon icon={faPaperPlaneTop} /> <Box ml={1} />
                                Submit
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                    {/* <PaperPanel.Header.RefreshButton
                      onClick={() => ocoQuery.refetch()}
                      isFetching={ocoQuery.isFetching}
                    /> */}
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PCCOGeneralInfoForm
                projectId={project.id}
                restrictNovoSigners={project.restrict_novo_signers}
                isReadOnly={oco.is_closed || project.is_closed}
                onSubmit={(values) => {
                  blockUI.blockUI("Saving...");
                  updateOCO
                    .mutateAsync(values)
                    .then(() =>
                      // Promise.all([ocoQuery.refetch(), pccoItemsQuery.refetch(), linkableItemsQuery.refetch()])
                      ocoQuery.refetch()
                    )
                    .then(() => blockUI.unblockUI())
                    .catch(() => blockUI.unblockUI());
                }}
                initialValues={oco}
              />
            </>
          </TabPanel>
          <TabPanel value="items">
            <>
              <PaperPanel.TabHeader isLoading={pccoItemsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <PaperItem.SelectedCheckbox
                    label={"Select All"}
                    onChange={(event, value) => {
                      if (value) {
                        addSelectedItemIds(allItemIds);
                      } else {
                        removeAllSelectedItemIds();
                      }
                    }}
                    indeterminate={
                      !allItemIdsSelected && selectedItemIds.size < allItemIds.length && selectedItemIds.size
                    }
                    checked={allItemIdsSelected}
                  />
                  CORs
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <Tooltip title={oco.is_closed ? `Cannot link items to a closed OCO` : ``}>
                      <span>
                        <PaperPanel.Header.CreateButton
                          startIcon={<FontAwesomeIcon icon={faLink} />}
                          onClick={() => setLinkItemsDialogIsOpen(true)}
                          disabled={oco.is_closed}
                        >
                          Link Items
                        </PaperPanel.Header.CreateButton>
                      </span>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <Tooltip title={oco.is_closed ? `Cannot unlink items from a closed OCO` : ``}>
                      <span>
                        <PaperPanel.Header.DeleteButton
                          startIcon={<FontAwesomeIcon icon={faLinkSlash} />}
                          onClick={() => setRemoveItemsConfirmationsIsOpen(true)}
                          disabled={selectedItemIds.size < 1 || oco.is_closed}
                        >
                          Unlink Selected
                        </PaperPanel.Header.DeleteButton>
                      </span>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={pccoItemsQuery.isFetching}
                      onClick={() => {
                        pccoItemsQuery.refetch();
                        ocoQuery.refetch();
                        linkableItemsQuery.refetch();
                      }}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PaperPanel.Body mx={-3} mt={-2} mb={-3}>
                {pccoItems.map((item) => (
                  <PCCOItemPaperItem
                    item={item}
                    key={item.id}
                    itemIsSelected={selectedItemIds.has(item.id)}
                    onChangeSelected={(e, selectBetween) => {
                      if (selectBetween) {
                        addSelectedItemId(item.id, e.nativeEvent.shiftKey);
                      } else {
                        removeSelectedItemId(item.id);
                      }
                    }}
                  />
                ))}
              </PaperPanel.Body>
            </>
          </TabPanel>
          {oco.timberline_run && (
            <TabPanel value="timberline">
              <>
                <PaperPanel.TabHeader>
                  <PaperPanel.Header.Title>Timberline</PaperPanel.Header.Title>
                </PaperPanel.TabHeader>
                <PaperPanel.Body mx={-3} mt={-2}>
                  <Alert severity={"info"}>
                    Timberline data is refreshed nightly and does not reflect changes made today.
                  </Alert>
                  <TimberlineCOPanel timberlineResults={timberlineResults} />
                </PaperPanel.Body>
              </>
            </TabPanel>
          )}
          <TabPanel value="logs">
            <>
              <PaperPanel.TabHeader>
                <PaperPanel.Header.Title>Logs</PaperPanel.Header.Title>
              </PaperPanel.TabHeader>
              <PaperPanel.Body mx={-3} mt={0}>
                <ActivityStream url={`actstream/ccos/pcco/${oco.id}/`} />
              </PaperPanel.Body>
            </>
          </TabPanel>
        </PaperPanel>
      </TabContext>

      <CreatedByModifiedBy obj={oco} mt={2} />
      {/* <LegacyUILink href={`/ccos/pcco/${oco.id}/legacy/`} mt={1} /> */}

      <ConfirmationDialog
        isOpen={submitDialogIsOpen}
        onDeny={() => setSubmitDialogIsOpen(false)}
        onApprove={() => {
          setSubmitDialogIsOpen(false);
          blockUI.blockUI("Submitting...");
          ocoRPC
            .mutateAsync({action: "submit"})
            .then(() => ocoQuery.refetch())
            .then(() => blockUI.unblockUI());
        }}
        title={"Submit OCO?"}
      >
        Submit this OCO to accounting? You will be unable to change the value once submitted.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={removeItemsConfirmationIsOpen}
        onDeny={() => setRemoveItemsConfirmationsIsOpen(false)}
        title="Remove Selected Items?"
        onApprove={() => {
          blockUI.blockUI("Removing...");
          pccoItemRPC
            .mutateAsync({action: "unlink", data: Array.from(selectedItemIds)})
            .then(() => Promise.all([pccoItemsQuery.refetch(), ocoQuery.refetch(), linkableItemsQuery.refetch()]))
            .then(() => {
              removeAllSelectedItemIds();
              setRemoveItemsConfirmationsIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => {
              setRemoveItemsConfirmationsIsOpen(false);
              blockUI.unblockUI();
            });
        }}
      >
        Do you want to remove the selected items from this OCO?
      </ConfirmationDialog>

      <PCCOLinkableSelectDialog
        isOpen={linkItemsDialogIsOpen}
        handleClose={() => setLinkItemsDialogIsOpen(false)}
        projectId={project.id}
        oco={oco}
        onChangeSelectedItem={(corIds) => {
          blockUI.blockUI("Adding...");
          pccoItemRPC
            .mutateAsync({action: "link", data: corIds})
            .then(() => Promise.all([pccoItemsQuery.refetch(), ocoQuery.refetch(), linkableItemsQuery.refetch()]))
            .then(() => {
              removeAllSelectedItemIds();
              setLinkItemsDialogIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => setLinkItemsDialogIsOpen(false));
        }}
        linkableItems={linkableItems}
      />
      {/* <h2>Page Data Query</h2>
      <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre>
      <h2>SCO Query</h2>
      <pre>{JSON.stringify(ocoQuery, null, 2)}</pre>
      <h2>SCOS</h2>
      <pre>{JSON.stringify(oco, null, 2)}</pre> */}
    </>
  );
};

export default PCCODetail;
