import {faBan} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {TextFieldMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";

export const VoidEnvelopeDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, isNew, onSubmit, isOpen, handleClose, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Void Document</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      By voiding this document, recipients can no longer view or sign it. Recipients receive an email
                      notification, which includes your reason for voiding the document.
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="void-reason" required maxlength="200" label="Void Reason" />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton
                  disabled={submitting || pristine}
                  label={"Void"}
                  startIcon={<FontAwesomeIcon icon={faBan} />}
                />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
