import {axiosAPI} from "../api";

const getCompanySignator = async (
  companyId: number
): Promise<{full_name: string; company: {name: string; id: number}} | null> => {
  const result = await axiosAPI.get(`/companies/${companyId}/`);
  const {signator, name, id} = result.data;
  if (signator) signator.company = {name: name, id: id};
  return signator;
};

export default getCompanySignator;
