import {
  faArrowRight,
  faCheck,
  faExclamationCircle,
  faExternalLink,
  faFileCheck,
  faHardHat,
  faHistory,
  faIdBadge,
  faUserShield,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Grid, Link, makeStyles, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import HtmlRender from "../../../../js/components/HtmlRender";
import {unslugify} from "../../../../js/utils/string";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {LabelValue} from "../../../components/LabelValue";
import {MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {colorError, colorSuccess} from "../../../theme/colors";
import {TwilioContactDialogForm} from "./ProjectDailyCheckInForms";

const WellnessCheckinDetail = (props) => {
  const {project, user, ...rest} = props;
  const blockUI = useBlockUI();

  const useStyles = makeStyles((theme) => {
    const {transitions, breakpoints, palette, shape, typography} = theme;
    return {
      response: {
        "& p": {
          marginBottom: 0,
        },
      },
    };
  });

  const params = useParams();
  const {checkinId} = params;
  const novoClasses = makeNovoClasses();
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useQueryState("tab", "contact");
  const [showContactEditDialog, setShowContactEditDialog] = React.useState(false);
  const [showFuzzyMatchDialog, setShowFuzzyMatchDialog] = React.useState(false);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;

  const {
    query: checkinQuery,
    update: updateCheckin,
    rpc: checkinRPC,
  } = useSentinelDetailAPI(`projects/${project.id}/twilioapp/wellness-check-results/${checkinId}/`, {
    initialData: {},
  });

  const {query: scanLogQuery} = useSentinelDetailAPI(
    `/projects/${project.id}/twilioapp/wellness-check-results/${checkinId}/scan-log/?page_size=1000`,
    {initialData: {results: []}}
  );

  const {query: foremenQuery} = useSentinelListAPI(
    `projects/${project.id}/foremen/`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    },
    {
      idProp: "uuid",
    }
  );
  const foremen = foremenQuery.data.results;

  const checkIn = checkinQuery.data;
  const scanLog = scanLogQuery.data.results;

  const foremenUUIDs = foremen.map((foreman) => foreman.uuid);
  const isForeman = foremenUUIDs.includes(checkIn?.twilio_contact?.uuid);

  const companyFuzzyMatch = checkIn.twilio_contact?.fuzzy_company?.name === checkIn.twilio_contact?.company;

  if (!checkinQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Pretask Plan Info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Check In Detail`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/check-ins/#date=${checkIn.compliance_date}`}>
          <Typography color="textSecondary">Check Ins</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">Check In Detail</Typography>
      </ProjectBreadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faIdBadge} /> {checkIn.twilio_contact.name} -{" "}
            {moment.tz(checkIn.checkin_timestamp, project.timezone).format("LL")}
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Contact" value="contact" className={novoClasses.smallTab} />
              <Tab label="Response" value="response" className={novoClasses.smallTab} />
              <Tab label="Check In Logs" value="checkinLogs" className={novoClasses.smallTab} />
              {/* <Tab label="QR Code" value="qr" className={novoClasses.smallTab} /> */}
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="contact">
            <PaperPanel.TabHeader>
              {/* <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faUser} /> Contact
              </PaperPanel.Header.Title> */}
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.EditButton onClick={() => setShowContactEditDialog(true)}>
                    Edit
                  </PaperPanel.Header.EditButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action>
                  <PaperPanel.Header.ColoredButton
                    startIcon={<FontAwesomeIcon icon={faUserShield} />}
                    href={`/wellness-check/${checkIn.uuid}`}
                  >
                    View Badge
                  </PaperPanel.Header.ColoredButton>
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => {
                      checkinQuery.refetch();
                      pageDataQuery.refetch();
                    }}
                    isFetching={checkinQuery.isFetching || pageDataQuery.isFetching}
                  />
                  <Box mr={-1} />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.Body p={1}>
              <Grid container>
                <Grid item sm={5} xs={12}>
                  <LabelValue
                    label="Name"
                    value={
                      <>
                        {checkIn.twilio_contact?.name}{" "}
                        {isForeman && (
                          <Tooltip title="Foreman">
                            <FontAwesomeIcon icon={faHardHat} color={colorSuccess} />
                          </Tooltip>
                        )}
                      </>
                    }
                  />
                  <LabelValue
                    label="Company"
                    value={
                      companyFuzzyMatch ? (
                        <>
                          {checkIn.twilio_contact?.company}{" "}
                          <Tooltip title="Company Matched">
                            <FontAwesomeIcon icon={faCheck} color={colorSuccess} />
                          </Tooltip>
                        </>
                      ) : (
                        <>
                          <Tooltip
                            title={
                              checkIn.twilio_contact?.fuzzy_company?.name
                                ? `Possible match to ${checkIn.twilio_contact?.fuzzy_company?.name}`
                                : `Unable to match to a project company`
                            }
                          >
                            <span
                              onClick={() => {
                                if (checkIn.twilio_contact?.fuzzy_company?.name) {
                                  setShowFuzzyMatchDialog(true);
                                } else {
                                  setShowContactEditDialog(true);
                                }
                              }}
                              style={{color: colorError, cursor: "pointer"}}
                            >
                              {checkIn.twilio_contact?.company}{" "}
                              <FontAwesomeIcon icon={faExclamationCircle} color={colorError} />
                            </span>
                          </Tooltip>
                        </>
                      )
                    }
                  />
                  <LabelValue
                    label="Date"
                    value={moment.tz(checkIn.checkin_timestamp, project.timezone).format("LL")}
                  />
                  <LabelValue
                    label="Language"
                    value={checkIn?.twilio_contact?.language === "SP" ? "Spanish" : "English"}
                  />
                </Grid>
                <Grid item sm={5} xs={12}>
                  <LabelValue label="Status" value={checkIn.status} />
                  <LabelValue
                    label="Created"
                    value={checkIn.created ? moment.tz(checkIn.created, project.timezone).format("hh:mm A z") : ""}
                  />
                  <LabelValue
                    label="Check In"
                    value={
                      checkIn.checkin_timestamp
                        ? moment.tz(checkIn.checkin_timestamp, project.timezone).format("hh:mm A z")
                        : ""
                    }
                  />
                  {checkIn.checkout_timestamp && (
                    <LabelValue
                      label="Check Out"
                      value={
                        checkIn.checkout_timestamp
                          ? moment.tz(checkIn.checkout_timestamp, project.timezone).format("hh:mm A z")
                          : ""
                      }
                    />
                  )}
                  <LabelValue label="Total Site Visits" value={pageData.checkInCount} />
                  <LabelValue
                    label="Last Site Visit"
                    value={
                      <Link href={`/v2/projects/${project.id}/check-ins/#date=${pageData.lastComplianceDate}`}>
                        {moment(pageData.lastComplianceDate).format("LL")}
                      </Link>
                    }
                  />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box pl={1} mt={{xs: 2, sm: 0}}>
                    <img src={`/qrcode/?data=${checkIn.qr_code_url}&border=0`} height={130} />
                  </Box>
                </Grid>
              </Grid>
            </PaperPanel.Body>
            {/* <PaperPanel.TabHeader mt={5}>
              {" "}
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faFileCheck} /> Responses
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => checkinQuery.refetch()}
                    isFetching={checkinQuery.isFetching}
                  />
                  <Box mr={-1} />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.Body>
              <Grid container>
                <Grid item xs={12}>
                  {checkIn.responses && (
                    <>
                      <LabelValue label="Responses" value="" />
                      {Object.entries(checkIn.responses).map(([key, value]) => {
                        const response = value as any;
                        return (
                          <div key={key} className={classes.response}>
                            <HtmlRender html={response.question} />{" "}
                            {key.startsWith("-") ? (
                              <strong style={{color: response.answer ? colorSuccess : colorError}}>
                                {unslugify(response.answer.toString())}
                              </strong>
                            ) : (
                              <strong style={{color: response.answer ? colorError : colorSuccess}}>
                                {unslugify(response.answer.toString())}
                              </strong>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                  {checkIn.scanned && (
                    <>
                      <Box mt={2} />
                      <LabelValue
                        label="Scanned"
                        value={moment.tz(checkIn.scanned, project.timezone).format("LL hh:mm A z")}
                      />
                      <LabelValue label="Scanned By" value={checkIn.scanned_by?.full_name} />
                    </>
                  )}
                </Grid>
              </Grid>
            </PaperPanel.Body> */}
          </TabPanel>

          <TabPanel value="response">
            <PaperPanel.TabHeader>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faFileCheck} /> Responses
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => checkinQuery.refetch()}
                    isFetching={checkinQuery.isFetching}
                  />
                  <Box mr={-1} />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.Body>
              <Grid container>
                <Grid item xs={12}>
                  {checkIn.responses && (
                    <>
                      <LabelValue label="Responses" value="" />
                      {Object.entries(checkIn.responses).map(([key, value]) => {
                        const response = value as any;
                        return (
                          <div key={key} className={classes.response}>
                            <HtmlRender html={response.question} />{" "}
                            {key.startsWith("-") ? (
                              <strong style={{color: response.answer ? colorSuccess : colorError}}>
                                {unslugify(response.answer.toString())}
                              </strong>
                            ) : (
                              <strong style={{color: response.answer ? colorError : colorSuccess}}>
                                {unslugify(response.answer.toString())}
                              </strong>
                            )}
                          </div>
                        );
                      })}
                    </>
                  )}
                  {checkIn.scanned && (
                    <>
                      <Box mt={2} />
                      <LabelValue
                        label="Scanned"
                        value={moment.tz(checkIn.scanned, project.timezone).format("LL hh:mm A z")}
                      />
                      <LabelValue label="Scanned By" value={checkIn.scanned_by?.full_name} />
                    </>
                  )}
                </Grid>
              </Grid>
            </PaperPanel.Body>
          </TabPanel>

          <TabPanel value="checkinLogs">
            <PaperPanel.TabHeader>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faHistory} /> Scan Log
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => checkinQuery.refetch()}
                    isFetching={checkinQuery.isFetching}
                  />
                  <Box mr={-1} />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.TabHeader>
            <PaperPanel.Body mx={-3} mt={-2}>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                columns={[
                  {
                    Header: "Scanned By",
                    accessor: "scanned_by.full_name",
                  },
                  {
                    Header: "Scanned",
                    accessor: "created",
                    Cell: ({values}) => moment.tz(checkIn.scanned, project.timezone).format("LL hh:mm A z"),
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "created",
                      desc: true,
                    },
                  ],
                }}
                data={scanLog}
              />
            </PaperPanel.Body>
          </TabPanel>
        </PaperPanel>
      </TabContext>
      {user.is_superuser && (
        <>
          <Box mt={2} />
          <strong>Superuser Info</strong>
          <Grid container spacing={1}>
            <Grid item sm={6}>
              <Link href={`/admin/twilioapp/wellnesscheckresult/${checkIn.id}/change/`} target="_blank">
                <FontAwesomeIcon icon={faExternalLink} /> Admin
              </Link>
            </Grid>
            <Grid item sm={6}>
              <Link
                href={`/tools/wellness-checks/contact-tracing/?number=${checkIn.twilio_contact?.number}&date=${checkIn.compliance_date}`}
                target="_blank"
              >
                <FontAwesomeIcon icon={faExternalLink} /> Contact Tracing
              </Link>
            </Grid>
          </Grid>
        </>
      )}
      <TwilioContactDialogForm
        initialValues={checkIn.twilio_contact}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          checkinRPC
            .mutateAsync({
              action: `${checkIn.twilio_contact.id}`,
              baseURL: `twilioapp/contacts/`,
              method: "PATCH",
              data: {
                name: values.name,
                company: values.company,
                language: values.language,
              },
            })
            .then(() => {
              checkinQuery.refetch().then(() => {
                setShowContactEditDialog(false);
                blockUI.unblockUI();
              });
            })
            .catch(() => blockUI.unblockUI());
        }}
        isOpen={showContactEditDialog}
        handleClose={() => setShowContactEditDialog(false)}
      />

      <ConfirmationDialog
        isOpen={showFuzzyMatchDialog}
        onDeny={() => setShowFuzzyMatchDialog(false)}
        onApprove={() => {
          blockUI.blockUI("Saving...");
          checkinRPC
            .mutateAsync({
              action: `${checkIn.twilio_contact.id}`,
              baseURL: `twilioapp/contacts/`,
              method: "PATCH",
              data: {
                company: checkIn.twilio_contact?.fuzzy_company?.name,
              },
            })
            .then(() => {
              checkinQuery.refetch().then(() => {
                setShowFuzzyMatchDialog(false);
                blockUI.unblockUI();
              });
            })
            .catch(() => blockUI.unblockUI());
        }}
        title="Clean Up Match?"
      >
        <span>
          Do you want to accept the suggested match?
          <Box mt={1}>
            {checkIn.twilio_contact?.company}

            <Box mx={1} component="span">
              <FontAwesomeIcon icon={faArrowRight} />
            </Box>

            <strong>{checkIn.twilio_contact?.fuzzy_company?.name}</strong>
          </Box>
        </span>
      </ConfirmationDialog>
      {/* <pre>{JSON.stringify(checkIn, null, 2)}</pre> */}
    </>
  );
};

export default WellnessCheckinDetail;
