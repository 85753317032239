import {faNoteSticky} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import PollIcon from "@material-ui/icons/Poll";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import LabeledGroup from "../../../../js/components/LabeledGroup";
import AutoSaveDebounce from "../../../../js/components/ReactFinalForm/AutoSaveDebounce";
import nl2br from "../../../../js/utils/nl2br";
import {Currency} from "../../../components/Accounting";
import {CreateButton} from "../../../components/Buttons";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {
  ApiAutoselectMui,
  AutoselectMui,
  CurrencyFieldMui,
  ProjectAutoselectMui,
  SimpleAutoselectMui,
  TextFieldMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {ProjectStatusIcon} from "../../../components/Icons";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";

export const MeetingProjectPaperItemForm = (props) => {
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const {isReadOnly, onSave, onDelete, onEditNotes, onEditForecast, meetingProject, packageId, ...rest} = props;

  return (
    <>
      <FinalForm key={meetingProject.id} initialValues={meetingProject} onSubmit={(values) => {}} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => {
          return (
            <form
              autoComplete="off"
              onSubmit={(e) => {
                e.preventDefault();
              }}
            >
              <PaperItem pt={1}>
                <AutoSaveDebounce
                  //debounce={0}
                  save={(newValue, oldValues) => {
                    if (isReadOnly) {
                      return;
                    }
                    onSave(newValue, values);
                  }}
                />
                <PaperItem.Body>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={6}>
                      <Box>
                        <ProjectStatusIcon project={meetingProject.project} showTooltip />{" "}
                        <Link underline="always" href={meetingProject.project.url}>
                          {meetingProject.project.display}
                        </Link>
                      </Box>
                    </Grid>

                    <Grid item xs={12} md={3}>
                      <CurrencyFieldMui name="contract_value" label="Contract Value" decimalScale={0} required />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <CurrencyFieldMui name="expected_profit" label="Expected Profit" decimalScale={0} required />
                    </Grid>
                  </Grid>
                  {values?.project_forecast && (
                    <>
                      <LabeledGroup label="Forecast">
                        <Grid container spacing={1}>
                          <Grid item xs={12} md={6}>
                            <Tooltip title="Forecast">
                              <span>
                                <Link href={values.project_forecast.url} underline="always">
                                  {values.project_forecast.display}
                                </Link>
                              </span>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Tooltip title="Forecast Expected Value">
                              <span>
                                <Link
                                  onClick={() =>
                                    form.change("contract_value", values.project_forecast.expected_total_value)
                                  }
                                  underline="always"
                                  style={{cursor: "pointer"}}
                                >
                                  <Currency number={values.project_forecast.expected_total_value} precision={0} />
                                </Link>
                              </span>
                            </Tooltip>
                          </Grid>
                          <Grid item xs={12} md={3}>
                            <Tooltip title="Forecast Expected Profit">
                              <span>
                                <Link
                                  onClick={() =>
                                    form.change("expected_profit", values.project_forecast.expected_total_profit)
                                  }
                                  underline="always"
                                  style={{cursor: "pointer"}}
                                >
                                  <Currency number={values.project_forecast.expected_total_profit} precision={0} />
                                </Link>
                              </span>
                            </Tooltip>
                          </Grid>
                          {/* <pre>{JSON.stringify(values.project_forecast, null, 2)}</pre> */}
                        </Grid>
                      </LabeledGroup>
                      <Box mb={1} />
                    </>
                  )}

                  {meetingProject.notes ? (
                    <Box lineHeight={1}>
                      <Link underline="none" onClick={() => onEditNotes(values)} style={{cursor: "pointer"}}>
                        <small>
                          <FontAwesomeIcon icon={faNoteSticky} /> {nl2br(meetingProject.notes)}
                        </small>
                      </Link>
                    </Box>
                  ) : (
                    <Box lineHeight={1}>
                      <Link underline="none" onClick={() => onEditNotes(values)} style={{cursor: "pointer"}}>
                        <small>
                          <FontAwesomeIcon icon={faNoteSticky} /> Add Notes
                        </small>
                      </Link>
                    </Box>
                  )}
                </PaperItem.Body>
                <PaperItem.Right minWidth={60}></PaperItem.Right>
                <PaperItem.RightHover>
                  <PaperItem.RightHover.IconButton
                    icon={PollIcon}
                    title="Forecast"
                    disabled={isReadOnly}
                    onClick={() => onEditForecast(meetingProject)}
                  />
                  <PaperItem.RightHover.IconButton
                    icon={DeleteIcon}
                    title="Delete"
                    disabled={isReadOnly}
                    onClick={() => {
                      setShowDeleteDialog(true);
                    }}
                  />
                  <Box textAlign="right"></Box>
                </PaperItem.RightHover>
              </PaperItem>
            </form>
          );
        }}
      </FinalForm>

      <ConfirmationDialog
        isOpen={showDeleteDialog}
        onApprove={() => {
          onDelete(meetingProject);
        }}
        onDeny={() => {
          setShowDeleteDialog(false);
        }}
      >
        You want to delete this project from this meeting?{" "}
        {meetingProject.forecast && "This will also unlink the associated forecast."}
      </ConfirmationDialog>
    </>
  );
};

export const MeetingProjectCreateToolbarForm = (props: {
  onSubmit: (values: any, form: any) => void;
  selectedProjectIds: number[];

  // All other props
  [rest: string]: any;
}) => {
  const {onSubmit, selectedProjectIds, ...rest} = props;

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values} = props;
        return (
          <>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <PaperPanel.Toolbar p={1}>
                  <Box display="flex">
                    <Box flexGrow={1}>
                      <ProjectAutoselectMui
                        label="Add Project to Meeting"
                        name="project"
                        getOptionDisabled={(option) => selectedProjectIds.includes(option.id)}
                      />
                    </Box>
                    <Box ml={2}>
                      <CreateButton type="submit" variant="outline" disabled={pristine || submitting}>
                        Add Project
                      </CreateButton>
                    </Box>
                  </Box>
                </PaperPanel.Toolbar>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const MeetingProjectNotesDialogForm = (props: {
  initialValues: any;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, userContact, onSubmit, isOpen, handleClose, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Update Notes</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui name="notes" label="Notes" multiline minRows={2} autoFocus />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const MeetingProjectForecastDialogForm = (props: {
  projectId: number;
  initialValues: any;
  onSubmit: (values: any) => void;
  onCreateNewForecast: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, onCreateNewForecast, isOpen, handleClose, ...rest} = props;

  // const {query: forecastsQuery} = useSentinelListAPI(`projects/${projectId}/forecasts/link/`, {
  //   initialData: [],
  //   enabled: Boolean(projectId),
  //   // https://react-query.tanstack.com/guides/paginated-queries
  //   keepPreviousData: false,
  // });
  // let forecasts = forecastsQuery.data;
  // Add the current forecast to the top of the list if it is not in there
  // if (props.initialValues?.forecast) {
  //   if (!forecasts.find((item) => item.id === props.initialValues?.forecast?.id)) {
  //     forecasts.unshift(props.initialValues?.forecast);
  //   }
  // }

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Update Forecast</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ApiAutoselectMui
                          autoFocus
                          label="Forecast"
                          name="project_forecast"
                          size="small"
                          minCharacters={0}
                          baseURL={`projects/${projectId}/forecasts/link/`}
                        />
                        {/* <pre>{JSON.stringify(forecasts, null, 2)}</pre> */}
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.Button
                    label="Create New Forecast"
                    onClick={() => {
                      onCreateNewForecast(values);
                    }}
                  />
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
