import {faFileExcel, faFileInvoice} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Link, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import {Helmet} from "react-helmet";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";

const OverdueBillings = (props) => {
  const {...rest} = props;
  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      overdueBillings: [],
    },
  });
  const pageData = pageDataQuery.data;
  const overdueBillings = pageData.overdueBillings;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Data..." />;
  }

  return (
    <>
      <Helmet title={`Overdue Billings`} />
      <Breadcrumbs>
        <Link href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </Link>
        <Typography color="textSecondary">Accounting Tools</Typography>
        <Typography color="textPrimary">Overdue Billings</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faFileInvoice} /> Overdue Billings{" "}
            {pageDataQuery.isFetchedAfterMount ? `(${accounting.formatNumber(overdueBillings.length)})` : null}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={(event) => {
                  saveExcel(
                    overdueBillings.map((item) => {
                      return {
                        Project: `${item.timberline_job}  - ${item.name}`,
                        "Project Manager": item.project_manager,
                        Value: item.current_budget,
                        "JTD Cost": item.jtd_cost,
                        "JTD Billed": item.jtd_work_billed,
                        "Last Billed": item.last_billed_date,
                      };
                    }),
                    `Overdue Billings`,
                    [{wch: 80}, {wch: 40}, {wch: 20}, {wch: 20}, {wch: 20}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["last_billed_date", "current_budget", "jtd_cost", "jtd_work_billed"]}
            columns={[
              {
                Header: "Project",
                id: "project",
                accessor: (value) => {
                  return `${value.timberline_job}  - ${value.name}`;
                },
              },
              {
                Header: "Project Manager",
                accessor: "project_manager",
              },
              {
                Header: "Value",
                accessor: "current_budget",
                Cell: ({value, row}) => <Currency number={value} />,
              },
              {
                Header: "JTD Cost",
                accessor: "jtd_cost",
                Cell: ({value, row}) => <Currency number={value} />,
              },
              {
                Header: "JTD Billed",
                accessor: "jtd_work_billed",
                Cell: ({value, row}) => <Currency number={value} />,
              },
              {
                Header: "Last Billed",
                accessor: "last_billed_date",
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "project",
                  desc: false,
                },
              ],
            }}
            data={overdueBillings}
          />
          {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>

      <Box mt={2} />
      <LegacyUILink href="/accounting-tools/overdue-billings/" />
    </>
  );
};

export default OverdueBillings;
