import {faExternalLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link} from "@material-ui/core";
import moment from "moment";
import {Radios} from "mui-rff";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {Metadata} from "../../../../js/components/StyledComponents";
import {
  ApiAutocompleteMui,
  DateTimePickerMui,
  SingleAutocompleteMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {LabelValue} from "../../../components/LabelValue";
import {SuperuserInfoBox} from "../../../components/SuperuserInfo";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";

export const TwilioContactDialogForm = (props: {
  initialValues: any;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, userContact, onSubmit, isOpen, handleClose, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Update Contact</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextFieldMui name="name" label="Name" required autoFocus />
                      </Grid>
                      <Grid item xs={6}>
                        <Radios
                          radioGroupProps={{row: true}}
                          label="Language"
                          name="language"
                          required
                          data={[
                            {label: "English", value: "EN"},
                            {label: "Spanish", value: "SP"},
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {/* <TextFieldMui label="Company" name="company" required /> */}
                        <ApiAutocompleteMui
                          label="Company"
                          name="company"
                          autoComplete="off"
                          valueProp="name"
                          required
                          baseURL="companies/search/"
                        />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ManualCheckInDialogCreateForm = (props: {
  initialValues: any;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  projectId: number;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, userContact, onSubmit, isOpen, handleClose, ...rest} = props;
  props.initialValues.checkin_timestamp = moment();
  const {query: phoneAutocompleteQuery} = useSentinelListAPI(
    `projects/${projectId}/superintendents/manual-wellness-checks/phone/autocomplete/`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      // keepPreviousData: true,
    }
  );
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Create Check In</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <SingleAutocompleteMui
                        name="phone"
                        label="Phone"
                        valueProp="phone"
                        labelProp="phone"
                        autoFocus
                        type="tel"
                        options={phoneAutocompleteQuery.data}
                        required
                        renderOption={(option: any) => (
                          <>
                            <div>{option.phone}</div>
                            <div>
                              <Metadata>{`${option.name} - ${option.company}`}</Metadata>
                            </div>
                          </>
                        )}
                        onChange={(event, value, reason) => {
                          if (reason === "clear") return;
                          form.batch(() => {
                            form.change("name", value.name);
                            form.change("company", value.company);
                            form.change("address", value.address);
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui name="name" label="Name" required />
                    </Grid>
                    <Grid item xs={12}>
                      <ApiAutocompleteMui
                        label="Company"
                        name="company"
                        valueProp="name"
                        required
                        baseURL="companies/search/"
                        qsParams={{
                          page_size: 10,
                        }}
                        renderOption={(option: any) => (
                          <>
                            <div>{option.name}</div>
                            <div>
                              <Metadata>{`${option.lookup_code}`}</Metadata>
                            </div>
                          </>
                        )}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerMui label="Check In" name="checkin_timestamp" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerMui label="Check Out" name="checkout_timestamp" />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="address" label="Address" multiline minRows={2} />
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui
                        name="notes"
                        label="Notes"
                        // height={220}
                      />
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(phoneAutocompleteQuery.data, null, 2)}</pre> */}
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ManualCheckInDialogUpdateForm = (props: {
  initialValues: any;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  projectId: number;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {projectId, userContact, onSubmit, isOpen, handleClose, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Update Check In</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={0}>
                    <Grid item xs={12} sm={6}>
                      <LabelValue label="Name" value={values.name} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelValue label="Compliance Date" value={values.compliance_date} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelValue label="Company" value={values.company} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <LabelValue label="Address" value={values.address} />
                    </Grid>
                  </Grid>
                  <Box mb={2} />
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerMui label="Check In" name="checkin_timestamp" required />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <DateTimePickerMui label="Check Out" name="checkout_timestamp" required />
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="address" label="Address" multiline minRows={2} />
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui
                        name="notes"
                        label="Notes"
                        // height={220}
                      />
                    </Grid>
                  </Grid>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  <SuperuserInfoBox mt={2}>
                    <Link href={`/admin/superintendents/manualwellnesscheck/${values.id}/change/`} target="_blank">
                      <FontAwesomeIcon icon={faExternalLink} /> Admin
                    </Link>
                  </SuperuserInfoBox>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
