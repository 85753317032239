import {faDollar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PaperPanel from "../../../components/PaperPanel";
import {normalizeCostCode} from "../../../utils/string";
import JobCostTransactionTable from "../ProjectBudgetCostDetail/JobCostTransactionTable";
import {ProjectForecastCostCodeChart} from "../ProjectForecast/ProjectForecastCharts";

const JobCostTransactions = (props: {
  projectId: number;
  budgetCode: string;
  transactions: any[];
  isFetching: boolean;
}) => {
  const {projectId, budgetCode, transactions, isFetching} = props;
  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faDollar} /> Job Cost Transactions
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                href={`/v2/projects/${projectId}/budget/cost-detail/?cost_code=${normalizeCostCode(budgetCode)}`}
              >
                Cost Detail
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            {/* <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={isFetching}
                onClick={(event) => {
                  saveExcel(
                    pcoItems.map((pcoItem) => {
                      return {
                        PCO: pcoItem.pco_display,
                        PCOItem: pcoItem.display,
                        Status: pcoItem.status_display,
                        COR: pcoItem?.cor_number_display,
                        SCO: pcoItem?.scco?.number_display,
                        OCO: pcoItem?.pcco?.number_display,
                        ROM: {v: pcoItem.budget_rom, t: "n", z: "$#,##0.00"},
                        Proposed: {v: pcoItem.budget_proposed_amount, t: "n", z: "$#,##0.00"},
                        Approved: {v: pcoItem.budget_approved_amount, t: "n", z: "$#,##0.00"},
                        "Budget Cost": {v: pcoItem.budget_cost, t: "n", z: "$#,##0.00"},
                        Cost: {v: pcoItem.cost, t: "n", z: "$#,##0.00"},
                      };
                    }),
                    `${project.display} - ${budget.display} - Change Orders`,
                    [
                      {wch: 60},
                      {wch: 60},
                      {wch: 15},
                      {wch: 10},
                      {wch: 10},
                      {wch: 10},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                    ]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action> */}
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <JobCostTransactionTable transactions={transactions} />
          {budgetCode && budgetCode.startsWith("013") && (
            <ProjectForecastCostCodeChart projectId={projectId} costCode={normalizeCostCode(budgetCode)} isReadOnly />
          )}
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default JobCostTransactions;
