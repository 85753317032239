import {faCcAmex} from "@fortawesome/free-brands-svg-icons";
import {faExternalLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import classnames from "classnames";
import {useSnackbar} from "notistack";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {Currency} from "../../components/Accounting";
import {ConfirmationDialog} from "../../components/Dialogs";
import {FileFieldMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import {PageHeader} from "../../components/PageHeader";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";

export const CreditCardUploadDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  action?: string;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, action, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Upload Credit Card File</DialogTitle>
              <DialogContent>
                <PaperPanel.Body>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FileFieldMui label="File" name="file" required />
                    </Grid>
                  </Grid>
                  {action === "amex_centurion_upload" && (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Date</TableCell>
                          <TableCell>Receipt</TableCell>
                          <TableCell>Description</TableCell>
                          <TableCell>Card Member</TableCell>
                          <TableCell>Account #</TableCell>
                          <TableCell>Amount</TableCell>
                          <TableCell>Category</TableCell>
                          <TableCell>Type</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>M/D/YY</TableCell>
                          <TableCell></TableCell>
                          <TableCell>TRANSACTION DESCRIPTION</TableCell>
                          <TableCell>BILL BOGUS</TableCell>
                          <TableCell>-12345</TableCell>
                          <TableCell>100.01</TableCell>
                          <TableCell></TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                  {action === "amex_corporate_upload" && (
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Last Name</TableCell>
                          <TableCell>First Name</TableCell>
                          <TableCell>Full Name</TableCell>
                          <TableCell>Card Member</TableCell>
                          <TableCell>Charge Date</TableCell>
                          <TableCell>Transaction Description</TableCell>
                          <TableCell>Charge Amount</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell>BOGUS</TableCell>
                          <TableCell>BILL</TableCell>
                          <TableCell>BILL BOGUS</TableCell>
                          <TableCell>1234-123456-12345</TableCell>
                          <TableCell>M/D/YY</TableCell>
                          <TableCell>TRANSACTION DESCRIPTION</TableCell>
                          <TableCell>100.01</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  )}
                </PaperPanel.Body>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label="Upload" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

const CreditCardUpload = (props) => {
  const [action, setAction] = React.useState("");
  const [showUploadForm, setShowUploadForm] = React.useState(false);
  const [markPendingConfirmationIsOpen, setMarkPendingConfirmationIsOpen] = React.useState(false);
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();
  const novoClasses = makeNovoClasses();
  const batchQuery = useFetchCurrentPage({
    initialData: {
      batches: [],
    },
  });
  const postCurrentPage = usePostCurrentPage(
    {},
    {
      baseURL: "",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );

  return (
    <>
      <Helmet title="Credit Card Upload" />

      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Credit Card Upload</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={batchQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faCcAmex} /> Credit Card Batches
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button onClick={() => setMarkPendingConfirmationIsOpen(true)}>
                Mark New as Pending
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.DropdownMenu title="Upload" startIcon={<CloudUploadIcon />}>
                {(setOpen) => (
                  <>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        setAction("amex_centurion_upload");
                        setShowUploadForm(true);
                        setOpen(false);
                        // popupState.close();
                      }}
                    >
                      Amex Centurion
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        setAction("amex_corporate_upload");
                        setShowUploadForm(true);
                        setOpen(false);
                        // popupState.close();
                      }}
                    >
                      Amex Corporate
                    </PaperPanel.Header.Menu.MenuItem>
                  </>
                )}
              </PaperPanel.Header.DropdownMenu>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => batchQuery.refetch()}
                isFetching={batchQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["amount"]}
            columns={[
              {
                Header: "Id",
                accessor: "id",
                Cell: ({value, row}) => (
                  <Link
                    href={`/admin/accounting_tools/creditcardtransactionbatch/${value}/change/`}
                    target="_blank"
                    underline="always"
                  >
                    {value} <FontAwesomeIcon icon={faExternalLink} />
                  </Link>
                ),
              },
              {
                Header: "Billing Date",
                accessor: "billing_date",
              },
              {
                Header: "Amount",
                accessor: "amount",
                Cell: ({value}) => <Currency number={value} precision={2} />,
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "id",
                  desc: true,
                },
              ],
            }}
            data={batchQuery.data.batches}
          />

          {/* <pre>{JSON.stringify(batchQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
      <CreditCardUploadDialogForm
        isOpen={showUploadForm}
        isNew={false}
        action={action}
        handleClose={() => {
          setShowUploadForm(false);
        }}
        onSubmit={(values) => {
          values = {action: action, ...values};
          let formData = new FormData();
          Object.entries(values).map(([key, value]) => {
            /* @ts-ignore */
            formData.append(key, value);
          });
          setShowUploadForm(false);
          setTimeout(() => blockUI.blockUI("Uploading..."));
          postCurrentPage
            .mutateAsync(formData)
            .then((response) => {
              batchQuery.refetch();
              blockUI.unblockUI();
              enqueueSnackbar(response.message, {
                variant: "success",
              });
            })
            .catch(() => blockUI.unblockUI());
          // updateBatch.mutateAsync(values);
        }}
      />
      <ConfirmationDialog
        isOpen={markPendingConfirmationIsOpen}
        onApprove={() => {
          setMarkPendingConfirmationIsOpen(false);
          blockUI.blockUI();
          let formData = new FormData();
          formData.append("action", "mark_new_credit_card_statements_pending");
          postCurrentPage
            .mutateAsync(formData)
            .then((response) => {
              batchQuery.refetch();
              blockUI.unblockUI();
              enqueueSnackbar(response.message, {
                variant: "success",
              });
            })
            .catch(() => blockUI.unblockUI());
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setMarkPendingConfirmationIsOpen(false);
        }}
      >
        You want to mark all new statements as pending and send notification emails?
      </ConfirmationDialog>
    </>
  );
};

export default CreditCardUpload;
