import LaunchIcon from "@material-ui/icons/Launch";

import {faFiles} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Typography} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {Helmet} from "react-helmet";
import {BoxCollaborationButton, BoxIFrame} from "../../../components/Box";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";

const ProjectJobsiteDocuments = (props) => {
  const {project, user, ...rest} = props;
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Jobsite Documents..." />;
  }

  if (!pageData.jobsite_documents_folder_id) {
    return (
      <>
        <Helmet title={`${project.display} - Jobsite Documents`} />
        <ProjectBreadcrumbs project={project}>
          <Typography color="textPrimary">Jobsite Documents</Typography>
        </ProjectBreadcrumbs>
        <Box mb={2} />
        <Alert severity="error">
          <AlertTitle>No Jobsite Documents Box Folder Found For This Project</AlertTitle>The jobsite documents folder is
          automatically created when documents are uploaded during check in.
        </Alert>
      </>
    );
  }

  return (
    <>
      <Helmet title={`${project.display} - Jobsite Documents`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Jobsite Documents</Typography>
      </ProjectBreadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faFiles} /> Jobsite Documents
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Actions>
            {pageData.jobsite_documents_folder_id && (
              <PaperPanel.Header.Action>
                <BoxCollaborationButton boxFolderId={pageData.jobsite_documents_folder_id} user={user} />
              </PaperPanel.Header.Action>
            )}
            {pageData.jobsite_documents_folder_shared_link && (
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button
                  href={pageData.jobsite_documents_folder_shared_link}
                  target="_blank"
                  endIcon={<LaunchIcon />}
                  // variant="text"
                >
                  Box Site
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <BoxIFrame sharedFolderId={pageData.jobsite_documents_folder_shared_id} height={800} />
        </PaperPanel.Body>
      </PaperPanel>
      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default ProjectJobsiteDocuments;
