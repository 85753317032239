import { faCcAmex, faSlack, faTelegramPlane } from "@fortawesome/free-brands-svg-icons";
import {
  faAddressBook,
  faBell,
  faBook,
  faBuilding,
  faCalendar,
  faCartShopping,
  faChartLine,
  faCheckToSlot,
  faClipboardList,
  faCloud,
  faClouds,
  faCodeMerge,
  faCogs,
  faComments,
  faDraftingCompass,
  faExchange,
  faFileChartPie,
  faFileInvoice,
  faFlagCheckered,
  faGavel,
  faHardHat,
  faHome,
  faIdCard,
  faLink,
  faMicrochipAi,
  faMoneyBill,
  faPaintBrush,
  faPalette,
  faPaperclip,
  faPenFancy,
  faPeopleGroup,
  faPiggyBank,
  faShoppingCart,
  faSink,
  faSpiderWeb,
  faTachometer,
  faTasks,
  faTrafficCone,
  faUmbrella,
  faUniversity,
  faUser,
  faUsers,
  faWrench,
} from "@fortawesome/pro-solid-svg-icons";
import Dashboard from "../pages/Dashboard";
import DjangoTest from "../pages/DjangoTest";
import Exception from "../pages/Exception";
import Props from "../pages/Props";
import AgingByPM from "../pages/accounting-tools/AgingByPM";
import ClientInvoiceLookup from "../pages/accounting-tools/ClientInvoiceLookup";
import SubcontractorLienReleaseList from "../pages/accounting-tools/SubcontractorLienReleases/SubcontractorLienReleaseList";
import TimecardsForEmployee from "../pages/accounting-tools/TimecardsForEmployee";
import HomeDepotUploadDetail from "../pages/accounting-tools/direct-costs/HomeDepot/HomeDepotBatchDetail";
import HomeDepotUploadsList from "../pages/accounting-tools/direct-costs/HomeDepot/HomeDepotBatchList";
import AuditAdmin from "../pages/admin-tools/AuditAdmin";
import BoxAdmin from "../pages/admin-tools/BoxAdmin";
import CheckInDashboard from "../pages/admin-tools/CheckInDashboard";
import CreditCardUpload from "../pages/admin-tools/CreditCardUpload";
import Hooks from "../pages/admin-tools/Hooks";
import MiscAdmin from "../pages/admin-tools/MiscAdmin";
import Theme from "../pages/admin-tools/Theme";
import BonusAccountDetail from "../pages/bonuses/BonusAccountDetail";
import BonusAccountDistributionRequestDetail from "../pages/bonuses/BonusAccountDistributionRequestDetail";
import BonusAccountDistributionRequestList from "../pages/bonuses/BonusAccountDistributionRequestList";
import BonusAccountTransactionList from "../pages/bonuses/BonusAccountTransactionList";
import BonusAccountsList from "../pages/bonuses/BonusAccountsList";
import BoxFiles from "../pages/box/BoxFiles";
import CompanyCreate from "../pages/companies/CompanyCreate";
import CompanyDetail from "../pages/companies/CompanyDetail";
import CompanyUpdate from "../pages/companies/CompanyUpdate";
import ContactCreate from "../pages/contacts/ContactCreate";
import ContactDetail from "../pages/contacts/ContactDetail";
import ClientProfitability from "../pages/executive-tools/ClientProfitability";
import EmployeeRoster from "../pages/hr-tools/EmployeeRoster";
import DesignTemplates from "../pages/misc-tools/DesignTemplates";
import MinorityOwnedSubcontractorSpend from "../pages/misc-tools/MinorityOwnedSubcontractorSpend";
import StatusSink from "../pages/misc-tools/StatusSink";
import User from "../pages/misc-tools/User";
import SubcontractorAwardVendor from "../pages/preconstruction/SubcontractorAwardVendor";
import SubcontractorAwards from "../pages/preconstruction/SubcontractorAwards";
import {
  PCCOCreate,
  PCCODetail,
  PCODetail,
  ProjectCORs,
  ProjectPCCOs,
  ProjectPCOs,
  ProjectSCOs,
  SCODetail,
} from "../pages/projects/ChangeOrders/";
import { ProjectCloseout } from "../pages/projects/Closeout";
import {
  PrimeContractDetail,
  ProjectContracts,
  SubcontractDetail,
  SubcontractDetailRestricted,
} from "../pages/projects/Contracts";
import LetterOfIntentDetail from "../pages/projects/Contracts/LetterOfIntentDetail";
import ProjectContractsRestricted from "../pages/projects/Contracts/ProjectContractsRestricted";
import ProjectLettersOfIntent from "../pages/projects/Contracts/ProjectLettersOfIntent";
// import SubcontractDetail from "../pages/projects/Contracts/SubcontractDetail";
import BonusAccountCloseoutRequestDetail from "../pages/bonuses/BonusAccountCloseoutRequestDetail";
import BonusAccountCloseoutRequestList from "../pages/bonuses/BonusAccountCloseoutRequestList";
import MBESubcontractors from "../pages/companies/MBESubcontractors";
import DocuSignEnvelopeDetail from "../pages/docusign/DocuSignEnvelopeDetail";
import DocuSignEnvelopes from "../pages/docusign/DocuSignEnvelopes";

import DocusignSendReminders from "../pages/docusign/DocusignSendReminders";

import RequestRemoteAccessAdmin from "../pages/RequestRemoteAccess/RemoteAccessAdmin";
import RequestRemoteAccess from "../pages/RequestRemoteAccess/RequestRemoteAccess";
import { SafetyDashboard } from "../pages/SafetyDashboard";
import { PSRDashboard } from "../pages/SafetyDashboard/PSRDashboard";
import Sink from "../pages/Sink";
import AccountingMasterProjectList from "../pages/accounting-tools/AccountingMasterProjectList/AccountingMasterProjectList";
import { CanCloseout } from "../pages/accounting-tools/CanCloseout";
import { JobStart } from "../pages/accounting-tools/JobStart";
import MasterSubcontractAgreementDetail from "../pages/accounting-tools/MasterSubcontractAgreements/MasterSubcontractAgreementDetail";
import MasterSubcontractAgreementList from "../pages/accounting-tools/MasterSubcontractAgreements/MasterSubcontractAgreementList";
import { OverdueBillings } from "../pages/accounting-tools/OverdueBillings";
import ProjectSubcontractorLienReleaseList from "../pages/accounting-tools/SubcontractorLienReleases/ProjectSubcontractorLienReleaseList";
import SubcontractorLienReleaseDetail from "../pages/accounting-tools/SubcontractorLienReleases/SubcontractorLienReleaseDetail";
import { UnlinkedObjects } from "../pages/accounting-tools/UnlinkedObjects";
import AIChat from "../pages/ai/AIChat";
import { AIDocumentList } from "../pages/ai/AIDocumentList";
import SubcontractorsByTrade from "../pages/companies/SubcontractorsByTrade";
import SubcontractorRevenue from "../pages/executive-reports/SubcontractorRevenue";
import ProjectManagerMeetingDetail from "../pages/project-managers/ProjectManagerMeetings/ProjectManagerMeetingDetail";
import ProjectManagerMeetingList from "../pages/project-managers/ProjectManagerMeetings/ProjectManagerMeetingList";
import {
  ChangeOrderWizard,
  NetZeroChangeOrder,
  PCOCreate,
  PCOtoCOR,
  PrimeContractSelect,
  SCCOCreate,
  SCCOFromApprovedItems,
  SubContractSelect,
} from "../pages/projects/ChangeOrderWizard";
import ProjectPCOItems from "../pages/projects/ChangeOrders/ProjectPCOItems";
import CreateProjectWizard from "../pages/projects/CreateProjectWizard";
import { ProjectDocuSignEnvelopeList } from "../pages/projects/DocuSign";
import { ProjectDrawings } from "../pages/projects/Drawings";
import ProjectLinks from "../pages/projects/Links/ProjectLinks";
import MasterProjectList from "../pages/projects/MasterProjectList/MasterProjectList";
import { AgendaDetail, MinutesDetail, ProjectMeetings } from "../pages/projects/Meetings/";
// import ProcurementLog from "../pages/projects/ProcurementLog/ProcurementLog";
import AccessibleProjects from "../pages/AccessibleProjects";
import { LaborContacts, LaborSchedule, LaborScheduleCalendar } from "../pages/Labor";
import DocuSignObjectEnvelopeList from "../pages/docusign/DocuSignObjectEnvelopeList";
import ProjectManagerBillingsList from "../pages/project-managers/ProjectManagerBillings/ProjectManagerBillingsList";
import ProcurementLog from "../pages/projects/ProcurementLog/ProcurementLog";
import ProjectAdminTools from "../pages/projects/ProjectAdminTools";
import ProjectBonusCloseoutRequests from "../pages/projects/ProjectBonuses/ProjectBonusCloseoutRequests";
import ProjectBudgetCodeDetail from "../pages/projects/ProjectBudgetCodeDetail";
import ProjectBudgetCostDetail from "../pages/projects/ProjectBudgetCostDetail";
import ProjectBudgetCurrent from "../pages/projects/ProjectBudgetCurrent";
import { ProjectBudgetOriginal } from "../pages/projects/ProjectBudgetOriginal";
import { ProjectBudgetVsJTD } from "../pages/projects/ProjectBudgetVsJTD";
import ProjectDailyCheckIns, { WellnessCheckinDetail } from "../pages/projects/ProjectDailyCheckIns";
import ProjectManualDailyCheckIns from "../pages/projects/ProjectDailyCheckIns/ProjectManualDailyCheckIns";
import ProjectWorkLogsBySubcontractor from "../pages/projects/ProjectDailyCheckIns/ProjectWorkLogsBySubcontractor";
import WorkLogBySubcontractor from "../pages/projects/ProjectDailyCheckIns/WorkLogBySubcontractor";
import { ProjectDailyReportsList } from "../pages/projects/ProjectDailyReports";
import ProjectDailyReportDetail from "../pages/projects/ProjectDailyReports/ProjectDailyReportDetail";
import ProjectDashboard from "../pages/projects/ProjectDashboard";
import ProjectDeleteObject from "../pages/projects/ProjectDeleteObject";
import ProjectDirectory from "../pages/projects/ProjectDirectory/ProjectDirectory";
import ProjectForecastDetail from "../pages/projects/ProjectForecast/ProjectForecastDetail";
import ProjectForecastList from "../pages/projects/ProjectForecast/ProjectForecastList";
import { ProjectGroups } from "../pages/projects/ProjectGroups";
import { ProjectInsurance } from "../pages/projects/ProjectInsurance";
import ProjectJobStart from "../pages/projects/ProjectJobStart";
import { ProjectJobsiteDocuments } from "../pages/projects/ProjectJobsiteDocuments";
import { ProjectPermissions } from "../pages/projects/ProjectPermissions";
import { ProjectPretaskPlans } from "../pages/projects/ProjectPretaskPlans";
import ProjectReports from "../pages/projects/ProjectReports/ProjectReports";
import { ProjectReviewDetail, ProjectReviews } from "../pages/projects/ProjectReviews/";
import {
  ProjectSafetyReviewDetail,
  ProjectSafetyReviews,
  SafetyReviewQuestionDetail,
  SafetyReviewQuestions,
} from "../pages/projects/ProjectSafetyReview";
import ProjectUpdate from "../pages/projects/ProjectUpdate";
import ProjectUserAuditView from "../pages/projects/ProjectUserAuditView";
import ProjectWeather from "../pages/projects/ProjectWeather/ProjectWeather";
import {
  ProjectPurchaseOrders,
  PurchaseOrderAmendmentDetail,
  PurchaseOrderDetail,
} from "../pages/projects/PurchaseOrders";
import ProjectPurchaseOrdersRestricted from "../pages/projects/PurchaseOrders/ProjectPurchaseOrdersRestricted";
import { ProjectRFIs, RFIDetail } from "../pages/projects/RFIs";
import SafetyAnnouncements from "../pages/projects/SafetyAnnouncements/SafetyAnnouncements";
import { ProjectSafetyFormDetail, ProjectSafetyForms } from "../pages/projects/SafetyForms";
import SafetyOrientation from "../pages/projects/SafetyOrientation/SafetyOrientation";
import { SlackProject } from "../pages/projects/Slack";
import ProjectSpecSections from "../pages/projects/SpecSections/ProjectSpecSections";
import {
  ProjectSubmittalItems,
  ProjectSubmittalPackages,
  SubmittalItemDetail,
  SubmittalPackageDetail,
  SubmittalTransmittalDetail,
} from "../pages/projects/Submittals";
import { ProjectTransmittals, TransmittalDetail } from "../pages/projects/Transmittals/";

import HighwireAdminDashboard from "../pages/SafetyDashboard/HighwireAdminDashboard";
import HighwireContractors from "../pages/companies/HighwireContractors";
import Timecards from "../pages/executive-tools/Timecards";
import EmployeeReviewDetail from "../pages/hr-tools/EmployeeReviewDetail";
import EmployeeReviewHRDashboard from "../pages/hr-tools/EmployeeReviewHRDashboard";
import EmployeeReviewManagerDashboard from "../pages/hr-tools/EmployeeReviewManagerDashboard";
import { DailySafetyInspectionDetail, ProjectDailySafetyInspections } from "../pages/projects/DailySafetyInspections";
import ProjectHighwireContractors from "../pages/projects/ProjectHighwireContractors/ProjectHighwireContractors";
import ProjectAIChat from "../pages/projects/ai/ProjectAIChat";
import ProjectAIDocumentAnalysisDetail from "../pages/projects/ai/ProjectAIDocumentAnalysisDetail";
import ProjectAIDocumentAnalysisList from "../pages/projects/ai/ProjectAIDocumentAnalysisList";
import ProjectAIDocumentList from "../pages/projects/ai/ProjectAIDocumentList";

const PATH_PREFIX = "/v2/";
const CURRENT_HR_YEAR = "2024";

const homeRoutes = {
  id: "Home",
  path: "/",
  icon: faHome,
  component: null,
  children: null,
};

const aiRoutes = {
  id: "AI",
  path: `${PATH_PREFIX}ai/documents/`,
  icon: faMicrochipAi,
  children: null,
  component: AIDocumentList,
};

const dashboardRoutes = {
  id: "Dashboard",
  path: `${PATH_PREFIX}dashboard/`,
  icon: faTachometer,
  component: Dashboard,
  children: null,
};

const companyRoutes = {
  id: "Companies",
  icon: faBuilding,
  children: [
    {
      id: "Create",
      path: `${PATH_PREFIX}companies/create/`,
      component: CompanyCreate,
    },
    {
      id: "Highwire Contractors",
      path: `${PATH_PREFIX}companies/highwire/contractors/`,
      component: HighwireContractors,
    },
    {
      id: "Subcontractors By Trade",
      path: `${PATH_PREFIX}companies/subcontractors-by-trade/`,
      component: SubcontractorsByTrade,
    },
    {
      id: "MSA Log",
      path: `/companies/msa-list/`,
    },

    {
      path: `${PATH_PREFIX}companies/:companyId/`,
      component: CompanyDetail,
    },
    {
      path: `${PATH_PREFIX}companies/:companyId/update/`,
      component: CompanyUpdate,
    },
  ],
  component: null,
};
const contactRoutes = {
  id: "Contacts",
  icon: faUser,
  children: [
    {
      id: "Create",
      path: `/contacts/create/`,
    },
    {
      path: `${PATH_PREFIX}contacts/create/`,
      component: ContactCreate,
    },
    {
      path: `${PATH_PREFIX}contacts/:contactId/`,
      component: ContactDetail,
    },
  ],
  component: null,
};
const projectRoutes = {
  id: "Project",
  icon: faTasks,
  children: [
    {
      id: "Create",
      // path: `/projects/create_project_wizard/`,
      path: `${PATH_PREFIX}projects/create-project-wizard/`,
      component: CreateProjectWizard,
    },
    {
      id: "Master Project List",
      path: `${PATH_PREFIX}projects/master-project-list/`,
      component: MasterProjectList,
    },
    {
      id: "Project Map",
      path: `/projects/map/`,
    },
  ],
  component: null,
};
const docuSignRoutes = {
  id: "DocuSign",
  path: `${PATH_PREFIX}docusign/envelopes/`,
  component: DocuSignEnvelopes,
  icon: faPenFancy,
  children: null,
};
// const pmReportRoutes = {
//   id: "PM Report",
//   path: `/dashboard/pm-report/~/`,
//   icon: faFileInvoice,
//   children: null,
// };
const creditCardRoutes = {
  id: "Statements",
  path: `/accounting-tools/credit-cards/statements/`,
  icon: faCcAmex,
  children: null,
};

const safetyRoutes = {
  id: "Safety",

  icon: faTrafficCone,
  children: [
    {
      id: "Check In Dashboard",
      path: `${PATH_PREFIX}safety/check-in-dashboard/`,
      component: CheckInDashboard,
    },
    {
      id: "Highwire Admin Dashboard",
      path: `${PATH_PREFIX}safety/highwire/admin-dashboard/`,
      component: HighwireAdminDashboard,
    },
    {id: "PSR Dashboard", path: `${PATH_PREFIX}safety/psr-dashboard/`, component: PSRDashboard},
  ],
  component: null,
};
const scheduleRoutes = {
  id: "Schedule",
  // path: `/superintendents/schedule-uploads/`,
  icon: faCalendar,
  children: [
    {
      id: "Labor Contacts",
      path: `${PATH_PREFIX}labor/contacts/`,
      component: LaborContacts,
    },
    {
      id: "Labor Schedule",
      path: `${PATH_PREFIX}labor/schedule/`,
      component: LaborSchedule,
    },
    {
      // id: "Labor Schedule Calendar",
      path: `${PATH_PREFIX}labor/schedule/calendar/`,
      component: LaborScheduleCalendar,
    },
    {id: "Schedule Uploads", path: `/superintendents/schedule-uploads/`},
  ],
};

const miscToolsRoutes = {
  id: "Misc Tools",
  icon: faWrench,
  children: [
    {
      id: "Novo CSI Codes",
      path: `/lookups/csi-codes/novo/`,
    },
    {
      id: "1995 CSI Codes",
      path: `/lookups/csi-codes/1995/`,
    },
    {
      id: "Labor Rates",
      path: `/employees/labor-rates/`,
    },
    {
      id: "Misc Reports",
      path: `/reports2/misc-reports/`,
    },
    {
      id: "Request Remote Access",
      path: `${PATH_PREFIX}request-remote-access/`,
      component: RequestRemoteAccess,
    },
    {
      // id: "Request Remote Access",
      path: `${PATH_PREFIX}request-remote-access/admin/`,
      component: RequestRemoteAccessAdmin,
    },
    {
      id: "Subcontractor Costs",
      path: `/executive-reports/subcontractors/groupings/`,
    },
    {
      id: "Subcontractor Revenue",
      path: `${PATH_PREFIX}executive-reports/subcontractors/revenue/`,
      component: SubcontractorRevenue,
    },
  ],
  component: null,
};
const accountingToolsRoutes = {
  id: "Accounting Tools",
  icon: faMoneyBill,
  children: [
    {
      id: "Master Project List",
      // path: `/accounting-tools/master-project-list/`,
      path: `${PATH_PREFIX}accounting-tools/master-project-list/`,
      component: AccountingMasterProjectList,
    },
    {
      id: "Approve Budgets",
      path: `/budgets/approval-list/`,
    },
    {
      id: "Aging By PM",
      path: `${PATH_PREFIX}accounting-tools/aging-by-pm/`,
      component: AgingByPM,
    },
    {
      id: "Billings",
      path: `/accounting-tools/billings/`,
    },
    {
      id: "Overdue Billings",
      path: `${PATH_PREFIX}accounting-tools/overdue-billings/`,
      component: OverdueBillings,
    },
    {
      id: "Client Invoice Lookup",
      path: `${PATH_PREFIX}accounting-tools/client-invoice-lookup/`,
      component: ClientInvoiceLookup,
    },
    {
      id: "MSA Log",
      path: `${PATH_PREFIX}accounting-tools/msas/`,
      component: MasterSubcontractAgreementList,
    },
    {
      id: "PO Import",
      path: `/accounting-tools/pos/daily-import/`,
    },
    {
      id: "Subcontract Import",
      path: `/accounting-tools/subcontracts/daily-import/`,
    },
    {
      id: "OCO Import",
      path: `/accounting-tools/pccos/daily-import/`,
    },
    {
      id: "SCO Import",
      path: `/accounting-tools/sccos/daily-import/`,
    },
    {
      id: "PO Amendment Import",
      path: `/accounting-tools/po-amendments/daily-import/`,
    },
    {
      id: "Subcontractor Billing",
      path: `/timberline/subcontractor-billed-summary/`,
    },
    {
      id: "IT  Subscriptions",
      path: `/accounting-tools/it-subscriptions/`,
    },
    {
      id: "Timecards",
      path: `/accounting-tools/timecards/`,
    },
    {
      id: "Home Depot Uploads",
      path: `${PATH_PREFIX}accounting-tools/direct-cost-batches/home-depot/`,
      component: HomeDepotUploadsList,
    },
    {
      path: `${PATH_PREFIX}accounting-tools/direct-cost-batches/home-depot/:batchId/`,
      component: HomeDepotUploadDetail,
    },
    {
      id: "Employee Rates",
      path: `/employees/rates/`,
    },
    {
      id: "Credit Cards",
      path: `/accounting-tools/credit-cards/statements/crosstab/`,
    },
    // {
    //   id: "Job Start",
    //   path: `/accounting-tools/job-start/`,
    // },
    {id: "Job Start", path: `${PATH_PREFIX}accounting-tools/job-start/`, component: JobStart},
    {
      id: "Subcontractor Lien Releases",
      path: `${PATH_PREFIX}accounting-tools/subcontractor-lien-releases/`,
      component: SubcontractorLienReleaseList,
    },
    // {
    //   id: "Can Closeout Projects",
    //   path: `/projects/can-closeout/`,
    // },
    {id: "Can Closeout Projects", path: `${PATH_PREFIX}accounting-tools/can-closeout/`, component: CanCloseout},
    // {
    //   id: "Unlinked Objects",
    //   path: ` /accounting-tools/unlinked/`,
    // },
    {id: "Unlinked Objects", path: `${PATH_PREFIX}accounting-tools/unlinked/`, component: UnlinkedObjects},
    {
      id: "SCCOs Not Matching Timberline Cost",
      path: `/accounting-tools/sccos-not-matching-timberline-cost/`,
    },
  ],
  component: null,
};
const executiveToolsRoutes = {
  id: "Executive Tools",
  icon: faUniversity,
  children: [
    {
      id: "Client Profitability",
      path: `${PATH_PREFIX}executive-tools/client-profitability/`,
      component: ClientProfitability,
    },
    {
      id: "Employees",
      path: `/employees/`,
    },
    {
      id: "PM Reports",
      path: `/executive-reports/pm-report/`,
    },
    {
      id: "PM Meetings By Date",
      path: `/executive-reports/pm-meetings/`,
    },
    {
      id: "Sentinel Projects",
      path: `/executive-reports/projects/`,
    },
    {
      id: "Closed Projects",
      path: `/executive-reports/projects/closed/`,
    },
    {
      id: "Subcontractors By Code",
      path: `/executive-reports/subcontractors/by-code/`,
    },
    {
      id: "Timberline Projects",
      path: `/executive-reports/projects/timberline/`,
    },
    {
      id: "Timecards",
      path: `${PATH_PREFIX}executive-tools/timecards/`,
      component: Timecards,
    },
    {
      id: "Project Heatmap",
      path: `/executive-reports/project-heatmap/`,
    },
    {
      id: "Project Comparison",
      path: `/projects/grouping/`,
    },
  ],
  component: null,
};

const diversityRoutes = {
  id: "Diversity",
  icon: faPeopleGroup,
  component: null,
  children: [
    {
      id: "Diverse Subcontractors",
      path: `${PATH_PREFIX}mbe/subcontractors/`,
      component: MBESubcontractors,
    },
    {
      id: "Diverse Subcontractor Spend",
      path: `${PATH_PREFIX}mbe/spend/`,
      component: MinorityOwnedSubcontractorSpend,
    },
  ],
};
const hrToolsAdminRoutes = {
  id: "HR Tools",
  icon: faUsers,
  children: [
    {
      id: "Employee Roster",
      path: `${PATH_PREFIX}employees/roster/`,
      component: EmployeeRoster,
    },
    // {
    //   path: `${PATH_PREFIX}employees/reviews/my-manager/`,
    //   component: EmployeeMyManger,
    // },
    // {
    //   id: "HR Evaluations",
    //   path: `/employees/reviews/hr-dashboard/${CURRENT_HR_YEAR}/`,
    // },
    {
      id: "HR Evaluations",
      path: `${PATH_PREFIX}employees/reviews/hr-dashboard/:year/`,
      overrideLink: `${PATH_PREFIX}employees/reviews/hr-dashboard/${CURRENT_HR_YEAR}/`,
      component: EmployeeReviewHRDashboard,
    },
    {
      id: "Manager Evaluations",
      path: `${PATH_PREFIX}employees/reviews/manager-dashboard/:year/`,
      overrideLink: `${PATH_PREFIX}employees/reviews/manager-dashboard/${CURRENT_HR_YEAR}/`,
      component: EmployeeReviewManagerDashboard,
    },
    // {
    //   id: "Manager Evaluations",
    //   path: `/employees/reviews/manager-dashboard/${CURRENT_HR_YEAR}/`,
    // },
    {
      id: "Self Evaluation",
      path: `${PATH_PREFIX}employees/reviews/:type/:year/`,
      overrideLink: `${PATH_PREFIX}employees/reviews/self/${CURRENT_HR_YEAR}/`,
      component: EmployeeReviewDetail,
    },
  ],
  component: null,
};
const hrToolsRoutes = {
  id: "HR Tools",
  icon: faUsers,
  children: [
    {
      id: "Manager Evaluations",
      path: `${PATH_PREFIX}employees/reviews/manager-dashboard/:year/`,
      overrideLink: `${PATH_PREFIX}employees/reviews/manager-dashboard/${CURRENT_HR_YEAR}/`,
      component: EmployeeReviewManagerDashboard,
    },
    {
      id: "Self Evaluation",
      path: `${PATH_PREFIX}employees/reviews/:type/:year/`,
      overrideLink: `${PATH_PREFIX}employees/reviews/self/${CURRENT_HR_YEAR}/`,
      component: EmployeeReviewDetail,
    },
    // {
    //   id: "Manager Evaluations",
    //   path: `/employees/reviews/manager-dashboard/${CURRENT_HR_YEAR}/`,
    // },
    // {
    //   id: "Self Evaluation",
    //   path: `/employees/reviews/self/${CURRENT_HR_YEAR}/`,
    // },
  ],
  component: null,
};
const preconstructionRoutes = {
  id: "Preconstruction",
  icon: faFileChartPie,
  children: [
    {
      id: "Subcontractor Awards",
      path: `${PATH_PREFIX}preconstruction/subcontractor-awards/`,
      component: SubcontractorAwards,
    },
  ],
  component: null,
};

const projectManagerRoutes = {
  id: "Project Managers",
  icon: faFileChartPie,
  children: [
    {
      id: "Bonus Accounts",
      path: `${PATH_PREFIX}bonuses/accounts/`,
      component: BonusAccountsList,
    },
    {
      id: "Bonus Closeout Requests",
      path: `${PATH_PREFIX}bonuses/closeout-requests/`,
      component: BonusAccountCloseoutRequestList,
    },
    {
      id: "Bonus Distribution Requests",
      path: `${PATH_PREFIX}bonuses/distribution-requests/`,
      component: BonusAccountDistributionRequestList,
    },
    {
      id: "Bonus Transactions",
      path: `${PATH_PREFIX}bonuses/transactions/`,
      component: BonusAccountTransactionList,
    },
    {
      id: "PM Meetings",
      path: `/employees/pm-meetings/~/`,
    },
    {
      path: `${PATH_PREFIX}employees/:employeeId/pm-meetings/`,
      component: ProjectManagerMeetingList,
    },
    {
      path: `${PATH_PREFIX}employees/:employeeId/pm-meetings/:meetingId/`,
      component: ProjectManagerMeetingDetail,
    },
    {id: "PM Report", path: `/dashboard/pm-report/~/`},
  ],
  component: null,
};

const designRoutes = {
  id: "Design",
  header: "Admin Design",
  icon: faPaintBrush,
  children: [
    {
      path: `${PATH_PREFIX}misc-tools/design-templates/`,
      id: "Design Templates",
      icon: faPalette,
      component: DesignTemplates,
    },
    {
      id: "Kitchen Sink",
      path: `${PATH_PREFIX}sink/`,
      icon: faSink,
      component: Sink,
    },
    // {
    //   id: "AI Q&A",
    //   path: `${PATH_PREFIX}projects/:projectId/ai/`,
    //   icon: faBrain,
    //   component: AIPDFList,
    // },
    {
      id: "Status Sink",
      path: `${PATH_PREFIX}sink/status/`,
      icon: faSink,
      component: StatusSink,
    },
    {
      id: "Props",
      path: `${PATH_PREFIX}props/`,
      // icon: faSink,
      component: Props,
      // badge: "5",
    },
    {
      path: `${PATH_PREFIX}misc-tools/user/`,
      id: "User",
      icon: faUser,
      component: User,
    },
  ],
  component: null,
};
const adminToolRoutes = {
  id: "Admin Tools",
  header: "Admin",
  icon: faCogs,
  children: [
    {
      path: `${PATH_PREFIX}admin-tools/audit/:app/:model/:object_id`,
      // id: "Box",
      component: AuditAdmin,
      // icon: faCloud,
    },
    {
      path: `${PATH_PREFIX}admin-tools/box/`,
      id: "Box",
      component: BoxAdmin,
      icon: faCloud,
    },
    {
      path: `/notifications/create/`,
      id: "Create Notifications",
      icon: faBell,
    },
    {
      path: `${PATH_PREFIX}admin-tools/credit-card-upload/`,
      id: "Credit Card Upload",
      component: CreditCardUpload,
      icon: faCcAmex,
    },
    {
      path: `/docusign/account-info/`,
      id: "DocuSign Info",
      icon: faPenFancy,
    },
    {
      path: `/tools/merge-companies/`,
      id: "Merge Companies",
      icon: faCodeMerge,
    },
    {
      path: `/tools/merge-employees/`,
      id: "Merge Employees",
      icon: faCodeMerge,
    },
    {
      path: `${PATH_PREFIX}admin-tools/misc/`,
      id: "Misc Tools",
      component: MiscAdmin,
      icon: faWrench,
    },
    {
      path: `/tools/twilio/contacts/`,
      id: "Twilio Contacts",
      icon: faIdCard,
    },
    {
      path: `/tools/wellness-checks/contact-tracing/`,
      id: "Check In Contact Tracing",
      icon: faSpiderWeb,
    },
    {
      path: `${PATH_PREFIX}admin-tools/check-in-dashboard/`,
      id: "Check In Dashboard",
      component: CheckInDashboard,
      icon: faCheckToSlot,
    },
    // {
    //   path: `/tools/wellness-check-results/dashboard/`,
    //   id: "Wellness Check Dashboard",
    //   icon: faVirus,
    // },
    {
      path: `/impersonate/list/`,
      id: "Impersonate User",
      icon: faUser,
    },
    {
      path: `${PATH_PREFIX}admin-tools/hooks/`,
      id: "Hooks",
      component: Hooks,
    },
    {
      path: `${PATH_PREFIX}admin-tools/theme/`,
      id: "Theme",
      component: Theme,
      icon: faPalette,
    },
    {
      id: "Exception",
      path: `${PATH_PREFIX}exception/`,
      // icon: faSink,
      component: Exception,
      // badge: "5",
    },
    {
      path: `${PATH_PREFIX}django/`,
      id: "Django Test",
      component: DjangoTest,
    },
  ],
  component: null,
};

const projectDashboardRoutes = {
  id: "Project Home",
  path: `${PATH_PREFIX}projects/:projectId/`,
  icon: faHome,
  component: ProjectDashboard,
  children: null,
};
const projectUpdateRoutes = {
  path: `${PATH_PREFIX}projects/:projectId/update/`,
  component: ProjectUpdate,
  children: null,
};
const projectReportRoutes = {
  id: "Reports",
  path: `${PATH_PREFIX}projects/:projectId/reports/`,
  component: ProjectReports,
  icon: faFileInvoice,
  children: null,
};

const projectAIRoutes = {
  id: "AI",
  path: `${PATH_PREFIX}projects/:projectId/ai/documents/`,
  component: ProjectAIDocumentList,
  icon: faMicrochipAi,
  children: null,
};

const projectBudgetRoutes = {
  id: "Budget",
  icon: faPiggyBank,
  badgeAttr: "budget_count",
  children: [
    // {
    //   path: `/projects/:projectId/budget/current/`,
    //   id: "Current",
    // },
    {
      id: "Current",
      path: `${PATH_PREFIX}projects/:projectId/budget/current/`,
      component: ProjectBudgetCurrent,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/budget/original/`,
      id: "Original",
      component: ProjectBudgetOriginal,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/budget/cost-detail/`,
      component: ProjectBudgetCostDetail,
      id: "Cost Detail",
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/budgets/:budgetId/`,
      component: ProjectBudgetCodeDetail,
      id: null,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/budget/vs-jtd/`,
      component: ProjectBudgetVsJTD,
      id: null,
    },
  ],
  component: null,
};
const projectChangeOrderRoutes = {
  id: "Change Orders",
  icon: faPaperclip,
  children: [
    {
      // path: `/projects/:projectId/co/create/`,
      path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/`,
      component: ChangeOrderWizard,
      id: "Change Order Wizard",
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/pcos/`,
      id: "Potential",
      badgeAttr: "pco_count",
      component: ProjectPCOs,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/cors/`,
      id: "Request",
      badgeAttr: "cor_count",
      component: ProjectCORs,
    },
    // {
    //   path: `/projects/:projectId/sccos/`,
    //   id: "Subcontract",
    //   badgeAttr: "scco_count",
    // },
    {
      id: "Subcontract",
      path: `${PATH_PREFIX}projects/:projectId/sccos/`,
      badgeAttr: "scco_count",
      component: ProjectSCOs,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/pccos/`,
      id: "Owner",
      badgeAttr: "pcco_count",
      component: ProjectPCCOs,
    },
  ],
  component: null,
};

const projectContractRoutes = {
  id: "Contracts",
  icon: faGavel,
  children: [
    {
      path: `${PATH_PREFIX}projects/:projectId/letters-of-intent/`,
      id: "Letters of Intent",
      badgeAttr: "letter_of_intent_count",
      component: ProjectLettersOfIntent,
    },
    {
      // path: `/projects/:projectId/contracts/?refresh=prime#tab_primecontracts`,
      // id: "Owner",
      // badgeAttr: "prime_contract_count",
      path: `${PATH_PREFIX}projects/:projectId/contracts/#tab=primeContracts`,
      id: "Owner",
      badgeAttr: "prime_contract_count",
      component: ProjectContracts,
    },
    {
      // path: `/projects/:projectId/contracts/?refresh=sub#tab_subcontracts`,
      // id: "Sub",
      // badgeAttr: "subcontract_count",
      path: `${PATH_PREFIX}projects/:projectId/contracts/`,
      id: "Sub",
      badgeAttr: "subcontract_count",
      component: ProjectContracts,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/subcontractor-lien-releases/`,
      id: "Lien Releases",
      component: ProjectSubcontractorLienReleaseList,
      badgeAttr: "subcontractorlienrelease_count",
    },
  ],
  component: null,
};
const projectContractRestrictedRoutes = {
  id: "Contracts",
  icon: faGavel,
  children: [
    {
      // path: `/projects/:projectId/contracts/?refresh=sub#tab_subcontracts`,
      // id: "Sub",
      // badgeAttr: "subcontract_count",
      path: `${PATH_PREFIX}projects/:projectId/contracts/`,
      id: "Sub",
      badgeAttr: "subcontract_count",
      component: ProjectContractsRestricted,
    },
  ],
  component: null,
};
// const projectDailyCheckInsRoutes = {
//   id: "Daily Check Ins",
//   path: `/projects/:projectId/covid/checkin/`,
//   icon: faVirus,
//   children: null,
//   badgeAttr: "daily_check_in_count",
// };
// const projectDailyCheckInsRoutes = {
//   id: "Daily Check Ins",
//   path: `${PATH_PREFIX}projects/:projectId/check-ins/`,
//   component: ProjectDailyCheckIns,
//   icon: faVirus,
//   children: null,
//   badgeAttr: "daily_check_in_count",
// };
const projectManualDailyCheckInsRoutes = {
  // id: "Daily Check Ins",
  path: `${PATH_PREFIX}projects/:projectId/manual-check-ins/`,
  component: ProjectManualDailyCheckIns,
};
// const projectDailyReportsRoutes = {
//   id: "Daily Reports",
//   path: `${PATH_PREFIX}projects/:projectId/daily-reports/`,
//   icon: faCalendarDay,
//   children: null,
//   badgeAttr: "daily_report_count",
//   component: ProjectDailyReportsList,
// };
const projectDirectoryRoutes = {
  id: "Directory",
  path: `${PATH_PREFIX}projects/:projectId/directory/`,
  component: ProjectDirectory,
  icon: faAddressBook,
  children: null,
  badgeAttr: "contact_count",
};
const projectDocuSignRoutes = {
  id: "DocuSign",
  // path: `/projects/:projectId/envelopes/`,
  path: `${PATH_PREFIX}projects/:projectId/docusign/envelopes/`,
  component: ProjectDocuSignEnvelopeList,
  icon: faPenFancy,
  children: null,
  badgeAttr: "docusign_count",
};
const projectDrawingsRoutes = {
  id: "Drawings",
  path: `${PATH_PREFIX}projects/:projectId/drawings/`,
  component: ProjectDrawings,
  icon: faDraftingCompass,
  badgeAttr: "drawing_count",
  children: null,
};

const projectFieldPersonnelRoutes = {
  id: "Field",
  icon: faHardHat,
  children: [
    {
      id: "Check Ins",
      path: `${PATH_PREFIX}projects/:projectId/check-ins/`,
      component: ProjectDailyCheckIns,
      // icon: faVirus,
      badgeAttr: "daily_check_in_count",
    },
    {
      id: "Daily Reports",
      path: `${PATH_PREFIX}projects/:projectId/daily-reports/`,

      badgeAttr: "daily_report_count",
      component: ProjectDailyReportsList,
    },
    {
      id: "Jobsite Documents",
      path: `${PATH_PREFIX}projects/:projectId/jobsite-documents/`,
      component: ProjectJobsiteDocuments,
    },
    {
      id: "Pretask Plans",
      path: `${PATH_PREFIX}projects/:projectId/pretask-plans/`,
      component: ProjectPretaskPlans,
      badgeAttr: "pretask_plan_count",
      // icon: faSquareCheck,
    },

    {
      id: "Sub Work Log",
      path: `${PATH_PREFIX}projects/:projectId/daily-reports/work-log-by-subcontractor/`,
      component: ProjectWorkLogsBySubcontractor,
    },
  ],
  component: null,
};
const projectFilesRoutes = {
  id: "Files",
  path: `${PATH_PREFIX}projects/:projectId/box/files/projects/project/:projectId/`,
  component: BoxFiles,
  icon: faCloud,
  children: null,
};
const projectForecastRoutes = {
  id: "Forecast",
  path: `${PATH_PREFIX}projects/:projectId/forecasts/`,
  component: ProjectForecastList,
  icon: faChartLine,
  children: null,
};
const projectInsuranceRoutes = {
  id: "Insurance",
  path: `${PATH_PREFIX}projects/:projectId/insurance/`,
  component: ProjectInsurance,
  icon: faUmbrella,
  children: null,
};
const projectLinkRoutes = {
  id: "Links",
  path: `${PATH_PREFIX}projects/:projectId/links/`,
  // path: `/projects/:projectId/links/`,
  component: ProjectLinks,
  icon: faLink,
  children: null,
};
const projectMeetingRoutes = {
  id: "Meetings",
  path: `${PATH_PREFIX}projects/:projectId/meetings/`,
  component: ProjectMeetings,
  icon: faUsers,
  children: null,
  badgeAttr: "meeting_series_count",
};
const projectPreconstructionRoutes = {
  id: "Preconstruction",
  icon: faFileChartPie,
  children: [
    {
      id: "Cost Model",
      path: `/projects/:projectId/cost-model/`,
    },
  ],
  component: null,
};
const projectPurchaseOrderRoutes = {
  id: "Purchase Orders",
  path: `${PATH_PREFIX}projects/:projectId/pos/`,
  icon: faShoppingCart,
  children: null,
  badgeAttr: "purchase_order_count",
  component: ProjectPurchaseOrders,
};
const projectPurchaseOrderRestrictedRoutes = {
  id: "Purchase Orders",
  icon: faCartShopping,
  path: `${PATH_PREFIX}projects/:projectId/pos/`,
  badgeAttr: "purchase_order_count",
  children: null,
  component: ProjectPurchaseOrdersRestricted,
};

const subcontractDetailRestrictedRoutes = {
  id: "Subcontract Detail",
  path: `${PATH_PREFIX}projects/:projectId/contracts/sub/:subcontractId/`,
};

const projectRFIRoutes = {
  id: "RFIs",
  path: `${PATH_PREFIX}projects/:projectId/rfis/`,
  icon: faComments,
  badgeAttr: "rfi_count",
  component: ProjectRFIs,
  children: null,
};
const projectReviewRoutes = {
  id: "Reviews",
  path: `${PATH_PREFIX}projects/:projectId/reviews/`,
  icon: faClipboardList,
  component: ProjectReviews,
  children: null,
};

const projectSafetyRoutes = {
  id: "Safety",
  icon: faTrafficCone,
  children: [
    {
      id: "Announcements",
      path: `${PATH_PREFIX}projects/:projectId/safety/announcements/`,
      component: SafetyAnnouncements,
      badgeAttr: "safety_announcement_count",
    },
    {
      id: "Daily Safety Inspections",
      path: `${PATH_PREFIX}projects/:projectId/safety/inspections/`,
      component: ProjectDailySafetyInspections,
    },
    {
      id: "Highwire Contractors",
      path: `${PATH_PREFIX}projects/:projectId/highwire/contractors/`,
      component: ProjectHighwireContractors,
    },
    {id: "Orientation", path: `${PATH_PREFIX}projects/:projectId/safety/orientation/`, component: SafetyOrientation},
    {id: "Permits", path: `${PATH_PREFIX}projects/:projectId/safety/permits/`, component: ProjectSafetyForms},
    {
      id: "Project Safety Reviews",
      path: `${PATH_PREFIX}projects/:projectId/safety/project-safety-reviews/`,
      component: ProjectSafetyReviews,
    },
  ],
};

const projectScheduleRoutes = {
  id: "Schedules",
  path: `/projects/:projectId/schedules/`,
  icon: faCalendar,
  children: null,
};
const projectSlackRoutes = {
  id: "Slack",
  path: `${PATH_PREFIX}projects/:projectId/slack/`,
  icon: faSlack,
  component: SlackProject,
  children: null,
};
const projectSpecRoutes = {
  id: "Specs",
  path: `${PATH_PREFIX}projects/:projectId/specs/`,
  icon: faBook,
  children: null,
  badgeAttr: "spec_section_count",
  component: ProjectSpecSections,
};
const projectSubmittalRoutes = {
  id: "Submittals",
  icon: faExchange,
  children: [
    {
      // path: `/projects/:projectId/submittals/packages/`,
      id: "Packages",
      path: `${PATH_PREFIX}projects/:projectId/submittals/packages/`,
      component: ProjectSubmittalPackages,
      // icon: faBoxOpen,
      badgeAttr: "submittal_package_count",
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/submittals/items/`,
      id: "Items",
      // icon: faList,
      component: ProjectSubmittalItems,
      badgeAttr: "submittal_item_count",
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/procurement-log/`,
      id: "Procurement Log",
      component: ProcurementLog,
      // icon: faTasks,
    },
    {
      path: `/projects/:projectId/submittals/tracking-log/`,
      id: "Tracking Log",
      // icon: faTasks,
    },
  ],
  component: null,
};
const projectTaskRoutes = {
  id: "Tasks",
  path: `/projects/:projectId/tasks/`,
  icon: faClipboardList,
  children: null,
  badgeAttr: "project_task_count",
};
const projectTransmittalRoutes = {
  id: "Transmittals",
  path: `${PATH_PREFIX}projects/:projectId/transmittals/`,
  icon: faTelegramPlane,
  component: ProjectTransmittals,
  children: null,
  badgeAttr: "transmittal_count",
};
const projectAccountingToolsRoutes = {
  id: "Accounting Tools",
  icon: faMoneyBill,
  children: [
    {
      path: `/budgets/project/:projectId/approve/`,
      id: "Approve Budget",
    },
    {
      path: `/projects/:projectId/billing/`,
      id: "Project Billing",
    },
  ],
  component: null,
};
const projectWeatherRoutes = {
  id: "Weather",
  path: `${PATH_PREFIX}projects/:projectId/weather/`,
  icon: faClouds,
  component: ProjectWeather,
  children: null,
};
const projectCloseoutRoutes = {
  id: "Closeout",
  path: `${PATH_PREFIX}projects/:projectId/closeout/`,
  icon: faFlagCheckered,
  component: ProjectCloseout,
  children: null,
};

const projectAdminRoutes = {
  id: "Admin Tools",
  path: `${PATH_PREFIX}projects/:projectId/admin-tools/`,
  icon: faCogs,
  component: ProjectAdminTools,
  children: null,
};

const otherRoutes = {
  children: [
    {
      path: `${PATH_PREFIX}bonuses/accounts/:bonusAccountId/`,
      component: BonusAccountDetail,
    },
    {
      path: `${PATH_PREFIX}bonuses/accounts/:bonusAccountId/distribution-requests/:distributionRequestId/`,
      component: BonusAccountDistributionRequestDetail,
    },
    {
      path: `${PATH_PREFIX}bonuses/accounts/:bonusAccountId/closeout-requests/:closeoutRequestId/`,
      component: BonusAccountCloseoutRequestDetail,
    },
    {
      path: `${PATH_PREFIX}box/files/:app/:model/:object_id/`,
      component: BoxFiles,
    },
    {
      path: `${PATH_PREFIX}accounting-tools/msas/:msaId/`,
      component: MasterSubcontractAgreementDetail,
    },
    {
      path: `${PATH_PREFIX}accounting-tools/timecards/employees/:adpEmployeeCode/`,
      component: TimecardsForEmployee,
    },
    {
      path: `${PATH_PREFIX}projects/`,
      component: AccessibleProjects,
    },
    {
      path: `${PATH_PREFIX}pm-billings/`,
      component: ProjectManagerBillingsList,
    },

    // Add temp route for Alyssa Birschtein so that we don't have to give her full accounting access but allow client invoice lookup
    // Removed Alyssa Birschtein is no longer with NOVO
    // {
    //   path: `${PATH_PREFIX}accounting-tools/client-invoice-lookup/`,
    //   component: ClientInvoiceLookup,
    // },

    {
      path: `${PATH_PREFIX}employees/reviews/:type/:year/:employeeId/`,
      component: EmployeeReviewDetail,
    },
    {
      path: `${PATH_PREFIX}preconstruction/subcontractor-awards/:vendorId/`,
      component: SubcontractorAwardVendor,
    },
    {path: `${PATH_PREFIX}docusign/envelopes/send-reminders/`, component: DocusignSendReminders},
    {
      path: `${PATH_PREFIX}docusign/envelopes/:app/:model/:object_id/`,
      component: DocuSignObjectEnvelopeList,
    },
    {path: `${PATH_PREFIX}docusign/envelopes/:envelopeUUID/`, component: DocuSignEnvelopeDetail},
    {path: `${PATH_PREFIX}ai/documents/`, component: AIDocumentList},
    {path: `${PATH_PREFIX}ai/documents/:documentId/`, component: AIChat},

    // {path: `${PATH_PREFIX}accounting-tools/master-project-list/`, component: AccountingMasterProjectList},
    // {path: `${PATH_PREFIX}projects/create-project-wizard/`, component: CreateProjectWizard},
    {path: `${PATH_PREFIX}safety/`, component: SafetyDashboard},
    // {path: `${PATH_PREFIX}labor/contacts/`, component: LaborContacts},
    // {path: `${PATH_PREFIX}labor/schedule/`, component: LaborSchedule},
  ],
};

const otherProjectRoutes = {
  children: [
    {
      path: `${PATH_PREFIX}projects/:projectId/audit/:app/:model/:object_id/`,
      component: ProjectUserAuditView,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/box/files/secure/`,
      component: BoxFiles,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/box/files/:app/:model/:object_id/`,
      component: BoxFiles,
    },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/docusign/envelopes/`,
    //   component: ProjectDocuSignEnvelopes,
    // },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/contracts/`,
    //   component: ProjectContracts,
    // },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/daily-reports/`,
    //   component: ProjectDailyReportsList,
    // },
    {
      path: `${PATH_PREFIX}projects/:projectId/bonuses/closeout-requests/`,
      component: ProjectBonusCloseoutRequests,
    },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/budget/cost-detail/`,
    //   component: ProjectBudgetCostDetail,
    // },
    {
      path: `${PATH_PREFIX}projects/:projectId/:app/:model/:object_id/docusign/envelopes/`,
      component: DocuSignObjectEnvelopeList,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/daily-reports/work-log-by-subcontractor/`,
      component: ProjectWorkLogsBySubcontractor,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/daily-reports/:dailyReportId/`,
      component: ProjectDailyReportDetail,
    },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/forecasts/`,
    //   component: ProjectForecastList,
    // },
    {
      path: `${PATH_PREFIX}projects/:projectId/forecasts/:forecastId/`,
      component: ProjectForecastDetail,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/groups/`,
      component: ProjectGroups,
    },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/links/`,
    //   component: ProjectLinks,
    // },
    {
      path: `${PATH_PREFIX}projects/:projectId/permissions/`,
      component: ProjectPermissions,
    },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/submittals/items/`,
    //   component: ProjectSubmittalItems,
    // },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/submittals/packages/`,
    //   component: ProjectSubmittalPackages,
    // },
    {
      path: `${PATH_PREFIX}projects/:projectId/delete/:app/:model/:object_id/`,
      component: ProjectDeleteObject,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/letters-of-intent/:letterOfIntentId/`,
      component: LetterOfIntentDetail,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/pcos/items/`,
      component: ProjectPCOItems,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/pcos/:pcoId/`,
      component: PCODetail,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/rfis/:rfiId/`,
      component: RFIDetail,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/contracts/sub/:subcontractId/`,
      component: SubcontractDetail,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/subcontractor-lien-releases/:subcontractorLienReleaseId/`,
      component: SubcontractorLienReleaseDetail,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/submittals/items/:submittalId/`,
      component: SubmittalItemDetail,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/submittals/packages/:submittalId/`,
      component: SubmittalPackageDetail,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/submittals/packages/:packageId/transmittals/:transmittalId/`,
      component: SubmittalTransmittalDetail,
    },

    {
      path: `${PATH_PREFIX}projects/:projectId/daily-reports/work-log-by-subcontractor/:subcontractorId/`,
      component: WorkLogBySubcontractor,
    },

    // {
    //   path: `${PATH_PREFIX}projects/:projectId/pcos/`,
    //   component: ProjectPCOs,
    // },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/cors/`,
    //   component: ProjectCORs,
    // },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/sccos/`,
    //   component: ProjectSCOs,
    // },
    // {path: `${PATH_PREFIX}projects/:projectId/directory/`, component: ProjectDirectory},
    {path: `${PATH_PREFIX}projects/:projectId/pccos/`, component: ProjectPCCOs},
    {path: `${PATH_PREFIX}projects/:projectId/pccos/:pccoId/`, component: PCCODetail},
    {path: `${PATH_PREFIX}projects/:projectId/sccos/:scoId/`, component: SCODetail},
    {path: `${PATH_PREFIX}projects/:projectId/pos/:purchaseOrderId/`, component: PurchaseOrderDetail},
    {
      path: `${PATH_PREFIX}projects/:projectId/pos/:purchaseOrderId/amendments/:amendmentId/`,
      component: PurchaseOrderAmendmentDetail,
    },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/budget/original/`,
    //   component: ProjectBudgetOriginal,
    // },
    {
      path: `${PATH_PREFIX}projects/:projectId/contracts/prime/:contractId/`,
      component: PrimeContractDetail,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/pcco/:contractId/create/`,
      component: PCCOCreate,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/sco/:subcontractId/create/`,
      component: SCCOCreate,
    },
    // {path: `${PATH_PREFIX}projects/:projectId/transmittals/`, component: ProjectTransmittals},
    {path: `${PATH_PREFIX}projects/:projectId/transmittals/:transmittalId`, component: TransmittalDetail},
    // {path: `${PATH_PREFIX}projects/:projectId/meetings/`, component: ProjectMeetings},
    // {path: `${PATH_PREFIX}projects/:projectId/insurance/`, component: ProjectInsurance},
    // {path: `${PATH_PREFIX}projects/:projectId/meetings/:meetingId`, component: ProjectMeetingDetail},
    {path: `${PATH_PREFIX}projects/:projectId/meetings/:meetingId/minutes`, component: MinutesDetail},
    {path: `${PATH_PREFIX}projects/:projectId/meetings/:meetingId/agenda`, component: AgendaDetail},
    {path: `${PATH_PREFIX}projects/:projectId/reports/`, component: ProjectReports},
    {path: `${PATH_PREFIX}projects/:projectId/docusign/envelopes/:envelopeUUID/`, component: DocuSignEnvelopeDetail},
    {path: `${PATH_PREFIX}projects/:projectId/pretask-plans/`, component: ProjectPretaskPlans},
    {path: `${PATH_PREFIX}projects/:projectId/start/`, component: ProjectJobStart},
    {path: `${PATH_PREFIX}projects/:projectId/check-ins/:checkinId/`, component: WellnessCheckinDetail},
    {
      path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/sco/select-subcontract/`,
      component: SubContractSelect,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/cor/`,
      component: PCOtoCOR,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/pcco/select-contract/`,
      component: PrimeContractSelect,
    },
    {
      path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/sco/from-approved-items/`,
      component: SCCOFromApprovedItems,
    },
    {path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/pco/`, component: PCOCreate},
    {path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/net-zero/`, component: NetZeroChangeOrder},
    // {path: `${PATH_PREFIX}projects/:projectId/change-order-wizard/`, component: ChangeOrderWizard},
    {path: `${PATH_PREFIX}projects/:projectId/reviews/:reviewId/`, component: ProjectReviewDetail},

    {path: `${PATH_PREFIX}projects/:projectId/ai/documents/`, component: ProjectAIDocumentList},
    {path: `${PATH_PREFIX}projects/:projectId/ai/documents/analysis/`, component: ProjectAIDocumentAnalysisList},
    {
      path: `${PATH_PREFIX}projects/:projectId/ai/documents/analysis/:questionSet/:documentId/`,
      component: ProjectAIDocumentAnalysisDetail,
    },
    {path: `${PATH_PREFIX}projects/:projectId/ai/documents/:documentId/`, component: ProjectAIChat},
    {
      path: `${PATH_PREFIX}projects/:projectId/safety/project-safety-reviews/questions/`,
      component: SafetyReviewQuestions,
    },
    {path: `${PATH_PREFIX}projects/:projectId/safety/project-safety-reviews/`, component: ProjectSafetyReviews},
    {
      path: `${PATH_PREFIX}projects/:projectId/safety/project-safety-reviews/:safetyReviewId/`,
      component: ProjectSafetyReviewDetail,
    },
    {path: `${PATH_PREFIX}projects/:projectId/safety/orientation/`, component: SafetyOrientation},
    // {path: `${PATH_PREFIX}projects/:projectId/procurement-log/`, component: ProcurementLog},

    {
      path: `${PATH_PREFIX}projects/:projectId/safety/project-safety-reviews/:safetyReviewId/questions/:questionId/`,
      component: SafetyReviewQuestionDetail,
    },

    {
      path: `${PATH_PREFIX}projects/:projectId/safety/permits/:formOrigin/:safetyFormId/`,
      component: ProjectSafetyFormDetail,
    },
    // {
    //   path: `${PATH_PREFIX}projects/:projectId/safety/inspections/`,
    //   component: ProjectTwiceDailySafetyInspections,
    // },
    {
      path: `${PATH_PREFIX}projects/:projectId/safety/inspections/:inspectionId/`,
      component: DailySafetyInspectionDetail,
    },

    // {
    //   path: `${PATH_PREFIX}projects/:projectId/specs/`,
    //   component: ProjectSpecSections,
    // },
    // {path: `${PATH_PREFIX}projects/:projectId/reviews/`, component: ProjectReviews},
  ],
};

let authenticatedLayoutRoutes = {
  dashboard: dashboardRoutes,
  ai: aiRoutes,
  company: companyRoutes,
  contact: contactRoutes,
  project: projectRoutes,
  docuSign: docuSignRoutes,
  diversity: diversityRoutes,
  projectManager: projectManagerRoutes,
  safetyRoutes: safetyRoutes,
  creditCard: creditCardRoutes,
  schedule: scheduleRoutes,

  miscTools: miscToolsRoutes,
  accountingTools: accountingToolsRoutes,
  executiveTools: executiveToolsRoutes,
  hrTools: hrToolsRoutes,
  preconstruction: preconstructionRoutes,
  design: designRoutes,
  adminTools: adminToolRoutes,

  other: otherRoutes,
};

const filterChildRoutes = (childRoutes, permissions, permissionMap) => {
  for (const permission in permissionMap) {
    if (!permissions[permission]) {
      childRoutes = childRoutes.filter((route) => !permissionMap[permission].includes(route.id));
    }
  }
  return childRoutes;
};

export const getAuthenticatedLayoutRoutes = (user, permissions, waffle) => {
  if (user.is_anonymous) return [];

  // if (!waffle?.FLAGS?.can_use_ai) {
  //   delete authenticatedLayoutRoutes.ai;
  // }
  if (user.is_superuser) {
    authenticatedLayoutRoutes.hrTools = hrToolsAdminRoutes;
    return Object.values(authenticatedLayoutRoutes);
  }

  if (permissions.is_in_hr_group) {
    authenticatedLayoutRoutes.hrTools = hrToolsAdminRoutes;
  }
  if (!permissions.is_executive) delete authenticatedLayoutRoutes.executiveTools;
  if (!permissions.view_preconstruction) delete authenticatedLayoutRoutes.preconstruction;

  // Company child routes
  authenticatedLayoutRoutes.company.children = filterChildRoutes(
    authenticatedLayoutRoutes.company.children,
    permissions,
    {
      can_edit_msa: ["MSA Log"],
    }
  );

  if (authenticatedLayoutRoutes?.safetyRoutes?.children) {
    authenticatedLayoutRoutes.safetyRoutes.children = filterChildRoutes(
      authenticatedLayoutRoutes.safetyRoutes.children,
      permissions,
      {
        can_change_safety_reviews: ["PSR Dashboard"],
        can_manage_safety_reviews: ["Check In Dashboard", "Highwire Admin Dashboard"],
      }
    );
  }

  // Misc tools child routes
  authenticatedLayoutRoutes.miscTools.children = filterChildRoutes(
    authenticatedLayoutRoutes.miscTools.children,
    permissions,
    {
      view_laborrate: ["Labor Rates"],
      can_view_subcontractor_cost: ["Subcontractor Costs", "Subcontractor Revenue", "Misc Reports"],
    }
  );

  // Project manager routes
  if (permissions.is_pm_or_greater) {
    permissions.can_view_bonus_accounts_page = permissions.has_bonus_account || permissions.can_manage_bonuses;
    authenticatedLayoutRoutes.projectManager.children = filterChildRoutes(
      authenticatedLayoutRoutes.projectManager.children,
      permissions,
      {
        can_manage_bonuses: ["Bonus Distribution Requests", "Bonus Closeout Requests", "Bonus Transactions"],
        can_view_own_pm_report: ["PM Report"],
        can_view_bonus_accounts_page: ["Bonus Accounts"],
      }
    );
  } else {
    delete authenticatedLayoutRoutes.projectManager;
  }

  if (!permissions.is_pm_or_greater && !permissions.can_view_diversity_reports) {
    delete authenticatedLayoutRoutes.diversity;
  }

  if (!permissions.can_view_accounting_tools_menu) {
    delete authenticatedLayoutRoutes.accountingTools;
  } else {
    // Accounting tools child routes
    authenticatedLayoutRoutes.accountingTools.children = filterChildRoutes(
      authenticatedLayoutRoutes.accountingTools.children,
      permissions,
      {
        can_approve_budget: ["Approve Budgets"],
        view_timecards: ["Timecards"],
        upload_direct_cost_timberline_imports: ["Home Depot Upload"],
        view_subcontractor_lien_release: ["Subcontractor Lien Releases"],
        change_employee_rates: ["Employee Rates"],
        view_all_credit_cards: ["Credit Cards"],
        review_project_closeout_request: ["Can Closeout Projects"],
        is_superuser: ["MSA Log"],
      }
    );
  }
  if (!permissions.can_change_safety_reviews) {
    delete authenticatedLayoutRoutes.safetyRoutes;
  }

  if (!permissions.view_laborscheduleitem || !permissions.change_laborscheduleitem) {
    authenticatedLayoutRoutes.schedule.children = authenticatedLayoutRoutes.schedule.children.filter(
      (item) => !["Labor Contacts", "Labor Schedule", "Labor Schedule Calendar"].includes(item.id)
    );
  }

  delete authenticatedLayoutRoutes.design;
  delete authenticatedLayoutRoutes.adminTools;
  return Object.values(authenticatedLayoutRoutes);
};

const projectLayoutRoutes = {
  dashboard: dashboardRoutes,
  projectDashboard: projectDashboardRoutes,
  projectUpdate: projectUpdateRoutes,
  projectReport: projectReportRoutes,
  projectAI: projectAIRoutes,
  projectBudget: projectBudgetRoutes,
  projectChangeOrder: projectChangeOrderRoutes,
  projectContract: projectContractRoutes,

  // projectDailyCheckIns: projectDailyCheckInsRoutes,
  // projectDailyCheckIns2: projectDailyCheckInsRoutes2,
  projectManualDailyCheckInsRoutes: projectManualDailyCheckInsRoutes,
  // projectDailyReports: projectDailyReportsRoutes,
  projectDirectory: projectDirectoryRoutes,
  projectDocuSign: projectDocuSignRoutes,
  projectDrawings: projectDrawingsRoutes,
  projectFieldPersonnelRoutes: projectFieldPersonnelRoutes,
  projectFiles: projectFilesRoutes,
  projectForecast: projectForecastRoutes,
  projectInsurance: projectInsuranceRoutes,
  projectLink: projectLinkRoutes,
  projectMeeting: projectMeetingRoutes,
  projectPurchaseOrder: projectPurchaseOrderRoutes,
  projectRFI: projectRFIRoutes,
  projectReview: projectReviewRoutes,
  projectSafetyRoutes: projectSafetyRoutes,
  projectSchedule: projectScheduleRoutes,
  projectSlack: projectSlackRoutes,
  projectSpec: projectSpecRoutes,
  projectSubmittal: projectSubmittalRoutes,
  projectTask: projectTaskRoutes,
  projectTransmittal: projectTransmittalRoutes,
  projectAccounting: projectAccountingToolsRoutes,
  projectWeather: projectWeatherRoutes,
  projectPreconstruction: projectPreconstructionRoutes,
  projectCloseout: projectCloseoutRoutes,
  projectAdmin: projectAdminRoutes,

  other: otherProjectRoutes,
};

export const getProjectLayoutRoutes = (user, permissions, waffle, project) => {
  if (user.is_anonymous) return [];

  if (user.is_superuser) {
    return Object.values(projectLayoutRoutes);
  }

  if (permissions.has_restricted_views) {
    delete projectLayoutRoutes.projectReport;
    delete projectLayoutRoutes.projectBudget;
    delete projectLayoutRoutes.projectChangeOrder;
    delete projectLayoutRoutes.projectDocuSign;
    delete projectLayoutRoutes.projectForecast;
    delete projectLayoutRoutes.projectInsurance;
    delete projectLayoutRoutes.projectLink;
    // delete projectLayoutRoutes.projectMeeting;
    delete projectLayoutRoutes.projectReview;
    // delete projectLayoutRoutes.projectSlack;
    delete projectLayoutRoutes.projectSpec;
    delete projectLayoutRoutes.projectTransmittal;
    delete projectLayoutRoutes.projectAccounting;
    projectLayoutRoutes["projectContract"] = projectContractRestrictedRoutes;
    projectLayoutRoutes["projectPurchaseOrder"] = projectPurchaseOrderRestrictedRoutes;

    otherProjectRoutes.children.map((child) => {
      if (
        child.path === `${PATH_PREFIX}projects/:projectId/contracts/sub/:subcontractId/` &&
        child.component !== SubcontractDetailRestricted
      ) {
        child.component = SubcontractDetailRestricted;
      }
    });

    projectLayoutRoutes.projectSubmittal.children = projectLayoutRoutes.projectSubmittal.children.filter((item) =>
      ["Packages", "Procurement Log"].includes(item.id)
    );
  }
  if (projectLayoutRoutes.projectBudget) {
    projectLayoutRoutes.projectBudget.children = filterChildRoutes(
      projectLayoutRoutes.projectBudget.children,
      permissions,
      {
        can_view_job_cost_reports: ["Cost Detail"],
      }
    );
  }

  if (!permissions.can_view_accounting_tools_menu) {
    delete projectLayoutRoutes.projectAccounting;
  } else {
    // Accounting tools child routes
    projectLayoutRoutes.projectAccounting.children = filterChildRoutes(
      projectLayoutRoutes.projectAccounting.children,
      permissions,
      {
        can_approve_budget: ["Approve Budget"],
        project_billing: ["Project Billing"],
      }
    );
  }

  if (!permissions.view_preconstruction) delete projectLayoutRoutes.projectPreconstruction;
  if (!permissions.is_apm_or_greater) delete projectLayoutRoutes.projectForecast;

  delete projectLayoutRoutes.projectAdmin;

  if (projectLayoutRoutes?.projectSafetyRoutes?.children) {
    projectLayoutRoutes.projectSafetyRoutes.children = projectLayoutRoutes.projectSafetyRoutes.children.filter(
      (item) => {
        if (item.id === "Daily Safety Inspections") {
          if ([7963, 8386].includes(project?.id)) return true;
          if (permissions.can_manage_safety_reviews) return true;
          return false;
        }
        return true;
      }
    );
  }

  return Object.values(projectLayoutRoutes);
};
