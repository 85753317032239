export const locationInline = (location) => {
  if (location.city && location.state) {
    return `
    ${location.address1 || ""}
    ${location.address2 || ""}
    ${location.address3 || ""}
    ${location.city || ""},
    ${location.state || ""}
    ${location.zip || ""}
    `;
  } else {
    return `
    ${location.address1 || ""}
    ${location.address2 || ""}
    ${location.address3 || ""}
    ${location.city || ""}
    ${location.state || ""}
    ${location.zip || ""}
    `;
  }
};
