import {Grid, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import usePermissions from "../../hooks/usePermissions";
import useUser from "../../hooks/useUser";
import useWaffle from "../../hooks/useWaffle";

const Hooks = (props) => {
  const user = useUser();
  const permissions = usePermissions();
  const waffle = useWaffle();
  return (
    <>
      <Helmet title="Hooks" />

      <Typography variant="h1">Hooks</Typography>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Typography variant="h2">User</Typography>
          <pre>{JSON.stringify({user}, null, 2)}</pre>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="h2">Permissions</Typography>
          <pre>{JSON.stringify({permissions}, null, 2)}</pre>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="h2">Waffle</Typography>
          <pre>{JSON.stringify({waffle}, null, 2)}</pre>
        </Grid>
      </Grid>
    </>
  );
};

export default Hooks;
