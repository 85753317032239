import {faBasketballBall, faComments} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Divider, Grid, Typography} from "@material-ui/core";
import moment from "moment";
import {useSnackbar} from "notistack";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {CreateMissingBoxFoldersConfirmationDialog, FixBoxFolderNamesConfirmationDialog} from "../../../components/Box";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useLocalStorageWithExpiration} from "../../../hooks/useLocalStorage";
import {getAxiosAPIResponseMessage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import useWhyDidYouUpdate from "../../../hooks/useWhyDidYouUpdate";
import getQueryStringFromIds from "../../../utils/getQueryStringFromIds";
import {RFIBulkUpdateDialogForm, RFIUpdateDialogForm} from "./RFIForms";
import RFIPaperItem from "./RFIPaperItem";

const ProjectRFIs = (props) => {
  useWhyDidYouUpdate("ProjectRFIs", props);
  const {project, userContact, ...rest} = props;
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [showBulkUpdateDialog, setShowBulkUpdateDialog] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [createMissingBoxFolders, setCreateMissingBoxFolders] = React.useState(false);
  const [fixBoxFolderNamesDialog, setFixBoxFolderNamesDialog] = React.useState(false);
  const [renumberConfirmationIsOpen, setRenumberConfirmationIsOpen] = React.useState(false);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = useLocalStorageWithExpiration("projectRFIsPageSize", 100);
  const {enqueueSnackbar} = useSnackbar();
  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "Status",
    "Tag",
    "Overdue",
    "Draft",
    "Responsible Party",
    "Spec Section",
  ]);
  const [activeRFI, setActiveRFI] = React.useState({} as any);
  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const {query: statusQuery} = useSentinelListAPI(`projects/${project.id}/rfis/status/`, {
    initialData: [],
  });
  const statusOptions = statusQuery.data;

  const {query: tagQuery} = useSentinelListAPI(`projects/${project.id}/rfis/tags/`, {
    initialData: [],
  });
  const tagOptions = tagQuery.data;

  const {query: responsiblePartyQuery} = useSentinelListAPI(`projects/${project.id}/rfis/responsible-parties/`, {
    initialData: [],
  });
  const responsiblePartyOptions = responsiblePartyQuery.data;

  const {query: specSectionQuery} = useSentinelListAPI(`projects/${project.id}/rfis/spec-sections/`, {
    initialData: [],
  });
  const specSectionOptions = specSectionQuery.data;

  const filterParams = {
    q: filterOptions.Search.value,
    status: filterOptions.Status.value,
    tag: filterOptions.Tag.value,
    is_overdue: filterOptions.Overdue.value,
    is_draft: filterOptions.Draft.value,
    responsible_party_id: filterOptions["Responsible Party"].value,
    spec_section_code: filterOptions["Spec Section"].value,
  };

  const {
    query: rfiQuery,
    create: createRFI,
    update: updateRFI,
    delete: deleteRFI,
    rpc: rfiRFI,
    onDragEnd: rfisOnDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/rfis/?page_size=${pageSize}&page=${page}&parent__isnull=True&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      // keepPreviousData: true,
    }
  );

  const rfis = rfiQuery.data.results;
  const allRFIIds = rfis.map((rfi) => rfi.id);

  const {
    selectedIds: selectedRFIIds,
    addSelectedId: addSelectedRFIId,
    removeSelectedId: removeSelectedRFIId,
    addAllSelectedIds: addAllRFIIds,
    removeAllSelectedIds: removeAllRFIIds,
    allIdsSelected: allRFISSelected,
  } = useSelectIds(allRFIIds);

  const updateRFIMemo = React.useCallback(updateRFI.mutateAsync, []);

  const pdfQueryString = React.useMemo(
    () => getQueryStringFromIds(selectedRFIIds, filterParams),
    [selectedRFIIds, filterParams]
  );

  return (
    <>
      <Helmet title={`${project.display} - RFIs`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">RFIs</Typography>
      </ProjectBreadcrumbs>
      {/*
      <PageHeader mb={1}>
        <PageHeader.Left>
          <PageHeader.Title>
            <ProjectStatusIcon project={project} /> {project.display} - RFI
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      */}
      <PaperPanel>
        <PaperPanel.Header isLoading={rfiQuery.isFetching}>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              indeterminate={!allRFISSelected && selectedRFIIds.size !== 0}
              checked={allRFISSelected}
              onChange={(event, checked) => {
                if (checked) {
                  addAllRFIIds();
                } else {
                  removeAllRFIIds();
                }
              }}
            />{" "}
            <FontAwesomeIcon icon={faComments} /> RFIs
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialog(true)}>
                Create RFI
              </PaperPanel.Header.CreateButton>
              <Box ml={1} />
              <PaperPanel.Header.EditButton
                disabled={selectedRFIIds.size === 0}
                onClick={() => setShowBulkUpdateDialog(true)}
              >
                Edit Selected
              </PaperPanel.Header.EditButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons
                pdfURL={`/reports2/projects/${project.id}/rfis/?${pdfQueryString}`}
                buildReportURL={`/reports2/projects/${project.id}/rfis/filter/`}
              >
                {/* <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/projects/${project.id}/rfis/?large=true`}>
                  Summary Large (includes cost impact)
                </PaperPanel.Header.Menu.PDFMenuItem> */}
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/rfis/ball-in-court/?${pdfQueryString}`}
                >
                  Ball In Court
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/rfis/by-responsible-party/?${pdfQueryString}`}
                >
                  Summary By Responsible Party
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/mail-log/63/?${pdfQueryString}`}
                >
                  Mail Log
                </PaperPanel.Header.Menu.PDFMenuItem>
              </PaperPanel.Header.PDFButtons>
            </PaperPanel.Header.Action>
            {/* <PaperPanel.Header.Action border>
                <PaperPanel.Header.RefreshButton onClick={() => rfiQuery.refetch()} isFetching={rfiQuery.isFetching} />
              </PaperPanel.Header.Action> */}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.ReorderButton
                onReorder={(orderBy) => {
                  blockUI.blockUI("Reordering...");
                  rfiRFI
                    .mutateAsync({
                      action: `reposition`,
                      qsParams: {order_by: orderBy},
                    })
                    .then(() => {
                      rfiQuery.refetch().then(() => blockUI.unblockUI());
                    })
                    .catch(() => {
                      rfiQuery.refetch().then(() => blockUI.unblockUI());
                    });
                }}
                onRenumber={() => {
                  setRenumberConfirmationIsOpen(true);
                }}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border pr={0}>
              <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={() => {
                        popupState.close();
                        blockUI.blockUI("Cleaning Ball In Court...");
                        axiosAPI
                          .post(`/projects/${project.id}/actions/cleanup_ball_in_court_for_rfis/`, {}, {baseURL: ""})
                          .then(() => {
                            rfiQuery.refetch().then(() => blockUI.unblockUI());
                          })
                          .catch((error) => {
                            enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
                            blockUI.unblockUI();
                          });
                      }}
                    >
                      <FontAwesomeIcon icon={faBasketballBall} fixedWidth /> Cleanup Ball In Court
                    </PaperPanel.Header.Menu.MenuItem>
                    <Divider />
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setCreateMissingBoxFolders(true);
                      }}
                    >
                      Create Missing Box Folders
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setFixBoxFolderNamesDialog(true);
                      }}
                    >
                      Fix Box Folder Names
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.Menu>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action px={0}>
              <PaperPanel.Header.RefreshButton isFetching={rfiQuery.isFetching} onClick={() => rfiQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="Status"
                name="Status"
                options={statusOptions}
                value={filterOptions.Status.value}
                showStatusIcon
                statusType="rfi"
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="Tag"
                name="Tag"
                options={tagOptions}
                value={filterOptions.Tag.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Tag", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="Responsible Party"
                name="Responsible Party"
                options={responsiblePartyOptions}
                value={filterOptions["Responsible Party"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Responsible Party", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="Spec Section"
                name="Spec Section"
                options={[{value: "EMPTY", label: "(Blank)"}, ...specSectionOptions]}
                value={filterOptions["Spec Section"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Spec Section", value, label);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                // native
                label="Overdue"
                name="Overdue"
                options={[
                  {label: "True", value: "True"},
                  {label: "False", value: "False"},
                ]}
                value={filterOptions.Overdue.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Overdue", value, label);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                // native
                label="Draft"
                name="Draft"
                options={[
                  {label: "True", value: "True"},
                  {label: "False", value: "False"},
                ]}
                value={filterOptions.Draft.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Draft", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={rfiQuery.data.total_pages}
                  page={page}
                  // pageSize={pageSize}
                  setPage={setPage}
                  // setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>

          {/* <pre>{JSON.stringify(filterOptions, null, 2)}</pre> */}
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <VirtualizedList itemList={rfis} isVirtualized={rfis.length > 100} onDragEnd={rfisOnDragEnd}>
            {(props) => {
              const {listItem: rfi, ...rest} = props;
              return (
                <RFIPaperItem
                  rfi={rfi}
                  key={rfi.id}
                  projectId={project.id}
                  updateRFI={(is_draft) => {
                    updateRFIMemo({...rfi, ...is_draft});
                  }}
                  // updateRFI={updateRFI.mutateAsync}
                  onChangeRFISelected={(event, value) => {
                    if (value) {
                      addSelectedRFIId(rfi.id, event.nativeEvent.shiftKey);
                    } else {
                      removeSelectedRFIId(rfi.id);
                    }
                  }}
                  isSelected={selectedRFIIds.has(rfi.id)}
                  onEditRFI={() => {
                    setActiveRFI(rfi);
                    setShowUpdateDialog(true);
                  }}
                  onDeleteRFI={() => {
                    setActiveRFI(rfi);
                    setDeleteConfirmationIsOpen(true);
                  }}
                  {...rest}
                />
              );
            }}
          </VirtualizedList>
          {/* <DragDropContext
              onDragEnd={(result) => {
                rfisOnDragEnd(result);
                // .then(() => fetchPCOItems())
                // .catch(() => {});
              }}
            >
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {rfis.map((rfi, index) => {
                      return (
                        <Draggable key={rfi.id} draggableId={rfi.id.toString()} index={index}>
                          {(provided, snapshot) => (
                            <div ref={provided.innerRef} {...provided.draggableProps}>

                              <RFIPaperItem
                                rfi={rfi}
                                projectId={project.id}
                                updateRFI={updateRFIMemo}
                                isDragging={snapshot.isDragging}
                                isSelected={selectedRFIIds.has(rfi.id)}
                                dragHandleProps={provided.dragHandleProps}
                                addSelectedRFIId={addSelectedRFIId}
                                removeSelectedRFIId={removeSelectedRFIId}
                                setActiveRFI={setActiveRFI}
                                setShowUpdateDialog={setShowUpdateDialog}
                                setDeleteConfirmationIsOpen={setDeleteConfirmationIsOpen}
                              />
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext> */}
        </PaperPanel.Body>
        {(rfiQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={rfiQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[10, 100, 250, 500, 1000]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/rfis/react/legacy/`} mt={2} />

      <RFIUpdateDialogForm
        projectId={project.id}
        isNew
        isOpen={showCreateDialog}
        handleClose={() => {
          setShowCreateDialog(false);
        }}
        initialValues={{
          number: rfiQuery?.data?.metadata?.next_number,
          date_created: moment().format("YYYY-MM-DD"),
          authored_by: userContact,
          tags: [],
        }}
        onSubmit={(values) => {
          setShowCreateDialog(false);
          const editAfterSubmit = values.editAfterSubmit;
          if (editAfterSubmit) {
            delete values.editAfterSubmit;
            blockUI.blockUI();
          }
          createRFI.mutateAsync(values).then((newRFI) => {
            if (editAfterSubmit) {
              navigate(`/v2/projects/${project.id}/rfis/${newRFI.id}/`);
              blockUI.unblockUI();
            } else {
              rfiQuery.refetch();
            }
          });
        }}
      />
      <RFIUpdateDialogForm
        projectId={project.id}
        isOpen={showUpdateDialog}
        handleClose={() => {
          setShowUpdateDialog(false);
          // HACK. setActiveRFI to {} (instead of null) prevents tinyMCE from throwing: Cannot call setIn() with null state; on cancel update
          setActiveRFI({});
        }}
        initialValues={activeRFI}
        onSubmit={(values) => {
          setShowUpdateDialog(false);
          updateRFI.mutateAsync(values);
        }}
        rfis={rfis}
        activeRFI={activeRFI}
        setActiveRFI={setActiveRFI}
      />
      <RFIBulkUpdateDialogForm
        projectId={project.id}
        isOpen={showBulkUpdateDialog}
        handleClose={() => {
          setShowBulkUpdateDialog(false);
        }}
        onSubmit={(values) => {
          setShowBulkUpdateDialog(false);
          blockUI.blockUI("Bulk Updating...");
          if (values.clear_importance) {
            values["importance_id"] = null;
          }
          if (values.is_draft) {
            values["is_draft"] = true;
          }
          if (values.not_draft) {
            values["is_draft"] = false;
          }
          if (values.responsible_party) {
            values["responsible_party_id"] = values.responsible_party.id;
          }
          if (values.clear_responsible_party) {
            values["responsible_party_id"] = null;
          }
          if (values.cc_contact) {
            rfiRFI
              .mutateAsync({
                action: "add-cc",
                data: {
                  ids: Array.from(selectedRFIIds),
                  cc_contact: values.cc_contact,
                },
              })
              .then(() => rfiQuery.refetch().then(() => blockUI.unblockUI()))
              .catch(() => blockUI.unblockUI());
          }
          rfiRFI
            .mutateAsync({
              action: "update",
              data: {
                ids: Array.from(selectedRFIIds),
                update: values,
              },
              method: "PUT",
            })
            .then(() => rfiQuery.refetch().then(() => blockUI.unblockUI()))
            .catch(() => blockUI.unblockUI());
          // updateRFI.mutateAsync(values);
        }}
      />

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          deleteRFI.mutateAsync(activeRFI.id);
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete {activeRFI?.display}.
      </ConfirmationDialog>

      <CreateMissingBoxFoldersConfirmationDialog
        isOpen={createMissingBoxFolders}
        setIsOpen={setCreateMissingBoxFolders}
        rpc={rfiRFI}
      />
      <FixBoxFolderNamesConfirmationDialog
        isOpen={fixBoxFolderNamesDialog}
        setIsOpen={setFixBoxFolderNamesDialog}
        rpc={rfiRFI}
      />

      <ConfirmationDialog
        isOpen={renumberConfirmationIsOpen}
        onApprove={() => {
          setRenumberConfirmationIsOpen(false);
          blockUI.blockUI("Renumbering...");
          axiosAPI
            .post(`/rfis/${rfis[0].id}/actions/renumber_on_position/`, {}, {baseURL: ""})
            .then(() => {
              rfiQuery.refetch().then(() => blockUI.unblockUI());
            })
            .catch((error) => {
              enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
              blockUI.unblockUI();
            });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setRenumberConfirmationIsOpen(false);
        }}
      >
        Are you sure that you want to renumber all RFI's based on their current order? This cannot be undone.
      </ConfirmationDialog>

      {/* <Typography variant="h2">RFIQuery</Typography>
      <pre>{JSON.stringify(rfiQuery, null, 2)}</pre> */}

      {/* <Typography variant="h2">Props</Typography>
      <pre>{JSON.stringify(props), null, 2)}</pre> */}
    </>
  );
};
export default ProjectRFIs;
