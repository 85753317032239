import {Box, Button, Grid} from "@material-ui/core";
import moment from "moment";
import {ImportanceLabel} from "../../../components/ImportanceLabel";
import {LabelValue} from "../../../components/LabelValue";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";

const SafetyReviewQuestionResponsePaperItem = (props: {question: any; onClick: () => void}) => {
  const {onClick, question, ...rest} = props;
  return (
    <PaperItem>
      <PaperItem.Body onClick={onClick} style={{cursor: "pointer"}}>
        <Grid container spacing={1}>
          <Grid item xs={9}>
            <StatusLabel status={question.status_display} width={120} />
            <Box mr={1} component="span" />
            {moment(question.safety_audit_datetime).format("YYYY-DD-MM h:mm a")}
          </Grid>
          <Grid item xs={3}>
            <Box textAlign="right">
              <Button size="small" variant="contained" onClick={onClick} color="secondary">
                Respond
              </Button>
            </Box>
          </Grid>
          <Grid item xs={12} sm={8}>
            <strong>{question.question}</strong>
          </Grid>
          <Grid item xs={6} sm={2}>
            <LabelValue label="Due Date:" value={question.due_date} />
          </Grid>
          <Grid item xs={6} sm={2}>
            <LabelValue label="Severity:" value={<ImportanceLabel importance={question.severity_display} />} />
          </Grid>
          <Grid item xs={12}>
            <LabelValue label="Comment:" value={question.comment} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <LabelValue label="Created By:" value={question?.created_by?.full_name} />
          </Grid>
          <Grid item xs={6} sm={4}>
            <LabelValue
              label="Modified:"
              value={question?.due_date ? moment(question.due_date).format("YYYY-DD-MM") : ""}
            />
          </Grid>
        </Grid>
      </PaperItem.Body>
    </PaperItem>
  );
};

export {SafetyReviewQuestionResponsePaperItem};
