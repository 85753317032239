import {faCheck, faEnvelope, faTimes} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  PaperProps,
} from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LaunchIcon from "@material-ui/icons/Launch";
import {Skeleton} from "@material-ui/lab";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import Moment from "react-moment";
import {useSentinelDetailAPI, useSentinelListAPI} from "../hooks/useSentinelAPI";
import {makeNovoClasses} from "../theme";
import {colorError, colorSuccess} from "../theme/colors";
import {LabelValue} from "./LabelValue";
import {PaginationWithPageSize} from "./Pagination";
import PaperPanel from "./PaperPanel";
import {ReactTableMui} from "./ReactTableMui";

const MailLog = (props: {url: string; initialPageSize?: number; paperPanelProps?: PaperProps}) => {
  const {url, initialPageSize = 20, paperPanelProps = {}, ...rest} = props;
  const novoClasses = makeNovoClasses();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(initialPageSize);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [detailDialogIsOpen, setDetailDialogIsOpen] = React.useState(false);
  const [detailExpanded, setDetailExpanded] = React.useState(false);

  const {query: mailLogQuery} = useSentinelListAPI(`${url}?page_size=${pageSize}&page=${page}`, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });

  const {query: mailLogDetailQuery} = useSentinelDetailAPI(`${url}${selectedItem?.message_id}/`, {
    initialData: {},
    enabled: selectedItem?.message_id !== undefined,
  });

  return (
    <>
      <PaperPanel square variant="outlined" {...paperPanelProps}>
        <PaperPanel.Header isLoading={mailLogQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faEnvelope} /> Mail Log
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => mailLogQuery.refetch()}
                isFetching={mailLogQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}
            rightAlignColumns={["created"]}
            columns={[
              {
                Header: "Message Id",
                accessor: "message_id",
                Cell: ({value, row}) =>
                  value && moment().diff(moment(row.original.created), "days") < 30 ? (
                    <IconButton
                      size="small"
                      onClick={() => {
                        setSelectedItem(row.original);
                        setDetailDialogIsOpen(true);
                      }}
                    >
                      <LaunchIcon />
                    </IconButton>
                  ) : null,
              },
              {
                Header: "To",
                accessor: "to_email",
              },
              {
                Header: "From",
                accessor: "from_email",
              },
              {
                Header: "Action",
                accessor: "action_type",
              },
              {
                Header: "State",
                accessor: "state",
              },
              {
                Header: "Opened",
                accessor: "opened",
                Cell: ({value, row}) =>
                  value ? (
                    <FontAwesomeIcon icon={faCheck} color={colorSuccess} />
                  ) : (
                    <FontAwesomeIcon icon={faTimes} color={colorError} />
                  ),
              },
              {
                Header: "Created",
                accessor: "created",
                Cell: ({value, row}) => (
                  <>
                    <Moment calendar withTitle date={value} />
                  </>
                ),
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "created",
                  desc: true,
                },
              ],
            }}
            data={mailLogQuery.data.results}
            {...rest}
          />
          {/* {mailLogQuery.data.total_pages > 1 && ( */}
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={mailLogQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[20, 50, 100]}
            />
          </PaperPanel.Footer>
          {/* )} */}
          {/* <pre>{JSON.stringify(mailLogQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
      <Dialog
        open={detailDialogIsOpen}
        onClose={() => {
          setDetailDialogIsOpen(false);
        }}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle id="form-dialog-title">Mail Log Detail</DialogTitle>
        <DialogContent>
          {mailLogDetailQuery.isLoading ? (
            <>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </>
          ) : (
            <>
              <LabelValue label="Subject" value={mailLogDetailQuery.data.subject} />
              <LabelValue label="Sender" value={mailLogDetailQuery.data.sender} />
              <LabelValue label="Recipient" value={mailLogDetailQuery.data.email} />
              <LabelValue label="State" value={mailLogDetailQuery.data.state} />
              <LabelValue label="Opens" value={mailLogDetailQuery.data.opens} />
              <LabelValue label="Clicks" value={mailLogDetailQuery.data.clicks} />
              <Box mt={2} />
              <PaperPanel square variant="outlined" {...paperPanelProps}>
                <PaperPanel.Header minHeight={0}>
                  <PaperPanel.Header.Title>SMTP Events</PaperPanel.Header.Title>
                </PaperPanel.Header>
                <PaperPanel.Body>
                  <ReactTableMui
                    size="small"
                    className={classnames(
                      novoClasses.stripedTable,
                      novoClasses.smallTable,
                      novoClasses.boldHeaderTable
                    )}
                    rightAlignColumns={["ts"]}
                    columns={[
                      {
                        Header: "Type",
                        accessor: "type",
                      },
                      {
                        Header: "Event",
                        accessor: "diag",
                      },
                      {
                        Header: "Timestamp",
                        accessor: "ts",
                        Cell: ({value, row}) =>
                          moment.tz(moment.unix(value), moment.tz.guess()).format("YYYY-MM-DD hh:mm A z"),
                      },
                    ]}
                    initialState={{
                      sortBy: [
                        {
                          id: "created",
                          desc: true,
                        },
                      ],
                    }}
                    data={mailLogDetailQuery.data?.smtp_events || []}
                  />
                </PaperPanel.Body>
              </PaperPanel>
              <Box mt={2} />
              <PaperPanel square variant="outlined" {...paperPanelProps}>
                <PaperPanel.Header minHeight={0}>
                  <PaperPanel.Header.Title>Opens</PaperPanel.Header.Title>
                </PaperPanel.Header>
                <PaperPanel.Body>
                  <ReactTableMui
                    size="small"
                    className={classnames(
                      novoClasses.stripedTable,
                      novoClasses.smallTable,
                      novoClasses.boldHeaderTable
                    )}
                    rightAlignColumns={["ts"]}
                    columns={[
                      {
                        Header: "Ip",
                        accessor: "ip",
                      },
                      {
                        Header: "Location",
                        accessor: "location",
                      },
                      {
                        Header: "User Agent",
                        accessor: "ua",
                      },
                      {
                        Header: "Timestamp",
                        accessor: "ts",
                        Cell: ({value, row}) =>
                          moment.tz(moment.unix(value), moment.tz.guess()).format("YYYY-MM-DD hh:mm A z"),
                      },
                    ]}
                    initialState={{
                      sortBy: [
                        {
                          id: "created",
                          desc: true,
                        },
                      ],
                    }}
                    data={mailLogDetailQuery.data?.opens_detail || []}
                  />
                </PaperPanel.Body>
              </PaperPanel>
            </>
          )}
          <Box display="flex" justifyContent="flex-end">
            <IconButton size="small" onClick={() => setDetailExpanded((lastDetailExpanded) => !lastDetailExpanded)}>
              {detailExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
          <Collapse in={detailExpanded} timeout="auto" unmountOnExit>
            <pre>{JSON.stringify(mailLogDetailQuery, null, 2)}</pre>
          </Collapse>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailDialogIsOpen(false)}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MailLog;
