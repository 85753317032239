import {faPalette} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Breadcrumbs, Table, TableCell, TableRow, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import bonusAccountDistributionRequestStatuses from "../../components/forms/choices/bonusAccountDistributionRequestStatuses.json";
import corStatuses from "../../components/forms/choices/corStatuses.json";
import pccoStatuses from "../../components/forms/choices/pccoStatuses.json";
import pcoInternalStatuses from "../../components/forms/choices/pcoInternalStatuses.json";
import pcoStatuses from "../../components/forms/choices/pcoStatuses.json";
import rfiStatuses from "../../components/forms/choices/rfiStatuses.json";
import subcontractStatuses from "../../components/forms/choices/subcontractStatuses.json";
import submittalItemReviewStatuses from "../../components/forms/choices/submittalItemReviewStatuses.json";
import submittalItemStatuses from "../../components/forms/choices/submittalItemStatuses.json";
import submittalPackageStatuses from "../../components/forms/choices/submittalPackageStatuses.json";
import {StatusIcon} from "../../components/Icons";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import {StatusLabel} from "../../components/Status";

const StatusTable = ({statuses, type = undefined}) => {
  return (
    <>
      <Table size="small">
        {statuses.map((status) => (
          <TableRow>
            <TableCell>{status.label}</TableCell>
            <TableCell>
              <StatusLabel status={status.label} hint={type} />
            </TableCell>
            <TableCell>
              <StatusIcon status={status.value} type={type} showTooltip />
            </TableCell>
          </TableRow>
        ))}
      </Table>
    </>
  );
};

const StatusSink = (props) => {
  return (
    <>
      <Helmet title="Status Sink" />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Status Sink</Typography>
      </Breadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faPalette} /> Status Sink
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      <h1>Budget</h1>
      <StatusTable statuses={corStatuses} type="changeOrder" />
      <h1>COR</h1>
      <StatusTable statuses={corStatuses} type="changeOrder" />
      <h1>PCO</h1>
      <StatusTable statuses={pcoStatuses} type="changeOrder" />
      <h1>Internal PCO</h1>
      <StatusTable statuses={pcoInternalStatuses} type="changeOrder" />
      <h1>RFI</h1>
      <StatusTable statuses={rfiStatuses} type="rfi" />
      <h1>Submittal Package</h1>
      <StatusTable statuses={submittalPackageStatuses} type="submittal" />
      <h1>Submittal Item</h1>
      <StatusTable statuses={submittalItemStatuses} type="submittal" />
      <h1>Submittal Item Review</h1>
      <StatusTable statuses={submittalItemReviewStatuses} type="submittal" />
      <h1>Subcontract</h1>
      <StatusTable statuses={subcontractStatuses} />
      <h1>Bonus Account Distribution Request</h1>
      <StatusTable statuses={bonusAccountDistributionRequestStatuses} type="bonusAccountDistributionRequest" />
      <h1>Owner Change Order</h1>
      <StatusTable statuses={pccoStatuses} type="pcco" />
      {/* type?: "submittal" | "changeOrder" | "rfi"; */}
      {/* <pre>{JSON.stringify(rfiStatuses, null, 2)}</pre> */}
    </>
  );
};

export default StatusSink;
