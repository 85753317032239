import {makeStyles} from "@material-ui/core";
import PaperPanel from "../../components/PaperPanel";
import {VaccineStatusCheckForm} from "./VaccineStatusCheckForms";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    formPanel: {backgroundColor: "#fff9ec"},
  };
});

const VaccineStatusCheck = (props) => {
  const {wellnessCheckResult, onSubmitted} = props;
  const classes = useStyles();
  return (
    <>
      {wellnessCheckResult.check_covid19_vaccine_status_self_attest ? (
        <h1>Self-Certification of Vaccination Status</h1>
      ) : (
        <h1>Certification of Vaccination Status</h1>
      )}
      {/*
      <p>
        Under the Health Officer’s May 18, 2021 Order, all businesses and governmental entities in Santa Clara County
        are required to determine the COVID-19 vaccination status of their employees (as well as contractors,
        volunteers, and others who regularly work onsite). In light of this requirement, you must provide the
        information requested below.
      </p>
      <p>
        Please note that you are required to provide accurate information about your vaccination status in response to
        the questions below, or alternatively may decline to provide your vaccination status. If you decline to provide
        information about your vaccination status, we will be required to assume you are unvaccinated for purposes of
        rules or requirements in the workplace that are different for vaccinated or unvaccinated employees. For example,
        if requirements on face coverings allow fully vaccinated employees not to wear face coverings in certain
        settings, the information collected below will be used to determine whether you will be required to wear a face
        covering in those settings.
      </p>
      */}
      <p>This project requires a verification of all on-site personnel’s vaccination status.</p>
      <p>
        Please note that you are required to provide accurate information about your vaccination status in response to
        the questions below, or alternatively may decline to provide your vaccination status. If you decline to provide
        information about your vaccination status, we will be required to assume you are unvaccinated for purposes of
        rules or requirements in the workplace that are different for vaccinated or unvaccinated employees. For example,
        if requirements on face coverings allow fully vaccinated employees not to wear face coverings in certain
        settings, the information collected below will be used to determine whether you will be required to wear a face
        covering in those settings.
      </p>
      <p>
        <strong>Please select the statement below that accurately describes your vaccination status:</strong>
      </p>
      <PaperPanel elevation={3}>
        <PaperPanel.Body p={3} className={classes.formPanel}>
          <VaccineStatusCheckForm
            twilioContactUUID={wellnessCheckResult.twilio_contact.uuid}
            selfAttest={wellnessCheckResult.check_covid19_vaccine_status_self_attest}
            onSubmitted={onSubmitted}
            initialValues={{
              covid19_vaccine_status: wellnessCheckResult.twilio_contact.covid19_vaccine_status,
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <p>
        I understand that I am required to provide accurate information in response to the question above. I hereby
        affirm that I have accurately and truthfully answered the question above.
        {/* I also understand that if I stated
        that I am fully vaccinated, my employer may request documentation of my vaccination status (e.g., a copy of my
        vaccine card or other similar official document confirming vaccination status). */}
      </p>
    </>
  );
};

export default VaccineStatusCheck;
