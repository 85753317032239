import {faBlockBrickFire, faCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Button, Grid, Tooltip, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import accounting from "accounting";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {unslugify} from "../../../js/utils/string";
import {TextFieldMui} from "../../components/forms/Fields";
import BlockUI from "../../components/GlobalLoaders";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import Typography2 from "../../components/Typography2";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../hooks/useSentinelAPI";
import {colorError, colorSuccess, colorWarning} from "../../theme/colors";

const RequestRemoteAccess = (props) => {
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      securityGroupDetails: {},
    },
  });
  const pageData = pageDataQuery.data;

  const postCurrentPage = usePostCurrentPage();

  const blockUI = useBlockUI();

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Remote Access info..." />;
  }
  if (pageData.currentIPAddressIsPrivate) {
    return (
      <>
        <Helmet title="Request Remote Access" />
        <Breadcrumbs>
          <MuiNavLink color="inherit" to="/v2/dashboard/">
            Dashboard
          </MuiNavLink>
          <Typography color="textPrimary">Request Remote Access</Typography>
        </Breadcrumbs>
        <PageHeader mt={2}>
          <PageHeader.Left>
            <PageHeader.Title>
              <FontAwesomeIcon icon={faBlockBrickFire} /> Request Remote Access for {pageData.currentIPAddress}
            </PageHeader.Title>
          </PageHeader.Left>
        </PageHeader>
        <Alert severity="error">
          {pageData.currentIPAddress} is a private IP address. You cannot request remote access from a private IP
          address.
        </Alert>
      </>
    );
  }

  return (
    <>
      <Helmet title="Request Remote Access" />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Request Remote Access</Typography>
      </Breadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faBlockBrickFire} /> Request Remote Access for {pageData.currentIPAddress}
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faBlockBrickFire} /> Request Remote Access for {pageData.currentIPAddress}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {props.user.is_superuser && (
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button href="/v2/request-remote-access/admin/">
                  Manage Rules (Superusers)
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={pageDataQuery.isFetching}
                onClick={() => pageDataQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {Object.entries(pageData.securityGroupDetails).map(([securityGroupName, details]) => {
            return (
              <RequestRemoteAccessPaperItem
                key={securityGroupName}
                securityGroupName={securityGroupName}
                currentIPAddress={pageData.currentIPAddress}
                currentIPAddressHasAccess={(details as any).currentIPAddressHasAccess}
                isReadOnly={(details as any).currentIPAddressHasAccess}
                initialValues={{
                  securityGroupName: securityGroupName,
                }}
                onSubmit={(values) => {
                  blockUI.blockUI("Requesting Access...");
                  postCurrentPage.mutateAsync({action: "request_access", ...values}).then((values) => {
                    pageDataQuery.refetch().then(() => blockUI.unblockUI());
                  });
                }}
                {...(details as any)}
              />
            );
          })}
        </PaperPanel.Body>
      </PaperPanel>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

const RequestRemoteAccessPaperItem = (props) => {
  const {
    currentIPAddress,
    securityGroupName,
    currentIPAddressHasAccess,
    isReadOnly,
    currentRulesCount,
    currentIPCount,
    onSubmit,
    initialValues,
    ...rest
  } = props;
  let ruleCountColor = "inherit";
  if (currentRulesCount >= 150) {
    ruleCountColor = colorWarning;
  }
  if (currentRulesCount >= 180) {
    ruleCountColor = colorError;
  }
  return (
    <FinalForm onSubmit={onSubmit} initialValues={initialValues}>
      {({handleSubmit, form, submitting, pristine, values}) => {
        return (
          <form onSubmit={handleSubmit}>
            <PaperItem noHover mt={0.5}>
              <PaperItem.Left minWidth={30}>
                <Tooltip title={currentIPAddressHasAccess ? `Has Access` : `No Access`}>
                  <span>
                    <FontAwesomeIcon
                      icon={faCircle}
                      size="xl"
                      style={{color: currentIPAddressHasAccess ? colorSuccess : colorError}}
                    />
                  </span>
                </Tooltip>
              </PaperItem.Left>
              <PaperItem.Body>
                <Grid container spacing={2}>
                  <Grid item sm={4}>
                    <strong>{unslugify(securityGroupName)}</strong>
                  </Grid>

                  <Grid item sm={4}>
                    <Typography2 type="metadata">
                      <Box>
                        Rule Count:{" "}
                        <strong style={{color: ruleCountColor}}>{accounting.formatNumber(currentRulesCount)}</strong>
                      </Box>
                      <Box>IP Count: {accounting.formatNumber(currentIPCount)}</Box>
                    </Typography2>
                  </Grid>

                  <Grid item sm={4}>
                    <TextFieldMui
                      name="description"
                      label="Description"
                      required
                      disabled={isReadOnly}
                      helperText="home, trailer, office, etc."
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </PaperItem.Body>
              <PaperItem.Right minWidth={150}>
                <Button
                  // variant="outlined"
                  variant="contained"
                  color="primary"
                  size="small"
                  disabled={isReadOnly}
                  type="submit"
                  // onClick={() => {
                  //   onRequestAccess({
                  //     securityGroupName: securityGroupName,
                  //   });
                  // }}
                >
                  Request Access
                </Button>
              </PaperItem.Right>
            </PaperItem>
          </form>
        );
      }}
    </FinalForm>
  );
};

export default RequestRemoteAccess;
