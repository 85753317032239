import {Box, Typography} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import ObjectTable from "../../../components/ObjectTable";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {SubcontractorLienReleaseDetailForm} from "./SubcontractorLienReleaseForms";
import {colorError} from "../../../theme/colors";

export const SubcontractorLienReleaseDetails = (props) => {
  const {project, lienRelease, ...rest} = props;

  const novoClasses = makeNovoClasses();

  const {query: paymentsQuery} = useSentinelListAPI(
    `projects/${project?.id}/subcontractor-lien-releases/${lienRelease?.id}/payments/`,
    {
      initialData: [],
      enabled: Boolean(lienRelease?.id),
    }
  );
  const payments = paymentsQuery.data;

  const {query: transactionsQuery} = useSentinelListAPI(
    `projects/${project?.id}/subcontractor-lien-releases/${lienRelease?.id}/transactions/`,
    {
      initialData: [],
      enabled: Boolean(lienRelease?.id),
    }
  );
  const transactions = transactionsQuery.data;
  return (
    <>
      <Typography variant="h4">Transactions</Typography>
      {/* <pre>{JSON.stringify(transactions, null, 2)}</pre> */}
      <ReactTableMui
        size="small"
        hover
        showFooter={Boolean(payments.length > 1)}
        className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
        // containerComponent={Paper}
        rightAlignColumns={["amount"]}
        columns={[
          {
            Header: "Invoice",
            accessor: "invoice",
          },
          {
            Header: "Payment Id",
            accessor: "payment_id",
          },
          {
            Header: "Payment Type",
            accessor: "payment_type",
          },
          {
            Header: "Check #",
            accessor: "check_number",
          },
          {
            Header: "Reference Date",
            accessor: "reference_date",
          },
          {
            Header: "Accounting Date",
            accessor: "accounting_date",
          },
          {
            Header: "Transaction Date",
            accessor: "transaction_date",
          },
          {
            Header: "Batch Source",
            accessor: "batch_source",
          },
          {
            Header: "Transaction Type",
            accessor: "transaction_type",
          },
          {
            Header: "Amount",
            accessor: "amount",
            Cell: ({value, row}) => (value ? <Currency number={value} precision={2} /> : ""),
          },
        ]}
        initialState={
          {
            // sortBy: [
            //   {
            //     id: "date_stamp",
            //     desc: false,
            //   },
            // ],
          }
        }
        data={transactions}
      />
      <Box mb={2} />
      <Typography variant="h4">Payments</Typography>
      {/* <pre>{JSON.stringify(payments, null, 2)}</pre> */}
      <ReactTableMui
        size="small"
        hover
        showFooter={Boolean(payments.length > 1)}
        className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
        // containerComponent={Paper}
        rightAlignColumns={["amount_paid", "retainage_held", "retainage_paid", "date_stamp"]}
        columns={[
          {
            Header: "Invoice",
            accessor: "invoice",
          },
          {
            Header: "Dist Seq",
            accessor: "dist_seq",
          },
          {
            Header: "Check #",
            accessor: "check_number",
          },
          {
            Header: "Date",
            accessor: "date_stamp",
          },
          {
            Header: "Retainage Held",
            accessor: "retainage_held",
            Cell: ({value, row}) => (value ? <Currency number={value} precision={2} /> : ""),
          },
          {
            Header: "Retainage Paid",
            accessor: "retainage_paid",
            Cell: ({value, row}) => (value ? <Currency number={value} precision={2} /> : ""),
          },
          {
            Header: "Amount Paid",
            accessor: "amount_paid",
            Cell: ({value, row}) => (value ? <Currency number={value} precision={2} /> : ""),
            Footer: () => {
              const total = React.useMemo(() => payments.reduce((sum, row) => row.amount_paid + sum, 0), [payments]);
              return <Currency number={total} />;
            },
          },
        ]}
        initialState={
          {
            // sortBy: [
            //   {
            //     id: "date_stamp",
            //     desc: false,
            //   },
            // ],
          }
        }
        data={payments}
      />
      <Box mb={2} />
      <Typography variant="h4">Payment Distribution</Typography>
      <ObjectTable
        object={lienRelease?.timberline_payment_distribution}
        exclude={["commitment"]}
        currencyFields={[
          "amount",
          "tax",
          "tax_liability",
          "discount_offered",
          "retainage",
          "amount_paid",
          "tax_paid",
          "tax_liability_accounted",
          "discount_taken",
          "discount_lost",
          "retainage_held",
          "retainage_paid",
          "misc_deduction2_deducted",
          "unit_cost",
          "pm_markup_amount",
          "pmt_amount",
          "pmt_retainage_to_pay",
          "pmt_disc_to_apply",
          "pmt_retainage_to_hold",
        ]}
        size="small"
      />
      <Box mb={2} />
      <Typography variant="h4">Invoice</Typography>
      <ObjectTable
        object={lienRelease?.invoice}
        currencyFields={["amount", "tax", "retainage", "amount_paid", "retainage_held", "retainage_paid"]}
        size="small"
      />
      <Box mb={2} />
      <Typography variant="h4">Commitment</Typography>{" "}
      {lienRelease?.timberline_payment_distribution?.commitment ? (
        <ObjectTable
          object={lienRelease?.timberline_payment_distribution?.commitment}
          currencyFields={[
            "committed",
            "balance",
            "amount",
            "approved_commitment_co_amount",
            "amount_invoiced",
            "amount_being_retained",
            "amount_paid",
            "last_check",
            "last_check",
          ]}
          size="small"
        />
      ) : (
        <Box color={colorError}>
          <strong>Commitment Missing</strong>
        </Box>
      )}
    </>
  );
};

const SubcontractorLienReleaseDetail = (props) => {
  const {project, user, ...rest} = props;
  const {subcontractorLienReleaseId} = useParams();
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();

  const {query: lienReleaseQuery, update: updateLienRelease} = useSentinelDetailAPI(
    `projects/${project?.id}/subcontractor-lien-releases/${subcontractorLienReleaseId}/`,
    {
      initialData: {},
    }
  );

  const lienRelease = lienReleaseQuery.data;
  if (!lienReleaseQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Subcontractor Lien Release info..." />;
  }

  return (
    <>
      <Helmet title={`Subcontractor Lien Releases`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/subcontractor-lien-releases/`}>
          Subcontractor Lien Release
        </MuiNavLink>
        <Typography color="textPrimary">{lienRelease.timberline_payment_distribution.vendor_name}</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={lienReleaseQuery.isFetching}>
          <PaperPanel.Header.Title>Subcontractor Lien Release</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.DocuSignButton docuSignObject={lienRelease} />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  lienReleaseQuery.refetch();
                }}
                isFetching={lienReleaseQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Body p={1}>
          <SubcontractorLienReleaseDetailForm
            projectId={project.id}
            initialValues={lienRelease}
            onSubmit={(values) => {
              blockUI.blockUI("Saving...");
              updateLienRelease.mutateAsync(values).then(() => {
                lienReleaseQuery.refetch().then(() => {
                  blockUI.unblockUI();
                });
              });
            }}
          />
          <Box mb={2} />
          <SubcontractorLienReleaseDetails project={project} lienRelease={lienRelease} />
        </PaperPanel.Body>
      </PaperPanel>
      {/* <pre>{JSON.stringify(lienRelease, null, 2)}</pre> */}
    </>
  );
};
export default SubcontractorLienReleaseDetail;
