import {ReactGrid as _ReactGrid, ReactGridProps} from "@silevis/reactgrid";
import React from "react";
import "./style.scss";

const ReactGrid = (props: ReactGridProps & {onChange?: (obj) => void}) => {
  const {columns: _columns, rows, onChange, ...rest} = props;
  const [columns, setColumns] = React.useState(_columns);
  const [reactGridContainerWidth, setReactGridContainerWidth] = React.useState(null);

  // Dynamically resize columns with width: 0
  const reactGridContainer = React.useCallback((node) => {
    const handleWindowResize = () => {
      if (node !== null) {
        const containerWidth = node.getBoundingClientRect().width;
        setReactGridContainerWidth(containerWidth);
        const fullColumnCount = _columns.filter((col) => col.width === 0).length;
        if (fullColumnCount > 0) {
          const otherColumnLength = _columns.reduce((acc, col) => col.width + acc, 0);
          const fullColumnWidth = (containerWidth - otherColumnLength) / fullColumnCount;
          const columns_ = _columns.map((col) => {
            let _col = {...col};
            if (col["width"] === 0) {
              _col["width"] = fullColumnWidth;
            }
            return _col;
          });
          setColumns(columns_);
        }
      }
    };
    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    // return () => {
    //   window.removeEventListener("resize", handleWindowResize);
    // };
  }, []);

  const handleColumnResize = (ci, width: number) => {
    setColumns((prevColumns) => {
      const columnIndex = prevColumns.findIndex((el) => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      const updatedColumn = {...resizedColumn, width};
      const newColumns = [...prevColumns];
      newColumns[columnIndex] = updatedColumn;
      return newColumns;
    });
  };

  return (
    <div ref={reactGridContainer}>
      <_ReactGrid
        columns={columns}
        rows={rows}
        onCellsChanged={(changes) => {
          if (!onChange) {
            return;
          }
          changes.map((change) => {
            let obj = {
              id: change.rowId,
            };
            let value = null;
            if (change.newCell?.type === "number") {
              value = change.newCell?.value;
              // } else if (change.newCell?.type === "dropdown") {
              //   console.log(change);
            } else {
              value = change.newCell?.text;
            }
            obj[change.columnId] = value;
            onChange(obj);
          });
        }}
        onColumnResized={handleColumnResize}
        {...rest}
      />
      {/* <div>{reactGridContainerWidth}</div> */}
    </div>
  );
};

export default ReactGrid;
