import {Box, Grid, Link} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import EmailIcon from "@material-ui/icons/Email";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {DocuSignStatusIcon} from "../../../components/DocuSign";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";

const MasterSubcontractAgreementPaperItem = (props) => {
  // useWhyDidYouUpdate("RFIPaperItem", props);
  const {msa, onEdit, onDelete} = props;
  return (
    <PaperItem>
      <PaperItem.Body>
        {/* <pre>{JSON.stringify(rfi, null, 2)}</pre> */}
        <Grid container spacing={0}>
          {/* <Grid item xs={10} sm={4}> */}
          <Grid item xs={8}>
            <StatusLabel status={msa.status_display} />{" "}
            <MuiNavLink to={msa.url} underline="always">
              {msa.display}
            </MuiNavLink>
          </Grid>
          <Grid item xs={1}>
            {msa.current_docusign_envelope_detail_url && (
              <Link href={msa.current_docusign_envelope_detail_url}>
                <DocuSignStatusIcon status={msa.docusign_envelope_status} />
              </Link>
            )}
          </Grid>
          <Grid item xs={3}>
            <Box>
              <Link href={msa.to_contact?.url} underline="always">
                <Typography2 type="metadata">{msa.to_contact?.full_name}</Typography2>
              </Link>
            </Box>
          </Grid>
        </Grid>
        {/* <pre>{JSON.stringify(msa, null, 2)}</pre> */}
      </PaperItem.Body>
      <PaperItem.Right minWidth={75}>
        <Box textAlign="right"></Box>
      </PaperItem.Right>
      <PaperItem.RightHover>
        {/* <PaperItem.RightHover.ItemNumber number={msa.number_display} /> */}
        {/* <PaperItem.RightHover.IconButton icon={LinkIcon} title="Link" component={MuiNavLink} href={msa.url} />*/}
        <PaperItem.RightHover.IconButton
          icon={EmailIcon}
          title="View DS Envelopes"
          href={msa.docusign_envelope_list_url}
        />
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEdit} disabled={msa.is_closed} />
        <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onDelete} disabled={msa.is_closed} />
        <BoxFilesIconButtonLink
          app="accounting_tools"
          model="mastersubcontractagreement"
          objectId={msa.id}
          boxFolderId={msa.box_folder_id}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
export default MasterSubcontractAgreementPaperItem;
