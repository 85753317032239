import {Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../js/components/FinalFormEnhancers";
import {ProjectContactAutoselectMui, TinyMCEMui} from "./forms/Fields";
import FormActions from "./forms/FormActions";

export const SendEmailDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  title?: string;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, handleClose, title = "Send Email", ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">{title}</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ProjectContactAutoselectMui
                        projectId={projectId}
                        multiple
                        name="to_contacts"
                        label="To"
                        required
                        disableClearable={false}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <ProjectContactAutoselectMui projectId={projectId} multiple name="cc_contacts" label="CC" />
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui name="comments" label="Comments" />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <Button startIcon={<SendIcon />} variant="contained" color="primary" type="submit">
                  Send
                </Button>
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
