import {Box, Dialog, DialogActions, DialogContent, DialogTitle, FormHelperText, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router-dom";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import InnerFooter from "../../../components/InnerFooter";
import {
  CurrencyFieldMui,
  DatePickerMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import usePermissions from "../../../hooks/usePermissions";

export const PrimeContractUpdateDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isNew?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, handleClose, isNew = false, isReadOnly = false, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                {isNew ? "Create Prime Contract" : "Update Prime Contract"}
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui type="number" name="number" label="Number" required disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextFieldMui name="description" label="Description" required autoFocus disabled={isReadOnly} />
                    </Grid>

                    <Grid item xs={12} sm={4}>
                      <DatePickerMui label="Issued Date" name="issued_date" required disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerMui label="Required Date" name="required_date" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <DatePickerMui
                        label="Executed Date"
                        name="executed_date"
                        helperText={`Date the contract was signed`}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ProjectCompanyAutoselectMui
                            projectId={projectId}
                            name="from_company"
                            label="From Company"
                            required
                            disabled={isReadOnly}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProjectContactAutoselectMui
                            projectId={projectId}
                            name="from_signed_by"
                            label="From Signed By"
                            disabled={isReadOnly}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ProjectCompanyAutoselectMui
                            projectId={projectId}
                            name="to_company"
                            label="To Company"
                            required
                            disabled={isReadOnly}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProjectContactAutoselectMui
                            projectId={projectId}
                            name="to_signed_by"
                            label="To Signed By"
                            disabled={isReadOnly}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    {isNew && (
                      <Grid item xs={12} sm={12}>
                        <CurrencyFieldMui name="value" label="Value" disabled={isReadOnly} />
                      </Grid>
                    )}
                  </Grid>
                </FinalFormKeyboardShortcuts>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                {isNew && (
                  <FormActions.SaveAndEditButton
                    disabled={submitting || pristine}
                    onClick={() => form.change("editAfterSubmit", true)}
                  />
                )}
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const PrimeContractDetailForm = (props) => {
  const {projectId, isReadOnly, isNew, onSubmit, ...rest} = props;
  const {pathname} = useLocation();
  const permissions = usePermissions();
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, ...rest} = props;

        return (
          <>
            <BeforeUnload block={!pristine} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <TextFieldMui type="number" name="number" label="Number" required disabled={isReadOnly} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldMui name="description" label="Description" required autoFocus disabled={isReadOnly} />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <DatePickerMui label="Issued Date" name="issued_date" required disabled={isReadOnly} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerMui label="Required Date" name="required_date" disabled={isReadOnly} />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerMui
                      label="Executed Date"
                      name="executed_date"
                      helperText={`Date the contract was signed`}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ProjectCompanyAutoselectMui
                          projectId={projectId}
                          name="from_company"
                          label="From Company"
                          required
                          disabled={isReadOnly}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui
                          projectId={projectId}
                          name="from_signed_by"
                          label="From Signed By"
                          disabled={isReadOnly}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ProjectCompanyAutoselectMui
                          projectId={projectId}
                          name="to_company"
                          label="To Company"
                          required
                          disabled={isReadOnly}
                          // fieldProps={{
                          //   parse: identity,
                          //   validate: validateSubcontractToCompany,
                          // }}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui
                          projectId={projectId}
                          name="to_signed_by"
                          label="To Signed By"
                          disabled={isReadOnly}
                        />
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <TinyMCEMui name="scope_of_work" label="Scope Of Work" disabled={isReadOnly} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TinyMCEMui name="notes" label="Notes" />
                    <FormHelperText>For internal use. Does not show up on subcontract.</FormHelperText>
                  </Grid>
                </Grid>
                <InnerFooter>
                  <Box display="flex">
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    <Box ml={1} />
                    <FormActions.DeleteButton
                      href={`/v2/projects/${projectId}/delete/contracts/primecontract/${values.id}/?next=/v2/projects/${projectId}/contracts#tab=primeContracts/&cancel=${pathname}`}
                      disabled={isReadOnly || !permissions.is_in_accounting_group}
                    />
                  </Box>
                </InnerFooter>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const PrimeContractItemDialog = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isNew?: boolean;
  isReadOnly?: boolean;
  onDelete?: () => void;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, handleClose, isNew = false, isReadOnly = false, onDelete, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                {isNew ? "Create Prime Contract Item" : "Update Prime Contract Item"}
              </DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item sm={6} xs={12}>
                      <TextFieldMui name="description" label="Description" disabled={isReadOnly} />
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      <CurrencyFieldMui name="value" label="Value" disabled={isReadOnly} />
                    </Grid>
                    <Grid item xs={12}>
                      <TinyMCEMui name="notes" label="Notes" disabled={isReadOnly} />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine || isReadOnly} />
                {onDelete && <FormActions.DeleteButton disabled={isReadOnly} onClick={onDelete} />}
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
