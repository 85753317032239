import {AppBar, Box, Link, Tab, Tabs, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TabCountChip} from "../../../components/Tabs";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";
import {LegacyUILink} from "../../../components/Links";
import BlockUI from "../../../components/GlobalLoaders";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";

const UnlinkedObjects = (props) => {
  const {...rest} = props;
  const [selectedTab, setSelectedTab] = useQueryState("tab", "subcontracts");
  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      subcontracts: [],
      pos: [],
      sccos: [],
      poAmendments: [],
    },
  });
  const pageData = pageDataQuery.data;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Data..." />;
  }

  return (
    <>
      <Helmet title={`Unlinked Objects`} />
      <Breadcrumbs>
        <Link href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </Link>
        <Typography color="textSecondary">Accounting Tools</Typography>
        <Typography color="textPrimary">Unlinked Objects</Typography>
      </Breadcrumbs>
      <TabContext value={selectedTab}>
        <AppBar position="static" color="default">
          <Tabs
            value={selectedTab}
            onChange={(event, newValue) => {
              setSelectedTab(newValue);
            }}
            variant="scrollable"
            scrollButtons="auto"
          >
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  Subcontracts
                  <TabCountChip isLoading={!pageDataQuery.isFetchedAfterMount} count={pageData.subcontracts?.length} />
                </Box>
              }
              value="subcontracts"
              className={novoClasses.smallTab}
            />

            <Tab
              label={
                <Box display="flex" alignItems="center">
                  Purchase Orders
                  <TabCountChip isLoading={!pageDataQuery.isFetchedAfterMount} count={pageData.pos?.length} />
                </Box>
              }
              value="pos"
              className={novoClasses.smallTab}
            />
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  SCCOS
                  <TabCountChip isLoading={!pageDataQuery.isFetchedAfterMount} count={pageData.sccos?.length} />
                </Box>
              }
              value="sccos"
              className={novoClasses.smallTab}
            />
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  PO Amendments
                  <TabCountChip isLoading={!pageDataQuery.isFetchedAfterMount} count={pageData.poAmendments?.length} />
                </Box>
              }
              value="poAmendments"
              className={novoClasses.smallTab}
            />
          </Tabs>
        </AppBar>
        <Box mb={2} />
        <TabPanel value="subcontracts">
          <PaperPanel.TabHeader isLoading={pageDataQuery.isFetching}>
            <PaperPanel.Header.Title>Unlinked Subcontracts</PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button
                  startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                  onClick={() => {
                    saveExcel(
                      pageData.subcontracts.map((sub) => {
                        return {
                          Project: sub.project,
                          Contract: sub.contract,
                          Budget: sub.budget,
                          Submitted: {v: sub.submitted_date, t: "d", z: "yyyy-mm-dd"},
                          Value: {v: sub.original_value, t: "n", z: "$#,##0.00"},
                        };
                      }),
                      "Unlinked Subcontracts",
                      [{wch: 60}, {wch: 70}, {wch: 10}, {wch: 10}, {wch: 15}]
                    );
                  }}
                  disabled={pageDataQuery.isFetching || pageData.subcontracts.length < 1}
                >
                  Get Excel
                </PaperPanel.Header.Button>
                <Box mr={-1} ml={1}>
                  <PaperPanel.Header.RefreshButton
                    isFetching={pageDataQuery.isFetching}
                    onClick={() => pageDataQuery.refetch()}
                  />
                </Box>
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.TabHeader>
          <Box mx={-3} mt={-2} mb={-3}>
            <ReactTableMui
              size="small"
              hover
              className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
              rightAlignColumns={["original_value"]}
              columns={[
                {
                  Header: "Project",
                  accessor: "project",
                  Cell: ({row, value}) => (
                    <Link href={row.original.project_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },
                {
                  Header: "Contract",
                  accessor: "contract",
                  Cell: ({row, value}) => (
                    <Link href={row.original.contract_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },
                {Header: "Budget", accessor: "budget"},
                {Header: "Submitted", accessor: "submitted_date"},
                {Header: "Value", accessor: "original_value", Cell: ({row, value}) => <Currency number={value} />},
              ]}
              initialState={{
                sortBy: [
                  {
                    id: "submitted_date",
                    asc: true,
                  },
                ],
              }}
              data={pageData.subcontracts}
            />
          </Box>
        </TabPanel>
        <TabPanel value="pos">
          <PaperPanel.TabHeader isLoading={pageDataQuery.isFetching}>
            <PaperPanel.Header.Title>Unlinked Purchase Orders</PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button
                  startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                  onClick={() => {
                    saveExcel(
                      pageData.pos.map((po) => {
                        return {
                          Project: po.project,
                          Contract: po.po,
                          Budget: po.budget,
                          Submitted: {v: po.submitted_date, t: "d", z: "yyyy-mm-dd"},
                          Value: {v: po.total_price, t: "n", z: "$#,##0.00"},
                        };
                      }),
                      "Unlinked Purchase Orders",
                      [{wch: 60}, {wch: 70}, {wch: 10}, {wch: 10}, {wch: 15}]
                    );
                  }}
                  disabled={pageDataQuery.isFetching || pageData.pos.length < 1}
                >
                  Get Excel
                </PaperPanel.Header.Button>
                <Box mr={-1} ml={1}>
                  <PaperPanel.Header.RefreshButton
                    isFetching={pageDataQuery.isFetching}
                    onClick={() => pageDataQuery.refetch()}
                  />
                </Box>
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.TabHeader>
          <Box mx={-3} mt={-2} mb={-3}>
            <ReactTableMui
              size="small"
              hover
              className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
              rightAlignColumns={["total_price"]}
              columns={[
                {
                  Header: "Project",
                  accessor: "project",
                  Cell: ({row, value}) => (
                    <Link href={row.original.project_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },
                {
                  Header: "PO",
                  accessor: "po",
                  Cell: ({row, value}) => (
                    <Link href={row.original.po_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },
                {Header: "Budget", accessor: "budget"},
                {Header: "Submitted", accessor: "submitted_date"},
                {Header: "Value", accessor: "total_price", Cell: ({row, value}) => <Currency number={value} />},
              ]}
              initialState={{
                sortBy: [
                  {
                    id: "submitted_date",
                    asc: true,
                  },
                ],
              }}
              data={pageData.pos}
            />
          </Box>
        </TabPanel>
        <TabPanel value="sccos">
          <PaperPanel.TabHeader isLoading={pageDataQuery.isFetching}>
            <PaperPanel.Header.Title>Unlinked SCCOs</PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button
                  startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                  onClick={() => {
                    saveExcel(
                      pageData.sccos.map((scco) => {
                        return {
                          Project: scco.project,
                          Contract: scco.contract,
                          Budget: scco.budget,
                          SCCO: scco.scco,
                          Submitted: {v: scco.submitted_date, t: "d", z: "yyyy-mm-dd"},
                          Cost: {v: scco.cost, t: "n", z: "$#,##0.00"},
                        };
                      }),
                      "Unlinked SCCOS",
                      [{wch: 60}, {wch: 70}, {wch: 10}, {wch: 60}, {wch: 10}, {wch: 15}]
                    );
                  }}
                  disabled={pageDataQuery.isFetching || pageData.sccos.length < 1}
                >
                  Get Excel
                </PaperPanel.Header.Button>
                <Box mr={-1} ml={1}>
                  <PaperPanel.Header.RefreshButton
                    isFetching={pageDataQuery.isFetching}
                    onClick={() => pageDataQuery.refetch()}
                  />
                </Box>
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.TabHeader>
          <Box mx={-3} mt={-2} mb={-3}>
            <ReactTableMui
              size="small"
              hover
              className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
              rightAlignColumns={["cost"]}
              columns={[
                {
                  Header: "Project",
                  accessor: "project",
                  Cell: ({row, value}) => (
                    <Link href={row.original.project_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },
                {
                  Header: "Contract",
                  accessor: "contract",
                  Cell: ({row, value}) => (
                    <Link href={row.original.scco_contract_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },
                {Header: "Budget", accessor: "budget"},
                {
                  Header: "SCCO",
                  accessor: "scco",
                  Cell: ({row, value}) => (
                    <Link href={row.original.scco_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },

                {Header: "Submitted", accessor: "submitted_date"},
                {Header: "Cost", accessor: "cost", Cell: ({row, value}) => <Currency number={value} />},
              ]}
              initialState={{
                sortBy: [
                  {
                    id: "submitted_date",
                    asc: true,
                  },
                ],
              }}
              data={pageData.sccos}
            />
          </Box>
        </TabPanel>
        <TabPanel value="poAmendments">
          <PaperPanel.TabHeader isLoading={pageDataQuery.isFetching}>
            <PaperPanel.Header.Title>Unlinked PO Amendments</PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button
                  startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                  onClick={() => {
                    saveExcel(
                      pageData.poAmendments.map((poAmendment) => {
                        return {
                          Project: poAmendment.project,
                          "PO Amendment": poAmendment.po_amendment,
                          Budget: poAmendment.budget,
                          PO: poAmendment.po,
                          Submitted: {v: poAmendment.submitted_date, t: "d", z: "yyyy-mm-dd"},
                          Cost: {v: poAmendment.total_price, t: "n", z: "$#,##0.00"},
                        };
                      }),
                      "Unlinked PO Amendments",
                      [{wch: 60}, {wch: 70}, {wch: 10}, {wch: 60}, {wch: 10}, {wch: 15}]
                    );
                  }}
                  disabled={pageDataQuery.isFetching || pageData.poAmendments.length < 1}
                >
                  Get Excel
                </PaperPanel.Header.Button>
                <Box mr={-1} ml={1}>
                  <PaperPanel.Header.RefreshButton
                    isFetching={pageDataQuery.isFetching}
                    onClick={() => pageDataQuery.refetch()}
                  />
                </Box>
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.TabHeader>
          <Box mx={-3} mt={-2} mb={-3}>
            <ReactTableMui
              size="small"
              hover
              className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
              rightAlignColumns={["total_price"]}
              columns={[
                {
                  Header: "Project",
                  accessor: "project",
                  Cell: ({row, value}) => (
                    <Link href={row.original.project_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },
                {
                  Header: "PO Amendment",
                  accessor: "po_amendment",
                  Cell: ({row, value}) => (
                    <Link href={row.original.po_amendment_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },
                {Header: "Budget", accessor: "budget"},
                {
                  Header: "PO",
                  accessor: "po",
                  Cell: ({row, value}) => (
                    <Link href={row.original.po_url} underline="always" target="_blank">
                      {value}
                    </Link>
                  ),
                },

                {Header: "Submitted", accessor: "submitted_date"},
                {Header: "Total Price", accessor: "total_price", Cell: ({row, value}) => <Currency number={value} />},
              ]}
              initialState={{
                sortBy: [
                  {
                    id: "submitted_date",
                    asc: true,
                  },
                ],
              }}
              data={pageData.poAmendments}
            />
          </Box>
        </TabPanel>
      </TabContext>
      <Box mt={2} />
      <LegacyUILink href="/accounting-tools/unlinked" />
    </>
  );
};

export default UnlinkedObjects;
