import {Chip, IconButton, InputAdornment, TextField, Tooltip} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {TextField as FinalTextField} from "mui-rff";
import React from "react";
import {Field} from "react-final-form";
import InputMask from "react-input-mask";
import {identity} from "../../../../js/components/FinalFormEnhancers";
import TinyMCEInput from "../../../../js/components/TinyMCEInput";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMicrochipAi} from "@fortawesome/pro-solid-svg-icons";

const _FinalTextField = (props) => {
  const {name, label, validateFields = [], fieldProps, ...rest} = props;
  return (
    <FinalTextField
      label={label}
      name={name}
      variant="outlined"
      size="small"
      fieldProps={{
        parse: identity,
        validate: (value) => {
          if (props.required) {
            return value ? undefined : "Required";
          }
        },
        validateFields: validateFields,
        ...fieldProps,
      }}
      {...rest}
    />
  );
};

export const TextFieldMui = (props) => {
  return <_FinalTextField {...props} />;
};

export const AItextFieldMui = (props) => {
  const {tooltipTitle = "", onClickAIButton, disabled: disabled, ...rest} = props;
  const [internallyDisabled, setInternallyDisabled] = React.useState(false);
  const [beatFade, setBeatFade] = React.useState(false);
  return (
    <TextFieldMui
      disabled={disabled || internallyDisabled}
      {...rest}
      InputProps={{
        endAdornment: (
          <Tooltip title={tooltipTitle}>
            <InputAdornment position="end">
              <IconButton
                size="small"
                onClick={() => {
                  setInternallyDisabled(true);
                  setBeatFade(true);
                  onClickAIButton()
                    .then(() => {
                      setInternallyDisabled(false);
                      setBeatFade(false);
                    })
                    .catch(() => {
                      setInternallyDisabled(false);
                      setBeatFade(false);
                    });
                }}
                disabled={disabled || internallyDisabled}
              >
                <FontAwesomeIcon icon={faMicrochipAi} style={{height: 22.28, width: 22.28}} beatFade={beatFade} />
              </IconButton>
            </InputAdornment>
          </Tooltip>
        ),
      }}
    />
  );
};

export const MaskedInputMui = (props: {name: string; mask: string; maskChar?: string; [rest: string]: any}) => {
  const {name, mask, maskChar = "_", ...rest} = props;
  return (
    <Field name={name} type="text" parse={identity}>
      {({input, meta}) => {
        return (
          <InputMask mask={mask} maskChar={maskChar} variant="outlined" {...rest} {...input}>
            {(inputProps) => {
              // this is double wrapped in a Field component.  Not ideal but seems to work.
              return <TextFieldMui {...inputProps} disabled={props.disabled} />;
            }}
          </InputMask>
        );
      }}
    </Field>
  );
};

export const TinyMCEMui = (props: {name: string; label: string; disabled?: boolean; [rest: string]: any}) => {
  return (
    <Field parse={identity} {...props}>
      {/* <Field {...props}> */}
      {(props) => {
        const {input, label, ...rest} = props;

        return (
          <>
            <label>{label}</label>
            <TinyMCEInput
              editorConfig={{
                content_css: "/static/css/tinymceMUI.css",
              }}
              {...rest}
              // initialValue={input.value}
              // @ts-ignore
              value={input.value}
              onEditorChange={(value) => {
                input.onChange(value);
              }}
              // onInit={(event, editor) => console.log(editor.ui.registry.getAll())}
            />
          </>
        );
      }}
    </Field>
  );
};

export const CSVTagsMui = (props: {
  name: string;
  label: string;
  placeholder?: string;
  helperText?: string;
  options?: string[];
  joinChar?: string;
  disabled?: boolean;
  required?: boolean;
  // [rest: string]: any;
}) => {
  const {
    name,
    label,
    placeholder,
    helperText,
    joinChar = ", ",
    options = [],
    disabled,
    required = false,
    ...rest
  } = props;

  return (
    <Field name={name} parse={identity}>
      {(props) => {
        const [value, setValue] = React.useState(props.input.value ? props.input.value.split(",") : []);
        const [inputValue, setInputValue] = React.useState("");
        React.useEffect(() => {
          props.input.onChange(value.map((item) => item.trim()).join(", "));
        }, [value]);

        return (
          <>
            {/* <pre>{JSON.stringify(value, null, 2)}</pre>
            <pre>{JSON.stringify(inputValue, null, 2)}</pre>
            <pre>{JSON.stringify(props.input.value, null, 2)}</pre> */}
            <Autocomplete
              multiple
              freeSolo
              size="small"
              options={options}
              value={value}
              inputValue={inputValue}
              disabled={disabled}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              onInputChange={(event, newInputValue) => {
                const options = newInputValue.split(",");

                if (options.length > 1) {
                  setValue(
                    value
                      .concat(options)
                      .map((x) => x.trim())
                      .filter((x) => x)
                  );
                } else {
                  setInputValue(newInputValue);
                }
              }}
              onBlur={(event) => {
                if (event.target.value) {
                  setValue(
                    value
                      .concat(event.target.value)
                      .map((x) => x.trim())
                      .filter((x) => x)
                  );
                }
              }}
              renderInput={(params) => {
                return (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    label={label}
                    placeholder={placeholder}
                    helperText={helperText}
                    required={required}
                  />
                );
              }}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => {
                  return (
                    <Chip
                      size="small"
                      variant="outlined"
                      // color="secondary"
                      label={<>{option}</>}
                      {...getTagProps({index})}
                    />
                  );
                })
              }
              {...rest}
            />
          </>
        );
      }}
    </Field>
  );
};
