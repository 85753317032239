import React from "react";

const newlineRegex = /(\r\n|\r|\n)/g;

export default (str) => {
  if (typeof str === "number") {
    return str;
  } else if (typeof str !== "string") {
    return "";
  }

  return str.split(newlineRegex).map((line, index) => {
    if (line.match(newlineRegex)) {
      return React.createElement("br", {key: index});
    }
    return line;
  });
};
