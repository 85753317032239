import {Box, Link, Tooltip, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

const ProjectHighwireContractors = (props) => {
  const {project, ...rest} = props;
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(500);
  const novoClasses = makeNovoClasses();

  const {query: highwireContractorQuery} = useSentinelListAPI(
    `/highwire/contractors/?ordering=company__name&page_size=${pageSize}&page=${page}&has_sentinel_company=true&project_id=${project.id}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const highwireContractors = highwireContractorQuery.data.results;

  return (
    <>
      <Helmet title="Highwire Contractors" />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Highwire Contractors</Typography>
      </ProjectBreadcrumbs>
      <Box mt={2} />
      {/* <pre>{JSON.stringify(highwireContractorQuery, null, 2)}</pre> */}
      <PaperPanel>
        <PaperPanel.Header isLoading={highwireContractorQuery.isFetching}>
          <PaperPanel.Header.Title>Highwire Contractors</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  highwireContractorQuery.refetch();
                  highwireContractorQuery.refetch();
                }}
                isFetching={highwireContractorQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                Header: "Sentinel Company",
                accessor: "sentinel_company.name",
                sortType: "basic",
                // disableSortBy: true,
                Cell: ({value, row}) => (
                  <>
                    <Link underline="always" target="_blank" href={row.original.sentinel_company?.url}>
                      {value}
                    </Link>
                  </>
                ),
              },
              {
                Header: "Safety Score",
                accessor: "safety_score",
                Cell: ({value, row}) => {
                  if (!value) return null;
                  return (
                    <>
                      <Tooltip title="Out of 100">
                        <span>{accounting.format(value, 2)}</span>
                      </Tooltip>
                    </>
                  );
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "sentinel_company.name",
                  desc: false,
                },
              ],
            }}
            data={highwireContractors}
          />
          {/* <pre>{JSON.stringify(csiCodesNovo, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default ProjectHighwireContractors;
