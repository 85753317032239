import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@material-ui/core";

import pluralize from "pluralize";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {Currency} from "../../../components/Accounting";
import {TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";

export const NamePCCOForm = (props) => {
  const {onSubmit, isOpen, handleClose, counts, ...rest} = props;
  const {selectedApprovedAmount, selectedCORCount} = counts;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Create OCO</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DialogContentText>
                        You have selected {pluralize(`${selectedCORCount} COR`, selectedCORCount)} with an approved
                        total of <Currency number={selectedApprovedAmount} /> to create a new OCO.
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="name" label="New OCO Name" autoFocus required placeholder="New OCO Name" />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting} label="Create" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
