import {Box, Grid} from "@material-ui/core";
import LabeledGroup from "../../../../js/components/LabeledGroup";
import {CurrencyItem} from "./BudgetPaperItemGrid";

const SCOItemPaperItemGrid = (props) => {
  const {item, onEditCost, ...rest} = props;

  return (
    <Grid container>
      <Grid item xs={9}>
        <Box mr={1}>
          <LabeledGroup label="Budget" fontSize={11}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <CurrencyItem amount={item.budget_approved_amount} tooltip="Budget Approved" />
              </Grid>
              <Grid item xs={12} sm={6}>
                <CurrencyItem amount={item.budget_cost} tooltip="Budget Cost" />
              </Grid>
            </Grid>
          </LabeledGroup>
        </Box>
      </Grid>
      <Grid item xs={3}>
        <LabeledGroup label="Cost" fontSize={11} onClick={onEditCost}>
          <CurrencyItem amount={item.cost} tooltip="Cost" />
        </LabeledGroup>
      </Grid>
    </Grid>
  );
};

export default SCOItemPaperItemGrid;
