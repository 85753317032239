import {faExternalLink, faLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, PaperProps} from "@material-ui/core";
import classnames from "classnames";
import {useSentinelListAPI} from "../hooks/useSentinelAPI";
import {makeNovoClasses} from "../theme";
import PaperPanel from "./PaperPanel";
import {ReactTableMui} from "./ReactTableMui";

const ExternalLinks = (props: {url: string; pageSize?: number; paperPanelProps?: PaperProps}) => {
  const {url, paperPanelProps = {}, ...rest} = props;
  const novoClasses = makeNovoClasses();
  const {query: externalLinkQuery} = useSentinelListAPI(`${url}`, {
    initialData: [],
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });

  return (
    <PaperPanel square variant="outlined" {...paperPanelProps}>
      <PaperPanel.Header isLoading={externalLinkQuery.isFetching}>
        <PaperPanel.Header.Title>
          <FontAwesomeIcon icon={faLink} /> External Links
        </PaperPanel.Header.Title>
        <PaperPanel.Header.Actions>
          <PaperPanel.Header.Action border>
            <PaperPanel.Header.RefreshButton
              onClick={() => externalLinkQuery.refetch()}
              isFetching={externalLinkQuery.isFetching}
            />
          </PaperPanel.Header.Action>
        </PaperPanel.Header.Actions>
      </PaperPanel.Header>
      <PaperPanel.Body>
        <ReactTableMui
          size="small"
          className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
          rightAlignColumns={["timestamp"]}
          columns={[
            {
              Header: "Contact",
              accessor: "contact",
              Cell: ({value, row}) => (
                <Link href={`${row.original.link}`} target="_blank">
                  <FontAwesomeIcon icon={faExternalLink} /> {value}
                </Link>
              ),
            },
            {
              Header: "Company",
              accessor: "company",
            },
          ]}
          initialState={{
            sortBy: [
              {
                id: "company",
              },
              {
                id: "contact",
              },
            ],
          }}
          data={externalLinkQuery.data}
          {...rest}
        />
        {/* <pre>{JSON.stringify(externalLinkQuery, null, 2)}</pre> */}
      </PaperPanel.Body>
    </PaperPanel>
  );
};

export default ExternalLinks;
