import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  RadioGroup,
  makeStyles,
} from "@material-ui/core";
import classNames from "classnames";
import {Field} from "react-final-form";
import {colorError} from "../../../theme/colors";

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    errorLabel: {
      color: colorError,
    },
  };
});

export const YesNARadios = (props: {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (event, value) => void;
  passRequired?: boolean;
  passRequiredMessage?: string;
}) => {
  const {
    name,
    disabled = false,
    label,
    required,
    onChange: _onChange,
    passRequired,
    passRequiredMessage = "Answer must be Yes",
    ...rest
  } = props;

  const classes = useStyles();

  const responseValidation = (value) => {
    if (!value) {
      return required ? "Required" : undefined;
    }
    if (passRequired && value === "na") {
      return passRequiredMessage;
    }
    return undefined;
  };

  return (
    <Field
      name={name}
      validate={responseValidation}
      // validate={(value) => (value ? undefined : required ? "Required" : undefined)}
      {...rest}
    >
      {(fieldProps) => {
        const {input, meta} = fieldProps;
        const errorClasses = classNames(meta.error && meta.invalid && meta.touched && classes.errorLabel);
        return (
          <FormControl disabled={disabled} error={meta.invalid}>
            <FormLabel error={meta.invalid && meta.touched}>{label}</FormLabel>
            <RadioGroup
              {...input}
              row
              name={name}
              value={input.value}
              onChange={(event, value) => {
                if (_onChange) {
                  _onChange(event, value);
                }
                input.onChange(event);
              }}
              className={errorClasses}
            >
              <FormControlLabel
                value="yes"
                control={<Radio className={errorClasses} />}
                label="Yes"
                className={errorClasses}
              />

              <FormControlLabel
                value="na"
                control={<Radio className={errorClasses} />}
                label="N/A"
                className={errorClasses}
              />
            </RadioGroup>
            {meta.invalid && meta.touched && <FormHelperText id="my-helper-text">{meta.error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};
