import {Box, Button, Grid, Tooltip, Typography} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import React from "react";
import {Helmet} from "react-helmet";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import Typography2 from "../../../components/Typography2";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";
import {TypeSelectDialog} from "./SubcontractSelectForms";

const SubContractSelect = (props) => {
  const {project, ...rest} = props;

  const [typeSelectDialogIsOpen, setTypeSelectDialogIsOpen] = React.useState(false);
  const [activeSubcontract, setActiveSubcontract] = React.useState({} as any);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      subcontracts: [],
    },
  });

  const pageData = pageDataQuery.data;

  const {subcontracts} = pageData;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Subcontracts..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Select Subcontract`} />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/change-order-wizard/`}>
          Change Order Wizard
        </MuiNavLink>
        <Typography color="inherit">SCO</Typography>
        <Typography color="textPrimary">Select Subcontract</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Title>Select Subcontract</PageHeader.Title>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Subcontracts</PaperPanel.Header.Title>
          {/* <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button href={`/projects/${project.id}/sccos/from-approved-items/`}>
                Subcontracts with approved PCO Items
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions> */}
        </PaperPanel.Header>
        <PaperPanel.Body>
          {subcontracts.length > 0 ? (
            subcontracts.map((subcontract) => {
              return (
                <SubcontractSelectPaperItem
                  subcontract={subcontract}
                  projectId={project.id}
                  key={subcontract.id}
                  setTypeDialogIsOpen={() => {
                    setActiveSubcontract(subcontract);
                    setTypeSelectDialogIsOpen(true);
                  }}
                />
              );
            })
          ) : (
            <Alert severity="warning">
              <AlertTitle>No Subcontracts Found</AlertTitle>
            </Alert>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <Box mt={1}>
        <LegacyUILink href={`/ccos/scco/${project.id}/sco-wizard/select-contract/`} />
      </Box>
      {/* <h3>PageData</h3>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
      <TypeSelectDialog
        isOpen={typeSelectDialogIsOpen}
        setIsOpen={setTypeSelectDialogIsOpen}
        subcontract={activeSubcontract}
        project={project}
      />
    </>
  );
};

export default SubContractSelect;

const SubcontractSelectPaperItem = (props) => {
  const {subcontract, projectId, setTypeDialogIsOpen, ...rest} = props;

  return (
    <PaperItem alignItems="center">
      <PaperItem.Left minWidth={80}>
        <Button
          color="default"
          variant="contained"
          size="small"
          // href={`/v2/projects/${projectId}/change-order-wizard/pcco/${subcontract.id}/create/`}
          onClick={() => setTypeDialogIsOpen(true)}
        >
          Select
        </Button>
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MuiNavLink href={subcontract.url} target="_blank">
              {`${subcontract?.budget?.budget_code ?? ""} ${subcontract.to_company.name} - ${subcontract.description}`}
            </MuiNavLink>
          </Grid>

          <Grid item xs={6} sm={2}>
            <Typography2 type="metadata">
              Original Value:{" "}
              <MuiNavLink href={`${subcontract.url}#tab=items`} underline="always">
                <Currency number={subcontract.original_value} />
              </MuiNavLink>
            </Typography2>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Tooltip title="Pending PCO items with same phase code as all item phase codes in contract (may be included in multiple contracts)">
              <span>
                <Typography2 type="metadata">
                  Pending Possible Revs:{" "}
                  <MuiNavLink
                    href={`/v2/projects/${projectId}/pcos/items/?budget_id=&is_approved=False#`}
                    underline="always"
                  >
                    <Currency number={subcontract.pending_possible_revs} />
                  </MuiNavLink>
                </Typography2>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Tooltip title="Approved PCO items with same phase code as all item phase codes in contract (may be included in multiple contracts)">
              <span>
                <Typography2 type="metadata">
                  Approved Possible Revs:
                  <MuiNavLink
                    href={`/v2/projects/${projectId}/pcos/items/?budget_id=${
                      subcontract?.budget?.id ?? ""
                    }&is_approved=True`}
                    // href={`/v2/projects/${projectId}/pcos/items/?primecontract=${subcontract.id}&is_approved=True#`}
                    underline="always"
                  >
                    <Currency number={subcontract.approved_possible_revs} />
                  </MuiNavLink>
                </Typography2>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography2 type="metadata">
              Approved Revs:
              <MuiNavLink
                // href={`/v2/projects/${projectId}/pcos/items/?primecontract=${subcontract.id}&is_approved=True#`}
                href={`/v2/projects/${projectId}/pcos/items/?budget_id=${
                  subcontract?.budget?.id ?? ""
                }&is_approved=True&pco__status=approved`}
                underline="always"
              >
                <Currency number={subcontract.approved_revs} />
              </MuiNavLink>
            </Typography2>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography2 type="metadata">
              Current Value: <Currency number={subcontract.current_value} />
            </Typography2>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right>
        <PaperItem.RightItemNumber number={subcontract.number_display} />
      </PaperItem.Right>
    </PaperItem>
  );
};

// export const TypeSelectDialog = (props: {
//   isOpen: boolean;
//   setIsOpen: (value: boolean) => void;
//   subcontract: {id: number; budget?: {budget_code: string}; to_company?: {name: string}; description?: string};
//   project: {id: number};
// }) => {
//   const {isOpen, setIsOpen, subcontract, project} = props;
//   const classes = useStyles();
//   const choiceClasses = classes.choice;
//   const [nameChangeOrderDialogIsOpen, setNameChangeOrderDialogIsOpen] = React.useState(false);
//   const blockUI = useBlockUI();
//   const navigate = useNavigate();

//   return (
//     <>
//       <Dialog
//         open={isOpen}
//         onClose={(event, reason) => {
//           setIsOpen(false);
//         }}
//         maxWidth="sm"
//       >
//         <DialogTitle>Select Change Order Type</DialogTitle>
//         <DialogContent>
//           <Grid container spacing={2}>
//             <Grid item xs={12}>
//               Which type of change order would you like to create for{" "}
//               <strong>{`${subcontract?.budget?.budget_code ?? ""} ${subcontract?.to_company?.name} - ${
//                 subcontract?.description
//               }`}</strong>
//               ?
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Box textAlign="center" className={classNames(choiceClasses)}>
//                 Create a new single item Internal Change Order and link to this Subcontract Change Order.
//                 <Box textAlign="center">
//                   <Button variant="contained" onClick={() => setNameChangeOrderDialogIsOpen(true)}>
//                     INTERNAL
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//             <Grid item xs={12} sm={6}>
//               <Box textAlign="center" className={classNames(choiceClasses)}>
//                 Link this Subcontract Change Order to an existing Change Order Request or Internal Change Order.
//                 <Box textAlign="center">
//                   <Button
//                     variant="contained"
//                     onClick={() =>
//                       navigate(`/v2/projects/${project.id}/change-order-wizard/sco/${subcontract.id}/create/`)
//                     }
//                   >
//                     EXISTING
//                   </Button>
//                 </Box>
//               </Box>
//             </Grid>
//           </Grid>
//         </DialogContent>
//       </Dialog>
//       <NameChangeOrderForm
//         isOpen={nameChangeOrderDialogIsOpen}
//         handleClose={() => setNameChangeOrderDialogIsOpen(false)}
//         onSubmit={(values) => {
//           blockUI.blockUI("Creating...");
//           axiosAPI
//             .post(`/projects/${project.id}/contracts/sub/${subcontract.id}/sccos/`, {
//               subcontract_id: subcontract.id,
//               ...values,
//             })
//             .then((response) => {
//               window.location.href = response.data.url;
//             });
//         }}
//       />
//     </>
//   );
// };
