import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Link, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect} from "../../components/Filters";
import {MuiNavLink} from "../../components/Links";
import {PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {saveExcel} from "../../utils/excel";

const Timecards = (props) => {
  const pageSizes = [500, 1000, 1500, 2000];
  const initialDaysFilter = 14;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(500);
  const [dateRange, setDateRange] = React.useState(initialDaysFilter as number | string);
  const novoClasses = makeNovoClasses();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Cost Code", "Start Date", "End Date"],
    {
      "Start Date": {
        value: moment().subtract(initialDaysFilter, "days").format("YYYY-MM-DD"),
        label: moment().subtract(initialDaysFilter, "days").format("YYYY-MM-DD"),
      },
      "End Date": {
        value: moment().format("YYYY-MM-DD"),
        label: moment().format("YYYY-MM-DD"),
      },
    }
  );

  const filterParams = {
    q: filterOptions.Search.value,
    cost_code: filterOptions["Cost Code"].value,
    date_min: filterOptions["Start Date"].value,
    date_max: filterOptions["End Date"].value,
  };

  const blockUI = useBlockUI();

  const {query: timecardQuery} = useSentinelListAPI(
    `accounting-tools/timecards/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const timecards = timecardQuery.data?.results;

  return (
    <>
      <Helmet title="Timecards" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography>Executive Tools</Typography>
        <Typography color="textPrimary">Timecards</Typography>
      </Breadcrumbs>

      <Box mt={1} />

      <PaperPanel>
        <PaperPanel.Header isLoading={timecardQuery.isFetching}>
          <PaperPanel.Header.Title>Timecards</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={timecardQuery.isFetching}
                onClick={(event) => {
                  let excelRows = timecards.map((timecard) => {
                    return {
                      "Last Name": timecard.last_name,
                      "First Name": timecard.first_name,
                      Date: timecard.accounting_date,
                      Job: timecard.job.display,
                      "Pay Code": timecard.pay_code,
                      Rate: {v: timecard.rate, t: "n"},
                      Hours: {v: timecard.hours, t: "n"},
                    };
                  });
                  saveExcel(excelRows, `Timecards`, [
                    {wch: 15},
                    {wch: 15},
                    {wch: 15},
                    {wch: 50},
                    {wch: 10},
                    {wch: 10},
                    {wch: 10},
                  ]);
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action px={0} border>
              <PaperPanel.Header.RefreshButton
                isFetching={timecardQuery.isFetching}
                onClick={() => timecardQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <FilterDate
                name="Start Date"
                value={filterOptions["Start Date"].value}
                label="Start Date"
                onChange={(date) => {
                  setPage(1);
                  setFilterOption("Start Date", date, date);
                  setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={3} xs={6}>
              <FilterDate
                name="End Date"
                value={filterOptions["End Date"].value}
                label="End Date"
                onChange={(date) => {
                  setPage(1);
                  setFilterOption("End Date", date, date);
                  setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                name="Date Range"
                label="Date Range"
                value={dateRange}
                options={[
                  {label: "Last Week", value: 7},
                  {
                    label: "Last 2 Weeks",
                    value: 14,
                  },
                  {
                    label: "Last Month",
                    value: 30,
                  },
                  {
                    label: "Last 2 Months",
                    value: 60,
                  },
                ]}
                onChange={(value, label) => {
                  if (value === "") {
                    clearFilterOption("Start Date");
                    clearFilterOption("End Date");
                    setDateRange("");
                    return;
                  }
                  const startDate = moment().subtract(value, "days").format("YYYY-MM-DD");
                  const endDate = moment().format("YYYY-MM-DD");
                  setPage(1);
                  setFilterOption("Start Date", startDate, startDate);
                  setFilterOption("End Date", endDate, endDate);
                  setDateRange(value);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={7} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                  if (key === "Start Date" || key === "End Date") {
                    setDateRange("");
                  }
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                  setDateRange("");
                }}
              />
            </Grid>
            <Grid item sm={5} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={timecardQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={pageSizes}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            showFooter={Boolean(timecards.length)}
            rightAlignColumns={["rate", "hours"]}
            columns={[
              {
                Header: "Last Name",
                accessor: "last_name",
                disableSortBy: true,
              },
              {
                Header: "First Name",
                accessor: "first_name",
                disableSortBy: true,
              },
              {
                Header: "Accounting Date",
                accessor: "accounting_date",
                disableSortBy: true,
                Cell: ({value, row}) => {
                  return (
                    <Link
                      style={{cursor: "pointer"}}
                      underline="always"
                      onClick={() => {
                        setFilterOption("Start Date", value, value);
                        setFilterOption("End Date", value, value);
                        setDateRange("");
                      }}
                    >
                      {value}
                    </Link>
                  );
                },
              },
              {
                Header: "Job",
                accessor: "job.display",
                disableSortBy: true,
              },
              {
                Header: "Pay Code",
                accessor: "pay_code",
                disableSortBy: true,
              },
              {
                Header: "Rate",
                accessor: "rate",
                disableSortBy: true,
              },
              {
                Header: "Hours",
                accessor: "hours",
                disableSortBy: true,
                Footer: () => {
                  const total = timecards.reduce((sum, row) => row.hours + sum, 0);
                  return accounting.formatNumber(total, 2);
                },
              },
            ]}
            data={timecards}
          />

          {/* <pre>{JSON.stringify(timecardQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
        {timecardQuery.data.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={timecardQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={pageSizes}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
    </>
  );
};

export default Timecards;
