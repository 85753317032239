import {
  faCalendar,
  faCircleDollar,
  faComment,
  faComments,
  faExclamationCircle,
  faGavel,
  faPaperPlane,
  faPencil,
  faQuestionCircle,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Avatar, Box, Dialog, DialogContent, DialogTitle, Grid, Link, Switch, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LaunchIcon from "@material-ui/icons/Launch";
import {Alert, AlertTitle} from "@material-ui/lab";
import moment from "moment-timezone";
import qs from "query-string";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import BeforeUnload from "../../../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../../../js/components/FinalFormEnhancers";
import HtmlRender from "../../../../../../js/components/HtmlRender";
import nl2br from "../../../../../../js/utils/nl2br";
import {Currency} from "../../../../../components/Accounting";
import {BoxIframeAndDropzone} from "../../../../../components/Box";
import {ConfirmationDialog} from "../../../../../components/Dialogs";
import ExternalInfoCard from "../../../../../components/ExternalInfoCard";
import {TextFieldMui} from "../../../../../components/forms/Fields";
import FormActions from "../../../../../components/forms/FormActions";
import BlockUI from "../../../../../components/GlobalLoaders";
import {StatusIcon} from "../../../../../components/Icons";
import {ImportanceLabel} from "../../../../../components/ImportanceLabel";
import {LabelValue} from "../../../../../components/LabelValue";
import {LegacyUILink} from "../../../../../components/Links";
import {PageHeader} from "../../../../../components/PageHeader";
import {PaperItem} from "../../../../../components/PaperItem";
import PaperPanel from "../../../../../components/PaperPanel";
import Typography2 from "../../../../../components/Typography2";
import useBlockUI from "../../../../../hooks/useBlockUI";
import {
  useFetchCurrentPage,
  usePostCurrentPage,
  useSentinelDetailAPI,
  useSentinelListAPI,
} from "../../../../../hooks/useSentinelAPI";
import {ContactChip, ListChip} from "../../../components/Chips";

const DATE_FORMAT = "ddd, MMM DD, YYYY";

const ExternalRFIDetail = (props) => {
  const {contact, project, user, ...rest} = props;
  const params = useParams();
  const blockUI = useBlockUI();
  const {contactUUID, projectUUID, rfiUUID} = params;
  const [activeRFIResponse, setActiveRFIResponse] = React.useState({} as any);
  const [deleteResponseConfirmationIsOpen, setDeleteResponseConfirmationIsOpen] = React.useState(false);
  const [editResponseDialogIsOpen, setEditResponseDialogIsOpen] = React.useState(false);
  const qsValues = qs.parse(location.search);

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        isFollowing: false,
      },
    },
    {
      token: qsValues.token,
    }
  );
  const pageData = pageDataQuery.data;

  const postCurrentPage = usePostCurrentPage({}, {baseURL: ""}, {token: qsValues.token});

  const {query: rfiQuery, rpc: rfiRPC} = useSentinelDetailAPI(
    `external/${contactUUID}/projects/${projectUUID}/rfis/${rfiUUID}/`,
    {
      initialData: {
        // drawings: [],
        // tags: [],
        // spec_section: "",
        // responsible_party: null,
        // authored_by: null,
        // answered_by: null,
      },
    }
  );
  const rfi = rfiQuery.data;

  const {query: rfiContactsQuery} = useSentinelListAPI(
    `external/${contactUUID}/projects/${projectUUID}/rfis/${rfiUUID}/contacts/`,
    {
      initialData: {
        results: [],
      },
    }
  );
  const rfiContacts = rfiContactsQuery.data.results;

  const responseRequiredContacts = rfiContacts
    .filter((item) => item.role === "response_required")
    .map((item) => item.contact);
  const ccContacts = rfiContacts.filter((item) => item.role === "cc").map((item) => item.contact);

  const {
    query: rfiResponsesQuery,
    create: createRFIResponse,
    update: updateRFIResponse,
    rpc: rfiResponseRPC,
    delete: deleteRFIResponse,
  } = useSentinelListAPI(`external/${contactUUID}/projects/${projectUUID}/rfis/${rfiUUID}/responses/`, {
    initialData: {
      results: [],
    },
  });
  const rfiResponses = rfiResponsesQuery.data.results;

  if (
    !rfiQuery.isFetchedAfterMount ||
    !pageDataQuery.isFetchedAfterMount ||
    !rfiContactsQuery.isFetchedAfterMount ||
    !rfiResponsesQuery.isFetchedAfterMount
  ) {
    return <BlockUI show={true} message="Fetching RFI info..." />;
  }

  const rfiStrings = {
    not_sure: "Not Sure",
    yes: "Yes",
    no: "No",
  };

  const drawingImpact = rfi.drawing_impact !== "no";
  const scheduleImpact = rfi.schedule_impact !== "no";
  const costImpact = rfi.cost_impact !== "no";

  // console.log(contact);

  return (
    <>
      <Helmet title={`${project.name} - ${rfi.display}`} />
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>{project.display}</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      {contact.has_portal_user && (
        <Box my={1}>
          <Alert severity="info">
            <AlertTitle>
              {contact.first_name}, it looks like you are signed up to use the NOVO Construction portal.
            </AlertTitle>
            <Link href={rfi.portal_url} underline="always" target="_blank">
              Click here to sign in to the portal
            </Link>
            . This will give you access to all of your projects with NOVO Construction.
          </Alert>
        </Box>
      )}
      {pageDataQuery.data.currentRevisionURL && (
        <Box mb={2}>
          <Alert severity="warning">
            This is a prior revision of this RFI.{" "}
            <Link href={pageData.currentRevisionURL} underline="always">
              Click here
            </Link>{" "}
            to go to the current revision.
          </Alert>
        </Box>
      )}
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faComments} /> {rfi.display}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Switch
                checked={pageData.isFollowing}
                onChange={() =>
                  postCurrentPage.mutateAsync({action: "toggle_following"}).then((values) => {
                    pageDataQuery.refetch();
                  })
                }
              />{" "}
              Following
              {project.can_view_external_reports && (
                <>
                  <Box ml={1} />
                  <PaperPanel.Header.PDFButton
                    href={`/reports2/external/${contactUUID}/projects/${projectUUID}/rfis/${rfiUUID}/?display_responses=true`}
                    target="_blank"
                  />
                </>
              )}
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RevisionsButtons
                url={`/external/${contactUUID}/projects/${projectUUID}/rfis/${rfiUUID}/revisions/`}
                obj={rfi}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton onClick={() => rfiQuery.refetch()} isFetching={rfiQuery.isFetching} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <LabelValue
                label="Response Required:"
                value={responseRequiredContacts.map((contact_) => {
                  return (
                    <ContactChip
                      contact={contact_}
                      active={contact.email === contact_.email}
                      displayAvatar={true}
                      key={contact_.email}
                    />
                  );
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <LabelValue
                label="CC:"
                value={ccContacts.map((contact_) => {
                  return (
                    <ContactChip
                      contact={contact_}
                      active={contact.email === contact_.email}
                      displayAvatar={true}
                      key={contact_.email}
                    />
                  );
                })}
              />
            </Grid>
            <Box mb={2} />
            <Grid item xs={12} sm={4}>
              <LabelValue label="Authored By:">
                {rfi.authored_by?.full_name} <Typography2 type="metadata">{rfi.authored_by?.company_name}</Typography2>
              </LabelValue>
              <LabelValue label="Responsible Party:" value={rfi.responsible_party?.name} />
              {rfi.answered_by && (
                <LabelValue label="Answered By:">
                  {rfi.answered_by.full_name} <Typography2 type="metadata">{rfi.answered_by.company_name}</Typography2>
                </LabelValue>
              )}
              <LabelValue label="Status:">
                <StatusIcon status={rfi.status} type="rfi" /> {rfi.status_display}
              </LabelValue>
            </Grid>
            <Grid item xs={12} sm={4}>
              <LabelValue
                label="Date Created:"
                value={rfi.date_created ? moment(rfi.date_created).format(DATE_FORMAT) : ""}
              />
              <LabelValue
                label="Date Required:"
                value={rfi.date_required ? moment(rfi.date_required).format(DATE_FORMAT) : ""}
              />
              <LabelValue
                label="Date Answered:"
                value={rfi.date_answered ? moment(rfi.date_answered).format(DATE_FORMAT) : ""}
              />
              <LabelValue label="Importance:" value={<ImportanceLabel importance={rfi.importance_display} />} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <LabelValue label="Category:" value={rfi.category_display} />
              <LabelValue
                label="Spec Section:"
                value={`${rfi.spec_section_code}${rfi.spec_subsection && ` - ${rfi.spec_subsection}`}`}
              />
              <LabelValue label="Discipline:" value={rfi.discipline_display} />
            </Grid>
            {rfi.tags.length > 0 && (
              <Grid item xs={12}>
                <Box mt={1} />
                <LabelValue
                  label="Tags:"
                  value={rfi.tags.map((tag) => (
                    <ListChip label={tag} key={tag} />
                  ))}
                />
              </Grid>
            )}
            {rfi.drawings2.length > 0 && (
              <Grid item xs={12}>
                <Box mt={1} />
                <LabelValue
                  label="Drawings:"
                  value={rfi.drawings2.map((drawing) => (
                    <ListChip label={drawing} key={drawing} />
                  ))}
                />
              </Grid>
            )}
          </Grid>
          {/* <Box my={2}> */}
          <ExternalInfoCard title="Question" icon={<FontAwesomeIcon icon={faQuestionCircle} />}>
            <HtmlRender html={rfi.question} />
          </ExternalInfoCard>
          {/* </Box> */}
          {rfi.suggestion && (
            <>
              {/* <Box mt={2} /> */}
              <ExternalInfoCard title="Suggestion" icon={<FontAwesomeIcon icon={faComment} />}>
                <HtmlRender html={rfi.suggestion} />
              </ExternalInfoCard>
            </>
          )}

          {rfi.answer && (
            <>
              {/* <Box mt={2} /> */}
              <ExternalInfoCard title="Final Answer" icon={<FontAwesomeIcon icon={faGavel} />} mb={2}>
                <HtmlRender html={rfi.answer} />
              </ExternalInfoCard>
            </>
          )}

          {(scheduleImpact || costImpact || drawingImpact) && (
            <>
              <ExternalInfoCard title="Impact" icon={<FontAwesomeIcon icon={faExclamationCircle} />} mb={2} display="">
                {scheduleImpact && (
                  <Box>
                    <LabelValue
                      label={
                        <>
                          <FontAwesomeIcon icon={faCalendar} /> Schedule Impact
                        </>
                      }
                      value={`${rfiStrings[rfi.schedule_impact]} ${
                        rfi.schedule_impact_days === 0 ? `TBD` : `${rfi.schedule_impact_days} days`
                      }`}
                    />
                    <HtmlRender html={rfi.schedule_impact_notes} />
                  </Box>
                )}
                {drawingImpact && (
                  <>
                    {scheduleImpact && <Box style={{borderTop: "1px solid LightGray"}} pt={0.5} />}
                    <Box>
                      <LabelValue
                        label={
                          <>
                            <FontAwesomeIcon icon={faPencil} /> Drawing Impact
                          </>
                        }
                        value={rfiStrings[rfi.drawing_impact]}
                      />
                      <HtmlRender html={rfi.drawing_impact_notes} />
                    </Box>
                  </>
                )}

                {costImpact && (
                  <>
                    {(drawingImpact || scheduleImpact) && <Box style={{borderTop: "1px solid LightGray"}} pt={0.5} />}
                    <Box>
                      <LabelValue
                        label={
                          <>
                            <FontAwesomeIcon icon={faCircleDollar} /> Cost Impact
                          </>
                        }
                        value={
                          <>
                            {rfiStrings[rfi.cost_impact]}{" "}
                            {rfi.cost_impact_amount === 0 ? `TBD` : <Currency number={rfi.cost_impact_amount} />}
                          </>
                        }
                      />
                      <HtmlRender html={rfi.cost_impact_notes} />
                    </Box>
                  </>
                )}
              </ExternalInfoCard>
            </>
          )}

          <PaperPanel>
            <PaperPanel.Header>
              <PaperPanel.Header.Title maxWidth={"100%"}>
                <FontAwesomeIcon icon={faComments} /> Responses
              </PaperPanel.Header.Title>
            </PaperPanel.Header>
            <PaperPanel.Body>
              {rfiResponses.map((response) => (
                <ResponsePaperItem
                  response={response}
                  contact={contact}
                  key={`response${response.number}`}
                  timezone={project.timezone}
                  onEdit={() => {
                    setActiveRFIResponse(response);
                    setEditResponseDialogIsOpen(true);
                  }}
                  onDelete={() => {
                    setActiveRFIResponse(response);
                    setDeleteResponseConfirmationIsOpen(true);
                  }}
                />
              ))}
              {rfi.permissions?.can_create_response && !pageDataQuery.data.currentRevisionURL && (
                <Box mt={2} px={1}>
                  <ExternalResponseForm
                    onSubmit={(values, form) => {
                      createRFIResponse.mutateAsync(values).then(() => {
                        rfiResponsesQuery.refetch();
                        form.reset();
                      });
                    }}
                  />
                </Box>
              )}
            </PaperPanel.Body>
          </PaperPanel>
        </PaperPanel.Body>
      </PaperPanel>
      {pageData.boxSharedFolderId && (
        <Box my={1}>
          <PaperPanel>
            <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
              <PaperPanel.Header.Title>Files</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                {user.is_superuser && (
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      href={pageData.boxUploadURL}
                      target="_blank"
                      endIcon={<LaunchIcon />}
                      // variant="text"
                    >
                      Box Upload URL (super user debug)
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                )}

                {pageData.boxSharedFolderLink && (
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      href={pageData.boxSharedFolderLink}
                      target="_blank"
                      endIcon={<LaunchIcon />}
                      // variant="text"
                    >
                      Box Site
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                )}
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => pageDataQuery.refetch()}
                    isFetching={pageDataQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <BoxIframeAndDropzone sharedFolderId={pageData.boxSharedFolderId} uploadURL={pageData.boxUploadURL} />
            </PaperPanel.Body>
          </PaperPanel>
        </Box>
      )}

      <LegacyUILink href={pageData.legacyURL} my={2} />

      <ConfirmationDialog
        title="Are You Sure?"
        isOpen={deleteResponseConfirmationIsOpen}
        onDeny={() => setDeleteResponseConfirmationIsOpen(false)}
        onApprove={() => {
          deleteRFIResponse
            .mutateAsync(activeRFIResponse.id)
            .then(() => {
              setDeleteResponseConfirmationIsOpen(false);
            })
            .catch(() => {
              setDeleteResponseConfirmationIsOpen(false);
            });
        }}
      >
        Do you want to delete this response? This can not be undone.
      </ConfirmationDialog>
      <EditResponseDialogForm
        isOpen={editResponseDialogIsOpen}
        handleClose={() => {
          setEditResponseDialogIsOpen(false);
          setActiveRFIResponse({});
        }}
        activeRFIResponse={activeRFIResponse}
        onSubmit={(values) => {
          updateRFIResponse.mutateAsync(values).then(() => {
            setEditResponseDialogIsOpen(false);
            setActiveRFIResponse({});
          });
        }}
      />
      {/* <h1>RFI</h1>
      <pre>{JSON.stringify(rfi, null, 2)}</pre>
      <h1>Project</h1>
      <pre>{JSON.stringify(project, null, 2)}</pre>
      <h1>Response Required</h1>
      <pre>{JSON.stringify(responseRequiredContacts, null, 2)}</pre>
      <h1>CC</h1>
      <pre>{JSON.stringify(ccContacts, null, 2)}</pre>
      <h1>Responses</h1>
      <pre>{JSON.stringify(rfiResponses, null, 2)}</pre>
      <h1>Page Data</h1>
      <pre>{JSON.stringify(pageData, null, 2)}</pre>
      <pre>{JSON.stringify(params, null, 2)}</pre>
      <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default ExternalRFIDetail;

const ExternalResponseForm = (props) => {
  const {onSubmit, isUpdating, disabled, ...rest} = props;

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values} = props;

        return (
          <>
            <BeforeUnload block={!pristine} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <TextFieldMui name="response" label="Response" minRows={3} multiline disabled={disabled} />
                {/* <FormActions>
                  <FormActions.Left> */}
                <Box m={1}>
                  <FormActions.SaveButton
                    disabled={submitting || pristine}
                    label={isUpdating ? `Update` : `Respond`}
                    startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                  />
                </Box>
                {/* </FormActions.Left>
                </FormActions> */}
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

const EditResponseDialogForm = (props) => {
  const {isOpen, handleClose, onSubmit, activeRFIResponse, ...rest} = props;
  return (
    <Dialog
      open={isOpen}
      onClose={(event, reason) => handleClose()}
      maxWidth="md"
      fullWidth
      // scroll="paper"
      {...rest}
    >
      <DialogTitle id="form-dialog-title">Update Response</DialogTitle>
      <DialogContent>
        <ExternalResponseForm onSubmit={onSubmit} initialValues={activeRFIResponse} isUpdating />
      </DialogContent>
    </Dialog>
  );
};

// const ExternalInfoCard = (props: {
//   children: React.ReactNode;
//   title?: React.ReactNode;
//   icon?: React.ReactNode;
//   display?: string;
//   [rest: string]: any;
// }) => {
//   const {children, title, icon, iconColor, display = "flex", ...rest} = props;
//   return (
//     <Box mt={2} {...rest}>
//       <PaperPanel>
//         <PaperPanel.Header>
//           <PaperPanel.Header.Title maxWidth={"100%"}>
//             {icon} {title}
//           </PaperPanel.Header.Title>
//         </PaperPanel.Header>
//         <PaperPanel.Body>
//           <Box display={display} flexWrap={"wrap"} px={1}>
//             {children}
//           </Box>
//         </PaperPanel.Body>
//       </PaperPanel>
//     </Box>
//   );
// };

const ResponsePaperItem = (props) => {
  const {response, contact, onDelete, onEdit, timezone, ...rest} = props;

  return (
    <PaperItem noHover={!response.permissions?.can_update && !response.permissions?.can_delete}>
      <PaperItem.Left minWidth="50px">
        <Avatar
          alt={response.author.full_name}
          src={response.author.avatar_url ? `${response.author.avatar_url}?d=mm` : null}
        />
      </PaperItem.Left>
      <PaperItem.Body>
        <strong>{response.author.full_name}</strong> - {response.author.company_name}{" "}
        <Typography2 type="metadata">
          <Tooltip
            title={
              moment(response.modified) > moment(response.created)
                ? `Modified: ${moment.tz(response.modified, timezone).format("YYYY-MM-DD hh:mm A z")}`
                : ""
            }
          >
            <span> - {moment.tz(response.created, timezone).format("YYYY-MM-DD hh:mm A z")}</span>
          </Tooltip>
        </Typography2>
        <Box py={0.5}>{nl2br(response.response)}</Box>
      </PaperItem.Body>
      <PaperItem.Right>
        <PaperItem.RightItemNumber number={response.number_display} />
      </PaperItem.Right>
      <PaperItem.RightHover>
        {response.permissions?.can_update && (
          <PaperItem.RightHover.IconButton icon={EditIcon} title="Edit Response" onClick={onEdit} />
        )}
        {response.permissions?.can_delete && (
          <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete Response" onClick={onDelete} />
        )}
      </PaperItem.RightHover>
    </PaperItem>
  );
};
