import {faBroom} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {axiosAPI} from "../api";
import useBlockUI from "../hooks/useBlockUI";
import {useSentinelListAPI} from "../hooks/useSentinelAPI";
import {ProjectContactAutoselectMui} from "./forms/Fields";

export const BallInCourtForm = (props: {projectId: number; url: string; [rest: string]: any}) => {
  const {projectId, url, ...rest} = props;
  const blockUI = useBlockUI();
  const {query: ballInCourtQuery} = useSentinelListAPI(url, {
    initialData: {
      results: [],
    },
  });

  return (
    <>
      <FinalForm
        onSubmit={() => {}}
        initialValues={{ball_in_court: ballInCourtQuery.data.results.map((bic) => bic.contact)}}
        {...rest}
      >
        {({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <ProjectContactAutoselectMui
                  projectId={projectId}
                  name="ball_in_court"
                  label="Ball In Court"
                  //   loading={ballInCourtQuery.isLoading || isLoading}
                  multiple
                  onChange={(event, values) => {
                    axiosAPI.put(url, values);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    blockUI.blockUI("Cleaning up ball in court...");
                    axiosAPI
                      .post(`${url}cleanup-ball-in-court/`)
                      .then(() => ballInCourtQuery.refetch().then(blockUI.unblockUI()));
                  }}
                >
                  <FontAwesomeIcon icon={faBroom} />
                  Cleanup Ball In Court
                </Button>
              </Grid>
            </Grid>

            {/* <FormActions>
              <FormActions.Left>
                <FormActions.SaveButton disabled={submitting || pristine} />
              </FormActions.Left>
            </FormActions> */}
          </form>
        )}
      </FinalForm>
    </>
  );
};
