import {faThumbsDown, faThumbsUp, faUpRightFromSquare} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Link, makeStyles, Tooltip} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import EditIcon from "@material-ui/icons/Edit";
import {Skeleton} from "@material-ui/lab";
import {default as classNames, default as classnames} from "classnames";
import pluralize from "pluralize";
import React from "react";
import HtmlRender from "../../js/components/HtmlRender";
import useUser from "../hooks/useUser";
import {makeNovoClasses} from "../theme";
import {colorError, colorMutedText, colorSuccess} from "../theme/colors";
import {Currency} from "./Accounting";
import {MuiNavLink} from "./Links";
import {ReactTableMui} from "./ReactTableMui";
import Typography2 from "./Typography2";

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;

  return {
    response: {
      padding: "10px 20px 10px 10px",
    },
    question: {
      padding: "10px 25px 10px 10px",
      backgroundColor: grey[200],
      borderBottom: "1px solid grey",
      transition: "background-color .25s",
      "&:hover": {
        backgroundColor: grey[100],
      },
    },
    questionAnswerBubble: {
      borderRadius: "10px 10px 0px 0px",
      position: "relative",
      "&:hover": {
        "& .right-hover": {
          transition: "opacity .5s",
          opacity: 1,
          cursor: "pointer",
        },
        "& $questionActions": {
          opacity: 1,
        },
      },
      "& .right-hover": {
        opacity: 0,
        zIndex: 10,
      },
      "& $questionActions": {
        opacity: 0,
      },
    },
    questionAnswer: {
      border: "1px solid grey",
      borderRadius: "10px",
    },

    rightActions: {
      position: "absolute",
      top: "50%",
      right: "10px",
      transform: "translateY(-50%)",
      flexDirection: "row",
      marginLeft: spacing(0.5),
    },

    questionActions: {
      transition: `opacity ${transitions.duration.shorter}ms ${transitions.easing.easeInOut}`,
      opacity: 0,
    },
    feedbackAction: {
      color: colorMutedText,
      cursor: "pointer",
      "&.helpful": {
        color: colorSuccess,
      },
      "&.unhelpful": {
        color: colorError,
      },
    },
  };
});

export const AIDocumentListTable = (props) => {
  const {documents, projectId, onEdit} = props;

  const novoClasses = makeNovoClasses();

  return (
    <>
      <ReactTableMui
        hover
        size="small"
        className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
        rightAlignColumns={["edit"]}
        // getHeaderProps={(column) => {
        //   if (["edit"].includes(column.id)) {
        //     return {
        //       style: {
        //         width: "20px",
        //       },
        //     };
        //   }
        // }}
        columnWidths={{edit: 20}}
        // getRowProps={(row) => {
        //   return {
        //     onClick: (event) => {
        //       // setActiveItem(row.original);
        //       onEdit(row.original);
        //     },
        //     style: {cursor: "pointer"},
        //   };
        // }}
        columns={[
          {
            id: "edit",
            disableSortBy: true,
            Cell: ({row, value}) => {
              return (
                onEdit && (
                  <Tooltip title="Edit">
                    <EditIcon fontSize="small" onClick={() => onEdit(row.original)} style={{cursor: "pointer"}} />
                  </Tooltip>
                )
              );
            },
          },

          {
            Header: "Name",
            accessor: "name",
            Cell: ({row, value}) => {
              return (
                <MuiNavLink
                  href={
                    projectId
                      ? `/v2/projects/${projectId}/ai/documents/${row.original.id}/`
                      : `/v2/ai/documents/${row.original.id}/`
                  }
                  underline="always"
                >
                  {value}
                </MuiNavLink>
              );
            },
          },
          {
            Header: "Description",
            accessor: "description",
          },
          {
            Header: "View Original",
            accessor: "url",
            disableSortBy: true,
            Cell: ({row, value}) => {
              return (
                <Link href={value} target="_blank">
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </Link>
              );
            },
          },
        ]}
        initialState={{}}
        data={documents}
      />
    </>
  );
};

const convertNewLinesToHtml = (inputText: string): string => {
  const htmlOutput = inputText.replace(/\n/g, "<br>");
  return htmlOutput;
};

export const QuestionAnswer = (props: {children: React.ReactNode | React.ReactNode[]}) => {
  const {children, ...rest} = props;
  const classes = useStyles();
  return (
    <Box className={classes.questionAnswer} my={1} {...rest}>
      {children}
    </Box>
  );
};

QuestionAnswer.Item = (props: {
  text: string;
  cost?: number;
  response?: boolean;
  children?: React.ReactNode;
  source_documents?: {metadata: {page: number}}[];
  onFeedback?: (feedback: boolean) => void;
  is_helpful?: Boolean;
  [rest: string]: any;
}) => {
  const {text, cost, response, children, source_documents, onFeedback, is_helpful, ...rest} = props;
  const classes = useStyles();
  const user = useUser();

  const getDocumentList = (sources) => {
    const documentMap = sources.reduce((acc, item) => {
      const source = item.metadata.source;
      const page = typeof item.metadata.page === "number" ? item.metadata.page + 1 : null;
      acc[source] = [...(acc[source] || []), page];
      return acc;
    }, {});

    return Object.keys(documentMap).map((name) => {
      if (documentMap[name].filter((page) => page !== null).length === 0) {
        return (
          <Typography2 type="metadata" component={Box} key={name}>
            {name}
          </Typography2>
        );
      }
      return (
        <Typography2 type="metadata" component={Box} key={name}>
          {name} - {pluralize("Page", documentMap[name].length)}: {documentMap[name].join(", ")}
        </Typography2>
      );
    });
  };

  return (
    <Box className={`${classes.questionAnswerBubble} ${response ? classes.response : classes.question}`} {...rest}>
      {!response && (
        <Box display="flex" flexWrap="noWrap" className={classNames(`${classes.rightActions} right-hover`)}>
          {React.Children.map(children, (child) => (
            <Box mx={0.5}>{child}</Box>
          ))}
        </Box>
      )}
      {text ? (
        <>
          <HtmlRender html={convertNewLinesToHtml(text)} />
          <Box mb={1} />
          {response && source_documents && (
            <>
              <Typography2 type="metadata">Sources:</Typography2> {getDocumentList(source_documents)}
            </>
          )}
          {response && cost && user.is_superuser && (
            <>
              <Box mr={1} component="span" justifyContent="space-between" width="100%" display="flex">
                <Typography2 type="metadata">
                  Cost - <Currency number={cost} precision={5} />
                </Typography2>
                <Box display="flex" flexDirection="row" className={classes.questionActions}>
                  {React.Children.map(children, (child) => (
                    <Box mx={0.5}>{child}</Box>
                  ))}
                  <Tooltip title="Helpful">
                    <Box
                      onClick={() => {
                        onFeedback(true);
                      }}
                      mx={0.5}
                      className={`${classes.feedbackAction} ${is_helpful === true && "helpful"}`}
                    >
                      <FontAwesomeIcon icon={faThumbsUp} />
                    </Box>
                  </Tooltip>
                  <Tooltip title="Not Helpful">
                    <Box
                      onClick={() => {
                        onFeedback(false);
                      }}
                      mx={0.5}
                      className={`${classes.feedbackAction} ${is_helpful === false && "unhelpful"}`}
                    >
                      <FontAwesomeIcon icon={faThumbsDown} />
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            </>
          )}
        </>
      ) : (
        <Skeleton />
      )}
    </Box>
  );
};
