import {Box, Card, CardContent, CardHeader, CardMedia, Grid} from "@material-ui/core";

const WeatherCard = (props) => {
  const {weatherData, ...rest} = props;

  return (
    <>
      <Card variant="outlined">
        <CardHeader
          component={Box}
          title={weatherData.local_time}
          bgcolor="grey.100"
          textAlign="center"
          titleTypographyProps={{variant: "h3", fontWeight: "bold"}}
        />
        <CardContent>
          <Box>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <CardMedia
                  title={weatherData.summary}
                  image={weatherData.icon_url}
                  style={{height: "5em", width: "5em"}}
                />
              </Grid>

              <Grid item xs={12} sm={8}>
                <h3 style={{margin: 0}}>
                  {weatherData.temperature.toFixed(0)}&#176; {weatherData.summary}
                </h3>
                <Box>Feels Like {weatherData.apparent_temperature.toFixed(0)}&#176;</Box>
                <Box>
                  <small>
                    Wind {weatherData.wind_speed} MPH | Precip {(weatherData.precip_probability * 100).toFixed(1)}%
                  </small>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </CardContent>
      </Card>
      {/* <pre>{JSON.stringify(weatherData, null, 2)}</pre> */}
    </>
  );
};

export default WeatherCard;
