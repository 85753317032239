import {faCheck, faCircleExclamation, faPlus, faSync} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Tooltip} from "@material-ui/core";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";
import {colorError, colorSuccess, colorWarning} from "../../../theme/colors";

import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import accounting from "accounting";
import moment from "moment";
import pluralize from "pluralize";

export const InsuranceCompanyPaperItem = (props) => {
  const {company, isFetching, onRefreshPolicies, onCreatePolicy, ...rest} = props;

  return (
    <PaperItem.Header>
      {/* <PaperItem.Left>
        <PaperItem.SelectedCheckbox />
      </PaperItem.Left> */}
      <PaperItem.Body>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <MuiNavLink href={`${company.url}#tab=insurance`} underline="always">
              {company.name}
            </MuiNavLink>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right>
        <Tooltip title="Add Policy">
          <FontAwesomeIcon icon={faPlus} fixedWidth onClick={onCreatePolicy} style={{cursor: "pointer"}} />
        </Tooltip>
        <Tooltip title="Refresh Policies">
          <FontAwesomeIcon
            icon={faSync}
            fixedWidth
            onClick={onRefreshPolicies}
            style={{cursor: "pointer"}}
            spin={isFetching}
          />
        </Tooltip>
      </PaperItem.Right>
    </PaperItem.Header>
  );
};

export const InsurancePolicyPaperItem = (props) => {
  const {policy, policyIsSelected, onChangePolicySelected, onEditPolicy, onDeletePolicy, ...rest} = props;

  return (
    <PaperItem {...rest}>
      <PaperItem.Left>
        <PaperItem.SelectedCheckbox checked={policyIsSelected} onChange={onChangePolicySelected} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={2}>
            {policy.type_display}
          </Grid>
          <Grid item xs={6} sm={2}>
            <Tooltip title="Policy Number">
              <span>{policy.policy_number}</span>
            </Tooltip>
          </Grid>

          <Grid item xs={12} sm={2}>
            {policy.description}
          </Grid>

          <Grid item xs={4} sm={2}>
            <Tooltip title="Start Date">
              <span>
                <Typography2 type="metadata">
                  {policy.start_date && moment(policy?.start_date).format("YYYY-DD-MM")}
                </Typography2>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={4} sm={2}>
            <Tooltip title="Expiration Date">
              <span>
                <Typography2 type="metadata">
                  {policy.ex_date && moment(policy?.ex_date).format("YYYY-DD-MM")}
                </Typography2>
              </span>
            </Tooltip>
          </Grid>
          <Grid item xs={4} sm={2}>
            {policy.days_until_expiration && <ExpirationLabel daysUntilExpiration={policy.days_until_expiration} />}
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={41} />
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onDeletePolicy} />
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditPolicy} />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

const ExpirationLabel = (props) => {
  const {daysUntilExpiration, expirationWarning = 30, ...rest} = props;

  if (daysUntilExpiration < 1) {
    return (
      <>
        <Typography2 type={"metadata"} style={{color: colorError}} {...rest}>
          <FontAwesomeIcon icon={faCircleExclamation} />{" "}
          {daysUntilExpiration === 0
            ? "Due today"
            : `Expired ${moment.duration(Math.abs(daysUntilExpiration), "days").humanize()} ago`}
        </Typography2>
      </>
    );
  }

  if (daysUntilExpiration < expirationWarning) {
    return (
      <>
        <Typography2 type={"metadata"} style={{color: colorWarning}} {...rest}>
          <FontAwesomeIcon icon={faCircleExclamation} />
          {`${accounting.format(daysUntilExpiration)} ${pluralize("day", daysUntilExpiration)} until expiration`}
        </Typography2>
      </>
    );
  }

  return (
    <>
      <Typography2 type={"metadata"} style={{color: colorSuccess}} {...rest}>
        <FontAwesomeIcon icon={faCheck} />
        {`${accounting.format(daysUntilExpiration)} ${pluralize("day", daysUntilExpiration)} until expiration`}
      </Typography2>
    </>
  );
};
