import {faFileExcel, faPaperclip} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, Tooltip} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Currency} from "../../../components/Accounting";
import {StatusIcon} from "../../../components/Icons";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";
import {truncateString} from "../../../utils/string";

const ChangeOrders = (props: {
  pcoItems: Array<{
    pco_display: string;
    display: string;
    status_display: string;
    status: string;
    cor_number_display: string;
    scco: {
      number_display: string;
    };
    pcco: {
      number_display: string;
    };
    budget_rom: number;
    budget_proposed_amount: number;
    budget_approved_amount: number;
    budget_cost: number;
    cost: number;
  }>;
  project: {display: string};
  budget: {display: string};
  isFetching?: Boolean;
}) => {
  const {pcoItems, project, budget, isFetching} = props;
  const alivePCOItems = pcoItems.filter((pcoItem) => !["void"].includes(pcoItem.status));
  const novoClasses = makeNovoClasses();

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faPaperclip} /> Change Orders
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={isFetching}
                onClick={(event) => {
                  saveExcel(
                    pcoItems.map((pcoItem) => {
                      return {
                        PCO: pcoItem.pco_display,
                        PCOItem: pcoItem.display,
                        Status: pcoItem.status_display,
                        COR: pcoItem?.cor_number_display,
                        SCO: pcoItem?.scco?.number_display,
                        OCO: pcoItem?.pcco?.number_display,
                        ROM: {v: pcoItem.budget_rom, t: "n", z: "$#,##0.00"},
                        Proposed: {v: pcoItem.budget_proposed_amount, t: "n", z: "$#,##0.00"},
                        Approved: {v: pcoItem.budget_approved_amount, t: "n", z: "$#,##0.00"},
                        "Budget Cost": {v: pcoItem.budget_cost, t: "n", z: "$#,##0.00"},
                        Cost: {v: pcoItem.cost, t: "n", z: "$#,##0.00"},
                      };
                    }),
                    `${project.display} - ${budget.display} - Change Orders`,
                    [
                      {wch: 60},
                      {wch: 60},
                      {wch: 15},
                      {wch: 10},
                      {wch: 10},
                      {wch: 10},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                      {wch: 15},
                    ]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            showFooter={Boolean(pcoItems.length)}
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={[
              "budget_rom",
              "budget_proposed_amount",
              "budget_approved_amount",
              "budget_cost",
              "cost",
            ]}
            columns={[
              {
                Header: "PCO",
                id: "pco",
                accessor: (value) => {
                  return `${value.status_sort}-${value.pco_display}`;
                },
                Cell: ({value, row}) => (
                  <>
                    <StatusIcon status={row.original.status} showTooltip />{" "}
                    <Link href={row.original.url}>{truncateString(row.original.pco_display, 50)}</Link>
                  </>
                ),
              },
              {
                Header: "PCO Item",
                accessor: "display",
                Cell: ({value, row}) => (
                  <>
                    <Link href={row.original.url}>{truncateString(row.original.display, 35)}</Link>
                  </>
                ),
              },
              {
                Header: "COR",
                accessor: "cor_number_display",
                Cell: ({value, row}) => (
                  <>
                    {row.original.pco_is_internal ? (
                      <Tooltip title="Internal PCO">
                        <Link href={row.original.pco_url}>Int</Link>
                      </Tooltip>
                    ) : (
                      <>{value && <Link href={row.original.cor_url}>{value}</Link>}</>
                    )}
                  </>
                ),
              },
              {
                Header: "SCO",
                accessor: "scco.number_display",
                Cell: ({value, row}) => <>{value && <Link href={row.original?.scco?.number_display}>{value}</Link>}</>,
              },

              {
                Header: "OCO",
                accessor: "pcco.number_display",
                Cell: ({value, row}) => <>{value && <Link href={row.original?.pcco?.number_display}>{value}</Link>}</>,
              },
              {
                Header: "ROM",
                accessor: "budget_rom",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => alivePCOItems.reduce((sum, row) => row.budget_rom + sum, 0),
                    [alivePCOItems]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Proposed",
                accessor: "budget_proposed_amount",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => alivePCOItems.reduce((sum, row) => row.budget_proposed_amount + sum, 0),
                    [alivePCOItems]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Approved",
                accessor: "budget_approved_amount",
                sortType: "basic",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => alivePCOItems.reduce((sum, row) => row.budget_approved_amount + sum, 0),
                    [alivePCOItems]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Budget Cost",
                accessor: "budget_cost",
                sortType: "basic",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => alivePCOItems.reduce((sum, row) => row.budget_cost + sum, 0),
                    [alivePCOItems]
                  );
                  return <Currency number={total} />;
                },
              },
              {
                Header: "Cost",
                accessor: "cost",
                sortType: "basic",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => alivePCOItems.reduce((sum, row) => row.cost + sum, 0),
                    [alivePCOItems]
                  );
                  return <Currency number={total} />;
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "pco",
                },
              ],
            }}
            data={pcoItems}
          />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default ChangeOrders;
