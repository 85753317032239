import {Skeleton} from "@material-ui/lab";
import accounting from "accounting";
import React from "react";
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  VictoryContainer,
  VictoryLegend,
  VictoryLine,
  VictoryTooltip,
  VictoryVoronoiContainer,
} from "victory";
import {axiosAPI} from "../../../api";
import {VictoryTooltipMui} from "../../../components/Victory";

const moneyTickFormatter = Intl.NumberFormat("en", {notation: "compact"});

export const ProjectForecastLMOEChart = (props: {projectId: string}) => {
  const {projectId, ...rest} = props;
  const [chartDataFetched, setChartDataFetched] = React.useState(false);
  const [chartData, setChartData] = React.useState({
    // costHistory: [],
    cost: {
      labor: [],
      material: [],
      equipment: [],
      other: [],
    },
    costForecast: {
      labor: [],
      material: [],
      equipment: [],
      other: [],
    },
  });
  React.useEffect(() => {
    axiosAPI.get(`/projects/${projectId}/timberline-cost-time-series/lmoe/forecast/`).then((response) => {
      setChartData(response.data);
      setChartDataFetched(true);
    });
  }, [projectId]);
  const findMinDateKey = (data) => {
    let minDate = null;
    let minDateKey = null;

    for (const key in data) {
      if (data[key].length > 0) {
        data[key].forEach((item) => {
          const currentDate = new Date(item.index);
          if (!minDate || currentDate < minDate) {
            minDate = currentDate;
            minDateKey = key;
          }
        });
      }
    }
    return minDateKey;
  };

  if (!chartDataFetched) {
    return (
      <Skeleton variant="rect" width="100%">
        <div style={{height: 200}} />
      </Skeleton>
    );
  }

  const minDateKey = findMinDateKey(chartData.cost);

  const chartLines = [
    {color: "blue", key: "labor", label: "Labor"},
    {color: "orange", key: "material", label: "Material"},
    {color: "red", key: "other", label: "Other"},
    {color: "green", key: "equipment", label: "Equipment"},
  ];

  // rearrange chartLines so that it starts with minDateKey this helps with the chart
  const startIndex = chartLines.findIndex((item) => item.key === (minDateKey || "labor"));
  const chartLinesOrdered = chartLines.slice(startIndex).concat(chartLines.slice(0, startIndex));

  return (
    <>
      <VictoryChart
        padding={{top: 15, bottom: 20, left: 40, right: 20}}
        height={100}
        // theme={VictoryTheme.material}
        containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis tickCount={6} style={{tickLabels: {fontSize: 6}}} />
        <VictoryAxis
          style={{tickLabels: {fontSize: 6}}}
          dependentAxis
          tickFormat={(x) => `$${moneyTickFormatter.format(x)}`}
        />
        <VictoryLegend
          x={0}
          y={0}
          // title="Legend"
          centerTitle
          orientation="horizontal"
          gutter={5}
          style={{title: {fontSize: 8}, labels: {fontSize: 8}, data: {fontSize: 8}}}
          data={[...chartLines, {color: "lightBlue", label: "Forecast"}].map((line) => {
            return {name: line.label, symbol: {fill: line.color}};
          })}
        />

        {chartLinesOrdered.map((line) => {
          return (
            <VictoryLine
              labelComponent={<VictoryTooltipMui />}
              height={5000}
              data={chartData.cost[line.key]}
              x="index"
              y="cumsum_amount"
              labels={({datum}) =>
                `${accounting.formatMoney(datum.cumsum_amount, "$", 0)}\n${accounting.formatMoney(datum.amount, "$", 0)}\n${datum.index}`
              }
              style={{data: {stroke: line.color, strokeWidth: 1}}}
              containerComponent={<VictoryContainer responsive={true} />}
            />
          );
        })}
        {chartLinesOrdered.map((line) => {
          return (
            <VictoryLine
              labelComponent={<VictoryTooltipMui />}
              height={5000}
              data={chartData.costForecast[line.key]}
              x="index"
              y="mean"
              labels={({datum}) => `${accounting.formatMoney(datum.mean, "$", 0)}\n${datum.index}`}
              style={{data: {stroke: `lightblue`, strokeWidth: 1}}}
              containerComponent={<VictoryContainer responsive={true} />}
            />
          );
        })}
      </VictoryChart>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export const ProjectForecastCostCodeChart = (props: {
  projectId: number | string;
  costCode: string;
  isReadOnly?: boolean;
  onClickForecast?: (data: any) => void;
}) => {
  const {projectId, costCode, isReadOnly, onClickForecast, ...rest} = props;
  const [costDataFetched, setCostDataFetched] = React.useState(false);
  const [forecastDataFetched, setForecastDataFetched] = React.useState(false);
  const [costData, setCostData] = React.useState({
    results: [],
  });
  const [forecastData, setForecastData] = React.useState({
    results: [],
  });
  React.useEffect(() => {
    // setCostDataFetched(false);
    axiosAPI.get(`/projects/${projectId}/timberline-cost-time-series/?cost_code=${costCode}`).then((response) => {
      setCostData(response.data);
      setCostDataFetched(true);
    });
    axiosAPI
      .get(`/projects/${projectId}/timberline-cost-time-series/forecast/?cost_code=${costCode}`)
      .then((response) => {
        setForecastData(response.data);
        setForecastDataFetched(true);
      });
  }, [projectId, costCode]);

  if (!costDataFetched || !forecastDataFetched) {
    return (
      <Skeleton variant="rect" width="100%">
        <div style={{height: 200}} />
      </Skeleton>
    );
  }

  return (
    <>
      <VictoryChart
        padding={{top: 15, bottom: 20, left: 40, right: 20}}
        height={100}
        // theme={VictoryTheme.material}
        // containerComponent={<VictoryVoronoiContainer />}
      >
        <VictoryAxis tickCount={6} style={{tickLabels: {fontSize: 6}}} />
        <VictoryAxis
          style={{tickLabels: {fontSize: 6}}}
          dependentAxis
          tickFormat={(x) => `$${moneyTickFormatter.format(x)}`}
        />
        <VictoryLegend
          x={0}
          y={0}
          // title="Legend"
          centerTitle
          orientation="horizontal"
          gutter={5}
          style={{title: {fontSize: 8}, labels: {fontSize: 8}, data: {fontSize: 8}}}
          data={[
            {name: costCode, symbol: {fill: "blue"}},
            {name: "Forecast", symbol: {fill: "lightblue"}},
          ]}
        />

        <VictoryBar
          // labelComponent={<VictoryTooltipMui />}
          labelComponent={
            <VictoryTooltip
              flyoutPadding={3}
              style={{fontSize: 6}}
              pointerWidth={3}
              pointerLength={4}
              cornerRadius={2}
              flyoutStyle={{
                fill: "white",
                strokeWidth: 0.5,
              }}
            />
          }
          height={5000}
          data={costData.results}
          x="index"
          y="cumsum_amount"
          labels={({datum}) =>
            `${accounting.formatMoney(datum.cumsum_amount, "$", 0)}\n${accounting.formatMoney(datum.amount, "$", 0)}\n${datum.index}`
          }
          style={{data: {fill: "blue"}}}
          // containerComponent={<VictoryContainer responsive={true} />}
        />

        <VictoryBar
          labelComponent={
            <VictoryTooltip
              flyoutPadding={3}
              style={{fontSize: 6}}
              pointerWidth={3}
              pointerLength={4}
              cornerRadius={2}
              flyoutStyle={{
                fill: "white",
                strokeWidth: 0.5,
              }}
            />
          }
          height={5000}
          data={forecastData.results}
          x="index"
          y="mean"
          labels={({datum}) => `${accounting.formatMoney(datum.mean, "$", 0)}\n${datum.index}`}
          style={{data: {fill: "lightblue", cursor: isReadOnly ? null : "pointer"}}}
          // containerComponent={<VictoryContainer responsive={true} />}
          events={[
            {
              target: "data",
              eventHandlers: {
                onClick: (event, data) => {
                  onClickForecast && !isReadOnly && onClickForecast(data);
                },
              },
            },
          ]}
        />
      </VictoryChart>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};
