import {faLock} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Typography} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ApiNoSearchAutoselectMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import BlockUI from "../../../components/GlobalLoaders";
import InnerFooter from "../../../components/InnerFooter";
import {LegacyUILink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../../hooks/useSentinelAPI";

const ProjectPermissions = (props) => {
  const {project, ...rest} = props;
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      users: [],
    },
  });
  const postCurrentPage = usePostCurrentPage();
  const blockUI = useBlockUI();

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching permission info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Permissions`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Permissions</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faLock} /> {project.display} - Permissions
          </PaperPanel.Header.Title>
          {/* <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions> */}
        </PaperPanel.Header>
        <PaperPanel.Body>
          <FinalForm
            onSubmit={(values) => {
              postCurrentPage.mutateAsync(values).then((values) => pageDataQuery.refetch());
            }}
            initialValues={pageDataQuery.data}
            {...rest}
          >
            {({handleSubmit, form, submitting, pristine, values}) => (
              <>
                <BeforeUnload block={!pristine} />
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body p={2}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <ApiNoSearchAutoselectMui
                            name="users"
                            label="Users"
                            valueProp="id"
                            labelProp="full_name"
                            baseURL={`users/`}
                            qsParams={{
                              page_size: 1000,
                              is_active: "True",
                            }}
                            multiple
                          />
                        </Grid>
                      </Grid>
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </PaperPanel.Body>
                    <InnerFooter>
                      <FormActions.SaveButton disabled={submitting || pristine} />
                    </InnerFooter>
                  </FinalFormKeyboardShortcuts>
                </form>
              </>
            )}
          </FinalForm>
        </PaperPanel.Body>
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/permissions/`} mt={2} />

      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
    </>
  );
};

export default ProjectPermissions;
