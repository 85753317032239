import {faBook} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, PaperProps} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import Moment from "react-moment";
import {useSentinelListAPI} from "../hooks/useSentinelAPI";
import {makeNovoClasses} from "../theme";
import {PaginationWithPageSize} from "./Pagination";
import PaperPanel from "./PaperPanel";
import {ReactTableMui} from "./ReactTableMui";

const ActivityStream = (props: {
  url: string;
  initialPageSize?: number;
  paperPanelProps?: PaperProps;
  title?: string;
}) => {
  const {url, initialPageSize = 20, paperPanelProps = {}, title = "Activity Stream", ...rest} = props;
  const novoClasses = makeNovoClasses();
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(initialPageSize);
  const {query: activityStreamQuery} = useSentinelListAPI(`${url}?page_size=${pageSize}&page=${page}`, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });

  return (
    <PaperPanel square variant="outlined" {...paperPanelProps}>
      <PaperPanel.Header isLoading={activityStreamQuery.isFetching}>
        <PaperPanel.Header.Title>
          <FontAwesomeIcon icon={faBook} /> {title}
        </PaperPanel.Header.Title>
        <PaperPanel.Header.Actions>
          <PaperPanel.Header.Action border>
            <PaperPanel.Header.RefreshButton
              onClick={() => activityStreamQuery.refetch()}
              isFetching={activityStreamQuery.isFetching}
            />
          </PaperPanel.Header.Action>
        </PaperPanel.Header.Actions>
      </PaperPanel.Header>
      <PaperPanel.Body>
        <ReactTableMui
          size="small"
          className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
          // containerComponent={Paper}
          rightAlignColumns={["timestamp"]}
          columns={[
            {
              Header: "Actor",
              accessor: "actor_object.full_name",
              Cell: ({value, row}) => <Link href={`mailto:${row.original.actor_object.email}`}>{value}</Link>,
            },
            {
              Header: "Verb",
              accessor: (value) => {
                return `${value.verb} ${value.description ? value.description : ""}`;
              },
            },
            {
              Header: "Object/Target",
              accessor: (value) => {
                return `${value.action_object?.display} ${value.target_object?.display}`;
              },
              Cell: ({value, row}) => {
                const entry = row.original;
                return (
                  <>
                    {entry.action_object && <Link href={entry.action_object.url}>{entry.action_object.display}</Link>}
                    {entry.target_object && (
                      <>
                        {" "}
                        on <Link href={entry.target_object.url}>{entry.target_object.display}</Link>
                      </>
                    )}
                  </>
                );
              },
            },
            {
              Header: "Timestamp",
              accessor: "timestamp",
              Cell: ({value, row}) => (
                <>
                  <Moment
                    // calendar
                    withTitle
                    date={value}
                  />
                </>
              ),
            },
          ]}
          initialState={{
            sortBy: [
              {
                id: "timestamp",
                desc: true,
              },
            ],
          }}
          data={activityStreamQuery.data.results}
          {...rest}
        />
        {/* {activityStreamQuery.data.total_pages > 1 && ( */}
        <PaperPanel.Footer display="flex" justifyContent="flex-end">
          <PaginationWithPageSize
            count={activityStreamQuery.data.total_pages}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
            pageSizes={[20, 50, 100]}
          />
        </PaperPanel.Footer>
        {/* )} */}
        {/* <pre>{JSON.stringify(activityStreamQuery, null, 2)}</pre> */}
      </PaperPanel.Body>
    </PaperPanel>
  );
};

export default ActivityStream;
