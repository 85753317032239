import {faCartShopping, faCloud, faSortAlphaDown, faSortAlphaUp} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Divider, FormControlLabel, Grid, Link, Switch, Tab, Tooltip, Typography} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import moment from "moment";
import {useSnackbar} from "notistack";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TabCountChip} from "../../../components/Tabs";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {getAxiosAPIResponseMessage, useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import getQueryStringFromIds from "../../../utils/getQueryStringFromIds";
import {PurchaseOrderUpdateDialogForm} from "./PurchaseOrderForms";
import PurchaseOrderPaperItem from "./PurchaseOrderPaperItem";
import {CreateMissingBoxFoldersConfirmationDialog, FixBoxFolderNamesConfirmationDialog} from "../../../components/Box";

const ProjectPurchaseOrders = (props) => {
  const {project, userContact, ...rest} = props;

  const [activePurchaseOrder, setActivePurchaseOrder] = React.useState({} as any);
  const [showPurchaseOrderDialog, setShowPurchaseOrderDialog] = React.useState(false);
  const [showPurchaseOrderEditDialog, setShowPurchaseOrderEditDialog] = React.useState(false);
  const [createMissingBoxFolders, setCreateMissingBoxFolders] = React.useState(false);
  const [fixBoxFolderNamesDialog, setFixBoxFolderNamesDialog] = React.useState(false);
  const [initialBudget, setInitialBudget] = React.useState(null);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);

  const currentPageQuery = useFetchCurrentPage({
    initialData: {},
  });

  const [
    purchaseOrderFilterOptions,
    setPurchaseOrderFilterOption,
    clearPurchaseOrderFilterOption,
    clearAllPurchaseOrderFilterOptions,
  ] = useFilterOptions(["Status", "To Company", "Search"]);
  const novoClasses = makeNovoClasses();
  const [selectedTab, setSelectedTab] = useQueryState("tab", "purchaseorders");
  const [timberlineCommitmentType, setTimberlineCommitmentType] = React.useState("po");
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();

  const purchaseOrderFilterParams = {
    status: purchaseOrderFilterOptions.Status.value,
    to_company_id: purchaseOrderFilterOptions["To Company"].value,
    q: purchaseOrderFilterOptions.Search.value,
  };

  const {
    query: purchaseOrderQuery,
    create: createPurchaseOrder,
    update: updatePurchaseOrder,
    // delete: deleteRFI,
    rpc: purchaseOrderRPC,
    onDragEnd: purchaseOrderOnDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/pos/?page_size=${pageSize}&page=${page}&${qs.stringify(purchaseOrderFilterParams)}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const {query: toCompanyQuery} = useSentinelListAPI(`projects/${project.id}/pos/to-companies/`, {
    initialData: [],
  });

  const toCompanyOptions = toCompanyQuery.data;

  const purchaseOrders = purchaseOrderQuery.data.results;

  const {query: purchaseOrderStatusQuery} = useSentinelListAPI(`projects/${project.id}/pos/status/`, {
    initialData: [],
  });
  const purchaseOrderStatusOptions = purchaseOrderStatusQuery.data;

  const {query: timberlineCommitmentsQuery} = useSentinelListAPI(
    `projects/${project.id}/timberline-commitments/?type=${timberlineCommitmentType}`,
    {
      initialData: {
        results: [],
      },

      keepPreviousData: true,
    }
  );

  const timberlineCommitments = timberlineCommitmentsQuery.data?.results;

  const reorderPos = (orderBy) => {
    blockUI.blockUI("Reordering...");
    axiosAPI
      .post(`/projects/${project.id}/actions/reposition_pos/?order_by=${orderBy}`, {}, {baseURL: ""})
      .then(() => {
        purchaseOrderQuery.refetch().then(() => blockUI.unblockUI());
      })
      .catch((error) => {
        enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
        blockUI.unblockUI();
      });
  };

  const pdfQueryString = React.useMemo(
    () => getQueryStringFromIds(undefined, purchaseOrderFilterParams),
    [purchaseOrderFilterParams]
  );

  return (
    <>
      <Helmet title={`${project.display} - Purchase Orders`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Purchase Orders</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <TabList
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Purchase Orders
                    <TabCountChip
                      isLoading={!purchaseOrderQuery.isFetchedAfterMount}
                      count={purchaseOrderQuery.data?.count}
                    />
                  </Box>
                }
                value="purchaseorders"
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Timberline
                    <TabCountChip
                      isLoading={!timberlineCommitmentsQuery.isFetchedAfterMount}
                      count={timberlineCommitmentsQuery.data?.count}
                    />
                  </Box>
                }
                value="timberline"
              />
            </TabList>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="purchaseorders">
            <Box mx={0}>
              <PaperPanel.TabHeader isLoading={purchaseOrderQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faCartShopping} /> Purchase Orders
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <Tooltip title={project.is_closed ? "Project is Closed" : "Create Purchase Order"}>
                      <div>
                        <PaperPanel.Header.CreateButton
                          disabled={!purchaseOrderQuery.isFetchedAfterMount || project.is_closed}
                          onClick={() => {
                            setShowPurchaseOrderDialog(true);
                          }}
                        >
                          Create
                        </PaperPanel.Header.CreateButton>
                      </div>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/projects/${project.id}/pos/?${pdfQueryString}`}
                      buildReportURL={`/reports2/projects/${project.id}/pos/filter/`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.ReorderButton onReorder={(orderBy) => reorderPos(orderBy)}>
                      {(setOpen) => (
                        <>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              setOpen(false);
                              reorderPos("to_company__name");
                            }}
                          >
                            <FontAwesomeIcon icon={faSortAlphaDown} fixedWidth />
                            Subcontractor Name
                          </PaperPanel.Header.Menu.MenuItem>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              setOpen(false);
                              reorderPos("-to_company__name");
                            }}
                          >
                            <FontAwesomeIcon icon={faSortAlphaUp} fixedWidth />
                            Subcontractor Name
                          </PaperPanel.Header.Menu.MenuItem>
                        </>
                      )}
                    </PaperPanel.Header.ReorderButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border pr={0}>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                              setCreateMissingBoxFolders(true);
                            }}
                          >
                            Create Missing Box Folders
                          </PaperPanel.Header.Menu.MenuItem>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                              setFixBoxFolderNamesDialog(true);
                            }}
                          >
                            Fix Box Folder Names
                          </PaperPanel.Header.Menu.MenuItem>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action pl={0}>
                    <PaperPanel.Header.RefreshButton
                      isFetching={purchaseOrderQuery.isFetching}
                      onClick={() => purchaseOrderQuery.refetch()}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PaperPanel.Toolbar p={1} mx={-3} mt={-2}>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                    <FilterSearch
                      label="Search"
                      value={purchaseOrderFilterOptions.Search.value}
                      name="Search"
                      onChange={(value) => {
                        setPage(1);
                        setPurchaseOrderFilterOption("Search", value, value);
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FilterSelect
                      label="Status"
                      name="Status"
                      options={purchaseOrderStatusOptions}
                      value={purchaseOrderFilterOptions.Status.value}
                      showStatusIcon
                      onChange={(value, label) => {
                        setPurchaseOrderFilterOption("Status", value, label);
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FilterSelect
                      label="To Company"
                      name="To Company"
                      options={toCompanyOptions}
                      value={purchaseOrderFilterOptions["To Company"].value}
                      onChange={(value, label) => {
                        setPurchaseOrderFilterOption("To Company", value, label);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={12} xs={12}>
                    <FilterOptionChips
                      filterOptions={purchaseOrderFilterOptions}
                      onDelete={(key) => {
                        clearPurchaseOrderFilterOption(key);
                      }}
                      onDeleteAll={() => {
                        clearAllPurchaseOrderFilterOptions();
                      }}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <Box display={"flex"} justifyContent="flex-end">
                      <Pagination count={purchaseOrderQuery.data.total_pages} page={page} setPage={setPage} />
                    </Box>
                  </Grid>
                </Grid>
              </PaperPanel.Toolbar>
              <PaperPanel.Body mx={-3} mb={-3}>
                <VirtualizedList
                  itemList={purchaseOrders}
                  isVirtualized={purchaseOrders.length > 200}
                  onDragEnd={purchaseOrderOnDragEnd}
                  boxFolderId={props.project.box_folder_id}
                >
                  {(props) => {
                    const {listItem: purchaseOrder, boxFolderId, ...rest} = props;
                    return (
                      <PurchaseOrderPaperItem
                        key={purchaseOrder.id}
                        purchaseOrder={purchaseOrder}
                        boxFolderId={project.box_folder_id}
                        onEditPurchaseOrder={() => {
                          setActivePurchaseOrder(purchaseOrder);
                          setShowPurchaseOrderEditDialog(true);
                        }}
                        boxFolderId={boxFolderId}
                        {...rest}
                      />
                    );
                  }}
                </VirtualizedList>
              </PaperPanel.Body>
              {(purchaseOrderQuery.data.total_pages > 1 || pageSize > 100) && (
                <PaperPanel.Footer display="flex" justifyContent="flex-end">
                  <PaginationWithPageSize
                    count={purchaseOrderQuery.data.total_pages}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                  />
                </PaperPanel.Footer>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="timberline">
            <Box mb={-3} mt={-2}>
              <PaperPanel.TabHeader isLoading={timberlineCommitmentsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faCartShopping} /> Timberline Purchase Orders
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={timberlineCommitmentType === "po"}
                          onChange={(event) => {
                            event.target.checked ? setTimberlineCommitmentType("po") : setTimberlineCommitmentType("");
                          }}
                        />
                      }
                      label="Only Purchase Orders"
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.RefreshButton
                    isFetching={timberlineCommitmentsQuery.isFetching}
                    onClick={() => timberlineCommitmentsQuery.refetch()}
                  />
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
              <PaperPanel.Body sectionBody>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  rightAlignColumns={["amount", "approved_commitment_co_amount", "committed"]}
                  showFooter={Boolean(timberlineCommitments.length)}
                  columns={[
                    {
                      Header: "Commitment",
                      accessor: "commitment",
                      Cell: ({value, row}) => (
                        <Link underline="always" href={`/timberline/subcontracts/${value}/`}>
                          {value}
                        </Link>
                      ),
                    },
                    {
                      Header: "Date",
                      accessor: "date",
                    },
                    {
                      Header: "Description",
                      accessor: "description",
                    },
                    {
                      Header: "Sentinel Commitment",
                      accessor: "sentinel_commitment",
                      sortType: "basic",
                      Cell: ({value, row}) => (
                        <Link underline="always" href={row.original.sentinel_commitment_url}>
                          {value}
                        </Link>
                      ),
                    },
                    {
                      Header: "Vendor",
                      accessor: "vendor_name",
                    },
                    {
                      Header: "Amount",
                      accessor: "amount",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = timberlineCommitments.reduce((sum, row) => row.amount + sum, 0);
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                    {
                      Header: "Changes",
                      accessor: "approved_commitment_co_amount",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = timberlineCommitments.reduce(
                          (sum, row) => row.approved_commitment_co_amount + sum,
                          0
                        );
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                    {
                      Header: "Total",
                      accessor: "committed",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = timberlineCommitments.reduce((sum, row) => row.committed + sum, 0);
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                  ]}
                  initialState={{
                    sortBy: [
                      {
                        id: "commitment",
                        desc: false,
                      },
                    ],
                  }}
                  data={timberlineCommitments}
                />
                {/* <pre>{JSON.stringify(timberlineCommitmentsQuery, null, 2)}</pre> */}
              </PaperPanel.Body>
            </Box>
          </TabPanel>
        </PaperPanel>
      </TabContext>
      <LegacyUILink href={`/projects/${project.id}/pos/legacy/`} mt={2} />
      {/* <h1>POS</h1>
      <pre>{JSON.stringify(purchaseOrders, null, 2)}</pre>
      <h1>Current Page Query</h1>
      <pre>{JSON.stringify(currentPageQuery, null, 2)}</pre>
      <h1>Purchase Order Query</h1>
      <pre>{JSON.stringify(purchaseOrderQuery, null, 2)}</pre>
      <h1>Props</h1>
      <pre>{JSON.stringify(props, null, 2)}</pre> */}
      <CreateMissingBoxFoldersConfirmationDialog
        isOpen={createMissingBoxFolders}
        setIsOpen={setCreateMissingBoxFolders}
        rpc={purchaseOrderRPC}
      />
      <FixBoxFolderNamesConfirmationDialog
        isOpen={fixBoxFolderNamesDialog}
        setIsOpen={setFixBoxFolderNamesDialog}
        rpc={purchaseOrderRPC}
      />

      <PurchaseOrderUpdateDialogForm
        projectId={project.id}
        isNew
        isOpen={showPurchaseOrderDialog}
        restrictNovoSigners={project.restrict_novo_signers}
        handleClose={() => {
          setShowPurchaseOrderDialog(false);
        }}
        initialValues={{
          number: purchaseOrderQuery?.data?.metadata?.next_number,
          issued_date: moment().format("YYYY-MM-DD"),
          from_company: userContact?.company,
          budget: initialBudget,
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Creating purchase order...");
          values.display = `${values.budget.csi_code} - ${values.description}`;

          const editAfterSubmit = values.editAfterSubmit;
          if (editAfterSubmit) {
            delete values.editAfterSubmit;
          }
          createPurchaseOrder
            .mutateAsync(values)
            .then((newPurchaseOrder) => {
              if (editAfterSubmit) {
                setShowPurchaseOrderDialog(false);
                /* @ts-ignore */
                window.location = `/pos/${newPurchaseOrder.id}/`;
                // blockUI.unblockUI();
              } else {
                setShowPurchaseOrderDialog(false);
                blockUI.unblockUI();
                currentPageQuery.refetch();
                purchaseOrderQuery.refetch();
              }
            })
            .catch(() => {
              setShowPurchaseOrderDialog(false);
            });
        }}
      />

      <PurchaseOrderUpdateDialogForm
        initialValues={activePurchaseOrder}
        projectId={project.id}
        isOpen={showPurchaseOrderEditDialog}
        restrictNovoSigners={project.restrict_novo_signers}
        isReadOnly={activePurchaseOrder?.is_closed}
        handleClose={() => {
          setActivePurchaseOrder({});
          setShowPurchaseOrderEditDialog(false);
        }}
        onSubmit={(values) => {
          setShowPurchaseOrderEditDialog(false);

          updatePurchaseOrder.mutateAsync(values);
        }}
      />
    </>
  );
};

export default ProjectPurchaseOrders;
