import {useMediaQuery} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import React from "react";
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import {AutoSizer, CellMeasurer, CellMeasurerCache, List} from "react-virtualized";

type Item = {
  id: number;
};

const VirtualizedList = (props: {
  isVirtualized?: boolean;
  disableDragAndDrop?: boolean;
  onDragEnd?: (result: any, positionProp?: string) => any;
  children: (childProps: {listItem: any}) => React.ReactNode;
  itemList: Item[] | [];
  defaultHeight?: number;
  [rest: string]: any;
}) => {
  const {isVirtualized, disableDragAndDrop, defaultHeight = 50, ...rest} = props;

  if (isVirtualized) {
    return <ListWithVirtualization defaultHeight={defaultHeight} {...rest} />;
  }
  if (disableDragAndDrop) {
    return <ListWithoutDragDrop {...rest} />;
  }

  return <ListWithDragAndDrop {...rest} />;
};

const ListWithVirtualization = (props: {
  children: (childProps: {listItem: Item}) => React.ReactNode;
  itemList: Item[];
  defaultHeight: number;
  [rest: string]: any;
}) => {
  const {children, itemList = [], defaultHeight, ...rest} = props;

  const useWidth = () => {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();
    return (
      keys.reduce((output, key) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const matches = useMediaQuery(theme.breakpoints.up(key));

        return !output && matches ? key : output;
      }, null) || "xs"
    );
  };
  const width = useWidth();

  const cache = React.useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: defaultHeight,
    })
  );

  React.useEffect(() => {
    cache.current.clearAll();
  }, [width]);

  return (
    <>
      <div>
        <div style={{width: "100%", height: "100vh"}}>
          <AutoSizer>
            {({width, height}) => {
              return (
                <List
                  width={width}
                  height={height}
                  deferredMeasurementCache={cache.current}
                  rowHeight={cache.current.rowHeight}
                  rowCount={itemList.length}
                  columnCount={1}
                  rowRenderer={({key, index, style, parent}) => {
                    const listItem = itemList[index];

                    return (
                      <CellMeasurer key={key} cache={cache.current} parent={parent} columnIndex={0} rowIndex={index}>
                        <div style={style}>{children({listItem: listItem, ...rest})}</div>
                      </CellMeasurer>
                    );
                  }}
                />
              );
            }}
          </AutoSizer>
        </div>
      </div>
    </>
  );
};

const ListWithDragAndDrop = (props: {
  children: (childProps: {listItem: Item; isDragging: boolean; dragHandleProps: any}) => React.ReactNode;
  itemList: Item[];
  [rest: string]: any;
}) => {
  const {children, itemList = [], onDragEnd, dragHandleProps, ...rest} = props;

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {itemList.map((listItem, index) => {
              return (
                <Draggable key={listItem.id} draggableId={listItem.id.toString()} index={index}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.draggableProps}>
                      {children({
                        listItem: listItem,
                        isDragging: snapshot.isDragging,
                        dragHandleProps: provided.dragHandleProps,

                        ...rest,
                      })}
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

const ListWithoutDragDrop = (props: {
  children: (childProps: {listItem: Item}) => React.ReactNode;
  itemList: Item[];
  [rest: string]: any;
}): React.ReactElement[] => {
  const {children, itemList = [], ...rest} = props;

  return itemList.map((listItem, index) => (
    <React.Fragment key={listItem.id}>
      {children({
        listItem: listItem,

        ...rest,
      })}
    </React.Fragment>
  ));
};

export default VirtualizedList;
