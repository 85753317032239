import ProjectMeetingDetail from "./ProjectMeetingDetail";

const AgendaDetail = (props) => {
  return (
    <>
      <ProjectMeetingDetail {...props} isAgenda />
    </>
  );
};

export default AgendaDetail;
