import {Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {useQuery} from "react-query";
import {useLocation} from "react-router-dom";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {FilterSearch} from "../../../components/Filters";
import {PageHeader} from "../../../components/PageHeader";
import {PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";

const ClientInvoiceLookup = (props) => {
  const [query, setQuery] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const {pathname} = useLocation();
  const novoClasses = makeNovoClasses();
  const jobCostDrawsQuery = useQuery(
    [query, page, pageSize],
    () =>
      axiosAPI
        .get(`${pathname}?type=ajax&q=${query}&page=${page}&page_size=${pageSize}`, {baseURL: ""})
        .then((res) => res.data),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      initialData: {
        jobCostDraws: [],
        pageInfo: {
          total_pages: 0,
          number: 1,
        },
      },
    }
  );
  return (
    <>
      <Helmet title={`Client Invoice Lookup`} />
      <Breadcrumbs>
        <Link color="inherit" href="/v2/">
          Dashboard
        </Link>
        <Typography>Accounting Tools</Typography>
        <Typography color="textPrimary">Client Invoice Lookup</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Client Invoice Lookup</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header isLoading={jobCostDrawsQuery.isFetching}>
          <PaperPanel.Header.Title>Client Invoice Lookup</PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Alert severity="info">Only contains invoices for the last 6 months.</PaperPanel.Alert>
        <PaperPanel.Toolbar p={1}>
          <FilterSearch
            label="Search"
            value={query}
            name="Search"
            onChange={(value) => {
              setPage(1);
              setQuery(value);
            }}
          />
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["charge_amount", "payment_amount", "date_stamp"]}
            columns={[
              {
                Header: "Job",
                accessor: "job",
              },
              {
                Header: "Invoice #",
                accessor: "last_invoice",
              },
              {
                Header: "Description",
                accessor: "description",
              },
              {
                Header: "Draw",
                accessor: "draw",
              },
              {
                Header: "Charge Amount",
                accessor: "charge_amount",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
              },
              {
                Header: "Payment Amount",
                accessor: "payment_amount",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
              },
              {
                Header: "Date Stamp",
                accessor: "date_stamp",
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "date_stamp",
                  desc: true,
                },
              ],
            }}
            data={jobCostDrawsQuery.data.jobCostDraws}
          />
        </PaperPanel.Body>
        <PaperPanel.Footer display="flex" justifyContent="flex-end">
          <PaginationWithPageSize
            count={jobCostDrawsQuery.data.pageInfo.total_pages}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
          />
          {/* <Pagination
            count={jobCostDrawsQuery.data.pageInfo.total_pages}
            page={page}
            color="primary"
            onChange={(event, value) => setPage(value)}
          /> */}
        </PaperPanel.Footer>
      </PaperPanel>
      {/* <pre>{JSON.stringify(jobCostDrawsQuery, null, 2)}</pre> */}
    </>
  );
};
export default ClientInvoiceLookup;
