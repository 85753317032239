import {
  faCheckCircle,
  faCircle,
  faClock,
  faExclamationCircle,
  faInfoCircle,
  faReply,
  faTimesCircle,
} from "@fortawesome/pro-solid-svg-icons";

export const getStatusIcon = (status: string, hint?: string) => {
  if (["Pending"].includes(status)) {
    return faClock;
  }
  if (["Received External Response"].includes(status)) {
    return faReply;
  }
  if (["Complete"].includes(status)) {
    return faCheckCircle;
  }
  if (["Void"].includes(status)) {
    return faTimesCircle;
  }
  return faCircle;
};

export const getImportanceIcon = (importance) => {
  if (["Low", "Normal", "Medium"].includes(importance)) {
    return faInfoCircle;
  }
  if (["High", "Urgent"].includes(importance)) {
    return faExclamationCircle;
  }
  return faCircle;
};
