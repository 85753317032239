import {faQrcode, faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import qs from "query-string";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import DropzoneMui from "../../components/DropzoneMui";
import {PageHeader} from "../../components/PageHeader";
import PaperPanel from "../../components/PaperPanel";
import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";

const JobsiteFiles = (props) => {
  const {twilioContact, project, hasJobsiteDocumentsCompanyFolder, ...rest} = props;
  const location = useLocation();
  const qsValues = qs.parse(location.search);

  const wellnessRedirectURL = `/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${qsValues?.date}/`;
  return (
    <UnauthenticatedLayout>
      <Helmet title={`${project.display} - Upload Jobsite Documents`} />

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faUpload} />
            {project.display}
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      {hasJobsiteDocumentsCompanyFolder ? (
        <PaperPanel>
          <PaperPanel.Header>
            <PaperPanel.Header.Title>
              <FontAwesomeIcon icon={faUpload} /> {twilioContact.name} - {twilioContact?.fuzzy_company?.name}
            </PaperPanel.Header.Title>
          </PaperPanel.Header>

          <PaperPanel.Body p={1}>
            <DropzoneMui
              getUploadParams={() => {
                return {
                  url: `.`,
                  // headers: {"X-CSRFToken": Cookies.get("csrftoken")},
                };
              }}
              timeout={1000 * 60 * 5}
              accept="image/*,application/pdf"
            />
          </PaperPanel.Body>
        </PaperPanel>
      ) : (
        <Alert severity="error">
          Jobsite folder for project does not exist. Please contact NOVO personnel to get this corrected.
        </Alert>
      )}
      {qsValues?.date && (
        <>
          <Box mb={1} />
          <Button
            fullWidth
            variant="contained"
            color="primary"
            size="small"
            href={wellnessRedirectURL}
            startIcon={<FontAwesomeIcon icon={faQrcode} />}
          >
            Back to QR Code
          </Button>
        </>
      )}
      {/* <pre>{JSON.stringify(qsValues, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </UnauthenticatedLayout>
  );
};

export default JobsiteFiles;
