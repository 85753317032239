import {FormApi, ValidationErrors} from "final-form";
import {isEmpty} from "lodash";
import React from "react";

const validateFormOnRender = (form: FormApi, errors: ValidationErrors, isEnabled: boolean = true) => {
  React.useEffect(() => {
    if (!isEmpty(errors) && !form.isValidationPaused() && isEnabled) {
      form.submit();
    }
  }, [isEmpty(errors), form.isValidationPaused(), isEnabled]);
};

export default validateFormOnRender;
