import {Link} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";

const V2AlertBanner = (props) => {
  return (
    <Alert severity="error" {...props}>
      You are currently on the staging/testing server. Changes made here will not be made to the production system.{" "}
      <Link href="https://sentinel.novoconstruction.com" underline="always">
        Click here
      </Link>{" "}
      to go to the production site.
    </Alert>
  );
};

export default V2AlertBanner;
