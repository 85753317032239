import {faEnvelope} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Link, Typography} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {Alert} from "@material-ui/lab";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../components/Breadcrumbs";
import BlockUI from "../../components/GlobalLoaders";
import {LegacyUILink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import {TableStatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {colorSuccess} from "../../theme/colors";
import {DocuSignCreateEnvelopeDialogForm} from "./DocuSignForms";

const DocuSignObjectEnvelopeList = (props) => {
  const {project, user, ...rest} = props;
  const hasProject = Boolean(project);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [createEnvelopeWithSupplementalDocuments, setCreateEnvelopeWithSupplementalDocuments] = React.useState(false);

  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();

  const postCurrentPage = usePostCurrentPage();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {envelopes: [], signers: [], currentEnvelope: {}},
  });
  const pageData = pageDataQuery.data;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching DocuSign Envelopes..." />;
  }

  return (
    <>
      <Helmet title={`${pageData.object.display} - DocuSign Envelopes`} />

      <Box my={1}>
        {hasProject ? (
          <ProjectBreadcrumbs project={project}>
            <Link color="inherit" href={pageData.object.url}>
              {pageData.object.display}
            </Link>
            <Typography color="textPrimary"> DocuSign Envelopes</Typography>
          </ProjectBreadcrumbs>
        ) : (
          <Breadcrumbs>
            <Typography color="textSecondary">Docusign</Typography>
            <Link href={`/v2/docusign/envelopes/`}>
              <Typography color="textSecondary">Envelopes</Typography>
            </Link>
            <Link color="inherit" href={pageData.object.url}>
              {pageData.object.display}
            </Link>
          </Breadcrumbs>
        )}
      </Box>
      {!pageData.object.can_docusign && (
        <Box mb={1}>
          <Alert severity="warning">
            <strong>Cannot DocuSign</strong> {pageData.object.can_docusign_reason}
          </Alert>
        </Box>
      )}

      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faEnvelope} /> DocuSign Envelopes{" "}
            {pageDataQuery.isFetchedAfterMount ? `(${accounting.formatNumber(pageData.envelopes.length)})` : null}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {pageData.object.can_docusign && (
              <>
                {pageData.showSupplementalDocuSignDocuments ? (
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFSplitButton
                      href={pageData.object.docusign_envelope_preview_url}
                      title="Preview Envelope"
                      target="_blank"
                    >
                      <PaperPanel.Header.Menu.PDFMenuItem
                        href={`${pageData.object.docusign_envelope_preview_url}?with-supplemental-documents=True`}
                      >
                        Preview with Supplemental Documents
                      </PaperPanel.Header.Menu.PDFMenuItem>
                    </PaperPanel.Header.PDFSplitButton>
                    <Box ml={1} />
                    <PaperPanel.Header.ColoredSplitButton
                      title="Create Envelope"
                      color={colorSuccess}
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setCreateEnvelopeWithSupplementalDocuments(false);
                        setShowCreateDialog(true);
                      }}
                    >
                      {(setOpen) => (
                        <div>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              setCreateEnvelopeWithSupplementalDocuments(true);
                              setShowCreateDialog(true);
                              setOpen(false);
                            }}
                          >
                            Create with Supplemental Documents
                          </PaperPanel.Header.Menu.MenuItem>
                        </div>
                      )}
                    </PaperPanel.Header.ColoredSplitButton>
                  </PaperPanel.Header.Action>
                ) : (
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButton
                      href={pageData.object.docusign_envelope_preview_url}
                      title="Preview Envelope"
                      target="_blank"
                    />
                    <Box ml={1} />
                    <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialog(true)}>
                      Create Envelope
                    </PaperPanel.Header.CreateButton>
                  </PaperPanel.Header.Action>
                )}
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => pageDataQuery.refetch()}
                    isFetching={pageDataQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </>
            )}
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}
            rightAlignColumns={["created"]}
            columns={[
              {
                Header: "Envelope",
                accessor: "envelope_id",
                disableSortBy: true,
                Cell: ({value, row}) => (
                  <>
                    <TableStatusLabel status={row.original.status || "Unknown"}>
                      {hasProject ? (
                        <Link underline="always" href={`/v2/projects/${project.id}/docusign/envelopes/${value}/`}>
                          {value.substring(0, 8)}...
                        </Link>
                      ) : (
                        <Link underline="always" href={`/v2/docusign/envelopes/${value}/`}>
                          {value.substring(0, 8)}...
                        </Link>
                      )}
                    </TableStatusLabel>
                  </>
                ),
              },
              {
                Header: "Object",
                accessor: "object.display",
                Cell: ({value, row}) => (
                  <Link underline="always" href={`${row.original.object.url}`}>
                    {value}
                  </Link>
                ),
              },
              {
                Header: "Obj Status",
                accessor: "object.status_display",
              },
              {
                Header: "Created By",
                accessor: "created_by.full_name",
              },
              {
                Header: "Created",
                accessor: "created",
                Cell: ({value, row}) => {
                  if (project?.timezone) {
                    return (
                      <Box whiteSpace="nowrap">
                        {value ? moment.tz(value, project.timezone).format("ddd, MMM D, YYYY, h:mm:ss A z") : ""}
                      </Box>
                    );
                  }
                  return (
                    <Box whiteSpace="nowrap">{value ? moment(value).format("ddd, MMM D, YYYY, h:mm:ss A z") : ""}</Box>
                  );
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "created",
                  desc: true,
                },
              ],
            }}
            data={pageData.envelopes}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <LegacyUILink href={pageData.legacyURL} mt={2} />

      <DocuSignCreateEnvelopeDialogForm
        project={project}
        object={pageData.object}
        isOpen={showCreateDialog}
        handleClose={() => setShowCreateDialog(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Creating DocuSign Envelope...");
          postCurrentPage
            .mutateAsync({action: "create_envelope", ...values})
            .then((response) => {
              // setShowCreateDialog(false);
              // blockUI.unblockUI();
              window.location.href = response.url;
            })
            .catch(() => blockUI.unblockUI());
        }}
        initialValues={{
          with_supplemental_documents: createEnvelopeWithSupplementalDocuments,
          cc_contacts: pageData.ccContacts || [],
        }}
        currentEnvelope={pageData.currentEnvelope}
        signers={pageData.signers}
      />

      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default DocuSignObjectEnvelopeList;
