import {grey, red} from "@material-ui/core/colors";
import {createTheme, makeStyles} from "@material-ui/core/styles";

export const novoTheme = createTheme({
  palette: {
    primary: {main: grey[800]},
    // primary: {main: "#212322"},
    secondary: {main: "#ffbf3f"},
    novo: "#ffbf3f",
    text: {
      novo: "#ffbf3f",
    },
    background: {
      default: "#fff",
    },
  },

  typography: {
    fontFamily: ["Open Sans", "Arial", "Helvetica", "Sans-Serif"].join(","),
    fontSize: 13,
    body1: {
      fontSize: 13,
    },
    subtitle1: {
      fontSize: 11,
      color: grey[600],
    },
    h1: {
      fontSize: "2rem",
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "1.75rem",
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: "1.25rem",
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h5: {
      fontSize: "1.125rem",
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h6: {
      fontSize: "1.0625rem",
      fontWeight: 600,
      lineHeight: 1.2,
    },
  },
  sidebar: {
    color: grey[800],
    background: "#f7f7f7",
    badge: {
      color: "#fff",
      background: red[500],
    },
  },
});

export const makeNovoClasses = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape, typography} = theme;
  return {
    smallTable: {
      "& .MuiTableCell-body, .MuiTableCell-head, .MuiTableCell-footer": {
        fontSize: "11px",
      },
      "& .MuiTableCell-sizeSmall": {
        padding: "2px 8px 2px 8px",
      },
    },
    mediumTable: {
      "& .MuiTableCell-body, .MuiTableCell-head, .MuiTableCell-footer": {
        fontSize: "13px",
      },
      "& .MuiTableCell-sizeSmall": {
        padding: "2px 8px 2px 8px",
      },
    },
    stripedTable: {
      "& tr:nth-child(2n)": {
        backgroundColor: palette.grey[50],
      },
    },
    boldHeaderTable: {
      "& th": {
        fontWeight: typography.fontWeightBold,
      },
    },
    smallTab: {
      minWidth: 0,
    },
  };
});
