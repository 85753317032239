import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@material-ui/core";

import pluralize from "pluralize";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {Currency} from "../../../components/Accounting";
import {TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";

export const NameSCCOForm = (props) => {
  const {onSubmit, isOpen, handleClose, counts, ...rest} = props;
  const {selectedApprovedAmount, selectedItemCount} = counts;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            console.log(values);
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Create Subcontract Change Order</DialogTitle>
                <DialogContent>
                  {/* <BeforeUnload block={!pristine} /> */}
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <DialogContentText>
                          You have selected {pluralize(`${selectedItemCount} PCO Item`, selectedItemCount)} with a total
                          cost of <Currency number={selectedApprovedAmount} /> to create a new Subcontract Change Order.
                        </DialogContentText>
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="description" label="Change Order Description" autoFocus required />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting} label="Create" />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
