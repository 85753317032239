import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from "@material-ui/core";
import accounting from "accounting";
import {groupBy, orderBy} from "lodash";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterDate, FilterSearch, FilterSelect} from "../../../components/Filters";
import {PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import CommitmentHeaderPaperItem, {MissingCommitmentHeaderPaperItem} from "./CommitmentHeaderPaperItem";
import {SubcontractorLienReleaseDetails} from "./SubcontractorLienReleaseDetail";
import {SubcontractorLienReleaseDialogForm} from "./SubcontractorLienReleaseForms";
import SubcontractorLienReleasePaperItem from "./SubcontractorLienReleasePaperItem";

const ProjectSubcontractorLienReleaseList = (props) => {
  const {project, user, ...rest} = props;
  // const novoClasses = makeNovoClasses();
  const [showItemDialog, setShowItemDialog] = React.useState(false);
  const [showDSEnvelopeDialog, setShowDSEnvelopeDialog] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState({} as any);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Date",
    "Search",
    "Status",
    "Envelope Status",
  ]);
  const filterParams = {
    q: filterOptions.Search.value,
    date: filterOptions.Date.value,
    status: filterOptions.Status.value,
    docusign_envelope_status: filterOptions["Envelope Status"].value,
  };

  const currentPageQuery = useFetchCurrentPage({
    initialData: {
      boxFolderId: null,
    },
  });
  const boxFolderId = currentPageQuery.data.boxFolderId;

  const url = `projects/${project?.id}/subcontractor-lien-releases/?ordering=commitment_sort,invoice__invoice_date,invoice__invoice&page_size=${pageSize}&page=${page}&${qs.stringify(
    filterParams
  )}`;
  const {query: lienReleaseQuery, update: updateLienRelease} = useSentinelListAPI(url, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });
  // const postCurrentPage = usePostCurrentPage({});
  const lienReleases = lienReleaseQuery.data.results;

  const lienReleasesByCommitment = groupBy(
    lienReleases,
    (lienRelease) => lienRelease?.timberline_payment_distribution?.commitment?.commitment
  );

  // const lienReleasesByCommitmentNumbers = orderBy(Object.keys(lienReleasesByCommitment), (item) => {
  //   const number = item.split(".")[1];
  //   return number ? parseInt(number) : number;
  // });
  const lienReleasesByCommitmentNumbers = Object.keys(lienReleasesByCommitment);

  const groupedCommitments = lienReleases.reduce((accumulator, lienRelease) => {
    accumulator[lienRelease?.timberline_payment_distribution?.commitment?.commitment] =
      lienRelease?.timberline_payment_distribution?.commitment;
    return accumulator;
  }, {});

  const {query: statusQuery} = useSentinelListAPI(`projects/${project?.id}/subcontractor-lien-releases/status/`, {
    initialData: [],
  });
  const statusOptions = statusQuery.data;
  const {query: envelopeStatusQuery} = useSentinelListAPI(
    `projects/${project?.id}/subcontractor-lien-releases/envelope-status/`,
    {
      initialData: [],
    }
  );
  const envelopeStatusOptions = envelopeStatusQuery.data;

  return (
    <>
      <Helmet title={`${project.display} - Subcontractor Lien Releases`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Subcontractor Lien Releases</Typography>
      </ProjectBreadcrumbs>

      <PaperPanel>
        <PaperPanel.Header isLoading={lienReleaseQuery.isFetching}>
          <PaperPanel.Header.Title>
            Subcontractor Lien Releases{" "}
            {lienReleases.length !== 0 && (
              <>
                ({accounting.formatNumber(lienReleases.length)}/{accounting.formatNumber(lienReleaseQuery.data.count)})
              </>
            )}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                href={`/reports2/projects/${project.id}/subcontractor-lien-releases/checklist/`}
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                variant="outlined"
              >
                Checklist
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.BoxFilesButton
                href={`/v2/projects/${project.id}/box/files/projects/project/${project.id}/?folder_id=${boxFolderId}`}
                disabled={Boolean(!boxFolderId)}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => lienReleaseQuery.refetch()}
                isFetching={lienReleaseQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>

            <Grid item sm={3} xs={12}>
              <FilterDate
                name="Date"
                value={filterOptions.Date.value}
                label="Date"
                onChange={(date) => {
                  setPage(1);
                  setFilterOption("Date", date, date);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                // native
                label="Status"
                name="Status"
                options={statusOptions}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                // native
                label="Envelope Status"
                name="Envelope Status"
                options={envelopeStatusOptions}
                value={filterOptions["Envelope Status"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Envelope Status", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <PaginationWithPageSize
                count={lienReleaseQuery.data.total_pages}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
              />
            </Grid>
          </Grid>
          {/* <pre>{JSON.stringify(filterOptions, null, 2)}</pre> */}
        </PaperPanel.Toolbar>

        <PaperPanel.Body>
          {lienReleasesByCommitmentNumbers.map((commitmentId) => {
            const commitment = groupedCommitments[commitmentId];
            const lienReleasesForCommitment = lienReleasesByCommitment[commitmentId];
            return (
              <React.Fragment key={commitmentId}>
                {commitment ? (
                  <CommitmentHeaderPaperItem key={commitmentId} commitment={commitment} />
                ) : (
                  <MissingCommitmentHeaderPaperItem key={commitmentId} />
                )}
                {lienReleasesForCommitment.map((lienRelease) => {
                  return (
                    <>
                      <SubcontractorLienReleasePaperItem
                        key={lienRelease.id}
                        lienRelease={lienRelease}
                        setActiveItem={setActiveItem}
                        setShowItemDialog={setShowItemDialog}
                        onCreateDSEnvelopeClicked={(lienRelease) => {
                          if (lienRelease.to_contact) {
                            window.location = lienRelease.docusign_envelope_list_url;
                          } else {
                            setActiveItem(lienRelease);
                            setShowDSEnvelopeDialog(true);
                          }
                        }}
                      />
                      {/* <pre>{JSON.stringify(lienRelease, null, 2)}</pre> */}
                    </>
                  );
                })}
              </React.Fragment>
            );
          })}
        </PaperPanel.Body>
        {lienReleaseQuery.data.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={lienReleaseQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      <SubcontractorLienReleaseDialogForm
        initialValues={activeItem}
        isOpen={showDSEnvelopeDialog}
        projectId={project.id}
        handleClose={() => setShowDSEnvelopeDialog(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateLienRelease.mutateAsync(values).then((lienRelease) => {
            blockUI.unblockUI();
            window.location = lienRelease.docusign_envelope_list_url;
          });
        }}
      />

      <Dialog open={showItemDialog} onClose={() => setShowItemDialog(false)} maxWidth="md" fullWidth>
        <DialogTitle id="form-dialog-title">Item Detail</DialogTitle>
        <DialogContent>
          <PaperPanel.Body>
            <SubcontractorLienReleaseDetails project={project} lienRelease={activeItem} />
            <Link href={`/v2/projects/${project.id}/subcontractor-lien-releases/${activeItem?.id}/`}>
              /v2/projects/{project.id}/subcontractor-lien-releases/{activeItem?.id}/
            </Link>
            {/* <pre>{JSON.stringify(activeItem, null, 2)}</pre> */}
          </PaperPanel.Body>
        </DialogContent>
        <DialogActions>
          <Button color="primary" onClick={() => setShowItemDialog(false)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      {/* lienReleasesByCommitmentNumbers
      <pre>{JSON.stringify(lienReleasesByCommitmentNumbers, null, 2)}</pre>
      groupedCommitments
      <pre>{JSON.stringify(groupedCommitments, null, 2)}</pre>
      lienReleasesByCommitment
      <pre>{JSON.stringify(lienReleasesByCommitment, null, 2)}</pre> */}
    </>
  );
};
export default ProjectSubcontractorLienReleaseList;
