import {Helmet} from "react-helmet";

const Exception = (props) => {
  return (
    <>
      <Helmet title="Exception Page" />
      <h1>This should raise an exception</h1>
      <BogusComponent />
    </>
  );
};

export default Exception;
