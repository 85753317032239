import React from "react";

const useFilterOptions = (keys = [], initialValues = {}) => {
  const getInitialValue = (force = false) => {
    let initialValue = {};
    keys.map((key) => {
      initialValue[key] = initialValues[key] && !force ? initialValues[key] : {value: "", label: ""};
    });
    return initialValue;
  };

  const [filterOptions, setFilterOptions] = React.useState(() => getInitialValue());

  const setFilterOption = (key, value, label = "") => {
    setFilterOptions((priorFilterOptions) => {
      priorFilterOptions[key] = {
        value: value,
        label: label,
      };
      return {...priorFilterOptions};
    });
  };
  const clearFilterOption = (key) => {
    setFilterOptions((priorFilterOptions) => {
      priorFilterOptions[key] = {
        value: "",
        label: "",
      };
      return {...priorFilterOptions};
    });
  };
  const clearAllFilterOptions = () => {
    setFilterOptions(getInitialValue(true));
  };

  return [
    filterOptions as {
      [key: string]: {
        value: string | number;
        label: string | number;
      };
    },
    setFilterOption,
    clearFilterOption,
    clearAllFilterOptions,
  ] as const;
};

export default useFilterOptions;
