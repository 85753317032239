export const truncateString = (str, num) => {
  if (!str) {
    return "";
  }
  if (str.length <= num) {
    return str;
  }
  return `${str.slice(0, num)}...`;
};

export const normalizeCostCode = (costCode: string) => {
  if (!costCode) return "";
  costCode = costCode.replace(/-/g, "");
  return costCode.slice(0, 2) + "-" + costCode.slice(2);
};
