import axios from "axios";
import Bloodhound from "bloodhound-js";
import PropTypes from "prop-types";
import React from "react";
import Autosuggest from "react-autosuggest";
import {axiosAPI, staticDataAPI} from "../api";
import {groupBy} from "../common/utils";

const theme = {
  container: "autosuggest",
  input: "form-control",
  suggestionsContainer: "dropdown open",
  suggestionsList: "dropdown-menu",
  suggestion: "suggestion",
  suggestionHighlighted: "suggestion--highlighted",
};

const multiSectionTheme = {
  container: "autosuggest",
  input: "form-control",
  suggestionsContainer: "dropdown open dropdown-menu multi-section",
  sectionContainer: "section-container",
  suggestion: "suggestion",
  suggestionHighlighted: "suggestion--highlighted",
  sectionTitle: "section-title",
};

export {theme as autosuggestTheme};
export {multiSectionTheme as autosuggestMultiSectionTheme};

export const SimpleAutosuggest = (props) => {
  const {suggestions: initialSuggestions, value, autoFocus, fluid, required, placeholder, inputProps, ...rest} = props;

  const [suggestions, setSuggestions] = React.useState(initialSuggestions);

  const bloodhound = new Bloodhound({
    local: initialSuggestions,
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    datumTokenizer: Bloodhound.tokenizers.whitespace,
  });

  const _inputProps = {
    autoFocus: autoFocus,
    required: required,
    placeholder: placeholder,
    value: value,
    onChange: (event, {newValue}) => {
      if (props.onChange) {
        props.onChange(newValue);
      }
    },
    onBlur: (event) => {
      if (props.onBlur) {
        props.onBlur(event);
      }
    },
    ...inputProps,
  };
  return (
    <Autosuggest
      suggestions={suggestions}
      theme={theme}
      renderSuggestion={(suggestion) => suggestion}
      getSuggestionValue={(suggestion) => suggestion}
      inputProps={_inputProps}
      onSuggestionsClearRequested={() => setSuggestions([])}
      onSuggestionsFetchRequested={({value}) => {
        bloodhound.search(value, (datum) => {
          setSuggestions(datum);
        });
      }}
      {...rest}
    />
  );
};
SimpleAutosuggest.propTypes = {
  suggestions: PropTypes.array.isRequired,
  inputProps: PropTypes.object,
};

export class CompanyAutosuggest extends React.Component {
  state = {
    suggestions: [],
    isLoading: false,
  };

  render() {
    const {value, autoFocus, fluid, required, placeholder, inputProps, ...rest} = this.props;
    const {suggestions} = this.state;
    const _inputProps = {
      autoFocus: autoFocus,
      required: required,
      placeholder: placeholder,
      value: value,
      onChange: (event, {newValue}) => {
        if (this.props.onChange) {
          this.props.onChange(newValue);
        }
      },
      onBlur: (event, {highlightedSuggestion}) => {
        if (this.props.onBlur) {
          this.props.onBlur(event);
        }
      },
      ...inputProps,
    };
    return (
      <div>
        <Autosuggest
          theme={theme}
          suggestions={suggestions}
          onSuggestionsFetchRequested={(query) => {
            this.setState({isLoading: true});
            axiosAPI.get(`companies/search/?q=${query.value}`).then((response) => {
              this.setState({
                isLoading: false,
                suggestions: response.data.results,
              });
            });
          }}
          onSuggestionsClearRequested={() => {
            this.setState({
              suggestions: [],
            });
          }}
          getSuggestionValue={(suggestion) => {
            return suggestion.name;
          }}
          renderSuggestion={(suggestion) => {
            return <div>{suggestion.name}</div>;
          }}
          shouldRenderSuggestions={(value) => {
            return value.trim().length >= 3;
          }}
          inputProps={_inputProps}
          {...rest}
        />
      </div>
    );
  }
}

export class ContactAutosuggest extends React.Component {
  state = {
    suggestions: [],
    isLoading: false,
  };

  render() {
    const {value, autoFocus, fluid, required, placeholder, inputProps, ...rest} = this.props;
    const {suggestions} = this.state;
    const _inputProps = {
      autoFocus: autoFocus,
      required: required,
      placeholder: placeholder,
      value: value,
      onChange: (event, {newValue}) => {
        if (this.props.onChange) {
          this.props.onChange(newValue);
        }
      },
      onBlur: (event, {highlightedSuggestion}) => {
        if (this.props.onBlur) {
          this.props.onBlur(event);
        }
      },
      ...inputProps,
    };
    return (
      <div>
        <Autosuggest
          theme={theme}
          suggestions={suggestions}
          onSuggestionsFetchRequested={(query) => {
            this.setState({isLoading: true});
            axiosAPI.get(`contacts/search/?q=${query.value}`).then((response) => {
              this.setState({
                isLoading: false,
                suggestions: response.data.results,
              });
            });
          }}
          onSuggestionsClearRequested={() => {
            this.setState({
              suggestions: [],
            });
          }}
          getSuggestionValue={(suggestion) => suggestion.full_name}
          renderSuggestion={(suggestion) => (
            <div>
              {suggestion.full_name} - <small className="text-muted">{suggestion.company.name}</small>
            </div>
          )}
          shouldRenderSuggestions={(value) => {
            return value.trim().length >= 3;
          }}
          inputProps={_inputProps}
          {...rest}
        />
      </div>
    );
  }
}

export class CSICodeAutosuggest extends React.Component {
  state = {
    suggestions: [],
    isLoading: false,
  };

  formatResults(results) {
    // const groupedResults = results.reduce((accumulator, currentValue) => {
    //     let groupCode = currentValue.division_code.replace(/^0+/, '');
    //     if (accumulator[groupCode] === undefined) {
    //       accumulator[groupCode] = {
    //         name: currentValue.division_display,
    //         results: [],
    //       }
    //     }
    //     accumulator[groupCode].results.push({
    //       title: currentValue.code,
    //       description: currentValue.description,
    //       display: currentValue.display,
    //     });
    //     return accumulator
    //   },
    //   {}
    // );
    return results;
    // console.log([groupedResults]);
    // return _.pluck(results, 'display');
    // return [groupedResults];
  }

  render() {
    const {value, autoFocus, fluid, required, inputProps, ...rest} = this.props;
    const {suggestions} = this.state;
    const _inputProps = {
      autoFocus: autoFocus,
      required: required,
      placeholder: "CSI Code",
      value: value,
      onChange: (event, {newValue}) => {
        if (this.props.onChange) {
          this.props.onChange(newValue);
        }
      },
      onBlur: (event, {highlightedSuggestion}) => {
        if (this.props.onBlur) {
          this.props.onBlur(event);
        }
      },
      ...inputProps,
    };
    return (
      <div>
        <Autosuggest
          theme={theme}
          suggestions={suggestions}
          onSuggestionsFetchRequested={(query) => {
            this.setState({isLoading: true});
            // this.onChange(query.value);
            return axiosAPI.get(`/csi-codes/search/?q=${query.value}`).then((response) => {
              this.setState({
                isLoading: false,
                suggestions: this.formatResults(response.data.results),
              });
            });
          }}
          onSuggestionsClearRequested={() => {
            this.setState({
              suggestions: [],
            });
          }}
          getSuggestionValue={(suggestion) => {
            return suggestion.code;
          }}
          renderSuggestion={(suggestion) => {
            return <div>{suggestion.display}</div>;
          }}
          shouldRenderSuggestions={(value) => {
            return value.trim().length >= 2;
          }}
          inputProps={_inputProps}
          {...rest}
        />
      </div>
    );
  }
}

export class CSICodeDescriptionAutosuggest extends React.Component {
  state = {
    suggestions: [],
    isLoading: false,
  };

  formatResults(results) {
    return results;
  }

  render() {
    const {value, autoFocus, fluid, required, inputProps, ...rest} = this.props;
    const {suggestions} = this.state;
    const _inputProps = {
      autoFocus: autoFocus,
      required: required,
      placeholder: "CSI Code",
      value: value,
      onChange: (event, {newValue}) => {
        if (this.props.onChange) {
          this.props.onChange(newValue);
        }
      },
      onBlur: (event, {highlightedSuggestion}) => {
        if (this.props.onBlur) {
          this.props.onBlur(event);
        }
      },
      ...inputProps,
    };
    return (
      <div>
        <Autosuggest
          theme={theme}
          suggestions={suggestions}
          onSuggestionsFetchRequested={(query) => {
            this.setState({isLoading: true});
            // this.onChange(query.value);
            axiosAPI.get(`/csi-codes/search/?q=${query.value}`).then((response) => {
              this.setState({
                isLoading: false,
                suggestions: this.formatResults(response.data.results),
              });
            });
          }}
          onSuggestionsClearRequested={() => {
            this.setState({
              suggestions: [],
            });
          }}
          getSuggestionValue={(suggestion) => {
            return suggestion.description;
          }}
          renderSuggestion={(suggestion) => {
            return <div>{suggestion.description}</div>;
          }}
          shouldRenderSuggestions={(value) => {
            return value.trim().length >= 2;
          }}
          inputProps={_inputProps}
          {...rest}
        />
      </div>
    );
  }
}

export class SpecSectionAutosuggest extends React.Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
  };
  state = {
    suggestions: [],
    isLoading: false,
  };

  suggestions = new Bloodhound({
    identify: (obj) => {
      return obj.code;
    },
    datumTokenizer: Bloodhound.tokenizers.obj.whitespace("display"),
    queryTokenizer: Bloodhound.tokenizers.whitespace,
    // local: choices[1].items
  });

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    const jsonFile = this.props.allowDivisions ? `novo_division_and_csi_codes.json` : `novo_csi_codes.json`;
    const specSectionURL = `/projects/${this.props.projectId}/spec-sections/`;
    const budgetURL = `/projects/${this.props.projectId}/budgets/`;

    axios
      .all([axiosAPI.get(specSectionURL), staticDataAPI.get(jsonFile), axiosAPI.get(budgetURL)])
      .then(
        axios.spread((specResponse, csiResponse, budgetResponse) => {
          const specOptions = specResponse.data.results.map((specSection) => {
            return {
              code: specSection.code,
              display: `${specSection.code} - ${specSection.description}`,
              type: "Spec Section",
            };
          });
          const csiOptions = csiResponse.data.map((csiCode) => {
            return {code: csiCode.code, display: csiCode.display, type: "CSI Code"};
          });
          const budgetOptions = budgetResponse.data.results.map((budget) => {
            return {
              code: budget.budget_code,
              display: `${budget.display}`,
              type: "Budget Codes",
            };
          });
          this.suggestions.add(specOptions);
          this.suggestions.add(csiOptions);
          this.suggestions.add(budgetOptions);
        })
      )
      .catch((error) => console.error(error));
  }

  render() {
    const {value, autoFocus, fluid, required, inputProps, ...rest} = this.props;
    const _inputProps = {
      autoFocus: autoFocus,
      required: required,
      placeholder: "Spec Section",
      value: value,
      onChange: (event, {newValue}) => {
        if (this.props.onChange) {
          this.props.onChange(newValue);
        }
      },
      onBlur: (event, {highlightedSuggestion}) => {
        if (this.props.onBlur) {
          this.props.onBlur(event);
        }
      },
      ...inputProps,
    };
    return (
      <div>
        <Autosuggest
          theme={multiSectionTheme}
          suggestions={this.state.suggestions}
          multiSection={true}
          onSuggestionsFetchRequested={({value}) => {
            this.suggestions.search(value, (datum) => {
              let suggestions = [];
              const groupedOptions = groupBy(datum, "type");
              Object.keys(groupedOptions).forEach((key, index) => {
                suggestions.push({
                  title: key,
                  items: groupedOptions[key],
                });
              });
              this.setState({suggestions: suggestions.filter((section) => section.items.length > 0)});
            });
          }}
          onSuggestionsClearRequested={() => {}}
          getSuggestionValue={(suggestion) => {
            return suggestion.code;
          }}
          getSectionSuggestions={(section) => {
            return section.items;
          }}
          renderSectionTitle={(section) => {
            return <strong>{section.title}</strong>;
          }}
          renderSuggestion={(suggestion) => {
            return <span>{suggestion.display}</span>;
          }}
          shouldRenderSuggestions={(value) => {
            return value.trim().length >= 2;
          }}
          inputProps={_inputProps}
          {...rest}
        />
      </div>
    );
  }
}
