import {faClock, faFileInvoiceDollar, faFunnelDollar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Link, Tooltip, Typography} from "@material-ui/core";
import {blue, deepOrange, green, red} from "@material-ui/core/colors";
import accounting from "accounting";
import classnames from "classnames";
import {Helmet} from "react-helmet";
import {Currency} from "../../../components/Accounting";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

const ProjectManagerBillingsList = (props) => {
  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      projects: [],
    },
  });
  const pageData = pageDataQuery.data;
  return (
    <>
      <Helmet title="Project Billings" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Project Billings</Typography>
      </Breadcrumbs>
      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faFileInvoiceDollar} /> My Billings
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={[
              "last_billed_date",
              "current_budget",
              "jtd_cost",
              "jtd_work_billed",
              "days_since_last_billing",
            ]}
            columns={[
              {
                Header: "Project",
                accessor: "display",
                Cell: ({value, row}) => (
                  <>
                    {row.original.is_underbilled && (
                      <>
                        <Tooltip title="This project is underbilled">
                          <FontAwesomeIcon icon={faFunnelDollar} />
                        </Tooltip>
                        <Box display="inline" mr={1} />
                      </>
                    )}
                    {row.original.is_billing_overdue && (
                      <>
                        <Tooltip title="This project billing is overdue">
                          <FontAwesomeIcon icon={faClock} />
                        </Tooltip>
                        <Box display="inline" mr={1} />
                      </>
                    )}
                    <Link href={`/v2/projects/${row.original.id}/`} underline="always">
                      {value}
                    </Link>
                  </>
                ),
              },
              {
                Header: "Current Value",
                accessor: "current_budget",
                Cell: ({value, row}) => <Currency number={value} precision={0} color={green[800]} />,
              },
              {
                Header: "Billed to Date",
                accessor: "jtd_work_billed",
                Cell: ({value, row}) => <Currency number={value} precision={0} color={blue[800]} />,
              },
              {
                Header: "Cost to Date	",
                accessor: "jtd_cost",
                Cell: ({value, row}) => <Currency number={value} precision={0} color={deepOrange[800]} />,
              },
              {
                Header: "Last Billed",
                accessor: "last_billed_date",
              },
              {
                Header: "Last Billed Days",
                accessor: "days_since_last_billing",
                Cell: ({value, row}) => <span style={{color: red[800]}}>{accounting.formatNumber(value)}</span>,
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "display",
                  desc: false,
                },
              ],
            }}
            data={pageData.projects}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/dashboard/pm-billings-list/`} mt={2} />

      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default ProjectManagerBillingsList;
