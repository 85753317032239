import {faSquare} from "@fortawesome/pro-regular-svg-icons";
import {faCheckSquare, faList, faPaperclip} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  Tab,
  TableHead,
  Tabs,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {TabContext} from "@material-ui/lab";
import classnames from "classnames";
import {groupBy, sumBy, uniqBy} from "lodash";
import moment from "moment";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";

import {Currency} from "../../../components/Accounting";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {StatusLabel} from "../../../components/Status";
import {MountedTabPanel} from "../../../components/TabPanel";
import {TabCountChip} from "../../../components/Tabs";
import Typography2 from "../../../components/Typography2";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {colorSuccess, colorWarning} from "../../../theme/colors";
const ProjectPCCOs = (props) => {
  const {project, ...rest} = props;

  const [selectedTab, setSelectedTab] = useQueryState("tab", "ocos");
  const [ocoQueryStats, setOcoQueryStats] = React.useState({isFetchedAfterMount: false, count: 0});
  const [timberlineQueryStats, setTimberlineQueryStats] = React.useState({isFetchedAfterMount: false, count: 0});
  const [sentinelTotal, setSentinelTotal] = React.useState(0);
  const [timberlineTotal, setTimberlineTotal] = React.useState(0);

  const novoClasses = makeNovoClasses();

  return (
    <>
      <Helmet title={`${project.display} - Owner Change Orders`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Owner Change Orders</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PageHeader>
          <PageHeader.Left></PageHeader.Left>
          <PageHeader.Right>
            {ocoQueryStats.isFetchedAfterMount && timberlineQueryStats.isFetchedAfterMount && (
              <>
                <PageHeader.Right.CurrencySpark title="Sentinel OCO Total" number={sentinelTotal} />
                <PageHeader.Right.CurrencySpark title="Timberline OCO Total" number={timberlineTotal} border />
              </>
            )}
          </PageHeader.Right>
        </PageHeader>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    OCOs
                    <TabCountChip isLoading={!ocoQueryStats.isFetchedAfterMount} count={ocoQueryStats.count} />
                  </Box>
                }
                value="ocos"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Timberline
                    <TabCountChip
                      isLoading={!timberlineQueryStats.isFetchedAfterMount}
                      count={timberlineQueryStats.count}
                    />
                  </Box>
                }
                value="timberline"
                className={novoClasses.smallTab}
              />
            </Tabs>
          </AppBar>
          <Box mb={2} />

          <MountedTabPanel value="ocos">
            <OCOList project={project} setQueryStats={setOcoQueryStats} setSentinelTotal={setSentinelTotal} />
          </MountedTabPanel>
          <MountedTabPanel value="timberline">
            <TimberlineList
              projectId={project.id}
              setQueryStats={setTimberlineQueryStats}
              setTimberlineTotal={setTimberlineTotal}
            />
          </MountedTabPanel>
        </PaperPanel>
      </TabContext>
      <LegacyUILink href={`/projects/${project.id}/pccos/legacy/`} mt={2} />
    </>
  );
};

export default ProjectPCCOs;

const OCOList = (props) => {
  const {project, setQueryStats, setSentinelTotal, ...rest} = props;

  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const [showOriginalCostInline, setShowOriginalCostInline] = React.useState(false);

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Status",
    "Search",
  ]);

  const filterParams = {
    status: filterOptions.Status.value,
    q: filterOptions.Search.value,
  };

  const {
    query: OCOQuery,
    create: createOCO,
    update: updateOCO,
    delete: deleteOCO,
    rpc: rpcOCOs,
    onDragEnd: ocoOnDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/pccos/?parent__isnull=True&page_size=${pageSize}&page=${page}&${qs.stringify(
      filterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const ocos = OCOQuery.data.results;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      sentinelTotal: 0,
    },
  });

  const pageData = pageDataQuery.data;
  const {sentinelTotal} = pageData;

  const {query: ocoStatusQuery} = useSentinelListAPI(`projects/${project.id}/pccos/status/`, {
    initialData: [],
  });

  const statusOptions = ocoStatusQuery.data;

  const groupedContracts = uniqBy(
    ocos.map((oco) => oco.contract),
    "id"
  ).sort((a: any, b: any) => a.number - b.number);

  const getOCObyContract = (contractId: number) => ocos.filter((oco) => oco.contract.id === contractId);

  React.useEffect(() => {
    setQueryStats({
      isFetchedAfterMount: OCOQuery.isFetchedAfterMount,
      count: OCOQuery.data.count,
    });
    setSentinelTotal(sentinelTotal);
  }, [OCOQuery.isFetchedAfterMount, OCOQuery.data.count, pageDataQuery.isFetchedAfterMount]);

  return (
    <>
      <Box mt={-1}>
        <PaperPanel.TabHeader isLoading={OCOQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faPaperclip} fixedWidth />
            OCOs
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                href={`/v2/projects/${project.id}/change-order-wizard/pcco/select-contract/`}
              >
                Create OCO
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons
                pdfURL={`/reports2/projects/${project.id}/ccos/pccos/?display_items=on`}
                buildReportURL={`/reports2/projects/${project.id}/ccos/pccos/filter/`}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button href={`/projects/${project.id}/pcco-items/`}>
                <FontAwesomeIcon icon={faList} fixedWidth /> CO Items
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action pr={0}>
              <FormControlLabel
                control={
                  <Switch
                    checked={showOriginalCostInline}
                    onChange={(event) => {
                      event.target.checked ? setShowOriginalCostInline(true) : setShowOriginalCostInline(false);
                    }}
                  />
                }
                label="Original Contract Value Inline"
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton isFetching={OCOQuery.isFetching} onClick={() => OCOQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.TabHeader>

        <PaperPanel.TabToolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Status"
                name="status"
                options={statusOptions}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}></Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <FilterOptionChips
                  filterOptions={filterOptions}
                  onDelete={(key) => {
                    clearFilterOption(key);
                  }}
                  onDeleteAll={() => {
                    clearAllFilterOptions();
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Pagination count={OCOQuery.data.total_pages} page={page} setPage={setPage} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </PaperPanel.TabToolbar>
        <PaperPanel.Body mx={-3}>
          {groupedContracts.map((contract: any) => {
            const ocoInThisContract = getOCObyContract(contract.id);
            return (
              <React.Fragment key={contract.id}>
                <PaperItem.Header>
                  <PaperItem.Body>
                    <Grid container>
                      <Grid item sm={8} xs={6}>
                        <Link href={contract.url}>{contract.display}</Link>
                      </Grid>
                      <Grid item sm={4} xs={6}>
                        <Box textAlign={"right"}>
                          <Tooltip
                            title={
                              <>
                                Original Contract Value: <Currency number={contract.original_value} />
                                <br />
                                Current Contract Value: <Currency number={contract.current_value} />
                              </>
                            }
                          >
                            <span>
                              {showOriginalCostInline ? (
                                <>
                                  Original Contract Value{"  "}
                                  <Currency number={contract.original_value} />
                                </>
                              ) : (
                                <strong>
                                  <Currency number={contract.current_value} />{" "}
                                </strong>
                              )}
                            </span>
                          </Tooltip>
                          {/* <Tooltip
                          title={
                            <>
                              Original Value: <Currency number={contract.original_value} />
                              <br />
                              Current Value: <Currency number={contract.current_value} />
                            </>
                          }
                        >
                          <span>
                            <Currency number={contract.current_value} />
                          </span>
                        </Tooltip> */}
                        </Box>
                      </Grid>
                    </Grid>
                  </PaperItem.Body>
                  <PaperItem.Right minWidth={37}>
                    {/* {groupedContracts.length > 1 && <Typography2 type="metadata">{contract.number_display}</Typography2>} */}
                  </PaperItem.Right>
                </PaperItem.Header>

                {ocoInThisContract.map((oco) => {
                  return <PCCOPaperItem oco={oco} key={oco.id} projectId={project.id} />;
                })}
                {!showOriginalCostInline ? null : (
                  <>
                    <PaperItem.Header>
                      <PaperItem.Body>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box textAlign="right">
                              <strong>
                                Current Contract Value <Currency number={contract.current_value} />
                              </strong>
                            </Box>
                          </Grid>
                        </Grid>
                      </PaperItem.Body>
                      <PaperItem.Right minWidth={37} />
                    </PaperItem.Header>
                    <Box mb={2} borderBottom="1px solid #696969" />
                  </>
                )}
              </React.Fragment>
            );
          })}
        </PaperPanel.Body>
        {OCOQuery.data.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={OCOQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </Box>
      {/* <Typography variant="h2">OCOs</Typography>
      <pre>{JSON.stringify(OCOQuery, null, 2)}</pre> */}
    </>
  );
};

const PCCOPaperItem = (props) => {
  const {oco, projectId, ...rest} = props;

  return (
    <PaperItem key={oco.id}>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={5} xs={12}>
            <StatusLabel status={oco.status_display} hint="pcco" />{" "}
            <MuiNavLink underline="always" to={`/v2/projects/${projectId}/pccos/${oco.id}/`}>
              {oco.display}
            </MuiNavLink>
          </Grid>
          <Grid item xs={2} sm={2}>
            {oco.is_draft ? (
              <Typography2 type="metadata" style={{color: colorWarning}}>
                <FontAwesomeIcon icon={faCheckSquare} /> Draft
              </Typography2>
            ) : (
              <Typography2 type="metadata" style={{color: colorSuccess}}>
                <FontAwesomeIcon icon={faSquare} /> Draft
              </Typography2>
            )}
          </Grid>
          <Grid item sm={2} xs={12}>
            <Tooltip title="Timberline Run">
              <span>
                <Typography2 type="metadata">{oco.timberline_run}</Typography2>
              </span>
            </Tooltip>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Box textAlign={"right"}>
              <Currency number={oco.budget_approved_amount} />
            </Box>
          </Grid>

          {/* <Grid item sm={5} xs={5}>
            <Typography2 type="metadata">
              Contract Value Prior to this OCO <Currency number={oco.prior_contract_value} />
            </Typography2>
          </Grid>
          <Grid item sm={5} xs={5}>
            <Typography2 type="metadata">
              Contract Value After this OCO <Currency number={oco.current_contract_value} />
            </Typography2>
          </Grid> */}
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={37}>
        <Box>
          <Typography2 type="metadata">{oco.number_display}</Typography2>
        </Box>
      </PaperItem.Right>

      <PaperItem.RightHover>
        <BoxFilesIconButtonLink
          projectId={projectId}
          app="pccos"
          model="pcco"
          objectId={oco.id}
          boxFolderId={oco.box_folder_id}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

const TimberlineList = (props) => {
  const {projectId, setQueryStats, setTimberlineTotal, ...rest} = props;
  const novoClasses = makeNovoClasses();

  const timberlineFilterParams = {};

  const {
    query: timberlineQuery,
    create: createTimberline,
    update: updateTimberline,
    delete: deleteTimberline,
    rpc: rpctimberlines,
    // onDragEnd: ocoOnDragEnd,
  } = useSentinelListAPI(
    `projects/${projectId}/timberline-ocos/?parent__isnull=True&page_size=10000&${qs.stringify(
      timberlineFilterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const timberlineOCOs = timberlineQuery.data.results;

  const groupedByRunNumber = groupBy(timberlineOCOs, "run");

  const timberlineRunNumbers = Object.keys(groupedByRunNumber);

  const runNumberTotals = timberlineRunNumbers.map((num) => ({
    number: num,
    total: sumBy(groupedByRunNumber[num], "amount"),
  }));

  React.useEffect(() => {
    setQueryStats({
      isFetchedAfterMount: timberlineQuery.isFetchedAfterMount,
      count: timberlineRunNumbers.length,
    });
    setTimberlineTotal(runNumberTotals.reduce((sum, row) => row.total + sum, 0));
  }, [timberlineQuery.isFetchedAfterMount, timberlineQuery.data.count]);

  return (
    <>
      <Box mx={-3} mb={1} mt={-4}>
        <PaperPanel>
          <PaperPanel.Header isLoading={timberlineQuery.isFetching}>
            <PaperPanel.Header.Title>
              <FontAwesomeIcon icon={faPaperclip} /> Timberline Data
            </PaperPanel.Header.Title>
          </PaperPanel.Header>
          <PaperPanel.Alert severity={"info"}>
            Timberline data is refreshed nightly and does not reflect changes made today.
          </PaperPanel.Alert>
          <Box mb={2} />
          <TableHead>
            <Box px={3}>
              <Typography variant="h6">Timberline Owner Change Orders By Run</Typography>
            </Box>
          </TableHead>
          <PaperPanel.Body>
            <ReactTableMui
              size="small"
              className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
              rightAlignColumns={["total"]}
              showFooter={Boolean(runNumberTotals.length)}
              columns={[
                {
                  Header: "Run",
                  accessor: "number",
                  Cell: ({value, row}) => value,
                },
                {
                  Header: "Amount",
                  accessor: "total",
                  Cell: ({value, row}) => <Currency number={value} />,
                  Footer: () => {
                    const total = runNumberTotals.reduce((sum, row) => row.total + sum, 0);
                    return total ? <Currency number={total} /> : "";
                  },
                },
              ]}
              data={runNumberTotals}
            />

            <Box my={4} />

            <TableHead>
              <Box px={3}>
                <Typography variant="h6">Timberline Owner Change Order Detail</Typography>
              </Box>
            </TableHead>

            <ReactTableMui
              size="small"
              className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
              rightAlignColumns={["amount"]}
              showFooter={Boolean(timberlineOCOs.length)}
              columns={[
                {
                  Header: "Seq",
                  accessor: "sequence",
                  Cell: ({value, row}) => value,
                },
                {
                  Header: "Cost Code",
                  accessor: "cost_code",
                  Cell: ({value, row}) => value,
                },
                {
                  Header: "Cat",
                  accessor: "category",

                  Cell: ({value, row}) => value,
                },
                {
                  Header: "Trans Date",
                  accessor: "transaction_date",
                  sortType: "basic",
                  Cell: ({value, row}) => moment(value).format("YYYY-MM-DD"),
                },
                {
                  Header: "Transaction Type",
                  accessor: "transaction_type",
                  sortType: "basic",
                  Cell: ({value, row}) => value,
                },
                {
                  Header: "Description",
                  accessor: "source",
                  sortType: "basic",
                  Cell: ({value, row}) => value,
                },
                {
                  Header: "Run",
                  accessor: "run",
                  sortType: "basic",
                  Cell: ({value, row}) => value,
                },
                {
                  Header: "Operator",
                  accessor: "operator_stamp",
                  sortType: "basic",
                  Cell: ({value, row}) => value,
                },
                {
                  Header: "Amount",
                  accessor: "amount",
                  sortType: "basic",
                  Cell: ({value, row}) => <Currency number={value} precision={2} />,
                  Footer: () => {
                    const total = timberlineOCOs.reduce((sum, row) => row.amount + sum, 0);
                    return total ? <Currency number={total} /> : "";
                  },
                },
              ]}
              initialState={{
                sortBy: [
                  {
                    id: "commitment",
                    desc: false,
                  },
                  {
                    id: "commitment_co",
                    desc: false,
                  },
                ],
              }}
              data={timberlineOCOs}
            />

            {/* <h1>Timberline</h1>
          <pre>{JSON.stringify(timberlineOCOs, null, 2)}</pre> */}
          </PaperPanel.Body>
        </PaperPanel>
      </Box>
      <Box mt={2} />
    </>
  );
};
