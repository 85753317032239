import {Box, Grid, Link} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";

export const SubmittalItemPaperItem = (props) => {
  const {
    submittalItem,
    isSelected,
    currentGrouping,
    onEditSubmittalItem,
    onDeleteSubmittalItem,
    onChangeSelectedSubmittalItem,
    ...rest
  } = props;

  return (
    <>
      <PaperItem style={{minHeight: "41.38px"}}>
        <PaperItem.Left minWidth={30}>
          <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangeSelectedSubmittalItem} />
        </PaperItem.Left>

        <PaperItem.Body>
          <Box display="flex" ml={-1} alignItems="left">
            <Grid container spacing={0}>
              <Grid item xs={9}>
                <StatusLabel status={submittalItem.status_display} width={100} />{" "}
                <MuiNavLink
                  href={`/v2/projects/${submittalItem.project_id}/submittals/items/${submittalItem.id}/`}
                  // href={`#`}
                  style={{cursor: "pointer"}}
                  underline="always"
                  // onClick={() => {
                  //   setSelectedSubmittalItem(submittalItem);

                  //   setEditFormIsOpen(true);
                  // }}
                >
                  {submittalItem.description}
                </MuiNavLink>
              </Grid>

              <Grid item xs={2}>
                <Typography2 type="metadata">
                  <Link href={submittalItem?.responsible_company?.url} underline="always">
                    {submittalItem.responsible_company?.name}
                  </Link>
                </Typography2>
              </Grid>
              <Grid item xs={1}>
                <Box textAlign="right">
                  {currentGrouping === "packages" ? (
                    <Typography2 noWrap type="metadata">
                      {submittalItem.item_number_display}
                    </Typography2>
                  ) : (
                    <Typography2 noWrap type="metadata">
                      {submittalItem.number_display}
                    </Typography2>
                  )}
                </Box>
              </Grid>

              {currentGrouping !== "csi_code" && (
                <Grid item xs={4}>
                  <Typography2 type="metadata">{submittalItem?.csi_code?.display}</Typography2>
                </Grid>
              )}
              {currentGrouping !== "packages" && (
                <Grid item xs={8}>
                  <Typography2 type="metadata">
                    <Link href={submittalItem?.submittal_package?.url}>
                      {submittalItem?.submittal_package?.display ?? "Not In A Package"}
                    </Link>
                  </Typography2>
                </Grid>
              )}
            </Grid>
          </Box>
        </PaperItem.Body>

        <PaperItem.RightHover>
          <PaperItem.RightHover.ItemNumber number={submittalItem.number_display} />
          <PaperItem.RightHover.IconButton
            icon={LinkIcon}
            title="Link"
            component={MuiNavLink}
            href={`/v2/projects/${submittalItem.project_id}/submittals/items/${submittalItem.id}/`}
          />
          <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditSubmittalItem} />
          <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onDeleteSubmittalItem} />
          <BoxFilesIconButtonLink
            projectId={submittalItem.project_id}
            app="submittals"
            model="submittalitem"
            objectId={submittalItem.id}
            boxFolderId={submittalItem.box_folder_id}
          />
          <Box textAlign="right"></Box>
        </PaperItem.RightHover>
      </PaperItem>
    </>
  );
};
