import {faMegaphone} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Tooltip, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import nl2br from "../../../../js/utils/nl2br";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {colorError} from "../../../theme/colors";
import {SafetyAnnouncementDialogForm} from "./SafetyAnnouncementForms";

const SafetyAnnouncements = (props) => {
  const {project, ...rest} = props;

  const [activeAnnouncement, setActiveAnnouncement] = React.useState(null);
  const [showCreateDialogForm, setShowCreateDialogForm] = React.useState(false);
  const [showEditDialogForm, setShowEditDialogForm] = React.useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = React.useState(false);
  const blockUI = useBlockUI();

  const {
    query: announcementsQuery,
    create: createAnnouncement,
    update: updateAnnouncement,
    delete: deleteAnnouncement,
  } = useSentinelListAPI(`projects/${project.id}/twilioapp/announcements/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });
  const announcements = announcementsQuery.data.results;

  return (
    <>
      <Helmet title={`${project.display} - Safety Announcements`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Safety Announcements</Typography>
      </ProjectBreadcrumbs>

      <PaperPanel>
        <PaperPanel.Header isLoading={announcementsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faMegaphone} /> Announcements
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setShowCreateDialogForm(true)}>
                Create Announcement
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => announcementsQuery.refetch()}
                isFetching={announcementsQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {announcements.map((announcement) => {
            return (
              <PaperItem>
                <PaperItem.Body>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      {nl2br(announcement.announcement)}
                    </Grid>
                    <Grid item xs={4}>
                      {nl2br(announcement.translated_announcement)}
                    </Grid>
                    <Grid item xs={2}>
                      {announcement.start_date && (
                        <Tooltip title="Start Date">
                          <span style={{color: announcement.is_started ? "" : colorError}}>
                            {moment(announcement.start_date).format("YYYY-MM-DD")}
                          </span>
                        </Tooltip>
                      )}
                      {announcement.data?.days_to_show && <Box> {announcement.data?.days_to_show.join(", ")}</Box>}
                    </Grid>
                    <Grid item xs={2}>
                      {announcement.expiration_date && (
                        <Tooltip title="Expiration Date">
                          <span style={{color: announcement.is_expired ? colorError : ""}}>
                            {moment(announcement.expiration_date).format("YYYY-MM-DD")}
                          </span>
                        </Tooltip>
                      )}
                    </Grid>
                  </Grid>
                </PaperItem.Body>

                <PaperItem.RightHover>
                  <PaperItem.RightHover.IconButton
                    icon={EditIcon}
                    title="Quick Edit"
                    onClick={() => {
                      setActiveAnnouncement(announcement);
                      setShowEditDialogForm(true);
                    }}
                  />
                  <PaperItem.RightHover.IconButton
                    icon={DeleteIcon}
                    title="Delete Item"
                    onClick={() => {
                      setActiveAnnouncement(announcement);
                      setShowDeleteConfirmation(true);
                    }}
                  />
                </PaperItem.RightHover>
              </PaperItem>
            );
          })}
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={2} />
      <center>
        <Box>
          <strong>Announcements will show up here</strong>
        </Box>
        <img src="/static/img/wellness-check-announcement-screen-shot.png" alt="Announcement Image" width={150} />
      </center>

      <SafetyAnnouncementDialogForm
        isOpen={showCreateDialogForm}
        handleClose={() => setShowCreateDialogForm(false)}
        isNew={true}
        onSubmit={(values) => {
          blockUI.blockUI("Creating/Translating...");
          createAnnouncement.mutateAsync(values).then(() => {
            setShowCreateDialogForm(false);
            blockUI.unblockUI();
          });
        }}
      />

      <SafetyAnnouncementDialogForm
        isOpen={showEditDialogForm}
        handleClose={() => setShowEditDialogForm(false)}
        isNew={false}
        initialValues={activeAnnouncement}
        onSubmit={(values) => {
          blockUI.blockUI("Saving/Translating...");
          updateAnnouncement.mutateAsync(values).then(() => {
            setShowEditDialogForm(false);
            blockUI.unblockUI();
          });
        }}
      />

      <ConfirmationDialog
        isOpen={showDeleteConfirmation}
        onApprove={() => {
          deleteAnnouncement.mutateAsync(activeAnnouncement.id).then(() => {
            // setShowWellnessCheckAnnouncementsDialog(false);
            setShowDeleteConfirmation(false);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => {
          setShowDeleteConfirmation(false);
        }}
      >
        You want to delete this announcement. This cannot be undone.
        <br />
      </ConfirmationDialog>
    </>
  );
};

export default SafetyAnnouncements;
