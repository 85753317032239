import {faFileExcel, faShoppingCart} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Currency} from "../../../components/Accounting";
import {StatusIcon} from "../../../components/Icons";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";
import {truncateString} from "../../../utils/string";

const PurchaseOrderAmendments = (props: {
  purchaseOrderAmendments: Array<{
    contract: {
      display: string;
    };
    to_company: {
      name: string;
    };
    display: string;
    status: string;
    total_price: number;
    purchase_order_status: string;
  }>;
  project: {display: string};
  budget: {display: string};
  isFetching?: Boolean;
}) => {
  const {purchaseOrderAmendments, project, budget, isFetching} = props;
  const alivePurchaseOrderAmendments = purchaseOrderAmendments.filter(
    (purchaseOrderAmendment) => !["void"].includes(purchaseOrderAmendment.purchase_order_status)
  );
  const novoClasses = makeNovoClasses();

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faShoppingCart} /> Purchase Amendments
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={isFetching}
                onClick={(event) => {
                  saveExcel(
                    purchaseOrderAmendments.map((purchaseOrderAmendment) => {
                      return {
                        "Purchase Order": purchaseOrderAmendment.display,
                        Amount: {v: purchaseOrderAmendment.total_price, t: "n", z: "$#,##0.00"},
                      };
                    }),
                    `${project.display} - ${budget.display} - Purchase Orders`,
                    [{wch: 60}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            showFooter={Boolean(purchaseOrderAmendments.length)}
            rightAlignColumns={["total_price"]}
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                Header: "PO Amendment",
                id: "poAmendment",
                accessor: (value) => {
                  return `${value.status_sort}-${value.display}`;
                },
                Cell: ({value, row}) => (
                  <>
                    <StatusIcon status={row.original.status} showTooltip />{" "}
                    <Link href={row.original.url}>{truncateString(row.original.display, 100)}</Link>
                  </>
                ),
              },
              {
                Header: "PO",
                id: "po",
                accessor: "purchase_order_display",
                Cell: ({value, row}) => (
                  <>
                    {/* <StatusIcon status={row.original.purchase_order_status} showTooltip />{" "} */}
                    <Link href={row.original.url}>{truncateString(row.original.purchase_order_display, 100)}</Link>
                  </>
                ),
              },
              {
                Header: "Amount",
                accessor: "total_price",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => alivePurchaseOrderAmendments.reduce((sum, row) => row.total_price + sum, 0),
                    [alivePurchaseOrderAmendments]
                  );
                  return <Currency number={total} />;
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "poAmendment",
                },
              ],
            }}
            data={purchaseOrderAmendments}
          />
        </PaperPanel.Body>
      </PaperPanel>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default PurchaseOrderAmendments;
