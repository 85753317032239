import {Scheduler, SchedulerData} from "@bitnoi.se/react-scheduler";
import "@bitnoi.se/react-scheduler/dist/style.css";
import {Breadcrumbs, Grid, Typography} from "@material-ui/core";
import {groupBy, orderBy} from "lodash";
import moment from "moment";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import officeChoices from "../../components/forms/choices/offices.json";
import PaperPanel from "../../components/PaperPanel";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useLocalStorage} from "../../hooks/useLocalStorage";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {ScheduleDialogForm} from "./LaborScheduleForms";

const getBGColor = (item) => {
  if (item.type === "project") {
    return "blue";
  }
  return "purple";
};

const LaborScheduleCalendar: React.FC = () => {
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(1000);
  const [startDate, setStartDate] = React.useState("");
  const [endDate, setEndDate] = React.useState("");
  const [activeItem, setActiveItem] = React.useState(null);
  const [updateScheduleItemFormIsOpen, setUpdateScheduleItemFormIsOpen] = React.useState(false);
  const [defaultOffice, setDefaultOffice] = useLocalStorage("labor-schedule-office", {
    value: "menlo",
    label: "Menlo Park",
  });
  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Office"],
    {
      Office: {value: defaultOffice.value, label: defaultOffice.label},
    }
  );

  const itemFilterParams = {
    q: filterOptions.Search.value,
    office: filterOptions.Office.value,
  };

  const {
    query: itemQuery,
    create: createItem,
    update: updateItem,
    delete: deleteItem,
  } = useSentinelListAPI(
    `labor/schedule/items/?page_size=${pageSize}&page=${page}&start_date=${startDate}&end_date=${endDate}&${qs.stringify(itemFilterParams)}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
      enabled: Boolean(startDate && endDate),
    }
  );
  const scheduleItems = itemQuery.data.results;
  const contactsById = scheduleItems.reduce((acc, item) => {
    if (!item?.contact?.contact) return acc;
    acc[item?.contact?.contact?.id] = item?.contact?.contact;
    return acc;
  }, {});

  const scheduleItemsByContactId = groupBy(scheduleItems, (scheduleItem) => scheduleItem?.contact?.contact?.id);
  const contacts: any = orderBy(
    Object.entries(contactsById).map(([key, contact]) => contact),
    ["full_name"]
  );
  const schedulerData: SchedulerData = contacts.map((contact) => {
    return {
      id: contact.id,
      label: {
        icon: null,
        // icon: "https://picsum.photos/24",
        title: contact.full_name,
        // subtitle: contact.title,
      },
      data: scheduleItemsByContactId[contact.id].map((item) => {
        return {
          id: item.id,
          startDate: new Date(item.start_date),
          endDate: new Date(item.end_date),
          occupancy: item.occupancy,
          title: item.type_display,
          subtitle: item?.project?.display,
          description: `${item.start_date} - ${item.end_date}`,
          bgColor: getBGColor(item),
          ...item,
        };
      }),
    };
  });

  // const mockedSchedulerData: SchedulerData = [
  //   {
  //     id: "070ac5b5-8369-4cd2-8ba2-0a209130cc60",
  //     label: {
  //       icon: "https://picsum.photos/24",
  //       title: "Joe Doe",
  //       subtitle: "Frontend Developer",
  //     },
  //     data: [
  //       {
  //         id: "8b71a8a5-33dd-4fc8-9caa-b4a584ba3762",
  //         startDate: new Date("2023-04-13T15:31:24.272Z"),
  //         endDate: new Date("2023-08-28T10:28:22.649Z"),
  //         occupancy: 3600,
  //         title: "Project A",
  //         subtitle: "Subtitle A",
  //         description: "array indexing Salad West Account",
  //         bgColor: "rgb(254,165,177)",
  //       },
  //       {
  //         id: "22fbe237-6344-4c8e-affb-64a1750f33bd",
  //         startDate: new Date("2023-10-07T08:16:31.123Z"),
  //         endDate: new Date("2023-11-15T21:55:23.582Z"),
  //         occupancy: 2852,
  //         title: "Project B",
  //         subtitle: "Subtitle B",
  //         description: "Tuna Home pascal IP drive",
  //         bgColor: "rgb(254,165,177)",
  //       },
  //       {
  //         id: "3601c1cd-f4b5-46bc-8564-8c983919e3f5",
  //         startDate: new Date("2023-03-30T22:25:14.377Z"),
  //         endDate: new Date("2023-09-01T07:20:50.526Z"),
  //         occupancy: 1800,
  //         title: "Project C",
  //         subtitle: "Subtitle C",
  //         bgColor: "rgb(254,165,177)",
  //       },
  //       {
  //         id: "b088e4ac-9911-426f-aef3-843d75e714c2",
  //         startDate: new Date("2023-10-28T10:08:22.986Z"),
  //         endDate: new Date("2023-10-30T12:30:30.150Z"),
  //         occupancy: 11111,
  //         title: "Project D",
  //         subtitle: "Subtitle D",
  //         description: "Garden heavy an software Metal",
  //         bgColor: "rgb(254,165,177)",
  //       },
  //     ],
  //   },
  // ];
  // if (!itemQuery.isFetchedAfterMount) {
  //   return <BlockUI show={true} message="Fetching Schedules..." />;
  // }

  return (
    <>
      <Helmet title="Labor Schedule Calendar" />
      <Breadcrumbs>
        <Typography color="textSecondary">Labor</Typography>
        <Typography color="textPrimary">Schedule Calendar</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={itemQuery.isFetching}>
          <PaperPanel.Header.Title>Labor Schedule</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton isFetching={itemQuery.isFetching} onClick={() => itemQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                label="Office"
                name="office"
                options={officeChoices}
                value={filterOptions.Office.value}
                onChange={(value, label) => {
                  setPage(1);
                  setDefaultOffice({value: value, label: label});
                  setFilterOption("Office", value, label);
                }}
                allowNull={false}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <div style={{position: "relative", height: "100vh", width: "100%"}}>
            <Scheduler
              data={schedulerData}
              isLoading={itemQuery.isLoading}
              onRangeChange={(newRange) => {
                console.log(newRange);
                setStartDate(moment(newRange.startDate).format("YYYY-MM-DD"));
                setEndDate(moment(newRange.endDate).format("YYYY-MM-DD"));
              }}
              onTileClick={(clickedResource) => {
                // console.log("onTileClick", clickedResource);
                setActiveItem(clickedResource);
                setUpdateScheduleItemFormIsOpen(true);
              }}
              onItemClick={(item) => {
                console.log("onItemClick", item);
                // setActiveItem(item);
                // setUpdateScheduleItemFormIsOpen(true);
              }}
              config={{
                // theme: dark,
                filterButtonState: -1,
                zoom: 0,
                showTooltip: false,
              }}
            />
          </div>
        </PaperPanel.Body>
      </PaperPanel>
      <ScheduleDialogForm
        isOpen={updateScheduleItemFormIsOpen}
        handleClose={() => setUpdateScheduleItemFormIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateItem.mutateAsync(values).then(() => {
            setUpdateScheduleItemFormIsOpen(false);
            blockUI.unblockUI();
          });
          setUpdateScheduleItemFormIsOpen(false);
        }}
        initialValues={activeItem}
      />

      {/* <pre>{JSON.stringify(schedulerData, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(contactsById, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(scheduleItems, null, 2)}</pre> */}
    </>
  );
};

export default LaborScheduleCalendar;
