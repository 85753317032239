import {Box} from "@material-ui/core";
import {useTabContext} from "@material-ui/lab";

export const MountedTabPanel = (props: any) => {
  const {children, className, style, value: id, ...rest} = props;
  const context = useTabContext();

  if (context === null) {
    throw new TypeError("No TabContext provided");
  }
  const tabId = context.value;

  return (
    <Box
      className={className}
      p={3}
      pb={0}
      style={{
        ...style,
        display: id === tabId ? "block" : "none",
      }}
      {...rest}
    >
      {children}
    </Box>
  );
};
