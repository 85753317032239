import {faSquare} from "@fortawesome/pro-regular-svg-icons";
import {faCheckSquare, faComments, faExclamationCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import pluralize from "pluralize";
import NumberFormat from "react-number-format";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {ImportanceLabel} from "../../../components/ImportanceLabel";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import {TagList} from "../../../components/Tags";
import Typography2 from "../../../components/Typography2";
import {colorError, colorSuccess, colorWarning} from "../../../theme/colors";

const RFIPaperItem = (props) => {
  // useWhyDidYouUpdate("RFIPaperItem", props);
  const {
    rfi,
    projectId,
    updateRFI,
    isDragging,
    isSelected,
    dragHandleProps,
    onChangeRFISelected,
    onEditRFI,
    onDeleteRFI,
  } = props;
  return (
    <PaperItem isDragging={isDragging}>
      <PaperItem.Left minWidth={45}>
        {/* <Box display="flex" alignItems="center"> */}
        <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangeRFISelected} />
        {dragHandleProps && (
          <Box display="flex" alignItems="center">
            <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
            <PaperItem.Position position={rfi.position} />
          </Box>
        )}
      </PaperItem.Left>
      <PaperItem.Body>
        {/* <pre>{JSON.stringify(rfi, null, 2)}</pre> */}
        <Grid container spacing={0}>
          {/* <Grid item xs={10} sm={4}> */}
          <Grid item xs={8}>
            <StatusLabel status={rfi.status_display} />{" "}
            <MuiNavLink to={`/v2/projects/${projectId}/rfis/${rfi.id}/`} underline="always">
              {rfi.subject}
            </MuiNavLink>
          </Grid>
          <Grid item xs={4}>
            <Box
            // textAlign="right"
            >
              <Link href={rfi.responsible_party?.url} underline="always">
                <Typography2 type="metadata">{rfi.responsible_party?.name}</Typography2>
              </Link>
            </Box>
          </Grid>

          <Grid item xs={12} sm={4}>
            {rfi.is_overdue && (
              <Typography2 type="metadata" style={{color: colorError}}>
                <FontAwesomeIcon icon={faExclamationCircle} />{" "}
                <NumberFormat
                  title={rfi.date_required}
                  thousandSeparator={true}
                  displayType="text"
                  value={rfi.business_days_required}
                />{" "}
                {`${pluralize("business day", rfi.business_days_required)} overdue`}
              </Typography2>
            )}
            {rfi.due_soon && (
              <Typography2 type="metadata" style={{color: colorWarning}}>
                <FontAwesomeIcon icon={faExclamationCircle} /> due in{" "}
                <NumberFormat
                  title={rfi.date_required}
                  thousandSeparator={true}
                  displayType="text"
                  value={rfi.business_days_required}
                />{" "}
                {`${pluralize("business day", rfi.business_days_required)}`}
              </Typography2>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography2 type="metadata">
              <ImportanceLabel importance={rfi.importance_display} />
            </Typography2>
          </Grid>
          <Grid item xs={12} sm={2}>
            {rfi.is_draft ? (
              <Typography2
                type="metadata"
                style={{color: colorWarning, cursor: "pointer"}}
                onClick={() => {
                  updateRFI({
                    // ...rfi,
                    is_draft: false,
                  });
                }}
              >
                <FontAwesomeIcon icon={faCheckSquare} /> Draft
              </Typography2>
            ) : (
              <Typography2
                type="metadata"
                style={{color: colorSuccess, cursor: "pointer"}}
                onClick={() => {
                  updateRFI({
                    // ...rfi,
                    is_draft: true,
                  });
                }}
              >
                <FontAwesomeIcon icon={faSquare} /> Draft
              </Typography2>
            )}
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography2 type="metadata">
              <MuiNavLink to={`/v2/projects/${projectId}/rfis/${rfi.id}/#tab=responses`}>
                <FontAwesomeIcon icon={faComments} />{" "}
                {`${rfi.response_count} ${pluralize("Response", rfi.response_count)}`}
              </MuiNavLink>
            </Typography2>
          </Grid>
          {/* <Grid item xs={12} sm={2}></Grid> */}
          <Grid item xs={12}>
            <TagList tags={rfi.tags} />
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right
        // minWidth={40}
        minWidth={65}
      >
        <Box textAlign="right">
          <Typography2 noWrap type="metadata">
            {rfi.number_display}
          </Typography2>
        </Box>
      </PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.ItemNumber number={rfi.number_display} />
        {/* <PaperItem.RightHover.IconButton
          icon={LinkIcon}
          title="Link"
          component={NavLink}
          to={`/v2/projects/${projectId}/rfis/${rfi.id}/`}
        /> */}
        <PaperItem.RightHover.IconButton icon={LinkIcon} title="Link" component={MuiNavLink} href={rfi.url} />
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditRFI} />
        <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onDeleteRFI} />
        <BoxFilesIconButtonLink
          projectId={projectId}
          app="rfis"
          model="rfi"
          objectId={rfi.id}
          boxFolderId={rfi.box_folder_id}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
export default RFIPaperItem;
