import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  makeStyles,
} from "@material-ui/core";
import classNames from "classnames";

import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {Currency} from "../../../components/Accounting";
import {CurrencyFieldMui, TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";

export const AdjustBudgetAmountForm = (props) => {
  const {onSubmit, isOpen, handleClose, netBudgetChange, changed, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            const changeAmount = changed - netBudgetChange;

            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Budget Adjustment Amount</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container>
                      <Grid item xs={12}>
                        <CurrencyFieldMui
                          name="amount"
                          label="Adjustment Amount"
                          autoFocus
                          required
                          helperText={
                            changeAmount !== 0 && (
                              <>
                                Change Needed to Zero: <Currency number={changeAmount} />{" "}
                                {changeAmount !== 0 && (
                                  <Link onClick={() => form.change("amount", changeAmount)} style={{cursor: "pointer"}}>
                                    (Click To Use This Value)
                                  </Link>
                                )}
                              </>
                            )
                          }
                        />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const NameCOForm = (props) => {
  const {onSubmit, isOpen, handleClose, hasCOR = false, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Create {hasCOR ? "COR" : "Internal PCO"}</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <DialogContentText>
                        You want to create a new create a new {hasCOR ? "COR" : "Internal PCO"} with the following
                        description:
                      </DialogContentText>
                    </Grid>
                    <Grid item xs={12}>
                      <TextFieldMui name="description" label="Description" autoFocus required />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting} label="Create" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

const colorHover = "#fffaf0";
const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    choice: {
      paddingBottom: `3px`,
      "&:hover": {
        backgroundColor: colorHover,
        boxShadow: `rgb(0 0 0 / 10%) 0px 2px 1px -1px, rgb(0 0 0 / 7%) 0px 1px 1px 0px, rgb(0 0 0 / 6%) 0px 1px 3px 0px;`,
      },
    },
  };
});

export const ChangeTypeSelectDialog = (props) => {
  const {isOpen, onSelect, handleClose} = props;

  const classes = useStyles();
  const choiceClasses = classes.choice;
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={(event, reason) => {
          if (["escapeKeyDown", "backdropClick"].includes(reason)) {
            handleClose();
          }
        }}
        maxWidth="sm"
      >
        <DialogTitle>Select Change Order Type</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              Which type of change are you trying to make?
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box textAlign="center" className={classNames(choiceClasses)}>
                Move Money Between Existing Budget Items
                <Box textAlign="center">
                  <Button variant="contained" onClick={() => onSelect(false)}>
                    Internal PCO
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Box textAlign="center" className={classNames(choiceClasses)}>
                Formalize a release of contingency or allowance to a budget item.
                <Box textAlign="center">
                  <Button variant="contained" onClick={() => onSelect(true)}>
                    COR
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};
