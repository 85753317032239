import {Box, FormControl, FormHelperText, FormLabel} from "@material-ui/core";
import {Rating} from "@material-ui/lab";
import {Field} from "react-final-form";

export const RatingMui = (props: {name: string; label: string; helperText?: string; required?: boolean}) => {
  const {name, label, required, ...rest} = props;
  return (
    <Box>
      <Field
        name={name}
        validate={(value) => (value ? undefined : required ? "Required" : undefined)}
        parse={(value) => (value ? parseInt(value) : undefined)}
      >
        {(fieldProps) => {
          const {input, meta} = fieldProps;
          return (
            <FormControl error={meta.invalid}>
              <Box>
                <FormLabel required={required} error={meta.invalid && meta.touched}>
                  {label}
                </FormLabel>
              </Box>
              <Rating
                {...input}
                value={parseInt(input.value)}
                size="small"
                onChange={(event, value) => {
                  input.onChange(value);
                }}
                {...rest}
              />
              {/* {input.value && (
                <Typography2 type="metadata" onClick={() => {}} component="span">
                  Clear
                </Typography2>
              )} */}
              {meta.invalid && meta.touched && <FormHelperText id="my-helper-text">{meta.error}</FormHelperText>}
            </FormControl>
          );
        }}
      </Field>
    </Box>
  );
};
