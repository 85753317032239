import {faCheck, faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import accounting from "accounting";
import {groupBy} from "lodash";
import qs from "qs";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {textToHTML} from "../../../js/common/utils";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import HtmlRender from "../../../js/components/HtmlRender";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterAutocomplete, FilterSearch} from "../../components/Filters";
import companyTrades from "../../components/forms/choices/companyTrades.json";
import offices from "../../components/forms/choices/offices.json";
import {SimpleAutoselectMui, SimpleSelect} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import {LabelValue} from "../../components/LabelValue";
import {MuiNavLink} from "../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import Typography2 from "../../components/Typography2";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchCurrentPage} from "../../hooks/useSentinelAPI";
import downloadFile from "../../utils/downloadFile";

const SubcontractorsByTrade = (props) => {
  const {...rest} = props;

  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(250);
  const [showBuildReportDialog, setShowBuildReportDialog] = React.useState(false);

  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "Trade",
  ]);

  const filtersApplied = Object.values(filterOptions)
    .map((option) => Boolean(option.value))
    .some((val) => val);

  const filterParams = {
    page: page,
    page_size: pageSize,
    trade: filterOptions.Trade.value,
    q: filterOptions.Search.value,
  };

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        subcontractors: {
          count: 0,
          current_page_number: 1,
          next_page_number: 2,
          page_size: pageSize,
          previous_page_number: null,
          total_pages: 1,
          results: [],
        },
      },
      enabled: filtersApplied,
    },
    filterParams
  );
  const pageData = pageDataQuery.data;
  const totalTrades = pageData.subcontractors.count;

  const groupedSubs = groupBy(pageData.subcontractors.results, "trade");
  const availableGroups = Object.keys(groupedSubs);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const tradeOptions = [
    {label: "No Trade", value: "No Trade"},
    ...companyTrades.map((trade) => ({label: trade.label, value: trade.label})),
  ];
  return (
    <>
      <Helmet title="Subcontractors By Trade" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textSecondary">Companies</Typography>
        <Typography color="textPrimary">Subcontractors By Trade</Typography>
      </Breadcrumbs>

      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            Subcontractors By Trade{" "}
            {!pageDataQuery.isFetching && filtersApplied && (
              <>
                ({accounting.formatNumber(pageData.subcontractors.results.length)}/
                {accounting.formatNumber(totalTrades)})
              </>
            )}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={pageDataQuery.isFetching}
                onClick={() => {
                  blockUI.blockUI("Generating Document...");
                  downloadFile(
                    `/v2/companies/subcontractors-by-trade/?export_type=excel&${qs.stringify(filterParams)}`
                  ).then(() => blockUI.unblockUI());
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>

              <Box mr={1} />
              <PaperPanel.Header.BuildReportButton onClick={() => setShowBuildReportDialog(true)} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FilterAutocomplete
                label="Trade"
                value={filterOptions.Trade.value}
                name="Trade"
                onChange={(label, value) => {
                  setPage(1);
                  value ? setFilterOption("Trade", value, label) : clearFilterOption("Trade");
                }}
                options={tradeOptions}
              />
              {/* <FilterSelect
                label="Trade"
                value={filterOptions.Trade.value}
                name="Trade"
                onChange={(label, value) => {
                  setPage(1);
                  value ? setFilterOption("Trade", value, label) : clearFilterOption("Trade");
                }}
                options={tradeOptions}
                // allowNull={false}
              /> */}
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination count={pageData.subcontractors.total_pages} page={page} setPage={setPage} />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        {filtersApplied ? (
          <>
            <PaperPanel.Body>
              {availableGroups.map((header) => (
                <React.Fragment key={header}>
                  <PaperItem.HeaderLabel label={header === "" ? "No Trade" : header} />
                  {!isMobile && (
                    <PaperItem.Header>
                      <Grid container spacing={1}>
                        <>
                          <Grid item xs={3}>
                            Company
                          </Grid>
                          <Grid item xs={1}>
                            Union
                          </Grid>
                          <Grid item xs={1}>
                            Contact
                          </Grid>
                          <Grid item xs={2}>
                            Phone/Fax
                          </Grid>
                          <Grid item xs={2}>
                            Address
                          </Grid>
                          <Grid item xs={2}>
                            Email
                          </Grid>
                          {/* <Grid item xs={1}>
                            Trade
                          </Grid> */}
                        </>
                      </Grid>
                    </PaperItem.Header>
                  )}

                  {groupedSubs[header].map((sub) => (
                    <SubcontractorPaperItem key={sub.id} sub={sub} isMobile={isMobile} />
                  ))}
                </React.Fragment>
              ))}
            </PaperPanel.Body>
            {pageData.subcontractors.count > 250 && (
              <PaperPanel.Footer display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={pageData.subcontractors.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  // pageSizes={[10, 100, 250, 500, 1000]}
                />
              </PaperPanel.Footer>
            )}
          </>
        ) : (
          <>
            <Alert severity="info">
              <AlertTitle>No Search Criteria</AlertTitle>Please add some search terms or select a trade above.
            </Alert>
          </>
        )}
      </PaperPanel>

      <BuildReportDialogForm
        onSubmit={(values) => {
          window.open(`/reports2/companies/subcontractors/by-trade/?build_report=true&${qs.stringify(values)}`);
          setShowBuildReportDialog(false);
        }}
        isOpen={showBuildReportDialog}
        handleClose={() => setShowBuildReportDialog(false)}
        initialValues={{trade: companyTrades.find((trade) => trade.label === filterOptions.Trade.label)?.value}}
      />
    </>
  );
};

export default SubcontractorsByTrade;

const SubcontractorPaperItem = (props) => {
  const {sub, isMobile, ...rest} = props;

  return (
    <PaperItem noHover {...rest}>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item md={3} xs={10}>
            <MuiNavLink to={`/v2/companies/${sub.id}/`} underline="always">
              {sub.company}
            </MuiNavLink>
          </Grid>
          <Grid item md={1} xs={2}>
            {sub.is_union &&
              (isMobile ? <Typography2 type="metadata">Union</Typography2> : <FontAwesomeIcon icon={faCheck} />)}
          </Grid>
          <Grid item md={1} xs={6}>
            {sub.contact}
          </Grid>
          <Grid item md={2} xs={6}>
            <Box style={{whiteSpace: "nowrap"}}>
              {sub.phone && <LabelValue label="P: " value={sub.phone} />}
              {sub.fax && <LabelValue label="F: " value={sub.fax} />}
            </Box>
          </Grid>
          <Grid item md={2} xs={6}>
            <HtmlRender html={textToHTML(sub.address)} />
          </Grid>
          <Grid item md={2} xs={6}>
            <Link href={`mailto:${sub.email}`} underline="always">
              {sub.email}
            </Link>
          </Grid>
        </Grid>
      </PaperItem.Body>
    </PaperItem>
  );
};

export const BuildReportDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Build Report</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <SimpleAutoselectMui name="trade" label="Trade" options={companyTrades} required />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <SimpleSelect
                          name="union"
                          label="Is Union"
                          options={[
                            {label: "Yes", value: "yes"},
                            {label: "No", value: "no"},
                            {label: "Both", value: "both"},
                          ]}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <SimpleSelect name="office" label="Office" options={offices} allowNull />
                      </Grid>
                    </Grid>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label="Build Report" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
