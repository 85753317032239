import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import EmailIcon from "@material-ui/icons/Email";
import OpenInBrowserIcon from "@material-ui/icons/OpenInBrowser";
import {Currency} from "../../../components/Accounting";
import {DocuSignStatusIcon} from "../../../components/DocuSign";
import {ProjectStatusIcon} from "../../../components/Icons";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";

const SubcontractorLienReleasePaperItem = (props) => {
  const {lienRelease, setActiveItem, setShowItemDialog, showProject, showCommitment, onCreateDSEnvelopeClicked} = props;
  const hasCommitment = lienRelease?.timberline_payment_distribution?.commitment;

  return (
    <PaperItem>
      <PaperItem.Body style={{fontSize: 11}}>
        <Grid container spacing={0}>
          {showProject && (
            <Grid item xs={1}>
              <ProjectStatusIcon project={lienRelease.project} />{" "}
              <Tooltip title={lienRelease.project.display}>
                <Link href={`/v2/projects/${lienRelease.project.id}/subcontractor-lien-releases/`} underline="always">
                  {lienRelease.project.timberline_job}
                </Link>
              </Tooltip>
            </Grid>
          )}
          {showCommitment && (
            <Grid item xs={2}>
              <Link
                href={`/timberline/commitments/${lienRelease.timberline_payment_distribution.commitment?.commitment}/`}
                underline="always"
              >
                {lienRelease.timberline_payment_distribution.commitment?.commitment}
              </Link>
            </Grid>
          )}
          <Grid item xs={2}>
            <Link
              href={`/v2/projects/${lienRelease.project.id}/subcontractor-lien-releases/${lienRelease.id}/`}
              underline="always"
            >
              {lienRelease.date}
            </Link>
          </Grid>
          <Grid item xs={showProject ? 4 : 5}>
            <Typography2 type="metadata">
              {lienRelease.timberline_payment_distribution.vendor_name}
              <br />
              {/* {lienRelease.timberline_payment_distribution.description} */}
              {/* {lienRelease.timberline_payment_distribution?.commitment?.commitment_type} */}
              Invoice: {lienRelease.timberline_payment_distribution.invoice} - {lienRelease.invoice.invoice_date} -
              Status: {lienRelease.timberline_payment_distribution.status}
              {lienRelease.timberline_payment_distribution.status === "Open" && (
                <>
                  {" "}
                  - Retained: <Currency number={lienRelease.timberline_payment_distribution.retainage} precision={2} />
                </>
              )}
            </Typography2>
          </Grid>
          <Grid item xs={1}>
            {lienRelease.current_docusign_envelope_detail_url && (
              <Link href={lienRelease.current_docusign_envelope_detail_url}>
                <DocuSignStatusIcon status={lienRelease.docusign_envelope_status} />
              </Link>
            )}
          </Grid>
          <Grid item xs={showCommitment ? 2 : 4}>
            <Box textAlign="right">
              Amount Paid: <Currency number={lienRelease.timberline_payment_distribution.amount_paid} precision={2} />
              <br />
              Amount: <Currency number={lienRelease.timberline_payment_distribution.amount} precision={2} />
            </Box>
          </Grid>
        </Grid>
        {/* <pre>{JSON.stringify(lienRelease, null, 2)}</pre> */}
      </PaperItem.Body>
      <PaperItem.Right minWidth={80} />
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton
          icon={DescriptionIcon}
          title="Report"
          href={`/reports2/accounting-tools/subcontractor-lien-releases/${lienRelease.id}/`}
          target="_blank"
          disabled={!hasCommitment}
        />
        <PaperItem.RightHover.IconButton
          icon={EmailIcon}
          title={lienRelease.to_contact ? "View DS Envelopes" : "Create DS Envelope"}
          onClick={() => onCreateDSEnvelopeClicked(lienRelease)}
          disabled={!hasCommitment}
          // href={lienRelease.docusign_envelope_list_url}
        />
        <PaperItem.RightHover.IconButton
          icon={OpenInBrowserIcon}
          title="View Lien Release"
          onClick={() => {
            setActiveItem(lienRelease);
            setShowItemDialog(true);
          }}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
export default SubcontractorLienReleasePaperItem;
