import {Box, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid} from "@material-ui/core";
import {isEmpty} from "lodash";
import moment from "moment";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../api";
import PaperPanel from "../../components/PaperPanel";
import Typography2 from "../../components/Typography2";
import {
  AutoselectMui,
  CSVTagsMui,
  DatePickerMui,
  ProjectAutoselectMui,
  SimpleAutoselectMui,
  SimpleSelect,
  TextFieldMui,
} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import contactRoles from "../../components/forms/choices/contactRoles.json";
import laborScheduleTypeChoices from "../../components/forms/choices/laborScheduleItemTypes.json";
import officeChoices from "../../components/forms/choices/offices.json";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {colorError} from "../../theme/colors";

const getOfficeDisplay = (office) => {
  return officeChoices.find((choice) => choice.value === office)?.label;
};

export const ScheduleDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;

  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, contact, ...rest} = props;
  const [projectOffice, setProjectOffice] = React.useState(undefined);
  const validateDates = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) return "Required";
    if (value === "Invalid Date") return "Invalid Date";
    if (values.start_date && values.end_date && moment(values.start_date).isAfter(values.end_date)) {
      return "Start date must be before end date";
    }
  };

  const {query: contactQuery} = useSentinelListAPI(`contacts/?company_id=1547&is_active=true&page_size=10000`, {
    initialData: {
      results: [],
    },
  });
  const contacts = contactQuery.data.results;
  const contactRoleOptions = contactRoles.filter((role) => role.group === "Internal");
  const laborScheduleTypeOptions = laborScheduleTypeChoices.filter(
    (type) => !["on_hold_unassigned_project"].includes(type.value)
  );
  const defaultRole = contacts.find((c) => c.id === contact?.contact?.id)?.contact_role?.id;
  return (
    <>
      <Dialog
        open={isOpen}
        onClose={() => {
          setProjectOffice(undefined);
          handleClose();
        }}
        maxWidth="md"
        fullWidth
      >
        <FinalForm onSubmit={onSubmit} keepDirtyOnReinitialize {...rest}>
          {({handleSubmit, form, submitting, pristine, values, errors, dirtyFields}) => {
            const showWrongOfficeWarning =
              values?.project && projectOffice && projectOffice !== values?.contact?.office;

            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  {`${isNew ? "Create" : "Update"} Labor Schedule Item For ${values?.contact.contact.full_name}`}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={4}>
                          <SimpleSelect
                            name="type"
                            label="Type"
                            options={
                              // values?.project
                              //   ? laborScheduleTypeOptions
                              //   : laborScheduleTypeOptions.filter((type) => type.value !== "project")
                              laborScheduleTypeOptions
                            }
                            disabled={!isEmpty(values?.project) || !isNew}
                            autoFocus
                            required
                          />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          {(values?.project || values?.type === "project") && (
                            <ProjectAutoselectMui
                              name="project"
                              label="Project"
                              required
                              disableClearable={false}
                              onChange={(event, value, reason) => {
                                setProjectOffice(undefined);
                                if (reason === "select-option") {
                                  form.change("start_date", value?.start_date);
                                  form.change("end_date", value?.finish_date);
                                  if (!values?.contact_role) {
                                    form.change("contact_role", defaultRole);
                                  }
                                  axiosAPI
                                    .get(`/projects/${value?.id}/`)
                                    .then((res) => res.data.office)
                                    .then((office) => {
                                      setProjectOffice(office);
                                      form.change("office.id", office);
                                    });
                                }
                              }}
                              qsParams={{
                                ordering: "status_sort",
                                page_size: 5,
                                is_closed: false,
                              }}
                              disabled={!isNew}
                            />
                          )}
                          <FormLabel error={showWrongOfficeWarning}>
                            {showWrongOfficeWarning
                              ? `${values?.contact.contact.full_name} is assigned to ${getOfficeDisplay(
                                  values?.contact.office
                                )} but this project is in ${getOfficeDisplay(projectOffice)}.`
                              : ""}
                          </FormLabel>
                          {values?.type === "other" && <TextFieldMui name="other_type" label="Description" required />}
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          {showWrongOfficeWarning ? (
                            <SimpleSelect
                              name="office.id"
                              label="Office"
                              options={officeChoices.filter((choice) =>
                                [projectOffice, values?.contact.office].includes(choice.value)
                              )}
                            />
                          ) : (
                            values?.project && (
                              <SimpleSelect name="office.id" label="Office" options={officeChoices} disabled />
                            )
                          )}
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <DatePickerMui
                            name="start_date"
                            label="Start Date"
                            required
                            fieldProps={{
                              validate: validateDates,
                            }}
                            validateFields={["end_date"]}
                            // helperText={
                            //   (!dirtyFields?.project && values?.type === "project" && dirtyFields?.start_date) ||
                            //   (dirtyFields?.project && values?.project?.start_date !== values?.start_date)
                            //     ? "This also modifies the project's start date"
                            //     : null
                            // }
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <DatePickerMui
                            name="end_date"
                            label="End Date"
                            required
                            validateFields={["start_date"]}
                            fieldProps={{
                              validate: validateDates,
                            }}
                            minDate={values?.start_date}
                            helperText={
                              <>
                                {/* {(!dirtyFields?.project && values?.type === "project" && dirtyFields?.end_date) ||
                                (dirtyFields?.project && values?.project?.finish_date !== values?.end_date)
                                  ? "This also modifies the project's end date"
                                  : null} */}
                                {values?.end_date && moment(values?.end_date).isBefore(moment()) && (
                                  <Box color={colorError}>
                                    {dirtyFields?.project && values?.project?.finish_date ? "Project" : "This"} end date
                                    is in the past. Consider changing it to a future date.
                                  </Box>
                                )}
                              </>
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                        >
                          {values?.start_date && values?.end_date && !errors?.start_date && !errors?.end_date && (
                            <FormLabel>
                              Days: {moment(values?.end_date).diff(moment(values?.start_date), "days") + 1}
                            </FormLabel>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="notes" label="Notes" multiline minRows={4} />
                        </Grid>
                        {values?.project?.id && (
                          <>
                            <Grid item xs={6}>
                              <SimpleAutoselectMui
                                name="contact_role"
                                label="Role"
                                options={contactRoleOptions}
                                required
                                helperText={
                                  dirtyFields?.contact_role
                                    ? "Changes made will also update the project directory"
                                    : null
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <CSVTagsMui
                                name="data.carpenters"
                                label="Carpenters"
                                options={contacts.map((contact) => contact.full_name)}
                              />
                            </Grid>
                          </>
                        )}
                      </Grid>
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const NoContactScheduleDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  office?: string;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, office, ...rest} = props;
  const validateDates = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) return "Required";
    if (value === "Invalid Date") return "Invalid Date";
    if (values.start_date && values.end_date && moment(values.start_date).isAfter(values.end_date)) {
      return "Start date must be before end date";
    }
  };

  const {
    query: teamQuery,
    create: createTeam,
    update: updateTeam,
    delete: deleteTeam,
  } = useSentinelListAPI(`labor/schedule/teams/?is_active=true&page=1&page_size=10000&office=${office}`, {
    initialData: {results: []},
  });

  const teams = teamQuery.data.results;

  const teamOptions = teams.map((team) => ({name: team.name, id: team.id, office: team.office}));

  const {query: contactQuery} = useSentinelListAPI(`contacts/?company_id=1547&is_active=true&page_size=10000`, {
    initialData: {
      results: [],
    },
  });
  const contacts = contactQuery.data.results;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} keepDirtyOnReinitialize {...rest}>
          {({handleSubmit, form, submitting, pristine, values, errors, dirtyFields, initialValues}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  {`${isNew ? "Create" : "Update"} Labor Schedule Item For Unassigned or On Hold Project`}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <ProjectAutoselectMui
                            name="project"
                            label="Project"
                            required
                            disableClearable={false}
                            onChange={(event, value, reason) => {
                              if (reason === "select-option") {
                                form.change("start_date", value?.start_date);
                                form.change("end_date", value?.finish_date);
                                axiosAPI
                                  .get(`/projects/${value?.id}/`)
                                  .then((res) => res.data.office)
                                  .then((office) => {
                                    form.change("office.id", office);
                                  });
                              }
                            }}
                            qsParams={{
                              ordering: "status_sort",
                              page_size: 5,
                              is_closed: false,
                            }}
                            disabled={!isNew}
                            autoFocus={isNew}
                          />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <SimpleSelect name="office.id" label="Office" required options={officeChoices} disabled />
                        </Grid>
                        <Grid item xs={12} sm={3}>
                          <AutoselectMui
                            name="team"
                            label="Team"
                            // options={teamOptions}
                            valueProp={"id"}
                            labelProp={"name"}
                            renderOption={(option) => {
                              return (
                                <>
                                  <Box component="span" mr={0.5}>
                                    {option.name}
                                  </Box>
                                  <Typography2 type="metadata">{option.office.description}</Typography2>
                                </>
                              );
                            }}
                            getOptionLabel={(option) => option.name ?? ""}
                            options={
                              values?.office
                                ? teamOptions.filter((team) => team.office.id === values?.office?.id)
                                : teamOptions
                            }
                            // disabled={disableTeamSelect}
                            // helperText={disableTeamSelect ? "Save Changes to Office First" : ""}
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <DatePickerMui
                            name="start_date"
                            label="Start Date"
                            required
                            fieldProps={{
                              validate: validateDates,
                            }}
                            validateFields={["end_date"]}
                            disabled={!values?.project}
                            // helperText={
                            //   (!dirtyFields?.project && dirtyFields?.start_date) ||
                            //   (dirtyFields?.project && values?.project?.start_date !== values?.start_date)
                            //     ? "This also modifies the project's start date"
                            //     : null
                            // }
                          />
                        </Grid>
                        <Grid item xs={12} sm={5}>
                          <DatePickerMui
                            name="end_date"
                            label="End Date"
                            required
                            validateFields={["start_date"]}
                            fieldProps={{
                              validate: validateDates,
                            }}
                            minDate={values?.start_date}
                            disabled={!values?.project}
                            helperText={
                              <>
                                {/* {(!dirtyFields?.project && dirtyFields?.end_date) ||
                                (dirtyFields?.project && values?.project?.finish_date !== values?.end_date)
                                  ? "This also modifies the project's end date"
                                  : null} */}
                                {values?.end_date && moment(values?.end_date).isBefore(moment()) && (
                                  <Box color={colorError}>
                                    {dirtyFields?.project && values?.project?.finish_date ? "Project" : "This"} end date
                                    is in the past. Consider changing it to a future date.
                                  </Box>
                                )}
                              </>
                            }
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={2}
                          style={{display: "flex", alignItems: "center", justifyContent: "center"}}
                        >
                          {values?.start_date && values?.end_date && !errors?.start_date && !errors?.end_date && (
                            <FormLabel>
                              Days: {moment(values?.end_date).diff(moment(values?.start_date), "days") + 1}
                            </FormLabel>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="notes" label="Notes" multiline minRows={4} />
                        </Grid>

                        <Grid item xs={6}>
                          <CSVTagsMui
                            name="data.carpenters"
                            label="Carpenters"
                            options={contacts.map((contact) => contact.full_name)}
                          />
                        </Grid>
                      </Grid>
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const BuildReportDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, ...rest} = props;

  const {
    query: teamQuery,
    create: createTeam,
    update: updateTeam,
    delete: deleteTeam,
  } = useSentinelListAPI(`labor/schedule/teams/?is_active=true&page=1&page_size=10000`, {
    initialData: {results: []},
  });

  const teams = teamQuery.data.results;

  const teamOptions = teams.map((team) => ({name: team.name, id: team.id, office: team.office}));

  // const validateTeam = async (value, values, field) => {
  //   if (!values.office || (values.office && !values.team)) return;
  //   const teamsInThisOffice = teamOptions.filter((team) => team?.office.id === values?.office).map((team) => team?.id);
  //   if (!teamsInThisOffice.includes(values.team.id)) {
  //     return "Selected team is not in this office";
  //   }
  // };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values, hasValidationErrors}) => {
            const teamOptionsInOffice = values?.office
              ? teamOptions.filter((team) => team.office.id === values?.office).map((team) => team?.id)
              : teamOptions;

            if (values?.team && !teamOptionsInOffice.includes(values?.team?.id)) {
              form.change("team", null);
            }
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Build Report</DialogTitle>
                <DialogContent>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <SimpleSelect
                            name="office"
                            label="Office"
                            options={officeChoices}
                            allowNull
                            // fieldProps={{validateFields: ["team"]}}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <AutoselectMui
                            name="team"
                            label="Team"
                            valueProp={"id"}
                            labelProp={"name"}
                            renderOption={(option) => {
                              return (
                                <>
                                  <Box component="span" mr={0.5}>
                                    {option.name}
                                  </Box>
                                  <Typography2 type="metadata">{option.office.description}</Typography2>
                                </>
                              );
                            }}
                            getOptionLabel={(option) => option.name ?? ""}
                            options={
                              values?.office
                                ? teamOptions.filter((team) => team.office.id === values?.office)
                                : teamOptions
                            }
                            // fieldProps={{
                            //   validate: validateTeam,
                            // }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePickerMui name="start_date" label="From Date" />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePickerMui name="end_date" label="To Date" />
                        </Grid>
                      </Grid>
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || hasValidationErrors} label="Build Report" />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
