import {faUserCrown} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link} from "@material-ui/core";
import React from "react";
import usePermissions from "../hooks/usePermissions";
import {LabelValue} from "./LabelValue";
import PaperPanel from "./PaperPanel";

export const SuperuserInfo = (props: {
  objects: {contentType: string; model: string; id: number | string; title: string}[];
  // All other props
  [rest: string]: any;
}) => {
  const {objects, ...rest} = props;
  const permissions = usePermissions();
  if (!permissions.is_superuser) {
    return null;
  }
  return (
    <Box mt={2} {...rest}>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faUserCrown} /> Superuser Info
          </PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <Grid container spacing={2}>
            {objects.map((obj) => {
              const adminURL = `/admin/${obj.contentType}/${obj.model}/${obj.id}/change/`;
              const auditURL = `/v2/admin-tools/audit/${obj.contentType}/${obj.model}/${obj.id}/`;
              return (
                <Grid key={`${obj.contentType}-${obj.model}-${obj.id}`} item xs={12} sm={4}>
                  <strong>{obj.title}</strong>
                  <LabelValue
                    label="Admin:"
                    value={
                      <Link target="_blank" href={adminURL}>
                        {adminURL}
                      </Link>
                    }
                  />
                  <LabelValue
                    label="Audit:"
                    value={
                      <Link target="_blank" href={auditURL}>
                        {auditURL}
                      </Link>
                    }
                  />
                </Grid>
              );
            })}
          </Grid>
        </PaperPanel.Body>
      </PaperPanel>
    </Box>
  );
};

export const SuperuserInfoBox = (props: {
  user?: {
    is_superuser: boolean;
  };
  children?: React.ReactNode;
  [rest: string]: any;
}) => {
  const {user, children, ...rest} = props;
  if (!user?.is_superuser) {
    return null;
  }
  return (
    <Box p={1} bgcolor="grey.50" border={1} borderRadius={4} borderColor="grey.400" {...rest}>
      <Box>
        <FontAwesomeIcon icon={faUserCrown} /> Superuser Info
      </Box>
      {children}
    </Box>
  );
};
