import {Box, Typography} from "@material-ui/core";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {QuestionDetailReadOnly, ThumbnailDetailDialog} from "./SafetyReviewComponents";

const MOMENTFORMAT = "YYYY-MM-DD h:mm a";

const SafetyReviewQuestionDetail = (props) => {
  const {project, ...rest} = props;
  const {safetyReviewId, questionId} = useParams();
  const [activeAttachment, setActiveAttachment] = React.useState(undefined as {});
  // const [activeThumbnail, setActiveThumbnail] = React.useState(undefined as {});
  const [thumbnailDetailIsOpen, setThumbnailDetailIsOpen] = React.useState(false);

  const blockUI = useBlockUI();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;

  const {safetyReview} = pageData;

  const {query: questionQuery} = useSentinelDetailAPI(
    `projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/questions/${questionId}/`,
    {
      initialData: {},
    }
  );

  const question = questionQuery.data;

  const {
    query: attachmentQuery,
    update: updateAttachment,
    delete: deleteAttachment,
  } = useSentinelListAPI(
    `projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/questions/${questionId}/attachments/`,
    {
      initialData: {
        results: [],
      },
    }
  );

  const {query: thumbnailQuery} = useSentinelDetailAPI(
    `projects/${project.id}/safety/project-safety-reviews/${safetyReviewId}/attachments/thumbnails/`,
    {
      initialData: [],
    }
  );

  const fetchedAttachments = attachmentQuery.data.results;
  const fetchedThumbnails = thumbnailQuery.data;

  const attachments = fetchedAttachments.map((attachment) => ({
    ...attachment,
    thumbnail: fetchedThumbnails.find((thumbnail) => thumbnail.id === attachment.file),
  }));

  const questionAttachments = attachments.filter((attachment) => attachment.type === "question");

  const answerAttachments = attachments.filter((attachment) => attachment.type === "action");

  if (!questionQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Question info..." />;
  }
  return (
    <>
      <Helmet title={`Project Safety Review Question Detail`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/safety/project-safety-reviews`}>
          <Typography color="textSecondary">Project Safety Reviews</Typography>
        </MuiNavLink>
        <MuiNavLink href={`/v2/projects/${project.id}/safety/project-safety-reviews/${safetyReview.id}/`}>
          <Typography color="textSecondary">{moment(safetyReview.review_datetime).format(MOMENTFORMAT)}</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">{question.question}</Typography>
      </ProjectBreadcrumbs>
      <Box mt={1} />

      <PaperPanel>
        <PaperPanel.Body px={1}>
          <QuestionDetailReadOnly
            question={question}
            questionAttachments={questionAttachments}
            answerAttachments={answerAttachments}
            onClickThumbnail={(attachment) => {
              setActiveAttachment(attachment);
              // setActiveThumbnail(attachment.thumbnail);
              setThumbnailDetailIsOpen(true);
            }}
            review={safetyReview}
            refreshQuestions={() => questionQuery.refetch()}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <ThumbnailDetailDialog
        onSubmit={() => {}}
        thumbnail={activeAttachment?.thumbnail ?? {}}
        attachment={activeAttachment}
        isOpen={thumbnailDetailIsOpen}
        handleClose={() => setThumbnailDetailIsOpen(false)}
        initialValues={activeAttachment}
        readOnly
      />
    </>
  );
};

export default SafetyReviewQuestionDetail;
