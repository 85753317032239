import {Button, Link} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import moment from "moment";
import {useLocation} from "react-router-dom";
import Countdown from "../../js/components/Countdown";

const SessionExpirationBanner = (props: {sessionExpireDate?: string}) => {
  const {sessionExpireDate, ...rest} = props;
  const location = useLocation();
  const loginURL = `/v2/login2/?next=${location.pathname}`;

  const hoursUntilExpiration = moment(sessionExpireDate).diff(moment(), "hours");
  if (!sessionExpireDate || hoursUntilExpiration > 8) {
    return null;
  }

  return (
    <Alert
      severity="warning"
      action={
        <Button color="inherit" size="small" href={loginURL}>
          Login
        </Button>
      }
      {...rest}
    >
      Your session is going to expire{" "}
      <Countdown
        date={sessionExpireDate}
        delay={1000 * 60 * 5}
        onExpiring={(sessionCountdownSeconds) => {
          window.location.href = loginURL;
        }}
      />{" "}
      and you will be logged out. Click{" "}
      <Link underline="always" href={loginURL}>
        here
      </Link>{" "}
      to re-login now.
    </Alert>
  );
};

export default SessionExpirationBanner;
