import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import PropTypes from "prop-types";
import {getImportanceColor} from "../theme/colors";
import {getImportanceIcon} from "../theme/icons";

export const ImportanceLabel = (props: {importance: string}) => {
  const {importance, ...rest} = props;
  if (!importance) {
    return null;
  }
  return (
    <span style={{color: getImportanceColor(importance)}} {...rest}>
      <FontAwesomeIcon icon={getImportanceIcon(importance)} /> {importance}
    </span>
  );
};

ImportanceLabel.propTypes = {
  importance: PropTypes.string,
};
