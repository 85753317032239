import {faList, faPlus, faUmbrella} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Grid, MenuItem, Tab, Tabs, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {TabContext, TabPanel} from "@material-ui/lab";
import {groupBy, uniqBy} from "lodash";
import pluralize from "pluralize";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {textToHTML} from "../../../../js/common/utils";
import HtmlRender from "../../../../js/components/HtmlRender";
import {useSelectIds} from "../../../../js/hooks";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {LegacyUILink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {AdditionalInsuredForm, CertificateHoldersForm, PolicyDialogForm} from "./ProjectInsuranceForms";
import {InsuranceCompanyPaperItem, InsurancePolicyPaperItem} from "./ProjectInsurancePaperItems";

const ProjectInsurance = (props) => {
  const {project, ...rest} = props;

  const blockUI = useBlockUI();

  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [deleteOneConfirmationIsOpen, setDeleteOneConfirmationIsOpen] = React.useState(false);
  const [refreshPoliciesDialogIsOpen, setRefreshPoliciesDialogIsOpen] = React.useState(false);
  const [refreshAllPoliciesDialogIsOpen, setRefreshAllPoliciesDialogIsOpen] = React.useState(false);
  const [editPolicyDialogIsOpen, setEditPolicyDialogIsOpen] = React.useState(false);
  const [createPolicyDialogIsOpen, setCreatePolicyDialogIsOpen] = React.useState(false);
  const [certificateHoldersCreateDialogIsOpen, setCertificateHoldersCreateDialogIsOpen] = React.useState(false);
  const [certificateHoldersEditDialogIsOpen, setCertificateHoldersEditDialogIsOpen] = React.useState(false);
  const [deleteCertificateHolderConfirmationDialog, setDeleteCertificateHolderConfirmationDialog] =
    React.useState(false);
  const [activeItem, setActiveItem] = React.useState({} as any);
  const [selectedTab, setSelectedTab] = useQueryState("tab", "policies");
  const [initialFocusField, setInitialFocusField] = React.useState(null);

  const {
    query: insuranceQuery,
    delete: deletePolicy,
    create: createPolicy,
    update: updatePolicy,
    rpc: policyRPC,
  } = useSentinelListAPI(`projects/${project.id}/insurance/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const {update: updateProject, query: projectQuery} = useSentinelDetailAPI(`projects/${project.id}/`, {
    initialData: {},
    // keepPreviousData: true,
  });

  const projectData = projectQuery.data;

  const {
    query: certificateHoldersQuery,
    update: updateCertificateHolders,
    create: createCertificateHolder,
    delete: deleteCertificateHolder,
  } = useSentinelListAPI(`projects/${project.id}/insurance/certificate-holders/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const certificateHolders = certificateHoldersQuery.data.results;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      companiesMissingInsurance: [],
    },
  });

  const insuranceList = insuranceQuery.data.results;

  const insuredCompanyLookup = groupBy(insuranceList, (insurance) => insurance.company.name);
  const insuredCompanies = uniqBy(
    insuranceList.map((insuredCompany) => insuredCompany.company),
    "id"
  );

  const uninsuredCompanies = pageDataQuery?.data?.companiesMissingInsurance;

  const allPolicyIds = insuranceList.map((policy) => policy.id);

  const {
    selectedIds: selectedPolicyIds,
    addSelectedId: addSelectedPolicyId,
    addSelectedIds: addSelectedPolicyIds,
    removeSelectedId: removeSelectedPolicyId,
    removeSelectedIds: removeSelectedPolicyIds,
    addAllSelectedIds: addAllSelectedPolicyIds,
    removeAllSelectedIds: removeAllSelectedPolicyIds,
    allIdsSelected: allPolicyIdsSelected,
  } = useSelectIds(allPolicyIds);

  return (
    <>
      <Helmet title={`${project.display} - Project Insurance`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Project Insurance</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Policies
                  </Box>
                }
                value="policies"
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Cert Holders/Additional
                  </Box>
                }
                value="additional"
              />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="policies">
            <>
              <PaperPanel.TabHeader isLoading={insuranceQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <PaperItem.SelectedCheckbox
                    label={"Select All"}
                    onChange={(event, value) => {
                      if (value) {
                        addAllSelectedPolicyIds();
                      } else {
                        removeAllSelectedPolicyIds();
                      }
                    }}
                    indeterminate={Boolean(
                      !allPolicyIdsSelected && selectedPolicyIds.size < allPolicyIds.length && selectedPolicyIds.size
                    )}
                    checked={allPolicyIdsSelected}
                  />
                  <FontAwesomeIcon icon={faUmbrella} fixedWidth />
                  Project Insurance
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.DeleteButton
                      disabled={selectedPolicyIds.size < 1}
                      onClick={() => {
                        setDeleteConfirmationIsOpen(true);
                      }}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.DropdownMenu
                      startIcon={<FontAwesomeIcon icon={faPlus} />}
                      title="Create Policies"
                    >
                      <MenuItem key="all_policies" onClick={() => setRefreshAllPoliciesDialogIsOpen(true)}>
                        All Policies
                      </MenuItem>
                      {uninsuredCompanies.length > 0 && (
                        <>
                          <MenuItem divider key="policy_divider" />
                          {uninsuredCompanies.map((uninsuredCompany) => (
                            <MenuItem
                              key={uninsuredCompany.id}
                              onClick={() => {
                                setRefreshPoliciesDialogIsOpen(true);
                                setActiveItem(uninsuredCompany);
                              }}
                            >
                              {uninsuredCompany.name}
                            </MenuItem>
                          ))}
                        </>
                      )}
                    </PaperPanel.Header.DropdownMenu>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.PDFButton href={`/reports2/projects/${project.id}/insurance/`} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => {
                        insuranceQuery.refetch();
                        projectQuery.refetch();
                      }}
                      isFetching={insuranceQuery.isFetching || projectQuery.isFetching}
                    />
                    <Box mr={-1} />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <Box mx={-3} mt={-2} mb={-3}>
                {insuredCompanies.map((insured) => {
                  return (
                    <React.Fragment key={insured.id}>
                      <InsuranceCompanyPaperItem
                        company={insured}
                        isFetching={insuranceQuery.isFetching}
                        onRefreshPolicies={() => {
                          setRefreshPoliciesDialogIsOpen(true);
                          setActiveItem(insured);
                        }}
                        onCreatePolicy={() => {
                          setActiveItem({company: {id: insured.id}});
                          setCreatePolicyDialogIsOpen(true);
                        }}
                      />

                      {insuredCompanyLookup[insured.name].map((policy) => (
                        <InsurancePolicyPaperItem
                          highlight={policy.id === activeItem.id}
                          key={policy.id}
                          policy={policy}
                          policyIsSelected={selectedPolicyIds.has(policy.id)}
                          onChangePolicySelected={(event, value) => {
                            if (value) {
                              addSelectedPolicyId(policy.id, event.nativeEvent.shiftKey);
                            } else {
                              removeSelectedPolicyId(policy.id);
                            }
                          }}
                          onEditPolicy={() => {
                            setActiveItem(policy);
                            setEditPolicyDialogIsOpen(true);
                          }}
                          onDeletePolicy={() => {
                            setActiveItem(policy);
                            setDeleteOneConfirmationIsOpen(true);
                          }}
                        />
                      ))}
                    </React.Fragment>
                  );
                })}
              </Box>

              {/* <h1>PageData</h1>
              <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
            </>
            {/* </Box> */}
          </TabPanel>

          <TabPanel value="additional">
            <>
              {/* <Box mx={-3} mt={-4} mb={-3}> */}
              {/* <PaperPanel> */}
              <PaperPanel.TabHeader isLoading={certificateHoldersQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faList} /> Certificate Holders
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.CreateButton onClick={() => setCertificateHoldersCreateDialogIsOpen(true)}>
                      Add Certificate Holder
                    </PaperPanel.Header.CreateButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButton href={`/reports2/projects/${project.id}/insurance/requirements/`} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => {
                        insuranceQuery.refetch();
                        projectQuery.refetch();
                      }}
                      isFetching={insuranceQuery.isFetching || projectQuery.isFetching}
                    />
                    <Box mr={-1} />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>

              <PaperPanel.Body sectionBody>
                {certificateHolders.map((holder) => {
                  return (
                    <PaperItem key={holder.id}>
                      <PaperItem.Body>
                        <Grid container spacing={2}>
                          <Grid item xs={12} sm={6}>
                            {holder.name}
                          </Grid>{" "}
                          <Grid item xs={12} sm={6}>
                            <HtmlRender html={textToHTML(holder.address)} />
                          </Grid>
                        </Grid>
                      </PaperItem.Body>
                      <PaperItem.RightHover>
                        <PaperItem.RightHover.IconButton
                          icon={EditIcon}
                          title="Edit"
                          onClick={() => {
                            setActiveItem(holder);
                            setCertificateHoldersEditDialogIsOpen(true);
                          }}
                        />
                        <PaperItem.RightHover.IconButton
                          icon={DeleteIcon}
                          title="Delete"
                          onClick={() => {
                            setActiveItem(holder);
                            setDeleteCertificateHolderConfirmationDialog(true);
                          }}
                        />
                      </PaperItem.RightHover>
                    </PaperItem>
                  );
                })}
              </PaperPanel.Body>
              {/* </PaperPanel> */}
              <Box my={1} />

              <PaperPanel.TabHeader sectionHeader isLoading={certificateHoldersQuery.isFetching}>
                <PaperPanel.Header.Title>Additional Insured</PaperPanel.Header.Title>
              </PaperPanel.TabHeader>
              <PaperPanel.Body p={1} maxHeight={550} overflow="auto" sectionBody>
                <AdditionalInsuredForm
                  onSubmit={(values, form) => {
                    blockUI.blockUI("Saving...");
                    updateProject
                      .mutateAsync(values)
                      .then(() => {
                        blockUI.unblockUI();
                      })
                      .catch(() => blockUI.unblockUI());
                  }}
                  initialValues={projectData}
                />
              </PaperPanel.Body>
              {/* </Box> */}
            </>
          </TabPanel>
        </PaperPanel>

        <ConfirmationDialog
          isOpen={deleteConfirmationIsOpen}
          onDeny={() => setDeleteConfirmationIsOpen(false)}
          onApprove={() => {
            blockUI.blockUI("Deleting...");
            policyRPC
              .mutateAsync({method: "DELETE", action: "delete", data: {ids: Array.from(selectedPolicyIds)}})
              .then(() => {
                removeAllSelectedPolicyIds();
                setDeleteConfirmationIsOpen(false);
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          title="Are You Sure?"
        >
          Do you want to delete {selectedPolicyIds.size} selected {pluralize("policy", selectedPolicyIds.size)}?
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={deleteOneConfirmationIsOpen}
          onDeny={() => setDeleteOneConfirmationIsOpen(false)}
          onApprove={() => {
            blockUI.blockUI("Deleting...");
            deletePolicy
              .mutateAsync(activeItem.id)
              .then(() => {
                setEditPolicyDialogIsOpen(false);
                setDeleteOneConfirmationIsOpen(false);
                setActiveItem({});
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          title="Are You Sure?"
        >
          Do you want to delete this policy?
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={refreshPoliciesDialogIsOpen}
          onDeny={() => setRefreshPoliciesDialogIsOpen(false)}
          onApprove={() => {
            blockUI.blockUI("Updating...");
            policyRPC
              .mutateAsync({
                action: "update-or-create-insurance-policies-for-company",
                method: "POST",
                data: {company_id: activeItem.id},
              })
              .then(() => Promise.all([insuranceQuery.refetch(), pageDataQuery.refetch()]))
              .then(() => {
                setRefreshPoliciesDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          title="Are You Sure?"
        >
          Are you sure add/update all policies for {activeItem?.name}? Policy data specified at the company level will
          override the current data.
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={refreshAllPoliciesDialogIsOpen}
          onDeny={() => setRefreshAllPoliciesDialogIsOpen(false)}
          onApprove={() => {
            blockUI.blockUI("Updating...");
            policyRPC
              .mutateAsync({
                action: "action-update-or-create-all-insurance-policies",
                method: "POST",
                data: {project_id: project.id},
              })
              .then(() => Promise.all([insuranceQuery.refetch(), pageDataQuery.refetch()]))
              .then(() => {
                setRefreshAllPoliciesDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          title="Are You Sure?"
        >
          Are you sure add/update all policies for all companies? Policy data specified at the company level will
          override the current data.
        </ConfirmationDialog>

        <ConfirmationDialog
          isOpen={deleteCertificateHolderConfirmationDialog}
          onDeny={() => setDeleteCertificateHolderConfirmationDialog(false)}
          onApprove={() => {
            blockUI.blockUI("Deleting...");
            deleteCertificateHolder
              .mutateAsync(activeItem.id)
              .then(() => {
                setDeleteCertificateHolderConfirmationDialog(false);
                blockUI.unblockUI();
              })
              .then(() => blockUI.unblockUI());
          }}
          title="Are you sure?"
        >
          Are you sure you want to remove {activeItem?.name} as a certificate holder? This can not be undone.
        </ConfirmationDialog>

        <PolicyDialogForm
          isOpen={editPolicyDialogIsOpen}
          handleClose={() => {
            setEditPolicyDialogIsOpen(false);
            setActiveItem({});
            setInitialFocusField(null);
          }}
          onSubmit={(values) => {
            blockUI.blockUI("Saving...");
            updatePolicy
              .mutateAsync(values)
              .then(() => {
                setEditPolicyDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .then(() => blockUI.unblockUI());
          }}
          onDeletePolicy={() => {
            setDeleteOneConfirmationIsOpen(true);
          }}
          initialValues={activeItem}
          activeItem={activeItem}
          items={insuredCompanyLookup[activeItem?.company?.name]}
          setActiveItem={setActiveItem}
          beforePrevNext={(values) => {
            // blockUI.blockUI("Updating...");
            return updatePolicy.mutateAsync(values).then(() => {
              // blockUI.unblockUI();
            });
          }}
          // afterPrevNext={() => {}}
          initialFocusField={initialFocusField}
          setInitialFocusField={setInitialFocusField}
        />

        <PolicyDialogForm
          isOpen={createPolicyDialogIsOpen}
          handleClose={() => {
            setCreatePolicyDialogIsOpen(false);
            setActiveItem({});
          }}
          onSubmit={(values) => {
            blockUI.blockUI("Saving...");
            createPolicy
              .mutateAsync(values)
              .then(() => {
                setCreatePolicyDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .then(() => blockUI.unblockUI());
          }}
          isNew
          initialValues={activeItem}
        />

        <CertificateHoldersForm
          isOpen={certificateHoldersCreateDialogIsOpen}
          handleClose={() => setCertificateHoldersCreateDialogIsOpen(false)}
          onSubmit={(values) => {
            blockUI.blockUI("Saving...");
            createCertificateHolder
              .mutateAsync(values)
              .then(() => {
                setCertificateHoldersCreateDialogIsOpen(false);
                setActiveItem({});
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          isNew
        />
        <CertificateHoldersForm
          isOpen={certificateHoldersEditDialogIsOpen}
          handleClose={() => setCertificateHoldersEditDialogIsOpen(false)}
          onSubmit={(values) => {
            blockUI.blockUI("Saving...");
            updateCertificateHolders
              .mutateAsync(values)
              .then(() => {
                setCertificateHoldersEditDialogIsOpen(false);
                setActiveItem({});
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }}
          initialValues={activeItem}
          onDelete={() => {
            setCertificateHoldersEditDialogIsOpen(false);
            setActiveItem(activeItem);
            setDeleteCertificateHolderConfirmationDialog(true);
          }}
        />
      </TabContext>
      <LegacyUILink href={`/projects/${project.id}/insurance/`} mt={2} />
    </>
  );
};

export default ProjectInsurance;
