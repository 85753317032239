import {faDraftingCompass, faExternalLink, faFileExcel, faLink, faSync} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Divider, Grid, Link, Typography} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import {useSnackbar} from "notistack";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {DrawingDialogForm} from "./DrawingForms";

const ProjectDrawings = (props) => {
  const {project} = props;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(500);
  const [showCreateDrawingDialog, setShowCreateDrawingDialog] = React.useState(false);
  const [showUpdateDrawingDialog, setShowUpdateDrawingDialog] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [activeDrawing, setActiveDrawing] = React.useState({} as any);
  const novoClasses = makeNovoClasses();
  const {enqueueSnackbar} = useSnackbar();
  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Search",
    "System",
  ]);

  const filterParams = {
    q: filterOptions.Search.value,
    system_key: filterOptions.System.value,
  };

  const {
    query: drawingQuery,
    create: createDrawing,
    update: updateDrawing,
    delete: deleteDrawing,
    rpc: drawingRPC,
  } = useSentinelListAPI(
    `projects/${project.id}/drawings2/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );

  const {query: systemKeyQuery} = useSentinelListAPI(`projects/${project.id}/drawings2/system-keys/`, {
    initialData: [],
  });
  const systemKeyOptions = systemKeyQuery.data;
  return (
    <>
      <Helmet title={`${project.display} - Drawings`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Drawings</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={drawingQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faDraftingCompass} /> Drawings{" "}
            {drawingQuery.isFetchedAfterMount ? `(${accounting.formatNumber(drawingQuery.data.count)})` : null}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setShowCreateDrawingDialog(true)}>
                Create Drawing
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                href={`/reports2/projects/${project.id}/drawings/`}
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                variant="outlined"
              >
                Get Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Menu border>
              {(popupState) => (
                <div>
                  {project.plangrid_project_id && (
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={() => {
                        popupState.close();
                        blockUI.blockUI("Updating PlanGrid drawings.  This could take a bit...");
                        drawingRPC
                          .mutateAsync({action: "update-plangrid-drawings"})
                          .then(() => {
                            blockUI.unblockUI();
                            drawingQuery.refetch();
                            enqueueSnackbar("PlanGrid drawings updated", {variant: "success"});
                          })
                          .catch(() => blockUI.unblockUI());
                      }}
                    >
                      <FontAwesomeIcon icon={faSync} fixedWidth /> Update PlanGrid Drawings
                    </PaperPanel.Header.Menu.MenuItem>
                  )}
                  {project.fieldwire_project_id && (
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={() => {
                        popupState.close();
                        blockUI.blockUI("Updating Fieldwire drawings.  This could take a bit...");
                        drawingRPC
                          .mutateAsync({action: "update-fieldwire-drawings"})
                          .then(() => {
                            blockUI.unblockUI();
                            drawingQuery.refetch();
                            enqueueSnackbar("Fieldwire drawings updated", {variant: "success"});
                          })
                          .catch(() => blockUI.unblockUI());
                      }}
                    >
                      <FontAwesomeIcon icon={faSync} fixedWidth /> Update Fieldwire Drawings
                    </PaperPanel.Header.Menu.MenuItem>
                  )}
                  {!project.plangrid_project_id && !project.fieldwire_project_id && (
                    <PaperPanel.Header.Menu.MenuItem component={Link} href={`/v2/projects/${project.id}/links/`}>
                      <FontAwesomeIcon icon={faLink} fixedWidth /> Link Project Drawings
                    </PaperPanel.Header.Menu.MenuItem>
                  )}
                  <Divider />
                  <PaperPanel.Header.Menu.MenuItem
                    onClick={() => {
                      popupState.close();
                      drawingQuery.refetch();
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} fixedWidth /> Refresh
                  </PaperPanel.Header.Menu.MenuItem>
                </div>
              )}
            </PaperPanel.Header.Menu>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="System"
                name="System"
                options={systemKeyOptions}
                value={filterOptions.System.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("System", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={drawingQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={[100, 250, 500, 1000]}
                />
              </Box>
            </Grid>
          </Grid>
          {/* <pre>{JSON.stringify(filterOptions, null, 2)}</pre> */}
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            // rightAlignColumns={["timestamp"]}
            columns={React.useMemo(
              () => [
                {
                  Header: "Name",
                  accessor: "name",
                  Cell: ({value, row}) => {
                    if (row.original.system_url) {
                      return (
                        <Link href={row.original.system_url} underline="always" target="_blank">
                          {value} <FontAwesomeIcon icon={faExternalLink} />
                        </Link>
                      );
                    }
                    if (row.original.system_key === "sentinel") {
                      return (
                        <Link
                          style={{cursor: "pointer"}}
                          onClick={() => {
                            setActiveDrawing(row.original);
                            setShowUpdateDrawingDialog(true);
                          }}
                        >
                          {value}
                        </Link>
                      );
                    }
                    return value;
                  },
                },
                {
                  Header: "Version Name",
                  accessor: "version_name",
                },
                {
                  Header: "Description",
                  accessor: "description",
                },
                {
                  Header: "System",
                  accessor: "system_key_display",
                },
              ],
              []
            )}
            // initialState={{
            //   sortBy: [
            //     {
            //       id: "name",
            //       desc: false,
            //     },
            //     {
            //       id: "version_name",
            //       desc: false,
            //     },
            //   ],
            // }}
            data={drawingQuery.data.results}
          />
          {drawingQuery.data.total_pages > 1 && (
            <PaperPanel.Footer display="flex" justifyContent="flex-end">
              <PaginationWithPageSize
                count={drawingQuery.data.total_pages}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                pageSizes={[100, 250, 500, 1000]}
              />
            </PaperPanel.Footer>
          )}
          {/* <pre>{JSON.stringify(activityStreamQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>

      <DrawingDialogForm
        isOpen={showCreateDrawingDialog}
        isNew={true}
        handleClose={() => {
          setShowCreateDrawingDialog(false);
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Creating drawing...");
          createDrawing.mutateAsync(values).then(() => {
            drawingQuery.refetch();
            setShowCreateDrawingDialog(false);
            blockUI.unblockUI();
          });
        }}
      />
      <DrawingDialogForm
        isOpen={showUpdateDrawingDialog}
        isNew={false}
        handleClose={() => {
          setShowUpdateDrawingDialog(false);
        }}
        initialValues={activeDrawing}
        onSubmit={(values) => {
          setShowUpdateDrawingDialog(false);
          updateDrawing.mutateAsync(values);
        }}
        onDelete={() => {
          setShowUpdateDrawingDialog(false);
          setDeleteConfirmationIsOpen(true);
        }}
      />
      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          deleteDrawing.mutateAsync(activeDrawing.id);
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete {activeDrawing?.display}.
      </ConfirmationDialog>
      {/* <pre>{JSON.stringify(drawingQuery, null, 2)}</pre> */}
    </>
  );
};
export default ProjectDrawings;
