import {Box, Grid} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {isEmpty} from "lodash";
import React from "react";
import {ProjectAutoselectMui, TextFieldMui} from "../../../../components/forms/Fields";

const CloneProjectFields = (props) => {
  const {namespace, values, useWizard} = props;
  const wizard = useWizard();

  const shouldClone = !isEmpty(values[namespace]?.projectToClone) && Boolean(values[namespace]?.newProjectName);

  React.useEffect(() => {
    wizard.setSubmitLabel(shouldClone ? "Clone" : "");
  }, [shouldClone]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <ProjectAutoselectMui name={`${namespace}.projectToClone`} label="Project To Clone" />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextFieldMui name={`${namespace}.newProjectName`} label="New Project Name" autoFocus />
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info">
            <Box>
              If you would like to clone an existing project, you may do so at this time. Cloning a project will copy
              the project details, directory, and markups of the existing project you select and automate the rest of
              the Project Wizard.
            </Box>
            <Box>If you would like to start with a clean slate, please click Next.</Box>
          </Alert>
        </Grid>
      </Grid>
    </>
  );
};

export default CloneProjectFields;
