import {faClock, faCheck, faQuestionCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, LinearProgress, Tooltip} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import moment from "moment";
import {colorInfo, colorSuccess, colorError, colorMutedText} from "../../../theme/colors";

export const SectionHeader = (props: {isLoading?: boolean; children: React.ReactNode; [rest: string]: any}) => {
  const {children, isLoading, ...rest} = props;
  return (
    <Box mb={2} mx={-3} mt={1}>
      <Box
        display="flex"
        py={0.5}
        px={1}
        bgcolor="grey.100"
        border={1}
        borderColor="grey.400"
        borderLeft={0}
        borderRight={0}
        alignItems="center"
        justifyContent="space-between"
        minHeight={38}
        {...rest}
      >
        {children}
      </Box>
      {isLoading && <LinearProgress color="secondary" />}
    </Box>
  );
};

const ForwardButton = (props) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" component="span">
      <ArrowForwardIcon />
    </Box>
  );
};

const BackButton = (props) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="flex-start" component="span">
      <ArrowBackIcon />
    </Box>
  );
};

export const PrevNextMondayArrows = (props: {
  children: React.ReactNode;
  weekOfDate: string | number;
  setDate: (date: string) => void;
  [rest: string]: any;
}) => {
  const {children, weekOfDate, setDate, ...rest} = props;

  const dayOfWeek = moment(weekOfDate).day();
  const daysToAdd = (8 - dayOfWeek) % 7 === 0 ? 7 : (8 - dayOfWeek) % 7;
  const daysToSubtract = dayOfWeek === 1 ? 7 : (dayOfWeek + 6) % 7;
  const nextMonday = moment(weekOfDate).add(daysToAdd, "days");
  const previousMonday = moment(weekOfDate).subtract(daysToSubtract, "days");

  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" {...rest}>
      <span onClick={() => setDate(previousMonday.format("YYYY-MM-DD"))} style={{cursor: "pointer"}}>
        <BackButton />
      </span>
      {children}
      {!nextMonday.isAfter(moment()) && (
        <span onClick={() => setDate(nextMonday.format("YYYY-MM-DD"))} style={{cursor: "pointer"}}>
          <ForwardButton />
        </span>
      )}
    </Box>
  );
};

export const NonCompliantItemCountLabel = (props: {
  nonCompliantItems: number;
  status: "pending" | "complete" | "missing";
}) => {
  const {nonCompliantItems, status} = props;
  if (status === "missing") {
    return (
      <Tooltip title="Safety Inspection Missing">
        <FontAwesomeIcon icon={faQuestionCircle} />
      </Tooltip>
    );
  }
  if (status === "pending") {
    return (
      <Tooltip title="Safety Inspection Incomplete">
        <FontAwesomeIcon icon={faClock} color={colorInfo} />
      </Tooltip>
    );
  }
  return nonCompliantItems === 0 ? (
    <Tooltip title="All observations in compliance">
      <FontAwesomeIcon icon={faCheck} color={colorSuccess} />
    </Tooltip>
  ) : (
    <span style={{color: colorError}}>{nonCompliantItems}</span>
  );
};
