import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {
  DatePickerMui,
  FileFieldMui,
  MaskedInputMui,
  SimpleSelect,
  TextFieldMui,
  TimberlineJobAutoselectMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {LabelValueList} from "../../../components/LabelValue";
import PaperPanel from "../../../components/PaperPanel";

export const DirectCostBatchDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl">
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                {isNew ? "Create Direct Cost Batch" : "Update Direct Cost Batch"}
              </DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui name="description" label="Description" required autoFocus />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui label="Accounting Date" name="date" required />
                      </Grid>
                      {isNew && (
                        <Grid item xs={12} sm={6}>
                          <FileFieldMui label="File" name="file" required />
                        </Grid>
                      )}
                    </Grid>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const DirectCostItemDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl">
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                {isNew ? "Create Direct Cost Item" : "Update Direct Cost Item"}
              </DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui name="description" label="Description" required />
                      </Grid>
                      <Grid item xs={12}>
                        <MaskedInputMui name="cost_code" label="Cost Code" mask="99-999" required />
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleSelect
                          name="category"
                          label="Category"
                          required
                          options={[
                            {value: "M", label: "Material"},
                            {value: "O", label: "Other"},
                          ]}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TimberlineJobAutoselectMui
                          name="job"
                          label="Job"
                          // baseURL="timberline/jobs/search/?status=In progress"
                          qsParams={{
                            status: "In progress",
                            show_all_for_coding: "True",
                          }}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <LabelValueList>
                          <LabelValueList.Item label="Invoice" value={values?.custom_data?.invoice_number} />
                        </LabelValueList>
                      </Grid>
                    </Grid>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
