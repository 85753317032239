import {Button} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

export const PrevNext = (props: {
  items: [];
  activeItem: {id?: number};
  idProp?: string;
  setActiveItem: (values: any) => void;
  displayProp?: string;
  beforePrevNext?: () => Promise<void>;
  afterPrevNext?: () => void;
  disabled?: boolean;
  [rest: string]: any;
}) => {
  const {
    items = [],
    activeItem = {},
    setActiveItem,
    displayProp,
    beforePrevNext,
    afterPrevNext,
    idProp = "id",
    disabled,
    ...rest
  } = props;

  const getAdjacentItems = (item, list) => {
    // const index = list.indexOf(item);
    const index = list.findIndex((listItem) => listItem[idProp] === item[idProp]);
    const groupLength = list.length;
    const previous = index - 1 > -1 ? index - 1 : null;
    const next = index < groupLength - 1 ? index + 1 : null;
    return {previous: list[previous], next: list[next]};
  };

  const {previous, next} = getAdjacentItems(activeItem, items);

  return (
    <>
      {previous && (
        <Button
          onClick={() => {
            if (beforePrevNext) {
              beforePrevNext().then(() => {
                setActiveItem(previous);
                if (afterPrevNext) afterPrevNext();
              });
            } else {
              setActiveItem(previous);
              if (afterPrevNext) afterPrevNext();
            }
          }}
          size={"small"}
          startIcon={<ArrowBackIcon />}
          disabled={disabled}
        >
          {displayProp && previous ? previous[displayProp] : `Previous`}
        </Button>
      )}
      {next && (
        <Button
          onClick={() => {
            if (beforePrevNext) {
              beforePrevNext().then(() => {
                setActiveItem(next);
                if (afterPrevNext) afterPrevNext();
              });
            } else {
              setActiveItem(next);
              if (afterPrevNext) afterPrevNext();
            }
          }}
          size={"small"}
          endIcon={<ArrowForwardIcon />}
          disabled={disabled}
        >
          {displayProp && next ? next[displayProp] : `Next`}
        </Button>
      )}
    </>
  );
};
