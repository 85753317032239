import {faCircleExclamation, faPenFancy} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import moment from "moment";
import {useLocation} from "react-router-dom";
import {Currency} from "../../../components/Accounting";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";
import {colorWarning} from "../../../theme/colors";

export const SubcontractPaperItem = (props) => {
  // useWhyDidYouUpdate("SubcontractPaperItem", props);
  const {pathname} = useLocation();
  const {
    contract,
    projectId,
    updateContract,
    isDragging,
    isSelected,
    dragHandleProps,
    addSelectedContractId,
    removeSelectedContractId,
    setActiveContract,
    setShowUpdateDialog,
    setDeleteConfirmationIsOpen,
  } = props;

  // if not contract.timberline_subcontract and contract.is_closed and contract.submitted_date|businessdays_ago > 3 and not contract.is_void
  const isSubmittedNotLinked =
    !contract.timberline_subcontract_id &&
    contract.is_closed &&
    !contract.is_void &&
    contract.submitted_date &&
    moment(contract.submitted_date).add(4, "d").isBefore(moment());

  return (
    <PaperItem isDragging={isDragging}>
      <PaperItem.Left minWidth={45}>
        <Box display="flex" alignItems="center">
          <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
          <PaperItem.Position position={contract.position} />
        </Box>
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={0}>
          <Grid item sm={8} xs={12}>
            <StatusLabel status={contract.status_display} />{" "}
            <MuiNavLink to={`/v2/projects/${projectId}/contracts/sub/${contract.id}/`} underline="always">
              {contract.display}
            </MuiNavLink>
            {isSubmittedNotLinked && (
              <Box display="inline" ml={1}>
                <Tooltip
                  title={`Subcontract was submitted on ${contract.submitted_date}  and has not been linked to Timberline`}
                >
                  <span>
                    <FontAwesomeIcon icon={faCircleExclamation} color={colorWarning} />
                  </span>
                </Tooltip>
              </Box>
            )}
          </Grid>
          <Grid item sm={4}>
            <Tooltip title={contract.budget?.display}>
              <span>
                <Typography2 noWrap type="metadata">
                  {contract.budget?.budget_code}
                </Typography2>
              </span>
            </Tooltip>
          </Grid>
          {/* New row */}

          <Grid item sm={6} xs={12}>
            <Link href={contract.to_company?.url}>
              <small>{contract.to_company?.name}</small>
            </Link>
          </Grid>
          <Grid item sm={2} xs={12}>
            {contract.docusign_envelope_status && (
              <Link href={contract.current_docusign_envelope_detail_url}>
                <Tooltip title="DocuSign Status">
                  <small>
                    <FontAwesomeIcon icon={faPenFancy} /> {contract.docusign_envelope_status}
                  </small>
                </Tooltip>
              </Link>
            )}
          </Grid>
          <Grid item sm={2} xs={6}>
            {contract.timberline_subcontract_id && (
              <Tooltip title="Timberline Subcontract">
                <small>
                  <MuiNavLink to={`/v2/projects/${projectId}/contracts/sub/${contract.id}/#tab=timberline`}>
                    {contract.timberline_subcontract_id}
                  </MuiNavLink>
                </small>
              </Tooltip>
            )}
          </Grid>
          <Grid item sm={2} xs={6}>
            {contract.original_value !== contract.current_value && (
              <Box textAlign="right">
                <Tooltip title="Original Value">
                  <small>
                    <Currency number={contract.original_value} precision={2} />
                  </small>
                </Tooltip>
              </Box>
            )}
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={110}>
        <Box textAlign="right">
          <Box>
            <Typography2 noWrap type="metadata">
              {contract.number_display}
            </Typography2>
          </Box>
          <Box>
            <Tooltip
              title={
                <>
                  Original Value: <Currency number={contract.original_value} precision={2} />
                  <br />
                  Current Value: <Currency number={contract.current_value} precision={2} />
                </>
              }
            >
              <strong>
                <Currency number={contract.current_value} precision={2} />
              </strong>
            </Tooltip>
          </Box>
        </Box>
      </PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton
          icon={LinkIcon}
          title="Link"
          component={MuiNavLink}
          to={`/v2/projects/${projectId}/contracts/sub/${contract.id}/`}
        />
        <PaperItem.RightHover.IconButton
          icon={EditIcon}
          title="Quick Edit"
          onClick={() => {
            setActiveContract(contract);
            setShowUpdateDialog(true);
          }}
        />
        <PaperItem.RightHover.IconButton
          icon={DeleteIcon}
          title="Delete"
          disabled={contract.is_closed}
          component={Link}
          href={`/v2/projects/${projectId}/delete/contracts/subcontract/${contract.id}/?next=${pathname}&cancel=${pathname}`}
        />
        <BoxFilesIconButtonLink
          projectId={projectId}
          app="contracts"
          model="subcontract"
          objectId={contract.id}
          boxFolderId={contract.box_folder_id}
        />

        <Box textAlign="right">
          <Tooltip
            title={
              <>
                Original Value: <Currency number={contract.original_value} precision={2} />
                <br />
                Current Value: <Currency number={contract.current_value} precision={2} />
              </>
            }
          >
            <strong>
              <Currency number={contract.current_value} precision={2} />
            </strong>
          </Tooltip>
        </Box>
      </PaperItem.RightHover>
    </PaperItem>
  );
};
