import {Box, Breadcrumbs as MuiBreadcrumbs} from "@material-ui/core";
import React from "react";
import useWaffle from "../hooks/useWaffle";
import {MuiNavLink} from "./Links";

export const Breadcrumbs = (props: {children?: React.ReactNode}) => {
  const {children, ...rest} = props;
  return (
    <Box my={1}>
      <MuiBreadcrumbs {...rest}>{children}</MuiBreadcrumbs>
    </Box>
  );
};

export const ProjectBreadcrumbs = (props: {
  project: {
    id: number;
    display: string;
  };
  children?: React.ReactNode;
}) => {
  const {project, children, ...rest} = props;
  const waffle = useWaffle();
  return (
    <Box my={1}>
      <MuiBreadcrumbs {...rest}>
        <MuiNavLink color="inherit" to={`/v2/dashboard/`}>
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        {children}
      </MuiBreadcrumbs>
    </Box>
  );
};
