import {
  faCalendarDay,
  faCheck,
  faCloudDownload,
  faPollH,
  faUserHardHat,
  faUsers,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CachedIcon from "@material-ui/icons/Cached";
import CheckIcon from "@material-ui/icons/Check";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import {Alert, Skeleton} from "@material-ui/lab";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment-timezone";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import HtmlRender from "../../../../js/components/HtmlRender";
import {BoxImageGallery} from "../../../components/Box";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {PDFButton} from "../../../components/Buttons";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import {ConfirmationDialog} from "../../../components/Dialogs";
import FormActions from "../../../components/forms/FormActions";
import BlockUI from "../../../components/GlobalLoaders";
import InnerFooter from "../../../components/InnerFooter";
import {LabelValue} from "../../../components/LabelValue";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TagList} from "../../../components/Tags";
import {WeatherPaperPanel} from "../../../components/Weather";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {PretaskPlanDialogForm} from "../ProjectPretaskPlans/PretaskPlanForms";
import {
  ProjectDailyReportCreateSubcontractorDialogForm,
  ProjectDailyReportDialogForm,
  ProjectDailyReportVisitorDialogForm,
  SurveyRowForm,
  WorkLogRowForm,
} from "./ProjectDailyReportForms";
import {StatusLabel} from "../../../components/Status";
import {axiosAPI} from "../../../api";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    notes: {
      "& p": {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  };
});

const ProjectDailyReportDetail = (props) => {
  const {project, user, userContact, ...rest} = props;

  const [showUpdateDailyReportDialog, setShowUpdateDailyReportDialog] = React.useState(false);
  const [showProjectDailyReportCreateSubcontractorDialog, setShowProjectDailyReportCreateSubcontractorDialog] =
    React.useState(false);
  const [showProjectDailyReportCreateVisitorDialog, setShowProjectDailyReportCreateVisitorDialog] =
    React.useState(false);
  const [showProjectDailyReportUpdateVisitorDialog, setShowProjectDailyReportUpdateVisitorDialog] =
    React.useState(false);

  setShowProjectDailyReportUpdateVisitorDialog;
  const [selectedWorkLogItem, setSelectedWorkLogItem] = React.useState(null);
  const [selectedPreviousDailyReportWorkLog, setSelectedPreviousDailyReportWorkLog] = React.useState(null);
  const [deleteWorkLogItemConfirmationIsOpen, setDeleteWorkLogItemConfirmationIsOpen] = React.useState(false);
  const [markReviewedIsOpen, setMarkReviewedIsOpen] = React.useState(false);
  const [copyWorkLogConfirmationIsOpen, setCopyWorkLogConfirmationIsOpen] = React.useState(false);
  const [workLogHelpDialogIsOpen, setWorkLogHelpDialogIsOpen] = React.useState(false);
  const [forceRefreshHoursDialogIsOpen, setForceRefreshHoursDialogIsOpen] = React.useState(false);
  const [showPretaskPlanDialog, setShowPretaskPlanDialog] = React.useState(false);
  const [currentPretaskPlan, setCurrentPretaskPlan] = React.useState({} as any);
  const [showUnlockDialog, setShowUnlockDialog] = React.useState(false);
  const [auditConfirmationIsOpen, setAuditConfirmationIsOpen] = React.useState(false);
  const [auditedFormValues, setAuditedFormValues] = React.useState({});
  const [reviseResubmitDialogIsOpen, setReviseResubmitDialogIsOpen] = React.useState(false);
  const [markUnansweredSurveyNoConfirmationIsOpen, setMarkUnansweredSurveyNoConfirmationIsOpen] = React.useState(false);
  const [markUnansweredSurveyNAConfirmationIsOpen, setMarkUnansweredSurveyNAConfirmationIsOpen] = React.useState(false);

  const [activeVisitor, setActiveVisitor] = React.useState(null);

  const {dailyReportId} = useParams();
  const classes = useStyles();

  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  // const {
  //   data: pageData,
  //   refetch: refetchPageData,
  // } = useFetchCurrentPage({
  //   initialData: {
  //     canMarkReviewedReasons: [],
  //     previousDailyReports: [],
  //     canMarkReviewed: false,
  //     canMarkNotReviewed: false,
  //     safetyOrientations: [],
  //   },
  // });

  const pageDataQuery = useFetchCurrentPage({
    initialData: {
      canMarkReviewedReasons: [],
      previousDailyReports: [],
      canMarkReviewed: false,
      canMarkNotReviewed: false,
      safetyOrientations: [],
    },
  });

  const {data: pageData, refetch: refetchPageData} = pageDataQuery;

  React.useEffect(() => {
    setSelectedPreviousDailyReportWorkLog(
      pageData.previousDailyReports.length > 0 ? pageData.previousDailyReports[0] : null
    );
  }, [pageData.previousDailyReports]);

  const {
    query: dailyReportQuery,
    update: updateDailyReport,
    rpc: dailyReportRPC,
  } = useSentinelDetailAPI(
    `projects/${project.id}/daily-reports/${dailyReportId}/`,
    {
      initialData: {},
      refetchOnReconnect: false,
    },
    {resetOnUpdateError: false}
  );
  const dailyReport = dailyReportQuery.data;

  const {
    query: workLogQuery,
    create: createWorkLog,
    update: updateWorkLog,
    delete: deleteWorkLog,
    rpc: workLogRPC,
  } = useSentinelListAPI(
    `/projects/${project.id}/daily-reports/${dailyReportId}/work-log/`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    },
    {
      disableOnSuccess: true,
      disableOnMutate: true,
    }
  );
  const workLog = workLogQuery.data?.results || [];

  const {
    query: surveyQuery,
    update: updateSurvey,
    rpc: surveyRPC,
  } = useSentinelListAPI(
    `/projects/${project.id}/daily-reports/${dailyReportId}/surveys/`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    },
    {
      disableOnSuccess: true,
      disableOnMutate: true,
    }
  );
  // HACK: fix strange bug where surveyQuery.data is undefined sometimes in production
  const survey = surveyQuery.data?.results || [];

  const {
    query: visitorQuery,
    create: createVisitor,
    update: updateVisitor,
    delete: deleteVisitor,
  } = useSentinelListAPI(`/projects/${project.id}/daily-reports/${dailyReportId}/visitors/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });
  const visitors = visitorQuery.data?.results || [];
  const dailyReportDate = moment(dailyReport.date).format("YYYY-MM-DD");

  const {
    query: pretaskPlanQuery,
    update: updatePretaskPlan,
    rpc: pretaskPlanRPC,
  } = useSentinelListAPI(
    `/projects/${project.id}/superintendents/pretask-plans/?page_size=300&date_min=${dailyReportDate}&date_max=${dailyReportDate}`,
    {
      initialData: {
        results: [],
      },
      enabled: Boolean(dailyReport.date),
      keepPreviousData: true,
    }
  );
  const pretaskPlans = pretaskPlanQuery.data?.results || [];
  const safetyOrientations = pageData?.safetyOrientations;

  const {query: permitQuery} = useSentinelListAPI(
    `/projects/${project.id}/safety/permits/onsite/?page_size=1000&date_min=${dailyReportDate}&date_max=${dailyReportDate}`,
    {
      initialData: {
        results: [],
      },
      enabled: Boolean(dailyReport.date),
      keepPreviousData: true,
    }
  );
  const permits = permitQuery.data?.results || [];

  const canUnlock =
    ["submitted", "audited"].includes(currentPretaskPlan.status) &&
    !currentPretaskPlan.is_unlocked &&
    currentPretaskPlan.is_closed;

  const isReadOnly = dailyReport.status === "reviewed";

  const saveSurveyItem = React.useCallback((newValue, values) => {
    return updateSurvey.mutateAsync({...values, ...newValue}).then(() => {
      if (Object.keys(newValue).includes("answer")) {
        setTimeout(() => {
          refetchPageData();
        }, 250);
      }
      // refetchPageData();
    });
  }, []);

  const saveWorkLogItem = React.useCallback((newValue, values) => {
    return updateWorkLog.mutateAsync({...values, ...newValue}).then(() => {
      if (Object.keys(newValue).includes("hours") || Object.keys(newValue).includes("quantity")) {
        setTimeout(() => {
          dailyReportQuery.refetch();
        }, 250);
      }
    });
  }, []);
  const deleteWorkLogItem = React.useCallback((workLogItem) => {
    setSelectedWorkLogItem(workLogItem);
    setDeleteWorkLogItemConfirmationIsOpen(true);
  }, []);

  const reviseResubmitContactEmail =
    currentPretaskPlan?.survey?.contact?.email ?? currentPretaskPlan?.twilio_contact?.email;

  if (!dailyReportQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching daily report..." />;
  }

  return (
    <>
      <Helmet title={`Daily Report - ${dailyReport.display}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/daily-reports/`}>
          Daily Reports
        </MuiNavLink>
        <Typography color="textPrimary">{dailyReport.display}</Typography>
      </ProjectBreadcrumbs>
      {/* <SafariBugBanner mb={2} /> */}
      {isReadOnly && (
        <Box mb={2}>
          <Alert severity="info">
            This report was marked reviewed {moment(dailyReport.reviewed_date).format("LLLL")} by{" "}
            {dailyReport.reviewed_by?.full_name}. It has been locked for editing.
          </Alert>
        </Box>
      )}
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faCalendarDay} /> Daily Report - {dailyReport.display}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButtons
                pdfURL={`/reports2/projects/${project.id}/daily-reports/${dailyReport.id}/pretask-plans/`}
                buildReportURL={`/reports2/projects/${project.id}/daily-reports/${dailyReport.id}/filter/`}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.EditButton onClick={() => setShowUpdateDailyReportDialog(true)} disabled={isReadOnly}>
                Edit
              </PaperPanel.Header.EditButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => dailyReportQuery.refetch()}
                isFetching={dailyReportQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={2}>
          <LabelValue label="Date:" value={dailyReportDate} />
          <LabelValue label="Tags:">
            <TagList tags={dailyReport.tags} />
          </LabelValue>
          <LabelValue label="Notes:">
            <Box className={classes.notes} maxHeight={250} overflow="auto">
              <HtmlRender html={dailyReport.notes} />
            </Box>
          </LabelValue>
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={2} />
      <WeatherPaperPanel projectId={project.id} date={dailyReportDate} />
      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={workLogQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faUserHardHat} /> Work Log
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                onClick={() => setForceRefreshHoursDialogIsOpen(true)}
                startIcon={<FontAwesomeIcon icon={faCloudDownload} />}
                disabled={isReadOnly}
              >
                Update Pretask Plan Labor
              </PaperPanel.Header.Button>
              <Box mr={1} />
              <PaperPanel.Header.Button
                onClick={() => setCopyWorkLogConfirmationIsOpen(true)}
                disabled={!pageData.previousDailyReports.length || isReadOnly}
                startIcon={<FileCopyIcon />}
              >
                Copy Previous Work Log
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.CreateButton
                onClick={() => setShowProjectDailyReportCreateSubcontractorDialog(true)}
                disabled={isReadOnly}
              >
                Add Subcontractor
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.IconButton onClick={() => setWorkLogHelpDialogIsOpen(true)}>
                <HelpOutlineIcon />
              </PaperPanel.Header.IconButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                disabled={isReadOnly}
                isFetching={workLogQuery.isFetching}
                onClick={() => {
                  blockUI.blockUI("Refreshing work log...");
                  workLogRPC.mutateAsync({action: "refresh"}).then(() => {
                    dailyReportQuery.refetch();
                    workLogQuery.refetch().then(() => blockUI.unblockUI());
                  });
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {!workLogQuery.isFetched ? (
            <Box p={1}>
              {[...Array(15)].map((_, i) => (
                <Skeleton key={i} />
              ))}
            </Box>
          ) : (
            workLog.map((workLogItem) => {
              return (
                <WorkLogRowForm
                  key={workLogItem.id}
                  workLogItem={workLogItem}
                  projectId={project.id}
                  isReadOnly={isReadOnly}
                  onSave={saveWorkLogItem}
                  onDelete={deleteWorkLogItem}
                />
              );
            })
          )}
        </PaperPanel.Body>
        <PaperPanel.Footer display="flex" justifyContent="flex-end">
          Total Hours: {accounting.formatNumber(dailyReport.work_log_current_hours)} Hours To Date:{" "}
          {accounting.formatNumber(dailyReport.work_log_hours_to_date)}
        </PaperPanel.Footer>
      </PaperPanel>
      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={surveyQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faPollH} /> Survey
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                onClick={() => setMarkUnansweredSurveyNoConfirmationIsOpen(true)}
                disabled={isReadOnly}
                startIcon={<FontAwesomeIcon icon={faCheck} fixedWidth size="sm" />}
              >
                Mark Unanswered as No
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                onClick={() => setMarkUnansweredSurveyNAConfirmationIsOpen(true)}
                disabled={isReadOnly}
                startIcon={<FontAwesomeIcon icon={faXmark} fixedWidth size="xs" />}
              >
                Mark Unanswered as N/A
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                disabled={isReadOnly}
                isFetching={surveyQuery.isFetching}
                onClick={() => {
                  blockUI.blockUI("Refreshing survey...");
                  surveyRPC.mutateAsync({action: "refresh"}).then(() => {
                    dailyReportQuery.refetch();
                    surveyQuery.refetch().then(() => blockUI.unblockUI());
                  });
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {!surveyQuery.isFetched ? (
            <Box p={1}>
              {[...Array(15)].map((_, i) => (
                <Skeleton key={i} />
              ))}
            </Box>
          ) : (
            survey.map((surveyItem) => {
              return (
                <SurveyRowForm
                  key={surveyItem.id}
                  surveyItem={surveyItem}
                  isReadOnly={
                    isReadOnly || markUnansweredSurveyNoConfirmationIsOpen || markUnansweredSurveyNAConfirmationIsOpen
                  }
                  onSave={saveSurveyItem}
                />
              );
            })
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={visitorQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faUsers} /> Visitors
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => setShowProjectDailyReportCreateVisitorDialog(true)}
                disabled={isReadOnly}
              >
                Add Visitor
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => visitorQuery.refetch()}
                isFetching={visitorQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}
            hover
            getRowProps={(row) => {
              if (isReadOnly) return;
              return {
                style: {cursor: "pointer"},
                onClick: () => {
                  setActiveVisitor(row.original);
                  setShowProjectDailyReportUpdateVisitorDialog(true);
                },
              };
            }}
            columns={[
              {
                Header: "Name",
                accessor: "visitor_name",
              },
              {
                Header: "Company",
                accessor: "visitor_company_name",
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "visitor_company_name",
                  desc: false,
                },
                {
                  id: "visitor_name",
                  desc: false,
                },
              ],
            }}
            data={visitors}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={2} />
      <BoxImageGallery
        url={`/projects/${project.id}/daily-reports/${dailyReport.id}/box-image-gallery/`}
        pdfURL={`/reports2/projects/${project.id}/daily-reports/${dailyReport.id}/box-image-gallery/`}
        boxFolderId={dailyReport.box_folder_id}
        collaborationFolderId={pageData.projectPhotoFolderBoxId}
        user={user}
        quickUploadURL={`/projects/${project.id}/daily-reports/${dailyReportId}/quick-upload/`}
      />
      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={pretaskPlanQuery.isFetching}>
          <PaperPanel.Header.Title>Pretask Plans - {dailyReportDate}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButtons
                pdfURL={`/reports2/projects/${project.id}/pretask-plans/${moment(dailyReport.date).format(
                  "YYYY-MM-DD"
                )}/`}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pretaskPlanQuery.refetch()}
                isFetching={pretaskPlanQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}
            hover
            columns={[
              {
                Header: "Name",
                accessor: "twilio_contact.name",
                Cell: ({row}) => {
                  const pretaskPlan = row.original;
                  return (
                    <Link
                      // href={`/reports2/projects/${project.id}/pretask-plan/${pretaskPlan.id}/`}
                      // target="_blank"
                      onClick={() => {
                        setCurrentPretaskPlan(pretaskPlan);
                        setShowPretaskPlanDialog(true);
                      }}
                      underline="always"
                      style={{cursor: "pointer"}}
                    >
                      {pretaskPlan?.twilio_contact?.name}
                    </Link>
                  );
                },
              },
              {
                Header: "Company",
                accessor: "twilio_contact.company",
              },
              {
                Header: "Created",
                accessor: "created",
                Cell: ({value}) => (value ? moment.tz(value, project.timezone).format("hh:mm A z") : ""),
              },
              {
                Header: "Status",
                accessor: "status_display",
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "twilio_contact.name",
                  desc: false,
                },
              ],
            }}
            data={pretaskPlans}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>Safety Orientations Completed - {dailyReportDate}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {/* <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButtons
                pdfURL={`/reports2/projects/${project.id}/pretask-plans/${moment(dailyReport.date).format(
                  "YYYY-MM-DD"
                )}/`}
              />
            </PaperPanel.Header.Action> */}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => pageDataQuery.refetch()}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}
            getHeaderProps={(column) => {
              if (["sticker_number"].includes(column.id)) {
                return {
                  style: {
                    width: "75px",
                    whiteSpace: "nowrap",
                  },
                };
              }
            }}
            columns={[
              {
                Header: "Name",
                accessor: "twilio_contact.name",
              },
              {
                Header: "Company",
                accessor: "twilio_contact.company",
              },
              {
                Header: "Completed",
                accessor: "created",
                Cell: ({value}) => (value ? moment.tz(value, project.timezone).format("hh:mm A z") : ""),
              },
              {
                Header: "Sticker #",
                accessor: "sticker_number",
                Cell: ({value}) => <Box textAlign="center">{value}</Box>,
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "twilio_contact.name",
                  desc: false,
                },
              ],
            }}
            data={safetyOrientations}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <Box mb={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={permitQuery.isFetching}>
          <PaperPanel.Header.Title>Permits Completed - {dailyReportDate}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => permitQuery.refetch()}
                isFetching={permitQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}

            columns={[
              {
                Header: "Permit",
                accessor: "form_type_display",
                Cell: ({row, value}) => {
                  return (
                    <>
                      <StatusLabel status={row.original.status_with_expiration_display} hint="projectSafetyForm" />{" "}
                      <MuiNavLink href={row.original.detail_url} underline="always" style={{cursor: "pointer"}}>
                        {row.original.display}
                        {row.original.form_type === "hot_work" && row.original.answers?.general?.location
                          ? ` (${row.original.answers.general.location})`
                          : ""}
                      </MuiNavLink>
                    </>
                  );
                },
              },
              {
                Header: "Company",
                accessor: "twilio_contact.company",
              },
              {
                Header: "Name",
                accessor: "twilio_contact.name",
              },
            ]}
            data={permits}
          />
        </PaperPanel.Body>
      </PaperPanel>

      <InnerFooter>
        <Box display="flex">
          <Box>
            {pageData.canMarkReviewed ? (
              <Button
                variant="contained"
                color="secondary"
                startIcon={<CheckIcon />}
                disableElevation
                onClick={() => {
                  setMarkReviewedIsOpen(true);
                }}
              >
                Mark Reviewed
              </Button>
            ) : pageData.canMarkNotReviewed ? (
              <Button
                variant="contained"
                // color="secondary"
                startIcon={<CheckIcon />}
                disableElevation
                onClick={() => {
                  blockUI.blockUI("Marking daily report as not reviewed...");
                  dailyReportRPC.mutateAsync({action: "mark-not-reviewed"}).then(() => {
                    window.location.reload();
                  });
                }}
              >
                Mark Not Reviewed
              </Button>
            ) : (
              <Tooltip
                title={
                  pageData?.canMarkReviewedReasons
                    ? pageData.canMarkReviewedReasons.map((reason) => reason.message).join(", ")
                    : ""
                }
              >
                <span>
                  <Button variant="outlined" color="secondary" startIcon={<CheckIcon />} disabled>
                    Mark Reviewed
                  </Button>
                </span>
              </Tooltip>
            )}
          </Box>
          <Box ml={2}>
            <PDFButton
              ml={2}
              href={`/reports2/projects/${project.id}/daily-reports/${dailyReport.id}/pretask-plans/`}
              target="_blank"
            />
          </Box>
        </Box>
      </InnerFooter>
      <CreatedByModifiedBy obj={dailyReport} />
      <LegacyUILink href={`/projects/${project.id}/daily-reports/${dailyReport.id}/legacy/`} mt={2} />
      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(dailyReportQuery, null, 2)}</pre> */}
      <ProjectDailyReportDialogForm
        // isNew
        projectId={project.id}
        isOpen={showUpdateDailyReportDialog}
        initialValues={dailyReport}
        onSubmit={(values) => {
          updateDailyReport.mutateAsync(values).then(() => setShowUpdateDailyReportDialog(false));
        }}
        handleClose={() => setShowUpdateDailyReportDialog(false)}
      />
      <ProjectDailyReportCreateSubcontractorDialogForm
        projectId={project.id}
        isNew
        isOpen={showProjectDailyReportCreateSubcontractorDialog}
        initialValues={{}}
        onSubmit={(values) => {
          createWorkLog.mutateAsync({type: "subcontractor", ...values}).then(() => dailyReportQuery.refetch());
          setShowProjectDailyReportCreateSubcontractorDialog(false);
        }}
        handleClose={() => setShowProjectDailyReportCreateSubcontractorDialog(false)}
      />
      <ProjectDailyReportVisitorDialogForm
        isNew
        isOpen={showProjectDailyReportCreateVisitorDialog}
        initialValues={{}}
        onSubmit={(values) => {
          createVisitor.mutateAsync(values);
          setShowProjectDailyReportCreateVisitorDialog(false);
        }}
        handleClose={() => setShowProjectDailyReportCreateVisitorDialog(false)}
      />
      <ProjectDailyReportVisitorDialogForm
        isOpen={showProjectDailyReportUpdateVisitorDialog}
        initialValues={activeVisitor}
        onSubmit={(values) => {
          updateVisitor.mutateAsync(values);
          setShowProjectDailyReportUpdateVisitorDialog(false);
        }}
        onDelete={() => {
          setShowProjectDailyReportUpdateVisitorDialog(false);
          deleteVisitor.mutateAsync(activeVisitor.id);
        }}
        handleClose={() => setShowProjectDailyReportUpdateVisitorDialog(false)}
      />
      <PretaskPlanDialogForm
        projectId={project.id}
        userContact={userContact}
        // initialValues={currentPretaskPlan}
        initialValues={{
          ...currentPretaskPlan,
          audited_by: currentPretaskPlan.audited_by ?? userContact,
          audited_date: currentPretaskPlan.audited_date ?? moment().format("YYYY-MM-DD"),
        }}
        isOpen={showPretaskPlanDialog}
        handleClose={() => setShowPretaskPlanDialog(false)}
        // onSubmit={(values) => {
        //   setShowPretaskPlanDialog(false);
        //   updatePretaskPlan.mutateAsync(values);
        // }}
        onSubmit={(values) => {
          setAuditedFormValues(values);
          setAuditConfirmationIsOpen(true);
        }}
        onReviseResubmit={(values) => {
          setAuditedFormValues(values);
          setReviseResubmitDialogIsOpen(true);
        }}
        canUnlock={canUnlock}
        onUnlockPlan={() => {
          setShowPretaskPlanDialog(false);
          setShowUnlockDialog(true);
        }}
        isReadOnly={currentPretaskPlan.status !== "submitted"}
      />

      <ConfirmationDialog
        isOpen={auditConfirmationIsOpen}
        onDeny={() => setAuditConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving Changes...");
          pretaskPlanRPC
            .mutateAsync({action: `${currentPretaskPlan.id}/mark-audited`, data: auditedFormValues})
            .then(() => {
              // updatePretaskPlan.mutateAsync(auditedFormValues).then(() => {
              setShowPretaskPlanDialog(false);
              setAuditConfirmationIsOpen(false);
              blockUI.unblockUI();
            });
        }}
        title="Audit this Pretask Plan?"
      >
        I have audited this Pretask Plan and certify that is has been completed correctly.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={reviseResubmitDialogIsOpen}
        onDeny={() => setReviseResubmitDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving Changes...");
          pretaskPlanRPC
            .mutateAsync({action: `${currentPretaskPlan.id}/mark-revise-resubmit`, data: auditedFormValues})
            .then(() => {
              setReviseResubmitDialogIsOpen(false);
              setShowPretaskPlanDialog(false);
              blockUI.unblockUI();
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
      >
        You want to mark this Pretask Plan <strong>Revise Resubmit</strong>.{" "}
        {reviseResubmitContactEmail
          ? `${currentPretaskPlan?.twilio_contact?.name} will receive an email at ${reviseResubmitContactEmail} with a link to make corrections.`
          : `No email address found for ${currentPretaskPlan?.twilio_contact?.name}, please inform them manually.`}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showUnlockDialog}
        onDeny={() => setShowUnlockDialog(false)}
        onApprove={() => {
          blockUI.blockUI("Saving Changes...");
          pretaskPlanRPC
            .mutateAsync({action: `${currentPretaskPlan.id}/unlock-pretask-plan`})
            .then(() => {
              setShowUnlockDialog(false);
              blockUI.unblockUI();
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
        title="Unlock this Pretask Plan?"
      >
        Do you want to unlock this Pretask Plan for editing? This will allow the foreman to make changes.
      </ConfirmationDialog>
      <Dialog open={workLogHelpDialogIsOpen} onClose={() => setWorkLogHelpDialogIsOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle id="form-dialog-title">Work Log Help</DialogTitle>
        <DialogContent>
          <Box>Don't see the sub you are looking for?</Box>
          <Box>
            Make sure that they are added to the directory{" "}
            <Link underline="always" href={`/projects/${project.id}/directory/`} target="_blank">
              here
            </Link>
          </Box>
          <Box>
            Once they have been added to the directory click the{" "}
            <IconButton size="small">
              <CachedIcon />
            </IconButton>{" "}
            button.
          </Box>
        </DialogContent>
        <DialogActions>
          <FormActions.CancelButton onClick={() => setWorkLogHelpDialogIsOpen(false)} />
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        isOpen={deleteWorkLogItemConfirmationIsOpen}
        onApprove={() => {
          setDeleteWorkLogItemConfirmationIsOpen(false);
          deleteWorkLog.mutateAsync(selectedWorkLogItem.id).then(() => dailyReportQuery.refetch());
        }}
        onDeny={() => setDeleteWorkLogItemConfirmationIsOpen(false)}
      >
        Are you sure you want to delete from this daily report {selectedWorkLogItem?.name_display}.
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={copyWorkLogConfirmationIsOpen}
        onApprove={() => {
          setCopyWorkLogConfirmationIsOpen(false);
          blockUI.blockUI("Copying previous work log...");
          dailyReportRPC
            .mutateAsync({action: "copy-previous-work-log", data: {dailyReport: selectedPreviousDailyReportWorkLog}})
            .then(() => {
              dailyReportQuery.refetch();
              workLogQuery.refetch().then(() => blockUI.unblockUI());
            });
        }}
        onDeny={() => setCopyWorkLogConfirmationIsOpen(false)}
      >
        You want to copy a work log from a previous daily report. The current work log will be overwritten. This cannot
        be undone.
        <Box mb={2} />
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="page_size">Previous Work Log</InputLabel>
          <Select
            label="previous_work_log"
            labelId="previous_work_log"
            onChange={(event) => {
              setSelectedPreviousDailyReportWorkLog(event.target.value);
            }}
            value={selectedPreviousDailyReportWorkLog}
          >
            {pageData.previousDailyReports.map((previousDailyReport) => {
              return (
                <MenuItem
                  key={previousDailyReport.id}
                  value={previousDailyReport}
                  dense
                  selected={previousDailyReport.id === selectedPreviousDailyReportWorkLog?.id}
                >
                  {moment(previousDailyReport?.date).format("dddd")} - {previousDailyReport?.date} -{" "}
                  {previousDailyReport?.prepared_by.full_name}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={markReviewedIsOpen}
        onApprove={() => {
          setMarkReviewedIsOpen(false);
          blockUI.blockUI("Marking daily report as reviewed...");
          dailyReportRPC.mutateAsync({action: "mark-reviewed"}).then(() => {
            window.location.reload();
          });
        }}
        onDeny={() => setMarkReviewedIsOpen(false)}
      >
        You want to mark this report as reviewed. This will lock the report.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={forceRefreshHoursDialogIsOpen}
        onDeny={() => setForceRefreshHoursDialogIsOpen(false)}
        onApprove={() => {
          setForceRefreshHoursDialogIsOpen(false);
          blockUI.blockUI("Fetching Hours...");
          dailyReportRPC
            .mutateAsync({action: "force-update-hours"})
            .then(() => {
              window.location.reload();
            })
            .catch(() => blockUI.unblockUI());
        }}
        title="Update Labor Hours From Pretask Plans"
      >
        You want to update hours from the Pretask Plans. This will overwrite all labor hours you have entered.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={markUnansweredSurveyNoConfirmationIsOpen}
        onDeny={() => setMarkUnansweredSurveyNoConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving...");
          surveyRPC.mutateAsync({action: "set-unanswered-to-no"}).then(() => {
            Promise.all([dailyReportQuery.refetch(), surveyQuery.refetch()]).then(() => {
              // Hack so that SurveyRowForm do not get autosaved when values changed on refetch (form is marked isReadOnly by setMarkUnansweredSurveyNoConfirmationIsOpen)
              setTimeout(() => {
                setMarkUnansweredSurveyNoConfirmationIsOpen(false);
                blockUI.unblockUI();
              }, 100);
            });
          });
        }}
        title="Mark all unanswered as no?"
      >
        All survey questions that have not been answered will be marked as "No".
      </ConfirmationDialog>
      <ConfirmationDialog
        isOpen={markUnansweredSurveyNAConfirmationIsOpen}
        onDeny={() => setMarkUnansweredSurveyNAConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Saving...");
          surveyRPC.mutateAsync({action: "set-unanswered-to-na"}).then(() => {
            Promise.all([dailyReportQuery.refetch(), surveyQuery.refetch()]).then(() => {
              // Hack so that SurveyRowForm do not get autosaved when values changed on refetch (form is marked isReadOnly by markUnansweredSurveyNAConfirmationIsOpen)
              setTimeout(() => {
                setMarkUnansweredSurveyNAConfirmationIsOpen(false);
                blockUI.unblockUI();
              }, 100);
            });
          });
        }}
        title="Mark all unanswered as N/A?"
      >
        All survey questions that have not been answered will be marked as "N/A".
      </ConfirmationDialog>

      {/* <LegacyUILink href={`/projects/${project.id}/daily-reports/legacy/`} mt={2} /> */}
    </>
  );
};

export default ProjectDailyReportDetail;
