import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {isEmpty} from "lodash";
import {Checkboxes} from "mui-rff";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import companyRolesOptions from "../../../components/forms/choices/companyRoles.json";
import contactRolesOptions from "../../../components/forms/choices/contactRoles.json";
import {
  ApiAutoselectMui,
  AutoselectMui,
  CompanyAutoselectMui,
  CompanyContactAutoselectMui,
  CSICodeAutoselectMui,
  SimpleAutoselectMui,
  TextFieldMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {SuperuserInfo} from "../../../components/SuperuserInfo";
import Typography2 from "../../../components/Typography2";
import {validateEmailAddress} from "../../../utils/validators";

export const AddCompanyDialogForm = (props) => {
  const {onSubmit, isOpen, handleClose, isReadOnly, projectId, ...rest} = props;
  const [selectedCompany, setSelectedCompany] = React.useState({} as any);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Add Company to Project</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <CompanyAutoselectMui
                        project={projectId}
                        name="company"
                        label="Company"
                        onChange={(e, selection, reason) => {
                          setSelectedCompany(selection);
                          form.batch(() => {
                            form.change("company_role_id", {
                              label: selection?.company_role_description,
                              value: selection?.company_role_id,
                            });
                          });
                        }}
                        autoFocus
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CompanyLocationSelect
                        name="location"
                        label="Location"
                        company={selectedCompany}
                        disabled={isEmpty(selectedCompany)}
                      />
                    </Grid>{" "}
                    {!isEmpty(selectedCompany) && (
                      <Grid item xs={12}>
                        <AutoselectMui
                          name="company_role_id"
                          label="Role"
                          options={companyRolesOptions}
                          disabled={isEmpty(selectedCompany)}
                        />
                      </Grid>
                    )}
                    {values?.company_role_id?.value === "subcontractor" && (
                      <>
                        <Grid item xs={12}>
                          <CSICodeAutoselectMui name="csi_code" label="CSI Code" projectId={projectId} />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="subcontractor_job_number" label="Subcontractor Job Number" />
                        </Grid>
                      </>
                    )}
                    {values?.company_role_id !== "subcontractor" && (
                      <Grid item xs={12}>
                        <Checkboxes name="is_primary" data={{label: "Primary", value: values.is_primary}} />
                      </Grid>
                    )}
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const ProjectCompanyEditDialog = (props) => {
  const {isOpen, handleClose, onSubmit, projectCompany, onDelete, projectId, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">{projectCompany.company.name}</DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {!isEmpty(projectCompany) && (
                          <Grid item xs={12}>
                            <SimpleAutoselectMui
                              name="company_role_id"
                              label="Role"
                              options={companyRolesOptions.map((option) => ({
                                value: option.value,
                                label: option.label,
                              }))}
                              autoFocus
                              required
                            />
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <CompanyLocationSelect
                          company={projectCompany.company}
                          name="company_location"
                          label="Location"
                        />
                      </Grid>
                      {values?.company_role_id === "subcontractor" && (
                        <>
                          <Grid item xs={12}>
                            <CSICodeAutoselectMui name="csi_code" label="CSI Code" projectId={projectId} />
                          </Grid>
                          <Grid item xs={12}>
                            <TextFieldMui name="subcontractor_job_number" label="Subcontractor Job Number" />
                          </Grid>
                        </>
                      )}
                    </Grid>
                    {values?.company_role_id !== "subcontractor" && (
                      <Grid item xs={12}>
                        <Checkboxes name="is_primary" data={{label: "Primary", value: values.is_primary}} />
                      </Grid>
                    )}
                  </FinalFormKeyboardShortcuts>
                  <SuperuserInfo
                    objects={[
                      {
                        contentType: "projects",
                        model: "projectcompany",
                        id: projectCompany.id,
                        title: projectCompany.company.name,
                      },
                    ]}
                  />
                </DialogContent>
                <DialogActions>
                  <FormActions.DeleteButton onClick={onDelete} />
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const CompanyCreateDialog = (props) => {
  const {isOpen, handleClose, onSubmit, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Create A New Company</DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui name="name" label="Company Name" required autoFocus />
                      </Grid>
                      <Grid item xs={12}>
                        <SimpleAutoselectMui
                          name="company_role_id"
                          required
                          label="Role"
                          options={companyRolesOptions.map((option) => ({
                            value: option.value,
                            label: option.label,
                          }))}
                        />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const CompanyLocationSelect = (props) => {
  const {company, ...rest} = props;
  if (!isEmpty(company)) {
    return (
      <ApiAutoselectMui
        getOptionLabel={(option) =>
          option
            ? `${option.description ? `${option.description}  - ` : ""}${option.address1} ${option.address2} ${
                option.city
              }, ${option.state} ${option.postal_code} `
            : ""
        }
        baseURL={`/companies/${company.id}/locations/`}
        qsParams={{
          no_deleted: "True",
          ordering: "description",
        }}
        minCharacters={0}
        renderOption={(option: any) => {
          return (
            <>
              {option.description !== "" ? <div>{option.description}</div> : <div>{company.name}</div>}
              <div>
                <Typography2 type="metadata">
                  <div>{`${option.address1} ${option.address2}`}</div>
                  <div>{`${option.city}, ${option.state} ${option.postal_code}`}</div>
                </Typography2>
              </div>
            </>
          );
        }}
        {...props}
      />
    );
  }
};

export const ProjectContactEditDialog = (props) => {
  const {isOpen, handleClose, onSubmit, projectContact, onDelete, ...rest} = props;

  const getCategory = React.useMemo(() => {
    const {company_role_id} = projectContact;

    if (["general_contractor"].includes(company_role_id)) {
      return "Internal";
    }
    if (["subcontractor"].includes(company_role_id)) {
      return "Subcontractor";
    }
    if (["owner", "client"].includes(company_role_id)) {
      return "Owner";
    }
    return "External";
  }, [projectContact]);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">{projectContact.contact.full_name}</DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <SimpleAutoselectMui
                          name="contact_role_id"
                          label="Role"
                          options={contactRolesOptions
                            .filter((option) => option.group === getCategory)
                            .map((option) => ({value: option.value, label: option.label}))}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui
                          name="contact_role_other_description"
                          label="Other Role"
                          helperText="Supersedes role if populated"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Checkboxes name="is_primary" data={{label: "Primary", value: values.is_primary}} />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                  <SuperuserInfo
                    objects={[
                      {
                        contentType: "projects",
                        model: "projectcompanycontact",
                        id: projectContact.id,
                        title: projectContact.contact.full_name,
                      },
                    ]}
                  />
                </DialogContent>
                <DialogActions>
                  <FormActions.DeleteButton onClick={onDelete} />
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const LinkContactToCompanyDialog = React.forwardRef(
  (
    props: {
      onSubmit: (values: any) => void;
      handleClose: () => void;
      isOpen: boolean;
      company: any;
      [rest: string]: any;
    },
    ref
  ) => {
    const {isOpen, handleClose, onSubmit, company, ...rest} = props;

    const getCategory = React.useMemo(() => {
      const {company_role_id} = company;

      if (["general_contractor"].includes(company_role_id)) {
        return "Internal";
      }
      if (["subcontractor"].includes(company_role_id)) {
        return "Subcontractor";
      }
      if (["owner", "client"].includes(company_role_id)) {
        return "Owner";
      }
      return "External";
    }, [company]);

    const formRoleOptions = contactRolesOptions
      .filter((option) => option.group === getCategory)
      .map((option) => ({value: option.value, label: option.label}));

    return (
      <>
        <Dialog open={isOpen} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine = true, values}) => {
              return (
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                  <DialogTitle id="form-dialog-title">Add Contact to {company.company.name}</DialogTitle>
                  <DialogContent>
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <CompanyContactAutoselectMui
                            companyId={company.company.id}
                            name="contact"
                            label="Contact"
                            autoFocus
                            required
                          />
                        </Grid>
                        <Grid item xs={12}>
                          {/* <SimpleAutoselectMui
                            name="contact_role_id"
                            label="Role"
                            options={formRoleOptions}
                            value={null} //Hack to get this to reset on Save and Add
                          /> */}
                          <AutoselectMui name="contact_role_id" label="Role" options={formRoleOptions} />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui
                            name="contact_role_other_description"
                            label="Other Role"
                            helperText="Supersedes role if populated"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Checkboxes name="is_primary" data={{label: "Primary", value: values.is_primary}} />
                        </Grid>
                      </Grid>
                    </FinalFormKeyboardShortcuts>
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    <FormActions.SaveAndAddButton
                      onClick={() => form.change("submitMode", "addAnother")}
                      disabled={submitting || pristine}
                    />
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);
export const ContactCreateDialog = (props) => {
  const {isOpen, handleClose, onSubmit, ...rest} = props;

  const validateContactEmail = async (value, values, field) => {
    if (!values.email) return "Required";
    if (!validateEmailAddress(value)) {
      return "Not a valid email address";
    }
    if (values.email) {
      const response = await axiosAPI.get(`/contacts/validate-email/?email=${values.email}`);
      if (response.data.error) {
        return response.data.error;
      }
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Create A New Contact</DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui
                          name="email"
                          label="Email"
                          fieldProps={{
                            validate: validateContactEmail,
                          }}
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldMui name="first_name" label="First Name" required />
                      </Grid>
                      <Grid item xs={6}>
                        <TextFieldMui name="last_name" label="Last Name" required />
                      </Grid>
                      <Grid item xs={12}>
                        <CompanyAutoselectMui name="company" label="Company" required />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
