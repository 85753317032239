import {faPaperclip} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Typography} from "@material-ui/core";
import moment from "moment";
import {useSnackbar} from "notistack";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {CreateMissingBoxFoldersConfirmationDialog, FixBoxFolderNamesConfirmationDialog} from "../../../components/Box";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {getAxiosAPIResponseMessage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import getQueryStringFromIds from "../../../utils/getQueryStringFromIds";
import {CORBulkUpdateForm, CORDialogForm} from "./CORForms";
import {CORPaperItem} from "./CORPaperItem";

const ProjectCORs = (props) => {
  const {project, userContact, ...rest} = props;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [activeCOR, setActiveCOR] = React.useState({} as any);
  const [showBulkUpdateDialog, setShowBulkUpdateDialog] = React.useState(false);
  const [fixBoxFolderNamesDialog, setFixBoxFolderNamesDialog] = React.useState(false);
  const [createMissingBoxFolders, setCreateMissingBoxFolders] = React.useState(false);
  const [renumberConfirmationIsOpen, setRenumberConfirmationIsOpen] = React.useState(false);
  const [initialFocusField, setInitialFocusField] = React.useState(null);

  const blockUI = useBlockUI();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Status",
    "Search",
    "Tag",
    "Has OCO",
  ]);

  const filterParams = {
    status: filterOptions.Status.value,
    q: filterOptions.Search.value,
    tag: filterOptions.Tag.value,
    has_pcco: filterOptions["Has OCO"].value,
  };

  const novoClasses = makeNovoClasses();
  // const userContact = useUser();
  const {enqueueSnackbar} = useSnackbar();

  const {
    query: corQuery,
    create: createCOR,
    update: updateCOR,
    delete: deleteCOR,
    rpc: corRPC,
    onDragEnd: corOnDragEnd,
  } = useSentinelListAPI(
    `projects/${
      project.id
    }/cors/?parent__isnull=True&is_internal=False&page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const cors = corQuery.data.results;

  const {query: statusQuery} = useSentinelListAPI(`projects/${project.id}/cors/status`, {
    initialData: [],
  });

  const statusOptions = statusQuery.data;

  const {query: tagOptionsQuery} = useSentinelListAPI(`projects/${project.id}/cors/tags/`, {
    initialData: [],
  });

  const tagOptions = tagOptionsQuery.data;

  const allCORIds = cors.map((cor) => cor.id);

  const {
    selectedIds,
    addSelectedId,
    addSelectedIds,
    removeSelectedId,
    removeSelectedIds,
    addAllSelectedIds,
    removeAllSelectedIds,
    allIdsSelected,
  } = useSelectIds(allCORIds);

  // const updateCORMemo = React.useCallback(updateCOR.mutateAsync, []);

  // const corIDQueryString = () => (selectedIds.size ? `&pk=${Array.from(selectedIds).join("&pk=")}` : "");

  const pdfQueryString = React.useMemo(
    () => getQueryStringFromIds(selectedIds, filterParams),
    [selectedIds, filterParams]
  );

  return (
    <>
      <Helmet title={`${project.display} - Change Order Requests`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Change Order Requests</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={corQuery.isLoading || corQuery.isFetching}>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              label={"Select All"}
              onChange={(event, value) => {
                if (value) {
                  addSelectedIds(allCORIds);
                } else {
                  removeAllSelectedIds();
                }
              }}
              indeterminate={!allIdsSelected && selectedIds.size < allCORIds.length && selectedIds.size}
              checked={allIdsSelected}
            />{" "}
            <FontAwesomeIcon icon={faPaperclip} /> CORS
          </PaperPanel.Header.Title>

          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setShowCreateDialog(true);
                }}
                disabled={project.is_closed}
              >
                Create COR
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/cors/by-status/?${pdfQueryString}`}
                >
                  By Status
                </PaperPanel.Header.Menu.PDFMenuItem>

                <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/projects/${project.id}/cors/?${pdfQueryString}`}>
                  By Number
                </PaperPanel.Header.Menu.PDFMenuItem>
              </PaperPanel.Header.PDFButtons>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.BuildReportButton href={`/reports2/projects/${project.id}/cors/by-status/filter/`}>
                Build Reports
              </PaperPanel.Header.BuildReportButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              {/* <PaperPanel.Header.Button
                disabled={!selectedIds.size}
                onClick={() => {
                  setShowBulkUpdateDialog(true);
                }}
              >
                <FontAwesomeIcon icon={faListCheck} style={{marginRight: "4px"}} /> Edit Selected
              </PaperPanel.Header.Button> */}
              <PaperPanel.Header.EditButton
                disabled={selectedIds.size === 0}
                onClick={() => setShowBulkUpdateDialog(true)}
              >
                Edit Selected
              </PaperPanel.Header.EditButton>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border>
              <PaperPanel.Header.ReorderButton
                onReorder={(orderBy) => {
                  blockUI.blockUI("Reordering...");

                  axiosAPI
                    .post(`/projects/${project.id}/actions/reposition_cors/?order_by=${orderBy}`, {}, {baseURL: ""})
                    .then(() => {
                      corQuery.refetch().then(() => blockUI.unblockUI());
                    })
                    .catch((error) => {
                      enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
                      blockUI.unblockUI();
                    });
                }}
                onRenumber={() => {
                  setRenumberConfirmationIsOpen(true);
                }}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border pr={0}>
              <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setCreateMissingBoxFolders(true);
                      }}
                    >
                      Create Missing Box Folders
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setFixBoxFolderNamesDialog(true);
                      }}
                    >
                      Fix Box Folder Names
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.Menu>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action pl={0}>
              <PaperPanel.Header.RefreshButton isFetching={corQuery.isFetching} onClick={() => corQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                label="Status"
                name="status"
                options={statusOptions}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                label="Tag"
                name="tag"
                options={tagOptions}
                value={filterOptions.Tag.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Tag", value, label);
                }}
              />
            </Grid>
            <Grid item sm={3} xs={12}>
              <FilterSelect
                // native
                label="Has OCO"
                name="has_oco"
                options={[
                  {label: "True", value: "True"},
                  {label: "False", value: "False"},
                ]}
                value={filterOptions["Has OCO"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Has OCO", value, label);
                }}
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <FilterOptionChips
                  filterOptions={filterOptions}
                  onDelete={(key) => {
                    clearFilterOption(key);
                  }}
                  onDeleteAll={() => {
                    clearAllFilterOptions();
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Pagination count={corQuery.data.total_pages} page={page} setPage={setPage} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>

        <PaperPanel.Body>
          <VirtualizedList itemList={cors} isVirtualized={cors.length > 100} onDragEnd={corOnDragEnd}>
            {(props) => {
              const {listItem: cor, ...rest} = props;
              return (
                <CORPaperItem
                  key={cor.id}
                  cor={cor}
                  corIsSelected={selectedIds.has(cor.id)}
                  onEditCor={() => {
                    setActiveCOR(cor);
                    setShowUpdateDialog(true);
                  }}
                  onChangeCorSelected={(event, value) => {
                    if (value) {
                      addSelectedId(cor.id, event.nativeEvent.shiftKey);
                    } else {
                      removeSelectedId(cor.id);
                    }
                  }}
                  highlight={activeCOR.id === cor.id && showUpdateDialog}
                  {...rest}
                />
              );
            }}
          </VirtualizedList>
        </PaperPanel.Body>
        {(corQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={corQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/cors-legacy/`} mt={2} />

      {/* Quick Create */}
      <CORDialogForm
        projectId={project.id}
        isOpen={showCreateDialog}
        restrictNovoSigners={project.restrict_novo_signers}
        isNew
        onSubmit={(values, form) => {
          // Check values for some value submitMode

          const submitMode = values.submitMode;
          delete values.submitMode;

          if (submitMode === "saveAndClose") {
            blockUI.blockUI("Creating...");
            createCOR
              .mutateAsync(values)
              .then(() => {
                corQuery.refetch();
                setShowCreateDialog(false);
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }
          if (submitMode === "editAfterSubmit") {
            blockUI.blockUI("Creating...");
            createCOR
              .mutateAsync(values)
              .then((newCOR) => {
                window.location.href = newCOR.url;
                setShowCreateDialog(false);
              })
              .catch(() => blockUI.unblockUI());
          }
          if (submitMode === "addAnother") {
            blockUI.blockUI("Creating...");
            setInitialFocusField(null);
            // const focusElement = createFormRef.current[10];
            // const focusElement = createFormRef.current.elements.description;
            createCOR
              .mutateAsync(values)
              .then(() => {
                // focusElement.focus();
                form.restart();
                corQuery.refetch().then(() => {
                  if (submitMode === "addAnother") {
                    setInitialFocusField("description");
                  }
                  blockUI.unblockUI();
                });
              })
              .catch(() => blockUI.unblockUI());
          }
        }}
        handleClose={() => {
          setShowCreateDialog(false);
          setActiveCOR({});
        }}
        setActiveCor={setActiveCOR}
        initialValues={{
          number: corQuery?.data?.metadata?.next_number,
          initiated_date: moment().format("YYYY-MM-DD"),
          status: "pending",
        }}
        initialFocusField={initialFocusField}
      />
      {/* Quick Edit */}
      <CORDialogForm
        projectId={project.id}
        isOpen={showUpdateDialog}
        isReadOnly={activeCOR.is_closed}
        restrictNovoSigners={project.restrict_novo_signers}
        initialValues={activeCOR}
        onSubmit={(values, form) => {
          blockUI.blockUI("Updating...");
          updateCOR
            .mutateAsync(values)
            .then(() => corQuery.refetch())
            .then(() => {
              setShowUpdateDialog(false);
              setActiveCOR({});
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        handleClose={() => {
          setShowUpdateDialog(false);
          setActiveCOR({});
          setInitialFocusField(null);
        }}
        setActiveCOR={setActiveCOR}
        activeCOR={activeCOR}
        cors={cors}
        beforePrevNext={(values) => {
          blockUI.blockUI("Updating...");
          return updateCOR.mutateAsync(values).then(() => {
            blockUI.unblockUI();
          });
        }}
        initialFocusField={initialFocusField}
        setInitialFocusField={setInitialFocusField}
      />
      {/* Bulk Edit */}
      <CORBulkUpdateForm
        isOpen={showBulkUpdateDialog}
        corCount={selectedIds.size}
        projectId={project.id}
        restrictNovoSigners={project.restrict_novo_signers}
        onSubmit={(values) => {
          setShowBulkUpdateDialog(false);
          blockUI.blockUI("Updating...");

          const date_fields = ["initiated_date", "required_date", "completed_date", "executed_date"];

          date_fields.forEach((date) => {
            if (values[date]) {
              values[date] = moment(values[date]).format("YYYY-MM-DD");
            }
          });

          if (values.to_contact) {
            values.to_contact_id = values.to_contact.id;
          }

          if (values.from_contact) {
            values.from_contact_id = values.from_contact.id;
          }

          corRPC
            .mutateAsync({
              action: "update",
              data: {
                ids: Array.from(selectedIds),
                update: values,
              },
              method: "PUT",
            })
            .then(() => corQuery.refetch().then(() => blockUI.unblockUI()))
            .catch(() => blockUI.unblockUI());
        }}
        handleClose={() => setShowBulkUpdateDialog(false)}
      />

      <CreateMissingBoxFoldersConfirmationDialog
        isOpen={createMissingBoxFolders}
        setIsOpen={setCreateMissingBoxFolders}
        rpc={corRPC}
      />
      <FixBoxFolderNamesConfirmationDialog
        isOpen={fixBoxFolderNamesDialog}
        setIsOpen={setFixBoxFolderNamesDialog}
        rpc={corRPC}
      />

      <ConfirmationDialog
        isOpen={renumberConfirmationIsOpen}
        onApprove={() => {
          setRenumberConfirmationIsOpen(false);
          blockUI.blockUI("Renumbering...");
          axiosAPI
            // .post(`/cors/${cors[0].id}/actions/renumber_on_position/`, {}, {baseURL: ""})
            .post(`projects/${project.id}/cors/renumber-on-position/`, {}, {baseURL: "/api/v1/"})
            .then(() => {
              corQuery.refetch().then(() => blockUI.unblockUI());
            })
            .catch((error) => {
              enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
              blockUI.unblockUI();
            });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setRenumberConfirmationIsOpen(false);
        }}
      >
        Are you sure that you want to renumber all CORs based on their current order? This cannot be undone.
      </ConfirmationDialog>

      {/* <Typography variant="h2">COR Query</Typography>
      <pre>{JSON.stringify(corQuery, null, 2)}</pre> */}
      {/* <h1>Current Page Query</h1>
      <pre>{JSON.stringify(currentPageQuery, null, 2)}</pre> */}
      {/* <Typography variant="h2">PROPS</Typography>
      <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default ProjectCORs;
