import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {blue, grey, indigo, purple} from "@material-ui/core/colors";
import {alpha} from "@material-ui/core/styles/colorManipulator";
import styled from "styled-components";
import {VictoryLabel, VictoryPie} from "victory";
import {Currency, getPercentage} from "../../../components/Accounting";
import {DateFormat} from "../../../components/Date";

const ColoredTag = styled("span")`
   {
    color: ${(props) => props.color};
    font-weight: ${(props) => props.theme.typography.fontWeightBold};
    background: ${(props) => alpha(props.color, 0.1)};
    padding: 2px;
    border-radius: 3px;
  }
`;

const CircularProgress = (props: {percent: number; color: string}) => {
  // VictoryPie does not link numbers with many decimal places (limit to 5 decimal places)
  const percent = parseFloat(props.percent.toFixed(5));
  const data = [
    {x: 1, y: percent},
    {x: 2, y: 1 - percent},
  ];

  const size = 50;
  return (
    <svg viewBox={`0 0 ${size} ${size}`} height={size} width={size}>
      <VictoryPie
        standalone={false}
        // animate={{duration: 1000}}
        width={size}
        height={size}
        padding={5}
        data={data}
        innerRadius={15}
        // cornerRadius={25}
        labels={() => null}
        style={{
          data: {
            fill: ({datum}) => {
              return datum.x === 1 ? props.color : grey[200];
            },
          },
        }}
      />
      <VictoryLabel
        textAnchor="middle"
        verticalAnchor="middle"
        x={size * 0.5}
        y={size * 0.5}
        text={`${getPercentage(percent)}%`}
        style={{fontSize: 12}}
      />
    </svg>
  );
};

const Finances = (props) => {
  const {project, finances, precision = 2} = props;
  return (
    <Table size="small" padding="none">
      <TableBody>
        <TableRow>
          <TableCell colSpan={2}>
            <strong>Budget</strong>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Original Budget</TableCell>
          <TableCell align="right">
            <Currency number={finances.originalBudget} precision={precision} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Approved Changes</TableCell>
          <TableCell align="right">
            <Currency number={finances.approvedBudgetRevs} precision={precision} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Current Budget</TableCell>
          <TableCell align="right">
            <Currency number={finances.currentBudget} precision={precision} />
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Pending Changes</TableCell>
          <TableCell align="right">
            <Currency number={finances.pendingBudgetRevs} precision={precision} />
          </TableCell>
        </TableRow>
        {project.timberline_job && (
          <>
            <TableRow>
              <TableCell colSpan={2}>
                <strong>Cost</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Current Cost</TableCell>
              <TableCell align="right">
                <Currency number={finances.jtdCost} precision={precision} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Cost Update</TableCell>
              <TableCell align="right">
                <DateFormat date={finances.lastCostUpdate} format="ddd, MMM D, YYYY" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Cost/Budget</TableCell>
              <TableCell align="right" style={{lineHeight: 0}}>
                <CircularProgress percent={finances.jtdCost / finances.currentBudget} color={blue[500]} />
                {/*
                <ColoredTag color={blue[500]}>
                  <Percentage number={finances.jtdCost / finances.currentBudget} />
                </ColoredTag>
                 */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell colSpan={2}>
                <strong>Billing</strong>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Billed</TableCell>
              <TableCell align="right">
                <Currency number={finances.jtdWorkBilled} precision={precision} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Last Billed</TableCell>
              <TableCell align="right">
                <DateFormat date={finances.lastBilledDate} format="ddd, MMM D, YYYY" />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Paid</TableCell>
              <TableCell align="right">
                <Currency number={finances.jtdPayments} precision={precision} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Receivable</TableCell>
              <TableCell align="right">
                <Currency number={finances.receivableBalance} precision={precision} />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Billed/Budget</TableCell>
              <TableCell align="right" style={{lineHeight: 0}}>
                <CircularProgress percent={finances.jtdWorkBilled / finances.currentBudget} color={purple[500]} />
                {/*
                <ColoredTag color={purple[500]}>
                  <Percentage number={finances.jtdWorkBilled / finances.currentBudget} />
                </ColoredTag>
                 */}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Owed/Billed</TableCell>
              <TableCell align="right" style={{lineHeight: 0}}>
                <CircularProgress percent={finances.receivableBalance / finances.jtdWorkBilled} color={indigo[500]} />
                {/*
                <ColoredTag color={indigo[500]}>
                  <Percentage number={finances.receivableBalance / finances.jtdWorkBilled} />
                </ColoredTag>
                 */}
              </TableCell>
            </TableRow>
          </>
        )}
      </TableBody>
    </Table>
  );
};

export default Finances;
