import {faInfoCircle, faSortAmountDown} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import NumericFormat from "react-number-format";
import {Currency} from "../../../components/Accounting";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";

export const CommitmentPaperItem = (props) => {
  const {budgetItem, onChangeSelected, isSelected, onEdit, onDelete, isLocked, onUpdateBudgetMarkup, ...rest} = props;
  const isExpectedMarkup = Math.abs(budgetItem.original_budget - budgetItem.expected_markup) < 1;
  return (
    <PaperItem {...rest}>
      {!isLocked && (
        <PaperItem.Left>
          <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangeSelected} />
        </PaperItem.Left>
      )}
      <PaperItem.Body>
        <Grid container>
          <Grid item xs={12} sm={5}>
            {budgetItem.display}
          </Grid>
          <Grid item xs={10} sm={2}>
            {budgetItem.allocation_display}
          </Grid>
          <Grid item xs={2} sm={1}>
            {budgetItem.is_excluded_from_markup && (
              <Tooltip title="Excluded From Markup">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={6} sm={2}>
            <Tooltip title="Original Budget" placement="bottom-end">
              <Box textAlign="right">
                <Currency number={budgetItem.original_budget} precision={2} />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Tooltip title="Current Budget" placement="bottom-end">
              <Box textAlign="right">
                <Currency number={budgetItem.current_budget} precision={2} />
              </Box>
            </Tooltip>
          </Grid>
          <Grid item xs={6} sm={3}>
            {budgetItem.is_markup && (
              <Typography2 type="metadata">
                <NumericFormat value={budgetItem?.markup_rate} displayType="text" suffix="%" decimalScale={3} /> Markup
              </Typography2>
            )}
          </Grid>
          <Grid item xs={6} sm={3}>
            {budgetItem.is_markup && (
              <Typography2 type="metadata">
                {budgetItem.is_compound_markup && (
                  <Tooltip title="Compound Markup">
                    <FontAwesomeIcon icon={faSortAmountDown} />
                  </Tooltip>
                )}
                <Currency number={budgetItem.total_for_markup} />
                {" * "}
                <NumericFormat value={budgetItem.markup_rate} displayType={"text"} suffix={"%"} decimalScale={3} />
                {" = "}
                {!isExpectedMarkup && !isLocked ? (
                  <Currency
                    number={budgetItem.expected_markup}
                    precision={2}
                    color="red"
                    style={{cursor: "pointer"}}
                    onClick={onUpdateBudgetMarkup}
                  />
                ) : (
                  <Currency number={budgetItem.expected_markup} precision={2} color={!isExpectedMarkup && "red"} />
                )}
              </Typography2>
            )}
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={59}></PaperItem.Right>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEdit} />
        <PaperItem.RightHover.IconButton
          icon={DeleteIcon}
          title={isLocked ? `Project is Locked` : `Delete`}
          onClick={onDelete}
          disabled={isLocked}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};
