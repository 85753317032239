import {Box, Grid, Typography} from "@material-ui/core";
import constructionTypes from "../../../../components/forms/choices/constructionTypes.json";
import contractTypes from "../../../../components/forms/choices/contractTypes.json";
import csiCodeTypes from "../../../../components/forms/choices/csiCodeTypes.json";
import gmpTypes from "../../../../components/forms/choices/GMPTypes.json";
import offices from "../../../../components/forms/choices/offices.json";
import projectDelivery from "../../../../components/forms/choices/projectDelivery.json";
import timezones from "../../../../components/forms/choices/timezones.json";
import states from "../../../../components/forms/choices/usStates.json";
import {
  CheckboxMui,
  DatePickerMui,
  GoogleMapsAddressAutocompleteMui,
  MaskedInputMui,
  NumberFieldMui,
  SimpleSelect,
  TextFieldMui,
} from "../../../../components/forms/Fields";

const GeneralInfoFields = (props) => {
  const {namespace, locationLookup, form, useWizard} = props;

  return (
    <>
      <Box mb={2}>
        <Typography variant="h4">General Info</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <TextFieldMui name={`${namespace}.name`} label="Project Name" required autoFocus />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect
            name={`${namespace}.csi_code_type`}
            label="CSI Code Set"
            options={csiCodeTypes}
            disabled
            required
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect name={`${namespace}.type_of_contract`} label="Contract Type" required options={contractTypes} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect name={`${namespace}.job_start_gmp`} label="GMP Type" options={gmpTypes} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect
            name={`${namespace}.office`}
            label="Office"
            required
            options={offices}
            inputProps={{
              onChange: (event) => {
                const code = event.target.value;
                form.change("insurance.certificate_holder_address", locationLookup[code]);
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect name={`${namespace}.timezone`} label="Timezone" required options={timezones} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect name={`${namespace}.project_delivery`} label="Project Delivery" options={projectDelivery} />
        </Grid>
        <Grid item xs={12}>
          <TextFieldMui name={`${namespace}.description`} label="Description" minRows={3} multiline />
        </Grid>
        <Grid item xs={12}>
          <CheckboxMui name={`${namespace}.is_externally_visible`} label={"Show In Sentinel Portal"} />
          <CheckboxMui name={`${namespace}.can_view_external_reports`} label={"Download PDFs in Portal"} />
        </Grid>
      </Grid>

      <Box my={2}>
        <Typography variant="h4">Address/Phone</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <GoogleMapsAddressAutocompleteMui
            name={`${namespace}.address1`}
            label="Address 1"
            onPlaceSelected={(place) => {
              form.change(`${namespace}.address1`, place.address1);
              form.change(`${namespace}.address2`, place.address2);
              form.change(`${namespace}.city`, place.locality_long_name);
              form.change(`${namespace}.state`, place.administrative_area_level_1_short_name);
              form.change(`${namespace}.postal_code`, place.postal_code);
            }}
          />

          {/* <TextFieldMui name={`${namespace}.address1`} label="Address 1" /> */}
        </Grid>
        <Grid item xs={12}>
          <TextFieldMui name={`${namespace}.address2`} label="Address 2" />
        </Grid>
        <Grid item xs={12}>
          <TextFieldMui name={`${namespace}.address3`} label="Address 3" />
        </Grid>

        <Grid item xs={6} sm={4}>
          <TextFieldMui name={`${namespace}.city`} label="City" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <SimpleSelect name={`${namespace}.state`} label="State" options={states} />
        </Grid>
        <Grid item xs={6} sm={4}>
          <TextFieldMui name={`${namespace}.postal_code`} label="Postal Code" />
        </Grid>
        <Grid item xs={6} sm={4}>
          <MaskedInputMui name={`${namespace}.job_phone`} label="Job Phone" mask="(999)-999-9999" />
        </Grid>
      </Grid>

      <Box my={2}>
        <Typography variant="h4">Construction Details</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <DatePickerMui name={`${namespace}.start_date`} label="Start Date" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerMui name={`${namespace}.finish_date`} label="Finish Date" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <DatePickerMui name={`${namespace}.warranty_date`} label="Warranty Date" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <NumberFieldMui name={`${namespace}.square_feet`} label="Square Footage" required />
        </Grid>
        <Grid item xs={12} sm={4}>
          <SimpleSelect
            name={`${namespace}.type_of_construction`}
            label="Construction Type"
            options={constructionTypes}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <TextFieldMui name={`${namespace}.utilities`} label="Utilities" />
        </Grid>
      </Grid>
    </>
  );
};

export default GeneralInfoFields;
