import {
  faCircle1,
  faCircle2,
  faCircle3,
  faCircle4,
  faUpload,
  faUpRightFromSquare,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link, Tooltip} from "@material-ui/core";
import pluralize from "pluralize";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {useLocation} from "react-router-dom";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {FinalCheckbox} from "../../../../js/components/ReactFinalForm/Fields";
import {axiosAPI} from "../../../api";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import {
  CompanyAutocomplete,
  CSICodeAutoselectMui,
  DatePickerMui,
  FileFieldMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  ProjectSubmittalPackagesAutoselectMui,
  SpecSectionAutocompleteMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import {beforeDialogClose} from "../../../components/forms/Form";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";
import {PrevNext} from "../../../components/PrevNext";
import {StatusLabel} from "../../../components/Status";
import {SuperuserInfo} from "../../../components/SuperuserInfo";
import Typography2 from "../../../components/Typography2";
import useFormFocusFieldRef from "../../../hooks/useFormFocusFieldRef";
import SubmittalItemScheduleStepper from "./SubmittalItemScheduleStepper";

const approvalStatus = ["approved", "approved_as_noted", "in_review"];

const SubmittalItemCommonFields = (props: {
  projectId: number;
  values: any;
  isNew?: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, values, isNew, isReadOnly, ...rest} = props;

  const validateNumber = async (value, values, field) => {
    if (!values.number) return "Required";
    if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
    const response = await axiosAPI.get(
      `/projects/${projectId}/submittals/items/validate-number/?number=${values?.number}${
        !isNew ? `&id=${values.id}` : ``
      }`
    );

    if (response.data.error) {
      return response.data.error;
    }
  };

  const validateNumberInPackage = async (value, values, field) => {
    if (!values?.item_number || !values?.submittal_package?.id) return;
    if (!field.active) return; // Hack: Don't fire validation when typing in TinyMCE
    const response = await axiosAPI.get(
      `/projects/${projectId}/submittals/items/validate-number-in-package/?number=${values?.item_number}&package_id=${
        values?.submittal_package?.id
      }${!isNew ? `&id=${values.id}` : ``}`
    );

    if (response.data.error) {
      return response.data.error;
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item sm={2}>
          <TextFieldMui
            label="Number"
            name="number"
            helperText="# In Project"
            disabled={isReadOnly}
            fieldProps={{validate: validateNumber}}
          />
        </Grid>
        <Grid item sm={2}>
          <TextFieldMui
            label="Item Number"
            name="item_number"
            helperText="# In Package"
            disabled={!values.submittal_package || isReadOnly}
            fieldProps={{validate: validateNumberInPackage}}
          />
        </Grid>
        <Grid item sm={2}>
          <TextFieldMui label="Revision" name="revision" disabled />
        </Grid>
        <Grid item sm={2}>
          <TextFieldMui label="Quantity" name="quantity" required disabled={isReadOnly} />
        </Grid>
        <Grid item sm={4} xs={12}>
          <Box display={"flex"}>
            <Box flexGrow={1} mr={0.5}>
              <ProjectSubmittalPackagesAutoselectMui
                disabled={approvalStatus.includes(values.status) || isReadOnly}
                projectId={projectId}
                name="submittal_package"
                label="Submittal Package"
                flexGrow={1}
              />
            </Box>

            {values?.submittal_package?.url && (
              <Tooltip title="Go to Submittal Package">
                <Link href={values.submittal_package?.url} target="_blank">
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </Link>
                {/* <MuiNavLink to={`/v2${values.submittal_package?.url}`}>
                  <FontAwesomeIcon icon={faUpRightFromSquare} />
                </MuiNavLink> */}
              </Tooltip>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <TextFieldMui label="Description" name="description" required autoFocus disabled={isReadOnly} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <ProjectCompanyAutoselectMui
            projectId={projectId}
            name="responsible_company"
            label="Responsible Company"
            helperText="Usually the Subcontractor"
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item xs={12} md={4} lg={4}>
          <CompanyAutocomplete type="text" name="supplier" label="Supplier" disabled={isReadOnly} />
        </Grid>
        <Grid item md={4} lg={4} xs={12}>
          <SpecSectionAutocompleteMui
            label="Spec Section"
            name="spec_section_code"
            projectId={projectId}
            allowDivisions={false}
            disabled={isReadOnly}
            helperText=" "
          />
        </Grid>
        <Grid item md={4} lg={4} xs={12}>
          <TextFieldMui
            type="text"
            name="spec_subsection"
            label="Spec Subsection"
            disabled={isReadOnly}
            inputProps={{maxLength: 16}}
          />
        </Grid>
      </Grid>
      {!isNew && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box mt={-2} mb={2} pt={1}>
              <SubmittalItemScheduleStepper submittalItem={values} />
            </Box>
          </Grid>
        </Grid>
      )}
      <Grid container spacing={2}>
        <Grid item lg={2} sm={3} xs={12}>
          <DatePickerMui
            label="Requested "
            name="requested_date"
            // helperText="GC sent request to sub"
            helperText={
              <>
                <FontAwesomeIcon icon={faCircle1} /> GC sent request to sub
              </>
            }
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item lg={2} sm={3} xs={12}>
          <DatePickerMui label="Due " name="due_date" helperText="Due from Sub back to GC" disabled={isReadOnly} />
        </Grid>
        <Grid item lg={2} sm={3} xs={12}>
          <DatePickerMui
            label="Received "
            name="received_date"
            // helperText="Received by GC from Sub"
            helperText={
              <>
                <FontAwesomeIcon icon={faCircle2} /> Received by GC from Sub
              </>
            }
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item lg={6} sm={3} xs={12}>
          <ProjectContactAutoselectMui
            label="Received By"
            name="received_by"
            projectId={projectId}
            disabled={isReadOnly}
          />
        </Grid>

        <Grid item sm={4} lg={2} xs={12}>
          <DatePickerMui
            label="Returned "
            name="returned_date"
            // helperText="Returned to Sub by GC"
            helperText={
              <>
                <FontAwesomeIcon icon={faCircle3} /> Returned to Sub by GC
              </>
            }
            disabled={isReadOnly}
          />
        </Grid>
        <Grid item sm={4} lg={2}>
          <TextFieldMui label="Lead Time (Weeks)" name="lead_time" disabled={isReadOnly} />
        </Grid>
        <Grid item sm={4} lg={2} xs={12}>
          <DatePickerMui
            label="Expected Delivery"
            name="target_delivery_date"
            disabled
            helperText="Returned date (GC to Sub) + Lead time"
          />
        </Grid>
        <Grid item sm={4} lg={2} xs={12}>
          <DatePickerMui
            label="Required On Site"
            name="needed_onsite_date"
            disabled={isReadOnly}
            helperText="Required on Site Per Master Schedule"
          />
        </Grid>
        <Grid item sm={4} lg={2} xs={12}>
          <DatePickerMui
            label="Scheduled "
            name="scheduled_delivery_date"
            disabled={isReadOnly}
            helperText="Date Delivery Is Scheduled"
          />
        </Grid>

        <Grid item sm={4} lg={2} xs={12}>
          <DatePickerMui
            label="Delivered Date"
            name="delivered_date"
            // helperText="Actual Delivery/Install Date"
            helperText={
              <>
                <FontAwesomeIcon icon={faCircle4} /> Actual Delivery/Install Date
              </>
            }
            disabled={isReadOnly}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TinyMCEMui name="notes" label="Notes" disabled={isReadOnly} />
        </Grid>
        <Grid item md={6} xs={12}>
          <TinyMCEMui name="internal_notes" label="Internal Notes" disabled={isReadOnly} />
        </Grid>
      </Grid>
    </>
  );
};

export const SubmittalItemUpdateForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  isNew?: boolean;
  isReadOnly?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {pathname} = useLocation();
  // useWhyDidYouUpdate("RFIUpdateDialogForm", props);
  const {onSubmit, projectId, isNew, isReadOnly, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values} = props;
        return (
          <>
            <BeforeUnload block={!pristine} />
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <SubmittalItemCommonFields
                  values={values}
                  isReadOnly={isReadOnly}
                  isNew={isNew}
                  projectId={projectId}
                />
                <InnerFooter>
                  <Box display="flex">
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    <Box ml={1} />
                    <FormActions.DeleteButton
                      href={`/v2/projects/${projectId}/delete/submittals/submittalitem/${values.id}/?next=/v2/projects/${projectId}/submittals/items/&cancel=${pathname}`}
                    />
                  </Box>
                </InnerFooter>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

export const SubmittalItemUpdateDialogForm = React.forwardRef(
  (
    props: {
      projectId: number;
      onSubmit: (values: any) => void;
      handleClose: () => void;
      isOpen: boolean;
      isNew?: boolean;
      isReadOnly?: boolean;
      // previous: any;
      // next: any;
      [rest: string]: any;
    },
    ref
  ) => {
    const {
      projectId,
      onSubmit,
      isNew,
      isOpen,
      handleClose,
      isReadOnly,
      setActiveSubmittalItem,
      submittalItems,
      activeSubmittalItem,
      beforePrevNext,
      afterPrevNext,
      initialFocusField,
      setInitialFocusField,
      ...rest
    } = props;
    const formPropsRef = React.useRef(null);

    const formRef = useFormFocusFieldRef(initialFocusField, [props.initialValues.id, props.initialFocusField]);

    const onFocusChange = (props) => {
      const {active} = props;
      if (setInitialFocusField && active) setInitialFocusField(active);
    };

    return (
      <>
        <Dialog
          open={isOpen}
          onClose={(event, reason) => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
          maxWidth="lg"
          fullWidth
          scroll="paper"
        >
          <FinalForm onSubmit={onSubmit} {...rest}>
            {(props) => {
              const {handleSubmit, form, submitting, pristine = true, values, hasValidationErrors} = props;
              formPropsRef.current = props;
              return (
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={formRef}>
                  <ActiveFieldsFormSpy onChange={onFocusChange} />
                  <DialogTitle id="quick-edit-form">
                    <Grid container justifyContent="space-between">
                      <Grid item>
                        {!isNew && <StatusLabel status={values.status_display} />}{" "}
                        {isNew ? "Create Submittal Item" : "Update Submittal Item"}
                      </Grid>
                      {!isNew && (
                        <Grid item>
                          <PrevNext
                            setActiveItem={setActiveSubmittalItem}
                            items={submittalItems}
                            activeItem={activeSubmittalItem}
                            beforePrevNext={!pristine && beforePrevNext ? () => beforePrevNext(values) : undefined}
                            afterPrevNext={!pristine && afterPrevNext ? () => afterPrevNext() : undefined}
                            disabled={hasValidationErrors && !pristine}
                          />
                        </Grid>
                      )}
                    </Grid>
                  </DialogTitle>
                  <DialogContent>
                    <BeforeUnload block={!pristine} />
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <SubmittalItemCommonFields
                        values={values}
                        isReadOnly={isReadOnly}
                        isNew={isNew}
                        projectId={projectId}
                      />
                    </FinalFormKeyboardShortcuts>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    {!isNew && (
                      <SuperuserInfo
                        objects={[
                          {
                            contentType: "submittals",
                            model: "submittalitem",
                            id: values.id,
                            title: values.display,
                          },
                        ]}
                      />
                    )}
                  </DialogContent>
                  <DialogActions>
                    {/* {!pristine && <Chip color="secondary" label="Unsaved Changes!" />} */}
                    <FormActions.SaveButton
                      label="Save"
                      disabled={pristine || isReadOnly}
                      onClick={() => form.change("submitMode", "saveAndClose")}
                    />
                    {isNew && (
                      <FormActions.SaveAndAddButton
                        disabled={pristine || isReadOnly}
                        onClick={() => form.change("submitMode", "addAnother")}
                      />
                    )}
                    <FormActions.CancelButton
                      onClick={() => beforeDialogClose(handleClose, !formPropsRef.current.pristine)}
                    />
                  </DialogActions>
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const SubmittalItemBulkUpdateDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  onDelete: () => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, onDelete, isOpen, handleClose, isReadOnly = false, itemCount, ...rest} = props;

  return (
    <>
      <Box display="flex" justifyContent={"center"} mx={"auto"}>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine = true, values}) => (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="bulk-update-form">
                  Bulk Update {itemCount} {pluralize("Submittal Item", itemCount)}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <CSICodeAutoselectMui
                          projectId={projectId}
                          label="CSI Code"
                          name="csi_code"
                          disabled={isReadOnly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SpecSectionAutocompleteMui
                          label="Spec Section"
                          name="spec_section_code"
                          projectId={projectId}
                          allowDivisions={false}
                          disabled={isReadOnly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProjectCompanyAutoselectMui
                          projectId={projectId}
                          name="responsible_company"
                          label="Responsible Company"
                          disabled={isReadOnly}
                          // helperText="Usually the Subcontractor"
                        />
                        <FinalCheckbox name="clear_responsible" label="Clear Responsible Company" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ProjectSubmittalPackagesAutoselectMui
                          label="Submittal Package"
                          name="submittal_package"
                          disabled={isReadOnly}
                          projectId={projectId}
                        />
                        <FinalCheckbox name="clear_submittal_package" label="Clear Submittal Package" />
                      </Grid>
                    </Grid>
                    <Box textAlign={"center"} my={3}>
                      <Typography2 type="header">Submittal Schedule</Typography2>
                      <hr />
                    </Box>

                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui label="Start Date" name="start_date" disabled={isReadOnly} />
                      </Grid>
                      <Grid item sm={6}></Grid>
                      <Grid item xs={12} sm={6}>
                        <TextFieldMui label="Sub Bidding (Weeks)" name="sub_bidding_period" disabled={isReadOnly} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui label="Actual Sub Release Date" name="requested_date" disabled={isReadOnly} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextFieldMui
                          label="Sub Compiles Submittal (Weeks)"
                          name="sub_compiles_submittal_period"
                          disabled={isReadOnly}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui label="Actual Sub Issues Submittal" name="received_date" disabled={isReadOnly} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextFieldMui label="Submittal Review (Weeks)" name="review_period" disabled={isReadOnly} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui label="Actual Release Date" name="returned_date" disabled={isReadOnly} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextFieldMui label="Lead Time (Weeks)" name="lead_time" disabled={isReadOnly} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui label="Actual Delivered Date" name="delivered_date" disabled={isReadOnly} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui
                          label="Needed On Site / Installation Date"
                          name="needed_onsite_date"
                          disabled={isReadOnly}
                        />
                      </Grid>
                      <Grid container item xs={12} sm={6}>
                        <Grid item xs={12}>
                          <FinalCheckbox label="Include In Report" name="include_in_pl" disabled={isReadOnly} />
                        </Grid>
                        <Grid item xs={12}>
                          <FinalCheckbox label="Exclude From Report" name="exclude_from_pl" disabled={isReadOnly} />
                        </Grid>
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.DeleteButton onClick={onDelete} disabled={isReadOnly} />
                  <FormActions.SaveButton disabled={submitting || pristine || isReadOnly} label={"Bulk Update"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            )}
          </FinalForm>
        </Dialog>
      </Box>
    </>
  );
};

export const SubmittalItemUploadDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, onSubmit, isOpen, isReadOnly, handleClose, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine = true, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                {" "}
                <FontAwesomeIcon icon={faUpload} fixedWidth /> Upload Submittal Items
              </DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  Please, use{" "}
                  <Link underline={"always"} href="/static/files/templates/submittal-items.xlsx">
                    this template file
                  </Link>{" "}
                  to avoid validation errors.
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FileFieldMui name="submittal_upload" />
                <FormActions.SaveButton label="Upload" disabled={pristine || isReadOnly} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

// Old bulk form
{
  /* <Grid item md={6} xs={12}>
<ProjectSubmittalPackagesAutoselectMui
  projectId={projectId}
  name="submittal_package"
  label="Submittal Package"
/>
<FinalCheckbox name="clear_submittal_package" label="Clear Submittal Package" />
</Grid>
<Grid item md={6} xs={12}>
<ProjectCompanyAutoselectMui
  projectId={projectId}
  name="responsible_company"
  label="Responsible Company"
  disabled={isReadOnly}
/>

</Grid>

<Grid item md={8} xs={12}>
<CompanyAutocomplete type="text" name="supplier" label="Supplier" disabled={isReadOnly} />
<FinalCheckbox name="clear_supplier" label="Clear Supplier" />
</Grid>
<Grid item md={4} xs={12}>
<SpecSectionAutocompleteMui
  label="Spec Section"
  name="spec_section_code"
  projectId={projectId}
  allowDivisions={false}
/>
</Grid>
<Grid item md={6} xs={12}>
<DatePickerMui label="Returned Date" name="returned_date" helperText={`GC to Sub`} />
</Grid> */
}
