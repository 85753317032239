import {faElevator, faFireFlameCurved, faForklift, faQrcode} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import InnerFooter from "../../../components/InnerFooter";
import {PageHeader} from "../../../components/PageHeader";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";
import useBlockUI from "../../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../../hooks/useSentinelAPI";
import moment from "moment";

const ExternalForms = (props) => {
  const {
    contact,
    project,
    user,
    twilioContact,
    safetyOrientationCompleted,
    siteInfo,
    hospitalNavigationLink,
    urgentCareNavigationLink,
    recentForms = [],
    ...rest
  } = props;
  const isSpanish = twilioContact?.language === "SP";
  const [date, setDate] = useQueryState("date", "");
  const [createFormDialogIsOpen, setCreateFormDialogIsOpen] = React.useState(false);
  const todaysForms = recentForms.filter((form) => form.date === date && !moment(form.start_date).isAfter(moment()));
  const upcomingForms = recentForms.filter((form) => moment(form.start_date).isAfter(moment()));
  const previousForms = recentForms.filter((form) => form.date !== date && !upcomingForms.includes(form));

  const postCurrentPage = usePostCurrentPage();
  const blockUi = useBlockUI();

  type FormType = "forklift_inspection" | "scissor_boom_lift_inspection" | "hot_work";

  const createSafetyForm = (form_type: FormType) => {
    blockUi.blockUI("Generating Form...");
    postCurrentPage.mutateAsync({action: "create_form", form_type: form_type}).then((response) => {
      window.location.href = response.redirect;
    });
  };

  const ButtonComponent = (props: {
    form: {
      form_type: FormType;
      url?: string;
      date?: string;
      answers?: any;
    };
    onSelectForm?: () => void;
  }) => {
    const {form, onSelectForm} = props;
    switch (form?.form_type) {
      case "forklift_inspection":
        return (
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faForklift} />}
            href={form.url}
            onClick={onSelectForm}
          >
            {isSpanish ? `Montacargas` : `Forklift`}
            {form?.date && form.date !== date && ` (${form.date})`}
          </Button>
        );
      case "hot_work":
        return (
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faFireFlameCurved} />}
            href={form.url}
            onClick={onSelectForm}
          >
            {/* {isSpanish ? `Trabajo en caliente` : `Hot Work`} */}
            {isSpanish
              ? `Permiso de Trabajo en Caliente ${
                  form?.answers?.general?.location ? `(${form.answers.general.location})` : ``
                }`
              : `Hot Work Permit ${form?.answers?.general?.location ? `(${form.answers.general.location})` : ``}`}
            {form?.date && form.date !== date && ` (${form.date})`}
          </Button>
        );
      case "scissor_boom_lift_inspection":
        return (
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faElevator} />}
            href={form.url}
            onClick={onSelectForm}
          >
            {isSpanish ? `Elevación de tijera/pluma` : `Scissor / Boom Lift`}
            {form?.date && form.date !== date && ` (${form.date})`}
          </Button>
        );
    }
  };

  return (
    <>
      <Helmet title={`${project?.display}`} />

      <PageHeader mt={2}>
        <PageHeader.Title>
          {project?.display} - {isSpanish ? `Formularios del sitio de trabajo` : `Jobsite Forms`}
        </PageHeader.Title>
      </PageHeader>

      <Grid container spacing={2}>
        {(todaysForms.length > 0 || previousForms.length > 0) && (
          <Grid item xs={12}>
            <Box textAlign="center" mt={2}>
              <Typography variant="h6">{isSpanish ? "Hoy" : "Today"}</Typography>
            </Box>
          </Grid>
        )}
        {todaysForms.length > 0 ? (
          <>
            {todaysForms.map((form) => {
              return (
                <Grid item xs={12} key={form.id}>
                  <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                    <ButtonComponent form={form} />
                    <StatusLabel status={form.status_with_expiration_display} hint="projectSafetyForm" width="100%" />
                    {form?.expiration_date && form.status === "approved" && (
                      <Box width={1} display="flex" justifyContent="center">
                        <Typography2 type={form.is_permit_expired ? "error" : "metadata"}>
                          {isSpanish ? `Comienza` : `Start`}: {form.start_date}
                          {"   "}
                          {isSpanish ? `Vence` : `Expiration`}: {form.expiration_date}
                        </Typography2>
                      </Box>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </>
        ) : (
          <Grid item xs={12}>
            <Box textAlign="center" mt={2}>
              <Typography variant="h6">{isSpanish ? "No se encontraron formularios" : "No Forms Found"}</Typography>
            </Box>
          </Grid>
        )}
      </Grid>
      {previousForms.length > 0 && (
        <>
          <Box my={2}>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography variant="h6">{isSpanish ? "Reciente" : "Recent"}</Typography>
              </Box>
            </Grid>
            {previousForms.map((form) => {
              return (
                <Grid item xs={12} key={form.id}>
                  <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                    <ButtonComponent form={form} />
                    <StatusLabel status={form.status_with_expiration_display} hint="projectSafetyForm" width="100%" />
                    {form?.expiration_date && form.status === "approved" && (
                      <Box width={1} display="flex" justifyContent="center">
                        <Typography2 type={form.is_permit_expired ? "error" : "metadata"}>
                          {isSpanish ? `Comienza` : `Start`}: {form.start_date}
                          {"   "}
                          {isSpanish ? `Vence` : `Expiration`}: {form.expiration_date}
                        </Typography2>
                      </Box>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}

      {upcomingForms.length > 0 && (
        <>
          <Box my={2}>
            <Divider />
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box textAlign="center">
                <Typography variant="h6">{isSpanish ? "Proximo" : "Upcoming"}</Typography>
              </Box>
            </Grid>
            {upcomingForms.map((form) => {
              return (
                <Grid item xs={12} key={form.id}>
                  <Box display="flex" flexWrap="wrap" justifyContent="flex-start">
                    <ButtonComponent form={form} />
                    <StatusLabel status={form.status_with_expiration_display} hint="projectSafetyForm" width="100%" />
                    {form?.expiration_date && form.status === "approved" && (
                      <Box width={1} display="flex" justifyContent="center">
                        <Typography2 type={"metadata"}>
                          {isSpanish ? `Comienza` : `Start`}: {form.start_date}
                          {"   "}
                          {isSpanish ? `Vence` : `Expiration`}: {form.expiration_date}
                        </Typography2>
                      </Box>
                    )}
                  </Box>
                </Grid>
              );
            })}
          </Grid>
        </>
      )}
      <Box mb={15} />
      <InnerFooter hasSidebar={false}>
        <Button fullWidth variant="contained" color="primary" onClick={() => setCreateFormDialogIsOpen(true)}>
          {isSpanish ? "Crear Permiso" : "Create Permit"}
        </Button>
        {date && (
          <Box mt={1}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              href={`/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${date}/`}
              startIcon={<FontAwesomeIcon icon={faQrcode} />}
            >
              {isSpanish ? `Volver al Código QR` : `Back to QR Code`}
            </Button>
          </Box>
        )}
      </InnerFooter>

      <Dialog open={createFormDialogIsOpen} onClose={() => setCreateFormDialogIsOpen(false)}>
        <DialogTitle>{isSpanish ? "Crear Permiso" : "Create Permit"}</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            {isSpanish
              ? "Seleccione el tipo de permiso que desea crear"
              : "Select the type of permit you'd like to create"}
          </Typography>
          {["forklift_inspection", "hot_work", "scissor_boom_lift_inspection"].map((form_type: FormType) => {
            return (
              <Box my={2} key={form_type}>
                <ButtonComponent form={{form_type: form_type}} onSelectForm={() => createSafetyForm(form_type)} />
              </Box>
            );
          })}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCreateFormDialogIsOpen(false)}>{isSpanish ? "Cancelar" : "Cancel"}</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ExternalForms;
