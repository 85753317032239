import {faCheck, faFilePdf, faFileSignature, faPaperPlane} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Dialog, DialogContent, DialogTitle, FormHelperText, Grid, Typography} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import {useSnackbar} from "notistack";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {axiosAPI} from "../../api";
import {RadioGroupMui, TextFieldMui} from "../../components/forms/Fields";
import useBlockUI from "../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";
import {OrientationConfirmationDialog} from "../ExternalSafetyOrientation/ExternalSafetyOrientation";
import {validateEmailAddress} from "../../utils/validators";

const SafetyOrientationSpeedBump = (props) => {
  const {project, twilioContact, safetyOrientationCompleted, ...rest} = props;
  const isSpanish = twilioContact?.language === "SP";
  const [confirmationDialigIsOpen, setConfirmationDialogIsOpen] = React.useState(false);
  const [hasSeenPDF, setHasSeenPDF] = React.useState(false);
  const [showSeePDFWarning, setShowSeePDFWarning] = React.useState(false);
  const [emailDocumentDialogIsOpen, setEmailDocumentDialogIsOpen] = React.useState(false);
  const postCurrentPage = usePostCurrentPage();
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();
  return (
    <>
      <Box textAlign="center" mb={2}>
        <Typography variant="h5">
          {isSpanish ? (
            <>
              La orientación de seguridad requerida debe completarse antes de ingresar a este sitio. Toque Ver PDF de
              orientación a continuación para leer el documento de orientación de seguridad. Regrese a esta página y
              toque Reconocer cuando finalice la revisión de ese documento.
            </>
          ) : (
            <>
              Required safety orientation must be completed before entering this site. Tap View Orientation PDF below to
              read through the safety orientation document. Return to this page and tap Acknowledge when your review of
              that document is complete.
            </>
          )}
        </Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            target="_blank"
            href={`/reports2/external/projects/${project.uuid}/safety/orientation/${
              isSpanish ? `?lang=${twilioContact.language}` : ``
            }`}
            startIcon={<FontAwesomeIcon icon={faFilePdf} />}
            onClick={() => setHasSeenPDF(true)}
          >
            {isSpanish ? `Ver PDF de Orientación` : `View Orientation PDF`}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            fullWidth
            variant="contained"
            startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
            onClick={() => setEmailDocumentDialogIsOpen(true)}
          >
            {isSpanish ? `Enviar PDF de Orientación` : `Send Orientation PDF`}
          </Button>
        </Grid>
        <Grid item xs={12}>
          <span
            onClick={() => {
              if (!hasSeenPDF && !showSeePDFWarning) {
                setShowSeePDFWarning(true);
              }
            }}
          >
            <Button
              fullWidth
              variant="contained"
              onClick={() => setConfirmationDialogIsOpen(true)}
              disabled={safetyOrientationCompleted || !hasSeenPDF}
              startIcon={<FontAwesomeIcon icon={safetyOrientationCompleted ? faCheck : faFileSignature} />}
              color={safetyOrientationCompleted ? "primary" : "secondary"}
            >
              {!isSpanish && (safetyOrientationCompleted ? `Acknowledged` : `Acknowledge`)}
              {isSpanish && (safetyOrientationCompleted ? `Admitido` : `Reconocer`)}
            </Button>
          </span>
          {showSeePDFWarning && !hasSeenPDF && !safetyOrientationCompleted && (
            <Box width="100%" display="flex" justifyContent="center">
              <FormHelperText error>
                {isSpanish
                  ? `Primero debe revisar la Orientación de seguridad.`
                  : `You must first review the Safety Orientation.`}
              </FormHelperText>
            </Box>
          )}
        </Grid>
      </Grid>

      <OrientationConfirmationDialog
        isOpen={confirmationDialigIsOpen}
        handleClose={() => setConfirmationDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI(isSpanish ? `Guardando...` : `Saving...`);
          postCurrentPage.mutateAsync({action: "complete_orientation", ...values}).then(() => {
            // window.location.href = `/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${date}/`;
            window.location.reload();
            // setConfirmationDialogIsOpen(false);
            // blockUI.unblockUI();
          });
        }}
        userName={twilioContact?.name}
        isSpanish={isSpanish}
      />

      <SafetyOrientationEmailLinkForm
        isOpen={emailDocumentDialogIsOpen}
        handleClose={() => setEmailDocumentDialogIsOpen(false)}
        isSpanish={isSpanish}
        onSubmit={(values) => {
          blockUI.blockUI("Emailing link...");
          axiosAPI
            .post(`/projects/${project.uuid}/twilio-contact/${twilioContact.uuid}/email-safety-orientation/`, values, {
              baseURL: "",
            })
            .then((res) => {
              setEmailDocumentDialogIsOpen(false);
              setHasSeenPDF(true);
              blockUI.unblockUI();
              enqueueSnackbar(res.data.message, {variant: "success"});
            })
            .catch((err) => {
              setEmailDocumentDialogIsOpen(false);
              blockUI.unblockUI();
              enqueueSnackbar("Error sending email", {variant: "error"});
            });
        }}
        initialValues={{email: twilioContact?.contact?.email, language: twilioContact?.language}}
      />
    </>
  );
};

export default SafetyOrientationSpeedBump;

const SafetyOrientationEmailLinkForm = (props: {
  onSubmit: any;
  isSpanish?: boolean;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isSpanish, isOpen, handleClose, ...rest} = props;
  const validateEmail = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) {
      return;
    }
    if (!validateEmailAddress(value)) {
      return isSpanish ? "La dirección de email no es válida" : "Not a valid email address";
    }
  };
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle>{isSpanish ? `Enviar PDF` : `Send PDF`}</DialogTitle>
      <DialogContent>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {(formProps) => {
            const {handleSubmit, form, submitting, pristine, errors, invalid, values} = formProps;
            return (
              <>
                <form onSubmit={handleSubmit}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} md={12}>
                      <TextFieldMui
                        name="email"
                        label="Email"
                        type="email"
                        size="small"
                        helperText={
                          isSpanish
                            ? `Ingrese un correo electrónico para enviar este documento a otro dispositivo o persona`
                            : "Enter an email to send this document to another device or person"
                        }
                        required
                        autoFocus
                        fieldProps={{validate: validateEmail}}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <RadioGroupMui
                        label={isSpanish ? `Idioma` : `Language`}
                        name="language"
                        options={[
                          {label: isSpanish ? `Ingles` : `English`, value: "EN"},
                          {label: isSpanish ? `Español` : `Spanish`, value: "SP"},
                        ]}
                      />
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        type="submit"
                        // size="large"
                        startIcon={<SendIcon />}
                        disabled={invalid || submitting || !values.email}
                      >
                        {isSpanish ? `Enviar Enlance` : `Email Link`}
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </>
            );
          }}
        </FinalForm>
      </DialogContent>
    </Dialog>
  );
};
