import React from "react";
import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";
import {TwilioContactUpdateForm} from "./TwilioContactUpdateForms";

const TwilioContactUpdate = (props) => {
  const {twilioContact: initialTwilioContact} = props;
  const [twilioContact, setTwilioContact] = React.useState(initialTwilioContact);
  return (
    <UnauthenticatedLayout>
      <h1>Update Contact</h1>
      <TwilioContactUpdateForm
        initialValues={twilioContact}
        onSubmitted={(data) => {
          setTwilioContact(data.twilioContact);
          if (data.wellnessCheckResultURL) {
            window.location = data.wellnessCheckResultURL;
          }
        }}
      />
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </UnauthenticatedLayout>
  );
};

export default TwilioContactUpdate;
