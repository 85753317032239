import {faBoxOpen} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Tooltip, Typography} from "@material-ui/core";
import moment from "moment";
import {useSnackbar} from "notistack";
import pluralize from "pluralize";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {CreateMissingBoxFoldersConfirmationDialog, FixBoxFolderNamesConfirmationDialog} from "../../../components/Box";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {getAxiosAPIResponseMessage, useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import getQueryStringFromIds from "../../../utils/getQueryStringFromIds";
import {SubmittalPackageBulkUpdateDialogForm, SubmittalPackageUpdateDialogForm} from "./SubmittalPackageForms";
import {SubmittalPackagePaperItem} from "./SubmittalPackagePaperItem";

const ProjectSubmittalPackages = (props) => {
  const {project, userContact, ...rest} = props;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [deleteMultipleConfirmationIsOpen, setDeleteMultipleConfirmationIsOpen] = React.useState(false);
  const [createMissingBoxFolders, setCreateMissingBoxFolders] = React.useState(false);
  const [fixBoxFolderNamesDialog, setFixBoxFolderNamesDialog] = React.useState(false);
  const [activeSubmittalPackage, setActiveSubmittalPackage] = React.useState({});
  const [initialFocusField, setInitialFocusField] = React.useState(null);
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();

  const navigate = useNavigate();

  const [bulkUpdateIsOpen, setBulkUpdateIsOpen] = React.useState(false);

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Status",
    "Spec Section",
    "Tag",
    "Overdue",
    "Search",
  ]);

  const filterParams = {
    status: filterOptions.Status.value,
    tag: filterOptions.Tag.value,
    spec_section_code: filterOptions["Spec Section"].value,
    is_overdue: filterOptions.Overdue.value,
    q: filterOptions.Search.value,
  };

  const {
    query: submittalPackageQuery,
    create: createSubmittalPackage,
    update: updateSubmittalPackage,
    delete: deleteSubmittalPackage,
    rpc: submittalPackageRPC,
    onDragEnd: submittalPackageOnDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/submittals/packages/?parent__isnull=True&page_size=${pageSize}&page=${page}&${qs.stringify(
      filterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const submittalPackages = submittalPackageQuery.data.results;

  const currentPageQuery = useFetchCurrentPage({
    initialData: {},
  });

  const allPackageIds = submittalPackages.map((item) => item.id);

  const {
    selectedIds: selectedPackageIds,
    addSelectedId: addSelectedPackageId,
    addSelectedIds: addSelectedPackageIds,
    removeSelectedId: removeSelectedPackageId,
    removeSelectedIds: removeSelectedPackageIds,
    addAllSelectedIds: addAllSelectedPackageIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allPackagesSelected,
  } = useSelectIds(allPackageIds);

  const {query: statusQuery} = useSentinelListAPI(`projects/${project.id}/submittals/packages/status/`, {
    initialData: [],
  });
  const statusOptions = statusQuery.data;

  const reorderPackages = (orderBy) => {
    blockUI.blockUI("Reordering...");
    axiosAPI
      .post(`/projects/${project.id}/actions/reposition_submittal_packages/?order_by=${orderBy}`, {}, {baseURL: ""})
      .then(() => {
        submittalPackageQuery.refetch().then(() => blockUI.unblockUI());
      })
      .catch((error) => {
        enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
        blockUI.unblockUI();
      });
  };

  const {query: tagQuery} = useSentinelListAPI(`projects/${project.id}/submittals/packages/tags/`, {
    initialData: [],
  });
  const tagOptions = tagQuery.data;

  const {query: specSectionQuery} = useSentinelListAPI(`projects/${project.id}/submittals/packages/spec-sections`, {
    initialData: [],
  });

  const specSectionOptions = specSectionQuery.data;

  const pdfQueryString = React.useMemo(
    () => getQueryStringFromIds(selectedPackageIds, filterParams),
    [selectedPackageIds, filterParams]
  );

  const updateSubmittalPackageMemo = React.useCallback(updateSubmittalPackage.mutateAsync, []);

  return (
    <>
      <Helmet title={`${project.display} - Submittal Packages`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Submittal Packages</Typography>
      </ProjectBreadcrumbs>

      <PaperPanel>
        <PaperPanel.Header isLoading={submittalPackageQuery.isLoading || submittalPackageQuery.isFetching}>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              label={"Select All"}
              onChange={(event, value) => {
                if (value) {
                  addSelectedPackageIds(allPackageIds);
                } else {
                  removeAllSelectedItemIds();
                }
              }}
              indeterminate={
                !allPackagesSelected && selectedPackageIds.size < allPackageIds.length && selectedPackageIds.size
              }
              checked={allPackagesSelected}
            />
            <FontAwesomeIcon icon={faBoxOpen} /> Submittal Packages
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Tooltip placement="top" title={<>{project.is_closed ? "Project is Closed" : "New Submittal Package"}</>}>
                <div>
                  <PaperPanel.Header.CreateButton
                    onClick={() => {
                      setShowCreateDialog(true);
                    }}
                    disabled={project.is_closed}
                  >
                    Create
                  </PaperPanel.Header.CreateButton>
                </div>
              </Tooltip>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              {/* <PaperPanel.Header.Button
                disabled={!selectedPackageIds.size}
                onClick={() => {
                  setBulkUpdateIsOpen(true);
                }}
              >
                <FontAwesomeIcon icon={faListCheck} /> Bulk Update
              </PaperPanel.Header.Button> */}
              <PaperPanel.Header.EditButton
                disabled={selectedPackageIds.size === 0}
                onClick={() => setBulkUpdateIsOpen(true)}
              >
                Edit Selected
              </PaperPanel.Header.EditButton>
            </PaperPanel.Header.Action>

            <PaperPanel.Header.Action border>
              {/* <PaperPanel.Header.PDFButtons
                  pdfURL={`/reports2/projects/${project.id}/submittals/packages/`}
                  buildReportURL={`reports2/projects/${project.id}/submittals/packages/filter/`}
                /> */}
              <PaperPanel.Header.PDFButtons
                buildReportURL={`/reports2/projects/${project.id}/submittals/packages/filter/`}
              >
                {/* <MenuItemHeader>Submittal Package Summary</MenuItemHeader> */}
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/packages/?${pdfQueryString}`}
                >
                  Summary
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/outstanding/?${pdfQueryString}`}
                >
                  Outstanding
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/submittals/packages/ball-in-court/?${pdfQueryString}`}
                >
                  Ball In Court
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem
                  href={`/reports2/projects/${project.id}/mail-log/65/?${pdfQueryString}`}
                >
                  Mail Log
                </PaperPanel.Header.Menu.PDFMenuItem>
              </PaperPanel.Header.PDFButtons>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.ReorderButton onReorder={(orderBy) => reorderPackages(orderBy)}>
                {(setOpen) => (
                  <>
                    {/* <PaperPanel.Header.Menu.MenuItem
                        onClick={() => {
                          setOpen(false);
                          reorderPackages("to_company__name");
                        }}
                      >
                        <FontAwesomeIcon icon={faSortAlphaDown} fixedWidth />
                        Subcontractor Name
                      </PaperPanel.Header.Menu.MenuItem>
                      <PaperPanel.Header.Menu.MenuItem
                        onClick={() => {
                          setOpen(false);
                          reorderPackages("-to_company__name");
                        }}
                      >
                        <FontAwesomeIcon icon={faSortAlphaUp} fixedWidth />
                        Subcontractor Name
                      </PaperPanel.Header.Menu.MenuItem> */}
                  </>
                )}
              </PaperPanel.Header.ReorderButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border pr={0}>
              <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setCreateMissingBoxFolders(true);
                      }}
                    >
                      Create Missing Box Folders
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      onClick={(event) => {
                        popupState.close();
                        setFixBoxFolderNamesDialog(true);
                      }}
                    >
                      Fix Box Folder Names
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.Menu>
              <Box pl={0}>
                <PaperPanel.Header.RefreshButton
                  isFetching={submittalPackageQuery.isFetching}
                  onClick={() => submittalPackageQuery.refetch()}
                />
              </Box>
            </PaperPanel.Header.Action>
            {/* <PaperPanel.Header.Action>

              </PaperPanel.Header.Action> */}
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Status"
                name="status"
                options={statusOptions}
                value={filterOptions.Status.value}
                showStatusIcon
                statusType="submittal"
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Spec Section"
                name="spec_section"
                options={[{value: "EMPTY", label: "(Blank)"}, ...specSectionOptions]}
                // options={specSectionOptions}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Spec Section", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="Tag"
                name="Tag"
                options={tagOptions}
                value={filterOptions.Tag.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Tag", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="Overdue"
                name="Overdue"
                options={[
                  {value: "true", label: "Yes"},
                  {value: "false", label: "No"},
                ]}
                value={filterOptions["Overdue"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Overdue", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={submittalPackageQuery.data.total_pages}
                  page={page}
                  // pageSize={pageSize}
                  setPage={setPage}
                  // setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>

        <PaperPanel.Body>
          <VirtualizedList
            itemList={submittalPackages}
            isVirtualized={submittalPackages.length > 100}
            onDragEnd={submittalPackageOnDragEnd}
          >
            {(props) => {
              const {listItem: submittalPackage, ...rest} = props;
              return (
                <SubmittalPackagePaperItem
                  submittalPackage={submittalPackage}
                  isSelected={selectedPackageIds.has(submittalPackage.id)}
                  onUpdateSubmittalPackage={(is_draft) =>
                    updateSubmittalPackageMemo({...submittalPackage, ...is_draft})
                  }
                  onDeleteSubmittalPackage={() => {
                    navigate(
                      `/v2/projects/${submittalPackage.project_id}/delete/submittals/submittalpackage/${submittalPackage.id}/?next=/v2/projects/${submittalPackage.project_id}/submittals/packages/&cancel=/v2/projects/${submittalPackage.project_id}/submittals/packages/${submittalPackage.id}/`
                    );
                  }}
                  onChangeSelectedSubmittalPackage={(event, value) => {
                    if (value) {
                      addSelectedPackageId(submittalPackage.id, event.nativeEvent.shiftKey);
                    } else {
                      removeSelectedPackageId(submittalPackage.id);
                    }
                  }}
                  onEditSubmittalPackage={() => {
                    setActiveSubmittalPackage(submittalPackage);
                    setShowUpdateDialog(true);
                  }}
                  highlight={submittalPackage.id === activeSubmittalPackage.id && showUpdateDialog}
                  {...rest}
                />
              );
            }}
          </VirtualizedList>
        </PaperPanel.Body>
        {(submittalPackageQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={submittalPackageQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/submittals/packages/legacy/`} mt={2} />

      {/* <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          deleteSubmittalPackage.mutateAsync(activeSubmittalPackage.id);
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete {activeSubmittalPackage?.display}
      </ConfirmationDialog> */}

      <ConfirmationDialog
        isOpen={deleteMultipleConfirmationIsOpen}
        onApprove={() => {
          selectedPackageIds.forEach((itemId) => {
            deleteSubmittalPackage.mutateAsync(itemId);
          });
          removeAllSelectedItemIds();
          setDeleteMultipleConfirmationIsOpen(false);
        }}
        onDeny={() => setDeleteMultipleConfirmationIsOpen(false)}
      >
        You want to delete {selectedPackageIds.size} {pluralize("item", selectedPackageIds.size)}
      </ConfirmationDialog>

      <CreateMissingBoxFoldersConfirmationDialog
        isOpen={createMissingBoxFolders}
        setIsOpen={setCreateMissingBoxFolders}
        rpc={submittalPackageRPC}
      />
      <FixBoxFolderNamesConfirmationDialog
        isOpen={fixBoxFolderNamesDialog}
        setIsOpen={setFixBoxFolderNamesDialog}
        rpc={submittalPackageRPC}
      />

      <SubmittalPackageBulkUpdateDialogForm
        projectId={project.id}
        isOpen={bulkUpdateIsOpen}
        onSubmit={(values) => {
          if (values.delete_selected) {
            setBulkUpdateIsOpen(false);
            setDeleteMultipleConfirmationIsOpen(true);
            return;
          }

          blockUI.blockUI("Bulk updating...");
          setBulkUpdateIsOpen(false);

          const date_fields = [
            "start_date",
            "requested_date",
            "received_date",
            "returned_date",
            "delivered_date",
            "needed_onsite_date",
          ];

          if (values.clear_submittal_package) {
            values["submittal_package_id"] = null;
          }
          if (values.responsible_company) {
            values["responsible_company_id"] = values.responsible_company.id;
          }
          if (values.submittal_package) {
            values["submittal_package_id"] = values.submittal_package.id;
          }
          if (values.clear_responsible_company) {
            values["responsible_company_id"] = null;
          }
          if (values.clear_supplier) {
            values["supplier"] = "";
          }
          if (values.csi_code) {
            values["csi_code_id"] = values.csi_code.id;
          }

          if (values.include_in_pl) {
            values["is_included_in_pl"] = true;
          }
          if (values.exclude_from_pl) {
            values["is_included_in_pl"] = false;
          }

          date_fields.forEach((date) => {
            if (values[date]) {
              values[date] = moment(values[date]).format("YYYY-MM-DD");
            }
          });

          submittalPackageRPC
            .mutateAsync({
              action: "update",
              data: {
                ids: Array.from(selectedPackageIds),
                update: values,
              },
              method: "PUT",
            })
            .then(() => submittalPackageQuery.refetch().then(() => blockUI.unblockUI()))
            .catch(() => blockUI.unblockUI());
        }}
        onDelete={() => {
          setBulkUpdateIsOpen(false);
          setDeleteMultipleConfirmationIsOpen(true);
        }}
        isReadOnly={false}
        handleClose={() => setBulkUpdateIsOpen(false)}
        itemCount={selectedPackageIds.size}
        submittalStatuses={statusOptions}
      />

      <SubmittalPackageUpdateDialogForm
        projectId={project.id}
        isOpen={showCreateDialog}
        isNew
        handleClose={() => {
          setShowCreateDialog(false);
        }}
        initialValues={{
          number: submittalPackageQuery?.data?.metadata?.next_number,
          revision: 0,
          author_company: userContact?.company,
          author_contact: userContact,
          is_draft: true,
          tags: [],
        }}
        onSubmit={(values, form) => {
          // Check values for some value submitMode
          const submitMode = values.submitMode;
          delete values.submitMode;

          if (submitMode === "saveAndClose") {
            setShowCreateDialog(false);
            createSubmittalPackage.mutateAsync(values).then(() => {
              submittalPackageQuery.refetch();
            });
          }
          if (submitMode === "editAfterSubmit") {
            blockUI.blockUI("Creating...");
            createSubmittalPackage.mutateAsync(values).then((newSubmittalPackage) => {
              window.location.href = newSubmittalPackage.url;
              setShowCreateDialog(false);
            });
          }
          if (submitMode === "addAnother") {
            setInitialFocusField(null);
            blockUI.blockUI("Creating...");
            form.restart();

            // const focusElement = createFormRef.current.elements.description;
            createSubmittalPackage.mutateAsync(values).then(() => {
              // focusElement.focus();
              submittalPackageQuery.refetch().then(() => {
                setInitialFocusField("description");

                blockUI.unblockUI();
              });
            });
          }
        }}
        initialFocusField={initialFocusField}
      />

      <SubmittalPackageUpdateDialogForm
        projectId={project.id}
        isOpen={showUpdateDialog}
        handleClose={() => {
          setShowUpdateDialog(false);
          setActiveSubmittalPackage({});
          setInitialFocusField(false);
        }}
        initialValues={activeSubmittalPackage}
        onSubmit={(values) => {
          // Missing keys key set to empty string. All because a bug in React Final Form - values with an empty string are getting set to undefined.
          // Parse is not getting called at the right time. https://github.com/final-form/react-final-form/issues/130
          values = {
            ...{spec_section_code: ""},
            ...values,
          };

          updateSubmittalPackage.mutateAsync(values).then(() => submittalPackageQuery.refetch());
          setShowUpdateDialog(false);
        }}
        // isReadOnly={project.is_closed}
        submittalPackages={submittalPackages}
        activeSubmittalPackage={activeSubmittalPackage}
        setActiveSubmittalPackage={setActiveSubmittalPackage}
        beforePrevNext={(values) => {
          blockUI.blockUI("Updating...");
          values = {
            ...{spec_section_code: ""},
            ...values,
          };
          return updateSubmittalPackage.mutateAsync(values).then(() => {
            blockUI.unblockUI();
          });
        }}
        initialFocusField={initialFocusField}
        setInitialFocusField={setInitialFocusField}
      />

      {/* <h1>Submittal Packages</h1>
      <pre>{JSON.stringify(submittalPackages, null, 2)}</pre>
      <h1>Current Page Query</h1>
      <pre>{JSON.stringify(currentPageQuery, null, 2)}</pre>
      <h1>Submittal Items Query</h1>
      <pre>{JSON.stringify(submittalPackageQuery, null, 2)}</pre>
      <h1>Props</h1>
      <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default ProjectSubmittalPackages;
