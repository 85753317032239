import {faPaperPlane} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import moment from "moment";
import qs from "qs";
import React from "react";

import {useSelectIds} from "../../../js/hooks";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSelect} from "../../components/Filters";
import {LegacyUILink, MuiNavLink} from "../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import {PaperItem} from "../../components/PaperItem";
import PaperPanel from "../../components/PaperPanel";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchCurrentPage, usePostCurrentPage, useSentinelListAPI} from "../../hooks/useSentinelAPI";

const DocusignSendReminders = (props) => {
  const {user, ...rest} = props;
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(100);
  const blockUI = useBlockUI();
  const postCurrentPage = usePostCurrentPage();
  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(["Created By"], {
    "Created By": {value: user.id, label: user.full_name},
  });

  const filterParams = {
    created_by_id: filterOptions["Created By"]?.value,
  };
  const {query: envelopeQuery} = useSentinelListAPI(
    `/docusign/sentinel-envelopes/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}&status=sent`,
    {
      initialData: {results: []},
      // refetchOnWindowFocus: true
    }
  );
  const envelopes = envelopeQuery.data.results;

  const allEnvelopeIds = envelopes.map((envelope) => envelope.id);

  const {
    selectedIds: selectedEnvelopeIds,
    addSelectedId: addSelectedEnvelopeId,
    removeSelectedId: removeSelectedEnvelopeId,
    addAllSelectedIds: addAllEnvelopeIds,
    removeAllSelectedIds: removeAllEnvelopeIds,
    allIdsSelected: allEnvelopesSelected,
  } = useSelectIds(allEnvelopeIds);

  const pageDataQuery = useFetchCurrentPage(
    {
      initialData: {
        users: [user],
      },
    },
    {}
  );

  const pageData = pageDataQuery;

  const createdByOptions = pageData.data.users;

  return (
    <>
      <Breadcrumbs>
        <Typography color="textSecondary">Docusign</Typography>
        <MuiNavLink href={`/v2/docusign/envelopes/`}>
          <Typography color="textSecondary">Envelopes</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">Send Reminders</Typography>
      </Breadcrumbs>

      <PaperPanel>
        {/* <pre>
          {JSON.stringify(
            {
              allEnvelopeIds: allEnvelopeIds.length,
              selectedEnvelopeIds: selectedEnvelopeIds.size,
              allEnvelopesSelected: Boolean(allEnvelopesSelected),
            },
            null,
            2
          )}
        </pre> */}
        <PaperPanel.Header isLoading={envelopeQuery.isFetching}>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              label={"Select All"}
              onChange={(event, value) => {
                if (value) {
                  addAllEnvelopeIds();
                } else {
                  removeAllEnvelopeIds();
                }
              }}
              indeterminate={Boolean(
                !allEnvelopesSelected && selectedEnvelopeIds.size < allEnvelopeIds.length && selectedEnvelopeIds.size
              )}
              checked={allEnvelopesSelected}
            />
            Send Reminders
          </PaperPanel.Header.Title>

          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Tooltip title={selectedEnvelopeIds.size < 1 ? `Please Select at Least 1 Envelope` : ""}>
                <span>
                  <PaperPanel.Header.ColoredButton
                    onClick={() => {
                      blockUI.blockUI("Sending Reminders...");
                      postCurrentPage
                        .mutateAsync({action: "send_reminders", ids: Array.from(selectedEnvelopeIds)})
                        .then(() => {
                          envelopeQuery.refetch().then(() => {
                            blockUI.unblockUI();
                          });
                        })
                        .catch(() => {
                          blockUI.unblockUI();
                        })

                        .catch(() => blockUI.unblockUI());
                    }}
                    startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                    disabled={selectedEnvelopeIds.size < 1}
                  >
                    Send Reminders
                  </PaperPanel.Header.ColoredButton>
                </span>
              </Tooltip>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton onClick={envelopeQuery.refetch} isFetching={envelopeQuery.isFetching} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            {/* <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid> */}

            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Created By"
                name="Created By"
                options={createdByOptions}
                value={filterOptions["Created By"]?.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Created By", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  setPage(1);
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  setPage(1);
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={envelopeQuery.data.total_pages}
                  page={page}
                  // pageSize={pageSize}
                  setPage={(value) => {
                    removeAllEnvelopeIds();
                    setPage(value);
                  }}
                  // setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>

          {/* <pre>{JSON.stringify(filterOptions, null, 2)}</pre> */}
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          {envelopes.map((envelope) => {
            return (
              <DocusignEnvelopePaperItem
                envelope={envelope}
                envelopeIsSelected={selectedEnvelopeIds.has(envelope.id)}
                onChangeSelected={(e, selectBetween) => {
                  if (selectBetween) {
                    addSelectedEnvelopeId(envelope.id, e.nativeEvent.shiftKey);
                  } else {
                    removeSelectedEnvelopeId(envelope.id);
                  }
                }}
                key={envelope.id}
              />
            );
          })}
        </PaperPanel.Body>
        {(envelopeQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={envelopeQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={(value) => {
                removeAllEnvelopeIds();
                setPage(value);
              }}
              setPageSize={setPageSize}
              pageSizes={[100, 250, 500, 1000]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
      <LegacyUILink href={`/docusign/envelopes/send-reminders/`} mt={2} />
    </>
  );
};

export default DocusignSendReminders;

const DocusignEnvelopePaperItem = (props) => {
  const {envelope, onChangeSelected, envelopeIsSelected, ...rest} = props;
  return (
    <PaperItem key={envelope.id}>
      <PaperItem.Left minWidth={100}>
        <PaperItem.SelectedCheckbox checked={envelopeIsSelected} onChange={onChangeSelected} />{" "}
        <StatusLabel status={envelope.status} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={3} xs={6}>
            <Link href={`/v2/docusign/envelopes/${envelope.envelope_id}/`} underline="always">
              {envelope.envelope_id}
            </Link>
          </Grid>

          <Grid item sm={3} xs={6} container spacing={0}>
            <Grid item xs={12} md={4}>
              <Tooltip title={`Created: ${moment(envelope.created).format("LL")}`}>
                <Typography>{moment(envelope.created).fromNow()}</Typography>
              </Tooltip>
            </Grid>
            <Grid item xs={12} md={8}>
              <Tooltip title="Created By">
                <Typography>{envelope.created_by.full_name}</Typography>
              </Tooltip>
            </Grid>
          </Grid>
          {/* <Grid item sm={2} xs={6}>

          </Grid> */}

          <Grid item sm={6} xs={12}>
            {/* <Tooltip title="Project"> */}
            <MuiNavLink href={envelope.project.url} underline="always">
              <Typography>{envelope.project.display}</Typography>
            </MuiNavLink>
            {/* </Tooltip> */}
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={60}>
        <PaperItem.RightHover>
          <PaperItem.RightHover.IconButton
            icon={PictureAsPdf}
            title="Docusign Envelope"
            component={Link}
            href={`/docusign/envelopes/${envelope.envelope_id}/download/`}
          />
        </PaperItem.RightHover>
      </PaperItem.Right>
    </PaperItem>
  );
};
