import {Box, Tooltip} from "@material-ui/core";
import {getStatusColor} from "../theme/colors";

export const StatusLabel = (props: {status?: string; hint?: string; [rest: string]: any}) => {
  const {status, hint, component = "span", ...rest} = props;

  return (
    <Tooltip title={status}>
      <Box
        display="inline-flex"
        bgcolor={getStatusColor(status, hint)}
        width={75}
        py={0.25}
        px={0.5}
        color="#fff"
        borderRadius={4}
        fontSize={10}
        fontWeight={600}
        justifyContent="center"
        {...rest}
      >
        <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis" component={component}>
          {/* Force this component to be a span instead of the default <p>
          https://stackoverflow.com/questions/41928567/div-cannot-appear-as-a-descendant-of-p

          Fixes:
          Warning: validateDOMNesting(...): <div> cannot appear as a descendant of <p>

          */}
          {status}
        </Box>
      </Box>
    </Tooltip>
  );
};

export const TableStatusLabel = (props) => {
  const {children, ...rest} = props;

  return (
    <>
      <StatusLabel ml={-2} position="absolute" {...rest} />
      <Box ml={8}>{children}</Box>
    </>
  );
};
