import {faQrcode} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import moment from "moment";
import {useSnackbar} from "notistack";
import React from "react";
import {Form} from "react-final-form";
import {Helmet} from "react-helmet";
import {LabelValue} from "../../../js/components/LabelValue";
import {axiosAPI} from "../../api";
import {ConfirmationDialog} from "../../components/Dialogs";
import FormActions from "../../components/forms/FormActions";
import PaperPanel from "../../components/PaperPanel";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";
import UnauthenticatedLayout from "../../layouts/UnauthenticatedLayout";
import {PretaskPlanExternalEmailLinkFrom, PretaskPlanExternalSurveyForm} from "./PretaskPlanForms";

const PretaskPlanExternalSurvey = (props) => {
  const {
    pretaskPlan: initialPretaskPlan,
    questions,
    previousPretaskPlans,
    options,
    pretaskPlanQuestionsVersion,
    subcontractChoices = [],
    initialTrade,
    crewAutocomplete,
    twilioContactNamesToAddToCrew = [],
    generalSuperintendentDefaults,
  } = props;

  const [pretaskPlan, setPretaskPlan] = React.useState(initialPretaskPlan);
  const [copyPreviousDialogIsOpen, setCopyPreviousDialogIsOpen] = React.useState(false);
  const [noPreviousPretaskPlansToCopyDialogIsOpen, setNoPreviousPretaskPlansToCopyDialogIsOpen] = React.useState(false);
  const [selectedPreviousPretaskPlan, setSelectedPreviousPretaskPlan] = React.useState(
    previousPretaskPlans.length ? previousPretaskPlans[0] : null
  );
  const [certificationDialogIsOpen, setCertificationDialogIsOpen] = React.useState(false);

  const postCurrentPage = usePostCurrentPage();
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();

  const wellnessRedirectURL = `/twilio-contact/${pretaskPlan.twilio_contact.uuid}/projects/${pretaskPlan.project.uuid}/wellness-check/${pretaskPlan.date}/`;

  // HACK: https://stackoverflow.com/questions/56137175/ios-safari-chrome-wont-scroll-up-to-show-validation-error-message-on-radio-inpu/67215758#67215758
  // HACK: https://blog.teknkl.com/auto-scroll-to-first-error-ios-focus/
  React.useEffect(() => {
    if (/^(iPad|iPhone|iPod)$/.test(navigator.platform)) {
      var inputs = document.querySelectorAll('input[type="radio"], input[type="checkbox"]');
      inputs.forEach((i) => {
        i.addEventListener("focus", (e) => {
          // @ts-ignore
          e.target.scrollIntoView({
            block: "center",
            behavior: "smooth",
          });
        });
      });
    }
  });

  const isSpanish = pretaskPlan?.twilio_contact?.language === "SP";

  const tradeOptions = options.trade.map((trade) => trade.value);

  return (
    <UnauthenticatedLayout>
      {/* <pre>{JSON.stringify(liveSystems, null, 2)}</pre> */}

      <Helmet title={`${pretaskPlan.project.display} - ${pretaskPlan.date} - Pretask Plan`} />
      <Typography variant="h3">Pretask Plan</Typography>
      <Typography>{pretaskPlan.project.display}</Typography>
      <Typography>
        {moment(pretaskPlan?.date).format("dddd")} - {pretaskPlan?.date}
      </Typography>
      <Typography>{pretaskPlan.twilio_contact.name}</Typography>
      <Typography>{pretaskPlan.twilio_contact.company}</Typography>
      <StatusLabel status={pretaskPlan.status_display} fontSize={12} width={120} />
      {pretaskPlan.auditor_comments && (
        <LabelValue
          label={isSpanish ? "Comentarios del auditor" : "Auditor Comments"}
          value={isSpanish ? pretaskPlan.translated_auditor_comments : pretaskPlan.auditor_comments}
        />
      )}
      <Box mb={1} />
      <Button
        fullWidth
        variant="contained"
        color="primary"
        size="small"
        href={wellnessRedirectURL}
        startIcon={<FontAwesomeIcon icon={faQrcode} />}
      >
        {isSpanish ? `Volver al Código QR` : `Back to QR Code`}
      </Button>
      <Box mb={2} />
      <PretaskPlanExternalEmailLinkFrom
        initialValues={{email: pretaskPlan.twilio_contact?.contact?.email}}
        onSubmit={(values) => {
          blockUI.blockUI("Emailing link...");
          axiosAPI
            .post(`/pretask-plan/${pretaskPlan.uuid}/email-link/`, values, {baseURL: ""})
            .then((res) => {
              blockUI.unblockUI();
              enqueueSnackbar(res.data.message, {variant: "success"});
            })
            .catch((err) => {
              blockUI.unblockUI();
              enqueueSnackbar("Error sending email", {variant: "error"});
            });
        }}
        isSpanish={isSpanish}
      />
      <Box mb={1} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>{isSpanish ? `Plan de Pretarea` : `Pretask Plan`}</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButton
                target="_blank"
                href={`/reports2/pretask-plan/${pretaskPlan.uuid}/`}
                title={isSpanish ? `PDF` : `Get PDF`}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                disabled={pretaskPlan.is_closed}
                startIcon={<FileCopyIcon />}
                onClick={() => {
                  previousPretaskPlans.length === 0
                    ? setNoPreviousPretaskPlansToCopyDialogIsOpen(true)
                    : setCopyPreviousDialogIsOpen(true);
                }}
              >
                {isSpanish ? `Copiar del anterior` : `Copy from previous`}
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={2}>
          <Box mt={2} />

          <PretaskPlanExternalSurveyForm
            onSubmitted={(values) => setPretaskPlan(values)}
            onUpdateCrew={() => {
              axiosAPI.post(`/pretask-plan/${pretaskPlan.uuid}/update-crew/`, {}, {baseURL: ""}).then(() => {
                location.reload();
              });
            }}
            questions={questions}
            options={options}
            pretaskPlan={pretaskPlan}
            disabled={pretaskPlan.is_closed}
            isSpanish={isSpanish}
            crewAutoCompleteOptions={crewAutocomplete}
            subcontractChoices={subcontractChoices}
            pretaskPlanQuestionsVersion={pretaskPlanQuestionsVersion}
            wellnessRedirectURL={wellnessRedirectURL}
            twilioContactNamesToAddToCrew={twilioContactNamesToAddToCrew}
            generalSuperintendent={generalSuperintendentDefaults}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <Box mb={13} />

      <ConfirmationDialog
        isOpen={copyPreviousDialogIsOpen}
        onApprove={() => {
          setCopyPreviousDialogIsOpen(false);
          setCertificationDialogIsOpen(true);
        }}
        onDeny={() => setCopyPreviousDialogIsOpen(false)}
      >
        {isSpanish
          ? `¿Quiere copiar respuestas de un plan previo a la tarea anterior a este plan previo a la tarea actual? Se sobrescribirán todas las respuestas actuales.`
          : `You want to copy answers from a previous pretask plan to this current pretask plan? Any current answers will be overwritten.`}
        <Box mb={2} />
        <FormControl fullWidth variant="outlined" size="small">
          <InputLabel id="page_size">Previous Pretask Plan</InputLabel>
          <Select
            label="previous_pretask_plan"
            labelId="previous_pretask_plan"
            onChange={(event) => {
              setSelectedPreviousPretaskPlan(event.target.value);
            }}
            value={selectedPreviousPretaskPlan}
          >
            {previousPretaskPlans.map((previousPretaskPlan) => {
              return (
                <MenuItem
                  key={previousPretaskPlan.id}
                  value={previousPretaskPlan}
                  dense
                  selected={previousPretaskPlan.id === selectedPreviousPretaskPlan?.id}
                >
                  {moment(previousPretaskPlan?.date).format("dddd")} - {previousPretaskPlan?.date}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </ConfirmationDialog>

      <Dialog
        open={noPreviousPretaskPlansToCopyDialogIsOpen}
        onClose={() => setNoPreviousPretaskPlansToCopyDialogIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {isSpanish ? `No Hay Planes Previos de Tareas Previas Disponibles` : `No Previous Pretask Plans Available`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {isSpanish
              ? `No hay planes previos a la tarea disponibles para copiar. Los planes previos a las tareas se pueden copiar durante un máximo de una semana; después de eso, deberá completar uno nuevo.`
              : `There are no previous Pretask Plans available to copy. Pretask Plans can be copied for a maximum of one week, after that you will need to complete a new one.`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setNoPreviousPretaskPlansToCopyDialogIsOpen(false)}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <CertifyCopiedPlan
        onSubmit={(values) => {
          pretaskPlan.survey = {...selectedPreviousPretaskPlan.survey};
          pretaskPlan.survey["time"] = {
            current_hours_worked_to_date: selectedPreviousPretaskPlan.total_hours,
            hours_per_crewmember: 8,
            // todays_hours_worked: 0,
          };
          pretaskPlan.survey["certification"] = ["certification"];
          const newPretaskPlan = {...pretaskPlan};
          if (!tradeOptions.includes(newPretaskPlan.survey.work.trade)) {
            newPretaskPlan.survey.work.trade = initialTrade;
          }
          setPretaskPlan(newPretaskPlan);
          postCurrentPage
            .mutateAsync({
              action: "copy-from-previous",
              new_pretask_plan: newPretaskPlan,
              pretask_plan_id_to_copy: selectedPreviousPretaskPlan.id,
            })
            .then((values) => setPretaskPlan(values));
          setCertificationDialogIsOpen(false);
        }}
        handleClose={() => setCertificationDialogIsOpen(false)}
        isOpen={certificationDialogIsOpen}
        questions={questions}
        isSpanish={isSpanish}
      />
    </UnauthenticatedLayout>
  );
};

export default PretaskPlanExternalSurvey;

const CertifyCopiedPlan = (props: {
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  questions: any;
  isSpanish: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, isReadOnly, handleClose, questions, isSpanish, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <Form onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine = true, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">
                {isSpanish
                  ? "Antes de copiar los valores anteriores, debe aceptar la siguiente certificación:"
                  : "Before copying previous values, you must agree to the following certification:"}
              </DialogTitle>
              <DialogContent>
                <Box>{questions.certification.map((question) => question.question)}</Box>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton label={isSpanish ? "Certificar" : "Certify"} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
    </>
  );
};
