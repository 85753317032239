import {faSackDollar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Grid, Typography} from "@material-ui/core";
import classnames from "classnames";
import moment from "moment-timezone";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {Currency} from "../../components/Accounting";
import {ConfirmationDialog} from "../../components/Dialogs";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import distributionRequestStatuses from "../../components/forms/choices/bonusAccountDistributionRequestStatuses.json";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import {TableStatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {BonusAccountCloseoutRequestDialogForm} from "./BonusForms";

const BonusAccountCloseoutRequestList = (props) => {
  const [showCreateCloseoutRequestDialog, setShowCreateCloseoutRequestDialog] = React.useState(false);
  const [showUpdateCloseoutRequestDialog, setShowUpdateCloseoutRequestDialog] = React.useState(false);
  const [showDeleteCloseoutRequestDialog, setShowDeleteCloseoutRequestDialog] = React.useState(false);
  const [activeCloseoutRequest, setActiveCloseoutRequest] = React.useState({}) as any;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);

  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const createCloseoutRequestFormRef: any = React.createRef();
  const updateCloseoutRequestFormRef: any = React.createRef();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Status"]
    // {
    //   Status: {
    //     value: "submitted",
    //     label: "Submitted",
    //   },
    // }
  );

  const filterParams = {
    q: filterOptions.Search.value,
    status: filterOptions.Status.value,
  };

  const {
    query: bonusAccountCloseoutRequestQuery,
    create: createBonusAccountCloseoutRequest,
    update: updateBonusAccountCloseoutRequest,
    delete: deleteBonusAccountCloseoutRequest,
    rpc: bonusAccountCloseoutRequestRPC,
  } = useSentinelListAPI(
    `bonuses/closeout-requests/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );

  return (
    <>
      <Helmet title="Bonus Closeout Requests" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Bonus Closeout Requests</Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faSackDollar} /> Bonus Closeout Requests
          </PageHeader.Title>
        </PageHeader.Left>
        {/* {pageDataQuery.isFetchedAfterMount && (
          <PageHeader.Right>
            <PageHeader.Right.CurrencySpark title="Created Total" number={pageData.createdTotal} precision={2} />
            <PageHeader.Right.CurrencySpark title="Submitted Total" number={pageData.submittedTotal} precision={2} />
          </PageHeader.Right>
        )} */}
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header isLoading={bonusAccountCloseoutRequestQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faSackDollar} /> Bonus Closeout Requests
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {/* <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setShowCreateCloseoutRequestDialog(true);
                }}
              >
                Create
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action> */}
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                isFetching={bonusAccountCloseoutRequestQuery.isFetching}
                onClick={() => bonusAccountCloseoutRequestQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                // native
                label="Status"
                name="Status"
                options={distributionRequestStatuses}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Box mt={1} />
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination
                  count={bonusAccountCloseoutRequestQuery.data.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["bonus_amount"]}
            columns={React.useMemo(
              () => [
                // {
                //   Header: "Status",
                //   accessor: "status_display",
                //   Cell: ({value, row}) => <StatusLabel status={value} hint="bonusAccountCloseoutRequest" />,
                // },
                {
                  Header: "Description",
                  accessor: "description",
                  Cell: ({value, row}) => {
                    return (
                      <>
                        <TableStatusLabel status={row.original.status_display} hint="bonusAccountCloseoutRequest">
                          {/* <Link
                            style={{cursor: "pointer"}}
                            onClick={() => {
                              setActiveCloseoutRequest(row.original);
                              setShowUpdateCloseoutRequestDialog(true);
                            }}
                            underline="always"
                          >
                            {value}
                          </Link>{" "}
                          <Link href={row.original.url} target="_blank">
                            <FontAwesomeIcon icon={faExternalLink} />
                          </Link> */}
                          <MuiNavLink to={row.original.url} underline="always">
                            {value}
                          </MuiNavLink>
                        </TableStatusLabel>
                      </>
                    );
                  },
                },
                {
                  Header: "Created",
                  accessor: "created",
                  Cell: ({value}) => {
                    return moment.tz(value, moment.tz.guess()).format("YYYY-MM-DD hh:mm A z");
                  },
                },
                {
                  Header: "Account",
                  accessor: "account.description",
                  Cell: ({value, row}) => {
                    return (
                      <MuiNavLink to={`/v2/bonuses/accounts/${row.original.account.id}/`} underline="always">
                        {value}
                      </MuiNavLink>
                    );
                  },
                },
                {
                  Header: "User",
                  accessor: "account.user.full_name",
                },
                {
                  Header: "Bonus Amount",
                  accessor: "bonus_amount",
                  Cell: ({value, row}) => <Currency number={value} />,
                },
              ],
              []
            )}
            initialState={{
              sortBy: [
                {
                  id: "created",
                  desc: true,
                },
              ],
            }}
            data={bonusAccountCloseoutRequestQuery.data.results}
          />
          {bonusAccountCloseoutRequestQuery.data.total_pages > 1 && (
            <PaperPanel.Footer display="flex" justifyContent="flex-end">
              <PaginationWithPageSize
                count={bonusAccountCloseoutRequestQuery.data.total_pages}
                page={page}
                pageSize={pageSize}
                setPage={setPage}
                setPageSize={setPageSize}
                pageSizes={[100, 250, 500, 1000]}
              />
            </PaperPanel.Footer>
          )}
        </PaperPanel.Body>
      </PaperPanel>

      <BonusAccountCloseoutRequestDialogForm
        ref={createCloseoutRequestFormRef}
        isOpen={showCreateCloseoutRequestDialog}
        isNew
        handleClose={() => {
          setShowCreateCloseoutRequestDialog(false);
        }}
        initialValues={activeCloseoutRequest}
        onDelete={(values, form) => {
          setShowDeleteCloseoutRequestDialog(true);
        }}
        onSubmit={(values, form) => {
          blockUI.blockUI("Creating...");
          createBonusAccountCloseoutRequest.mutateAsync(values).then((newCloseoutRequest) => {
            blockUI.unblockUI();
            setShowCreateCloseoutRequestDialog(false);
            bonusAccountCloseoutRequestQuery.refetch();
          });
        }}
      />

      <BonusAccountCloseoutRequestDialogForm
        ref={updateCloseoutRequestFormRef}
        isOpen={showUpdateCloseoutRequestDialog}
        isReadOnly={activeCloseoutRequest.is_closed}
        handleClose={() => {
          setShowUpdateCloseoutRequestDialog(false);
        }}
        initialValues={activeCloseoutRequest}
        onDelete={(values, form) => {
          setShowDeleteCloseoutRequestDialog(true);
        }}
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;

          if (["submit", "approve", "reject"].includes(submitMode)) {
            blockUI.blockUI("Updating...");
            updateBonusAccountCloseoutRequest.mutateAsync(values).then(() => {
              bonusAccountCloseoutRequestRPC
                .mutateAsync({action: submitMode, id: activeCloseoutRequest.id})
                .then(() => {
                  bonusAccountCloseoutRequestQuery.refetch().then(() => {
                    blockUI.unblockUI();
                    setShowUpdateCloseoutRequestDialog(false);
                  });
                });
            });
            return;
          }
          blockUI.blockUI("Updating...");
          updateBonusAccountCloseoutRequest.mutateAsync(values).then(() => {
            bonusAccountCloseoutRequestQuery.refetch().then(() => {
              blockUI.unblockUI();
              setShowUpdateCloseoutRequestDialog(false);
            });
          });
        }}
      />

      <ConfirmationDialog
        isOpen={showDeleteCloseoutRequestDialog}
        onApprove={() => {
          setShowDeleteCloseoutRequestDialog(false);
          //   blockUI.blockUI();
          setShowUpdateCloseoutRequestDialog(false);
          deleteBonusAccountCloseoutRequest.mutateAsync(activeCloseoutRequest.id).then(() => {
            setActiveCloseoutRequest({});
          });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setShowDeleteCloseoutRequestDialog(false);
        }}
      >
        You want to delete this closeout request. This cannot be undone.
      </ConfirmationDialog>
      {/* <pre>{JSON.stringify(bonusAccountCloseoutRequestQuery, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(bonusAccountCloseoutRequestQuery, null, 2)}</pre>
      <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
    </>
  );
};

export default BonusAccountCloseoutRequestList;
