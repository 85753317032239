import {faEnvelope} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Breadcrumbs, Grid, Link, Tab, Typography} from "@material-ui/core";
import SendIcon from "@material-ui/icons/Send";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import accounting from "accounting";
import classnames from "classnames";
import {range} from "lodash";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {Percentage} from "../../components/Accounting";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSelect} from "../../components/Filters";
import {LegacyUILink, MuiNavLink} from "../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import {TableStatusLabel} from "../../components/Status";
import {CompanyAutoselectMui, ProjectAutoselectMui, UserAutoselectMui} from "../../components/forms/Fields";
import docuSignStatuses from "../../components/forms/choices/docuSignStatuses.json";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";

const DocuSignEnvelopes = (props) => {
  const {user, ...rest} = props;

  const [selectedTab, setSelectedTab] = React.useState("envelopes");
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const [project, setProject] = React.useState(null);
  const [to_company, setToCompany] = React.useState(null);
  const [createdBy, setCreatedBy] = React.useState(user);
  const novoClasses = makeNovoClasses();

  const yearOptions = range(2016, new Date().getFullYear() + 1).map((year) => {
    return {label: year.toString(), value: year.toString()};
  });

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Year", "Status", "To Company", "Project", "Created By"],
    {
      Year: {
        value: new Date().getFullYear(),
        label: new Date().getFullYear(),
      },
      "Created By": {
        value: user?.id,
        label: user?.full_name,
      },
    }
  );
  const filterParams = {
    status: filterOptions.Status.value,
    project_id: filterOptions.Project.value,
    year: filterOptions.Year.value,
    created_by_id: filterOptions["Created By"].value,
    to_company_id: filterOptions["To Company"].value,
  };

  const {query: envelopeQuery} = useSentinelListAPI(
    `/docusign/sentinel-envelopes/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
        metadata: {
          stats: [],
        },
      },
      keepPreviousData: true,
    }
  );

  return (
    <>
      <Helmet title="DocuSign Envelopes" />
      <Box my={1}>
        <Breadcrumbs>
          <MuiNavLink color="inherit" to="/v2/dashboard/">
            Dashboard
          </MuiNavLink>
          <Typography color="textPrimary">DocuSign Envelopes</Typography>
        </Breadcrumbs>
      </Box>

      {/*
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faEnvelope} /> DocuSign Envelopes
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      */}
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar
            position="static"
            color="default"
            // elevation={0}
          >
            <TabList
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
                // navigate(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Envelopes" value="envelopes" />
              <Tab label="Stats" value="stats" />
            </TabList>
          </AppBar>
          <TabPanel value="envelopes">
            <Box mx={-3} mb={-3} mt={-2}>
              <PaperPanel.Header isLoading={envelopeQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faEnvelope} /> DocuSign Envelopes{" "}
                  {envelopeQuery.isFetchedAfterMount ? `(${accounting.formatNumber(envelopeQuery.data.count)})` : null}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      component={Link}
                      href={`/v2/docusign/envelopes/send-reminders/`}
                      underline="none"
                      startIcon={<SendIcon />}
                    >
                      Send Reminders
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => envelopeQuery.refetch()}
                      isFetching={envelopeQuery.isFetching}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>
              <PaperPanel.Toolbar p={1}>
                <FinalForm onSubmit={() => {}}>
                  {() => (
                    <Grid container spacing={1}>
                      <Grid item sm={3} xs={12}>
                        <FilterSelect
                          // native
                          allowNull={false}
                          label="Year"
                          name="Year"
                          options={yearOptions}
                          value={filterOptions.Year.value}
                          // showStatusIcon
                          // statusType="rfi"
                          onChange={(value, label) => {
                            setPage(1);
                            setFilterOption("Year", value, label);
                          }}
                        />
                      </Grid>
                      <Grid item sm={3} xs={12}>
                        <FilterSelect
                          // native
                          label="Status"
                          name="Status"
                          options={docuSignStatuses}
                          value={filterOptions.Status.value}
                          // showStatusIcon
                          // statusType="rfi"
                          onChange={(value, label) => {
                            setPage(1);
                            setFilterOption("Status", value, label);
                          }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <UserAutoselectMui
                          label="Created By"
                          name="created_by"
                          value={createdBy}
                          onChange={(e, value) => {
                            setPage(1);
                            setCreatedBy(value);
                            if (value === null) {
                              clearFilterOption("Created By");
                            } else {
                              setFilterOption("Created By", value?.id, value?.full_name);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <ProjectAutoselectMui
                          label="Project"
                          name="project"
                          value={project}
                          onChange={(e, value) => {
                            setPage(1);
                            setProject(value);
                            if (value === null) {
                              clearFilterOption("Project");
                            } else {
                              setFilterOption("Project", value?.id, value?.display);
                            }
                          }}
                        />
                      </Grid>
                      <Grid item sm={6} xs={12}>
                        <CompanyAutoselectMui
                          label="To Company"
                          name="to_company"
                          value={to_company}
                          onChange={(e, value) => {
                            setPage(1);
                            setToCompany(value);
                            if (value === null) {
                              clearFilterOption("To Company");
                            } else {
                              setFilterOption("To Company", value?.id, value?.name);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </FinalForm>

                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FilterOptionChips
                      filterOptions={filterOptions}
                      onDelete={(key) => {
                        if (key === "Project") {
                          setProject(null);
                        }
                        if (key === "Created By") {
                          setCreatedBy(null);
                        }
                        clearFilterOption(key);
                      }}
                      onDeleteAll={() => {
                        setProject(null);
                        setCreatedBy(null);
                        clearAllFilterOptions();
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Pagination
                        count={envelopeQuery.data.total_pages}
                        page={page}
                        // pageSize={pageSize}
                        setPage={setPage}
                        // setPageSize={setPageSize}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </PaperPanel.Toolbar>
              <PaperPanel.Body>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  // containerComponent={Paper}
                  rightAlignColumns={["created"]}
                  columns={[
                    {
                      Header: "Envelope",
                      accessor: "envelope_id",
                      disableSortBy: true,
                      Cell: ({value, row}) => (
                        <>
                          <TableStatusLabel status={row.original.status || "Unknown"}>
                            <Link underline="always" href={`/v2/docusign/envelopes/${value}/`}>
                              {value.substring(0, 8)}...
                            </Link>
                          </TableStatusLabel>
                        </>
                      ),
                    },
                    {
                      Header: "Object",
                      accessor: "object.display",
                      Cell: ({value, row}) => (
                        <Link underline="always" href={`${row.original.object.url}`}>
                          {value}
                        </Link>
                      ),
                    },
                    {
                      Header: "Project",
                      accessor: "project.display",
                      Cell: ({value, row}) =>
                        row.original.project ? (
                          <Link underline="always" href={`${row.original.project.url}`}>
                            {value}
                          </Link>
                        ) : (
                          <></>
                        ),
                    },
                    {
                      Header: "Obj Status",
                      accessor: "object.status_display",
                    },
                    {
                      Header: "Created By",
                      accessor: "created_by.full_name",
                    },
                    {
                      Header: "Created",
                      accessor: "created",
                      Cell: ({value, row}) =>
                        row.original.project ? (
                          <Box whiteSpace="nowrap">
                            {value
                              ? moment.tz(value, row.original.project.timezone).format("ddd, MMM D, h:mm:ss A z")
                              : ""}
                          </Box>
                        ) : (
                          <Box whiteSpace="nowrap">{value ? moment(value).format("ddd, MMM D, h:mm:ss A z") : ""}</Box>
                        ),
                    },
                  ]}
                  initialState={{
                    sortBy: [
                      {
                        id: "created",
                        desc: true,
                      },
                    ],
                  }}
                  data={envelopeQuery.data.results}
                />

                {/* <pre>{JSON.stringify(envelopeQuery, null, 2)}</pre> */}
              </PaperPanel.Body>
              {(envelopeQuery.data.total_pages > 1 || pageSize > 100) && (
                <PaperPanel.Footer display="flex" justifyContent="flex-end">
                  <PaginationWithPageSize
                    count={envelopeQuery.data.total_pages}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    pageSizes={[10, 100, 250, 500, 1000]}
                  />
                </PaperPanel.Footer>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="stats">
            <Box mx={-3} mb={-3} mt={-2}>
              <PaperPanel.Header isLoading={envelopeQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faEnvelope} /> Envelope Stats{" "}
                  {envelopeQuery.isFetchedAfterMount ? `(${accounting.formatNumber(envelopeQuery.data.count)})` : null}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => envelopeQuery.refetch()}
                      isFetching={envelopeQuery.isFetching}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>
              <PaperPanel.Toolbar p={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FilterOptionChips
                      filterOptions={filterOptions}
                      onDelete={(key) => {
                        if (key === "Project") {
                          setProject(null);
                        }
                        if (key === "Created By") {
                          setCreatedBy(null);
                        }
                        clearFilterOption(key);
                      }}
                      onDeleteAll={() => {
                        setProject(null);
                        setCreatedBy(null);
                        clearAllFilterOptions();
                      }}
                    />
                  </Grid>
                </Grid>
              </PaperPanel.Toolbar>
              <PaperPanel.Body>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  // containerComponent={Paper}
                  rightAlignColumns={["count", "percent"]}
                  columns={[
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Count",
                      accessor: "count",
                      Cell: ({value, row}) => accounting.formatNumber(value),
                    },
                    {
                      Header: "Percent",
                      accessor: "percent",
                      Cell: ({value, row}) => <Percentage number={value} precision={2} />,
                    },
                  ]}
                  initialState={{
                    sortBy: [
                      {
                        id: "status",
                        desc: false,
                      },
                    ],
                  }}
                  data={envelopeQuery.data.metadata.stats}
                />
                {/* <pre>{JSON.stringify(envelopeQuery.data.metadata.stats, null, 2)}</pre> */}
              </PaperPanel.Body>
            </Box>
          </TabPanel>
        </PaperPanel>
      </TabContext>
      <LegacyUILink href={`/docusign/envelopes/`} mt={2} />

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default DocuSignEnvelopes;
