import {faCamera, faImages, faQrcode} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Link,
  Tooltip,
  Typography,
} from "@material-ui/core";
import CreateNewFolderIcon from "@material-ui/icons/CreateNewFolder";
import FolderIcon from "@material-ui/icons/Folder";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import {Skeleton} from "@material-ui/lab";
import Cookies from "js-cookie";
import {find, initial} from "lodash";
import moment from "moment";
import React from "react";
import {isMobile} from "react-device-detect";
import {Form} from "react-final-form";
import AutoSaveDebounce from "../../js/components/ReactFinalForm/AutoSaveDebounce";
import {axiosAPI} from "../api";
import useBlockUI from "../hooks/useBlockUI";
import {useSentinelListAPI} from "../hooks/useSentinelAPI";
import {ColoredButton, UploadFileButton} from "./Buttons";
import {ConfirmationDialog} from "./Dialogs";
import DropzoneMui from "./DropzoneMui";
import {TextFieldMui} from "./forms/Fields";
import FormActions from "./forms/FormActions";
import PaperPanel, {paperPanelButtonProps} from "./PaperPanel";
import Typography2 from "./Typography2";
import {on} from "events";

export const BoxFilesIconButtonLink = (props: {
  app: string;
  model: string;
  objectId: number | string;
  projectId?: number | string;
  boxFolderId?: number | string;
}) => {
  const {projectId, app, model, objectId, boxFolderId} = props;
  if (boxFolderId) {
    let href = `/v2/box/files/${app}/${model}/${objectId}/?folder_id=${boxFolderId}`;
    if (projectId) {
      href = `/v2/projects/${projectId}/box/files/${app}/${model}/${objectId}/?folder_id=${boxFolderId}`;
    }
    return (
      <Tooltip title="Files">
        <IconButton size="small" component={Link} href={href}>
          <FolderIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title="Create Folder">
        <IconButton size="small" component={Link} href={`/v2/box/files/${app}/${model}/${objectId}/`}>
          <CreateNewFolderIcon fontSize="small" />
        </IconButton>
      </Tooltip>
    );
  }
};

export const BoxCollaborationButton = (props: {boxFolderId: string | number; user: any; [rest: string]: any}) => {
  const {boxFolderId, user, ...rest} = props;
  const [collaborator, setCollaborator] = React.useState(undefined);
  const [isLoading, setIsLoading] = React.useState(true);
  const [showRemoveCollaborationConfirmation, setShowRemoveCollaborationConfirmation] = React.useState(false);
  const collaborationURL = `/box/api/folders/${boxFolderId}/collaborations/`;
  const userEmail = user.email;
  const isCollaborator = Boolean(collaborator);

  const blockUI = useBlockUI();

  const fetchCollaborators = () => {
    setIsLoading(true);
    return axiosAPI
      .get(collaborationURL, {baseURL: ""})
      .then((response) => {
        const collaborator = find(response.data.entries, (entry) => {
          return entry.accessible_by?.login?.toLowerCase() === userEmail.toLowerCase();
        });
        setCollaborator(collaborator);
      })
      .then(() => setIsLoading(false));
  };
  React.useEffect(() => {
    fetchCollaborators();
  }, []);
  if (isLoading) {
    return (
      /* @ts-ignore */
      <Button {...paperPanelButtonProps} disabled>
        Fetching Collaborators
      </Button>
    );
  }
  if (isCollaborator) {
    return (
      <>
        <ColoredButton
          color="error"
          startIcon={<HighlightOffIcon />}
          {...paperPanelButtonProps}
          onClick={() => setShowRemoveCollaborationConfirmation(true)}
        >
          Remove Me As Collaborator
        </ColoredButton>

        <ConfirmationDialog
          isOpen={showRemoveCollaborationConfirmation}
          onApprove={() => {
            blockUI.blockUI("Removing collaboration...");
            setShowRemoveCollaborationConfirmation(false);
            axiosAPI
              .delete(`/box/api/collaborations/${collaborator?.id}/`, {baseURL: ""})
              .then(() => {
                setShowRemoveCollaborationConfirmation(false);
                fetchCollaborators().then(() => blockUI.unblockUI());
              })
              .catch(() => {
                blockUI.unblockUI();
              });
          }}
          onDeny={() => {
            setShowRemoveCollaborationConfirmation(false);
          }}
        >
          You want to remove yourself as a collaborator from this folder?
          <br />
          This will also remove this folder form Box sync.
        </ConfirmationDialog>
      </>
    );
  }
  return (
    <ColoredButton
      color="success"
      startIcon={<GroupAddIcon />}
      {...paperPanelButtonProps}
      onClick={() => {
        blockUI.blockUI("Adding collaboration...");
        axiosAPI
          .post(
            "/box/api/collaborations/?notify=false",
            {
              item: {id: boxFolderId, type: "folder"},
              accessible_by: {login: userEmail},
              role: "editor",
            },
            {baseURL: ""}
          )
          .then((response) => {
            fetchCollaborators().then(() => blockUI.unblockUI());
          });
      }}
    >
      Join Folder as Collaborator
    </ColoredButton>
  );
};

export const BoxCollaborationButton2 = (props: {collaborationId?: string; [rest: string]: any}) => {
  const {collaborationId, ...rest} = props;
  const [removeCollaborationConfirmationIsOpen, setRemoveCollaborationConfirmationIsOpen] = React.useState(false);

  const blockUI = useBlockUI();

  return (
    <>
      {collaborationId ? (
        <ColoredButton
          color="error"
          startIcon={<HighlightOffIcon />}
          {...paperPanelButtonProps}
          onClick={() => setRemoveCollaborationConfirmationIsOpen(true)}
        >
          Remove Me As Collaborator
        </ColoredButton>
      ) : (
        <ColoredButton
          color="success"
          startIcon={<GroupAddIcon />}
          {...paperPanelButtonProps}
          onClick={() => {
            blockUI.blockUI("Adding collaboration...");
            const data = {
              item: {id: collaborationId, type: "folder"},
              accessible_by: {login: props.user.email},
              role: "editor",
            };
            axiosAPI({
              url: "/box/api/collaborations/",
              method: "post",
              baseURL: "",
              data: data,
              params: {
                notify: false,
              },
            })
              .then(() => {
                // pageDataQuery.refetch().then(() => {
                //   blockUI.unblockUI();
                //   setIframeKey(Math.random());
                // });
              })
              .catch(() => {
                // pageDataQuery.refetch().then(() => blockUI.unblockUI());
              });
          }}
        >
          Join Folder as Collaborator
        </ColoredButton>
      )}
      <ConfirmationDialog
        isOpen={removeCollaborationConfirmationIsOpen}
        onApprove={() => {
          blockUI.blockUI("Removing collaboration...");
          setRemoveCollaborationConfirmationIsOpen(false);
          axiosAPI
            .delete(`/box/api/collaborations/${collaborationId}/`, {baseURL: ""})
            .then(() => {
              // pageDataQuery.refetch().then(() => {
              //   blockUI.unblockUI();
              //   setIframeKey(Math.random());
              // });
            })
            .catch(() => {
              // pageDataQuery.refetch().then(() => blockUI.unblockUI());
            });
        }}
        onDeny={() => {
          setRemoveCollaborationConfirmationIsOpen(false);
        }}
      >
        You want to remove yourself as a collaborator from this folder?
        <br />
        This will also remove this folder form Box sync.
      </ConfirmationDialog>
    </>
  );
};

const QuickCaptureToBoxButton = (props: {onUpload: (file) => void}) => {
  const {onUpload} = props;
  return (
    <UploadFileButton
      accept="image/*"
      capture="environment"
      name="quick_upload"
      startIcon={<FontAwesomeIcon icon={faCamera} />}
      size="small"
      onUpload={onUpload}
      {...props}
    >
      Quick Capture
    </UploadFileButton>
  );
};

export const BoxImageGallery = (props: {
  url: string;
  collaborationFolderId: string;
  boxFolderId?: string;
  pdfURL?: string;
  user: any;
  quickUploadURL: string;
}) => {
  const {url, collaborationFolderId, user, pdfURL, quickUploadURL, ...rest} = props;
  const [boxFolderId, setBoxFolderId] = React.useState(props.boxFolderId);
  const [showQRLinkDialog, setShowQRLinkDialog] = React.useState(false);
  const blockUI = useBlockUI();

  const {
    query: imageQuery,
    create: createImageFolder,
    update: updateImage,
  } = useSentinelListAPI(
    `${url}?thumbnails=True`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
      // retry 3 times as Box token will time out
      retry: 3,
    },
    {}
  );
  const gallery = imageQuery.data.results;

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={imageQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faImages} /> Images
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {boxFolderId ? (
              <>
                <PaperPanel.Header.Action>
                  <BoxCollaborationButton boxFolderId={boxFolderId} user={user} />
                </PaperPanel.Header.Action>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.BoxFilesButton
                    href={
                      isMobile
                        ? `boxapp://folder?id=${boxFolderId}`
                        : `https://novoconstruction1.app.box.com/folder/${boxFolderId}/`
                    }
                    target="_blank"
                  />
                  <Box ml={1} />
                  {quickUploadURL && isMobile && (
                    <>
                      <QuickCaptureToBoxButton
                        onUpload={(file) => {
                          blockUI.blockUI("Uploading...");
                          let formData = new FormData();
                          formData.append("file", file);
                          axiosAPI
                            .post(quickUploadURL, formData)
                            .then((response) => {
                              imageQuery.refetch().then(() => {
                                blockUI.unblockUI();
                              });
                            })
                            .catch(() => {
                              blockUI.unblockUI();
                            });
                        }}
                      />
                      <Box mr={1} />
                    </>
                  )}
                  <PaperPanel.Header.Button
                    startIcon={<FontAwesomeIcon icon={faQrcode} />}
                    onClick={() => {
                      setShowQRLinkDialog(true);
                    }}
                  >
                    Box QR Link
                  </PaperPanel.Header.Button>
                </PaperPanel.Header.Action>
              </>
            ) : (
              <PaperPanel.Header.Action>
                <PaperPanel.Header.CreateButton
                  startIcon={<CreateNewFolderIcon />}
                  onClick={() => {
                    blockUI.blockUI("Creating folder...");
                    createImageFolder.mutateAsync({collaboration_folder_id: collaborationFolderId}).then((response) => {
                      blockUI.unblockUI();
                      setBoxFolderId(response.id);
                    });
                  }}
                >
                  Create Folder
                </PaperPanel.Header.CreateButton>
              </PaperPanel.Header.Action>
            )}
            {/* <PaperPanel.Header.CreateButton onClick={() => setShowProjectDailyReportCreateVisitorDialog(true)}>
            Add Visitor
          </PaperPanel.Header.CreateButton> */}
            {boxFolderId && pdfURL && (
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.PDFButtons pdfURL={pdfURL} />
              </PaperPanel.Header.Action>
            )}

            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => imageQuery.refetch()}
                isFetching={imageQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          {!imageQuery.isFetched ? (
            <Grid container spacing={1}>
              {[...Array(4)].map((_, i) => (
                <Grid item key={i} xs={6} sm={4} md={3}>
                  <Skeleton key={i} variant="rect" width={200} height={200} />
                </Grid>
              ))}
            </Grid>
          ) : (
            <>
              {gallery.map((galleryItem) => {
                return (
                  <Box key={galleryItem.folder_name}>
                    {galleryItem.folder_name && (
                      <Box my={2}>
                        <Typography variant="h3">{galleryItem.folder_name}</Typography>
                      </Box>
                    )}
                    {galleryItem.images && (
                      <Grid container spacing={1}>
                        {galleryItem.images.map((image) => {
                          // remove the some keys from the image object for initialValues
                          // download url changes which causes the form save on load
                          // other keys are not needed to save - reduce payload size
                          const {download_url, thumbnail_base64, ...initialValues} = image;
                          return (
                            <Grid item key={image.id} xs={6} sm={4} md={3}>
                              <Box position="relative">
                                <img
                                  src={`data:image/jpeg;base64, ${image.thumbnail_base64}`}
                                  // style={{width: "auto"}}
                                  style={{height: "auto", maxWidth: "100%", display: "block"}}

                                  // onClick={() => {
                                  //   console.log("clicked", image);
                                  // }}
                                />
                                <Box mb={1} />
                                <Form
                                  initialValues={initialValues}
                                  onSubmit={(values) => {
                                    console.log("onSubmit", values);
                                  }}
                                >
                                  {({handleSubmit, form, submitting, pristine, values}) => (
                                    <form
                                      onSubmit={(e) => {
                                        e.preventDefault();
                                      }}
                                    >
                                      <AutoSaveDebounce
                                        save={(values) => {
                                          // if (isReadOnly) {
                                          //   return;
                                          // }
                                          // return updateImage.mutateAsync({...image, ...values});
                                          return updateImage.mutateAsync({...initialValues, ...values});
                                        }}
                                      >
                                        <Box position="absolute" top={2} left={4}>
                                          <CircularProgress color="secondary" size={12} />
                                        </Box>
                                      </AutoSaveDebounce>
                                      <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                          <TextFieldMui name="filename" label="Name" required />
                                        </Grid>
                                        <Grid item xs={12}>
                                          <TextFieldMui name="description" label="Description" multiline />
                                        </Grid>
                                      </Grid>
                                      <Typography2 type="metadata">
                                        {moment(image.content_created_at).format("LLLL")}
                                      </Typography2>
                                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                                    </form>
                                  )}
                                </Form>
                              </Box>
                            </Grid>
                          );
                        })}
                      </Grid>
                    )}
                  </Box>
                );
              })}

              {/* <h1>Props</h1>
            <pre>{JSON.stringify(props, null, 2)}</pre>
            <h1>Image Query</h1>
            <pre>{JSON.stringify(imageQuery, null, 2)}</pre> */}
            </>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <Dialog open={showQRLinkDialog} onClose={() => setShowQRLinkDialog(false)} maxWidth="xs">
        <DialogTitle id="form-dialog-title">Box App Link</DialogTitle>
        <DialogContent>
          <center>
            <img
              src={`https://sentinel.novoconstruction.com/qrcode/?border=0&data=boxapp://folder?id=${boxFolderId}`}
              alt="QR Code"
              width="95%"
            />
          </center>
        </DialogContent>
        <DialogActions>
          <FormActions.CancelButton onClick={() => setShowQRLinkDialog(false)} />
        </DialogActions>
      </Dialog>
    </>
  );
};

export const BoxIFrame = React.forwardRef(
  (props: {sharedFolderId: string; height?: number; [rest: string]: any}, ref: any) => {
    const {sharedFolderId, height = 400, ...rest} = props;
    return (
      <iframe
        ref={ref}
        // key={iframeKey}
        src={`https://novoconstruction1.app.box.com/embed/s/${sharedFolderId}?view=list&sortColumn=name`}
        width="100%"
        height={height}
        frameBorder={0}
        {...rest}
      />
    );
  }
);

export const BoxIframeAndDropzone = (props: {
  sharedFolderId: string;
  uploadURL?: string;
  accept?: string;
  timeout?: number;
}) => {
  const boxIframeRef = React.useRef(null);
  const {sharedFolderId, uploadURL = `.`, accept = "*", timeout = 1000 * 60 * 5, ...rest} = props;
  return (
    <>
      <BoxIFrame ref={boxIframeRef} sharedFolderId={sharedFolderId} {...rest} />
      <DropzoneMui
        getUploadParams={() => {
          return {
            url: uploadURL,
            headers: {"X-CSRFToken": Cookies.get("csrftoken")},
          };
        }}
        timeout={timeout}
        accept={accept}
        onChangeStatus={(event) => {
          if (event.meta.status === "done") {
            boxIframeRef.current.src = boxIframeRef.current.src;
          }
        }}
      />
    </>
  );
};

export const FixBoxFolderNamesConfirmationDialog = (props: {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  rpc: {
    mutateAsync: (params: {action: string}) => Promise<any>;
  };
}) => {
  const {isOpen, setIsOpen, rpc} = props;
  const blockUI = useBlockUI();
  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onApprove={() => {
        setIsOpen(false);
        blockUI.blockUI("Updating Box folder names...");
        rpc
          .mutateAsync({
            action: "update-box-folder-names",
          })
          .then(() => {
            blockUI.unblockUI();
          });
      }}
      onDeny={() => setIsOpen(false)}
    >
      You want to update the Box folder names for all items with Box folders? This rename them to their default naming
      convention. This will run in the background and may take some time.
    </ConfirmationDialog>
  );
};

export const MoveToCorrectBoxFoldersConfirmationDialog = (props: {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  rpc: {
    mutateAsync: (params: {action: string}) => Promise<any>;
  };
  children?: React.ReactNode;
}) => {
  const {isOpen, setIsOpen, rpc, children} = props;
  const blockUI = useBlockUI();
  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onApprove={() => {
        setIsOpen(false);
        blockUI.blockUI("Updating Box folder locations...");
        rpc
          .mutateAsync({
            action: "move-to-correct-box-folders",
          })
          .then(() => {
            blockUI.unblockUI();
          });
      }}
      onDeny={() => setIsOpen(false)}
    >
      {children ? (
        children
      ) : (
        <>
          You want to update the Box folder locations for all items with Box folders? This will run in the background
          and may take some time.
        </>
      )}
    </ConfirmationDialog>
  );
};

export const CreateMissingBoxFoldersConfirmationDialog = (props: {
  isOpen: boolean;
  setIsOpen: (boolean) => void;
  rpc: {
    mutateAsync: (params: {action: string}) => Promise<any>;
  };
}) => {
  const {isOpen, setIsOpen, rpc} = props;
  const blockUI = useBlockUI();
  return (
    <ConfirmationDialog
      isOpen={isOpen}
      onApprove={() => {
        setIsOpen(false);
        blockUI.blockUI("Creating missing box folders...");
        rpc
          .mutateAsync({
            action: "create-missing-box-folders",
          })
          .then(() => {
            blockUI.unblockUI();
          });
      }}
      onDeny={() => setIsOpen(false)}
    >
      You want to create missing Box folders? This will run in the background and may take some time.
    </ConfirmationDialog>
  );
};
