import {faFileExcel, faGavel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Currency} from "../../../components/Accounting";
import {StatusIcon} from "../../../components/Icons";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";

const Subcontracts = (props: {
  subcontracts: Array<{
    to_company: {
      name: string;
    };
    display: string;
    status: string;
    original_value: number;
    current_value: number;
  }>;
  project: {display: string};
  budget: {display: string};
  isFetching?: Boolean;
}) => {
  const {subcontracts, project, budget, isFetching} = props;
  const aliveSubcontracts = subcontracts.filter((subcontract) => !["void"].includes(subcontract.status));
  const novoClasses = makeNovoClasses();

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faGavel} /> Subcontracts
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={isFetching}
                onClick={(event) => {
                  saveExcel(
                    subcontracts.map((subcontract) => {
                      return {
                        Contract: subcontract?.display,
                        "To Company": subcontract.to_company.name,
                        "Original Value": {v: subcontract.original_value, t: "n", z: "$#,##0.00"},
                        "Current Value": {v: subcontract.current_value, t: "n", z: "$#,##0.00"},
                      };
                    }),
                    `${project.display} - ${budget.display} - Subcontract Items`,
                    [{wch: 60}, {wch: 60}, {wch: 15}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            showFooter={Boolean(subcontracts.length)}
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["original_value", "current_value"]}
            columns={[
              {
                Header: "Contract",
                id: "contract",
                accessor: (value) => {
                  return `${value.status_sort}-${value.display}`;
                },
                Cell: ({value, row}) => (
                  <>
                    {value && (
                      <>
                        <StatusIcon status={row.original.status} showTooltip />{" "}
                        <Link href={row.original?.url}>{value}</Link>
                      </>
                    )}
                  </>
                ),
              },
              {
                Header: "To Company",
                accessor: "to_company.name",
              },
              {
                Header: "Original Value",
                accessor: "original_value",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => aliveSubcontracts.reduce((sum, row) => row.original_value + sum, 0),
                    [aliveSubcontracts]
                  );

                  return <Currency number={total} />;
                },
              },
              {
                Header: "Current Value",
                accessor: "current_value",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => aliveSubcontracts.reduce((sum, row) => row.current_value + sum, 0),
                    [aliveSubcontracts]
                  );
                  return <Currency number={total} />;
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "contract",
                },
              ],
            }}
            data={subcontracts}
          />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default Subcontracts;
