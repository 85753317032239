import {Alert} from "@material-ui/lab";

export const FrozenProjectAlert = (props) => {
  const {frozenDate, ...rest} = props;
  return (
    <Alert severity="info" {...rest}>
      <strong>Frozen Project</strong>: This project is closed, JTD data is as of {frozenDate}.
    </Alert>
  );
};
