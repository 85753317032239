import {faComments, faExchange, faNoteSticky, faStamp} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Link,
  Switch,
  Typography,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LaunchIcon from "@material-ui/icons/Launch";
import {Alert, AlertTitle} from "@material-ui/lab";
import {groupBy} from "lodash";
import moment from "moment-timezone";
import qs from "query-string";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import BeforeUnload from "../../../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../../../js/components/FinalFormEnhancers";
import HtmlRender from "../../../../../../js/components/HtmlRender";
import {BoxIframeAndDropzone} from "../../../../../components/Box";
import ExternalInfoCard from "../../../../../components/ExternalInfoCard";
import {TinyMCEMui} from "../../../../../components/forms/Fields";
import FormActions from "../../../../../components/forms/FormActions";
import BlockUI from "../../../../../components/GlobalLoaders";
import {LabelValue} from "../../../../../components/LabelValue";
import {LegacyUILink} from "../../../../../components/Links";
import {PageHeader} from "../../../../../components/PageHeader";
import {PaperItem} from "../../../../../components/PaperItem";
import PaperPanel from "../../../../../components/PaperPanel";
import {StatusLabel} from "../../../../../components/Status";
import Typography2 from "../../../../../components/Typography2";
import useBlockUI from "../../../../../hooks/useBlockUI";
import {
  useFetchCurrentPage,
  usePostCurrentPage,
  useSentinelDetailAPI,
  useSentinelListAPI,
} from "../../../../../hooks/useSentinelAPI";
import {colorError2} from "../../../../../theme/colors";
import {
  SubmittalItemReviewStatusSelect,
  SubmittalReviewCompanyStampSelect,
} from "../../../../projects/Submittals/SubmittalItemReviewForms";
import {ContactChip} from "../../../components/Chips";

const DATE_FORMAT = "ddd, MMM DD, YYYY";

const ExternalSubmittalPackageDetail = (props) => {
  const {contact, project, user, ...rest} = props;
  const params = useParams();
  const blockUI = useBlockUI();
  const {contactUUID, projectUUID, submittalPackageUUID} = params;
  const [editResponseDialogIsOpen, setEditResponseDialogIsOpen] = React.useState(false);
  const [activeReview, setActiveReview] = React.useState({} as any);
  const qsValues = qs.parse(location.search);

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      initialData: {
        isFollowing: false,
      },
    },
    {
      token: qsValues.token,
    }
  );
  const pageData = pageDataQuery.data;

  const postCurrentPage = usePostCurrentPage({}, {baseURL: ""}, {token: qsValues.token});

  const {query: submittalPackageQuery, rpc: rfiRPC} = useSentinelDetailAPI(
    `external/${contactUUID}/projects/${projectUUID}/submittals/packages/${submittalPackageUUID}/`,
    {
      initialData: {
        // drawings: [],
        // tags: [],
        // spec_section: "",
        // responsible_party: null,
        // authored_by: null,
        // answered_by: null,
      },
    }
  );
  const submittalPackage = submittalPackageQuery.data;

  const {query: submittalItemQuery} = useSentinelDetailAPI(
    `external/${contactUUID}/projects/${projectUUID}/submittals/packages/${submittalPackageUUID}/items/`,
    {
      initialData: {results: []},
    }
  );
  const submittalItems = submittalItemQuery.data.results;

  const {query: submittalItemReviewQuery, update: updateSubmittalItemReview} = useSentinelListAPI(
    `external/${contactUUID}/projects/${projectUUID}/submittals/packages/${submittalPackageUUID}/reviews/`,
    {
      initialData: {results: []},
    }
  );
  const submittalItemReviews = submittalItemReviewQuery.data.results;

  const groupReviews = (itemId) => {
    const sortedReviews = groupBy(submittalItemReviews, "submittal_item_id");
    return sortedReviews[itemId] || [];
  };

  const {query: submittalPackageContactsQuery} = useSentinelListAPI(
    `external/${contactUUID}/projects/${projectUUID}/submittals/packages/${submittalPackageUUID}/contacts/`,
    {
      initialData: {
        results: [],
      },
    }
  );
  const submittalPackageContacts = submittalPackageContactsQuery.data.results;

  const approverContacts = submittalPackageContacts
    .filter((item) => item.role === "approver")
    .map((item) => item.contact);
  const ccContacts = submittalPackageContacts.filter((item) => item.role === "cc").map((item) => item.contact);

  if (
    !submittalPackageQuery.isFetchedAfterMount ||
    !pageDataQuery.isFetchedAfterMount ||
    !submittalPackageContactsQuery.isFetchedAfterMount ||
    !submittalItemReviewQuery.isFetchedAfterMount
  ) {
    return <BlockUI show={true} message="Fetching Submittal Package info..." />;
  }

  // console.log(contact);

  return (
    <>
      <Helmet title={`${project.name} - ${submittalPackage.display}`} />
      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>{project.display}</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      {contact.has_portal_user && (
        <Box my={1}>
          <Alert severity="info">
            <AlertTitle>
              {contact.first_name}, it looks like you are signed up to use the NOVO Construction portal.
            </AlertTitle>
            <Link href={submittalPackage.portal_url} underline="always" target="_blank">
              Click here to sign in to the portal
            </Link>
            . This will give you access to all of your projects with NOVO Construction.
          </Alert>
        </Box>
      )}

      {pageDataQuery.data.currentRevisionURL && (
        <Box mb={2}>
          <Alert severity="warning">
            This is a prior revision of this submittal package.{" "}
            <Link href={pageData.currentRevisionURL} underline="always">
              Click here
            </Link>{" "}
            to go to the current revision.
          </Alert>
        </Box>
      )}
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faComments} /> {submittalPackage.display}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Switch
                checked={pageData.isFollowing}
                onChange={() =>
                  postCurrentPage.mutateAsync({action: "toggle_following"}).then((values) => {
                    pageDataQuery.refetch();
                  })
                }
              />{" "}
              Following
              {project.can_view_external_reports && (
                <>
                  <Box ml={1} />
                  <PaperPanel.Header.PDFButton
                    href={`/reports2/external/${contactUUID}/projects/${projectUUID}/submittals/packages/${submittalPackageUUID}/?display_reviewers=on&display_reviewer_notes=on`}
                    target="_blank"
                  />
                </>
              )}
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RevisionsButtons
                url={`/external/${contactUUID}/projects/${projectUUID}/submittals/packages/${submittalPackageUUID}/revisions/`}
                obj={submittalPackage}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => submittalPackageQuery.refetch()}
                isFetching={submittalPackageQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Body p={1}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <LabelValue
                label="Approvers:"
                value={approverContacts.map((contact_) => {
                  return (
                    <ContactChip
                      contact={contact_}
                      active={contact.email === contact_.email}
                      displayAvatar={true}
                      key={contact_.email}
                    />
                  );
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <LabelValue
                label="CC:"
                value={ccContacts.map((contact_) => {
                  return (
                    <ContactChip
                      contact={contact_}
                      active={contact.email === contact_.email}
                      displayAvatar={true}
                      key={contact_.email}
                    />
                  );
                })}
              />
            </Grid>
            <Box mb={2} />
            <Grid item xs={12} sm={4}>
              <LabelValue label="Author Company:">{submittalPackage.author_company.name}</LabelValue>
              <LabelValue label="Author:" value={submittalPackage.author_contact.full_name} />

              <LabelValue label="Author Package #:" value={submittalPackage?.author_package_number} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <LabelValue label="Spec Section:" value={submittalPackage.spec_section_code} />
              <LabelValue label="Status:" value={submittalPackage.status_display} />
            </Grid>
            <Grid item xs={12} sm={4}>
              <LabelValue
                label="Date Submitted:"
                value={
                  submittalPackage?.submitted_date ? moment(submittalPackage.submitted_date).format(DATE_FORMAT) : ""
                }
              />
              <LabelValue
                label="Date Due:"
                value={submittalPackage.due_date ? moment(submittalPackage.due_date).format(DATE_FORMAT) : "None"}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {submittalPackage.custom_data?.sharepoint_link && (
                <LabelValue label="Sharepoint Link:">
                  <Link href={submittalPackage.custom_data?.sharepoint_link}>
                    {submittalPackage.custom_data?.sharepoint_link}
                  </Link>
                </LabelValue>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              {submittalPackage.custom_data?.fusion_live_link && (
                <LabelValue label="Fusion Live Link:">
                  <Link href={submittalPackage.custom_data?.fusion_live_link}>
                    {submittalPackage.custom_data?.fusion_live_link}
                  </Link>
                </LabelValue>
              )}
            </Grid>
          </Grid>
          {submittalPackage?.general_notes && (
            <Box mt={1}>
              <ExternalInfoCard title="General Notes" icon={<FontAwesomeIcon icon={faNoteSticky} />}>
                <HtmlRender html={submittalPackage?.general_notes} />
              </ExternalInfoCard>
            </Box>
          )}
          {submittalPackage?.reviewer_notes && (
            <Box mt={1}>
              <ExternalInfoCard title="Reviewer Notes" icon={<FontAwesomeIcon icon={faNoteSticky} />}>
                <HtmlRender html={submittalPackage?.reviewer_notes} />
              </ExternalInfoCard>
            </Box>
          )}
          <Box mt={2} />
          <PaperPanel>
            <PaperPanel.Header>
              <PaperPanel.Header.Title maxWidth={"100%"}>
                <FontAwesomeIcon icon={faExchange} /> Submittal Items Requiring Approval
              </PaperPanel.Header.Title>
            </PaperPanel.Header>
            <PaperPanel.Body>
              {submittalItemQuery.data.count > 0 ? (
                submittalItems.map((item) => {
                  const reviews = groupReviews(item.id);

                  return (
                    <React.Fragment key={item.id}>
                      <PaperItem.Header>
                        <PaperItem.Body>
                          <Grid container spacing={1}>
                            <Grid item xs={12} sm={3}>
                              {item.display}
                            </Grid>
                          </Grid>
                        </PaperItem.Body>
                        <PaperItem.Right>
                          <PaperItem.RightItemNumber number={item.number_display} />
                        </PaperItem.Right>
                      </PaperItem.Header>
                      {reviews &&
                        reviews.map((review) => {
                          const hasCustomStamp = review.company_submittal_stamp !== null;
                          return review.permissions.can_update ? (
                            <ExternalReviewPaperItem
                              review={review}
                              key={review.id}
                              hasCustomStamp={hasCustomStamp}
                              onEdit={() => {
                                setActiveReview(review);
                                setEditResponseDialogIsOpen(true);
                              }}
                              style={{cursor: "pointer"}}
                            />
                          ) : (
                            <ExternalReviewPaperItem
                              review={review}
                              key={review.id}
                              hasCustomStamp={hasCustomStamp}
                              noHover
                            />
                          );
                        })}
                    </React.Fragment>
                  );
                })
              ) : (
                <Box my={2}>
                  <Typography variant="h3">No submittal items found.</Typography>
                </Box>
              )}
            </PaperPanel.Body>
          </PaperPanel>

          {/* <pre>{JSON.stringify(submittalPackage, null, 2)}</pre>
          <h1>Items</h1>
          <pre>{JSON.stringify(submittalItems, null, 2)}</pre>
          <h1>Item Reviews</h1>
          <pre>{JSON.stringify(submittalItemReviews, null, 2)}</pre>
          <h1>Project</h1>
          <pre>{JSON.stringify(project, null, 2)}</pre>
          <h1>Approvers</h1>
          <pre>{JSON.stringify(approverContacts, null, 2)}</pre>
          <h1>CC</h1>
          <pre>{JSON.stringify(ccContacts, null, 2)}</pre>
          <h1>Page Data</h1>
          <pre>{JSON.stringify(pageData, null, 2)}</pre>
          <pre>{JSON.stringify(params, null, 2)}</pre>
          <pre>{JSON.stringify(props, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>

      {pageData.boxSharedFolderId && (
        <Box my={1}>
          <PaperPanel>
            <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
              <PaperPanel.Header.Title>Files</PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                {user.is_superuser && (
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      href={pageData.boxUploadURL}
                      target="_blank"
                      endIcon={<LaunchIcon />}
                      // variant="text"
                    >
                      Box Upload URL (super user debug)
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                )}

                {pageData.boxSharedFolderLink && (
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      href={pageData.boxSharedFolderLink}
                      target="_blank"
                      endIcon={<LaunchIcon />}
                      // variant="text"
                    >
                      Box Site
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                )}
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    onClick={() => pageDataQuery.refetch()}
                    isFetching={pageDataQuery.isFetching}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <BoxIframeAndDropzone sharedFolderId={pageData.boxSharedFolderId} uploadURL={pageData.boxUploadURL} />
            </PaperPanel.Body>
          </PaperPanel>
        </Box>
      )}

      <LegacyUILink href={pageData.legacyURL} my={2} />

      {pageDataQuery.data.currentRevisionURL ? (
        <div>
          <Dialog
            open={editResponseDialogIsOpen}
            // onClose={handleClose}
            onClose={() => setEditResponseDialogIsOpen(false)}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">This is a Prior Revision</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                This is a prior revision of this submittal package.{" "}
                <Link href={pageData.currentRevisionURL} underline="always">
                  Click here
                </Link>{" "}
                to go to the current revision.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setEditResponseDialogIsOpen(false)} color="primary" variant="contained">
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      ) : (
        <SubmittalItemReviewUpdateDialogForm
          onSubmit={(values) => {
            blockUI.blockUI("Saving Response...");
            updateSubmittalItemReview
              .mutateAsync(values)
              .then(() => {
                setEditResponseDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .catch(() => {
                blockUI.unblockUI();
              });
          }}
          isOpen={editResponseDialogIsOpen}
          handleClose={() => setEditResponseDialogIsOpen(false)}
          review={activeReview}
        />
      )}
    </>
  );
};

export default ExternalSubmittalPackageDetail;

const ExternalReviewPaperItem = (props) => {
  const {review, hasCustomStamp, onEdit, ...rest} = props;
  const responseRequired = review.permissions.can_update && review.status === "pending";

  return (
    <PaperItem onClick={onEdit} leftHighlight={responseRequired} {...rest}>
      <PaperItem.Left minWidth={80}>
        <StatusLabel status={review.status_display} hint="submittalPackage" />
      </PaperItem.Left>

      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={2}>
            {review.contact.full_name}{" "}
            <Typography2 type="metadata" component="div">
              {review.contact.company_name}
            </Typography2>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            {hasCustomStamp && review.company_submittal_stamp_choice && (
              <>
                <FontAwesomeIcon icon={faStamp} />{" "}
                <Typography component="span">{review.company_submittal_stamp_choice?.description}</Typography>
                <Typography2 component="div" type="metadata">
                  {review.company_submittal_stamp_choice?.additional_description}
                </Typography2>
              </>
            )}
          </Grid>
          <Grid item xs={12} md={7}>
            {responseRequired && <span style={{color: colorError2}}>Awaiting Your Response</span>}
            {review?.reviewer_notes && <HtmlRender html={review.reviewer_notes} />}
          </Grid>
        </Grid>
      </PaperItem.Body>

      <PaperItem.Right minWidth={30}></PaperItem.Right>
      {onEdit && (
        <PaperItem.RightHover>
          <PaperItem.RightHover.IconButton icon={EditIcon} title="Edit Response" />
        </PaperItem.RightHover>
      )}
    </PaperItem>
  );
};

export const SubmittalItemReviewUpdateDialogForm = (props: {
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;

  review: any;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isReadOnly, setActiveReview, projectId, review, ...rest} = props;

  // const formRef: React.MutableRefObject<FormApi> = React.useRef(null);
  const hasCustomStamp = review.company_submittal_stamp !== null;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth scroll="paper">
        <FinalForm onSubmit={onSubmit} initialValues={review} {...rest}>
          {(props) => {
            const {handleSubmit, form, submitting, pristine = true, values} = props;

            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="update-form">
                  {/* <Grid container justifyContent="space-between"> */}
                  <Grid item>Update {values.item_display}</Grid>
                  {/* </Grid> */}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {hasCustomStamp ? (
                          <SubmittalReviewCompanyStampSelect
                            name="company_submittal_stamp_choice.id"
                            label="Company Stamp"
                            companyId={review.company_submittal_stamp?.company}
                            baseURL={`companies/${review.company_submittal_stamp?.company}/submittals/stamp/external/`}
                          />
                        ) : (
                          <SubmittalItemReviewStatusSelect name="status" label="Status" />
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <TinyMCEMui name="reviewer_notes" label="Reviewer Notes" />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton
                    label="Save"
                    disabled={pristine || isReadOnly}
                    //   onClick={() => form.change("submitMode", "saveAndClose")}
                  />

                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
