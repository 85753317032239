import classnames from "classnames";
import styled from "styled-components";

export const theme = {
  smartPanelBorderColor: "#CCC",
  smartPanelColor: "#333",
  smartPanelBodyPadding: "13px",
  metadataColor: "#999999",
  smartPanelFooterBackground: "#F8F7F7",
  smartPanelFooterBoarderColor: "#E4E4E4",
};

export const Metadata = styled.div`
  font-size: 11px;
  line-height: 17px;
  color: ${(props) => props.theme.metadataColor};
`;

export const PageTitle = styled.h1.attrs((props) => ({
  className: classnames("page-title", {truncate: props.truncate}, props.className),
}))``;
