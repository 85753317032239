import {makeStyles} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import {colorError} from "../theme/colors";

export const Currency = (props: {
  number?: number;
  symbol?: string;
  precision?: number;
  color?: string;
  className?: string;
}) => {
  const {number, symbol = "$", precision = 2, color, className, ...rest} = props;

  if (number === undefined) return null;
  return (
    <span
      className={classnames(className)}
      style={{color: color ? color : number > -0.000001 ? "inherit" : colorError}}
      {...rest}
    >
      {accounting.formatMoney(number, symbol, precision)}
    </span>
  );
};

export const getPercentage = (number?: number, precision?: number) => {
  return accounting.formatNumber(number && number !== Infinity ? number * 100 : 0, precision);
};

export const Percentage = (props: {
  number?: number;
  precision?: number;
  suffix?: string;
  color?: string;
  className?: string;
}) => {
  const {number, precision = 0, suffix = "%", color, className, ...rest} = props;
  const percentage = getPercentage(number, precision);
  const useStyles = makeStyles((theme) => {
    return {
      root: {
        // color: color ? color : number >= 0 ? theme.palette.primary.main : theme.palette.error.dark,
        // https://material-ui.com/customization/color/#color-palette
        color: color ? color : percentage > -0.000001 ? "inherit" : colorError,
      },
    };
  });
  const styles = useStyles();
  if (number === undefined) return null;
  return (
    <span className={classnames(styles.root, className)} {...rest}>
      {percentage}
      {suffix}
    </span>
  );
};

export const Number = (props: {number?: number; precision?: number; className?: string}) => {
  const {number, precision = 0, className, ...rest} = props;
  if (number === undefined) return null;
  return (
    <span className={classnames(className)} {...rest}>
      {accounting.formatNumber(number, precision)}
    </span>
  );
};
