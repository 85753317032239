import ProjectMeetingDetail from "./ProjectMeetingDetail";

const MinutesDetail = (props) => {
  return (
    <>
      <ProjectMeetingDetail {...props} isAgenda={false} />
    </>
  );
};

export default MinutesDetail;
