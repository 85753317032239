import {faList} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Grid, Link, Typography} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import pluralize from "pluralize";
import {Helmet} from "react-helmet";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import Typography2 from "../../../components/Typography2";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";

const PrimeContractSelect = (props) => {
  const {project, ...rest} = props;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      contracts: [],
      counts: [],
    },
  });

  const pageData = pageDataQuery.data;

  const contracts = pageData.contracts;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Prime Contracts..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Select Prime Contract`} />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/change-order-wizard/`}>
          Change Order Wizard
        </MuiNavLink>
        <Typography color="inherit">OCO</Typography>
        <Typography color="textPrimary">Select Prime Contract</Typography>
      </Breadcrumbs>
      <PageHeader>
        <PageHeader.Title>Select Prime Contract</PageHeader.Title>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Prime Contracts</PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {contracts.length > 0 ? (
            contracts.map((contract) => {
              return (
                <ContractPaperItem
                  contract={contract}
                  counts={pageData.counts}
                  projectId={project.id}
                  key={contract.id}
                />
              );
            })
          ) : (
            <Alert severity="warning">
              <AlertTitle>No Prime Contracts Found</AlertTitle>
              This project needs a{" "}
              <MuiNavLink href={`/v2/projects/${project.id}/contracts/#tab=primeContracts`} underline="always">
                prime contract
              </MuiNavLink>{" "}
              before you can issue an owner change order.
            </Alert>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <Box mt={1}>
        <LegacyUILink href={`/ccos/pcco/${project.id}/select-contract/`} />
      </Box>
      {/* <h3>PageData</h3>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default PrimeContractSelect;

const ContractPaperItem = (props) => {
  const {contract, counts, projectId, ...rest} = props;

  return (
    <PaperItem alignItems="center">
      <PaperItem.Left minWidth={80}>
        <Button
          color="default"
          variant="contained"
          size="small"
          href={`/v2/projects/${projectId}/change-order-wizard/pcco/${contract.id}/create/`}
        >
          Select
        </Button>
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Link href={contract.url} target="_blank">
              {contract.display}
            </Link>
          </Grid>
          <Grid item xs={12} sm={2}>
            <FontAwesomeIcon icon={faList} />
            <MuiNavLink href={`${contract.url}#tab=items`} underline="always">
              {contract.item_count} {pluralize("Schedule", contract.item_count)} of Value
            </MuiNavLink>
          </Grid>

          <Grid item xs={6} sm={2}>
            <Typography2 type="metadata">
              Original Value:{" "}
              <MuiNavLink href={`${contract.url}#tab=items`} underline="always">
                <Currency number={contract.original_value} />
              </MuiNavLink>
            </Typography2>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography2 type="metadata">
              Pending Revs:{" "}
              <MuiNavLink
                href={`/v2/projects/${projectId}/pcos/items/?primecontract=${contract.id}&is_approved=False#`}
                underline="always"
              >
                <Currency number={contract.pending_revs} />
              </MuiNavLink>
            </Typography2>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography2 type="metadata">
              Approved Revs:{" "}
              <MuiNavLink
                href={`/v2/projects/${projectId}/pcos/items/?primecontract=${contract.id}&is_approved=True#`}
                underline="always"
              >
                <Currency number={contract.approved_revs} />
              </MuiNavLink>
            </Typography2>
          </Grid>
          <Grid item xs={6} sm={2}>
            <Typography2 type="metadata">
              Current Value: <Currency number={contract.current_value} />
            </Typography2>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right>
        <PaperItem.RightItemNumber number={contract.number_display} />
      </PaperItem.Right>
    </PaperItem>
  );
};
