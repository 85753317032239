import {faQrcode} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Grid, Link, Typography, makeStyles} from "@material-ui/core";
import arrayMutators from "final-form-arrays";
import {isEmpty, merge} from "lodash";
import moment from "moment";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {CreateButton} from "../../../components/Buttons";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {LabelValue} from "../../../components/LabelValue";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import FormActions from "../../../components/forms/FormActions";
import useBlockUI from "../../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../../hooks/useSentinelAPI";
import {colorError, colorMutedText, colorSuccess} from "../../../theme/colors";
import validateFormOnRender from "../../../utils/validateFormOnRender";
import {Alert} from "@material-ui/lab";

// const validateFormOnLoad = (form, errors) => {
//   React.useEffect(() => {
//     if (!isEmpty(errors) && !form.isValidationPaused()) {
//       form.submit();
//     }
//   }, [isEmpty(errors), form.isValidationPaused()]);
// };

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    project: {
      fontSize: 16,
      color: colorMutedText,
      [theme.breakpoints.up("md")]: {
        fontSize: 18,
      },
    },
  };
});

export const AddButton = (props) => {
  const {children = "Add", ...rest} = props;
  return (
    <CreateButton fullWidth variant="outlined" disableElevation {...rest}>
      {children}
    </CreateButton>
  );
};

export const EquipmentFailureCounts = (props: {
  equipmentCount: number;
  failureCount: number;
  formType: "forklift_inspection" | "scissor_boom_lift_inspection";
}) => {
  const {equipmentCount, failureCount, formType, ...rest} = props;
  const equipmentLabel =
    formType === "forklift_inspection"
      ? "Forklifts"
      : formType === "scissor_boom_lift_inspection"
      ? "Scissor/Boom Lifts"
      : "Equipment";

  return (
    <>
      {equipmentCount > 0 && failureCount > 0 ? (
        <Box color={colorError}>
          {failureCount} of {equipmentCount} {equipmentLabel} Failed Inspection
        </Box>
      ) : (
        <Box color={colorSuccess}>All Equipment Passing</Box>
      )}
    </>
  );
};

const JobsiteSafetyForm = (props: {
  children: any;
  title: string;
  onSuccess: (response) => void;
  hideButtons?: boolean;

  [rest: string]: any;
}) => {
  const {
    children,
    title = "NOVO Jobsite Form",
    safetyForm,
    onSuccess,
    superintendent,
    internalDetailView,
    initialValues,

    ...rest
  } = props;
  const project = safetyForm.project;
  const twilioContact = safetyForm.twilio_contact;
  const blockUI = useBlockUI();
  const postCurrentPage = usePostCurrentPage();
  const isSpanish = twilioContact?.language === "SP";

  const formAnswers =
    internalDetailView && Object.keys(safetyForm.translated_answers).length > 0
      ? {...safetyForm.translated_answers}
      : {...safetyForm.answers};

  const onSubmit = (values) => {
    setFormValues(values);
    setLockFormConfirmation(true);
  };

  const submitAndFinalize = (values) => {
    blockUI.blockUI(isSpanish ? "Guardando..." : "Saving...");
    setSuppressBeforeUnload(true);
    postCurrentPage.mutateAsync({answers: values, action: "mark_complete"}).then((response) => {
      blockUI.unblockUI();
      if (onSuccess) {
        onSuccess(response);
      } else {
        if (twilioContact && safetyForm?.has_twilio_checkin) {
          window.location.href = `/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${safetyForm.date}/`;
        } else {
          window.location.reload();
        }
      }
    });
  };
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [lockFormConfirmation, setLockFormConfirmation] = React.useState(false);
  const [formValues, setFormValues] = React.useState(undefined as FormData);
  const [suppressBeforeUnload, setSuppressBeforeUnload] = React.useState(internalDetailView);
  const classes = useStyles();
  const failingEquipment = safetyForm.answers?.failures
    ? new Set(Object.values(safetyForm.answers.failures).flat())
    : new Set();
  return (
    <>
      <Helmet title={`${safetyForm.project.display} - ${safetyForm.form_type_display}`} />
      <Box my={1}>
        {!twilioContact.fuzzy_company && (
          <Alert severity="info">
            {isSpanish ? (
              <>
                No se pudo identificar su empresa. Por favor{" "}
                <Link underline="always" href={`/twilio-contact/${twilioContact.uuid}/`}>
                  haga clic aquí
                </Link>{" "}
                para actualizar su información.
              </>
            ) : (
              <>
                Your company could not be identified. Please{" "}
                <Link underline="always" href={`/twilio-contact/${twilioContact.uuid}/`}>
                  click here
                </Link>{" "}
                to update your information.
              </>
            )}
          </Alert>
        )}
        <PageHeader mt={2}>
          <Box display="flex" flexGrow={1}>
            <Box display="flex" flexDirection="column">
              <PageHeader.Title>{title}</PageHeader.Title>
              <Box>
                <Typography variant="h3" className={classes.project}>
                  {safetyForm.project.display}
                </Typography>
              </Box>
            </Box>
          </Box>
          <PageHeader.Right flexDirection="column" alignItems={{xs: "center", sm: "flex-end"}}>
            {internalDetailView ? (
              safetyForm?.twilio_contact?.name ? (
                <>
                  <Box>
                    Completed By: {safetyForm?.twilio_contact?.name} - {safetyForm?.twilio_contact?.company}
                  </Box>
                  {safetyForm.answers?.equipment && (
                    <Box>
                      <EquipmentFailureCounts
                        equipmentCount={safetyForm.answers.equipment.length}
                        failureCount={failingEquipment.size}
                        formType={safetyForm.form_type}
                      />
                    </Box>
                  )}
                </>
              ) : (
                safetyForm?.responsible_company && <Box>Completed By: {safetyForm?.responsible_company?.name}</Box>
              )
            ) : (
              <StatusLabel status={safetyForm.status_with_expiration_display} hint="projectSafetyForm" />
            )}
          </PageHeader.Right>
        </PageHeader>
        <FinalForm
          onSubmit={onSubmit}
          mutators={{
            ...arrayMutators,
          }}
          initialValues={merge({...initialValues, ...formAnswers})}
          {...rest}
        >
          {(props) => {
            const {handleSubmit, form, submitting, pristine, values, invalid, errors} = props;

            validateFormOnRender(form, errors, internalDetailView && !safetyForm.is_closed);

            return (
              <>
                <BeforeUnload block={!pristine && !internalDetailView && !suppressBeforeUnload} />
                <form onSubmit={handleSubmit} autoComplete="off" noValidate={true}>
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Box pb={internalDetailView ? 0 : 18}>
                      <Box my={2}>
                        {["revise_resubmit"].includes(safetyForm.status) && safetyForm.approver_comments && (
                          <LabelValue
                            label={
                              isSpanish
                                ? "Revisar y volver a enviar: comentarios del aprobador:"
                                : "Revise and Resubmit - Approver Comments:"
                            }
                            value={safetyForm.approver_comments}
                          />
                        )}
                        {["failed"].includes(safetyForm.status) && safetyForm.approver_comments && (
                          <LabelValue
                            label={isSpanish ? "Error: Comentarios del Revisor" : "Failed - Reviewer Comments:"}
                            value={safetyForm.approver_comments}
                          />
                        )}
                      </Box>
                      {children(form, values)}
                      {safetyForm.status === "approved" && (
                        <Box mt={2}>
                          <PaperPanel>
                            <PaperPanel.Header>
                              <PaperPanel.Header.Title>Approval</PaperPanel.Header.Title>
                            </PaperPanel.Header>
                            <PaperPanel.Body px={1}>
                              <Grid container spacing={1}>
                                <>
                                  <Grid item xs={6}>
                                    <LabelValue
                                      label={isSpanish && !internalDetailView ? "Aprobado Por" : "Approved By"}
                                      value={safetyForm.approved_by?.full_name}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <LabelValue
                                      label={isSpanish && !internalDetailView ? "Aprobado En" : "Approved On"}
                                      value={moment(safetyForm.approved_datetime).format("LLLL")}
                                    />
                                  </Grid>
                                  <Grid item xs={6}></Grid>
                                  <Grid item xs={6}>
                                    {safetyForm?.expiration_date && (
                                      <LabelValue label="Expiration" value={safetyForm.expiration_date} />
                                    )}
                                  </Grid>

                                  {safetyForm.approver_comments && (
                                    <Grid item xs={12}>
                                      <LabelValue
                                        label={isSpanish && !internalDetailView ? "Comentarios" : "Comments"}
                                        value={safetyForm.approver_comments}
                                      />
                                    </Grid>
                                  )}
                                </>
                              </Grid>
                            </PaperPanel.Body>
                          </PaperPanel>
                        </Box>
                      )}
                      {!internalDetailView && (
                        <>
                          {!safetyForm.is_closed && (
                            <>
                              <Box mt={3} />
                              <FormActions.Button
                                fullWidth
                                label={isSpanish ? `Guardar Progreso y Cerrar` : `Save Progress and Close`}
                                disabled={submitting || pristine}
                                onClick={(event) => {
                                  setSuppressBeforeUnload(true);
                                  event.preventDefault();
                                  blockUI.blockUI("Saving...");
                                  form.pauseValidation();

                                  postCurrentPage.mutateAsync({answers: values}).then((response) => {
                                    if (twilioContact && safetyForm?.has_twilio_checkin) {
                                      window.location.href = `/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${safetyForm.date}/`;
                                    } else {
                                      window.location.reload();
                                    }
                                  });
                                }}
                              />
                              <Box mt={1} />
                              <FormActions.SaveButton
                                fullWidth
                                color="secondary"
                                disabled={submitting}
                                label={
                                  isSpanish
                                    ? invalid
                                      ? `Complete Todos Los Campos Obligatorios`
                                      : `Finalizar y Enviar`
                                    : invalid
                                    ? `Complete All Required Fields`
                                    : `Finalize and Submit`
                                }
                                onClick={() => {
                                  if (!invalid) {
                                    setLockFormConfirmation(true);
                                  }
                                }}
                              />

                              <Box mt={1} />
                            </>
                          )}
                          {twilioContact && Object.keys(twilioContact).length > 0 && safetyForm?.has_twilio_checkin && (
                            <>
                              {safetyForm.is_closed && <Box mt={2} />}
                              <Button
                                href={`/twilio-contact/${twilioContact.uuid}/projects/${project.uuid}/wellness-check/${safetyForm.date}/`}
                                fullWidth
                                variant="contained"
                                color="primary"
                                startIcon={<FontAwesomeIcon icon={faQrcode} />}
                              >
                                {isSpanish ? `Volver al Código QR` : `Back to QR Code`}
                              </Button>
                            </>
                          )}
                        </>
                      )}

                      {/* </InnerFooter> */}
                    </Box>
                  </FinalFormKeyboardShortcuts>
                </form>
              </>
            );
          }}
        </FinalForm>
      </Box>
      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          postCurrentPage
            .mutateAsync({action: "delete"})
            .then((response) => {
              window.location.href = response.redirect;
            })
            .catch((err) => {
              setDeleteConfirmationIsOpen(false);
            });
        }}
      >
        {isSpanish ? (
          <>
            ¿Quieres descartar este formulario? Se eliminará de forma permanente y se perderán todos los datos
            ingresados.
          </>
        ) : (
          <>You want to discard this form? It will be permanently deleted and all data entered will be lost.</>
        )}
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={lockFormConfirmation}
        onDeny={() => setLockFormConfirmation(false)}
        onApprove={() => {
          setLockFormConfirmation(false);
          setSuppressBeforeUnload(true);
          submitAndFinalize({...formValues});
        }}
        title={isSpanish ? "Finalizar y guardar" : "Finalize and Save"}
        approveLabel={isSpanish ? `Si` : `Yes`}
        denyLabel="No"
      >
        {isSpanish
          ? `¿Deseas enviar este formulario al ${
              superintendent || "superintendente del proyecto"
            } para su aprobación? Este formulario se bloqueará y no podrás realizar más cambios.`
          : `Do you want to submit this form to ${
              superintendent || "the project superintendent"
            } for approval? This form will be locked, and you will not be able to make further changes.`}
      </ConfirmationDialog>
    </>
  );
};

export default JobsiteSafetyForm;
