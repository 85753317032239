import {faFileExcel, faShoppingCart} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Currency} from "../../../components/Accounting";
import {StatusIcon} from "../../../components/Icons";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";
import {truncateString} from "../../../utils/string";

const PurchaseOrders = (props: {
  purchaseOrders: Array<{
    contract: {
      display: string;
    };
    to_company: {
      name: string;
    };
    display: string;
    status: string;
    total_price: number;
  }>;
  project: {display: string};
  budget: {display: string};
  isFetching?: Boolean;
}) => {
  const {purchaseOrders, project, budget, isFetching} = props;
  const alivePurchaseOrders = purchaseOrders.filter((purchaseOrder) => !["void"].includes(purchaseOrder.status));
  const novoClasses = makeNovoClasses();

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faShoppingCart} /> Purchase Orders
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={isFetching}
                onClick={(event) => {
                  saveExcel(
                    purchaseOrders.map((purchaseOrder) => {
                      return {
                        "Purchase Order": purchaseOrder.display,
                        Amount: {v: purchaseOrder.total_price, t: "n", z: "$#,##0.00"},
                      };
                    }),
                    `${project.display} - ${budget.display} - Purchase Orders`,
                    [{wch: 60}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            showFooter={Boolean(purchaseOrders.length)}
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["total_price"]}
            columns={[
              {
                Header: "PO",
                id: "po",
                accessor: (value) => {
                  return `${value.status_sort}-${value.display}`;
                },
                Cell: ({value, row}) => (
                  <>
                    <StatusIcon status={row.original.status} showTooltip />{" "}
                    <Link href={row.original.url}>{truncateString(row.original.display, 100)}</Link>
                  </>
                ),
              },
              {
                Header: "Amount",
                accessor: "total_price",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => alivePurchaseOrders.reduce((sum, row) => row.total_price + sum, 0),
                    [alivePurchaseOrders]
                  );
                  return <Currency number={total} />;
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "contract.display",
                },
              ],
            }}
            data={purchaseOrders}
          />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default PurchaseOrders;
