import {Box, Grid, Link, Typography} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import React from "react";
import {CheckboxMui, CompanyAutoselectMui, CompanyContactAutoselectMui} from "../../../../components/forms/Fields";

const TeamFields = (props) => {
  const {namespace, values, form} = props;
  const [shouldDisable, setShouldDisable] = React.useState(false);
  return (
    <>
      <Box my={2}>
        <Typography variant="h4">External Team</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <CompanyAutoselectMui
            name={`${namespace}.client`}
            label="Client"
            required
            autoFocus
            onChange={(event, value, reason) => {
              const additionalInsured = values?.insurance?.additional_insured;
              form.change("insurance.additional_insured", `${additionalInsured} ${value?.name}`);
            }}
          />

          <CheckboxMui
            name={`${namespace}.client_is_building_owner`}
            label={"Client is building owner"}
            onChange={(event, value) => {
              const client = form.getFieldState(`${namespace}.client`)?.value;
              if (value && client) {
                form.change(`${namespace}.building_owner`, client);
              }
              setShouldDisable(value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <CompanyAutoselectMui name={`${namespace}.building_owner`} label="Building Owner" disabled={shouldDisable} />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyAutoselectMui name={`${namespace}.tenant`} label="Tenant" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyAutoselectMui name={`${namespace}.architect`} label="Architect" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyAutoselectMui name={`${namespace}.construction_manager`} label="Construction Manager" />
        </Grid>
      </Grid>
      {(!values[namespace]?.client ||
        !values[namespace]?.tenant ||
        !values[namespace]?.architect ||
        !values[namespace]?.construction_manager ||
        !values[namespace]?.building_owner) && (
        <Box my={1}>
          <Alert severity="info">
            <AlertTitle>Don't See The Company You're Looking For?</AlertTitle>
            <Link href={"/v2/companies/create/"} underline="always" target="_blank">
              Click here to create a new company in a new tab.
            </Link>{" "}
            After creating the company and closing the tab the newly created company should be available.
          </Alert>
        </Box>
      )}

      <Box my={2}>
        <Typography variant="h4">Internal Team</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <CompanyContactAutoselectMui
            companyId={1547}
            name={`${namespace}.project_executive`}
            label="Project Executive"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyContactAutoselectMui
            companyId={1547}
            name={`${namespace}.senior_project_manager`}
            label="Senior Project Manager"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyContactAutoselectMui companyId={1547} name={`${namespace}.project_manager`} label="Project Manager" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyContactAutoselectMui
            companyId={1547}
            name={`${namespace}.assistant_project_manager`}
            label="Assistant Project Manager"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyContactAutoselectMui
            companyId={1547}
            name={`${namespace}.project_engineer`}
            label="Project Engineer"
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyContactAutoselectMui companyId={1547} name={`${namespace}.superintendent`} label="Superintendent" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyContactAutoselectMui companyId={1547} name={`${namespace}.project_admin`} label="Project Admin" />
        </Grid>
        <Grid item xs={12} sm={4}>
          <CompanyContactAutoselectMui
            companyId={1547}
            name={`${namespace}.project_accountant`}
            label="Project Accountant"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default TeamFields;
