import {faEnvelope} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Grid, Link, Tab, Typography} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import accounting from "accounting";
import classnames from "classnames";
import moment from "moment";
import qs from "query-string";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {Percentage} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSelect} from "../../../components/Filters";
import docuSignStatuses from "../../../components/forms/choices/docuSignStatuses.json";
import {UserAutoselectMui} from "../../../components/forms/Fields";
import {LegacyUILink} from "../../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TableStatusLabel} from "../../../components/Status";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

const ProjectDocuSignEnvelopeList = (props) => {
  const {project, user, ...rest} = props;

  const [selectedTab, setSelectedTab] = React.useState("envelopes");
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const [createdBy, setCreatedBy] = React.useState();
  const novoClasses = makeNovoClasses();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Status",
    "Created By",
  ]);
  const filterParams = {
    status: filterOptions.Status.value,
    created_by_id: filterOptions["Created By"].value,
  };

  const {query: envelopeQuery} = useSentinelListAPI(
    `/projects/${project.id}/docusign/envelopes/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
        metadata: {
          stats: [],
        },
      },
      keepPreviousData: true,
    }
  );

  return (
    <>
      <Helmet title={`${project.display} - DocuSign Envelopes`} />

      <Box my={1}>
        <ProjectBreadcrumbs project={project}>
          <Typography color="textPrimary"> DocuSign Envelopes</Typography>
        </ProjectBreadcrumbs>
      </Box>

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar
            position="static"
            color="default"
            // elevation={0}
          >
            <TabList
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
                // navigate(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label="Envelopes" value="envelopes" />
              <Tab label="Stats" value="stats" />
            </TabList>
          </AppBar>
          <TabPanel value="envelopes">
            <Box mx={-3} mb={-3} mt={-2}>
              <PaperPanel.Header isLoading={envelopeQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faEnvelope} /> DocuSign Envelopes{" "}
                  {envelopeQuery.isFetchedAfterMount ? `(${accounting.formatNumber(envelopeQuery.data.count)})` : null}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => envelopeQuery.refetch()}
                      isFetching={envelopeQuery.isFetching}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>
              <PaperPanel.Toolbar p={1}>
                <FinalForm onSubmit={() => {}}>
                  {() => (
                    <Grid container spacing={1}>
                      <Grid item sm={6} xs={12}>
                        <FilterSelect
                          // native
                          label="Status"
                          name="Status"
                          options={docuSignStatuses}
                          value={filterOptions.Status.value}
                          // showStatusIcon
                          // statusType="rfi"
                          onChange={(value, label) => {
                            setPage(1);
                            setFilterOption("Status", value, label);
                          }}
                        />
                      </Grid>

                      <Grid item sm={6} xs={12}>
                        <UserAutoselectMui
                          label="Created By"
                          name="created_by"
                          value={createdBy}
                          onChange={(e, value) => {
                            setPage(1);
                            setCreatedBy(value);
                            if (value === null) {
                              clearFilterOption("Created By");
                            } else {
                              setFilterOption("Created By", value?.id, value?.full_name);
                            }
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </FinalForm>

                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <FilterOptionChips
                      filterOptions={filterOptions}
                      onDelete={(key) => {
                        if (key === "Created By") {
                          setCreatedBy(null);
                        }
                        clearFilterOption(key);
                      }}
                      onDeleteAll={() => {
                        setCreatedBy(null);
                        clearAllFilterOptions();
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <Box display="flex" justifyContent="flex-end">
                      <Pagination
                        count={envelopeQuery.data.total_pages}
                        page={page}
                        // pageSize={pageSize}
                        setPage={setPage}
                        // setPageSize={setPageSize}
                      />
                    </Box>
                  </Grid>
                </Grid>
              </PaperPanel.Toolbar>
              <PaperPanel.Body>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  // containerComponent={Paper}
                  rightAlignColumns={["created"]}
                  columns={[
                    {
                      Header: "Envelope",
                      accessor: "envelope_id",
                      disableSortBy: true,
                      Cell: ({value, row}) => (
                        <>
                          <TableStatusLabel status={row.original.status || "Unknown"}>
                            <Link underline="always" href={`/v2/docusign/envelopes/${value}/`}>
                              {value.substring(0, 8)}...
                            </Link>
                          </TableStatusLabel>
                        </>
                      ),
                    },
                    {
                      Header: "Object",
                      accessor: "object.display",
                      Cell: ({value, row}) => (
                        <Link underline="always" href={`${row.original.object.url}`}>
                          {value}
                        </Link>
                      ),
                    },
                    {
                      Header: "Obj Status",
                      accessor: "object.status_display",
                    },
                    {
                      Header: "Created By",
                      accessor: "created_by.full_name",
                    },
                    {
                      Header: "Created",
                      accessor: "created",
                      Cell: ({value, row}) => (
                        <Box whiteSpace="nowrap">
                          {value ? moment.tz(value, project.timezone).format("ddd, MMM D, YYYY, h:mm:ss A z") : ""}
                        </Box>
                      ),
                    },
                  ]}
                  initialState={{
                    sortBy: [
                      {
                        id: "created",
                        desc: true,
                      },
                    ],
                  }}
                  data={envelopeQuery.data.results}
                />

                {/* <pre>{JSON.stringify(envelopeQuery, null, 2)}</pre> */}
              </PaperPanel.Body>
              {(envelopeQuery.data.total_pages > 1 || pageSize > 100) && (
                <PaperPanel.Footer display="flex" justifyContent="flex-end">
                  <PaginationWithPageSize
                    count={envelopeQuery.data.total_pages}
                    page={page}
                    pageSize={pageSize}
                    setPage={setPage}
                    setPageSize={setPageSize}
                    pageSizes={[10, 100, 250, 500, 1000]}
                  />
                </PaperPanel.Footer>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="stats">
            <Box mx={-3} mb={-3} mt={-2}>
              <PaperPanel.Header isLoading={envelopeQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faEnvelope} /> Envelope Stats{" "}
                  {envelopeQuery.isFetchedAfterMount ? `(${accounting.formatNumber(envelopeQuery.data.count)})` : null}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => envelopeQuery.refetch()}
                      isFetching={envelopeQuery.isFetching}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>
              <PaperPanel.Toolbar p={1}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <FilterOptionChips
                      filterOptions={filterOptions}
                      onDelete={(key) => {
                        if (key === "Created By") {
                          setCreatedBy(null);
                        }
                        clearFilterOption(key);
                      }}
                      onDeleteAll={() => {
                        setCreatedBy(null);
                        clearAllFilterOptions();
                      }}
                    />
                  </Grid>
                </Grid>
              </PaperPanel.Toolbar>
              <PaperPanel.Body>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  // containerComponent={Paper}
                  rightAlignColumns={["count", "percent"]}
                  columns={[
                    {
                      Header: "Status",
                      accessor: "status",
                    },
                    {
                      Header: "Count",
                      accessor: "count",
                      Cell: ({value, row}) => accounting.formatNumber(value),
                    },
                    {
                      Header: "Percent",
                      accessor: "percent",
                      Cell: ({value, row}) => <Percentage number={value} precision={2} />,
                    },
                  ]}
                  initialState={{
                    sortBy: [
                      {
                        id: "status",
                        desc: false,
                      },
                    ],
                  }}
                  data={envelopeQuery.data.metadata.stats}
                />
                {/* <pre>{JSON.stringify(envelopeQuery.data.metadata.stats, null, 2)}</pre> */}
              </PaperPanel.Body>
            </Box>
          </TabPanel>
        </PaperPanel>
      </TabContext>
      <LegacyUILink href={`/projects/${project.id}/envelopes/`} mt={2} />

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default ProjectDocuSignEnvelopeList;
