import {faSackDollar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Avatar,
  Box,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import LockIcon from "@material-ui/icons/Lock";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {Currency, Percentage} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import Gravatar from "../../../components/Gravatar";
import {ProjectStatusIcon} from "../../../components/Icons";
import {MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TableStatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {ProjectBonusPercentDialogForm} from "../../bonuses/BonusForms";

const ProjectBonusCloseoutRequests = (props) => {
  const {project, user} = props;

  const [selectedBonusAccount, setSelectedBonusAccount] = React.useState({} as any);
  const [selectedBonusBonusPercent, setSelectedBonusBonusPercent] = React.useState({} as any);
  const [showCreateProjectBonusPercentDialog, setShowCreateProjectBonusPercentDialog] = React.useState(false);
  const [showUpdateProjectBonusPercentDialog, setShowUpdateProjectBonusPercentDialog] = React.useState(false);
  const [showLockBonusPercentDialog, setShowLockBonusPercentDialog] = React.useState(false);

  const blockUI = useBlockUI();
  const permissions = usePermissions();
  const novoClasses = makeNovoClasses();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("xs"));

  const {
    query: projectBonusPercentQuery,
    create: createProjectBonusPercent,
    update: updateProjectBonusPercent,
    delete: deleteProjectBonusPercent,
    rpc: projectBonusPercentRPC,
  } = useSentinelListAPI(`projects/${project.id}/bonuses/percents/`, {
    initialData: {
      results: [],
    },
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });
  const projectBonusPercents = projectBonusPercentQuery.data.results;
  const isLocked = project.is_bonus_percent_locked;

  const pageDataQuery = useFetchCurrentPage({
    initialData: {
      projectCloseout: {},
      closeoutRequests: [],
      bonusAccounts: [],
    },
  });

  const pageData = pageDataQuery.data;
  const {projectCloseout, closeoutRequests, bonusAccounts} = pageData;

  // const bonusAmountTotal = transactions.reduce((sum, row) => row.amount + sum, 0);

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching project bonuses data..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Closeout Requests`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/closeout/`}>
          <Typography color="textSecondary">Closeout</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">Closeout Request</Typography>
      </ProjectBreadcrumbs>

      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>
            <ProjectStatusIcon project={project} /> {project.display}
          </PageHeader.Title>
        </PageHeader.Left>

        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark title="JTD Payments" number={projectCloseout?.jtd_work_billed} />
          <PageHeader.Right.CurrencySpark title="JTD Cost" number={projectCloseout?.jtd_cost} border />
          <PageHeader.Right.CurrencySpark title="Gross Profit" number={projectCloseout?.gross_profit} border />
        </PageHeader.Right>
      </PageHeader>

      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faSackDollar} /> Project Bonus %
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {!isLocked && (
              <PaperPanel.Header.Action>
                <PaperPanel.Header.Button
                  startIcon={<LockIcon />}
                  onClick={() => {
                    setShowLockBonusPercentDialog(true);
                  }}
                >
                  Lock Bonus Percentages
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border={!isLocked}>
              <PaperPanel.Header.HelpButton title="Project Bonus % Help">
                <>
                  <strong>PM is missing from the project bonus pool?</strong>
                  <p>PM's eligible to add to the bonus pool are determined by the following criteria:</p>
                  <ol>
                    <li>Does the PM have a bonus account?</li>
                    <li>Is the PM in the project directory?</li>
                  </ol>
                </>
              </PaperPanel.Header.HelpButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={projectBonusPercentQuery.isFetching}
                onClick={() => {
                  projectBonusPercentQuery.refetch();
                  pageDataQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          {isLocked && (
            <PaperPanel.Alert severity="info">
              The bonus percentages for this project have been locked. Contact Sentinel support to have this unlocked if
              you need to make changes.
            </PaperPanel.Alert>
          )}
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["bonus_percent"]}
            showFooter={Boolean(projectBonusPercents.length)}
            columns={[
              {
                Header: "Account",
                accessor: "account.description",
                Cell: ({value, row}) => (
                  <Link
                    underline="always"
                    style={{cursor: "pointer"}}
                    onClick={() => {
                      setSelectedBonusBonusPercent(row.original);
                      setShowUpdateProjectBonusPercentDialog(true);
                    }}
                  >
                    {value}
                  </Link>
                ),
              },
              {
                Header: "Bonus %",
                accessor: "bonus_percent",
                Cell: ({value, row}) => <Percentage number={value / 100} precision={2} />,
                Footer: () => {
                  const total = React.useMemo(
                    () => projectBonusPercents.reduce((sum, row) => row.bonus_percent + sum, 0),
                    [projectBonusPercents]
                  );
                  return <Percentage number={total / 100} precision={2} />;
                },
              },
            ]}
            initialState={
              {
                // sortBy: [
                //   {
                //     id: "created",
                //     desc: true,
                //   },
                // ],
              }
            }
            data={projectBonusPercents}
          />
          {!isLocked && (
            <List dense style={{display: "flex", flexDirection: isSmall ? "column" : "row"}}>
              {bonusAccounts.map((account) => {
                return (
                  <Tooltip key={account.id} title={`Add ${account.full_name}`}>
                    <ListItem
                      button
                      style={{width: "auto"}}
                      onClick={() => {
                        setSelectedBonusAccount(account);
                        setShowCreateProjectBonusPercentDialog(true);
                      }}
                    >
                      <ListItemIcon style={{minWidth: 0, width: 25}}>
                        <AddIcon />
                      </ListItemIcon>
                      <ListItemAvatar>
                        <Avatar>
                          <Gravatar alt={account.full_name} email={account.email} />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={account.full_name} />
                    </ListItem>
                  </Tooltip>
                );
              })}
            </List>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      {permissions.can_manage_bonuses && (
        <>
          <Box mb={2} />
          <PaperPanel>
            <PaperPanel.Header>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faSackDollar} /> Bonus Closeout Requests
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action border>
                  <PaperPanel.Header.RefreshButton
                    isFetching={pageDataQuery.isFetching}
                    onClick={() => pageDataQuery.refetch()}
                  />
                </PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Body>
              <ReactTableMui
                size="small"
                className={classnames(
                  novoClasses.stripedTable,
                  permissions.can_manage_bonuses ? novoClasses.smallTable : novoClasses.mediumTable,
                  novoClasses.boldHeaderTable
                )}
                rightAlignColumns={[
                  "sf_gross_receipts_tax",
                  "overhead_and_insurance",
                  "cost_adjustments",
                  "net_profit",
                  "bonus_percent",
                  "bonus_amount",
                ]}
                showFooter={Boolean(closeoutRequests?.length)}
                columns={[
                  {
                    Header: "Description",
                    accessor: "closeout_request.description",
                    Cell: ({value, row}) => {
                      return (
                        <>
                          <TableStatusLabel
                            status={row.original.closeout_request.status_display}
                            hint="bonusAccountDistributionRequest"
                          >
                            <>
                              {permissions.can_manage_bonuses || row.original.account?.user?.id === user.id ? (
                                <Link
                                  underline="always"
                                  href={`/v2/bonuses/accounts/${row.original.account.id}/closeout-requests/${row.original.closeout_request.id}/`}
                                >
                                  {value}
                                </Link>
                              ) : (
                                <>{value}</>
                              )}
                            </>
                          </TableStatusLabel>
                        </>
                      );
                    },
                  },
                  {
                    Header: "Account",
                    accessor: "account.description",
                  },
                  // {
                  //   Header: "Final Billing",
                  //   accessor: "final_billing",
                  //   Cell: ({value, row}) => <Currency number={value} />,
                  // },
                  // {
                  //   Header: "JTD Cost",
                  //   accessor: "jtd_cost",
                  //   Cell: ({value, row}) => <Currency number={value} />,
                  // },
                  {
                    Header: "SF Tax",
                    accessor: "sf_gross_receipts_tax",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "Overhead & Insurance",
                    accessor: "overhead_and_insurance",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "Cost Adjustments",
                    accessor: "cost_adjustments",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "Profit",
                    accessor: "net_profit",
                    Cell: ({value, row}) => <Currency number={value} />,
                  },
                  {
                    Header: "Bonus %",
                    accessor: "bonus_percent",
                    Cell: ({value, row}) => <Percentage number={value / 100} precision={2} />,
                    Footer: () => {
                      const total = React.useMemo(
                        () => closeoutRequests.reduce((sum, row) => row.bonus_percent + sum, 0),
                        [closeoutRequests]
                      );
                      return <Percentage number={total / 100} precision={2} />;
                    },
                  },
                  {
                    Header: "Bonus",
                    accessor: "bonus_amount",
                    Cell: ({value, row}) => <Currency number={value} />,
                    Footer: () => {
                      const total = React.useMemo(
                        () => closeoutRequests.reduce((sum, row) => row.bonus_amount + sum, 0),
                        [closeoutRequests]
                      );
                      return <Currency number={total} />;
                    },
                  },
                ]}
                initialState={{
                  // sortBy: [
                  //   {
                  //     id: "created",
                  //     desc: true,
                  //   },
                  // ],
                  hiddenColumns: permissions.can_manage_bonuses
                    ? []
                    : [
                        "sf_gross_receipts_tax",
                        "overhead_and_insurance",
                        "cost_adjustments",
                        "net_profit",
                        "bonus_amount",
                      ],
                }}
                data={closeoutRequests || []}
              />
            </PaperPanel.Body>
          </PaperPanel>
        </>
      )}

      {/* <pre>{JSON.stringify(projectCloseout, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(permissions, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(projectBonusPercents, null, 2)}</pre> */}
      {/* <pre>{JSON.stringify(selectedBonusBonusPercent, null, 2)}</pre> */}
      <ProjectBonusPercentDialogForm
        projectId={project.id}
        isNew
        isOpen={showCreateProjectBonusPercentDialog}
        handleClose={() => setShowCreateProjectBonusPercentDialog(false)}
        initialValues={{
          account: selectedBonusAccount,
          // bonus_percent: 0,
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          createProjectBonusPercent.mutateAsync(values).then((newProjectBonusPercent) => {
            Promise.all([pageDataQuery.refetch(), projectBonusPercentQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowCreateProjectBonusPercentDialog(false);
            });
          });
        }}
      />

      <ProjectBonusPercentDialogForm
        projectId={project.id}
        isOpen={showUpdateProjectBonusPercentDialog}
        isReadOnly={selectedBonusBonusPercent?.is_closed}
        handleClose={() => setShowUpdateProjectBonusPercentDialog(false)}
        initialValues={selectedBonusBonusPercent}
        onSubmit={(values) => {
          blockUI.blockUI("Updating...");
          updateProjectBonusPercent.mutateAsync(values).then(() => {
            Promise.all([pageDataQuery.refetch(), projectBonusPercentQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowUpdateProjectBonusPercentDialog(false);
            });
          });
        }}
        onDelete={(values) => {
          blockUI.blockUI("Deleting...");
          deleteProjectBonusPercent.mutateAsync(values.id).then(() => {
            Promise.all([pageDataQuery.refetch(), projectBonusPercentQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowUpdateProjectBonusPercentDialog(false);
            });
          });
        }}
      />
      <ConfirmationDialog
        isOpen={showLockBonusPercentDialog}
        onApprove={() => {
          blockUI.blockUI("Locking Bonus Percentages...");
          projectBonusPercentRPC.mutateAsync({action: "lock"}).then(() => {
            Promise.all([pageDataQuery.refetch(), projectBonusPercentQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setShowLockBonusPercentDialog(false);
              window.location.reload();
            });
          });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setShowLockBonusPercentDialog(false);
        }}
      >
        You want to lock bonus percentages for this project? You will not be able to adjust once locked.
      </ConfirmationDialog>
    </>
  );
};
export default ProjectBonusCloseoutRequests;
