import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";

import {Box, Grid} from "@material-ui/core";
import {PaperItem} from "../../../components/PaperItem";

const AdditionalPagePaperItem = (props) => {
  const {setActiveDocument, onDelete, onEdit, document, projectId, isDragging, dragHandleProps, ...rest} = props;

  return (
    <PaperItem isDragging={isDragging} {...rest}>
      <PaperItem.Left minWidth={45}>
        <Box display="flex" alignItems="center">
          <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
          <PaperItem.Position position={document.position} />
        </Box>
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={4}>
            {document.name}
          </Grid>
          <Grid item xs={4}>
            {document.description}
          </Grid>
        </Grid>
      </PaperItem.Body>

      <PaperItem.Right></PaperItem.Right>

      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton icon={LinkIcon} title="View Document" href={document.url} target={"_blank"} />
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEdit} />
        <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete Item" onClick={onDelete} />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export default AdditionalPagePaperItem;
