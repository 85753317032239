import {faArrowsH, faPenFancy} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import {Currency} from "../../../components/Accounting";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";

const SCOPaperItem = (props: {
  sco: {
    id: number;
    display: string;
    description: string;
    docusign_envelope_status: string;
    docusign_envelope_id: string;
    extracker_id: string;
    timberline_subcontract_co: number;
    cost: number;
    item_number_display: string;
    url: string;
    status_display: string;
    box_folder_id: string;
  };
  projectId: number;
}) => {
  const {sco, projectId, ...rest} = props;

  return (
    <PaperItem key={sco.id}>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={6} xs={12}>
            {" "}
            <StatusLabel status={sco.status_display} />{" "}
            <MuiNavLink underline="always" to={`/v2/projects/${projectId}/sccos/${sco.id}/`}>
              {sco.item_number_display} - {sco.description}
            </MuiNavLink>
            {sco.extracker_id && (
              <Tooltip title="Created By Extracker">
                <Box display="inline" ml={1}>
                  <Typography2 type="metadata">
                    <FontAwesomeIcon icon={faArrowsH} />
                  </Typography2>
                </Box>
              </Tooltip>
            )}
          </Grid>
          <Grid item sm={2} xs={4}>
            {sco.timberline_subcontract_co && (
              <MuiNavLink underline="always" to={`/v2/projects/${projectId}/sccos/${sco.id}/#tab=timberline`}>
                <Tooltip title="Timberline CO Number">
                  <small>{sco.timberline_subcontract_co}</small>
                </Tooltip>
              </MuiNavLink>
            )}
          </Grid>
          <Grid item sm={2} xs={4}>
            {sco.docusign_envelope_status && (
              <Link href={`/v2/docusign/envelopes/${sco.docusign_envelope_id}/`}>
                <Tooltip title="DocuSign Status">
                  <small>
                    <FontAwesomeIcon icon={faPenFancy} /> {sco.docusign_envelope_status}
                  </small>
                </Tooltip>
              </Link>
            )}
          </Grid>
          <Grid item xs={4} sm={2}>
            <Box textAlign="right">
              <Currency number={sco.cost || 0} />
            </Box>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={37}>
        <Box>
          <Typography2 type="metadata">{sco.item_number_display}</Typography2>
        </Box>
      </PaperItem.Right>

      <PaperItem.RightHover>
        <BoxFilesIconButtonLink
          projectId={projectId}
          app="sccos"
          model="scco"
          objectId={sco.id}
          boxFolderId={sco.box_folder_id}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export default SCOPaperItem;
