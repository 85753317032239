import classnames from "classnames";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import {FormGroup, HelpBlock, InputGroup} from "react-bootstrap";
import ReactDate from "react-datepicker";
import {Field} from "react-final-form";
import _NumberFormat from "react-number-format";
import ReactSelect, {components} from "react-select";
import {axiosAPI} from "../../api";
import {identity} from "../../components/FinalFormEnhancers";
import * as Autosuggest from "../Autosuggest";
import {
  CSICodeAutosuggest as _CSICodeAutosuggest,
  CSICodeDescriptionAutosuggest as _CSICodeDescriptionAutosuggest,
} from "../Autosuggest";
import {
  BudgetAllocationSelect as _BudgetAllocationSelect,
  defaultOptions,
  ProjectContactSelect as _ProjectContactSelect,
  ProjectSelect as _ProjectSelect,
  SimpleSelectFK as _SimpleSelectApi,
  TagSelect as _TagSelect,
} from "../ReactSelect";
import TinyMCEInput from "../TinyMCEInput";

export const FinalFieldWrapper = (props) => {
  const {
    label,
    help,
    className,
    ignoreTouchedError,
    ignoreFormControlClass,
    children,
    validationState,
    input,
    ...rest
  } = props;
  return (
    <Field name={props.name} subscription={{error: true, touched: true}}>
      {({meta: {touched, error}}) => {
        const showError = error && (touched || ignoreTouchedError);
        return (
          <FormGroup className={classnames({"has-error": showError})} validationState={validationState}>
            {label && <label className="control-label">{label}</label>}
            {rest.required && <sup style={{color: "#e14b39"}}>*</sup>}
            <Field className={classnames({"form-control": !ignoreFormControlClass}, className)} {...input} {...rest}>
              {(props) => {
                return children(props);
              }}
            </Field>
            {help && <HelpBlock className="note">{help}</HelpBlock>}
            {showError ? <small className="help-block">{error}</small> : null}
          </FormGroup>
        );
      }}
    </Field>
  );
};

export const FinalField = React.forwardRef((props, ref) => {
  const {children, ...rest} = props;
  return (
    <FinalFieldWrapper {...rest}>
      {({input, ...rest}) => {
        return React.cloneElement(children, {ref, ...input, ...rest});
      }}
    </FinalFieldWrapper>
  );
});

export const FinalCheckbox = (props) => {
  const {label, help, children, ...rest} = props;
  return (
    <label>
      <Field component="input" type="checkbox" {...rest} /> {label} {children}
      {help && <HelpBlock className="note">{help}</HelpBlock>}
    </label>
  );
};

export const FinalSimpleSelect = (props) => {
  const {children, ...rest} = props;
  return (
    <FinalFieldWrapper ignoreFormControlClass parse={(val) => val && val.value} {...rest}>
      {({input, className, ...rest}) => {
        return React.cloneElement(children, {...input, ...rest});
      }}
    </FinalFieldWrapper>
  );
};

export const FinalTinyMCE = (props) => {
  return (
    <FinalFieldWrapper parse={identity} {...props}>
      {({input: {value, onChange}, ...rest}) => {
        return (
          <TinyMCEInput
            {...rest}
            value={value}
            onEditorChange={(value) => {
              onChange(value);
            }}
          />
        );
      }}
    </FinalFieldWrapper>
  );
};

export const DatePicker = (props) => {
  return (
    <FinalFieldWrapper {...props}>
      {(props) => {
        const {input, ...rest} = props;
        const selected = moment(input.value).isValid() ? moment(input.value).toDate() : null;
        return (
          <InputGroup>
            <ReactDate
              autoComplete="off"
              dateFormat="yyyy-MM-dd"
              className="form-control react-datepicker"
              selected={selected}
              {...input}
              {...rest}
              onChange={(value) => {
                if (value === null) {
                  input.onChange(null);
                } else {
                  input.onChange(moment(value).format("YYYY-MM-DD"));
                }
              }}
            />
            <InputGroup.Addon>
              <i className="fa fa-calendar" />
            </InputGroup.Addon>
          </InputGroup>
        );
      }}
    </FinalFieldWrapper>
  );
};

export const DateTimePicker = (props) => {
  return (
    <FinalFieldWrapper {...props}>
      {(props) => {
        const {input, ...rest} = props;
        const selected = moment(input.value).isValid() ? moment(input.value).toDate() : null;
        return (
          <InputGroup>
            <ReactDate
              dateFormat="yyyy-MM-dd h:mm a"
              className="form-control"
              timeFormat="h:mm a"
              showTimeSelect
              selected={selected}
              {...rest}
              onChange={(value) => {
                if (value === null) {
                  input.onChange(null);
                } else {
                  input.onChange(value);
                }
              }}
            />
            <InputGroup.Addon>
              <i className="fa fa-calendar" />
            </InputGroup.Addon>
          </InputGroup>
        );
      }}
    </FinalFieldWrapper>
  );
};

export const SimpleAutosuggest = ({projectId, ...rest}) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...rest}>
      {(props) => {
        const {input, ...rest} = props;
        return (
          <Autosuggest.SimpleAutosuggest
            {...rest}
            value={input.value}
            onBlur={() => input.onBlur(input.value)}
            onChange={(value) => {
              input.onChange(value);
            }}
          />
        );
      }}
    </FinalFieldWrapper>
  );
};

export const TagSelect = ({projectId, ...rest}) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...rest}>
      {(props) => {
        const {input, className, ...rest} = props;
        let value = [];
        if (input.value) {
          value = input.value.map((option) => {
            return {value: option, label: option};
          });
        }
        return (
          <_TagSelect
            projectId={projectId}
            {...input}
            {...rest}
            value={value}
            onChange={(value) => {
              if (value === null) {
                input.onChange([]);
              } else {
                input.onChange(value.map((value) => value.value));
              }
            }}
          />
        );
      }}
    </FinalFieldWrapper>
  );
};

export const CSICodeAutosuggest = ({inputProps, ...rest}) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...rest}>
      {(props) => {
        const {input, className, ...rest} = props;
        return (
          <_CSICodeAutosuggest
            {...rest}
            inputProps={inputProps}
            value={input.value}
            onChange={(value) => {
              input.onChange(value);
            }}
          />
        );
      }}
    </FinalFieldWrapper>
  );
};

export const CurrencyInput = ({decimalScale = 2, ...rest}) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...rest} className="form-control">
      {(props) => {
        const {input, ...rest} = props;
        const {onChange, ...inputRest} = input;
        return (
          <InputGroup>
            <InputGroup.Addon>
              <i className="fa fa-dollar-sign" />
            </InputGroup.Addon>
            <_NumberFormat
              thousandSeparator={true}
              decimalScale={decimalScale}
              fixedDecimalScale
              {...inputRest}
              {...rest}
              onValueChange={(values) => {
                input.onChange(values.floatValue);
              }}
            />
          </InputGroup>
        );
      }}
    </FinalFieldWrapper>
  );
};

export const PercentInput = ({decimalScale = 2, ...rest}) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...rest} className="form-control">
      {(props) => {
        const {input, ...rest} = props;
        const {onChange, ...inputRest} = input;
        return (
          <InputGroup>
            <InputGroup.Addon>
              <i className="fa fa-percentage" />
            </InputGroup.Addon>
            <_NumberFormat
              thousandSeparator={true}
              decimalScale={decimalScale}
              fixedDecimalScale
              {...inputRest}
              {...rest}
              onValueChange={(values) => {
                input.onChange(values.floatValue);
              }}
            />
          </InputGroup>
        );
      }}
    </FinalFieldWrapper>
  );
};

export const CSICodeDescriptionAutosuggest = ({inputProps, ...rest}) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...rest}>
      {(props) => {
        const {input, ...rest} = props;
        return <_CSICodeDescriptionAutosuggest {...input} {...rest} inputProps={inputProps} />;
      }}
    </FinalFieldWrapper>
  );
};

export const ReactSelectApi = React.forwardRef(({url, ...rest}, ref) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  React.useEffect(() => {
    setIsLoading(true);
    axiosAPI
      .get(url)
      .then((response) => {
        setOptions(response.data.results);
      })
      .then(() => setIsLoading(false));
  }, []);
  return (
    <FinalFieldWrapper ignoreFormControlClass {...rest}>
      {(props) => {
        const {input, inputOnChange, className, ...rest} = props;
        return (
          <ReactSelect
            {...defaultOptions}
            ref={ref}
            options={options}
            getOptionValue={(option) => option.id}
            getOptionLabel={(option) => option.display}
            components={{
              Option: ({data, ...rest}) => (
                <components.Option {...rest}>
                  <div>{data.display}</div>
                </components.Option>
              ),
              SingleValue: ({data, ...rest}) => (
                <components.SingleValue {...rest}>
                  <div>{data.display}</div>
                </components.SingleValue>
              ),
            }}
            isLoading={isLoading}
            {...input}
            onChange={(e) => {
              input.onChange(e);
              inputOnChange && inputOnChange(e);
            }}
            {...rest}
          />
        );
      }}
    </FinalFieldWrapper>
  );
});

export const FinalSimpleSelectFK = (props) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...props}>
      {(props) => {
        const {input, inputOnChange, className, ...rest} = props;
        return (
          <_SimpleSelectApi
            {...input}
            {...rest}
            onChange={(e) => {
              input.onChange(e);
              inputOnChange && inputOnChange(e);
            }}
          />
        );
      }}
    </FinalFieldWrapper>
  );
};

FinalSimpleSelectFK.propTypes = {
  options: PropTypes.array.isRequired,
  valueProp: PropTypes.string,
  labelProp: PropTypes.string,
};

export const BudgetSelect = React.forwardRef(({projectId, onCreateNewBudgetCode, ...rest}, ref) => {
  const menuFooterStyle = {
    paddingLeft: "12px",
    paddingRight: "12px",
    paddingBottom: "12px",
  };

  return (
    <ReactSelectApi
      url={`projects/${projectId}/budgets/`}
      label="Budget"
      placeholder="Budget"
      ref={ref}
      components={{
        MenuList: ({children, ...rest}) => (
          <>
            <components.MenuList {...rest}>{children}</components.MenuList>
            {onCreateNewBudgetCode && (
              <a onClick={() => onCreateNewBudgetCode()}>
                <div style={menuFooterStyle}>
                  <i className="fa fa-plus" /> Create New Budget Code
                </div>
              </a>
            )}
          </>
        ),
        Option: ({data, ...rest}) => (
          <components.Option {...rest}>
            <div>{`${data.display} (${data.allocation})`}</div>
          </components.Option>
        ),
        SingleValue: ({data, ...rest}) => (
          <components.SingleValue {...rest}>
            <div>{`${data.display} - (${data.allocation})`}</div>
          </components.SingleValue>
        ),
      }}
      {...rest}
    />
  );
});

export const BudgetAllocationSelect = (props) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...props}>
      {(props) => {
        const {input, className, ...rest} = props;
        return (
          <_BudgetAllocationSelect
            {...input}
            value={input.value}
            onBlur={() => input.onBlur(input.value)}
            onChange={(value) => {
              input.onChange(value ? value.value : value);
            }}
            {...rest}
          />
        );
      }}
    </FinalFieldWrapper>
  );
};

export const ProjectSelect = (props) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...props}>
      {(props) => {
        const {input, className, ...rest} = props;
        return <_ProjectSelect {...input} {...rest} />;
      }}
    </FinalFieldWrapper>
  );
};

export const ProjectContactSelect = ({projectId, ...rest}) => {
  return (
    <FinalFieldWrapper ignoreFormControlClass {...rest}>
      {(props) => {
        const {input, className, ...rest} = props;
        return <_ProjectContactSelect projectId={projectId} {...input} {...rest} />;
      }}
    </FinalFieldWrapper>
  );
};

export const NovoRestrictedSigners = ({projectId, ...rest}) => {
  return (
    <>
      <FinalFieldWrapper ignoreFormControlClass {...rest}>
        {(props) => {
          const {input, className, ...rest} = props;
          return (
            <_ProjectContactSelect
              baseURL={`/projects/${projectId}/contacts/novo-signers/`}
              projectId={projectId}
              {...input}
              {...rest}
            />
          );
        }}
      </FinalFieldWrapper>
      <HelpBlock className="note">Restricted to only allow signers PM and above.</HelpBlock>
    </>
  );
};
