import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {isEmpty} from "lodash";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../api";
import PaperPanel from "../../components/PaperPanel";
import Typography2 from "../../components/Typography2";
import {AutoselectMui, ContactAutoselectMui, SimpleSelect, TextFieldMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import officeChoices from "../../components/forms/choices/offices.json";

export const ContactDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;

  teamOptions: {name: string; id: number; office: {id: number; description: string}}[];
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, teamOptions = [], ...rest} = props;
  const [disableTeamSelect, setDisableTeamSelect] = React.useState(false);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values, initialValues, hasValidationErrors}) => {
            const officeTeamOptions = values?.office
              ? teamOptions.filter((team) => team.office.id === values.office).map((team) => team.id)
              : teamOptions;

            if (isNew && values?.team && !officeTeamOptions.includes(values?.team?.id)) {
              form.change("team", null);
            }

            const validationTest = async (value, values, field) => {
              if (!field.dirty) return;
              if (!value) {
                return "Required";
              }
              const response = await axiosAPI.get(
                `/labor/schedule/contacts/validate-contact/?id=${value.id}${
                  isNew ? "" : `&exclude=${initialValues?.contact?.id}`
                }`
              );
              if (response.data.error) {
                return `${response.data.error} (${response.data.office})`;
              }
            };
            if (!isNew && initialValues?.office !== values.office) {
              form.batch(() => {
                form.change("team", null);
              });
              setDisableTeamSelect(true);
            } else {
              setDisableTeamSelect(false);
            }
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  {isNew ? "Add Labor Schedule Contact" : "Update Labor Schedule Contact"}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          {(isNew || !isEmpty(values.contact)) && (
                            <ContactAutoselectMui
                              name="contact"
                              label="Contact"
                              autoFocus
                              helperText={"Link to NOVO Contact"}
                              qsParams={{
                                company_id: 1547,
                                is_active: true,
                              }}
                              disableClearable={Boolean(initialValues?.contact)}
                              disabled={Boolean(initialValues?.contact)}
                              required
                              fieldProps={{
                                validate: validationTest,
                              }}
                            />
                          )}
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <SimpleSelect name="office" label="Office" required options={officeChoices} />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <AutoselectMui
                            name="team"
                            label="Team"
                            options={
                              values?.office
                                ? teamOptions.filter((team) => team.office.id === values.office)
                                : teamOptions
                            }
                            valueProp={"id"}
                            labelProp={"name"}
                            renderOption={(option) => {
                              return (
                                <>
                                  <Box component="span" mr={0.5}>
                                    {option.name}
                                  </Box>
                                  <Typography2 type="metadata">{option.office.description}</Typography2>
                                </>
                              );
                            }}
                            getOptionLabel={(option) => option.name ?? ""}
                            disabled={disableTeamSelect}
                            helperText={disableTeamSelect ? "Save Changes to Office First" : ""}
                          />
                        </Grid>
                      </Grid>
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton
                    disabled={submitting || pristine || hasValidationErrors}
                    label={isNew ? "Create" : "Save"}
                  />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const TeamDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  offices: {label: string; value: string}[];

  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, offices, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="xl">
        <FinalForm onSubmit={onSubmit} keepDirtyOnReinitialize {...rest}>
          {({handleSubmit, form, submitting, pristine, values, initialValues}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">
                  {isNew ? "Create Labor Schedule Team" : "Update Labor Schedule Team"}
                </DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldMui name="name" label="Team Name" required autoFocus />
                        </Grid>
                        <Grid item xs={12}>
                          <SimpleSelect name="office.id" label="Office" required options={offices} disabled={!isNew} />
                        </Grid>
                      </Grid>
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
