import {faArrowDown, faArrowUp, faArrowUpArrowDown} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {findKey} from "lodash";
import React from "react";

enum OrderingMode {
  DISABLED = "disabled",
  ASCENDING = "ascending",
  DESCENDING = "descending",
}

const useOrderingOptions = (
  fields: string[],
  initialOrdering?: {
    [fieldName: string]: {
      mode: OrderingMode;
    };
  }
): [
  getQueryString: () => string | undefined,
  getIcon: (field: string) => React.JSX.Element,
  cycleOrdering: (field: string) => void,
] => {
  const getInitialOrdering = (initialize: boolean = false) => {
    let ordering = {};
    fields.map((field) => {
      ordering[field] = {mode: OrderingMode.DISABLED};
    });
    if (initialize) ordering = {...ordering, ...initialOrdering};
    return ordering;
  };

  const [orderingData, setOrderingData] = React.useState(() => getInitialOrdering(true));

  const getIcon = (field: string) => {
    switch (orderingData[field].mode) {
      case OrderingMode.ASCENDING:
        return <FontAwesomeIcon icon={faArrowUp} />;
      case OrderingMode.DESCENDING:
        return <FontAwesomeIcon icon={faArrowDown} />;
      default:
        return <FontAwesomeIcon icon={faArrowUpArrowDown} />;
    }
  };

  const cycleOrdering = (field: string) => {
    switch (orderingData[field].mode) {
      case OrderingMode.DISABLED:
        setOrderingData(() => ({...getInitialOrdering(), [field]: {mode: OrderingMode.ASCENDING}}));
        break;
      case OrderingMode.ASCENDING:
        setOrderingData(() => ({...getInitialOrdering(), [field]: {mode: OrderingMode.DESCENDING}}));
        break;
      case OrderingMode.DESCENDING:
        setOrderingData(() => ({...getInitialOrdering(), [field]: {mode: OrderingMode.DISABLED}}));
        break;
    }
  };

  const getQueryString = () => {
    const fieldName = findKey(orderingData, (field) => field["mode"] !== OrderingMode.DISABLED);
    return fieldName ? `${orderingData[fieldName].mode === OrderingMode.DESCENDING ? "-" : ""}${fieldName}` : undefined;
  };

  return [getQueryString, getIcon, cycleOrdering];
};

export default useOrderingOptions;
