import {faPalette} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Breadcrumbs,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {ConfirmationDialog} from "../../components/Dialogs";
import {TextFieldMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import {MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import PaperPanel from "../../components/PaperPanel";

const DesignTemplates = (props) => {
  const [confirmationIsOpen, setConfirmationIsOpen] = React.useState(false);
  const [showDialogForm, setShowDialogForm] = React.useState(false);
  return (
    <>
      <Helmet title="Design Templates" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography color="textPrimary">Design Templates</Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faPalette} /> Design Templates
          </PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <PaperPanel>
            <PaperPanel.Header>
              <PaperPanel.Header.Title>
                <FontAwesomeIcon icon={faPalette} /> Paper Panel
              </PaperPanel.Header.Title>
              <PaperPanel.Header.Actions>
                <PaperPanel.Header.Action>Action</PaperPanel.Header.Action>
              </PaperPanel.Header.Actions>
            </PaperPanel.Header>
            <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar>
            <PaperPanel.Body p={1}>Body</PaperPanel.Body>
            <PaperPanel.Footer p={1}>Footer</PaperPanel.Footer>
          </PaperPanel>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setConfirmationIsOpen(true);
            }}
          >
            Show Confirmation Dialog
          </Button>
          <Box mb={2} />
          <Button
            variant="contained"
            fullWidth
            onClick={() => {
              setShowDialogForm(true);
            }}
          >
            Show Form Dialog
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Form
            onSubmit={(values) => {
              console.log("onSubmit", values);
            }}
          />
        </Grid>
      </Grid>

      <ConfirmationDialog
        isOpen={confirmationIsOpen}
        onApprove={() => {
          setConfirmationIsOpen(false);
        }}
        onDeny={() => {
          setConfirmationIsOpen(false);
        }}
      >
        This is a confirmation dialog
      </ConfirmationDialog>

      <DialogForm
        isOpen={showDialogForm}
        isNew={true}
        handleClose={() => {
          setShowDialogForm(false);
        }}
        onSubmit={(values) => {
          setShowDialogForm(false);
          console.log("onSubmit", values);
        }}
      />
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default DesignTemplates;

const Form = (props: {onSubmit: (values: any) => void; [rest: string]: any}) => {
  const {onSubmit, isOpen, handleClose, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {({handleSubmit, form, submitting, pristine, values}) => (
        <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
          <BeforeUnload block={!pristine} />
          <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
            <PaperPanel.Body>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldMui name="field1" label="Field1" required autoFocus />
                </Grid>
              </Grid>
              <pre>{JSON.stringify(values, null, 2)}</pre>
            </PaperPanel.Body>
          </FinalFormKeyboardShortcuts>
          <FormActions>
            <FormActions.Left>
              <FormActions.SaveButton disabled={submitting || pristine} />
            </FormActions.Left>
            <FormActions.Right>Right</FormActions.Right>
          </FormActions>
        </form>
      )}
    </FinalForm>
  );
};

const DialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Dialog Form Title</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextFieldMui name="field1" label="Field1" required autoFocus />
                      </Grid>
                    </Grid>
                    <pre>{JSON.stringify(values, null, 2)}</pre>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
