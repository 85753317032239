import {Box, Button, Typography} from "@material-ui/core";
import {LabelValue} from "../../components/LabelValue";

const PretaskPlanReviseResubmitSpeedBump = (props) => {
  const {project, twilioContact, pretaskPlan, ...rest} = props;
  const isSpanish = twilioContact?.language === "SP";
  return (
    <>
      <Box textAlign="center" mb={2} mt={3}>
        <Typography variant="h5">
          {isSpanish ? (
            <>
              Su plan de tareas previo para hoy tiene problemas que deben corregirse. Revise su plan de tareas previo y
              vuelva a enviarlo.
            </>
          ) : (
            <>
              Your Pretask Plan for today has issues that need to be corrected. Please revise your Pretask Plan and
              resubmit it.
            </>
          )}
        </Typography>
        <Box mb={3} />
        <LabelValue
          label={isSpanish ? "Comentarios del Auditor:" : "Auditor Comments:"}
          value={isSpanish ? pretaskPlan.translated_auditor_comments : pretaskPlan.auditor_comments}
        />
        <Box mb={3} />
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          //   startIcon={<FontAwesomeIcon icon={faListRadio} />}
          href={pretaskPlan.url}
        >
          {isSpanish ? "Plan De Tareas Previo" : "Go To Pretask Plan"}
        </Button>
      </Box>
    </>
  );
};
export default PretaskPlanReviseResubmitSpeedBump;
