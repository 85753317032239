import {faExternalLink, faStar} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, PaperProps} from "@material-ui/core";
import classnames from "classnames";
import Moment from "react-moment";
import {useSentinelListAPI} from "../hooks/useSentinelAPI";
import {makeNovoClasses} from "../theme";
import PaperPanel from "./PaperPanel";
import {ReactTableMui} from "./ReactTableMui";

const Followers = (props: {url: string; pageSize?: number; paperPanelProps?: PaperProps}) => {
  const {url, paperPanelProps = {}, ...rest} = props;
  const novoClasses = makeNovoClasses();
  const {query: followersQuery} = useSentinelListAPI(`${url}`, {
    initialData: {results: []},
    // https://react-query.tanstack.com/guides/paginated-queries
    keepPreviousData: true,
  });

  return (
    <PaperPanel square variant="outlined" {...paperPanelProps}>
      <PaperPanel.Header isLoading={followersQuery.isFetching}>
        <PaperPanel.Header.Title>
          <FontAwesomeIcon icon={faStar} /> Followers
        </PaperPanel.Header.Title>
        <PaperPanel.Header.Actions>
          <PaperPanel.Header.Action border>
            <PaperPanel.Header.RefreshButton
              onClick={() => followersQuery.refetch()}
              isFetching={followersQuery.isFetching}
            />
          </PaperPanel.Header.Action>
        </PaperPanel.Header.Actions>
      </PaperPanel.Header>
      <PaperPanel.Body>
        <ReactTableMui
          size="small"
          className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
          rightAlignColumns={["timestamp"]}
          columns={[
            {
              Header: "Contact",
              accessor: "contact.full_name",
              Cell: ({value, row}) => (
                <Link href={`${row.original.contact.url}`} target="_blank">
                  <FontAwesomeIcon icon={faExternalLink} /> {value}
                </Link>
              ),
            },
            {
              Header: "Company",
              accessor: "contact.company.name",
            },
            {
              Header: "Created",
              accessor: "created",
              Cell: ({value, row}) => <Moment calendar withTitle date={value} />,
            },
          ]}
          initialState={{
            sortBy: [
              {
                id: "contact.company.name",
              },
              {
                id: "contact.full_name",
              },
            ],
          }}
          data={followersQuery.data.results}
          // data={[]}
          {...rest}
        />
        {/* <pre>{JSON.stringify(followersQuery, null, 2)}</pre> */}
      </PaperPanel.Body>
    </PaperPanel>
  );
};

export default Followers;
