import {faExternalLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Link} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import {Alert} from "@material-ui/lab";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import {ContactAutoselectMui, ProjectContactAutoselectMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";

export const DocuSignCreateEnvelopeDialogForm = (props: {
  object: any;
  onSubmit: (values: any) => void;
  isOpen: boolean;
  signers: any[];
  project?: any;
  currentEnvelope?: any;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {project, object, onSubmit, isOpen, handleClose, isNew = false, currentEnvelope, signers, ...rest} = props;
  const hasProject = Boolean(project);
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Create New DocuSign Envelope</DialogTitle>
              <DialogContent>
                {currentEnvelope?.status === "Created" && (
                  <Box mb={2}>
                    <Alert severity="warning">
                      The current envelope is in draft mode and will be deleted if a new envelope is created.
                    </Alert>
                  </Box>
                )}
                {currentEnvelope?.status === "Completed" && (
                  <Box mb={2}>
                    <Alert severity="warning">
                      You have a completed (signed) envelope for this object. Are you sure you want to send a new
                      envelope? This might be confusing.
                    </Alert>
                  </Box>
                )}
                {currentEnvelope?.can_void && (
                  <Box mb={2}>
                    <Alert severity="warning">
                      The current envelope status is {currentEnvelope?.status} - it will be voided if a new envelope is
                      created.
                    </Alert>
                  </Box>
                )}
                <Box mb={2}>
                  <h2>Signers</h2>
                  {signers.map((signer) => {
                    return (
                      <div>
                        ({signer?.routing_order}) <strong>{signer?.contact?.full_name} </strong> -{" "}
                        <Link href={`mailto:${signer?.contact?.email}`}>{signer?.contact?.email}</Link>
                      </div>
                    );
                  })}
                </Box>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      {hasProject ? (
                        <ProjectContactAutoselectMui projectId={project.id} name="cc_contacts" label="CC" multiple />
                      ) : (
                        <ContactAutoselectMui name="cc_contacts" label="CC" multiple />
                      )}
                    </Grid>
                  </Grid>
                  <p>
                    This will create a new DocuSign envelope for{" "}
                    <Link href={object.url} underline="always" target="_blank">
                      {hasProject ? (
                        <>
                          {`${project.display}: ${object.display}`} <FontAwesomeIcon icon={faExternalLink} />
                        </>
                      ) : (
                        <>
                          {`${object.display}`} <FontAwesomeIcon icon={faExternalLink} />
                        </>
                      )}
                    </Link>
                  </p>
                  <p>
                    Please ensure{" "}
                    <Link href={object.docusign_envelope_preview_url} underline="always" target="_blank">
                      this document <FontAwesomeIcon icon={faExternalLink} />
                    </Link>{" "}
                    is correct before creating the envelope as it cannot be changed once the envelope has been created
                    without deleting or voiding the envelope.
                  </p>
                  <p>
                    If this document in incorrect it can be fixed{" "}
                    <Link href={object.url} underline="always" target="_blank">
                      here <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                    .
                  </p>
                  {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.CreateButton startIcon={<AddIcon />} label="Create Draft" disabled={submitting} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
