import {faCircleInfo, faCopy} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";
import {sumBy} from "lodash";
import pluralize from "pluralize";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import LabeledGroup from "../../../../js/components/LabeledGroup";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {CurrencyFieldMui} from "../../../components/forms/Fields";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {PageHeader} from "../../../components/PageHeader";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";
import {colorInfo} from "../../../theme/colors";
import {CurrencyItem} from "../ChangeOrders/BudgetPaperItemGrid";
import {NameSCCOForm} from "./SCCOCreateForms";

const SCCOCreate = (props) => {
  const {project} = props;

  const blockUI = useBlockUI();
  const [createSCCODialogIsOpen, setCreateSCCODialogIsOpen] = React.useState(false);
  const [copyAllToCostDialogIsOpen, setCopyAllToCostDialogIsOpen] = React.useState(false);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      internalItems: [],
      pcoItems: [],
    },
  });

  const pageData = pageDataQuery.data;

  const {subContract} = pageData;

  const pcoItems = [...pageData.internalItems, ...pageData.pcoItems];

  const allItemIds = pcoItems.map((item) => item.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  const selectedItems = pcoItems.filter((item) => selectedItemIds.has(item.id));

  const selectedApprovedAmount = sumBy(selectedItems, "cost");

  const onUpdateItemCost = (pcoItem, cost) =>
    axiosAPI.put(pcoItem.api_url, {...pcoItem, cost: cost}, {baseURL: ""}).then(() => pageDataQuery.refetch());

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Items..." />;
  }

  return (
    <>
      <Helmet title={project.name} />
      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/change-order-wizard/`}>
          Change Order Wizard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/contracts/sub/${subContract.id}/`}>
          {subContract.display}
        </MuiNavLink>
        {/* <Typography color="inherit">SCO</Typography> */}
        <Typography color="textPrimary">Create SCO</Typography>
      </Breadcrumbs>
      <PageHeader mt={2}>
        <PageHeader.Left></PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark title="Selected Total Cost" number={selectedApprovedAmount} precision={2} />
        </PageHeader.Right>
      </PageHeader>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              label={"Select All"}
              onChange={(event, value) => {
                if (value) {
                  addSelectedItemIds(allItemIds);
                } else {
                  removeAllSelectedItemIds();
                }
              }}
              indeterminate={Boolean(
                !allItemIdsSelected && selectedItemIds.size < allItemIds.length && selectedItemIds.size
              )}
              checked={allItemIdsSelected}
            />
            Link to PCO Items
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <Tooltip
                title={
                  <>
                    Copy <strong>budget cost</strong> to <strong>cost</strong> for all selected items.
                  </>
                }
              >
                <span>
                  <PaperPanel.Header.Button
                    startIcon={<FontAwesomeIcon icon={faCopy} />}
                    onClick={() => setCopyAllToCostDialogIsOpen(true)}
                    disabled={selectedItemIds.size < 1}
                  >
                    Copy Budget Cost
                  </PaperPanel.Header.Button>
                </span>
              </Tooltip>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action>
              {/* Selected Total Cost:
              <Box mx={1}>
                <strong>
                  <Currency number={selectedApprovedAmount} />
                </strong>
              </Box> */}
              <PaperPanel.Header.CreateButton
                disabled={selectedItemIds.size < 1}
                onClick={() => setCreateSCCODialogIsOpen(true)}
              >
                Create Change Order
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>

        <PaperPanel.Body>
          {pcoItems.length > 0 ? (
            pcoItems.map((pcoItem) => (
              <SCCOCreatePaperItem
                pcoItem={pcoItem}
                pcoItemIsSelected={selectedItemIds.has(pcoItem.id)}
                onChangePCOItemSelected={(event, value) => {
                  if (value) {
                    addSelectedItemId(pcoItem.id, event.nativeEvent.shiftKey);
                  } else {
                    removeSelectedItemId(pcoItem.id);
                  }
                }}
                onUpdateItem={onUpdateItemCost}
                key={pcoItem.id}
              />
            ))
          ) : (
            <Alert severity="warning">
              <AlertTitle>Linkable Items could be found.</AlertTitle>Why? There are no approved{" "}
              <MuiNavLink href={`/v2/projects/${project.id}/pcos/#`} underline="always">
                PCO Items
              </MuiNavLink>{" "}
              that have not already been assigned to an SCO. Make sure that you have approved any{" "}
              <MuiNavLink href={`/v2/projects/${project.id}/pcos/#`} underline="always">
                PCO Items
              </MuiNavLink>{" "}
              that you wish to apply to this SCO.
            </Alert>
          )}
        </PaperPanel.Body>
      </PaperPanel>
      <NameSCCOForm
        isOpen={createSCCODialogIsOpen}
        handleClose={() => setCreateSCCODialogIsOpen(false)}
        onSubmit={({description}) => {
          blockUI.blockUI("Saving...");
          axiosAPI
            .post(`/projects/${project.id}/contracts/sub/${subContract.id}/sccos/`, {
              subcontract_id: subContract.id,
              description: description,
              selected: Array.from(selectedItemIds),
            })
            .then((response) => {
              window.location.href = response.data.url;
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
        counts={{selectedApprovedAmount: selectedApprovedAmount, selectedItemCount: selectedItemIds.size}}
        initialValues={{description: `${subContract.description}`}}
      />
      <Box mb={1} />
      <ConfirmationDialog
        isOpen={copyAllToCostDialogIsOpen}
        onApprove={() => {
          setCopyAllToCostDialogIsOpen(false);
          blockUI.blockUI("Copying...");
          axiosAPI
            .post(`/projects/${project.id}/contracts/sub/${subContract.id}/sccos/copy-budget-to-cost/`, {
              selected: Array.from(selectedItemIds),
            })
            .then(() => {
              pageDataQuery.refetch().then(() => blockUI.unblockUI());
            })
            .catch(() => blockUI.unblockUI());
        }}
        onDeny={() => {
          setCopyAllToCostDialogIsOpen(false);
        }}
      >
        You want to copy the <strong>budget cost</strong> to the <strong>cost</strong> for all selected PCO items. This
        will affect {selectedItemIds.size} {pluralize("item", selectedItemIds.size)} and can not be undone.
      </ConfirmationDialog>
      <LegacyUILink href={`/ccos/scco/${project.id}/sco-wizard/link/${subContract.id}/cor/`} />
      {/* <Typography variant="h2">PageData</Typography>
      <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
    </>
  );
};

export default SCCOCreate;

const SCCOCreatePaperItem = (props) => {
  const {pcoItem, pcoItemIsSelected, onChangePCOItemSelected, onUpdateItem} = props;
  const itemCount = pcoItem.pcoitem_count;
  return (
    <PaperItem alignItems="center">
      <PaperItem.Left minWidth={25}>
        <PaperItem.SelectedCheckbox checked={pcoItemIsSelected} onChange={onChangePCOItemSelected} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} container spacing={0}>
            <Grid item xs={12}>
              <StatusLabel status={pcoItem.status_display} />
              <Box mr={0.5} component="span" />
              <Link href={pcoItem.url} underline="always">
                {pcoItem.pco_display}
              </Link>
              {pcoItem.pco_is_internal && (
                <Box component="span" ml={0.5}>
                  <Tooltip title="Internal">
                    <FontAwesomeIcon icon={faCircleInfo} color={colorInfo} />
                  </Tooltip>
                </Box>
              )}
            </Grid>
            <Grid item xs={4}>
              <Tooltip title="Budget">
                <span>
                  <Typography2 type="metadata">
                    {pcoItem.budget.display} ({pcoItem.budget.allocation})
                  </Typography2>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={4}>
              <Tooltip title="Description">
                <span>
                  <Typography2 type="metadata">{pcoItem.description}</Typography2>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Item Number In PCO">
                <span>
                  <Typography2 type="metadata">#{pcoItem.number_display}</Typography2>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={2}>
              <Tooltip title="Subcontractor Reference Number">
                <span>
                  <Typography2 type="metadata">{pcoItem.subcontractor_reference_number}</Typography2>
                </span>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <PCOBudgetGrid
              item={pcoItem}
              isReadOnly={false}
              // onUpdateCost={(values) => console.log({...pcoItem, ...values})}
              onUpdateCost={(cost) => onUpdateItem(pcoItem, cost)}
            />
          </Grid>
        </Grid>
      </PaperItem.Body>
    </PaperItem>
  );
};

const PCOBudgetGrid = (props) => {
  const {item, isReadOnly, onUpdateCost, ...rest} = props;

  return (
    <Grid container>
      <Grid item sm={8} xs={12}>
        <Box mr={1}>
          <LabeledGroup label="Budget" fontSize={13}>
            <Grid container>
              <Grid item xs={12} sm={6}>
                <Link underline="always">
                  <CurrencyItem
                    amount={item.budget_approved_amount}
                    // tooltip="Approved (Click to copy to cost)"
                    tooltip={
                      <>
                        <div>Budget Approved</div>
                        <div>(Click to copy to Cost)</div>
                      </>
                    }
                    pointer
                    onClick={() => onUpdateCost(item.budget_approved_amount)}
                  />
                </Link>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Link underline="always">
                  <CurrencyItem
                    amount={item.budget_cost}
                    // tooltip="Budget Cost (Click to copy to Cost)"
                    tooltip={
                      <>
                        <div>Budget Cost</div>
                        <div>(Click to copy to cost)</div>
                      </>
                    }
                    pointer
                    onClick={() => onUpdateCost(item.budget_cost)}
                  />
                </Link>
              </Grid>
            </Grid>
          </LabeledGroup>
        </Box>
      </Grid>

      <Grid item xs={12} sm={4}>
        {/* <LabeledGroup label="Cost" fontSize={11}> */}
        {/* <CurrencyItem amount={item.cost} tooltip="Cost" /> */}
        <Box mt={1}>
          <FinalForm
            onSubmit={(values) => {
              onUpdateCost(values.cost);
            }}
            initialValues={{
              cost: item.cost,
            }}
          >
            {(props) => {
              const {form, pristine} = props;
              return (
                <>
                  {isReadOnly ? (
                    <Currency number={item.cost} precision={2} />
                  ) : (
                    <CurrencyFieldMui
                      name="cost"
                      label="Cost"
                      required
                      onBlur={() => {
                        if (!pristine) {
                          form.submit();
                        }
                      }}
                    />
                  )}
                </>
              );
            }}
          </FinalForm>
        </Box>
        {/* </LabeledGroup> */}
      </Grid>
    </Grid>
  );
};
