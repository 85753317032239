import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@material-ui/core";
import Cookies from "js-cookie";
import {useSnackbar} from "notistack";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router";
import PDFViewer from "../../../js/components/PDFViewer";
import {axiosAPI} from "../../api";
import {DropdownButton} from "../../components/Buttons";
import DropzoneMui from "../../components/DropzoneMui";
import BlockUI from "../../components/GlobalLoaders";
import {LabelValue} from "../../components/LabelValue";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../hooks/useSentinelAPI";
import {EmployeeReviewForm, EmployeeReviewQuestionSetForm} from "./EmployeeReviewForms";

const formatAnswersForForm = (answers) => {
  let formData = {};
  answers.map((answer) => {
    formData[answer.question_code] = {
      rating: answer.answer_rating,
      description: answer.answer_description,
    };
    if (answer?.improvement_items?.groups) {
      let improvementItems = {};
      answer.improvement_items.groups.map((group) => {
        const groupCode = group.group_code || "_";
        group.improvement_items.map((item) => {
          if (item.improvement_item_answer) {
            if (!improvementItems[groupCode]) {
              improvementItems[groupCode] = {};
            }
            improvementItems[groupCode][item.improvement_item_code] = true;
          }
        });
        formData[answer.question_code]["improvement_items"] = improvementItems;
      });
    }
  });
  return formData;
};

const getAnswerForImprovementItem = (questionCode, improvementItemCode, answers) => {
  if (answers[questionCode]?.improvement_items) {
    const flattenedImprovementItems = Object.values(answers[questionCode]?.improvement_items || {});
    return flattenedImprovementItems.some((item) => item[improvementItemCode] === true);
  }
  return false;
};

const formatQuestionAndAnswersForApi = (questions, answers) => {
  // HACK: Disgusting so we don't mutate the original questions
  const clonedQuestions = JSON.parse(JSON.stringify(questions));
  return clonedQuestions.map((question) => {
    const payloadItem = {
      question_code: question.code,
      question_index: question.index,
      question_type: question.type,
      question: question.question,
      question_description: question.description,
      answer_description: answers[question.code]?.description || "",
    };
    if (question.type === "rated") {
      payloadItem["answer_rating"] = answers[question.code]?.rating || "";
    }
    if (question?.improvement_items) {
      payloadItem["improvement_items"] = question.improvement_items;
      payloadItem["improvement_items"]["groups"].map((group) => {
        let improvementItems = [];
        Object.entries(group.improvement_items).map(([key, value]) => {
          improvementItems.push({
            improvement_item_code: key,
            improvement_item_description: value,
            improvement_item_answer: getAnswerForImprovementItem(question.code, key, answers),
          });
        });
        group["improvement_items"] = improvementItems;
      });
    }
    return payloadItem;
  });
};

const EmployeeReviewDetail: React.FC = (props) => {
  const [questions, setQuestions] = React.useState([]);
  const {enqueueSnackbar} = useSnackbar();
  const blockUI = useBlockUI();
  const params = useParams();
  const {type, year} = params;

  //   const apiURL = `/employees/reviews/${type}/${review.get("year")}/${review.get("id")}/`;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      review: {},
      question_set_choices: [],
    },
  });
  const pageData = pageDataQuery.data;

  const {
    query: employeeReviewQuery,
    update: updateEmployeeReview,
    rpc: employeeReviewRPC,
  } = useSentinelDetailAPI(`/employees/reviews/${type}/${year}/${pageData.review.id}/`, {
    initialData: {},
    keepPreviousData: true,
    enabled: Boolean(pageData.review.id),
  });
  const review = employeeReviewQuery.data;
  const title = `${year} - ${review.employee?.full_name} - ${
    type === "self" ? "Self Evaluation" : "Manager Evaluation"
  }`;
  const canSubmit = review.percent_complete >= 1 && !review.is_closed;

  React.useEffect(() => {
    if (review.question_set) {
      axiosAPI.get(`employees/reviews/questions/${review.question_set}/?year=${year}`).then((response) => {
        setQuestions(response.data);
      });
    } else {
      setQuestions([]);
    }
  }, [review.question_set]);

  if (!employeeReviewQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Review..." />;
  }

  if (review.is_manual) {
    return (
      <>
        <Helmet title={title} />
        <Breadcrumbs>
          <MuiNavLink href="/v2/dashboard/">
            <Typography color="textSecondary">Dashboard</Typography>
          </MuiNavLink>
          <Typography color="textPrimary">{title}</Typography>
        </Breadcrumbs>
        <Box mt={2} />
        <PaperPanel>
          <PaperPanel.Header isLoading={employeeReviewQuery.isFetching}>
            <PaperPanel.Header.Title>
              <StatusLabel status={review.status_display} /> {title}
            </PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              {/* <PaperPanel.Header.Action>
                <PaperPanel.Header.PDFButton
                  target="_blank"
                  href={`/reports2/employees/${type}-review/${review.id}/`}
                />
              </PaperPanel.Header.Action> */}
              <PaperPanel.Header.Action>
                <DropdownButton title="Blank Review PDF" size="small" fullWidth>
                  {(setOpen) => (
                    <div>
                      {pageData.question_set_choices.map((choice) => {
                        return (
                          <MenuItem
                            key={choice.year}
                            onClick={() => {
                              window.open(
                                `/reports2/employees/self-review/blank/?question_set=${choice.value}`,
                                "_blank"
                              );
                              setOpen(false);
                            }}
                          >
                            {choice.description}
                          </MenuItem>
                        );
                      })}
                    </div>
                  )}
                </DropdownButton>
              </PaperPanel.Header.Action>

              {pageData.prior_reviews && (
                <PaperPanel.Header.Action border>
                  <DropdownButton title="Prior Reviews" size="small" fullWidth>
                    {(setOpen) => (
                      <div>
                        {pageData.prior_reviews.map((review) => {
                          return (
                            <MenuItem
                              key={review.year}
                              onClick={() => {
                                window.open(review.report_url, "_blank");
                                setOpen(false);
                              }}
                            >
                              {review.year}
                            </MenuItem>
                          );
                        })}
                      </div>
                    )}
                  </DropdownButton>
                </PaperPanel.Header.Action>
              )}
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.RefreshButton
                  onClick={() => employeeReviewQuery.refetch()}
                  isFetching={employeeReviewQuery.isFetching}
                />
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.Header>
          <PaperPanel.Toolbar p={1}>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <LabelValue label="Employee" value={review.employee?.full_name} />
              </Grid>
              <Grid item xs={6}>
                <LabelValue label="Manager" value={review.manager?.full_name} />
              </Grid>
            </Grid>
          </PaperPanel.Toolbar>
          <PaperPanel.Body>
            <PaperPanel.Alert severity="info">
              This review has been marked as a manual paper review by {review.manager?.full_name}. Click{" "}
              <Link
                underline="always"
                style={{cursor: "pointer"}}
                onClick={() => {
                  blockUI.blockUI();
                  // Not using updateEmployeeReview.mutateAsync because it changes it before page is reloaded
                  return axiosAPI
                    .patch(`/employees/reviews/${type}/${review.year}/${review.id}/`, {is_manual: false})
                    .then((response) => {
                      blockUI.unblockUI();
                      window.location.reload();
                    });
                }}
              >
                here
              </Link>{" "}
              to fill out the online form.
            </PaperPanel.Alert>
            {review.manual_pdf && (
              <>
                <PDFViewer url={review.manual_pdf} height="500px" />
                <p />
              </>
            )}
            {!review.is_closed && (
              <DropzoneMui
                getUploadParams={() => {
                  return {
                    url: `/api/v1/employees/reviews/self/${review.year}/${review.id}/upload/`,
                    headers: {"X-CSRFToken": Cookies.get("csrftoken")},
                  };
                }}
                timeout={1000 * 60 * 5}
                accept=".pdf"
                onChangeStatus={(event) => {
                  if (event.meta.status === "rejected_file_type") {
                    enqueueSnackbar("You must upload a PDF", {variant: "error"});
                  }
                  if (event.meta.status === "uploading") {
                    blockUI.blockUI("Uploading...");
                  }

                  if (event.meta.status === "done") {
                    blockUI.unblockUI();
                    window.location.reload();
                  }
                }}
              />
            )}
          </PaperPanel.Body>
        </PaperPanel>
      </>
    );
  }

  return (
    <>
      <Helmet title={title} />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">{title}</Typography>
      </Breadcrumbs>
      <Box mt={2} />
      <PaperPanel>
        <PaperPanel.Header isLoading={employeeReviewQuery.isFetching}>
          <PaperPanel.Header.Title>
            <StatusLabel status={review.status_display} /> {title}
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButton target="_blank" href={`/reports2/employees/${type}-review/${review.id}/`} />
            </PaperPanel.Header.Action>
            {pageData.prior_reviews && (
              <PaperPanel.Header.Action>
                <DropdownButton title="Prior Reviews" size="small" fullWidth>
                  {(setOpen) => (
                    <div>
                      {pageData.prior_reviews.map((review) => {
                        return (
                          <MenuItem
                            key={review.year}
                            onClick={() => {
                              window.open(review.report_url, "_blank");
                              setOpen(false);
                            }}
                          >
                            {review.year}
                          </MenuItem>
                        );
                      })}
                    </div>
                  )}
                </DropdownButton>
              </PaperPanel.Header.Action>
            )}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => employeeReviewQuery.refetch()}
                isFetching={employeeReviewQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {canSubmit && (
          <PaperPanel.Alert severity="warning">
            You have answered all the required questions. Remember to submit the evaluation at the bottom of the screen
            once you're done.
          </PaperPanel.Alert>
        )}
        {/* {type === "self" && (
          <PaperPanel.Alert severity="info">
            If {review.manager?.full_name} is not your manager, please click{" "}
            <MuiNavLink href="/v2/employees/reviews/my-manager/" underline="always">
              here
            </MuiNavLink>{" "}
            to change it.
          </PaperPanel.Alert>
        )} */}
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <LabelValue label="Employee" value={review.employee?.full_name} />
            </Grid>
            <Grid item xs={6}>
              <LabelValue label="Manager" value={review.manager?.full_name} />
            </Grid>

            <Grid item xs={12}>
              <EmployeeReviewQuestionSetForm
                initialValues={review}
                questionSetChoices={pageData.question_set_choices.map((choice) => {
                  return {value: choice.value, label: choice.description};
                })}
                onSubmit={(values) => {
                  //   console.log("update question set", values);
                  updateEmployeeReview.mutateAsync(values);
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <Table size="small">
            <TableBody>
              {Object.values(pageData?.ratings).map((rating: any) => {
                return (
                  <TableRow key={rating.rating}>
                    <TableCell style={{whiteSpace: "nowrap"}}>
                      <strong>{rating.rating}</strong>
                    </TableCell>
                    <TableCell>{rating.description}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
          {/* <pre>{JSON.stringify(tempPayload, null, 2)}</pre> */}
          <Box p={2}>
            <EmployeeReviewForm
              // initialValues={formatAnswersForForm(Immutable.fromJS(review.get("answers")))}
              // initialValues={review.answers}
              initialValues={formatAnswersForForm(review.answers)}
              review={review}
              type={type}
              questions={questions}
              ratingChoices={Object.values(pageData?.ratings).map((rating: any) => {
                return {value: rating.rating, label: rating.rating};
              })}
              onSubmitReview={() => {
                blockUI.blockUI("Submitting...");
                employeeReviewRPC.mutateAsync({action: "mark-submitted"}).then(() => {
                  employeeReviewQuery.refetch().then(() => {
                    blockUI.unblockUI();
                  });
                });
              }}
              onSubmit={(values, form, saveProgress = false) => {
                form.pauseValidation();
                const answersPayload = formatQuestionAndAnswersForApi(questions, values);
                let payload = review;
                payload.answers = answersPayload;
                // console.log("payload", payload);
                updateEmployeeReview.mutateAsync(payload).then(() => {
                  form.resumeValidation();
                  if (saveProgress) {
                    form.submit();
                  }
                });
                // setTempPayload(payload);
              }}
            />
          </Box>
          {/* <pre>{JSON.stringify(review.answers, null, 2)}</pre> */}

          {/* <pre>{JSON.stringify(params, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(employeeReviewQuery, null, 2)}</pre> */}

          {/* <pre>{JSON.stringify(review, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(pageData, null, 2)}</pre> */}
          {/* <pre>{JSON.stringify(questions, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default EmployeeReviewDetail;
