import {Table, TableBody, TableCell, TableRow} from "@material-ui/core";
import {unslugify} from "../../js/utils/string";
import {Currency} from "./Accounting";

const ObjectTable = (props: {
  object: any;
  exclude?: Array<string>;
  currencyFields?: Array<string>;
  fontSize?: number;
  py?: number;
  // All other props
  [rest: string]: any;
}) => {
  const {object = {}, exclude = [], currencyFields = [], fontSize = 10, py = 1, ...rest} = props;
  const tableCellStyle = {fontSize: fontSize, paddingTop: py, paddingBottom: py};
  return (
    <Table {...rest}>
      <TableBody>
        {Object.keys(object).map((key, index) => {
          let value = object[key];
          if (currencyFields.includes(key)) {
            value = <Currency number={value} precision={2} />;
          }
          if (exclude.includes(key)) {
            return null;
          }
          return (
            <TableRow key={key} hover>
              <TableCell style={tableCellStyle}>{unslugify(key)}</TableCell>
              <TableCell align="right" style={tableCellStyle}>
                {value}
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};

export default ObjectTable;
