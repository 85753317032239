//PM UTILITY FUNCTIONS
export function parseCostCode(string, validDivisions) {
  let errorMessage = "Code must me in form [division][code] - [description]";
  if (!string) {
    throw errorMessage;
  }
  if (string.length < 2) {
    throw `Missing division: ${errorMessage}`;
  }
  let division = string.substring(0, 2);
  if ($.inArray(division, validDivisions) === -1) {
    throw `Invalid division ${division}: ${errorMessage}`;
  }
  let lastdash = string.lastIndexOf("-");
  if (lastdash === -1) {
    throw `Invalid format: ${errorMessage}`;
  }
  let code = string.substring(0, lastdash);
  let description = string.substring(lastdash + 1, string.length);
  if (!code) {
    throw `Invalid format: ${errorMessage}`;
  }
  if (!description) {
    throw `Missing Description: ${errorMessage}`;
  }
  return {
    division: division,
    costCode: code.trim(),
    description: description.trim(),
  };
}

export function getResourceURIFromID(app, resource, id) {
  if (!id) {
    return null;
  }
  return `/${app}/api/v1/${resource}/${id}/`;
}

export function getIDFromResourceURI(resourceURI) {
  let parts;
  let i;
  let id;
  if (!resourceURI) {
    return null;
  }
  parts = resourceURI.split("/");
  i = 1;
  id = null;
  while (!id) {
    id = parts[parts.length - i];
    i = i + 1;
  }
  return id;
}

//END PM UTILITY FUNCTIONS

//UTILITY FUNCTIONS
export function pad(num, size) {
  let s = `${num}`;
  while (s.length < size) {
    s = `0${s}`;
  }
  return s;
}

export function stringToBoolean(string) {
  if (string === undefined) {
    return false;
  }
  switch (string.toLowerCase().trim()) {
    case "true":
    case "yes":
    case "1":
    case "on":
      return true;
    case "false":
    case "no":
    case "0":
    case "off":
    case null:
      return false;
    default:
      return Boolean(string);
  }
}

export function tally(selector) {
  $(selector).each(function () {
    let total = 0;
    let column = $(this).siblings(selector).andSelf().index(this);
    $(this)
      .parents()
      .prevUntil(`:has(${selector})`)
      .each(function () {
        let data = $(`td.sum:eq(${column})`, this).text();
        total += parseFloat(data.replace(/[^0-9\.]+/g, "")) || 0;
      });
    $(this).html(total);
  });
}

export function textToHTML(text) {
  if (!text) {
    return "";
  }
  return (
    text
      .replace(/\r\n?/g, "\n")
      // normalize newlines - I'm not sure how these
      // are parsed in PC's. In Mac's they're \n's
      .replace(/(^((?!\n)\s)+|((?!\n)\s)+$)/gm, "")
      // trim each line
      .replace(/(?!\n)\s+/g, " ")
      // reduce multiple spaces to 2 (like in "a    b")
      .replace(/^\n+|\n+$/g, "")
      // trim the whole string
      .replace(/[<>&"']/g, (a) => {
        // replace these signs with encoded versions
        switch (a) {
          case "<":
            return "&lt;";
          case ">":
            return "&gt;";
          case "&":
            return "&amp;";
          case '"':
            return "&quot;";
          case "'":
            return "&apos;";
        }
      })
      .replace(/\n{2,}/g, "</p><p>")
      // replace 2 or more consecutive empty lines with these
      .replace(/\n/g, "<br />")
    // replace single newline symbols with the <br /> entity
    // .replace(/^(.+?)$/, "<p>$1</p>")
  );
  // wrap all the string into <p> tags
  // if there's at least 1 non-empty character
}

export function getParameterByName(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  let regex = new RegExp(`[\\?&]${name}=([^&#]*)`);
  let results = regex.exec(location.search);
  return results === null ? "" : decodeURIComponent(results[1].replace(/\+/g, " "));
}

export function getHash() {
  return document.location.hash.replace(/\/$/, ""); // Remove hashbang trailing slash
}

export function removeDataAttributes(target) {
  let i;
  let $target = $(target);
  let dataAttrsToDelete = [];
  let dataAttrs = $target.get(0).attributes;
  let dataAttrsLen = dataAttrs.length;

  // loop through attributes and make a list of those
  // that begin with 'data-'
  for (i = 0; i < dataAttrsLen; i++) {
    if ("data-" === dataAttrs[i].name.substring(0, 5)) {
      // Why don't you just delete the attributes here?
      // Deleting an attribute changes the indices of the
      // others wreaking havoc on the loop we are inside
      // b/c dataAttrs is a NamedNodeMap (not an array or obj)
      dataAttrsToDelete.push(dataAttrs[i].name);
    }
  }
  // delete each of the attributes we found above
  // i.e. those that start with "data-"
  $.each(dataAttrsToDelete, (index, attrName) => {
    $target.removeAttr(attrName);
  });
}

function addParam(url, param, value) {
  let a = document.createElement("a");
  let regex = /(?:\?|&amp;|&)+([^=]+)(?:=([^&]*))*/gi;
  let params = {};
  let match;
  let str = [];
  a.href = url;
  while ((match = regex.exec(a.search)))
    if (encodeURIComponent(param) != match[1]) str.push(match[1] + (match[2] ? `=${match[2]}` : ""));
  str.push(encodeURIComponent(param) + (value ? `=${encodeURIComponent(value)}` : ""));
  a.search = str.join("&");
  return a.href;
}

export function reloadWithQueryStringVars(queryStringVars) {
  let url = location.href;
  if (queryStringVars) {
    for (let queryStringVar in queryStringVars) {
      if (queryStringVars.hasOwnProperty(queryStringVar)) {
        url = addParam(url, queryStringVar, queryStringVars[queryStringVar]);
      }
    }
  }
  window.location.href = url;
}

export function groupBy(xs, key) {
  return xs.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

//END UTILITY FUNCTIONS

//EXTEND CLASSES
String.prototype.toTitleCase = function () {
  return this.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};
String.prototype.unslugify = function () {
  let str = this.replace(/[-_]/g, " ");
  return str.toTitleCase();
};
String.prototype.trunc = function (n) {
  return this.substr(0, n - 1) + (this.length > n ? "&hellip;" : "");
};

// searchWords
String.prototype.searchWords = function (query) {
  let that = this;
  let toReturn = true;
  $.each(query.split(" "), function () {
    if (!that.toUpperCase().includes(this.toUpperCase())) {
      toReturn = false;
      return false;
    }
  });
  return toReturn;
};
