import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import {Currency} from "../../../components/Accounting";
import {PaperItem} from "../../../components/PaperItem";
import {colorError} from "../../../theme/colors";

export const MissingCommitmentHeaderPaperItem = (props) => {
  return (
    <strong>
      <PaperItem.Header>
        <PaperItem.Body style={{fontSize: 11}}>
          <Box color={colorError}>Commitment Missing</Box>
        </PaperItem.Body>
      </PaperItem.Header>
    </strong>
  );
};

const CommitmentHeaderPaperItem = (props) => {
  const {commitment, ...rest} = props;
  return (
    <strong>
      <PaperItem.Header>
        <PaperItem.Body style={{fontSize: 11}}>
          <Grid container spacing={0}>
            <Grid item xs={2}>
              <Link href={`/timberline/commitments/${commitment?.commitment}/`} underline="always">
                {commitment?.commitment}
              </Link>
            </Grid>
            <Grid item xs={4}>
              {commitment?.description}
            </Grid>
            <Grid item xs={2}>
              <Box textAlign="right">
                <Tooltip title="Total Committed Cost">
                  <span>
                    <Currency number={commitment?.committed} precision={2} />
                  </span>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign="right">
                <Tooltip title="Amount Paid">
                  <span>
                    <Currency number={commitment?.amount_paid} precision={2} />
                  </span>
                </Tooltip>
              </Box>
            </Grid>
            <Grid item xs={2}>
              <Box textAlign="right">
                <Tooltip title="Outstanding Balance">
                  <span>
                    <Currency number={commitment?.outstanding_balance} precision={2} />
                  </span>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
        </PaperItem.Body>
      </PaperItem.Header>
    </strong>
  );
};
export default CommitmentHeaderPaperItem;
