import insurancePolicyTypes from "../../../components/forms/choices/insurancePolicyTypes.json";

import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";

import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import {DatePickerMui, SimpleAutoselectMui, TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {PrevNext} from "../../../components/PrevNext";
import useFormFocusFieldRef from "../../../hooks/useFormFocusFieldRef";

export const PolicyDialogForm = (props) => {
  const {
    isOpen,
    handleClose,
    onSubmit,
    isNew,
    isReadOnly,
    onDeletePolicy,
    activeItem,
    setActiveItem,
    initialFocusField,
    setInitialFocusField,
    beforePrevNext,
    afterPrevNext,
    items,
    ...rest
  } = props;

  const formRef = useFormFocusFieldRef(initialFocusField, [props.initialValues.id, props.initialFocusField]);

  const onFocusChange = (props) => {
    const {active} = props;
    if (setInitialFocusField && active) setInitialFocusField(active);
  };

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values, hasValidationErrors}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={formRef}>
                <ActiveFieldsFormSpy onFocusChange={onFocusChange} />
                <DialogTitle id="form-dialog-title">
                  <Grid container justifyContent="space-between">
                    <Grid item>{isNew ? `Create` : `Edit`} Insurance Policy</Grid>
                    <Grid item>
                      <PrevNext
                        items={items}
                        setActiveItem={setActiveItem}
                        activeItem={activeItem}
                        beforePrevNext={!pristine ? () => beforePrevNext(values) : undefined}
                        // afterPrevNext={!pristine ? () => afterPrevNext() : undefined}
                        disabled={hasValidationErrors && !pristine}
                      />
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <SimpleAutoselectMui options={insurancePolicyTypes} name="type" label="Policy Type" autoFocus />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextFieldMui name="policy_number" label="Policy Number" />
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <TextFieldMui name="description" label="Description" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui name="start_date" label="Policy Start Date" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui name="ex_date" label="Policy Expiration Date" />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  {!isNew && <FormActions.DeleteButton onClick={onDeletePolicy} />}
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const CertificateHoldersForm = (props) => {
  const {isOpen, handleClose, onSubmit, isNew, isReadOnly, onDelete, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">{isNew ? `Create` : `Edit`} Certificate Holder</DialogTitle>
                <DialogContent>
                  {/* <DialogContentText>DialogContentText</DialogContentText> */}
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui name="name" label="Name" />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="address" label="Address" multiline minRows={4} />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  {!isNew && <FormActions.DeleteButton onClick={onDelete} />}
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const AdditionalInsuredForm = (props) => {
  const {onSubmit, ...rest} = props;
  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {({handleSubmit, form, submitting, pristine = true, values}) => (
        <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
          <BeforeUnload block={!pristine} />
          <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
            <Box my={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextFieldMui name="additional_insured" label="Additional Insured" multiline minRows={4} />
                </Grid>
              </Grid>
            </Box>

            <FormActions.SaveButton disabled={submitting || pristine} />
          </FinalFormKeyboardShortcuts>
        </form>
      )}
    </FinalForm>
  );
};
