import PDFObject from "pdfobject";
import PropTypes from "prop-types";
import React from "react";

const PDFViewer = (props) => {
  const {url, width = "100%", height = "100%", containerId = "pdf-viewer"} = props;
  React.useEffect(() => {
    PDFObject.embed(url, `#${containerId}`);
  }, []);

  return <div style={{width, height}} id={containerId} />;
};

PDFViewer.propTypes = {
  url: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  containerId: PropTypes.string,
};

export default PDFViewer;
