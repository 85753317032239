import React from "react";

const useFormFocusFieldRef = (initialFocusField: string, dependencies: any[]) => {
  return React.useCallback((node) => {
    if (!(node?.elements && initialFocusField)) return;
    const field = node.elements[initialFocusField];
    if (typeof field?.focus === "function") {
      field.focus();
      if (typeof field?.focus === "function") {
        setTimeout(() => {
          field.select();
        }, 100);
      }
    }
  }, dependencies);
};

export default useFormFocusFieldRef;
