import {faUserPlus} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Tooltip} from "@material-ui/core";
import classnames from "classnames";
import {useSnackbar} from "notistack";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";

import meetingPriorityChoices from "../../../components/forms/choices/meetingPriorityChoices.json";
import {
  DatePickerMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  SelectImportance,
  SimpleSelect,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";
import {SuperuserInfo} from "../../../components/SuperuserInfo";

const MeetingBusinessItemPrioritySimpleSelect = (props) => {
  return <SelectImportance {...props} options={meetingPriorityChoices} />;
};

export const ItemMinutesForm = (props: {
  isOpen: boolean;
  onSubmit: (values) => void;
  itemName: string;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, handleClose, isOpen, itemName, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Minutes for {itemName}</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <TinyMCEMui name="minutes" label="Minutes" autoFocus />
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const MeetingItemForm = React.forwardRef(
  (
    props: {
      isOpen: boolean;
      onSubmit: (values, form) => void;
      handleClose: () => void;
      isReadOnly?: boolean;
      categories: {value: number; label: string}[];
      isNew?: boolean;
      projectId: number;
      isAgenda?: boolean;
      [rest: string]: any;
    },
    ref
  ) => {
    const {onSubmit, handleClose, isOpen, isReadOnly, categories, isNew, projectId, isAgenda, ...rest} = props;

    return (
      <>
        <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
          <FinalForm onSubmit={onSubmit} {...rest}>
            {({handleSubmit, form, submitting, pristine, values}) => {
              return (
                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off" ref={ref}>
                  <DialogTitle id="form-dialog-title">
                    {isNew ? `Create Meeting Item` : `Edit Meeting Item`}
                  </DialogTitle>
                  <DialogContent>
                    <BeforeUnload block={!pristine} />
                    <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextFieldMui name="title" label="Title" disabled={isReadOnly} required autoFocus />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <SimpleSelect
                            name="meeting_category_id"
                            label="Category"
                            options={categories}
                            disabled={isReadOnly}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <DatePickerMui name="due_date" label="Due Date" disabled={isReadOnly} />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <MeetingBusinessItemPrioritySimpleSelect
                            name="priority"
                            label="Priority"
                            disabled={isReadOnly}
                          />
                          {/* <SimpleSelect
                            name="priority"
                            label="Priority"
                            options={[
                              {value: "low", label: "Low"},
                              {value: "medium", label: "Medium"},
                              {value: "high", label: "High"},
                            ]}
                            disabled={isReadOnly}
                          /> */}
                        </Grid>
                        <Grid item xs={12}>
                          <ProjectCompanyAutoselectMui
                            projectId={projectId}
                            name="assigned_to"
                            label="Assigned To"
                            multiple
                            getOptionDisabled={(option) =>
                              values.assigned_to.map((value) => value.id).includes(option.id)
                            }
                            disabled={isReadOnly}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TinyMCEMui name="description" label="Description" disabled={isReadOnly} />
                        </Grid>
                        {!isAgenda && (
                          <Grid item xs={12}>
                            <TinyMCEMui name="minutes" label="Minutes" />
                          </Grid>
                        )}
                      </Grid>
                      {!isNew && (
                        <SuperuserInfo
                          objects={[
                            {
                              contentType: "meetings",
                              model: "meetingbusinessitem",
                              id: values.id,
                              title: values.display,
                            },
                          ]}
                        />
                      )}
                    </FinalFormKeyboardShortcuts>
                  </DialogContent>
                  <DialogActions>
                    <FormActions.SaveButton disabled={submitting || pristine} />
                    {isNew && (
                      <FormActions.SaveAndAddButton
                        disabled={submitting || pristine}
                        onClick={() => form.change("submitMode", "addAnother")}
                      />
                    )}
                    <FormActions.CancelButton onClick={handleClose} />
                  </DialogActions>
                </form>
              );
            }}
          </FinalForm>
        </Dialog>
      </>
    );
  }
);

export const CategoryForm = (props: {
  isOpen: boolean;
  onSubmit: (values) => void;
  handleClose: () => void;
  onDeleteCategory?: () => void;
  isNew?: boolean;

  [rest: string]: any;
}) => {
  const {onSubmit, handleClose, isOpen, isNew, onDeleteCategory, totalPositions, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">{!isNew ? "Rename" : "Create"} Category</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <TextFieldMui name="description" label="Description" required autoFocus />
                      </Grid>
                      <Grid item xs={4}></Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} />

                  {!isNew && (
                    <Tooltip
                      title={values.default ? "The default category can only be renamed, not deleted." : ""}
                      placement="top"
                    >
                      <span>
                        <FormActions.DeleteButton onClick={() => onDeleteCategory()} disabled={values.default} />
                      </span>
                    </Tooltip>
                  )}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const SelectAttendeesForm = (props: {
  isOpen: boolean;
  onSubmit: (values) => void;
  handleClose: () => void;
  projectId: number;
  selectedAttendees: {contact: {id: number; full_name: string}}[];
  companyContacts: any[];
  onAddContact: (contactId: number) => void;
  [rest: string]: any;
}) => {
  const {
    onSubmit,
    handleClose,
    isOpen,
    projectId,
    selectedAttendees = [],
    companyContacts = [],
    onAddContact,
    ...rest
  } = props;
  const novoClasses = makeNovoClasses();

  const selectedIds = selectedAttendees.map((attendee) => attendee.contact.id);
  const {enqueueSnackbar} = useSnackbar();

  // const displayedContacts = React.useMemo(() => {
  //   return companyContacts.filter((companyContact) => !selectedIds.includes(companyContact.contact.id));
  // }, [companyContacts, selectedAttendees]);

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">Select Attendees</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}></Grid>
                      <Grid item xs={12}>
                        <ReactTableMui
                          size="small"
                          className={classnames(
                            novoClasses.stripedTable,
                            novoClasses.smallTable,
                            novoClasses.boldHeaderTable
                          )}
                          rightAlignColumns={[]}
                          getHeaderProps={(column) => {
                            if (["add"].includes(column.id)) {
                              return {
                                style: {
                                  width: "20px",
                                },
                              };
                            }
                          }}
                          columns={[
                            {
                              id: "add",
                              Cell: ({value, row}) => {
                                return (
                                  <Tooltip title="Add to Meeting">
                                    <Box
                                      textAlign="center"
                                      onClick={() => {
                                        onAddContact(row.original.contact.id);
                                        enqueueSnackbar(`Added ${row.original.contact.full_name}`, {
                                          variant: "success",
                                          autoHideDuration: 500,
                                        });
                                      }}
                                      style={{cursor: "pointer"}}
                                    >
                                      <FontAwesomeIcon icon={faUserPlus} />
                                    </Box>
                                  </Tooltip>
                                );
                              },
                            },
                            // {
                            //   accessor: "id",
                            //   disableSortBy: true,
                            //   Cell: ({value, row}) => (
                            //     <>
                            //       <ReactTableMuiCheckboxSelector
                            //         key={row.original.id}
                            //         id={row.original.id}
                            //         selected={selectedContactIds.has(row.original.contact.id)}
                            //         onAddSelected={addSelectedContactId}
                            //         onRemoveSelected={removeSelectedContactId}
                            //         onClick={(event) => event.preventDefault()}
                            //       />
                            //     </>
                            //   ),
                            // },
                            {
                              Header: "Name",
                              accessor: "contact.full_name",
                              // Cell: ({value, row}) => ({value}),
                            },
                            {
                              Header: "Company",
                              accessor: "contact.company.name",
                              // Cell: ({value, row}) => ({value}),
                            },
                          ]}
                          data={companyContacts.filter(
                            (companyContact) => !selectedIds.includes(companyContact.contact.id)
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui
                          projectId={projectId}
                          name="guest"
                          label="Add Guest Attendee"
                          getOptionDisabled={(option) => values.selected.map((value) => value.id).includes(option.id)}
                          onChange={(e, selection, reason) => {
                            if (reason === "select-option") {
                              onAddContact(selection.id);
                              enqueueSnackbar(`Added ${selection.full_name}`, {
                                variant: "success",
                                autoHideDuration: 500,
                              });
                              form.resetFieldState("guest");
                            }
                          }}
                          minCharacters={3}
                        />
                      </Grid>
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>

                <DialogActions>
                  <FormActions.CloseButton
                    onClick={() => {
                      handleClose();
                    }}
                  />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};
