import {makeStyles, Table, TableBody, TableCell, TableHead, TableRow, useTheme} from "@material-ui/core";
import classnames from "classnames";
import {Currency} from "../../../components/Accounting";
import PaperPanel from "../../../components/PaperPanel";
import {makeNovoClasses} from "../../../theme";

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape, typography} = theme;
  return {
    table: {
      "& th:nth-child(1), td:nth-child(1)": {
        textAlign: "left",
      },
      "& th, td": {
        textAlign: "right",
      },
    },
  };
});

const Overview = (props: {
  project: {id: number; display: string};
  budget: {
    id: number;
    display: string;
    original_budget: number;
    approved_budget_revs: number;
    budget_code: string;
    current_budget: number;
    available_budget: number;
    approved_subcontract_cost_revs: number;
    approved_purchase_order_cost_revs: number;
    current_cost: number;
  };
  isFetching?: Boolean;
}) => {
  const {project, budget, isFetching} = props;
  const theme = useTheme();
  const classes = useStyles();
  const novoClasses = makeNovoClasses();

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={isFetching}>
          <PaperPanel.Header.Title>{budget.display} Overview</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.PDFButtons pdfURL={`/reports2/budgets/${budget.id}/?display_cost=on`}>
                <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/budgets/${budget.id}/`}>
                  Hide Costs
                </PaperPanel.Header.Menu.PDFMenuItem>
              </PaperPanel.Header.PDFButtons>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <Table
            size="small"
            padding="none"
            className={classnames(novoClasses.smallTable, novoClasses.boldHeaderTable, classes.table)}
          >
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>Original</TableCell>
                <TableCell>Approved COR's</TableCell>
                <TableCell>Approved PO's</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Available</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{fontWeight: theme.typography.fontWeightBold}}>Budget</TableCell>
                <TableCell>
                  <Currency number={budget.original_budget} precision={2} />
                </TableCell>
                <TableCell>
                  <Currency number={budget.approved_budget_revs} precision={2} />
                </TableCell>
                <TableCell>
                  <i>n/a</i>
                </TableCell>
                <TableCell>
                  <Currency number={budget.current_budget} precision={2} />
                </TableCell>
                <TableCell>
                  <Currency number={budget.available_budget} precision={2} />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{fontWeight: theme.typography.fontWeightBold}}>Costs</TableCell>
                <TableCell>{/* <Currency number={0} precision={2} /> */}</TableCell>
                <TableCell>
                  <Currency number={budget.approved_subcontract_cost_revs} precision={2} />
                </TableCell>
                <TableCell>
                  <Currency number={budget.approved_purchase_order_cost_revs} precision={2} />
                </TableCell>
                <TableCell>
                  <Currency number={budget.current_cost} precision={2} />
                </TableCell>
                <TableCell>
                  <i>n/a</i>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default Overview;
