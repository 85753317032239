import {faCheckCircle, faTimesCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, makeStyles, Tooltip} from "@material-ui/core";
import React from "react";
import {colorError, colorSuccess} from "../theme/colors";

const useStyles = makeStyles((theme) => {
  return {
    dl: {
      display: "flex",
      flexWrap: "wrap",
      "& dt": {
        width: "33.33%",
        display: "flex",
        fontWeight: "bold",
      },
      // "& dt:after": {
      //   content: ":",
      // },
      "& dd": {
        marginLeft: "auto",
        width: "66.66%",
      },
    },
  };
});

export const LabelValue = (props: {
  label: string | React.ReactElement;
  value?: string | React.ReactElement;
  children?: React.ReactElement | React.ReactElement[];
  [rest: string]: any;
}) => {
  const {label, value, children, ...rest} = props;
  return (
    <Box {...rest}>
      <strong>{label}</strong> <span>{value || children}</span>
    </Box>
  );
};

export const LabelValueList = (props: {children: React.ReactElement | React.ReactElement[]}) => {
  const {children, ...rest} = props;
  const classes = useStyles();
  return (
    <dl className={classes.dl} {...rest}>
      {children}
    </dl>
  );
};

LabelValueList.Item = (props: {label: string | React.ReactElement; value: string | React.ReactElement}) => {
  const {label, value} = props;
  return (
    <>
      <dt>{label}</dt> <dd>{value}</dd>
    </>
  );
};

export const LabelBoolean = (props: {
  label: string | React.ReactElement;
  value: boolean;
  children?: React.ReactElement | React.ReactElement[];
}) => {
  const {label, value, children, ...rest} = props;
  return (
    <Box {...rest}>
      {value ? (
        <Tooltip title="True">
          <span>
            <FontAwesomeIcon icon={faCheckCircle} color={colorSuccess} />
          </span>
        </Tooltip>
      ) : (
        <Tooltip title="False">
          <span>
            <FontAwesomeIcon icon={faTimesCircle} color={colorError} />
          </span>
        </Tooltip>
      )}{" "}
      <strong>{label}</strong>
    </Box>
  );
};
