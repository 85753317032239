import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Switches as FinalSwitches} from "mui-rff";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../js/components/FinalFormEnhancers";
import offices from "../../components/forms/choices/offices.json";
import {
  DatePickerMui,
  EmployeeAutoselectMui,
  SimpleSelect,
  SingleAutocompleteMui,
  TextFieldMui,
} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import PaperPanel from "../../components/PaperPanel";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";

export const EmployeeDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isNew = false, ...rest} = props;
  const {query: titlesQuery} = useSentinelListAPI(`employees/titles`, {
    initialData: [],
  });
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">{isNew ? "Create Employee" : "Update Employee"}</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6}>
                        <TextFieldMui name="first_name" label="First Name" required autoFocus />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextFieldMui name="last_name" label="Last Name" required />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <EmployeeAutoselectMui name="manager" label="Manager" />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SimpleSelect name="office.id" label="Office" required options={offices} />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <SingleAutocompleteMui
                          label="Title"
                          name="title"
                          options={titlesQuery.data.map((option) => {
                            return {
                              value: option,
                              label: option,
                            };
                          })}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <DatePickerMui label="Hire Date" name="hire_date" />
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        <FinalSwitches name="is_active" data={{label: "Is Active", value: true}} />
                      </Grid>
                    </Grid>
                    {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
