import {faCheck, faPause} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, makeStyles, Tooltip} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import PauseIcon from "@material-ui/icons/Pause";
import moment from "moment";
import HtmlRender from "../../../../js/components/HtmlRender";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";

import {ImportanceLabel} from "../../../components/ImportanceLabel";
import {colorMutedText, colorSuccess} from "../../../theme/colors";

const useStyles = makeStyles((theme) => {
  return {
    notes: {
      "& p": {
        marginTop: 0,
        marginBottom: 0,
      },
    },
  };
});

export const MeetingItemPaperItem = (props) => {
  const {
    item,
    projectId,
    updateItem,
    isDragging,
    dragHandleProps,
    onEditItem,
    onDeleteItem,
    onEditMinutes,
    onChangeStatus,
    hideNotes,
    isAgenda,
    ...rest
  } = props;
  const onHold = item.status === "on_hold";

  const classes = useStyles();

  return (
    <PaperItem isDragging={isDragging}>
      <PaperItem.Left minWidth={45}>
        {/* {dragHandleProps && ( */}
        <Box display="flex" alignItems="center">
          <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
          <PaperItem.Position position={item.position} />
        </Box>
        {/*  )} */}
        <Typography2 type="metadata">{item.number_display}</Typography2>
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container spacing={1}>
          <Grid item xs={6} sm={3}>
            <Box style={{cursor: "pointer"}} onClick={onEditItem}>
              {item.title}
            </Box>
          </Grid>
          <Grid item xs={6} sm={3}>
            {item.due_date && moment(item.due_date).format("L")}
          </Grid>
          <Grid item xs={11} sm={5}>
            <Typography2 type="metadata">{item.assigned_to.map((assigned) => assigned.name).join(", ")}</Typography2>
          </Grid>
          <Grid item xs={1}>
            {/* <PriorityIcon priority={item.priority} priorityDisplay={item.priority_display} /> */}
            <ImportanceLabel importance={item.priority_display} />
          </Grid>
          <Grid item xs={12}>
            {!hideNotes && (
              <Box style={{cursor: "pointer"}} onClick={onEditItem}>
                <small className={classes.notes}>
                  <HtmlRender html={item.description} />
                </small>
              </Box>
            )}
          </Grid>

          {item.minutes ? (
            <>
              {!hideNotes && (
                <div onClick={onEditMinutes} style={{cursor: "pointer"}}>
                  <Grid container>
                    <Grid item xs={12}>
                      <small>
                        <strong>Official Minutes</strong>
                      </small>
                    </Grid>
                    <Grid item xs={12}>
                      <small className={classes.notes}>
                        <HtmlRender html={item.minutes} />
                      </small>
                    </Grid>
                  </Grid>
                </div>
              )}
            </>
          ) : (
            !isAgenda && (
              <Grid item xs={12}>
                <Link onClick={onEditMinutes} underline="always" style={{cursor: "pointer"}}>
                  <small>Add Meeting Minutes</small>
                </Link>
              </Grid>
            )
          )}
        </Grid>
      </PaperItem.Body>

      <PaperItem.Right minWidth={isAgenda ? 65 : 105}>
        {!isAgenda && (
          <>
            <Tooltip title={`${onHold ? "" : "Place "} On Hold`}>
              <>
                <FontAwesomeIcon
                  icon={faPause}
                  color={onHold ? colorSuccess : colorMutedText}
                  fixedWidth
                  onClick={() => {
                    onChangeStatus(onHold ? "open" : "on_hold");
                  }}
                  style={{cursor: "pointer"}}
                />
              </>
            </Tooltip>
            <Tooltip title={item.status === "closed" ? "Complete" : "Not Complete"}>
              <FontAwesomeIcon
                icon={faCheck}
                color={item.status === "closed" ? colorSuccess : colorMutedText}
                fixedWidth
                onClick={() => {
                  onChangeStatus(item.status === "closed" ? "open" : "closed");
                }}
                style={{cursor: "pointer"}}
              />
            </Tooltip>
          </>
        )}
      </PaperItem.Right>

      <PaperItem.RightHover>
        {!isAgenda && (
          <>
            <PaperItem.RightHover.IconButton
              icon={PauseIcon}
              title={`${onHold ? "" : "Place "} On Hold`}
              onClick={() => {
                onChangeStatus(onHold ? "open" : "on_hold");
              }}
              iconProps={{style: {color: onHold ? colorSuccess : undefined}}}
            />
            <PaperItem.RightHover.IconButton
              icon={CheckIcon}
              title={item.status === "closed" ? "Complete" : "Not Complete"}
              onClick={() => {
                onChangeStatus(item.status === "closed" ? "open" : "closed");
              }}
              iconProps={{style: {color: item.status === "closed" ? colorSuccess : undefined}}}
            />
          </>
        )}
        <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditItem} />
        <PaperItem.RightHover.IconButton icon={DeleteIcon} title="Delete" onClick={onDeleteItem} />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

// const PriorityIcon = (props: {priority: string; priorityDisplay: string}) => {
//   const {priority, priorityDisplay, ...rest} = props;

//   const getPriorityIcon = React.useMemo(() => {
//     switch (priority) {
//       case "low":
//         return <FontAwesomeIcon icon={faInfoCircle} color={colorInfo} />;

//       case "medium":
//         return <FontAwesomeIcon icon={faInfoCircle} color={colorWarning} />;

//       case "high":
//         return <FontAwesomeIcon icon={faExclamationCircle} color={colorError} />;

//       case "":
//         return null;
//       default:
//         return <FontAwesomeIcon icon={faCircle} />;
//     }
//   }, [priority, priorityDisplay]);

//   return (
//     <Tooltip title={`${priorityDisplay} Priority`}>
//       <span>{getPriorityIcon}</span>
//     </Tooltip>
//   );
// };
