import {Grid} from "@material-ui/core";
import LabeledGroup from "../../../../js/components/LabeledGroup";
import {CurrencyItem} from "./BudgetPaperItemGrid";

const PCCOItemPaperItemGrid = (props) => {
  const {item, ...rest} = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <LabeledGroup label="Budget" fontSize={11}>
          <Grid container>
            <Grid item xs={12} sm={4}>
              <CurrencyItem amount={item.budget_rom} tooltip="ROM" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyItem amount={item.budget_proposed_amount} tooltip="Proposed" />
            </Grid>
            <Grid item xs={12} sm={4}>
              <strong>
                <CurrencyItem amount={item.budget_approved_amount} tooltip="Approved" />
              </strong>
            </Grid>
          </Grid>
        </LabeledGroup>
      </Grid>
    </Grid>
  );
};

export default PCCOItemPaperItemGrid;
