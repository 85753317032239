import {Box, makeStyles, useTheme} from "@material-ui/core";
import React from "react";
import {colorError} from "../../v2/theme/colors";
import {color} from "@material-ui/system";
import classNames from "classnames";

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    fieldset: {
      // borderColor: palette.text.secondary,
      borderWidth: 1,
      borderRadius: shape.borderRadius,
      borderStyle: "solid",
      paddingBlockStart: 0,
      paddingBlockEnd: spacing(0.5),
      marginInline: 0,
      "& legend": {
        fontSize: 13 * 0.75,
        // color: palette.text.secondary,
      },
    },
    error: {
      borderColor: colorError,
    },
  };
});

const LabeledGroup = (props: {
  label: string | JSX.Element;
  children: React.ReactNode;
  error?: boolean;
  [rest: string]: any;
}) => {
  const {label, children, error, ...rest} = props;
  const classes = useStyles();
  const theme = useTheme();
  return (
    <>
      <fieldset className={classNames(classes.fieldset, {[classes.error]: error})}>
        <legend>{label}</legend>
        <Box {...rest}>{children}</Box>
      </fieldset>
    </>
  );
};

export default LabeledGroup;
