import {Avatar, Box, Divider, ListItemIcon, MenuItem, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import DoneIcon from "@material-ui/icons/Done";
import EditIcon from "@material-ui/icons/Edit";
import moment from "moment";
import nl2br from "../../../../js/utils/nl2br";
import {MoreMenuButton} from "../../../components/Buttons";
import {colorError} from "../../../theme/colors";

const RFIResponse = (props: {
  response: any;
  onUpdate: (any) => void;
  onDelete: (any) => void;
  onMarkAsFinalAnswer: (any) => void;
  // All other props
  [rest: string]: any;
}) => {
  const {response, onUpdate, onDelete, onMarkAsFinalAnswer, ...rest} = props;
  const borderColor = "grey.300";
  return (
    <Box mt={1} border={1} borderRadius={4} borderColor={borderColor} overflow="hidden" {...rest}>
      <Box
        display="flex"
        bgcolor="grey.50"
        py={0.5}
        px={1}
        borderColor={borderColor}
        border={1}
        borderLeft={0}
        borderRight={0}
        borderTop={0}
      >
        <Box mr={1}>
          <Avatar
            alt={response.author.full_name}
            src={response.author.avatar_url ? `${response.author.avatar_url}?d=mm` : null}
          />
        </Box>
        <Box>
          <Typography variant="subtitle2">
            <strong>{response.author.full_name}</strong> - <small>{response.author.company.name}</small>
          </Typography>
          <Typography variant="caption">{moment(response.created).format("LLLL")}</Typography>
        </Box>
        <Box display="flex" flexGrow={1} justifyContent="flex-end" alignItems="center">
          <Typography variant="caption">{response.number_display}</Typography>{" "}
          <MoreMenuButton>
            {(popupState) => (
              <div>
                <MenuItem
                  dense
                  onClick={() => {
                    popupState.close();
                    onUpdate(response);
                  }}
                >
                  <ListItemIcon>
                    <EditIcon />
                  </ListItemIcon>
                  Edit
                </MenuItem>
                <MenuItem
                  dense
                  onClick={() => {
                    popupState.close();
                    onDelete(response);
                  }}
                  style={{color: colorError}}
                >
                  <ListItemIcon>
                    <DeleteIcon style={{color: colorError}} />
                  </ListItemIcon>
                  Delete
                </MenuItem>
                <Divider />
                <MenuItem
                  dense
                  onClick={() => {
                    popupState.close();
                    onMarkAsFinalAnswer(response);
                  }}
                >
                  <ListItemIcon>
                    <DoneIcon />
                  </ListItemIcon>
                  Mark As Final Answer
                </MenuItem>
              </div>
            )}
          </MoreMenuButton>
        </Box>
      </Box>
      <Box py={0.5} px={1}>
        {nl2br(response.response)}
      </Box>
      {/* <pre>{JSON.stringify(response, null, 2)}</pre> */}
      {/* <Divider />
      <Box mb={1} /> */}
    </Box>
  );
};

export const RFIResponses = (props: {
  rfiResponses: any;
  onUpdate: (any) => void;
  onDelete: (any) => void;
  onMarkAsFinalAnswer: (any) => void;
  // All other props
  [rest: string]: any;
}) => {
  const {rfiResponses, onUpdate, onDelete, onMarkAsFinalAnswer, ...rest} = props;

  return (
    <>
      {rfiResponses.results.map((response, index) => {
        return (
          <RFIResponse
            response={response}
            key={response.id}
            mt={index === 0 ? 0 : 1}
            onDelete={onDelete}
            onUpdate={onUpdate}
            onMarkAsFinalAnswer={onMarkAsFinalAnswer}
          />
        );
      })}
    </>
  );
};

export default RFIResponses;
