import {Box, TableHead, Typography} from "@material-ui/core";
import classnames from "classnames";
import {groupBy, sumBy} from "lodash";
import moment from "moment";
import {Currency} from "../../../components/Accounting";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";

const TimberlineCOPanel = (props) => {
  const {timberlineResults, ...rest} = props;
  const novoClasses = makeNovoClasses();

  const groupedByRunNumber = groupBy(timberlineResults, "run");

  const timberlineRunNumbers = Object.keys(groupedByRunNumber);

  const runNumberTotals = timberlineRunNumbers.map((num) => ({
    number: num,
    total: sumBy(groupedByRunNumber[num], "amount"),
  }));

  return (
    <>
      <ReactTableMui
        size="small"
        className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
        rightAlignColumns={["total"]}
        showFooter={Boolean(runNumberTotals.length)}
        columns={[
          {
            Header: "Run",
            accessor: "number",
            Cell: ({value, row}) => value,
          },
          {
            Header: "Amount",
            accessor: "total",
            Cell: ({value, row}) => <Currency number={value} />,
            Footer: () => {
              const total = runNumberTotals.reduce((sum, row) => row.total + sum, 0);
              return total ? <Currency number={total} /> : "";
            },
          },
        ]}
        data={runNumberTotals}
      />

      <Box my={4} />

      <TableHead>
        <Box px={3}>
          <Typography variant="h6">Timberline Owner Change Order Detail</Typography>
        </Box>
      </TableHead>

      <ReactTableMui
        size="small"
        className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
        rightAlignColumns={["amount"]}
        showFooter={Boolean(timberlineResults.length)}
        columns={[
          {
            Header: "Seq",
            accessor: "sequence",
            Cell: ({value, row}) => value,
          },
          {
            Header: "Cost Code",
            accessor: "cost_code",
            Cell: ({value, row}) => value,
          },
          {
            Header: "Cat",
            accessor: "category",

            Cell: ({value, row}) => value,
          },
          {
            Header: "Trans Date",
            accessor: "transaction_date",
            sortType: "basic",
            Cell: ({value, row}) => moment(value).format("YYYY-MM-DD"),
          },
          {
            Header: "Transaction Type",
            accessor: "transaction_type",
            sortType: "basic",
            Cell: ({value, row}) => value,
          },
          {
            Header: "Description",
            accessor: "source",
            sortType: "basic",
            Cell: ({value, row}) => value,
          },
          {
            Header: "Run",
            accessor: "run",
            sortType: "basic",
            Cell: ({value, row}) => value,
          },
          {
            Header: "Operator",
            accessor: "operator_stamp",
            sortType: "basic",
            Cell: ({value, row}) => value,
          },
          {
            Header: "Amount",
            accessor: "amount",
            sortType: "basic",
            Cell: ({value, row}) => <Currency number={value} precision={2} />,
            Footer: () => {
              const total = timberlineResults.reduce((sum, row) => row.amount + sum, 0);
              return total ? <Currency number={total} /> : "";
            },
          },
        ]}
        initialState={{
          sortBy: [
            {
              id: "commitment",
              desc: false,
            },
            {
              id: "commitment_co",
              desc: false,
            },
          ],
        }}
        data={timberlineResults}
      />
    </>
  );
};
export default TimberlineCOPanel;
