import {Box, Button, Grid, Typography} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import moment, {Moment} from "moment";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterDate} from "../../../components/Filters";
import BlockUI from "../../../components/GlobalLoaders";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelDetailAPI} from "../../../hooks/useSentinelAPI";
import WeatherCard from "./WeatherCard";

const ProjectWeather = (props) => {
  const [date, setDate] = useQueryState("date", moment().format("YYYY-MM-DD"));
  const {project, ...rest} = props;
  const blockUI = useBlockUI();

  const {query: weatherQuery, rpc: weatherRPC} = useSentinelDetailAPI(`/projects/${project.id}/weather/${date}/`, {
    initialData: {},
  });
  const weatherData = weatherQuery.data;

  if (!weatherQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching weather data..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Weather`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Weather</Typography>
      </ProjectBreadcrumbs>

      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title maxWidth="100%">
            <Box
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <img style={{height: "18px", marginRight: 2}} src={weatherData.summary_icon} /> Weather for{" "}
              {moment(weatherData.date).format("ddd, ll")} - {weatherData.summary}
            </Box>
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={weatherQuery.isFetching}
                onClick={() => {
                  blockUI.blockUI("Fetching weather data...");
                  weatherRPC.mutateAsync({action: "refresh"}).then(() => {
                    weatherQuery.refetch();
                    blockUI.unblockUI();
                  });
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={3} xs={12}>
              <FilterDate
                name="Date"
                value={date}
                label="Date"
                onChange={setDate}
                // onChange={(date) => {
                //   setDate(date);
                // }}
              />
            </Grid>
            <Grid item sm={9} xs={12}>
              <Box textAlign="center" display="flex" justifyContent="flex-end">
                <PrevNextDate date={date} setDate={setDate} />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <WeatherCard weatherData={weatherData.morning} />
            </Grid>
            <Grid item sm={4} xs={12}>
              <WeatherCard weatherData={weatherData.midday} />
            </Grid>
            <Grid item sm={4} xs={12}>
              <WeatherCard weatherData={weatherData.evening} />
            </Grid>
          </Grid>
          {/* <pre>{JSON.stringify(weatherQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>

      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default ProjectWeather;

const PrevNextDate = (props: {
  date: string | Moment;
  setDate: (values: any) => void;
  displayProp?: string;
  [rest: string]: any;
}) => {
  const {date = moment(), setDate, displayProp, ...rest} = props;

  const getAdjacentDates = (date) => {
    const tomorrow = moment(date).add(1, "d");
    const yesterday = moment(date).subtract(1, "d");

    return {
      previous: yesterday.format("YYYY-MM-DD"),
      next: tomorrow.isAfter() ? null : tomorrow.format("YYYY-MM-DD"),
    };
  };

  const {previous, next} = getAdjacentDates(date);

  return (
    <>
      {previous && (
        <Button onClick={() => setDate(previous)} size={"small"} startIcon={<ArrowBackIcon />}>
          {displayProp && previous ? previous[displayProp] : `Previous`}
        </Button>
      )}
      {next && (
        <Button onClick={() => setDate(next)} size={"small"} endIcon={<ArrowForwardIcon />}>
          {displayProp && next ? next[displayProp] : `Next`}
        </Button>
      )}
    </>
  );
};
