import React from "react";
import {useSentinelDetailAPI} from "./hooks/useSentinelAPI";
import useUser from "./hooks/useUser";

export const AppContext = React.createContext({
  user: {
    id: null,
    full_name: null,
    is_superuser: null,
  },
  permissions: {},
  project: null,
  timezone: "",
  waffle: {
    FLAGS: {} as any,
    SWITCHES: {} as any,
    SAMPLES: {} as any,
  },
  apiKeys: {
    googleMapsAPIKey: null,
  },
});

export const AuthenticatedContext = React.createContext({
  notificationQuery: {},
});

export const AuthenticatedContextProvider = (props) => {
  const user = useUser();
  const {query: notificationQuery} = useSentinelDetailAPI(["users", user.id, "notifications", "stats"], {
    initialData: {},
    // refetchOnWindowFocus: true,
  });
  return (
    <AuthenticatedContext.Provider value={{notificationQuery: notificationQuery}}>
      {props.children}
    </AuthenticatedContext.Provider>
  );
};
