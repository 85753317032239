import arrayMove from "array-move";
import {Method} from "axios";
import Immutable from "immutable";
import {isEqual, last} from "lodash";
import qs from "query-string";
import React from "react";
import {useMutation, useQuery, useQueryClient} from "react-query";
import {axiosAPI} from "./api";
import {AppContext} from "./context";

export const useSentinelDetailApi = (url, initialData = null, method = "GET") => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [apiData, setApiData] = React.useState(initialData || Immutable.fromJS({}));

  const fetch = () => {
    setIsFetching(true);
    return axiosAPI({
      url: url,
      method: method as Method,
    })
      .then((response) => {
        setIsFetching(false);
        setApiData(Immutable.fromJS(response.data));
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };

  const update = (data, method = "PUT", url_ = url) => {
    setIsUpdating(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        setIsUpdating(false);
        const data = Immutable.fromJS(response.data);
        setApiData(data);
        return data;
      })
      .catch((response) => {
        setIsUpdating(false);
        setIsError(true);
      });
  };

  const delete_ = (method = "DELETE", url_ = url) => {
    setIsDeleting(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
    })
      .then((response) => {
        setIsDeleting(false);
        setApiData(Immutable.fromJS(response.data));
      })
      .catch((response) => {
        setIsDeleting(false);
        setIsError(true);
      });
  };

  const rpc = (action, data = {}, method = "POST", url_ = url) => {
    url_ = `${url_}${action}/`;
    if (url_.includes("?")) {
      url_ = url_.replace(/\/$/, "");
    }
    setIsFetching(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        setIsFetching(false);
        return Immutable.fromJS(response.data);
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };

  return {
    data: apiData,
    set: setApiData,
    isFetching,
    isUpdating,
    isDeleting,
    isError,
    fetch,
    update,
    delete: delete_,
    rpc,
  };
};

export const useSentinelListApi = (baseURL, initialData = null, extraFetchParams = {}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isFetchingNextPage, setIsFetchingNextPage] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [apiResults, setApiResults] = React.useState(Immutable.fromJS(initialData || Immutable.fromJS([])));
  const [apiMetadata, setApiMetadata] = React.useState(Immutable.fromJS({}));

  const fetchNextPage = (url, method = "GET") => {
    setIsFetchingNextPage(true);
    return axiosAPI({
      url: url,
      method: method as Method,
    })
      .then((response) => {
        setIsFetchingNextPage(false);
        const results = Immutable.fromJS(response.data.results);
        setApiResults((prevApiResults) => {
          return prevApiResults.push(...results);
        });
        const metadata = apiMetadata.merge(Immutable.fromJS(response.data.metadata));
        setApiMetadata(
          metadata.merge({
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
          })
        );
        if (response.data.next) {
          fetchNextPage(response.data.next);
        }
        return response.data.results;
      })
      .catch((response) => {
        setIsFetchingNextPage(false);
        setIsError(true);
      });
  };

  const fetch = (pageSize = 1000, method = "GET") => {
    setIsFetching(true);
    return axiosAPI({
      url: `${baseURL}?page_size=${pageSize}&${qs.stringify(extraFetchParams)}`,
      method: method as Method,
    })
      .then((response) => {
        setIsFetching(false);
        const results = Immutable.fromJS(response.data.results);
        setApiResults(results);
        const metadata = apiMetadata.merge(Immutable.fromJS(response.data.metadata));
        setApiMetadata(
          metadata.merge({
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
          })
        );
        if (response.data.next) {
          fetchNextPage(response.data.next);
        }
        return results;
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };

  const create = (data, insertIndex = null, method = "POST") => {
    setIsCreating(true);
    return axiosAPI({
      url: baseURL,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        const newData = Immutable.fromJS(response.data);
        setIsCreating(false);
        if (insertIndex == null) {
          setApiResults(apiResults.push(newData));
        } else {
          setApiResults(apiResults.insert(insertIndex, newData));
        }
        return newData;
      })
      .catch((response) => {
        setIsCreating(false);
        setIsError(true);
      });
  };

  const update = (id, data, method = "PATCH") => {
    setIsUpdating(true);
    const updatedDataIndex = apiResults.findIndex((_item) => {
      return _item.get("id") === id;
    });
    setApiResults(apiResults.set(updatedDataIndex, apiResults.get(updatedDataIndex).merge(Immutable.fromJS(data))));

    return axiosAPI({
      url: `${baseURL}${id}/`,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        const updatedData = Immutable.fromJS(response.data);
        const updatedDataIndex = apiResults.findIndex((_item) => {
          return _item.get("id") === updatedData.get("id");
        });
        setIsUpdating(false);
        setApiResults(apiResults.set(updatedDataIndex, updatedData));
        return updatedData;
      })
      .catch((response) => {
        setIsUpdating(false);
        setIsError(true);
      });
  };

  const delete_ = (id, method = "DELETE") => {
    setIsDeleting(true);
    return axiosAPI({
      url: `${baseURL}${id}/`,
      method: method as Method,
    })
      .then((response) => {
        const resultsWithoutDeletedItem = apiResults.filterNot((_item) => {
          return _item.get("id") === id;
        });
        setIsDeleting(false);
        setApiResults(resultsWithoutDeletedItem);
      })
      .catch((response) => {
        setIsDeleting(false);
        setIsError(true);
      });
  };

  const bulkUpdate = (ids, update, method = "PUT", url_ = `${baseURL}update/`) => {
    setIsUpdating(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: {
        ids: ids,
        update: update,
      },
    })
      .then((response) => {
        setIsUpdating(false);
      })
      .catch((response) => {
        setIsUpdating(false);
        setIsError(true);
      });
  };
  const bulkDelete = (ids, method = "DELETE", url_ = `${baseURL}delete/`) => {
    setIsDeleting(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: {
        ids: ids,
      },
    })
      .then((response) => {
        setIsDeleting(false);
      })
      .catch((response) => {
        setIsDeleting(false);
        setIsError(true);
      });
  };
  const onDragEnd = (result, method = "PATCH", items) => {
    if (!result.destination) {
      return Promise.reject("No drop destination found");
    }
    if (result.source.index === result.destination.index) {
      return Promise.reject("Dropped in same location");
    }
    // We do this in case items are filtered on front end (internal/external PCOs)
    items = items || apiResults;
    const movedItem = items.get(result.source.index);
    const resultsWithItemMoved = items.remove(result.source.index).insert(result.destination.index, movedItem);
    setIsFetching(true);
    setApiResults(resultsWithItemMoved);
    return axiosAPI({
      url: `${baseURL}${movedItem.get("id")}/`,
      method: method as Method,
      data: {
        position: result.destination.index,
      },
    })
      .then((response) => {
        setIsFetching(false);
        // fetch();
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };
  const rpc = (action, data = {}, method = "POST", url_ = baseURL) => {
    url_ = `${url_}${action}/`;
    if (url_.includes("?")) {
      url_ = url_.replace(/\/$/, "");
    }
    setIsFetching(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        setIsFetching(false);
        return Immutable.fromJS(response.data);
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };
  return {
    data: apiResults,
    metadata: apiMetadata,
    set: setApiResults,
    isFetching,
    isFetchingNextPage,
    isCreating,
    isUpdating,
    isDeleting,
    isError,
    fetch,
    create,
    update,
    delete: delete_,
    bulkUpdate,
    bulkDelete,
    rpc,
    onDragEnd,
  };
};

export const useSentinelDetailApi2 = (url, initialData = null, method = "GET") => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [apiData, setApiData] = React.useState(initialData || {});

  const fetch = () => {
    setIsFetching(true);
    return axiosAPI({
      url: url,
      method: method as Method,
    })
      .then((response) => {
        setIsFetching(false);
        setApiData(response.data);
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };

  const update = (data, method = "PUT", url_ = url) => {
    setIsUpdating(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        setIsUpdating(false);
        const data = response.data;
        setApiData(data);
        return data;
      })
      .catch((response) => {
        setIsUpdating(false);
        setIsError(true);
      });
  };

  const delete_ = (method = "DELETE", url_ = url) => {
    setIsDeleting(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
    })
      .then((response) => {
        setIsDeleting(false);
        setApiData(response.data);
      })
      .catch((response) => {
        setIsDeleting(false);
        setIsError(true);
      });
  };

  const rpc = (action, data = {}, method = "POST", url_ = url) => {
    url_ = `${url_}${action}/`;
    if (url_.includes("?")) {
      url_ = url_.replace(/\/$/, "");
    }
    setIsFetching(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        setIsFetching(false);
        return response.data;
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };

  return {
    data: apiData,
    set: setApiData,
    isFetching,
    isUpdating,
    isDeleting,
    isError,
    fetch,
    update,
    delete: delete_,
    rpc,
  };
};

export const useSentinelListApi2 = (baseURL, initialData = [], extraFetchParams = {}) => {
  const [isFetching, setIsFetching] = React.useState(false);
  const [isUpdating, setIsUpdating] = React.useState(false);
  const [isCreating, setIsCreating] = React.useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  // const [isLoadingNextPage, setIsLoadingNextPage] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [apiResults, setApiResults] = React.useState(initialData || []);
  const [apiMetadata, setApiMetadata] = React.useState({});

  const fetch = (url = null, pageSize = 1000, method = "GET") => {
    setIsFetching(true);
    const fetchURL = url || `${baseURL}?page_size=${pageSize}&${qs.stringify(extraFetchParams)}`;
    return axiosAPI({
      url: fetchURL,
      method: method as Method,
    })
      .then((response) => {
        setIsFetching(false);
        const results = response.data.results;
        setApiResults(results);
        setApiMetadata({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
        return results;
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };

  const create = (data, insertIndex = null, method = "POST") => {
    setIsCreating(true);
    return axiosAPI({
      url: baseURL,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        const newData = response.data;
        setIsCreating(false);
        if (insertIndex == null) {
          apiResults.push(newData);
          // TODO: seems like we should do this but turned off for perf
          // setApiResults([...apiResults]);
        } else {
          apiResults.splice(insertIndex, 0, newData);
          // TODO: seems like we should do this but turned off for perf
          // setApiResults([...apiResults]);
        }
        return newData;
      })
      .catch((response) => {
        setIsCreating(false);
        setIsError(true);
      });
  };

  const update = (id, data, method = "PATCH") => {
    setIsUpdating(true);
    const updatedDataIndex = apiResults.findIndex((_item) => {
      return _item.id === id;
    });
    apiResults[updatedDataIndex] = Object.assign({}, apiResults[updatedDataIndex], data);
    // TODO: seems like we should do this but turned off for perf
    // setApiResults([...apiResults]);

    return axiosAPI({
      url: `${baseURL}${id}/`,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        const updatedData = response.data;
        const updatedDataIndex = apiResults.findIndex((_item) => {
          return _item.id === updatedData.id;
        });
        setIsUpdating(false);
        apiResults[updatedDataIndex] = updatedData;
        // TODO: seems like we should do this but turned off for perf
        // setApiResults([...apiResults]);
        return updatedData;
      })
      .catch((response) => {
        setIsUpdating(false);
        setIsError(true);
      });
  };
  const delete_ = (id, method = "DELETE") => {
    setIsDeleting(true);
    const deletedDataIndex = apiResults.findIndex((_item) => {
      return _item?.id === id;
    });
    delete apiResults[deletedDataIndex];
    return axiosAPI({
      url: `${baseURL}${id}/`,
      method: method as Method,
    })
      .then((response) => {
        setIsDeleting(false);
        // TODO: seems like we should do this but turned off for perf
        // const resultsWithoutDeletedItem = apiResults.filter((_item) => {
        //   return _item.id !== id;
        // });
        // const deletedDataIndex = apiResults.findIndex((_item) => {
        //   return _item.id === id;
        // });
        // setApiResults([...resultsWithoutDeletedItem]);
        // delete apiResults[deletedDataIndex];
      })
      .catch((response) => {
        setIsDeleting(false);
        setIsError(true);
      });
  };
  const bulkUpdate = (ids, update, method = "PUT", url_ = `${baseURL}update/`) => {
    setIsUpdating(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: {
        ids: ids,
        update: update,
      },
    })
      .then((response) => {
        setIsUpdating(false);
      })
      .catch((response) => {
        setIsUpdating(false);
        setIsError(true);
      });
  };
  const bulkDelete = (ids, method = "DELETE", url_ = `${baseURL}delete/`) => {
    setIsDeleting(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: {
        ids: ids,
      },
    })
      .then((response) => {
        setIsDeleting(false);
      })
      .catch((response) => {
        setIsDeleting(false);
        setIsError(true);
      });
  };
  const onDragEnd = (result, method = "PATCH", items, positionProp = "position") => {
    if (!result.destination) {
      return Promise.reject("No drop destination found");
    }
    if (result.source.index === result.destination.index) {
      return Promise.reject("Dropped in same location");
    }
    // We do this in case items are filtered on front end (internal/external PCOs)
    items = items || apiResults;
    const movedItem = items[result.source.index];
    const resultsWithItemMoved = arrayMove(items, result.source.index, result.destination.index);
    setIsFetching(true);
    setApiResults(resultsWithItemMoved);
    let data = {};
    data[positionProp] = result.destination.index;

    return axiosAPI({
      url: `${baseURL}${movedItem.id}/`,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        setIsFetching(false);
        // fetch();
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };
  const rpc = (action, data = {}, method = "POST", url_ = baseURL) => {
    url_ = `${url_}${action}/`;
    if (url_.includes("?")) {
      url_ = url_.replace(/\/$/, "");
    }
    setIsFetching(true);
    return axiosAPI({
      url: url_,
      method: method as Method,
      data: data,
    })
      .then((response) => {
        setIsFetching(false);
        return response.data;
      })
      .catch((response) => {
        setIsFetching(false);
        setIsError(true);
      });
  };
  return {
    data: apiResults,
    metadata: apiMetadata,
    set: setApiResults,
    isFetching,
    isCreating,
    isUpdating,
    isDeleting,
    isError,
    fetch,
    create,
    update,
    delete: delete_,
    bulkUpdate,
    bulkDelete,
    rpc,
    onDragEnd,
  };
};

export const useSentinelListAPI3 = (queryKey, useQueryConfig = {}, additionalProps: {idProp?: string} = {}) => {
  const queryClient = useQueryClient();
  const {idProp = "id"} = additionalProps;
  let url = queryKey;
  if (Array.isArray(queryKey)) {
    url = `/${queryKey.join("/")}/`;
  }
  const baseURL = url.split("?")[0];

  const query = useQuery(queryKey, () => axiosAPI.get(url).then((res) => res.data), {
    // initialData: [],
    retry: false,
    refetchOnWindowFocus: false,
    ...useQueryConfig,
  });
  const create = useMutation((newObject) => axiosAPI.post(url, newObject).then((res) => res.data), {
    onMutate: (newData) => {
      const previousValue: {results: any[]} = queryClient.getQueryData(queryKey);
      // let newQueryData = {...previousValue};
      // newQueryData.results.push(newData);
      // queryClient.setQueryData(queryKey, newQueryData);
      return previousValue;
    },
    onSuccess: (newObject, oldObject, previousData: {results: any[]}) => {
      let newQueryData = {...previousData};
      newQueryData.results.push(newObject);
      queryClient.setQueryData(queryKey, newQueryData);
    },
    onError: (error, variables, previousValue) => {
      queryClient.setQueryData(queryKey, previousValue);
    },
  });
  const update = useMutation(
    (newObject: {id: number}) => {
      return axiosAPI.patch(`${baseURL}${newObject[idProp]}/`, newObject).then((res) => res.data);
    },
    {
      onMutate: (newData) => {
        const previousValue: {results: any[]} = queryClient.getQueryData(queryKey);
        let newQueryData = {...previousValue};
        newQueryData.results = previousValue.results.map((obj) => (newData[idProp] == obj[idProp] ? newData : obj));
        queryClient.setQueryData(queryKey, newQueryData);
        return previousValue;
      },
      onSuccess: (newObject, oldObject, previousData: {results: any[]}) => {
        let newQueryData = {...previousData};
        newQueryData.results = previousData.results.map((obj) => (newObject[idProp] == obj[idProp] ? newObject : obj));
        queryClient.setQueryData(queryKey, newQueryData);
      },
      onError: (error, variables, previousValue) => {
        queryClient.setQueryData(queryKey, previousValue);
      },
    }
  );
  const delete_ = useMutation((id: number) => axiosAPI.delete(`${baseURL}${id}/`).then((res) => res.data), {
    onMutate: (id) => {
      const previousValue: {results: any[]} = queryClient.getQueryData(queryKey);
      let newQueryData = {...previousValue};
      newQueryData.results = previousValue.results.filter((obj) => id !== obj[idProp]);
      queryClient.setQueryData(queryKey, newQueryData);
      return previousValue;
    },
    onSuccess: () => {
      queryClient.invalidateQueries(queryKey);
    },
    onError: (error, variables, previousValue) => {
      queryClient.setQueryData(queryKey, previousValue);
    },
  });

  const onDragEnd = (result, positionProp = "position") => {
    if (!result.destination) {
      return Promise.reject("No drop destination found");
    }
    if (result.source.index === result.destination.index) {
      return Promise.reject("Dropped in same location");
    }
    const previousValue: {results: any[]; current_page_number: number; page_size: number} =
      queryClient.getQueryData(queryKey);
    const movedItem = previousValue.results[result.source.index];
    const pageOffset = (previousValue.current_page_number - 1) * previousValue.page_size;
    const position = result.destination.index + pageOffset;
    movedItem[positionProp] = position;
    let newQueryData = {...previousValue};
    const reorderedResults = arrayMove(newQueryData.results, result.source.index, result.destination.index).map(
      (item, index) => {
        item[positionProp] = index + pageOffset;
        return item;
      }
    );
    newQueryData.results = reorderedResults;
    queryClient.setQueryData(queryKey, newQueryData);
    // update.mutateAsync(movedItem).then(() => queryClient.invalidateQueries(queryKey));
    return update.mutateAsync(movedItem);
  };

  const rpc = useMutation(
    (options: {action: string; data?: any; method?: Method; baseURL?: string}) => {
      const {action, data = {}, method = "POST", baseURL = url} = options;

      let url_ = `${baseURL}${action}/`;
      if (url_.includes("?")) {
        url_ = url_.replace(/\/$/, "");
      }
      // return axiosAPI.post(url, newObject).then((res) => res.data);
      return axiosAPI({
        url: url_,
        method: method,
        data: data,
      }).then((res) => res.data);
    },
    {
      onMutate: (newData) => {
        // queryCache.setQueryData(queryKey, newData);
      },
      onSuccess: (newData) => {
        queryClient.invalidateQueries(queryKey);
      },
    }
  );

  return {
    query,
    create,
    update,
    delete: delete_,
    onDragEnd: onDragEnd,
    rpc,
  };
};

export const useBlockUI = () => {
  const blockUI = () => {
    jQuery.blockUI();
  };
  const unblockUI = () => {
    jQuery.unblockUI();
  };
  return [blockUI, unblockUI];
};

export const useInterval = (callback, delay) => {
  const savedCallback = React.useRef();

  // Remember the latest callback.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useAsyncState = (initialValue) => {
  const [value, setValue] = React.useState(initialValue);
  const setter = (x) =>
    new Promise((resolve) => {
      setValue(x);
      resolve(x);
    });
  return [value, setter];
};

export const useSmallBox = () => {
  return {
    smallBoxSuccess: (message) => {
      jQuery.smallBoxSuccess({
        content: message,
      });
    },
    smallBoxInfo: (message) => {
      jQuery.smallBoxInfo({
        content: message,
      });
    },
    smallBoxError: (message) => {
      jQuery.smallBoxError({
        content: message,
      });
    },
  };
};

export const useSelectIds = (allIds, cacheKey = undefined) => {
  const [selectedIds, setSelectedIds] = React.useState(new Set());
  if (!cacheKey) {
    cacheKey = JSON.stringify(allIds);
  }
  const removeSelectedId = React.useCallback(
    (id) => {
      setSelectedIds((prevSelectedIds) => {
        let newIds = new Set(prevSelectedIds);
        newIds.delete(id);
        return newIds;
      });
    },
    // SEE: https://www.benmvp.com/blog/object-array-dependencies-react-useEffect-hook/
    [cacheKey]
  );
  const removeSelectedIds = React.useCallback(
    (ids) => {
      setSelectedIds((prevSelectedIds) => {
        ids.map((id) => prevSelectedIds.delete(id));
        return new Set(prevSelectedIds);
      });
    },
    [cacheKey]
  );
  const addSelectedId = React.useCallback(
    (id, selectBetween = false) => {
      if (selectBetween) {
        setSelectedIds((prevSelectedIds) => {
          const idsArray = Array.from(prevSelectedIds);
          const lastSelection = last(idsArray);

          const currentSelectedIndex = allIds.findIndex((item) => item === id);
          const lastSelectedIndex = allIds.findIndex((item) => item === lastSelection);

          const newIds = allIds.slice(
            Math.min(lastSelectedIndex, currentSelectedIndex),
            Math.max(lastSelectedIndex, currentSelectedIndex) + 1
          );
          return new Set([...prevSelectedIds, ...newIds]);
        });
      } else {
        setSelectedIds((prevSelectedIds) => new Set(prevSelectedIds.add(id)));
      }
    },
    [cacheKey]
  );
  const addSelectedIds = React.useCallback(
    (ids) => {
      setSelectedIds((prevSelectedIds) => new Set([...prevSelectedIds, ...ids]));
    },
    [cacheKey]
  );
  const addAllSelectedIds = React.useCallback(() => {
    setSelectedIds(new Set(allIds));
  }, [cacheKey]);
  const removeAllSelectedIds = React.useCallback(() => {
    setSelectedIds(new Set());
  }, []);

  const allIdsSelected = isEqual(selectedIds, new Set(allIds)) && selectedIds.size !== 0;

  return {
    selectedIds,
    addSelectedId,
    addSelectedIds,
    removeSelectedId,
    removeSelectedIds,
    addAllSelectedIds,
    removeAllSelectedIds,
    allIdsSelected,
  } as const;
  // as const SEE: https://pauledenburg.com/typescript-not-all-constituents-of-type-boolean-void-are-callable/
};

// If you need to set a cookie outside of a React component, you can use the named setCookie export
export const setCookie = (name, value, options) => {
  const optionsWithDefaults = {
    days: 7,
    path: "/",
    ...options,
  };
  const expires = new Date(Date.now() + optionsWithDefaults.days * 864e5).toUTCString();
  document.cookie =
    name + "=" + encodeURIComponent(value) + "; expires=" + expires + "; path=" + optionsWithDefaults.path;
};

// If you need to access a cookie outside of a React component, you can use the named getCookie export:
export const getCookie = (name) => {
  return document.cookie.split("; ").reduce((r, v) => {
    const parts = v.split("=");
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, "");
};

export const useCookie = (key, initialValue) => {
  const [item, setItem] = React.useState(() => {
    return getCookie(key) || initialValue;
  });

  const updateItem = (value, options) => {
    setItem(value);
    setCookie(key, value, options);
  };

  return [item, updateItem];
};

export const useFilterOptions = (initialValue = {}) => {
  const [filterOptions, setFilterOptions] = React.useState(() => initialValue);
  const [filterOptionValues, setFilterOptionValues] = React.useState({});

  React.useEffect(() => {
    const filterOptionValues = {};
    Object.entries(filterOptions).map(([key, value]) => (filterOptionValues[key] = value?.value));
    setFilterOptionValues(filterOptionValues);
  }, [filterOptions]);

  const addFilterOption = (param, value, description, valueDescription) => {
    setFilterOptions((priorState) => {
      priorState[param] = {
        value: value,
        description: description,
        valueDescription: valueDescription,
      };
      return {...priorState};
    });
  };
  const removeFilterOption = (param) => {
    setFilterOptions((priorState) => {
      delete priorState[param];
      return {...priorState};
    });
  };
  const clearFilterOptions = () => {
    setFilterOptions({});
  };

  return [filterOptions, addFilterOption, removeFilterOption, clearFilterOptions, filterOptionValues];
};

export const useWaffle = () => {
  const appContext = React.useContext(AppContext);
  return appContext.waffle;
};
