import {grey} from "@material-ui/core/colors";
import {VictoryLabel, VictoryPie} from "victory";
import {getPercentage} from "../../components/Accounting";
import {colorError, colorSuccess, colorWarning} from "../../theme/colors";

export const sortByPercentComplete = (prev, curr) => {
  const prevPercent = prev.original.self_review?.percent_complete || -1;
  const currPercent = curr.original.self_review?.percent_complete || -1;
  if (prevPercent > currPercent) {
    return -1;
  } else if (prevPercent < currPercent) {
    return 1;
  } else {
    return 0;
  }
};

const getEmployeeReviewCircularProgressColor = (percent: number) => {
  if (percent >= 1) {
    return colorSuccess;
  } else if (percent >= 0.5) {
    return colorWarning;
  } else if (percent >= 0.0) {
    return colorError;
  } else {
    return grey[200];
  }
};

export const EmployeeReviewCircularProgress = (props: {percent: number}) => {
  const percent = parseFloat(props.percent.toFixed(5));
  const data = [
    {x: 1, y: percent},
    {x: 2, y: 1 - percent},
  ];

  const size = 35;
  return (
    <svg viewBox={`0 0 ${size} ${size}`} height={size} width={size}>
      <VictoryPie
        standalone={false}
        // animate={{duration: 1000}}
        width={size}
        height={size}
        padding={0}
        data={data}
        innerRadius={13}
        // cornerRadius={25}
        labels={() => null}
        style={{
          data: {
            fill: ({datum}) => {
              //   return color;
              return datum.x === 1 ? getEmployeeReviewCircularProgressColor(percent) : grey[200];
            },
          },
        }}
      />
      <VictoryLabel
        textAnchor="middle"
        verticalAnchor="middle"
        x={size * 0.5}
        y={size * 0.5}
        text={`${getPercentage(percent)}%`}
        style={{fontSize: 10}}
      />
    </svg>
  );
};
