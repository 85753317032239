import {faBook, faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Checkbox, Link, Tooltip, Typography} from "@material-ui/core";
import {useStyles} from "@material-ui/pickers/views/Calendar/SlideTransition";
import classnames from "classnames";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {Pagination} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui, ReactTableMuiCheckboxSelector} from "../../../components/ReactTableMui";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {SpecSectionUpdateForm, SpecSectionUploadForm} from "./ProjectSpecSectionForms";

const ProjectSpecSections = (props) => {
  const {project, ...rest} = props;
  const [specSectionCreateFormIsOpen, setSpecSectionCreateFormIsOpen] = React.useState(false);
  const [specSectionUpdateFormIsOpen, setSpecSectionUpdateFormIsOpen] = React.useState(false);
  const [confirmDeleteIsOpen, setConfirmDeleteIsOpen] = React.useState(false);
  const [bulkDeleteConfirmationIsOpen, setBulkDeleteConfirmationIsOpen] = React.useState(false);
  const [createSpecSectionDialogIsOpen, setCreateSpecSectionDialogIsOpen] = React.useState(false);
  const [uploadFormIsOpen, setUploadFormIsOpen] = React.useState(false);
  const [activeSpecSection, setActiveSpecSection] = React.useState({} as any);
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(1000);

  //   const pageDataQuery = useFetchCurrentPage({
  //     refetchOnWindowFocus: false,
  //     initialData: {},
  //   });
  //   const pageData = pageDataQuery.data;

  const {
    query: specSectionQuery,
    create: createSpecSection,
    update: updateSpecSection,
    delete: deleteSpecSection,
    rpc: specSectionRPC,
  } = useSentinelListAPI(`projects/${project.id}/spec-sections/?page_size=${pageSize}&page=${page}`, {
    initialData: {results: []},
    keepPreviousData: true,
  });

  const specSections = specSectionQuery.data.results;

  const classes = useStyles();

  const blockUI = useBlockUI();
  const novoClasses = makeNovoClasses();

  const allSpecSectionIds = specSections.map((section) => section.id);

  const {
    selectedIds: selectedSpecSectionIds,
    addSelectedId: addSelectedSpecSectionId,
    removeSelectedId: removeSelectedSpecSectionId,
    addAllSelectedIds: addAllSelectedSpecSectionIds,
    removeAllSelectedIds: removeAllSelectedSpecSectionIds,
    allIdsSelected: allSpecSectionIdsSelected,
  } = useSelectIds(allSpecSectionIds);

  return (
    <>
      <Helmet title={`${project.display} - Spec Sections`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Spec Sections</Typography>
      </ProjectBreadcrumbs>
      <Box mt={1} />
      <PaperPanel>
        <PaperPanel.Header isLoading={specSectionQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faBook} />
            <Box mr={1} component="span" />
            Spec Sections
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setSpecSectionCreateFormIsOpen(true)} />
              <Box mr={1} />
              <Tooltip title={selectedSpecSectionIds.size < 1 ? `No Spec Sections Selected` : ``}>
                <Box>
                  <PaperPanel.Header.DeleteButton
                    disabled={selectedSpecSectionIds.size < 1}
                    onClick={() => setBulkDeleteConfirmationIsOpen(true)}
                  />
                </Box>
              </Tooltip>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.CreateButton color="default" onClick={() => setCreateSpecSectionDialogIsOpen(true)}>
                Create Known Specs
              </PaperPanel.Header.CreateButton>
              <Box mr={1} />
              <PaperPanel.Header.Button
                onClick={() => setUploadFormIsOpen(true)}
                startIcon={<FontAwesomeIcon icon={faUpload} />}
              >
                Upload Specs
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border mr={-1}>
              <PaperPanel.Header.RefreshButton
                onClick={() => specSectionQuery.refetch()}
                isFetching={specSectionQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            hover
            columns={[
              {
                accessor: "id",
                disableSortBy: true,
                Header: () => (
                  <Checkbox
                    // checked={checked}
                    size="small"
                    style={{width: "20px", padding: 0}}
                    indeterminate={!allSpecSectionIdsSelected && selectedSpecSectionIds.size !== 0}
                    checked={allSpecSectionIdsSelected}
                    onChange={(event, checked) => {
                      if (checked) {
                        addAllSelectedSpecSectionIds();
                      } else {
                        removeAllSelectedSpecSectionIds();
                      }
                    }}
                  />
                ),

                Cell: ({value, row}) => (
                  <ReactTableMuiCheckboxSelector
                    id={row.original.id}
                    selected={selectedSpecSectionIds.has(row.original.id)}
                    onAddSelected={addSelectedSpecSectionId}
                    onRemoveSelected={removeSelectedSpecSectionId}
                    onClick={(event) => event.preventDefault()}
                  />
                ),
              },
              {
                Header: "Code",
                accessor: "code",
                Cell: ({value, row}) => (
                  <Link
                    onClick={() => {
                      setActiveSpecSection(row.original);
                      setSpecSectionUpdateFormIsOpen(true);
                    }}
                    underline="always"
                    style={{cursor: "pointer"}}
                  >
                    {value}
                  </Link>
                ),
              },
              {
                Header: "Description",
                accessor: "description",
                Cell: ({value, row}) => (
                  <Link
                    onClick={() => {
                      setActiveSpecSection(row.original);
                      setSpecSectionUpdateFormIsOpen(true);
                    }}
                    underline="always"
                    style={{cursor: "pointer"}}
                  >
                    {value}
                  </Link>
                ),
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "code",
                  desc: false,
                },
              ],
            }}
            data={specSections}
          />
        </PaperPanel.Body>
        {specSectionQuery.data.total_pages > 1 && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <Pagination
              count={specSectionQuery.data.total_pages}
              page={page}
              // pageSize={pageSize}
              setPage={setPage}
              // setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      <SpecSectionUpdateForm
        isOpen={specSectionCreateFormIsOpen}
        handleClose={() => setSpecSectionCreateFormIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          createSpecSection.mutateAsync(values).then(() => {
            setSpecSectionCreateFormIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        isNew
      />

      <SpecSectionUpdateForm
        isOpen={specSectionUpdateFormIsOpen}
        handleClose={() => setSpecSectionUpdateFormIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateSpecSection.mutateAsync(values).then(() => {
            setSpecSectionUpdateFormIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        initialValues={activeSpecSection}
        onDelete={() => setConfirmDeleteIsOpen(true)}
      />

      <SpecSectionUploadForm
        isOpen={uploadFormIsOpen}
        handleClose={() => setUploadFormIsOpen(false)}
        onSubmit={async (values) => {
          blockUI.blockUI("Analyzing...");
          let formData = new FormData();
          Object.entries(values).map(([key, value]) => {
            /* @ts-ignore */
            formData.append(key, value);
          });
          const response = await axiosAPI
            .post(`/projects/${project.id}/spec-sections/upload/`, formData)
            .then(() => specSectionQuery.refetch());
          blockUI.unblockUI();
          setUploadFormIsOpen(false);
        }}
      />

      <ConfirmationDialog
        isOpen={confirmDeleteIsOpen}
        onDeny={() => setConfirmDeleteIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteSpecSection.mutateAsync(activeSpecSection.id).then(() => {
            setConfirmDeleteIsOpen(false);
            setSpecSectionUpdateFormIsOpen(false);
            blockUI.unblockUI();
          });
        }}
      >
        You want to delete this spec section?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={bulkDeleteConfirmationIsOpen}
        onDeny={() => setBulkDeleteConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          specSectionRPC
            .mutateAsync({action: "delete", method: "DELETE", data: {ids: Array.from(selectedSpecSectionIds)}})
            .then(() => {
              setBulkDeleteConfirmationIsOpen(false);
              blockUI.unblockUI();
            });
        }}
      >
        You want to delete the selected spec sections?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={createSpecSectionDialogIsOpen}
        onDeny={() => setCreateSpecSectionDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Creating...");
          specSectionRPC.mutateAsync({action: "create-all-known-spec-sections"}).then(() => {
            setCreateSpecSectionDialogIsOpen(false);
            blockUI.unblockUI();
          });
        }}
      >
        Are you sure you want create all known spec sections? This will go though all submittals and RFIs and add any
        missing spec section codes.
      </ConfirmationDialog>
    </>
  );
};

export default ProjectSpecSections;
