import {Box, Grid, Typography} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import contractTypes from "../../../components/forms/choices/contractTypes.json";
import GMPTypes from "../../../components/forms/choices/GMPTypes.json";
import offices from "../../../components/forms/choices/offices.json";
import authorizationTypes from "../../../components/forms/choices/projectAuthorizationChoices.json";
import contractWithChoices from "../../../components/forms/choices/projectContractIsWith.json";
import states from "../../../components/forms/choices/usStates.json";
import {
  CheckboxMui,
  CurrencyFieldMui,
  DatePickerMui,
  GoogleMapsAddressAutocompleteMui,
  MaskedInputMui,
  RadioGroupMui,
  SimpleSelect,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";
import {validateEmailAddress} from "../../../utils/validators";

export const JobStartForm = (props) => {
  const {onSubmit, needsJobStartSubmission, ...rest} = props;

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values, initialValues} = props;

        const validateEmail = async (value, values, field) => {
          if (!field.dirty) return;
          if (!value) {
            return;
          }
          if (!validateEmailAddress(value)) {
            return "Not a valid email address";
          }
          const response = await axiosAPI.get(
            `/contacts/validate-email/?email=${value}&exclude=${initialValues.collections_contact_email}`
          );
          if (response.data.error) {
            return response.data.error;
          }
        };
        // console.log(values);
        return (
          <>
            {/* <BeforeUnload block={!pristine} /> */}
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <RadioGroupMui
                      name="contract_is_with"
                      label="Contract Is With"
                      options={contractWithChoices}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroupMui
                      name="bill_to"
                      label="Bill To"
                      options={[
                        {value: "owner", label: "Owner"},
                        {value: "tenant", label: "Tenant"},

                        {value: "other", label: "Other"},
                      ]}
                      required
                    />
                    {values.bill_to === "other" && (
                      <Box mt={1}>
                        <TextFieldMui name="bill_to_other" label="Other" required />
                      </Box>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <RadioGroupMui name="job_start_gmp" label="GMP Type" options={GMPTypes} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <RadioGroupMui
                      name="job_start_authorization"
                      label="Authorization"
                      options={authorizationTypes}
                      required
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldMui
                      name="job_start_authorization_number"
                      label="Job Start Authorization #"
                      helperText="Contract #, PO # etc"
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h3">Collection Contact</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldMui name="collections_contact_name" label="Name" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldMui
                      name="collections_contact_email"
                      label="Email"
                      fieldProps={{
                        validate: validateEmail,
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MaskedInputMui name="collections_contact_cell" label="Cell" mask="(999)-999-9999" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MaskedInputMui name="collections_contact_phone" label="Phone" mask="(999)-999-9999" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <MaskedInputMui name="collections_contact_fax" label="Fax" mask="(999)-999-9999" />
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h3">Other Details</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SimpleSelect name="office" label="Office" required options={offices} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CheckboxMui name="client_is_building_owner" label="Client Is Business Owner" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <SimpleSelect name="type_of_contract" label="Contract Type" required options={contractTypes} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CurrencyFieldMui name="job_start_contract_amount" label="Project Value" required />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldMui name="timberscan_code" label="Timberscan Code" />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextFieldMui name="bid_number" label="Bid Number" />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerMui name="start_date" label="Start Date" required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerMui name="finish_date" label="Finish Date" required />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <DatePickerMui name="warranty_date" label="Warranty Date" />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="h3">Job Address</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    {/* <TextFieldMui name="address1" label="Address 1" required /> */}
                    <GoogleMapsAddressAutocompleteMui
                      name="address1"
                      label="Address 1"
                      onPlaceSelected={(place) => {
                        form.change("address1", place.address1);
                        form.change("address2", place.address2);
                        form.change("city", place.locality_long_name);
                        form.change("state", place.administrative_area_level_1_short_name);
                        form.change("postal_code", place.postal_code);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui name="address2" label="Address 2" />
                  </Grid>
                  <Grid item xs={12}>
                    <TextFieldMui name="address3" label="Address 3" />
                  </Grid>

                  <Grid item xs={6} sm={4}>
                    <TextFieldMui name="city" label="City" required />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <SimpleSelect name="state" label="State" options={states} required />
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <TextFieldMui name="postal_code" label="Postal Code" required />
                  </Grid>
                  <Grid item xs={12}>
                    <TinyMCEMui name="job_start_notes" label="Job Start Notes" />
                  </Grid>
                </Grid>
                <InnerFooter>
                  <Box display="flex">
                    {needsJobStartSubmission && (
                      <Box mr={1}>
                        <FormActions.SaveButton
                          disabled={submitting}
                          onClick={() => {
                            form.change("submitMode", "submit");
                          }}
                          label="Submit"
                        />
                      </Box>
                    )}

                    <FormActions.SaveAndEditButton
                      disabled={submitting || pristine}
                      onClick={() => {
                        form.change("submitMode", "save");
                      }}
                      label="Save and Continue Editing/Finish Later"
                    />
                  </Box>
                </InnerFooter>
              </FinalFormKeyboardShortcuts>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};
