import {Chip, makeStyles, TextField} from "@material-ui/core";
import axios from "axios";
import {Autocomplete as FinalAutocomplete} from "mui-rff";
import qs from "query-string";
import React from "react";
import {Field, useField} from "react-final-form";
import {usePlacesWidget} from "react-google-autocomplete";
import {useQuery} from "react-query";
import {useDebounce} from "use-debounce";
import {staticDataAPI} from "../../../../js/api";
import {identity} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import useAPIKeys from "../../../hooks/useAPIKeys";
import Typography2 from "../../Typography2";

export const useAutocompleteStyles = makeStyles({
  option: {
    display: "block",
    paddingTop: 3,
    paddingBottom: 3,
  },
});

// HACK:  This component was created to fix this issue
// https://github.com/lookfirst/mui-rff/pull/504
// Should be using AutocompleteMui
export const SingleAutocompleteMui = (props: {
  label: string;
  name: string;
  options: string[] | any[];
  onChange?: (event: any, value: any, reason: string) => void;
  fieldProps?: any;
  // All other props
  [rest: string]: any;
}) => {
  const {
    options,
    label,
    name,
    valueProp = "value",
    labelProp = "label",
    onChange,
    validateFields = [],
    fieldProps,
    ...rest
  } = props;
  const {input, meta} = useField(name);

  return (
    <FinalAutocomplete
      classes={useAutocompleteStyles()}
      size="small"
      options={options}
      getOptionLabel={(option) => {
        return option ? option[labelProp] || "" : "";
      }}
      getOptionSelected={(option, value) => {
        return option[valueProp] === value[valueProp];
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return <Chip size="small" variant="outlined" label={<>{option[labelProp]}</>} {...getTagProps({index})} />;
        })
      }
      onInputChange={(event, value, reason) => {
        if (reason === "input") {
          input.onChange(value);
        }
      }}
      onChange={(event, value, reason) => {
        onChange ? onChange(event, value, reason) : null;
        input.onChange(value ? value[valueProp] || "" : "");
      }}
      label={label}
      name={name}
      textFieldProps={{
        autoFocus: props.autoFocus,
        variant: "outlined",
      }}
      fieldProps={{
        parse: identity,
        validate: (value) => {
          if (props.required) {
            return value ? undefined : "Required";
          }
        },
        validateFields: validateFields,
        ...fieldProps,
      }}
      renderOption={(option: any) => <>{option[labelProp]}</>}
      inputValue={input.value}
      disableClearable={props.required}
      freeSolo
      {...rest}
    />
  );
};

export const MultipleAutocompleteMui = (props: {
  label: string;
  name: string;
  options: string[] | any[];
  filterSelectedOptions?: boolean;
  onChange?: (event: any, value: any, reason: string) => void;
  fieldProps?: any;
  // All other props
  [rest: string]: any;
}) => {
  const {
    options = [],
    label,
    name,
    multiple = true,
    filterSelectedOptions = props.filterSelectedOptions || props.multiple || false,
    onChange,
    validateFields = [],
    fieldProps,
    ...rest
  } = props;
  const {input, meta} = useField(name);

  return (
    <FinalAutocomplete
      classes={useAutocompleteStyles()}
      size="small"
      options={options}
      filterSelectedOptions={filterSelectedOptions}
      label={label}
      name={name}
      multiple={multiple}
      textFieldProps={{
        autoFocus: props.autoFocus,
        variant: "outlined",
      }}
      fieldProps={{
        parse: identity,
        validate: (value) => {
          if (props.required) {
            return value ? undefined : "Required";
          }
        },
        validateFields: validateFields,
        ...fieldProps,
      }}
      // TODO: https://github.com/lookfirst/mui-rff/pull/504
      // filterOptions={(options, params) => {
      //   console.log("filterOptions", options, params);
      // }
      // onInputChange={(event, value, reason) => {
      //   if (multiple) return;
      //   if (reason === "input") {
      //     // console.log("onInputChange", event, value, reason);
      //     input.onChange(value);
      //   }
      // }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => <Chip size="small" variant="outlined" label={option} {...getTagProps({index})} />)
      }
      disableClearable={props.required}
      freeSolo
      {...rest}
    />
  );
};

export const ApiAutocompleteMui = (props: {
  label: string;
  name: string;
  baseURL: string;
  objProp?: string;
  queryProp?: string;
  queryParams?: any;
  qsParams?: any;
  minCharacters?: any;
  filterSelectedOptions?: boolean;
  onChange?: (event: any, value: any, reason: string) => void;
  fieldProps?: any;
  // All other props
  [rest: string]: any;
}) => {
  const {
    name,
    label,
    baseURL,
    qsParams = {},
    queryParams = {},
    valueProp = "display",
    queryProp = "q",
    minCharacters = 3,
    filterSelectedOptions = props.filterSelectedOptions || props.multiple || false,
    onChange,
    validateFields = [],
    fieldProps,
    ...rest
  } = props;
  const [q, setQ] = React.useState("");
  const [debouncedQ] = useDebounce(q, 300);
  const {input, meta} = useField(name);

  qsParams[queryProp] = debouncedQ;
  const queryString = qs.stringify(qsParams);
  const shouldQuery = debouncedQ.length >= minCharacters;

  const query = useQuery(
    ["api autocomplete", baseURL, qsParams, debouncedQ],
    () => {
      return axiosAPI.get(`${baseURL}?${queryString}`).then((res) => res.data.results);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
      enabled: shouldQuery,
      ...queryParams,
    }
  );
  const {data: results, isFetching} = query;

  return (
    <FinalAutocomplete
      classes={useAutocompleteStyles()}
      size="small"
      options={results}
      loading={isFetching}
      filterOptions={(options) => options} // Turn off auto filtering
      filterSelectedOptions={filterSelectedOptions}
      onInputChange={(event, value, reason) => {
        if (reason === "input") {
          // console.log("onInputChange", event, value, reason);
          setQ(value);
          input.onChange(value);
        }
      }}
      onChange={(event, value, reason) => {
        // console.log("onChange", event, value, reason);
        // console.log("onChange", value, valueProp);
        onChange ? onChange(event, value, reason) : null;
        input.onChange(value ? value[valueProp] || "" : "");
      }}
      label={label}
      name={name}
      textFieldProps={{
        autoFocus: props.autoFocus,
        variant: "outlined",
      }}
      fieldProps={{
        parse: identity,
        validate: (value) => {
          if (props.required) {
            return value ? undefined : "Required";
          }
        },
        validateFields: validateFields,
        ...fieldProps,
      }}
      renderOption={(option: any) => <>{option[valueProp]}</>}
      noOptionsText={
        query.isFetching
          ? "Searching..."
          : shouldQuery
            ? `No matches found for ${debouncedQ}`
            : `Enter at least ${minCharacters} characters...`
      }
      // inputValue={input.value}
      disableClearable={props.required}
      freeSolo
      {...rest}
    />
  );
};

export const CompanyAutocomplete = (props: {
  label: string;
  name: string;
  // All other props
  [rest: string]: any;
}) => {
  const {label, name, ...rest} = props;
  return (
    <ApiAutocompleteMui
      label={label}
      name={name}
      valueProp="name"
      baseURL="companies/search/"
      qsParams={{
        page_size: 10,
      }}
      renderOption={(option: any) => (
        <>
          <div>{option.name}</div>
          <div>
            <Typography2 type="metadata">{`${option.lookup_code}`}</Typography2>
          </div>
        </>
      )}
      {...rest}
    />
  );
};

export const ContactAutocomplete = (props: {
  label: string;
  name: string;
  // All other props
  [rest: string]: any;
}) => {
  const {label, name, ...rest} = props;
  return (
    <ApiAutocompleteMui
      label={label}
      name={name}
      valueProp="full_name"
      baseURL="contacts/search/"
      qsParams={{
        page_size: 10,
      }}
      renderOption={(option: any) => (
        <>
          <div>{option.full_name}</div>
          <div>
            <Typography2 type="metadata">{`${option.company.name}`}</Typography2>
          </div>
        </>
      )}
      {...rest}
    />
  );
};

export const ProjectTagAutocompleteMui = (props: {
  projectId: number;
  label: string;
  name: string;
  // All other props
  [rest: string]: any;
}) => {
  const {projectId, ...rest} = props;
  const query = useQuery(
    ["tags autocomplete", projectId],
    () => {
      return axiosAPI.get(`/projects/${projectId}/tags/`).then((res) => res.data);
    },
    {
      // initialData: [],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 5,
    }
  );
  const {data: options = [], isFetching} = query;
  return <MultipleAutocompleteMui options={options} loading={isFetching} multiple {...rest} />;
};

export const ProjectDrawingAutocompleteMui = (props: {
  projectId: number;
  label: string;
  name: string;
  // All other props
  [rest: string]: any;
}) => {
  const {projectId, ...rest} = props;
  const query = useQuery(
    ["drawings autocomplete", projectId],
    () => {
      return axiosAPI.get(`/projects/${projectId}/drawings/`).then((res) => res.data);
    },
    {
      // initialData: [],
      retry: false,
      refetchOnWindowFocus: false,
      staleTime: 1000 * 60 * 60,
    }
  );
  const {data: options = [], isFetching} = query;
  return <MultipleAutocompleteMui options={options} loading={isFetching} multiple {...rest} />;
};

export const CSICodeAutocompleteMui = (props: {
  label: string;
  name: string;
  allowDivisions?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {allowDivisions = false, ...rest} = props;

  const jsonFile = allowDivisions ? `novo_division_and_csi_codes.json` : `novo_csi_codes.json`;

  const query = useQuery(
    ["csi autocomplete"],
    () => {
      return staticDataAPI.get(jsonFile).then((resp) => {
        return resp.data.map((csiCode) => {
          return {value: csiCode.code, label: csiCode.display, description: csiCode.description};
        });
      });
    },
    {
      initialData: [],
      retry: false,
      refetchOnWindowFocus: false,
      // staleTime: 1000 * 60 * 5,
    }
  );
  const {data: options, isFetching} = query;

  return <SingleAutocompleteMui options={options} loading={isFetching} {...rest} />;
};

export const SpecSectionAutocompleteMui = (props: {
  projectId: number;
  label: string;
  name: string;
  allowDivisions?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {projectId, allowDivisions = false, ...rest} = props;

  const jsonFile = allowDivisions ? `novo_division_and_csi_codes.json` : `novo_csi_codes.json`;
  const specSectionURL = `/projects/${projectId}/spec-sections/`;
  const budgetURL = `/projects/${projectId}/budgets/`;

  const query = useQuery(
    ["spec section autocomplete", projectId],
    () => {
      return axios.all([axiosAPI.get(specSectionURL), staticDataAPI.get(jsonFile), axiosAPI.get(budgetURL)]).then(
        axios.spread((specResponse, csiResponse, budgetResponse) => {
          const specOptions = specResponse.data.results.map((specSection) => {
            return {
              value: specSection.code,
              label: `${specSection.code} - ${specSection.description}`,
              description: `${specSection.description}`,
              group: "Spec Section",
            };
          });
          const csiOptions = csiResponse.data.map((csiCode) => {
            return {value: csiCode.code, label: csiCode.display, group: "CSI Code"};
          });
          const budgetOptions = budgetResponse.data.results.map((budget) => {
            return {
              value: budget.budget_code,
              label: `${budget.display}`,
              group: "Budget Codes",
            };
          });
          return [...specOptions, ...csiOptions, ...budgetOptions];
        })
      );
    },
    {
      initialData: [],
      retry: false,
      refetchOnWindowFocus: false,
      // staleTime: 1000 * 60 * 5,
    }
  );
  const {data: options, isFetching} = query;

  return (
    <SingleAutocompleteMui options={options} loading={isFetching} groupBy={(option: any) => option.group} {...rest} />
  );
};

export const GoogleMapsAddressAutocompleteMui = (props: {
  name: string;
  label: string;
  onPlaceSelected: (place) => void;
  required?: boolean;
  validateFields?: [any];
  fieldProps?: any;
}) => {
  const {onPlaceSelected, name, label, validateFields = [], fieldProps, ...rest} = props;
  const {googleMapsAPIKey} = useAPIKeys();

  const parseAddress = (place) => {
    const addressComponents = place.address_components;
    const addressFields = [
      "street_number",
      "subpremise",
      "route",
      "locality",
      "administrative_area_level_1",
      "country",
      "postal_code",
      "postal_code_suffix",
    ];
    const addressParts = {
      street_number_long_name: "",
      subpremise_long_name: "",
      route_short_name: "",
      postal_code_long_name: "",
      postal_code_suffix_long_name: "",
    };
    addressFields.map((field) => {
      addressParts[`${field}_long_name`] = "";
      addressParts[`${field}_short_name`] = "";
    });

    addressComponents.forEach((component) => {
      const addressType = component.types[0];
      if (addressFields.includes(addressType)) {
        addressParts[`${addressType}_long_name`] = component.long_name;
        addressParts[`${addressType}_short_name`] = component.short_name;
      }
    });
    addressParts["formatted_address"] = place.formatted_address;
    addressParts["address1"] = `${addressParts.street_number_long_name} ${addressParts.route_short_name}`.trim();
    addressParts["address2"] = `${addressParts.subpremise_long_name}`.trim();
    addressParts["postal_code"] = addressParts.postal_code_suffix_long_name
      ? `${addressParts.postal_code_long_name}-${addressParts.postal_code_suffix_long_name}`
      : addressParts.postal_code_long_name;
    return addressParts;
  };

  const {ref: materialRef} = usePlacesWidget({
    apiKey: googleMapsAPIKey,
    onPlaceSelected: (place) => {
      // console.log(place);
      const parsedAddress = parseAddress(place);
      onPlaceSelected(parsedAddress);
    },
    options: {
      types: ["address"],
      componentRestrictions: {country: "us"},
    },
  });

  return (
    <Field
      name={name}
      parse={identity}
      validate={(value) => {
        if (props.required) {
          return value ? undefined : "Required";
        }
      }}
      validateFields={validateFields}
      {...fieldProps}
    >
      {({input, meta}) => (
        <TextField
          {...input}
          label={label}
          fullWidth
          variant="outlined"
          size="small"
          inputRef={materialRef}
          error={meta.touched && !!meta.error}
          helperText={meta.touched ? meta.error : undefined}
          {...rest}
        />
      )}
    </Field>
  );
};
