import {Box, Grid, Typography} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {isEmpty} from "lodash";
import moment from "moment";
import ReactWeather, {useVisualCrossing} from "react-open-weather";
import {useSentinelDetailAPI} from "../hooks/useSentinelAPI";
import PaperPanel from "./PaperPanel";

export const ProjectWeather = (props) => {
  const {project} = props;

  const {data, isLoading, errorMessage} = useVisualCrossing({
    key: "X6VJXYRPAXL94H6HYDJQCJV7R",
    lat: project.latitude,
    lon: project.longitude,
    lang: "en",
    unit: "us", // values are (metric,us,uk)
  });

  return (
    <>
      <ReactWeather
        isLoading={isLoading}
        errorMessage={errorMessage}
        data={data}
        lang="en"
        locationLabel={project.display}
        unitsLabels={{temperature: "F", windSpeed: "MPH"}}
        showForecast
        theme={{
          fontFamily: "Open Sans, Arial, Helvetica, Sans-Serif",
          gradientStart: "#FFBF3F",
          gradientMid: "#FFBF3F",
          gradientEnd: "#FFBF3F",
          locationFontColor: "rgba(0, 0, 0, 0.87)",
          todayTempFontColor: "rgba(0, 0, 0, 0.87)",
          todayDateFontColor: "rgba(0, 0, 0, 0.87)",
          todayRangeFontColor: "rgba(0, 0, 0, 0.87)",
          todayDescFontColor: "rgba(0, 0, 0, 0.87)",
          todayInfoFontColor: "rgba(0, 0, 0, 0.87)",
          todayIconColor: "rgba(0, 0, 0, 0.87)",
          forecastBackgroundColor: "#323232",
          forecastSeparatorColor: "#DDD",
          forecastDateColor: "#FFF",
          forecastDescColor: "#FFF",
          forecastRangeColor: "#FFF",
          forecastIconColor: "#FFBF3F",
        }}
      />
    </>
    // <div id="project-weather">
    //   {/* https://darksky.net/widget/graph-bar/{{ project.latitude }},{{ project.longitude }}/us12/en.js?width=undefined&title=Full Forecast&textColor=333333&bgColor=FFFFFF&skyColor=undefined&fontFamily=Default&customFont=&units=us&timeColor=333333&tempColor=C7C7C7&currentDetailsOption=true */}
    //   <iframe
    //     id="forecast_embed"
    //     // type="text/html"
    //     frameBorder="0"
    //     height={height}
    //     width="100%"
    //     src={`https://forecast.io/embed/#lat=${project.latitude}&lon=${project.longitude}&name=${project.display}`}
    //   />
    // </div>
  );
};

export const HourlyWeatherData = (props) => {
  const {weatherData, ...rest} = props;
  if (!weatherData || isEmpty(weatherData)) return null;
  return (
    <Box border={1} borderColor="grey.400" borderRadius={4} {...rest}>
      <Box bgcolor="grey.100" p={0.5} border={1} borderLeft={0} borderRight={0} borderTop={0} borderColor="grey.400">
        <Typography variant="h5" align="center">
          {weatherData.local_time}
        </Typography>
      </Box>
      <Box display="flex" justifyContent="center">
        <Box p={0.5} display="flex">
          <Box mr={1}>
            <img src={weatherData.icon_url} alt={weatherData.icon} style={{maxHeight: "54px"}} />
          </Box>
          <Box>
            <Box>
              <strong>
                {weatherData.temperature.toFixed(0)}&#176; {weatherData.summary}
              </strong>
            </Box>
            <Box>Feels Like {weatherData.apparent_temperature.toFixed(0)}&#176;</Box>
            <Box>
              <small>
                {" "}
                Wind {weatherData.wind_speed} MPH | Precip {(weatherData.precip_probability * 100).toFixed(1)}%
              </small>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export const WeatherPaperPanel = (props: {projectId: number; date: string}) => {
  const {projectId, date, ...rest} = props;
  const {query: weatherQuery} = useSentinelDetailAPI(
    `projects/${projectId}/weather/${date}/`,

    {
      initialData: {
        morning: {},
        midday: {},
        evening: {},
      },
    }
  );
  const weather = weatherQuery.data;
  return (
    <PaperPanel {...rest}>
      <PaperPanel.Header isLoading={weatherQuery.isFetching}>
        <PaperPanel.Header.Title maxWidth="100%">
          <img src={weather.summary_icon} alt={weather.summary} style={{height: "18px"}} />{" "}
          {`Weather for ${moment(date).format("ddd, YYYY-MM-DD")}`} {weather.summary && ` - ${weather.summary}`}
        </PaperPanel.Header.Title>
        <PaperPanel.Header.Actions>
          <PaperPanel.Header.Action border>
            <PaperPanel.Header.RefreshButton
              onClick={() => weatherQuery.refetch()}
              isFetching={weatherQuery.isFetching}
            />
          </PaperPanel.Header.Action>
        </PaperPanel.Header.Actions>
      </PaperPanel.Header>
      <PaperPanel.Body p={1}>
        {!weatherQuery.isFetched ? (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Grid>
            <Grid item xs={12} md={4}>
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
              <Skeleton />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1}>
            <Grid item xs={12} md={4}>
              <HourlyWeatherData weatherData={weather.morning} />
            </Grid>
            <Grid item xs={12} md={4}>
              <HourlyWeatherData weatherData={weather.midday} />
            </Grid>
            <Grid item xs={12} md={4}>
              <HourlyWeatherData weatherData={weather.evening} />
            </Grid>
          </Grid>
        )}
        {/* <pre>{JSON.stringify(weatherQuery, null, 2)}</pre> */}
      </PaperPanel.Body>
    </PaperPanel>
  );
};
