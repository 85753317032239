import {faPenFancy} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  LinearProgress,
  Link,
  makeStyles,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {blue, grey} from "@material-ui/core/colors";
import CachedIcon from "@material-ui/icons/Cached";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import ListAltIcon from "@material-ui/icons/ListAlt";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import Alert from "@material-ui/lab/Alert";
import classnames from "classnames";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import React from "react";
import {
  BoxFilesButton,
  ColoredButton,
  ColoredSplitButton,
  CreateButton,
  DeleteButton,
  DropdownButton,
  EditButton,
  FieldwireUploadButton,
  PDFButton,
  PDFDropdownButton,
  PDFSplitButton,
  ReorderButton,
  RevisionsButton,
} from "./Buttons";

const useStyles = makeStyles((theme) => {
  const {transitions, breakpoints, palette, shape, typography} = theme;
  return {
    root: {overflow: "hidden"},
    alert: {borderRadius: 0},
    toolbar: {
      backgroundColor: grey[50],
      borderWidth: "0px 0px 1px",
      borderStyle: "solid",
      borderColor: grey[400],
    },
  };
});

export const paperPanelButtonProps = {
  size: "small",
  variant: "outlined",
  disableElevation: true,
  // style: {fontSize: 10},
};

const PaperPanel = (props) => {
  const {children, className, ...rest} = props;
  const classes = useStyles();
  return (
    <Paper {...rest} className={classnames(classes.root, className)}>
      {children}
    </Paper>
  );
};

const Header = (props) => {
  const {children, isLoading, ...rest} = props;
  return (
    <>
      <Box
        display="flex"
        py={0.75}
        px={1}
        bgcolor="grey.100"
        border={1}
        borderColor="grey.400"
        borderTop={0}
        borderLeft={0}
        borderRight={0}
        // alignItems="center"
        // alignItems="flex-start"
        // justifyContent="center"
        alignItems={{xs: "flex-start", lg: "center"}}
        flexDirection={{xs: "column", lg: "row"}}
        minHeight={38}
        // borderBottom={3}
        {...rest}
      >
        {children}
      </Box>
      {isLoading && <LinearProgress color="secondary" />}
    </>
  );
};
PaperPanel.Header = Header;

const TabHeader = (props) => {
  const {children, isLoading, sectionHeader, ...rest} = props;
  return (
    <Box mb={2} mx={-3} mt={sectionHeader ? 1 : -4}>
      <Box
        display="flex"
        py={0.5}
        px={1}
        bgcolor="grey.100"
        border={1}
        borderColor="grey.400"
        // borderTop={0}
        borderLeft={0}
        borderRight={0}
        // alignItems="center"
        // justifyContent="center"
        alignItems={{xs: "flex-start", lg: "center"}}
        flexDirection={{xs: "column", lg: "row"}}
        minHeight={38}
        {...rest}
      >
        {children}
      </Box>
      {isLoading && <LinearProgress color="secondary" />}
    </Box>
  );
};
PaperPanel.TabHeader = TabHeader;

const Title = (props) => {
  const {children, wrap = false, ...rest} = props;
  return (
    <Box
      maxWidth={{xs: "100%", lg: "33%"}}
      // component="div"
      // overflow="hidden"
      // whiteSpace="nowrap"
      // textOverflow="ellipsis"
      {...rest}
    >
      <Typography noWrap={!wrap} component="div">
        {children}
      </Typography>
    </Box>
  );
};
Header.Title = Title;

const Actions = (props) => {
  const {children, ...rest} = props;
  // const theme = useTheme();
  // const isSmall = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <Box
      display="flex"
      flexWrap="wrap"
      flexDirection={{xs: "column", sm: "row"}}
      // flexDirection={isSmall ? "column" : "row"}
      // flexGrow={1}
      width="100%"
      justifyContent="flex-end"
      {...rest}
    >
      {children}
    </Box>
  );
};
Header.Actions = Actions;

const HeaderAction = (props) => {
  const {children, border, ...rest} = props;
  return (
    <Box display="flex" px={1} borderColor="grey.400" alignItems="center" borderLeft={border ? 1 : 0} {...rest}>
      {children}
    </Box>
  );
};
Header.Action = HeaderAction;

PaperPanel.Alert = (props) => {
  const {children, className, ...rest} = props;
  const classes = useStyles();
  return (
    <Alert className={classnames(classes.alert, className)} {...rest}>
      {children}
    </Alert>
  );
};

const Toolbar = (props) => {
  const {children, className, ...rest} = props;
  const classes = useStyles();
  return (
    <Box className={classnames(classes.toolbar, className)} {...rest}>
      {children}
    </Box>
  );
};
PaperPanel.Toolbar = Toolbar;

const TabToolbar = (props) => {
  const {children, className, ...rest} = props;
  const classes = useStyles();
  return (
    <Box mx={-3} mt={-2} className={classnames(classes.toolbar, className)} {...rest}>
      {children}
    </Box>
  );
};

PaperPanel.TabToolbar = TabToolbar;

const Body = (props) => {
  const {children, sectionBody, ...rest} = props;
  return (
    <Box mx={sectionBody && -3} mt={sectionBody && -2} {...rest}>
      {children}
    </Box>
  );
};
PaperPanel.Body = Body;

const Footer = (props) => {
  const {children, ...rest} = props;
  return (
    <Box
      display="flex"
      p={1}
      bgcolor="grey.100"
      border={1}
      borderColor="grey.400"
      borderBottom={0}
      borderLeft={0}
      borderRight={0}
      // borderTop={3}
      {...rest}
    >
      {children}
    </Box>
  );
};
PaperPanel.Footer = Footer;

const HeaderMenu = (props) => {
  const {children, border} = props;
  // TODO: add custom menu button: https://stackoverflow.com/questions/48919320/react-how-to-pass-props-to-a-component-passed-as-prop
  return (
    // <Box borderColor="grey.400" borderLeft={border ? 1 : 0} pl={border ? 1 : 0}>
    <PopupState variant="popover" popupId="demo-popup-menu">
      {(popupState) => (
        <>
          <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            size="small"
            {...bindTrigger(popupState)}
          >
            <MoreIcon />
          </IconButton>
          <Menu {...bindMenu(popupState)}>{children(popupState)}</Menu>
        </>
      )}
    </PopupState>
    // </Box>
  );
};
Header.Menu = HeaderMenu;

const HeaderButton = (props) => {
  const {children, ...rest} = props;
  return (
    <Button {...paperPanelButtonProps} {...rest}>
      {children}
    </Button>
  );
};
Header.Button = HeaderButton;

const HeaderIconButton = (props) => {
  const {children, ...rest} = props;
  return (
    <IconButton {...paperPanelButtonProps} {...rest}>
      {children}
    </IconButton>
  );
};
Header.IconButton = HeaderIconButton;

const HeaderColoredButton = (props) => {
  const {children, ...rest} = props;
  return (
    <ColoredButton {...paperPanelButtonProps} {...rest}>
      {children}
    </ColoredButton>
  );
};
Header.ColoredButton = HeaderColoredButton;

const HeaderColoredSplitButton = (props: {
  title: string;
  color: string;
  children: React.ReactNode | ((setOpen: any) => React.ReactNode);
  [rest: string]: any;
}) => {
  const {children, ...rest} = props;
  return (
    <ColoredSplitButton {...paperPanelButtonProps} {...rest}>
      {children}
    </ColoredSplitButton>
  );
};
Header.ColoredSplitButton = HeaderColoredSplitButton;

const HeaderDropdownMenu = (props: {
  title: string | React.ReactElement;
  children: React.ReactNode | ((setOpen) => React.ReactElement);
  [rest: string]: any;
}) => {
  const {children, ...rest} = props;
  return (
    <DropdownButton size="small" {...rest}>
      {children}
    </DropdownButton>
  );
};
Header.DropdownMenu = HeaderDropdownMenu;

const HeaderCreateButton = (props) => {
  const {children, ...rest} = props;
  return (
    <CreateButton {...paperPanelButtonProps} {...rest}>
      {children}
    </CreateButton>
  );
};
Header.CreateButton = HeaderCreateButton;

const HeaderDocuSignButton = (props: {
  docuSignObject: {
    can_docusign: boolean;
    docusign_envelope_list_url: string;
    current_docusign_envelope_detail_url?: string;
    can_docusign_reason?: string;
  };
  children?: React.ReactNode | string;
  [rest: string]: any;
}) => {
  const {children = "DocuSign", docuSignObject, ...rest} = props;
  return (
    <Tooltip
      title={
        !docuSignObject.can_docusign && !docuSignObject.current_docusign_envelope_detail_url
          ? docuSignObject.can_docusign_reason
          : ""
      }
    >
      <span>
        <HeaderButton
          startIcon={<FontAwesomeIcon icon={faPenFancy} />}
          href={docuSignObject.docusign_envelope_list_url}
          disabled={!docuSignObject.can_docusign && !docuSignObject.current_docusign_envelope_detail_url}
          {...rest}
        >
          {children}
        </HeaderButton>
      </span>
    </Tooltip>
  );
};
Header.DocuSignButton = HeaderDocuSignButton;

const HeaderEditButton = (props) => {
  const {children, ...rest} = props;
  return (
    <EditButton {...paperPanelButtonProps} {...rest}>
      {children}
    </EditButton>
  );
};
Header.EditButton = HeaderEditButton;

const HeaderDeleteButton = (props) => {
  const {children, ...rest} = props;
  return (
    <DeleteButton {...paperPanelButtonProps} {...rest}>
      {children}
    </DeleteButton>
  );
};
Header.DeleteButton = HeaderDeleteButton;

const HeaderPDFButton = (props: {href: string; title?: string; [rest: string]: any}) => {
  const {children, ...rest} = props;
  return <PDFButton {...paperPanelButtonProps} {...rest} />;
};
Header.PDFButton = HeaderPDFButton;

const HeaderPDFDropdownButton = (props) => {
  const {children, ...rest} = props;
  return (
    <PDFDropdownButton {...paperPanelButtonProps} {...rest}>
      {children}
    </PDFDropdownButton>
  );
};
Header.PDFDropdownButton = HeaderPDFDropdownButton;

const HeaderPDFSplitButton = (props) => {
  const {children, ...rest} = props;
  return (
    <PDFSplitButton {...paperPanelButtonProps} {...rest}>
      {children}
    </PDFSplitButton>
  );
};
Header.PDFSplitButton = HeaderPDFSplitButton;

const HeaderBuildReportButton = (props) => {
  const {children, ...rest} = props;
  return (
    <Box display="inline" color={blue[900]}>
      <HeaderButton color="inherit" startIcon={<ListAltIcon />} {...rest}>
        Build Report
      </HeaderButton>
    </Box>
  );
};
Header.BuildReportButton = HeaderBuildReportButton;

const HeaderPDFButtons = (props: {
  pdfURL?: string;
  buildReportURL?: string;
  children?: React.ReactNode;
  [rest: string]: any;
}) => {
  const {pdfURL, buildReportURL, children, ...rest} = props;
  if (children && pdfURL)
    return (
      <>
        <HeaderPDFSplitButton href={pdfURL} target="_blank" {...rest}>
          {children}
        </HeaderPDFSplitButton>
        {buildReportURL && (
          <Box ml={1}>
            <HeaderBuildReportButton href={buildReportURL} />
          </Box>
        )}
      </>
    );
  if (children && !pdfURL)
    return (
      <>
        <HeaderPDFDropdownButton {...rest}>{children}</HeaderPDFDropdownButton>
        {buildReportURL && (
          <Box ml={1}>
            <HeaderBuildReportButton href={buildReportURL} />
          </Box>
        )}
      </>
    );
  return (
    <>
      <HeaderPDFButton target="_blank" href={pdfURL} />
      {buildReportURL && (
        <Box ml={1}>
          <HeaderBuildReportButton href={buildReportURL} />
        </Box>
      )}
    </>
  );
};
Header.PDFButtons = HeaderPDFButtons;

const HeaderPDFMenuItem = (props) => {
  const {children, ...rest} = props;
  return (
    <HeaderMenuItem component={Link} target="_blank" {...rest}>
      {children}
    </HeaderMenuItem>
  );
};
HeaderMenu.PDFMenuItem = HeaderPDFMenuItem;

const HeaderReorderButton = (props) => <ReorderButton {...paperPanelButtonProps} {...props} />;
Header.ReorderButton = HeaderReorderButton;

const HeaderRevisionsButton = (props) => <RevisionsButton {...paperPanelButtonProps} {...props} />;
Header.RevisionsButtons = HeaderRevisionsButton;

const HeaderBoxFilesButton = (props) => <BoxFilesButton {...paperPanelButtonProps} {...props} />;
Header.BoxFilesButton = HeaderBoxFilesButton;

const HeaderFieldwireUploadButton = (props) => <FieldwireUploadButton {...paperPanelButtonProps} {...props} />;
Header.FieldwireUploadButton = HeaderFieldwireUploadButton;

const HeaderHelpButton = (props) => {
  const {children, title = "Help", maxWidth = "md", ...rest} = props;
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <>
      <IconButton size="small" onClick={() => setIsOpen(true)} {...rest}>
        <HelpOutlineIcon />
      </IconButton>
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth={maxWidth}
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <Button onClick={() => setIsOpen(false)} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

Header.HelpButton = HeaderHelpButton;

const HeaderRefreshButton = (props: {isFetching: boolean; [rest: string]: any}) => {
  const {isFetching, ...rest} = props;
  if (isFetching) {
    return <CircularProgress color="secondary" size={24} />;
  }
  return (
    <IconButton size="small" {...rest}>
      <CachedIcon />
      {/* <FontAwesomeIcon icon={faSync} spin={isFetching} fontSize="inherit" /> */}
    </IconButton>
  );
};

Header.RefreshButton = HeaderRefreshButton;

const HeaderMenuItem = (props) => {
  const {children, ...rest} = props;
  return (
    <MenuItem dense {...rest}>
      {children}
    </MenuItem>
  );
};
HeaderMenu.MenuItem = HeaderMenuItem;

export default PaperPanel;
