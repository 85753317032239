import {
  Box,
  FormControl,
  FormControlLabel,
  FormGroup,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
} from "@material-ui/core";
import {Autocomplete} from "@material-ui/lab";
import {KeyboardDatePicker} from "@material-ui/pickers";
import React from "react";
import {useDebounce} from "use-debounce";
import {StatusIcon} from "./Icons";

export const FilterSearch = (props) => {
  const {debounceTimeout = 500, onChange = () => {}, value: controlledValue = "", ...rest} = props;
  const [text, setText] = React.useState("");
  const [debouncedValue] = useDebounce(text, debounceTimeout);

  React.useEffect(() => {
    onChange(debouncedValue);
  }, [debouncedValue]);

  React.useEffect(() => {
    setText(controlledValue);
  }, [controlledValue]);

  return (
    <TextField
      fullWidth
      variant="outlined"
      onChange={(event) => {
        setText(event.target.value);
      }}
      size="small"
      value={text}
      autoComplete="off"
      {...rest}
    />
  );
};

export const FilterDate = (props: {
  label: string;
  name?: string;
  onChange: (date) => void;
  // All other props
  [rest: string]: any;
}) => {
  const {value, onChange, ...rest} = props;

  return (
    <KeyboardDatePicker
      format="YYYY-MM-DD"
      size="small"
      inputVariant="outlined"
      onChange={(date) => {
        if (!date) {
          onChange("");
        } else {
          if (date.isValid()) {
            onChange(date.format("YYYY-MM-DD"));
          }
        }
      }}
      value={value || null}
      disableFuture
      showTodayButton
      fullWidth
      {...rest}
    />
  );
};

export const FilterSelect = (props: {
  options: {value: string | number; label: string}[];
  label: string;
  name: string;
  onChange: (value, label) => void;
  allowNull?: boolean;
  showStatusIcon?: boolean;
  statusType?: "submittal" | "changeOrder" | "rfi";
  renderOption?: (value) => React.ReactNode;
  // All other props
  [rest: string]: any;
}) => {
  const {
    value,
    allowNull = true,
    options = [],
    label,
    name,
    onChange,
    multiple,
    showStatusIcon = false,
    statusType,
    renderOption,
    ...rest
  } = props;
  const labelId = `${name}-label`;

  return (
    <FormControl fullWidth variant="outlined" size="small">
      <InputLabel id={labelId}>{label}</InputLabel>
      <Select
        label={label}
        labelId={labelId}
        multiple={multiple}
        value={multiple ? value || [] : value}
        onChange={(event) => {
          if (multiple) {
            const values = (event.target.value || []) as string[];
            const labels = values.map((value) => {
              return options.find((option) => option.value === value)?.label;
            });
            onChange(event.target.value, labels.join(", "));
          } else {
            onChange(event.target.value, options.find((option) => option.value === event.target.value)?.label);
          }
        }}
        {...rest}
      >
        {allowNull && (
          <MenuItem key={-1} value={""}>
            ---------
          </MenuItem>
        )}
        {options.map((option) => {
          return (
            <MenuItem key={option.value} value={option.value} dense>
              {renderOption ? (
                renderOption(option)
              ) : (
                <>
                  {showStatusIcon && (
                    <Box display="inline" mr={1}>
                      <StatusIcon status={option.value as string} type={statusType} />
                    </Box>
                  )}
                  {option.label}
                </>
              )}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export const FilterSwitch = (props: {
  label: string;
  name: string;
  checked: boolean;
  onChange: (value, label) => void;
}) => {
  const {label, name, checked, onChange, ...rest} = props;

  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Switch
            checked={checked}
            name={name}
            onChange={(event, checked) => onChange(checked, checked ? "True" : "False")}
            {...rest}
          />
        }
        label={label}
      />
    </FormGroup>
  );
};

export const FilterAutocomplete = (props: {
  options: {value: string | number; label: string}[];
  label: string;
  name: string;
  onChange: (value, label) => void;
  renderOption?: (value) => React.ReactNode;
  valueProp?: string;
  [rest: string]: any;
}) => {
  const {
    options = [],
    label,
    name,
    onChange,
    renderOption,
    value,
    valueProp = "value",
    labelProp = "label",
    ...rest
  } = props;

  return (
    <Autocomplete
      options={options}
      renderInput={(params) => <TextField {...params} label={label} variant="outlined" />}
      getOptionLabel={(option) => option[labelProp] || option}
      getOptionSelected={(option) => option[valueProp] === value}
      autoSelect
      autoHighlight
      onChange={(event, newValue, reason) => {
        if (typeof newValue === "string") {
          return onChange(newValue, newValue);
        }
        onChange(newValue?.value, newValue?.label);
      }}
      size="small"
      value={value || null}
      {...rest}
    />
  );
};
