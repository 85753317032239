import classnames from "classnames";
import PropTypes from "prop-types";
import React from "react";

export const ProjectStatusIcon = class extends React.Component {
  static propTypes = {
    status: PropTypes.string,
  };

  static getClassName(status) {
    if (!status) {
      status = "";
    }
    if (["unstarted"].indexOf(status.toLowerCase()) > -1) {
      return "fa fa-clock status-color-blue";
    }
    if (["complete", "closed"].indexOf(status.toLowerCase()) > -1) {
      return `fa fa-check status-color-dark-red`;
    }
    if (["sentinel deleted"].indexOf(status.toLowerCase()) > -1) {
      return `fa fa-exclamation-circle status-color-dark-red`;
    }
    if (["in progress"].indexOf(status.toLowerCase()) > -1) {
      return `fa fa-cogs status-color-green`;
    }
    if (["submitted"].indexOf(status.toLowerCase()) > -1) {
      return `fa fa-share`;
    }
    if (["submitted for closeout"].indexOf(status.toLowerCase()) > -1) {
      return `fa fa-share status-color-dark-red`;
    }
    return `fa fa-question-circle text-muted`;
  }

  render() {
    const {status, className, ...rest} = this.props;
    return <i className={classnames(ProjectStatusIcon.getClassName(status), className)} title={status} {...rest} />;
  }
};
