import {faSquare} from "@fortawesome/pro-regular-svg-icons";
import {faCheckSquare, faCircle, faExternalLink, faFileExcel, faUsers} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, makeStyles, Tooltip} from "@material-ui/core";
import accounting from "accounting";
import classnames from "classnames";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {FilterOptionChips} from "../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../components/Filters";
import offices from "../../components/forms/choices/offices.json";
import {PaginationWithPageSize} from "../../components/Pagination";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import useFilterOptions from "../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../theme";
import {colorError, colorSuccess, colorWarning} from "../../theme/colors";
import {EmployeeDialogForm} from "./EmployeeForms";

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    table: {
      "& tr": {
        cursor: "pointer",
      },
    },
  };
});

const EmployeeRoster = (props) => {
  const {user} = props;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const [showCreateEmployeeDialog, setShowCreateEmployeeDialog] = React.useState(false);
  const [showUpdateEmployeeDialog, setShowUpdateEmployeeDialog] = React.useState(false);
  const [activeItem, setActiveItem] = React.useState({} as any);
  const novoClasses = makeNovoClasses();
  const classes = useStyles();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Office", "Active"],
    {Active: {value: "True", label: "True"}}
  );
  const filterParams = {
    q: filterOptions.Search.value,
    office_id: filterOptions.Office.value,
    is_active: filterOptions.Active.value,
  };

  const {
    query: employeeQuery,
    create: createEmployee,
    update: updateEmployee,
  } = useSentinelListAPI(`employees/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`, {
    initialData: {results: []},
  });
  const employeeData = employeeQuery.data as any;
  const employees = employeeData.results;
  return (
    <>
      <Helmet title="Employee Roster" />

      <Box my={2}>
        <PaperPanel>
          <PaperPanel.Header isLoading={employeeQuery.isFetching}>
            <PaperPanel.Header.Title>
              <FontAwesomeIcon icon={faUsers} /> Employee Roster{" "}
              {employeeData?.count &&
                `(${employeeData.results.length} of ${accounting.formatNumber(employeeData?.count)})`}
            </PaperPanel.Header.Title>
            <PaperPanel.Header.Actions>
              <PaperPanel.Header.Action>
                <PaperPanel.Header.CreateButton onClick={() => setShowCreateEmployeeDialog(true)}>
                  Create Employee
                </PaperPanel.Header.CreateButton>
              </PaperPanel.Header.Action>
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.Button
                  href={`/v2/employees/roster/?export_type=excel`}
                  startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                  variant="outlined"
                >
                  Get Excel
                </PaperPanel.Header.Button>
              </PaperPanel.Header.Action>
              <PaperPanel.Header.Action border>
                <PaperPanel.Header.RefreshButton
                  onClick={() => employeeQuery.refetch()}
                  isFetching={employeeQuery.isFetching}
                />
              </PaperPanel.Header.Action>
            </PaperPanel.Header.Actions>
          </PaperPanel.Header>
          <PaperPanel.Toolbar p={1}>
            <Grid container spacing={1}>
              <Grid item sm={4} xs={12}>
                <FilterSearch
                  label="Search"
                  value={filterOptions.Search.value}
                  name="Search"
                  onChange={(value) => {
                    setPage(1);
                    setFilterOption("Search", value, value);
                  }}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FilterSelect
                  // native
                  label="Office"
                  name="Office"
                  options={offices}
                  value={filterOptions.Office.value}
                  onChange={(value, label) => {
                    setPage(1);
                    setFilterOption("Office", value, label);
                  }}
                />
              </Grid>
              <Grid item sm={4} xs={12}>
                <FilterSelect
                  // native
                  label="Active"
                  name="Active"
                  options={[
                    {label: "True", value: "True"},
                    {label: "False", value: "False"},
                  ]}
                  value={filterOptions.Active.value}
                  onChange={(value, label) => {
                    setPage(1);
                    setFilterOption("Active", value, label);
                  }}
                />
              </Grid>
            </Grid>
            <Box mt={1} />
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <FilterOptionChips
                  filterOptions={filterOptions}
                  onDelete={(key) => {
                    clearFilterOption(key);
                  }}
                  onDeleteAll={() => {
                    clearAllFilterOptions();
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <PaginationWithPageSize
                  count={employeeData.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={[100, 250, 500, 1000]}
                />
              </Grid>
            </Grid>
            {/* <pre>{JSON.stringify(filterOptions, null, 2)}</pre> */}
          </PaperPanel.Toolbar>
          <PaperPanel.Body>
            <ReactTableMui
              size="small"
              className={classnames(
                novoClasses.stripedTable,
                novoClasses.smallTable,
                novoClasses.boldHeaderTable,
                classes.table
              )}
              // rightAlignColumns={["timestamp"]}
              hover
              getRowProps={(row) => {
                return {
                  // hover: true,
                  onClick: (event) => {
                    setActiveItem(row.original);
                    setShowUpdateEmployeeDialog(true);
                  },
                };
              }}
              columns={React.useMemo(
                () => [
                  {
                    Header: "Name",
                    accessor: "last_name",
                    Cell: ({value, row}) => <>{row.original.full_name}</>,
                  },
                  {
                    Header: "Title",
                    accessor: "title",
                  },
                  {
                    Header: "Username",
                    accessor: "username",
                  },
                  {
                    Header: "Manager",
                    accessor: "manager.full_name",
                  },
                  // {
                  //   Header: "Hire Date",
                  //   accessor: "hire_date",
                  // },
                  {
                    Header: "Office",
                    accessor: "office.description",
                  },
                  {
                    Header: "Active",
                    accessor: "is_active",
                    disableSortBy: true,
                    Cell: ({value, row}) =>
                      value ? <FontAwesomeIcon icon={faCheckSquare} /> : <FontAwesomeIcon icon={faSquare} />,
                  },
                  {
                    Header: "Info",
                    accessor: "id",
                    disableSortBy: true,
                    Cell: ({value, row}) => {
                      return (
                        <Box onClick={(event) => event.stopPropagation()} style={{cursor: "default"}}>
                          <Tooltip title="Has User">
                            <span>
                              {row.original.user_id ? (
                                <FontAwesomeIcon icon={faCircle} color={colorSuccess} />
                              ) : (
                                <FontAwesomeIcon icon={faCircle} color={colorWarning} />
                              )}
                            </span>
                          </Tooltip>{" "}
                          <Tooltip title="Has Contact">
                            <span>
                              {row.original.contact_id ? (
                                <FontAwesomeIcon icon={faCircle} color={colorSuccess} />
                              ) : (
                                <FontAwesomeIcon icon={faCircle} color={colorError} />
                              )}
                            </span>
                          </Tooltip>{" "}
                          {/* {row.original.user_id}-{row.original.contact_id} */}
                          {user.is_superuser && (
                            <Link href={`/admin/employees/employee/${row.original.id}/change/`} target="_blank">
                              <FontAwesomeIcon icon={faExternalLink} />
                            </Link>
                          )}
                        </Box>
                      );
                    },
                  },
                ],
                []
              )}
              initialState={{
                sortBy: [
                  {
                    id: "last_name",
                    desc: false,
                  },
                ],
              }}
              data={employees}
            />
            {employeeData.total_pages > 1 && (
              <PaperPanel.Footer display="flex" justifyContent="flex-end">
                <PaginationWithPageSize
                  count={employeeData.total_pages}
                  page={page}
                  pageSize={pageSize}
                  setPage={setPage}
                  setPageSize={setPageSize}
                  pageSizes={[100, 250, 500, 1000]}
                />
              </PaperPanel.Footer>
            )}
            {/* <pre>{JSON.stringify(employeeQuery, null, 2)}</pre> */}
          </PaperPanel.Body>
        </PaperPanel>
      </Box>
      <EmployeeDialogForm
        isOpen={showCreateEmployeeDialog}
        isNew={true}
        handleClose={() => {
          setShowCreateEmployeeDialog(false);
        }}
        initialValues={{
          is_active: "True",
        }}
        onSubmit={(values) => {
          setShowCreateEmployeeDialog(false);
          createEmployee.mutateAsync(values);
        }}
      />
      <EmployeeDialogForm
        isOpen={showUpdateEmployeeDialog}
        isNew={false}
        handleClose={() => {
          setShowUpdateEmployeeDialog(false);
        }}
        initialValues={activeItem}
        onSubmit={(values) => {
          setShowUpdateEmployeeDialog(false);
          updateEmployee.mutateAsync(values);
        }}
      />
    </>
  );
};

export default EmployeeRoster;
