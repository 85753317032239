import {Box, Button, Typography} from "@material-ui/core";
import {useSnackbar} from "notistack";
import {Helmet} from "react-helmet";
import {textToHTML} from "../../../js/common/utils";
import HtmlRender from "../../../js/components/HtmlRender";
import {PageHeader} from "../../components/PageHeader";
import useBlockUI from "../../hooks/useBlockUI";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";

const MiscAdmin = (props) => {
  const postCurrentPage = usePostCurrentPage();
  const blockUI = useBlockUI();

  const {enqueueSnackbar} = useSnackbar();

  const runAction = (action) => {
    blockUI.blockUI();
    postCurrentPage
      .mutateAsync({action: action})
      .then((response) => {
        blockUI.unblockUI();
        enqueueSnackbar(<HtmlRender html={textToHTML(response.message)} />, {
          variant: "success",
        });
      })
      .catch(() => blockUI.unblockUI());
  };

  return (
    <>
      <Helmet title="Misc Admin Tools" />
      <PageHeader>
        <PageHeader.Left>
          <PageHeader.Title>Misc Admin Tools</PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <Typography variant="h4" gutterBottom>
        Axes
      </Typography>
      <Button variant="contained" onClick={() => runAction("axes_list_attempts")}>
        Axes List Attempts
      </Button>{" "}
      <Button variant="contained" onClick={() => runAction("axes_reset")}>
        Reset Axes
      </Button>
      <Box mt={2} />
      <Typography variant="h4" gutterBottom>
        Employee Evaluations
      </Typography>
      <Button variant="contained" onClick={() => runAction("fix_employee_manager_on_reviews")}>
        Fix Employee Manager on Reviews
      </Button>
    </>
  );
};

export default MiscAdmin;
