import Announcements from "../components/Announcements";
import Content from "../components/Content";
import Header from "../components/Header";
import SessionExpirationBanner from "../components/SessionExpirationBanner";
import Sidebar from "../components/Sidebar";
import StagingBanner from "../components/StagingBanner";
import {SuperuserInfo} from "../components/SuperuserInfo";
import {AuthenticatedContextProvider} from "../Contexts";
import usePermissions from "../hooks/usePermissions";
import useUser from "../hooks/useUser";
import {getAuthenticatedLayoutRoutes} from "../routes";

const AuthenticatedLayout = (props) => {
  const {children, isStaging, sessionExpireDate} = props;
  const user = useUser();
  const permissions = usePermissions();

  return (
    <AuthenticatedContextProvider>
      <Header />
      <Sidebar routes={getAuthenticatedLayoutRoutes(user, permissions, props.waffle)} />
      <Content>
        {isStaging && <StagingBanner />}
        <Announcements />
        <SessionExpirationBanner sessionExpireDate={sessionExpireDate} />
        {children}
        {props.adminObjects && <SuperuserInfo objects={props.adminObjects} />}
      </Content>
    </AuthenticatedContextProvider>
  );
};

export default AuthenticatedLayout;
