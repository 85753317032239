import {faCartShopping} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Link, Tooltip, Typography} from "@material-ui/core";
import classnames from "classnames";
import qs from "query-string";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSelect} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TableStatusLabel} from "../../../components/Status";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

const ProjectPurchaseOrdersRestricted = (props) => {
  const {project, userContact, ...rest} = props;

  const [
    purchaseOrderFilterOptions,
    setPurchaseOrderFilterOption,
    clearPurchaseOrderFilterOption,
    clearAllPurchaseOrderFilterOptions,
  ] = useFilterOptions(["Status", "To Company"]);
  const novoClasses = makeNovoClasses();

  const purchaseOrderFilterParams = {
    status: purchaseOrderFilterOptions.Status.value,
    to_company_id: purchaseOrderFilterOptions["To Company"].value,
  };

  const {query: purchaseOrdersQuery} = useSentinelListAPI(
    `projects/${project.id}/pos/?_=_&${qs.stringify(purchaseOrderFilterParams)}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );
  const {query: subcontractStatusQuery} = useSentinelListAPI(`projects/${project.id}/contracts/sub/status/`, {
    initialData: [],
  });
  const subcontractStatusOptions = subcontractStatusQuery.data;
  const {query: toCompanyQuery} = useSentinelListAPI(`projects/${project.id}/contracts/sub/to-companies/`, {
    initialData: [],
  });
  const toCompanyOptions = toCompanyQuery.data;

  const purchaseOrders = purchaseOrdersQuery.data?.results;

  return (
    <>
      <Helmet title={`${project.display} - Purchase Orders`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Purchase Orders</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={purchaseOrdersQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faCartShopping} /> Purchaser Orders
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                isFetching={purchaseOrdersQuery.isFetching}
                onClick={() => purchaseOrdersQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                label="Status"
                name="Status"
                options={subcontractStatusOptions}
                value={purchaseOrderFilterOptions.Status.value}
                onChange={(value, label) => {
                  setPurchaseOrderFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                label="To Company"
                name="To Company"
                options={toCompanyOptions}
                value={purchaseOrderFilterOptions["To Company"].value}
                onChange={(value, label) => {
                  setPurchaseOrderFilterOption("To Company", value, label);
                }}
              />
            </Grid>
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={12} xs={12}>
              <FilterOptionChips
                filterOptions={purchaseOrderFilterOptions}
                onDelete={(key) => {
                  clearPurchaseOrderFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllPurchaseOrderFilterOptions();
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            rightAlignColumns={["number_display"]}
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            columns={[
              {
                id: "description",
                Header: "Purchase Order",
                accessor: (value) => {
                  return `${value.status_sort}-${value.description}`;
                },
                Cell: ({value, row}) => (
                  <>
                    <TableStatusLabel status={row.original.status_display || "Unknown"}>
                      <Link underline="always" href={`${row.original.url}`}>
                        {row.original.description}
                      </Link>
                    </TableStatusLabel>
                  </>
                ),
              },
              {
                Header: "Company",
                accessor: "to_company.name",
                Cell: ({value, row}) => (
                  <>
                    <Link href={`${row.original.to_company.url}`}>{value}</Link>
                  </>
                ),
              },
              {
                Header: "Budget",
                accessor: "budget.budget_code",
                Cell: ({value, row}) => (
                  <>
                    <Tooltip title={`${row.original?.budget?.description}`}>
                      <span>{value}</span>
                    </Tooltip>
                  </>
                ),
              },
              {
                Header: "#",
                accessor: "number_display",
              },
            ]}
            initialState={{}}
            data={purchaseOrders}
          />
          {/* <pre>{JSON.stringify(purchaseOrders, null, 2)}</pre> */}
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/pos/`} mt={2} />
    </>
  );
};

export default ProjectPurchaseOrdersRestricted;
