import {
  faAddressBook,
  faBuilding,
  faCloud,
  faCopy,
  faEnvelope,
  faFileExcel,
  faTrash,
  faUser,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Checkbox, Grid, Link, Typography} from "@material-ui/core";
import {groupBy, sortBy, uniq, uniqBy} from "lodash";
import {useSnackbar} from "notistack";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSelect} from "../../../components/Filters";
import rolesOptions from "../../../components/forms/choices/companyRoles.json";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {colorError} from "../../../theme/colors";
import {
  AddCompanyDialogForm,
  CompanyCreateDialog,
  ContactCreateDialog,
  LinkContactToCompanyDialog,
  ProjectCompanyEditDialog,
  ProjectContactEditDialog,
} from "./ProjectDirectoryForms";
import {CompanyPaperItem, ContactPaperItem} from "./ProjectDirectoryPaperItem";
import {updateProjectCompany} from "../../../../js/actions/ProjectDirectory";

const ProjectDirectory = (props) => {
  const {project, user, ...rest} = props;
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();
  const linkFormRef = React.createRef();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Company Role",
    "Contact Role",
  ]);
  const [addCompanyDialogIsOpen, setAddCompanyDialogIsOpen] = React.useState(false);
  const [primaryContactDialogIsOpen, setPrimaryContactDialogIsOpen] = React.useState(false);
  const [primaryCompanyDialogIsOpen, setPrimaryCompanyDialogIsOpen] = React.useState(false);
  const [inviteToPortalDialogIsOpen, setInviteToPortalDialogIsOpen] = React.useState(false);
  const [editContactDialogIsOpen, setEditContactDialogIsOpen] = React.useState(false);
  const [editCompanyDialogIsOpen, setEditCompanyDialogIsOpen] = React.useState(false);
  const [createCompanyDialogIsOpen, setCreateCompanyDialogIsOpen] = React.useState(false);
  const [createContactDialogIsOpen, setCreateContactDialogIsOpen] = React.useState(false);
  const [createExternalBoxDialogIsOpen, setCreateExternalBoxDialogIsOpen] = React.useState(false);
  const [addContactToCompanyDialogIsOpen, setAddContactToCompanyDialogIsOpen] = React.useState(false);
  const [activeProjectContact, setActiveProjectContact] = React.useState({} as any);
  const [activeProjectCompany, setActiveProjectCompany] = React.useState({} as any);
  const [sendNotificationsOnBoxCreate, setSendNotificationsOnBoxCreate] = React.useState(false);
  const [deleteContactConfirmationIsOpen, setDeleteContactConfirmationIsOpen] = React.useState(false);
  const [bulkDeleteContactsConfirmationIsOpen, setBulkDeleteContactsConfirmationIsOpen] = React.useState(false);
  const [deleteCompanyConfirmationIsOpen, setDeleteCompanyConfirmationIsOpen] = React.useState(false);

  const filterParams = {
    company_role_id: filterOptions["Company Role"].value,
    contact_role_id: filterOptions["Contact Role"].value,
  };

  const {
    query: companyQuery,
    create: createCompany,
    update: updateCompany,
    delete: deleteCompany,
    rpc: companyRPC,
    // onDragEnd,
  } = useSentinelListAPI(`projects/${project.id}/companies/?page_size=10000&${qs.stringify(filterParams)}`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const projectCompanies = companyQuery?.data?.results;

  const {
    query: contactQuery,
    create: createContact,
    update: updateContact,
    delete: deleteContact,
    rpc: contactRPC,
    // onDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/contacts/?page_size=10000&primary_phone=True&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const contacts = contactQuery?.data?.results;

  const allCompanyIds = projectCompanies.map((company) => company.id);
  const allContactIds = contacts
    .filter((contact) => allCompanyIds.includes(contact.project_company_id))
    .map((contact) => contact.id);

  const {
    selectedIds: selectedContacts,
    addSelectedId: addSelectedContact,
    addSelectedIds: addSelectedContacts,
    removeSelectedId: removeSelectedContact,
    removeSelectedIds: removeSelectedContacts,
    addAllSelectedIds: addAllSelectedContacts,
    removeAllSelectedIds: removeAllSelectedContacts,
    allIdsSelected: allContactsSelected,
  } = useSelectIds(allContactIds);

  const {
    query: portalUsersQuery,
    create: createPortalUser,
    update: updatePortalUser,
    delete: deletePortalUser,
    rpc: portalUserRPC,
    // onDragEnd,
  } = useSentinelListAPI(`projects/${project.id}/portal-users/?page_size=10000`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const portalUsers = portalUsersQuery?.data?.results;

  const companyRoles = uniq(projectCompanies.map((projectCompany) => projectCompany.company_role_display));

  const companyRoleFilterOptions = uniqBy(
    projectCompanies.map((projectCompany) => ({
      value: projectCompany.company_role_id,
      label: projectCompany.company_role_display,
    })),
    "value"
  );

  const contactRoleFilterOptions = uniqBy(
    contacts
      .filter((item) => item.value !== null)
      .map((projectContact) => {
        let roleDisplay = projectContact.contact_role_display;
        if (roleDisplay && projectContact.contact_role_id.startsWith("sub")) {
          roleDisplay = `Sub - ${roleDisplay}`;
        }
        return {
          value: projectContact.contact_role_id,
          // label: `${projectContact.contact_role_display} - ${projectContact.contact_role_id}`,
          label: roleDisplay,
        };
      }),
    "value"
  ).sort((a, b) => {
    if (a.value < b.value) {
      return -1;
    }
    if (a.value > b.value) {
      return 1;
    }
    return 0;
  });

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });

  const possibleRoles = rolesOptions.map((option) => option.label);

  const companyLookup = groupBy(projectCompanies, "company_role_display");

  const contactLookup = groupBy(contacts, (contact) => contact?.company?.id);

  if (!pageDataQuery.isFetchedAfterMount || !companyQuery.isFetchedAfterMount || !contactQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Directory Info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - Project Directory`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Project Directory</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={companyQuery.isFetching || contactQuery.isFetching}>
          <PaperPanel.Header.Title>
            <PaperItem.SelectedCheckbox
              label={"Select All"}
              onChange={(event, value) => {
                if (value) {
                  addSelectedContacts(allContactIds);
                } else {
                  removeAllSelectedContacts();
                }
              }}
              indeterminate={Boolean(
                !allContactsSelected && selectedContacts.size < allContactIds.length && selectedContacts.size
              )}
              checked={allContactsSelected}
            />
            <FontAwesomeIcon icon={faAddressBook} fixedWidth />
            Project Directory
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                startIcon={<FontAwesomeIcon icon={faBuilding} />}
                onClick={() => setAddCompanyDialogIsOpen(true)}
                disabled={project.is_closed}
              >
                Add Company
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons buildReportURL={`/reports2/projects/${project.id}/directory/filter/`}>
                <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/projects/${project.id}/directory/`}>
                  Portrait
                </PaperPanel.Header.Menu.PDFMenuItem>
                <PaperPanel.Header.Menu.PDFMenuItem href={`/reports2/projects/${project.id}/directory/?landscape=on`}>
                  Landscape
                </PaperPanel.Header.Menu.PDFMenuItem>
              </PaperPanel.Header.PDFButtons>
              <Box ml={1} />
              <PaperPanel.Header.Button
                href={`/reports2/projects/${project.id}/directory/?export_type=excel`}
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
              >
                Get Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button
                href={`/projects/${project.id}/groups/`}
                startIcon={<FontAwesomeIcon icon={faUsers} />}
              >
                Groups
              </PaperPanel.Header.Button>
              <Box ml={1} />
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faEnvelope} />}
                onClick={() => {
                  const mailingList = contacts
                    .filter((contact) => selectedContacts.has(contact.id))
                    .map((contact) => contact.contact.email);

                  window.location.href = "mailto:" + mailingList.join(";");
                }}
                disabled={selectedContacts.size < 1}
              >
                Email Selected
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            {/* <Box ml={-1} /> */}
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.DropdownMenu title={"Create New"} disabled={project.is_closed}>
                {(setOpen) => (
                  <div>
                    {/* <PaperPanel.Header.Menu.MenuItem onClick={() => setCreateCompanyDialogIsOpen(true)}>
                      <FontAwesomeIcon icon={faBuilding} fixedWidth />
                      Create New Company
                    </PaperPanel.Header.Menu.MenuItem> */}
                    <PaperPanel.Header.Menu.MenuItem onClick={() => setCreateContactDialogIsOpen(true)}>
                      <FontAwesomeIcon icon={faUser} fixedWidth />
                      Create New Contact
                    </PaperPanel.Header.Menu.MenuItem>
                    <MenuItemHeader border />
                    <PaperPanel.Header.Menu.MenuItem onClick={() => setCreateExternalBoxDialogIsOpen(true)}>
                      <FontAwesomeIcon icon={faCloud} fixedWidth />
                      Create External Box Folder
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.DropdownMenu>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border pr={0}>
              <PaperPanel.Header.Menu>
                {(popupState) => (
                  <div>
                    <PaperPanel.Header.Menu.MenuItem
                      disabled={!selectedContacts.size}
                      onClick={() => {
                        popupState.close();
                        setBulkDeleteContactsConfirmationIsOpen(true);
                      }}
                      style={{color: colorError}}
                    >
                      <FontAwesomeIcon icon={faTrash} /> <Box mr={1} /> Delete Selected Contacts
                    </PaperPanel.Header.Menu.MenuItem>
                    <PaperPanel.Header.Menu.MenuItem
                      component={Link}
                      href={`/projects/${project.id}/directory/copy/`}
                      onClick={() => {
                        popupState.close();
                      }}
                    >
                      <FontAwesomeIcon icon={faCopy} fixedWidth />
                      Copy directory from another project
                    </PaperPanel.Header.Menu.MenuItem>
                  </div>
                )}
              </PaperPanel.Header.Menu>
              {/* </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action> */}
              <PaperPanel.Header.RefreshButton
                isFetching={contactQuery.isFetching || companyQuery.isFetching}
                onClick={() => {
                  contactQuery.refetch();
                  companyQuery.refetch();
                  portalUsersQuery.refetch();
                }}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                label="Company Role"
                name="company_role"
                options={companyRoleFilterOptions}
                value={filterOptions["Company Role"].value}
                onChange={(value, label) => {
                  removeAllSelectedContacts();
                  setFilterOption("Company Role", value, label);
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <FilterSelect
                label="Contact Role"
                name="contact_role"
                options={contactRoleFilterOptions}
                value={filterOptions["Contact Role"].value}
                onChange={(value, label) => {
                  removeAllSelectedContacts();
                  setFilterOption("Contact Role", value, label);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        {possibleRoles.map((role) => {
          if (!companyRoles.includes(role)) {
            return;
          }
          return (
            <>
              <PaperItem.Header>
                <PaperItem.Body>
                  <h3 style={{margin: 0}}>{role}</h3>
                </PaperItem.Body>
              </PaperItem.Header>
              {/* {companyLookup[role].map((company) => ( */}
              {sortBy(companyLookup[role], "csi_code.code").map((company) => (
                <>
                  <CompanyPaperItem
                    company={company}
                    onAddContact={() => {
                      setActiveProjectCompany(company);
                      setAddContactToCompanyDialogIsOpen(true);
                    }}
                    onEditCompany={() => {
                      setActiveProjectCompany(company);
                      setEditCompanyDialogIsOpen(true);
                    }}
                    onMarkPrimary={() => {
                      setActiveProjectCompany(company);
                      setPrimaryCompanyDialogIsOpen(true);
                    }}
                    key={company.id}
                  />

                  {(contactLookup[company.company.id] ?? []).map((contact) => {
                    if (contact.project_company_id === company.id) {
                      return (
                        <ContactPaperItem
                          key={contact.id}
                          contact={contact}
                          isSelected={selectedContacts.has(contact.id)}
                          onChangeSelected={(e, value) => {
                            if (value) {
                              addSelectedContact(contact.id, e.nativeEvent.shiftKey);
                            } else {
                              removeSelectedContact(contact.id);
                            }
                          }}
                          isPortalUser={Boolean(portalUsers.find((user) => user.email === contact.contact.email))}
                          onPortalInvite={() => {
                            setActiveProjectContact(contact);
                            setInviteToPortalDialogIsOpen(true);
                          }}
                          onMarkPrimary={() => {
                            setActiveProjectContact(contact);
                            setPrimaryContactDialogIsOpen(true);
                          }}
                          onCarbonCopy={() => {
                            updateContact
                              .mutateAsync({id: contact.id, cc: !contact.cc})
                              .then(() => contactQuery.refetch());
                          }}
                          onEditContact={() => {
                            setActiveProjectContact(contact);
                            setEditContactDialogIsOpen(true);
                          }}
                        />
                      );
                    }
                  })}
                </>
              ))}
            </>
          );
        })}
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/directory/legacy/`} mt={2} />

      <AddCompanyDialogForm
        projectId={project.id}
        onSubmit={(values) => {
          values.company_role_id = values.company_role_id.value;
          blockUI.blockUI("Saving...");
          createCompany
            .mutateAsync(values)
            .then((response) => {
              setActiveProjectCompany(projectCompanies.find((company) => company.id === response.id));
              return companyQuery.refetch();
            })
            .then(() => setAddCompanyDialogIsOpen(false))
            .then(() => {
              blockUI.unblockUI();
              setAddContactToCompanyDialogIsOpen(true);
            })
            .catch(() => blockUI.unblockUI());
        }}
        handleClose={() => setAddCompanyDialogIsOpen(false)}
        isReadOnly={project.is_closed}
        isOpen={addCompanyDialogIsOpen}
      />

      <LinkContactToCompanyDialog
        ref={linkFormRef}
        isOpen={addContactToCompanyDialogIsOpen}
        handleClose={() => setAddContactToCompanyDialogIsOpen(false)}
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;
          values.contact_role_id = values.contact_role_id?.value;

          if (submitMode === "addAnother") {
            blockUI.blockUI("Creating...");

            const focusElement = linkFormRef.current.elements.contact;
            createContact
              .mutateAsync({...values, project_company_id: activeProjectCompany.id})
              .then(() => {
                // form.reset();
                form.restart();
                focusElement.focus();
                return contactQuery.refetch();
              })
              .then(() => blockUI.unblockUI());
          } else {
            createContact.mutateAsync({...values, project_company_id: activeProjectCompany.id});
            setAddContactToCompanyDialogIsOpen(false);
          }
        }}
        company={activeProjectCompany}
        // contacts={contacts.filter(contact => contact.)}
      />

      <CompanyCreateDialog
        onSubmit={(values) => {
          axiosAPI.post(`/companies/`, values).then(() => setCreateCompanyDialogIsOpen(false));
        }}
        isOpen={createCompanyDialogIsOpen}
        handleClose={() => setCreateCompanyDialogIsOpen(false)}
      />

      <ProjectCompanyEditDialog
        projectCompany={activeProjectCompany}
        projectId={project.id}
        isOpen={editCompanyDialogIsOpen}
        handleClose={() => setEditCompanyDialogIsOpen(false)}
        onSubmit={(values) => {
          updateCompany.mutateAsync(values).then(() => {
            setEditCompanyDialogIsOpen(false);
            companyQuery.refetch();
          });
        }}
        initialValues={activeProjectCompany}
        onDelete={() => setDeleteCompanyConfirmationIsOpen(true)}
      />

      <ProjectContactEditDialog
        isOpen={editContactDialogIsOpen}
        handleClose={() => setEditContactDialogIsOpen(false)}
        isReadOnly={project.is_closed}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateContact
            .mutateAsync(values)
            .then(() => contactQuery.refetch())
            .then(() => {
              setEditContactDialogIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        projectContact={activeProjectContact}
        initialValues={activeProjectContact}
        onDelete={() => setDeleteContactConfirmationIsOpen(true)}
      />

      <ContactCreateDialog
        isOpen={createContactDialogIsOpen}
        handleClose={() => setCreateContactDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          // createContact
          //   .mutateAsync(values)
          axiosAPI
            .post(`/contacts/`, values)
            .then(() => Promise.all([companyQuery.refetch(), contactQuery.refetch()]))
            .then(() => {
              setCreateContactDialogIsOpen(false);
              blockUI.unblockUI();
            });
        }}
      />

      <ConfirmationDialog
        isOpen={primaryCompanyDialogIsOpen}
        onDeny={() => setPrimaryCompanyDialogIsOpen(false)}
        onApprove={() => {
          updateCompany
            .mutateAsync({...activeProjectCompany, is_primary: true})
            .then(() => companyQuery.refetch())
            .then(() => {
              setPrimaryCompanyDialogIsOpen(false);
              setActiveProjectCompany({});
            });
        }}
      >
        Mark {activeProjectCompany?.company?.name} as the primary {activeProjectCompany?.company_role_display}?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={primaryContactDialogIsOpen}
        onDeny={() => setPrimaryContactDialogIsOpen(false)}
        onApprove={() => {
          updateContact
            .mutateAsync({...activeProjectContact, is_primary: true})
            .then(() => contactQuery.refetch())
            .then(() => {
              setPrimaryContactDialogIsOpen(false);
              setActiveProjectContact({});
            });
        }}
      >
        Mark {activeProjectContact?.contact?.full_name} as the primary contact?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={inviteToPortalDialogIsOpen}
        onDeny={() => setInviteToPortalDialogIsOpen(false)}
        onApprove={() => {
          axiosAPI.post(`/contacts/${activeProjectContact.contact.id}/invite-to-portal/`).then((response) => {
            setInviteToPortalDialogIsOpen(false);
            setActiveProjectContact({});
            enqueueSnackbar(response.status === 200 ? "Email Sent!" : "Failed to Send Email", {
              variant: response.status === 200 ? "success" : "warning",
            });
          });
        }}
      >
        Are you sure you want to invite {activeProjectContact?.contact?.full_name} to use the Sentinel portal? This will
        send an invitation email.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={createExternalBoxDialogIsOpen}
        onDeny={() => setCreateExternalBoxDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Creating...");
          axiosAPI({
            url: `/projects/${project.id}/actions/grant_external_folder_access/`,
            method: "post",
            baseURL: "",
            params: {
              send_notifications: sendNotificationsOnBoxCreate,
            },
          }).then((response) => {
            setCreateExternalBoxDialogIsOpen(false);
            blockUI.unblockUI();
            enqueueSnackbar(
              response.status === 200 ? "External Box folder successfully created." : "Failed to Create Box Folder",
              {variant: response.status === 200 ? "success" : "warning"}
            );
          });
        }}
        title="Create External Box Folder"
      >
        <>
          Are you sure you to create a shared folder and grant access to all external users in this directory? External
          users who are not already collaborators on this folder will be added as collaborators.
          <div>
            <Checkbox
              size="small"
              checked={sendNotificationsOnBoxCreate}
              onChange={() => setSendNotificationsOnBoxCreate(!sendNotificationsOnBoxCreate)}
            />{" "}
            Have Box send notifications
          </div>
        </>
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteContactConfirmationIsOpen}
        onDeny={() => setDeleteContactConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteContact
            .mutateAsync(activeProjectContact.id)
            .then(() => Promise.all([companyQuery.refetch(), contactQuery.refetch()]))
            .then(() => {
              setEditContactDialogIsOpen(false);
              setDeleteContactConfirmationIsOpen(false);
              blockUI.unblockUI();
            });
        }}
        title="Are you sure?"
      >
        You want to delete {activeProjectContact?.contact?.full_name} from this project. This cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={bulkDeleteContactsConfirmationIsOpen}
        onApprove={() => {
          setBulkDeleteContactsConfirmationIsOpen(false);
          blockUI.blockUI("Deleting...");
          contactRPC
            .mutateAsync({
              action: "delete",
              method: "DELETE",
              data: {ids: Array.from(selectedContacts)},
            })
            .then(() => {
              contactQuery.refetch().then(() => {
                removeAllSelectedContacts();
                blockUI.unblockUI();
              });
            });
        }}
        onDeny={() => setBulkDeleteContactsConfirmationIsOpen(false)}
      >
        You want to delete the selected {selectedContacts.size} contacts.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteCompanyConfirmationIsOpen}
        onDeny={() => setDeleteCompanyConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteCompany
            .mutateAsync(activeProjectCompany.id)
            .then(() => Promise.all([companyQuery.refetch(), contactQuery.refetch()]))
            .then(() => {
              setEditCompanyDialogIsOpen(false);
              setDeleteCompanyConfirmationIsOpen(false);
              blockUI.unblockUI();
            });
        }}
        title="Are you sure?"
      >
        You want to delete {activeProjectCompany?.company?.name} from this project. This cannot be undone.
      </ConfirmationDialog>
      {/* <h1>PROJECT DIRECTORY</h1>
      <Typography variant="h2">Companies</Typography>
      <pre>{JSON.stringify(projectCompanies, null, 2)}</pre>

      <Typography variant="h2">Portal Users</Typography>
      <pre>{JSON.stringify(portalUsers, null, 2)}</pre>

      <Typography variant="h2">Contacts</Typography>
      <pre>{JSON.stringify(contacts, null, 2)}</pre> */}
    </>
  );
};

export default ProjectDirectory;
