import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import Typography2 from "../../../components/Typography2";
import SCOItemPaperItemGrid from "./SCOItemPaperItemGrid";

export const SCOItemPaperItem = (props) => {
  const {item, isSelected, onChangeItemSelected, onEditItem, ...rest} = props;

  return (
    <>
      <PaperItem>
        <PaperItem.Left minWidth={45}>
          <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangeItemSelected} />
        </PaperItem.Left>

        <PaperItem.Body>
          <Grid container>
            <Grid container item xs={12} sm={7}>
              <Grid item xs={12}>
                <StatusLabel status={item.status_display} />{" "}
                <Link href={`${item.url}#tab_items`} underline="always">
                  {item.pco_display}
                </Link>
              </Grid>
              <Grid item xs={12} sm={4}>
                <Tooltip title="Budget">
                  <span>
                    <Typography2 type="metadata">{item.budget?.display}</Typography2>
                  </span>
                </Tooltip>
              </Grid>
              <Grid item xs={12} sm={8}>
                <Tooltip title="Description">
                  <span>
                    <Typography2 type="metadata">{item.description}</Typography2>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={5}>
              <SCOItemPaperItemGrid item={item} onEditCost={onEditItem} />
            </Grid>
          </Grid>
        </PaperItem.Body>
        <PaperItem.Right minWidth={70}>
          <Box textAlign="right">
            <Typography2 noWrap type="metadata">
              {item.number_display}
            </Typography2>
          </Box>
        </PaperItem.Right>
        <PaperItem.RightHover>
          <PaperItem.RightHover.IconButton icon={LinkIcon} title="Link" component={Link} href={item.url} />
          <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditItem} />
        </PaperItem.RightHover>
      </PaperItem>
    </>
  );
};
