import {faBan, faClosedCaptioning, faCloudUpload, faPaperPlane, faPenFancy} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Breadcrumbs, Grid, Link, MenuItem, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {Alert, AlertTitle, TabContext, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import Helmet from "react-helmet";
import {PDFObject} from "react-pdfobject";
import {useNavigate, useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {ProjectBreadcrumbs} from "../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../components/Dialogs";
import BlockUI from "../../components/GlobalLoaders";
import {LabelValue} from "../../components/LabelValue";
import {LegacyUILink, MuiNavLink} from "../../components/Links";
import {PageHeader} from "../../components/PageHeader";
import PaperPanel from "../../components/PaperPanel";
import {ReactTableMui} from "../../components/ReactTableMui";
import {StatusLabel} from "../../components/Status";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI} from "../../hooks/useSentinelAPI";
import useWaffle from "../../hooks/useWaffle";
import {makeNovoClasses} from "../../theme";
import {VoidEnvelopeDialogForm} from "./DocuSignEnvelopeForms";

const DocuSignEnvelopeDetail = (props) => {
  const {...rest} = props;
  const {envelopeUUID} = useParams();
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const waffle = useWaffle();

  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [deleteDialogIsOpen, setDeleteDialogIsOpen] = React.useState(false);
  const [voidDialogIsOpen, setVoidDialogIsOpen] = React.useState(false);

  const pageDataQuery = useFetchCurrentPage(
    {
      initialData: {
        envelope: {
          object: {
            display: "",
            docusign_envelope_list_url: "",
          },
          recipients: {
            signers: [],
            carbonCopies: [],
          },
        },
        lock: true,
        documents: [],
      },
    },
    {}
  );

  const envelope = pageDataQuery.data.envelope;
  const pageData = pageDataQuery.data;

  const {query: auditLogQuery} = useSentinelDetailAPI(`/docusign/envelopes/${envelopeUUID}/audit_events/`, {
    initialData: [],
  });

  const auditLog = auditLogQuery.data;

  const {rpc: docusignRPC} = useSentinelDetailAPI(`/docusign/sentinel-envelopes/${envelope.id}/`, {
    initialData: {},
    enabled: Boolean(pageDataQuery.isFetchedAfterMount),
  });

  const lockedUntilString = `This envelope is locked. This lock will expire ${moment(
    pageData?.lock?.lockedUntilDateTime
  ).fromNow()}.`;

  const project = envelope.project;
  const hasProject = Boolean(project);

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message={`Fetching envelope details...`} />;
  }

  return (
    <>
      <Helmet title={`${envelope?.object?.display} - Envelope Detail`} />
      {hasProject ? (
        <ProjectBreadcrumbs project={project}>
          <Link href={envelope?.object?.url}>
            <Typography color="textSecondary">{envelope?.object?.display}</Typography>
          </Link>
          <Typography color="textSecondary">Docusign</Typography>
          {envelope.object?.docusign_envelope_list_url && (
            <MuiNavLink href={envelope.object?.docusign_envelope_list_url}>
              <Typography color="textSecondary">Envelopes</Typography>
            </MuiNavLink>
          )}
          <Typography color="textPrimary">{envelopeUUID}</Typography>
        </ProjectBreadcrumbs>
      ) : (
        <Breadcrumbs>
          <Typography color="textSecondary">Docusign</Typography>
          <Link href={envelope.object.docusign_envelope_list_url}>
            <Typography color="textSecondary">Envelopes</Typography>
          </Link>
          <Link color="inherit" href={envelope?.object?.url}>
            {envelope?.object?.display}
          </Link>
        </Breadcrumbs>
      )}

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faPenFancy} fixedWidth /> Docusign
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader>
      <Box my={1}>
        <Alert severity="info">DocuSign Envelope detail can take up to 20 minutes to update.</Alert>
      </Box>

      {/* <pre>{JSON.stringify(pageData.envelope.recipients, null, 2)}</pre> */}

      {pageData.lock?.lockedUntilDateTime && (
        <Box my={1}>
          <Alert severity="warning">
            <AlertTitle>{lockedUntilString}</AlertTitle>
            <Box>
              Make sure that you click "Other Actions" &#8594; "Save and Close" when editing an envelope to prevent
              locking.
            </Box>
          </Alert>
        </Box>
      )}
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={"Envelope"} value="general" className={novoClasses.smallTab} />
              {/* <Tab label={"Details"} value="details" className={novoClasses.smallTab} /> */}
              <Tab label={"Logs"} value="logs" className={novoClasses.smallTab} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={pageDataQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <StatusLabel status={envelope.status} component="span" /> Envelope For {envelope?.object?.display}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  {envelope?.object?.can_upload_envelope_to_box && (
                    <PaperPanel.Header.Action>
                      <PaperPanel.Header.ColoredButton
                        startIcon={<FontAwesomeIcon icon={faCloudUpload} />}
                        onClick={() => {
                          blockUI.blockUI("Uploading...");
                          //   axiosAPI
                          //     .post(`/docusign/envelopes/${envelope.envelope_id}/upload-to-box/`, {baseURL: ""})
                          //     .then(() => blockUI.unblockUI());
                          docusignRPC
                            .mutateAsync({action: "upload-to-box"})
                            .then(() => blockUI.unblockUI())
                            .catch(() => blockUI.unblockUI());
                        }}
                      >
                        Upload Current Envelope To Box
                      </PaperPanel.Header.ColoredButton>
                    </PaperPanel.Header.Action>
                  )}
                  {[envelope.can_delete, envelope.can_send, envelope.can_void, envelope.can_resend].includes(true) && (
                    <PaperPanel.Header.Action border>
                      {envelope.can_delete && (
                        <>
                          <Tooltip title={pageData?.lock ? lockedUntilString : ""}>
                            <span>
                              <PaperPanel.Header.DeleteButton
                                disabled={pageData?.lock}
                                onClick={() => setDeleteDialogIsOpen(true)}
                              />
                            </span>
                          </Tooltip>
                          <Box ml={1} />
                        </>
                      )}
                      {envelope.can_resend && (
                        <Box mx={1}>
                          <PaperPanel.Header.EditButton
                            startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                            onClick={() => {
                              blockUI.blockUI("Resending...");
                              docusignRPC
                                .mutateAsync({action: "resend"})
                                .then(() => blockUI.unblockUI())
                                .catch(() => blockUI.unblockUI());
                            }}
                          >
                            Resend
                          </PaperPanel.Header.EditButton>
                        </Box>
                      )}
                      {envelope.can_send && (
                        <Tooltip title={pageData?.lock ? lockedUntilString : ""}>
                          <span>
                            <PaperPanel.Header.EditButton
                              startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                              href={`/docusign/envelopes/${envelopeUUID}/views/sender/`}
                            >
                              Edit/Send
                            </PaperPanel.Header.EditButton>
                          </span>
                        </Tooltip>
                      )}
                      {envelope.can_void && (
                        <Box mx={1}>
                          <PaperPanel.Header.ColoredButton
                            onClick={() => setVoidDialogIsOpen(true)}
                            startIcon={<FontAwesomeIcon icon={faBan} />}
                          >
                            Void
                          </PaperPanel.Header.ColoredButton>
                        </Box>
                      )}
                    </PaperPanel.Header.Action>
                  )}
                  <PaperPanel.Header.Action border>
                    {waffle.FLAGS.docusign_supplemental_docs && (
                      <PaperPanel.Header.DropdownMenu
                        title="Supplemental Docs"
                        size="small"
                        disabled={
                          !pageData.supplemental_docusign_documents ||
                          pageData.supplemental_docusign_documents.length < 1
                        }
                      >
                        {(setOpen) =>
                          pageData.supplemental_docusign_documents.map((document) => (
                            <div key={document.name}>
                              <MenuItem
                                onClick={() => {
                                  setOpen(false);
                                  // window.open(document.url, "_blank");
                                }}
                                href={document.url}
                                component={Link}
                                target="_blank"
                              >
                                {document.name}
                              </MenuItem>
                            </div>
                          ))
                        }
                      </PaperPanel.Header.DropdownMenu>
                    )}
                    <Box ml={1} />
                    <PaperPanel.Header.PDFButton
                      href={`/docusign/envelopes/${envelope.envelope_id}/download/`}
                      title="View Envelope"
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      onClick={() => {
                        blockUI.blockUI("Refreshing Envelope Data...");
                        Promise.all([pageDataQuery.refetch(), auditLogQuery.refetch()]).then(() => blockUI.unblockUI());
                      }}
                      isFetching={pageDataQuery.isFetching || auditLogQuery.isFetching}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PaperPanel.Body>
                <Grid container>
                  <Grid item xs={12} sm={6}>
                    <LabelValue label="Status:" value={envelope.status} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelValue label="Created:" value={moment(envelope.created).format("LL")} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelValue label="Created By:" value={envelope?.created_by?.full_name} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelValue label="Last Modified:" value={moment(envelope.modified).format("LL")} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelValue label="Sent:" value={envelope.sent ? moment(envelope.sent).format("LL") : "Not Sent"} />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LabelValue
                      label="Completed:"
                      value={envelope.sent ? moment(envelope.sent).format("LL") : "Not Completed"}
                    />
                  </Grid>
                </Grid>

                <PaperPanel.TabHeader sectionHeader>
                  <PaperPanel.Header.Title>Documents</PaperPanel.Header.Title>
                </PaperPanel.TabHeader>

                <PaperPanel.Body sectionBody>
                  <ReactTableMui
                    size="small"
                    className={classnames(
                      novoClasses.stripedTable,
                      novoClasses.mediumTable,
                      novoClasses.boldHeaderTable
                    )}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "Order",
                        accessor: "order",
                      },
                      {
                        Header: "Pages",
                        accessor: "pages",
                      },
                    ]}
                    initialState={{
                      sortBy: [
                        {
                          id: "order",
                          desc: false,
                        },
                      ],
                    }}
                    data={pageData.documents}
                  />
                </PaperPanel.Body>

                {/* <Box mx={-3} mt={3} mb={-3}>
                  <PaperPanel> */}
                <PaperPanel.TabHeader sectionHeader>
                  <PaperPanel.Header.Title>Recipients</PaperPanel.Header.Title>
                </PaperPanel.TabHeader>
                <PaperPanel.Body sectionBody>
                  <ReactTableMui
                    size="small"
                    className={classnames(
                      novoClasses.stripedTable,
                      novoClasses.mediumTable,
                      novoClasses.boldHeaderTable
                    )}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                      },
                      {
                        Header: "Order",
                        accessor: "routingOrder",
                      },
                      {
                        Header: "Delivered",
                        Cell: ({row, value}) =>
                          row.original.deliveredDateTime ? (
                            <Tooltip title={moment(row.original.deliveredDateTime).fromNow()}>
                              <span>{moment(row.original.deliveredDateTime).format("LLLL")}</span>
                            </Tooltip>
                          ) : (
                            "Not Delivered"
                          ),
                        // row.original.deliveredDateTime ? moment(row.original.deliveredDateTime).fromNow() : "",
                      },
                      {
                        Header: "Signed",
                        Cell: ({row}) =>
                          row.original.signedDateTime ? (
                            <Tooltip title={moment(row.original.signedDateTime).fromNow()}>
                              <span>{moment(row.original.signedDateTime).format("LLLL")}</span>
                            </Tooltip>
                          ) : (
                            "Not Signed"
                          ),
                        // row.original.signedDateTime ? moment(row.original.signedDateTime).fromNow() : "",
                      },
                    ]}
                    initialState={{
                      sortBy: [
                        {
                          id: "routingOrder",
                          desc: false,
                        },
                      ],
                    }}
                    data={pageData.envelope.recipients?.signers}
                  />
                </PaperPanel.Body>
                <PaperPanel.TabHeader sectionHeader>
                  <PaperPanel.Header.Title>
                    <FontAwesomeIcon icon={faClosedCaptioning} /> Carbon Recipients
                  </PaperPanel.Header.Title>
                </PaperPanel.TabHeader>
                <PaperPanel.Body sectionBody>
                  <ReactTableMui
                    size="small"
                    className={classnames(
                      novoClasses.stripedTable,
                      novoClasses.mediumTable,
                      novoClasses.boldHeaderTable
                    )}
                    columns={[
                      {
                        Header: "Name",
                        accessor: "name",
                      },
                      {
                        Header: "Email",
                        accessor: "email",
                      },
                      {
                        Header: "Status",
                        accessor: "status",
                      },
                      {
                        Header: "Delivered",
                        Cell: ({row, value}) =>
                          row.original.deliveredDateTime ? moment(row.original.deliveredDateTime).fromNow() : "",
                      },
                    ]}
                    initialState={{
                      sortBy: [{}],
                    }}
                    data={pageData.envelope.recipients?.carbonCopies}
                  />
                </PaperPanel.Body>
                <Box mb={3} />
                {envelope.get_download_url && (
                  <PDFObject
                    url={envelope.get_download_url}
                    pdfOpenParams={{pagemode: "thumbs", view: "FitH"}}
                    containerProps={{style: {width: "100%", height: "50rem"}}}
                  />
                )}
                {/* </PaperPanel>
                </Box> */}
              </PaperPanel.Body>
            </>
          </TabPanel>

          <TabPanel value="logs">
            {/* <Box mx={-3} mb={-3}> */}
            {/* <PaperPanel> */}
            <PaperPanel.TabHeader isLoading={pageDataQuery.isFetching}>
              <PaperPanel.Header.Title>Audit Events</PaperPanel.Header.Title>
            </PaperPanel.TabHeader>

            <PaperPanel.Body mx={-3} mb={-3} mt={-2}>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
                columns={[
                  {
                    Header: "Action",
                    accessor: "Action",
                  },
                  {
                    Header: "Time",
                    accessor: "logTime",
                    Cell: ({value}) => moment(value).format("LLLL"),
                  },
                  {
                    Header: "Message",
                    accessor: "Message",
                  },
                ]}
                initialState={{
                  sortBy: [{}],
                }}
                data={auditLog}
              />
            </PaperPanel.Body>
            {/* </PaperPanel> */}
            {/* </Box> */}
          </TabPanel>
        </PaperPanel>
        <Box mt={1} />
        <LegacyUILink href={`/docusign/envelopes/${envelopeUUID}/`} />
      </TabContext>

      <ConfirmationDialog
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          const listURL = envelope.object?.docusign_envelope_list_url;
          docusignRPC.mutateAsync({action: "delete"}).then(() => {
            navigate(listURL);
            blockUI.unblockUI();
          });
        }}
        onDeny={() => setDeleteDialogIsOpen(false)}
        isOpen={deleteDialogIsOpen}
        title="Delete this Envelope?"
      >
        Are you sure you want to delete this envelope?
      </ConfirmationDialog>

      <VoidEnvelopeDialogForm
        isOpen={voidDialogIsOpen}
        handleClose={() => setVoidDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Voiding...");
          docusignRPC
            .mutateAsync({action: "void", data: {...values}})
            .then(() => {
              navigate(`${envelope.object?.docusign_envelope_list_url}`);
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
      />
    </>
  );
};

export default DocuSignEnvelopeDetail;
