import {Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../../hooks/useSentinelAPI";

const ProjectAIDocumentAnalysisDetail = (props) => {
  const {project} = props;

  const blockUI = useBlockUI();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;
  const postCurrentPage = usePostCurrentPage();

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Analyzing Document..." />;
  }

  return (
    <>
      <Helmet title={`${project.display} - AI Document Analysis`} />

      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/ai/documents/`}>
          <Typography color="textSecondary">AI Documents</Typography>
        </MuiNavLink>
        <MuiNavLink href={`/v2/projects/${project.id}/ai/documents/analysis/`}>
          <Typography color="textSecondary">AI Document Analysis</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">{pageData?.aiDocument?.name}</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={pageDataQuery.isFetching}>
          <PaperPanel.Header.Title>AI Document Analysis</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  blockUI.blockUI("Analyzing Document...");
                  postCurrentPage.mutateAsync().then(() => {
                    pageDataQuery.refetch().then(() => blockUI.unblockUI());
                  });
                }}
                isFetching={pageDataQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre>
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default ProjectAIDocumentAnalysisDetail;
