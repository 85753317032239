import {Link} from "@material-ui/core";
import {Alert, AlertTitle} from "@material-ui/lab";

const WellnessCheckSiteInvalidDateMessage = (props) => {
  const {wellnessCheckResult, twilioNumber, twilioNumberDisplay} = props;
  const jobCode = wellnessCheckResult?.project?.timberline_job.replace("-", "");

  return (
    <>
      <Alert severity="error">
        <AlertTitle>Invalid Date</AlertTitle>
        This link was for a prior date {wellnessCheckResult.compliance_date}. Please text your job code{" "}
        <strong>{jobCode}</strong> to{" "}
        <strong>
          <Link href={`sms:+${twilioNumber}&body=${jobCode}`} underline="always">
            {twilioNumberDisplay}
          </Link>
        </strong>{" "}
        to complete todays questionnaire.
      </Alert>
    </>
  );
};

export default WellnessCheckSiteInvalidDateMessage;
