import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import {ConfirmationDialog} from "../../../components/Dialogs";
import sentVia from "../../../components/forms/choices/sentVia.json";
import transmittalTransmittedFor from "../../../components/forms/choices/transmittalTransmittedFor.json";
import {
  DatePickerMui,
  ProjectContactAutoselectMui,
  SimpleAutoselectMui,
  SingleAutocompleteMui,
  TextFieldMui,
  TinyMCEMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";
import Typography2 from "../../../components/Typography2";

export const SubmittalTransmittalCommonFields = (props) => {
  const {projectId, form, approverIds, ...rest} = props;
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <ProjectContactAutoselectMui
          projectId={projectId}
          name="from_contact"
          label="From Contact"
          disableClearable={false}
          required
          onChange={(event, value, reason) => {
            if (reason === "select-option") {
              axiosAPI.get(`projects/${projectId}/companies/${value.company.id}/location/`).then((response) => {
                if (!response.data?.full_address) return;
                form.batch(() => {
                  form.change("from_address", response.data?.full_address);
                });
              });
            }
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <ProjectContactAutoselectMui
          projectId={projectId}
          name="to_contact"
          label="To Contact"
          disableClearable={false}
          required
          autoFocus
          onChange={(event, value, reason) => {
            if (reason === "select-option") {
              axiosAPI.get(`projects/${projectId}/companies/${value.company.id}/location/`).then((response) => {
                if (!response.data?.full_address) return;
                form.batch(() => {
                  form.change("to_address", response.data?.full_address);
                });
              });
            }
          }}
          renderOption={(option: any) => {
            return (
              <>
                <div>
                  {option.first_name} {option.last_name} {approverIds.includes(option.id) && `(Approver)`}
                </div>
                <div>
                  <Typography2 type="metadata">{`${option.company.name}`}</Typography2>
                </div>
              </>
            );
          }}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldMui name="from_address" label="From Address" multiline minRows={3} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldMui name="to_address" label="To Address" multiline minRows={3} />
      </Grid>
      <Grid item xs={12} sm={4}>
        <SimpleAutoselectMui options={transmittalTransmittedFor} name="transmitted_for" label="Transmitted For" />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePickerMui name="date_sent" label="Date Sent" required />
      </Grid>
      <Grid item xs={12} sm={4}>
        <DatePickerMui name="date_required" label="Date Required" />
      </Grid>
      <Grid item xs={12} sm={6}>
        {/* <SimpleAutoselectMui options={sentVia} name="sent_via" label="Sent Via" /> */}
        <SingleAutocompleteMui
          label="Sent Via"
          name="sent_via"
          options={sentVia.map((option) => {
            return {
              value: option.label,
              label: option.label,
            };
          })}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextFieldMui name="tracking_number" label="Tracking Number" />
      </Grid>
      <Grid item xs={12}>
        <TinyMCEMui name="comments" label="Comments" />
      </Grid>
    </Grid>
  );
};

export const SubmittalTransmittalDialogForm = (props: {
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isNew?: boolean;
  isReadOnly?: boolean;
  projectId: number;
  approverIds?: number[];
  [rest: string]: any;
}) => {
  const {
    onSubmit,
    isOpen,
    isNew,
    handleClose,
    isReadOnly,
    projectId,
    approverIds,
    initialValues: _initialValues,
    ...rest
  } = props;

  const [initialValues, setInitialValues] = React.useState(_initialValues);
  React.useEffect(() => {
    setInitialValues(_initialValues);
  }, [_initialValues]);

  React.useEffect(() => {
    if (!isOpen || !isNew) return;
    if (_initialValues.to_contact) {
      axiosAPI
        .get(`projects/${projectId}/companies/${_initialValues.to_contact.company.id}/location/`)
        .then((response) => {
          if (!response.data?.full_address) return;

          setInitialValues((prevInitialValues) => {
            prevInitialValues["to_address"] = response.data.full_address;
            return {...prevInitialValues};
          });
        });
    }
    if (_initialValues.from_contact) {
      axiosAPI
        .get(`projects/${projectId}/companies/${_initialValues.from_contact.company.id}/location/`)
        .then((response) => {
          if (!response.data?.full_address) return;
          setInitialValues((prevInitialValues) => {
            prevInitialValues["from_address"] = response.data.full_address;
            return {...prevInitialValues};
          });
        });
    }
  }, [_initialValues]);

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
      {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
      <FinalForm onSubmit={onSubmit} initialValues={initialValues} {...rest}>
        {({handleSubmit, form, submitting, pristine = true, values}) => {
          return (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle>{isNew ? "Create" : "Update"} Submittal Transmittal</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <SubmittalTransmittalCommonFields projectId={projectId} form={form} approverIds={approverIds} />
                </FinalFormKeyboardShortcuts>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || (pristine && !isNew) || isReadOnly} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

export const SubmittalTransmittalDetailForm = (props: {
  onSubmit: (values: any) => void;
  onDelete: () => void;
  projectId: number;
  isNew?: boolean;
  isReadOnly?: boolean;
  approverIds: number[];
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, isNew, isReadOnly, approverIds, onDelete, ...rest} = props;
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  return (
    <>
      <FinalForm onSubmit={(values) => {}} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values} = props;
          return (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <SubmittalTransmittalCommonFields projectId={projectId} form={form} approverIds={approverIds} />
                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton
                        disabled={submitting || pristine}
                        onClick={() => {
                          onSubmit(values);
                        }}
                      />
                      <Box ml={1} />
                      <FormActions.SaveAndEditButton
                        disabled={submitting || pristine}
                        onClick={() => {
                          values.submitMode = "saveAndEdit";
                          onSubmit(values);
                        }}
                      />
                      <Box ml={1} />
                      <FormActions.DeleteButton onClick={() => setDeleteConfirmationIsOpen(true)} />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
              <ConfirmationDialog
                onDeny={() => setDeleteConfirmationIsOpen(false)}
                onApprove={onDelete}
                isOpen={deleteConfirmationIsOpen}
                title="Delete this transmittal?"
              >
                You want to delete submittal transmittal #{values.number_display}? This can not be undone.
              </ConfirmationDialog>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};
