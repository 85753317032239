import {faSpinner} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Backdrop, Box, CircularProgress, Fade, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

import {useIsFetching} from "react-query";
import styled from "styled-components";
import useBlockUI from "../hooks/useBlockUI";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 10000,
  },
}));

const SpinnerIcon = styled(FontAwesomeIcon)`
  color: ${(props) => props.theme.palette.novo};
  position: fixed;
  top: 5rem;
  right: 2rem;
  font-size: 1.5rem;
  transition: 0.3s ease;
  pointer-events: none;
`;

const OldGlobalLoader = () => {
  const isFetching = useIsFetching();

  return (
    <SpinnerIcon
      icon={faSpinner}
      spin
      style={{
        opacity: isFetching ? 1 : 0,
      }}
    />
  );
};

const Root = styled.div`
  position: fixed;
  top: 5rem;
  right: 2rem;
  min-height: 100%;
`;

export const ReactQueryGlobalLoader = () => {
  // Cast isFetching to Boolean:
  // Will be the number (int) of the queries is currently loading or fetching in the background.
  const isFetching = useIsFetching();
  return (
    <Root>
      <Fade
        in={Boolean(isFetching)}
        style={{
          transitionDelay: isFetching ? "300ms" : "0ms",
        }}
        unmountOnExit
      >
        <CircularProgress color="secondary" />
      </Fade>
    </Root>
  );
};

export const BlockUI = (props) => {
  const classes = useStyles();
  const blockUI = useBlockUI();

  return (
    <Backdrop
      open={props.show}
      className={classes.backdrop}
      onClick={() => {
        blockUI.unblockUI();
      }}
    >
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <CircularProgress color="secondary" />
        {props.message && <Typography color="secondary">{props.message}</Typography>}
      </Box>
    </Backdrop>
  );
};

export default BlockUI;
