import {faCircleExclamation} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import accounting from "accounting";
import moment from "moment";
import pluralize from "pluralize";
import Typography2 from "./Typography2";

import {colorError, colorSuccess, colorWarning} from "../theme/colors";

export const DaysOverdueLabel = (props: {
  dueDate?: string;
  businessDaysOverdue?: number;
  showUpcomingWarning?: boolean;
  showUpcomingVisible?: boolean;
  [rest: string]: any;
}) => {
  const {dueDate: _dueDate, businessDaysOverdue, showUpcomingWarning, showUpcomingVisible, ...rest} = props;
  const dueDate = _dueDate ? moment(_dueDate) : undefined;

  if (!dueDate) {
    return null;
  }

  const daysUntilDue = dueDate.diff(moment().startOf("day"), "days");

  if (daysUntilDue <= 0) {
    return (
      <Typography2 type={"metadata"} style={{color: colorError}} {...rest}>
        <FontAwesomeIcon icon={faCircleExclamation} />{" "}
        {businessDaysOverdue ? (
          <>{`${accounting.format(businessDaysOverdue)} ${pluralize("business day", businessDaysOverdue)} overdue`}</>
        ) : (
          <>{daysUntilDue === 0 ? "Due today" : `${moment.duration(daysUntilDue * -1, "days").humanize()} overdue`}</>
        )}
      </Typography2>
    );
  }
  if (showUpcomingWarning) {
    return (
      <Typography2 type={"metadata"} style={{color: colorWarning}} {...rest}>
        <FontAwesomeIcon icon={faCircleExclamation} /> {`Due ${moment.duration(daysUntilDue, "days").humanize(true)}`}
      </Typography2>
    );
  }

  if (showUpcomingVisible) {
    return (
      <Typography2 type={"metadata"} style={{color: colorSuccess}} {...rest}>
        {`Due ${moment.duration(daysUntilDue, "days").humanize(true)}`}
      </Typography2>
    );
  }
};
