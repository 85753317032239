import {faPaperPlane} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Typography} from "@material-ui/core";
import qs from "qs";
import React from "react";
import Helmet from "react-helmet";
import {useNavigate} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {TransmittalDialogForm} from "./TransmittalForms";
import TransmittalPaperItem from "./TransmittalPaperItem";

const ProjectTransmittals = (props) => {
  const {project, ...rest} = props;

  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(100);
  const [createTransmittalDialogIsOpen, setCreateTransmittalDialogIsOpen] = React.useState(false);
  const [editTransmittalDialogIsOpen, setEditTransmittalDialogIsOpen] = React.useState(false);
  const [renumberConfirmationIsOpen, setRenumberConfirmationIsOpen] = React.useState(false);
  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(["Search"]);
  const [activeTransmittal, setActiveTransmittal] = React.useState({} as any);

  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const pageData = pageDataQuery.data;

  const filterParams = {
    q: filterOptions.Search.value,
  };

  const {
    query: transmittalQuery,
    create: createTransmittal,
    update: updateTransmittals,
    delete: deleteTransmittal,
    rpc: transmittalRPC,
  } = useSentinelListAPI(
    `projects/${project.id}/transmittals/?page_size=${pageSize}&page=${page}&parent__isnull=True&${qs.stringify(
      filterParams
    )}`,
    {
      initialData: {
        results: [],
      },
    }
  );

  const transmittals = transmittalQuery.data.results;

  return (
    <>
      <Helmet title={`${project.display} - Transmittals`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Transmittals</Typography>
      </ProjectBreadcrumbs>

      <PaperPanel>
        <PaperPanel.Header isLoading={transmittalQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faPaperPlane} fixedWidth /> Transmittals
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setCreateTransmittalDialogIsOpen(true)} />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.ReorderButton
                onReorder={(orderBy) => {
                  blockUI.blockUI("Reordering...");
                  transmittalRPC
                    .mutateAsync({
                      action: `reposition`,
                      qsParams: {order_by: orderBy},
                    })
                    .then(() => {
                      transmittalQuery.refetch().then(() => blockUI.unblockUI());
                    })
                    .catch(() => {
                      transmittalQuery.refetch().then(() => blockUI.unblockUI());
                    });
                }}
                onRenumber={() => {
                  setRenumberConfirmationIsOpen(true);
                }}
              />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton onClick={() => transmittalQuery.refetch()} />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination count={transmittalQuery.data.total_pages} page={page} setPage={setPage} />
              </Box>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>
        <PaperPanel.Body>
          <VirtualizedList itemList={transmittals} isVirtualized={transmittals.length > 100} disableDragAndDrop>
            {(props) => {
              const {listItem: transmittal, ...rest} = props;
              return (
                <TransmittalPaperItem
                  transmittal={transmittal}
                  onEdit={() => {
                    setActiveTransmittal(transmittal);
                    setEditTransmittalDialogIsOpen(true);
                  }}
                  projectId={project.id}
                  key={transmittal.id}
                  {...rest}
                />
              );
            }}
          </VirtualizedList>
          <PaperItem.Header>
            <Grid container>
              <Grid item xs={12}>
                <Box textAlign="right">{transmittalQuery?.data?.count} Total Transmittals</Box>
              </Grid>
            </Grid>
          </PaperItem.Header>
        </PaperPanel.Body>
        {(transmittalQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={transmittalQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[10, 100, 250, 500, 1000]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/transmittals/legacy/`} mt={2} />
      <ConfirmationDialog
        isOpen={renumberConfirmationIsOpen}
        onApprove={() => {
          setRenumberConfirmationIsOpen(false);
          blockUI.blockUI("Renumbering...");
          transmittalRPC
            .mutateAsync({
              action: `renumber-on-position`,
            })
            .then(() => {
              transmittalQuery.refetch().then(() => blockUI.unblockUI());
            })
            .catch(() => {
              transmittalQuery.refetch().then(() => blockUI.unblockUI());
            });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setRenumberConfirmationIsOpen(false);
        }}
      >
        Are you sure that you want to renumber all transmittals based on their current order? This cannot be undone.
      </ConfirmationDialog>

      <TransmittalDialogForm
        projectId={project.id}
        isNew
        isOpen={createTransmittalDialogIsOpen}
        handleClose={() => setCreateTransmittalDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          values.project = project.id;

          const submitMode = values.submitMode;
          delete values.submitMode;

          if (submitMode === "saveAndEdit") {
            createTransmittal.mutateAsync(values).then((response) => {
              navigate(`/v2/projects/${project.id}/transmittals/${response.id}/`);
              blockUI.unblockUI();
            });
          } else {
            createTransmittal
              .mutateAsync(values)
              .then(() => transmittalQuery.refetch())
              .then(() => {
                setCreateTransmittalDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }
        }}
        initialValues={{number: transmittalQuery?.data?.metadata?.next_number}}
      />

      <TransmittalDialogForm
        projectId={project.id}
        isOpen={editTransmittalDialogIsOpen}
        handleClose={() => setEditTransmittalDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          values.project = project.id;
          const submitMode = values.submitMode;
          delete values.submitMode;

          if (submitMode === "saveAndEdit") {
            updateTransmittals.mutateAsync(values).then((response) => {
              navigate(`/v2/projects/${project.id}/transmittals/${response.id}/`);
              blockUI.unblockUI();
            });
          } else {
            updateTransmittals
              .mutateAsync(values)
              .then(() => transmittalQuery.refetch())
              .then(() => {
                setEditTransmittalDialogIsOpen(false);
                blockUI.unblockUI();
              })
              .catch(() => blockUI.unblockUI());
          }
        }}
        initialValues={activeTransmittal}
      />
    </>
  );
};

export default ProjectTransmittals;
