import {Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";

import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {FileFieldMui, NumberFieldMui, TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import PaperPanel from "../../../components/PaperPanel";

export const SpecSectionUpdateForm = (props: {
  //   projectId: number;
  isNew?: boolean;
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isOpen, handleClose, isNew, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">{isNew ? `Create` : `Update`} Spec Section</DialogTitle>
              <DialogContent>
                {/* <DialogContentText>DialogContentText</DialogContentText> */}
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                      <TextFieldMui name="code" label="Code" required autoFocus />
                    </Grid>
                    <Grid item xs={12} md={8}>
                      <TextFieldMui name="description" label="Description" required />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                {!isNew && <FormActions.DeleteButton onClick={onDelete} />}
                <FormActions.SaveButton disabled={submitting || pristine} label="Save" startIcon={null} />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const SpecSectionUploadForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, action, ...rest} = props;
  const MAX_PAGE_DIFFERENCE = 4;
  const validatePageRange = (value, values, field) => {
    if (!value) return "Required";
    if (value < 1) return "Invalid Page Number";
    if (values?.end < values?.start) return "First page must come before last page.";
    if (Math.abs(values?.end - values?.start) > MAX_PAGE_DIFFERENCE)
      return `Max range is ${MAX_PAGE_DIFFERENCE + 1} pages.`;
  };
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Upload Spec Sections</DialogTitle>
              <DialogContent>
                <PaperPanel.Body>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormLabel>
                        Choose a spec book PDF to upload. Sentinel will use AI to try to determine all of the project
                        spec sections. This can take a few minutes depending on the size of the PDF. Always check the
                        results after completion.
                      </FormLabel>
                    </Grid>
                    <Grid item xs={12}>
                      <FileFieldMui label="File" name="file" accept=".pdf, .docx, .xlsx" required />
                    </Grid>
                    {values.file && (
                      <>
                        <Grid item xs={12}>
                          <FormLabel>
                            Please enter the pages that contain the spec section list. This is usually the table of
                            contents or first few pages.
                          </FormLabel>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NumberFieldMui
                            name="start"
                            label="From"
                            fieldProps={{validate: validatePageRange, validateFields: ["start", "end"]}}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <NumberFieldMui
                            name="end"
                            label="To"
                            fieldProps={{validate: validatePageRange, validateFields: ["start", "end"]}}
                          />
                        </Grid>
                      </>
                    )}
                  </Grid>
                </PaperPanel.Body>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label="Upload" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
