import {Box, Grid} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import {Currency} from "../../../components/Accounting";
import {StatusIcon} from "../../../components/Icons";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";
import usePermissions from "../../../hooks/usePermissions";

export const PrimeContractItemPaperItem = (props) => {
  const {item, projectId, onEditItem, onDeleteItem} = props;
  const permissions = usePermissions();
  return (
    <PaperItem>
      <PaperItem.Body>
        <Grid container spacing={0}>
          <Grid item xs={8}>
            {item.display}
          </Grid>
          <Grid item xs={3}>
            <Box textAlign="right">
              <Currency number={item.value} precision={2} />
            </Box>
          </Grid>
          <Grid item xs={1}>
            <Box textAlign="right">
              <Typography2 noWrap type="metadata">
                {item.number_display}
              </Typography2>
            </Box>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.RightHover>
        <PaperItem.RightHover.IconButton
          icon={EditIcon}
          title="Edit"
          onClick={onEditItem}
          disabled={!permissions.is_in_accounting_group}
        />
        <PaperItem.RightHover.IconButton
          icon={DeleteIcon}
          title="Delete"
          onClick={onDeleteItem}
          disabled={!permissions.is_in_accounting_group}
        />
      </PaperItem.RightHover>
    </PaperItem>
  );
};

export const PrimeContractOCOPaperItem = (props) => {
  const {item, projectId} = props;
  return (
    <PaperItem>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={6} xs={12}>
            <StatusIcon status={item.status_display} />{" "}
            <MuiNavLink to={`/v2/projects/${projectId}/pccos/${item.id}/`} underline="always">
              {item.display}
            </MuiNavLink>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Box textAlign="right">
              <Currency number={item?.prior_contract_value} precision={2} />
            </Box>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Box textAlign="right">
              <Currency number={item?.budget_approved_amount} precision={2} />
            </Box>
          </Grid>
          <Grid item sm={2} xs={4}>
            <Box textAlign="right">
              <Currency number={item?.current_contract_value} precision={2} />
            </Box>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={30}>
        <Typography2 type="metadata">{item?.number_display}</Typography2>
      </PaperItem.Right>
    </PaperItem>
  );
};
