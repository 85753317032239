import {Grid, Typography} from "@material-ui/core";
import React from "react";
import {Helmet} from "react-helmet";
import {AppContext} from "../../Contexts";
import useBlockUI from "../../hooks/useBlockUI";

const User = (props) => {
  const appContext = React.useContext(AppContext);
  const blockUI = useBlockUI();
  return (
    <>
      <Helmet title="Misc User" />
      <Typography variant="h1">Misc User</Typography>
      <Grid container spacing={2}>
        <Grid item sm={6}>
          <Typography variant="h2">Props</Typography>
          <pre>{JSON.stringify(props, null, 2)}</pre>
        </Grid>
        <Grid item sm={6}>
          <Typography variant="h2">Context</Typography>
          <pre>{JSON.stringify(appContext, null, 2)}</pre>
        </Grid>
      </Grid>
    </>
  );
};

export default User;
