import {Box, Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {ProjectContactAutoselectMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import InnerFooter from "../../../components/InnerFooter";

export const SubcontractorLienReleaseDialogForm = (props: {
  onSubmit: (values: any) => void;
  handleClose: () => void;
  isOpen: boolean;
  isReadOnly?: boolean;
  projectId: number;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, isReadOnly, projectId, initialValues, ...rest} = props;

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      {/* <pre>{JSON.stringify(initialValues, null, 2)}</pre> */}
      <FinalForm onSubmit={onSubmit} initialValues={initialValues} {...rest}>
        {({handleSubmit, form, submitting, pristine = true, values}) => {
          return (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle>Update Lien Release</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ProjectContactAutoselectMui
                        projectId={projectId}
                        name="to_contact"
                        label="To Contact"
                        required
                        autoFocus
                      />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || isReadOnly} />
                {/* <FormActions.Button
                  href={values.docusign_envelope_list_url}
                  startIcon={<FontAwesomeIcon icon={faPenFancy} />}
                  label="DocuSign"
                /> */}
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

export const SubcontractorLienReleaseDetailForm = (props: {
  onSubmit: (values: any) => void;
  projectId: number;
  isReadOnly?: boolean;
  [rest: string]: any;
}) => {
  const {onSubmit, projectId, isReadOnly, ...rest} = props;
  return (
    <>
      <FinalForm onSubmit={(values) => {}} {...rest}>
        {(props) => {
          const {handleSubmit, form, submitting, pristine, values} = props;
          return (
            <>
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <ProjectContactAutoselectMui
                        projectId={projectId}
                        name="to_contact"
                        label="To Contact"
                        disableClearable={false}
                        autoFocus
                      />
                    </Grid>
                  </Grid>
                  <InnerFooter>
                    <Box display="flex">
                      <FormActions.SaveButton
                        disabled={submitting || pristine}
                        onClick={() => {
                          onSubmit(values);
                        }}
                      />
                    </Box>
                  </InnerFooter>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};
