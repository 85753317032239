import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, ListSubheader, MenuItem as MenuItemMui} from "@material-ui/core";
import {groupBy} from "lodash";
import qs from "query-string";
import React from "react";
import {useQuery} from "react-query";
import {axiosAPI} from "../../../api";
import importance from "../../../components/forms/choices/importance.json";
import {getImportanceColor} from "../../../theme/colors";
import {getImportanceIcon} from "../../../theme/icons";
import {StatusIcon} from "../../Icons";
import {TextFieldMui} from "./TextFields";

export const SimpleSelect = (props: {
  options: {value: string | number; label: string}[];
  label: string;
  name: string;
  allowNull?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {options, label, name, allowNull, ...rest} = props;
  return (
    <TextFieldMui select label={label} name={name} {...rest}>
      {/*
          // @ts-ignore */}
      {allowNull && <MenuItemMui value={null}>---------</MenuItemMui>}
      {options.map((option) => {
        return (
          <MenuItemMui dense key={option.value} value={option.value}>
            {option.label}
          </MenuItemMui>
        );
      })}
    </TextFieldMui>
  );
};

export const ApiSelectMui = (props: {
  label: string;
  name: string;
  baseURL: string;
  children: (option: any) => React.ReactNode;
  qsParams?: any;
  queryParams?: any;
  optionsProp?: string;
  allowNull?: boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {label, name, baseURL, qsParams, queryParams, optionsProp = "results", allowNull, children, ...rest} = props;
  const queryString = qs.stringify(qsParams);
  const query = useQuery(
    ["api autocomplete", baseURL, qsParams],
    () => {
      return axiosAPI.get(`${baseURL}?${queryString}`).then((res) => res.data[optionsProp]);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
      ...queryParams,
    }
  );
  const {data: options, isFetching} = query;
  return (
    <TextFieldMui select label={label} name={name} disabled={isFetching} {...rest}>
      {/* // @ts-ignore */}
      {allowNull && <MenuItemMui value={null}>---------</MenuItemMui>}
      {options.map((option) => children(option))}
    </TextFieldMui>
  );
};

export const GroupedSimpleSelect = (props: {
  options: {value: string | number; label: string}[];
  label: string;
  name: string;
  allowNull?: boolean;
  groupProp?: string;
  // All other props
  [rest: string]: any;
}) => {
  const {options, label, name, allowNull, groupProp = "group", ...rest} = props;
  const groupedOptions = groupBy(options, groupProp);
  const choices = [];

  Object.keys(groupedOptions).map((group) => {
    choices.push(<ListSubheader>{group}</ListSubheader>);
    groupedOptions[group].map((option) => {
      choices.push(
        <MenuItemMui dense key={option.value} value={option.value}>
          {option.label}
        </MenuItemMui>
      );
    });
  });

  return (
    <TextFieldMui select label={label} name={name} {...rest}>
      {/*
          // @ts-ignore */}
      {allowNull && <MenuItemMui value={null}>---------</MenuItemMui>}
      {choices}
    </TextFieldMui>
  );
};

export const SelectImportance = (props: {
  // options: {value: string | number; label: string}[];
  label: string;
  name: string;
  allowNull?: boolean;
  options?: any;
  // All other props
  [rest: string]: any;
}) => {
  const {label, name, allowNull, options = importance, ...rest} = props;
  return (
    <TextFieldMui select label={label} name={name} {...rest}>
      {allowNull && <MenuItemMui value={null}>---------</MenuItemMui>}
      {options.map((option) => {
        return (
          <MenuItemMui dense key={option.value} value={option.value}>
            <Box alignItems="center">
              <FontAwesomeIcon
                icon={getImportanceIcon(option.label)}
                style={{color: getImportanceColor(option.label), paddingRight: 4}}
              />
              {option.label}
            </Box>
          </MenuItemMui>
        );
      })}
    </TextFieldMui>
  );
};

export const SelectStatus = (props: {
  options: {value: string; label: string}[];
  label: string;
  name: string;
  type?: "submittal" | "changeOrder" | "rfi";
  allowNull?: boolean;
  isOptionDisabled?: (option) => boolean;
  // All other props
  [rest: string]: any;
}) => {
  const {options, label, name, type, allowNull, isOptionDisabled, ...rest} = props;
  return (
    <TextFieldMui select label={label} name={name} {...rest}>
      {/*
          // @ts-ignore */}
      {allowNull && <MenuItemMui value={null}>---------</MenuItemMui>}
      {options.map((option) => {
        return (
          <MenuItemMui
            dense
            key={option.value}
            value={option.value}
            disabled={isOptionDisabled && isOptionDisabled(option)}
          >
            <Box display="flex" alignItems="center">
              <StatusIcon status={option.value} type={type} />
              <Box ml={1} />
              {option.label}
            </Box>
          </MenuItemMui>
        );
      })}
    </TextFieldMui>
  );
};
