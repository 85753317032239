import {Grid, Typography} from "@material-ui/core";
import * as rdd from "react-device-detect";
import {Helmet} from "react-helmet";
import {useLocation, useParams} from "react-router-dom";

const Props = (props) => {
  const location = useLocation();
  const params = useParams();
  const {waffle} = props;
  return (
    <>
      <Helmet title="Props Page" />
      <Typography variant="h1">Props</Typography>
      <Grid container spacing={2}>
        <Grid item sm={6} xs={12}>
          <Typography variant="h2">React Device Detect</Typography>
          <pre>{JSON.stringify(rdd, null, 2)}</pre>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="h2">Props</Typography>
          <pre>{JSON.stringify(props, null, 2)}</pre>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="h2">Location</Typography>
          <pre>{JSON.stringify(location, null, 2)}</pre>
        </Grid>
        <Grid item sm={6} xs={12}>
          <Typography variant="h2">Params</Typography>
          <pre>{JSON.stringify(params, null, 2)}</pre>
        </Grid>
        {/* <Grid item sm={6} xs={12}>
          <Typography variant="h2">History</Typography>
          <pre>{JSON.stringify(history, null, 2)}</pre>
        </Grid> */}
        <Grid item sm={6} xs={12}>
          <Typography variant="h2">Waffle flag is active</Typography>
          <pre>{JSON.stringify(waffle.flag_is_active("docusign_supplemental_docs"), null, 2)}</pre>
        </Grid>
      </Grid>
    </>
  );
};

export default Props;
