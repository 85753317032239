import {faCalendar, faLock, faNote} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Tooltip} from "@material-ui/core";
import moment from "moment-timezone";
import HtmlRender from "../../../../js/components/HtmlRender";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";

export const MeetingPaperItem = (props) => {
  const {meeting, ...rest} = props;
  return (
    <PaperItem>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={4} xs={12}>
            <FontAwesomeIcon icon={meeting?.state === "minutes" ? faNote : faCalendar} fixedWidth />
            <MuiNavLink
              href={`/v2/projects/${meeting.project_id}/meetings/${meeting.id}/${meeting.state}/`}
              underline="always"
            >
              {meeting.meeting_name}
            </MuiNavLink>
          </Grid>
          <Grid item sm={5} xs={12}>
            {meeting?.start && moment.tz(meeting?.start, moment.tz.guess()).format("ddd, MMM D, YYYY, h:mm a z")}
          </Grid>
          <Grid item sm={2} xs={11}>
            {meeting?.state_display}
          </Grid>
          <Grid item sm={1} xs={1}>
            {meeting?.is_private && (
              <Tooltip title="Private">
                <FontAwesomeIcon icon={faLock} />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography2 type="metadata">
              <HtmlRender html={meeting?.overview} />
            </Typography2>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right>
        <PaperItem.RightItemNumber number={meeting.number_display} />
      </PaperItem.Right>
      <PaperItem.RightHover>
        <Grid container>
          <Grid item xs={6}>
            <BoxFilesIconButtonLink
              projectId={meeting.project_id}
              app="meetings"
              model="meeting"
              objectId={meeting.id}
              boxFolderId={meeting.box_folder_id}
            />
          </Grid>
          <Grid item xs={6}>
            <PaperItem.RightHover.ItemNumber number={meeting.number_display} />
          </Grid>
        </Grid>
      </PaperItem.RightHover>
    </PaperItem>
  );
};
