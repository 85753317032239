import {Box, Button, Grid, Paper, Typography} from "@material-ui/core";
import {Helmet} from "react-helmet";
import Typography2 from "../components/Typography2";
import OktaLogo from "../images/okta.svg";
import UnauthenticatedLayout from "../layouts/UnauthenticatedLayout";

const Login = (props) => {
  console.log(window.location.search);
  return (
    <UnauthenticatedLayout>
      <Helmet title="Sentinel Login" />
      <Box mt={6} />
      <Box margin="auto" maxWidth={400}>
        <Paper elevation={5}>
          <Box py={2} px={4}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <center>
                  <Typography component="h2" variant="h3">
                    Login
                  </Typography>
                </center>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth={true}
                  variant="contained"
                  color="secondary"
                  size="large"
                  href={`/okta/login2/${window.location.search}`}
                >
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <img src={OktaLogo} style={{height: 30}} alt="Okta Logo" />
                  </Box>
                </Button>
              </Grid>
              <Grid item xs={12}>
                <center>
                  <Typography2 type="metadata" style={{lineHeight: 0}}>
                    Most users should be utilizing the Okta login above. If you need the legacy login (for users such as
                    "scanner") click the link below.
                  </Typography2>
                </center>
              </Grid>
              <Grid item xs={12}>
                <Button
                  fullWidth={true}
                  variant="contained"
                  size="small"
                  href={`/accounts/login/${window.location.search}`}
                >
                  Legacy Login
                </Button>
                <Box mb={2} />
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Box>
    </UnauthenticatedLayout>
  );
};

export default Login;
