import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

export const ConfirmationDialog = (props: {
  isOpen: boolean;
  onApprove: () => void;
  onDeny: () => void;
  title?: string;
  approveLabel?: string;
  denyLabel?: string;
  children?: React.ReactNode;
  // All other props
  [rest: string]: any;
}) => {
  const {
    isOpen,
    onApprove,
    onDeny,
    title = "Are you sure?",
    approveLabel = "OK",
    denyLabel = "Cancel",
    children,
  } = props;

  return (
    <div>
      <Dialog
        open={isOpen}
        // onClose={handleClose}
        onClose={onDeny}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{children}</DialogContentText>
        </DialogContent>
        <DialogActions>
          {/* Remove autoFocus to fix https://github.com/colinstoner/Sentinel/issues/265 in Safari */}
          {/* <Button onClick={onApprove} color="primary" variant="contained" autoFocus> */}
          <Button onClick={onApprove} color="primary" variant="contained">
            {approveLabel}
          </Button>
          <Button onClick={onDeny} color="primary">
            {denyLabel}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
