import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Breadcrumbs, Button, Grid, Typography} from "@material-ui/core";
import fileDownload from "js-file-download";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";

import offices from "../../components/forms/choices/offices.json";
import {CompanyAutoselectMui, ContactAutoselectMui, DatePickerMui, SimpleSelect} from "../../components/forms/Fields";
import {MuiNavLink} from "../../components/Links";
import PaperPanel from "../../components/PaperPanel";
import useBlockUI from "../../hooks/useBlockUI";
import {useFetchCurrentPage, usePostCurrentPage} from "../../hooks/useSentinelAPI";

const ClientProfitabilityForm = (props: {
  onSubmit: (values: any) => void;

  // All other props
  [rest: string]: any;
}) => {
  const {pathname} = useLocation();
  // useWhyDidYouUpdate("RFIUpdateDialogForm", props);
  const {onSubmit, ...rest} = props;

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {(props) => {
        const {handleSubmit, form, submitting, pristine, values} = props;
        return (
          <>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <CompanyAutoselectMui name="companies" label="Companies" multiple autoFocus />
                </Grid>
                <Grid item xs={12} sm={6}>
                  {/* NOVO company id = 1547 */}
                  <ContactAutoselectMui
                    name="contact"
                    label="Contact"
                    helperText="Filter projects where above contact in in project directory (optional)"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <SimpleSelect name="office" label="Office" options={offices} allowNull />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePickerMui label="Project Start Date >=" name="start_date_gte" />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <DatePickerMui label="Project Start Date <=" name="start_date_lte" />
                </Grid>
                {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                <Grid item xs={12}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                    disabled={submitting}
                  >
                    Download Excel
                  </Button>
                </Grid>
              </Grid>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};

const ClientProfitability = (props) => {
  const [filterValues, setFilterValues] = React.useState({companies: []});
  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {},
  });
  const postCurrentPage = usePostCurrentPage({}, {baseURL: "", responseType: "blob"});
  const blockUI = useBlockUI();

  return (
    <>
      <Helmet title="Client Profitability" />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <Typography>Executive Tools</Typography>
        <Typography color="textPrimary">Client Profitability</Typography>
      </Breadcrumbs>

      {/* <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>Client Profitability</PageHeader.Title>
        </PageHeader.Left>
        <PageHeader.Right></PageHeader.Right>
      </PageHeader> */}
      <Box mt={1} />
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Client Profitability</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions></PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {/* <PaperPanel.Alert severity="info">
          This is in <strong>beta</strong> prerelease testing. Returns a maximum of 1,000 projects. Only closed projects
          are included.
        </PaperPanel.Alert> */}
        <PaperPanel.Body p={1}>
          <ClientProfitabilityForm
            initialValues={filterValues}
            onSubmit={(values) => {
              setFilterValues(values);
              blockUI.blockUI("Creating Excel File...");
              postCurrentPage.mutateAsync(values).then((response) => {
                // fileDownload(response, `${values.company.name} - Client Profitability.xlsx`);
                fileDownload(response, `Client Profitability.xlsx`);
                blockUI.unblockUI();
              });
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>

      {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
    </>
  );
};

export default ClientProfitability;
