import NumberFormat from "react-number-format";
import {identity} from "../../../../js/components/FinalFormEnhancers";
import {TextFieldMui} from "./TextFields";

const NumberFormatCustom = (props) => {
  const {inputRef, onChange, ...rest} = props;
  return (
    <NumberFormat
      {...rest}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.floatValue,
          },
        });
      }}
      // thousandSeparator
      isNumericString
      fixedDecimalScale
    />
  );
};

const _NumberFieldMui = (props) => {
  const {prefix = "", suffix = "", decimalScale = 0, required, thousandSeparator = true, ...rest} = props;
  return (
    <TextFieldMui
      fieldProps={{
        parse: identity,
        validate: (value) => {
          if (required) {
            return value === ("" || undefined) ? "Required" : undefined;
          }
        },
      }}
      required={required}
      InputProps={{
        inputComponent: NumberFormatCustom,
        inputProps: {prefix: prefix, suffix: suffix, decimalScale: decimalScale, thousandSeparator: thousandSeparator},
      }}
      {...rest}
    />
  );
};

export const NumberFieldMui = (props) => {
  return <_NumberFieldMui {...props} />;
};

export const CurrencyFieldMui = (props) => {
  const {prefix = "$", decimalScale = 2, ...rest} = props;
  return <_NumberFieldMui prefix={prefix} decimalScale={decimalScale} {...rest} />;
};

export const PercentFieldMui = (props) => {
  const {suffix = "%", decimalScale = 3, ...rest} = props;
  return <_NumberFieldMui suffix={suffix} decimalScale={decimalScale} {...rest} />;
};
