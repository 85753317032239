import {faCodeBranch, faList, faPaperPlane} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Tab, Tabs, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import pluralize from "pluralize";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import useBlockUI from "../../../hooks/useBlockUI";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {TransmittalDetailForm, TransmittalItemForm} from "./TransmittalForms";
import TransmittalItemPaperItem from "./TransmittalItemPaperItem";

const ProjectTransmittalDetail = (props) => {
  const {project, ...rest} = props;
  const {transmittalId} = useParams();
  const novoClasses = makeNovoClasses();
  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const createItemFormRef = React.createRef();

  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [cloneConfirmationIsOpen, setCloneConfirmationIsOpen] = React.useState(false);
  const [createTransmittalItemDialogIsOpen, setCreateTransmittalDialogIsOpen] = React.useState(false);
  const [editTransmittalItemDialogIsOpen, setEditTransmittalDialogIsOpen] = React.useState(false);
  const [deleteTransmittalItemsDialogIsOpen, setDeleteTransmittalItemsDialogIsOpen] = React.useState(false);
  const [initialValuesHack, setInitialValuesHack] = React.useState(Math.random());
  const [transmittalInitialValues, setTransmittalInitialValues] = React.useState({
    cc_contacts: [],
  });

  const [activeTransmittalItem, setActiveTransmittalItem] = React.useState({} as any);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
    },
  });

  const {
    query: transmittalQuery,
    create: createTransmittal,
    update: updateTransmittal,
    delete: deleteTransmittal,
    rpc: transmittalRPC,
  } = useSentinelDetailAPI(`projects/${project.id}/transmittals/${transmittalId}/`, {
    initialData: {},
  });

  const transmittal = transmittalQuery.data;

  const {
    query: transmittalItemsQuery,
    create: createTransmittalItem,
    update: updateTransmittalItem,
    delete: deleteTransmittalItem,
    rpc: transmittalItemRPC,
  } = useSentinelListAPI(`projects/${project.id}/transmittals/${transmittalId}/items/`, {
    initialData: {
      results: [],
    },
  });

  const transmittalItems = transmittalItemsQuery.data.results;

  const {
    query: contactsQuery,
    create: createContact,
    update: updateContact,
    delete: deleteContact,
    rpc: contactRPC,
  } = useSentinelListAPI(`projects/${project.id}/transmittals/${transmittalId}/contacts/`, {
    initialData: {
      results: [],
    },
  });

  const contacts = contactsQuery.data.results.map((item) => item.contact);

  const allItemIds = transmittalItems.map((item) => item.id);
  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedItemIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  React.useEffect(() => {
    setTransmittalInitialValues({...transmittal, cc_contacts: contacts});
  }, [transmittalQuery.isFetchedAfterMount, contactsQuery.isFetchedAfterMount, initialValuesHack]);

  const onUpdateTransmittal = React.useCallback((values) => {
    setTransmittalInitialValues(values);
    updateTransmittal.mutateAsync(values).then((transmittal) => {
      setTransmittalInitialValues((lastState) => {
        return {...lastState, ...transmittal};
      });
    });
  }, []);

  const createItemDefaultValues = React.useMemo(() => {
    return {number: transmittalItemsQuery?.data?.metadata?.next_number, quantity: 1};
  }, [transmittalItemsQuery?.data?.metadata?.next_number]);

  if (!transmittalQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Transmittal info..." />;
  }

  return (
    <>
      <Helmet title={`${transmittal.display}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/transmittals/`}>
          Transmittals
        </MuiNavLink>
        <Typography color="textPrimary">{transmittal.description}</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    General Info
                  </Box>
                }
                value="general"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Items
                    <TabCountChip
                      isLoading={!transmittalItemsQuery.isFetchedAfterMount}
                      count={transmittalItemsQuery.data.count}
                    />
                  </Box>
                }
                value="items"
                className={novoClasses.smallTab}
              />

              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={transmittalQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faPaperPlane} fixedWidth /> Transmittal #{transmittal.number}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/transmittals/${transmittal.id}/?display_from_signature=on`}
                      buildReportURL={`/reports2/projects/${project.id}/transmittals/detail/filter/?pk=${transmittal.id}`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.Button
                      startIcon={<FontAwesomeIcon icon={faCodeBranch} />}
                      onClick={() => setCloneConfirmationIsOpen(true)}
                    >
                      Clone
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <TransmittalDetailForm
                transmittal={transmittal}
                projectId={project.id}
                onSubmit={(values) => {
                  onUpdateTransmittal(values);
                }}
                initialValues={transmittalInitialValues}
              />
            </>
          </TabPanel>
          <TabPanel value="items">
            <>
              <PaperPanel.TabHeader isLoading={transmittalItemsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faList} fixedWidth /> Transmittal #{transmittal.number} Items
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.CreateButton onClick={() => setCreateTransmittalDialogIsOpen(true)}>
                      Add Item
                    </PaperPanel.Header.CreateButton>
                    <Box ml={1}>
                      <PaperPanel.Header.DeleteButton
                        disabled={selectedItemIds.size < 1}
                        onClick={() => setDeleteTransmittalItemsDialogIsOpen(true)}
                      >
                        Delete Selected
                      </PaperPanel.Header.DeleteButton>
                    </Box>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={transmittalItemsQuery.isFetching}
                      onClick={() => transmittalItemsQuery.refetch()}
                    />
                    <Box mr={-1} />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <Box mx={-3} mt={-2} mb={-3}>
                {transmittalItems.map((item) => (
                  <TransmittalItemPaperItem
                    key={item.id}
                    item={item}
                    onChangeItemSelected={(e, value) => {
                      if (value) {
                        addSelectedItemId(item.id, e.nativeEvent.shiftKey);
                      } else {
                        removeSelectedItemId(item.id);
                      }
                    }}
                    isSelected={selectedItemIds.has(item.id)}
                    projectId={project.id}
                    onEdit={() => {
                      setActiveTransmittalItem(item);
                      setEditTransmittalDialogIsOpen(true);
                    }}
                  />
                ))}
              </Box>
            </>
          </TabPanel>
        </PaperPanel>
        <LegacyUILink href={`/transmittals/${transmittalId}/legacy/`} mt={2} />
      </TabContext>

      <ConfirmationDialog
        isOpen={cloneConfirmationIsOpen}
        onDeny={() => setCloneConfirmationIsOpen(false)}
        onApprove={() => {
          transmittalRPC.mutateAsync({action: "clone", method: "POST"}).then((response) => {
            navigate(`/v2/projects/${project.id}/transmittals/${response.id}/`);
            setCloneConfirmationIsOpen(false);
          });
        }}
        title="Clone Transmittal?"
      >
        Do you want to make a copy of this transmittal?
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={deleteTransmittalItemsDialogIsOpen}
        onDeny={() => setDeleteTransmittalItemsDialogIsOpen(false)}
        onApprove={() => {
          transmittalItemRPC
            .mutateAsync({action: "delete", method: "DELETE", data: {ids: Array.from(selectedItemIds)}})
            .then(() => {
              removeSelectedItemIds(Array.from(selectedItemIds));
              return transmittalItemsQuery.refetch();
            })
            .then(() => setDeleteTransmittalItemsDialogIsOpen(false));
        }}
        title="Delete Transmittal Items"
      >
        Are you you want to delete {selectedItemIds.size} selected {pluralize("item", selectedItemIds.size)}?
      </ConfirmationDialog>

      <TransmittalItemForm
        ref={createItemFormRef}
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;
          blockUI.blockUI("Creating...");
          // if (values.item_name) {
          //   //Hack because the reference to a field named "item" always returns <value unavailable>
          //   values.item = values.item_name;
          //   delete values.item_name;
          // }

          if (submitMode === "addAnother") {
            const focusElement = createItemFormRef.current.elements.description;
            focusElement.focus();
          }
          createTransmittalItem.mutateAsync(values).then(() =>
            transmittalItemsQuery.refetch().then(() => {
              if (submitMode === "addAnother") {
                form.restart();
                blockUI.unblockUI();
              } else {
                setCreateTransmittalDialogIsOpen(false);
                blockUI.unblockUI();
              }
            })
          );
        }}
        handleClose={() => setCreateTransmittalDialogIsOpen(false)}
        isOpen={createTransmittalItemDialogIsOpen}
        isNew
        initialValues={createItemDefaultValues}
      />

      <TransmittalItemForm
        isOpen={editTransmittalItemDialogIsOpen}
        handleClose={() => setEditTransmittalDialogIsOpen(false)}
        onSubmit={(values) => {
          updateTransmittalItem.mutateAsync(values).then(() => setEditTransmittalDialogIsOpen(false));
        }}
        initialValues={activeTransmittalItem}
      />
    </>
  );
};

export default ProjectTransmittalDetail;
