import {faPeopleGroup, faSync} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Typography} from "@material-ui/core";
import {groupBy} from "lodash";
import React from "react";
import Helmet from "react-helmet";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {LegacyUILink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {MeetingPaperItem} from "./MeetingPaperItem";
import {CreateMeetingSeriesForm, RenameMeetingSeriesForm} from "./ProjectMeetingsForms";

const ProjectMeetings = (props) => {
  const {project, ...rest} = props;
  const blockUI = useBlockUI();

  const [createMeetingDialogIsOpen, setCreateMeetingDialogIsOpen] = React.useState(false);
  const [meetingSeriesConfirmationIsOpen, setMeetingSeriesConfirmationIsOpen] = React.useState(false);
  const [renameMeetingDialogIsOpen, setRenameMeetingDialogIsOpen] = React.useState(false);
  const [renumberDialogIsOpen, setRenumberDialogIsOpen] = React.useState(false);
  const [activeMeetingSeries, setActiveMeetingSeries] = React.useState({} as any);

  const {
    query: meetingsSeriesQuery,
    create: createMeetingSeries,
    update: updateMeetingSeries,
    delete: deleteMeetingSeries,
    rpc: meetingSeriesRPC,
  } = useSentinelListAPI(`projects/${project.id}/meetings/series/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const meetingsSeries = meetingsSeriesQuery.data.results;

  //   const meetingsLookup = groupBy(meetingsSeries, "meeting_name");

  const meetingsLookup = groupBy(meetingsSeries, "id");

  const totalIndividualMeetings = meetingsSeries.reduce((count, current) => count + current.meetings.length, 0);

  return (
    <>
      <Helmet title={`${project.display} - Meetings`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Meetings</Typography>
      </ProjectBreadcrumbs>

      {/* <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faPeopleGroup} fixedWidth /> Project Meetings
          </PageHeader.Title>
        </PageHeader.Left>
      </PageHeader> */}

      <PaperPanel>
        <PaperPanel.Header isLoading={meetingsSeriesQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faPeopleGroup} /> Meetings
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton onClick={() => setMeetingSeriesConfirmationIsOpen(true)}>
                Create Meeting Series
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Menu>
                {(popupState) => [
                  <PaperPanel.Header.Menu.MenuItem
                    key="renumber"
                    onClick={() => {
                      popupState.close();
                      setRenumberDialogIsOpen(true);
                    }}
                  >
                    Renumber
                  </PaperPanel.Header.Menu.MenuItem>,
                  <PaperPanel.Header.Menu.MenuItem
                    key="create"
                    onClick={() => {
                      popupState.close();
                      blockUI.blockUI("Working...");
                      axiosAPI
                        .post(`/projects/${project.id}/actions/create_update_meeting_folders/`, {}, {baseURL: ""})
                        .then(() => {
                          blockUI.unblockUI();
                        })
                        .catch(() => {
                          blockUI.unblockUI();
                        });
                    }}
                  >
                    <FontAwesomeIcon icon={faSync} fixedWidth />
                    Create/Update All Meeting Folders
                  </PaperPanel.Header.Menu.MenuItem>,
                ]}
              </PaperPanel.Header.Menu>
              <PaperPanel.Header.RefreshButton
                onClick={() => meetingsSeriesQuery.refetch()}
                isFetching={meetingsSeriesQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {meetingsSeries.map((series) => (
          <React.Fragment key={series.id}>
            {/* <PaperItem.Header>
              <Box
                onClick={() => {
                  setActiveMeetingSeries(series);
                  setRenameMeetingDialogIsOpen(true);
                }}
                style={{cursor: "pointer"}}
              >
                <h3 style={{margin: 0}}>{series.meeting_name}</h3>
              </Box>
            </PaperItem.Header> */}
            <PaperItem.HeaderLabel
              label={
                <Box
                  onClick={() => {
                    setActiveMeetingSeries(series);
                    setRenameMeetingDialogIsOpen(true);
                  }}
                  style={{cursor: "pointer"}}
                >
                  {series.meeting_name}
                </Box>
              }
            />
            <VirtualizedList disableDragAndDrop itemList={meetingsLookup[series.id][0].meetings} isVirtualized={false}>
              {(props) => {
                const {listItem: meeting, ...rest} = props;
                return <MeetingPaperItem meeting={meeting} key={meeting.id} />;
              }}
            </VirtualizedList>
          </React.Fragment>
        ))}
      </PaperPanel>

      <LegacyUILink href={`/projects/${project.id}/meetings/`} mt={2} />

      <ConfirmationDialog
        isOpen={renumberDialogIsOpen}
        onDeny={() => setRenumberDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Renumbering...");
          axiosAPI.post(`projects/${project.id}/meetings/renumber/`).then(() => {
            setRenumberDialogIsOpen(false);
            blockUI.unblockUI();
          });
        }}
      >
        Renumber all meetings? This cannot be undone?
      </ConfirmationDialog>

      <CreateMeetingSeriesForm
        isOpen={createMeetingDialogIsOpen}
        handleClose={() => setCreateMeetingDialogIsOpen(false)}
        onSubmit={(values) => {
          values.series = "";
          axiosAPI
            .post(`projects/${project.id}/meetings/`, values)
            .then(() => meetingsSeriesQuery.refetch().then(() => setCreateMeetingDialogIsOpen(false)));
        }}
      />

      <ConfirmationDialog
        isOpen={meetingSeriesConfirmationIsOpen}
        onDeny={() => setMeetingSeriesConfirmationIsOpen(false)}
        onApprove={() => {
          setMeetingSeriesConfirmationIsOpen(false);
          setCreateMeetingDialogIsOpen(true);
        }}
        title="Create A New Meeting Series?"
      >
        Are you sure you want to create a new Meeting <strong>Series</strong>? If you want to create a followup to an
        existing meeting, go to that meeting and click Create Followup.
      </ConfirmationDialog>

      <RenameMeetingSeriesForm
        isOpen={renameMeetingDialogIsOpen}
        handleClose={() => setRenameMeetingDialogIsOpen(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateMeetingSeries
            .mutateAsync(values)
            .then(() => {
              setRenameMeetingDialogIsOpen(false);
              setActiveMeetingSeries({});
              blockUI.unblockUI();
            })
            .catch(() => setRenameMeetingDialogIsOpen(false));
        }}
        initialValues={activeMeetingSeries}
      />
    </>
  );
};

export default ProjectMeetings;
