import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {FileFieldMui, TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import PaperPanel from "../../../components/PaperPanel";

export const UpdateDocumentDialogForm = (props: {
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  isNew?: boolean;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, onDelete, isOpen, handleClose, isNew = false, ...rest} = props;

  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => {
            return (
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <DialogTitle id="form-dialog-title">{isNew ? "Create Document" : "Update Document"}</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextFieldMui name="name" label="Name" required autoFocus />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui name="description" label="Description" />
                        </Grid>
                      </Grid>
                      {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                  {!false && <FormActions.DeleteButton onClick={() => onDelete()} />}
                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    </>
  );
};

export const DocumentUploadDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  questionSet?: string;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, action, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Upload Document to Project</DialogTitle>
              <DialogContent>
                <PaperPanel.Body>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FileFieldMui label="File" name="file" accept=".pdf, .docx, .xlsx" required />
                    </Grid>
                  </Grid>
                </PaperPanel.Body>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label="Upload" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
