import {Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography} from "@material-ui/core";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import {Helmet} from "react-helmet";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FileFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";

const ProjectAIDocumentAnalysisList = (props) => {
  const {project} = props;

  const [showUploadForm, setShowUploadForm] = React.useState(false);
  const [questionSet, setQuestionSet] = React.useState("");

  const blockUI = useBlockUI();

  return (
    <>
      <Helmet title={`${project.display} - AI Document Analysis`} />

      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/ai/documents/`}>
          <Typography color="textSecondary">AI Documents</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">AI Document Analysis</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>AI Document Analysis</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            {/* <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                onClick={() => documentQuery.refetch()}
                isFetching={documentQuery.isFetching}
              />
            </PaperPanel.Header.Action> */}
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <DocumentAIAnalysisTaskPaperItem
            questionSet="non_aia_contract"
            onClick={(questionSet_) => {
              setQuestionSet(questionSet_);
              setShowUploadForm(true);
            }}
          >
            <Typography>Start Analysis</Typography>
          </DocumentAIAnalysisTaskPaperItem>
        </PaperPanel.Body>
      </PaperPanel>
      <DocumentUploadDialogForm
        isOpen={showUploadForm}
        isNew={false}
        questionSet={questionSet}
        handleClose={() => {
          setShowUploadForm(false);
        }}
        onSubmit={async (values) => {
          values = {questionSet: questionSet, ...values};
          let formData = new FormData();
          Object.entries(values).map(([key, value]) => {
            /* @ts-ignore */
            formData.append(key, value);
          });
          setShowUploadForm(false);
          setTimeout(() => blockUI.blockUI("Uploading..."));
          const response = await axiosAPI.post(`/projects/${project.id}/ai/documents/upload/`, formData);
          blockUI.unblockUI();
          window.location.href = `/v2/projects/${project.id}/ai/documents/analysis/${values.questionSet}/${response.data.id}/`;
        }}
      />
    </>
  );
};

export default ProjectAIDocumentAnalysisList;

const DocumentAIAnalysisTaskPaperItem = (props: {
  questionSet: string;
  onClick: (questionSet: string) => void;
  children: React.ReactNode;
  [rest: string]: any;
}) => {
  const {questionSet, onClick: onClick, children, ...rest} = props;

  return (
    <PaperItem onClick={() => onClick(questionSet)} style={{cursor: "pointer"}} {...rest}>
      {questionSet} - {children}
    </PaperItem>
  );
};

const DocumentUploadDialogForm = (props: {
  onSubmit: (values: any) => void;
  isOpen: boolean;
  questionSet?: string;
  handleClose: () => void;
  [rest: string]: any;
}) => {
  const {onSubmit, isOpen, handleClose, action, ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="sm" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">Upload Document for Analysis</DialogTitle>
              <DialogContent>
                <PaperPanel.Body>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FileFieldMui label="File" name="file" accept=".pdf, .docx, .xlsx" required />
                    </Grid>
                  </Grid>
                </PaperPanel.Body>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label="Upload" />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
