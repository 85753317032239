import {Box, Button, Grid} from "@material-ui/core";
import {Radios} from "mui-rff";
import {Form} from "react-final-form";
import {ApiAutocompleteMui, TextFieldMui} from "../../components/forms/Fields";
import {usePostCurrentPage} from "../../hooks/useSentinelAPI";

export const TwilioContactUpdateForm = (props) => {
  const {onSubmitted, ...rest} = props;
  const postCurrentPage = usePostCurrentPage();

  return (
    <Form
      onSubmit={(values) => {
        postCurrentPage.mutateAsync(values).then((data) => onSubmitted(data));
      }}
      validate={(values) => {
        const errors = {};
        return errors;
      }}
      {...rest}
    >
      {(formProps) => {
        const {handleSubmit, form, submitting, pristine, errors, invalid, values} = formProps;
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldMui name="name" label="Full Name" required autoComplete="off" autoFocus />
              </Grid>
              <Grid item xs={12}>
                {/* <TextFieldMui name="company" label="Company" required autocomplete="off" /> */}
                <ApiAutocompleteMui
                  label="Company"
                  name="company"
                  autoComplete="off"
                  valueProp="name"
                  required
                  baseURL="companies/search/external/"
                />
              </Grid>
              <Grid item xs={12}>
                <Radios
                  label="Language"
                  name="language"
                  required
                  data={[
                    {label: "English", value: "EN"},
                    {label: "Spanish", value: "SP"},
                  ]}
                />
              </Grid>
            </Grid>
            <Box mt={4} mb={4}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                type="submit"
                size="large"
                disabled={invalid || submitting || postCurrentPage.isLoading}
              >
                Submit
              </Button>
            </Box>
            {/* <pre>{JSON.stringify(formProps, null, 2)}</pre> */}
          </form>
        );
      }}
    </Form>
  );
};
