import {
  faEnvelope,
  faGavel,
  faLink,
  faLinkSlash,
  faPaperclip,
  faPaperPlaneTop,
  faXmark,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Breadcrumbs, Grid, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {Alert, TabContext, TabPanel} from "@material-ui/lab";
import {useSnackbar} from "notistack";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {CommitmentBanner} from "../../../components/ClosedBanner";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {StatusIcon} from "../../../components/Icons";
import {MuiNavLink, ProjectAuditLink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {PageHeader} from "../../../components/PageHeader";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import ActivityStream from "../../../components/ActivityStream";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import {PaperItem} from "../../../components/PaperItem";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import {SCOGeneralInfoForm, SCOItemDialogForm, SCOLinkableSelectDialog} from "./SCOForms";
import {SCOItemPaperItem} from "./SCOItemPaperItem";

const SCODetail = (props) => {
  const {project, ...rest} = props;
  const {scoId} = useParams();
  const novoClasses = makeNovoClasses();
  const permissions = usePermissions();
  const blockUI = useBlockUI();
  const navigate = useNavigate();
  const {enqueueSnackbar} = useSnackbar();

  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [requestDialogIsOpen, setRequestDialogIsOpen] = React.useState(false);
  const [voidDialogIsOpen, setVoidDialogIsOpen] = React.useState(false);
  const [submitDialogIsOpen, setSubmitDialogIsOpen] = React.useState(false);
  const [removeItemsConfirmationIsOpen, setRemoveItemsConfirmationsIsOpen] = React.useState(false);
  const [itemCostFormIsOpen, setItemCostFormIsOpen] = React.useState(false);
  const [activeSCOItem, setActiveSCOItem] = React.useState({} as any);
  const [linkItemsDialogIsOpen, setLinkItemsDialogIsOpen] = React.useState(false);

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
    },
  });

  const pageData = pageDataQuery.data;

  const {
    query: scoQuery,
    update: updateSCO,
    rpc: scoRPC,
  } = useSentinelDetailAPI(["projects", project.id, "sccos", parseInt(scoId)], {
    initialData: {},
  });

  const sco = scoQuery.data;

  const {query: scoItemsQuery, rpc: scoItemRPC} = useSentinelListAPI(
    ["projects", project.id, "sccos", parseInt(scoId), "items"],
    {
      initialData: {
        results: [],
      },
    }
  );

  const scoItems = scoItemsQuery.data.results;

  const allItemIds = scoItems.map((item) => item.id);

  const {query: linkableItemsQuery} = useSentinelListAPI(
    `projects/${project.id}/sccos/${parseInt(scoId)}/items/linkable/`,

    {
      initialData: [],
    }
  );

  const linkableItems = linkableItemsQuery.data;

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  if (!scoQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching SCO info..." />;
  }

  return (
    <>
      <Helmet title={`${sco.item_number_display} - ${sco.commitment.to_company.name}`} />

      <Breadcrumbs>
        <MuiNavLink color="inherit" to="/v2/dashboard/">
          Dashboard
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/`}>
          {project.display}
        </MuiNavLink>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/sccos/`}>
          SCOs
        </MuiNavLink>
        <Typography color="textPrimary">{sco.commitment.display}</Typography>
      </Breadcrumbs>

      <PageHeader mt={2}>
        <PageHeader.Left>
          <PageHeader.Title>
            <FontAwesomeIcon icon={faPaperclip} /> {sco.item_number_display} - {sco.commitment.to_company.name}
          </PageHeader.Title>
          {/* <LeftHeader content={`#${sco.number_display} ${sco.commitment.description}`} />
          <LeftHeader content={`${sco.commitment?.to_company?.name}`} tooltip="Contract To Company" />
          <LeftHeader content={`${sco?.to_signed_by?.full_name}`} tooltip="Contract Signed By" /> */}
        </PageHeader.Left>
        <PageHeader.Right>
          <PageHeader.Right.CurrencySpark title="SCO Amount" number={sco.cost} precision={2} />
        </PageHeader.Right>
      </PageHeader>
      <Box mb={2}>
        <CommitmentBanner object={sco} type="SCO" />
      </Box>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <StatusIcon status={sco.status} tooltip={sco.status_display} showTooltip />
                    <Box ml={1}>#{sco.item_number_display}</Box>
                  </Box>
                }
                value="general"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Items
                    <TabCountChip isLoading={!scoItemsQuery.isFetchedAfterMount} count={scoItemsQuery.data?.count} />
                  </Box>
                }
                className={novoClasses.smallTab}
                value="items"
              />
              {sco.timberline_subcontract_co && (
                <Tab value="timberline" className={novoClasses.smallTab} label="Timberline" />
              )}
              <Tab label={"Logs"} value="logs" className={novoClasses.smallTab} />

              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={scoQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <Grid container wrap="nowrap" spacing={4}>
                    <Grid item>
                      <StatusLabel status={sco.status_display} />
                    </Grid>
                    {/* {sco.commitment.id && (
                      <>
                        <Grid item>
                          {sco.commitment.type}:{" "}
                          <MuiNavLink
                            to={`/v2/projects/${project.id}/contracts/sub/${sco.commitment.id}/`}
                            underline="always"
                          >
                            {sco.commitment.display}
                          </MuiNavLink>
                        </Grid>
                      </>
                    )} */}
                  </Grid>
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/ccos/scco/${sco.id}/`}
                      buildReportURL={`/reports2/projects/${project.id}/ccos/sccos/detail/filter/?pk=${sco.id}`}
                    />
                  </PaperPanel.Header.Action>
                  {sco.commitment.id && (
                    <PaperPanel.Header.Action border>
                      <Tooltip title={sco.commitment.display}>
                        <span>
                          <PaperPanel.Header.Button
                            to={`/v2/projects/${project.id}/contracts/sub/${sco.commitment.id}/`}
                            component={MuiNavLink}
                            startIcon={<FontAwesomeIcon icon={faGavel} />}
                            underline="none"
                            // onClick={() =>
                            //   navigate(`/v2/projects/${project.id}/contracts/sub/${sco.commitment.id}/`)
                            // }
                          >
                            Subcontract
                          </PaperPanel.Header.Button>
                        </span>
                      </Tooltip>
                    </PaperPanel.Header.Action>
                  )}
                  <PaperPanel.Header.Action border={sco.commitment.id}>
                    <PaperPanel.Header.DocuSignButton docuSignObject={sco} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/ccos/scco/${sco.id}/`}
                      uploadURL={`/projects/${project.id}/sccos/${sco.id}/upload-to-box/`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          {permissions.can_import_subcontracts && (
                            <>
                              <Tooltip title="Request this change order be submitted">
                                <span>
                                  <PaperPanel.Header.Menu.MenuItem
                                    onClick={(e) => {
                                      popupState.close();
                                      setRequestDialogIsOpen(true);
                                    }}
                                    disabled={["submitted", "voided"].includes(sco.status)}
                                  >
                                    <FontAwesomeIcon icon={faEnvelope} fixedWidth /> <Box ml={1} /> Request Submission
                                  </PaperPanel.Header.Menu.MenuItem>
                                </span>
                              </Tooltip>

                              <MenuItemHeader border />
                            </>
                          )}

                          <Tooltip
                            title={
                              sco.can_void
                                ? `Void change order and submit to accounting`
                                : sco.can_void_reason !== ""
                                  ? sco.can_void_reason
                                  : ""
                            }
                          >
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={(event) => {
                                  popupState.close();
                                  setVoidDialogIsOpen(true);
                                }}
                                disabled={!sco.can_void}
                              >
                                <FontAwesomeIcon icon={faXmark} fixedWidth /> <Box ml={1} /> Void
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>
                          <Tooltip
                            title={
                              !sco.can_submit_to_accounting
                                ? sco?.can_submit_to_accounting_reason || ``
                                : `Submit SCO to Accounting`
                            }
                          >
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={(event) => {
                                  setSubmitDialogIsOpen(true);
                                  popupState.close();
                                }}
                                disabled={!sco.can_submit_to_accounting}
                              >
                                <FontAwesomeIcon icon={faPaperPlaneTop} /> <Box ml={1} /> Submit
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>

                          {/* </ConditionalWrapper> */}
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <Box ml={-2}>
                      <PaperPanel.Header.RefreshButton
                        onClick={() => scoQuery.refetch()}
                        isFetching={scoQuery.isFetching}
                      />
                    </Box>
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <Box mx={-3} mb={2} mt={-2}>
                <PaperPanel.Alert severity="info" icon={false}>
                  {/* <strong>Commitment Details</strong> */}
                  <Grid container>
                    <Grid item xs={9}>
                      Original Contract Value
                    </Grid>
                    <Grid item xs={3}>
                      <Box textAlign="right">
                        <Currency number={sco.commitment.original_value} precision={2} />
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      Sum of Prior SCOs
                    </Grid>
                    <Grid item xs={3}>
                      <Box textAlign="right">
                        <Currency number={sco.prior_contract_changes} precision={2} />
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      Contract Value Prior to This SCO
                    </Grid>
                    <Grid item xs={3}>
                      <Box textAlign="right">
                        <Currency number={sco.prior_contract_value} precision={2} />
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      Value of This SCO
                    </Grid>
                    <Grid item xs={3}>
                      <Box textAlign="right">
                        <Currency number={sco.cost} precision={2} />
                      </Box>
                    </Grid>
                    <Grid item xs={9}>
                      New Contract Value
                    </Grid>
                    <Grid item xs={3}>
                      <Box textAlign="right">
                        <strong>
                          <Currency number={sco.cost + sco.prior_contract_value} precision={2} />
                        </strong>
                      </Box>
                    </Grid>
                  </Grid>
                </PaperPanel.Alert>
              </Box>
              <SCOGeneralInfoForm
                projectId={project.id}
                restrictNovoSigners={project.restrict_novo_signers}
                isReadOnly={project.is_closed || sco.is_closed}
                onSubmit={(values) => {
                  blockUI.blockUI("Saving...");
                  updateSCO
                    .mutateAsync(values)
                    .then(() =>
                      Promise.all([scoQuery.refetch(), scoItemsQuery.refetch(), linkableItemsQuery.refetch()])
                    )
                    .then(() => blockUI.unblockUI())
                    .catch(() => blockUI.unblockUI());
                }}
                initialValues={sco}
              />
            </>
            {sco.extracker_id && (
              <Box mt={2}>
                <Alert severity="success">This SCO was created by Extracker</Alert>
              </Box>
            )}
          </TabPanel>
          <TabPanel value="items">
            <>
              <PaperPanel.TabHeader isLoading={scoItemsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <PaperItem.SelectedCheckbox
                    label={"Select All"}
                    onChange={(event, value) => {
                      if (value) {
                        addSelectedItemIds(allItemIds);
                      } else {
                        removeAllSelectedItemIds();
                      }
                    }}
                    indeterminate={
                      !allItemIdsSelected && selectedItemIds.size < allItemIds.length && selectedItemIds.size
                    }
                    checked={allItemIdsSelected}
                  />
                  Change Order Items
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <Tooltip title={sco.is_closed ? `Cannot link items to a closed SCO` : ``}>
                      <span>
                        <PaperPanel.Header.CreateButton
                          startIcon={<FontAwesomeIcon icon={faLink} />}
                          onClick={() => setLinkItemsDialogIsOpen(true)}
                          disabled={sco.is_closed}
                        >
                          Link Items
                        </PaperPanel.Header.CreateButton>
                      </span>
                    </Tooltip>
                    <Box ml={1} />
                    <Tooltip title={sco.is_closed ? `Cannot unlink items from a closed SCO` : ``}>
                      <span>
                        <PaperPanel.Header.DeleteButton
                          startIcon={<FontAwesomeIcon icon={faLinkSlash} />}
                          onClick={() => setRemoveItemsConfirmationsIsOpen(true)}
                          disabled={selectedItemIds.size < 1 || sco.is_closed}
                        >
                          Unlink Selected
                        </PaperPanel.Header.DeleteButton>
                      </span>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={scoItemsQuery.isFetching}
                      onClick={() =>
                        Promise.all([scoItemsQuery.refetch(), scoQuery.refetch(), linkableItemsQuery.refetch()])
                      }
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <Box mx={-3} mt={-2} mb={-3}>
                {scoItems.map((item) => (
                  <SCOItemPaperItem
                    item={item}
                    onEditItem={() => {
                      setActiveSCOItem(item);
                      setItemCostFormIsOpen(true);
                    }}
                    key={item.id}
                    isSelected={selectedItemIds.has(item.id)}
                    onChangeItemSelected={(e, selectBetween) => {
                      if (selectBetween) {
                        addSelectedItemId(item.id, e.nativeEvent.shiftKey);
                      } else {
                        removeSelectedItemId(item.id);
                      }
                    }}
                  />
                ))}
              </Box>
            </>
          </TabPanel>
          {sco.timberline_subcontract_co && (
            <TabPanel value="timberline">
              <>
                <PaperPanel.TabHeader>
                  <PaperPanel.Header.Title>Timberline</PaperPanel.Header.Title>
                </PaperPanel.TabHeader>
                <PaperPanel.Body mx={-3} mt={-2}>
                  <PaperPanel.Alert severity={"info"}>
                    Timberline data is refreshed nightly and does not reflect changes made today.
                  </PaperPanel.Alert>
                  <Box mx={5}>
                    <Grid container>
                      <Grid item xs={12} sm={3}>
                        <strong>Subcontract:</strong>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        {pageData?.timberlineCommitmentCoItem?.commitment}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <strong>Subcontract CO#:</strong>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        {pageData?.timberlineCommitmentCoItem?.commitment_co}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <strong>Cost Code:</strong>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        {pageData?.timberlineCommitmentCoItem?.cost_code}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <strong>Category:</strong>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        {pageData?.timberlineCommitmentCoItem?.category}
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <strong>Amount:</strong>
                      </Grid>
                      <Grid item xs={12} sm={9}>
                        <Currency number={pageData?.timberlineCommitmentCoItem?.amount} precision={2} />
                      </Grid>
                    </Grid>
                  </Box>
                </PaperPanel.Body>
              </>
            </TabPanel>
          )}
          <TabPanel value="logs">
            <>
              <PaperPanel.TabHeader>
                <PaperPanel.Header.Title>Logs</PaperPanel.Header.Title>
              </PaperPanel.TabHeader>
              <PaperPanel.Body mx={-3} mt={0}>
                <ActivityStream url={`actstream/ccos/scco/${sco.id}/`} />
              </PaperPanel.Body>
            </>
          </TabPanel>
        </PaperPanel>
      </TabContext>

      <CreatedByModifiedBy obj={sco} mt={2} />
      <ProjectAuditLink projectId={project.id} app="ccos" model="scco" id={sco.id} mt={1} />
      {/* <LegacyUILink href={`/ccos/scco/${sco.id}/legacy/`} /> */}

      <ConfirmationDialog
        isOpen={requestDialogIsOpen}
        onDeny={() => setRequestDialogIsOpen(false)}
        onApprove={() => {
          setRequestDialogIsOpen(false);
          blockUI.blockUI("Requesting...");
          scoRPC
            .mutateAsync({action: "request-sco-submission"})
            .then((res) => {
              res === "success" && enqueueSnackbar("Email Sent!", {variant: "success"});
              return scoQuery.refetch();
            })
            .then(() => blockUI.unblockUI())
            .catch(() => blockUI.unblockUI());
        }}
        title="Send Email?"
      >
        This will send an email to {sco.created_by.full_name} and request that they submit this subcontract.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={voidDialogIsOpen}
        title={`Void SCO?`}
        onApprove={() => {
          setVoidDialogIsOpen(false);
          blockUI.blockUI("Voiding...");
          scoRPC
            .mutateAsync({action: "void"})
            .then(() => scoQuery.refetch())
            .then(() => blockUI.unblockUI());
        }}
        onDeny={() => {
          setVoidDialogIsOpen(false);
        }}
      >
        Void this subcontract change order? This will void the current change order and submit a deductive change order
        to accounting.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={submitDialogIsOpen}
        onDeny={() => setSubmitDialogIsOpen(false)}
        onApprove={() => {
          setSubmitDialogIsOpen(false);
          blockUI.blockUI("Submitting...");
          scoRPC
            .mutateAsync({action: "submit"})
            .then(() => scoQuery.refetch())
            .then(() => blockUI.unblockUI());
        }}
        title={"Submit SCO?"}
      >
        Submit this SCO to accounting? You will be unable to change the value once submitted.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={removeItemsConfirmationIsOpen}
        onDeny={() => setRemoveItemsConfirmationsIsOpen(false)}
        title="Remove Selected Items?"
        onApprove={() => {
          blockUI.blockUI("Removing...");
          scoItemRPC
            .mutateAsync({action: "unlink", data: Array.from(selectedItemIds)})
            .then(() => Promise.all([scoItemsQuery.refetch(), scoQuery.refetch(), linkableItemsQuery.refetch()]))
            .then(() => {
              removeAllSelectedItemIds();
              setRemoveItemsConfirmationsIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => {
              setRemoveItemsConfirmationsIsOpen(false);
              blockUI.unblockUI();
            });
        }}
      >
        Do you want to remove the selected items from this SCO?
      </ConfirmationDialog>

      <SCOItemDialogForm
        isOpen={itemCostFormIsOpen}
        handleClose={() => {
          setActiveSCOItem({});
          setItemCostFormIsOpen(false);
        }}
        initialValues={activeSCOItem}
        onSubmit={(values) => {
          blockUI.blockUI("Updating...");
          axiosAPI
            .patch(`projects/${project.id}/pcos/${activeSCOItem.pco_id}/items/${activeSCOItem.id}/`, {
              ...values,
            })
            // updateSCOItem
            //   .mutateAsync({id: activeSCOItem.id, cost: values.cost})
            .then(() => Promise.all([scoItemsQuery.refetch(), scoQuery.refetch()]))
            .then(() => {
              setItemCostFormIsOpen(false);
              setActiveSCOItem({});
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        isReadOnly={sco.is_closed}
        isReadOnlyReason={sco.is_closed_reason}
      />

      <SCOLinkableSelectDialog
        isOpen={linkItemsDialogIsOpen}
        handleClose={() => setLinkItemsDialogIsOpen(false)}
        projectId={project.id}
        sco={sco}
        onChangeSelectedItem={(corIds) => {
          blockUI.blockUI("Adding...");
          scoItemRPC
            .mutateAsync({action: "link", data: corIds})
            .then(() => Promise.all([scoItemsQuery.refetch(), scoQuery.refetch(), linkableItemsQuery.refetch()]))
            .then(() => {
              removeAllSelectedItemIds();
              setLinkItemsDialogIsOpen(false);
              blockUI.unblockUI();
            })
            .catch(() => setLinkItemsDialogIsOpen(false));
        }}
        linkableItems={linkableItems}
      />

      {/* <h2>Page Data Query</h2>
      <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre>
      <h2>SCO Query</h2>
      <pre>{JSON.stringify(scoQuery, null, 2)}</pre>
      <h2>SCOS</h2>
      <pre>{JSON.stringify(sco, null, 2)}</pre> */}
    </>
  );
};

export default SCODetail;
