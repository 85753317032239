import {Box, Grid, Typography} from "@material-ui/core";
import React from "react";

import {
  faCogs,
  faDollar,
  faDraftingCompass,
  faFileContract,
  faHardHat,
  faListOl,
  faShoePrints,
  faTasks,
  faTrafficCone,
  faUmbrella,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import {
  CheckboxMui,
  CurrencyFieldMui,
  DatePickerMui,
  NumberFieldMui,
  PercentFieldMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  RadioGroupMui,
  RatingMui,
  TextFieldMui,
  YesNoRadios,
} from "../../../components/forms/Fields";
import {ReviewForm} from "./ProjectReviewForms";

interface IFieldProps {
  onSubmit: (values, form?) => void;
  data: {};
  projectId?: number;
  onRefresh?: () => void;
  isFetching?: boolean;
  setSectionErrors?: React.Dispatch<any>;
}

const validateDatePickerSubmission = (form) => {
  //Form active field doesn't update if you hit the calendar icon on this input. This handler ensures dates autosubmit.
  const {hasValidationErrors} = form.getState();
  if (!hasValidationErrors) return form.submit();
};

export const ProjectFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;

  return (
    <ReviewForm onSubmit={onSubmit} section="Project" data={data} icon={faTasks} {...rest}>
      {(props) => {
        const {values, form} = props;
        return (
          <>
            <Grid item xs={12} sm={3}>
              <CurrencyFieldMui name="project_value" label="Project Value" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <NumberFieldMui name="square_feet" label="Square Feet" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CurrencyFieldMui name="expected_profit" label="Expected Profit" />
            </Grid>
            <Grid item xs={12} sm={3}>
              {/* Expected Profit Margin:{" "}
              <Percentage
                number={values.expected_profit === 0 ? 0 : values.expected_profit / values.project_value}
                precision={2}
              /> */}
              <PercentFieldMui
                name="expected_profit_margin"
                label="Expected Profit Margin"
                value={values.expected_profit === 0 ? 0 : (values.expected_profit / values.project_value) * 100}
                decimalScale={2}
                disabled
                defaultValue={values?.expected_profit_margin}
              />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="contract_signed" label="Contract Signed" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="po_issued" label="Purchase Order Issued" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <RatingMui name="schedule_aggressiveness" label="Schedule Aggressiveness" />
            </Grid>
            {[
              {role: "client", label: "Client"},
              {role: "architect", label: "Architect"},
              {role: "landlord", label: "Landlord"},
              {role: "construction_manager", label: "Construction Manager"},
            ].map((row) => {
              const {role, label} = row;
              return (
                <React.Fragment key={role}>
                  <Grid item xs={12} sm={4}>
                    <ProjectCompanyAutoselectMui
                      name={`${role}_company`}
                      label={label}
                      projectId={projectId}
                      disableClearable
                      required={["client", "architect"].includes(role)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={4}>
                    <ProjectContactAutoselectMui
                      projectId={projectId}
                      name={`${role}_contact`}
                      label="Contact"
                      getOptionLabel={(option) =>
                        option ? `${option.full_name} - ${option.company_name || option.company.name}` : ""
                      }
                      renderOption={(option: any) => {
                        return (
                          <>
                            <div>{option.full_name}</div>
                            <div>
                              <Typography color="textSecondary" style={{fontSize: "0.8em"}}>
                                {option.company?.name}
                              </Typography>
                            </div>
                          </>
                        );
                      }}
                      // companyId={values[`${role}_company`]?.id}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <YesNoRadios
                      name={`${role}_worked_with`}
                      label="Worked With Before"
                      required={["client", "architect"].includes(role)}
                    />
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={12} sm={4} lg={6}>
              <NumberFieldMui name="precon_duration" label="Precon Duration (Weeks)" />
            </Grid>
            <Grid item xs={12} sm={4} lg={6}>
              <NumberFieldMui name="schedule_duration" label="Schedule Duration (Weeks)" />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <DatePickerMui
                name="permit_submission_date"
                label="Permit Submission Date"
                onClose={() => validateDatePickerSubmission(form)}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <DatePickerMui
                name="permit_received_date"
                label="Permit Received Date"
                onClose={() => validateDatePickerSubmission(form)}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <DatePickerMui
                name="start_date"
                label="Construction Start Date"
                onClose={() => validateDatePickerSubmission(form)}
              />
            </Grid>
            <Grid item xs={12} sm={4} lg={3}>
              <DatePickerMui
                name="substantial_completion_date"
                label="Substantial Completion Date"
                onClose={() => validateDatePickerSubmission(form)}
              />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const TeamFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;
  return (
    <ReviewForm onSubmit={onSubmit} section="Team" data={data} icon={faUsers} {...rest}>
      {(props) => {
        return (
          <>
            {[
              {role: "project_executive", label: "Project Executive"},
              {role: "senior_project_manager", label: "Senior Project Manager"},
              {role: "project_manager", label: "Project Manager"},
              {role: "assistant_project_manager", label: "Assistant Project Manager"},
              {role: "project_engineer", label: "Project Engineer"},
              {role: "estimator", label: "Estimator"},
              {role: "superintendent", label: "Superintendent"},
              {role: "assistant_superintendent", label: "Assistant Superintendent"},
              {role: "accountant", label: "Accountant"},
              {role: "admin", label: "Admin"},
            ].map((row) => {
              const {role, label} = row;
              return (
                <React.Fragment key={role}>
                  <Grid item xs={8} lg={4}>
                    <ProjectContactAutoselectMui
                      companyId={1547}
                      projectId={projectId}
                      name={`${role}_contact`}
                      label={label}
                    />
                  </Grid>
                  <Grid item xs={4} lg={2}>
                    <NumberFieldMui name={`${role}_hours`} label="Hours/Week" />
                  </Grid>
                </React.Fragment>
              );
            })}
            <Grid item xs={12}>
              <TextFieldMui name="team_notes" label="Notes" multiline minRows={4} />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const InsuranceFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;
  return (
    <ReviewForm onSubmit={onSubmit} section="Insurance" data={data} icon={faUmbrella} {...rest}>
      {(props) => {
        const {values} = props;
        return (
          <>
            <Grid item xs={12} sm={4}>
              <RadioGroupMui
                name="builders_risk"
                label="Builders Risk Provided By"
                options={[
                  {value: "NOVO", label: "NOVO"},
                  {value: "Client", label: "Client"},
                ]}
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <YesNoRadios name="verified_with_client" label="Verified With Client" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyFieldMui
                name="deductible_amount"
                label="Deductible Amount"
                required={values.builders_risk === "Client"}
              />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const BillingsFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;
  return (
    <ReviewForm onSubmit={onSubmit} section="Billings" data={data} icon={faDollar} {...rest}>
      {(props) => {
        return (
          <>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="pre_billing_meeting" label="Pre Billing Meeting" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="first_bill_submitted" label="First Bill Submitted" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="first_invoice_paid" label="First Invoice Paid" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <NumberFieldMui name="payment_terms_days" label="Payment Terms (Days)" required />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Retention Held</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="retention_li" label="Liability Insurance" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="retention_fee" label="Fee" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="retention_gc_gr" label="GCs/GRs" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="retention_permits" label="Permits" required />
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui name="level_of_backup_" label="Level of Backup " multiline minRows={2} required />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const GeneralConditionsStaffingFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;
  return (
    <ReviewForm
      onSubmit={onSubmit}
      section="General Conditions and Staffing - 01-300 to 01-345"
      data={data}
      icon={faHardHat}
      {...rest}
    >
      {(props) => {
        return (
          <>
            <Grid item xs={12} sm={3}>
              <CurrencyFieldMui name="total_amount" label="Total Amount" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <PercentFieldMui name="percent_of_gmp" label="% of GMP" decimalScale={2} required placeholder="%" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CurrencyFieldMui name="amount_per_week" label="Spend Per Week" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CurrencyFieldMui name="spent_to_date" label="Spent To Date" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <NumberFieldMui name="weeks_left" label="Weeks Left" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CurrencyFieldMui name="expected_overrun" label="Expected Overrun" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CurrencyFieldMui name="precon_budget" label="Precon Budget" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <CurrencyFieldMui name="precon_actual_spent" label="Precon Actual Spent" />
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui name="plan_for_recovery" label="Plan For Recovery" multiline minRows={2} />
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui name="gc_notes" label="Notes" multiline minRows={4} />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const SiteRequirementsFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;
  return (
    <ReviewForm
      onSubmit={onSubmit}
      section="Site Requirements - 01-355 to 02-900"
      data={data}
      icon={faHardHat}
      {...rest}
    >
      {(props) => {
        return (
          <>
            <Grid item xs={12} sm={4}>
              <CurrencyFieldMui name="total_amount" label="Total Amount" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <PercentFieldMui name="percent_of_gmp" label="% of GMP" decimalScale={2} placeholder="%" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyFieldMui name="amount_per_week" label="Spend Per Week" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyFieldMui name="spent_to_date" label="Spent To Date" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <CurrencyFieldMui name="expected_overrun" label="Expected Overrun" />
            </Grid>
            <Grid item xs={8}>
              <TextFieldMui name="plan_for_recovery" label="Plan For Recovery" multiline minRows={2} />
            </Grid>
            <Grid item xs={4}>
              <Box textAlign="center">
                <YesNoRadios name="reviewed_with_superintendent" label="Reviewed With Superintendent" />
              </Box>
            </Grid>
            <Grid item xs={12}>
              <TextFieldMui name="sr_notes" label="Notes" multiline minRows={4} />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const OwnerTermsFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;

  return (
    <ReviewForm onSubmit={onSubmit} section="Owner Terms" data={data} icon={faFileContract} {...rest}>
      {(props) => {
        const {values} = props;

        return (
          <>
            <Grid item xs={12} sm={2}>
              <RadioGroupMui
                name="contract_type"
                label="Type"
                options={[
                  {label: "LS", value: "LS"},
                  {label: "GMP", value: "GMP"},
                ]}
                required
              />
            </Grid>
            {values.contract_type === "GMP" && (
              <>
                <Grid item xs={12} sm={2}>
                  <YesNoRadios name="gmp_submitted" label="Submitted" required />
                </Grid>
                <Grid item xs={12} sm={2}>
                  <YesNoRadios name="gmp_approved" label="Approved" required />
                </Grid>
              </>
            )}
            <Grid item xs={12} sm={6}>
              <RadioGroupMui
                name="contract"
                label="Contract"
                options={[
                  {label: "AIA", value: "AIA Format"},
                  {label: "Client", value: "Client Format"},
                  {label: "MSA", value: "MSA"},
                  {label: "PO", value: "PO"},
                ]}
                required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PercentFieldMui name="fee_percent" label="Fee" decimalScale={2} required placeholder="%" />
            </Grid>
            <Grid item xs={12} sm={6}>
              <PercentFieldMui
                name="li_percent"
                label="Liability Insurance"
                decimalScale={2}
                required
                placeholder="%"
              />
            </Grid>
            <Grid item xs={12} sm={2}>
              <YesNoRadios name="executed" label="Executed" required />
            </Grid>
            {values?.executed ? (
              <>
                <Grid item xs={12} sm={4}>
                  <RadioGroupMui
                    name="executed_amount_type"
                    label="Executed Type"
                    options={[
                      {label: "Full", value: "Full Amount"},
                      {label: "GC Only", value: "GC Only"},
                      {label: "GC plus trades", value: "GC's plus some trades"},
                    ]}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <CurrencyFieldMui name="executed_amount" label="Executed Amount" required />
                </Grid>
              </>
            ) : (
              <>
                <Grid item xs={12} sm={2}>
                  <YesNoRadios name="loas_executed" label="NOVO LOAs executed" required />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CurrencyFieldMui name={`loa_approved_to_date`} label="LOA Approved to date" required />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <CheckboxMui name="mep_design_cost" label="MEP Design Cost" required />
                </Grid>
              </>
            )}
            <Grid item xs={4}>
              <CurrencyFieldMui name={`construction_contingency`} label="Construction Contingency" required />
            </Grid>
            <Grid item xs={4}>
              <CurrencyFieldMui
                name={`construction_contingency_spent`}
                label="Construction Contingency Spent"
                required
              />
            </Grid>
            <Grid item xs={4}>
              <RadioGroupMui
                name="construction_contingency_held_by"
                label="Held By"
                options={[
                  {value: "NOVO", label: "NOVO"},
                  {value: "Other", label: "Other"},
                ]}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <CurrencyFieldMui name={`design_contingency`} label="Design Contingency" required />
            </Grid>
            <Grid item xs={4}>
              <CurrencyFieldMui name={`design_contingency_spent`} label="Design Contingency Spent" required />
            </Grid>
            <Grid item xs={4}>
              <RadioGroupMui
                name="design_contingency_held_by"
                label="Held By"
                options={[
                  {value: "NOVO", label: "NOVO"},
                  {value: "Other", label: "Other"},
                ]}
                required
              />
            </Grid>
            <Grid item xs={4}>
              <CurrencyFieldMui name={`ot_allowance`} label="OT Allowance" required />
            </Grid>
            <Grid item xs={4}>
              <CurrencyFieldMui name={`ot_allowance_spent`} label="OT Allowance Spent" required />
            </Grid>
            <Grid item xs={4}>
              <RadioGroupMui
                name="ot_allowance_held_by"
                label="Held By"
                options={[
                  {value: "NOVO", label: "NOVO"},
                  {value: "Other", label: "Other"},
                ]}
                required
              />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const DesignFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;

  return (
    <ReviewForm onSubmit={onSubmit} section="Design" data={data} icon={faDraftingCompass} {...rest}>
      {(props) => {
        return (
          <>
            <Grid item xs={12} sm={4}>
              <YesNoRadios name="design_build_with_architect" label="Design/Build With Architect" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <YesNoRadios name="mep_design_build" label="MEP - Design Build" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <YesNoRadios name="mep_fully_engineered" label="MEP - Fully Engineered" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <YesNoRadios name="permit_received" label="Permit received" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumberFieldMui
                name="expected_number_of_permit_submissions"
                label="Expected Permit Submissions"
                required
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <RatingMui name="quality_of_documents" label="Quality of Documents" required />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const SubcontractorsFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;

  return (
    <ReviewForm onSubmit={onSubmit} section="Subcontractors" data={data} icon={faHardHat} {...rest}>
      {(props) => {
        return (
          <>
            {data.subcontractors_list.map((subcontractor, index) => {
              return (
                <React.Fragment key={`${subcontractor.company.id}-${index}`}>
                  <Grid item xs={12} sm={4}>
                    <RatingMui
                      name={`subcontractors_list[${index}].quality`}
                      label={`${subcontractor.company.name} ${subcontractor.code && ` - ${subcontractor.code}`} `}
                    />{" "}
                  </Grid>
                  <Grid item xs={12} sm={8}>
                    <TextFieldMui name={`subcontractors_list[${index}].notes`} label="Notes" />
                  </Grid>
                </React.Fragment>
              );
            })}
          </>
        );
      }}
    </ReviewForm>
  );
};

export const JobWalkFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;

  return (
    <ReviewForm onSubmit={onSubmit} section="Job Walk" data={data} icon={faShoePrints} {...rest}>
      {(props) => {
        return (
          <>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="novo_sign" label="Novo Sign" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <RatingMui name="cleanliness" label="Cleanliness" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <RatingMui name="organization" label="Organization" />
            </Grid>
            <Grid item xs={12} sm={3}>
              <RatingMui name="signage" label="Signage" />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const OperationsFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;

  return (
    <ReviewForm onSubmit={onSubmit} section="Operations" data={data} icon={faCogs} {...rest}>
      {(props) => {
        return (
          <>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="budget_in_sentinel" label="Budget In Sentinel" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="lois_issued" label="LOIs Issued" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="subcontracts_issued" label="Subcontracts Issued" required />
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextFieldMui
                name="how_contingency_and_overtime_expenditures_approved"
                label="How/by who are contingency and overtime expenditures approved"
                multiline
                required
                minRows={3}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Provide For Review</Typography>
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="insurance_log" label="Insurance Log" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="submittal_log" label="Submittal Log" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="procurement_log" label="Procurement Log" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="contingency_log" label="Contingency Log" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="overtime_log" label="Overtime Log" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="meeting_minutes" label="Meeting Minutes" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="rfi_log" label="RFI Log" required />
            </Grid>
            <Grid item xs={12} sm={3}>
              <YesNoRadios name="cost_report" label="Cost Report" required />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5">Owner Vendor</Typography>
            </Grid>
            <Grid item xs={12}>
              <ProjectCompanyAutoselectMui projectId={projectId} name="owner_vendor_av" label="AV" />
            </Grid>
            <Grid item xs={12}>
              <ProjectCompanyAutoselectMui projectId={projectId} name="owner_vendor_cabling" label="Cabling" />
            </Grid>
            <Grid item xs={12}>
              <ProjectCompanyAutoselectMui projectId={projectId} name="owner_vendor_furniture" label="Furniture" />
            </Grid>
            <Grid item xs={12}>
              <ProjectCompanyAutoselectMui projectId={projectId} name="owner_vendor_security" label="Security" />
            </Grid>
            <Grid item xs={12}>
              <ProjectCompanyAutoselectMui projectId={projectId} name="owner_vendor_other" label="Other" />
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const SafetyFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;

  return (
    <ReviewForm onSubmit={onSubmit} section="Safety" data={data} icon={faTrafficCone} {...rest}>
      {(props) => {
        const {form} = props;
        return (
          <>
            <Grid item xs={12} sm={4}>
              <DatePickerMui
                name="last_time_thomas_walked_the_site"
                label="Last Time Novo Safety Walked Site"
                onClose={() => validateDatePickerSubmission(form)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <DatePickerMui
                name="last_time_gs_walked_the_site"
                label="Last Time General Super Walked Site"
                onClose={() => validateDatePickerSubmission(form)}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <NumberFieldMui name="number_of_incidents" label="Number Of Incidents" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <YesNoRadios name="exterior_excavations" label="Exterior Excavations" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <YesNoRadios name="notice_given_to_811" label="Notice Given To 811" required />
            </Grid>
            <Grid item xs={12} sm={4}>
              <YesNoRadios
                name="site_specific_safety_plan_completed"
                label="Site Specific Safety Plan Completed"
                required
              />
            </Grid>
            <Grid item xs={12} sm={6} container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">Top 5 Project Risks</Typography>
              </Grid>
              {[...Array(5).keys()].map((i) => {
                return (
                  <Grid item xs={12} key={`risk${i}`}>
                    <TextFieldMui name={`project_risk[${i}]`} label={`#${i + 1}`} />
                  </Grid>
                );
              })}
            </Grid>
            <Grid item xs={12} sm={6} container spacing={1}>
              <Grid item xs={12}>
                <Typography variant="h5">EMR Ratings</Typography>
              </Grid>
              <Grid item xs={12}>
                <NumberFieldMui name="demo_subcontractor_emr" label="Demo Subcontractor" required decimalScale={2} />
              </Grid>
              <Grid item xs={12}>
                <NumberFieldMui
                  name="drywall_subcontractor_emr"
                  label="Drywall Subcontractor"
                  required
                  decimalScale={2}
                />
              </Grid>
              <Grid item xs={12}>
                <NumberFieldMui
                  name="fire_protection_subcontractor_emr"
                  label="Fire Protection Subcontractor"
                  required
                  decimalScale={2}
                />
              </Grid>
              <Grid item xs={12}>
                <NumberFieldMui
                  name="mechanical_subcontractor_emr"
                  label="Mechanical Subcontractor"
                  required
                  decimalScale={2}
                />
              </Grid>
              <Grid item xs={12}>
                <NumberFieldMui
                  name="electrical_subcontractor_emr"
                  label="Electrical Subcontractor"
                  required
                  decimalScale={2}
                />
              </Grid>
            </Grid>
          </>
        );
      }}
    </ReviewForm>
  );
};

export const FollowupFields = (props: IFieldProps) => {
  const {onSubmit, data, projectId, ...rest} = props;

  return (
    <ReviewForm onSubmit={onSubmit} section="Action & Follow Up Items" data={data} icon={faListOl} {...rest}>
      {(props) => {
        return (
          <>
            {[...Array(5).keys()].map((i) => {
              return (
                <Grid item xs={12} key={`followUp${i}`}>
                  <TextFieldMui name={`follow_up_item[${i}]`} label={`#${i + 1}`} />
                </Grid>
              );
            })}
          </>
        );
      }}
    </ReviewForm>
  );
};
