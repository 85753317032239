import {Link, Typography} from "@material-ui/core";
import {groupBy} from "lodash";
import {Helmet} from "react-helmet";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import BlockUI from "../../../components/GlobalLoaders";
import {MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {useFetchCurrentPage} from "../../../hooks/useSentinelAPI";

const SafetyReviewQuestions = (props) => {
  const {project, userContact, ...rest} = props;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      questions: [],
      safetyOrientationEnabled: false,
    },
  });
  const pageData = pageDataQuery.data;

  if (!pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching Project Safety Review Questions..." />;
  }

  const questions = pageData.questions;
  const groupedQuestions = groupBy(questions, "category");

  return (
    <>
      <Helmet title={`${project.display} - Project Safety Review Questions`} />

      <ProjectBreadcrumbs project={project}>
        <MuiNavLink href={`/v2/projects/${project.id}/safety/project-safety-reviews`}>
          <Typography color="textSecondary">Project Safety Reviews</Typography>
        </MuiNavLink>
        <Typography color="textPrimary">Project Safety Review Questions</Typography>
      </ProjectBreadcrumbs>

      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Project Safety Review Questions</PaperPanel.Header.Title>
        </PaperPanel.Header>
        <PaperPanel.Body p={1}>
          <ul style={{listStyleType: "none"}}>
            {Object.keys(groupedQuestions).map((categoryKey) => {
              return (
                <li key={categoryKey}>
                  <h3>{categoryKey}</h3>
                  <ul style={{listStyleType: "none"}}>
                    {groupedQuestions[categoryKey].map((question) => {
                      return (
                        <li key={question.question}>
                          {question.question}
                          <ul>
                            {question.regulations.map((regulation) => (
                              <li key={regulation.regulation}>
                                <Link href={regulation?.link} underline="always">
                                  {regulation.regulation}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      );
                    })}
                  </ul>
                </li>
              );
            })}
          </ul>
        </PaperPanel.Body>
      </PaperPanel>

      {/* <pre>{JSON.stringify(groupedQuestions, null, 2)}</pre> */}
    </>
  );
};

export default SafetyReviewQuestions;
