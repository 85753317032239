import {Grid} from "@material-ui/core";
import {NumberFieldMui} from "../../../../components/forms/Fields";

const DefaultsFields = (props) => {
  const {namespace} = props;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <NumberFieldMui name={`${namespace}.rfi_required_days_out`} label="RFI Required Days Out" autoFocus />
        </Grid>
        <Grid item xs={12} sm={6}>
          <NumberFieldMui name={`${namespace}.submittal_review_duration`} label="NOVO Submittal Review Duration" />
        </Grid>
      </Grid>
    </>
  );
};

export default DefaultsFields;
