import {
  faCamera,
  faCaretDown,
  faCaretUp,
  faFilePdf,
  faHelmetSafety,
  faSortNumericDown,
  faSortNumericUp,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Divider, IconButton, Link, makeStyles, Menu, MenuItem} from "@material-ui/core";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import {blue, green, red, yellow} from "@material-ui/core/colors";
import Grow from "@material-ui/core/Grow";
import MenuList from "@material-ui/core/MenuList";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import {alpha} from "@material-ui/core/styles/colorManipulator";
import AddIcon from "@material-ui/icons/Add";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import FolderIcon from "@material-ui/icons/Folder";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import SearchIcon from "@material-ui/icons/Search";
import {AxiosResponse} from "axios";
import classnames from "classnames";
import {capitalize} from "lodash";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {useSnackbar} from "notistack";
import React from "react";
import {axiosAPI} from "../api";
import useBlockUI from "../hooks/useBlockUI";
import {getAxiosAPIResponseMessage, useSentinelDetailAPI} from "../hooks/useSentinelAPI";
import {colorError, colorInfo, colorSuccess, colorWarning} from "../theme/colors";
import {ConfirmationDialog} from "./Dialogs";
import {MuiNavLink} from "./Links";
import {MenuItemHeader} from "./Menu";

export const useButtonStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    textSuccess: {
      color: colorSuccess,
      "&:hover": {
        backgroundColor: alpha(colorSuccess, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
    outlinedSuccess: {
      border: `1px solid ${alpha(colorSuccess, 0.5)}`,
      "&:hover": {
        border: `1px solid ${colorSuccess}`,
      },
      "&.Mui-disabled": {
        border: `1px solid ${theme.palette.action.disabled}`,
      },
    },
    containedSuccess: {
      color: palette.getContrastText(colorSuccess),
      backgroundColor: colorSuccess,
      "&:hover": {
        backgroundColor: green[700],
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: colorSuccess,
        },
      },
    },
    textInfo: {
      color: colorInfo,
      "&:hover": {
        backgroundColor: alpha(colorInfo, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
    outlinedInfo: {
      border: `1px solid ${alpha(colorInfo, 0.5)}`,
      "&:hover": {
        border: `1px solid ${colorInfo}`,
      },
      "&.Mui-disabled": {
        border: `1px solid ${theme.palette.action.disabled}`,
      },
    },
    containedInfo: {
      color: palette.getContrastText(colorInfo),
      backgroundColor: colorInfo,
      "&:hover": {
        backgroundColor: blue[700],
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: colorInfo,
        },
      },
    },

    textWarning: {
      color: colorWarning,
      "&:hover": {
        backgroundColor: alpha(colorWarning, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
    outlinedWarning: {
      border: `1px solid ${alpha(colorWarning, 0.5)}`,
      "&:hover": {
        border: `1px solid ${colorWarning}`,
      },
      "&.Mui-disabled": {
        border: `1px solid ${theme.palette.action.disabled}`,
      },
    },
    containedWarning: {
      color: palette.getContrastText(colorWarning),
      backgroundColor: colorWarning,
      "&:hover": {
        backgroundColor: yellow[900],
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: colorWarning,
        },
      },
    },

    textError: {
      color: colorError,
      "&:hover": {
        backgroundColor: alpha(colorError, theme.palette.action.hoverOpacity),
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: "transparent",
        },
      },
    },
    outlinedError: {
      border: `1px solid ${alpha(colorError, 0.5)}`,
      "&:hover": {
        border: `1px solid ${colorError}`,
      },
      "&.Mui-disabled": {
        border: `1px solid ${theme.palette.action.disabled}`,
      },
    },
    containedError: {
      color: palette.getContrastText(colorError),
      backgroundColor: colorError,
      "&:hover": {
        backgroundColor: red[700],
        // Reset on touch devices, it doesn't add specificity
        "@media (hover: none)": {
          backgroundColor: colorError,
        },
      },
    },
  };
});

export const SplitButton = (props) => {
  const {children, title, size, color, variant, disableElevation, disableDropDown, ...rest} = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <ButtonGroup
        ref={anchorRef}
        aria-label="split button"
        size={size}
        color={color}
        variant={variant}
        disableElevation={disableElevation}
      >
        <Button {...rest}>{title}</Button>
        <Button
          // color="primary"
          color={color}
          aria-controls={open ? "split-button-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          // aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
          }}
          style={{paddingLeft: 0, paddingRight: 0, minWidth: 25}}
          disabled={disableDropDown}
        >
          <ArrowDropDownIcon fontSize={size || "small"} />
        </Button>
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 100}}>
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener
                onClickAway={(event) => {
                  if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
                    return;
                  }
                  setOpen(false);
                }}
              >
                <MenuList id="split-button-menu">
                  {children instanceof Function ? children(setOpen) : children}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export const DropdownButton = (props: {
  children: React.ReactNode | ((setOpen) => React.ReactElement);
  button?: React.ReactElement;
  title?: string | React.ReactElement;
  size?: "small" | "medium" | "large";
  fullWidth?: boolean;
  disableElevation?: boolean;
  [rest: string]: any;
}) => {
  const {children, title, button, size, disableElevation, fullWidth, startIcon, ...rest} = props;
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLDivElement>(null);

  return (
    <>
      <ButtonGroup ref={anchorRef} disableElevation={disableElevation} fullWidth={fullWidth}>
        {button ? (
          React.cloneElement(button, {
            onClick: () => {
              setOpen((prevOpen) => !prevOpen);
            },
          })
        ) : (
          <Button
            size={size}
            startIcon={startIcon}
            endIcon={<ArrowDropDownIcon fontSize={size || "small"} />}
            variant="outlined"
            onClick={() => {
              setOpen((prevOpen) => !prevOpen);
            }}
            {...rest}
          >
            {title}
          </Button>
        )}
      </ButtonGroup>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{zIndex: 100}}>
        {({TransitionProps, placement}) => {
          return (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "center top" : "center bottom",
              }}
            >
              <Paper>
                <ClickAwayListener
                  onClickAway={(event) => {
                    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
                      return;
                    }
                    setOpen(false);
                  }}
                >
                  <MenuList id="dropdown-button-menu">
                    {children instanceof Function ? children(setOpen) : children}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          );
        }}
      </Popper>
    </>
  );
};

export const PDFButton = (props: {href: string; title?: string; [rest: string]: any}) => {
  const {title = "Get PDF", ...rest} = props;
  return (
    <Box display="inline" color={blue[900]}>
      <Button
        color="inherit"
        startIcon={<FontAwesomeIcon icon={faFilePdf} color={red.A700} />}
        variant="outlined"
        {...rest}
      >
        {title}
      </Button>
    </Box>
  );
};

export const PDFDropdownButton = (props: {
  children: React.ReactNode;
  title?: string;
  size?: "small" | "medium" | "large";
  [rest: string]: any;
}) => {
  const {children, title = "Get PDF", size, ...rest} = props;

  return (
    <DropdownButton
      button={
        <Box display="inline" color={blue[900]}>
          <Button
            color="inherit"
            size={size}
            startIcon={<FontAwesomeIcon icon={faFilePdf} color={red.A700} />}
            endIcon={<ArrowDropDownIcon fontSize={size || "small"} />}
            variant="outlined"
            {...rest}
          >
            {title}
          </Button>
        </Box>
      }
    >
      {children}
    </DropdownButton>
  );
};

export const PDFSplitButton = (props: {
  title?: string;
  href: string;
  children: React.ReactNode;
  [rest: string]: any;
}) => {
  const {title = "Get PDF", children, ...rest} = props;
  return (
    <Box display="inline" color={blue[900]}>
      <SplitButton
        color="inherit"
        startIcon={<FontAwesomeIcon icon={faFilePdf} color={red.A700} />}
        title={title}
        {...rest}
      >
        {children}
      </SplitButton>
    </Box>
  );
};

export const SearchButton = (props: {children?: React.ReactNode; [rest: string]: any}) => {
  const {children = "Search", disableElevation = true, variant = "contained", ...rest} = props;
  return (
    <Button startIcon={<SearchIcon />} disableElevation={disableElevation} variant={variant} {...rest}>
      {children}
    </Button>
  );
};

export const ColoredButton = (props: {
  color: "success" | "warning" | "error" | "info";
  children: React.ReactNode;
  [rest: string]: any;
}) => {
  const {variant = "contained", className, color, children, ...rest} = props;
  const classes = useButtonStyles();
  const capitalizedColor = capitalize(color);
  const classes_ =
    variant === "contained"
      ? classes[`contained${capitalizedColor}`]
      : [classes[`text${capitalizedColor}`], classes[`outlined${capitalizedColor}`]];
  return (
    <Button variant={variant} className={classnames(classes_, className)} {...rest}>
      {children}
    </Button>
  );
};

export const CreateButton = (props: {title?: string; children?: React.ReactNode; [rest: string]: any}) => {
  const {children = "Create", ...rest} = props;
  return (
    <ColoredButton startIcon={<AddIcon />} color="success" {...rest}>
      {children}
    </ColoredButton>
  );
};

export const EditButton = (props: {title?: string; children?: React.ReactNode; [rest: string]: any}) => {
  const {children = "Edit", ...rest} = props;
  return (
    <Button startIcon={<EditIcon />} {...rest}>
      {children}
    </Button>
  );
};

export const DeleteButton = (props: {title?: string; children?: React.ReactNode; [rest: string]: any}) => {
  const {children = "Delete", ...rest} = props;
  return (
    <ColoredButton startIcon={<DeleteIcon />} color="error" variant="outlined" {...rest}>
      {children}
    </ColoredButton>
  );
};

export const ColoredSplitButton = (props: {
  title: string;
  color: string;
  children: React.ReactNode | ((setOpen: any) => React.ReactNode);
  [rest: string]: any;
}) => {
  const {variant = "outlined", title, color, children, ...rest} = props;
  return (
    <Box display="inline" color={color}>
      <SplitButton title={title} color="inherit" {...rest}>
        {children}
      </SplitButton>
    </Box>
  );
};

export const MoreMenuButton = (props) => {
  const {children, border, size = "small", ...rest} = props;
  // TODO: add custom menu button: https://stackoverflow.com/questions/48919320/react-how-to-pass-props-to-a-component-passed-as-prop
  return (
    <Box borderColor="grey.400" borderLeft={border ? 1 : 0} pl={border ? 1 : 0}>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {(popupState) => (
          <>
            <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              size={size}
              {...bindTrigger(popupState)}
              {...rest}
            >
              <MoreIcon />
            </IconButton>
            <Menu {...bindMenu(popupState)}>{children(popupState)}</Menu>
          </>
        )}
      </PopupState>
    </Box>
  );
};

export const BoxFilesButton = (props: {href: string; uploadURL?: string; title?: string; [rest: string]: any}) => {
  const {href, uploadURL, title = "Box Files", ...rest} = props;
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();

  if (uploadURL) {
    return (
      <SplitButton title={title} href={href} startIcon={<FolderIcon />} {...rest}>
        {(setOpen) => (
          <MenuItem
            dense
            onClick={() => {
              setOpen(false);
              blockUI.blockUI("Uploading to Box...");
              axiosAPI
                .post(uploadURL, {baseURL: ""})
                .then(() => blockUI.unblockUI())
                .catch((error) => {
                  enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
                  blockUI.unblockUI();
                });
            }}
          >
            Upload PDF to Box
          </MenuItem>
        )}
      </SplitButton>
    );
  }

  return (
    <Button href={href} startIcon={<FolderIcon />} {...rest}>
      {title}
    </Button>
  );
};

export const FieldwireUploadButton = (props: {
  uploadURL: string;
  fieldwireFileId?: string;
  onUploadSuccess?: () => void;
  [rest: string]: any;
}) => {
  const {uploadURL, fieldwireFileId, onUploadSuccess, ...rest} = props;
  const [confirmationIsOpen, setConfirmationIsOpen] = React.useState(false);
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();

  return (
    <>
      <Button
        onClick={() => {
          setConfirmationIsOpen(true);
        }}
        startIcon={<FontAwesomeIcon icon={faHelmetSafety} />}
        {...rest}
      >
        Upload to Fieldwire
      </Button>
      <ConfirmationDialog
        isOpen={confirmationIsOpen}
        onApprove={() => {
          setConfirmationIsOpen(false);
          blockUI.blockUI("Uploading to Fieldwire...");
          axiosAPI
            .post(uploadURL)
            .then(() => {
              blockUI.unblockUI();
              onUploadSuccess && onUploadSuccess();
            })
            .catch((error) => {
              enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
              blockUI.unblockUI();
            });

          // onCreateRevision();
        }}
        onDeny={() => {
          setConfirmationIsOpen(false);
        }}
      >
        Are you sure you want to upload a PDF to Fieldwire?
        {fieldwireFileId && <div> This will override the current file (including any comments, markups).</div>}
      </ConfirmationDialog>
    </>
  );
};

export const ReorderButton = (props: {
  // children: React.ReactNode;
  onReorder: (field: string) => void;
  onRenumber?: () => void;
  onFixPositions?: () => void;
  children?: (setOpen?) => React.ReactNode;
  [rest: string]: any;
}) => {
  const {variant = "contained", dense = true, onReorder, onRenumber, onFixPositions, children, ...rest} = props;
  return (
    <DropdownButton title="Reorder" {...rest}>
      {(setOpen) => (
        <div>
          <MenuItem
            dense={dense}
            onClick={() => {
              setOpen(false);
              onReorder("created");
            }}
          >
            <FontAwesomeIcon icon={faCaretDown} fixedWidth />
            Created
          </MenuItem>
          <MenuItem
            dense={dense}
            onClick={() => {
              setOpen(false);
              onReorder("-created");
            }}
          >
            <FontAwesomeIcon icon={faCaretUp} fixedWidth />
            Created
          </MenuItem>
          <Divider />
          <MenuItem
            dense={dense}
            onClick={() => {
              setOpen(false);
              onReorder("number");
            }}
          >
            <FontAwesomeIcon icon={faSortNumericDown} fixedWidth />
            Number
          </MenuItem>
          <MenuItem
            dense={dense}
            onClick={() => {
              setOpen(false);
              onReorder("-number");
            }}
          >
            <FontAwesomeIcon icon={faSortNumericUp} fixedWidth />
            Number
          </MenuItem>
          {children && children(setOpen)}
          {onRenumber && (
            <>
              <Divider />
              <MenuItem
                dense={dense}
                onClick={() => {
                  setOpen(false);
                  onRenumber();
                }}
              >
                <FontAwesomeIcon icon={faSortNumericDown} fixedWidth />
                Renumber By Position
              </MenuItem>
            </>
          )}
          {onFixPositions && (
            <>
              <Divider />
              <MenuItem
                dense={dense}
                onClick={() => {
                  setOpen(false);
                  onFixPositions();
                }}
              >
                <FontAwesomeIcon icon={faSortNumericDown} fixedWidth />
                Fix Positions
              </MenuItem>
            </>
          )}
        </div>
      )}
    </DropdownButton>
  );
};

export const RevisionsButton = (props: {obj: any; url: string; onCreateRevision: () => void; [rest: string]: any}) => {
  const {
    obj,
    url,
    onCreateRevision,
    children,
    confirmationMessage = "You want to create a new revision.",
    ...rest
  } = props;
  const [createRevisionConfirmationIsOpen, setCreateRevisionConfirmationIsOpen] = React.useState(false);

  const {query: revisionsQuery} = useSentinelDetailAPI(url, {
    initialData: {
      revisions: [],
    },
  });
  const revisionData = revisionsQuery.data;
  if (revisionsQuery.isFetching)
    return (
      <Button variant="outlined" disabled {...rest}>
        Revisions
      </Button>
    );
  if (revisionData.can_create_revision && onCreateRevision && revisionData.revisions.length > 1)
    return (
      <>
        <SplitButton
          title="Create Revision"
          startIcon={<AddIcon />}
          {...rest}
          onClick={() => {
            setCreateRevisionConfirmationIsOpen(true);
          }}
        >
          {(setOpen) => {
            return (
              <>
                {revisionData.revisions.map((revision) => (
                  <MenuItem
                    dense
                    key={revision.id}
                    component={(revision?.url || "").startsWith("/v2/") ? MuiNavLink : Link}
                    href={revision.url}
                    disabled={revision.id === obj.id}
                  >
                    {revision.number_display}
                  </MenuItem>
                ))}
                {children && (
                  <>
                    <MenuItemHeader border />
                    {children}
                  </>
                )}
              </>
            );
          }}
        </SplitButton>
        <ConfirmationDialog
          isOpen={createRevisionConfirmationIsOpen}
          onApprove={() => {
            setCreateRevisionConfirmationIsOpen(false);
            onCreateRevision();
          }}
          onDeny={() => {
            setCreateRevisionConfirmationIsOpen(false);
          }}
        >
          {confirmationMessage}
        </ConfirmationDialog>
      </>
    );
  if ((!revisionData.can_create_revision || !onCreateRevision) && revisionData.revisions.length > 1)
    return (
      <DropdownButton title="Revisions" {...rest}>
        {(setOpen) => {
          return (
            <>
              {revisionData.revisions.map((revision) => (
                <div key={revision.id}>
                  <MenuItem
                    dense
                    component={(revision?.url || "").startsWith("/v2/") ? MuiNavLink : Link}
                    href={revision.url}
                    disabled={revision.id === obj.id}
                  >
                    {revision.number_display}
                  </MenuItem>
                </div>
              ))}
              {children && (
                <>
                  <MenuItemHeader border />
                  {children}
                </>
              )}
            </>
          );
        }}
      </DropdownButton>
    );
  if (revisionData.revisions.length <= 1)
    return (
      <>
        {onCreateRevision ? (
          <>
            {children ? (
              <DropdownButton title="Revisions" {...rest}>
                <MenuItem
                  disabled={!revisionData.can_create_revision}
                  onClick={() => {
                    setCreateRevisionConfirmationIsOpen(true);
                  }}
                  {...rest}
                >
                  Create Revision
                </MenuItem>
                {children}
              </DropdownButton>
            ) : (
              <Button
                variant="outlined"
                startIcon={<AddIcon />}
                disabled={!revisionData.can_create_revision}
                onClick={() => {
                  setCreateRevisionConfirmationIsOpen(true);
                }}
                {...rest}
              >
                Create Revision
              </Button>
            )}

            <ConfirmationDialog
              isOpen={createRevisionConfirmationIsOpen}
              onApprove={() => {
                setCreateRevisionConfirmationIsOpen(false);
                onCreateRevision();
              }}
              onDeny={() => {
                setCreateRevisionConfirmationIsOpen(false);
              }}
            >
              {confirmationMessage}
            </ConfirmationDialog>
          </>
        ) : (
          <Button variant="outlined" disabled {...rest}>
            Revisions
          </Button>
        )}
      </>
    );
};

export const UploadFileButton = (props: {
  onUpload: (FileList) => void;
  accept?: string;
  children?: React.ReactNode;
  multiple?: boolean;
  capture?: boolean | "user" | "environment";
  [rest: string]: any;
}) => {
  const {onUpload, accept, children = "Upload", multiple = false, capture = false, ...rest} = props;
  return (
    <Button variant="outlined" component="label" {...rest}>
      {children}
      <input
        type="file"
        accept={accept}
        hidden
        onChange={(event) => {
          const file = event.target.files[0];
          if (!file) {
            event.target.value = null;
            return;
          }
          onUpload(file);
          event.target.value = null;
        }}
        multiple={multiple}
        capture={capture}
      />
    </Button>
  );
};

export const CameraCaptureButton = (props: {
  label?: string;
  uploadURL: string;
  beforeUpload?: () => void;
  afterUpload?: (response: AxiosResponse<any, any>) => void;
  additionalFormData?: {[key: string]: any};
  capture?: boolean | "user" | "environment";
  [rest: string]: any;
}) => {
  const {
    label = "Capture Photo",
    beforeUpload = () => {},
    afterUpload = (response) => {},
    uploadURL,
    additionalFormData = {},
    baseURL = "/api/v1",
    capture = "environment",
    ...rest
  } = props;

  return (
    <UploadFileButton
      accept="image/*"
      onUpload={(file) => {
        beforeUpload();
        let formData = new FormData();
        formData.append("file", file);
        Object.entries(additionalFormData).forEach(([key, value]) => formData.append(key, value));
        axiosAPI
          .post(uploadURL, formData, {baseURL: baseURL, headers: {"Content-Type": "multipart/form-data"}})
          .then((response) => {
            afterUpload(response);
          });
      }}
      capture={capture}
      startIcon={<FontAwesomeIcon icon={faCamera} />}
      {...rest}
    >
      {label}
    </UploadFileButton>
  );
};
