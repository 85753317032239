import {faSortAmountDown} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, makeStyles, Tooltip, Typography} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import classnames from "classnames";
import NumberFormat from "react-number-format";
import LabeledGroup from "../../../../js/components/LabeledGroup";
import {Currency} from "../../../components/Accounting";

interface BudgetItemProps {
  item: {
    budget_rom: number;
    budget_proposed_amount: number;
    budget_approved_amount: number;
    budget_cost: number;
    budget: {
      is_markup: boolean;
      is_compound_markup: boolean;
      markup_rate: number;
    };
    expected_rom_markup: number;
    expected_proposed_markup: number;
    expected_approved_markup: number;
    total_rom_for_markup: number;
    total_proposed_for_markup: number;
    total_approved_for_markup: number;
    extracker_id?: number;
    is_closed: boolean;
  };
  isMarkup?: boolean;
  onExpectedMarkupClick?: (item) => void;
  onQuickEdit?: (event, item?: string) => void;
  hideCost?: boolean;
}

export const CurrencyItem = (props: {
  amount: number;
  tooltip?: string | React.ReactElement;
  color?: string;
  onItemClick?: (event) => void;
  pointer?: boolean;
  [rest: string]: any;
}) => {
  const {amount, tooltip = "", color, onItemClick, pointer, ...rest} = props;

  return (
    //sx={pointer ? {cursor: "pointer"} : ""}

    <Box textAlign="right" onClick={onItemClick} style={{cursor: pointer ? "pointer" : "inherit"}} {...rest}>
      <Tooltip title={tooltip}>
        <span>
          <Currency number={amount} color={color} />
        </span>
      </Tooltip>
    </Box>
  );
};

const BudgetPaperItemGrid = (props: BudgetItemProps) => {
  const {item, hideCost = false, ...rest} = props;

  return (
    <Grid container>
      <Grid
        item
        xs={hideCost ? 12 : 9}
        // style={{backgroundColor: "lightPink"}}
      >
        <Box mr={1}>
          <LabeledGroup label="Budget" fontSize={11}>
            <Grid container>
              <Grid item xs={12} sm={4}>
                <CurrencyItem amount={item.budget_rom} tooltip="ROM" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyItem amount={item.budget_proposed_amount} tooltip="Proposed" />
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyItem amount={item.budget_approved_amount} tooltip="Approved" />
              </Grid>
            </Grid>
          </LabeledGroup>
        </Box>
      </Grid>
      {!hideCost && (
        <Grid item xs={3}>
          <LabeledGroup label="Cost" fontSize={11}>
            <CurrencyItem amount={item.budget_cost} tooltip="Budget Cost" />
          </LabeledGroup>
        </Grid>
      )}
    </Grid>
  );
};

export default BudgetPaperItemGrid;

export const BudgetMarkupPaperItemGrid = (props: BudgetItemProps) => {
  const {item, isMarkup = item?.budget?.is_markup, onExpectedMarkupClick, onQuickEdit, ...rest} = props;

  return (
    <Grid container {...rest}>
      <Grid
        item
        xs={9}
        // style={{backgroundColor: "lightPink"}}
      >
        <Box mr={1}>
          <LabeledGroup label="Budget" fontSize={11}>
            <Grid container>
              <Grid item xs={12} sm={4} container>
                <Grid item xs={12}>
                  <CurrencyItem
                    amount={item.budget_rom}
                    tooltip="ROM"
                    onItemClick={(event) => {
                      onQuickEdit(event, "budget_rom");
                    }}
                    style={{cursor: onQuickEdit ? "pointer" : "inherit"}}
                  />
                </Grid>

                {isMarkup && (
                  <>
                    <Grid item xs={12}>
                      <small>
                        <CurrencyItem
                          amount={item.expected_rom_markup}
                          tooltip={item.is_closed ? "" : "Copy to ROM"}
                          color={Math.abs(item.budget_rom - item.expected_rom_markup) > 0.5 && "red"}
                          onItemClick={() =>
                            onExpectedMarkupClick({
                              item: item,
                              field: "budget_rom",
                              fieldName: "ROM",
                              amount: item.expected_rom_markup,
                            })
                          }
                          pointer={!item.is_closed}
                        />
                      </small>
                    </Grid>
                    <Grid item xs={12}>
                      {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
                      <Box
                        textAlign="right"
                        onClick={onQuickEdit}
                        style={{cursor: onQuickEdit ? "pointer" : "inherit"}}
                      >
                        <Calculation>
                          {item?.budget?.is_compound_markup && (
                            <Tooltip title="Compound Markup">
                              <FontAwesomeIcon icon={faSortAmountDown} />
                            </Tooltip>
                          )}
                          <NumberFormat
                            value={item?.budget?.markup_rate}
                            displayType={"text"}
                            suffix={"%"}
                            decimalScale={3}
                          />
                          {" * "}
                          <Currency number={item.total_rom_for_markup} />{" "}
                        </Calculation>
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyItem
                  amount={item.budget_proposed_amount}
                  tooltip="Proposed"
                  onItemClick={(event) => {
                    onQuickEdit(event, "budget_proposed_amount");
                  }}
                  style={{cursor: onQuickEdit ? "pointer" : "inherit"}}
                />
                {isMarkup && (
                  <>
                    <small>
                      <CurrencyItem
                        amount={item.expected_proposed_markup}
                        tooltip={item.is_closed ? "" : "Copy to Proposed"}
                        color={Math.abs(item.budget_proposed_amount - item.expected_proposed_markup) > 0.5 && "red"}
                        onItemClick={() =>
                          onExpectedMarkupClick({
                            item: item,
                            field: "budget_proposed_amount",
                            fieldName: "Proposed",
                            amount: item.expected_proposed_markup,
                          })
                        }
                        pointer={!item.is_closed}
                      />
                    </small>
                    <Box textAlign="right" onClick={onQuickEdit} style={{cursor: onQuickEdit ? "pointer" : "inherit"}}>
                      <Calculation>
                        {item?.budget?.is_compound_markup && (
                          <Tooltip title="Compound Markup">
                            <FontAwesomeIcon icon={faSortAmountDown} />
                          </Tooltip>
                        )}
                        <NumberFormat
                          value={item?.budget?.markup_rate}
                          displayType={"text"}
                          suffix={"%"}
                          decimalScale={3}
                        />
                        {" * "}
                        <Currency number={item.total_proposed_for_markup} />
                      </Calculation>
                    </Box>
                  </>
                )}
              </Grid>
              <Grid item xs={12} sm={4}>
                <CurrencyItem
                  amount={item.budget_approved_amount}
                  tooltip="Approved"
                  onItemClick={(event) => {
                    onQuickEdit(event, "budget_approved_amount");
                  }}
                  style={{cursor: onQuickEdit ? "pointer" : "inherit"}}
                />

                {isMarkup && (
                  <>
                    <small>
                      <CurrencyItem
                        amount={item.expected_approved_markup}
                        tooltip={item.is_closed ? "" : "Copy to Approved"}
                        color={Math.abs(item.budget_approved_amount - item.expected_approved_markup) > 0.5 && "red"}
                        onItemClick={() =>
                          onExpectedMarkupClick({
                            item: item,
                            field: "budget_approved_amount",
                            fieldName: "Approved",
                            amount: item.expected_approved_markup,
                          })
                        }
                        pointer={!item.is_closed}
                      />
                    </small>

                    <Box textAlign="right" onClick={onQuickEdit} style={{cursor: onQuickEdit ? "pointer" : "inherit"}}>
                      <Calculation>
                        {item?.budget?.is_compound_markup && (
                          <Tooltip title="Compound Markup">
                            <FontAwesomeIcon icon={faSortAmountDown} />
                          </Tooltip>
                        )}
                        <NumberFormat
                          value={item?.budget?.markup_rate}
                          displayType={"text"}
                          suffix={"%"}
                          decimalScale={3}
                        />
                        {" * "}
                        <Currency number={item.total_approved_for_markup} />
                      </Calculation>
                    </Box>
                  </>
                )}
              </Grid>
            </Grid>
          </LabeledGroup>
        </Box>
      </Grid>
      <Grid
        item
        xs={3}
        // style={{backgroundColor: "mintCream"}}
      >
        <LabeledGroup
          // label={
          //   item.extracker_id ? (
          //     <>
          //       {item.extracker_id && (
          //         <Tooltip title="Created By Extracker">
          //           <Box>
          //             Cost <FontAwesomeIcon icon={faArrowsH} />
          //           </Box>
          //         </Tooltip>
          //       )}
          //     </>
          //   ) : (
          //     "Cost"
          //   )
          // }
          label={"Cost"}
          fontSize={11}
        >
          <CurrencyItem
            amount={item.budget_cost}
            tooltip="Budget Cost"
            onItemClick={(event) => {
              onQuickEdit(event, "budget_cost");
            }}
            style={{cursor: onQuickEdit ? "pointer" : "inherit"}}
          />
        </LabeledGroup>
      </Grid>
    </Grid>
  );
};

//One-shot component for now. This might be better as a modification to Typography2 instead

const useStyles = makeStyles((theme) => {
  return {
    calculation: {
      fontSize: 9,
      color: grey[600],
      "& a": {
        color: grey[600],
      },
      "& a:hover": {
        // textDecoration: "none",
      },
    },
  };
});

const Calculation = (props: {children: React.ReactNode; [rest: string]: any}) => {
  const {children, className, ...rest} = props;
  const styles = useStyles();
  return (
    <Typography component="span" className={classnames(styles["calculation"], className)} {...rest}>
      {children}
    </Typography>
  );
};
