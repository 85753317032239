import {Box} from "@material-ui/core";
import {Helmet} from "react-helmet";
import {axiosAPI} from "../../api";
import {AIDocumentListTable} from "../../components/AI";
import BlockUI from "../../components/GlobalLoaders";
import PaperPanel from "../../components/PaperPanel";
import useBlockUI from "../../hooks/useBlockUI";
import {useSentinelListAPI} from "../../hooks/useSentinelAPI";

export const AIDocumentList = (props) => {
  const {user} = props;
  const blockUI = useBlockUI();
  const {query: documentQuery} = useSentinelListAPI(`ai/documents/`, {
    initialData: {
      results: [],
    },

    keepPreviousData: true,
  });

  const documents = documentQuery.data.results;

  if (!documentQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching documents..." />;
  }

  const refreshDocuments = () => {
    if (user.is_superuser) {
      return Promise.all([axiosAPI.post(`/ai/documents/import/`), documentQuery.refetch()]);
    }
    return documentQuery.refetch();
  };

  return (
    <>
      <Helmet title={`Sentinel - AI Documents`} />
      <Box mb={3} />
      {/* {!pageData.boxAIFolderId && (
        <Box my={1}>
          <Alert severity="info">
            <AlertTitle>No Box Folder Found</AlertTitle>Unable to locate the global Box AI folder. Please open a
            UserSnap for this issue.
          </Alert>
        </Box>
      )} */}
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>AI Documents</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.RefreshButton
                onClick={() => {
                  blockUI.blockUI("Refreshing...");
                  refreshDocuments().then(() => blockUI.unblockUI());
                }}
                isFetching={documentQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <AIDocumentListTable documents={documents} />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};
