import {useSnackbar} from "notistack";
import React from "react";

export const DjangoMessages = (props) => {
  const {messages} = props;
  const {enqueueSnackbar} = useSnackbar();
  if (!messages) {
    return null;
  }
  React.useEffect(() => {
    messages.map((message, i) => {
      enqueueSnackbar(message.message, {variant: message.tags});
      // return <MessageSnackbar key={i} severity={message.tags} message={message.message} />;
    });
  }, [messages]);

  return null;
};
