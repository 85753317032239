import {
  faCheck,
  faEnvelope,
  faEnvelopeOpen,
  faFilePlus,
  faFileTimes,
  faQuestionCircle,
  faTrash,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Tooltip} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import {colorError, colorInfo, colorSuccess} from "../theme/colors";

export const DocuSignStatusIcon = (props: {
  status: string;
  showTooltip?: boolean;
  tooltipPlacement?:
    | "right"
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | "top";
}) => {
  const {status, showTooltip = true, tooltipPlacement = "bottom", ...rest} = props;
  const theme = useTheme();

  const icon = ((status) => {
    if (["Created"].includes(status)) {
      return {
        icon: faFilePlus,
        color: colorInfo,
      };
    }
    if (["Declined"].includes(status)) {
      return {
        icon: faFileTimes,
        color: colorError,
      };
    }
    if (["Voided"].includes(status)) {
      return {
        icon: faFileTimes,
        color: theme.palette.text.secondary,
      };
    }
    if (["Deleted"].includes(status)) {
      return {
        icon: faTrash,
        color: colorError,
      };
    }
    if (["Sent"].includes(status)) {
      return {
        icon: faEnvelope,
        color: colorInfo,
      };
    }
    if (["Delivered"].includes(status)) {
      return {
        icon: faEnvelopeOpen,
        color: colorSuccess,
      };
    }
    if (["Completed"].includes(status)) {
      return {
        icon: faCheck,
        color: colorSuccess,
      };
    }
    return {
      icon: faQuestionCircle,
      color: theme.palette.text.secondary,
    };
  })(status);

  if (showTooltip && status) {
    return (
      <Tooltip title={status} placement={tooltipPlacement}>
        <span>
          <FontAwesomeIcon icon={icon.icon} style={{color: icon.color}} {...rest} />
        </span>
      </Tooltip>
    );
  }
  return <FontAwesomeIcon icon={icon.icon} style={{color: icon.color}} {...rest} />;
};
