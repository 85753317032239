import {faExternalLink} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Button, Grid, Link, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {Switches as FinalSwitches} from "mui-rff";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {SimpleAutoselectMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";

export const ProjectLinksForm = (props: {
  planGridProjectOptions: any[];
  onSubmit: (values: any) => void;
  onLinkFieldwireProject?: () => void;
  allowLinkPlangrid?: () => boolean;
  [rest: string]: any;
}) => {
  const {
    planGridProjectOptions,
    onSubmit,
    onLinkFieldwireProject,
    allowLinkPlangrid = false,
    sentinelUserOnFieldwireProject,
    ...rest
  } = props;

  return (
    <>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values}) => (
          <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
            <BeforeUnload block={!pristine} />
            <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
              <Typography variant="h4" gutterBottom>
                PlanGrid
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SimpleAutoselectMui
                    name="plangrid_project_id"
                    label="PlanGrid Project"
                    options={planGridProjectOptions}
                    disabled={!allowLinkPlangrid}
                  />
                  <Box my={1}>
                    <Alert severity="info">
                      If the PlanGrid project is not in the list be sure that you add sentinel@novoconstruction.com to
                      team in PlanGrid{" "}
                      <Link href="https://app.plangrid.com/" underline="always">
                        here
                      </Link>
                      .
                    </Alert>
                  </Box>
                </Grid>
              </Grid>
              <Box mb={2} />
              <Typography variant="h4" gutterBottom>
                Fieldwire
              </Typography>
              {values.fieldwire_project_id ? (
                <>
                  <Box>
                    <Link
                      href={`https://app.fieldwire.com/#!/projects/${values.fieldwire_project_id}/`}
                      target="_blank"
                    >
                      {`https://app.fieldwire.com/#!/projects/${values.fieldwire_project_id}/`}{" "}
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  </Box>
                  <Box>
                    {!sentinelUserOnFieldwireProject && (
                      <Alert severity="warning">
                        It looks like sentinel@novoconstruction.com has not been added to this Fieldwire project. Please
                        go{" "}
                        <Link
                          href={`https://app.fieldwire.com/#!/projects/${values.fieldwire_project_id}/people`}
                          target="_blank"
                          underline="always"
                        >
                          here
                        </Link>{" "}
                        and add sentinel@novoconstruction.com to the Fieldwire project for full functionality.
                      </Alert>
                    )}
                  </Box>
                </>
              ) : (
                <Alert
                  severity="info"
                  action={
                    <Button
                      color="inherit"
                      size="small"
                      onClick={onLinkFieldwireProject ? () => onLinkFieldwireProject() : null}
                    >
                      Link to fieldwire project
                    </Button>
                  }
                >
                  {values.timberline_job ? (
                    <>
                      This project has not been linked to Fieldwire. Go{" "}
                      <Link href="https://app.fieldwire.com/" underline="always">
                        here
                      </Link>{" "}
                      and add {values.timberline_job} to the "Project code" to link to Fieldwire.
                    </>
                  ) : (
                    <>You cannot link to Fieldwire until you have a job number</>
                  )}
                </Alert>
              )}
              <Box mb={2} />
              <Typography variant="h4" gutterBottom>
                Clearstory (Extracker)
              </Typography>
              {values.extracker_project_id ? (
                <>
                  <Box>
                    <Link
                      href={`https://app.extracker.com/projects/${values.extracker_project_id}/edit`}
                      target="_blank"
                    >
                      {`https://app.extracker.com/projects/${values.extracker_project_id}/edit`}{" "}
                      <FontAwesomeIcon icon={faExternalLink} />
                    </Link>
                  </Box>
                  {/* <Box>
                    <FinalSwitches
                      name="extracker_auto_create_scos"
                      data={{label: "Have Extracker auto create SCO's", value: true}}
                    />
                  </Box> */}
                </>
              ) : (
                <Alert severity="info">
                  {values.timberline_job ? (
                    <>
                      This project has not been linked to Clearstory. Go{" "}
                      <Link href="https://app.extracker.com/" underline="always">
                        here
                      </Link>{" "}
                      and add {values.timberline_job} to the "My Job Number" to link to Clearstory.
                    </>
                  ) : (
                    <>You cannot link to Clearstory until you have a job number</>
                  )}
                </Alert>
              )}
              {/* <pre>{JSON.stringify(planGridProjectOptions, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </FinalFormKeyboardShortcuts>
            <Box mb={2} />
            <FormActions.SaveButton disabled={submitting || pristine} />
          </form>
        )}
      </FinalForm>
    </>
  );
};
