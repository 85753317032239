import {Box, Grid, Typography} from "@material-ui/core";
import {FieldArray} from "react-final-form-arrays";
import {CheckboxMui, NumberFieldMui, PercentFieldMui, TextFieldMui} from "../../../../components/forms/Fields";

const MarkupsFields = (props) => {
  const {namespace, values} = props;

  return (
    <>
      <Box my={2}>
        <Typography variant="h4">Project Markups</Typography>
      </Box>
      <FieldArray name={namespace}>
        {({fields}) => (
          <Grid container spacing={2}>
            {fields.map((markup, index) => {
              return <MarkupFieldItem markup={markup} autoFocus={index === fields.length - 1} key={index} />;
            })}
          </Grid>
        )}
      </FieldArray>
    </>
  );
};

export default MarkupsFields;

const MarkupFieldItem = (props) => {
  const {markup, autoFocus} = props;
  return (
    <Grid item xs={12} container spacing={2}>
      <Grid item xs={12} sm={2}>
        <NumberFieldMui
          name={`${markup}.budget_code`}
          label="Budget Code"
          thousandSeparator={false}
          autoFocus={autoFocus}
        />
      </Grid>
      <Grid item xs={12} sm={5}>
        <TextFieldMui name={`${markup}.description`} label="Description" />
      </Grid>
      <Grid item xs={12} sm={2}>
        <PercentFieldMui name={`${markup}.rate`} label="Markup Rate" decimalScale={3} />
      </Grid>
      <Grid item xs={12} sm={2}>
        <CheckboxMui name={`${markup}.compound`} label="Compound" />
      </Grid>
    </Grid>
  );
};

//{budget_code: "", description: "", rate: 0, compound: false}
