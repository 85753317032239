import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  makeStyles,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import classNames from "classnames";
import {Radios} from "mui-rff";
import {Field} from "react-final-form";
import {identity} from "../../../../js/components/FinalFormEnhancers";
import {colorError} from "../../../theme/colors";

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    errorLabel: {
      color: colorError,
    },
  };
});

export const RadioGroupMui = (props: {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  options: {
    value: string | boolean;
    label: string | React.ReactElement;
  }[];
  row?: boolean;
  fieldProps?: {};
}) => {
  const {name, disabled = false, options, label, row = true, fieldProps = {}, ...rest} = props;

  return (
    <Radios
      name={name}
      data={options}
      radioGroupProps={{row: row}}
      disabled={disabled}
      label={label}
      required={props.required}
      fieldProps={{
        parse: identity,
        validate: (value) => {
          if (props.required) {
            return value ? undefined : "Required";
          }
        },
        ...fieldProps,
      }}
      {...rest}
    />
  );
};

export const YesNoRadios = (props: {
  name: string;
  label: string;
  yesLabel?: string;
  noLabel?: string;
  fieldProps?: {};
  required?: boolean;
  disabled?: boolean;
}) => {
  const {name, label, yesLabel = "Yes", noLabel = "No", fieldProps, disabled = false, ...rest} = props;

  return (
    <RadioGroupMui
      name={name}
      label={label}
      disabled={disabled}
      options={[
        {value: true, label: yesLabel},
        {value: false, label: noLabel},
      ]}
      fieldProps={{
        parse: (value, name) => (value === "true" ? true : value === "false" ? false : value),
        validate: (value) => {
          if (props.required) {
            return (value === undefined || ![true, false].includes(value)) && "Required";
          }
        },
        ...fieldProps,
      }}
      {...rest}
    />
  );
};

export const YesNoNARadios = (props: {
  name: string;
  label?: string;
  disabled?: boolean;
  required?: boolean;
  onChange?: (event, value) => void;
}) => {
  const {name, disabled = false, label, required, onChange: _onChange, ...rest} = props;
  const classes = useStyles();
  return (
    <Field name={name} validate={(value) => (value ? undefined : required ? "Required" : undefined)}>
      {(fieldProps) => {
        const {input, meta} = fieldProps;
        const errorClasses = classNames(meta.error && meta.invalid && meta.touched && classes.errorLabel);
        return (
          <FormControl disabled={disabled} error={meta.invalid}>
            <FormLabel error={meta.invalid && meta.touched}>{label}</FormLabel>
            <RadioGroup
              {...input}
              row
              name={name}
              value={input.value}
              onChange={(event, value) => {
                if (_onChange) {
                  _onChange(event, value);
                }
                input.onChange(event);
              }}
              className={errorClasses}
            >
              <FormControlLabel
                value="yes"
                control={<Radio className={errorClasses} />}
                label="Yes"
                className={errorClasses}
              />
              <FormControlLabel
                value="no"
                control={<Radio className={errorClasses} />}
                label="No"
                className={errorClasses}
              />
              <FormControlLabel
                value="na"
                control={<Radio className={errorClasses} />}
                label="N/A"
                className={errorClasses}
              />
            </RadioGroup>
            {meta.invalid && meta.touched && <FormHelperText id="my-helper-text">{meta.error}</FormHelperText>}
          </FormControl>
        );
      }}
    </Field>
  );
};
