import {Box, Grid, Typography} from "@material-ui/core";
import axios from "axios";
import {sum} from "lodash";
import React from "react";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {
  DatePickerMui,
  DateTimePickerMui,
  NumberFieldMui,
  PercentFieldMui,
  RadioGroupMui,
  TextFieldMui,
  YesNoRadios,
} from "../../../components/forms/Fields";
import useBlockUI from "../../../hooks/useBlockUI";
import JobsiteSafetyForm from "./JobsiteFormComponents";
import useBuildField from "./useBuildField";
import moment from "moment";

export const CraneLiftEvaluation = (props) => {
  const [showPretaskPlanDialog, setShowPretaskPlanDialog] = React.useState(false);
  const blockUI = useBlockUI();

  const {safetyForm, internalDetailView} = props;
  const isSpanish = !internalDetailView && safetyForm?.twilio_contact?.language === "SP";
  const isReadOnly = internalDetailView || safetyForm.is_closed;

  // const buildField = (
  //   namespace: string,
  //   field: {labelES: string; labelEN: string; name: string; Component: any; required?: boolean}
  // ) => {
  //   const {labelES, labelEN, name, Component, required = true, ...rest} = field;
  //   return (
  //     <>
  //       <Component name={`${namespace}.${name}`} label={isSpanish ? labelES : labelEN} required={required} {...rest} />
  //     </>
  //   );
  // };
  const buildField = useBuildField(isSpanish, isReadOnly);

  const generalFields = [
    {Component: DatePickerMui, name: "date", labelEN: "Date", labelES: "Fecha"},
    {
      Component: TextFieldMui,
      labelES: "Cliente",
      labelEN: "Customer",
      name: "customer",
    },
    {
      Component: TextFieldMui,
      labelES: "Ubicaciones de elevación",
      labelEN: "Lift Locations",
      name: "lift_locations",
    },
    {
      Component: DateTimePickerMui,
      labelES: "Fecha/Hora",
      labelEN: "Date/Time",
      name: "date_time",
    },
    {
      Component: TextFieldMui,
      labelES: "Descripción de los elementos a elevar",
      labelEN: "Description of item(s) to be lifted",
      name: "item_description",
    },
    {
      Component: TextFieldMui,
      labelES: "Modelo/Fabricante/Tipo de grúa",
      labelEN: "Crane Model / Manufacture / Type",
      name: "crane_details",
    },
    {
      Component: TextFieldMui,
      labelES: "Configuración",
      labelEN: "Configuration",
      name: "configuration",
      helperText: isSpanish
        ? "Pluma / Ángulo / Jib fijo / Extensión / etc."
        : "Boom / Angle / Fixed Jib / Extension / etc.",
    },
  ];
  const craneOperationFields = [
    {
      Component: TextFieldMui,
      labelES: "Funcionalidad de la grúa",
      labelEN: "Crane functionality",
      name: "crane_functionality",
    },
    {
      Component: TextFieldMui,
      labelES: "Capacidad/Condición del suelo",
      labelEN: "Soil Capacity / Condition",
      name: "soil_capacity_condition",
    },
    {
      Component: YesNoRadios,
      labelES: "Mats Requeridos bajo la pista / Pluma extendida",
      labelEN: "Mats Required under Track / Outrigger",
      name: "mats_required",
    },
    {
      Component: TextFieldMui,
      labelES: "Tamaño / Número",
      labelEN: "Size/ Number",
      name: "size_number",
    },
    {
      Component: TextFieldMui,
      labelES: "Tagline / Softeners",
      labelEN: "Tagline / Softeners",
      name: "tagline_softeners",
    },
    {
      Component: TextFieldMui,
      labelES: "Velocidad del viento",
      labelEN: "Wind Speed",
      name: "wind_speed",
    },
  ];
  const hazardFields = [
    {
      Component: TextFieldMui,
      labelES: "Sobre cabeza",
      labelEN: "Overhead",
      name: "overhead",
    },
    {
      Component: TextFieldMui,
      labelES: "Subterráneo",
      labelEN: "Underground",
      name: "underground",
    },
    {
      Component: TextFieldMui,
      labelES: "Eléctrico",
      labelEN: "Electrical",
      name: "electrical",
    },
    {
      Component: TextFieldMui,
      labelES: "Obstáculos / Obstrucciones",
      labelEN: "Obstacles / Obstructions",
      name: "obstacles",
    },
    {
      Component: TextFieldMui,
      labelES: "Otro",
      labelEN: "Other",
      name: "other",
    },
  ];
  const riggingFields = [
    {
      Component: TextFieldMui,

      labelES: "Condición",
      labelEN: "Condition",
      name: "condition",
    },
    {
      Component: TextFieldMui,

      labelES: "Arreglo",
      labelEN: "Arrangement",
      name: "arrangement",
    },
    {
      Component: TextFieldMui,

      labelES: "Número",
      labelEN: "Number",
      name: "number",
    },
    {
      Component: TextFieldMui,

      labelES: "Longitud",
      labelEN: "Length",
      name: "length",
    },
    {
      Component: TextFieldMui,

      labelES: "Capacidad",
      labelEN: "Capacity",
      name: "capacity",
    },
  ];
  const shacklesFields = [
    {
      Component: TextFieldMui,

      labelES: "Número",
      labelEN: "Number",
      name: "number",
    },
    {
      Component: TextFieldMui,

      labelES: "Capacidad",
      labelEN: "Capacity",
      name: "capacity",
    },
    {
      Component: TextFieldMui,

      labelES: "Cómo se adjunta a la carga",
      labelEN: "How attached to Load",
      name: "attachment",
    },
  ];
  const additionalRiggingFields = [
    {
      Component: TextFieldMui,

      labelES: "Longitud de los separadores",
      labelEN: "Spreaders Length",
      name: "spreaders_length",
    },
    {
      Component: TextFieldMui,

      labelES: "Capacidad de la viga de elevación",
      labelEN: "Lifting Beam Capacity",
      name: "lifting_beam_capacity",
    },
    {
      Component: TextFieldMui,
      //
      labelES: "Otra capacidad",
      labelEN: "Other Capacity",
      name: "other_capacity",
      helperText: isSpanish ? "(caída de cadena, acompañamiento, etc.)" : "(Chain Fall, Come-Along, etc.)",
    },
  ];
  const weightOfObjectFields = [
    {
      Component: TextFieldMui,
      helperText: isSpanish
        ? "Báscula / Manifiesto de envío / Calculado / Fabricante"
        : "Scale / Shipping Manifest / Calculated / Manufacture",
      labelES: "Cómo se obtuvo el peso",
      labelEN: "How weight was obtained",
      name: "weight_source",
    },

    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Peso total del objeto",
      labelEN: "Total Object Weight",
      name: "total_object_weight",
    },
  ];
  const otherWeightFields = [
    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Pluma / Pluma",
      labelEN: "Jib / Boom",
      name: "jib_boom_weight",
      required: false,
    },
    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Bola de dolor de cabeza",
      labelEN: "Headache Ball",
      name: "headache_ball_weight",
      required: false,
    },
    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Bloque principal",
      labelEN: "Main Block",
      name: "main_block_weight",
      required: false,
    },
    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Cable",
      labelEN: "Cable",
      name: "cable_weight",
      required: false,
    },
    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Cable de látigo",
      labelEN: "Whip Line",
      name: "whip_line_weight",
      required: false,
    },
    // {
    //   Component: NumberFieldMui,
    //   helperText: isSpanish ? "Peso en libras" : "Weight in LBS",
    //   labelES: "Peso total de otros elementos",
    //   labelEN: "Total Other Weight",
    //   name: "total_other_weight",
    // },
  ];
  const riggingWeightFields = [
    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Eslingas",
      labelEN: "Slings",
      name: "slings_weight",
      required: false,
    },
    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Grilletes",
      labelEN: "Shackles",
      name: "shackles_weight",
      required: false,
    },
    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Separadores",
      labelEN: "Spreaders",
      name: "spreaders_weight",
      required: false,
    },
    {
      Component: NumberFieldMui,
      helperText: isSpanish ? "Peso en toneladas" : "Weight in Tons",
      labelES: "Otro",
      labelEN: "Other",
      name: "other_rigging_weight",
      required: false,
    },
    // {
    //   Component: NumberFieldMui,
    //   helperText: isSpanish ? "Peso en libras" : "Weight in LBS",
    //   labelES: "Peso total del aparejo",
    //   labelEN: "Total Rigging Weight",
    //   name: "total_rigging_weight",
    // },
  ];

  const contactsFields = [
    {
      Component: TextFieldMui,
      labelES: "Operador de grúa",
      labelEN: "Crane Operator",
      name: "crane_operator",
    },
    {
      Component: TextFieldMui,
      labelES: "Rigging",
      labelEN: "Rigger",
      name: "rigger",
    },
    {
      Component: TextFieldMui,
      labelES: "Gerente autorizado por el cliente",
      labelEN: "Customer Authorized Manager",
      name: "customer_authorized_manager",
    },
  ];

  const calculateTotalValues = (fields) => {
    return sum(Object.values(fields || {}));
  };

  return (
    <>
      <JobsiteSafetyForm
        //   isSpanish={isSpanish}
        initialValues={{
          general: {date: moment()},
          contacts: {crane_operator: safetyForm?.twilio_contact?.name.trim()},
        }}
        title={isSpanish ? "Formulario de evaluación de elevación de grúa" : "Crane Lift Evaluation Form"}
        onSuccess={(response) => {
          setShowPretaskPlanDialog(true);
        }}
        {...props}
      >
        {(form, values) => {
          return (
            <>
              <Grid container spacing={2}>
                {generalFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("general", field)}
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {isSpanish ? `Equipo de protección personal:` : `Crane Operation / Placement`}
                    </Typography>
                  </Box>
                </Grid>

                {craneOperationFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("crane_operation", field)}
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">{isSpanish ? `Equipo de protección personal:` : `Hazards`}</Typography>
                  </Box>
                </Grid>

                {hazardFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("hazards", field)}
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {isSpanish ? `Equipo de protección personal:` : `Rigging Slings`}
                    </Typography>
                  </Box>
                </Grid>

                {riggingFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("rigging", field)}
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">{isSpanish ? `Equipo de protección personal:` : `Shackles`}</Typography>
                  </Box>
                </Grid>

                {shacklesFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("shackles", field)}
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {isSpanish ? `Equipo de protección personal:` : `Additional Rigging`}
                    </Typography>
                  </Box>
                </Grid>

                {additionalRiggingFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("additional_rigging", field)}
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {isSpanish ? `Equipo de protección personal:` : `Weight Of Object`}
                    </Typography>
                  </Box>
                </Grid>

                {weightOfObjectFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("weight_of_object", field)}
                  </Grid>
                ))}

                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {isSpanish ? `Equipo de protección personal:` : `Rigging Weight`}
                    </Typography>
                  </Box>
                </Grid>

                {riggingWeightFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("rigging_weight", field)}
                  </Grid>
                ))}
                <Grid item xs={12} sm={4}>
                  <NumberFieldMui
                    name="total_rigging_weight"
                    label="Total Rigging Weight"
                    value={calculateTotalValues(values.rigging_weight)}
                    disabled
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">
                      {isSpanish ? `Equipo de protección personal:` : `Other Weights`}
                    </Typography>
                  </Box>
                </Grid>

                {otherWeightFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("other_weight", field)}
                  </Grid>
                ))}

                <Grid item xs={12} sm={4}>
                  <NumberFieldMui
                    name="total_other_weight"
                    label="Total other Weight"
                    value={calculateTotalValues(values.other_weight)}
                    disabled
                  />
                </Grid>

                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">{isSpanish ? `Cálculos de peso` : `Weight Calculations`}</Typography>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={4}>
                  <NumberFieldMui
                    name="totals.total_weight"
                    label={isSpanish ? "Peso total" : "Total Weight"}
                    value={sum([
                      values.total_object_weight,
                      values.total_rigging_weight,
                      values.total_other_weight,
                      values?.weight_of_object?.total_object_weight,
                    ])}
                    disabled
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <TextFieldMui
                    name="totals.maximum_radius"
                    label={isSpanish ? "Radio máximo" : "Maximum Radius"}
                    helperText={isSpanish ? "(Recoger / Girar / Colocar)" : "(Pick / Swing / Set)"}
                    required
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <NumberFieldMui
                    name="totals.crane_capacity"
                    label={isSpanish ? "Capacidad de la grúa" : "Crane Capacity"}
                    helperText={isSpanish ? "En radio máximo" : "At Maximum Radius"}
                    required
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <PercentFieldMui
                    name="totals.total_weight_percentage"
                    label={isSpanish ? "Peso total %" : "Total Weight %"}
                    helperText={isSpanish ? "(De capacidad de grúa)" : "(Of Crane Capacity)"}
                    disabled
                    value={
                      (values.totals?.crane_capacity ? values.totals.total_weight / values.totals.crane_capacity : 0) *
                      100
                    }
                    decimalScale={2}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">{isSpanish ? `Tipo de elevación` : `Lift Type`}</Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <RadioGroupMui
                    label={""}
                    name="lift_type"
                    options={[
                      {
                        label: isSpanish ? (
                          <>
                            <strong>Elevación no estándar</strong>
                            <div>
                              Peso total {">"}10 toneladas y {"<"}80% de capacidad
                            </div>
                            <div>Requiere la firma de:</div>
                            <ul>
                              <li>Operador</li>
                              <li>Capataz de aparejos</li>
                              <li>Superintendente</li>
                              <li>Seguridad</li>
                              <li>Cliente - Administrador autorizado</li>
                            </ul>
                          </>
                        ) : (
                          <>
                            <strong>Non-Standard Lift</strong>
                            <div>
                              Total weight {">"}10 tons & {"<"}80% capacity
                            </div>
                            <div>Requires signature from:</div>
                            <ul>
                              <li>Operator</li>
                              <li>Rigging Foreman</li>
                              <li>Superintendent</li>
                              <li>Safety</li>
                              <li>Client - Authorized Manager</li>
                            </ul>
                          </>
                        ),
                        value: "non_standard",
                      },
                      {
                        label: isSpanish ? (
                          <>
                            <strong>Elevación crítica</strong>
                            <div>Peso total {">"}Se requieren 15 toneladas o 90 % de capacidad o dos o más grúas.</div>
                            <div>Requiere la firma de:</div>
                            <ul>
                              <li>Operador</li>
                              <li>Capataz de aparejos</li>
                              <li>Superintendente</li>
                              <li>NOVO - Gerente de Proyecto</li>
                              <li>NOVO - Director de Seguridad</li>
                            </ul>
                          </>
                        ) : (
                          <>
                            <strong>Critical Lift</strong>
                            <div>Total Weight {">"}15t or 90% capacity or two or more cranes required.</div>
                            <div>Requires signature from:</div>
                            <ul>
                              <li>Operator</li>
                              <li>Rigging Foreman</li>
                              <li>Superintendent</li>
                              <li>NOVO - Project Manager</li>
                              <li>NOVO - Safety Director</li>
                            </ul>
                          </>
                        ),
                        value: "critical",
                      },
                    ]}
                    disabled={isReadOnly}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Box textAlign="center">
                    <Typography variant="h6">{isSpanish ? `Contactos` : `Contacts`}</Typography>
                  </Box>
                </Grid>
                {contactsFields.map((field) => (
                  <Grid item xs={12} sm={4} key={field.name}>
                    {buildField("contacts", field)}
                  </Grid>
                ))}
              </Grid>
            </>
          );
        }}
      </JobsiteSafetyForm>
      <ConfirmationDialog
        isOpen={showPretaskPlanDialog}
        onDeny={() => {
          setShowPretaskPlanDialog(false);
          window.location.href = `/projects/${safetyForm.project.uuid}/twilio-contact/${safetyForm.twilioContact.uuid}/safety/permits/`;
        }}
        onApprove={() => {
          blockUI.blockUI(isSpanish ? "Creando..." : "Creating...");
          setShowPretaskPlanDialog(false);

          axios
            .post(
              `/projects/${safetyForm.project.uuid}/twilio-contact/${safetyForm.twilioContact.uuid}/safety/permits/`,
              {
                form_type: "crane_pretask_plan",
              }
            )
            .then(({data}) => (window.location.href = data.redirect));
        }}
        title={isSpanish ? `Contacts` : `Create Pretask Plan?`}
        approveLabel={isSpanish ? "Si" : "Yes"}
        denyLabel="No"
      >
        {isSpanish
          ? "¿Le gustaría crear un plan de tareas previas para la grúa en este momento?"
          : "Would you like to create a Crane Pretask Plan at this time?"}
      </ConfirmationDialog>
    </>
  );
};
