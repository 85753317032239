import {faCircle} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, LinearProgress, Tooltip} from "@material-ui/core";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import ActiveFieldsFormSpy from "../../../components/forms/ActiveFieldsFormSpy";
import PaperPanel from "../../../components/PaperPanel";
import Typography2 from "../../../components/Typography2";
import {colorError, colorSuccess, colorWarning} from "../../../theme/colors";

export const ReviewForm = (props) => {
  const {
    onSubmit,
    projectId,
    data,
    section,
    children,
    namespace,
    fieldProps,
    icon,
    onRefresh,
    isFetching,
    setSectionErrors,
    ...rest
  } = props;

  return (
    <>
      <FinalForm onSubmit={onSubmit} initialValues={data} {...rest}>
        {(props) => {
          const {handleSubmit, dirty, pristine, hasValidationErrors, errors, values, form} = props;
          React.useEffect(() => {
            if (hasValidationErrors) {
              setSectionErrors({type: "addSection", data: section});
            } else {
              setSectionErrors({type: "removeSection", data: section});
            }
          }, [hasValidationErrors]);
          return (
            <>
              <SectionHeader mx={3}>
                <Box>
                  {icon && <FontAwesomeIcon icon={icon} />} {section}
                </Box>
                <Box display="flex" flexWrap="noWrap" alignItems="center">
                  {onRefresh && (
                    <Tooltip title="Refresh Contractor List">
                      <Box mr={1}>
                        <PaperPanel.Header.RefreshButton onClick={onRefresh} isFetching={isFetching} />
                      </Box>
                    </Tooltip>
                  )}
                  <Box>
                    <Tooltip
                      title={
                        hasValidationErrors ? (
                          <>
                            <div>Please Correct All Errors</div>
                            <div>(click to show)</div>
                          </>
                        ) : dirty ? (
                          "Unsaved Changes"
                        ) : (
                          "Up To Date"
                        )
                      }
                    >
                      <Box
                        onClick={() => {
                          handleSubmit();
                        }}
                        style={{cursor: "pointer"}}
                      >
                        {hasValidationErrors && !pristine && (
                          <Typography2 type="metadata">(Unsaved Changes!) </Typography2>
                        )}
                        <FontAwesomeIcon
                          icon={faCircle}
                          color={hasValidationErrors ? colorError : dirty ? colorWarning : colorSuccess}
                        />
                      </Box>
                    </Tooltip>
                  </Box>
                </Box>
              </SectionHeader>
              <PaperPanel.Body p={2}>
                <BeforeUnload
                  block={!pristine && hasValidationErrors}
                  message={`${section} has errors preventing saving. Do you wish to close? (changes will be lost)`}
                />

                <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <Grid container spacing={2}>
                      {/* {children} */}
                      {children({values: values, form: form})}

                      <ActiveFieldsFormSpy
                        onFocusChange={({active}) => {
                          if (active === undefined && !pristine && !hasValidationErrors) {
                            handleSubmit();
                          }
                        }}
                      />
                    </Grid>
                  </FinalFormKeyboardShortcuts>
                </form>
                {/* <pre>{JSON.stringify(data, null, 2)}</pre> */}
              </PaperPanel.Body>
            </>
          );
        }}
      </FinalForm>
    </>
  );
};

export const SectionHeader = (props: {isLoading?: boolean; children: React.ReactNode; [rest: string]: any}) => {
  const {children, isLoading, ...rest} = props;
  return (
    <Box mb={2} mx={-3} mt={1}>
      <Box
        display="flex"
        py={0.5}
        px={1}
        bgcolor="grey.100"
        border={1}
        borderColor="grey.400"
        borderLeft={0}
        borderRight={0}
        alignItems="center"
        justifyContent="space-between"
        minHeight={38}
        {...rest}
      >
        {children}
      </Box>
      {isLoading && <LinearProgress color="secondary" />}
    </Box>
  );
};
