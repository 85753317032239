import {Avatar, Chip, Tooltip} from "@material-ui/core";

export const ContactChip = (props) => {
  const {contact, displayAvatar, active, ...rest} = props;
  if (!contact) return null;
  return (
    <Tooltip title={contact.email}>
      <span>
        <Chip
          size="small"
          variant="outlined"
          label={
            <>
              {contact.full_name} - <span style={{fontSize: "0.8em"}}>{contact.company_name}</span>
            </>
          }
          style={{margin: "4px"}}
          color={active ? "primary" : "default"}
          avatar={
            displayAvatar ? (
              <Avatar alt={contact.full_name} src={contact.avatar_url ? `${contact.avatar_url}?d=mm` : null} />
            ) : null
          }
          {...rest}
        />
      </span>
    </Tooltip>
  );
};

export const ListChip = (props) => {
  const {label, ...rest} = props;
  return <Chip size="small" variant="outlined" label={label} style={{margin: "2px"}} />;
};
