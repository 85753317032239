import {faLock} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Avatar, Box, Chip, Tooltip, Typography} from "@material-ui/core";
import {Autocomplete as FinalAutocomplete} from "mui-rff";
import qs from "query-string";
import React from "react";
import {useField} from "react-final-form";
import {useQuery} from "react-query";
import {useDebounce} from "use-debounce";
import {useAutocompleteStyles} from ".";
import {identity} from "../../../../js/components/FinalFormEnhancers";
import {axiosAPI} from "../../../api";
import {ProjectStatusIcon} from "../../Icons";
import Typography2 from "../../Typography2";

export const SimpleAutoselectMui = (props: {
  options: {value: string | number; label: string}[];
  label: string;
  name: string;
  valueProp?: string;
  labelProp?: string;
  fieldProps?: any;
  // All other props
  [rest: string]: any;
}) => {
  const {
    options,
    label,
    name,
    valueProp = "value",
    labelProp = "label",
    validateFields = [],
    fieldProps,
    ...rest
  } = props;
  const {input, meta} = useField(name);

  return (
    <FinalAutocomplete
      options={options}
      label={label}
      name={name}
      size="small"
      getOptionValue={(option) => option[valueProp]}
      getOptionLabel={(option) => option[labelProp]}
      disableClearable={props.required}
      textFieldProps={{
        variant: "outlined",
        autoFocus: props.autoFocus,
      }}
      // onChange={(event, value, reason) => {
      //   onChange ? onChange(event, value, reason) : null;
      //   input.onChange(value[valueProp]);
      // }}
      fieldProps={{
        parse: identity,
        validate: (value) => {
          if (props.required && props.multiple) {
            return value.length === 0 ? "Required" : undefined;
          }
          if (props.required) {
            return value ? undefined : "Required";
          }
        },
        validateFields: validateFields,
        ...fieldProps,
      }}
      {...rest}
    />
  );
};

export const AutoselectMui = (props: {
  label: string;
  name: string;
  options: string[] | any[];
  onChange?: (event: any, value: any, reason: string) => void;
  fieldProps?: any;
  // All other props
  [rest: string]: any;
}) => {
  const {
    options,
    label,
    name,
    valueProp = "value",
    labelProp = "label",
    multiple = false,
    filterSelectedOptions = props.filterSelectedOptions || props.multiple || false,
    onChange,
    validateFields = [],
    fieldProps,
    ...rest
  } = props;
  const {input, meta} = useField(name);

  return (
    <FinalAutocomplete
      classes={useAutocompleteStyles()}
      size="small"
      options={options}
      filterSelectedOptions={filterSelectedOptions}
      getOptionLabel={(option) => {
        return option ? option[labelProp] || "" : "";
      }}
      getOptionSelected={(option, value) => {
        return option[valueProp] === value[valueProp];
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return (
            <Chip
              size="small"
              variant="outlined"
              // color="secondary"
              label={<>{option[labelProp]}</>}
              {...getTagProps({index})}
            />
          );
        })
      }
      // onInputChange={(event, value, reason) => {
      //   if (reason === "input") {
      //     input.onChange(value);
      //   }
      // }}
      onChange={(event, value, reason) => {
        onChange ? onChange(event, value, reason) : null;
        input.onChange(value);
      }}
      label={label}
      name={name}
      multiple={multiple}
      textFieldProps={{
        autoFocus: props.autoFocus,
        variant: "outlined",
      }}
      fieldProps={{
        parse: identity,
        validate: (value) => {
          if (props.required && props.multiple) {
            return value.length === 0 ? "Required" : undefined;
          }
          if (props.required) {
            return value ? undefined : "Required";
          }
        },
        validateFields: validateFields,
        ...fieldProps,
      }}
      renderOption={(option: any) => <>{option[labelProp]}</>}
      // inputValue={multiple ? undefined : input.value}
      disableClearable={props.required}
      {...rest}
    />
  );
};

export const ApiAutoselectMui = (props: {
  label: string;
  name: string;
  baseURL: string;
  objProp?: string;
  queryProp?: string;
  optionsProp?: string;
  qsParams?: any;
  queryParams?: any;
  minCharacters?: any;
  filterSelectedOptions?: boolean;
  onChange?: (event: any, value: any, reason: string) => void;
  fieldProps?: any;
  // All other props
  [rest: string]: any;
}) => {
  const {
    name,
    label,
    baseURL,
    qsParams = {},
    queryParams = {},
    valueProp = "id",
    labelProp = "display",
    queryProp = "q",
    optionsProp = "results",
    minCharacters = 3,
    filterSelectedOptions = props.filterSelectedOptions || props.multiple || false,
    onChange,
    validateFields = [],
    fieldProps,
    ...rest
  } = props;
  const [q, setQ] = React.useState("");
  const [debouncedQ] = useDebounce(q, 300);
  const {input, meta} = useField(name);

  qsParams[queryProp] = debouncedQ;
  const queryString = qs.stringify(qsParams);
  const shouldQuery = debouncedQ.length >= minCharacters;

  const query = useQuery(
    ["api autocomplete", baseURL, qsParams, debouncedQ],
    () => {
      return axiosAPI.get(`${baseURL}?${queryString}`).then((res) => res.data[optionsProp]);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
      enabled: shouldQuery,
      ...queryParams,
    }
  );
  const {data: options = [], isFetching} = query;

  return (
    <FinalAutocomplete
      {...input}
      classes={useAutocompleteStyles()}
      size="small"
      label={label}
      name={name}
      options={options}
      loading={isFetching}
      textFieldProps={{
        autoFocus: props.autoFocus,
        variant: "outlined",
      }}
      filterOptions={(options) => options} // Turn off auto filtering
      filterSelectedOptions={filterSelectedOptions}
      getOptionLabel={(option) => {
        return option ? option[labelProp] || "" : "";
      }}
      getOptionSelected={(option, value) => {
        return option[valueProp] === value[valueProp];
      }}
      onInputChange={(event, value, reason) => {
        // setQ(value);
        // remove "-"" so that search works on selected items (Name - Company)
        setQ(value.replace("-", " "));
      }}
      onChange={(event, value, reason) => {
        onChange ? onChange(event, value, reason) : null;
        // input.onChange(value);
      }}
      fieldProps={{
        parse: identity,
        validate: (value) => {
          if (props.required && props.multiple) {
            return value.length === 0 ? "Required" : undefined;
          }
          if (props.required) {
            return value ? undefined : "Required";
          }
        },
        validateFields: validateFields,
        ...fieldProps,
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          return <Chip size="small" variant="outlined" label={<>{option[labelProp]}</>} {...getTagProps({index})} />;
        })
      }
      renderOption={(option: any) => {
        return <>{option[labelProp]}</>;
      }}
      noOptionsText={
        query.isFetching
          ? "Searching..."
          : shouldQuery
          ? `No matches found for ${debouncedQ}`
          : `Enter at least ${minCharacters} characters...`
      }
      disableClearable={props.required}
      {...rest}
    />
  );
};

export const ApiNoSearchAutoselectMui = (props: {
  label: string;
  name: string;
  baseURL: string;
  objProp?: string;
  optionsProp?: string;
  qsParams?: any;
  queryParams?: any;
  filterSelectedOptions?: boolean;
  onChange?: (event: any, value: any, reason: string) => void;
  // All other props
  [rest: string]: any;
}) => {
  const {name, label, baseURL, qsParams = {}, queryParams = {}, optionsProp = "results", ...rest} = props;

  const queryString = qs.stringify(qsParams);

  const query = useQuery(
    ["api autocomplete no query", baseURL, qsParams],
    () => {
      return axiosAPI.get(`${baseURL}?${queryString}`).then((res) => res.data[optionsProp]);
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
      ...queryParams,
    }
  );
  const {data: options, isFetching} = query;

  return (
    <AutoselectMui
      classes={useAutocompleteStyles()}
      size="small"
      label={label}
      name={name}
      options={options}
      loading={isFetching}
      disableClearable={props.required}
      {...rest}
    />
  );
};

export const BonusAccountAutoselectMui = (props) => {
  return (
    <ApiAutoselectMui
      labelProp="description"
      baseURL="bonuses/accounts/"
      minCharacters={0}
      qsParams={{
        status: "active",
      }}
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.description}</div>
          </>
        );
      }}
      {...props}
    />
  );
};

export const CompanyAutoselectMui = (props) => {
  return (
    <ApiAutoselectMui
      labelProp="name"
      baseURL="companies/search/"
      qsParams={{
        is_active: "True",
      }}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            size="small"
            variant="outlined"
            // color="secondary"
            label={<>{option.name}</>}
            {...getTagProps({index})}
          />
        ))
      }
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.name}</div>
            <div>
              <Typography2 type="metadata">{`${option.lookup_code}`}</Typography2>
            </div>
          </>
        );
      }}
      {...props}
    />
  );
};

export const EmployeeAutoselectMui = (props) => {
  return (
    <ApiAutoselectMui
      labelProp="full_name"
      baseURL="employees/"
      qsParams={{
        is_active: "True",
      }}
      {...props}
    />
  );
};

export const ProjectAutoselectMui = (props) => {
  const {projectId, ...rest} = props;
  return (
    <ApiAutoselectMui
      baseURL={`projects/search/`}
      qsParams={{
        ordering: "status_sort",
        page_size: 5,
      }}
      minCharacters={3}
      getOptionLabel={(option: any) => (option ? `${option.display}` : "")}
      renderOption={(option: any) => {
        return (
          <Box display="flex">
            <Box flexGrow={1}>
              <ProjectStatusIcon project={option} /> {option.display}
            </Box>

            {!option.user_has_access && (
              <Box textAlign="right" flexGrow={0}>
                <FontAwesomeIcon icon={faLock} />
              </Box>
            )}
          </Box>
        );
      }}
      {...rest}
    />
  );
};

export const ProjectBudgetAutoselectMui = (props) => {
  const {projectId, ...rest} = props;
  return (
    <ApiAutoselectMui
      baseURL={`projects/${projectId}/budgets/search/`}
      minCharacters={0}
      getOptionLabel={(option: any) => (option ? `${option.display} - ${option.allocation_display}` : "")}
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.display}</div>
            <div>
              <Typography2 type="metadata">{`${option.allocation_display}`}</Typography2>
            </div>
          </>
        );
      }}
      {...rest}
    />
  );
};

export const ProjectCompanyAutoselectMui = (props) => {
  const {projectId, ...rest} = props;
  return (
    <ApiAutoselectMui
      labelProp="name"
      baseURL={`projects/${projectId}/companies/search/`}
      minCharacters={0}
      qsParams={{
        is_active: "True",
      }}
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.name}</div>
            <div>
              <Typography2 type="metadata">{`${option.lookup_code}`}</Typography2>
            </div>
          </>
        );
      }}
      {...rest}
    />
  );
};

export const ProjectContactAutoselectMui = (props) => {
  const {projectId, companyId, avatar, ...rest} = props;
  return (
    <ApiAutoselectMui
      labelProp="full_name"
      baseURL={`projects/${projectId}/contacts/search/`}
      minCharacters={0}
      qsParams={{
        is_active: "True",
        company_id: companyId,
      }}
      getOptionLabel={(option) => (option ? `${option.full_name} - ${option.company?.name}` : "")}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip title={option.email} key={option.email}>
            <span>
              <Chip
                size="small"
                variant="outlined"
                // color="secondary"
                avatar={
                  avatar ? (
                    <Avatar alt={option.full_name} src={option.avatar_url ? `${option.avatar_url}?d=mm` : null} />
                  ) : null
                }
                label={
                  <>
                    {option.full_name} - <span style={{fontSize: "0.8em"}}>{option.company?.name}</span>
                  </>
                }
                {...getTagProps({index})}
              />
            </span>
          </Tooltip>
        ))
      }
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.full_name}</div>
            <div>
              <Typography color="textSecondary" style={{fontSize: "0.8em"}}>
                {option.company?.name}
              </Typography>
            </div>
          </>
        );
      }}
      {...rest}
    />
  );
};

export const NovoRestrictedSignersAutoselectMui = (props) => {
  const {projectId, avatar, ...rest} = props;
  return (
    <ApiAutoselectMui
      labelProp="full_name"
      baseURL={`projects/${projectId}/contacts/novo-signers/`}
      minCharacters={0}
      qsParams={{
        is_active: "True",
      }}
      getOptionLabel={(option) => (option ? `${option.full_name} - ${option.company?.name}` : "")}
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.full_name}</div>
            <div>
              <Typography color="textSecondary" style={{fontSize: "0.8em"}}>
                {option.company?.name}
              </Typography>
            </div>
          </>
        );
      }}
      helperText="Restricted to only allow signers PM and above."
      {...rest}
    />
  );
};

export const ProjectSubmittalPackagesAutoselectMui = (props) => {
  const {projectId, disablePriorRevisions = true, ...rest} = props;
  return (
    <ApiAutoselectMui
      baseURL={`projects/${projectId}/submittals/packages/`}
      qsParams={{ordering: "number"}}
      minCharacters={0}
      getOptionDisabled={(option) => {
        if (!disablePriorRevisions) {
          return false;
        }
        // console.log("option", option);
        return Boolean(option.parent);
      }}
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.display}</div>
            {/* <div>
              <Typography2 type="metadata">{`${option.display}`}</Typography2>
            </div> */}
          </>
        );
      }}
      {...rest}
    />
  );
};

export const CompanyContactAutoselectMui = (props) => {
  const {companyId, ...rest} = props;
  return (
    <ApiAutoselectMui
      labelProp="full_name"
      getOptionLabel={(option) =>
        option ? (option.title ? `${option.full_name} - ${option.title}` : option.full_name) : ""
      }
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.full_name}</div>
            <div>
              <Typography color="textSecondary" style={{fontSize: "0.8em"}}>
                {option.title}
              </Typography>
            </div>
          </>
        );
      }}
      baseURL="contacts/"
      qsParams={{
        is_active: "True",
        company_id: companyId,
      }}
      minCharacters={0}
      {...rest}
    />
  );
};

export const TwilioContactAutoselectMui = (props) => {
  const {...rest} = props;
  return (
    <ApiAutoselectMui
      labelProp="name"
      baseURL={`twilio-contact/search/`}
      renderOption={(option) => (
        <Box mt={-1}>
          {option.name}
          <Box>
            <Typography color="textSecondary" style={{fontSize: "0.8em"}}>
              <span>{option?.company}</span>
            </Typography>
          </Box>
        </Box>
      )}
      {...rest}
    />
  );
};

export const ContactAutoselectMui = (props) => {
  const {...rest} = props;
  return (
    <ApiAutoselectMui
      labelProp="full_name"
      getOptionLabel={(option) =>
        option ? (option.company ? `${option.full_name} - ${option.company.name}` : option.full_name) : ""
      }
      renderOption={(option: any) => {
        return (
          <>
            <div>{option.full_name}</div>
            <div>
              <Typography color="textSecondary" style={{fontSize: "0.8em"}}>
                {option.company.name}
              </Typography>
            </div>
          </>
        );
      }}
      baseURL="contacts/"
      qsParams={{
        is_active: "True",
      }}
      minCharacters={3}
      {...rest}
    />
  );
};

export const CSICodeAutoselectMui = (props) => {
  const {projectId, ...rest} = props;
  return <ApiAutoselectMui baseURL={`csi-codes/search/`} {...rest} />;
};

export const ProjectGroupAutoselectMui = (props) => {
  const {projectId, ...rest} = props;
  return (
    <ApiNoSearchAutoselectMui valueProp="id" labelProp="name" baseURL={`projects/${projectId}/groups/`} {...rest} />
  );
};

export const TimberlineJobAutoselectMui = (props) => {
  return (
    <ApiAutoselectMui
      baseURL={`timberline/jobs/search/`}
      renderOption={(option: any) => (
        <>
          <ProjectStatusIcon project={option} showTooltip /> {option.display}
        </>
      )}
      {...props}
    />
  );
};

export const TimberlineVendorAutoselectMui = (props) => {
  return (
    <ApiAutoselectMui
      valueProp="vendor"
      labelProp="name"
      baseURL={`timberline/vendors/`}
      renderOption={(option: any) => (
        <>
          <div>{`${option.name} - ${option.vendor}`}</div>
        </>
      )}
      {...props}
    />
  );
};

export const UserAutoselectMui = (props) => {
  return (
    <ApiAutoselectMui
      labelProp="full_name"
      baseURL="users/search/"
      // qsParams={{
      //   is_active: "True",
      // }}
      {...props}
    />
  );
};
