import {Alert} from "@material-ui/lab";
import {useSentinelDetailAPI} from "../hooks/useSentinelAPI";

const Announcements = () => {
  const {query: announcementQuery} = useSentinelDetailAPI(["announcements"], {
    // initialData: [],
    staleTime: 1000 * 60 * 5,
  });
  const announcementData = announcementQuery.data || [];
  return (
    <>
      {announcementData.map((announcement, i) => {
        let severity = announcement.message_level;
        if (severity === "debug") severity = "info";
        return (
          <Alert severity={severity} key={i}>
            <span
              dangerouslySetInnerHTML={{
                __html: announcement.body,
              }}
            />
          </Alert>
        );
      })}
    </>
  );
};

export default Announcements;
