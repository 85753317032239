import {Box, Link, Typography} from "@material-ui/core";
import {Breadcrumbs} from "../../../components/Breadcrumbs";
import {useFetchCurrentPage, usePostCurrentPage} from "../../../hooks/useSentinelAPI";
import {Helmet} from "react-helmet";
import PaperPanel from "../../../components/PaperPanel";
import {makeNovoClasses} from "../../../theme";
import classnames from "classnames";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TableStatusLabel} from "../../../components/Status";
import {textToHTML} from "../../../../js/common/utils";
import HtmlRender from "../../../../js/components/HtmlRender";
import {LabelValue} from "../../../components/LabelValue";
import moment from "moment";
import {faFileExcel} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {saveExcel} from "../../../utils/excel";
import React from "react";

import {Alert, AlertTitle, Skeleton} from "@material-ui/lab";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {ConfirmationDialog} from "../../../components/Dialogs";

const CanCloseout = (props) => {
  const {...rest} = props;
  const [refreshConfirmationDialogIsOpen, setRefreshConfirmationDialogIsOpen] = React.useState(false);
  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      projects: [],
    },
  });
  const pageData = pageDataQuery.data;

  const postCurrentPage = usePostCurrentPage();

  const teamRoles = {
    project_executive: "PX:",
    senior_project_manager: "Sr. PM:",
    project_manager: "PM:",
    superintendent: "Super:",
    project_admin: "Admin:",
  };

  const buildRoleString = (team) => {
    const roles = Object.keys(teamRoles)
      .map((role) => {
        if (team[role]) {
          return `${teamRoles[role]} ${team[role]}`;
        }
      })
      .filter((role) => role);
    return roles.join("  ");
  };

  return (
    <>
      <Helmet title={`Projects that qualify to be closed`} />
      <Breadcrumbs>
        <MuiNavLink href="/v2/dashboard/">
          <Typography color="textSecondary">Dashboard</Typography>
        </MuiNavLink>
        <Typography color="textSecondary">Accounting Tools</Typography>
        <Typography color="textPrimary">Projects that qualify to be closed</Typography>
      </Breadcrumbs>
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Projects that qualify to be closed</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                onClick={() => {
                  saveExcel(
                    pageData.projects.map((project) => {
                      return {
                        Project: `${project.status}  ${project.project}`,
                        Address: {v: project.project_address, h: true},
                        Client: project.client,
                        Team: buildRoleString(project.team),
                        Created: {v: project.created, t: "d", z: "yyyy-mm-dd"},
                      };
                    }),
                    "Projects that qualify to be closed",
                    [{wch: 75}, {wch: 50}, {wch: 40}, {wch: 80}, {wch: 10}]
                  );
                }}
                disabled={pageDataQuery.isFetching || pageData.projects.length < 1}
              >
                Get Excel
              </PaperPanel.Header.Button>
              <Box mr={-1} ml={1}>
                <PaperPanel.Header.RefreshButton
                  isFetching={pageDataQuery.isFetching}
                  onClick={() => setRefreshConfirmationDialogIsOpen(true)}
                />
              </Box>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        {pageDataQuery.isFetchedAfterMount ? (
          <ReactTableMui
            size="small"
            hover
            className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
            getHeaderProps={(column) => {
              if (["created"].includes(column.id)) {
                return {
                  style: {
                    width: "6rem",
                  },
                };
              }
            }}
            columns={[
              {
                Header: "Project",
                accessor: "project",
                Cell: ({row, value}) => (
                  <>
                    <TableStatusLabel status={row.original.status} hint="project">
                      <Link href={row.original.project_url} underline="always" target="_blank">
                        {value}
                      </Link>
                    </TableStatusLabel>
                  </>
                ),
              },
              {
                Header: "Address",
                accessor: "project_address",
                Cell: ({row, value}) => <HtmlRender html={textToHTML(value)} />,
              },
              {Header: "Client", accessor: "client"},
              {
                Header: "Team",
                accessor: "team",
                Cell: ({row, value}) => (
                  <Box style={{whiteSpace: "nowrap"}}>
                    {value.project_executive && <LabelValue label="PX: " value={value.project_executive} />}
                    {value.senior_project_manager && (
                      <LabelValue label="Sr. PM: " value={value.senior_project_manager} />
                    )}
                    {value.project_manager && <LabelValue label="PM: " value={value.project_manager} />}
                    {value.superintendent && <LabelValue label="Super: " value={value.superintendent} />}
                    {value.project_admin && <LabelValue label="Admin: " value={value.project_admin} />}
                  </Box>
                ),
              },
              {
                Header: "Created",
                accessor: "created",
                Cell: ({row, value}) => moment(value).format("YYYY-MM-DD"),
              },
            ]}
            //   initialState={{
            //     sortBy: [
            //       {
            //         id: "submitted_date",
            //         asc: true,
            //       },
            //     ],
            //   }}
            data={pageData.projects}
          />
        ) : (
          <>
            <Box p={1}>
              <Alert severity="info">
                <AlertTitle>Fetching the latest data. This can take a long time. Please hang tight...</AlertTitle>
              </Alert>
              {[...Array(30)].map((_, i) => (
                <Skeleton key={i} />
              ))}
            </Box>
          </>
        )}
      </PaperPanel>
      <LegacyUILink href={`/projects/can-closeout/`} />
      <ConfirmationDialog
        title="Refresh the list of projects?"
        onDeny={() => setRefreshConfirmationDialogIsOpen(false)}
        isOpen={refreshConfirmationDialogIsOpen}
        onApprove={() => postCurrentPage.mutateAsync().then(() => window.location.reload())}
      >
        This will refresh the list of projects that can be closed out. This may take several minutes. Do you want to
        continue?
      </ConfirmationDialog>
    </>
  );
};

export default CanCloseout;
