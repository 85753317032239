import {makeStyles, Typography} from "@material-ui/core";
import {grey} from "@material-ui/core/colors";
import classnames from "classnames";
import React from "react";
import {colorError} from "../theme/colors";

const useStyles = makeStyles((theme) => {
  return {
    metadata: {
      fontSize: 11,
      color: grey[600],
      "& a": {
        color: grey[600],
      },
      "& a:hover": {
        // textDecoration: "none",
      },
    },
    error: {
      fontSize: 11,
      color: colorError,
    },
  };
});

const Typography2 = (props: {
  children: React.ReactNode;
  type: "metadata" | "header" | "error";
  [rest: string]: any;
}) => {
  const {children, type, className, ...rest} = props;
  const styles = useStyles();
  return (
    <Typography component="span" className={classnames(styles[type], className)} {...rest}>
      {children}
    </Typography>
  );
};

export default Typography2;
