import {faPaperclip} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Typography} from "@material-ui/core";
import classnames from "classnames";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {StatusIcon} from "../../../components/Icons";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TabCountChip} from "../../../components/Tabs";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";

const ProjectCORs = (props) => {
  const {project, userContact, ...rest} = props;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(50);
  const novoClasses = makeNovoClasses();
  // const {budget_id: initialBudgetId} = useParams();
  const {search} = useLocation();
  const queryString = qs.parse(search);

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(
    ["Search", "Internal/External", "Status", "Budget", "SCO", "Prime Contract", "Approved"],
    {
      "Internal/External": {
        label: "External",
        value: "False",
      },
      Budget: {
        label: queryString.budget_id ? "From URL" : "",
        value: queryString.budget_id || "",
      },
      SCO: {
        label: queryString.scco ? "From URL" : "",
        value: queryString.scco || "",
      },
      "Prime Contract": {
        label: queryString.primecontract ? "From URL" : "",
        value: queryString.primecontract || "",
      },
      Approved: {
        label: queryString.is_approved || "",
        value: queryString.is_approved || "",
      },
      Status: {
        label: queryString.pco__status || "",
        value: queryString.pco__status || "",
      },
    }
  );

  const filterParams = {
    q: filterOptions.Search.value,
    pco__is_internal: filterOptions["Internal/External"].value,
    pco__status: filterOptions.Status.value,
    budget_id: filterOptions.Budget.value,
    scco: filterOptions.SCO.value,
    primecontract: filterOptions["Prime Contract"].value,
    is_approved: filterOptions.Approved.value,
  };

  const {query: pcoItemsQuery} = useSentinelListAPI(
    `projects/${project.id}/pcos/items/?pco__parent__isnull=True&page_size=${pageSize}&page=${page}&${qs.stringify(
      filterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );
  const pcoItems = pcoItemsQuery.data.results;

  const {query: statusOptionsQuery} = useSentinelListAPI(`projects/${project.id}/pcos/status/`, {
    initialData: [],
  });
  const statusOptions = statusOptionsQuery.data;

  const {query: budgetOptionsQuery} = useSentinelListAPI(`projects/${project.id}/budgets/`, {
    initialData: {
      results: [],
    },
  });
  const budgetOptions = budgetOptionsQuery.data.results.map((budget) => {
    return {value: budget.id, label: `${budget.display} - ${budget.allocation_display}`};
  });

  const {query: sccoOptionsQuery} = useSentinelListAPI(`projects/${project.id}/sccos/`, {
    initialData: {
      results: [],
    },
  });
  const sccoOptions = sccoOptionsQuery.data.results.map((scco) => {
    return {value: scco.id, label: `${scco.display}`};
  });

  const {query: primeContractOptionsQuery} = useSentinelListAPI(`projects/${project.id}/contracts/prime/`, {
    initialData: {
      results: [],
    },
  });
  const primeContractOptions = primeContractOptionsQuery.data.results.map((primeContract) => {
    return {value: primeContract.id, label: `${primeContract.display}`};
  });

  return (
    <>
      <Helmet title={`${project.display} - PCO Items`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">PCO Items</Typography>
      </ProjectBreadcrumbs>

      {/* <pre>{JSON.stringify(primeContractOptionsQuery, null, 2)}</pre> */}

      <PaperPanel>
        <PaperPanel.Header isLoading={pcoItemsQuery.isFetching}>
          <PaperPanel.Header.Title>
            <Box display="flex" alignItems="center">
              <FontAwesomeIcon icon={faPaperclip} /> PCO Items{" "}
              <TabCountChip count={pcoItemsQuery.data.count} isLoading={pcoItemsQuery.isFetching} />
            </Box>
          </PaperPanel.Header.Title>

          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action pl={0}>
              <PaperPanel.Header.RefreshButton
                isFetching={pcoItemsQuery.isFetching}
                onClick={() => pcoItemsQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                label="Status"
                name="status"
                options={statusOptions}
                value={filterOptions.Status.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Status", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="Budget"
                name="budget"
                options={budgetOptions}
                value={filterOptions.Budget.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Budget", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="SCO"
                name="scco"
                options={sccoOptions}
                value={filterOptions.SCO.value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("SCO", value, label);
                }}
              />
            </Grid>
            <Grid item sm={4} xs={12}>
              <FilterSelect
                // native
                label="Prime Contract"
                name="prime_contract"
                options={primeContractOptions}
                value={filterOptions["Prime Contract"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Prime Contract", value, label);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                // allowNull={false}
                label="Internal/External"
                name="internal_external"
                options={[
                  {
                    label: "External",
                    value: "False",
                  },
                  {
                    label: "Internal",
                    value: "True",
                  },
                ]}
                value={filterOptions["Internal/External"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Internal/External", value, label);
                }}
              />
            </Grid>
            <Grid item sm={2} xs={12}>
              <FilterSelect
                // allowNull={false}
                label="Approved"
                name="approved"
                options={[
                  {
                    label: "False",
                    value: "False",
                  },
                  {
                    label: "True",
                    value: "True",
                  },
                ]}
                value={filterOptions["Approved"].value}
                onChange={(value, label) => {
                  setPage(1);
                  setFilterOption("Approved", value, label);
                }}
              />
            </Grid>

            <Grid container spacing={1}>
              <Grid item xs={12}>
                <FilterOptionChips
                  filterOptions={filterOptions}
                  onDelete={(key) => {
                    clearFilterOption(key);
                  }}
                  onDeleteAll={() => {
                    clearAllFilterOptions();
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Pagination count={pcoItemsQuery.data.total_pages} page={page} setPage={setPage} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>

        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            // containerComponent={Paper}
            rightAlignColumns={["budget_rom", "budget_proposed_amount", "budget_approved_amount", "cost"]}
            columns={[
              {
                Header: "PCO #",
                accessor: "pco_number_display",
                Cell: ({value, row}) => (
                  <>
                    <StatusIcon status={row.original.status_display} type="changeOrder" showTooltip />{" "}
                    <Link href={row.original.pco_url}>{value}</Link>
                  </>
                  // <TableStatusLabel status={row.original.status_display || "Unknown"}>
                  //   <Link href={row.original.pco_url}>{value}</Link>
                  // </TableStatusLabel>
                ),
              },
              {
                Header: "PCO Description",
                accessor: "pco_description",
                Cell: ({value, row}) => (
                  <Box flex maxWidth={250}>
                    <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                      {value}
                    </Box>
                  </Box>
                ),
              },
              {
                Header: "Item #",
                accessor: "number_display",
                Cell: ({value, row}) => <Link href={row.original.url}>{value}</Link>,
              },
              {
                Header: "Item Description",
                accessor: "description",
                Cell: ({value, row}) => (
                  <Box flex maxWidth={250}>
                    <Box whiteSpace="nowrap" overflow="hidden" textOverflow="ellipsis">
                      {value}
                    </Box>
                  </Box>
                ),
              },
              {
                Header: "Budget Code",
                accessor: "budget.budget_code",
                Cell: ({value, row}) => <Link href={row.original.budget?.url}>{value}</Link>,
              },
              {
                Header: "Budget Allocation",
                accessor: "budget.allocation_display",
              },
              {
                Header: "SCO #",
                accessor: "scco.number_display",
                Cell: ({value, row}) => <Link href={row.original.scco?.url}>{value}</Link>,
              },
              {
                Header: "ROM",
                accessor: "budget_rom",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
              },
              {
                Header: "Proposed",
                accessor: "budget_proposed_amount",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
              },
              {
                Header: "Approved",
                accessor: "budget_approved_amount",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
              },
              {
                Header: "Cost",
                accessor: "cost",
                Cell: ({value, row}) => <Currency number={value} precision={2} />,
              },
            ]}
            initialState={
              {
                // sortBy: [
                //   {
                //     id: "timestamp",
                //     desc: true,
                //   },
                // ],
              }
            }
            data={pcoItems}
            {...rest}
          />
          {/* <pre>{JSON.stringify(pcoItemsQuery, null, 2)}</pre> */}
        </PaperPanel.Body>
        {(pcoItemsQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={pcoItemsQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel>

      {/* <Typography variant="h2">COR Query</Typography>
      <pre>{JSON.stringify(corQuery, null, 2)}</pre> */}
      {/* <h1>Current Page Query</h1>
      <pre>{JSON.stringify(currentPageQuery, null, 2)}</pre> */}
      {/* <Typography variant="h2">PROPS</Typography>
      <pre>{JSON.stringify(props, null, 2)}</pre> */}
    </>
  );
};

export default ProjectCORs;
