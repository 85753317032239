import {
  faCaretDown,
  faCaretUp,
  faFileExcel,
  faGavel,
  faSortAlphaDown,
  faSortAlphaUp,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  AppBar,
  Box,
  Chip,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Switch,
  Tab,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import moment from "moment";
import {useSnackbar} from "notistack";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {axiosAPI} from "../../../api";
import {Currency} from "../../../components/Accounting";
import {CreateMissingBoxFoldersConfirmationDialog, FixBoxFolderNamesConfirmationDialog} from "../../../components/Box";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {TabCountChip} from "../../../components/Tabs";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import usePermissions from "../../../hooks/usePermissions";
import {getAxiosAPIResponseMessage, useFetchCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import getQueryStringFromIds from "../../../utils/getQueryStringFromIds";
import {SubcontractPaperItem} from "./ContractPaperItems";
import {PrimeContractUpdateDialogForm} from "./PrimeContractForms";
import {SubcontractUpdateDialogForm} from "./SubcontractForms";

const ProjectContracts = (props) => {
  const {project, userContact, ...rest} = props;
  const [timberlineCommitmentType, setTimberlineCommitmentType] = React.useState("subcontract");
  const [createMissingBoxFolders, setCreateMissingBoxFolders] = React.useState(false);
  const [fixBoxFolderNamesDialog, setFixBoxFolderNamesDialog] = React.useState(false);
  const [showCreateSubcontractDialog, setShowCreateSubcontractDialog] = React.useState(false);
  const [showUpdateSubcontractDialog, setShowUpdateSubcontractDialog] = React.useState(false);
  const [showCreatePrimeContractDialog, setShowCreatePrimeContractDialog] = React.useState(false);
  const [selectedTab, setSelectedTab] = useQueryState("tab", "subcontracts");
  const [activeSubcontract, setActiveSubcontract] = React.useState({} as any);
  const [initialBudget, setInitialBudget] = React.useState(null);
  const blockUI = useBlockUI();
  const permissions = usePermissions();
  const {enqueueSnackbar} = useSnackbar();

  const currentPageQuery = useFetchCurrentPage({
    initialData: {
      budgetsMissingSubcontract: [],
    },
  });
  const budgetsMissingSubcontract = currentPageQuery.data?.budgetsMissingSubcontract;

  const [
    subcontractFilterOptions,
    setSubcontractFilterOption,
    clearSubcontractFilterOption,
    clearAllSubcontractFilterOptions,
  ] = useFilterOptions(["Status", "To Company", "Search"]);
  const novoClasses = makeNovoClasses();

  const subcontractFilterParams = {
    q: subcontractFilterOptions.Search.value,
    status: subcontractFilterOptions.Status.value,
    to_company_id: subcontractFilterOptions["To Company"].value,
  };

  const {
    query: subcontractsQuery,
    create: createSubcontract,
    update: updateSubcontract,
    // delete: deleteRFI,
    rpc: subcontractRPC,
    onDragEnd: subcontractsOnDragEnd,
  } = useSentinelListAPI(
    // `projects/${project.id}/rfis/?page_size=${pageSize}&page=${page}&parent__isnull=True&${qs.stringify(filterParams)}`,
    `projects/${project.id}/contracts/sub/?_=_&${qs.stringify(subcontractFilterParams)}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const {query: subcontractStatusQuery} = useSentinelListAPI(`projects/${project.id}/contracts/sub/status/`, {
    initialData: [],
  });
  const subcontractStatusOptions = subcontractStatusQuery.data;
  const {query: toCompanyQuery} = useSentinelListAPI(`projects/${project.id}/contracts/sub/to-companies/`, {
    initialData: [],
  });
  const toCompanyOptions = toCompanyQuery.data;

  const {
    query: primeContractsQuery,
    create: createPrimeContract,
    update: updatePrimeContract,
    // delete: deleteRFI,
    rpc: primeContractRPC,
    onDragEnd: primeContractOnDragEnd,
  } = useSentinelListAPI(
    // `projects/${project.id}/rfis/?page_size=${pageSize}&page=${page}&parent__isnull=True&${qs.stringify(filterParams)}`,
    `projects/${project.id}/contracts/prime/?serializer=full`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const {query: timberlineCommitmentsQuery} = useSentinelListAPI(
    // `projects/${project.id}/rfis/?page_size=${pageSize}&page=${page}&parent__isnull=True&${qs.stringify(filterParams)}`,
    `projects/${project.id}/timberline-commitments/?type=${timberlineCommitmentType}`,
    {
      initialData: {
        results: [],
      },
      // https://react-query.tanstack.com/guides/paginated-queries
      keepPreviousData: true,
    }
  );
  const subcontracts = subcontractsQuery.data?.results;
  const primeContracts = primeContractsQuery.data?.results;
  const timberlineCommitments = timberlineCommitmentsQuery.data?.results;

  // if (
  //   !subcontractQuery.isFetchedAfterMount ||
  //   !primeContractQuery.isFetchedAfterMount ||
  //   !timberlineCommitmentQuery.isFetchedAfterMount
  // ) {
  //   return <BlockUI show={true} message="Fetching Commitments..." />;
  // }

  const reorderSubcontracts = (orderBy) => {
    blockUI.blockUI("Reordering...");
    axiosAPI
      .post(`/projects/${project.id}/actions/reposition_subcontracts/?order_by=${orderBy}`, {}, {baseURL: ""})
      .then(() => {
        subcontractsQuery.refetch().then(() => blockUI.unblockUI());
      })
      .catch((error) => {
        enqueueSnackbar(getAxiosAPIResponseMessage(error), {variant: "error"});
        blockUI.unblockUI();
      });
  };

  const pdfQueryString = React.useMemo(
    () => getQueryStringFromIds(undefined, subcontractFilterParams),
    [subcontractFilterParams]
  );

  return (
    <>
      <Helmet title={`${project.display} - Contracts`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Contracts</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar
            position="static"
            color="default"
            // elevation={0}
          >
            <TabList
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
                // navigate(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Subcontracts
                    <TabCountChip
                      isLoading={!subcontractsQuery.isFetchedAfterMount}
                      count={subcontractsQuery.data?.count}
                    />
                  </Box>
                }
                value="subcontracts"
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Prime Contracts
                    <TabCountChip
                      isLoading={!primeContractsQuery.isFetchedAfterMount}
                      count={primeContractsQuery.data?.count}
                    />
                  </Box>
                }
                value="primeContracts"
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Timberline
                    <TabCountChip
                      isLoading={!timberlineCommitmentsQuery.isFetchedAfterMount}
                      count={timberlineCommitmentsQuery.data?.count}
                    />
                  </Box>
                }
                value="timberline"
              />
            </TabList>
          </AppBar>
          <TabPanel value="subcontracts">
            <Box mx={-3} mb={-3} mt={-2}>
              <PaperPanel.Header isLoading={subcontractsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faGavel} /> Subcontracts
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <Tooltip title={project.is_closed ? "Project is Closed" : "Create Subcontract"}>
                      <div>
                        <PaperPanel.Header.CreateButton
                          disabled={!subcontractsQuery.isFetchedAfterMount || project.is_closed}
                          onClick={() => {
                            setInitialBudget(null);
                            setShowCreateSubcontractDialog(true);
                          }}
                        >
                          Create
                        </PaperPanel.Header.CreateButton>
                      </div>
                    </Tooltip>

                    <Box ml={1} />
                    <Tooltip title="Create Missing Subcontract">
                      <div>
                        <PaperPanel.Header.DropdownMenu
                          disabled={budgetsMissingSubcontract.length === 0}
                          title={
                            <>
                              {budgetsMissingSubcontract.length > 0 && (
                                <Box mr={1}>
                                  <Chip size="small" label={budgetsMissingSubcontract.length} />
                                </Box>
                              )}
                              Create Missing
                            </>
                          }
                        >
                          {(setOpen) => (
                            <>
                              {budgetsMissingSubcontract.map((budget) => (
                                <PaperPanel.Header.Menu.MenuItem
                                  // component={Link}
                                  // href={`/contracts/subcontract-for-budget/${project.id}/${budget.id}/create/`}
                                  onClick={(event) => {
                                    setOpen(false);
                                    setInitialBudget(budget);
                                    setShowCreateSubcontractDialog(true);
                                  }}
                                >
                                  {budget.display}
                                </PaperPanel.Header.Menu.MenuItem>
                              ))}
                            </>
                          )}
                        </PaperPanel.Header.DropdownMenu>
                      </div>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/projects/${project.id}/subcontracts/?${pdfQueryString}`}
                      buildReportURL={`/reports2/projects/${project.id}/subcontracts/filter/`}
                    />
                    <Box ml={1} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.Button
                      href={`/reports2/projects/${project.id}/subcontractor/checklist/`}
                      startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                      variant="outlined"
                    >
                      Checklist
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.ReorderButton onReorder={(orderBy) => reorderSubcontracts(orderBy)}>
                      {(setOpen) => (
                        <>
                          <Divider />
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              setOpen(false);
                              reorderSubcontracts("budget__budget_code");
                            }}
                          >
                            <FontAwesomeIcon icon={faCaretDown} fixedWidth />
                            Budget Code
                          </PaperPanel.Header.Menu.MenuItem>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              setOpen(false);
                              reorderSubcontracts("-budget__budget_code");
                            }}
                          >
                            <FontAwesomeIcon icon={faCaretUp} fixedWidth />
                            Budget Code
                          </PaperPanel.Header.Menu.MenuItem>
                          <Divider />
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              setOpen(false);
                              reorderSubcontracts("to_company__name");
                            }}
                          >
                            <FontAwesomeIcon icon={faSortAlphaDown} fixedWidth />
                            Subcontractor Name
                          </PaperPanel.Header.Menu.MenuItem>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={() => {
                              setOpen(false);
                              reorderSubcontracts("-to_company__name");
                            }}
                          >
                            <FontAwesomeIcon icon={faSortAlphaUp} fixedWidth />
                            Subcontractor Name
                          </PaperPanel.Header.Menu.MenuItem>
                        </>
                      )}
                    </PaperPanel.Header.ReorderButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border pr={0}>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          {/* <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              subcontractsQuery.refetch();
                              popupState.close();
                            }}
                          >
                            <FontAwesomeIcon icon={faSync} fixedWidth /> Refresh
                          </PaperPanel.Header.Menu.MenuItem> */}
                          <PaperPanel.Header.Menu.MenuItem
                            component={Link}
                            href={`/reports2/projects/${project.id}/subcontractor/checklist/`}
                            onClick={() => {
                              popupState.close();
                            }}
                          >
                            <FontAwesomeIcon icon={faFileExcel} fixedWidth /> Get Checklist
                          </PaperPanel.Header.Menu.MenuItem>
                          <Divider />
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                              setCreateMissingBoxFolders(true);
                            }}
                          >
                            Create Missing Box Folders
                          </PaperPanel.Header.Menu.MenuItem>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                              setFixBoxFolderNamesDialog(true);
                            }}
                          >
                            Fix Box Folder Names
                          </PaperPanel.Header.Menu.MenuItem>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                    {/* </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action> */}
                    <PaperPanel.Header.RefreshButton
                      isFetching={subcontractsQuery.isFetching}
                      onClick={() => subcontractsQuery.refetch()}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>
              <PaperPanel.Toolbar p={1}>
                <Grid container spacing={1}>
                  <Grid item sm={4} xs={12}>
                    <FilterSearch
                      label="Search"
                      value={subcontractFilterOptions.Search.value}
                      name="Search"
                      onChange={(value) => {
                        setSubcontractFilterOption("Search", value, value);
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FilterSelect
                      label="Status"
                      name="Status"
                      options={subcontractStatusOptions}
                      value={subcontractFilterOptions.Status.value}
                      showStatusIcon
                      onChange={(value, label) => {
                        setSubcontractFilterOption("Status", value, label);
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FilterSelect
                      label="To Company"
                      name="To Company"
                      options={toCompanyOptions}
                      value={subcontractFilterOptions["To Company"].value}
                      onChange={(value, label) => {
                        setSubcontractFilterOption("To Company", value, label);
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={12} xs={12}>
                    <FilterOptionChips
                      filterOptions={subcontractFilterOptions}
                      onDelete={(key) => {
                        clearSubcontractFilterOption(key);
                      }}
                      onDeleteAll={() => {
                        clearAllSubcontractFilterOptions();
                      }}
                    />
                  </Grid>
                </Grid>
              </PaperPanel.Toolbar>
              <PaperPanel.Body>
                <VirtualizedList
                  itemList={subcontracts}
                  isVirtualized={subcontracts.length > 200}
                  onDragEnd={subcontractsOnDragEnd}
                >
                  {(props) => {
                    const {listItem: subcontract, ...rest} = props;
                    return (
                      <SubcontractPaperItem
                        key={subcontract.id}
                        contract={subcontract}
                        projectId={project.id}
                        setActiveContract={setActiveSubcontract}
                        setShowUpdateDialog={setShowUpdateSubcontractDialog}
                        {...rest}
                      />
                    );
                  }}
                </VirtualizedList>
                {/* <DragDropContext
                  onDragEnd={(result) => {
                    subcontractsOnDragEnd(result);
                  }}
                >
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {subcontracts.map((subcontract, index) => {
                          return (
                            <Draggable key={subcontract.id} draggableId={subcontract.id.toString()} index={index}>
                              {(provided, snapshot) => (
                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                  <SubcontractPaperItem
                                    key={subcontract.id}
                                    contract={subcontract}
                                    projectId={project.id}
                                    isDragging={snapshot.isDragging}
                                    setActiveContract={setActiveSubcontract}
                                    setShowUpdateDialog={setShowUpdateSubcontractDialog}
                                    dragHandleProps={provided.dragHandleProps}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        })}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext> */}
                {/* <pre>{JSON.stringify(subcontracts, null, 2)}</pre> */}
              </PaperPanel.Body>
              {subcontracts.length > 0 && (
                <PaperPanel.Footer display="flex" justifyContent="flex-end">
                  <Tooltip
                    title={
                      <>
                        Original Value:{" "}
                        <Currency
                          number={subcontracts.reduce((sum, contract) => contract.original_value + sum, 0)}
                          precision={2}
                        />
                        <br />
                        Current Value:{" "}
                        <Currency
                          number={subcontracts.reduce((sum, contract) => contract.current_value + sum, 0)}
                          precision={2}
                        />
                      </>
                    }
                  >
                    <strong>
                      <Currency
                        number={subcontracts.reduce((sum, contract) => contract.current_value + sum, 0)}
                        precision={2}
                      />
                    </strong>
                  </Tooltip>
                </PaperPanel.Footer>
              )}
            </Box>
          </TabPanel>
          <TabPanel value="primeContracts">
            <Box mx={-3} mb={-3} mt={-2}>
              <PaperPanel.Header isLoading={primeContractsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faGavel} /> Prime Contracts
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.CreateButton
                      onClick={() => {
                        setShowCreatePrimeContractDialog(true);
                      }}
                      disabled={!permissions.is_in_accounting_group}
                    >
                      Create
                    </PaperPanel.Header.CreateButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/projects/${project.id}/primecontracts/?display_pccos=on`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={primeContractsQuery.isFetching}
                      onClick={() => primeContractsQuery.refetch()}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>
              <PaperPanel.Body>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  rightAlignColumns={["original_value", "pending_revs", "approved_revs", "current_value"]}
                  showFooter={Boolean(primeContracts.length)}
                  columns={[
                    {
                      Header: "Description",
                      accessor: "description",
                      Cell: ({value, row}) => (
                        <MuiNavLink
                          underline="always"
                          // href={`/contracts/primecontract/${row.original.id}/`}
                          to={`/v2/projects/${project.id}/contracts/prime/${row.original.id}/`}
                        >
                          {value}
                        </MuiNavLink>
                      ),
                    },
                    {
                      Header: "Original Value",
                      accessor: "original_value",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = primeContracts.reduce((sum, row) => row.original_value + sum, 0);
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                    {
                      Header: "Pending Changes",
                      accessor: "pending_revs",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = primeContracts.reduce((sum, row) => row.pending_revs + sum, 0);
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                    {
                      Header: "Approved Changes",
                      accessor: "approved_revs",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = primeContracts.reduce((sum, row) => row.approved_revs + sum, 0);
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                    {
                      Header: "Current Value",
                      accessor: "current_value",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = primeContracts.reduce((sum, row) => row.current_value + sum, 0);
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                  ]}
                  initialState={{
                    sortBy: [
                      {
                        id: "number",
                        desc: false,
                      },
                    ],
                  }}
                  data={primeContracts}
                />
                {/* <pre>{JSON.stringify(primeContractsQuery, null, 2)}</pre> */}
              </PaperPanel.Body>
            </Box>
          </TabPanel>
          <TabPanel value="timberline">
            <Box mx={-3} mb={-3} mt={-2}>
              <PaperPanel.Header isLoading={timberlineCommitmentsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faGavel} /> Timberline Commitments
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={timberlineCommitmentType === "subcontract"}
                          onChange={(event) => {
                            event.target.checked
                              ? setTimberlineCommitmentType("subcontract")
                              : setTimberlineCommitmentType("");
                            // console.log("!!!", event.target.checked);
                          }}
                        />
                      }
                      label="Only Subcontracts"
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.RefreshButton
                    isFetching={timberlineCommitmentsQuery.isFetching}
                    onClick={() => timberlineCommitmentsQuery.refetch()}
                  />
                </PaperPanel.Header.Actions>
              </PaperPanel.Header>
              {/* <PaperPanel.Toolbar p={1}>Toolbar</PaperPanel.Toolbar> */}
              <PaperPanel.Body>
                <ReactTableMui
                  size="small"
                  className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
                  rightAlignColumns={["amount", "approved_commitment_co_amount", "committed"]}
                  showFooter={Boolean(timberlineCommitments.length)}
                  columns={[
                    {
                      Header: "Commitment",
                      accessor: "commitment",
                      Cell: ({value, row}) =>
                        row.original.sentinel_commitment_id ? (
                          <MuiNavLink
                            underline="always"
                            to={`/v2/projects/${project.id}/contracts/sub/${row.original.sentinel_commitment_id}/#tab=timberline`}
                          >
                            {value}
                          </MuiNavLink>
                        ) : (
                          <Link underline="always" href={`/timberline/subcontracts/${value}/`}>
                            {value}
                          </Link>
                        ),
                    },

                    {
                      Header: "Date",
                      accessor: "date",
                    },
                    {
                      Header: "Description",
                      accessor: "description",
                    },
                    {
                      Header: "Sentinel Commitment",
                      accessor: "sentinel_commitment",
                      sortType: "basic",
                      Cell: ({value, row}) => (
                        // <Link underline="always" href={row.original.sentinel_commitment_url}>
                        //   {value}
                        // </Link>
                        <MuiNavLink
                          underline="always"
                          to={`/v2/projects/${project.id}/contracts/sub/${row.original.sentinel_commitment_id}/`}
                        >
                          {value}
                        </MuiNavLink>
                      ),
                    },
                    {
                      Header: "Vendor",
                      accessor: "vendor_name",
                    },
                    {
                      Header: "Amount",
                      accessor: "amount",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = timberlineCommitments.reduce((sum, row) => row.amount + sum, 0);
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                    {
                      Header: "Changes",
                      accessor: "approved_commitment_co_amount",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = timberlineCommitments.reduce(
                          (sum, row) => row.approved_commitment_co_amount + sum,
                          0
                        );
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                    {
                      Header: "Total",
                      accessor: "committed",
                      sortType: "basic",
                      Cell: ({value, row}) => <Currency number={value} precision={2} />,
                      Footer: () => {
                        const total = timberlineCommitments.reduce((sum, row) => row.committed + sum, 0);
                        return total ? <Currency number={total} /> : "";
                      },
                    },
                  ]}
                  initialState={{
                    sortBy: [
                      {
                        id: "commitment",
                        desc: false,
                      },
                    ],
                  }}
                  data={timberlineCommitments}
                />
                {/* <pre>{JSON.stringify(timberlineCommitmentsQuery, null, 2)}</pre> */}
              </PaperPanel.Body>
            </Box>
          </TabPanel>
        </PaperPanel>
      </TabContext>
      {/* <pre>{JSON.stringify(userContact, null, 2)}</pre> */}
      <LegacyUILink href={`/projects/${project.id}/contracts/legacy/`} mt={2} />

      <SubcontractUpdateDialogForm
        projectId={project.id}
        isNew
        isOpen={showCreateSubcontractDialog}
        restrictNovoSigners={project.restrict_novo_signers}
        handleClose={() => {
          setShowCreateSubcontractDialog(false);
        }}
        initialValues={{
          number: subcontractsQuery?.data?.metadata?.next_number,
          issued_date: moment().format("YYYY-MM-DD"),
          from_company: userContact?.company,
          type: "fa",
          budget: initialBudget,
        }}
        onSubmit={(values) => {
          blockUI.blockUI("Creating subcontract...");
          const editAfterSubmit = values.editAfterSubmit;
          if (editAfterSubmit) {
            delete values.editAfterSubmit;
          }
          createSubcontract
            .mutateAsync(values)
            .then((newSubcontract) => {
              if (editAfterSubmit) {
                // navigate(`/v2/projects/${project.id}/contracts/sub/${newSubcontract.id}/`);
                setShowCreateSubcontractDialog(false);
                /* @ts-ignore */
                window.location = `/contracts/subcontract/${newSubcontract.id}/`;
                // blockUI.unblockUI();
              } else {
                setShowCreateSubcontractDialog(false);
                currentPageQuery.refetch();
                subcontractsQuery.refetch();
                blockUI.unblockUI();
              }
            })
            .catch(() => {
              blockUI.unblockUI();
            });
        }}
      />
      <SubcontractUpdateDialogForm
        projectId={project.id}
        isOpen={showUpdateSubcontractDialog}
        restrictNovoSigners={project.restrict_novo_signers}
        isReadOnly={activeSubcontract?.is_closed}
        handleClose={() => {
          setActiveSubcontract({});
          setShowUpdateSubcontractDialog(false);
        }}
        initialValues={activeSubcontract}
        onSubmit={(values) => {
          setShowUpdateSubcontractDialog(false);
          updateSubcontract.mutateAsync(values);
        }}
      />

      <PrimeContractUpdateDialogForm
        projectId={project.id}
        isNew
        isOpen={showCreatePrimeContractDialog}
        handleClose={() => {
          setShowCreatePrimeContractDialog(false);
        }}
        initialValues={{
          number: primeContractsQuery?.data?.metadata?.next_number,
          issued_date: moment().format("YYYY-MM-DD"),
          to_company: userContact?.company,
        }}
        onSubmit={(values) => {
          setShowCreatePrimeContractDialog(false);
          const editAfterSubmit = values.editAfterSubmit;
          if (editAfterSubmit) {
            delete values.editAfterSubmit;
            blockUI.blockUI();
          }
          createPrimeContract.mutateAsync(values).then((newPrimeContract) => {
            if (editAfterSubmit) {
              // navigate(`/v2/projects/${project.id}/contracts/sub/${newSubcontract.id}/`);
              /* @ts-ignore */
              window.location = `/contracts/primecontract/${newPrimeContract.id}/`;
              blockUI.unblockUI();
            } else {
              currentPageQuery.refetch();
              primeContractsQuery.refetch();
            }
          });
        }}
      />

      <CreateMissingBoxFoldersConfirmationDialog
        isOpen={createMissingBoxFolders}
        setIsOpen={setCreateMissingBoxFolders}
        rpc={subcontractRPC}
      />
      <FixBoxFolderNamesConfirmationDialog
        isOpen={fixBoxFolderNamesDialog}
        setIsOpen={setFixBoxFolderNamesDialog}
        rpc={subcontractRPC}
      />
    </>
  );
};

export default ProjectContracts;
