import {Container, Link, Toolbar, Typography} from "@material-ui/core";
import {getHeader} from "@mui-treasury/layout";
import styled from "styled-components";
import {HeaderLogo} from "./Header";

const GlobalFallback = (props) => {
  const {error} = props;
  const HeaderTUI = getHeader(styled);
  return (
    <>
      <HeaderTUI elevation={4} color="primary">
        <Toolbar>
          <HeaderLogo />
        </Toolbar>
      </HeaderTUI>
      <Container fixed>
        <Typography variant="h1">Error</Typography>
        <Typography variant="h2">
          <strong>Oooops, Something went wrong!</strong>
        </Typography>
        <br />
        <p>
          <strong>You have experienced a technical error. We apologize.</strong>
        </p>
        <p>
          <span>
            <Link href="mailto:skuehn@novoconstruction.com">Sam</Link> and{" "}
            <Link href="mailto:cstoner@novoconstruction.com">Colin</Link> have been notified.
          </span>
        </p>
        <p>{error.message}</p>
        <p>
          <small>{error.stack}</small>
        </p>
        <p>We are working hard to correct this issue.</p>
      </Container>
    </>
  );
};
export default GlobalFallback;
