import {faCheck} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, LinearProgress, Typography} from "@material-ui/core";
import {Checkboxes} from "mui-rff";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../js/components/BeforeUnload";
import nl2br from "../../../js/utils/nl2br";
import {ConfirmationDialog} from "../../components/Dialogs";
import InnerFooter from "../../components/InnerFooter";
import {SimpleSelect, TextFieldMui} from "../../components/forms/Fields";
import FormActions from "../../components/forms/FormActions";
import validateFormOnRender from "../../utils/validateFormOnRender";
import {EmployeeReviewCircularProgress} from "./EmployeeReviewComponents";

export const EmployeeReviewQuestionSetForm = (props) => {
  const {onSubmit, questionSetChoices, initialValues, ...rest} = props;
  return (
    <>
      <FinalForm onSubmit={onSubmit} initialValues={initialValues} {...rest}>
        {({handleSubmit, form, errors, submitting, pristine, values}) => {
          validateFormOnRender(form, errors);
          // React.useEffect(() => {
          //   if (!Boolean(values.question_set)) {
          //     form.submit();
          //   }
          // }, []);
          return (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              {/* <BeforeUnload block={!pristine} /> */}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SimpleSelect
                    name="question_set"
                    label="Select Question Set/Job"
                    options={questionSetChoices}
                    disabled={values.is_closed}
                    required
                    allowNull
                    onChange={(event) => {
                      let value = event.target.value;
                      if (!value) value = "";
                      form.change("question_set", value);
                      const selectedQuestionSet = questionSetChoices.find((item) => item.value === value);
                      const questionSetDescription = selectedQuestionSet ? selectedQuestionSet?.label : "";
                      form.batch(() => {
                        form.change("question_set_description", questionSetDescription);
                      });
                      form.submit();
                    }}
                  />
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(questionSetChoices, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </form>
          );
        }}
      </FinalForm>
    </>
  );
};

const getEmployeeReviewFormComponent = (question, ratingChoices, isReadOnly) => {
  if (question.type === "rated") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          {/* <SimpleSelect options={ratingChoices} /> */}
          <SimpleSelect
            name={`${question.code}[rating]`}
            label="Rating"
            options={ratingChoices}
            allowNull
            disabled={isReadOnly}
            required
          />
        </Grid>
        <Grid item xs={12} sm={8}>
          <TextFieldMui
            name={`${question.code}[description]`}
            label="Comments"
            multiline
            rows={4}
            disabled={isReadOnly}
          />
        </Grid>
        {question?.improvement_items?.groups && (
          <>
            <Box>
              <strong>Please select the items you would like to improve on in the upcoming year.</strong>
            </Box>
            <Grid item xs={12}>
              {question.improvement_items.groups.map((group) => {
                return (
                  <React.Fragment key={group.group_code}>
                    {question.code && <div>{group.group_name}</div>}
                    {Object.entries(group.improvement_items).map(([key, value]: [string, string]) => {
                      return (
                        <Box key={`${question.code}["improvement_items"][${group.group_code || "_"}][${key}]`}>
                          <Checkboxes
                            name={`${question.code}["improvement_items"][${group.group_code || "_"}][${key}]`}
                            data={{label: value, value: true}}
                            disabled={isReadOnly}
                          />
                        </Box>
                      );
                    })}
                  </React.Fragment>
                );
              })}
            </Grid>
          </>
        )}
      </Grid>
    );
  }
  if (question.type === "open") {
    return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextFieldMui
            name={`${question.code}[description]`}
            label="Comments"
            multiline
            rows={4}
            required
            disabled={isReadOnly}
          />
        </Grid>
      </Grid>
    );
  }
};

export const EmployeeReviewForm = (props) => {
  const {review, type, questions, onSubmit, initialValues, ratingChoices, onSubmitReview, ...rest} = props;
  const isReadOnly = review.is_closed;
  const canSubmit = review.percent_complete >= 1 && !review.is_closed;
  const [showSubmitConfirmation, setSubmitConfirmation] = React.useState(false);

  return (
    <>
      <FinalForm onSubmit={onSubmit} initialValues={initialValues} {...rest}>
        {({handleSubmit, form, submitting, pristine, values, hasValidationErrors, errors}) => {
          validateFormOnRender(form, errors);
          return (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              {/* <FinalFormKeyboardShortcuts
                handleSubmit={() => onSubmit(values, form, hasValidationErrors)}
                pristine={pristine}
              > */}
              <BeforeUnload block={!pristine} />
              {/* <pre>{JSON.stringify(review, null, 2)}</pre> */}
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
              {questions.map((question) => {
                return (
                  <Box key={question.code}>
                    <Box border={1} my={1} borderColor="grey.400" borderTop={0} borderLeft={0} borderRight={0} />
                    <Typography variant="h6">{question.question}</Typography>
                    <small>{nl2br(question.description)}</small>
                    <Box mb={2} />
                    {getEmployeeReviewFormComponent(question, ratingChoices, isReadOnly)}
                    {/* <pre>{JSON.stringify(question, null, 2)}</pre> */}
                  </Box>
                );
              })}
              {questions.length > 0 && (
                <InnerFooter>
                  <Box display="flex" alignItems="center">
                    <FormActions.Button
                      disabled={submitting || pristine || review.is_closed}
                      onClick={() => {
                        onSubmit(values, form, hasValidationErrors);
                      }}
                      type="button"
                      label={hasValidationErrors ? "Save Progress" : "Save"}
                      color="primary"
                    />
                    <Box ml={1} />
                    <FormActions.Button
                      disabled={!pristine || !canSubmit}
                      type="button"
                      onClick={() => {
                        setSubmitConfirmation(true);
                      }}
                      startIcon={<FontAwesomeIcon icon={faCheck} />}
                      label="Submit"
                      color="secondary"
                    />

                    <Box ml={1}>
                      <Box display="flex" alignItems="center">
                        <Box>
                          <EmployeeReviewCircularProgress percent={review.percent_complete} />
                        </Box>
                        <Box ml={1}> Complete</Box>
                      </Box>
                    </Box>
                  </Box>

                  <Box mt={0.5} />
                  <LinearProgress variant="determinate" value={review.percent_complete * 100} color="secondary" />
                </InnerFooter>
              )}
              {/* </FinalFormKeyboardShortcuts> */}
            </form>
          );
        }}
      </FinalForm>

      <ConfirmationDialog
        isOpen={showSubmitConfirmation}
        onApprove={() => {
          setSubmitConfirmation(false);
          onSubmitReview();
        }}
        onDeny={() => {
          setSubmitConfirmation(false);
        }}
      >
        You want to submit this evaluation?
        <br />
        After you submit this evaluation will be locked and will not be able to be changed.
      </ConfirmationDialog>
    </>
  );
};
