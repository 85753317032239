import {
  faArrowUpRightFromSquare,
  faCheck,
  faCheckCircle,
  faCircle,
  faClosedCaptioning,
  faGlobe,
  faPenToSquare,
  faPlus,
} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Grid, Link, Tooltip} from "@material-ui/core";
import {PaperItem} from "../../../components/PaperItem";
import Typography2 from "../../../components/Typography2";
import {colorMutedText, colorSuccess} from "../../../theme/colors";

export const ContactPaperItem = (props) => {
  const {
    contact,
    isSelected,
    onChangeSelected,
    isPortalUser,
    onPortalInvite,
    onMarkPrimary,
    onCarbonCopy,
    onEditContact,
    ...rest
  } = props;

  return (
    <PaperItem>
      <PaperItem.Left>
        <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangeSelected} />
      </PaperItem.Left>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={3}>
            {contact.contact?.full_name}{" "}
            <Link href={`/v2/contacts/${contact?.contact?.id}/`} target="_blank">
              <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
            </Link>
          </Grid>
          <Grid item sm={4}>
            <Link href={`mailto:${contact.contact.email}`}>{contact.contact.email}</Link>
          </Grid>
          <Grid item sm={2}>
            {contact.primary_phone && (
              <Tooltip title={contact.primary_phone?.type_display}>
                <Link href={`tel:${contact.primary_phone?.phone}`}>{contact.primary_phone?.phone}</Link>
              </Tooltip>
            )}
          </Grid>
          <Grid item sm={3}>
            {contact?.contact_role_other_description || contact?.contact_role_display}
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={70}>
        <Tooltip title={isPortalUser ? `Portal User` : `Invite To Portal`}>
          {isPortalUser ? (
            <FontAwesomeIcon icon={faGlobe} color={colorSuccess} fixedWidth />
          ) : (
            <FontAwesomeIcon icon={faGlobe} onClick={onPortalInvite} fixedWidth style={{cursor: "pointer"}} />
          )}
        </Tooltip>
        <Tooltip title={contact.is_primary ? `Primary` : `Mark Primary`}>
          {contact.is_primary ? (
            <FontAwesomeIcon icon={faCheck} fixedWidth color={colorSuccess} />
          ) : (
            <FontAwesomeIcon icon={faCheck} fixedWidth onClick={onMarkPrimary} style={{cursor: "pointer"}} />
          )}
        </Tooltip>
        {!contact.is_primary && (
          <Tooltip title={contact.cc ? `Carbon Copy` : `Mark CC`}>
            <FontAwesomeIcon
              icon={faClosedCaptioning}
              color={!contact.cc ? colorMutedText : colorSuccess}
              fixedWidth
              onClick={onCarbonCopy}
              style={{cursor: "pointer"}}
            />
          </Tooltip>
        )}
        <Tooltip title="Edit">
          <FontAwesomeIcon icon={faPenToSquare} fixedWidth onClick={onEditContact} style={{cursor: "pointer"}} />
        </Tooltip>
      </PaperItem.Right>
    </PaperItem>
  );
};

export const CompanyPaperItem = (props) => {
  const {company, onAddContact, onEditCompany, onMarkPrimary, ...rest} = props;

  return (
    <PaperItem bgcolor="grey.50" noHover>
      <PaperItem.Body>
        <Grid container>
          <Grid item sm={6} container>
            <Grid item xs={8}>
              <strong>{company?.company?.name}</strong>
              <Link href={`/v2/companies/${company?.company?.id}/`} target="_blank">
                <FontAwesomeIcon icon={faArrowUpRightFromSquare} fixedWidth />
              </Link>
            </Grid>
            <Grid item xs={4}>
              <Tooltip title={company?.csi_code?.display || ""}>
                <span>{company?.csi_code?.code}</span>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              {company?.company_location?.full_address}{" "}
              <Typography2 type="metadata">{company?.company_location?.description}</Typography2>
            </Grid>
          </Grid>
          <Grid item sm={6} container>
            <Grid item sm={6}>
              <Tooltip title={`Subcontractor Job Number`}>
                <span>{company?.subcontractor_job_number}</span>
              </Tooltip>
            </Grid>
            <Grid item sm={6}>
              {company?.company?.is_union ? (
                <Tooltip title="Union">
                  <FontAwesomeIcon icon={faCheckCircle} />
                </Tooltip>
              ) : (
                <Tooltip title="Non Union">
                  <FontAwesomeIcon icon={faCircle} />
                </Tooltip>
              )}
            </Grid>
            <Grid item sm={6}>
              {company?.company_location?.phone && (
                <Link href={`tel:${company?.company_location?.phone}`}>{company?.company_location?.phone}</Link>
              )}
            </Grid>
          </Grid>
        </Grid>
      </PaperItem.Body>
      <PaperItem.Right minWidth={50}>
        {company.company_role_id !== "subcontractor" && (
          <Tooltip title={company.is_primary ? `Primary` : `Mark Primary`}>
            {company.is_primary ? (
              <FontAwesomeIcon icon={faCheck} fixedWidth color={colorSuccess} />
            ) : (
              <FontAwesomeIcon icon={faCheck} fixedWidth onClick={onMarkPrimary} style={{cursor: "pointer"}} />
            )}
          </Tooltip>
        )}
        {/* {company.is_primary && (
          <Tooltip title="Primary">
            <FontAwesomeIcon icon={faCheck} color={colorSuccess} fixedWidth />
          </Tooltip>
        )} */}
        <Tooltip title="Add Contact">
          <FontAwesomeIcon icon={faPlus} fixedWidth onClick={onAddContact} style={{cursor: "pointer"}} />
        </Tooltip>
        <Tooltip title="Edit">
          <FontAwesomeIcon icon={faPenToSquare} fixedWidth onClick={onEditCompany} style={{cursor: "pointer"}} />
        </Tooltip>
      </PaperItem.Right>
    </PaperItem>
  );
};
