import {Divider, ListItem, Typography} from "@material-ui/core";
import Typography2 from "./Typography2";

export const MenuItemHeader = (props) => {
  const {children, border, ...rest} = props;
  return (
    <>
      {border ? <Divider /> : ""}
      <ListItem {...rest}>
        <Typography variant="button">
          <Typography2 type="metadata">{children}</Typography2>
        </Typography>
      </ListItem>
    </>
  );
};
