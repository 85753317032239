import {faEnvelope, faPaperPlaneTop, faShoppingCart, faXmark} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Grid, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {TabContext, TabPanel} from "@material-ui/lab";
import {sumBy} from "lodash";
import {useSnackbar} from "notistack";
import pluralize from "pluralize";
import React from "react";
import {Helmet} from "react-helmet";
import {useParams} from "react-router-dom";
import {useSelectIds} from "../../../../js/hooks";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {CommitmentBanner} from "../../../components/ClosedBanner";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import {StatusIcon} from "../../../components/Icons";
import {MuiNavLink, ProjectAuditLink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import {
  TimberlineCommitmentChangeOrderPanel,
  TimberlineCommitmentInfoPanel,
  TimberlineCommitmentItemPanel,
} from "../../../components/TimberlineInfoPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import usePermissions from "../../../hooks/usePermissions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {AmendmentPaperItem, PurchaseOrderItemPaperItem} from "./PurchaseOrderDetailPaperItems";
import {
  PurchaseOrderAmendmentDialogForm,
  PurchaseOrderGeneralInfoForm,
  PurchaseOrderItemDialog,
} from "./PurchaseOrderForms";

import moment from "moment";
import {useQueryState} from "use-location-state";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import {PageHeader} from "../../../components/PageHeader";
import {StatusLabel} from "../../../components/Status";
import {makeNovoClasses} from "../../../theme";

const PurchaseOrderDetail = (props) => {
  const {project, ...rest} = props;

  const [selectedTab, setSelectedTab] = useQueryState("tab", "general");
  const [deleteMultipleItemsConfirmationIsOpen, setDeleteMultipleItemsConfirmationIsOpen] = React.useState(false);
  const [voidDialogIsOpen, setVoidDialogIsOpen] = React.useState(false);
  const [submitDialogIsOpen, setSubmitDialogIsOpen] = React.useState(false);
  const [requestDialogIsOpen, setRequestDialogIsOpen] = React.useState(false);
  const [poItemCreateDialogIsOpen, setPOItemCreateDialogIsOpen] = React.useState(false);
  const [poItemUpdateDialogIsOpen, setPOItemUpdateDialogIsOpen] = React.useState(false);
  const [activePurchaseOrderItem, setActivePurchaseOrderItem] = React.useState({});
  const [amendmentCreateDialogIsOpen, setAmendmentCreateDialogIsOpen] = React.useState(false);
  const [amendmentUpdateDialogIsOpen, setAmendmentUpdateDialogIsOpen] = React.useState(false);
  const [activeAmendment, setActiveAmendment] = React.useState({}) as any;

  const createItemFormRef = React.createRef();

  const {purchaseOrderId} = useParams();
  const permissions = usePermissions();
  const blockUI = useBlockUI();
  const {enqueueSnackbar} = useSnackbar();
  const novoClasses = makeNovoClasses();

  const {
    query: purchaseOrderQuery,
    update: updatePurchaseOrder,
    rpc: purchaseOrderRPC,
  } = useSentinelDetailAPI(["projects", project.id, "pos", parseInt(purchaseOrderId)], {
    initialData: {},
  });
  const purchaseOrder = purchaseOrderQuery.data;

  const {
    query: purchaseOrderItemsQuery,
    update: updatePurchaseOrderItem,
    delete: deletePurchaseOrderItem,
    create: createPurchaseOrderItem,
    rpc: purchaseOrderItemRPC,
  } = useSentinelListAPI(["projects", project.id, "pos", parseInt(purchaseOrderId), "items"], {
    initialData: {
      results: [],
    },
  });
  const items = purchaseOrderItemsQuery.data.results;

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
    },
  });

  const {
    query: amendmentQuery,
    update: updateAmendment,
    delete: deleteAmendment,
    create: createAmendment,
    rpc: amendmentRPC,
  } = useSentinelListAPI(["projects", project.id, "pos", parseInt(purchaseOrderId), "amendments"], {
    initialData: {
      results: [],
    },
  });

  const amendments = amendmentQuery.data.results;
  // console.log("Amend", amendments);

  const allItemIds = items.map((item) => item.id);

  const {
    selectedIds: selectedItemIds,
    addSelectedId: addSelectedItemId,
    addSelectedIds: addSelectedItemIds,
    removeSelectedId: removeSelectedItemId,
    removeSelectedIds: removeSelectedItemIds,
    addAllSelectedIds: addAllSelectedIds,
    removeAllSelectedIds: removeAllSelectedItemIds,
    allIdsSelected: allItemIdsSelected,
  } = useSelectIds(allItemIds);

  // console.log(purchaseOrder);
  //   console.log(project);
  //   console.log(pageDataQuery);

  if (
    !purchaseOrderQuery.isFetchedAfterMount ||
    !pageDataQuery.isFetchedAfterMount ||
    !amendmentQuery.isFetchedAfterMount
  ) {
    return <BlockUI show={true} message="Fetching Purchase Order info..." />;
  }

  return (
    <>
      <Helmet title={`${project.display}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink color="inherit" to={`/v2/projects/${project.id}/pos/`}>
          Purchase Orders
        </MuiNavLink>
        <Typography color="textPrimary">{purchaseOrder.display}</Typography>
      </ProjectBreadcrumbs>
      <PageHeader>
        <PageHeader.Left></PageHeader.Left>
        <PageHeader.Right>
          {purchaseOrder.approved_revs !== 0 && (
            <PageHeader.Right.CurrencySpark title="Original Value" number={purchaseOrder.total_price} precision={2} />
          )}
          {purchaseOrder.pending_revs !== 0 && (
            <PageHeader.Right.CurrencySpark
              title="Pending Amendments"
              number={purchaseOrder.pending_revs}
              border={purchaseOrder.approved_revs !== 0}
              precision={2}
            />
          )}
          {purchaseOrder.approved_revs !== 0 && (
            <PageHeader.Right.CurrencySpark
              title="Approved Amendments"
              number={purchaseOrder.approved_revs}
              border
              precision={2}
            />
          )}
          <PageHeader.Right.CurrencySpark
            title="Current Value"
            number={purchaseOrder.current_value}
            border={purchaseOrder.approved_revs !== 0 || purchaseOrder.pending_revs !== 0}
            precision={2}
          />
        </PageHeader.Right>
      </PageHeader>
      <CommitmentBanner object={purchaseOrder} mb={2} type="purchase order" />

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
              className={novoClasses.smallTab}
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    <StatusIcon status={purchaseOrder.status} tooltip={purchaseOrder.status_display} showTooltip />
                    <Box ml={1} />
                    General Info
                  </Box>
                }
                value="general"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Items{" "}
                    <TabCountChip
                      isLoading={!purchaseOrderQuery.isFetchedAfterMount}
                      count={purchaseOrder.item_count}
                    />
                  </Box>
                }
                value="items"
                className={novoClasses.smallTab}
              />

              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Amendments{" "}
                    <TabCountChip isLoading={!amendmentQuery.isFetchedAfterMount} count={amendments.length} />
                  </Box>
                }
                value="amendments"
                className={novoClasses.smallTab}
              />

              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Timberline
                  </Box>
                }
                value="timberline"
                disabled={!purchaseOrder.timberline_subcontract_id}
                className={novoClasses.smallTab}
              />
              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <TabPanel value="general">
            <>
              <PaperPanel.TabHeader isLoading={purchaseOrderQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <StatusLabel status={purchaseOrder.status_display} /> <FontAwesomeIcon icon={faShoppingCart} />{" "}
                  {purchaseOrder.display}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButtons
                      pdfURL={`/reports2/pos/${purchaseOrder.id}/?display_vendor_signature=on`}
                      buildReportURL={`/reports2/projects/${project.id}/pos/detail/filter/?pk=${purchaseOrder.id}`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.DocuSignButton docuSignObject={purchaseOrder} />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/pos/purchaseorder/${purchaseOrder.id}/`}
                      uploadURL={`/projects/${project.id}/pos/${purchaseOrder.id}/upload-to-box/`}
                    />
                  </PaperPanel.Header.Action>

                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          {permissions.can_import_subcontracts && (
                            <PaperPanel.Header.Menu.MenuItem
                              onClick={(event) => {
                                popupState.close();
                                setRequestDialogIsOpen(true);
                              }}
                              disabled={
                                purchaseOrder.submitted_date || ["void", "submitted"].includes(purchaseOrder.status)
                              }
                            >
                              <FontAwesomeIcon icon={faEnvelope} fixedWidth /> Request
                            </PaperPanel.Header.Menu.MenuItem>
                          )}
                          <Tooltip title={!purchaseOrder.can_void ? purchaseOrder.can_void_reason : ""}>
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={(event) => {
                                  popupState.close();
                                  setVoidDialogIsOpen(true);
                                }}
                                disabled={!purchaseOrder.can_void}
                              >
                                <FontAwesomeIcon icon={faXmark} fixedWidth /> Void
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>

                          <MenuItemHeader border />

                          <Tooltip
                            title={
                              !purchaseOrder.can_submit_to_accounting
                                ? purchaseOrder?.can_submit_to_accounting_reason
                                : ""
                            }
                          >
                            <span>
                              <PaperPanel.Header.Menu.MenuItem
                                onClick={(event) => {
                                  setSubmitDialogIsOpen(true);
                                  popupState.close();
                                }}
                                disabled={!purchaseOrder.can_submit_to_accounting}
                              >
                                <FontAwesomeIcon icon={faPaperPlaneTop} /> Submit
                              </PaperPanel.Header.Menu.MenuItem>
                            </span>
                          </Tooltip>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action ml={-2}>
                    <PaperPanel.Header.RefreshButton
                      isFetching={purchaseOrderItemsQuery.isFetching}
                      onClick={() => Promise.all([purchaseOrderItemsQuery.refetch(), purchaseOrderQuery.refetch()])}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PurchaseOrderGeneralInfoForm
                projectId={project.id}
                onSubmit={(values) => {
                  blockUI.blockUI("Saving...");

                  updatePurchaseOrder
                    .mutateAsync(values)
                    .then(() => purchaseOrderQuery.refetch())
                    .then(() => blockUI.unblockUI());
                }}
                initialValues={purchaseOrder}
                restrictNovoSigners={project.restrict_novo_signers}
                isReadOnly={purchaseOrder.is_closed}
              />
            </>
          </TabPanel>
          <TabPanel value="items">
            <Box mx={-3} mb={-3} mt={-4}>
              <PaperPanel>
                <PaperPanel.Header isLoading={purchaseOrderItemsQuery.isFetching}>
                  <PaperPanel.Header.Title>
                    <FontAwesomeIcon icon={faShoppingCart} /> {purchaseOrder.display} Items
                  </PaperPanel.Header.Title>
                  <PaperPanel.Header.Actions>
                    <PaperPanel.Header.Action>
                      <Tooltip title={purchaseOrder.is_closed ? purchaseOrder.is_closed_reason : ""}>
                        <span>
                          <PaperPanel.Header.CreateButton
                            onClick={() => setPOItemCreateDialogIsOpen(true)}
                            disabled={project.is_closed || purchaseOrder.is_closed}
                          />
                        </span>
                      </Tooltip>
                    </PaperPanel.Header.Action>
                    <PaperPanel.Header.Action>
                      <Tooltip title={purchaseOrder.is_closed ? purchaseOrder.is_closed_reaso : ""}>
                        <span>
                          <PaperPanel.Header.DeleteButton
                            onClick={() => setDeleteMultipleItemsConfirmationIsOpen(true)}
                            disabled={purchaseOrder.is_closed || selectedItemIds.size < 1}
                          />
                        </span>
                      </Tooltip>
                    </PaperPanel.Header.Action>
                    <PaperPanel.Header.Action border>
                      <PaperPanel.Header.PDFButtons
                        pdfURL={`/reports2/pos/${purchaseOrderId}/`}
                        buildReportURL={`/reports2/projects/${project.id}/pos/detail/filter/?pk=${purchaseOrderId}`}
                      />
                    </PaperPanel.Header.Action>
                    <PaperPanel.Header.Action border>
                      <PaperPanel.Header.RefreshButton
                        isFetching={purchaseOrderItemsQuery.isFetching}
                        onClick={() => Promise.all([purchaseOrderItemsQuery.refetch(), purchaseOrderQuery.refetch()])}
                      />
                    </PaperPanel.Header.Action>
                  </PaperPanel.Header.Actions>
                </PaperPanel.Header>
                <PaperPanel.Body>
                  <PaperItem.Header>
                    <PaperItem.Left>
                      <PaperItem.SelectedCheckbox
                        label={"Select All"}
                        onChange={(event, value) => {
                          if (value) {
                            addSelectedItemIds(allItemIds);
                          } else {
                            removeAllSelectedItemIds();
                          }
                        }}
                        indeterminate={
                          !allItemIdsSelected && selectedItemIds.size < allItemIds.length && selectedItemIds.size
                        }
                        checked={allItemIdsSelected}
                      />
                    </PaperItem.Left>
                    <PaperItem.Body>
                      <Grid container>
                        <Grid item md={1} xs={1}>
                          #
                        </Grid>
                        <Grid item md={5} xs={11}>
                          Description
                        </Grid>
                        <Grid item md={1} xs={2}>
                          Unit Price
                        </Grid>
                        <Grid item md={2} xs={2}>
                          Quantity
                        </Grid>
                        {/* <Grid item md={1} xs={2}>
                          UOM
                        </Grid> */}
                        <Grid item md={1} xs={3}>
                          Subtotal
                        </Grid>
                        <Grid item md={1} xs={3}>
                          Taxes
                        </Grid>
                        {/* <Grid item md={1} xs={2}>
                          Taxable
                        </Grid> */}
                        <Grid item md={1} xs={2}>
                          <Box textAlign="right">Total</Box>
                        </Grid>
                      </Grid>
                    </PaperItem.Body>
                    <PaperItem.Right minWidth={60}></PaperItem.Right>
                  </PaperItem.Header>
                  {items.map((item) => (
                    <PurchaseOrderItemPaperItem
                      key={item.id}
                      item={item}
                      isSelected={selectedItemIds.has(item.id)}
                      isReadOnly={purchaseOrder.is_closed}
                      onDelete={() =>
                        deletePurchaseOrderItem.mutateAsync(item.id).then(() => purchaseOrderQuery.refetch())
                      }
                      onEdit={() => {
                        setActivePurchaseOrderItem(item);
                        setPOItemUpdateDialogIsOpen(true);
                      }}
                      onSelectedChange={(e, value) => {
                        if (value) {
                          addSelectedItemId(item.id, e.nativeEvent.shiftKey);
                        } else {
                          removeSelectedItemId(item.id);
                        }
                      }}
                    />
                  ))}
                  <PaperItem.Header>
                    <PaperItem.Left></PaperItem.Left>
                    <Grid container>
                      <Grid item xs={11} />
                      <Grid item xs={1}>
                        <Box textAlign="right">
                          <strong>
                            <Currency number={sumBy(items, "total_price")} />
                          </strong>
                        </Box>
                      </Grid>
                    </Grid>
                    <PaperItem.Right minWidth={60}></PaperItem.Right>
                  </PaperItem.Header>
                </PaperPanel.Body>
              </PaperPanel>
            </Box>
          </TabPanel>

          <TabPanel value="timberline">
            <Box mx={-3} mb={-3} mt={-4}>
              <TimberlineCommitmentInfoPanel
                projectId={project.id}
                timberlineCommitmentId={purchaseOrder?.timberline_subcontract_id}
              />

              <Box mb={3} />
              <TimberlineCommitmentItemPanel
                projectId={project.id}
                timberlineCommitmentId={purchaseOrder?.timberline_subcontract_id}
              />

              <Box mb={3} />
              <TimberlineCommitmentChangeOrderPanel
                projectId={project.id}
                timberlineCommitmentId={purchaseOrder?.timberline_subcontract_id}
              />
            </Box>
          </TabPanel>

          <TabPanel value="amendments">
            <Box mx={-3} mb={-3} mt={-4}>
              <PaperPanel>
                <PaperPanel.Header isLoading={amendmentQuery.isFetching}>
                  <PaperPanel.Header.Title>{purchaseOrder.display} Amendments</PaperPanel.Header.Title>
                  <PaperPanel.Header.Actions>
                    <PaperPanel.Header.Action>
                      <PaperPanel.Header.CreateButton
                        onClick={() => setAmendmentCreateDialogIsOpen(true)}
                        disabled={purchaseOrder.status === "void"}
                      />
                    </PaperPanel.Header.Action>

                    <PaperPanel.Header.Action border>
                      <PaperPanel.Header.RefreshButton
                        onClick={() => amendmentQuery.refetch()}
                        isFetching={amendmentQuery.isFetching}
                      />
                    </PaperPanel.Header.Action>
                  </PaperPanel.Header.Actions>
                </PaperPanel.Header>
                <PaperPanel.Body>
                  {amendments.map((amendment) => {
                    return (
                      <AmendmentPaperItem
                        key={amendment.id}
                        amendment={amendment}
                        onEditAmendment={() => {
                          setActiveAmendment(amendment);
                          setAmendmentUpdateDialogIsOpen(true);
                        }}
                      />
                    );
                  })}
                </PaperPanel.Body>
              </PaperPanel>
            </Box>
          </TabPanel>
        </PaperPanel>
      </TabContext>

      <CreatedByModifiedBy obj={purchaseOrder} mt={2} />
      <ProjectAuditLink projectId={project.id} app="pos" model="purchaseorder" id={purchaseOrderId} mt={1} />
      {/* <LegacyUILink href={`/pos/${purchaseOrderId}/legacy/`} /> */}

      <ConfirmationDialog
        isOpen={deleteMultipleItemsConfirmationIsOpen}
        title="Are you sure?"
        onApprove={() => {
          setDeleteMultipleItemsConfirmationIsOpen(false);
          blockUI.blockUI("Deleting...");
          purchaseOrderItemRPC
            .mutateAsync({
              action: "delete",
              method: "DELETE",
              data: {ids: Array.from(selectedItemIds)},
            })
            .then(() => {
              removeAllSelectedItemIds();
              purchaseOrderItemsQuery.refetch().then(() => blockUI.unblockUI());
            });
        }}
        onDeny={() => setDeleteMultipleItemsConfirmationIsOpen(false)}
      >
        You want to delete {`${selectedItemIds.size} ${pluralize(`item`, selectedItemIds.size)}`}? This can not be
        undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={voidDialogIsOpen}
        title="Void Purchase Order?"
        onDeny={() => setVoidDialogIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Voiding...");
          purchaseOrderRPC.mutateAsync({action: "void"}).then((res) => {
            Promise.all([purchaseOrderQuery.refetch(), amendmentQuery.refetch()]).then(() => {
              blockUI.unblockUI();
              setVoidDialogIsOpen(false);
            });
          });
        }}
      >
        Void this purchase order? This will mark it as void and create a deductive change order to zero out all
        committed costs and submit it to accounting.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={submitDialogIsOpen}
        title={"Submit this Purchase Order?"}
        onApprove={() => {
          setSubmitDialogIsOpen(false);
          blockUI.blockUI("Submitting...");
          setSubmitDialogIsOpen(false);
          blockUI.blockUI("Submitting...");
          purchaseOrderRPC.mutateAsync({action: "submit"}).then(() => {
            purchaseOrderQuery.refetch().then(() => {
              blockUI.unblockUI();
            });
            purchaseOrderItemsQuery.refetch();
          });
        }}
        onDeny={() => setSubmitDialogIsOpen(false)}
      >
        Submit this PO to accounting? You will be unable to change the PO once submitted.
      </ConfirmationDialog>

      {permissions.can_import_subcontracts && (
        <ConfirmationDialog
          isOpen={requestDialogIsOpen}
          title="Send Email?"
          onApprove={() => {
            blockUI.blockUI("Requesting...");
            purchaseOrderRPC.mutateAsync({action: "request-po-submission"}).then((res) => {
              res === "success" && enqueueSnackbar("Email Sent!", {variant: "success"});

              blockUI.unblockUI();
              purchaseOrderQuery.refetch();
              setRequestDialogIsOpen(false);
            });
          }}
          onDeny={() => {
            setRequestDialogIsOpen(false);
          }}
        >
          This will send an email to {purchaseOrder?.created_by?.full_name} and request that they submit this
          subcontract.
        </ConfirmationDialog>
      )}
      <PurchaseOrderItemDialog
        ref={createItemFormRef}
        isOpen={poItemCreateDialogIsOpen}
        isNew
        onSubmit={(values, form) => {
          const submitMode = values.submitMode;
          delete values.submitMode;
          blockUI.blockUI("Creating...");

          if (submitMode === "addAnother") {
            const focusElement = createItemFormRef.current.elements.description;
            focusElement.focus();
          }

          createPurchaseOrderItem.mutateAsync(values).then(() => {
            if (submitMode === "addAnother") {
              // form.reset();
              form.restart();
            } else {
              setPOItemCreateDialogIsOpen(false);
            }
            purchaseOrderQuery.refetch();
            purchaseOrderItemsQuery.refetch();
            blockUI.unblockUI();
          });
        }}
        handleClose={() => setPOItemCreateDialogIsOpen(false)}
        initialValues={{is_taxable: true, quantity: 1, tax_rate: purchaseOrder.tax_rate}}
      />

      <PurchaseOrderItemDialog
        isOpen={poItemUpdateDialogIsOpen}
        restrictNovoSigners={project.restrict_novo_signers}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updatePurchaseOrderItem.mutateAsync(values).then(() => {
            purchaseOrderQuery.refetch();
            setPOItemUpdateDialogIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        handleClose={() => setPOItemUpdateDialogIsOpen(false)}
        initialValues={{...activePurchaseOrderItem, tax_rate: purchaseOrder.tax_rate}}
        isReadOnly={project.is_closed}
      />

      <PurchaseOrderAmendmentDialogForm
        isOpen={amendmentCreateDialogIsOpen}
        restrictNovoSigners={project.restrict_novo_signers}
        onSubmit={(values) => {
          blockUI.blockUI("Creating...");
          createAmendment.mutateAsync(values).then((res) => {
            if (values.editAfterSubmit) {
              setAmendmentCreateDialogIsOpen(false);
              window.location = res.url;
            } else {
              purchaseOrderQuery.refetch();
              amendmentQuery.refetch();
              blockUI.unblockUI();
              setAmendmentCreateDialogIsOpen(false);
            }
          });
        }}
        handleClose={() => setAmendmentCreateDialogIsOpen(false)}
        isNew
        projectId={project.id}
        initialValues={{
          budget: purchaseOrder.budget,
          tax_rate: purchaseOrder.tax_rate,
          from_company: purchaseOrder.from_company,
          from_contact: purchaseOrder.from_contact,
          to_company: purchaseOrder.to_company,
          to_contact: purchaseOrder.to_contact,
          number: amendmentQuery?.data?.metadata?.next_number,
          issued_date: moment(),
          description: `${purchaseOrder.description} - Amendment #${amendmentQuery?.data?.metadata?.next_number}`,
        }}
        purchaseOrderId={purchaseOrder.id}
      />

      <PurchaseOrderAmendmentDialogForm
        isOpen={amendmentUpdateDialogIsOpen}
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");

          updateAmendment.mutateAsync(values).then(() => {
            amendmentQuery.refetch();
            blockUI.unblockUI();
            setAmendmentUpdateDialogIsOpen(false);
          });
        }}
        handleClose={() => setAmendmentUpdateDialogIsOpen(false)}
        projectId={project.id}
        initialValues={activeAmendment}
        isReadOnly={activeAmendment.is_closed}
        purchaseOrderId={purchaseOrder.id}
      />
      {/* <pre>{JSON.stringify(purchaseOrder, null, 2)}</pre> */}
    </>
  );
};

export default PurchaseOrderDetail;
