import {map} from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Select, {components} from "react-select";
import Creatable from "react-select/creatable";
import {axiosAPI, staticDataAPI} from "../api";
import {locationInline} from "../utils/location";
import {StatusIcon} from "./Icons";
import {ProjectStatusIcon} from "./Project";
// not nested implementation
// export const getValueFromOptions = (opts, val, valueProp = "value") => {
//   return opts.find((o) => o[valueProp] === val);
// };

export const getValueFromOptions = (options, val, valueProp = "value", optionProp = "options") => {
  if (options) {
    for (let i = 0; i < options.length; i++) {
      if (options[i][valueProp] === val) {
        return options[i];
      }
      const found = getValueFromOptions(options[i][optionProp], val);
      if (found) return found;
    }
  }
};

export const defaultOptions = {
  isClearable: true,
  className: "react-select-container",
  classNamePrefix: "react-select",
  styles: {
    menu: (styles) => ({...styles, zIndex: 1500}),
  },
  // SEE: https://github.com/JedWatson/react-select/blob/master/src/theme.js
  theme: (theme) => ({
    ...theme,
    borderRadius: 0,
    spacing: {
      ...theme.spacing,
      controlHeight: 32,
    },
  }),
};

export const SimpleSelect = class extends React.Component {
  static propTypes = {
    options: PropTypes.array.isRequired,
  };

  render() {
    const {options, ...rest} = this.props;
    return (
      <Select
        {...defaultOptions}
        options={options}
        isClearable={false}
        {...rest}
        value={getValueFromOptions(options, this.props.value, "value")}
      />
    );
  }
};

export const SimpleSelectFK = (props) => {
  const {options, valueProp = "id", labelProp = "display", ...rest} = props;
  return (
    <Select
      {...defaultOptions}
      options={options}
      isClearable={false}
      getOptionValue={(option) => option[valueProp]}
      getOptionLabel={(option) => option[labelProp]}
      {...rest}
    />
  );
};
SimpleSelectFK.propTypes = {
  options: PropTypes.array.isRequired,
  valueProp: PropTypes.string,
  labelProp: PropTypes.string,
};

export const BudgetAllocationSelect = class extends React.Component {
  static propTypes = {};

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    const url = `/budgets/allocations/`;
    axiosAPI.get(url).then((response) => {
      this.setState({options: response.data.results});
    });
    // api.request(url, {
    //   success: (response) => {
    //     this.setState({options: response.results});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        placeholder="Allocation"
        {...this.props}
        value={getValueFromOptions(this.state.options, this.props.value, "value")}
      />
    );
  }
};

export const BillingTransactionCategorySelect = class extends React.Component {
  static propTypes = {};
  state = {
    options: [
      {value: "S", label: "Subcontract"},
      {value: "O", label: "Other"},
      {value: "L", label: "Labor"},
      {value: "M", label: "Material"},
      {value: "E", label: "Equipment"},
    ],
  };

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        placeholder="Category"
        {...this.props}
        value={getValueFromOptions(this.state.options, this.props.value, "value")}
      />
    );
  }
};

export const CSICodeSelect = class extends React.Component {
  static propTypes = {};

  state = {
    options: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getInitialOptions();
  }

  getInitialOptions() {
    if (this.props.value) {
      this.setState({isLoading: true});
      axiosAPI.get(`/csi-codes/search/${this.props.value.id}/`).then((response) => {
        this.setState({options: [response.data], isLoading: false});
      });
      // api.request(`/csi-codes/search/${this.props.value.id}/`, {
      //   success: (response) => {
      //     this.setState({options: [response], isLoading: false});
      //   },
      // });
    }
  }

  onInputChange(input) {
    if (input.length < 2) {
      this.setState({options: []});
      return;
    }
    this.setState({isLoading: true});
    axiosAPI.get(`/csi-codes/search/?q=${input}`).then((response) => {
      this.setState({options: response.data.results, isLoading: false});
    });
    // api.request(`/csi-codes/search/?q=${input}`, {
    //   success: (response) => {
    //     this.setState({options: response.results, isLoading: false});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        noOptionsMessage={({inputValue}) => {
          if (inputValue.length < 2) {
            return `2 characters minimum for search`;
          } else {
            return `No CSI codes found matching ${inputValue}`;
          }
        }}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.description}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>{`${data.code} - ${data.description}`}</div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>{`${data.code} - ${data.description}`}</div>
            </components.SingleValue>
          ),
        }}
        placeholder="CSI Code"
        isLoading={this.state.isLoading}
        onInputChange={(e) => this.onInputChange(e)}
        filterOption={() => true}
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "id")}
      />
    );
  }
};

export const CompanyRoleSelect = class extends React.Component {
  static propTypes = {};

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    const url = `/companies/roles/`;
    axiosAPI.get(url).then((response) => {
      this.setState({options: response.data.results});
    });
    // api.request(url, {
    //   success: (response) => {
    //     this.setState({options: response.results});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.code}
        getOptionLabel={(option) => option.description}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>{data.description}</div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>{data.description}</div>
            </components.SingleValue>
          ),
        }}
        placeholder="Role"
        {...this.props}
        value={getValueFromOptions(this.state.options, this.props.value, "code")}
      />
    );
  }
};

export const ContactRoleSelect = class extends React.Component {
  static propTypes = {
    companyRoleId: PropTypes.string,
  };

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    const {companyRoleId} = this.props;
    let category = companyRoleId ? "External" : "";
    if (["general_contractor"].includes(companyRoleId)) {
      category = "Internal";
    }
    if (["subcontractor"].includes(companyRoleId)) {
      category = "Subcontractor";
    }

    if (["owner", "client"].includes(companyRoleId)) {
      category = "Owner";
    }
    const url = `/contacts/roles/?category=${category}`;
    axiosAPI.get(url).then((response) => {
      this.setState({options: response.data.results});
    });
    // api.request(url, {
    //   success: (response) => {
    //     this.setState({options: response.results});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.code}
        getOptionLabel={(option) => option.description}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>{data.description}</div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>{data.description}</div>
            </components.SingleValue>
          ),
        }}
        placeholder="Role"
        {...this.props}
        value={getValueFromOptions(this.state.options, this.props.value, "code")}
      />
    );
  }
};

export const CompanySelect = class extends React.Component {
  static propTypes = {};

  state = {
    options: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getInitialOptions();
  }

  getInitialOptions() {
    if (this.props.value) {
      this.setState({isLoading: true});
      axiosAPI.get(url).then((response) => {
        this.setState({options: [response.data], isLoading: false});
      });
      // api.request(`/companies/search/${this.props.value}/`, {
      //   success: (response) => {
      //     this.setState({options: [response], isLoading: false});
      //   },
      // });
    }
  }

  onInputChange(input) {
    if (input.length < 3) {
      this.setState({options: []});
      return;
    }
    this.setState({isLoading: true});
    axiosAPI.get(`/companies/search/?q=${input}`).then((response) => {
      this.setState({options: response.data.results, isLoading: false});
    });
    // api.request(`/companies/search/?q=${input}`, {
    //   success: (response) => {
    //     this.setState({options: response.results, isLoading: false});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        noOptionsMessage={({inputValue}) => {
          if (inputValue.length < 3) {
            return `3 characters minimum for search`;
          } else {
            return `No companies found matching ${inputValue}`;
          }
        }}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>
                <div>{data.name}</div>
                <small className="text-muted">{data.lookup_code}</small>
              </div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>
                {data.name} - <small className="text-muted">{data.lookup_code}</small>
              </div>
            </components.SingleValue>
          ),
        }}
        placeholder="Company"
        isLoading={this.state.isLoading}
        onInputChange={(e) => this.onInputChange(e)}
        filterOption={() => true}
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "id")}
      />
    );
  }
};

export const CompanyLocationSelect = class extends React.Component {
  static propTypes = {
    companyId: PropTypes.number,
  };

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions(this.props.companyId);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.companyId !== nextProps.companyId) {
      this.getOptions(nextProps.companyId);
    }
  }

  getOptions(companyId) {
    if (!companyId) {
      return;
    }
    const url = `/companies/${companyId}/locations/?ordering=description&no_deleted=True`;
    axiosAPI.get(url).then((response) => {
      this.setState({options: response.data.results});
    });
    // api.request(url, {
    //   success: (response) => {
    //     this.setState({options: response.results});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.description}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>
                {data.description ? `${data.description} - ${locationInline(data)}` : `${locationInline(data)}`}
              </div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>
                {data.description ? `${data.description} - ${locationInline(data)}` : `${locationInline(data)}`}
              </div>
            </components.SingleValue>
          ),
        }}
        placeholder="Location"
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "id")}
      />
    );
  }
};

export const ContactSelect = class extends React.Component {
  static propTypes = {};

  state = {
    options: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getInitialOptions();
  }

  getInitialOptions() {
    if (this.props.value) {
      this.setState({isLoading: true});
      axiosAPI.get(`/contacts/search/${this.props.value}/`).then((response) => {
        this.setState({options: [response.data], isLoading: false});
      });
      // api.request(`/contacts/search/${this.props.value}/`, {
      //   success: (response) => {
      //     this.setState({options: [response], isLoading: false});
      //   },
      // });
    }
  }

  onInputChange(input) {
    if (input.length < 3) {
      this.setState({options: []});
      return;
    }
    this.setState({isLoading: true});
    axiosAPI.get(`/contacts/search/?q=${input}`).then((response) => {
      this.setState({options: response.data.results, isLoading: false});
    });
    // api.request(`/contacts/search/?q=${input}`, {
    //   success: (response) => {
    //     this.setState({options: response.results, isLoading: false});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        noOptionsMessage={({inputValue}) => {
          if (inputValue.length < 3) {
            return `3 characters minimum for search`;
          } else {
            return `No contacts found matching ${inputValue}`;
          }
        }}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.full_name}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>
                {data.full_name} - <small className="text-muted">{data.company.name}</small>
              </div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>
                {data.full_name} - <small className="text-muted">{data.company.name}</small>
              </div>
            </components.SingleValue>
          ),
        }}
        placeholder="Contact"
        isLoading={this.state.isLoading}
        onInputChange={(e) => this.onInputChange(e)}
        filterOption={() => true}
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "id")}
      />
    );
  }
};

export const ProjectCompanySelect = class extends React.Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
  };

  state = {
    options: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getOptions("");
  }

  getOptions(input) {
    this.setState({isLoading: true});
    let value = this.props.value.id | "";
    if (this.props.value && this.props.value.constructor === Array) {
      value = map(this.props.value, "id");
    }
    axiosAPI
      .get(`/projects/${this.props.projectId}/companies/search/?q=${input}&selected=${value}`)
      .then((response) => {
        this.setState({options: response.data.results, isLoading: false});
      });
    // api.request(`/projects/${this.props.projectId}/companies/search/?q=${input}&selected=${value}`, {
    //   success: (response) => {
    //     this.setState({options: response.results, isLoading: false});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.name}
        noOptionsMessage={({inputValue}) => `No companies found matching ${inputValue}`}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>
                <div>{data.name}</div>
                <small className="text-muted">{data.lookup_code}</small>
                {data.role && (
                  <div>
                    <small className="text-muted">{data.role}</small>
                  </div>
                )}
              </div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>
                {data.name} - <small className="text-muted">{data.lookup_code}</small>
              </div>
            </components.SingleValue>
          ),
        }}
        placeholder="Company"
        isLoading={this.state.isLoading}
        onInputChange={(e) => this.getOptions(e)}
        filterOptions={false}
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, 'id')}
      />
    );
  }
};

export const ProjectContactSelect = class extends React.Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
    companyId: PropTypes.number,
    maxOptions: PropTypes.number,
    baseURL: PropTypes.string,
  };

  static defaultProps = {};

  state = {
    options: [],
    companyId: this.props.companyId,
    isLoading: false,
  };

  componentDidMount() {
    this.getOptions("");
  }

  getOptions(input) {
    this.setState({isLoading: true});
    let value = this.props.value | "";
    if (this.props.value && this.props.value.constructor === Array) {
      value = map(this.props.value, "id");
    }
    let url = this.props?.baseURL
      ? `${this.props.baseURL}?q=${input}&selected=${value}`
      : `/projects/${this.props.projectId}/contacts/search/?q=${input}&selected=${value}`;
    if (this.state.companyId) {
      url += `&company_id=${this.state.companyId}`;
    }
    axiosAPI.get(url).then((response) => {
      this.setState({options: response.data.results, isLoading: false});
    });
    // api.request(url, {
    //   success: (response) => {
    //     this.setState({options: response.results, isLoading: false});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.props.maxOptions && this.props.maxOptions === this.props.value.length ? [] : this.state.options}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.full_name}
        noOptionsMessage={({inputValue}) => {
          if (this.props.maxOptions && this.props.maxOptions === this.props.value.length) {
            return `You can only select ${this.props.maxOptions} contacts.`;
          }
          return `No contacts found matching ${inputValue}`;
        }}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>
                <div>{data.full_name}</div>
                <small className="text-muted">{data.company_name || data.company?.name}</small>
              </div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>
                {data.full_name} - <small className="text-muted">{data.company_name || data.company?.name}</small>
              </div>
            </components.SingleValue>
          ),
          MultiValue: ({data, ...rest}) => (
            <components.MultiValue {...rest}>
              <div>
                {data.full_name} - <small className="text-muted">{data.company_name || data.company?.name}</small>
              </div>
            </components.MultiValue>
          ),
        }}
        placeholder="Contact"
        isLoading={this.state.isLoading}
        onInputChange={(e) => this.getOptions(e)}
        filterOption={() => true}
        {...this.props}
        onChange={(value, event) => {
          let customEvent = new CustomEvent("onChange");
          if (event.action === "select-option") {
            if (this.props.onAdd) {
              // customEvent.type = 'onAdd';
              customEvent = new CustomEvent("onAdd");
              customEvent.contact = event.option;
              this.props.onAdd(event.option, customEvent);
            }
            // console.log('onAdd', event.option)
          }
          if (event.action === "remove-value") {
            if (this.props.onRemove) {
              customEvent = new CustomEvent("onRemove");
              customEvent.contact = event.removedValue;
              this.props.onRemove(event.removedValue, customEvent);
            }
          }
          console.log(customEvent);
          if (this.props.onChange && customEvent.defaultPrevented !== true) {
            this.props.onChange(value);
          }
        }}
      />
    );
  }
};

export const CompanyContactSelect = class extends React.Component {
  static propTypes = {
    companyId: PropTypes.number.isRequired,
    page_size: PropTypes.number,
  };

  static defaultProps = {
    page_size: 1000,
  };

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    const url = `/contacts/?company_id=${this.props.companyId}&page_size=${this.props.page_size}`;
    axiosAPI.get(url).then((response) => {
      this.setState({options: response.data.results});
    });
    // api.request(url, {
    //   success: (response) => {
    //     this.setState({options: response.results});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.full_name}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>
                <div>{data.full_name}</div>
                <small className="text-muted">{data.email}</small>
                {data.role && (
                  <div>
                    <small className="text-muted">{data.role}</small>
                  </div>
                )}
              </div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>
                {data.full_name} - <small className="text-muted">{data.email}</small>
              </div>
            </components.SingleValue>
          ),
        }}
        placeholder="Contact"
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "id")}
      />
    );
  }
};

export const PCCOSelect = class extends React.Component {
  static propTypes = {
    projectId: PropTypes.number,
  };

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    if (!this.props.projectId) {
      return;
    }
    const url = `/projects/${this.props.projectId}/pccos/`;
    axiosAPI.get(url).then((response) => {
      this.setState({options: response.data.results});
    });
    // api.request(url, {
    //   success: (response) => {
    //     console.log(response);
    //     this.setState({options: response.results});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.display}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>{`${data.display}`}</div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>{`${data.display}`}</div>
            </components.SingleValue>
          ),
        }}
        placeholder="Owner Change Order"
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "id")}
      />
    );
  }
};

export const DivisionStaticSelect = class extends React.Component {
  static propTypes = {};

  static defaultProps = {};

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    staticDataAPI.get(`novo_divisions.json`).then((response) => {
      this.setState({options: response.data});
    });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.display}
        placeholder="Division"
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "value")}
      />
    );
  }
};

export const CSICodeStaticSelect = class extends React.Component {
  static propTypes = {
    allowDivisions: PropTypes.bool,
  };

  static defaultProps = {};

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    const jsonFile = this.props.allowDivisions ? `novo_division_and_csi_codes.json` : `novo_csi_codes.json`;
    staticDataAPI.get(jsonFile).then((response) => {
      this.setState({options: response.data});
    });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.display}
        placeholder="CSI Code"
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "value")}
      />
    );
  }
};

export const ProjectSelect = class extends React.Component {
  static propTypes = {};
  static defaultProps = {
    pageSize: 10,
    renderValue: (option) => {
      return (
        <div>
          <i className={ProjectStatusIcon.getClassName(option.status)} /> {option.display}
        </div>
      );
    },
    renderOption: (option) => {
      return (
        <div>
          <i className={ProjectStatusIcon.getClassName(option.status)} /> {option.display}
        </div>
      );
    },
    apiURL: "/projects/search/?ordering=status_sort",
  };

  state = {
    options: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getInitialOptions();
  }

  getInitialOptions() {
    if (this.props.value) {
      this.setState({isLoading: true});
      axiosAPI.get(`/projects/search/${this.props.value}/`).then((response) => {
        this.setState({options: [response.data], isLoading: false});
      });
      // api.request(`/projects/search/${this.props.value}/`, {
      //   success: (response) => {
      //     this.setState({options: [response], isLoading: false});
      //   },
      // });
    }
  }

  onInputChange(input) {
    if (input.length < 3) {
      return;
    }
    this.setState({isLoading: true});
    axiosAPI.get(`${this.props.apiURL}&page_size=${this.props.pageSize}&q=${input}`).then((response) => {
      this.setState({options: response.data.results, isLoading: false});
    });
    // api.request(`${this.props.apiURL}&page_size=${this.props.pageSize}&q=${input}`, {
    //   success: (response) => {
    //     this.setState({options: response.results, isLoading: false});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        noOptionsMessage={({inputValue}) => {
          if (inputValue.length < 3) {
            return `3 characters minimum for search`;
          } else {
            return `No projects found matching ${inputValue}`;
          }
        }}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.display}
        components={{
          Option: ({data, ...rest}) => <components.Option {...rest}>{this.props.renderOption(data)}</components.Option>,
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>{this.props.renderValue(data)}</components.SingleValue>
          ),
        }}
        placeholder="Project"
        isLoading={this.state.isLoading}
        onInputChange={(e) => this.onInputChange(e)}
        filterOption={() => true}
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "id")}
      />
    );
  }
};

export const TimberlineJobSelect = class extends React.Component {
  static propTypes = {
    showAll: PropTypes.bool,
  };
  static defaultProps = {
    pageSize: 10,
    showAll: false,
    renderValue: (option) => {
      return (
        <div>
          <i className={ProjectStatusIcon.getClassName(option.status)} /> {option.display}
        </div>
      );
    },
    renderOption: (option) => {
      return (
        <div>
          <i className={ProjectStatusIcon.getClassName(option.status)} /> {option.display}
        </div>
      );
    },
    apiURL: `/timberline/jobs/search/?ordering=-status,job_code,description`,
  };

  state = {
    options: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getInitialOptions();
  }

  getInitialOptions() {
    if (this.props.value) {
      this.setState({isLoading: true});
      axiosAPI.get(`/timberline/jobs/search/${this.props.value}/?show_all=True`).then((response) => {
        this.setState({options: [response.data], isLoading: false});
      });
      // api.request(`/timberline/jobs/search/${this.props.value}/?show_all=True`, {
      //   success: (response) => {
      //     this.setState({options: [response], isLoading: false});
      //   },
      // });
    }
  }

  onInputChange(input) {
    if (input.length < 3) {
      return;
    }
    let url = `${this.props.apiURL}&page_size=${this.props.pageSize}&q=${input}`;
    if (this.props.showAll) {
      url += "&show_all=True";
    }
    this.setState({isLoading: true});
    axiosAPI.get(url).then((response) => {
      this.setState({options: response.data.results, isLoading: false});
    });
    // api.request(url, {
    //   success: (response) => {
    //     this.setState({options: response.results, isLoading: false});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        noOptionsMessage={({inputValue}) => {
          if (inputValue.length < 3) {
            return `3 characters minimum for search`;
          } else {
            return `No jobs found matching ${inputValue}`;
          }
        }}
        getOptionValue={(option) => option.job_code}
        getOptionLabel={(option) => option.display}
        components={{
          Option: ({data, ...rest}) => <components.Option {...rest}>{this.props.renderOption(data)}</components.Option>,
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>{this.props.renderValue(data)}</components.SingleValue>
          ),
        }}
        placeholder="Job"
        isLoading={this.state.isLoading}
        onInputChange={(e) => this.onInputChange(e)}
        filterOption={() => true}
        {...this.props}
        value={getValueFromOptions(this.state.options, this.props.value, "job_code")}
      />
    );
  }
};

export const UserSelect = class extends React.Component {
  static propTypes = {
    onlyActive: PropTypes.bool,
  };

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    let url = `/users/?ordering=first_name&page_size=1000&id__gt=0`;
    if (this.props.onlyActive) {
      url += `&is_active=True`;
    }
    axiosAPI.get(url).then((response) => {
      this.setState({options: response.data.results});
    });
    // api.request(url, {
    //   success: (response) => {
    //     this.setState({options: response.results});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.full_name}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>{data.full_name}</div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>{data.full_name}</div>
            </components.SingleValue>
          ),
        }}
        placeholder="User"
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "id")}
      />
    );
  }
};

export const TagSelect = class extends React.Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
  };

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    let url = `/projects/${this.props.projectId}/tags/`;
    axiosAPI.get(url).then((response) => {
      const options = response.data.map((tag) => {
        return {value: tag, label: tag};
      });
      this.setState({options: options});
    });
    // api.request(url, {
    //   success: (response) => {
    //     const options = response.map((tag) => {
    //       return {value: tag, label: tag};
    //     });
    //     this.setState({options: options});
    //   },
    // });
  }

  render() {
    return (
      <Creatable
        {...defaultOptions}
        options={this.state.options}
        placeholder="Tags"
        isMulti={true}
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value)}
      />
    );
  }
};

export const DrawingSelect = class extends React.Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
  };

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    let url = `/projects/${this.props.projectId}/drawings/`;
    axiosAPI.get(url).then((response) => {
      const options = response.data.map((drawing) => {
        return {value: drawing, label: drawing};
      });
      this.setState({options: options});
    });
    // api.request(url, {
    //   success: (response) => {
    //     const options = response.map((drawing) => {
    //       return {value: drawing, label: drawing};
    //     });
    //     this.setState({options: options});
    //   },
    // });
  }

  render() {
    return (
      <Creatable
        {...defaultOptions}
        options={this.state.options}
        placeholder="Drawing"
        isMulti={true}
        {...this.props}
      />
    );
  }
};

export const ProjectGroupSelect = class extends React.Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
  };

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    let url = `/projects/${this.props.projectId}/groups/`;
    axiosAPI.get(url).then((response) => {
      const options = response.data.results.map((group) => {
        return {value: group.id, label: group.name};
      });
      this.setState({options: options});
    });
    // api.request(url, {
    //   success: (response) => {
    //     const options = response.results.map((group) => {
    //       return {value: group.id, label: group.name};
    //     });
    //     this.setState({options: options});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        placeholder="Group"
        {...this.props}
        // value={getValueFromOptions(this.state.options, this.props.value, "value")}
      />
    );
  }
};

export const SimpleLookupSelect = class extends React.Component {
  static propTypes = {
    url: PropTypes.string.isRequired,
    getIcon: PropTypes.func,
  };

  state = {
    options: [],
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    axiosAPI.get(this.props.url).then((response) => {
      this.setState({options: response.data.results});
    });
    // api.request(this.props.url, {
    //   success: (response) => {
    //     this.setState({options: response.results});
    //   },
    // });
  }

  render() {
    const {getIcon} = this.props;
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        getOptionValue={(option) => option.value}
        getOptionLabel={(option) => option.label}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>
                {getIcon && getIcon(data.value)} {data.label}
              </div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>
                {getIcon && getIcon(data.value)} {data.label}
              </div>
            </components.SingleValue>
          ),
        }}
        {...this.props}
        value={getValueFromOptions(this.state.options, this.props.value)}
      />
    );
  }
};

export const SubmittalPackageSelect = class extends React.Component {
  static propTypes = {
    projectId: PropTypes.number.isRequired,
    isItemDisabled: PropTypes.func,
  };

  state = {
    options: [],
    isLoading: false,
  };

  componentDidMount() {
    this.getOptions();
  }

  getOptions() {
    this.setState({isLoading: true});
    let url = `/projects/${this.props.projectId}/submittals/packages/?page_size=10000&parent__isnull=True`;
    axiosAPI.get(url).then((response) => {
      if (this.props.isItemDisabled) {
        const options = response.data.results.map((item) => {
          item.isDisabled = this.props.isItemDisabled(item);
          return item;
        });
        this.setState({options: options, isLoading: false});
      } else {
        this.setState({options: response.data.results, isLoading: false});
      }
    });
    // api.request(url, {
    //   success: (response) => {
    //     console.log("isItemDisabled", this.props.isItemDisabled);
    //     if (this.props.isItemDisabled) {
    //       const options = response.results.map((item) => {
    //         item.isDisabled = this.props.isItemDisabled(item);
    //         return item;
    //       });
    //       this.setState({options: options, isLoading: false});
    //     } else {
    //       this.setState({options: response.results, isLoading: false});
    //     }
    //
    //     // const options = response.results.map(submittal_package => {
    //     //   return {value: submittal_package.id, label: submittal_package.display}
    //     // });
    //     // this.setState({options: options, isLoading: false});
    //   },
    // });
  }

  render() {
    return (
      <Select
        {...defaultOptions}
        options={this.state.options}
        placeholder="Submittal Package"
        getOptionValue={(option) => option.id}
        getOptionLabel={(option) => option.display}
        components={{
          Option: ({data, ...rest}) => (
            <components.Option {...rest}>
              <div>
                <StatusIcon status={data.status} /> {data.display}
              </div>
            </components.Option>
          ),
          SingleValue: ({data, ...rest}) => (
            <components.SingleValue {...rest}>
              <div>
                <StatusIcon status={data.status} /> {data.display}
              </div>
            </components.SingleValue>
          ),
        }}
        {...this.props}
        isLoading={this.state.isLoading}
        // onInputChange={(e) => this.getOptions(e)}
        value={getValueFromOptions(this.state.options, this.props.value, "id")}
      />
    );
  }
};

export const DisciplineSelect = class extends React.Component {
  render() {
    return <SimpleLookupSelect {...this.props} url="/lookups/discipline/" placeholder="Discipline" />;
  }
};

export const ImportanceSelect = class extends React.Component {
  render() {
    return (
      <SimpleLookupSelect
        {...this.props}
        url="/lookups/importance/"
        placeholder="Importance"
        getIcon={(importance) => {
          switch (importance) {
            case "low":
              return <i className="fa fa-info-circle status-color-green" />;
            case "normal":
              return <i className="fa fa-info-circle status-color-blue" />;
            case "high":
              return <i className="fa fa-exclamation-circle status-color-yellow" />;
            case "urgent":
              return <i className="fa fa-exclamation-circle status-color-red" />;
            case "":
              return none;
            default:
              return <i className="fa fa-circle" />;
          }
        }}
      />
    );
  }
};
