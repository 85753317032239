import {faBasketball, faCircleNotch} from "@fortawesome/pro-regular-svg-icons";
import {faBroom, faFolderGear, faLink, faList, faPaperPlane} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Button, Grid, MenuItem, Switch, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import EmailIcon from "@material-ui/icons/Email";
import GroupIcon from "@material-ui/icons/Group";
import GroupAddIcon from "@material-ui/icons/GroupAdd";
import LinkIcon from "@material-ui/icons/Link";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import {Alert, TabContext, TabPanel} from "@material-ui/lab";
import {groupBy, isEmpty, uniq} from "lodash";
import moment from "moment";
import {useSnackbar} from "notistack";
import qs from "qs";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate, useParams} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {axiosAPI} from "../../../api";
import ActivityStream from "../../../components/ActivityStream";
import {BallInCourtForm} from "../../../components/BallInCourtForms";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {SplitButton} from "../../../components/Buttons";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {SendEmailDialogForm} from "../../../components/EmailForms";
import ExternalLinks from "../../../components/ExternalLinks";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSelect} from "../../../components/Filters";
import Followers from "../../../components/Followers";
import BlockUI from "../../../components/GlobalLoaders";
import {ProjectGroupSelectDialogForm} from "../../../components/GroupForms";
import {StatusIcon} from "../../../components/Icons";
import {LegacyUILink, MuiNavLink, PortalLink, ProjectAuditLink} from "../../../components/Links";
import MailLog from "../../../components/MailLog";
import {MenuItemHeader} from "../../../components/Menu";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {StatusLabel} from "../../../components/Status";
import {PreviousNextTabs, TabCountChip} from "../../../components/Tabs";
import Typography2 from "../../../components/Typography2";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, useSentinelDetailAPI, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import getChoicesFromObjectList from "../../../utils/getChoicesFromObjectList";
import SubmittalTransmittalPaperItem from "./SubmittalTransmittalPaperItem";
// import TransmittalPaperItem from "../Transmittals/TransmittalPaperItem";
import CreatedByModifiedBy from "../../../components/CreatedByModifiedBy";
import {useLocalStorage} from "../../../hooks/useLocalStorage";
import {SubmittalItemUpdateDialogForm} from "./SubmittalItemForms";
import {
  SubmittalItemReviewBulkUpdateForm,
  SubmittalItemReviewBulkUpdateStatusForm,
  SubmittalItemReviewPaperItemForm,
  SubmittalItemReviewUpdateDialogForm,
} from "./SubmittalItemReviewForms";
import {
  LinkSubmittalItemsDialog,
  SubmittalPackageCustomDataForm,
  SubmittalPackageUpdateForm,
} from "./SubmittalPackageForms";
import {SubmittalTransmittalDialogForm} from "./SubmittalTransmittalForms";

const SubmittalPackageDetail = (props) => {
  const {project, isNew = false, isReadOnly = project.is_closed, userContact, ...rest} = props;

  const {enqueueSnackbar} = useSnackbar();
  const {submittalId} = useParams();

  const [selectedTab, setSelectedTab] = useQueryState("tab", "detail");

  const [packageInitialValues, setPackageInitialValues] = React.useState({
    approver_contacts: [],
    cc_contacts: [],
  });

  const [initialValuesHack, setInitialValuesHack] = React.useState(Math.random());
  const [showSelectGroupDialog, setShowSelectGroupDialog] = React.useState(false);
  const [showEmailLinkDialog, setShowEmailLinkDialog] = React.useState(false);
  const [showSubmittalItemCreateDialogForm, setShowSubmittalItemCreateDialogForm] = React.useState(false);
  const [showSubmittalItemReviewUpdateDialogForm, setShowSubmittalItemReviewUpdateDialogForm] = React.useState(false);
  const [showCreateTransmittalDialog, setShowCreateTransmittalDialog] = React.useState(false);
  const [showUpdateTransmittalDialog, setShowUpdateTransmittalDialog] = React.useState(false);
  const [showBulkUpdateForm, setShowBulkUpdateForm] = React.useState(false);
  const [showBulkUpdateStatusForm, setShowBulkUpdateStatusForm] = React.useState(false);
  const [activeReview, setActiveReview] = React.useState({} as any);
  const [activeTransmittal, setActiveTransmittal] = React.useState({} as any);
  const [showNotesInline, setShowNotesInline] = useLocalStorage("showSubmittalPackageItemNotesInline", false);
  const [deleteReviewersConfirmationIsOpen, setDeleteReviewersConfirmationIsOpen] = React.useState(false);
  const [formValues, setFormValues] = React.useState({});
  const [transmittalTo, setTransmittalTo] = React.useState({});
  const [showCreateForRecordRevisionConfirmation, setShowCreateForRecordRevisionConfirmation] = React.useState(false);
  const [initialFocusField, setInitialFocusField] = React.useState(null);
  const [linkSubmittalItemsDialogIsOpen, setLinkSubmittalItemsDialogIsOpen] = React.useState(false);

  const blockUI = useBlockUI();

  const novoClasses = makeNovoClasses();

  const pageDataQuery = useFetchCurrentPage({
    refetchOnWindowFocus: false,
    initialData: {
      previous: null,
      next: null,
      linkableItemsCount: 0,
    },
  });

  const pageData = pageDataQuery.data;

  const [itemFilterOptions, setItemFilterOption, clearItemFilterOption, clearAllItemFilterOptions] = useFilterOptions([
    "Status",
    "Item",
  ]);

  const itemFilterParams = {
    status: itemFilterOptions.Status.value,
    id: itemFilterOptions.Item.value,
    // contact: filterOptions.Contact.value,
  };
  const [reviewFilterOptions, setReviewFilterOption, clearReviewFilterOption, clearAllReviewFilterOptions] =
    useFilterOptions(["Status", "Item", "Contact"]);

  const reviewFilterParams = {
    status: reviewFilterOptions.Status.value,
    submittal_item_id: reviewFilterOptions.Item.value,
    contact_id: reviewFilterOptions.Contact.value,
  };

  const {query: packageContactsQuery} = useSentinelListAPI(
    ["projects", project.id, "submittals/packages", parseInt(submittalId), "contacts"],
    {
      initialData: {
        results: [],
      },
    }
  );

  const packageContacts = packageContactsQuery.data.results;

  const ccContacts = packageContacts.filter((item) => item.role === "cc").map((item) => item.contact);
  const approverContacts = packageContacts.filter((item) => item.role === "approver").map((item) => item.contact);

  const {
    query: submittalItemsQuery,
    create: createSubmittalItem,
    update: updateSubmittalItem,
    delete: deleteSubmittalItem,
    rpc: submittalItemRPC,
    // onDragEnd: purchaseOrderOnDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/submittals/packages/${parseInt(submittalId)}/items/?page_size=10000&${qs.stringify(
      itemFilterParams
    )}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );
  const submittalItems = submittalItemsQuery.data.results;

  const {
    query: reviewsQuery,
    create: createReview,
    update: updateReview,
    delete: deleteReview,
    rpc: reviewRPC,
    // onDragEnd: purchaseOrderOnDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/submittals/packages/${parseInt(
      submittalId
    )}/reviews/?parent__isnull=True&page_size=10000&${qs.stringify(reviewFilterParams)}
    `,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );
  const reviews = reviewsQuery.data.results;

  const allReviewIds = reviews.map((review) => review.id);

  const {
    selectedIds: selectedReviewIds,
    addSelectedId: addSelectedReviewId,
    addSelectedIds: addSelectedReviewIds,
    removeSelectedId: removeSelectedReviewId,
    removeSelectedIds: removeSelectedReviewIds,
    addAllSelectedIds: addAllSelectedReviewIds,
    removeAllSelectedIds: removeAllSelectedReviewIds,
    allIdsSelected: allReviewsSelected,
  } = useSelectIds(allReviewIds);

  const {
    query: submittalPackageQuery,
    update: updateSubmittalPackage,
    delete: deleteSubmittalPackage,
    rpc: submittalPackageRPC,
  } = useSentinelDetailAPI(["projects", project.id, "submittals/packages", parseInt(submittalId)], {
    initialData: {},
  });
  const submittalPackage = submittalPackageQuery.data;

  const {
    query: transmittalQuery,
    update: updateTransmittal,
    create: createTransmittal,
    rpc: transmittalRPC,
    delete: deleteTransmittal,
  } = useSentinelListAPI(
    `projects/${project.id}/submittals/packages/${parseInt(submittalId)}/transmittals/?page_size=10000`
  );
  const transmittals = transmittalQuery.data?.results;

  const {query: followersQuery} = useSentinelListAPI(`followers/submittals/submittalpackage/${parseInt(submittalId)}/`);
  const followers = followersQuery.data;

  React.useEffect(() => {
    setPackageInitialValues({...submittalPackage, cc_contacts: ccContacts, approver_contacts: approverContacts});
    removeAllSelectedReviewIds();
  }, [submittalPackageQuery.isFetchedAfterMount, packageContactsQuery.isFetchedAfterMount, initialValuesHack]);

  const groupReviews = (itemId) => {
    const sortedReviews = groupBy(reviews, "submittal_item_id");
    return sortedReviews[itemId] || [];
  };

  const handleReviewUpdate = React.useCallback((newValue, values) => {
    // const review = {...values, id};
    const updatePayload = {
      id: values.id,
      status: values.status,
      company_submittal_stamp_choice: values.company_submittal_stamp_choice,
      returned_date: values.returned_date,
      ...newValue,
    };
    blockUI.blockUI("Updating review...");
    updateReview.mutateAsync(updatePayload).then(() => {
      Promise.all([submittalItemsQuery.refetch(), submittalPackageQuery.refetch()]).then(() => {
        blockUI.unblockUI();
        setInitialValuesHack(Math.random());
      });
    });
  }, []);

  if (!submittalPackageQuery.isFetchedAfterMount || !pageDataQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching submittal package info..." />;
  }

  const selectedCompaniesWithStamps: number[] | [] = uniq(
    uniq(
      reviews
        .filter((review) => selectedReviewIds.has(review.id) && review.company_submittal_stamp !== null)
        .map((review) => review.company_submittal_stamp.company)
    )
  );

  const selectedCompaniesWithoutStamps: number[] | [] = uniq(
    reviews
      .filter((review) => selectedReviewIds.has(review.id) && review.company_submittal_stamp === null)
      .map((review) => review.contact.company.id)
  );

  const submittalItemReviewStatusFilterChoices = getChoicesFromObjectList(reviews, "status", "status_display");
  const submittalItemReviewItemFilterChoices = getChoicesFromObjectList(submittalItems, "id", "display");
  const submittalItemReviewContactFilterChoices = getChoicesFromObjectList(
    reviews.map((review) => review.contact),
    "id",
    "full_name"
  );

  const bulkUpdateStatusField = (): string | number => {
    if (selectedCompaniesWithStamps.length === 0) {
      return "status";
    }
    if (selectedCompaniesWithStamps.length > 1) {
      return "disabled";
    }
    if (selectedCompaniesWithStamps.length === 1 && selectedCompaniesWithoutStamps.length > 0) {
      return "disabled";
    }
    if (selectedCompaniesWithStamps.length === 1 && selectedCompaniesWithoutStamps.length === 0) {
      return selectedCompaniesWithStamps[0];
    }
  };

  return (
    <>
      <Helmet title={`${submittalPackage.display}`} />
      <ProjectBreadcrumbs project={project}>
        <MuiNavLink to={`/v2/projects/${project.id}/submittals/packages/`}>
          <Typography color="textSecondary">Submittal Packages</Typography>
        </MuiNavLink>

        {/* <Link href={submittalPackage?.url}>
          <Typography color="textSecondary">{submittalPackage?.display}</Typography>
        </Link> */}

        <Typography color="textPrimary">{submittalPackage.display}</Typography>
      </ProjectBreadcrumbs>

      {/* <pre>{JSON.stringify(reviews, null, 2)}</pre> */}
      {pageDataQuery.data.currentRevisionURL && (
        <Box mb={2}>
          <Alert severity="info">
            This is a prior revision of this submittal package.{" "}
            <MuiNavLink to={pageDataQuery.data.currentRevisionURL} underline="always">
              Click here
            </MuiNavLink>{" "}
            to go to the current revision.
          </Alert>
        </Box>
      )}

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    {submittalPackageQuery.isFetchedAfterMount ? (
                      <StatusIcon status={submittalPackage.status} showTooltip type="submittal" />
                    ) : (
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    )}
                    <Box mr={1} />
                    Detail
                  </Box>
                }
                value="detail"
                className={novoClasses.smallTab}
              />
              <Tab
                value="items"
                className={novoClasses.smallTab}
                label={
                  <Box display="flex" alignItems="center">
                    Items/Approval
                    <TabCountChip
                      isLoading={!submittalItemsQuery.isFetchedAfterMount || !reviewsQuery.isFetchedAfterMount}
                      count={`${submittalItemsQuery.data?.count}/${reviewsQuery.data?.count}`}
                    />
                  </Box>
                }
              />

              <Tab label="Ball In Court" value="ballInCourt" className={novoClasses.smallTab} />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Transmittals
                    <TabCountChip
                      isLoading={!transmittalQuery.isFetchedAfterMount}
                      count={transmittalQuery.data?.count}
                    />
                  </Box>
                }
                value="transmittals"
                className={novoClasses.smallTab}
              />
              <Tab label="External Links" value="externalLinks" className={novoClasses.smallTab} />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Followers
                    <TabCountChip isLoading={!followersQuery.isFetchedAfterMount} count={followersQuery.data?.count} />
                  </Box>
                }
                value="followers"
                className={novoClasses.smallTab}
              />
              <Tab label="Custom Data" value="customData" className={novoClasses.smallTab} />
              <Tab label="Logs" value="logs" className={novoClasses.smallTab} />
              {props.user.is_superuser && <Tab label="Debug" value="debug" className={novoClasses.smallTab} />}

              <PreviousNextTabs previous={pageDataQuery.data.previous} next={pageDataQuery.data.next} />
            </Tabs>
          </AppBar>
          <Box mb={2} />
          <PaperPanel.Body>
            <TabPanel value="detail">
              <PaperPanel.TabHeader isLoading={submittalPackageQuery.isFetching || packageContactsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <StatusLabel status={submittalPackage?.status_display} width={100} hint="submittalPackage" />{" "}
                  {submittalPackage?.description}
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButtons>
                      <PaperPanel.Header.Menu.PDFMenuItem
                        href={`/reports2/submittals/package/${submittalPackage.id}/?display_reviewers=on`}
                      >
                        Without Reviewer Comments
                      </PaperPanel.Header.Menu.PDFMenuItem>
                      <PaperPanel.Header.Menu.PDFMenuItem
                        href={`/reports2/submittals/package/${submittalPackage.id}/?display_reviewers=on&display_reviewer_notes=on`}
                      >
                        With Reviewer Comments
                      </PaperPanel.Header.Menu.PDFMenuItem>
                    </PaperPanel.Header.PDFButtons>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.BuildReportButton
                      href={`/reports2/projects/${project.id}/submittals/packages/detail/filter/?pk=${submittalPackage.id}`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RevisionsButtons
                      url={`projects/${project.id}/submittals/packages/${submittalPackage.id}/revisions/`}
                      obj={submittalPackage}
                      confirmationMessage="You want to create a new revision for this submittal package.  This will copy all non approved items to the new revision."
                      onCreateRevision={() => {
                        blockUI.blockUI("Creating new revision...");
                        submittalPackageRPC
                          .mutateAsync({action: "revisions"})
                          .then((response) => {
                            // navigate(response.url);
                            window.location = response.url;
                            blockUI.unblockUI();
                          })
                          .catch(() => blockUI.unblockUI());
                      }}
                    >
                      {pageDataQuery.data.canCreateRorRecordRevision && (
                        <MenuItem onClick={() => setShowCreateForRecordRevisionConfirmation(true)}>
                          Create For Record Revision
                        </MenuItem>
                      )}
                    </PaperPanel.Header.RevisionsButtons>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.BoxFilesButton
                      href={`/v2/projects/${project.id}/box/files/submittals/submittalpackage/${submittalPackage.id}/`}
                      uploadURL={`/projects/${project.id}/submittals/packages/${submittalPackage.id}/upload-to-box/`}
                    />
                  </PaperPanel.Header.Action>
                  {project.fieldwire_project_id && (
                    <PaperPanel.Header.Action border>
                      <PaperPanel.Header.FieldwireUploadButton
                        uploadURL={`/projects/${project.id}/submittals/packages/${submittalPackage.id}/upload-to-fieldwire/`}
                        fieldwireFileId={submittalPackage.fieldwire_file_id}
                        onUploadSuccess={() => {
                          submittalPackageQuery.refetch();
                        }}
                      />
                    </PaperPanel.Header.Action>
                  )}

                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={submittalItemsQuery.isFetching || packageContactsQuery.isFetching}
                      onClick={() => {
                        Promise.all([submittalItemsQuery.refetch(), packageContactsQuery.refetch()]).then(() =>
                          setInitialValuesHack(Math.random())
                        );
                      }}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <SubmittalPackageUpdateForm
                onSubmit={(values) => {
                  blockUI.blockUI("Saving...");

                  values = {
                    ...{spec_section_code: ""},
                    ...values,
                  };

                  updateSubmittalPackage.mutateAsync(values).then(() => {
                    Promise.all([
                      submittalPackageQuery.refetch(),
                      packageContactsQuery.refetch(),
                      reviewsQuery.refetch(),
                      submittalItemsQuery.refetch(),
                    ]).then(() => {
                      blockUI.unblockUI();
                      setInitialValuesHack(Math.random());
                    });
                  });
                }}
                // initialValues={submittalPackage}
                initialValues={packageInitialValues}
                projectId={project.id}
                contactChildren={
                  <Grid container spacing={1}>
                    <Grid item>
                      <Button size="small" startIcon={<GroupIcon />} onClick={() => setShowSelectGroupDialog(true)}>
                        Groups
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        href={`mailto:${approverContacts.map((contact) => contact.email).join(";")}?cc=${ccContacts
                          .map((contact) => contact.email)
                          .join(";")}&subject=${submittalPackage.display}`}
                        size="small"
                        startIcon={<EmailIcon />}
                      >
                        Send Email
                      </Button>
                    </Grid>
                    <Grid item>
                      <Tooltip title={submittalPackage.is_draft ? "Is Draft" : ""}>
                        <span>
                          <Button
                            size="small"
                            startIcon={<LinkIcon />}
                            disabled={submittalPackage.is_draft}
                            onClick={() => setShowEmailLinkDialog(true)}
                          >
                            Email Link
                          </Button>
                        </span>
                      </Tooltip>
                    </Grid>
                    <Grid item>
                      <Tooltip title={submittalPackage.is_draft ? "Is Draft" : ""}>
                        <span>
                          <Button
                            size="small"
                            startIcon={<OpenInNewIcon />}
                            disabled={submittalPackage.is_draft}
                            href={pageDataQuery.data.previewExternalResponseLink}
                            target="_blank"
                          >
                            Preview Link
                          </Button>
                          {/* {pageDataQuery.data.previewExternalResponseLinkV2 && (
                            <Button
                              size="small"
                              startIcon={<OpenInNewIcon />}
                              disabled={submittalPackage.is_draft}
                              href={pageDataQuery.data.previewExternalResponseLinkV2}
                              target="_blank"
                            >
                              Preview Link v2
                            </Button>
                          )} */}
                        </span>
                      </Tooltip>
                    </Grid>

                    <Grid item>
                      <Button size="small" startIcon={<GroupAddIcon />} href={`/projects/${project.id}/groups/`}>
                        Create Group
                      </Button>
                    </Grid>
                  </Grid>
                }
              />
              <Box mt={2}>
                <CreatedByModifiedBy obj={submittalPackage} />
              </Box>
              {/* <Typography variant="h2">Package</Typography> */}
              {/* <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre> */}
            </TabPanel>
            <TabPanel value="items">
              <PaperPanel.TabHeader isLoading={submittalItemsQuery.isLoading || submittalItemsQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <PaperItem.SelectedCheckbox
                    label={"Select All"}
                    onChange={(event, value) => {
                      if (value) {
                        addSelectedReviewIds(allReviewIds);
                      } else {
                        removeAllSelectedReviewIds();
                      }
                    }}
                    indeterminate={Boolean(
                      !allReviewsSelected && selectedReviewIds.size < allReviewIds.length && selectedReviewIds.size
                    )}
                    checked={allReviewsSelected}
                  />
                  <FontAwesomeIcon icon={faList} /> Items/Approval
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.CreateButton
                      onClick={
                        () => setShowSubmittalItemCreateDialogForm(true)
                        // navigate({
                        //   pathname: `/v2/projects/${project.id}/submittals/items/`,
                        //   state: {createForm: true},
                        // })
                      }
                    >
                      Create Item
                    </PaperPanel.Header.CreateButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.EditButton
                      disabled={!selectedReviewIds.size}
                      onClick={() => setShowBulkUpdateForm(true)}
                    >
                      Update Selected
                    </PaperPanel.Header.EditButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <Tooltip title={pageData.linkableItemsCount < 1 ? `No Linkable Items Found` : ``}>
                      <span>
                        <PaperPanel.Header.Button
                          // href={`/submittals/packages/${submittalId}/link-items/`}
                          onClick={() => setLinkSubmittalItemsDialogIsOpen(true)}
                          disabled={pageData.linkableItemsCount < 1}
                        >
                          <FontAwesomeIcon icon={faLink} /> Link Items
                        </PaperPanel.Header.Button>
                      </span>
                    </Tooltip>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <Switch onChange={() => setShowNotesInline(!showNotesInline)} checked={showNotesInline} />
                    Show Notes Inline
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border pr={0}>
                    <PaperPanel.Header.Menu>
                      {(popupState) => (
                        <div>
                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              blockUI.blockUI("Cleaning up...");
                              popupState.close();
                              axiosAPI
                                .post(
                                  `/api/v1/projects/${project.id}/submittals/packages/${submittalId}/reviews/cleanup-reviews/`,
                                  {},
                                  {baseURL: ""}
                                )
                                .then(() => {
                                  blockUI.unblockUI();
                                  submittalPackageQuery.refetch();
                                  submittalItemsQuery.refetch();
                                })
                                .catch(() => {
                                  blockUI.unblockUI();
                                });
                            }}
                          >
                            <FontAwesomeIcon icon={faBroom} fixedWidth />
                            Cleanup Statuses and Reviews
                          </PaperPanel.Header.Menu.MenuItem>

                          <PaperPanel.Header.Menu.MenuItem
                            onClick={(event) => {
                              popupState.close();
                              setShowBulkUpdateStatusForm(true);
                            }}
                          >
                            Update Status On All Pending Reviews
                          </PaperPanel.Header.Menu.MenuItem>
                        </div>
                      )}
                    </PaperPanel.Header.Menu>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action pl={0}>
                    <PaperPanel.Header.RefreshButton
                      isFetching={submittalItemsQuery.isFetching || reviewsQuery.isFetching}
                      onClick={() => {
                        submittalItemsQuery.refetch();
                        reviewsQuery.refetch();
                        pageDataQuery.refetch();
                      }}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              <PaperPanel.TabToolbar p={1}>
                <Grid container spacing={1}>
                  <Grid item sm={3} xs={12}>
                    <FilterSelect
                      label="Status"
                      name="status"
                      options={submittalItemReviewStatusFilterChoices}
                      value={reviewFilterOptions.Status.value}
                      onChange={(value, label) => {
                        setReviewFilterOption("Status", value, label);
                      }}
                    />
                  </Grid>
                  <Grid item sm={5} xs={12}>
                    <FilterSelect
                      label="Item"
                      name="item"
                      options={submittalItemReviewItemFilterChoices}
                      value={reviewFilterOptions.Item.value}
                      onChange={(value, label) => {
                        setReviewFilterOption("Item", value, label);
                      }}
                    />
                  </Grid>
                  <Grid item sm={4} xs={12}>
                    <FilterSelect
                      label="Contact"
                      name="contact"
                      options={submittalItemReviewContactFilterChoices}
                      value={reviewFilterOptions.Contact.value}
                      onChange={(value, label) => {
                        setReviewFilterOption("Contact", value, label);
                      }}
                    />
                  </Grid>
                  {/* <Grid item sm={6} xs={12}>
                    <FilterSelect
                      label="Item"
                      name="item"
                      options={submittalItemReviewItemChoices}
                      value={itemFilterOptions.Item.value}
                      onChange={(value, label) => {
                        setPage(1);
                        setItemFilterOption("Item", value, label);
                      }}
                    />
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <FilterSelect
                      label="Item"
                      name="item"
                      options={submittalItemReviewItemChoices}
                      value={itemFilterOptions.Item.value}
                      onChange={(value, label) => {
                        setPage(1);
                        setItemFilterOption("Item", value, label);
                      }}
                    />
                  </Grid> */}
                </Grid>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <FilterOptionChips
                      filterOptions={reviewFilterOptions}
                      onDelete={(key) => {
                        clearReviewFilterOption(key);
                      }}
                      onDeleteAll={() => {
                        clearAllReviewFilterOptions();
                      }}
                    />
                  </Grid>
                </Grid>
              </PaperPanel.TabToolbar>
              <PaperPanel.Body mx={-3}>
                {submittalItems &&
                  submittalItems.map((item) => {
                    const reviews = groupReviews(item.id);

                    return (
                      <React.Fragment key={item.id}>
                        <PaperItem.Header>
                          <PaperItem.Left>
                            <Tooltip title={item.status_display}>
                              <Box display={"inline-flex"}>
                                <StatusIcon status={item.status} />
                              </Box>
                            </Tooltip>{" "}
                            <MuiNavLink to={item.url}>
                              {item.number_display} - {item.description}
                            </MuiNavLink>
                          </PaperItem.Left>
                          <PaperItem.Body></PaperItem.Body>
                          {/* Needed to right align the PaperItem.Right */}
                          <PaperItem.Right>
                            <Typography2 type="metadata">{item.item_number_display}</Typography2>
                          </PaperItem.Right>
                        </PaperItem.Header>

                        {reviews &&
                          reviews.map((review) => {
                            return (
                              <SubmittalItemReviewPaperItemForm
                                review={review}
                                key={review.id}
                                addSelectedReviewId={addSelectedReviewId}
                                removeSelectedReviewId={removeSelectedReviewId}
                                selectedReviewIds={selectedReviewIds}
                                setShowSubmittalItemReviewUpdateDialogForm={setShowSubmittalItemReviewUpdateDialogForm}
                                setActiveReview={setActiveReview}
                                onSave={handleReviewUpdate}
                                isReadOnly={showBulkUpdateForm || showBulkUpdateStatusForm}
                                packageId={item.submittal_package.id}
                                showNotesInline={showNotesInline}
                                onCreateTransmittal={(review) => {
                                  setTransmittalTo(review.contact);
                                  setShowCreateTransmittalDialog(true);
                                }}
                              />
                            );
                          })}
                      </React.Fragment>
                    );
                  })}
              </PaperPanel.Body>
              {/* <Typography variant="h2">Reviews</Typography>
              <pre>{JSON.stringify(reviews, null, 2)}</pre>
              <Typography variant="h2">items</Typography>
              <pre>{JSON.stringify(submittalItems, null, 2)}</pre> */}
            </TabPanel>
            <TabPanel value="ballInCourt">
              <PaperPanel.TabHeader isLoading={submittalPackageQuery.isLoading}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faBasketball} /> Ball In Court
                </PaperPanel.Header.Title>
              </PaperPanel.TabHeader>
              <BallInCourtForm
                projectId={project.id}
                url={`ball-in-court/submittals/submittalpackage/${submittalId}/`}
              />
            </TabPanel>
            <TabPanel value="transmittals">
              <PaperPanel.TabHeader isLoading={transmittalQuery.isLoading || transmittalQuery.isFetching}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faPaperPlane} />
                  {submittalPackage.display} Transmittals
                </PaperPanel.Header.Title>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <TransmittalDropdownButton
                      approvers={packageContacts.filter((contact) => contact.role === "approver")}
                      cc={packageContacts.filter((contact) => contact.role === "cc")}
                      setSelectedContact={setTransmittalTo}
                      setShowTransmittalDialog={setShowCreateTransmittalDialog}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.PDFButton
                      title="Get All PDFs"
                      href={`/reports2/projects/${project.id}/submittals/transmittals/detail/?submittal_package=${submittalId}`}
                    />
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border>
                    <PaperPanel.Header.RefreshButton
                      isFetching={transmittalQuery.isFetching || packageContactsQuery.isFetching}
                      onClick={() => {
                        transmittalQuery.refetch();
                        packageContactsQuery.refetch();
                      }}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>

              <PaperPanel.Body mx={-3} mt={-2}>
                {transmittals &&
                  transmittals.map((transmittal) => (
                    <SubmittalTransmittalPaperItem
                      key={transmittal.id}
                      transmittal={transmittal}
                      packageId={submittalId}
                      projectId={project.id}
                      onEdit={(transmittal) => {
                        setActiveTransmittal(transmittal);
                        setShowUpdateTransmittalDialog(true);
                      }}
                    />
                  ))}
              </PaperPanel.Body>
              {/* <Typography variant="h2">Transmittals</Typography> */}
              {/* <pre>{JSON.stringify(transmittals, null, 2)}</pre> */}
            </TabPanel>
            <TabPanel value="externalLinks">
              <Box mx={-3} mt={-4}>
                <ExternalLinks url={`projects/${project.id}/submittals/packages/${submittalId}/external-links`} />
              </Box>
            </TabPanel>
            <TabPanel value="followers">
              <Box mx={-3} mt={-4}>
                <Followers url={`followers/submittals/submittalpackage/${submittalId}`} />
              </Box>
            </TabPanel>
            <TabPanel value="customData">
              <PaperPanel.TabHeader isLoading={submittalPackageQuery.isLoading}>
                <PaperPanel.Header.Title>
                  <FontAwesomeIcon icon={faFolderGear} /> Custom Data
                </PaperPanel.Header.Title>
              </PaperPanel.TabHeader>
              <PaperPanel.Body>
                <SubmittalPackageCustomDataForm
                  initialValues={submittalPackage}
                  onSubmit={(values) => {
                    updateSubmittalPackage.mutateAsync(values).then(() => submittalPackageQuery.refetch());
                  }}
                />
              </PaperPanel.Body>
            </TabPanel>
            <TabPanel value="logs">
              <Box mx={-3} mt={-4}>
                <ActivityStream url={`actstream/submittals/submittalpackage/${submittalId}/`} />
                <Box mb={2} />
                <MailLog url={`mailer/submittals/submittalpackage/${submittalId}/log/`} />
              </Box>
            </TabPanel>
            {props.user.is_superuser && (
              <TabPanel value="debug">
                <>
                  <Typography variant="h2">Followers</Typography>
                  <pre>{JSON.stringify(followers, null, 2)}</pre>
                  <Typography variant="h2">Page DataQuery</Typography>
                  <pre>{JSON.stringify(pageDataQuery, null, 2)}</pre>
                  <Typography variant="h2">Submittal Package</Typography>
                  <pre>{JSON.stringify(submittalPackageQuery, null, 2)}</pre>
                  <Typography variant="h2">Props</Typography>
                  <pre>{JSON.stringify(props, null, 2)}</pre>
                </>
              </TabPanel>
            )}
          </PaperPanel.Body>
        </PaperPanel>
      </TabContext>

      <ProjectAuditLink projectId={project.id} app="submittals" model="submittalpackage" id={submittalId} mt={2} />
      <PortalLink href={`/projects/${project.id}/submittals/packages/${submittalId}/`} />
      <LegacyUILink href={`/projects/${project.id}/submittals/packages/${submittalId}/legacy/`} />

      <ProjectGroupSelectDialogForm
        projectId={project.id}
        isOpen={showSelectGroupDialog}
        handleClose={() => {
          setShowSelectGroupDialog(false);
        }}
        onSubmit={(values) => {
          setShowSelectGroupDialog(false);
          blockUI.blockUI("Adding group members...");
          submittalPackageRPC
            .mutateAsync({action: "add-group", data: {group_id: values.group?.id}})
            .then(() => {
              Promise.all([
                submittalPackageQuery.refetch(),
                packageContactsQuery.refetch(),
                reviewsQuery.refetch(),
                submittalItemsQuery.refetch(),
              ]).then(() => {
                blockUI.unblockUI();
                setInitialValuesHack(Math.random());
              });
            })
            .catch(() => blockUI.unblockUI());
        }}
      />

      <SendEmailDialogForm
        title="Email Submittal Package Link"
        projectId={project.id}
        isOpen={showEmailLinkDialog}
        handleClose={() => {
          setShowEmailLinkDialog(false);
        }}
        initialValues={{
          to_contacts: approverContacts,
          cc_contacts: ccContacts,
          comments: "",
        }}
        onSubmit={(values) => {
          setShowEmailLinkDialog(false);
          blockUI.blockUI();
          submittalPackageRPC
            .mutateAsync({action: "email-links", data: values})
            .then((response) => {
              blockUI.unblockUI();
              enqueueSnackbar(response.message, {variant: "success"});
            })
            .catch(() => blockUI.unblockUI());
        }}
      />
      <SubmittalItemUpdateDialogForm
        projectId={project.id}
        isOpen={showSubmittalItemCreateDialogForm}
        isNew
        handleClose={() => {
          setShowSubmittalItemCreateDialogForm(false);
        }}
        initialValues={{
          submittal_package: submittalPackage,
          number: submittalItemsQuery?.data?.metadata?.next_number_for_project,
          item_number: submittalItemsQuery?.data?.metadata?.next_number_for_package,
          // date_created: moment().format("YYYY-MM-DD"),
          // authored_by: userContact,
          quantity: 1,
          revision: 0,
        }}
        onSubmit={(values, form) => {
          // Check values for some value submitMode
          const submitMode = values.submitMode;
          delete values.submitMode;

          if (submitMode === "saveAndClose") {
            blockUI.blockUI("Creating...");
            createSubmittalItem.mutateAsync(values).then(() => {
              Promise.all([reviewsQuery.refetch(), submittalItemsQuery.refetch()]).then(() => {
                setShowSubmittalItemCreateDialogForm(false);
                blockUI.unblockUI();
              });
            });
          }
          if (submitMode === "addAnother") {
            blockUI.blockUI("Creating...");
            setInitialFocusField(null);
            form.restart();
            createSubmittalItem.mutateAsync(values).then(() => {
              Promise.all([reviewsQuery.refetch(), submittalItemsQuery.refetch()]).then(() => {
                setTimeout(() => {
                  form.batch(() => {
                    form.change("spec_section_code", values.spec_section_code);
                    form.change("spec_subsection", values.spec_subsection);
                    form.change("requested_date", values.requested_date);
                    form.change("due_date", values.due_date);
                    form.change("received_date", values.received_date);
                    setInitialFocusField("description");
                  });
                });

                blockUI.unblockUI();
              });
            });
          }
        }}
        initialFocusField={initialFocusField}
      />

      <SubmittalItemReviewUpdateDialogForm
        projectId={project.id}
        isOpen={showSubmittalItemReviewUpdateDialogForm}
        setActiveReview={setActiveReview}
        review={activeReview}
        onSubmit={(values) => {
          setShowSubmittalItemReviewUpdateDialogForm(false);
          setActiveReview({});
          updateReview.mutateAsync(values).then(() => {
            reviewsQuery.refetch();
            submittalItemsQuery.refetch();
          });
        }}
        handleClose={() => setShowSubmittalItemReviewUpdateDialogForm(false)}
      />

      <SubmittalItemReviewBulkUpdateForm
        isOpen={showBulkUpdateForm}
        handleClose={() => setShowBulkUpdateForm(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Bulk Updating...");
          if (values.returned_date) {
            values.returned_date = moment(values.returned_date).format("YYYY-MM-DD");
          }
          if (values.company_submittal_stamp_choice) {
            values.company_submittal_stamp_choice_id = values.company_submittal_stamp_choice.id;
          }
          reviewRPC
            .mutateAsync({
              action: "update",
              data: {
                ids: Array.from(selectedReviewIds),
                update: values,
              },
              method: "PUT",
            })
            .then(() => {
              Promise.all([
                submittalPackageQuery.refetch(),
                submittalItemsQuery.refetch(),
                reviewsQuery.refetch(),
              ]).then(() => {
                // Hack so that SubmittalItemReviewPaperItemForm do not get autosaved when values changed on refetch (form is marked isReadOnly by showBulkUpdateForm)
                setTimeout(() => {
                  setShowBulkUpdateForm(false);
                  blockUI.unblockUI();
                }, 100);
              });
            })
            .catch(() => blockUI.unblockUI());
        }}
        projectId={project.id}
        reviewCount={selectedReviewIds.size}
        customStamp={bulkUpdateStatusField()}
      />

      <SubmittalItemReviewBulkUpdateStatusForm
        isOpen={showBulkUpdateStatusForm}
        handleClose={() => setShowBulkUpdateStatusForm(false)}
        onSubmit={(values) => {
          blockUI.blockUI("Updating Status...");
          submittalPackageRPC
            .mutateAsync({action: "update-status", data: {status: values.status}})
            .then(() => {
              Promise.all([
                submittalPackageQuery.refetch(),
                reviewsQuery.refetch(),
                submittalItemsQuery.refetch(),
              ]).then(() => {
                // Hack so that SubmittalItemReviewPaperItemForms do not get autosaved when values changed on refetch (form is marked isReadOnly by showBulkUpdateForm)
                setTimeout(() => {
                  setShowBulkUpdateStatusForm(false);
                  blockUI.unblockUI();
                  setInitialValuesHack(Math.random());
                }, 100);
              });
            })
            .catch(() => blockUI.unblockUI());
        }}
      />

      <SubmittalTransmittalDialogForm
        isOpen={showCreateTransmittalDialog}
        isNew={true}
        handleClose={() => {
          setShowCreateTransmittalDialog(false);
          setTransmittalTo({});
        }}
        initialValues={{
          to_contact: !isEmpty(transmittalTo) ? transmittalTo : null,
          // to_address: transmittalTo?.full_address,
          from_contact: userContact,
          date_sent: moment().format("YYYY-MM-DD"),
        }}
        onSubmit={(values) => {
          setShowCreateTransmittalDialog(false);
          blockUI.blockUI("Creating transmittal...");
          createTransmittal.mutateAsync(values).then(() => {
            // transmittalQuery.refetch();
            blockUI.unblockUI();
            setSelectedTab("transmittals");
          });
          // console.log("Transmittal Form!", values);
          setTransmittalTo({});
        }}
        projectId={project.id}
        approverIds={packageContacts
          .filter((contact) => contact.role === "approver")
          .map((approver) => approver.contact_id)}
      />

      <SubmittalTransmittalDialogForm
        isOpen={showUpdateTransmittalDialog}
        handleClose={() => {
          setShowUpdateTransmittalDialog(false);
          setTransmittalTo({});
        }}
        initialValues={activeTransmittal}
        onSubmit={(values) => {
          setShowUpdateTransmittalDialog(false);
          updateTransmittal.mutateAsync(values);
        }}
        projectId={project.id}
        approverIds={packageContacts
          .filter((contact) => contact.role === "approver")
          .map((approver) => approver.contact_id)}
      />

      <LinkSubmittalItemsDialog
        onSubmit={(values) => console.log(values)}
        handleClose={() => setLinkSubmittalItemsDialogIsOpen(false)}
        refetchPackages={() =>
          Promise.all([
            submittalPackageQuery.refetch(),
            packageContactsQuery.refetch(),
            reviewsQuery.refetch(),
            submittalItemsQuery.refetch(),
            pageDataQuery.refetch(),
          ])
        }
        isOpen={linkSubmittalItemsDialogIsOpen}
        projectId={project.id}
        packageId={submittalPackage.id}
      />

      <ConfirmationDialog
        isOpen={deleteReviewersConfirmationIsOpen}
        onApprove={() => {
          setDeleteReviewersConfirmationIsOpen(false);
          blockUI.blockUI("Saving...");
          updateSubmittalPackage
            .mutateAsync(formValues)
            .then(() => {
              submittalPackageQuery.refetch();
              submittalItemsQuery.refetch();
              reviewsQuery.refetch();
              setInitialValuesHack(Math.random());
            })
            .then(() => setFormValues({}))
            .then(blockUI.unblockUI());
        }}
        onDeny={() => {
          setFormValues({});
          setDeleteReviewersConfirmationIsOpen(false);
        }}
      >
        It looks like 1 or more approvers were removed from the package. This will also delete all their reviews. This
        cannot be undone.
      </ConfirmationDialog>

      <ConfirmationDialog
        isOpen={showCreateForRecordRevisionConfirmation}
        onApprove={() => {
          blockUI.blockUI("Creating...");
          submittalPackageRPC.mutateAsync({action: `for-record-revisions`}).then((response) => {
            setShowCreateForRecordRevisionConfirmation(false);
            blockUI.unblockUI();
            window.location.href = response.url;
          });
        }}
        onDeny={() => setShowCreateForRecordRevisionConfirmation(false)}
        title={"Create For Record"}
      >
        Are you sure that you want to create a new for record revision of this submittal package? This will copy all non
        rejected items to a new submittal package.
      </ConfirmationDialog>
    </>
  );
};

export default SubmittalPackageDetail;

interface TransmittalContact {
  contact?: {
    full_name?: string;
  };
}
const TransmittalDropdownButton = (props: {
  title?: string;
  href?: string;
  approvers?: TransmittalContact[];
  cc?: TransmittalContact[];
  setSelectedContact?: (contact: any) => void;
  setShowTransmittalDialog?: (value: boolean) => void;

  [rest: string]: any;
}) => {
  const {
    title = "New Transmittal",
    approvers = [],
    cc = [],
    setSelectedContact,
    setShowTransmittalDialog,
    ...rest
  } = props;
  return (
    <Box display="inline">
      <SplitButton
        color="inherit"
        startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
        title={title}
        size="small"
        onClick={() => setShowTransmittalDialog(true)}
        {...rest}
      >
        {approvers.length ? <MenuItemHeader>Approvers</MenuItemHeader> : null}
        {approvers.map((approver) => (
          <MenuItem
            onClick={() => {
              setSelectedContact(approver.contact);
              setShowTransmittalDialog(true);
            }}
          >
            {approver.contact.full_name}
          </MenuItem>
        ))}
        {cc.length ? <MenuItemHeader>CC</MenuItemHeader> : null}
        {cc.map((ccContact) => (
          <MenuItem
            onClick={() => {
              setSelectedContact(ccContact.contact);
              setShowTransmittalDialog(true);
            }}
          >
            {ccContact.contact.full_name}
          </MenuItem>
        ))}
        <MenuItemHeader>Other</MenuItemHeader>
        <MenuItem onClick={() => setShowTransmittalDialog(true)}>Other Project Contact</MenuItem>
      </SplitButton>
    </Box>
  );
};
