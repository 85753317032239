import {Dialog, DialogActions, DialogContent, DialogTitle, Grid} from "@material-ui/core";
import {Form as FinalForm} from "react-final-form";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import {ProjectContactAutoselectMui, TextFieldMui} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";

export const ProjectGroupDialogForm = (props: {
  projectId: number;
  onSubmit: (values: any) => void;
  onDelete?: () => void;
  isOpen: boolean;
  handleClose: () => void;
  isNew?: boolean;
  title?: string;
  [rest: string]: any;
}) => {
  const {projectId, isNew, onSubmit, onDelete, isOpen, handleClose, title = "Project Group", ...rest} = props;
  return (
    <>
      <Dialog open={isOpen} onClose={handleClose} maxWidth="lg" fullWidth>
        <FinalForm onSubmit={onSubmit} {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <DialogTitle id="form-dialog-title">{title}</DialogTitle>
              <DialogContent>
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextFieldMui name="name" label="Name" required autoFocus />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ProjectContactAutoselectMui
                        projectId={projectId}
                        name="response_required_contacts"
                        label="Response Required"
                        multiple
                        getOptionDisabled={
                          (option) => values.cc_contacts.map((value) => value.id).includes(option.id)
                          // values.response_required_contacts.map((value) => value.id).includes(option.id)
                        }
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <ProjectContactAutoselectMui
                        projectId={projectId}
                        name="cc_contacts"
                        label="CC"
                        multiple
                        getOptionDisabled={
                          (option) => values.response_required_contacts.map((value) => value.id).includes(option.id)
                          // values.response_required_contacts.map((value) => value.id).includes(option.id)
                        }
                      />
                    </Grid>
                  </Grid>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton disabled={submitting || pristine} label={isNew ? "Create" : "Save"} />
                {!isNew && <FormActions.DeleteButton onClick={() => onDelete()} />}
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
              {/* <pre>{JSON.stringify(values, null, 2)}</pre> */}
            </form>
          )}
        </FinalForm>
      </Dialog>
    </>
  );
};
