import {faBell} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Badge, IconButton, Tooltip} from "@material-ui/core";
import React from "react";
import {AuthenticatedContext} from "../Contexts";
import {MuiNavLink} from "./Links";

export const NotificationsMenu = () => {
  const authenticatedContext = React.useContext(AuthenticatedContext);
  const unreadCount = authenticatedContext.notificationQuery.data.unread_count;

  return (
    <>
      <Tooltip title="Notifications">
        <span>
          <MuiNavLink component={IconButton} color="inherit" to="/v2/notifications/">
            {unreadCount === undefined ? (
              <FontAwesomeIcon icon={faBell} />
            ) : (
              <Badge
                badgeContent={unreadCount.toString()}
                color={unreadCount === 0 ? "secondary" : "error"}
                overlap="rectangular"
              >
                <FontAwesomeIcon icon={faBell} />
              </Badge>
            )}
          </MuiNavLink>
        </span>
      </Tooltip>
    </>
  );
};
