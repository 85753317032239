import {faCircleX, faExclamationTriangle, faPlusCircle, faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Grid, Link, Switch, Tab, Tabs, Tooltip, Typography} from "@material-ui/core";
import {AlertTitle, TabContext, TabPanel} from "@material-ui/lab";
import classnames from "classnames";
import moment from "moment";
import React from "react";
import {Helmet} from "react-helmet";
import {useLocation} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {axiosAPI} from "../../../api";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterSearch} from "../../../components/Filters";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useFetchCurrentPage, usePostCurrentPage, useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {makeNovoClasses} from "../../../theme";
import {colorError, colorSuccess} from "../../../theme/colors";
import {
  AdditionalSafetyDocumentUploadDialogForm,
  SafetyOrientationUpdateForm,
  UpdateAdditionalDocumentDialogForm,
} from "./SafetyOrientationForms";
import AdditionalPagePaperItem from "./SafetyOrientationPaperItem";

const SafetyOrientation = (props) => {
  const {project, userContact, ...rest} = props;
  const [activeDocument, setActiveDocument] = React.useState(undefined as {id: number; name: string});
  const [showUploadForm, setShowUploadForm] = React.useState(false);
  const [documentDialogIsOpen, setDocumentDialogIsOpen] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [selectedTab, setSelectedTab] = useQueryState("tab", "safetyRoster");
  const [safetyOrientationEnabled, setSafetyOrientationEnabled] = React.useState(false);
  const [stickersEnabled, setStickersEnabled] = React.useState(false);
  const blockUI = useBlockUI();
  const postCurrentPage = usePostCurrentPage();
  const novoClasses = makeNovoClasses();

  const {hash} = useLocation();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(["Search"]);

  const pageDataQuery = useFetchCurrentPage(
    {
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      initialData: {
        checkins: [],
        siteInfo: {},
        safetyOrientationEnabled: false,
        stickersEnabled: false,
      },
    },
    {q: filterOptions.Search.value}
  );
  const pageData = pageDataQuery.data;

  const {
    query: additionalDocumentQuery,
    create: createAdditionalDocument,
    update: updateAdditionalDocument,
    delete: deleteAdditionalDocument,
    onDragEnd: additionalDocumentOnDragEnd,
  } = useSentinelListAPI(`projects/${project.id}/safety/orientation/documents/`, {
    initialData: {
      results: [],
    },

    keepPreviousData: true,
  });

  const additionalDocuments = additionalDocumentQuery.data.results;

  const requiredFields = ["first_aid_location", "rtk_area_location", "weekday_start", "weekday_end"];
  const requiredFieldsFilled = requiredFields.map((field) => Boolean(pageData.siteInfo[field])).every((val) => val);

  React.useEffect(() => {
    setSafetyOrientationEnabled(pageData.safetyOrientationEnabled);
    if (!hash) setSelectedTab(pageData.safetyOrientationEnabled ? "safetyRoster" : "siteInfo");
  }, [pageData.safetyOrientationEnabled]);

  React.useEffect(() => {
    setStickersEnabled(pageData.stickersEnabled);
  }, [pageData.stickersEnabled]);

  // This does not work when filtering
  // if (!pageDataQuery.isFetchedAfterMount) {
  //   return <BlockUI show={true} message="Fetching Safety Orientation Info..." />;
  // }

  return (
    <>
      <Helmet title={`${project.display} - Safety Orientation`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Safety Orientation</Typography>
      </ProjectBreadcrumbs>

      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab label={"Safety Roster"} value="safetyRoster" className={novoClasses.smallTab} />
              <Tab label={"Site Info"} value="siteInfo" className={novoClasses.smallTab} />
            </Tabs>
          </AppBar>
          <Box mb={1} />
          <TabPanel value="safetyRoster">
            <>
              <PaperPanel.TabHeader isLoading={pageDataQuery.isFetching}>
                {/* <PaperPanel.Header.Title>Safety Roster</PaperPanel.Header.Title> */}
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <Box display="flex" alignItems="center">
                      Enable Safety Orientation{" "}
                      <Switch
                        checked={safetyOrientationEnabled}
                        // initialChecked={pageData?.safetyOrientationEnabled || false}
                        onChange={(event) => {
                          const checked = event.target.checked;
                          setSafetyOrientationEnabled(checked);
                          postCurrentPage.mutateAsync({action: "enabled", enabled: checked});
                        }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      Use Stickers{" "}
                      <Switch
                        checked={stickersEnabled}
                        // initialChecked={pageData?.safetyOrientationEnabled || false}
                        onChange={(event) => {
                          postCurrentPage.mutateAsync({action: "toggle_stickers"}).then((response) => {
                            setStickersEnabled(response.stickers_enabled);
                          });
                          // const checked = event.target.checked;
                          // setSafetyOrientationEnabled(checked);
                          // postCurrentPage.mutateAsync({action: "enabled", enabled: checked});
                        }}
                      />
                    </Box>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFButton
                      href={`/reports2/projects/${project.id}/safety/orientation/`}
                      target="_blank"
                    />
                    {/* <PaperPanel.Header.PDFSplitButton
                      href={`/reports2/external/projects/${project.uuid}/safety/orientation/`}
                      target="_blank"
                    >
                      <PaperPanel.Header.Menu.PDFMenuItem
                        href={`/reports2/external/projects/${project.uuid}/safety/orientation/?lang=SP`}
                        target="_blank"
                      >
                        Spanish
                      </PaperPanel.Header.Menu.PDFMenuItem>
                    </PaperPanel.Header.PDFSplitButton> */}
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border pr={-2}>
                    <PaperPanel.Header.RefreshButton
                      isFetching={pageDataQuery.isFetching}
                      onClick={() => pageDataQuery.refetch()}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
            </>
            <Box mx={-3} mt={-2}>
              {safetyOrientationEnabled && !requiredFieldsFilled && (
                <Box>
                  <PaperPanel.Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>External acknowledgements are enabled, but important information may
                    be missing. Please visit the Site Info tab to complete all required fields.
                  </PaperPanel.Alert>
                </Box>
              )}

              <PaperPanel.Toolbar p={1}>
                <Grid container spacing={1}>
                  <Grid item sm={6} xs={12}>
                    <FilterSearch
                      label="Search"
                      value={filterOptions.Search.value}
                      name="Search"
                      onChange={(value) => {
                        setFilterOption("Search", value, value);
                      }}
                    />
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(filterOptions, null, 2)}</pre> */}
              </PaperPanel.Toolbar>
              <ReactTableMui
                size="small"
                className={classnames(novoClasses.stripedTable, novoClasses.mediumTable, novoClasses.boldHeaderTable)}
                getHeaderProps={(column) => {
                  if (["sticker_number"].includes(column.id)) {
                    return {
                      style: {
                        width: "75px",
                        whiteSpace: "nowrap",
                      },
                    };
                  }
                  if (["created", "sticker_issued"].includes(column.id)) {
                    return {
                      style: {
                        width: "170px",
                        whiteSpace: "nowrap",
                      },
                    };
                  }
                }}
                columns={[
                  {
                    Header: "Name",
                    accessor: "name",
                    Cell: ({row, value}) => {
                      return (
                        <Link
                          href={`/projects/${project.uuid}/twilio-contact/${row.original.twilio_contact_uuid}/safety/orientation/`}
                          target="_blank"
                          underline="always"
                        >
                          {value}
                        </Link>
                      );
                    },
                  },
                  {Header: "Company", accessor: "company"},
                  {Header: "Checkin Count", accessor: "count"},
                  {
                    Header: "Last Checked In",
                    accessor: "last_checkin",
                    Cell: ({row, value}) => (
                      <Link href={`/v2/projects/${project.id}/check-ins/#date=${value}`} underline="always">
                        {value}
                      </Link>
                    ),
                  },
                  {
                    Header: "Safety Orientation",
                    accessor: "created",

                    Cell: ({row, value}) => {
                      if (safetyOrientationEnabled) {
                        return value ? (
                          moment(value).format("YYYY-MM-DD h:mm a")
                        ) : (
                          <Tooltip title="Missing Orientation">
                            <Box textAlign="center">
                              <FontAwesomeIcon icon={faExclamationTriangle} color={colorError} />
                            </Box>
                          </Tooltip>
                        );
                      }
                      return value && moment(value).format("YYYY-MM-DD h:mm a");
                    },
                  },
                  {
                    Header: "Sticker Issued",
                    accessor: "sticker_issued",
                    Cell: ({row, value}) => {
                      if (safetyOrientationEnabled && stickersEnabled && row.original.created) {
                        return value ? (
                          <>
                            {moment(value).format("YYYY-MM-DD h:mm a")}{" "}
                            <Tooltip title="Revoke Sticker">
                              <span style={{cursor: "pointer"}}>
                                <FontAwesomeIcon
                                  icon={faCircleX}
                                  color={colorError}
                                  size="xs"
                                  onClick={() => {
                                    blockUI.blockUI("Revoking Sticker...");
                                    postCurrentPage
                                      .mutateAsync({
                                        action: "revoke_sticker",
                                        twilio_contact_uuid: row.original.twilio_contact_uuid,
                                      })
                                      .then(() => pageDataQuery.refetch().then(() => blockUI.unblockUI()));
                                  }}
                                />
                              </span>
                            </Tooltip>
                          </>
                        ) : (
                          <Tooltip title="Issue Sticker">
                            <Box textAlign="center">
                              <span style={{cursor: "pointer"}}>
                                <FontAwesomeIcon
                                  icon={faPlusCircle}
                                  color={colorSuccess}
                                  onClick={() => {
                                    blockUI.blockUI("Issuing Sticker...");
                                    postCurrentPage
                                      .mutateAsync({
                                        action: "issue_sticker",
                                        twilio_contact_uuid: row.original.twilio_contact_uuid,
                                      })
                                      .then(() => pageDataQuery.refetch().then(() => blockUI.unblockUI()));
                                  }}
                                />
                              </span>
                            </Box>
                          </Tooltip>
                        );
                      }
                      return value && moment(value).format("YYYY-MM-DD h:mm a");
                    },
                  },
                  {
                    Header: "Sticker #",
                    accessor: "sticker_number",
                    Cell: ({row, value}) => (
                      <Box
                        textAlign="center"
                        color={row.original.sticker_issued ? "" : colorError}
                        style={{textDecoration: row.original.sticker_issued ? "" : "line-through"}}
                      >
                        {value}
                      </Box>
                    ),
                  },
                ]}
                initialState={{
                  sortBy: [
                    {
                      id: "last_checkin",
                      desc: true,
                    },
                  ],
                }}
                data={pageData.checkins}
              />
            </Box>
          </TabPanel>
          <TabPanel value="siteInfo">
            <>
              <PaperPanel.TabHeader isLoading={pageDataQuery.isFetching}>
                <PaperPanel.Header.Actions>
                  <PaperPanel.Header.Action>
                    <Box display="flex" alignItems="center">
                      Enable Safety Orientation{" "}
                      <Switch
                        checked={safetyOrientationEnabled}
                        onChange={(event) => {
                          const checked = event.target.checked;
                          setSafetyOrientationEnabled(checked);
                          postCurrentPage.mutateAsync({action: "enabled", enabled: checked});
                        }}
                      />
                    </Box>
                    <Box display="flex" alignItems="center">
                      Use Stickers{" "}
                      <Switch
                        checked={stickersEnabled}
                        onChange={(event) => {
                          postCurrentPage.mutateAsync({action: "toggle_stickers"}).then((response) => {
                            setStickersEnabled(response.stickers_enabled);
                          });
                        }}
                      />
                    </Box>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.PDFSplitButton
                      href={`/reports2/external/projects/${project.uuid}/safety/orientation/`}
                      target="_blank"
                    >
                      <PaperPanel.Header.Menu.PDFMenuItem
                        href={`/reports2/external/projects/${project.uuid}/safety/orientation/?lang=SP`}
                        target="_blank"
                      >
                        Spanish
                      </PaperPanel.Header.Menu.PDFMenuItem>
                    </PaperPanel.Header.PDFSplitButton>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action>
                    <PaperPanel.Header.Button
                      onClick={() => setShowUploadForm(true)}
                      startIcon={<FontAwesomeIcon icon={faUpload} />}
                    >
                      Upload Additional Documents
                    </PaperPanel.Header.Button>
                  </PaperPanel.Header.Action>
                  <PaperPanel.Header.Action border pr={-2}>
                    <PaperPanel.Header.RefreshButton
                      isFetching={pageDataQuery.isFetching}
                      onClick={() => pageDataQuery.refetch()}
                    />
                  </PaperPanel.Header.Action>
                </PaperPanel.Header.Actions>
              </PaperPanel.TabHeader>
              {safetyOrientationEnabled && !requiredFieldsFilled && (
                <Box mt={-2} mx={-3} mb={1}>
                  <PaperPanel.Alert severity="warning">
                    <AlertTitle>Warning</AlertTitle>External acknowledgements are enabled, but important information may
                    be missing. Please complete all required fields and save your changes.
                  </PaperPanel.Alert>
                </Box>
              )}
              <SafetyOrientationUpdateForm
                onSubmit={(values, form) => {
                  // console.log(Object.fromEntries(Object.entries(values).filter(([key, value]) => value)));
                  blockUI.blockUI("Saving...");
                  postCurrentPage.mutateAsync({action: "update_site_info", ...values}).then((response) => {
                    // form.initialize(response);
                    pageDataQuery.refetch().then(() => {
                      blockUI.unblockUI();
                    });
                  });
                }}
                isReadOnly={false}
                projectId={project.id}
                initialValues={{
                  safety_director: "Matt Buzzetta - (650) 339-5341",
                  ...pageData.contacts,
                  ...pageData?.siteInfo,
                }}
                orientationEnabled={safetyOrientationEnabled}
                hospitalMapImage={pageData?.hospitalMapImage}
                urgentCareMapImage={pageData?.urgentCareMapImage}
              />

              {additionalDocuments.length > 0 && (
                <>
                  <Box mt={1} />
                  <Typography variant="h4">Additional Documents</Typography>
                  <Box border={1} borderColor="grey.400">
                    <VirtualizedList
                      itemList={additionalDocuments}
                      isVirtualized={false}
                      onDragEnd={additionalDocumentOnDragEnd}
                    >
                      {(props) => {
                        const {listItem, ...rest} = props;
                        return (
                          <AdditionalPagePaperItem
                            document={listItem}
                            projectId={project.id}
                            setActiveDocument={setActiveDocument}
                            onDelete={() => {
                              setActiveDocument(listItem);
                              setDeleteConfirmationIsOpen(true);
                            }}
                            onEdit={() => {
                              setActiveDocument(listItem);
                              setDocumentDialogIsOpen(true);
                            }}
                            {...rest}
                          />
                        );
                      }}
                    </VirtualizedList>
                  </Box>
                </>
              )}
            </>
          </TabPanel>

          {/* <Typography variant="h2">PageData</Typography>
          <pre>{JSON.stringify(pageData, null, 2)}</pre>

          <Typography variant="h2">Props</Typography>
          <pre>{JSON.stringify(props, null, 2)}</pre> */}
        </PaperPanel>
      </TabContext>
      <AdditionalSafetyDocumentUploadDialogForm
        isOpen={showUploadForm}
        handleClose={() => {
          setShowUploadForm(false);
        }}
        onSubmit={async (values) => {
          values = {...values};
          let formData = new FormData();
          Object.entries(values).map(([key, value]) => {
            /* @ts-ignore */
            formData.append(key, value);
          });
          setShowUploadForm(false);
          setTimeout(() => blockUI.blockUI("Uploading..."));
          const response = await axiosAPI
            .post(`/projects/${project.id}/safety/orientation/documents/upload/`, formData)
            .then(() => additionalDocumentQuery.refetch());
          blockUI.unblockUI();
        }}
      />

      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          deleteAdditionalDocument.mutateAsync(activeDocument.id).then(() => {
            setDeleteConfirmationIsOpen(false);
            blockUI.unblockUI();
          });
        }}
      >
        You want to delete <strong>{activeDocument?.name}</strong>? This will permanently delete the document and can
        not be undone. Deleted documents will no longer appear on the Safety Orientation PDF.
      </ConfirmationDialog>

      <UpdateAdditionalDocumentDialogForm
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateAdditionalDocument.mutateAsync(values).then(() => {
            setDocumentDialogIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        onDelete={() => {
          setDocumentDialogIsOpen(false);
          setDeleteConfirmationIsOpen(true);
        }}
        isOpen={documentDialogIsOpen}
        handleClose={() => setDocumentDialogIsOpen(false)}
        initialValues={activeDocument}
      />
    </>
  );
};

export default SafetyOrientation;
