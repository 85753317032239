import {faArrowUpRightFromSquare} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Fab, IconButton, Link, Menu, MenuItem} from "@material-ui/core";
import MoreIcon from "@material-ui/icons/MoreHoriz";
import SendIcon from "@material-ui/icons/Send";
import {FormApi} from "final-form";
import PopupState, {bindMenu, bindTrigger} from "material-ui-popup-state";
import {Form as FinalForm} from "react-final-form";
import {TextFieldMui} from "./Fields";

export const AIQuestionForm = (props: {
  onSubmit: (values: any, form: FormApi<any, Partial<any>>) => Promise<Object> | void;
  onReIndex?: () => void;
  onClearQuestions: () => void;
  documentURL?: string;
  initialValues: {};
  disabled?: boolean;
}) => {
  const {onSubmit, onClearQuestions, onReIndex, documentURL, disabled, ...rest} = props;

  return (
    <FinalForm onSubmit={onSubmit} {...rest}>
      {({handleSubmit, form, submitting, pristine, values}) => {
        const _handleSubmit = (e) => {
          e.preventDefault();
          handleSubmit();
          form.restart();
        };
        return (
          <>
            <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
              <Box style={{padding: "20px"}} alignItems="center" display="flex">
                <Box alignItems="center" display="flex" height="100%" justifyContent="center" mr={2}>
                  <PopupState variant="popover" popupId="demo-popup-menu">
                    {(popupState) => (
                      <>
                        <IconButton
                          aria-label="more"
                          aria-controls="long-menu"
                          aria-haspopup="true"
                          size="small"
                          {...bindTrigger(popupState)}
                        >
                          <MoreIcon />
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem
                            dense
                            onClick={() => {
                              popupState.close();
                              onReIndex();
                            }}
                          >
                            Reindex
                          </MenuItem>
                          {documentURL && (
                            <MenuItem
                              dense
                              href={documentURL}
                              target="_blank"
                              component={Link}
                              onClick={() => {
                                popupState.close();
                              }}
                            >
                              View Document
                              <Box component="span" mr={1} />
                              <FontAwesomeIcon icon={faArrowUpRightFromSquare} size="xs" />
                            </MenuItem>
                          )}
                          <MenuItem
                            dense
                            onClick={() => {
                              popupState.close();
                              onClearQuestions();
                            }}
                          >
                            Clear All Questions/Answers
                          </MenuItem>
                        </Menu>
                      </>
                    )}
                  </PopupState>
                </Box>
                <TextFieldMui
                  variant="outlined"
                  size="small"
                  id="user-input-query"
                  label="Ask A Question"
                  fullWidth
                  minRows={3}
                  name="question"
                  multiline
                  placeholder="What is the jobsite address?"
                  // disabled={hasPendingQuestion}
                  onKeyDown={(e) => {
                    if (e.key === "Enter" && !e.shiftKey) {
                      _handleSubmit(e);
                      // e.preventDefault();
                      // handleSubmit();
                      // form.restart();
                    }
                  }}
                  autoFocus
                />

                <Box textAlign="center" ml={1}>
                  <Fab
                    color="primary"
                    aria-label="add"
                    onClick={(e) => _handleSubmit(e)}
                    disabled={submitting || pristine || !values.question || disabled}
                  >
                    <SendIcon />
                  </Fab>
                </Box>
              </Box>
            </form>
          </>
        );
      }}
    </FinalForm>
  );
};
