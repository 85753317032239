import {alpha, IconButton, Link, makeStyles, Toolbar} from "@material-ui/core";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import EditIcon from "@material-ui/icons/Edit";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import {getCollapseIcon, getHeader, getSidebarTrigger} from "@mui-treasury/layout";
import React from "react";
import styled from "styled-components";
import useUser from "../hooks/useUser";
import GlobalSearch from "./GlobalSearch";
import Gravatar from "./Gravatar";
import {NotificationsMenu} from "./Notifications";

const SidebarTrigger = getSidebarTrigger(styled);
const CollapseIcon = getCollapseIcon(styled);
const HeaderTUI = getHeader(styled);

const useStyles = makeStyles((theme) => {
  const {spacing, transitions, breakpoints, palette, shape} = theme;
  return {
    // HeaderOffset: {
    //   width: "100%",
    //   minHeight: theme.header.minHeight,
    //   backgroundColor: "transparent",
    // },
    sidebarTrigger: {
      color: palette.common.white,
    },
    collapseIcon: {
      color: palette.common.white,
    },
    grow: {
      flexGrow: 1,
    },
    iconLink: {
      lineHeight: 0,
      [breakpoints.down("xs")]: {
        display: "none",
      },
    },
    search: {
      borderRadius: shape.borderRadius,
      // backgroundColor: alpha(palette.common.white, 1),
      // "&:hover": {
      //   backgroundColor: alpha(palette.common.white, 1),
      // },
      backgroundColor: alpha(palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(palette.common.white, 0.25),
      },
      marginRight: spacing(2),
      marginLeft: 0,
      width: "100%",
      [breakpoints.up("sm")]: {
        marginLeft: spacing(3),
        maxWidth: "400px",
        // width: "auto",
      },
      "& input": {
        // color: palette.common.white,
        color: palette.common.white,
      },
    },
  };
});

const AvatarMenu = (props) => {
  const {user} = props;
  const [userMenuAnchorEl, setUserMenuAnchorEl] = React.useState(null);
  return (
    <>
      <IconButton
        onClick={(event) => {
          setUserMenuAnchorEl(event.currentTarget);
        }}
      >
        <Gravatar alt={user.full_name} email={user.email} />
      </IconButton>
      <Menu
        id="user-menu"
        anchorEl={userMenuAnchorEl}
        open={Boolean(userMenuAnchorEl)}
        keepMounted
        onClose={() => setUserMenuAnchorEl(null)}
      >
        <Link href="/accounts/detail/" style={{textDecoration: "none"}}>
          <MenuItem
            onClick={() => {
              setUserMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <EditIcon />
            </ListItemIcon>
            <ListItemText primary="Edit" />
          </MenuItem>
        </Link>
        <Link href="/accounts/logout/" style={{textDecoration: "none"}}>
          <MenuItem
            onClick={() => {
              setUserMenuAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </MenuItem>
        </Link>
      </Menu>
    </>
  );
};

export const HeaderLogo = () => {
  return <img alt="Sentinel Project Management" src="/static/img/novo_logo.svg" width="140px" />;
};

const Header = (props) => {
  const user = useUser();
  const classes = useStyles();
  return (
    <HeaderTUI elevation={4} color="primary">
      <Toolbar>
        <CollapseIcon sidebarId="primarySidebar" className={classes.collapseIcon} />
        <SidebarTrigger sidebarId="primarySidebar" className={classes.sidebarTrigger} />
        <Link href="/" className={classes.iconLink}>
          <HeaderLogo />
        </Link>
        <div className={classes.grow} />

        <GlobalSearch
          className={classes.search}
          // textFieldProps={{
          //   size: "small",
          // }}
        />
        <NotificationsMenu />
        <AvatarMenu user={user} />
      </Toolbar>
    </HeaderTUI>
  );
};
export default Header;
