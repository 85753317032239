import accounting from "accounting";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import {useInterval} from "../hooks";

const Countdown = (props) => {
  const {date, delay, onExpiring} = props;
  const [sessionCountdown, setSessionCountdown] = React.useState(moment(date).fromNow());
  const [sessionCountdownSeconds, setSessionCountdownSeconds] = React.useState(moment(date).diff(moment(), "seconds"));
  const [onExpiringFired, setExpiringFired] = React.useState(false);
  const refreshSessionCountdown = () => {
    const secondsUntilExpiration = moment(date).diff(moment(), "seconds");
    setSessionCountdownSeconds(secondsUntilExpiration);
    setSessionCountdown(moment(date).fromNow());
    if (secondsUntilExpiration * 1000 < delay * 2 && !onExpiringFired) {
      onExpiring(secondsUntilExpiration);
      setExpiringFired(true);
    }
  };
  useInterval(refreshSessionCountdown, delay);

  return <span title={`Seconds: ${accounting.formatNumber(sessionCountdownSeconds)}`}>{sessionCountdown}</span>;
};

Countdown.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.instanceOf(moment)]),
  delay: PropTypes.number,
  onExpiring: PropTypes.func,
};
Countdown.defaultProps = {
  delay: 1000,
  onExpiring: () => {},
};

export default Countdown;
