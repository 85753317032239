import {faCheck, faPaperPlane} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Dialog, DialogActions, DialogContent, DialogTitle, FormLabel, Grid, Tooltip} from "@material-ui/core";
import React from "react";
import {Form as FinalForm} from "react-final-form";
import BeforeUnload from "../../../../js/components/BeforeUnload";
import {FinalFormKeyboardShortcuts} from "../../../../js/components/FinalFormEnhancers";
import PaperPanel from "../../../components/PaperPanel";
import {
  ApiNoSearchAutoselectMui,
  CheckboxMui,
  ProjectCompanyAutoselectMui,
  ProjectContactAutoselectMui,
  TextFieldMui,
  TwilioContactAutoselectMui,
} from "../../../components/forms/Fields";
import FormActions from "../../../components/forms/FormActions";
import {validateEmailAddress} from "../../../utils/validators";

const FormTypeSelectDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values) => void;
  formTypes: {label: string; value: string}[];
  projectId: number;
  [rest: string]: any;
}) => {
  const {isOpen, handleClose, onSubmit, formTypes = [], projectId, ...rest} = props;
  const validateEmail = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) {
      return;
    }
    if (!validateEmailAddress(value)) {
      return "Not a valid email address";
    }
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <FinalForm
        onSubmit={onSubmit}
        initialValues={{email_contacts: [], cc_contacts: [], other_contact: "", types: {}}}
        {...rest}
      >
        {({handleSubmit, form, submitting, pristine, values, invalid}) => {
          const atLeastOneFormSelected = Object.values(values.types).some((formValue) => formValue);
          const atLeastOneRecipient =
            values.email_contacts.length || values.email_contacts.length || values.other_contact;
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle id="form-dialog-title">Create New Safety Permits</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <FormLabel>Select Form Types to Send</FormLabel>
                      </Grid>
                      {formTypes.map((form) => (
                        <Grid item xs={12} sm={4} key={form.value}>
                          <CheckboxMui name={`types.${form.value}`} label={form.label} key={form.value} />
                        </Grid>
                      ))}
                      <Grid item xs={12}>
                        {!atLeastOneFormSelected && !pristine && (
                          <Box mt={-3}>
                            <FormLabel error>Select at Least One Form Type</FormLabel>
                          </Box>
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui
                          projectId={projectId}
                          name="email_contacts"
                          label="Contact to Email"
                          multiple
                          getOptionDisabled={(option) =>
                            values.cc_contacts.map((value) => value.id).includes(option.id)
                          }
                          autoFocus
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectContactAutoselectMui
                          projectId={projectId}
                          name="cc_contacts"
                          label="Contact to CC"
                          multiple
                          getOptionDisabled={(option) =>
                            values.email_contacts.map((value) => value.id).includes(option.id)
                          }
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui
                          name="other_contact"
                          label="Other Contact"
                          helperText="Email someone not in the Project Directory"
                          type="email"
                          placeholder="someone@company.com"
                          fieldProps={{validate: validateEmail}}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <ProjectCompanyAutoselectMui
                          name="responsible_company"
                          label="Responsible Company"
                          helperText="Which company is responsible for completing this form?"
                          projectId={projectId}
                          required
                        />
                      </Grid>
                    </Grid>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton
                  disabled={submitting || pristine || invalid || !atLeastOneFormSelected || !atLeastOneRecipient}
                  label={"Send"}
                  startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                />

                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
              {/* <pre>{JSON.stringify(values)}</pre> */}
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

const SendCopyDialog = React.forwardRef(
  (
    props: {
      isOpen: boolean;
      handleClose: () => void;
      onSubmit: (values) => void;
      projectId: number;
      [rest: string]: any;
    },
    ref
  ) => {
    const {isOpen, handleClose, onSubmit, projectId, ...rest} = props;
    const validateEmail = async (value, values, field) => {
      if (!field.dirty) return;
      if (!value) {
        return;
      }
      if (!validateEmailAddress(value)) {
        return "Not a valid email address";
      }
    };

    return (
      <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
        <FinalForm
          onSubmit={onSubmit}
          initialValues={{email_contacts: [], cc_contacts: [], other_contact: ""}}
          {...rest}
        >
          {({handleSubmit, form, submitting, pristine, values, invalid}) => {
            return (
              <form onSubmit={handleSubmit} autoComplete="off" ref={ref}>
                <DialogTitle id="form-dialog-title">Send Safety Permits</DialogTitle>
                <DialogContent>
                  <BeforeUnload block={!pristine} />
                  <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                    <PaperPanel.Body>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          Choose emails to receive a copy of this form.
                        </Grid>
                        <Grid item xs={12}>
                          <ProjectContactAutoselectMui
                            projectId={projectId}
                            name="email_contacts"
                            label="Contact to Email"
                            multiple
                            getOptionDisabled={(option) =>
                              values.cc_contacts.map((value) => value.id).includes(option.id)
                            }
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <ProjectContactAutoselectMui
                            projectId={projectId}
                            name="cc_contacts"
                            label="Contact to CC"
                            multiple
                            getOptionDisabled={(option) =>
                              values.email_contacts.map((value) => value.id).includes(option.id)
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldMui
                            name="other_contact"
                            label="Other Contact"
                            helperText="Email someone not in the Project Directory"
                            type="email"
                            placeholder="someone@company.com"
                            fieldProps={{validate: validateEmail}}
                          />
                        </Grid>
                      </Grid>
                    </PaperPanel.Body>
                  </FinalFormKeyboardShortcuts>
                </DialogContent>
                <DialogActions>
                  <FormActions.SaveButton
                    disabled={submitting || pristine || invalid}
                    label={"Send"}
                    startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                  />

                  <FormActions.CancelButton onClick={handleClose} />
                </DialogActions>
                {/* <pre>{JSON.stringify(values)}</pre> */}
              </form>
            );
          }}
        </FinalForm>
      </Dialog>
    );
  }
);

const ApproveFormDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values) => void;
  formTypeDisplay: string;
  [rest: string]: any;
}) => {
  const {isOpen, handleClose, onSubmit, formTypeDisplay, ...rest} = props;

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values, invalid}) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle id="form-dialog-title">Approve Safety Form</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        By approving this {formTypeDisplay} you are indicating that you have reviewed it for accuracy
                        and found it accurate and complete.
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="comments" label="Comments" minRows={3} multiline required />
                      </Grid>
                    </Grid>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton
                  disabled={submitting || invalid}
                  label={"Approve"}
                  startIcon={<FontAwesomeIcon icon={faCheck} />}
                />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
              {/* <pre>{JSON.stringify(values)}</pre> */}
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

const ReviseResubmitDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values) => void;
  formTypeDisplay: string;
  twilioContact: any;
  [rest: string]: any;
}) => {
  const {isOpen, handleClose, onSubmit, formTypeDisplay, twilioContact, ...rest} = props;
  const startIcon = twilioContact?.email ? {startIcon: <FontAwesomeIcon icon={faPaperPlane} />} : {};
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values, invalid}) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle id="form-dialog-title">Reject Safety Form</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        This will mark this {formTypeDisplay} <strong>Revise & Resubmit</strong> and the sub will be
                        able to make changes. After making changes, {twilioContact.name} will need to click "Finalize
                        and Complete" again.
                      </Grid>

                      <Grid item xs={12}>
                        {twilioContact?.email
                          ? `An email will be sent to ${twilioContact.email} with your comments and a link to make corrections.`
                          : `${twilioContact.name} does not have an email address on file, so no notification will be sent. Please contact them directly.`}
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="comments" label="Comments" minRows={3} multiline required />
                      </Grid>
                    </Grid>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton
                  disabled={submitting || invalid}
                  label={twilioContact?.email ? "Send" : "Save"}
                  {...startIcon}
                />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
              {/* <pre>{JSON.stringify(values)}</pre> */}
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

const RejectFormDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values) => void;
  formTypeDisplay: string;
  twilioContact: any;
  [rest: string]: any;
}) => {
  const {isOpen, handleClose, onSubmit, formTypeDisplay, twilioContact, ...rest} = props;
  const startIcon = twilioContact?.email ? {startIcon: <FontAwesomeIcon icon={faPaperPlane} />} : {};
  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <FinalForm onSubmit={onSubmit} {...rest}>
        {({handleSubmit, form, submitting, pristine, values, invalid}) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off">
              <DialogTitle id="form-dialog-title">Reject Safety Form</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        This will reject the {formTypeDisplay} and mark it <strong>Failed</strong>. {twilioContact.name}{" "}
                        will need to complete a new form.
                      </Grid>

                      <Grid item xs={12}>
                        {twilioContact?.email
                          ? `An email will be sent to ${twilioContact.email} with your comments.`
                          : `${twilioContact.name} does not have an email address on file, so no notification will be sent. Please contact them directly.`}
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui name="comments" label="Comments" minRows={3} multiline required />
                      </Grid>
                    </Grid>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton
                  disabled={submitting || invalid}
                  label={twilioContact?.email ? "Send" : "Save"}
                  {...startIcon}
                />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
              {/* <pre>{JSON.stringify(values)}</pre> */}
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

const ManageEmailContactsForm = (props: {
  onSubmit: (values) => void;
  setAddDefaultContactsConfirmationIsOpen: (isOpen: boolean) => void;
  isFetching: boolean;
  onRefresh: () => void;
  disabled?: boolean;
  [rest: string]: any;
}) => {
  const {projectId, setAddDefaultContactsConfirmationIsOpen, isFetching, onRefresh, disabled, ...rest} = props;
  return (
    <PaperPanel>
      <PaperPanel.Header>
        <PaperPanel.Header.Title>
          <FontAwesomeIcon icon={faPaperPlane} /> Email Notification Contacts
        </PaperPanel.Header.Title>
        <PaperPanel.Header.Actions>
          <PaperPanel.Header.Action>
            <Tooltip
              title={
                disabled ? "Default Contacts Already Added" : "Add the default contacts from the project directory"
              }
            >
              <div>
                <PaperPanel.Header.CreateButton
                  onClick={() => setAddDefaultContactsConfirmationIsOpen(true)}
                  disabled={disabled}
                >
                  Add Default Contacts
                </PaperPanel.Header.CreateButton>
              </div>
            </Tooltip>
            <Box mr={1} />
            <PaperPanel.Header.RefreshButton isFetching={isFetching} onClick={onRefresh} />
          </PaperPanel.Header.Action>
        </PaperPanel.Header.Actions>
      </PaperPanel.Header>

      <PaperPanel.Body>
        <FinalForm {...rest}>
          {({handleSubmit, form, submitting, pristine, values}) => (
            <>
              <BeforeUnload block={!pristine} />
              <form onSubmit={handleSubmit} noValidate={true} autoComplete="off">
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body p={2}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ApiNoSearchAutoselectMui
                          name="contacts"
                          label="Contacts"
                          valueProp="id"
                          labelProp="full_name"
                          baseURL={`contacts/`}
                          qsParams={{
                            page_size: 1000,
                            is_active: "True",
                            company_id: 1547,
                          }}
                          multiple
                          helperText="Select contacts to receive email notifications for safety forms. If no contacts are selected, the default contacts will be used."
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Box textAlign="right">
                          <FormActions.SaveButton disabled={submitting || pristine} />
                        </Box>
                      </Grid>
                    </Grid>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </form>
            </>
          )}
        </FinalForm>
      </PaperPanel.Body>
    </PaperPanel>
  );
};

export const CreateAndSendFormDialog = (props: {
  isOpen: boolean;
  handleClose: () => void;
  onSubmit: (values) => void;
  formType: string;
  [rest: string]: any;
}) => {
  const {isOpen, handleClose, onSubmit, formType, ...rest} = props;

  const validateEmail = async (value, values, field) => {
    if (!field.dirty) return;
    if (!value) {
      return;
    }
    if (!validateEmailAddress(value)) {
      return "Not a valid email address";
    }
  };

  const validateForm = (values) => {
    if (values.twilio_contact && !values?.twilio_contact?.email && !values?.extra_email)
      return {
        extra_email: `${values?.twilio_contact?.name} does not have an email address on file. Enter an email to send this form to them.`,
      };
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} maxWidth="md" fullWidth>
      <FinalForm onSubmit={onSubmit} validate={validateForm} {...rest}>
        {({handleSubmit, form, submitting, pristine, values, invalid}) => {
          return (
            <form onSubmit={handleSubmit} autoComplete="off" noValidate={true}>
              <DialogTitle id="form-dialog-title">Create and Send Form</DialogTitle>
              <DialogContent>
                <BeforeUnload block={!pristine} />
                <FinalFormKeyboardShortcuts handleSubmit={handleSubmit} pristine={pristine}>
                  <PaperPanel.Body>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        Create a new <strong>{formType}</strong> form and send it to the selected recipient.
                      </Grid>

                      <Grid item xs={12}>
                        <TwilioContactAutoselectMui
                          name="twilio_contact"
                          label="Recipient"
                          required
                          getOptionLabel={(option) => (option ? `${option.name} - ${option.company}` : "")}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {values?.twilio_contact && !values?.twilio_contact?.email ? (
                          <TextFieldMui
                            name="extra_email"
                            label="Email"
                            type="email"
                            helperText={`${values?.twilio_contact?.name} does not have an email address on file. Enter an email to send this form to them.`}
                            required
                            autoFocus
                            fieldProps={{validate: validateEmail}}
                          />
                        ) : (
                          values?.twilio_contact && (
                            <>
                              <FormLabel>Email will be sent to {values?.twilio_contact?.email}</FormLabel>
                            </>
                          )
                        )}
                      </Grid>
                      <Grid item xs={12}>
                        <TextFieldMui
                          name="comments"
                          label="Comments"
                          minRows={3}
                          multiline
                          helperText="Included in the email sent to the recipient"
                        />
                      </Grid>
                    </Grid>
                  </PaperPanel.Body>
                </FinalFormKeyboardShortcuts>
              </DialogContent>
              <DialogActions>
                <FormActions.SaveButton
                  disabled={submitting || invalid}
                  label={"Send"}
                  startIcon={<FontAwesomeIcon icon={faPaperPlane} />}
                />
                <FormActions.CancelButton onClick={handleClose} />
              </DialogActions>
            </form>
          );
        }}
      </FinalForm>
    </Dialog>
  );
};

export {
  ApproveFormDialog,
  FormTypeSelectDialog,
  ManageEmailContactsForm,
  RejectFormDialog,
  ReviseResubmitDialog,
  SendCopyDialog,
};
