import {faFileExcel, faPaperclip} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "@material-ui/core";
import classnames from "classnames";
import React from "react";
import {Currency} from "../../../components/Accounting";
import {StatusIcon} from "../../../components/Icons";
import PaperPanel from "../../../components/PaperPanel";
import {ReactTableMui} from "../../../components/ReactTableMui";
import {makeNovoClasses} from "../../../theme";
import {saveExcel} from "../../../utils/excel";
import {truncateString} from "../../../utils/string";

const SubcontractChangeOrders = (props: {
  scos: Array<{
    display: string;
    commitment_display: string;
    status: string;
    cost: number;
  }>;
  project: {display: string};
  budget: {display: string};
  isFetching?: Boolean;
}) => {
  const {scos, project, budget, isFetching} = props;
  const aliveSCOs = scos.filter((sco) => !["void"].includes(sco.status));
  const novoClasses = makeNovoClasses();

  return (
    <>
      <PaperPanel>
        <PaperPanel.Header isLoading={isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faPaperclip} /> SCO's
          </PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faFileExcel} />}
                disabled={isFetching}
                onClick={(event) => {
                  saveExcel(
                    scos.map((sco) => {
                      return {
                        SCO: sco.display,
                        Commitment: sco.commitment_display,
                        Cost: {v: sco.cost, t: "n", z: "$#,##0.00"},
                      };
                    }),
                    `${project.display} - ${budget.display} - SCO's`,
                    [{wch: 60}, {wch: 60}, {wch: 15}]
                  );
                }}
              >
                Export Excel
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <ReactTableMui
            size="small"
            showFooter={Boolean(scos.length)}
            className={classnames(novoClasses.stripedTable, novoClasses.smallTable, novoClasses.boldHeaderTable)}
            rightAlignColumns={["cost"]}
            columns={[
              {
                Header: "SCO",
                id: "sco",
                accessor: (value) => {
                  return `${value.status_sort}-${value.display}`;
                },
                Cell: ({value, row}) => (
                  <>
                    <StatusIcon status={row.original.status} showTooltip />{" "}
                    <Link href={row.original.url}>{truncateString(row.original.display, 100)}</Link>
                  </>
                ),
              },
              {
                Header: "Commitment",
                accessor: "commitment_display",
                Cell: ({value, row}) => (
                  <>
                    <Link href={row.original.commitment_url}>
                      {truncateString(row.original.commitment_display, 100)}
                    </Link>
                  </>
                ),
              },
              {
                Header: "Cost",
                accessor: "cost",
                Cell: ({value}) => <Currency number={value} />,
                Footer: () => {
                  const total = React.useMemo(() => aliveSCOs.reduce((sum, row) => row.cost + sum, 0), [aliveSCOs]);
                  return <Currency number={total} />;
                },
              },
            ]}
            initialState={{
              sortBy: [
                {
                  id: "sco",
                },
              ],
            }}
            data={scos}
          />
        </PaperPanel.Body>
      </PaperPanel>
    </>
  );
};

export default SubcontractChangeOrders;
