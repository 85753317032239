import {faArrowsH, faList} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import pluralize from "pluralize";
import {BoxFilesIconButtonLink} from "../../../components/Box";
import {MuiNavLink} from "../../../components/Links";
import {PaperItem} from "../../../components/PaperItem";
import {StatusLabel} from "../../../components/Status";
import {TagList} from "../../../components/Tags";
import Typography2 from "../../../components/Typography2";
import BudgetPaperItemGrid, {BudgetMarkupPaperItemGrid} from "./BudgetPaperItemGrid";

export const PCOPaperItem = (props) => {
  const {pco, isSelected, isDragging, dragHandleProps, onChangePCOSelected, onEditPCO, ...rest} = props;

  const itemCount = pco.item_count;

  return (
    <>
      <PaperItem isDragging={isDragging} {...rest}>
        <PaperItem.Left minWidth={45}>
          {/* <Box display="flex" alignItems="center"> */}
          <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangePCOSelected} />
          {dragHandleProps && (
            <Box display="flex" alignItems="center">
              <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
              <PaperItem.Position position={pco.position} />
            </Box>
          )}
          {/* </Box> */}
        </PaperItem.Left>

        <PaperItem.Body>
          <Grid container>
            <Grid item container xs={12} md={6}>
              <Grid item xs={12}>
                <StatusLabel status={pco.status_display} />{" "}
                <MuiNavLink href={`/v2/projects/${pco.project_id}/pcos/${pco.id}/`} underline="always">
                  {pco.description}
                </MuiNavLink>
              </Grid>
              <Grid item container xs={12}>
                <Grid item xs={6} sm={3}>
                  <Typography2 type="metadata">
                    <MuiNavLink href={`${pco.url}#tab=items`}>
                      <FontAwesomeIcon icon={faList} /> {pluralize(`${itemCount} Item`, itemCount)}
                    </MuiNavLink>
                  </Typography2>
                </Grid>
                <Grid item xs={6} sm={3}>
                  {pco.contract && (
                    <Typography2 type="metadata">
                      <MuiNavLink underline="always" href={pco.contract.url}>
                        Contract: #{pco.contract?.number_display}
                      </MuiNavLink>
                    </Typography2>
                  )}
                </Grid>
                <Grid item xs={6} sm={3}>
                  {pco.cor && (
                    <Typography2 type="metadata">
                      <MuiNavLink underline="always" href={pco.cor.url}>
                        COR: #{pco.cor?.number_display}
                      </MuiNavLink>
                    </Typography2>
                  )}
                </Grid>
                <Grid item xs={6} sm={3}>
                  {pco.pcco && (
                    <Typography2 type="metadata">
                      <MuiNavLink underline="always" href={pco.pcco.url}>
                        OCO: #{pco.pcco?.number_display}
                      </MuiNavLink>
                    </Typography2>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6}>
              <BudgetPaperItemGrid item={pco} />
            </Grid>
          </Grid>
          <TagList tags={pco.tags} />
          {/* <pre>{JSON.stringify(pco, null, 2)}</pre> */}
        </PaperItem.Body>
        <PaperItem.Right
          // minWidth={40}
          minWidth={105}
        >
          <Box textAlign="right">
            <Typography2 noWrap type="metadata">
              {pco.number_display}
            </Typography2>
          </Box>
        </PaperItem.Right>
        <PaperItem.RightHover>
          <PaperItem.RightHover.ItemNumber number={pco.number_display} />
          <PaperItem.RightHover.IconButton icon={LinkIcon} title="Link" component={Link} href={pco.url} />
          <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditPCO} />
          <PaperItem.RightHover.IconButton
            icon={DeleteIcon}
            title="Delete"
            component={MuiNavLink}
            to={`/v2/projects/${pco.project_id}/delete/pcos/pco/${pco.id}/?next=/v2/projects/${pco.project_id}/pcos/&cancel=/v2/projects/${pco.project_id}/pcos/${pco.id}/`}
            disabled={pco.is_closed}
          />
          <BoxFilesIconButtonLink
            projectId={pco.project_id}
            app="pcos"
            model="pco"
            objectId={pco.id}
            boxFolderId={pco.box_folder_id}
          />
        </PaperItem.RightHover>
      </PaperItem>
    </>
  );
};

export const PCOItemPaperItem = (props) => {
  const {
    pcoItem,
    isSelected,
    isDragging,
    dragHandleProps,
    onChangePCOItemSelected,
    onEditPCOItem,
    onDeletePCOItem,
    projectId,
    onExpectedMarkupClick,
    onQuickEdit,
    ...rest
  } = props;

  return (
    <>
      <PaperItem isDragging={isDragging} {...rest}>
        <PaperItem.Left minWidth={45}>
          <PaperItem.SelectedCheckbox checked={isSelected} onChange={onChangePCOItemSelected} />
          {dragHandleProps && (
            <Box display="flex" alignItems="center">
              <PaperItem.DragHandle dragHandleProps={dragHandleProps} />
              <PaperItem.Position position={pcoItem.position} />
            </Box>
          )}
        </PaperItem.Left>

        <PaperItem.Body>
          <Grid container>
            <Grid item container xs={12} md={6} alignContent="flex-start">
              <Grid item xs={12}>
                {/* <StatusLabel status={pco.status_display} />{" "} */}
                <Link underline="always" style={{cursor: "pointer"}} onClick={onEditPCOItem}>
                  {pcoItem.budget?.display} ({pcoItem.budget?.allocation_display})
                </Link>
                {pcoItem.extracker_id && (
                  <Tooltip title="Created By Extracker">
                    <Box display="inline" ml={1}>
                      <Typography2 type="metadata">
                        <FontAwesomeIcon icon={faArrowsH} />
                      </Typography2>
                    </Box>
                  </Tooltip>
                )}
              </Grid>
              <Grid item xs={pcoItem.scco ? 9 : 12}>
                <Typography2 type="metadata">{pcoItem.description}</Typography2>
              </Grid>
              {pcoItem.scco && (
                <Grid item xs={3}>
                  <Typography2 type="metadata">
                    <MuiNavLink href={pcoItem?.scco?.url}>SCO #{pcoItem?.scco?.number_display}</MuiNavLink>
                  </Typography2>
                </Grid>
              )}
            </Grid>
            <Grid item container xs={12} md={6}>
              <Grid item xs={12}>
                <BudgetMarkupPaperItemGrid
                  item={pcoItem}
                  onExpectedMarkupClick={onExpectedMarkupClick}
                  onQuickEdit={onEditPCOItem}
                />
              </Grid>
            </Grid>
          </Grid>
        </PaperItem.Body>
        <PaperItem.Right minWidth={90}>
          <Box textAlign="right">
            <Typography2 noWrap type="metadata">
              {pcoItem.number_display}
            </Typography2>
          </Box>
        </PaperItem.Right>
        <PaperItem.RightHover>
          <Box textAlign="right">
            <Typography2 noWrap type="metadata">
              {pcoItem.number_display}
            </Typography2>
          </Box>
          {/* <PaperItem.RightHover.IconButton icon={LinkIcon} title="Link" component={Link} href={pco.url} /> */}
          <PaperItem.RightHover.IconButton icon={EditIcon} title="Quick Edit" onClick={onEditPCOItem} />
          <PaperItem.RightHover.IconButton
            icon={DeleteIcon}
            title="Delete"
            onClick={onDeletePCOItem}
            // component={MuiNavLink}
            // to={`/v2/projects/${projectId}/delete/pcos/pco/${pco.id}/?next=/v2/projects/${projectId}/pcos/&cancel=/v2/projects/${pco.project_id}/pcos/${pco.id}/`}
            disabled={pcoItem.is_closed}
          />
          <BoxFilesIconButtonLink
            projectId={pcoItem.project_id}
            app="pcos"
            model="pcoitem"
            objectId={pcoItem.id}
            boxFolderId={pcoItem.box_folder_id}
          />
        </PaperItem.RightHover>
      </PaperItem>
    </>
  );
};
