import {faGavel, faPenFancy} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Box, Grid, Link, Tooltip, Typography} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import LinkIcon from "@material-ui/icons/Link";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import moment from "moment";
import {useSnackbar} from "notistack";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useNavigate} from "react-router-dom";
import {useQueryState} from "use-location-state";
import {Currency} from "../../../components/Accounting";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch} from "../../../components/Filters";
import {LegacyUILink, MuiNavLink} from "../../../components/Links";
import {Pagination} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import Typography2 from "../../../components/Typography2";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {LetterOfIntentDialogForm} from "./LetterOfIntentForms";

const ProjectLettersOfIntent = (props) => {
  const {project, userContact, ...rest} = props;
  const [page, setPage] = useQueryState("page", 1);
  const [pageSize, setPageSize] = React.useState(1000);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [activeLetterOfIntent, setActiveLetterOfIntent] = React.useState({} as any);
  const [initialFocusField, setInitialFocusField] = React.useState(null);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);

  const createFormRef = React.createRef();
  const blockUI = useBlockUI();
  const navigate = useNavigate();

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions(["Search"]);

  const filterParams = {
    q: filterOptions.Search.value,
  };

  // const userContact = useUser();
  const {enqueueSnackbar} = useSnackbar();

  const {
    query: lettersOfIntentQuery,
    create: createLetterOfIntent,
    update: updateLetterOfIntent,
    delete: deleteLetterOfIntent,
  } = useSentinelListAPI(
    `projects/${project.id}/letters-of-intent/?page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  const lettersOfIntent = lettersOfIntentQuery.data.results;

  return (
    <>
      <Helmet title={`${project.display} - Letters of Intent`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Letters of Intent</Typography>
      </ProjectBreadcrumbs>
      <PaperPanel>
        <PaperPanel.Header isLoading={lettersOfIntentQuery.isFetching}>
          <PaperPanel.Header.Title>
            <FontAwesomeIcon icon={faGavel} /> Letters Of Intent
          </PaperPanel.Header.Title>

          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.CreateButton
                onClick={() => {
                  setShowCreateDialog(true);
                }}
                disabled={!lettersOfIntentQuery.isFetchedAfterMount}
              >
                Create
              </PaperPanel.Header.CreateButton>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.PDFButtons pdfURL={`/reports2/projects/${project.id}/letters-of-intent/`} />
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                isFetching={lettersOfIntentQuery.isFetching}
                onClick={() => lettersOfIntentQuery.refetch()}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Toolbar p={1}>
          <Grid container spacing={1}>
            <Grid item sm={4} xs={12}>
              <FilterSearch
                label="Search"
                value={filterOptions.Search.value}
                name="Search"
                onChange={(value) => {
                  setPage(1);
                  setFilterOption("Search", value, value);
                }}
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid item sm={6} xs={12}>
                <FilterOptionChips
                  filterOptions={filterOptions}
                  onDelete={(key) => {
                    clearFilterOption(key);
                  }}
                  onDeleteAll={() => {
                    clearAllFilterOptions();
                  }}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <Box display="flex" justifyContent="flex-end">
                  <Pagination count={lettersOfIntentQuery.data.total_pages} page={page} setPage={setPage} />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </PaperPanel.Toolbar>

        <PaperPanel.Body>
          {lettersOfIntent.map((letterOfIntent, index) => {
            return (
              <PaperItem key={letterOfIntent.id} highlight={letterOfIntent.id === activeLetterOfIntent.id}>
                <PaperItem.Body>
                  {/* <pre>{JSON.stringify(letterOfIntent, null, 2)}</pre> */}
                  <Grid container spacing={0}>
                    <Grid item xs={10}>
                      <MuiNavLink
                        to={`/v2/projects/${project.id}/letters-of-intent/${letterOfIntent.id}`}
                        underline="always"
                      >
                        {letterOfIntent.display}
                      </MuiNavLink>
                    </Grid>
                    <Grid item xs={2}>
                      <Box textAlign="right">
                        <Typography2 noWrap type="metadata">
                          {letterOfIntent.number_display}
                        </Typography2>
                      </Box>
                    </Grid>
                    <Grid item sm={6} xs={12}>
                      {letterOfIntent.subcontract && (
                        <Typography2 type="metadata">
                          <Link href={letterOfIntent.subcontract?.url}>
                            Subcontract: {letterOfIntent.subcontract?.display}
                          </Link>
                        </Typography2>
                      )}
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      {letterOfIntent.docusign_envelope_status && (
                        <Link href={`/v2/docusign/envelopes/${letterOfIntent.docusign_envelope_id}/`}>
                          <Tooltip title="DocuSign Status">
                            <small>
                              <FontAwesomeIcon icon={faPenFancy} /> {letterOfIntent.docusign_envelope_status}
                            </small>
                          </Tooltip>
                        </Link>
                      )}
                    </Grid>
                    <Grid item sm={3} xs={12}>
                      <Box textAlign="right">
                        <Tooltip title="Not To Exceed">
                          <strong>
                            <Currency number={letterOfIntent.not_to_exceed_value} precision={2} />
                          </strong>
                        </Tooltip>
                      </Box>
                    </Grid>
                  </Grid>
                </PaperItem.Body>
                <PaperItem.RightHover>
                  <PaperItem.RightHover.IconButton
                    icon={LinkIcon}
                    title="Link"
                    component={MuiNavLink}
                    // component={NavLink}
                    to={`/v2/projects/${project.id}/letters-of-intent/${letterOfIntent.id}`}
                    // href={letterOfIntent.url}
                  />
                  <PaperItem.RightHover.IconButton
                    icon={PictureAsPdfIcon}
                    title="Get PDF"
                    component={Link}
                    href={`/reports2/contracts/letter-of-intent/${letterOfIntent.id}/`}
                    target="_blank"
                  />
                  <PaperItem.RightHover.IconButton
                    icon={EditIcon}
                    title="Quick Edit"
                    onClick={() => {
                      setActiveLetterOfIntent(letterOfIntent);
                      setShowUpdateDialog(true);
                    }}
                  />
                  <PaperItem.RightHover.IconButton
                    icon={DeleteIcon}
                    title="Delete"
                    disabled={letterOfIntent.is_closed}
                    // component={Link}
                    onClick={() => {
                      setActiveLetterOfIntent(letterOfIntent);
                      setDeleteConfirmationIsOpen(true);
                    }}
                    //   href={`/v2/projects/${projectId}/delete/contracts/subcontract/${contract.id}/?next=${pathname}&cancel=${pathname}`}
                  />
                  <Box textAlign="right">
                    <Tooltip title="Not To Exceed">
                      <strong>
                        <Currency number={letterOfIntent.not_to_exceed_value} precision={2} />
                      </strong>
                    </Tooltip>
                  </Box>
                </PaperItem.RightHover>
              </PaperItem>
            );
          })}
        </PaperPanel.Body>
      </PaperPanel>
      <LegacyUILink href={`/projects/${project.id}/letters-of-intent/legacy/`} mt={2} />

      {/* Quick Create */}
      <LetterOfIntentDialogForm
        ref={createFormRef}
        projectId={project.id}
        isOpen={showCreateDialog}
        isNew
        onSubmit={(values) => {
          setShowCreateDialog(false);
          blockUI.blockUI("Creating...");
          // Check values for some value submitMode
          const submitMode = values.submitMode;
          delete values.submitMode;
          if (submitMode === "editAfterSubmit") {
            createLetterOfIntent.mutateAsync(values).then((newLetterOfIntent) => {
              blockUI.unblockUI();
              navigate(`/v2/projects/${project.id}/letters-of-intent/${newLetterOfIntent.id}/`);
            });
            return;
          }
          if (submitMode === "saveAndClose") {
            createLetterOfIntent.mutateAsync(values).then((newLetterOfIntent) => {
              blockUI.unblockUI();
              lettersOfIntentQuery.refetch();
            });
            return;
          }
        }}
        handleClose={() => {
          setShowCreateDialog(false);
          setActiveLetterOfIntent({});
        }}
        initialValues={{
          number: lettersOfIntentQuery?.data?.metadata?.next_number,
          issued_date: moment().format("YYYY-MM-DD"),
          from_contact: userContact,
          from_company: userContact?.company,
          not_to_exceed_value: 0,
        }}
      />
      {/* Quick Edit */}
      <LetterOfIntentDialogForm
        projectId={project.id}
        isOpen={showUpdateDialog}
        initialValues={activeLetterOfIntent}
        isReadOnly={activeLetterOfIntent.is_closed}
        onSubmit={(values) => {
          updateLetterOfIntent.mutateAsync(values).then(() => lettersOfIntentQuery.refetch());
          setShowUpdateDialog(false);
          setActiveLetterOfIntent({});
        }}
        handleClose={() => {
          setShowUpdateDialog(false);
          setActiveLetterOfIntent({});
          setInitialFocusField(null);
        }}
        setActiveLetterOfIntent={setActiveLetterOfIntent}
        activeLetterOfIntent={activeLetterOfIntent}
        lettersOfIntent={lettersOfIntent}
        onDelete={() => {
          setShowUpdateDialog(false);
          setDeleteConfirmationIsOpen(true);
        }}
        initialFocusField={initialFocusField}
        setInitialFocusField={setInitialFocusField}
        beforePrevNext={(values) => {
          blockUI.blockUI("Updating...");
          return updateLetterOfIntent.mutateAsync(values).then(() => {
            blockUI.unblockUI();
          });
        }}
        afterPrevNext={() => {
          lettersOfIntentQuery.refetch();
        }}
      />
      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onApprove={() => {
          setDeleteConfirmationIsOpen(false);
          deleteLetterOfIntent.mutateAsync(activeLetterOfIntent.id).then(() => lettersOfIntentQuery.refetch());
        }}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
      >
        You want to delete {activeLetterOfIntent?.display}. This cannot be undone.
      </ConfirmationDialog>
    </>
  );
};

export default ProjectLettersOfIntent;
