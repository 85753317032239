import {faUpload} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Typography} from "@material-ui/core";
import React from "react";
import {Helmet} from "react-helmet";
import {axiosAPI} from "../../../api";
import {AIDocumentListTable} from "../../../components/AI";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import BlockUI from "../../../components/GlobalLoaders";
import PaperPanel from "../../../components/PaperPanel";
import useBlockUI from "../../../hooks/useBlockUI";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import {DocumentUploadDialogForm, UpdateDocumentDialogForm} from "./ProjectAIForms";

const ProjectAIDocumentList = (props) => {
  const {project} = props;

  const [showUploadForm, setShowUploadForm] = React.useState(false);
  const [deleteConfirmationIsOpen, setDeleteConfirmationIsOpen] = React.useState(false);
  const [documentFormIsOpen, setDocumentFormIsOpen] = React.useState(false);
  const [activeDocument, setActiveDocument] = React.useState({} as any);

  const blockUI = useBlockUI();

  const {
    query: documentQuery,
    delete: documentDelete,
    update: updateDocument,
  } = useSentinelListAPI(`projects/${project.id}/ai/documents/`, {
    initialData: {
      results: [],
    },
    keepPreviousData: true,
  });

  const documents = documentQuery.data.results;

  if (!documentQuery.isFetchedAfterMount) {
    return <BlockUI show={true} message="Fetching documents..." />;
  }
  return (
    <>
      <Helmet title={`${project.display} - Documents`} />

      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">AI Documents</Typography>
      </ProjectBreadcrumbs>
      {/* {!pageData.boxAIFolderId && (
        <Box my={1}>
          <Alert severity="info">
            <AlertTitle>No Box Folder Found</AlertTitle>There is currently no AI folder on Box for this project. Please{" "}
            <Link href={`https://novoconstruction1.app.box.com/folder/${project.box_folder_id}/`} underline="always">
              create a folder on Box
            </Link>{" "}
            called <strong>AI</strong> and place your documents in it.
          </Alert>
        </Box>
      )} */}
      <PaperPanel>
        <PaperPanel.Header>
          <PaperPanel.Header.Title>Project Documents</PaperPanel.Header.Title>
          <PaperPanel.Header.Actions>
            <PaperPanel.Header.Action>
              <PaperPanel.Header.Button
                startIcon={<FontAwesomeIcon icon={faUpload} fixedWidth />}
                onClick={() => {
                  setShowUploadForm(true);
                }}
              >
                Upload Document
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.RefreshButton
                onClick={() => documentQuery.refetch()}
                isFetching={documentQuery.isFetching}
              />
            </PaperPanel.Header.Action>
          </PaperPanel.Header.Actions>
        </PaperPanel.Header>
        <PaperPanel.Body>
          <AIDocumentListTable
            documents={documents}
            projectId={project.id}
            onEdit={(doc) => {
              setActiveDocument(doc);
              setDocumentFormIsOpen(true);
            }}
          />
        </PaperPanel.Body>
      </PaperPanel>
      <DocumentUploadDialogForm
        isOpen={showUploadForm}
        handleClose={() => {
          setShowUploadForm(false);
        }}
        onSubmit={async (values) => {
          let formData = new FormData();
          Object.entries(values).map(([key, value]) => {
            /* @ts-ignore */
            formData.append(key, value);
          });
          setShowUploadForm(false);
          setTimeout(() => blockUI.blockUI("Uploading..."));
          const response = await axiosAPI.post(`/projects/${project.id}/ai/documents/upload/`, formData);
          blockUI.unblockUI();
          window.location.href = `/v2/projects/${project.id}/ai/documents/${response.data.id}/`;
        }}
      />
      <ConfirmationDialog
        isOpen={deleteConfirmationIsOpen}
        onDeny={() => setDeleteConfirmationIsOpen(false)}
        onApprove={() => {
          blockUI.blockUI("Deleting...");
          documentDelete.mutateAsync(activeDocument.id).then(() => {
            setDeleteConfirmationIsOpen(false);
            blockUI.unblockUI();
          });
        }}
      >
        You want to delete <strong>{activeDocument?.name}</strong>? This will permanently delete the document and all of
        its associated questions and answers.
      </ConfirmationDialog>

      <UpdateDocumentDialogForm
        onSubmit={(values) => {
          blockUI.blockUI("Saving...");
          updateDocument.mutateAsync(values).then(() => {
            setDocumentFormIsOpen(false);
            blockUI.unblockUI();
          });
        }}
        onDelete={() => {
          setDocumentFormIsOpen(false);
          setDeleteConfirmationIsOpen(true);
        }}
        isOpen={documentFormIsOpen}
        handleClose={() => setDocumentFormIsOpen(false)}
        initialValues={activeDocument}
      />

      {/* <pre>{JSON.stringify(documentQuery, null, 2)}</pre> */}
    </>
  );
};

export default ProjectAIDocumentList;
