import {faCloud, faList, faPaperclip} from "@fortawesome/pro-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppBar, Box, Grid, Tab, Tabs, Typography} from "@material-ui/core";
import {TabContext} from "@material-ui/lab";
import moment from "moment";
import {useSnackbar} from "notistack";
import qs from "query-string";
import React from "react";
import {Helmet} from "react-helmet";
import {useQueryState} from "use-location-state";
import {useSelectIds} from "../../../../js/hooks";
import {CreateMissingBoxFoldersConfirmationDialog, FixBoxFolderNamesConfirmationDialog} from "../../../components/Box";
import {ProjectBreadcrumbs} from "../../../components/Breadcrumbs";
import {ConfirmationDialog} from "../../../components/Dialogs";
import {FilterOptionChips} from "../../../components/FilterOptions";
import {FilterSearch, FilterSelect} from "../../../components/Filters";
import {LegacyUILink} from "../../../components/Links";
import {MenuItemHeader} from "../../../components/Menu";
import {Pagination, PaginationWithPageSize} from "../../../components/Pagination";
import {PaperItem} from "../../../components/PaperItem";
import PaperPanel from "../../../components/PaperPanel";
import {MountedTabPanel} from "../../../components/TabPanel";
import {TabCountChip} from "../../../components/Tabs";
import VirtualizedList from "../../../components/VirtualizedList";
import useBlockUI from "../../../hooks/useBlockUI";
import useFilterOptions from "../../../hooks/useFilterOptions";
import {useSentinelListAPI} from "../../../hooks/useSentinelAPI";
import useUser from "../../../hooks/useUser";
import {makeNovoClasses} from "../../../theme";
import getQueryStringFromIds from "../../../utils/getQueryStringFromIds";
import {PCOBulkUpdateForm, PCODialogForm} from "./PCOForms";
import {PCOPaperItem} from "./PCOPaperItem";

const ProjectPCOs = (props) => {
  const {project, ...rest} = props;
  const [selectedTab, setSelectedTab] = useQueryState("tab", "external");
  const [externalQueryStats, setExternalQueryStats] = React.useState({isFetchedAfterMount: false, count: 0});
  const [internalQueryStats, setInternalQueryStats] = React.useState({isFetchedAfterMount: false, count: 0});
  const [pcoIdToFlash, setPCOIdToFlash] = React.useState();

  const novoClasses = makeNovoClasses();
  // useWhyDidYouUpdate("ProjectPCOs", props);
  return (
    <>
      <Helmet title={`${project.display} - Potential Change Orders`} />
      <ProjectBreadcrumbs project={project}>
        <Typography color="textPrimary">Potential Change Orders</Typography>
      </ProjectBreadcrumbs>
      <TabContext value={selectedTab}>
        <PaperPanel>
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={(event, newValue) => {
                setSelectedTab(newValue);
              }}
              variant="scrollable"
              scrollButtons="auto"
            >
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    PCOs
                    <TabCountChip
                      isLoading={!externalQueryStats.isFetchedAfterMount}
                      count={externalQueryStats.count}
                    />
                  </Box>
                }
                value="external"
                className={novoClasses.smallTab}
              />
              <Tab
                label={
                  <Box display="flex" alignItems="center">
                    Internal
                    <TabCountChip
                      isLoading={!internalQueryStats.isFetchedAfterMount}
                      count={internalQueryStats.count}
                    />
                  </Box>
                }
                value="internal"
                className={novoClasses.smallTab}
              />
            </Tabs>
          </AppBar>
          <Box mb={2} />

          <MountedTabPanel value="external">
            <PCOList
              project={project}
              setQueryStats={setExternalQueryStats}
              pcoIdToFlash={pcoIdToFlash}
              setPCOIdToFlash={setPCOIdToFlash}
            />
          </MountedTabPanel>
          <MountedTabPanel value="internal">
            <PCOList
              project={project}
              isInternal
              setQueryStats={setInternalQueryStats}
              pcoIdToFlash={pcoIdToFlash}
              setPCOIdToFlash={setPCOIdToFlash}
            />
          </MountedTabPanel>
        </PaperPanel>
      </TabContext>

      <LegacyUILink href={`/projects/${project.id}/pcos-legacy/`} mt={2} />
    </>
  );
};

export default ProjectPCOs;

const PCOList = (props) => {
  const {project, isInternal, setQueryStats, pcoIdToFlash, setPCOIdToFlash, ...rest} = props;
  const [page, setPage] = React.useState(1);
  const [pageSize, setPageSize] = React.useState(50);

  const [activePCO, setActivePCO] = React.useState({} as any);
  const [showCreateDialog, setShowCreateDialog] = React.useState(false);
  const [showUpdateDialog, setShowUpdateDialog] = React.useState(false);
  const [fixBoxFolderNamesDialog, setFixBoxFolderNamesDialog] = React.useState(false);
  const [createMissingBoxFolders, setCreateMissingBoxFolders] = React.useState(false);
  const [showBulkUpdateDialog, setShowBulkUpdateDialog] = React.useState(false);
  const [renumberConfirmationIsOpen, setRenumberConfirmationIsOpen] = React.useState(false);

  const {enqueueSnackbar} = useSnackbar();
  const createFormRef = React.createRef() as any;

  const [filterOptions, setFilterOption, clearFilterOption, clearAllFilterOptions] = useFilterOptions([
    "Status",
    "Search",
    "Tag",
  ]);

  const filterParams = {
    status: filterOptions.Status.value,
    q: filterOptions.Search.value,
    tag: filterOptions.Tag.value,
  };

  const {
    query: pcoQuery,
    create: createPCO,
    update: updatePCO,
    delete: deletePCO,
    rpc: pcoRPC,
    onDragEnd: pcoOnDragEnd,
  } = useSentinelListAPI(
    `projects/${project.id}/pcos/?parent__isnull=True&is_internal=${
      isInternal ? "True" : "False"
    }&page_size=${pageSize}&page=${page}&${qs.stringify(filterParams)}`,
    {
      initialData: {
        results: [],
      },
      keepPreviousData: true,
    }
  );

  // const updatePCOCallback = React.useCallback((pco) => updatePCO, []);
  // This Solves the ReRendering all the time but it's tabled for now.

  React.useEffect(() => {
    setQueryStats({
      isFetchedAfterMount: pcoQuery.isFetchedAfterMount,
      count: pcoQuery.data.count,
    });
  }, [pcoQuery.isFetchedAfterMount, pcoQuery.data.count]);

  const pcos = pcoQuery.data.results;

  const allPCOIds = pcos.map((pco) => pco.id);

  const {
    selectedIds: selectedPCOIds,
    addSelectedId: addSelectedPCOId,
    addSelectedIds: addSelectedPCOIds,
    removeSelectedId: removeSelectedPCOId,
    removeSelectedIds: removeSelectedPCOIds,
    addAllSelectedIds: addAllSelectedPCOIds,
    removeAllSelectedIds: removeAllSelectedPCOIds,
    allIdsSelected: allPCOIdsSelected,
  } = useSelectIds(allPCOIds);

  const userContact = useUser();
  const blockUI = useBlockUI();

  const {query: statusOptionsQuery} = useSentinelListAPI(`projects/${project.id}/pcos/status/`, {
    initialData: [],
  });

  const statusOptions = statusOptionsQuery.data;

  const {query: tagOptionsQuery} = useSentinelListAPI(`projects/${project.id}/pcos/tags/`, {
    initialData: [],
  });

  const tagOptions = tagOptionsQuery.data;

  const pdfQueryString = React.useMemo(
    () => getQueryStringFromIds(selectedPCOIds, filterParams),
    [selectedPCOIds, filterParams]
  );

  return (
    <>
      <PaperPanel.TabHeader isLoading={pcoQuery.isFetching}>
        <PaperPanel.Header.Title>
          <PaperItem.SelectedCheckbox
            label={"Select All"}
            onChange={(event, value) => {
              if (value) {
                addSelectedPCOIds(allPCOIds);
              } else {
                removeAllSelectedPCOIds();
              }
            }}
            indeterminate={Boolean(!allPCOIdsSelected && selectedPCOIds.size < allPCOIds.length && selectedPCOIds.size)}
            checked={allPCOIdsSelected}
          />{" "}
          <FontAwesomeIcon icon={faPaperclip} /> PCOS
        </PaperPanel.Header.Title>
        <PaperPanel.Header.Actions border>
          <PaperPanel.Header.Action>
            <PaperPanel.Header.CreateButton
              onClick={() => {
                setShowCreateDialog(true);
              }}
              disabled={!pcoQuery.isFetchedAfterMount}
            >
              Create PCO
            </PaperPanel.Header.CreateButton>
          </PaperPanel.Header.Action>
          <PaperPanel.Header.Action border>
            <PaperPanel.Header.PDFButtons>
              <PaperPanel.Header.Menu.PDFMenuItem
                href={`/reports2/projects/${project.id}/pcos/by-status/?is_internal=${
                  isInternal ? "True" : "False"
                }&display_tags=on${pdfQueryString}`}
              >
                By Status
              </PaperPanel.Header.Menu.PDFMenuItem>
              <PaperPanel.Header.Menu.PDFMenuItem
                href={`/reports2/projects/${project.id}/pcos/by-status/?is_internal=${
                  isInternal ? "True" : "False"
                }&display_items=on&display_tags=on${pdfQueryString}`}
              >
                By Status With Items
              </PaperPanel.Header.Menu.PDFMenuItem>
              <MenuItemHeader border />
              <PaperPanel.Header.Menu.PDFMenuItem
                href={`/reports2/projects/${project.id}/pcos/?is_internal=${
                  isInternal ? "True" : "False"
                }&display_tags=on${pdfQueryString}`}
              >
                By Number
              </PaperPanel.Header.Menu.PDFMenuItem>
              <PaperPanel.Header.Menu.PDFMenuItem
                href={`/reports2/projects/${project.id}/pcos/?is_internal=${
                  isInternal ? "True" : "False"
                }&display_items=on&display_tags=on${pdfQueryString}`}
              >
                By Number with Items
              </PaperPanel.Header.Menu.PDFMenuItem>
            </PaperPanel.Header.PDFButtons>
          </PaperPanel.Header.Action>
          <PaperPanel.Header.Action>
            <PaperPanel.Header.BuildReportButton
              href={`/reports2/projects/${project.id}/pcos/by-status/filter/?is_internal=${
                isInternal ? "True" : "False"
              }`}
            >
              Build Reports
            </PaperPanel.Header.BuildReportButton>
          </PaperPanel.Header.Action>

          <PaperPanel.Header.Action border>
            <PaperPanel.Header.EditButton
              disabled={selectedPCOIds.size === 0}
              onClick={() => setShowBulkUpdateDialog(true)}
            >
              Edit Selected
            </PaperPanel.Header.EditButton>
          </PaperPanel.Header.Action>

          {!isInternal && (
            <PaperPanel.Header.Action border>
              <PaperPanel.Header.Button href={`/v2/projects/${project.id}/pcos/items/`}>
                <FontAwesomeIcon icon={faList} fixedWidth /> All PCO Items
              </PaperPanel.Header.Button>
            </PaperPanel.Header.Action>
          )}
          <PaperPanel.Header.Action border>
            <PaperPanel.Header.ReorderButton
              onReorder={(orderBy) => {
                blockUI.blockUI("Reordering...");
                pcoRPC
                  .mutateAsync({
                    action: `reposition-${isInternal ? "internal" : "external"}-pcos`,
                    qsParams: {order_by: orderBy},
                  })
                  .then(() => {
                    pcoQuery.refetch().then(() => blockUI.unblockUI());
                  })
                  .catch(() => {
                    pcoQuery.refetch().then(() => blockUI.unblockUI());
                  });
              }}
              onRenumber={() => {
                setRenumberConfirmationIsOpen(true);
              }}
              onFixPositions={() => {
                blockUI.blockUI("Fixing positions...");
                pcoRPC
                  .mutateAsync({
                    action: `fix-positions`,
                  })
                  .then(() => {
                    pcoQuery.refetch().then(() => blockUI.unblockUI());
                  })
                  .catch(() => {
                    pcoQuery.refetch().then(() => blockUI.unblockUI());
                  });
              }}
            />
          </PaperPanel.Header.Action>
          <PaperPanel.Header.Action border pr={0}>
            <PaperPanel.Header.Menu>
              {(popupState) => (
                <div>
                  <PaperPanel.Header.Menu.MenuItem
                    onClick={(event) => {
                      popupState.close();
                      setCreateMissingBoxFolders(true);
                    }}
                  >
                    Create Missing Box Folders
                  </PaperPanel.Header.Menu.MenuItem>
                  <PaperPanel.Header.Menu.MenuItem
                    onClick={(event) => {
                      popupState.close();
                      setFixBoxFolderNamesDialog(true);
                    }}
                  >
                    Fix Box Folder Names
                  </PaperPanel.Header.Menu.MenuItem>
                </div>
              )}
            </PaperPanel.Header.Menu>
          </PaperPanel.Header.Action>
          <PaperPanel.Header.Action pl={0}>
            <PaperPanel.Header.RefreshButton isFetching={pcoQuery.isFetching} onClick={() => pcoQuery.refetch()} />
          </PaperPanel.Header.Action>
        </PaperPanel.Header.Actions>
      </PaperPanel.TabHeader>
      <PaperPanel.TabToolbar p={1}>
        <Grid container spacing={1}>
          <Grid item sm={4} xs={12}>
            <FilterSearch
              label="Search"
              value={filterOptions.Search.value}
              name="Search"
              onChange={(value) => {
                setPage(1);
                setFilterOption("Search", value, value);
              }}
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <FilterSelect
              label="Status"
              name="status"
              options={statusOptions}
              value={filterOptions.Status.value}
              onChange={(value, label) => {
                setPage(1);
                setFilterOption("Status", value, label);
              }}
              showStatusIcon
              statusType="changeOrder"
            />
          </Grid>
          <Grid item sm={4} xs={12}>
            <FilterSelect
              // native
              label="Tag"
              name="Tag"
              options={tagOptions}
              value={filterOptions.Tag.value}
              onChange={(value, label) => {
                setPage(1);
                setFilterOption("Tag", value, label);
              }}
            />
          </Grid>
          <Grid container spacing={1}>
            <Grid item sm={6} xs={12}>
              <FilterOptionChips
                filterOptions={filterOptions}
                onDelete={(key) => {
                  clearFilterOption(key);
                }}
                onDeleteAll={() => {
                  clearAllFilterOptions();
                }}
              />
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box display="flex" justifyContent="flex-end">
                <Pagination count={pcoQuery.data.total_pages} page={page} setPage={setPage} />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </PaperPanel.TabToolbar>
      <PaperPanel.Body mx={-3}>
        <VirtualizedList itemList={pcos} isVirtualized={pcos.length > 50} onDragEnd={pcoOnDragEnd}>
          {(props) => {
            const {listItem, ...rest} = props;

            return (
              <PCOPaperItem
                pco={listItem}
                isSelected={selectedPCOIds.has(listItem.id)}
                onChangePCOSelected={(e, value) => {
                  if (value) {
                    addSelectedPCOId(listItem.id, e.nativeEvent.shiftKey);
                  } else {
                    removeSelectedPCOId(listItem.id);
                  }
                }}
                onEditPCO={() => {
                  setActivePCO(listItem);
                  setShowUpdateDialog(true);
                }}
                {...rest}
                flash={listItem.id === pcoIdToFlash}
              />
            );
          }}
        </VirtualizedList>

        {(pcoQuery.data.total_pages > 1 || pageSize > 100) && (
          <PaperPanel.Footer display="flex" justifyContent="flex-end">
            <PaginationWithPageSize
              count={pcoQuery.data.total_pages}
              page={page}
              pageSize={pageSize}
              setPage={setPage}
              setPageSize={setPageSize}
              pageSizes={[50, 100, 200]}
            />
          </PaperPanel.Footer>
        )}
      </PaperPanel.Body>
      {/* Quick Create */}
      <PCODialogForm
        ref={createFormRef}
        projectId={project.id}
        isOpen={showCreateDialog}
        isNew
        onSubmit={(values, form) => {
          // Check values for some value submitMode
          const submitMode = values.submitMode;
          delete values.submitMode;
          blockUI.blockUI("Creating...");
          const focusElement = createFormRef.current.elements.description;
          const formValues = values;
          form.restart();

          if (submitMode === "addAnother") {
            createPCO
              .mutateAsync(formValues)
              .then(() => {
                pcoQuery.refetch().then(() => {
                  focusElement.focus();
                  blockUI.unblockUI();
                });
              })
              .catch(() => blockUI.unblockUI());
          }
          if (submitMode === "saveAndClose") {
            createPCO
              .mutateAsync(formValues)
              .then((newPCO) => {
                setPCOIdToFlash(newPCO.id);
                setShowCreateDialog(false);
                pcoQuery.refetch().then(() => blockUI.unblockUI());
              })
              .catch(() => blockUI.unblockUI());
          }
          if (submitMode === "editAfterSubmit") {
            createPCO
              .mutateAsync(formValues)
              .then((newPCO) => {
                window.location.href = `/projects/${newPCO.project_id}/pcos/${newPCO.id}/`;
              })
              .then(() => setShowCreateDialog(false))
              .catch(() => blockUI.unblockUI());
          }
        }}
        handleClose={() => {
          setShowCreateDialog(false);
          setActivePCO({});
        }}
        setActivePco={(active) => {
          setActivePCO(active);
          // setPreviousSubmittalItem(getAdjacentSubmittalItems(active).previous);
          // setNextSubmittalItem(getAdjacentSubmittalItems(active).next);
        }}
        initialValues={{
          number: isInternal
            ? pcoQuery?.data?.metadata?.next_internal_number
            : pcoQuery?.data?.metadata?.next_external_number,
          status: isInternal ? "approved" : "pending",
          date: moment().format("YYYY-MM-DD"),
          created_by: userContact,
          is_internal: isInternal,
          tags: [],
        }}
        isInternal={isInternal ?? false}
      />
      {/* Quick Edit */}
      <PCODialogForm
        projectId={project.id}
        isOpen={showUpdateDialog}
        isReadOnly={activePCO.is_closed}
        initialValues={activePCO}
        onSubmit={(values) => {
          blockUI.blockUI("Updating...");
          updatePCO
            .mutateAsync(values)
            .then(() => {
              setShowUpdateDialog(false);
              setActivePCO({});
              blockUI.unblockUI();
            })
            .catch(() => blockUI.unblockUI());
        }}
        handleClose={() => {
          setShowUpdateDialog(false);
          setActivePCO({});
        }}
        setActivePCO={setActivePCO}
        pcos={pcos}
        activePCO={activePCO}
      />

      <PCOBulkUpdateForm
        isOpen={showBulkUpdateDialog}
        pcoCount={selectedPCOIds.size}
        projectId={project.id}
        restrictNovoSigners={project.restrict_novo_signers}
        onSubmit={(values) => {
          setShowBulkUpdateDialog(false);
          blockUI.blockUI("Updating...");

          const date_fields = ["initiated_date", "required_date", "completed_date", "executed_date"];

          date_fields.forEach((date) => {
            if (values[date]) {
              values[date] = moment(values[date]).format("YYYY-MM-DD");
            }
          });

          if (values.to_contact) {
            values.to_contact_id = values.to_contact.id;
          }

          if (values.from_contact) {
            values.from_contact_id = values.from_contact.id;
          }

          pcoRPC
            .mutateAsync({
              action: "update",
              data: {
                ids: Array.from(selectedPCOIds),
                update: values,
              },
              method: "PUT",
            })
            .then(() => pcoQuery.refetch().then(() => blockUI.unblockUI()))
            .catch(() => blockUI.unblockUI());
        }}
        handleClose={() => setShowBulkUpdateDialog(false)}
      />

      {/* Update Box Folders */}
      <CreateMissingBoxFoldersConfirmationDialog
        isOpen={createMissingBoxFolders}
        setIsOpen={setCreateMissingBoxFolders}
        rpc={pcoRPC}
      />
      <FixBoxFolderNamesConfirmationDialog
        isOpen={fixBoxFolderNamesDialog}
        setIsOpen={setFixBoxFolderNamesDialog}
        rpc={pcoRPC}
      />

      <ConfirmationDialog
        isOpen={renumberConfirmationIsOpen}
        onApprove={() => {
          setRenumberConfirmationIsOpen(false);
          blockUI.blockUI("Renumbering...");
          pcoRPC
            .mutateAsync({
              action: `renumber-${isInternal ? "internal" : "external"}-on-position`,
            })
            .then(() => {
              pcoQuery.refetch().then(() => blockUI.unblockUI());
            })
            .catch(() => {
              pcoQuery.refetch().then(() => blockUI.unblockUI());
            });
        }}
        onDeny={() => {
          blockUI.unblockUI();
          setRenumberConfirmationIsOpen(false);
        }}
      >
        Are you sure that you want to renumber all PCOs based on their current order? This cannot be undone.
      </ConfirmationDialog>
    </>
  );
};
